import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number/';
import * as React from 'react';

interface IProps extends InputNumberProps {
  autoFocus?: boolean;
}

const Component: React.StatelessComponent<IProps> = props => {
  const { children, ...restProps } = props;

  restProps.onChange = (value: number) => {
    const passValue = value ? value : 0;
    if (props.onChange) {
      props.onChange(passValue);
    }
  };

  return (
    <InputNumber {...restProps} type="number" decimalSeparator=".">
      {children}
    </InputNumber>
  );
};

export default Component;
