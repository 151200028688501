import { Field, FieldProps, Formik, FormikProps } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { boolean, object, string } from 'yup';

import { Col, Row, Select, Switch } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, AutoSaveFormikEnhancer, inputStyle, mediumLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { ConfigWarehouseModel, UpdateWarehouseDetailsModel } from '@src/models';
import { services } from '@src/services';
import { getBooleanAsState, getStateAsBoolean } from '@src/utils/data-transformations';
import { getFormikError } from '@src/utils/forms';

const SwitchField = withFormItem(Switch, mediumLayout);
const SelectField = withFormItem(Select, mediumLayout);

interface OwnProps {
  warehouse: ConfigWarehouseModel;
  updateWarehouseDetails: (payload: { model: UpdateWarehouseDetailsModel }) => void;
}

type FormValues = {
  state: boolean;
  timezone: string;
};

const warehouseAdditionalDataSchema = object().shape({
  state: boolean().required(),
  timezone: string().required(),
});

export const WarehouseAdditionalData: React.FunctionComponent<OwnProps> = ({
  warehouse,
  updateWarehouseDetails,
}) => {
  const updateWarehouseAdditionalData = ({ timezone, state }: FormValues) => {
    updateWarehouseDetails({
      model: {
        ...warehouse,
        warehouseId: warehouse.id,
        state: getBooleanAsState(state),
        timezone: timezone || warehouse.timezone,
      },
    });
  };

  return (
    <div>
      <Formik
        initialValues={{
          state: getStateAsBoolean(warehouse.state),
          timezone: warehouse.timezone,
        }}
        // tslint:disable-next-line: no-empty
        onSubmit={() => {}}
        validationSchema={warehouseAdditionalDataSchema}
        enableReinitialize={true}
      >
        {(renderProps: FormikProps<FormValues>) => (
          <>
            <AutoSaveForm>
              <AutoSaveFormikEnhancer
                name="state"
                onSave={updateWarehouseAdditionalData}
                render={({ name, onInstantChange }) => (
                  <Field name={name}>
                    {({ field }: FieldProps<FormValues['state']>) => (
                      <SwitchField
                        label={<FormattedMessage id="ACTIVE" />}
                        {...field}
                        checked={field.value}
                        onChange={value => {
                          onInstantChange(value);
                        }}
                      />
                    )}
                  </Field>
                )}
              />
            </AutoSaveForm>
            <AutoSaveForm>
              <AutoSaveFormikEnhancer
                name="timezone"
                onSave={updateWarehouseAdditionalData}
                render={({ name, onInstantChange }) => (
                  <Field name={name}>
                    {({ field }: FieldProps<FormValues['timezone']>) => (
                      <SelectField
                        {...field}
                        showSearch
                        label={<FormattedMessage id="TIMEZONE" />}
                        className={inputStyle}
                        options={services.dictionariesService.getTimezonesOptions()}
                        onChange={(value: string) => onInstantChange(value)}
                        error={getFormikError(
                          renderProps.touched.timezone,
                          renderProps.errors.timezone
                        )}
                      />
                    )}
                  </Field>
                )}
              />
            </AutoSaveForm>
          </>
        )}
      </Formik>

      <Row className={styles.additionalDataWrapper}>
        <Col span={6}>
          <FormattedMessage className={styles.additionalData} id="CREATED_AT" />
        </Col>
        <Col span={12}>
          <span className={styles.paddingLeft}>
            {moment(warehouse.createdAt).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        </Col>
      </Row>
    </div>
  );
};

const styles = stylesheet({
  additionalDataWrapper: {
    marginTop: '5px',
    marginBottom: '10px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  additionalData: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  borderTopLine: {
    paddingTop: '15px',
    borderTop: '1px solid #D9D9D9',
  },
  paddingTopLine: {
    paddingTop: '15px',
  },
  paddingLeft: {
    paddingLeft: '11px',
  },
});
