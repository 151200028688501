import { RequireOnlyOne } from '@src/utils/types';
import * as DOMPurify from 'dompurify';
import { Overwrite } from 'utility-types';
import {
  ConfigRegionDTO,
  ConfigRegionModel,
  ConfigShippingCategoryDTO,
  ConfigShippingCategoryModel,
  ConfigWarehouseDTO,
  ConfigWarehouseModel,
  CutoffTimesModel,
  DeliveryTypeEnum,
  LocalizationModel,
  ParcelTypeEnum,
  StateEnum,
} from './';
import {
  CategoryAdditionalOptionsDTO,
  CategoryAdditionalOptionsModel,
  CategoryRequirementsDTO,
  CategoryRequirementsModel,
  ConfigFilterRuleDTO,
  ConfigFilterRuleModel,
  CustomEventDTO,
  CustomEventLocalizationDTO,
  CustomEventLocalizationModel,
  CustomEventModel,
  DeliveryTimePropertiesDTO,
  DeliveryTimePropertiesModel,
  DeliveryTimeUnit,
  PriceRulesDTO,
  PriceRulesModel,
  RegionType,
  ShippingDateAdjustmentDTO,
  ShippingDateAdjustmentModel,
  WidgetFeaturesDTO,
  WidgetFeaturesModel,
  WidgetStyleDTO,
  WidgetStyleModel,
} from './config-models';

export class UpdateConfigRegionModel {
  name: ConfigRegionModel['name'];
  countryConfig?: ConfigRegionModel['regionCountryConfig'];
  regionPostalCodeConfig?: ConfigRegionModel['regionPostalCodeConfig'];
  regionId: ConfigRegionModel['id'];
  warehouseId: ConfigRegionModel['warehouseId'];
  state?: ConfigRegionModel['state'];
  regionType: ConfigRegionModel['regionType'];
  features?: ConfigRegionModel['features'];

  static createFromDTO(dto: UpdateConfigRegionDTO): UpdateConfigRegionModel {
    return {
      name: dto.name,
      countryConfig: dto.country_config,
      regionPostalCodeConfig: dto.postalcode_config && {
        country: dto.postalcode_config.country,
        postalCodes: dto.postalcode_config.postal_codes,
        excludedPostalCodes: dto.postalcode_config.excluded_postal_codes,
      },
      regionId: dto.region_id,
      warehouseId: dto.warehouse_id,
      state: dto.state,
      regionType: dto.region_type,
      features: dto.features && {
        showHighDemandAffectsDeliveryDisclaimerMessage:
          dto.features.show_high_demand_affects_delivery_disclaimer_message,
      },
    };
  }
}

export class UpdateConfigRegionDTO {
  name: ConfigRegionDTO['name'];
  country_config?: ConfigRegionDTO['region_country_config'];
  postalcode_config?: ConfigRegionDTO['region_postal_code_config'];
  region_id: ConfigRegionDTO['id'];
  warehouse_id: ConfigRegionDTO['warehouse_id'];
  state?: ConfigRegionDTO['state'];
  region_type: ConfigRegionDTO['region_type'];
  features?: ConfigRegionDTO['features'];

  static createFromModel(model: UpdateConfigRegionModel): UpdateConfigRegionDTO {
    return {
      name: model.name,
      country_config: model.countryConfig,
      postalcode_config: model.regionPostalCodeConfig,
      region_id: model.regionId,
      warehouse_id: model.warehouseId,
      state: model.state,
      region_type: model.regionType,
      features: {
        show_high_demand_affects_delivery_disclaimer_message:
          model.features?.showHighDemandAffectsDeliveryDisclaimerMessage ?? false,
      },
    };
  }
}

export class UpdateDeliveryTimeModel {
  min?: number;
  max?: number;
  unit?: DeliveryTimeUnit;

  static createFromDTO(dto: UpdateDeliveryTimeDTO): UpdateDeliveryTimeModel {
    return {
      max: dto.max,
      min: dto.min,
      unit: dto.unit,
    };
  }
}

export class UpdateDeliveryTimeDTO {
  min?: number;
  max?: number;
  unit?: DeliveryTimeUnit;

  static createFromModel(model: UpdateDeliveryTimeModel): UpdateDeliveryTimeDTO {
    return {
      min: model.min,
      max: model.max,
      unit: model.unit,
    };
  }
}

class CategoryDetailRequestDTO {
  name?: ConfigShippingCategoryDTO['name'];
  region_ids?: ConfigShippingCategoryDTO['region_ids'];
  methods?: ConfigShippingCategoryDTO['shipping_methods'];
  delivery_type?: ConfigShippingCategoryDTO['delivery_type'];
  sort_order?: ConfigShippingCategoryDTO['sort_order'];
  preselected?: ConfigShippingCategoryDTO['preselected'];
  state?: ConfigShippingCategoryDTO['state'];
}

export class UpdateCategoryDetailsDTO extends CategoryDetailRequestDTO {
  category_id: ConfigShippingCategoryDTO['id'];
  requirements?: ConfigShippingCategoryDTO['requirements'];
  delivery_time?: UpdateDeliveryTimeDTO;
  delivery_time_properties?: Overwrite<
    DeliveryTimePropertiesDTO,
    {
      timespan?: DeliveryTimePropertiesDTO['timespan'] | null;
    }
  >;
  additional_options?: ConfigShippingCategoryDTO['additional_options'];
  max_choices_count?: number;

  static createFromModel(model: UpdateCategoryDetailsModel): UpdateCategoryDetailsDTO {
    return {
      category_id: model.categoryId,
      name: model.name,
      region_ids: model.regionIds,
      methods: model.shippingMethods,
      delivery_type: model.deliveryType,
      sort_order: model.sortOrder,
      preselected: model.preselected,
      state: model.state,
      requirements:
        model.requirements && CategoryRequirementsDTO.createFromModel(model.requirements),
      delivery_time_properties:
        model.deliveryTimeProperties &&
        DeliveryTimePropertiesDTO.createFromModel(model.deliveryTimeProperties),
      delivery_time: model.deliveryTime,
      additional_options:
        model.additionalOptions &&
        CategoryAdditionalOptionsDTO.createFromModel(model.additionalOptions),
      max_choices_count: model.maxChoicesCount,
    };
  }
}

class CategoryDetailsRequestModel {
  name?: ConfigShippingCategoryModel['name'];
  shippingMethods?: ConfigShippingCategoryModel['shippingMethods'];
  deliveryType?: ConfigShippingCategoryModel['deliveryType'];
  regionIds?: ConfigShippingCategoryModel['regionIds'];
  sortOrder?: ConfigShippingCategoryModel['sortOrder'];
  preselected?: ConfigShippingCategoryModel['preselected'];
  state?: ConfigShippingCategoryModel['state'];
}

export class UpdateCategoryDetailsModel extends CategoryDetailsRequestModel {
  categoryId: ConfigShippingCategoryModel['id'];
  requirements?: ConfigShippingCategoryModel['requirements'];
  deliveryTime?: UpdateDeliveryTimeModel;
  deliveryTimeProperties?: Overwrite<
    DeliveryTimePropertiesModel,
    {
      timespan?: DeliveryTimePropertiesModel['timespan'] | null;
    }
  >;
  additionalOptions?: ConfigShippingCategoryModel['additionalOptions'];
  maxChoicesCount?: number;

  static createFromDTO(dto: UpdateCategoryDetailsDTO): UpdateCategoryDetailsModel {
    return {
      categoryId: dto.category_id,
      name: dto.name,
      regionIds: dto.region_ids,
      shippingMethods: dto.methods,
      deliveryType: dto.delivery_type,
      sortOrder: dto.sort_order,
      preselected: dto.preselected,
      state: dto.state,
      requirements: CategoryRequirementsModel.createFromDTO(dto.requirements),
      deliveryTime: dto.delivery_time,
      deliveryTimeProperties: dto.delivery_time_properties,
      additionalOptions: CategoryAdditionalOptionsModel.createFromDTO(dto.additional_options),
      maxChoicesCount: dto.max_choices_count,
    };
  }
}

export class CreateCategoryModel extends CategoryDetailsRequestModel {
  name: ConfigShippingCategoryModel['name'];
  deliveryTime: ConfigShippingCategoryModel['deliveryTime'];
  regionIds: ConfigShippingCategoryModel['regionIds'];
  filterRules: ConfigShippingCategoryModel['filterRules'];
  timeFormatting: ConfigShippingCategoryModel['timeFormatting'];
  requirements: ConfigShippingCategoryModel['requirements'];
  additionalOptions: ConfigShippingCategoryModel['additionalOptions'];

  static createFromDTO(dto: CreateCategoryDTO): CreateCategoryModel {
    return {
      name: dto.name,
      regionIds: dto.region_ids,
      shippingMethods: dto.methods,
      deliveryType: dto.delivery_type,
      sortOrder: dto.sort_order,
      preselected: dto.preselected,
      deliveryTime: dto.delivery_time,
      filterRules: dto.filter_rules || [],
      timeFormatting: dto.time_formatting,
      state: dto.state,
      requirements: CategoryRequirementsModel.createFromDTO(dto.requirements),
      additionalOptions: CategoryAdditionalOptionsModel.createFromDTO(dto.additional_options),
    };
  }
}

export class CreateCategoryDTO extends CategoryDetailRequestDTO {
  name: ConfigShippingCategoryDTO['name'];
  delivery_time: ConfigShippingCategoryDTO['delivery_time'];
  region_ids: ConfigShippingCategoryDTO['region_ids'];
  filter_rules: ConfigShippingCategoryDTO['filter_rules'];
  time_formatting: ConfigShippingCategoryDTO['time_formatting'];
  requirements: ConfigShippingCategoryDTO['requirements'];
  additional_options: ConfigShippingCategoryDTO['additional_options'];

  static createFromModel(model: CreateCategoryModel): CreateCategoryDTO {
    return {
      name: model.name,
      region_ids: model.regionIds,
      methods: model.shippingMethods,
      delivery_type: model.deliveryType,
      sort_order: model.sortOrder,
      preselected: model.preselected,
      delivery_time: model.deliveryTime,
      filter_rules: model.filterRules,
      time_formatting: model.timeFormatting,
      state: model.state,
      requirements: CategoryRequirementsDTO.createFromModel(model.requirements),
      additional_options: CategoryAdditionalOptionsDTO.createFromModel(model.additionalOptions),
    };
  }
}

export class UpdatePriceRulesDTO {
  conditions: string[];
  price: number;
  valid_from?: string;
  valid_until?: string;

  static createFromModel(rule: UpdatePriceRulesModel): UpdatePriceRulesDTO {
    return {
      conditions: rule.conditions,
      price: rule.price,
      valid_from: rule.validFrom,
      valid_until: rule.validUntil,
    };
  }
}

export class UpdatePriceRulesModel {
  conditions: string[];
  price: number;
  validFrom?: string;
  validUntil?: string;
}

export class UpdateCarrierProductModel {
  regionId: string;
  method: string;
  priceRules?: UpdatePriceRulesModel[];
  externalMethodId?: string;
  deliveryTypes: DeliveryTypeEnum[];
  shippingDateAdjustment?: ShippingDateAdjustmentModel;

  static createFromDTO(dto: UpdateCarrierProductDTO): UpdateCarrierProductModel {
    return {
      regionId: dto.region_id,
      method: dto.method,
      priceRules: dto.price_rules,
      externalMethodId: dto.external_method_id,
      deliveryTypes: dto.delivery_types,
      shippingDateAdjustment:
        dto.shipping_date_adjustment &&
        ShippingDateAdjustmentModel.createFromDTO(dto.shipping_date_adjustment),
    };
  }
}

export class UpdateCarrierProductFilterRulesModel {
  regionId: string;
  method: string;
  deliveryTypes: DeliveryTypeEnum[];
  filterRules: ConfigFilterRuleModel[];
}

export class UpdateCarrierProductFilterRulesDTO {
  region_id: string;
  method: string;
  delivery_types: DeliveryTypeEnum[];
  filter_rules: ConfigFilterRuleDTO[];

  static createFromModel(
    model: UpdateCarrierProductFilterRulesModel
  ): UpdateCarrierProductFilterRulesDTO {
    return {
      region_id: model.regionId,
      method: model.method,
      delivery_types: model.deliveryTypes,
      filter_rules: model.filterRules,
    };
  }
}

export class UpdateCarrierProductDTO {
  region_id: string;
  method: string;
  price_rules?: UpdatePriceRulesDTO[];
  external_method_id?: string;
  delivery_types: DeliveryTypeEnum[];
  shipping_date_adjustment?: ShippingDateAdjustmentDTO;

  static createFromModel(model: UpdateCarrierProductModel): UpdateCarrierProductDTO {
    return {
      region_id: model.regionId,
      method: model.method,
      price_rules: model.priceRules?.map(rule => UpdatePriceRulesDTO.createFromModel(rule)),
      external_method_id: model.externalMethodId,
      delivery_types: model.deliveryTypes,
      shipping_date_adjustment:
        model.shippingDateAdjustment &&
        ShippingDateAdjustmentDTO.createFromModel(model.shippingDateAdjustment),
    };
  }
}

export class UpdateCategoryTranslationsModel {
  categoryId: ConfigShippingCategoryModel['id'];
  locale: string;
  name: LocalizationModel['name'];
  customText: LocalizationModel['customText'];
  customInfoText: LocalizationModel['customInfoText'];

  static createFromDTO(dto: UpdateCategoryTranslationsDTO): UpdateCategoryTranslationsModel {
    return {
      categoryId: dto.category_id,
      locale: dto.locale,
      name: dto.name,
      customText: dto.custom_text,
      customInfoText: dto.custom_info_text,
    };
  }
}

const sanitize = (text: string | undefined) =>
  text && DOMPurify.sanitize(text, { ALLOWED_TAGS: ['a', 'br'], ALLOWED_ATTR: ['target', 'href'] });

export class UpdateCategoryTranslationsDTO {
  category_id: ConfigShippingCategoryModel['id'];
  locale: string;
  name: LocalizationModel['name'];
  custom_text: LocalizationModel['customText'];
  custom_info_text: LocalizationModel['customInfoText'];

  static createFromModel(model: UpdateCategoryTranslationsModel): UpdateCategoryTranslationsDTO {
    return {
      category_id: model.categoryId,
      locale: model.locale,
      name: model.name,
      custom_text: sanitize(model.customText),
      custom_info_text: sanitize(model.customInfoText),
    };
  }
}

export class AddCategoryTagsModel {
  categoryId: string;
  tagName: string;

  static createFromDTO(dto: AddCategoryTagsDTO): AddCategoryTagsModel {
    return {
      categoryId: dto.category_id,
      tagName: dto.tag_name,
    };
  }
}

export class AddCategoryTagsDTO {
  category_id: string;
  tag_name: string;

  static createFromModel(model: AddCategoryTagsModel): AddCategoryTagsDTO {
    return {
      category_id: model.categoryId,
      tag_name: model.tagName,
    };
  }
}

export class CreateCategoryLabelModel {
  categoryId: string;
  name: string;
  type: string;
  state: 'INACTIVE' | 'ACTIVE';

  static createFromDTO(dto: CreateCategoryLabelDTO): CreateCategoryLabelModel {
    return {
      categoryId: dto.category_id,
      name: dto.name,
      type: dto.type,
      state: dto.state,
    };
  }
}

export class CreateCategoryLabelDTO {
  category_id: string;
  name: string;
  type: string;
  state: 'INACTIVE' | 'ACTIVE';

  static createFromModel(model: CreateCategoryLabelModel): CreateCategoryLabelDTO {
    return {
      category_id: model.categoryId,
      name: model.name,
      type: model.type,
      state: model.state,
    };
  }
}

export class UpdateCategoryFilterRulesModel {
  categoryId: string;
  filterRules: ConfigFilterRuleModel[];
}

export class UpdateCategoryFilterRulesDTO {
  category_id: string;
  filter_rules: ConfigFilterRuleDTO[];

  static createFromModel(model: UpdateCategoryFilterRulesModel): UpdateCategoryFilterRulesDTO {
    return {
      category_id: model.categoryId,
      filter_rules: model.filterRules,
    };
  }
}

export class CreateWarehouseModel {
  address: GenericAddressEntityModel;
  timezone: ConfigWarehouseModel['timezone'];
  state?: StateEnum;

  static createFromDTO(dto: CreateWarehouseDTO): CreateWarehouseModel {
    return {
      address: GenericAddressEntityModel.createFromDTO(dto.address),
      timezone: dto.timezone,
      state: dto.state,
    };
  }
}

export class CreateWarehouseDTO {
  address: GenericAddressEntityDTO;
  timezone: ConfigWarehouseDTO['timezone'];
  state?: StateEnum;

  static createFromModel(model: CreateWarehouseModel): CreateWarehouseDTO {
    return {
      address: GenericAddressEntityDTO.createFromModel(model.address),
      timezone: model.timezone,
      state: model.state,
    };
  }
}

export class UpdateWarehouseDetailsModel {
  warehouseId: ConfigWarehouseModel['id'];
  address: GenericAddressEntityModel;
  timezone: ConfigWarehouseModel['timezone'];
  state?: StateEnum;
  contactData?: ContactDataModel;

  static createFromDTO(dto: UpdateWarehouseDetailsDTO): UpdateWarehouseDetailsModel {
    return {
      warehouseId: dto.warehouse_id,
      address: GenericAddressEntityModel.createFromDTO(dto.address),
      timezone: dto.timezone,
      state: dto.state,
      contactData: dto.contact_data && ContactDataModel.createFromDTO(dto.contact_data),
    };
  }
}

export class UpdateWarehouseDetailsDTO {
  warehouse_id: ConfigWarehouseDTO['id'];
  address: GenericAddressEntityDTO;
  timezone: ConfigWarehouseDTO['timezone'];
  state?: StateEnum;
  contact_data?: ContactDataDTO;

  static createFromModel(model: UpdateWarehouseDetailsModel): UpdateWarehouseDetailsDTO {
    return {
      warehouse_id: model.warehouseId,
      address: GenericAddressEntityDTO.createFromModel(model.address),
      timezone: model.timezone,
      state: model.state,
      contact_data: model.contactData && ContactDataDTO.createFromModel(model.contactData),
    };
  }
}

export class CreateWarehouseCutoffTimesDTO {
  warehouse_id: string;
  method: string;
  carrier_cutoff?: boolean;
  cutoff_times: CutoffTimesModel;

  static createFromModel(model: CreateWarehouseCutoffTimesModel): CreateWarehouseCutoffTimesDTO {
    return {
      warehouse_id: model.warehouseId,
      method: model.method,
      carrier_cutoff: model.carrierCutoff,
      cutoff_times: model.cutoffTimes,
    };
  }
}

export class CreateWarehouseCutoffTimesModel {
  warehouseId: string;
  method: string;
  carrierCutoff?: boolean;
  cutoffTimes: CutoffTimesModel;

  static createFromDTO(dto: CreateWarehouseCutoffTimesDTO): CreateWarehouseCutoffTimesModel {
    return {
      warehouseId: dto.warehouse_id,
      method: dto.method,
      carrierCutoff: dto.carrier_cutoff,
      cutoffTimes: dto.cutoff_times,
    };
  }
}

export class DeleteWarehouseCutoffTimesModel {
  warehouseId: string;
  method: string;

  static createFromDTO(dto: DeleteWarehouseCutoffTimesDTO): DeleteWarehouseCutoffTimesModel {
    return {
      warehouseId: dto.warehouse_id,
      method: dto.method,
    };
  }
}

export class DeleteWarehouseCutoffTimesDTO {
  warehouse_id: string;
  method: string;

  static createFromModel(model: DeleteWarehouseCutoffTimesModel): DeleteWarehouseCutoffTimesDTO {
    return {
      warehouse_id: model.warehouseId,
      method: model.method,
    };
  }
}

export class UpdateWarehouseCutoffTimesDTO {
  warehouse_id: ConfigWarehouseDTO['id'];
  method: string;
  carrier_cutoff?: boolean;
  cutoff_times: CutoffTimesModel;

  static createFromModel(model: UpdateWarehouseCutoffTimesModel): UpdateWarehouseCutoffTimesDTO {
    return {
      warehouse_id: model.warehouseId,
      method: model.method,
      carrier_cutoff: model.carrierCutoff,
      cutoff_times: model.cutoffTimes,
    };
  }
}

export class UpdateWarehouseCutoffTimesModel {
  warehouseId: ConfigWarehouseModel['id'];
  method: string;
  carrierCutoff?: boolean;
  cutoffTimes: CutoffTimesModel;

  static createFromDTO(dto: UpdateWarehouseCutoffTimesDTO): UpdateWarehouseCutoffTimesModel {
    return {
      warehouseId: dto.warehouse_id,
      method: dto.method,
      carrierCutoff: dto.carrier_cutoff,
      cutoffTimes: dto.cutoff_times,
    };
  }
}

export class UpdateWarehouseCarrierCutoffDTO {
  warehouse_id: ConfigWarehouseDTO['id'];
  method: string;
  carrier_cutoff: boolean;

  static createFromModel(
    model: UpdateWarehouseCarrierCutoffModel
  ): UpdateWarehouseCarrierCutoffDTO {
    return {
      warehouse_id: model.warehouseId,
      method: model.method,
      carrier_cutoff: model.carrierCutoff,
    };
  }
}

export class UpdateWarehouseCarrierCutoffModel {
  warehouseId: ConfigWarehouseModel['id'];
  method: string;
  carrierCutoff: boolean;

  static createFromDTO(dto: UpdateWarehouseCarrierCutoffDTO): UpdateWarehouseCarrierCutoffModel {
    return {
      warehouseId: dto.warehouse_id,
      method: dto.method,
      carrierCutoff: dto.carrier_cutoff,
    };
  }
}

export class UpdatePreselectionOrderModel {
  country: string;
  countrySettings: CountrySettingsModel[];

  static createFromDTO(dto: UpdatePreselectionOrderDTO): UpdatePreselectionOrderModel {
    return {
      country: dto.country,
      countrySettings: dto.country_settings.map(countrySetting =>
        CountrySettingsModel.createFromDTO(countrySetting)
      ),
    };
  }
}

export class UpdatePreselectionOrderDTO {
  country: string;
  country_settings: CountrySettingsDTO[];

  static createFromModel(model: UpdatePreselectionOrderModel): UpdatePreselectionOrderDTO {
    return {
      country: model.country,
      country_settings: model.countrySettings.map(countrySetting =>
        CountrySettingsDTO.createFromModel(countrySetting)
      ),
    };
  }
}

class CountrySettingsModel {
  categoryId: string;
  preselectionOrder: number;
  useForFallback?: boolean;

  static createFromDTO(dto: CountrySettingsDTO): CountrySettingsModel {
    return {
      categoryId: dto.category_id,
      preselectionOrder: dto.preselection_order,
      useForFallback: dto.use_for_fallback,
    };
  }
}

class CountrySettingsDTO {
  category_id: string;
  preselection_order: number;
  use_for_fallback?: boolean;

  static createFromModel(model: CountrySettingsModel): CountrySettingsDTO {
    return {
      category_id: model.categoryId,
      preselection_order: model.preselectionOrder,
      use_for_fallback: model.useForFallback,
    };
  }
}

export class CreateOrUpdateWarehouseCutoffTimesModel {
  action: 'CREATE' | 'UPDATE';
  model: UpdateWarehouseCutoffTimesModel;
}

export class CreateOrUpdateWarehouseCutoffTimesDTO {
  action: 'CREATE' | 'UPDATE';
  model: UpdateWarehouseCutoffTimesDTO;

  static createFromModel(
    model: CreateOrUpdateWarehouseCutoffTimesModel
  ): CreateOrUpdateWarehouseCutoffTimesDTO {
    return {
      action: model.action,
      model: UpdateWarehouseCutoffTimesDTO.createFromModel(model.model),
    };
  }
}

export class GenericAddressEntityModel {
  name: string;
  careOf?: string;
  attn?: string;
  addressLines: string[];
  city: string;
  region: string;
  postalCode: string;
  country: string;
  doorCode: string;
  coordinates?: {
    lat: number;
    lng: number;
  };

  static createFromDTO(dto: GenericAddressEntityDTO): GenericAddressEntityModel {
    return {
      name: dto.name,
      careOf: dto.care_of,
      attn: dto.attn,
      addressLines: dto.address_lines,
      city: dto.city,
      region: dto.region,
      postalCode: dto.postal_code,
      country: dto.country,
      doorCode: dto.door_code,
      coordinates: dto.coordinates,
    };
  }
}

export class GenericAddressEntityDTO {
  name: string;
  care_of?: string;
  attn?: string;
  address_lines: string[];
  city: string;
  region: string;
  postal_code: string;
  country: string;
  coordinates?: {
    lat: number;
    lng: number;
  };
  door_code: string;

  static createFromModel(model: GenericAddressEntityModel): GenericAddressEntityDTO {
    return {
      name: model.name,
      care_of: model.careOf,
      attn: model.attn,
      address_lines: model.addressLines,
      city: model.city,
      region: model.region,
      postal_code: model.postalCode,
      country: model.country,
      door_code: model.doorCode,
      coordinates: model.coordinates,
    };
  }
}

export class ContactDataModel {
  email?: string;
  phone?: string;

  static createFromDTO(dto: ContactDataDTO): ContactDataModel {
    return {
      email: dto.email,
      phone: dto.phone,
    };
  }
}

export class ContactDataDTO {
  email?: string;
  phone?: string;

  static createFromModel(model: ContactDataModel): ContactDataDTO {
    return { email: model.email, phone: model.phone };
  }
}

export class DeactivateShippingMethodDTO {
  regionIds: string[];
  categoryIds: string[];
}

export class DeactivateShippingMethodModel {
  regionIds: string[];
  categoryIds: string[];

  static createFromDTO(dto: DeactivateShippingMethodDTO): DeactivateShippingMethodModel {
    return {
      regionIds: dto.regionIds,
      categoryIds: dto.categoryIds,
    };
  }
}

export class DeleteTranslationModel {
  categoryId: string;
  locale: string;

  static createFromDTO(dto: DeleteTranslationDTO): DeleteTranslationModel {
    return {
      categoryId: dto.category_id,
      locale: dto.locale,
    };
  }
}

export class DeleteTranslationDTO {
  category_id: string;
  locale: string;

  static createFromModel(model: DeleteTranslationModel): DeleteTranslationDTO {
    return {
      category_id: model.categoryId,
      locale: model.locale,
    };
  }
}

export class RemoveCategoryTagsModel {
  categoryId: string;
  tagName: string;

  static createFromDTO(dto: RemoveCategoryTagsDTO): RemoveCategoryTagsModel {
    return {
      categoryId: dto.category_id,
      tagName: dto.tag_name,
    };
  }
}

export class RemoveCategoryTagsDTO {
  category_id: string;
  tag_name: string;

  static createFromModel(model: RemoveCategoryTagsModel): RemoveCategoryTagsDTO {
    return {
      category_id: model.categoryId,
      tag_name: model.tagName,
    };
  }
}

export class DeleteCategoryLabelModel {
  categoryId: string;
  labelId: string;

  static createFromDTO(dto: DeleteCategoryLabelDTO): DeleteCategoryLabelModel {
    return {
      categoryId: dto.category_id,
      labelId: dto.label_id,
    };
  }
}

export class DeleteCategoryLabelDTO {
  category_id: string;
  label_id: string;

  static createFromModel(model: DeleteCategoryLabelModel): DeleteCategoryLabelDTO {
    return {
      category_id: model.categoryId,
      label_id: model.labelId,
    };
  }
}

export class CreateCarrierProductModel {
  regionId: string;
  method: string;
  externalMethodId?: string;
  deliveryTypes: DeliveryTypeEnum[];
  priceRules: PriceRulesModel[];

  static createFromDTO(dto: CreateCarrierProductDTO): CreateCarrierProductModel {
    return {
      regionId: dto.region_id,
      method: dto.method,
      externalMethodId: dto.external_method_id,
      deliveryTypes: dto.delivery_types,
      priceRules: dto.price_rules.map(rule => PriceRulesModel.createFromDTO(rule)),
    };
  }
}

export class CreateCarrierProductDTO {
  region_id: string;
  method: string;
  external_method_id?: string;
  delivery_types: DeliveryTypeEnum[];
  price_rules: PriceRulesDTO[];

  static createFromModel(model: CreateCarrierProductModel): CreateCarrierProductDTO {
    return {
      region_id: model.regionId,
      method: model.method,
      external_method_id: model.externalMethodId,
      delivery_types: model.deliveryTypes,
      price_rules: model.priceRules.map(rule => PriceRulesDTO.createFromModel(rule)),
    };
  }
}

export class DeleteCarrierProductModel {
  regionId: string;
  method: string;
  deliveryTypes: DeliveryTypeEnum[];

  static createFromDTO(dto: DeleteCarrierProductDTO): DeleteCarrierProductModel {
    return {
      regionId: dto.region_id,
      method: dto.method,
      deliveryTypes: dto.delivery_types,
    };
  }
}

export class DeleteCarrierProductDTO {
  region_id: string;
  method: string;
  delivery_types: DeliveryTypeEnum[];

  static createFromModel(model: DeleteCarrierProductModel): DeleteCarrierProductDTO {
    return {
      region_id: model.regionId,
      method: model.method,
      delivery_types: model.deliveryTypes,
    };
  }
}

type CreateRegionDTO = RequireOnlyOne<
  {
    name: string;
    group_id?: string;
    country_config?: RequireOnlyOne<{
      included?: string[];
      excluded?: string[];
    }>;
    postalcode_config?: {
      postal_codes?: string[];
      excluded_postal_codes?: string[];
      country: string;
    };
    region_type?: RegionType;
    warehouse_id?: string;
    state: StateEnum;
  },
  'country_config' | 'postalcode_config'
>;

type CreateRegionModel = RequireOnlyOne<
  {
    name: string;
    state: StateEnum;
    countryConfig?: RequireOnlyOne<{ included?: string[]; excluded?: string[] }>;
    postalcodeConfig?: { postalCodes?: string[]; excludedPostalCodes?: string[]; country: string };
    regionType?: RegionType;
    warehouseId?: string;
    groupId?: string;
  },
  'postalcodeConfig' | 'countryConfig'
>;

export class CreateRegion {
  dto: CreateRegionDTO;
  model: CreateRegionModel;

  static createFromModel(model: CreateRegion['model']): CreateRegion['dto'] {
    return {
      name: model.name,
      ...(model.countryConfig
        ? {
            country_config: {
              ...(model.countryConfig.included
                ? { included: model.countryConfig.included }
                : { excluded: model.countryConfig.excluded }),
            },
          }
        : {
            postalcode_config: {
              postal_codes: model.postalcodeConfig?.postalCodes ?? [],
              excluded_postal_codes: model.postalcodeConfig?.excludedPostalCodes ?? [],
              country: model.postalcodeConfig?.country ?? '',
            },
          }),
      region_type: model.regionType,
      warehouse_id: model.warehouseId,
      group_id: model.groupId,
      state: model.state,
    };
  }

  static createFromDTO(dto: CreateRegion['dto']): CreateRegion['model'] {
    return {
      name: dto.name,
      ...(dto.country_config
        ? {
            countryConfig: {
              ...(dto.country_config.included
                ? { included: dto.country_config.included }
                : { excluded: dto.country_config.excluded }),
            },
          }
        : {
            postalcodeConfig: {
              postalCodes: dto.postalcode_config?.postal_codes ?? [],
              excludedPostalCodes: dto.postalcode_config?.excluded_postal_codes ?? [],
              country: dto.postalcode_config?.country ?? '',
            },
          }),
      regionType: dto.region_type,
      warehouseId: dto.warehouse_id,
      groupId: dto.group_id,
      state: dto.state,
    };
  }
}

export class UpdateWidgetConfigurationModel {
  features?: WidgetFeaturesModel;
  style?: WidgetStyleModel;

  static createFromDTO(dto: UpdateWidgetConfigurationDTO): UpdateWidgetConfigurationModel {
    return {
      features: dto.features && WidgetFeaturesModel.createFromDTO(dto.features),
      style: dto.style && WidgetStyleModel.createFromDTO(dto.style),
    };
  }
}

export class UpdateWidgetConfigurationDTO {
  features?: WidgetFeaturesDTO;
  style?: WidgetStyleDTO;

  static createFromModel(model: UpdateWidgetConfigurationModel): UpdateWidgetConfigurationDTO {
    return {
      features: model.features && WidgetFeaturesDTO.createFromModel(model.features),
      style: model.style && WidgetStyleDTO.createFromModel(model.style),
    };
  }
}

export class UpdateWidgetCountryConfigurationModel {
  country: string;
  features?: WidgetFeaturesModel;
  carrierLogos?: { methods: string[] };

  static createFromDTO(
    dto: UpdateWidgetCountryConfigurationDTO
  ): UpdateWidgetCountryConfigurationModel {
    return {
      country: dto.country,
      features: dto.features && WidgetFeaturesModel.createFromDTO(dto.features),
      carrierLogos: dto.carrier_logos,
    };
  }
}

export class UpdateWidgetCountryConfigurationDTO {
  country: string;
  features?: WidgetFeaturesDTO;
  carrier_logos?: { methods: string[] };

  static createFromModel(
    model: UpdateWidgetCountryConfigurationModel
  ): UpdateWidgetCountryConfigurationDTO {
    return {
      country: model.country,
      features: model.features && WidgetFeaturesDTO.createFromModel(model.features),
      carrier_logos: model.carrierLogos,
    };
  }
}

export class UpdateCategoryLabelDetailsDTO {
  label_id: string;
  category_id: string;
  name?: string;
  type?:
    | 'LABEL_TYPE_PRIORITY'
    | 'LABEL_TYPE_SUSTAINABILITY'
    | 'LABEL_TYPE_CUSTOM'
    | 'LABEL_TYPE_SWAN';
  state?: 'ACTIVE' | 'INACTIVE';

  static createFromModel(model: UpdateCategoryLabelDetailsModel): UpdateCategoryLabelDetailsDTO {
    return {
      label_id: model.labelId,
      category_id: model.categoryId,
      name: model.name,
      type: model.type,
      state: model.state,
    };
  }
}

export class UpdateCategoryLabelDetailsModel {
  labelId: string;
  categoryId: string;
  name?: string;
  type?:
    | 'LABEL_TYPE_PRIORITY'
    | 'LABEL_TYPE_SUSTAINABILITY'
    | 'LABEL_TYPE_CUSTOM'
    | 'LABEL_TYPE_SWAN';
  state?: 'ACTIVE' | 'INACTIVE';

  static createFromDTO(dto: UpdateCategoryLabelDetailsDTO): UpdateCategoryLabelDetailsModel {
    return {
      labelId: dto.label_id,
      categoryId: dto.category_id,
      name: dto.name,
      type: dto.type,
      state: dto.state,
    };
  }
}

export class UpdateCategoryLabelTranslationsModel {
  categoryId: string;
  labelId: string;
  locale: string;
  name?: string;
  description?: string;

  static createFromDTO(
    dto: UpdateCategoryLabelTranslationsDTO
  ): UpdateCategoryLabelTranslationsModel {
    return {
      categoryId: dto.category_id,
      labelId: dto.label_id,
      locale: dto.locale,
      name: dto.name,
      description: dto.description,
    };
  }
}

export class UpdateCategoryLabelTranslationsDTO {
  category_id: string;
  label_id: string;
  locale: string;
  name?: string;
  description?: string;

  static createFromModel(
    model: UpdateCategoryLabelTranslationsModel
  ): UpdateCategoryLabelTranslationsDTO {
    return {
      category_id: model.categoryId,
      label_id: model.labelId,
      locale: model.locale,
      name: model.name,
      description: model.description,
    };
  }
}

export class DeleteCategoryLabelTranslationsModel {
  categoryId: string;
  labelId: string;
  locale: string;

  static createFromDTO(
    dto: DeleteCategoryLabelTranslationsDTO
  ): DeleteCategoryLabelTranslationsModel {
    return {
      categoryId: dto.category_id,
      labelId: dto.label_id,
      locale: dto.locale,
    };
  }
}

export class DeleteCategoryLabelTranslationsDTO {
  category_id: string;
  label_id: string;
  locale: string;

  static createFromModel(
    model: DeleteCategoryLabelTranslationsModel
  ): DeleteCategoryLabelTranslationsDTO {
    return {
      category_id: model.categoryId,
      label_id: model.labelId,
      locale: model.locale,
    };
  }
}

export class UpdateCategoryLabelsOrderModel {
  categoryId: string;
  labelsIds: string[];

  static createFromDTO(dto: UpdateCategoryLabelsOrderDTO): UpdateCategoryLabelsOrderModel {
    return {
      categoryId: dto.category_id,
      labelsIds: dto.labels_ids,
    };
  }
}

export class UpdateCategoryLabelsOrderDTO {
  category_id: string;
  labels_ids: string[];

  static createFromModel(model: UpdateCategoryLabelsOrderModel): UpdateCategoryLabelsOrderDTO {
    return {
      category_id: model.categoryId,
      labels_ids: model.labelsIds,
    };
  }
}

export type DeliveryAddonType =
  | 'TYPE_UNKNOWN'
  | 'TYPE_CUSTOM'
  | 'TYPE_CARRY_IN'
  | 'TYPE_LEAVE_AT_DOOR'
  | 'TYPE_UNWRAPPING';

export class CreateDeliveryAddonModel {
  categoryId: string;
  name: string;
  type: DeliveryAddonType;
  externalId?: string;
  price: {
    value: string;
    vatRate: number;
  };
  defaults: {
    selected: boolean;
  };
  state: 'ACTIVE' | 'INACTIVE';

  static createFromDTO(dto: CreateDeliveryAddonDTO): CreateDeliveryAddonModel {
    return {
      categoryId: dto.category_id,
      name: dto.name,
      type: dto.type,
      externalId: dto.external_id,
      price: {
        value: dto.price.value,
        vatRate: dto.price.vat_rate,
      },
      defaults: {
        selected: dto.defaults.selected,
      },
      state: dto.state,
    };
  }
}

export class CreateDeliveryAddonDTO {
  category_id: string;
  name: string;
  type: DeliveryAddonType;
  external_id?: string;
  price: {
    value: string;
    vat_rate: number;
  };
  defaults: {
    selected: boolean;
  };
  state: 'ACTIVE' | 'INACTIVE';

  static createFromModel(model: CreateDeliveryAddonModel): CreateDeliveryAddonDTO {
    return {
      category_id: model.categoryId,
      name: model.name,
      type: model.type,
      external_id: model.externalId,
      price: {
        value: model.price.value,
        vat_rate: model.price.vatRate,
      },
      defaults: {
        selected: model.defaults.selected,
      },
      state: model.state,
    };
  }
}

export class UpdateDeliveryAddonModel {
  categoryId: string;
  addonId: string;
  name: string;
  type: DeliveryAddonType;
  externalId?: string;
  price: {
    value: string;
    vatRate: number;
  };
  defaults: {
    selected: boolean;
  };
  state: 'ACTIVE' | 'INACTIVE';

  static createFromDTO(dto: UpdateDeliveryAddonDTO): UpdateDeliveryAddonModel {
    return {
      categoryId: dto.category_id,
      addonId: dto.addon_id,
      name: dto.name,
      type: dto.type,
      externalId: dto.external_id,
      price: {
        value: dto.price.value,
        vatRate: dto.price.vat_rate,
      },
      defaults: {
        selected: dto.defaults.selected,
      },
      state: dto.state,
    };
  }
}

export class UpdateDeliveryAddonDTO {
  category_id: string;
  addon_id: string;
  name: string;
  type: DeliveryAddonType;
  external_id?: string;
  price: {
    value: string;
    vat_rate: number;
  };
  defaults: {
    selected: boolean;
  };
  state: 'ACTIVE' | 'INACTIVE';

  static createFromModel(model: UpdateDeliveryAddonModel): UpdateDeliveryAddonDTO {
    return {
      category_id: model.categoryId,
      addon_id: model.addonId,
      name: model.name,
      type: model.type,
      external_id: model.externalId,
      price: {
        value: model.price.value,
        vat_rate: model.price.vatRate,
      },
      defaults: {
        selected: model.defaults.selected,
      },
      state: model.state,
    };
  }
}

export class DeleteDeliveryAddonModel {
  categoryId: string;
  addonId: string;

  static createFromDTO(dto: DeleteDeliveryAddonDTO): DeleteDeliveryAddonModel {
    return {
      categoryId: dto.category_id,
      addonId: dto.addon_id,
    };
  }
}

export class DeleteDeliveryAddonDTO {
  category_id: string;
  addon_id: string;

  static createFromModel(model: DeleteDeliveryAddonModel): DeleteDeliveryAddonDTO {
    return {
      category_id: model.categoryId,
      addon_id: model.addonId,
    };
  }
}

export class AdjustDeliveryAddonsOrderModel {
  categoryId: string;
  addonIds: string[];

  static createFromDTO(dto: AdjustDeliveryAddonsOrderDTO): AdjustDeliveryAddonsOrderModel {
    return {
      categoryId: dto.category_id,
      addonIds: dto.addon_ids,
    };
  }
}

export class AdjustDeliveryAddonsOrderDTO {
  category_id: string;
  addon_ids: string[];

  static createFromModel(model: AdjustDeliveryAddonsOrderModel): AdjustDeliveryAddonsOrderDTO {
    return {
      category_id: model.categoryId,
      addon_ids: model.addonIds,
    };
  }
}

export class UpsertDeliveryAddonTranslationModel {
  categoryId: string;
  addonId: string;
  locale: string;
  name?: string;
  description?: string;

  static createFromDTO(
    dto: UpsertDeliveryAddonTranslationDTO
  ): UpsertDeliveryAddonTranslationModel {
    return {
      categoryId: dto.category_id,
      addonId: dto.addon_id,
      locale: dto.locale,
      name: dto.name,
      description: dto.description,
    };
  }
}

export class UpsertDeliveryAddonTranslationDTO {
  category_id: string;
  addon_id: string;
  locale: string;
  name?: string;
  description?: string;

  static createFromModel(
    model: UpsertDeliveryAddonTranslationModel
  ): UpsertDeliveryAddonTranslationDTO {
    return {
      category_id: model.categoryId,
      addon_id: model.addonId,
      locale: model.locale,
      name: model.name,
      description: model.description,
    };
  }
}

export class DeleteDeliveryAddonTranslationModel {
  categoryId: string;
  addonId: string;
  locale: string;

  static createFromDTO(
    dto: DeleteDeliveryAddonTranslationDTO
  ): DeleteDeliveryAddonTranslationModel {
    return {
      categoryId: dto.category_id,
      addonId: dto.addon_id,
      locale: dto.locale,
    };
  }
}

export class DeleteDeliveryAddonTranslationDTO {
  category_id: string;
  addon_id: string;
  locale: string;

  static createFromModel(
    model: DeleteDeliveryAddonTranslationModel
  ): DeleteDeliveryAddonTranslationDTO {
    return {
      category_id: model.categoryId,
      addon_id: model.addonId,
      locale: model.locale,
    };
  }
}

export class ExtendedShippingMethodDTO {
  id: string;
  name?: string;
  carrier?: string;
  delivery_types: DeliveryTypeEnum[];
  parcel_type: ParcelTypeEnum;
  shipping_config_status: ShippingMethodConfigurationStatus;
  auto_configurable?: boolean;
  booking_config_status: ShippingMethodBookingStatus;
  deprecated_on_listing: ShippingMethodConfigurationDeprecationStatus;
}

export class ExtendedShippingMethodModel {
  id: string;
  name?: string;
  carrier?: string;
  deliveryTypes: DeliveryTypeEnum[];
  parcelType: ParcelTypeEnum;
  shippingConfigStatus: ShippingMethodConfigurationStatus | ShippingMethodCreateResponseStatus;
  autoConfigurable?: boolean;
  bookingConfigStatus: ShippingMethodBookingStatus;
  deprecatedOnListing: ShippingMethodConfigurationDeprecationStatus;

  static createFromDTO(dto: ExtendedShippingMethodDTO): ExtendedShippingMethodModel {
    return {
      id: dto.id,
      name: dto.name,
      carrier: dto.carrier,
      deliveryTypes: dto.delivery_types,
      parcelType: dto.parcel_type,
      shippingConfigStatus: dto.shipping_config_status || 'METHOD_BOOKING_STATUS_UNKNOWN',
      autoConfigurable: dto.auto_configurable,
      bookingConfigStatus: dto.booking_config_status || 'METHOD_BOOKING_STATUS_UNKNOWN',
      deprecatedOnListing: dto.deprecated_on_listing || 'DEPRECATED_METHOD_VISIBILITY_UNKNOWN',
    };
  }
}

export type ShippingMethodConfigurationStatus =
  | 'METHOD_BOOKING_STATUS_UNKNOWN'
  | 'METHOD_SHIPPING_STATUS_NOT_PRESENT'
  | 'METHOD_SHIPPING_STATUS_PRESENT_NOT_CONFIGURED'
  | 'METHOD_SHIPPING_STATUS_CONFIGURED'
  | 'METHOD_SHIPPING_STATUS_NOT_POSSIBLE'
  | 'METHOD_SHIPPING_STATUS_UNAVAILABLE';

export type ShippingMethodCreateResponseStatus =
  | 'CREATE_REGION_PRODUCT_STATUS_CONFIGURED'
  | 'CREATE_REGION_PRODUCT_STATUS_UNABLE_TO_CONFIGURE'
  | 'CREATE_REGION_PRODUCT_STATUS_ALREADY_CONFIGURED';

export type ShippingMethodBookingStatus =
  | 'METHOD_BOOKING_STATUS_UNKNOWN'
  | 'METHOD_BOOKING_STATUS_NOT_PRESENT'
  | 'METHOD_BOOKING_STATUS_PRESENT_NOT_CONFIGURED'
  | 'METHOD_BOOKING_STATUS_CONFIGURED'
  | 'METHOD_BOOKING_STATUS_NOT_POSSIBLE';

export type ShippingMethodConfigurationDeprecationStatus =
  | 'DEPRECATED_METHOD_VISIBILITY_UNKNOWN'
  | 'DEPRECATED_METHOD_VISIBILITY_INVISIBLE'
  | 'DEPRECATED_METHOD_VISIBILITY_VISIBLE';

export class CreateMerchantModel {
  id: string;

  static createFromDTO(dto: CreateMerchantDTO): CreateMerchantModel {
    return {
      id: dto.id,
    };
  }
}

export class CreateMerchantDTO {
  id: string;
}

export class CreateSiteModel {
  id: string;

  static createFromDTO(dto: CreateSiteDTO): CreateSiteModel {
    return {
      id: dto.id,
    };
  }
}

export class CreateSiteDTO {
  id: string;
}

export class UpdateInternalEventModel {
  id: string;
  state: 'ACTIVE' | 'INACTIVE';

  static createFromDTO(dto: UpdateInternalEventDTO): UpdateInternalEventModel {
    return {
      id: dto.id,
      state: dto.state,
    };
  }
}

export class UpdateInternalEventDTO {
  id: string;
  state: 'ACTIVE' | 'INACTIVE';

  static createFromModel(model: UpdateInternalEventModel): UpdateInternalEventDTO {
    return {
      id: model.id,
      state: model.state,
    };
  }
}

export class UpsertCustomEventModel {
  name: string;
  id: string;
  state: 'ACTIVE' | 'INACTIVE';

  static createFromDTO(dto: UpsertCustomEventDTO): UpsertCustomEventModel {
    return {
      name: dto.name,
      id: dto.id,
      state: dto.state,
    };
  }
}

export class UpsertCustomEventDTO {
  name: string;
  id: string;
  state: 'ACTIVE' | 'INACTIVE';

  static createFromModel(model: UpsertCustomEventModel): UpsertCustomEventDTO {
    return { id: model.id, name: model.name, state: model.state };
  }
}

export class DeleteCustomEventTranslationModel {
  id: string;
  locale: string;

  static createFromDTO(dto: DeleteCustomEventTranslationDTO): DeleteCustomEventTranslationModel {
    return {
      id: dto.id,
      locale: dto.locale,
    };
  }
}

export class DeleteCustomEventTranslationDTO {
  id: string;
  locale: string;

  static createFromModel(
    model: DeleteCustomEventTranslationModel
  ): DeleteCustomEventTranslationDTO {
    return {
      id: model.id,
      locale: model.locale,
    };
  }
}

export class UpsertCustomEventTranslationModel {
  id: CustomEventModel['customEventTypeId'];
  locale: string;
  name: CustomEventLocalizationModel['name'];
  description: CustomEventLocalizationModel['description'];

  static createFromDTO(dto: UpsertCustomEventTranslationDTO): UpsertCustomEventTranslationModel {
    return {
      id: dto.id,
      locale: dto.locale,
      name: dto.name,
      description: dto.description,
    };
  }
}

export class UpsertCustomEventTranslationDTO {
  id: CustomEventDTO['custom_event_type_id'];
  locale: string;
  name: CustomEventLocalizationDTO['name'];
  description: CustomEventLocalizationDTO['description'];

  static createFromModel(
    model: UpsertCustomEventTranslationModel
  ): UpsertCustomEventTranslationDTO {
    return {
      id: model.id,
      locale: model.locale,
      name: model.name,
      description: model.description,
    };
  }
}

export type CarrierProductMapping = {
  id: string;
  custom: boolean;
  carrier_product_ref: string;
  carrier_product_id?: string;
  tracking_links?: { [locale: string]: string };
  always_show_tracking_links: boolean;
  carrier_product_name?: string;
  carrier_product_carrier?: string;
  tracking_config_status?: TrackingConfigurationStatus;
};

export type CreateCarrierProductMappingRequest = Pick<
  CarrierProductMapping,
  | 'custom'
  | 'carrier_product_ref'
  | 'carrier_product_id'
  | 'tracking_links'
  | 'always_show_tracking_links'
>;
export type UpdateCarrierProductMappingRequest = Pick<
  CarrierProductMapping,
  | 'id'
  | 'custom'
  | 'carrier_product_ref'
  | 'carrier_product_id'
  | 'tracking_links'
  | 'always_show_tracking_links'
>;

export type DeleteCarrierProductMappingRequest = Pick<CarrierProductMapping, 'id'>;

export type UpsertCarrierProductSettingsRequest = CarrierProductSettingsMapping;

export type ListCarrierProductMappingResponse = {
  carrier_product_mappings?: CarrierProductMapping[];
};

export type ListIntegratedCarrierProductMappingResponse = {
  integrated_carrier_product_mappings?: CarrierProductSettingsMapping[];
};

export type TrackingLinksForCarrierProduct = {
  tracking_links?: { [locale: string]: string };
};

export type GlobalCarrierProduct = {
  id: string;
  carrier: string;
  product: string;
};

export type TrackingConfigurationStatus =
  | 'TRACKING_STATUS_UNKNOWN'
  | 'TRACKING_STATUS_UNAVAILABLE'
  | 'TRACKING_STATUS_AVAILABLE'
  | 'TRACKING_STATUS_CONFIGURED';

export type GlobalCarrierProductList = {
  carrier_products: GlobalCarrierProduct[];
};

export type CarrierProductSettingsMappingResponse = {
  carrier_product_settings?: CarrierProductSettingsMapping[];
};

export type CarrierProductSettingsMapping = {
  carrier_product_id: string;
  always_show_tracking_links: boolean;
  custom: boolean;
  tracking_links?: { [locale: string]: string };
  carrier_product_name?: string;
  carrier_product_carrier?: string;
  tracking_config_status?: TrackingConfigurationStatus;
};

export type ListSuggestedCarrierProductMappingsResponse = {
  automapped_carrier_product_refs: SuggestedCarrierProductMapping[];
  unmapped_carrier_product_refs: SuggestedCarrierProductMapping[];
};

export type SuggestedCarrierProductMapping = {
  carrier_product_ref: string;
  carrier_product_id?: string;
  always_show_tracking_links?: boolean;
  carrier_product_name?: string;
  carrier_product_carrier?: string;
};

export type UpsertIntegratedProductMappingRequest = Pick<
  CarrierProductSettingsMapping,
  'carrier_product_id' | 'custom' | 'always_show_tracking_links' | 'tracking_links'
>;

export type TrackingConfigurationStatusResponse = {
  tracking_configuration_status: {
    status: TrackingConfigurationStatus;
  };
};

export type NotificationsChannelsSettings = {
  sms_properties?: {
    enabled: boolean;
    sender_name: string;
  };
  email_properties?: {
    enabled: boolean;
    sender_address: string;
    sender_name: string;
  };
};

export type NotificationChannels = {
  channel_type: string;
  enabled: boolean;
};

export type ProgressNotifications = {
  tracking_step: string;
  enabled: boolean;
  channel_settings: NotificationChannels[];
};

export type NotificationsSettingsResponse = {
  enabled: boolean;
  progress_notifications_settings: ProgressNotifications[];
  channels_properties: NotificationsChannelsSettings;
};

export type SmartNotificationsSettingsResponse = {
  enabled: boolean;
  pickup_reminder: SmartNotificationData;
};

export type SmartNotificationData = {
  display_name: string;
  channel_settings: NotificationChannels[];
  enabled?: boolean;
  threshold?: number;
  key_name?: string;
};
