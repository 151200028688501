import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Checkbox, Radio, Tooltip } from 'antd';
import * as React from 'react';
import { classes, stylesheet } from 'typestyle';

import { Table, Input, InputNumber, InputProps } from '@src/controls';
import { WidgetFeaturesModel } from '@src/models/config-models';
import { defaultTheme } from '@src/styles';
import { InputNumberProps } from 'antd/lib/input-number';
import { isNumber } from 'lodash';

type FeatureKey = keyof WidgetFeaturesModel;

type TableConfigItem =
  | { rowType: 'GROUP_HEADER'; groupName: string }
  | {
      rowType: 'FEATURE_ITEM_CHECKBOX';
      featureName: string;
      featureKey: FeatureKey;
      featureDescription: string;
      disabledIf?: (features: WidgetFeaturesModel) => boolean;
    }
  | {
      rowType: 'FEATURE_ITEM_RADIO';
      radioValue: number;
      featureName: string;
      featureKey: FeatureKey;
      featureDescription: string;
      disabledIf?: (features: WidgetFeaturesModel) => boolean;
    }
  | {
      rowType: 'FEATURE_ITEM_INPUT';
      inputType: HTMLInputElement['type'];
      featureName: string;
      featureKey: FeatureKey;
      featureDescription: string;
      disabledIf?: (features: WidgetFeaturesModel) => boolean;
    };

type TableItem =
  | { rowType: 'GROUP_HEADER'; groupName: string }
  | {
      rowType: 'FEATURE_ITEM_CHECKBOX';
      featureName: string;
      featureKey: FeatureKey;
      featureDescription: string;
      featureValue: boolean;
      referenceFeatureValue?: boolean;
      disabledIf?: (features: WidgetFeaturesModel) => boolean;
    }
  | {
      rowType: 'FEATURE_ITEM_RADIO';
      radioValue: number;
      featureName: string;
      featureKey: FeatureKey;
      featureDescription: string;
      featureValue: boolean;
      referenceFeatureValue?: boolean;
      disabledIf?: (features: WidgetFeaturesModel) => boolean;
    }
  | {
      rowType: 'FEATURE_ITEM_INPUT';
      inputType: HTMLInputElement['type'];
      featureName: string;
      featureKey: FeatureKey;
      featureDescription: string;
      featureValue: number | string;
      referenceFeatureValue?: number | string;
      disabledIf?: (features: WidgetFeaturesModel) => boolean;
    };

const TABLE_CONFIG: TableConfigItem[] = [
  { rowType: 'GROUP_HEADER', groupName: 'Checkout Configuration' },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Show delivery options without requiring address',
    featureKey: 'showShippingCategoriesBeforeAddress',
    featureDescription:
      'Displays applicable delivery options without user entering a delivery address.',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Always show address field',
    featureKey: 'displayUpfrontAddress',
    featureDescription: 'Makes the address field always available to user.',
  },
  // folding feature flag is a number instead of bool, if 0 disabled, if 1+ controlls amount of categories visible when folded
  // therefore this checkbox is cosmetic, if enabled it sets shippingCategoriesVisibleWhenFolded to 1 if disabled sets it to 0
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Fold shipping categories',
    featureKey: 'shippingCategoriesVisibleWhenFoldedCount',
    featureDescription:
      'Hides shipping categories other than preselected one, adds "show more" button to expand widget and make all categories visible.',
  },
  {
    rowType: 'FEATURE_ITEM_INPUT',
    inputType: 'number',
    featureName: 'Categories visible when folded',
    disabledIf: features => !features.shippingCategoriesVisibleWhenFoldedCount,
    featureKey: 'shippingCategoriesVisibleWhenFoldedCount',
    featureDescription: 'Controls how many categories vill be shown when widget is folded.',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Shorter delivery times',
    featureKey: 'enableShortDeliveryTimes',
    featureDescription:
      'Enables shorter delivery time descriptions. Eg. ‘Delivered between Thursday (Mar 25th) and Friday (Mar 26th)’ will be shown as ‘Wed, Mar 25 - Fri, Mar 26’',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Show delivery times as estimated',
    featureKey: 'showDeliveryTimesAsEstimates',
    featureDescription:
      'Adds information that the delivery time is an estimation, for example ‘4 business days’ will be shown as ‘4 business days (estimated)’',
  },
  { rowType: 'GROUP_HEADER', groupName: 'Address Field' },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Show street in address',
    featureKey: 'showStreetOnAddressModule',
    featureDescription: 'Adds “Street” as input field to the Address module.',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Require street in address',
    featureKey: 'requireStreetOnAddressModule',
    featureDescription: 'Makes the input of street mandatory.',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Show region in address',
    featureKey: 'showRegion',
    featureDescription: 'Adds “Region” as input field to the Address module.',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Require region in address',
    featureKey: 'requireRegion',
    featureDescription: 'Makes the input of region mandatory.',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Show city in address',
    featureKey: 'showCityOnAddressModule',
    featureDescription: 'Adds “City” as input field to the Address module.',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Activate address proposal',
    featureKey: 'autocompleteStreet',
    featureDescription:
      'Helps user to enter all address fields auto completing the full address using proposals from mapping services. (This feature requires "Show street in address module" and "Street required" options to be turned on).',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Disable address fields',
    featureKey: 'disableAddressForm',
    featureDescription:
      'Removes the possibility to enter and change the address inside of the Delivery Checkout. Should only be used if merchant prepopulates Ingrid with address/zip code.',
  },
  { rowType: 'GROUP_HEADER', groupName: 'Pickup Points Display' },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Show pickup points on a map',
    featureKey: 'showMap',
    featureDescription:
      'Visualises pickup points on a map for a better user experience and easier pickup selection.',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Show price per pickup point',
    featureKey: 'showShippingPriceOnServicePointsView',
    featureDescription:
      'Shows the shipping price in the pickup point selector next to each available option. Useful if prices differ between pickup point options, e.g. when two carrier products are in the same category.',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Show type for pickup locations (Locker, Store, Pick Up Point)',
    featureKey: 'displayLocationType',
    featureDescription: 'Adds informative tag for each type of a pickup point.',
  },
  { rowType: 'GROUP_HEADER', groupName: 'FREE SHIPPING INDICATOR' },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Show free delivery indicator',
    featureKey: 'enableFreeShippingIndicator',
    featureDescription:
      'Enables a free delivery indicator in Delivery Checkout that shows customers the next step to get a free delivery',
  },
  {
    rowType: 'FEATURE_ITEM_RADIO',
    radioValue: 1,
    disabledIf: features => !features.enableFreeShippingIndicator,
    featureName: 'Free delivery indication based on Delivery type and Carrier name',
    featureKey: 'enableFreeShippingIndicatorCategoryNameMessage',
    featureDescription:
      'Messaging is based on both Delivery Type and Carrier name for free delivery indication',
  },
  {
    rowType: 'FEATURE_ITEM_RADIO',
    radioValue: 2,
    disabledIf: features => !features.enableFreeShippingIndicator,
    featureName: 'Free delivery indication based on Category name',
    featureKey: 'enableFreeShippingIndicatorCategoryNameMessage',
    featureDescription: 'Messaging is only based on Category name for free delivery indication',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    disabledIf: features => !features.enableFreeShippingIndicator,
    featureName: 'Show Carrier logo in free delivery indicator',
    featureKey: 'enableFreeShippingIndicatorCarrierLogo',
    featureDescription: '',
  },
  { rowType: 'GROUP_HEADER', groupName: 'Price and currency' },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Display zero price as number',
    featureKey: 'enablePriceZeroValue',
    featureDescription: 'Instead of "Free" label',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Display currency as ISO code (9.99 EUR)',
    featureKey: 'displayCurrencyIsoCode',
    featureDescription: 'Instead of Currency Symbol ( 9.99 €)',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Display base price',
    featureKey: 'showBasePrice',
    featureDescription: 'The base price will be struck through and displayed in grey',
  },
  { rowType: 'GROUP_HEADER', groupName: 'Color and Display' },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Enable border around checkout module',
    featureKey: 'enableWidgetBorder',
    featureDescription: 'Activates a border around Ingrid Delivery Checkout.',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Make checkout module transparent',
    featureKey: 'enableTransparentBackground',
    featureDescription:
      'Makes the Delivery Checkout background transparent, inheriting its color from the the checkout page’s background.',
  },
  {
    rowType: 'FEATURE_ITEM_CHECKBOX',
    featureName: 'Use accent color for widget elements',
    featureKey: 'enableAccentColorForWidgetElements',
    featureDescription: 'Color applies to buttons, pins on map, radio buttons',
  },
];

type OwnProps = {
  isLoading: boolean;
  features: WidgetFeaturesModel;
  featuresHeaderName: string;
  onFeatureChange: (featues: WidgetFeaturesModel) => void;
  referenceFeatures?: WidgetFeaturesModel;
  referenceFeaturesHeaderName?: string;
};

const getDataSource = (features: WidgetFeaturesModel, referenceFeatures?: WidgetFeaturesModel) =>
  TABLE_CONFIG.map(rowConfig => {
    if (rowConfig.rowType === 'GROUP_HEADER') {
      return rowConfig;
    } else {
      return {
        ...rowConfig,
        featureValue: features[rowConfig.featureKey] ?? getDefaultFallbackValueForRow(rowConfig),
        referenceFeatureValue: referenceFeatures
          ? referenceFeatures[rowConfig.featureKey] ?? getDefaultFallbackValueForRow(rowConfig)
          : undefined,
      };
    }
  });

const getDefaultFallbackValueForRow = (rowConfig: TableConfigItem): any => {
  if (rowConfig.rowType === 'FEATURE_ITEM_INPUT') {
    switch (rowConfig.inputType) {
      case 'text':
        return '';
      case 'number':
        return 0;
      default:
        return undefined;
    }
  }
  return false;
};

export const FeaturesTable: React.FunctionComponent<OwnProps> = ({
  isLoading,
  features,
  featuresHeaderName,
  onFeatureChange,
  referenceFeatures,
  referenceFeaturesHeaderName,
}) => {
  const showReferenceColumn = referenceFeatures && referenceFeaturesHeaderName;
  return (
    <Table<TableItem>
      className={classes(
        styles.table,
        showReferenceColumn ? styles.broadTable : styles.narrowTable
      )}
      components={{ body: { row: RowWithTooltip } }}
      onRow={(record, _) => ({
        tooltipText: record.rowType === 'GROUP_HEADER' ? undefined : record.featureDescription,
      })}
      loading={isLoading}
      dataSource={getDataSource(features, referenceFeatures)}
      pagination={false}
      rowKey={(record, idx) =>
        record.rowType === 'GROUP_HEADER'
          ? `${record.groupName}-${idx}`
          : `${record.featureKey}-${idx}`
      }
      hideTitle={true}
    >
      <Table.Column<TableItem>
        title="Feature name"
        key="featureName"
        render={(_, record) => {
          if (record.rowType === 'GROUP_HEADER') {
            return {
              children: <span className={styles.groupHeaderText}>{record.groupName}</span>,
              props: {
                colSpan: showReferenceColumn ? 3 : 2,
                className: 'group-header',
              },
            };
          }
          return record.featureName;
        }}
        width={'348px'}
      />
      {showReferenceColumn && (
        <Table.Column<TableItem>
          className={styles.centered}
          title={referenceFeaturesHeaderName}
          key="referenceFeatureValue"
          render={(_, record) => {
            if (record.rowType === 'GROUP_HEADER') {
              return {
                children: '',
                props: {
                  colSpan: 0,
                },
              };
            }
            if (record.rowType === 'FEATURE_ITEM_RADIO') {
              if (record.radioValue === 2) {
                return record.referenceFeatureValue ? (
                  <CheckOutlined className={styles.iconAccent} />
                ) : (
                  <CloseOutlined className={styles.iconGrey} />
                );
              } else {
                return record.referenceFeatureValue ? (
                  <CloseOutlined className={styles.iconGrey} />
                ) : (
                  <CheckOutlined className={styles.iconAccent} />
                );
              }
            }
            if (record.rowType === 'FEATURE_ITEM_INPUT') {
              return (
                <FeatureInput
                  className={styles.input}
                  disabled={true}
                  type={record.inputType}
                  onChange={(_: any) => ''}
                  value={record.referenceFeatureValue}
                ></FeatureInput>
              );
            }
            return record.referenceFeatureValue ? (
              <CheckOutlined className={styles.iconAccent} />
            ) : (
              <CloseOutlined className={styles.iconGrey} />
            );
          }}
          width={'96px'}
        />
      )}
      <Table.Column<TableItem>
        className={styles.centered}
        title={featuresHeaderName}
        key="featureValue"
        render={(_, record) => {
          if (record.rowType === 'GROUP_HEADER') {
            return {
              children: '',
              props: {
                colSpan: 0,
              },
            };
          }
          if (record.rowType === 'FEATURE_ITEM_RADIO') {
            const disabled = record.disabledIf ? record.disabledIf(features) : false;
            return (
              <Radio.Group
                className={styles.radioGroup}
                disabled={disabled}
                value={record.featureValue ? 2 : 1}
                onChange={() => {
                  onFeatureChange({
                    [record.featureKey]: !record.featureValue,
                  });
                }}
              >
                <Radio className={styles.radioButton} value={record.radioValue} />
              </Radio.Group>
            );
          }
          if (record.rowType === 'FEATURE_ITEM_INPUT') {
            return (
              <FeatureInput
                className={styles.input}
                disabled={record.disabledIf ? record.disabledIf(features) : false}
                type={record.inputType}
                onChange={(e: React.ChangeEvent<HTMLInputElement> | number) =>
                  onFeatureChange({ [record.featureKey]: isNumber(e) ? e : e.target.value })
                }
                value={record.featureValue}
                {...(record.featureKey === 'shippingCategoriesVisibleWhenFoldedCount'
                  ? { min: 0 }
                  : {})}
              />
            );
          }
          return (
            <Checkbox
              disabled={record.disabledIf ? record.disabledIf(features) : false}
              checked={
                record.featureKey === 'shippingCategoriesVisibleWhenFoldedCount' &&
                !features.shippingCategoriesVisibleWhenFoldedCount
                  ? false
                  : !!record.featureValue
              }
              onChange={e => {
                if (record.featureKey === 'shippingCategoriesVisibleWhenFoldedCount') {
                  onFeatureChange({ [record.featureKey]: e.target.checked ? 1 : 0 });
                } else {
                  onFeatureChange({ [record.featureKey]: e.target.checked });
                }
              }}
            />
          );
        }}
        width={'96px'}
      />
    </Table>
  );
};

const FeatureInput = (props: InputProps | InputNumberProps) => {
  return props.type && props.type === 'number' ? (
    <InputNumber {...(props as InputNumberProps)} />
  ) : (
    <Input {...(props as InputProps)} />
  );
};

const styles = stylesheet({
  narrowTable: {
    width: '564px',
  },
  broadTable: {
    width: '660px',
  },
  table: {
    $nest: {
      '.ant-table-tbody tr td': {
        borderBottom: '1px solid rgba(0,0,0,0.09)',
      },
      '.ant-table-tbody tr:hover td.group-header': {
        background: 'unset',
      },
      '.ant-table-thead tr th': {
        background: 'rgba(0,0,0,0.02)',
        borderBottom: '1px solid rgba(0,0,0,0.09)',
      },
    },
  },
  groupHeaderText: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  centered: {
    $nest: {
      '.ant-table-header-column': {
        width: '100%',
      },
      '& .ant-checkbox-wrapper, & .anticon': {
        display: 'flex',
        justifyContent: 'center',
      },
      '&.ant-table-cell': {
        textAlign: 'center',
      },
    },
  },
  iconAccent: {
    color: defaultTheme.color.primary,
  },
  iconGrey: {
    color: '#C0C0C0',
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'center',
  },
  radioButton: {
    marginRight: 0,
  },
  input: {
    width: '50px',
  },
});

const RowWithTooltip: React.FunctionComponent<{ tooltipText: string | undefined }> = ({
  tooltipText,
  children,
}) => (
  <Tooltip title={tooltipText}>
    <tr>{children}</tr>
  </Tooltip>
);
