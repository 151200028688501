import { useFormatMessage } from '@src/i18n';
import { ShipmentSummaryModel } from '@src/models';
import { Button } from 'antd';
import React, { FC } from 'react';
import { useBulkBookingMutation } from './use-bulk-actions';
import { WithTooltip } from './with-tooltip';

type Props = {
  selectedShipments: ShipmentSummaryModel[];
};

export const BulkBooking: FC<Props> = ({ selectedShipments }) => {
  const formatMessage = useFormatMessage();

  const disableBulkBookingCond =
    selectedShipments.some(({ status }) => status !== 'CREATED') || !selectedShipments.length;

  const { mutate, isLoading } = useBulkBookingMutation();

  const onBulkBookingHandler = () => mutate(selectedShipments.map(({ id }) => id));

  return (
    <WithTooltip
      isDisabled={disableBulkBookingCond}
      tooltipText={formatMessage('DISABLED_BULK_BOOKING_TOOLTIP')}
    >
      <Button disabled={disableBulkBookingCond} onClick={onBulkBookingHandler} loading={isLoading}>
        {formatMessage('BULK_BOOKING_SHIPMENTS')}
      </Button>
    </WithTooltip>
  );
};
