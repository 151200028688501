import { Tabs as AntTabs } from 'antd';
import { TabPaneProps as AntTabPaneProps, TabsProps } from 'antd/lib/tabs';
import * as React from 'react';

export class Tabs extends React.Component<TabsProps> {
  render() {
    const { children, ...restProps } = this.props;
    return <AntTabs {...restProps}>{children}</AntTabs>;
  }
}

export class TabPane extends React.Component<AntTabPaneProps> {
  render() {
    const { children, ...restProps } = this.props;
    return <AntTabs.TabPane {...restProps}>{children}</AntTabs.TabPane>;
  }
}
