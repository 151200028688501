interface CreatedDTO {
  triggered_at: string;
  triggered_by: string;
}

export class CreateExperimentDTO {
  site_id: string;
  name: string;
  traffic: number;
  country: string;
  type: 'INTERNAL' | 'EXTERNAL';
  external_traffic_balancing_enabled: boolean;

  static createFromModel(model: CreateExperimentModel) {
    return {
      site_id: model.siteId,
      name: model.name,
      traffic: model.traffic,
      country: model.country,
      type: model.type,
      external_traffic_balancing_enabled: model.externalTrafficBalancingEnabled,
    };
  }
}

type ExperimentType = 'INTERNAL' | 'EXTERNAL';

export class CreateExperimentModel {
  siteId: string;
  name: string;
  traffic: number;
  country: string;
  type: ExperimentType;
  externalTrafficBalancingEnabled: boolean;
}

export class CreateVariantDTO {
  site_id: string;
  name: string;
  description: string;

  static createFromModel(model: CreateVariantModel) {
    return {
      site_id: model.siteId,
      name: model.name,
      description: model.description,
    };
  }
}

export class CreateVariantModel {
  siteId: string;
  name: string;
  description: string;
}

export class ExperimentDTO {
  id: string;
  site_id: string;
  name: string;
  description: string;
  status: string;
  created: CreatedDTO;
  started?: CreatedDTO;
  ended?: CreatedDTO;
  country: string;
  traffic: number;
  type: ExperimentType;
  external_traffic_balancing_enabled?: boolean;
}

export class ExperimentModel {
  id: string;
  siteId: string;
  name: string;
  description: string;
  status: string;
  createdAt: string;
  createdBy: string;
  startedAt?: string;
  startedBy?: string;
  endedAt?: string;
  endedBy?: string;
  country: string;
  traffic: number;
  type: ExperimentType;
  externalTrafficBalancingEnabled?: boolean;

  static createFromDTO(dto: ExperimentDTO) {
    return {
      id: dto.id,
      siteId: dto.site_id,
      name: dto.name,
      description: dto.description,
      status: dto.status,
      createdAt: dto.created.triggered_at,
      createdBy: dto.created.triggered_by,
      startedAt: dto.started?.triggered_at,
      startedBy: dto.started?.triggered_by,
      endedAt: dto.ended?.triggered_at,
      endedBy: dto.ended?.triggered_by,
      country: dto.country,
      traffic: dto.traffic,
      type: dto.type,
      externalTrafficBalancingEnabled: dto.external_traffic_balancing_enabled,
    };
  }
}

export class ExperimentWithVariantDTO extends ExperimentDTO {
  variants: VariantDTO[];
}

export class ExperimentWithVariantModel extends ExperimentModel {
  variants: VariantModel[];
  result?: ExperimentResultModel;

  static createFromDTO(dto: ExperimentWithVariantDTO & { result: ExperimentResultDTO }) {
    return {
      ...ExperimentModel.createFromDTO(dto),
      variants: dto.variants.map(VariantModel.createFromDTO),
      result: dto.result ? ExperimentResultModel.createFromDTO(dto.result) : undefined,
    };
  }
}

export class ExperimentResultDTO {
  original_result: ExperimentVariantResultDTO;
  variant_results: ExperimentVariantResultDTO[];
  received_at: string;
}

export class ExperimentResultModel {
  originalResult: ExperimentVariantResultDTO;
  variantResults: ExperimentVariantResultDTO[];
  receivedAt: string;

  static createFromDTO(dto: ExperimentResultDTO) {
    return {
      originalResult: dto.original_result,
      variantResults: dto.variant_results,
      receivedAt: dto.received_at,
    };
  }
}

export class ExperimentVariantResultDTO {
  result_summary: any;
  site_id: string;
}

export class UpdateExperimentDTO {
  experiment_id: string;
  site_id: string;
  name: string;
  description: string;
  traffic: number;
  country: string;
  type: ExperimentType;
  external_traffic_balancing_enabled?: boolean;

  static createFromModel(model: UpdateExperimentModel) {
    return {
      experiment_id: model.id,
      site_id: model.siteId,
      name: model.name,
      description: model.description,
      traffic: model.traffic,
      country: model.country,
      type: model.type,
      external_traffic_balancing_enabled: model.externalTrafficBalancingEnabled,
    };
  }
}

export class UpdateExperimentModel {
  id: string;
  siteId: string;
  name: string;
  description: string;
  traffic: number;
  country: string;
  type: ExperimentType;
  externalTrafficBalancingEnabled?: boolean;
}

export class UpdateVariantDTO {
  variant_id: string;
  experiment_id: string;
  site_id: string;
  name: string;
  description: string;
  external_traffic_balancing_id?: string;

  static createFromModel(model: UpdateVariantModel) {
    return {
      variant_id: model.id,
      experiment_id: model.experimentId,
      site_id: model.siteId,
      name: model.name,
      description: model.description,
      external_traffic_balancing_id: model.externalTrafficBalancingId,
    };
  }
}

export class UpdateVariantModel {
  id: string;
  experimentId: string;
  siteId: string;
  name: string;
  description: string;
  externalTrafficBalancingId?: string;
}

class VariantDiffDTO {
  text: string;
  no_of_changes: number;
}

export class VariantDTO {
  id: string;
  name: string;
  description: string;
  variant_site_id: string;
  weight: number;
  created_at: string;
  updated_at: string;
  diff: VariantDiffDTO;
  external_traffic_balancing_id?: string;
}

export class VariantModel {
  id: string;
  name: string;
  description: string;
  variantSiteId: string;
  weight: number;
  createdAt: string;
  updatedAt: string;
  diff: VariantDiffDTO;
  externalTrafficBalancingId?: string;

  static createFromDTO(dto: VariantDTO) {
    return {
      id: dto.id,
      name: dto.name,
      description: dto.description,
      variantSiteId: dto.variant_site_id,
      weight: dto.weight,
      createdAt: dto.created_at,
      updatedAt: dto.updated_at,
      diff: dto.diff,
      externalTrafficBalancingId: dto.external_traffic_balancing_id,
    };
  }
}
