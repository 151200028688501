import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;

import { TokenAuthModel } from '../../models';

const GET_AUTH_TOKEN_REQUEST = '@tokenAuth/GET_TOKEN_REQUEST';
const GET_AUTH_TOKEN_SUCCESS = '@tokenAuth/GET_TOKEN_SUCCESS';
const GET_AUTH_TOKEN_ERROR = '@tokenAuth/GET_TOKEN_ERROR';

export const getAuthTokenRequest = createAction(
  GET_AUTH_TOKEN_REQUEST,
  resolve => (payload: { siteId: string; resource: string; sitePrivateKey: string }) =>
    resolve(payload)
);
export const getAuthTokenSuccess = createAction(
  GET_AUTH_TOKEN_SUCCESS,
  resolve => (token: TokenAuthModel) => resolve(token)
);
export const getAuthTokenError = createAction(
  GET_AUTH_TOKEN_ERROR,
  resolve => (error: string) => resolve(error)
);
