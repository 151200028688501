import { Field, FieldProps, Form, FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { style as tss } from 'typestyle';
import { number, object } from 'yup';

import { routerActions } from '@src/modules/router';
import { getFormikError } from '@src/utils/forms';
import { useDispatch } from '@src/utils/hooks';
import { Alert, Button, InputNumber, Modal, Progress } from '../../../controls';
import { withFormItem } from '../../../decorators';
import { ERRORS, LABELS } from '../../../dictionaries';
import { RequestState } from '../../../modules/reports/reducers';
import defaultTheme from '../../../styles/theme.default';

const InputNumberField = withFormItem(InputNumber);

const styles = {
  progress: tss({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: defaultTheme.lineHeight,
  }),
  button: tss({
    marginRight: 8,
  }),
};

interface OwnProps {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (numberOfShipments: number) => void;
  requestsStatus: RequestState;
  progress: number;
  labelsFileNames: string[] | null;
  mergeLabels: (fileNames: string[]) => void;
  mergedLabel: string | null;
  isFetchingMergedLabel: boolean;
  resetMergedLabels: () => void;
}

interface FormValues {
  numberOfShipments: number;
}

type Props = OwnProps & FormikProps<FormValues>;

const InnerForm: React.FunctionComponent<Props> = ({
  isFetchingMergedLabel,
  mergedLabel,
  mergeLabels,
  labelsFileNames,
  requestsStatus,
  onCancel,
  handleSubmit,
  resetMergedLabels,
  visible,
  touched,
  setFieldValue,
  progress,
  values,
  errors,
}) => {
  const dispatch = useDispatch();

  const renderExtra = () => {
    if (!labelsFileNames) {
      return;
    }

    return mergedLabel ? (
      <Button href={mergedLabel} target="_blank" className={styles.button}>
        {LABELS.DOWNLOAD_CONSOLIDATED_LABEL}
      </Button>
    ) : (
      <Button onClick={() => mergeLabels(labelsFileNames)} loading={isFetchingMergedLabel}>
        {LABELS.PREPARE_CONSOLIDATED_LABEL}
      </Button>
    );
  };

  const renderFooter = () => {
    switch (requestsStatus) {
      case RequestState.pending:
        return (
          <>
            <Button type="primary" onClick={(e: any) => handleSubmit(e)}>
              {LABELS.PROCEED}
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </>
        );
      case RequestState.success:
        return (
          <>
            {renderExtra()}
            <Button
              type="primary"
              onClick={() => {
                resetMergedLabels();
                dispatch(routerActions.push({ name: 'SHIPMENT_LIST' }));
              }}
            >
              {LABELS.CONFIRMATION_TEXT}
            </Button>
          </>
        );
      case RequestState.exception:
        return (
          <Button
            type="primary"
            onClick={() => dispatch(routerActions.push({ name: 'SHIPMENT_LIST' }))}
          >
            {LABELS.CONFIRMATION_TEXT}
          </Button>
        );
      default:
        return null;
    }
  };

  const renderAlert = () => {
    switch (requestsStatus) {
      case RequestState.success:
        return (
          <Alert
            type="success"
            message="Success"
            description={ERRORS.CREATE_AND_BOOK_MULTIPLE_SHIPMENTS_SUCCESS}
            showIcon={true}
          />
        );
      case RequestState.exception:
        return (
          <Alert
            type="error"
            message="Error"
            description={ERRORS.CREATE_AND_BOOK_MULTIPLE_SHIPMENTS_ERROR}
            showIcon={true}
          />
        );
      case RequestState.active:
        return (
          <Alert
            type="info"
            message="In progress"
            description={ERRORS.CREATE_AND_BOOK_MULTIPLE_SHIPMENTS_PROGRESS}
            showIcon={true}
          />
        );
      default:
        return requestsStatus;
    }
  };

  const setStatus = () => {
    switch (requestsStatus) {
      case RequestState.pending:
        return 'active';
      default:
        return requestsStatus;
    }
  };

  const isPending = requestsStatus === RequestState.pending;

  return (
    <Modal
      title={LABELS.CREATE_AND_BOOK_MULTIPLE_HEADER}
      visible={visible}
      footer={renderFooter()}
      maskClosable={isPending}
      closable={isPending}
      onCancel={onCancel}
    >
      {isPending ? (
        <Form>
          <Field name="numberOfShipments">
            {({ field }: FieldProps<FormValues['numberOfShipments']>) => (
              <InputNumberField
                {...field}
                min={1}
                max={100}
                label="Number of shipments"
                value={field.value}
                required={true}
                error={getFormikError(touched.numberOfShipments, errors.numberOfShipments)}
                onChange={value => setFieldValue('numberOfShipments', value)}
              />
            )}
          </Field>
        </Form>
      ) : (
        <>
          <Progress
            type="circle"
            format={() => `${progress}/${values.numberOfShipments}`}
            percent={Math.floor((progress / values.numberOfShipments) * 100)}
            status={setStatus()}
            className={styles.progress}
          />
          {renderAlert()}
        </>
      )}
    </Modal>
  );
};

export const MultipleShipmentsCreateAndBookModal = withFormik<OwnProps, FormValues>({
  mapPropsToValues: () => ({ numberOfShipments: 1 }),
  handleSubmit: (values, { props }) => props.onSubmit(values.numberOfShipments),
  validationSchema: () =>
    object({
      numberOfShipments: number()
        .moreThan(0, 'Number of shipments has to be greater than 0')
        .lessThan(101, 'Number of shipments has to be less than 100')
        .required('Number of shipments is required'),
    }),
  displayName: 'MultipleShipmentsCreateAndBookForm',
})(InnerForm);
