import { List } from 'antd';
import * as React from 'react';
import { classes, stylesheet } from 'typestyle';

import { LimitedNumberOfItems } from '@src/components';
import { Tag, Tooltip } from '@src/controls';
import { LABELS } from '@src/dictionaries';
import { FormattedMessage } from '@src/i18n';
import { warehouseSelectors } from '../selectors';
import { ConfigWarehouseModel } from '@src/models';

interface Props {
  isFetching: boolean;
  warehouses: ReturnType<typeof warehouseSelectors['getWarehousesByDraftOrCurrentSite']>;
  warehousesRegionsMap: ReturnType<typeof warehouseSelectors['getWarehousesRegionsMap']>;
  renderLink: (warehouse: ConfigWarehouseModel) => React.ReactNode;
  onDeleteItem: (warehouseId: string) => void;
}

interface ListItem {
  content: JSX.Element;
}

export const WarehousesList: React.FunctionComponent<Props> = ({
  warehouses,
  isFetching,
  renderLink,
  onDeleteItem,
  warehousesRegionsMap,
}) => {
  const data: ListItem[] = warehouses.map(warehouse => {
    const linkedRegions = warehousesRegionsMap[warehouse.id];
    return {
      content: (
        <div className={styles.listItem}>
          {renderLink(warehouse)}
          <div className={styles.subRegions}>
            {linkedRegions.length > 0 && (
              <>
                <Tag
                  color={colors.lightGrey}
                  textColor={colors.darkGrey}
                  borderColor={colors.darkGrey}
                  nonClickable={true}
                >
                  <FormattedMessage id="LINKED_REGIONS" />
                </Tag>
                <div className={styles.linkedRegions}>
                  <LimitedNumberOfItems list={linkedRegions} />
                </div>
              </>
            )}
          </div>
          {linkedRegions.length ? (
            <Tooltip placement="topLeft" title={<FormattedMessage id="CANNOT_DELETE_WAREHOUSE" />}>
              <span className={classes(styles.delete, styles.inactive)}>{LABELS.DELETE}</span>
            </Tooltip>
          ) : (
            <a onClick={() => onDeleteItem(warehouse.id)} className={styles.delete}>
              {LABELS.DELETE}
            </a>
          )}
        </div>
      ),
    };
  });
  return (
    <List
      dataSource={data}
      loading={isFetching}
      locale={{ emptyText: <FormattedMessage id="WAREHOUSES_EMPTY_LIST" /> }}
      renderItem={(item: ListItem) => (
        <List.Item>
          <List.Item.Meta description={item.content} />
        </List.Item>
      )}
    />
  );
};

const styles = stylesheet({
  listItem: {
    display: 'grid',
    gridTemplateColumns: '300px 1fr 100px',
    gridGap: '20px',
    gridAutoFlow: 'column',
    placeItems: 'center self-start',
  },
  subRegions: {
    marginRight: 'auto',
    paddingLeft: '20px',
  },
  delete: {
    width: '50px',
    marginRight: '20px',
    textAlign: 'left',
  },
  inactive: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
  linkedRegions: {
    marginTop: '2px',
    display: 'flex',
    flexWrap: 'wrap',
  },
});

const colors = {
  lightGrey: 'rgba(0, 0, 0, 0.01)',
  darkGrey: 'rgba(0, 0, 0, 0.6)',
};
