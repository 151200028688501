import { Tag } from '@src/controls';
import { TrackingStatus } from '@src/models';
import { getColorForStatus } from '@src/utils';
import { humanize } from '@src/utils/string';
import React, { FC } from 'react';

type Props = {
  status: TrackingStatus;
};

export const StatusTag: FC<Props> = ({ status }) => {
  const color = getColorForStatus(status);
  return (
    <Tag
      textColor="white"
      borderColor={color}
      style={{ backgroundColor: color, width: 'min-content' }}
    >
      {humanize(status)}
    </Tag>
  );
};
