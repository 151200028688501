import { useFormatMessage } from '@src/i18n';
import { GlobalOutlined } from '@ant-design/icons';
import { ContainerContent, ListFixedHeader } from '@src/components';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { FormattedMessage } from '@src/i18n';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { AutogeneratedCarrierProductMappings } from './autogenerated-mappings';
import { CarrierProductMappings } from './carrier-product-mappings';
import { IntegratedCarrierProductsTable } from './integrated-carrier-products';

export const ProductMappingsContainer: React.FunctionComponent = () => {
  const formatMessage = useFormatMessage();

  return (
    <ContainerContent className={styles.container}>
      <StatusBarContainer />
      <ListFixedHeader
        title={<FormattedMessage id="PRODUCT_MAPPINGS" />}
        IconComponent={GlobalOutlined}
        showSearch={false}
      />
      <h3 className={styles.sectionHeader} data-cy="product-mappings-external-header">
        {formatMessage('EXTERNALLY_BOOKED_PRODUCTS')}
      </h3>
      <AutogeneratedCarrierProductMappings />
      <CarrierProductMappings />
      <h3 className={styles.sectionHeader} data-cy="product-mappings-ingrid-header">
        {formatMessage('INGRID_BOOKED_PRODUCTS')}
      </h3>
      <IntegratedCarrierProductsTable />
    </ContainerContent>
  );
};

const styles = stylesheet({
  container: {
    flex: 1,
  },
  sectionHeader: {
    borderBottom: '1px solid rgba(0,0,0,0.09)',
    paddingBottom: '16px',
    marginBottom: '16px',
  },
});
