import * as React from 'react';
import { stylesheet } from 'typestyle';

import { ModalPopover } from '@src/controls';
import { commons } from '@src/styles';
import { LABELS } from '@src/dictionaries';
import { FormattedMessage } from '@src/i18n';

interface OwnProps {
  title?: string;
  disabled?: boolean;
  onConfirm: () => void;
}

const DeleteButton: React.FC<OwnProps> = ({ title, disabled, onConfirm }) => {
  if (disabled) {
    return <span className={styles.disabledButton}>{LABELS.DELETE}</span>;
  }

  return (
    <ModalPopover
      title={title || <FormattedMessage id="DELETE" />}
      content={LABELS.CONFIRMATION_HEADER}
      onOk={onConfirm}
      width="400px"
      className={commons.modal}
    >
      <a>{LABELS.DELETE}</a>
    </ModalPopover>
  );
};

const styles = stylesheet({
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
});

export default DeleteButton;
