import { NotificationSummary, TrackingEvent } from '@src/api/wimo';
import { TrackingPreviewDrawer } from '@src/containers/tracking-numbers/tracking-preview-drawer';
import { Card } from '@src/controls';
import { useFormatMessage } from '@src/i18n';
import { isAuthUserSuperUser } from '@src/modules/auth/auth-selectors';
import { SitesSelectors } from '@src/modules/sites';
import { services } from '@src/services';
import theme from '@src/styles/theme.default';
import { getColorForStatus } from '@src/utils';
import { useSelector } from '@src/utils/hooks';
import { humanize } from '@src/utils/string';
import { Button, Form, Switch, Timeline } from 'antd';
import { sortBy } from 'lodash';
import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { style as tss } from 'typestyle';
import { mergeRelatedEvents } from './merge-related-events';
import { getNotificationSummary } from './notification-summary';
import { StatusItem } from './status-item';
import { NotificationEventType, TrackingEventOf } from './types';

const { Item } = Timeline;

const { fetchTrackingEvents } = services.trackingEventsService;

type Props = {
  trackingNumber: string;
  tosId?: string;
  externalId?: string;
};

// Removing the ESTIMATED_DELIVERY_TIME_UPDATED event from the list, in case it is a first event.
// ESTIMATED_DELIVERY_TIME_UPDATED appears on every tracking number from checkout as a first event.
// However for that case it's a bit misleading, as it refers to the initial value, not update.
const omitInitialDeliveryTimeUpdatedEvent = (events: TrackingEvent[]) => {
  const firstEvent = events[0];
  const hasInitialDeliveryTimeUpdated =
    firstEvent?.tracking_status === 'ESTIMATED_DELIVERY_TIME_UPDATED';

  return hasInitialDeliveryTimeUpdated ? events.slice(1) : events;
};

export const HistorySection: FC<Props> = ({ trackingNumber, tosId, externalId }) => {
  const formatMessage = useFormatMessage();
  const [isDrawerVisible, setDrawerVisibility] = useState(false);
  const [extendedTrackingHistory, setExtendedTrackingHistory] = useState(false);
  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);
  const isSuperUser = useSelector(isAuthUserSuperUser);
  const { data } = useQuery(['trackingEvents', siteId, trackingNumber], () =>
    fetchTrackingEvents({ siteId, locale: 'en-US', trackingNumber })
  );

  const trackingEventsBase = data?.logistic_units?.[0].tracking_events || [];
  const trackingEventsOrderLevel =
    data?.tracking_events?.map(event => ({ ...event, orderLevel: true })) || [];

  const trackingEvents =
    extendedTrackingHistory && isSuperUser
      ? sortBy([...trackingEventsBase, ...trackingEventsOrderLevel], event =>
          event.created_at ? new Date(event.created_at).getTime() : undefined
        )
      : trackingEventsBase;

  const events = mergeRelatedEvents(omitInitialDeliveryTimeUpdatedEvent(trackingEvents ?? []), [
    {
      main: 'NOTIFICATION_TRIGGERED',
      related: ['NOTIFICATION_DELIVERED', 'NOTIFICATION_FAILED'],
      getId: event => getNotificationSummary(event)?.id!,
    },
  ]);

  const historySectionTitle = (
    <div className={styles.sectionTitle}>
      <span>{formatMessage('TRACKING_NUMBERS.DETAILS.HISTORY_SECTION_TITLE')}</span>
      <Button
        onClick={event => {
          event.stopPropagation();
          setDrawerVisibility(true);
        }}
        type="ghost"
        size="small"
      >
        {formatMessage('TRACKING_NUMBERS.DETAILS.PREVIEW_BUTTON')}
      </Button>
    </div>
  );

  const timeItems = events.map(trackingEvent => {
    const { tracking_status, event_id, relatedEvent } = trackingEvent;
    const status = tracking_status ?? 'UNKNOWN';
    const color = getColorForStatus(relatedEvent?.tracking_status ?? status);

    const statusInfo = getEventAdditionalStatus(trackingEvent);
    const levelInfo = extendedTrackingHistory && getEventInternalSource(trackingEvent);
    const additionalInfo = [...statusInfo, levelInfo].filter((info): info is string =>
      Boolean(info)
    );

    const statusItem = <StatusItem trackingEvent={trackingEvent} additionalInfo={additionalInfo} />;

    return (
      <Item color={color} key={event_id}>
        {relatedEvent ? (
          <>
            <StatusItem trackingEvent={relatedEvent} />
            <div className={styles.topSpacing}>{statusItem}</div>
          </>
        ) : (
          statusItem
        )}
      </Item>
    );
  });

  return (
    <Card className={styles.cardStyles} title={historySectionTitle}>
      {isSuperUser ? (
        <div>
          <Form.Item label="Show extended history">
            <Switch checked={extendedTrackingHistory} onChange={setExtendedTrackingHistory} />
          </Form.Item>
        </div>
      ) : null}
      <Timeline>{timeItems}</Timeline>
      <TrackingPreviewDrawer
        isVisible={isDrawerVisible}
        drawerData={{
          trackingNumber: trackingNumber ?? '',
          tosId: tosId ?? '',
          externalId: externalId ?? '',
        }}
        onClose={() => setDrawerVisibility(false)}
      />
    </Card>
  );
};

const styles = {
  topSpacing: tss({ paddingTop: 10 }),
  cardStyles: tss({
    height: '100%',
    // do not render top redundant top border as card header inherits it
    // discard width only as we need to inherit
    borderTopWidth: 0,
    $nest: {
      '>.ant-card-head': {
        // keeps the card border when header is sticky
        borderTop: `inherit`,
        borderTopWidth: 1,
        position: 'sticky',
        top: 0,
        backgroundColor: theme.color.white,
        zIndex: 1,
      },
    },
  }),
  itemStyles: tss({
    display: 'flex',
    flexDirection: 'column',
  }),
  descriptionStyles: tss({
    color: 'rgba(0, 0, 0, 0.6)',
  }),
  sectionTitle: tss({
    display: 'flex',
    justifyContent: 'space-between',
  }),
};

const getEventAdditionalStatus = (event: TrackingEvent) => {
  switch (event.tracking_status) {
    case 'NOTIFICATION_TRIGGERED':
    case 'NOTIFICATION_DELIVERED':
    case 'NOTIFICATION_FAILED': {
      const summary = getNotificationSummary(event as TrackingEventOf<NotificationEventType>);
      const channel = summary?.channel ? humanize(summary.channel) : 'UNKNOWN CHANNEL';
      const kind = getNotificationKind(summary);

      return [channel, kind];
    }
    default:
      return [];
  }
};

const getEventInternalSource = (event: TrackingEvent & { orderLevel?: boolean }) =>
  `Level: ${event.orderLevel ? 'Order' : 'Parcel'}`;

const getNotificationKind = (summary?: NotificationSummary) => {
  if (!summary?.kind) {
    return 'UNKNOWN NOTIFICATION';
  }

  // api returns just a number in case that value is out of the enum range
  if (typeof summary.kind !== 'string') {
    return `UNKNOWN NOTIFICATION: ${String(summary.kind)}`;
  }

  // return the actual status for the progress kind
  if (summary.kind === 'PROGRESS_KIND') {
    return summary.step ? humanize(summary.step) : 'UNKNOWN STEP';
  }

  return humanize(summary.kind.replace(/_KIND$/, ''));
};
