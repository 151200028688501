import { InfoCircleOutlined } from '@ant-design/icons';

import { Tooltip } from '@src/controls';
import * as React from 'react';
import { stylesheet } from 'typestyle';

interface Props {
  label: React.ReactNode;
  labelWidth?: string;
  labelTooltip?: React.ReactNode;
}

export const LabelWithWrapper: React.FunctionComponent<Props> = ({
  label,
  labelWidth,
  children,
  labelTooltip,
}) => (
  <div className={styles.wrapper}>
    <label className={styles.label} style={{ width: labelWidth || 'auto' }}>
      {label}
      {labelTooltip && (
        <Tooltip title={labelTooltip}>
          <InfoCircleOutlined style={{ marginLeft: '5px' }} />
        </Tooltip>
      )}
    </label>
    {children}
  </div>
);

const styles = stylesheet({
  wrapper: {
    display: 'flex',
  },
  label: {
    color: 'rgba(0,0,0,0.5)',
  },
});
