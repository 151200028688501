import { Popover } from 'antd';
import { Field, FieldProps, FormikProps } from 'formik';
import { isEmpty } from 'ramda';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { DragableRow } from '@src/components';
import { PriceInput, Table, Tag, Tooltip } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveFormikEnhancer, inputStyle, wideLabelLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { DragIcon } from '@src/images/drag-icon';
import { CarrierProductModel } from '@src/models';
import { CarrierProductFormValue } from '.';
import { getConditionFromFormValue, PriceRuleFormValue, PriceRuleType } from '../helpers';
import { RuleAddingContainer } from '../rule-adding-container/rule-adding-container';

const PriceField = withFormItem(PriceInput, wideLabelLayout);

interface OwnProps {
  regionId: string;
  product: CarrierProductModel;
  formProps: FormikProps<CarrierProductFormValue>;
  onPriceRuleChange: (values: CarrierProductFormValue) => void;
  onRuleRemove: (values: CarrierProductFormValue, ruleIndex: number) => void;
}

export const PriceRulesTable: React.FunctionComponent<OwnProps> = ({
  regionId,
  formProps,
  onPriceRuleChange,
  onRuleRemove,
  product,
}) => {
  const [ruleEditPopoverVisible, setRuleEditPopoverVisible] = React.useState(false);
  const [selectedRuleIndex, setselectedRuleIndex] = React.useState(0);

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const {
      values: { priceRules },
    } = formProps;

    const rulesWithoutSelectedOne = priceRules.filter((_, index) => index !== dragIndex);

    const updatedPriceRules = [
      ...rulesWithoutSelectedOne.slice(0, hoverIndex),
      priceRules[dragIndex],
      ...rulesWithoutSelectedOne.slice(hoverIndex),
    ];

    onPriceRuleChange({ ...formProps.values, priceRules: updatedPriceRules });
  };

  return (
    <>
      <Table<PriceRuleFormValue>
        dataSource={formProps.values.priceRules}
        pagination={false}
        onRow={(_, index) => ({ index, moveRow, className: styles.rowWrapper })}
        components={{ body: { row: DragableRow } }}
        rowKey={(_, index) => `row_${index}`}
        hideTitle
        className={styles.table}
      >
        <Table.Column<PriceRuleFormValue>
          title={<FormattedMessage id="CONDITIONS" />}
          key="conditions"
          render={(_, rule) => (
            <div className={styles.conditions}>
              <DragIcon className={styles.dragIcon} />
              {rule.conditions.map((condition, index) => (
                <Tooltip
                  title={getConditionFromFormValue(condition, false)}
                  key={`${condition.type}_${index}`}
                >
                  <Tag className={styles.conditionTag}>{condition.type}</Tag>
                </Tooltip>
              ))}
            </div>
          )}
        />
        <Table.Column<PriceRuleFormValue>
          title={<FormattedMessage id="PRICE" />}
          key="price"
          className={styles.priceColumn}
          render={(_, rule, ruleIndex) => {
            return (
              <AutoSaveFormikEnhancer
                name={`priceRules[${ruleIndex}]`}
                onSave={onPriceRuleChange}
                render={({ onKeyDown, onBlur }) => (
                  <Field name={`priceRules[${ruleIndex}].price`}>
                    {({ field }: FieldProps<CarrierProductFormValue['priceRules'][0]['price']>) => (
                      <PriceField
                        className={inputStyle}
                        label={<FormattedMessage id="PRICE" />}
                        {...field}
                        value={field.value}
                        disabled={isEmpty(
                          rule.conditions.filter(
                            condition => condition.type !== PriceRuleType.UNKNOWN
                          )
                        )}
                        onChange={value => formProps.setFieldValue(field.name, value)}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                      />
                    )}
                  </Field>
                )}
              />
            );
          }}
        />
        <Table.Column<PriceRuleFormValue>
          title={<FormattedMessage id="ACTIONS" />}
          key="actions"
          render={(_, _rule, index) => (
            <>
              <Popover
                className={styles.editRule}
                trigger="click"
                title={<FormattedMessage id="EDIT_RULE" />}
                visible={index === selectedRuleIndex && ruleEditPopoverVisible}
                onVisibleChange={setRuleEditPopoverVisible}
                placement="left"
                content={
                  <RuleAddingContainer
                    regionId={regionId}
                    product={product}
                    priceRuleIndex={selectedRuleIndex}
                    onSaveClick={() => setRuleEditPopoverVisible(false)}
                  />
                }
                destroyTooltipOnHide
              >
                <a onClick={() => setselectedRuleIndex(index)}>
                  <FormattedMessage id="EDIT" />
                </a>
              </Popover>
              <a onClick={() => onRuleRemove(formProps.values, index)}>
                <FormattedMessage id="DELETE" />
              </a>
            </>
          )}
        />
      </Table>
    </>
  );
};

const styles = stylesheet({
  editRule: {
    marginRight: '20px',
  },
  conditions: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  table: {
    overflow: 'scroll',
    $nest: {
      '.ant-table-thead > tr > th': {
        background: 'inherit',
      },
      '.ant-table-tbody > tr > td': {
        padding: '8px 16px',
      },
    },
  },
  dragIcon: {
    position: 'absolute',
    left: '1px',
  },
  rowWrapper: {
    $nest: {
      '& img': {
        opacity: 0,
      },
      '&:hover img': {
        opacity: 1,
      },
    },
  },
  priceColumn: {
    minWidth: '180px',
  },
  conditionTag: {
    margin: '2px 6px 2px 0',
  },
});
