import { compose } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '@src/modules';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Input } from '../../../controls';
import { withFormItem } from '../../../decorators';
import { AdditionalFields } from '../../../models';
import { decamelize, titelize } from '../../../utils/string';

const InputField = withFormItem(Input);
const CheckboxField = withFormItem(Checkbox);
const titelizeCamelCase = compose(titelize, decamelize);

type AdditionalFieldsKeys = keyof typeof defaultFieldsMap;

const defaultFieldsMap = {
  time_zone: '',
  reference: '',
  contact_person: '',
  department: '',
  phone: '',
  mobile: '',
  fax: '',
  giro: '',
  ean: '',
  organisation_no: '',
  vat_no: '',
  notes1: '',
  notes2: '',
  notes3: '',
  pallet_reg_no: '',
  domain: '',
  consignor: '',
  consignee: '',
  pick_up: '',
  delivery: '',
  payer: '',
  notify: '',
  return_address: '',
  earliest_delivery_time: '',
  latest_delivery_time: '',
  id: '',
};

const mapStateToProps = (state: RootState) => ({});

interface IProps {
  value: AdditionalFields;
  onChange: (value: AdditionalFields) => void;
  onBlur: (value: AdditionalFields) => void;
}

function AddressBookContactAdditionalFields({ value, onChange, onBlur }: IProps) {
  const fieldsMap = { ...defaultFieldsMap, ...value };

  const createFieldProps = (fieldKey: AdditionalFieldsKeys) => ({
    label: titelizeCamelCase(fieldKey),
    value: fieldsMap[fieldKey],
    onChange: ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      onChange({ ...fieldsMap, [fieldKey]: target.value });
    },
    onBlur: ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      onBlur({ ...fieldsMap, [fieldKey]: target.value });
    },
  });

  const createCheckboxProps = (fieldKey: AdditionalFieldsKeys) => ({
    onChange: ({ target }: CheckboxChangeEvent) => {
      const fieldValue = target.checked ? '1' : '0';
      onChange({ ...fieldsMap, [fieldKey]: fieldValue });
    },
    checked: fieldsMap[fieldKey] !== '0',
  });

  return (
    <>
      <CheckboxField {...createCheckboxProps('consignor')} label="Sender" />
      <CheckboxField {...createCheckboxProps('consignee')} label="Customer" />
      <CheckboxField {...createCheckboxProps('delivery')} label="Delivery" />
      <InputField {...createFieldProps('id')} label="External ID" />
      <InputField {...createFieldProps('time_zone')} />
      <InputField {...createFieldProps('reference')} />
      <InputField {...createFieldProps('contact_person')} />
      <InputField {...createFieldProps('department')} />
      <InputField {...createFieldProps('phone')} />
      <InputField {...createFieldProps('mobile')} />
      <InputField {...createFieldProps('fax')} />
      <InputField {...createFieldProps('giro')} />
      <InputField {...createFieldProps('ean')} />
      <InputField {...createFieldProps('organisation_no')} />
      <InputField {...createFieldProps('vat_no')} />
      <InputField {...createFieldProps('notes1')} />
      <InputField {...createFieldProps('notes2')} />
      <InputField {...createFieldProps('notes3')} />
      <InputField {...createFieldProps('pallet_reg_no')} />
      <InputField {...createFieldProps('domain')} />
      <InputField {...createFieldProps('pick_up')} />
      <InputField {...createFieldProps('payer')} />
      <InputField {...createFieldProps('notify')} />
      <InputField {...createFieldProps('return_address')} />
      <InputField {...createFieldProps('earliest_delivery_time')} />
      <InputField {...createFieldProps('latest_delivery_time')} />
    </>
  );
}

export const AddressBookContactAdditionalFieldsConnected = connect(
  mapStateToProps,
  {}
)(AddressBookContactAdditionalFields);
