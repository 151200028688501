import { LocationChangeAction, RouterAction } from 'connected-react-router';

import { addressBookActions } from './address-book';
import { appActions } from './app';
import { authActions } from './auth';
import { blobsActions } from './blobs';
import { configActions } from './config';
import { deliveriesActions } from './deliveries';
import { dictionariesActions } from './dictionaries';
import { eventTrackingActions } from './event-tracking';
import { labelMergerActions } from './label-merger';
import { merchantsActions } from './merchants';
import { reportsActions } from './reports';
import { sessionHistoryActions } from './session-events';
import { shipmentNotificationsActions } from './shipment-notifications';
import { shipmentsActions } from './shipments';
import { sitesActions } from './sites';
import { siwActions } from './siw';
import { somSearchActions } from './som-search';
import { tagsActions } from './tags';
import { tokenAuthActions } from './tokenauth';
import { trackingWidgetActions } from './tracking-widget';
import { transportOrdersActionCreators } from './transport-orders';
import { userprofileActions } from './userprofile';
import { usersActions } from './users';

import { ActionType } from 'typesafe-actions';

const actions = {
  ...eventTrackingActions,
  ...appActions,
  ...authActions,
  ...blobsActions,
  ...deliveriesActions,
  ...dictionariesActions,
  ...transportOrdersActionCreators,
  ...shipmentsActions,
  ...addressBookActions,
  ...merchantsActions,
  ...sitesActions,
  ...userprofileActions,
  ...usersActions,
  ...siwActions,
  ...reportsActions,
  ...somSearchActions,
  ...labelMergerActions,
  ...tokenAuthActions,
  ...tagsActions,
  ...shipmentNotificationsActions,
  ...configActions,
  ...sessionHistoryActions,
  ...trackingWidgetActions,
};
export type AppAction = ActionType<typeof actions>;

export type VendorAction = RouterAction | LocationChangeAction;

export type RootAction = AppAction | VendorAction;
