import { getUsersTableInfo } from '@src/modules/users/users-selectors';

export const selectedUsersTableInfo = (
  searchUserQuery: string,
  usersTableItems: ReturnType<typeof getUsersTableInfo>
) => {
  const re = new RegExp(searchUserQuery, 'i');
  return usersTableItems.filter(user => {
    if (user.email && user.email.search(re) >= 0) {
      return user.email.search(re) >= 0;
    } else if (user.externalId && user.externalId.search(re) >= 0) {
      return user.externalId.search(re) >= 0;
    } else {
      return !!user.name && user.name.search(re) >= 0;
    }
  });
};
