import { createSelector } from 'reselect';

import { RootState } from '../root-reducer';

const getSessionHistoryState = (state: RootState) => state.sessionHistory;

export const isFetching = createSelector(getSessionHistoryState, state => state.isFetching);
const getRawEvents = createSelector(getSessionHistoryState, state => state.rawEvents);

export const getEvents = createSelector(getSessionHistoryState, getRawEvents, (state, rawEvents) =>
  state.events.map(event => ({
    ...event,
    json: rawEvents.find(raw => raw.id === event.id) || {},
  }))
);
