import { BlobsDTO } from '@src/models';
import { apiUtils, authFetch } from '../utils';

const API_URL = apiUtils.getApiUrl() + '/blobs/';

export async function storeBlob(siteId: string, file: File): Promise<BlobsDTO> {
  const form = new FormData();
  form.append('file', file);

  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: form,
  };

  return authFetch<BlobsDTO>(API_URL, options, false).then(res => {
    return res;
  });
}
