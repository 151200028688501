import { ShoppingCartOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { CheckoutWidgetConfigurationEmbed } from '@src/containers/checkout-widget';
import { services } from '@src/services';

export const CheckoutWidgetDrawer: React.FunctionComponent = () => {
  const [isWidgetDrawerVisible, toggleWidgetDrawer] = React.useState(false);
  return (
    <>
      <div
        className={styles.widgetDrawer}
        onClick={() => {
          if (window._sw) {
            services.siwService.resumeWidget();
          }
          toggleWidgetDrawer(true);
        }}
      >
        <ShoppingCartOutlined style={{ fontSize: '22px' }} />
      </div>
      <Drawer
        visible={isWidgetDrawerVisible}
        onClose={() => {
          if (window._sw) {
            services.siwService.suspendWidget();
          }
          toggleWidgetDrawer(false);
        }}
        width={780}
      >
        <CheckoutWidgetConfigurationEmbed />
      </Drawer>
    </>
  );
};

const styles = stylesheet({
  widgetDrawer: {
    width: '55px',
    height: '55px',
    borderRadius: '50%',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'fixed',
    right: '20px',
    bottom: '20px',
    background: '#fff',
  },
});
