import { getType } from 'typesafe-actions';

import { RootAction } from '../../modules';
import * as actions from './app-actions';

// STATE
export type AppState = {
  readonly isLoading: boolean;
  readonly pageSize: number;
};
const initialState: AppState = {
  isLoading: true,
  pageSize: 10,
};

// REDUCER
export default function reducer(state: AppState = initialState, action: RootAction): AppState {
  switch (action.type) {
    case getType(actions.initApp):
      return { ...state, isLoading: false };
    default:
      return state;
  }
}
