import { FieldState, FormState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { stylesheet } from 'typestyle';

import { Button, Card, TextArea, Tooltip } from '@src/controls';
import { RootState } from '@src/modules';
import { shipmentsActions } from '@src/modules/shipments';
import { SitesSelectors } from '@src/modules/sites';
import { Uploader } from '.';
import { withFieldStateInput, withFormItem } from '../../../decorators';
import { LABELS } from '../../../dictionaries';
import { defaultTheme } from '../../../styles';
import { getEncodedFileName } from '../../../utils/string';
import { DownloadOutlined } from '@ant-design/icons';

const TextAreaField = withFormItem(withFieldStateInput(TextArea));

export class ExtraInformationFormState extends FormState<{
  notesFieldState: FieldState<string | undefined>;
  attachmentFieldState: FieldState<string | undefined>;
}> {
  static create = ({
    notes,
    attachment,
  }: {
    notes?: string;
    attachment?: string;
  } = {}): ExtraInformationFormState =>
    new FormState({
      notesFieldState: new FieldState(notes),
      attachmentFieldState: new FieldState(attachment),
    });
}

interface OwnProps {
  formState: ExtraInformationFormState;
  storeFile?: (file: File) => void;
  attachmentField: string;
  attachmentUrl: string;
  isNotInEditMode?: boolean;
  shipmentId?: string;
  isLoading?: boolean;
  children?: React.ReactNode;
}

interface State {
  canEditNote: boolean;
}

const mapStateToProps = (state: RootState) => ({
  siteId: SitesSelectors.getSelectedSiteIdOrEmpty(state),
});

const mapDispatch = {
  updateNote: shipmentsActions.editNoteRequest,
};

type Props = OwnProps & typeof mapDispatch & ReturnType<typeof mapStateToProps>;

@observer
class Component extends React.Component<Props, State> {
  componentDidUpdate() {
    const { attachmentField } = this.props;
    const { attachmentFieldState } = this.props.formState.$;

    if (attachmentField) {
      attachmentFieldState.onChange(attachmentField);
    }
  }

  handleEditButtonClick = () => {
    const {
      updateNote,
      siteId,
      shipmentId,
      formState: {
        $: {
          notesFieldState: { value },
        },
      },
    } = this.props;
    updateNote({
      siteId,
      model: {
        shipmentId: shipmentId || '',
        notes: value || '',
      },
    });
  };

  render() {
    const { isNotInEditMode, attachmentUrl, storeFile, isLoading, children } = this.props;
    const { notesFieldState } = this.props.formState.$;

    return (
      <Card
        className={styles.card}
        title={LABELS.EXTRA_INFORMATION}
        bodyStyle={{ display: 'flex', flexDirection: 'column' }}
        loading={isLoading}
      >
        <TextAreaField
          className={styles.notes}
          label="Notes"
          fieldState={notesFieldState}
          labelCol={{ span: 24 }}
        />
        {isNotInEditMode && (
          <Button className={styles.button} onClick={this.handleEditButtonClick}>
            {LABELS.SAVE}
          </Button>
        )}
        {attachmentUrl && (
          <Tooltip title="download">
            <a href={attachmentUrl} target="_blank">
              <DownloadOutlined style={{ fontSize: '16px' }} />
              {` ${LABELS.GET_ATTACHMENT} ${getEncodedFileName(attachmentUrl)}`}
            </a>
          </Tooltip>
        )}
        {!isNotInEditMode && <Uploader storeFile={storeFile} />}
        {children}
      </Card>
    );
  }
}

const styles = stylesheet({
  card: {
    marginBottom: defaultTheme.lineHeight,
    $nest: { '& .ant-row': { marginBottom: '10px' } },
  },
  button: { marginBottom: '20px', alignSelf: 'flex-start' },
  notes: {
    $nest: {
      '&.ant-input': {
        width: '50%',
        minHeight: '73px',
        backgroundColor: 'inherit',
      },
    },
  },
});

export const ExtraInformation = connect(mapStateToProps, mapDispatch)(Component);
ExtraInformation.displayName = 'ExtraInformation';
