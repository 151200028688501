import { InfoCircleOutlined } from '@ant-design/icons';
import * as React from 'react';
import { style as tss } from 'typestyle';
import { Affix, Button, Search, Tooltip } from '../controls';
import { LABELS } from '../dictionaries';
import { defaultTheme } from '../styles';

type Props = {
  title?: string | JSX.Element;
  titleTooltip?: React.ReactNode;
  actions?: JSX.Element;
  onSearchItems?: (value: string) => void;
  searchPlaceholder?: string;
  IconComponent: React.ComponentType<{ className?: string }>;
  isLoading?: boolean;
  filtersSearchValue?: string;
  showSearch?: boolean;
};

export const ListFixedHeader: React.FunctionComponent<Props> = ({
  actions,
  searchPlaceholder,
  title,
  IconComponent,
  titleTooltip,
  isLoading = false,
  showSearch = true,
  filtersSearchValue,
  onSearchItems,
}) => {
  const [searchValue, setSearchValue] = React.useState<string>(filtersSearchValue || '');

  React.useEffect(() => setSearchValue(filtersSearchValue || ''), [filtersSearchValue]);

  const onSearchHandler = () => {
    if (onSearchItems) {
      onSearchItems(searchValue);
    }
  };

  return (
    <Affix>
      <div className={styles.root}>
        <div className={styles.nameAndButton}>
          <div className={styles.iconWrapper}>
            <IconComponent className={styles.icon} />
          </div>
          <div className={styles.name}>
            {title}
            {titleTooltip && (
              <Tooltip title={titleTooltip}>
                <InfoCircleOutlined
                  style={{ marginBottom: 'auto', fontSize: '14px', marginLeft: '5px' }}
                />
              </Tooltip>
            )}
          </div>
          {actions}
        </div>
        {showSearch && (
          <div className={styles.search}>
            <Search
              style={{ width: '65%' }}
              placeholder={searchPlaceholder}
              disabled={isLoading}
              onSearch={onSearchHandler}
              onSearchValueChange={setSearchValue}
              value={searchValue}
            />
            <Button
              onClick={onSearchHandler}
              loading={isLoading}
              disabled={isLoading || !searchValue.length}
              style={{ marginLeft: 10 }}
            >
              {LABELS.SEARCH}
            </Button>
          </div>
        )}
      </div>
    </Affix>
  );
};

const styles = {
  root: tss({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '24px',
  }),
  nameAndButton: tss({
    display: 'flex',
    alignItems: 'center',
  }),
  name: tss({
    fontSize: '20px',
    fontWeight: defaultTheme.font.bold,
    marginRight: 'auto',
    display: 'flex',
  }),
  iconWrapper: tss({
    height: 32,
    width: 32,
    backgroundColor: defaultTheme.color.primary,
    borderRadius: 4,
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  icon: tss({
    fontSize: 16,
    color: defaultTheme.icon.color,
  }),
  search: tss({
    paddingTop: '24px',
  }),
};
