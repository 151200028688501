import { Tag } from 'antd';
import * as React from 'react';
const { CheckableTag: AntCheckableTag } = Tag;

interface IProps {
  checked: boolean;
  onChange?: (checked: boolean) => void;
  className?: string;
}

export const CheckableTag: React.FunctionComponent<IProps> = ({ checked, onChange, ...props }) => {
  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (checkedValue: boolean) => {
    if (onChange) {
      onChange(checkedValue);
    }
    setIsChecked(checkedValue);
  };

  return <AntCheckableTag {...props} checked={isChecked} onChange={handleChange} />;
};
