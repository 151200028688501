import * as React from 'react';
import { connect } from 'react-redux';

import { warehouseSelectors } from '@src/containers/warehouse/selectors';
import { ConfigRegionModel, CutoffTimesModel, OperatingScheduleModel, Weekdays } from '@src/models';
import { RootState } from '@src/modules';
import { configActions } from '@src/modules/config';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import {
  addCutoffTimes,
  copyTimeForCutoffTimes,
  editDateCutoffTimes,
  removeCutoffTimes,
} from './helpers';
import { WarehouseCutoffTimesForm } from './warehouse-cutoff-times-form';
import { CutoffTimesFormValue } from './warehouse-cutoff-times-form-timePicker';

interface OwnProps {
  cutoffTimes?: CutoffTimesModel;
  carrierCutoff?: boolean;
  shippingMethod: string;
  warehouseId: string;
  region?: ConfigRegionModel;
  operatingSchedule: OperatingScheduleModel;
}

const mapDispatchToProps = () => ({
  editCutoffTimes: dispatchOnDraftSiteId(configActions.updateCutoffTimesRequest),
  editCarrierCutoff: dispatchOnDraftSiteId(configActions.updateCarrierCutoffRequest),
  deleteWarehouseCutoffTimes: dispatchOnDraftSiteId(
    configActions.deleteWarehouseCutoffTimesRequest
  ),
});

const makeMapStateToProps = () => {
  // This makes reselect memoization work properly - https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances
  const getRegionsWithSelectedMethodForWarehouse =
    warehouseSelectors.makeGetRegionsWithSelectedMethodForWarehouse();
  const mapStateToProps = (state: RootState, props: OwnProps) => {
    return {
      regionsWithSelectedMethodForWarehouse: getRegionsWithSelectedMethodForWarehouse(
        state,
        props.shippingMethod,
        props.warehouseId
      ),
    };
  };
  return mapStateToProps;
};

type Props = OwnProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<ReturnType<typeof makeMapStateToProps>>;

class Component extends React.Component<Props> {
  handleChangeDate = (
    values: CutoffTimesFormValue,
    method: string,
    dayKey: Weekdays,
    index: number
  ) => {
    const { carrierCutoff, editCutoffTimes, warehouseId, operatingSchedule } = this.props;
    editCutoffTimes({
      model: {
        warehouseId,
        method,
        carrierCutoff: !!carrierCutoff,
        cutoffTimes: editDateCutoffTimes(dayKey, method, index, values.time, operatingSchedule),
      },
    });
  };

  handleAddCutoff = (dayKey: Weekdays, method: string, time: string) => {
    const { carrierCutoff, editCutoffTimes, warehouseId, operatingSchedule } = this.props;
    editCutoffTimes({
      model: {
        warehouseId,
        method,
        carrierCutoff: !!carrierCutoff,
        cutoffTimes: addCutoffTimes(dayKey, method, time, operatingSchedule),
      },
    });
  };

  handleRemoveCutoff = (dayKey: Weekdays, method: string, index: number) => {
    const { carrierCutoff, editCutoffTimes, warehouseId, operatingSchedule } = this.props;
    editCutoffTimes({
      model: {
        warehouseId,
        method,
        carrierCutoff: !!carrierCutoff,
        cutoffTimes: removeCutoffTimes(dayKey, method, index, operatingSchedule),
      },
    });
  };

  handleCopyTimeForCutoffTimes = (
    method: string,
    time: string,
    copy: 'existingDays' | 'weekWithoutWeekend' | 'fullWeek'
  ) => {
    const { carrierCutoff, editCutoffTimes, warehouseId, operatingSchedule } = this.props;
    editCutoffTimes({
      model: {
        warehouseId,
        method,
        carrierCutoff: !!carrierCutoff!,
        cutoffTimes: copyTimeForCutoffTimes(method, time, copy, operatingSchedule),
      },
    });
  };

  handleRemoveAllCutoffs = () => {
    this.props.deleteWarehouseCutoffTimes({
      model: { warehouseId: this.props.warehouseId, method: this.props.shippingMethod },
    });
  };

  handleToggleCutoffs = (method: string, carrierCutoff: boolean) => {
    const { editCarrierCutoff, warehouseId } = this.props;
    editCarrierCutoff({
      model: {
        warehouseId,
        method,
        carrierCutoff: carrierCutoff,
      },
    });
  };

  render() {
    const {
      shippingMethod,
      cutoffTimes,
      carrierCutoff,
      region,
      regionsWithSelectedMethodForWarehouse,
    } = this.props;
    /**
     * if region is provided, but shipping method we want to display
     * cuttofs for is not in one of the warehouse's carrier product
     * we don't want to display any cutoffs
     */
    if (
      region &&
      !region.carrierProducts
        .map(carrierProduct => carrierProduct.shippingMethod)
        .find(method => method === shippingMethod)
    ) {
      return null;
    }

    return (
      <WarehouseCutoffTimesForm
        method={shippingMethod}
        cutoffTimes={cutoffTimes}
        carrierCutoff={carrierCutoff}
        onChangeDate={this.handleChangeDate}
        onAddCutoff={this.handleAddCutoff}
        onRemoveCutoff={this.handleRemoveCutoff}
        onCopyTimeForCutoffTimes={this.handleCopyTimeForCutoffTimes}
        onToggleCutoffs={this.handleToggleCutoffs}
        regionsWithSelectedMethodForWarehouse={regionsWithSelectedMethodForWarehouse}
        onDeleteClick={this.handleRemoveAllCutoffs}
      />
    );
  }
}

export const WarehouseCutoffTimesFormContainer = connect(
  makeMapStateToProps,
  mapDispatchToProps
)(Component);
