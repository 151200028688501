import * as qs from 'query-string';
import { pathOr } from 'ramda';

import { DeliveryEventModel } from '../models';
import { apiUtils, authFetch } from '../utils';
import { GetTrackingEventsResponse, LogisticUnit } from '@src/api/wimo';

const WIMO_SERVICE_URL = apiUtils.getApiUrl() + '/frontend/wimo';

export const getDeliveryStatusHistoryForDeliveryWith = async (
  siteId: string,
  deliveryId: string
): Promise<DeliveryEventModel[]> => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<GetTrackingEventsResponse>(
    `${WIMO_SERVICE_URL}/tracking.events?` + qs.stringify({ delivery_id: deliveryId }),
    options
  ).then(res => {
    const events = pathOr([], [0, 'tracking_events'], res.logistic_units) as NonNullable<
      LogisticUnit['tracking_events']
    >;

    return events.map(DeliveryEventModel.createFromDTO);
  });
};
