import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Tooltip } from 'antd';
import * as moment from 'moment';
import { uniq } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { style as tss } from 'typestyle';

import { Spinner } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { RootState } from '@src/modules';
import { authSelectors } from '@src/modules/auth';
import { configActions, configSelectors } from '@src/modules/config';
import { MerchantsSelectors } from '@src/modules/merchants';
import { SitesSelectors } from '@src/modules/sites';
import { siwActions } from '@src/modules/siw';
import { usersActions } from '@src/modules/users';
import { commons, defaultTheme } from '@src/styles';
import { RemoveDraftModal } from './components';
import { PublishDraftModalContainer } from './publish-draft-modal-container';

const mapStateToProps = (state: RootState) => ({
  draftSiteExists: SitesSelectors.draftSiteExists(state),
  site: SitesSelectors.getDraftSiteOrSelectedSite(state),
  merchantId: MerchantsSelectors.getSelectedMerchantId(state),
  isSuperuser: authSelectors.isAuthUserSuperUser(state),
  isSendingChanges: state.config.isSendingChanges,
  isSynced: state.config.isSynced,
  changes: configSelectors.getChangesWithEmails(state),
});

const dispatchProps = {
  deleteDraft: configActions.deleteDraftRequest,
  checkDraftSync: configActions.getDraftSiteRequest,
  getChanges: configActions.getChangesListRequest,
  listUserSummaries: usersActions.listUserSummariesRequest,
  resetWidgetSession: siwActions.resetWidgetSession,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Component: React.FunctionComponent<Props> = ({
  draftSiteExists,
  site,
  isSuperuser,
  deleteDraft,
  isSendingChanges,
  checkDraftSync,
  getChanges,
  isSynced,
  listUserSummaries,
  changes,
  resetWidgetSession,
  merchantId,
}) => {
  React.useEffect(() => {
    if (draftSiteExists) {
      checkDraftSync();
    }
  }, []);

  React.useEffect(() => {
    if (!draftSiteExists) {
      return;
    }
    getChanges({
      onComplete: ids =>
        listUserSummaries({
          userIds: uniq(ids),
          siteId: site!.id,
          isSuperuser: !!isSuperuser,
          merchantId,
        }),
    });
  }, [site?.id, isSendingChanges]);

  const [isRemoveDraftModalVisible, toggleRemoveDraftModalVisibility] = React.useState(false);
  const [isPublishDraftModalVisible, togglePublishDraftModalVisibility] = React.useState(false);

  const authorEmail = changes[0]?.email;

  return (
    <div className={styles.statusBarContainer}>
      <div className={styles.contentWrapper}>
        {!site ? (
          <Spinner />
        ) : draftSiteExists ? (
          <>
            <span className={commons.flex}>
              <FormattedMessage id="DRAFT" />
              <Tooltip title={<FormattedMessage id="STATUS_BAR_DRAFT_TOOLTIP" />}>
                <InfoCircleOutlined
                  style={{ marginBottom: 'auto', fontSize: '12px', marginLeft: '3px' }}
                />
              </Tooltip>
            </span>
            <div className={styles.detailedInfo}>
              {isSendingChanges && (
                <ReloadOutlined style={{ fontSize: '16px' }} spin={isSendingChanges} />
              )}
              <span className={styles.date}>
                {isSendingChanges ? (
                  <>
                    <FormattedMessage id="SAVING" />
                    ...
                  </>
                ) : (
                  <FormattedMessage id="SAVED" />
                )}
                &nbsp;
                {moment(changes[0]?.updatedAt || site.updatedAt).format('LLL')}
                {authorEmail && ` by ${authorEmail}`}
              </span>
              {!isSynced && (
                <FormattedMessage id="DRAFT_OUT_OF_SYNC">
                  {msg => (
                    <Tooltip
                      trigger="hover"
                      placement="bottom"
                      title={<FormattedMessage id="DRAFT_OUT_OF_SYNC_TOOLTIP_MSG" />}
                    >
                      <span className={styles.outOfSync}>{msg}</span>
                    </Tooltip>
                  )}
                </FormattedMessage>
              )}
              <Button
                type="primary"
                onClick={() => togglePublishDraftModalVisibility(true)}
                className={styles.publishButton}
                disabled={isSendingChanges}
              >
                <FormattedMessage id="PREVIEW_CHANGES" />
              </Button>
              <Dropdown
                disabled={isSendingChanges}
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item onClick={() => toggleRemoveDraftModalVisibility(true)}>
                      <span>
                        <FormattedMessage id="REMOVE_DRAFT" />
                      </span>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button type="primary">
                  <span>...</span>
                </Button>
              </Dropdown>
            </div>
            <RemoveDraftModal
              handleOk={() => {
                deleteDraft();
                resetWidgetSession();
              }}
              isVisible={isRemoveDraftModalVisible}
              toggleVisibility={toggleRemoveDraftModalVisibility}
            />
            <PublishDraftModalContainer
              isModalVisible={isPublishDraftModalVisible}
              toggleVisibility={togglePublishDraftModalVisibility}
            />
          </>
        ) : (
          <>
            <div className={commons.flex}>
              <FormattedMessage id="PRODUCTION_SITE" />
              <Tooltip title={<FormattedMessage id="STATUS_BAR_PRODUCTION_TOOLTIP" />}>
                <InfoCircleOutlined
                  style={{ marginBottom: 'auto', fontSize: '12px', marginLeft: '3px' }}
                />
              </Tooltip>
            </div>
            <div>
              <FormattedMessage id="PUBLISHED" /> {moment(site.updatedAt).format('LLL')}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  statusBarContainer: tss({
    minHeight: '48px',
    width: '100%',
  }),
  contentWrapper: tss({
    display: 'flex',
    padding: '0 28px',
    height: '48px',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: defaultTheme.color.neutralLight,
    color: 'rgba(0, 0, 0, 0.5)',
    flexShrink: 0,
    position: 'fixed',
    left: '240px',
    right: '0px',
    top: '64px',
    zIndex: 999,
    $nest: {
      '@media screen and (max-width: 900px)': {
        position: 'unset',
        left: 'unset',
        right: 'unset',
        top: 'unset',
        zIndex: 'unset',
      },
    },
  }),
  detailedInfo: tss({
    display: 'flex',
    alignItems: 'center',
    $nest: {
      div: {
        padding: 0,
        margin: 0,
      },
    },
  }),
  date: tss({
    marginLeft: '8px',
    marginRight: '28px',
  }),
  publishButton: tss({
    marginRight: '16px',
  }),
  outOfSync: tss({
    marginRight: '28px',
    color: 'rgba(0, 0, 0, 0.5)',
    fontWeight: 500,
  }),
};

export const StatusBarContainer = connect(mapStateToProps, dispatchProps)(Component);
