import { dateSorter } from '@src/utils/sorting';
import { isEmpty } from 'ramda';
import { createSelector } from 'reselect';
import { RootState } from '..';

const getState = (state: RootState) => state.shipmentNotifications;

export const thereIsNoUnreadNotifications = createSelector(getState, notifications =>
  isEmpty(notifications.notifications.items.filter(notification => !notification.readAt))
);

export const getNotificationsSorted = createSelector(getState, state =>
  [...state.notifications.items]
    .sort((itemA, itemB) => dateSorter(itemA.createdAt, itemB.createdAt))
    .reverse()
);
