import { Input } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { getFieldArrayError } from '@src/utils/forms';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { string } from 'yup';
import { PriceRuleType } from '../../helpers';
import { RuleAddingValues } from '../rule-adding-container';

const InputItem = withFormItem(Input);

interface Props {
  name: string;
  errors: FormikProps<RuleAddingValues>['errors'];
  touched: FormikProps<RuleAddingValues>['touched'];
  type: PriceRuleType.HAS_CART_ATTRIBUTE | PriceRuleType.HAS_CART_ITEM_ATTRIBUTE;
}

const cartAttributeValidate = async (value: string) => {
  try {
    await string()
      .required('Cannot be empty')
      .matches(/^[^\s^'"]+$/g, 'Should not contain spaces or apostrophes')
      .validate(value);
  } catch (err) {
    return err;
  }
};

export const Attribute: React.FunctionComponent<Props> = ({ name, errors, touched, type }) => {
  const mapTypeToLabel: { [key in Props['type']]: string } = {
    [PriceRuleType.HAS_CART_ATTRIBUTE]: 'Cart attribute is',
    [PriceRuleType.HAS_CART_ITEM_ATTRIBUTE]: 'Cart item attribute is',
  };

  return (
    <div className={styles.wrapper}>
      <Field name={`${name}.value`} validate={cartAttributeValidate}>
        {({ field }: FieldProps<string>) => (
          <InputItem
            label={mapTypeToLabel[type]}
            {...field}
            style={{ width: '250px' }}
            error={getFieldArrayError(touched, errors, `${name}.value`)}
          />
        )}
      </Field>
    </div>
  );
};

const styles = stylesheet({
  input: {
    width: '250px',
  },
  wrapper: {
    padding: '5px 0',
    $nest: {
      '.ant-row': {
        display: 'flex',
        marginBottom: 0,
      },
      '.ant-form-explain': {
        width: '200px',
      },
    },
  },
  label: {
    paddingRight: '10px',
    whiteSpace: 'nowrap',
  },
});
