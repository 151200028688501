import { stylesheet } from "typestyle";

export default {
  color: {
    error: "#C80707",
    primary: "#000000",
    primaryLight: "#444444",
    neutralDark: "#FBFBFB",
    neutralLight: "#FBFBFB",
    palePrimary: "#333333",
    statusGrey: "#C0C0C0",
    statusPurple: "#7B61FF",
    statusPink: "#eb2f96",
    statusYellow: "#FFF4CE",
    statusOrange: "#EE9100",
    statusGreen: "#A1DC00",
    statusDarkGrey: "#555555",
    white: "#FFFFFF",
    dotStatusGreen: "#A1DC00",
    dotStatusOrange: "#FFD749",
    dotStatusRed: "#C80707",
    dotStatusGrey: "#C0C0C0"
  },
  regionForm: {
    color: {
      label: "rgba(0, 0, 0, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.15)",
      tableHeader: "rgba(0, 0, 0, 0.02)",
      shippingMethodCarrierInfo: "#F5F2F0",
      modalBackground: "#FBFBFB"
    }
  },
  lineHeight: "1.5em",
  icon: {
    color: "#FFF"
  },
  font: {
    family: `
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol'`,
    base: "13px",
    small: "11px",
    xsmall: "9px",
    large: "20px",
    xlarge: "30px",
    xxlarge: "50px",
    bold: 600
  },
  headings: {
    family: "Helvetica Neue"
  },
  headerHeight: "64px",
  gutter: "2em",
  transition: "300ms ease-in-out",
  modalWidth: "750px",
  mediumModalWidth: "1016px",
  largeModalWidth: "1192px",
  tosIdFormWidth: "540px",
  styles: stylesheet({
    flexColumn: {
      display: "flex",
      flexDirection: "column"
    }
  })
};
