import { RequireOnlyOne } from '@src/utils/types';
import { Select } from 'antd';
import { OptionProps, SelectProps as AntSelectProps } from 'antd/lib/select';
import * as React from 'react';
import { classes, style as tss, stylesheet } from 'typestyle';

const { Option: AntOption, OptGroup } = Select;

interface OptionPropsWithLabel extends OptionProps {
  label: React.ReactNode;
  additional_filter_by_values?: string[];
}

// see comments below on why this code is needed
const Option = AntOption as React.FC<OptionPropsWithLabel>;

export const inequalityOptions = [
  { label: '>', value: '>' },
  { label: '<', value: '<' },
  { label: '>=', value: '>=' },
  { label: '<=', value: '<=' },
];

export type SelectOption<Label = React.ReactNode> = {
  value: string;
  label: Label;
  optionLabel?: React.ReactNode;
  rightText?: React.ReactNode;
  disabled?: boolean;
  additionalFilterByValues?: string[];
  children?: React.ReactNode;
};

export type GroupSelectOption = {
  [key: string]: SelectOption[];
};

export interface SelectProps extends AntSelectProps<any> {
  dropdownAlign?: {
    overflow?: {
      adjustX?: boolean;
      adjustY?: boolean;
    };
  };
  caseInsensitiveSearch?: boolean;
  optionRenderer?: (item: any) => JSX.Element;
  selectedRenderer?: (item: any) => any;
  description?: string;
  options: SelectOption[];
  groupOptions: GroupSelectOption;
  // override Antd filterOption definition so we can inject additional data into option
  filterOption?: boolean | ((inputValue: string, option: any) => boolean);
}

class Component extends React.Component<RequireOnlyOne<SelectProps, 'options' | 'groupOptions'>> {
  caseInsensitiveFilter = (inputValue: string, option: any) => {
    const optionChildren = option.children;
    if (!optionChildren) {
      return false;
    }

    if (option.key === 'loadingIndicator') {
      return true;
    }

    if (typeof optionChildren !== 'string') {
      return false;
    }
    return optionChildren.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
  };

  render() {
    const {
      children,
      options,
      value,
      caseInsensitiveSearch,
      optionRenderer,
      selectedRenderer,
      defaultValue,
      className,
      description,
      groupOptions,
      ...restProps
    } = this.props;
    const nonEmptyValue = value
      ? (selectedRenderer && selectedRenderer(value)) || value
      : defaultValue;

    const overriddenProps: Pick<SelectProps, 'filterOption'> = {};
    if (caseInsensitiveSearch) {
      const { filterOption } = this.props;
      overriddenProps.filterOption = filterOption ? filterOption : this.caseInsensitiveFilter;
    }

    return (
      <>
        <Select
          value={nonEmptyValue}
          getPopupContainer={triggerNode =>
            (triggerNode && triggerNode.parentElement) || document.body
          }
          {...overriddenProps}
          {...restProps}
          className={classes(selectStyle, className)}
          placeholder={this.props.placeholder ? this.props.placeholder : '-'}
          optionLabelProp="label"
        >
          {options
            ? options.map(item => (
                <Option
                  value={item.value}
                  disabled={item.disabled}
                  // Select takes a prop 'optionLabelProp' allowing different names on open select list / select box
                  // see https://ant.design/components/select/#components-select-demo-option-label-prop
                  label={item.optionLabel || item.label}
                  key={item.value}
                  additional_filter_by_values={item.additionalFilterByValues}
                >
                  {optionRenderer ? (
                    optionRenderer(item)
                  ) : item.rightText ? (
                    <div className={styles.rightTextWrapper}>
                      {item.label}
                      <span className={styles.rightText}> {item.rightText} </span>
                    </div>
                  ) : (
                    item.label
                  )}
                </Option>
              ))
            : Object.entries(groupOptions!).map(([groupName, option]) => (
                <OptGroup label={groupName} key={groupName}>
                  {option.map(item => {
                    return (
                      <Option
                        key={item.value}
                        value={item.value}
                        disabled={item.disabled}
                        // Select takes a prop 'optionLabelProp' allowing different names on open select list / select box
                        // see https://ant.design/components/select/#components-select-demo-option-label-prop
                        label={item.optionLabel || item.label}
                      >
                        {optionRenderer ? (
                          optionRenderer(item)
                        ) : item.rightText ? (
                          <div className={styles.rightTextWrapper}>
                            {item.label}
                            <span className={styles.rightText}> {item.rightText} </span>
                          </div>
                        ) : (
                          item.label
                        )}
                      </Option>
                    );
                  })}
                </OptGroup>
              ))}
        </Select>
        {description && <div className={styles.notificationTypeDesc}>{description}</div>}
      </>
    );
  }
}

const styles = stylesheet({
  notificationTypeDesc: {
    fontSize: '13px',
    color: '#9c9c9c',
    marginLeft: '2px',
    lineHeight: '24px',
  },
  rightTextWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightText: {
    color: 'rgba(0, 0, 0, 0.25)',
    fontSize: '13px',
    marginLeft: '10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

const selectStyle = tss({
  $nest: {
    '.ant-select-selection-selected-value': {
      $nest: {
        [`.${styles.rightText}`]: {
          display: 'none',
        },
      },
    },
  },
});

export default Component;
