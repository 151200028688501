import { Layout } from 'antd';
import * as React from 'react';
import { style as tss } from 'typestyle';
const { Content } = Layout;

import { HeaderLogo, UncaughtErrorModal } from '../components';
import { SelectorMenuContainer } from '../containers/menus/selector-menu-container';
import { withErrorBoundary } from '../decorators';
import { ModulesErrorLayer } from '../layers';

import BaseLayout from './base-layout';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  padding: '24px',
  overflowY: 'auto' as 'auto',
};

const contentStyle = tss({ ...baseStyle });

const contentStyleNoPadding = tss({ ...baseStyle, padding: 0 });

export interface ContextLayoutOptions {
  noPadding?: boolean;
  showNotifications: boolean;
}
interface Props {
  options?: ContextLayoutOptions;
}

const UncaughtErrorModalWithErrorBoundary = withErrorBoundary(UncaughtErrorModal);
export const mainScrollContainerId = 'main-content-container';

const ContextLayout: React.FunctionComponent<Props> = ({
  options = { showNotifications: true, noPadding: false },
  children,
}) => {
  return (
    <BaseLayout
      showNotifications={options.showNotifications}
      headerChild={
        <>
          <HeaderLogo />
          <SelectorMenuContainer />
        </>
      }
      contentChild={
        <Content
          className={options.noPadding ? contentStyleNoPadding : contentStyle}
          // The ID and position relative style are needed for absolutely positioned content to work properly
          // Like tooltips, select menus etc.
          id={mainScrollContainerId}
          style={{ position: 'relative' }}
        >
          <UncaughtErrorModalWithErrorBoundary>
            <ModulesErrorLayer>{children}</ModulesErrorLayer>
          </UncaughtErrorModalWithErrorBoundary>
        </Content>
      }
    />
  );
};

export default ContextLayout;
