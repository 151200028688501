import { useFormatMessage } from '@src/i18n';
import { ShipmentSummaryModel } from '@src/models';
import React, { FC } from 'react';
import { Button } from 'antd';
import { useBulkPrintingMutation } from './use-bulk-actions';
import { WithTooltip } from './with-tooltip';

type Props = {
  selectedShipments: ShipmentSummaryModel[];
};

export const BulkPrinting: FC<Props> = ({ selectedShipments }) => {
  const formatMessage = useFormatMessage();

  const disableBulkPrintingCond =
    selectedShipments.some(({ status }) => status === 'CREATED' || status === 'CANCELED') ||
    !selectedShipments.length;

  const { mutate, isLoading } = useBulkPrintingMutation();

  const onBulkPrintingHandler = () => mutate(selectedShipments.map(({ id }) => id));

  return (
    <WithTooltip
      isDisabled={disableBulkPrintingCond}
      tooltipText={formatMessage('DISABLED_BULK_PRINT')}
    >
      <Button
        disabled={disableBulkPrintingCond}
        onClick={onBulkPrintingHandler}
        loading={isLoading}
      >
        {formatMessage('BULK_PRINT_LABELS')}
      </Button>
    </WithTooltip>
  );
};
