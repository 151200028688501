import * as React from 'react';
import { useQuery } from 'react-query';
import { ScheduleOutlined } from '@ant-design/icons';
import { Button, Card as AntCard, Form, Collapse } from 'antd';
import { useSelector } from 'react-redux';

import { FormattedMessage } from '@src/i18n';
import { ListFixedHeader } from '@src/components';
import { Select, Switch } from '@src/controls';
import { services } from '@src/services';
import { getAvailableLocalesOptions } from '@src/modules/dictionaries/selectors';
import { getEnvironmentalVariables } from '@src/services/env-service';
import { SitesSelectors } from '@src/modules/sites';
import { stylesheet } from 'typestyle';

const { MAD_CDN_URL } = getEnvironmentalVariables();

interface FaqFormValues {
  country: string;
  currency: string;
  locales: string[];
}

interface FaqWidgetConfigurationProps {
  visible?: boolean;
}

export const FaqWidgetConfiguration: React.FC<FaqWidgetConfigurationProps> = ({ visible }) => {
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [formInstance] = Form.useForm();

  const ref = React.useRef<HTMLDivElement>(null);

  const availableLocalesOptions = useSelector(getAvailableLocalesOptions);

  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);
  const draftSiteId = useSelector(SitesSelectors.getDraftSiteIdOrEmpty);
  const effectiveSiteId = !!draftSiteId ? draftSiteId : siteId;

  const [useDraft, setUseDraft] = React.useState<boolean>(false);

  const loadScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `${MAD_CDN_URL}/faq-widget/bootstrap.js`;
    script.onload = () => {
      setLoaded(true);
    };
    document.head.appendChild(script);
  };

  React.useEffect(() => {
    loadScript();
  }, []);

  React.useEffect(() => {
    return () => {
      if (ref.current) {
        ref.current.innerHTML = '';
      }
    };
  }, [effectiveSiteId]);

  React.useEffect(() => {
    if (visible && loaded && ref.current) {
      ref.current.innerHTML = '';
    }
  }, [visible, loaded]);

  React.useEffect(() => {
    setUseDraft(!!draftSiteId || !siteId);
  }, [draftSiteId]);

  const tokenQuery = useQuery({
    queryKey: ['faq-auth-token', effectiveSiteId, { useDraft }],
    queryFn: () => {
      return services.tokenAuthService.getFaqAuthToken(useDraft ? effectiveSiteId : siteId);
    },
    retry: false,
    staleTime: Infinity,
  });

  const initialValues: FaqFormValues = {
    country: 'SE',
    locales: ['en-US'],
    currency: 'USD',
  };

  const onCreate = (values: FaqFormValues) => {
    if (ref.current) {
      ref.current.innerHTML = '';
    }

    new window.ingrid.faq('faq-widget-placeholder', {
      ...values,
      auth_token: tokenQuery.status === 'success' ? tokenQuery.data.token : '',
    });
  };

  return (
    <>
      <ListFixedHeader
        title="FAQ Widget Test Client"
        IconComponent={ScheduleOutlined}
        showSearch={false}
      />
      <AntCard>
        <div className={styles.widgetContainer} id="faq-widget-placeholder" ref={ref} />
      </AntCard>
      <Form
        form={formInstance}
        initialValues={initialValues}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        onFinish={onCreate}
      >
        <AntCard
          title={<FormattedMessage id="SETTINGS" />}
          extra={
            <>
              <Button disabled={!loaded} type="primary" htmlType="submit">
                <FormattedMessage id={'GO_TO_WIDGET'} />
              </Button>
            </>
          }
        >
          <Collapse defaultActiveKey={['1']}>
            <Collapse.Panel header="General" key="1" forceRender>
              <div className={`${styles.switchContainer} ant-row ant-form-item`}>
                <div className="ant-col ant-col-4 ant-form-item-label">
                  <label className=".ant-form-item-label">Use draft</label>
                </div>
                <Switch disabled={!draftSiteId} checked={useDraft} onChange={setUseDraft} />
              </div>
              <Form.Item
                name="country"
                label={<FormattedMessage id="COUNTRY" />}
                rules={[{ required: true, message: '' }]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  options={services.dictionariesService.getCountriesOptions()}
                />
              </Form.Item>

              <Form.Item
                name={['currency']}
                label={<FormattedMessage id="CURRENCY" />}
                rules={[{ required: true, message: '' }]}
              >
                <Select showSearch options={services.dictionariesService.getCurrenciesOptions()} />
              </Form.Item>

              <Form.Item
                name={['locales', 0]}
                label={<FormattedMessage id="LOCALE" />}
                rules={[{ required: true, message: '' }]}
              >
                <Select options={availableLocalesOptions} />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </AntCard>
      </Form>
    </>
  );
};

const styles = stylesheet({
  widgetContainer: {
    width: '640px',
    margin: 'auto',
  },
  switchContainer: {
    alignItems: 'center',
  },
});
