import { Formik } from 'formik';
import * as React from 'react';

import { ComponentReturningChildren } from '@src/components';
import { InputNumber, Switch } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, AutoSaveFormikEnhancer, widerLayout, wideLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import {
  CategoryAdditionalOptionsModel,
  CategoryRequirementsModel,
  ConfigShippingCategoryModel,
  DeliveryTypeEnum,
} from '@src/models';

const InputNumberField = withFormItem(InputNumber, widerLayout);
const LabelWrapper = withFormItem(ComponentReturningChildren, wideLayout);
const SwitchField = withFormItem(Switch, widerLayout);

const MAX_LOCATION_LIMIT = 20;
const MIN_LOCATION_LIMIT = 0;

interface Props {
  category: ConfigShippingCategoryModel;
  handleUpdate: (values: {
    preselected?: boolean;
    requirements?: Partial<CategoryRequirementsModel>;
    additionalOptions?: CategoryAdditionalOptionsModel;
  }) => void;
  showPreselectedFlag: boolean;
}

export const Properties: React.FunctionComponent<Props> = ({
  category,
  handleUpdate,
  showPreselectedFlag,
}) => {
  return (
    <AutoSaveForm>
      <Formik
        initialValues={{
          locationsLimit: category.additionalOptions.instoreSearchOptions.locationsLimit,
        }}
        enableReinitialize={false}
        onSubmit={() => undefined}
      >
        {formik => (
          <LabelWrapper label={<FormattedMessage id="PROPERTIES" />}>
            {showPreselectedFlag && (
              <SwitchField
                label={<FormattedMessage id="PRESELECTED" />}
                checked={category.preselected}
                onChange={value => {
                  handleUpdate({
                    preselected: value,
                    requirements: value
                      ? {
                          address: false,
                        }
                      : undefined,
                  });
                }}
              />
            )}
            <SwitchField
              label={<FormattedMessage id="ADDRESS_REQUIRED" />}
              checked={category.requirements.address}
              onChange={value => {
                handleUpdate({
                  preselected: value ? false : undefined,
                  requirements: {
                    address: value,
                  },
                });
              }}
              labelTooltip={<FormattedMessage id="ADDRESS_REQUIRED_TOOLTIP" />}
            />
            <SwitchField
              label={<FormattedMessage id="ENABLE_CHOICE_SELECTION" />}
              checked={category.requirements.enableChoiceSelection}
              onChange={value => {
                handleUpdate({
                  requirements: {
                    enableChoiceSelection: value,
                  },
                });
              }}
              labelTooltip={<FormattedMessage id="ENABLE_CHOICE_SELECTION_TOOLTIP" />}
            />
            <SwitchField
              label={<FormattedMessage id="SHOW_CARRIER_LOGOS" />}
              checked={category.requirements.showCarrierLogos}
              onChange={value => {
                handleUpdate({
                  requirements: {
                    showCarrierLogos: value,
                  },
                });
              }}
              labelTooltip={<FormattedMessage id="SHOW_CARRIER_LOGOS_TOOLTIP" />}
            />
            {category.deliveryType === DeliveryTypeEnum.INSTORE && (
              <>
                <SwitchField
                  label={<FormattedMessage id="ENABLE_LOCATION_SEARCH" />}
                  checked={category.additionalOptions.instoreSearchOptions.enabled}
                  onChange={value => {
                    handleUpdate({
                      additionalOptions: {
                        instoreSearchOptions: {
                          enabled: value,
                          locationsLimit: formik.values.locationsLimit,
                        },
                      },
                    });
                  }}
                  labelTooltip={<FormattedMessage id="ENABLE_LOCATION_SEARCH_TOOLTIP" />}
                />
                {category.additionalOptions.instoreSearchOptions.enabled && (
                  <AutoSaveFormikEnhancer
                    onSave={() =>
                      handleUpdate({
                        additionalOptions: {
                          instoreSearchOptions: {
                            enabled: category.additionalOptions.instoreSearchOptions.enabled,
                            locationsLimit: formik.values.locationsLimit,
                          },
                        },
                      })
                    }
                    name="locationsLimit"
                    render={({ onBlur, onKeyDown }) => (
                      <InputNumberField
                        min={MIN_LOCATION_LIMIT}
                        max={MAX_LOCATION_LIMIT}
                        precision={0}
                        label={<FormattedMessage id="ENABLE_LOCATION_LIMIT" />}
                        labelTooltip={<FormattedMessage id="ENABLE_LOCATION_LIMIT_TOOLTIP" />}
                        value={formik.values.locationsLimit}
                        onBlur={onBlur}
                        onChange={(value: number) =>
                          formik.setFieldValue(
                            'locationsLimit',
                            // if value is lower than MIN set MIN value; if value is higher than MAX set MAX value
                            Math.min(Math.max(value, MIN_LOCATION_LIMIT), MAX_LOCATION_LIMIT)
                          )
                        }
                        onKeyDown={onKeyDown}
                      />
                    )}
                  />
                )}
              </>
            )}
          </LabelWrapper>
        )}
      </Formik>
    </AutoSaveForm>
  );
};
