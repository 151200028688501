export const ERRORS = {
  CREATE_FAILED: 'Oops, something went wrong. Please try again.',
  CANCEL_SUCCESS: 'Cancel completed successfully.',
  CANCEL_FAILED: 'Oops, something went wrong. Please try again.',
  UPDATE_SUCCESS: 'Update completed successfully.',
  UPDATE_FAILED: 'Oops, something went wrong. Please try again.',
  DELETE_SUCCESS: 'Delete completed successfully.',
  DELETE_FAILED: 'Oops, something went wrong. Please try again.',
  FORM_UNDEFINED: 'Form is undefined',
  NOT_FOUND: 'Item not found',
  DEFAULT_PRICE_MISSING: 'Default price rule is missing',
  CREATE_SHIPMENT_SUCCESS: 'Shipment has been successfully created',
  CREATE_ADDRESSES_FAILED: 'Oops, something went wrong. Check your file structure and try again.',
  DELETE_ADDRESS_ERROR: 'Oops, something went wrong. Please try again.',
  DELETE_CONTACT_SUCCESS: `Address has been deleted.`,
  DELETE_CONTACTS_SUCCESS: (itemCount: number) =>
    itemCount === 1 ? `1 address has been deleted` : `${itemCount} addresses have been deleted.`,
  LIST_CONTACTS_ERROR: 'Oops, something went wrong. Error while getting contacts.',
  CLOSE_ALERT: `OK, I got it`,
  CREATE_ADDRESS_SUCCESS: (itemCount: number) => {
    switch (itemCount) {
      case 0:
        return 'No new addresses have been added';
      case 1:
        return '1 new address has been added';
      default:
        return `${itemCount} new addresses have been added`;
    }
  },
  PARSE_ADDRESS_ERROR: 'Error while reading file',
  BOOKING_PARCELS_SUCCESS: 'Shipment has been successfully booked',
  BOOKING_PARCELS_ERROR: 'Shipment booking error',
  BOOK_PICKUP_DELIVERY_SUCCESS: 'Shipment has been scheduled',
  BOOK_PICKUP_DELIVERY_ERROR: 'Oops, something went wrong. Please try again.',
  CREATE_TEMPLATE_SUCCESS: 'Template successfully created',
  CONFIGURING_SHIPPING_METHOD_SUCCESS: 'Shipping method has been configured',
  CONFIGURING_SHIPPING_METHOD_ERROR: 'Error during shipping method configuration',
  CREATE_AND_BOOK_MULTIPLE_SHIPMENTS_SUCCESS: 'Shipments created and booked successfully',
  CREATE_AND_BOOK_MULTIPLE_SHIPMENTS_ERROR:
    'Oops, there are some errors, not all shipments have been created and booked',
  CREATE_AND_BOOK_MULTIPLE_SHIPMENTS_PROGRESS: 'Creating and booking of shipments in progress',
  STORING_FILE_SUCCESS: 'File has been stored',
  STORING_FILE_ERROR: 'Error during storing file',
  GETTING_FILE_SUCCESS: 'File has been downloaded',
  GETTING_FILE_ERROR: 'Error during getting file',
  GENERATING_SHIPMENTS_REPORT_ERROR: 'Error during generating shipments report',
  GENERATING_SHIPMENTS_REPORT_IN_PROGRESS: 'Generating shipments report in progress',
  GENERATING_SHIPMENTS_REPORT_SUCCESS: 'Report is ready. Please click button do download it.',
  MERGING_LABELS_ERROR: 'Error during merging labels',
  ADD_TAGS_TO_SHIPMENTS_SUCCESS: 'Shipments have been tagged',
  ADD_TAGS_TO_SHIPMENTS_ERROR: 'Oops, something went wrong. Please try again.',
  REMOVE_TAGS_TO_SHIPMENTS_SUCCESS: 'Tag has been removed',
  REMOVE_TAGS_TO_SHIPMENTS_ERROR: 'Oops, something went wrong. Please try again.',
  USER_UPDATE_SUCCESS: 'User updated successfully',
  USER_UPDATE_ERROR: 'Error during user update',
  MARK_NOTIFICATIONS_AS_READ_ERROR: 'Error during marking notifications as read',
  DELETE_TRIGGER_ERROR: 'There was an error during deleting your trigger',
  CREATE_TRIGGER_SUCCESS:
    'Creation success. You can find your pending shipment notifications under the bell icon on the right',
  PUBLISH_DRAFT_ERROR:
    'Something went wrong when publishing the draft. Changes you made are saved, but production did not get updated',
  PUBLISH_DRAFT_SUCCESS: 'Draft successfully published to production',
  REMOVE_DRAFT_SUCCESS: 'Draft successfully removed',
  REMOVE_DRAFT_ERROR: 'There was an error while removing the draft',
  UPDATE_CUTOFF_TIMES_ERROR: 'There was an error while updating cutoff times',
  UPDATE_COUNTRY_SETTINGS_ERROR: 'There was an error while updating country settings',
  UPDATE_WAREHOUSE_DETAILS_ERROR: 'There was an error while updating warehouse details',
  DELETE_WAREHOUSE_ERROR: 'There was an error while deleting warehouse',
  CREATE_OR_UPDATE_CUTOFF_TIMES: 'There was an error while copying cutoff times',
};
