import { isNil, pipe, toString } from 'ramda';

export const intToDecimal = (int: number): number => int / 100;

export const decimalToInt = (decimal: number): number => Math.round(decimal * 100);

export const stringToDecimal = (valueToConvert: string) =>
  pipe((value: string) => parseInt(value, 10), intToDecimal)(valueToConvert);

export const decimalToStringWithoutFactorial = (value: number | undefined) =>
  isNil(value) ? value : pipe(decimalToInt, toString)(value);
