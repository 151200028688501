import { LinkStyleButton } from '@src/components';
import { useFormatMessage } from '@src/i18n';
import { CarrierProductMapping, ListCarrierProductMappingResponse } from '@src/models';
import { configSelectors } from '@src/modules/config';
import { useSelector } from '@src/utils/hooks';
import { Switch, Table } from 'antd';
import * as React from 'react';
import { style as tss } from 'typestyle';
import { TrackingConfigStatus } from '../components/tracking-config-status';
import { selectGlobalFetching } from '../utils';
import { useDeleteMappingMutation, useUpdateMappingMutation } from './mutations';

type Props = {
  onRefClick: (carrierProductMappingId: string) => void;
  isLoading?: boolean;
  carrierProductMappings?: ListCarrierProductMappingResponse;
};

export const CarrierProductMappingsTable: React.FC<Props> = ({
  onRefClick,
  isLoading,
  carrierProductMappings,
}) => {
  const formatMessage = useFormatMessage();
  const updateTrackingLinksMutation = useUpdateMappingMutation();
  const deleteMappingMutation = useDeleteMappingMutation();
  const isGlobalFetching = useSelector(selectGlobalFetching);

  const isCreatingDraft = useSelector(configSelectors.getIsCreatingDraft);

  return (
    <Table<CarrierProductMapping>
      className={styles.table}
      dataSource={carrierProductMappings?.carrier_product_mappings || []}
      pagination={false}
      rowKey="carrier_product_ref"
      loading={isLoading || isCreatingDraft || isGlobalFetching}
    >
      <Table.Column<CarrierProductMapping>
        title={formatMessage('CARRIER_PRODUCT_REF')}
        dataIndex="carrier_product_ref"
        defaultSortOrder="ascend"
        sorter={(mappingA, mappingB) =>
          mappingA.carrier_product_ref.localeCompare(mappingB.carrier_product_ref)
        }
        key="carrier_product_ref"
        width={270}
        render={(value, record) => (
          <LinkStyleButton onClick={() => onRefClick(record.id)}>{value}</LinkStyleButton>
        )}
      />
      <Table.Column<CarrierProductMapping>
        title={formatMessage('CARRIER_PRODUCT', { multiple: false })}
        dataIndex="carrier_product_id"
        key="carrier_product_id"
        width={250}
        render={(_, { carrier_product_carrier, carrier_product_name, carrier_product_id }) => {
          if (!carrier_product_id) {
            return '—';
          }
          if (!carrier_product_carrier && !carrier_product_name) {
            return carrier_product_id;
          }
          return `${carrier_product_carrier} ${carrier_product_name}`;
        }}
      />
      <Table.Column<CarrierProductMapping>
        title={formatMessage('TRACKING_CONFIG_STATUS')}
        key="tracking_config_status"
        render={(_, { custom, tracking_config_status }) =>
          custom ? '' : <TrackingConfigStatus status={tracking_config_status} />
        }
      />
      <Table.Column<CarrierProductMapping>
        title={formatMessage('ALWAYS_SHOW_TRACKING_LINKS')}
        key="always_show_tracking_links"
        render={(_, record) => {
          return (
            <Switch
              checked={record.tracking_links && record.always_show_tracking_links}
              onChange={value => {
                updateTrackingLinksMutation.mutate({
                  ...record,
                  always_show_tracking_links: value,
                });
              }}
              disabled={!record.tracking_links || updateTrackingLinksMutation.isLoading}
            />
          );
        }}
      />
      <Table.Column<CarrierProductMapping>
        title={formatMessage('ACTIONS')}
        key="actions"
        render={(_, record) => (
          <a
            onClick={e => {
              e.preventDefault();
              deleteMappingMutation.mutate(record.id);
            }}
          >
            {formatMessage('DELETE')}
          </a>
        )}
      />
    </Table>
  );
};

const styles = {
  table: tss({
    $nest: {
      '.ant-table-tbody tr td': {
        borderBottom: '1px solid rgba(0,0,0,0.09)',
      },
      '.ant-table-tbody tr:hover td.group-header': {
        background: 'unset',
      },
      '.ant-table-thead tr th': {
        background: 'rgba(0,0,0,0.02)',
        borderBottom: '1px solid rgba(0,0,0,0.09)',
      },
    },
  }),
};
