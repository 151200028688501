import { InfoCircleOutlined } from '@ant-design/icons';
import * as React from 'react';
import { useQuery } from 'react-query';
import * as moment from 'moment';
import { clamp } from 'lodash';
import { Form, InputNumber, Switch, Tooltip } from 'antd';
import { Input, Select, TextArea } from '@src/controls';
import { formStyle } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { inputStyle } from '@src/forms';
import { services } from '@src/services';
import { ExperimentWithVariantModel, UpdateExperimentModel, UserSummaryModel } from '@src/models';

import { ImprovementTable, ResultTableWithTabs } from './result-table';
import { getCountriesGroupedByGeoregionForDefinedRegions } from '@src/modules/config/selectors';
import { useSelector } from '@src/utils/hooks';
import { isAuthUserSuperUser } from '@src/modules/auth/auth-selectors';
import { useForm } from 'antd/lib/form/Form';

interface OwnProps {
  experimentData: UpdateExperimentModel & ExperimentWithVariantModel;
  disabled?: boolean;
  onSubmit: (values: UpdateExperimentModel) => void;
}

interface UpdateExperimentForm extends Omit<UpdateExperimentModel, 'type' | 'siteId' | 'id'> {
  internal: boolean;
}

const confidenceThresholdTooltip =
  'Confidence indicates the degree of confidence that the statistical result did not occur by chance or by sampling error. It is customary to require a confidence threshold of 95% or sometimes 99% to consider the results statistically significant. The confidence threshold is set by the experimenter in advance to determine whether experiment results can be considered statistically significant.';

export const externalBalancingTooltip = 
  'External balancing of traffic causes the traffic to the experiment to be balanced based on identifier set on cart attributes for a checkout session. In order to assign checkout sessions to A/B testing experiment variant, one has to define external traffic balancing IDs on A/B testing variants and pass those values in `cart_attributes` field when creating a session.';

export const ExperimentDetails: React.FC<OwnProps> = ({ experimentData, disabled, onSubmit }) => {
  const isSuperUser = useSelector(isAuthUserSuperUser);
  const countriesOptions = useSelector(getCountriesGroupedByGeoregionForDefinedRegions);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [trafficEnabled, setTrafficEnabled] = React.useState<boolean>(!experimentData.externalTrafficBalancingEnabled);

  const [form] = useForm<UpdateExperimentForm>();

  const userIds = [
    experimentData.createdBy,
    ...(experimentData.status !== 'CREATED' ? [experimentData.startedBy] : []),
    ...(experimentData.status === 'COMPLETED' ? [experimentData.endedBy] : []),
  ];

  const usersQuery = useQuery<UserSummaryModel[]>({
    queryKey: ['users', experimentData.id],
    queryFn: () => {
      return services.usersService.listUserSummaries(userIds as string[]);
    },
    initialData: [],
    retry: false,
  });

  const createdBy = usersQuery.data!.find(({ id }) => id === experimentData.createdBy);
  const startedBy = usersQuery.data!.find(({ id }) => id === experimentData.startedBy);
  const endedBy = usersQuery.data!.find(({ id }) => id === experimentData.endedBy);
  const onValuesChange = (changedValues: Partial<UpdateExperimentForm>) => {
    // We want to submit the form on Switch change instantly instead of waiting for the blur event
    if (changedValues.internal !== undefined || changedValues.externalTrafficBalancingEnabled !== undefined) {
      form.submit();
    } else {
      setIsDirty(true);
    }
  };

  const onBlur = () => {
    if (isDirty) {
      form.submit();
      setIsDirty(true);
    }
  };

  return (
    <Form
      initialValues={{ ...experimentData, internal: experimentData.type === 'INTERNAL' }}
      labelCol={{ span: 6 }}
      labelAlign="left"
      className={formStyle}
      onBlur={onBlur}
      form={form}
      onValuesChange={onValuesChange}
      onFinish={({ internal, ...values }) => {
        onSubmit({
          ...values,
          type: internal ? 'INTERNAL' : 'EXTERNAL',
          id: experimentData.id,
          siteId: experimentData.siteId,
        });
      }}
    >
      <Form.Item
        name="name"
        label={<FormattedMessage id="NAME" />}
        rules={[{ required: true, message: '' }]}
      >
        <Input disabled={disabled} className={inputStyle} />
      </Form.Item>

      <Form.Item name="country" label="Audience">
        <Select
          groupOptions={countriesOptions}
          showSearch
          filterOption={true}
          optionFilterProp="children"
          className={inputStyle}
          disabled={disabled}
        />
      </Form.Item>

      <Form.Item name="description" label="Goal">
        <TextArea disabled={disabled} className={inputStyle} />
      </Form.Item>

      {isSuperUser && (
        <Form.Item name="internal" label="Internal" valuePropName="checked">
          <Switch />
        </Form.Item>
      )}

      <Form.Item
        name="status"
        label={<FormattedMessage id="STATUS" />}
        getValueProps={value => ({ value: `${value[0]}${value.slice(1).toLowerCase()}` })}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        label={
          <>
            Confidence threshold
            <Tooltip title={confidenceThresholdTooltip}>
              <InfoCircleOutlined style={{ marginLeft: '3px' }} />
            </Tooltip>
          </>
        }
      >
        <Input value="95%" disabled className={inputStyle} />
      </Form.Item>

      {experimentData.result !== undefined && (
        <>
          <Form.Item label="Improvement">
            <ImprovementTable experiment={experimentData} />
          </Form.Item>

          <Form.Item label="Result">
            <ResultTableWithTabs experiment={experimentData} />
          </Form.Item>
        </>
      )}

      {experimentData.result !== undefined && (
        <Form.Item
          name={['result', 'receivedAt']}
          label="Result updated"
          getValueProps={value => ({
            value: `at ${moment(value).format('YYYY-MM-DD HH:mm:ss')}`,
          })}
        >
          <Input disabled />
        </Form.Item>
      )}

      <Form.Item
        name="createdAt"
        label="Created"
        getValueProps={value => ({
          value: `at ${moment(value).format('YYYY-MM-DD HH:mm:ss')} by ${
            createdBy ? createdBy.email : experimentData.createdBy
          }`,
        })}
      >
        <Input disabled />
      </Form.Item>

      {experimentData.status !== 'CREATED' && (
        <Form.Item
          name="startedAt"
          label="Started"
          getValueProps={value => ({
            value: `at ${moment(value).format('YYYY-MM-DD HH:mm:ss')} by ${
              startedBy ? startedBy.email : experimentData.startedBy
            }`,
          })}
        >
          <Input disabled />
        </Form.Item>
      )}

      {experimentData.status === 'COMPLETED' && (
        <Form.Item
          name="endedAt"
          label="Ended"
          getValueProps={value => ({
            value: `at ${moment(value).format('YYYY-MM-DD HH:mm:ss')} by ${
              endedBy ? endedBy.email : experimentData.endedBy
            }`,
          })}
        >
          <Input disabled />
        </Form.Item>
      )}

      <Form.Item
        name="externalTrafficBalancingEnabled"
        label={
          <>
            External balancing of traffic
            <Tooltip title={externalBalancingTooltip}>
              <InfoCircleOutlined style={{ marginLeft: '3px' }} />
            </Tooltip>
          </>
        }
        valuePropName="checked"
      >
        <Switch disabled={disabled} onChange={(checked) => setTrafficEnabled(!checked)} />
      </Form.Item>

      { trafficEnabled && (
        <Form.Item
          name="traffic"
          label={<FormattedMessage id="TRAFFIC" />}
          rules={[{ required: true, message: '' }]}
        >
          <InputNumber
            step={10}
            min={0}
            max={1000}
            formatter={(value: number) => (value !== undefined ? `${value / 10}%` : '')}
            parser={(value: string) => {
              const numValue = value !== undefined ? value.replace('%', '') : 0;
              return clamp((numValue as number) * 10, 0, 1000);
            }}
            className={inputStyle}
            disabled={disabled}
          />
        </Form.Item>
      )}
    </Form>
  );
};
