import { Col } from 'antd';
import { ColProps } from 'antd/lib/col';
import * as React from 'react';

const Component: React.StatelessComponent<ColProps> = props => {
  const { children, ...restProps } = props;

  return <Col {...restProps}>{children}</Col>;
};

export default Component;
