import React, { FC, Fragment } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { FormattedMessage } from '@src/i18n';
import { useSiteId } from '@src/utils/hooks';
import { services } from '@src/services';
import { useMutationOnDraft } from '@src/utils/queries';

import { BreadcrumbSection, FeaturesTable, Header, TableGrid } from './components';
import { ReceiptFeaturesConfigFormValues, mapFormValuesToRequestPayload } from './helpers';

export const ReceiptDefaultSettings: FC = () => {
  const siteId = useSiteId();
  const receiptFeatures = useQuery(['site', siteId], () => services.configService.getSite(siteId), {
    enabled: !!siteId,
    select: data => data?.receiptWidgetConfiguration?.features,
  });

  const queryClient = useQueryClient();

  const onSubmitDraft = useMutationOnDraft<ReceiptFeaturesConfigFormValues, unknown>(
    (siteId, payload) =>
      services.selfCareService.updateReceiptConfiguration(
        siteId,
        mapFormValuesToRequestPayload(payload)
      ),
    {
      onSuccess: () => queryClient.invalidateQueries(['site']),
    }
  );

  return (
    <Fragment>
      <StatusBarContainer />
      <BreadcrumbSection>
        <FormattedMessage id="DEFAULT_SETTINGS" />
      </BreadcrumbSection>
      <Header>Default receipt settings</Header>
      <TableGrid>
        <FeaturesTable
          onSubmit={onSubmitDraft.mutate}
          loading={receiptFeatures.isLoading}
          receiptFeatures={receiptFeatures.data}
        />
      </TableGrid>
    </Fragment>
  );
};
