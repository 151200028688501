import React, { useEffect, useRef, useState } from 'react';
import { Field, FieldProps, FormikProps } from 'formik';
import { stylesheet } from 'typestyle';
import { getFieldArrayError } from '@src/utils/forms';
import { withFormItem } from '@src/decorators';
import { Select } from '@src/controls';
import { RuleAddingValues } from '@src/containers/regions/carrier-products/rule-adding-container';
import { useSelector } from '@src/utils/hooks';
import { configSelectors } from '@src/modules/config';

const SelectItem = withFormItem(Select);

interface CarrierProductAvailabilityProps {
  name: string;
  errors: FormikProps<RuleAddingValues>['errors'];
  touched: FormikProps<RuleAddingValues>['touched'];
  setFieldValue: FormikProps<RuleAddingValues>['setFieldValue'];
}

export const CarrierProductAvailability = ({
  name,
  errors,
  touched,
  setFieldValue,
}: CarrierProductAvailabilityProps) => {
  const [carrierProducts, setCarrierProducts] = useState<{ label: string; value: string }[]>([]);
  const regions = useSelector(state => configSelectors.getRegionsByDraftOrCurrentSiteId(state));

  const conditions = useRef<{
    available: string[];
    unavailable: string[];
  }>({ available: [], unavailable: [] });

  useEffect(() => {
    const carrierProducts = regions.flatMap(region => region.carrierProducts);
    const uniqueShippingMethods = Array.from(
      new Set(carrierProducts.map(carrierProducts => carrierProducts.shippingMethod)).values()
    );
    const allSiteRegionsProductCarriers = uniqueShippingMethods.reduce<
      {
        label: string;
        value: string;
      }[]
    >((acc, shippingMethod) => {
      const newShippingMethod = {
        label: shippingMethod,
        value: shippingMethod,
      };
      return [...acc, newShippingMethod];
    }, []);

    setCarrierProducts(allSiteRegionsProductCarriers);
  }, []);

  const handleOnChange = () => {
    const availableCondition = conditions.current.available.map(available => `'${available}'`);
    const unavailableCondition = conditions.current.unavailable.map(
      unavailable => `'${unavailable}'`
    );

    const containsAvailable =
      availableCondition.length > 0
        ? `contains_any(available_shipping_methods, (${availableCondition}))`
        : '';
    const containsUnavailable =
      unavailableCondition.length > 0
        ? `!contains_all(available_shipping_methods, (${unavailableCondition}))`
        : '';

    const connector =
      availableCondition.length > 0 && unavailableCondition.length > 0 ? ` && ` : '';

    const builtStringValue = `${containsAvailable}${connector}${containsUnavailable}`;

    setFieldValue(`${name}.value`, builtStringValue);
  };

  return (
    <div className={styles.wrapper}>
      <Field name={`${name}.available.value`}>
        {({ field }: FieldProps<string[]>) => (
          <SelectItem
            label="Available products"
            className={styles.select}
            options={carrierProducts}
            mode="tags"
            filterOption
            showSearch
            {...field}
            onChange={values => {
              conditions.current.available = values;
              handleOnChange();
            }}
            placeholder="-"
            error={getFieldArrayError(touched, errors, `${name}.available.values`)}
          />
        )}
      </Field>

      <Field name={`${name}.unavailable.value`}>
        {({ field }: FieldProps<string[]>) => (
          <SelectItem
            label="Unavailable products"
            className={styles.select}
            options={carrierProducts}
            mode="tags"
            filterOption
            showSearch
            {...field}
            onChange={values => {
              conditions.current.unavailable = values;
              handleOnChange();
            }}
            placeholder="-"
            error={getFieldArrayError(touched, errors, `${name}.unavailable.values`)}
          />
        )}
      </Field>
    </div>
  );
};

const styles = stylesheet({
  wrapper: {
    padding: '5px 0',
    $nest: {
      '.ant-row': {
        display: 'flex',
        marginBottom: '10px',
      },
      '.ant-form-explain': {
        width: '200px',
      },
      '.ant-form-item-label': {
        minWidth: '150px',
        textAlign: 'left',
      },
    },
  },
  select: {
    width: '260px!important',
  },
  label: {
    paddingRight: '10px',
    whiteSpace: 'nowrap',
  },
});
