import * as React from 'react';
import { Checkbox, Form, FormItemProps, Input, InputNumber, Table, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { matchHexColor } from '@src/utils';

import { FeatureFlags as FaqWidgetFeatures } from '@src/api/faq-widget';
import Select from '@src/controls/select';
import { FeatureDefinition } from '../types';
import { featureDefinitions } from '../constants';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface FeaturesTableProps {
  loading: boolean;
  values?: FaqWidgetFeatures;
  onSubmit: (param: FaqWidgetFeatures) => void;
  country?: string;
}

export const FeaturesTable: React.FC<FeaturesTableProps> = ({
  loading,
  values = {},
  onSubmit,
  country,
}) => {
  const [form] = Form.useForm<FaqWidgetFeatures>();

  React.useEffect(() => {
    form.resetFields();
  }, [values]);

  async function handleSubmit(name: keyof FaqWidgetFeatures) {
    const value = form.getFieldValue(name);
    if (value !== values[name]) {
      await form.validateFields([name]);
      onSubmit({ [name]: value });
    }
  }

  const columns: ColumnType<FeatureDefinition>[] = [
    {
      title: 'Feature name',
      dataIndex: 'title',
      render: (title, record) => {
        if (!record.description) {
          return title;
        }

        return <Tooltip title={record.description}>{title}</Tooltip>;
      },
    },
    {
      title: country ? country : 'Default',
      dataIndex: 'name',
      render: (name, record) => {
        if (record.type === 'checkbox') {
          const props = {
            onChange: (e: CheckboxChangeEvent) => handleSubmit(name),
          } as FormItemProps;

          return (
            <Form.Item name={name} valuePropName="checked" style={{ margin: 0 }} {...props}>
              <Checkbox checked={form.getFieldValue(name)} />
            </Form.Item>
          );
        }

        if (record.type === 'select') {
          return (
            <Form.Item name={name} style={{ margin: 0 }}>
              <Select
                options={record.options}
                onChange={() => {
                  handleSubmit(name);
                }}
              />
            </Form.Item>
          );
        }

        const props = {
          onBlur: () => handleSubmit(name),
        } as FormItemProps;
        return (
          <Form.Item
            name={name}
            style={{ margin: 0 }}
            rules={[
              {
                validator: (_, value) => {
                  if (record.type === 'hex' && !matchHexColor(value || '')) {
                    return Promise.reject(
                      new Error('The color should be entered in hex format (starts with "#")')
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
            validateTrigger="onBlur"
            {...props}
          >
            {record.type === 'number' ? (
              <InputNumber disabled={record.disabledIf?.(values, form.getFieldsValue())} />
            ) : (
              <Input />
            )}
          </Form.Item>
        );
      },
    },
  ];

  return (
    <Form
      form={form}
      initialValues={{
        font_weight_default: '400',
        font_weight_stronger: '500',
        shipping_categories_visible_when_folded_count: 1,
        ...values,
      }}
    >
      <Table
        rowKey={record => `${record.name}-${record.type}`}
        dataSource={featureDefinitions}
        columns={columns}
        loading={loading}
        pagination={false}
      />
    </Form>
  );
};
//
