import { humanize } from '@src/utils/string';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { FC } from 'react';
import { style as tss } from 'typestyle';
import type { GeneralCustomsDeclaration } from '../types';
import { getAddress } from '../utils';

type TextBlockProps = {
  label: string;
  value?: string;
};

const TextBlock: FC<TextBlockProps> = ({ label, value, children }) => (
  <div className={styles.textblock.container}>
    <span className={styles.textblock.label}>{label}</span>
    {children ?? <span className={styles.textblock.value}>{value ?? '-'}</span>}
  </div>
);

type Props = {
  customsDeclaration: GeneralCustomsDeclaration | undefined;
};

export const GeneralInformation: FC<Props> = ({ customsDeclaration }) => {
  return (
    <div>
      <h3 className={styles.title}>General Information</h3>
      <Row gutter={[32, 0]}>
        <Col span={6}>
          <TextBlock label="Payer address">
            <span>{customsDeclaration?.sold_to?.address_lines?.join(', ')}</span>
            <span>{getAddress(customsDeclaration)}</span>
          </TextBlock>
        </Col>
        <Col span={18}>
          <Row gutter={[8, 24]}>
            <Col span={6}>
              <TextBlock label="Content type" value={humanize(customsDeclaration?.contents_type)} />
            </Col>
            <Col span={6}>
              <TextBlock
                label="Content explanation"
                value={customsDeclaration?.contents_explanation}
              />
            </Col>
            <Col span={6}>
              <TextBlock label="Incoterms" value={customsDeclaration?.incoterms} />
            </Col>
            <Col span={6}>
              <TextBlock label="Currency" value={customsDeclaration?.currency} />
            </Col>
            <Col span={6}>
              <TextBlock label="Invoice number" value={customsDeclaration?.invoice_number} />
            </Col>
            <Col span={6}>
              <TextBlock
                label="Invoice date"
                value={
                  customsDeclaration?.invoice_date &&
                  moment(customsDeclaration?.invoice_date).format('DD MMM YYYY')
                }
              />
            </Col>
            <Col span={6}>
              <TextBlock
                label="Seller tax id number"
                value={customsDeclaration?.seller_tax_id_number}
              />
            </Col>
            <Col span={6}>
              <TextBlock
                label="Buyer tax id number"
                value={customsDeclaration?.buyer_tax_id_number}
              />
            </Col>
            <Col span={6}>
              <TextBlock label="Payer's name" value={customsDeclaration?.sold_to?.name} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const styles = {
  title: tss({ marginBottom: '16px' }),
  textblock: {
    container: tss({
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    }),
    label: tss({
      color: '#555555',
    }),
    value: tss({
      color: 'black',
    }),
  },
};
