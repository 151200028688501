import * as React from 'react';
import { Tabs } from 'antd';
import { Modal } from '@src/controls';
import { useSelector } from 'react-redux';
import { setVariantSiteId } from '@src/modules/config/actions';
import { useDispatch } from '@src/utils/hooks';
import { resetWidgetSession } from '@src/modules/siw/actions';
import { getSelectedMerchantId } from '@src/modules/merchants/merchants-selectors';

import { RegionsListEmbedded } from '@src/containers/regions';
import { CountryListEmbedded } from '@src/containers/features';
import { WarehouseListEmbedded } from '@src/containers/warehouse';
import { CheckoutWidgetConfiguration } from '@src/containers/checkout-widget';
import { VariantModel } from '@src/models';
import { sitesActions, SitesSelectors } from '@src/modules/sites';
import { useExperimentQuery } from './use-experiment-query';

interface OwnProps {
  variant?: VariantModel;
  experimentId: string;
  onClose: () => void;
}

export const VariantEditModal: React.FC<OwnProps> = ({ variant, onClose, experimentId }) => {
  const dispatch = useDispatch();

  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);
  const merchantId = useSelector(getSelectedMerchantId);

  const [query] = useExperimentQuery(siteId, experimentId);

  React.useEffect(() => {
    if (!variant) {
      return;
    }

    dispatch(setVariantSiteId({ siteId: variant.variantSiteId }));
    dispatch(resetWidgetSession());
    dispatch(sitesActions.getSitesRequest({ merchantId, siteId: variant.variantSiteId }));

    return () => {
      dispatch(setVariantSiteId({ siteId: null }));
      dispatch(resetWidgetSession());
      dispatch(sitesActions.getSitesRequest({ merchantId, siteId }));
    };
  }, [variant]);

  if (!variant) {
    return null;
  }

  return (
    <Modal
      visible
      onCancel={onClose}
      width="900px"
      footer={null}
      title={`Edit variant "${variant.name}"`}
    >
      <Tabs>
        <Tabs.TabPane tab="Regions" key="1">
          <RegionsListEmbedded countryToFilterBy={query.data?.country} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Checkout Features" key="2">
          <CountryListEmbedded countryToFilterBy={query.data?.country} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Warehouses" key="3">
          <WarehouseListEmbedded countryToFilterBy={query.data?.country} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Test widget" key="4">
          <CheckoutWidgetConfiguration />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};
