import { ContactModel } from '@src/models';
import { FieldState, FormState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Input } from '../controls';
import { withFieldStateInput, withFormItem } from '../decorators';
import { CountrySelectorForm, CountrySelectorFormState } from '../forms';
import { formFieldLayout } from '../forms/form-layouts';
import { requiredFieldWithMessage } from '../utils/validation';

const InputField = withFormItem(withFieldStateInput(Input));

export class ShippingAddressFormState extends FormState<{
  nameFieldState: FieldState<string>;
  addressLine1FieldState: FieldState<string>;
  addressLine2FieldState: FieldState<string>;
  addressLine3FieldState: FieldState<string>;
  regionFieldState: FieldState<string>;
  postalCodeFieldState: FieldState<string>;
  cityFieldState: FieldState<string>;
  countrySelectorFormState: CountrySelectorFormState;
  emailFieldState: FieldState<string>;
  phoneNumberFieldState: FieldState<string>;
}> {
  static create = (model: Partial<ContactModel> = {}): ShippingAddressFormState => {
    const country = (model.address && model.address.country) || '';
    const addressLines = (model.address && model.address.addressLines) || [];
    return new FormState({
      nameFieldState: new FieldState((model.address && model.address!.name) || '').validators(
        requiredFieldWithMessage('Field is required')
      ),
      addressLine1FieldState: new FieldState(addressLines[0] || '').validators(
        requiredFieldWithMessage('Field is required')
      ),
      addressLine2FieldState: new FieldState(
        (addressLines.length > 0 && addressLines[1]) || ''
      ).validators(),
      addressLine3FieldState: new FieldState(
        (addressLines.length > 1 && addressLines[2]) || ''
      ).validators(),
      regionFieldState: new FieldState((model.address && model.address!.region) || '').validators(),
      postalCodeFieldState: new FieldState(
        (model.address && model.address!.postalCode) || ''
      ).validators(),
      cityFieldState: new FieldState((model.address && model.address!.city) || '').validators(
        requiredFieldWithMessage('Field is required')
      ),
      countrySelectorFormState: CountrySelectorFormState.create(country),
      emailFieldState: new FieldState(model.email || '').validators(),
      phoneNumberFieldState: new FieldState(model.phone || '').validators(),
    });
  };
}

// COMPONENT

type Props = {
  formState: ShippingAddressFormState;
  autoFocus?: boolean;
};

@observer
export class ShippingAddressForm extends React.Component<Props, {}> {
  render() {
    const { autoFocus } = this.props;
    const {
      nameFieldState,
      addressLine1FieldState,
      addressLine2FieldState,
      addressLine3FieldState,
      regionFieldState,
      postalCodeFieldState,
      cityFieldState,
      countrySelectorFormState,
      emailFieldState,
      phoneNumberFieldState,
    } = this.props.formState.$;
    return (
      <form>
        <InputField
          required={true}
          autoFocus={autoFocus}
          label="Name"
          name="Name"
          fieldState={nameFieldState}
          error={nameFieldState.error}
          {...formFieldLayout}
        />
        <InputField
          required={true}
          autoFocus={autoFocus}
          label="Address line 1"
          name="Address line 1"
          fieldState={addressLine1FieldState}
          error={addressLine1FieldState.error}
          {...formFieldLayout}
        />
        <InputField
          required={false}
          autoFocus={autoFocus}
          label="Address line 2"
          name="Address line 2"
          fieldState={addressLine2FieldState}
          error={addressLine2FieldState.error}
          {...formFieldLayout}
        />
        <InputField
          required={false}
          autoFocus={autoFocus}
          label="Address line 3"
          name="Address line 3"
          fieldState={addressLine3FieldState}
          error={addressLine3FieldState.error}
          {...formFieldLayout}
        />
        <InputField
          required={false}
          autoFocus={autoFocus}
          label="Region"
          name="Region"
          fieldState={regionFieldState}
          error={regionFieldState.error}
          {...formFieldLayout}
        />
        <InputField
          required={false}
          autoFocus={autoFocus}
          label="Postal code"
          name="Postal code"
          fieldState={postalCodeFieldState}
          error={postalCodeFieldState.error}
          {...formFieldLayout}
        />
        <InputField
          required={true}
          autoFocus={autoFocus}
          label="City"
          name="City"
          fieldState={cityFieldState}
          error={cityFieldState.error}
          {...formFieldLayout}
        />
        <CountrySelectorForm required={true} formState={countrySelectorFormState} />
        <InputField
          autoFocus={autoFocus}
          label="E-mail"
          name="E-mail"
          fieldState={emailFieldState}
          error={emailFieldState.error}
          {...formFieldLayout}
        />
        <InputField
          autoFocus={autoFocus}
          label="Phone number"
          name="Phone number"
          fieldState={phoneNumberFieldState}
          error={phoneNumberFieldState.error}
          {...formFieldLayout}
        />
      </form>
    );
  }
}
