import * as React from 'react';
import { classes, style as tss } from 'typestyle';

import { Form } from '@src/controls';
import { defaultTheme } from '@src/styles';

export const AutoSaveForm: React.FunctionComponent<{
  className?: string;
  onFinish?: (e?: React.FormEvent<HTMLFormElement>) => void;
}> = ({ children, className, onFinish }) => (
  <Form layout="horizontal" className={classes(formStyle, className)} onFinish={onFinish}>
    {children}
  </Form>
);

export const inputStyle = tss({
  backgroundColor: 'hsla(0, 0%, 50%, 0.05)',
  border: 'unset',
  borderRadius: '3px',
  cursor: 'pointer',
  $nest: {
    input: {
      border: 'unset',
    },
    '&:hover, & input:hover': {
      backgroundColor: `${defaultTheme.color.white}`,
      borderRadius: '4px',
      border: 'inherit',
      cursor: 'pointer',
    },
    '&:active, &:focus, & input:active, & input:hover': {
      backgroundColor: `${defaultTheme.color.white}`,
      border: 'inherit',
      borderRadius: '4px',
      boxShadow: `0px 2px 8px ${defaultTheme.regionForm.color.boxShadow}`,
    },
  },
});

export const formStyle = tss({
  $nest: {
    '.ant-form-item': {
      margin: '0 0 8px 0',
    },
    '.ant-form-item-label': {
      textAlign: 'start',
    },
    '.ant-legacy-form-item .ant-legacy-form-item-control-wrapper .ant-legacy-form-item-control': {
      lineHeight: 'unset',
    },
    '.ant-legacy-form-item .ant-col': {
      lineHeight: '32px',
    },
    '.ant-switch': {
      marginLeft: '11px',
    },
    '.ant-form-item.ant-form-item-has-error': {
      $nest: {
        input: {
          background: '#f5222d0d',
          border: '1px solid #f5222d',
          borderRadius: '3px',
        },
        '.ant-select': {
          background: '#f5222d0d',
          border: '1px solid #f5222d',
          borderRadius: '3px',
          $nest: {
            input: {
              background: 'inherit',
              border: 'inherit',
            },
          },
        },
        '.ant-form-explain': {
          paddingLeft: '11px',
          fontSize: '12px',
          marginTop: '4px',
          marginBottom: '4px',
        },
        '.ant-form-explain::first-letter': {
          textTransform: 'uppercase',
        },
        '.ant-form-item-children-icon': {
          display: 'none',
        },
      },
    },
    label: { color: defaultTheme.regionForm.color.label },
    '& .ant-input-disabled, & .ant-input-number-disabled, & .ant-input[disabled]': {
      backgroundColor: 'inherit',
      color: 'rgba(0, 0, 0, 0.4)',
      $nest: {
        '&:active, &:focus, &:hover': {
          backgroundColor: 'unset',
          borderRadius: 'unset',
          border: 'unset',
          cursor: 'default',
          boxShadow: 'unset',
        },
      },
    },
    '.ant-select .ant-select-selector': {
      backgroundColor: 'unset',
      border: 'unset',
      $nest: {
        '&:active, &:focus, &:hover': {
          backgroundColor: 'unset',
          borderRadius: 'unset',
          border: 'unset',
          cursor: 'default',
          boxShadow: 'unset',
        },
      },
    },
    '.ant-select-multiple .ant-select-selection-item': {
      background: '#FAFAFA',
    },
  },
});

const commonLayout = {
  labelCol: { span: 4 },
  colon: false,
};

export const smallLabelLayout = {
  colon: false,
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};

export const wideLayout = {
  colon: false,
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const regionFormContainerLayout = {
  colon: false,
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

export const regionFormItemLayout = {
  ...commonLayout,
  wrapperCol: { span: 12 },
};

export const mediumLayout = {
  colon: false,
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

export const widerLayout = {
  labelCol: { span: 6 },
  colon: false,
  wrapperCol: { span: 18 },
};

export const bigLabelLayout = {
  labelCol: { span: 8 },
  colon: false,
  wrapperCol: { span: 16 },
};

export const wideLabelLayout = {
  labelCol: { span: 10 },
  colon: false,
  wrapperCol: { span: 14 },
};

export const biggerLabelLayout = {
  labelCol: { span: 15 },
  colon: false,
  wrapperCol: { span: 8 },
};

export const noLabelLayout = {
  labelCol: { span: 0 },
  colon: false,
  wrapperCol: { span: 24 },
};

export const rowStyle = tss({
  alignItems: 'center',
  display: 'flex',
});
