import { filter, isEmpty, isNil } from 'ramda';
import { createSelector } from 'reselect';

import { SiteTagModel } from '@src/models';
import { RootState } from '../root-reducer';

const getState = (state: RootState) => state.tags;

const filterOutUserTags = filter<SiteTagModel>(tag => tag.name !== 'user tag');

export const getUserTags = createSelector(getState, state => state.userTags);
export const getSiteTags = createSelector(getState, state =>
  state.siteTags.filter(tag => isNil(tag.userId))
);
export const getUserTagsWithNoPersonalTag = createSelector(getState, getUserTags, (state, tags) =>
  filterOutUserTags(tags)
);

export const getUserTagOptions = createSelector(getUserTagsWithNoPersonalTag, tags =>
  tags.map(tag => ({ value: tag.id, label: tag.name }))
);

export const getSiteTagOptions = createSelector(getSiteTags, tags =>
  tags.map(tag => ({ value: tag.id, label: tag.name }))
);

export const getUserPersonalTag = createSelector(getState, tags =>
  tags.userTags.find(tag => tag.name === 'user tag')
);

export const getShipmentIdsByTagId = (state: RootState, tagId: string) => {
  const possibleTaggedShipmentIds = state.tags.taggedShipments[tagId];
  return possibleTaggedShipmentIds ? possibleTaggedShipmentIds : [];
};

export const getShipmentsByTagId = (state: RootState, tagId: string) =>
  !isEmpty(state.tags.shipmentsByTagId) ? state.tags.shipmentsByTagId[tagId] || [] : [];

export const getUserTagsWithUserPersonalTagFirst = createSelector(getUserTags, userTags => [
  ...userTags.filter(tag => tag.name === 'user tag'),
  ...userTags.filter(tag => tag.name !== 'user tag'),
]);

export const getSiteTagsForUser = createSelector(getState, state =>
  filterOutUserTags(state.siteTagsForUserEdit).map(tag => tag.id)
);
