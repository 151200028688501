import { getEnvironmentalVariables } from '@src/services/env-service';
import { MAD_TEST_HOST } from './constants';

const env = getEnvironmentalVariables();

export function resolveWithDelay<T>(value: T, time: number = 1000) {
  return new Promise<T>(resolve => {
    setTimeout(() => resolve(value), time);
  });
}

export const getApiUrl = () => {
  if (process.env.NODE_ENV === 'test') {
    return MAD_TEST_HOST;
  }

  switch (env.MAD_API_ENV) {
    case 'production':
      return 'https://api.ingrid.com/v1';

    case 'stage':
      return 'https://api-stage.ingrid.com/v1';

    case 'development':
    default:
      return 'https://api-development.ingrid.com/v1';
  }
};

export const getRedirectionUrl = () => {
  return env.MAD_REDIRECT_URL || 'http://localhost:4000';
};

export const getSIWUrl = () => {
  switch (env.MAD_API_ENV) {
    case 'production':
      return 'https://api.ingrid.com/v1/siw';

    case 'stage':
      return 'https://api-stage.ingrid.com/v1/siw';

    case 'development':
    default:
      return 'https://api-development.ingrid.com/v1/siw';
  }
};
