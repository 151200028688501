import * as moment from 'moment';

type DateFormat = 'date' | 'datetime';

function formatObjectAsDateTime(date: Date): string {
  return moment(date, moment.ISO_8601).format('DD/MM/YYYY, HH:mm');
}

function formatObjectAsDate(date: Date): string {
  return date.toLocaleDateString();
}

// PUBLIC

export function parseIsoDate(isoDate: string | undefined): Date | null {
  if (isoDate == null || isNaN(Date.parse(isoDate))) {
    return null;
  }

  return new Date(isoDate);
}

export function formatTimestamp(timestamp: number | undefined): string | null {
  if (timestamp == null) {
    return null;
  }

  return formatObjectAsDateTime(new Date(timestamp * 1000));
}

export function formatIsoDate(
  isoDate: string | undefined,
  dateFormat: DateFormat = 'datetime'
): string | null {
  const date = parseIsoDate(isoDate);

  switch (dateFormat) {
    case 'datetime':
      return date && formatObjectAsDateTime(date);

    case 'date':
      return date && formatObjectAsDate(date);

    default:
      throw new Error('unknown date format!');
  }
}

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;

export function isTheSameDay(d1: Date, d2: Date) {
  if (d1 > d2) {
    return d1.getTime() - d2.getTime() < DAY;
  }
  return d2.getTime() - d1.getTime() < DAY;
}
