import { FormattedMessage } from '@src/i18n';
import * as React from 'react';
import { style as tss } from 'typestyle';

interface Props {
  list: string[];
}

export const LimitedNumberOfItems: React.FunctionComponent<Props> = ({ list }) => {
  const LIMIT = 5;
  return list.length > LIMIT ? (
    <span>
      {list.slice(0, LIMIT).join(', ')}&nbsp;
      <span
        className={tss({
          color: 'rgba(0, 0, 0, 0.5)',
        })}
      >
        <FormattedMessage id="AND" />
        &nbsp;{list.length - LIMIT}&nbsp;
        <FormattedMessage id="MORE" />
      </span>
    </span>
  ) : (
    <span>{list.join(', ')}</span>
  );
};
