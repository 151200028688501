import { useFormatMessage } from '@src/i18n';
import { Badge, Popover } from 'antd';
import React from 'react';
import { ChannelsSettings } from '@src/modules/delivery-tracking-notifications/notifications-channels/data-model';
import { stylesheet } from 'typestyle';
import { useGetNotificationsFeatures } from '@src/modules/delivery-tracking-notifications/notifications-features/api';

export const useGetChannelData = () => {
  const { data, isLoading, isFetching } = useGetNotificationsFeatures();

  const formatMessage = useFormatMessage();
  const channelsData: ChannelsSettings = { email: [], sms: [] };

  const isDataLoading = isLoading || isFetching;

  if (data?.channels_properties.email_properties) {
    channelsData.email = [
      {
        key: `status-email`,
        name: formatMessage('NOTIFICATIONS.CHANNELS.STATUS'),
        details: (
          <Badge
            status={data.channels_properties.email_properties.enabled ? 'success' : 'error'}
            text={
              <Popover
                overlayClassName={styles.popover}
                content={formatMessage('NOTIFICATIONS.CHANNELS.POPOVER_MESSAGE')}
                placement="right"
              >
                {data.channels_properties.email_properties.enabled
                  ? formatMessage('NOTIFICATIONS.CHANNELS.STATUS.ENABLED')
                  : formatMessage('NOTIFICATIONS.CHANNELS.STATUS.DISABLED')}
              </Popover>
            }
          />
        ),
      },
      {
        key: `sender-name-email`,
        name: formatMessage('NOTIFICATIONS.CHANNELS.SENDER_NAME', { value: 'email' }),
        details: data.channels_properties.email_properties?.sender_name,
      },
      {
        key: `sender-email-address`,
        name: formatMessage('NOTIFICATIONS.CHANNELS.SENDER_ADDRESS'),
        details: data.channels_properties.email_properties?.sender_address,
      },
    ];
  }

  if (data?.channels_properties.sms_properties) {
    channelsData.sms = [
      {
        key: `status-sms`,
        name: formatMessage('NOTIFICATIONS.CHANNELS.STATUS'),
        details: (
          <Badge
            status={data.channels_properties.sms_properties?.enabled ? 'success' : 'error'}
            text={
              <Popover
                overlayClassName={styles.popover}
                content={formatMessage('NOTIFICATIONS.CHANNELS.POPOVER_MESSAGE')}
                placement="right"
              >
                {data.channels_properties.sms_properties?.enabled
                  ? formatMessage('NOTIFICATIONS.CHANNELS.STATUS.ENABLED')
                  : formatMessage('NOTIFICATIONS.CHANNELS.STATUS.DISABLED')}
              </Popover>
            }
          />
        ),
      },
      {
        key: `sender-name-sms`,
        name: formatMessage('NOTIFICATIONS.CHANNELS.SENDER_NAME', { value: 'SMS' }),
        details: data.channels_properties.sms_properties?.sender_name,
      },
    ];
  }

  return { channelsData, isDataLoading };
};

const styles = stylesheet({
  popover: {
    $nest: {
      '.ant-popover-inner-content': {
        width: '315px',
        whiteSpace: 'break-spaces',
      },
    },
  },
});
