import * as React from 'react';
import { Modal } from '@src/controls';
import { VariantModel } from '@src/models';

interface OwnProps {
  variant?: VariantModel;
  onClose: () => void;
}

export const VariantChangesModal: React.FC<OwnProps> = ({ variant, onClose }) => {
  if (!variant) {
    return null;
  }

  return (
    <Modal
      visible
      onCancel={onClose}
      width="900px"
      footer={null}
      title={`Changes in "${variant.name}"`}
    >
      <pre>{variant.diff.text}</pre>
    </Modal>
  );
};
