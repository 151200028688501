import { RootState } from '@src/modules';
import { configSelectors } from '@src/modules/config';
import * as React from 'react';
import { connect } from 'react-redux';
import { Option } from 'space-lift';
import { Column, Input, Text } from '../../../controls';
import { withFormItem } from '../../../decorators';
import { MetadataModel } from '../../../models';
import { dateUtils } from '../../../utils';

const TextField = withFormItem(Text);

interface IProps {
  metadata: MetadataModel | undefined;
  // omit label which can be string | JSX.Element and make it string always
  shippingMethodsOptions: { value: string; label: string }[];
}

class MetadataFormBase extends React.Component<IProps, {}> {
  render() {
    const { metadata, shippingMethodsOptions } = this.props;
    if (!metadata || !metadata.session) {
      return null;
    }

    const sessionID = metadata.session.sessionId && (
      <TextField label="Session ID">
        <Input readOnly={true} value={metadata.session.sessionId} />
      </TextField>
    );

    const method = metadata.session.method && (
      <TextField label="Carrier Product">
        <Input
          readOnly={true}
          value={Option(metadata.session.method)
            .flatMap(m => Option(shippingMethodsOptions.find(o => o.value === m)))
            .map(o => o.label)
            .getOrElse(' - ')}
        />
      </TextField>
    );

    const timeslot = metadata.session.timeSlot.start && (
      <TextField label="Time slot">
        <Input
          readOnly={true}
          value={[
            dateUtils.formatIsoDate(metadata.session.timeSlot.start, 'date'),
            ' - ',
            dateUtils.formatIsoDate(metadata.session.timeSlot.end, 'date'),
          ].join(' ')}
        />
      </TextField>
    );

    if (!sessionID && !method && !timeslot) {
      return null;
    }

    return (
      <Column justifyContent="flex-end" style={{ width: '50%' }}>
        {sessionID}
        {method}
        {timeslot}
      </Column>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  shippingMethodsOptions: configSelectors.getShippingMethodsOptions(state),
});

export const MetadataForm = connect(mapStateToProps, {})(MetadataFormBase);
