import { Action } from 'redux';
import { BEGIN, COMMIT, REVERT } from 'redux-optimistic-ui';
import { deprecated, createAction as newCreateAction } from 'typesafe-actions';
const { createAction } = deprecated;

import {
  AddCategoryTagsModel,
  AdjustDeliveryAddonsOrderModel,
  ConfigChangeModel,
  ConfigDraftModel,
  ConfigSiteModel,
  ConfigWarehouseModel,
  CreateCarrierProductModel,
  CreateCategoryLabelModel,
  CreateCategoryModel,
  CreateDeliveryAddonModel,
  CreateOrUpdateWarehouseCutoffTimesModel,
  CreateRegion,
  CreateWarehouseCutoffTimesModel,
  CreateWarehouseModel,
  DeleteCarrierProductModel,
  DeleteCategoryLabelModel,
  DeleteCategoryLabelTranslationsModel,
  DeleteDeliveryAddonModel,
  DeleteDeliveryAddonTranslationModel,
  DeleteTranslationModel,
  DeleteWarehouseCutoffTimesModel,
  ExtendedShippingMethodModel,
  GetPrivateKeyModel,
  RemoveCategoryTagsModel,
  ShippingDateAdjustmentModel,
  ShippingMethodCreateResponseStatus,
  UpdateCarrierProductFilterRulesModel,
  UpdateCarrierProductModel,
  UpdateCategoryDetailsModel,
  UpdateCategoryFilterRulesModel,
  UpdateCategoryLabelDetailsModel,
  UpdateCategoryLabelsOrderModel,
  UpdateCategoryLabelTranslationsModel,
  UpdateCategoryTranslationsModel,
  UpdateConfigRegionModel,
  UpdateDeliveryAddonModel,
  UpdatePreselectionOrderModel,
  UpdateWarehouseCarrierCutoffModel,
  UpdateWarehouseCutoffTimesModel,
  UpdateWarehouseDetailsModel,
  UpdateWidgetCountryConfigurationModel,
  UpsertDeliveryAddonTranslationModel,
  WidgetConfigurationModel,
} from '@src/models';
import { getRandomNumber } from '@src/utils/number';
import { createOptimisticAction } from '@src/utils/optimistic-updates';

export const updateCutoffTimesRequest = createOptimisticAction(
  '@config/UPDATE_CUTOFF_TIMES_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      model: UpdateWarehouseCutoffTimesModel;
      siteId: string;
      onComplete?: (error: string) => void;
    }) =>
      resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() }, onComplete })
);
export const updateCutoffTimesSuccess = createOptimisticAction(
  '@config/UPDATE_CUTOFF_TIMES_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const updateCutoffTimesError = createOptimisticAction(
  '@config/UPDATE_CUTOFF_TIMES_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const updateCarrierCutoffRequest = createOptimisticAction(
  '@config/UPDATE_CARRIER_CUTOFF_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      model: UpdateWarehouseCarrierCutoffModel;
      siteId: string;
      onComplete?: (error: string) => void;
    }) =>
      resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() }, onComplete })
);
export const updateCarrierCutoffSuccess = createOptimisticAction(
  '@config/UPDATE_CARRIER_CUTOFF_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const updateCarrierCutoffError = createOptimisticAction(
  '@config/UPDATE_CARRIER_CUTOFF_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const updateCarrierProductRequest = createOptimisticAction(
  '@config/UPDATE_CARRIER_PRODUCT_REQUEST',
  resolve => (payload: { model: UpdateCarrierProductModel; siteId: string }) =>
    resolve(payload, {
      optimistic: {
        type: BEGIN,
        id: getRandomNumber(),
      },
    })
);
export const updateCarrierProductSuccess = createOptimisticAction(
  '@config/UPDATE_CARRIER_PRODUCT_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const updateCarrierProductError = createOptimisticAction(
  '@config/UPDATE_CARRIER_PRODUCT_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, {
        optimistic: {
          type: REVERT,
          id: transactionId,
        },
      })
);

export const createDraftRequest = createAction(
  '@config/CREATE_DRAFT_REQUEST',
  resolve => (args: { onComplete: (draftSiteId: string) => Action<any> }) =>
    resolve(undefined, { onComplete: args.onComplete })
);
export const createDraftError = createAction(
  '@config/CREATE_DRAFT_ERROR',
  resolve => (error: string) => resolve(error)
);
export const createDraftSuccess = createAction(
  '@config/CREATE_DRAFT_SUCCESS',
  resolve => (draft: ConfigDraftModel) => resolve(draft)
);

export const deleteDraftRequest = createAction('@config/DELETE_DRAFT_REQUEST');
export const deleteDraftSuccess = createAction(
  '@config/DELETE_DRAFT_SUCCESS',
  resolve => (draftSiteId: string) => resolve(draftSiteId)
);
export const deleteDraftError = createAction(
  '@config/DELETE_DRAFT_ERROR',
  resolve => (error: string) => resolve(error)
);

export const promoteDraftRequest = createAction('@config/PROMOTE_DRAFT_REQUEST');
export const promoteDraftSuccess = newCreateAction('@config/PROMOTE_DRAFT_SUCCESS')<string>();
export const promoteDraftError = createAction(
  '@config/PROMOTE_DRAFT_ERROR',
  resolve => (error: string) => resolve(error)
);

export const getDraftSiteRequest = createAction('@config/GET_DRAFT_SITE_REQUEST');
export const getDraftSiteError = createAction('@config/GET_DRAFT_SITE_ERROR');
export const getDraftSiteSuccess = createAction(
  '@config/GET_DRAFT_SITE_SUCCESS',
  resolve => (draft: ConfigDraftModel) => resolve(draft)
);

export const updateRegionDetailRequest = createOptimisticAction(
  '@config/UPDATE_REGION_DETAIL_REQUEST',
  resolve => (payload: { siteId: string; model: UpdateConfigRegionModel }) =>
    resolve(payload, {
      optimistic: {
        type: BEGIN,
        id: getRandomNumber(),
      },
    })
);
export const updateRegionDetailSuccess = createOptimisticAction(
  '@config/UPDATE_REGION_DETAIL_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const updateRegionDetailError = createOptimisticAction(
  '@config/UPDATE_REGION_DETAIL_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const updateCategoryFilterRulesRequest = createOptimisticAction(
  '@config/UPDATE_CATEGORY_FILTER_RULES_REQUEST',
  resolve => (payload: { siteId: string; model: UpdateCategoryFilterRulesModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);

export const updateCategoryFilterRulesSuccess = createOptimisticAction(
  '@config/UPDATE_CATEGORY_FILTER_RULES_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);

export const updateCategoryFilterRulesError = createOptimisticAction(
  '@config/UPDATE_CATEGORY_FILTER_RULES_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const updateCategoryDetailsRequest = createOptimisticAction(
  '@config/UPDATE_CATEGORY_DETAILS_REQUEST',
  resolve => (payload: { siteId: string; model: UpdateCategoryDetailsModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const updateCategoryDetailsSuccess = createOptimisticAction(
  '@config/UPDATE_CATEGORY_DETAILS_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const updateCategoryDetailsError = createOptimisticAction(
  '@config/UPDATE_CATEGORY_DETAILS_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const updateCountrySettingsRequest = createOptimisticAction(
  '@config/UPDATE_COUNTRY_SETTINGS_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      model: UpdatePreselectionOrderModel;
      onComplete: () => void;
    }) =>
      resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() }, onComplete })
);

export const updateCountrySettingsSuccess = createOptimisticAction(
  '@config/UPDATE_COUNTRY_SETTINGS_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const updateCountrySettingsError = createOptimisticAction(
  '@config/UPDATE_COUNTRY_SETTINGS_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const updateCategoryTranslationsRequest = createOptimisticAction(
  '@config/UPDATE_CATEGORY_TRANSLATIONS_REQUEST',
  resolve => (payload: { siteId: string; model: UpdateCategoryTranslationsModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const updateCategoryTranslationsSuccess = createOptimisticAction(
  '@config/UPDATE_CATEGORY_TRANSLATIONS_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const updateCategoryTranslationsError = createOptimisticAction(
  '@config/UPDATE_CATEGORY_TRANSLATIONS_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const deleteCategoryRequest = createOptimisticAction(
  '@config/DELETE_CATEGORY_REQUEST',
  resolve => (payload: { siteId: string; categoryId: string }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const deleteCategorySuccess = createOptimisticAction(
  '@config/DELETE_CATEGORY_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const deleteCategoryError = createOptimisticAction(
  '@config/DELETE_CATEGORY_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const getChangesListRequest = createAction(
  '@config/GET_CHANGES_LIST_REQUEST',
  resolve => (payload: { onComplete: (ids: string[]) => Action<any> }) =>
    resolve({ onComplete: payload.onComplete })
);
export const getChangesListSuccess = createAction(
  '@config/GET_CHANGES_LIST_SUCCESS',
  resolve => (payload: { changes: ConfigChangeModel[] }) => resolve(payload)
);
export const getChangesListError = createAction(
  '@config/GET_CHANGES_LIST_ERROR',
  resolve => (error: string) => resolve(error)
);

export const updateWarehouseDetailsRequest = createOptimisticAction(
  '@config/UPDATE_WAREHOUSE_DETAILS_REQUEST',
  resolve => (payload: { siteId: string; model: UpdateWarehouseDetailsModel }) =>
    resolve(payload, {
      optimistic: { type: BEGIN, id: getRandomNumber() },
    })
);
export const updateWarehouseDetailsSuccess = createOptimisticAction(
  '@config/UPDATE_WAREHOUSE_DETAILS_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const updateWarehouseDetailsError = createOptimisticAction(
  '@config/UPDATE_WAREHOUSE_DETAILS_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const deleteCategoryTranslationsRequest = createOptimisticAction(
  '@config/DELETE_CATEGORY_TRANSLATION_REQUEST',
  resolve => (payload: { siteId: string; model: DeleteTranslationModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const deleteCategoryTranslationsSuccess = createOptimisticAction(
  '@config/DELETE_CATEGORY_TRANSLATION_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const deleteCategoryTranslationsError = createOptimisticAction(
  '@config/DELETE_CATEGORY_TRANSLATIONS_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const addCategoryTagsRequest = createOptimisticAction(
  '@config/CREATE_CATEGORY_TAGS_REQUEST',
  resolve => (payload: { siteId: string; model: AddCategoryTagsModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);

export const addCategoryTagsSuccess = createOptimisticAction(
  '@config/CREATE_CATEGORY_TAGS_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);

export const addCategoryTagsError = createOptimisticAction(
  '@config/CREATE_CATEGORY_TAGS_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const createCategoryRequest = createAction(
  '@config/CREATE_CATEGORY_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      model: CreateCategoryModel;
      onComplete?: (id: string) => void;
    }) =>
      resolve(payload, { onComplete })
);
export const createCategorySuccess = createAction(
  '@config/CREATE_CATEGORY_SUCCESS',
  resolve => (payload: { model: CreateCategoryModel & { id: string }; siteId: string }) =>
    resolve(payload)
);
export const createCategoryError = createAction(
  '@config/CREATE_CATEGORY_ERROR',
  resolve => (error: string) => resolve(error)
);

export const createCarrierProductRequest = createAction(
  '@config/CREATE_CARRIER_PRODUCT_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      model: CreateCarrierProductModel;
      onComplete: () => void;
    }) =>
      resolve(payload, { onComplete })
);
export const createCarrierProductSuccess = createAction(
  '@config/CREATE_CARRIER_PRODUCT_SUCCESS',
  resolve =>
    (payload: {
      siteId: string;
      model: CreateCarrierProductModel & { status: ShippingMethodCreateResponseStatus };
    }) =>
      resolve(payload)
);
export const createCarrierProductError = createAction(
  '@config/CREATE_CARRIER_PRODUCT_ERROR',
  resolve => (error: string) => resolve(error)
);

export const updateCarrierProductFilterRulesRequest = createOptimisticAction(
  '@config/UPDATE_CARRIER_PRODUCT_FILTER_RULES_REQUEST',
  resolve => (payload: { siteId: string; model: UpdateCarrierProductFilterRulesModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);

export const updateCarrierProductFilterRulesSuccess = createOptimisticAction(
  '@config/UPDATE_CARRIER_PRODUCT_FILTER_RULES_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);

export const updateCarrierProductFilterRulesError = createOptimisticAction(
  '@config/UPDATE_CARRIER_PRODUCT_FILTER_RULES_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const deleteCarrierProductRequest = createOptimisticAction(
  '@config/DELETE_CARRIER_PRODUCT_REQUEST',
  resolve => (payload: { model: DeleteCarrierProductModel; siteId: string }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const deleteCarrierProductSuccess = createOptimisticAction(
  '@config/DELETE_CARRIER_PRODUCT_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const deleteCarrierProductError = createOptimisticAction(
  '@config/DELETE_CARRIER_PRODUCT_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const removeCategoryTagsRequest = createOptimisticAction(
  '@config/DELETE_CATEGORY_TAGS_REQUEST',
  resolve => (payload: { model: RemoveCategoryTagsModel; siteId: string }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const removeCategoryTagsSuccess = createOptimisticAction(
  '@config/DELETE_CATEGORY_TAGS_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const removeCategoryTagsError = createOptimisticAction(
  '@config/DELETE_CATEGORY_TAGS_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const createWarehouseRequest = createAction(
  '@config/CREATE_WAREHOUSE_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      model: CreateWarehouseModel;
      onComplete?: (id: string) => void;
    }) =>
      resolve(payload, { onComplete })
);
export const createWarehouseSuccess = createAction(
  '@config/CREATE_WAREHOUSE_SUCCESS',
  resolve => (payload: { model: Omit<ConfigWarehouseModel, 'createdAt'>; siteId: string }) =>
    resolve(payload)
);

export const createWarehouseError = createAction(
  '@config/CREATE_WAREHOUSE_ERROR',
  resolve => (error: string) => resolve(error)
);

export const deleteWarehouseRequest = createAction(
  '@config/DELETE_WAREHOUSE_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      warehouseId: string;
      onComplete?: () => void;
    }) =>
      resolve(payload, { onComplete })
);

export const deleteWarehouseSuccess = createAction(
  '@config/DELETE_WAREHOUSE_SUCCESS',
  resolve => (payload: { warehouseId: string; siteId: string }) => resolve(payload)
);

export const deleteWarehouseError = createAction(
  '@config/DELETE_WAREHOUSE_ERROR',
  resolve => (error: string) => resolve(error)
);

export const createWarehouseCutoffTimesRequest = createOptimisticAction(
  '@config/CREATE_WAREHOUSE_CUTOFF_TIMES_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      model: CreateWarehouseCutoffTimesModel;
      onComplete: (error: string) => void;
    }) =>
      resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() }, onComplete })
);

export const createWarehouseCutoffTimesSuccess = createOptimisticAction(
  '@config/CREATE_WAREHOUSE_CUTOFF_TIMES_SUCCESS',
  resolve =>
    ({ model, transactionId }: { model: CreateWarehouseCutoffTimesModel; transactionId: number }) =>
      resolve(model, { optimistic: { type: COMMIT, id: transactionId } })
);

export const createWarehouseCutoffTimesError = createOptimisticAction(
  '@config/CREATE_WAREHOUSE_CUTOFF_TIMES_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const createRegionRequest = createAction(
  '@config/CREATE_REGION_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      model: CreateRegion['model'];
      onComplete: (regionId: string) => void;
    }) =>
      resolve(payload, { onComplete })
);
export const createRegionSuccess = createAction(
  '@config/CREATE_REGION_SUCCESS',
  resolve => (payload: { siteId: string; regionId: string }) => resolve(payload)
);
export const createRegionError = createAction(
  '@config/CREATE_REGION_ERROR',
  resolve => (error: string) => resolve(error)
);

export const getSiteRequest = createAction(
  '@config/GET_SITE_REQUEST',
  resolve =>
    ({ onComplete, ...payload }: { siteId: string; onComplete: () => void }) =>
      resolve(payload, { onComplete })
);
export const getSiteSuccess = createAction(
  '@config/GET_SITE_SUCCESS',
  resolve => (payload: { siteId: string; site: ConfigSiteModel }) => resolve(payload)
);
export const getSiteError = createAction(
  '@config/GET_SITE_ERROR',
  resolve => (error: string) => resolve(error)
);

export const deleteRegionRequest = createOptimisticAction(
  '@config/DELETE_REGION_REQUEST',
  resolve => (payload: { siteId: string; regionId: string }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const deleteRegionSuccess = createOptimisticAction(
  '@config/DELETE_REGION_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const deleteRegionError = createOptimisticAction(
  '@config/DELETE_REGION_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const updateWidgetConfigurationRequest = createOptimisticAction(
  '@config/UPDATE_WIDGET_CONFIGURATION_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      model: Partial<WidgetConfigurationModel>;
      onComplete: () => void;
    }) =>
      resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() }, onComplete })
);

export const updateWidgetConfigurationSuccess = createOptimisticAction(
  '@config/UPDATE_WIDGET_CONFIGURATION_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const updateWidgetConfigurationError = createOptimisticAction(
  '@config/UPDATE_WIDGET_CONFIGURATION_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const createWidgetCountryConfigurationRequest = createOptimisticAction(
  '@config/CREATE_WIDGET_COUNTRY_CONFIGURATION_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      model: UpdateWidgetCountryConfigurationModel;
      onComplete: () => void;
    }) =>
      resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() }, onComplete })
);

export const createWidgetCountryConfigurationSuccess = createOptimisticAction(
  '@config/CREATE_WIDGET_COUNTRY_CONFIGURATION_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);

export const createWidgetCountryConfigurationError = createOptimisticAction(
  '@config/CREATE_WIDGET_COUNTRY_CONFIGURATION_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const updateWidgetCountryConfigurationRequest = createOptimisticAction(
  '@config/UPDATE_WIDGET_COUNTRY_CONFIGURATION_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      model: UpdateWidgetCountryConfigurationModel;
      onComplete: () => void;
    }) =>
      resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() }, onComplete })
);

export const updateWidgetCountryConfigurationSuccess = createOptimisticAction(
  '@config/UPDATE_WIDGET_COUNTRY_CONFIGURATION_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);

export const updateWidgetCountryConfigurationError = createOptimisticAction(
  '@config/UPDATE_WIDGET_COUNTRY_CONFIGURATION_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const createOrUpdateCutoffTimesRequest = createOptimisticAction(
  '@config/CREATE_OR_UPDATE_CUTOFF_TIMES_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      warehouseId: string;
      models: CreateOrUpdateWarehouseCutoffTimesModel[];
      siteId: string;
      onComplete?: (error: string) => void;
    }) =>
      resolve(payload, {
        optimistic: { type: BEGIN, id: getRandomNumber() },
        onComplete,
      })
);
export const createOrUpdateCutoffTimesSuccess = createOptimisticAction(
  '@config/CREATE_OR_UPDATE_CUTOFF_TIMES_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const createOrUpdateCutoffTimesError = createOptimisticAction(
  '@config/CREATE_OR_UPDATE_CUTOFF_TIMES_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);
export const createOrUpdateCutoffTimesPartialSuccess = createAction(
  '@config/CREATE_OR_UPDATE_CUTOFF_TIMES_PARTIAL_SUCCESS',
  resolve => (progress: number) => resolve(progress)
);

export const setWarehouseShippingDateAdjustmentRequest = createOptimisticAction(
  '@config/SET_WAREHOUSE_SHIPPING_DATE_ADJUSTMENT_REQUEST',
  resolve =>
    (payload: { warehouseId: string; model: ShippingDateAdjustmentModel; siteId: string }) =>
      resolve(payload, {
        optimistic: { type: BEGIN, id: getRandomNumber() },
      })
);
export const setWarehouseShippingDateAdjustmentSuccess = createOptimisticAction(
  '@config/SET_WAREHOUSE_SHIPPING_DATE_ADJUSTMENT_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const setWarehouseShippingDateAdjustmentError = createOptimisticAction(
  '@config/SET_WAREHOUSE_SHIPPING_DATE_ADJUSTMENT_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const listShippingMethodSuccess = createAction(
  'LIST_SHIPPING_METHODS_SUCCESS',
  resolve => (payload: { [key: string]: ExtendedShippingMethodModel[] }) => resolve(payload)
);

export const getPrivateKeyRequest = createAction(
  '@config/GET_PRIVATE_KEY_REQUEST',
  resolve =>
    ({ onComplete, ...payload }: { siteId: string; onComplete?: (privateKey: string) => void }) =>
      resolve(payload, { onComplete })
);

export const getPrivateKeySuccess = createAction(
  '@config/GET_PRIVATE_KEY_SUCCESS',
  resolve => (payload: GetPrivateKeyModel) => resolve(payload)
);

export const getPrivateKeyError = createAction(
  '@config/GET_PRIVATE_KEY_ERROR',
  resolve => (error: string) => resolve(error)
);

// special class of actions meant for use with many react-query request that need to hook into store and set config.isSendingChanges
export const genericConfigModificationRequest = createAction('@config/GENERIC_REQUEST_START');
export const genericConfigModificationSuccess = createAction('@config/GENERIC_REQUEST_SUCCESS');
export const genericConfigModificationError = createAction('@config/GENERIC_REQUEST_ERROR');

export const changeCategoryLabelsOrderRequest = createOptimisticAction(
  '@config/CHANGE_CATEGORY_LABELS_ORDER_REQUEST',
  resolve => (payload: { model: UpdateCategoryLabelsOrderModel; siteId: string }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const changeCategoryLabelsOrderSuccess = createOptimisticAction(
  '@config/CHANGE_CATEGORY_LABELS_ORDER_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const changeCategoryLabelsOrderError = createOptimisticAction(
  '@config/CHANGE_CATEGORY_LABELS_ORDER_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const createCategoryLabelRequest = createAction(
  '@config/CREATE_CATEGORY_LABEL_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      model: CreateCategoryLabelModel;
      onComplete?: () => void;
    }) =>
      resolve(payload, { onComplete })
);

export const createCategoryLabelSuccess = createAction(
  '@config/CREATE_CATEGORY_LABEL_SUCCESS',
  resolve => (payload: { model: CreateCategoryLabelModel & { id: string }; siteId: string }) =>
    resolve(payload)
);

export const createCategoryLabelError = createAction(
  '@config/CREATE_CATEGORY_LABEL_ERROR',
  resolve => (error: string) => resolve(error)
);

export const deleteCategoryLabelRequest = createOptimisticAction(
  '@config/DELETE_CATEGORY_LABEL_REQUEST',
  resolve => (payload: { model: DeleteCategoryLabelModel; siteId: string }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const deleteCategoryLabelSuccess = createOptimisticAction(
  '@config/DELETE_CATEGORY_LABEL_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const deleteCategoryLabelError = createOptimisticAction(
  '@config/DELETE_CATEGORY_LABEL_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const updateCategoryLabelDetailsRequest = createOptimisticAction(
  '@config/UPDATE_CATEGORY_LABEL_DETAILS_REQUEST',
  resolve => (payload: { siteId: string; model: UpdateCategoryLabelDetailsModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const updateCategoryLabelDetailsSuccess = createOptimisticAction(
  '@config/UPDATE_CATEGORY_LABEL_DETAILS_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const updateCategoryLabelDetailsError = createOptimisticAction(
  '@config/UPDATE_CATEGORY_LABEL_DETAILS_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const updateCategoryLabelTranslationsRequest = createOptimisticAction(
  '@config/UPDATE_CATEGORY_LABEL_TRANSLATIONS_REQUEST',
  resolve => (payload: { siteId: string; model: UpdateCategoryLabelTranslationsModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const updateCategoryLabelTranslationsSuccess = createOptimisticAction(
  '@config/UPDATE_CATEGORY_LABEL_TRANSLATIONS_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const updateCategoryLabelTranslationsError = createOptimisticAction(
  '@config/UPDATE_CATEGORY_LABEL_TRANSLATIONS_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const deleteCategoryLabelTranslationsRequest = createOptimisticAction(
  '@config/DELETE_CATEGORY_LABEL_TRANSLATIONS_REQUEST',
  resolve => (payload: { siteId: string; model: DeleteCategoryLabelTranslationsModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const deleteCategoryLabelTranslationsSuccess = createOptimisticAction(
  '@config/DELETE_CATEGORY_LABEL_TRANSLATIONS_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const deleteCategoryLabelTranslationsError = createOptimisticAction(
  '@config/DELETE_CATEGORY_LABEL_TRANSLATIONS_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const deleteWarehouseCutoffTimesRequest = createOptimisticAction(
  '@config/DELETE_WAREHOUSE_CUTOFF_TIMES_REQUEST',
  resolve => (payload: { siteId: string; model: DeleteWarehouseCutoffTimesModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const deleteWarehouseCutoffTimesSuccess = createOptimisticAction(
  '@config/DELETE_WAREHOUSE_CUTOFF_TIMES_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const deleteWarehouseCutoffTimesError = createOptimisticAction(
  '@config/DELETE_WAREHOUSE_CUTOFF_TIMES_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const createDeliveryAddonRequest = createAction(
  '@config/CREATE_DELIVERY_ADDON_REQUEST',
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      model: CreateDeliveryAddonModel;
      onComplete?: () => void;
    }) =>
      resolve(payload, { onComplete })
);
export const createDeliveryAddonSuccess = createAction(
  '@config/CREATE_DELIVERY_ADDON_SUCCESS',
  resolve => (payload: { model: CreateDeliveryAddonModel & { addonId: string }; siteId: string }) =>
    resolve(payload)
);
export const createDeliveryAddonError = createAction(
  '@config/CREATE_DELIVERY_ADDON_ERROR',
  resolve => (error: string) => resolve(error)
);

export const updateDeliveryAddonRequest = createOptimisticAction(
  '@config/UPDATE_DELIVERY_ADDON_REQUEST',
  resolve => (payload: { siteId: string; model: UpdateDeliveryAddonModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const updateDeliveryAddonSuccess = createOptimisticAction(
  '@config/UPDATE_DELIVERY_ADDON_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const updateDeliveryAddonError = createOptimisticAction(
  '@config/UPDATE_DELIVERY_ADDON_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const deleteDeliveryAddonRequest = createOptimisticAction(
  '@config/DELETE_DELIVERY_ADDON_REQUEST',
  resolve => (payload: { model: DeleteDeliveryAddonModel; siteId: string }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const deleteDeliveryAddonSuccess = createOptimisticAction(
  '@config/DELETE_DELIVERY_ADDON_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const deleteDeliveryAddonError = createOptimisticAction(
  '@config/DELETE_DELIVERY_ADDON_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const adjustDeliveryAddonsOrderRequest = createOptimisticAction(
  '@config/ADJUST_DELIVERY_ADDONS_ORDER_REQUEST',
  resolve => (payload: { model: AdjustDeliveryAddonsOrderModel; siteId: string }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const adjustDeliveryAddonsOrderSuccess = createOptimisticAction(
  '@config/ADJUST_DELIVERY_ADDONS_ORDER_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const adjustDeliveryAddonsOrderError = createOptimisticAction(
  '@config/ADJUST_DELIVERY_ADDONS_ORDER_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const upsertDeliveryAddonTranslationRequest = createOptimisticAction(
  '@config/UPSERT_DELIVERY_ADDON_TRANSLATION_REQUEST',
  resolve => (payload: { siteId: string; model: UpsertDeliveryAddonTranslationModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const upsertDeliveryAddonTranslationSuccess = createOptimisticAction(
  '@config/UPSERT_DELIVERY_ADDON_TRANSLATION_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const upsertDeliveryAddonTranslationError = createOptimisticAction(
  '@config/UPSERT_DELIVERY_ADDON_TRANSLATION_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const deleteDeliveryAddonTranslationRequest = createOptimisticAction(
  '@config/DELETE_DELIVERY_ADDON_TRANSLATION_REQUEST',
  resolve => (payload: { siteId: string; model: DeleteDeliveryAddonTranslationModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);
export const deleteDeliveryAddonTranslationSuccess = createOptimisticAction(
  '@config/DELETE_DELIVERY_ADDON_TRANSLATION_SUCCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);
export const deleteDeliveryAddonTranslationError = createOptimisticAction(
  '@config/DELETE_DELIVERY_ADDON_TRANSLATION_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const setVariantSiteId = newCreateAction('@config/SET_VARIANT_SITE_ID')<{
  siteId: string | null;
}>();
