import * as React from 'react';
import { Tag } from '../../../controls';
import { DeliveryStatus } from '../../../models';
import { getDeliveryTextColorForDeliveryStatus } from '../helpers';
import { humanize } from '@src/utils/string';

interface IProps {
  status: DeliveryStatus;
  style?: object;
}

const ShipmentStatusTag: React.StatelessComponent<IProps> = props => {
  const { status, style } = props;
  const color = getDeliveryTextColorForDeliveryStatus(status);
  return (
    <Tag color={color} style={style}>
      {status ? humanize(status) : 'Unknown'}
    </Tag>
  );
};

export { ShipmentStatusTag };
