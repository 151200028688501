import { ShippingLogo } from '@src/components/shipping-logo';
import React, { FC } from 'react';
import { style as tss } from 'typestyle';

type Props = {
  carrierProductId?: string;
  name?: string;
  carrier?: string;
};

export const CarrierProductWithLogo: FC<Props> = ({ carrierProductId, name, carrier }) => {
  return carrierProductId && carrier && name ? (
    <div className={styles.containerStyles}>
      <ShippingLogo shippingMethod={carrierProductId} />
      <span>{`${carrier} ${name}`}</span>
    </div>
  ) : (
    <> — </>
  );
};

const styles = {
  containerStyles: tss({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  }),
};
