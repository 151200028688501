import { APOTEKET_MERCHANT_ID } from '@src/utils/constants';
import { RootState } from '../../modules';

export interface ShippingMethodMetadata {
  name: string;
  required: boolean;
  info?: string;
  displayName: string;
  options?: {
    value: string;
    label: string;
  }[];
}

export const shippingMethodMetadataSelector =
  (state: RootState) =>
  (shippingMethod: string): ShippingMethodMetadata[] => {
    switch (shippingMethod) {
      case 'pnl-bua':
      case 'pnl-brev':
        return state.merchants.selectedMerchantId === APOTEKET_MERCHANT_ID
          ? [
              {
                name: 'pnl.customer_id',
                displayName: 'PNL Customer Number',
                required: true,
                info: 'It will override the default settings.',
                options: [
                  {
                    value: '20558385',
                    label: 'Distansapoteket Hässleholm Varubrev',
                  },
                  {
                    value: '20628631',
                    label: 'Distansapoteket Stockholm Varubrev',
                  },
                ],
              },
            ]
          : [];
      case 'pnl-mpc':
      case 'pnl-mph':
      case 'pnl-par':
      case 'pnl-bag':
      case 'pnl-bue':
      case 'pnl-bbm':
      case 'pnl-hemp':
      case 'pnl-pall':
      case 'pnl-par09':
      case 'pnl-par12':
      case 'pnl-parexp':
      case 'pnl-dpdfs':
      case 'pnl-mypack':
      case 'pnl-fpk':
        return state.merchants.selectedMerchantId === APOTEKET_MERCHANT_ID
          ? [
              {
                name: 'pnl.customer_id',
                displayName: 'PNL Customer Number',
                required: true,
                info: 'It will override the default settings.',
                options: [
                  {
                    value: '0205093073',
                    label: 'Nya DOS Örebro',
                  },
                  {
                    value: '0205583859',
                    label: 'Distansapoteket Hässleholm (EBS)',
                  },
                  {
                    value: '0206018012',
                    label: 'Distansapoteket Hässleholm (EBS-varubrev)',
                  },
                  {
                    value: '0206286312',
                    label: 'Distansapoteket Stockholm (Distans)',
                  },
                  {
                    value: '0206289837',
                    label: 'Distansapoteket Stockholm (Avtal)',
                  },
                  {
                    value: '0205732217',
                    label: 'Kundservice / Orderberedning',
                  },
                  {
                    value: '0205732449',
                    label: 'RGL VGR Castor',
                  },
                  {
                    value: '0205367568',
                    label: 'Transportavdelningen',
                  },
                  {
                    value: '1106217050',
                    label: 'Kundservice',
                  },
                  {
                    value: '8502024022',
                    label: 'Apo Örnen',
                  },
                  {
                    value: '0205435134',
                    label: 'Kundservice adm.',
                  },
                  {
                    value: '0205731821',
                    label: 'Trycksaksdistribution',
                  },
                  {
                    value: '0205732167',
                    label: '20573216',
                  },
                  {
                    value: '0205732522',
                    label: 'Dosapoteket produktion',
                  },
                  {
                    value: '0205732654',
                    label: '20573265',
                  },
                  {
                    value: '0205732779',
                    label: 'Kliniskapr.',
                  },
                  {
                    value: '0205734965',
                    label: 'Försäljning ledning',
                  },
                  {
                    value: '0205809338',
                    label: 'Stab Logistik Utveckling',
                  },
                  {
                    value: '0206037236',
                    label: 'Snabboteket',
                  },
                  {
                    value: '0206109480',
                    label: 'Apoteket NUS Tillverkning',
                  },
                  {
                    value: '0206282063',
                    label: 'Vid Helsingb lasarett tillverk',
                  },
                  {
                    value: '0206286080',
                    label: 'Sjukhusapoteket Karlstad Försörjning',
                  },
                  {
                    value: '0206289928',
                    label: 'Vid Sundsvalls sjukhus tillver',
                  },
                  {
                    value: '0202596425',
                    label: 'Vård LKM Destruktion',
                  },
                  {
                    value: '0203181516',
                    label: 'Sjukhusapoteket Sörmland',
                  },
                  {
                    value: '0207859307',
                    label: 'Tillverkning nationella enheten Uppsala',
                  },
                  {
                    value: '20573542',
                    label: 'Tillverkning Nationella Enheten',
                  },
                  {
                    value: '0207822966',
                    label: 'Hemofili Göteborg',
                  },
                  {
                    value: '0207822974',
                    label: 'Hemofili Malmö',
                  },
                  {
                    value: '0207822990',
                    label: 'Hemofili Stockholm',
                  },
                  {
                    value: '0207823006',
                    label: 'Skeppsapoteket Göteborg',
                  },
                  {
                    value: '0207823030',
                    label: 'Manuella enheten Tamro Sthlm',
                  },
                  {
                    value: '0206290017',
                    label: 'Kliiniska prövningar Linköping',
                  },
                  {
                    value: '0207859281',
                    label: 'Kliiniska prövningar Östersund',
                  },
                  {
                    value: '0206289829',
                    label: 'Kliiniska prövningar Gävle',
                  },
                  {
                    value: '1215077023',
                    label: 'SA Linköping',
                  },
                  {
                    value: '1215077031',
                    label: 'SA Norrköping',
                  },
                  {
                    value: '0205868300',
                    label: 'Kliniska prövningar Göteborg',
                  },
                  {
                    value: '0200267953',
                    label: 'Tamro Göteborg',
                  },
                  {
                    value: '0205951155',
                    label: 'Kliniska prövningar Eskilstun',
                  },
                  {
                    value: '0207952268',
                    label: 'Kliniska prövningar Uppsala nationella enheten',
                  },
                ],
              },
            ]
          : [];

      case 'gnc-std':
        return [
          { name: 'label.from_phone', displayName: 'Sender phone number', required: false },
          {
            name: 'gnc.transport_instruction_1',
            displayName: 'Transport instruction',
            required: false,
          },
        ];

      case 'bst-std':
        return [
          { name: 'brn.product_name', displayName: 'BRN Product Name', required: false },
          { name: 'brn.product_route', displayName: 'BRN Product Route', required: false },
          { name: 'brn.delivery_date', displayName: 'BRN Delivery Date', required: false },
        ];

      case 'brn-blp':
      case 'brn-pip':
      case 'brn-ppi':
      case 'brn-phd':
        return [
          {
            name: 'brn.additionalServiceCode',
            displayName: 'BRN Additional Service Code',
            required: false,
          },
        ];

      case 'dbs-par':
      case 'dbs-pos':
        return [
          { name: 'dbs.location_ref', displayName: 'DBS Location Ref', required: false },
          { name: 'dbs.payment_cost', displayName: 'DBS Payment Cost', required: false },
          { name: 'dbs.gs1_prefix', displayName: 'DBS GS1 Prefix', required: false },
          { name: 'dbs.consignors_ref', displayName: 'DBS Consignor Ref', required: false },
          { name: 'dbs.consignors_phone', displayName: 'DBS Consignor Phone', required: false },
          {
            name: 'dbs.return_tracking_number',
            displayName: 'DBS Return Tracking Number',
            required: false,
          },
          { name: 'dbs.merchant_id', displayName: 'DBS Merchant ID', required: false },
        ];

      case 'tmr-std':
        return state.merchants.selectedMerchantId === APOTEKET_MERCHANT_ID
          ? [
              {
                name: 'tmr.customer_id',
                displayName: 'TMR Customer Id',
                required: false,
                info: 'It will override the default settings.',
              },
              { name: 'tmr.routing_code', displayName: 'TMR Routing Code', required: true },
            ]
          : [];

      case 'tmr-noroute':
        return [
          {
            name: 'tmr.customer_id',
            required: false,
            displayName: 'TMR Customer ID',
            info: 'It will override the default settings.',
          },
          { name: 'tmr.routing_code', displayName: 'TMR Routing Code', required: false },
        ];

      case 'dhl-esl':
      case 'dhl-eww':
      case 'dhl-fsp':
      case 'dhl-fpk':
      case 'dhl-prt':
      case 'dhl-sty':
        return [
          { name: 'dhl.customer_no', displayName: 'DHL Customer No', required: false },
          { name: 'dhl.phone_from', displayName: 'DHL Phone From', required: false },
          {
            name: 'dhl.paying_customer_no',
            displayName: 'DHL Paying Customer No',
            required: false,
          },
          { name: 'dhl.routing_code', displayName: 'DHL Routing Code', required: false },
          { name: 'dhl.external_id', displayName: 'DHL External ID', required: false },
          { name: 'dhl.addon_codes', displayName: 'DHL Addon Codes', required: false },
          { name: 'dhl.feature_code', displayName: 'DHL Feature Code', required: false },
        ];

      case 'tnt-exp':
      case 'tnt-exp12':
        return [{ name: 'tnt.customer_id', displayName: 'TNT Customer ID', required: false }];

      default:
        return [];
    }
  };
