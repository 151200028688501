import { Select } from '@src/controls';
import { useFormatMessage } from '@src/i18n';
import { trackingStatusList } from '@src/models';
import { SitesSelectors } from '@src/modules/sites';
import { services } from '@src/services';
import {
  listCarrierProductMapping,
  listSuggestedCarrierProductMappings,
} from '@src/services/selfcare-service';
import { useSelector } from '@src/utils/hooks';
import { humanize } from '@src/utils/string';
import { Button, DatePicker } from 'antd';
import { FormikProps } from 'formik';
import { concat } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useQuery } from 'react-query';
import { style as tss } from 'typestyle';
import { GroupedGlobalCarrierProductList } from '../product-mappings/form-commons';
import { sourceOptions } from './source-options';
import { Carrier, directions, TrackingRecordFiltering } from './types';

const { RangePicker } = DatePicker;

const { fetchAvailableCarrierProducts } = services.trackingNumberService;

const statusOptions = trackingStatusList.map(key => ({
  value: key,
  label: humanize(key),
}));

const directionOptions = directions.map(direction => ({
  label: humanize(direction),
  value: direction,
}));

const buildCarrierProductOptions = (carriers: Carrier[]) =>
  carriers.reduce((result, carrier) => {
    return {
      ...result,
      [carrier.name]: carrier.carrier_products.map(carrierProduct => ({
        value: carrierProduct.id,
        label: `${carrier.name} ${carrierProduct.name}`,
        optionLabel: `${carrier.name} ${carrierProduct.name}`,
      })),
    };
  }, {} as GroupedGlobalCarrierProductList);

type Props = {
  formik: FormikProps<Omit<TrackingRecordFiltering, 'id'>>;
  values: FormikProps<Omit<TrackingRecordFiltering, 'id'>>['values'];
  setFieldValue: FormikProps<Omit<TrackingRecordFiltering, 'id'>>['setFieldValue'];
  onApply: () => void;
  onClearFiters: () => void;
  onCancel: () => void;
};

export const FilteringFormPopover: React.FC<Props> = ({
  formik,
  onApply,
  onClearFiters,
  onCancel,
}) => {
  const formatMessage = useFormatMessage();

  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);

  const carriersListData = useQuery(['availableCarriersList', siteId], () =>
    fetchAvailableCarrierProducts(siteId)
  );

  const carrierProductsMappings = useQuery(['carrier_product_mapping.list', siteId], () =>
    listCarrierProductMapping(siteId)
  );

  const suggestedCarrierProductsMappings = useQuery(
    ['suggested_carrier_product_mappings.list', siteId],
    () => listSuggestedCarrierProductMappings(siteId),
    {
      select: data => ({
        carrier_product_mappings: concat(
          data.automapped_carrier_product_refs ?? [],
          data.unmapped_carrier_product_refs ?? []
        ),
      }),
    }
  );

  const isCarrierProductsMappingsFetching =
    carrierProductsMappings.isFetching && suggestedCarrierProductsMappings.isFetching;

  const carrierProductsMappingsOptions = concat(
    carrierProductsMappings.data?.carrier_product_mappings ?? [],
    suggestedCarrierProductsMappings.data?.carrier_product_mappings ?? []
  ).map(mapping => ({
    value: mapping.carrier_product_ref,
    label: mapping.carrier_product_ref,
  }));

  const carriersList = carriersListData?.data?.available_carriers ?? [];

  const carrierProductOptions = buildCarrierProductOptions(carriersList);

  return (
    <>
      <div className={styles.filterColumnWrapper}>
        <div className={styles.filterColumn}>
          <label className={styles.label}>{formatMessage('CREATED_AT')}</label>
          <RangePicker
            value={[
              formik.values.created_at_range?.start
                ? moment(formik.values.created_at_range?.start)
                : null,
              formik.values.created_at_range?.end
                ? moment(formik.values.created_at_range?.end)
                : null,
            ]}
            onChange={values => {
              if (values === null) {
                formik.setFieldValue('created_at_range', undefined);
              } else {
                formik.setFieldValue('created_at_range', {
                  start: values[0]?.startOf('day').format(),
                  end: values[1]?.endOf('day').format(),
                });
              }
            }}
            className={styles.input}
            allowEmpty={[true, true]}
          />
          <label className={styles.label}>{formatMessage('CARRIER_PRODUCT')}</label>
          <Select
            disabled={carriersListData.isFetching}
            loading={carriersListData.isFetching}
            id="carrier_product_ids"
            className={styles.input}
            mode="multiple"
            placeholder="Please select"
            groupOptions={carrierProductOptions}
            value={formik.values.carrier_product_ids}
            onChange={values =>
              formik.setFieldValue('carrier_product_ids', values.length === 0 ? undefined : values)
            }
          />
          <label className={styles.label}>{formatMessage('STATUS')}</label>
          <Select
            id="statuses"
            mode="multiple"
            placeholder="Please select"
            className={styles.input}
            options={statusOptions}
            caseInsensitiveSearch
            value={formik.values.statuses}
            onChange={values =>
              formik.setFieldValue('statuses', values.length === 0 ? undefined : values)
            }
          />
          <label className={styles.label}>{formatMessage('TRACKING_NUMBERS.DIRECTIONS')}</label>
          <Select
            id="directions"
            mode="multiple"
            options={directionOptions}
            className={styles.input}
            value={formik.values.direction_types}
            onChange={values =>
              formik.setFieldValue('direction_types', values.length === 0 ? undefined : values)
            }
          />
        </div>
        <div className={styles.filterColumn}>
          <label className={styles.label}>{formatMessage('UPDATED_AT')}</label>
          <RangePicker
            value={[
              formik.values.updated_at_range?.start
                ? moment(formik.values.updated_at_range?.start)
                : null,
              formik.values.updated_at_range?.end
                ? moment(formik.values.updated_at_range?.end)
                : null,
            ]}
            onChange={values => {
              if (values === null) {
                formik.setFieldValue('updated_at_range', undefined);
              } else {
                formik.setFieldValue('updated_at_range', {
                  start: values[0]?.startOf('day').format(),
                  end: values[1]?.endOf('day').format(),
                });
              }
            }}
            className={styles.input}
            allowEmpty={[true, true]}
          />
          <label className={styles.label}>
            {formatMessage('TRACKING_NUMBERS.CARRIER_PRODUCT_REFS')}
          </label>
          <Select
            disabled={isCarrierProductsMappingsFetching}
            loading={isCarrierProductsMappingsFetching}
            id="carrier_product_refs"
            mode="tags"
            className={styles.input}
            options={carrierProductsMappingsOptions}
            value={formik.values.carrier_product_refs}
            onChange={values =>
              formik.setFieldValue('carrier_product_refs', values.length === 0 ? undefined : values)
            }
          />
          <label className={styles.label}>{formatMessage('TRACKING_NUMBERS.SOURCES')}</label>
          <Select
            id="sources"
            mode="multiple"
            options={sourceOptions}
            className={styles.input}
            value={formik.values.sources}
            onChange={values => {
              formik.setFieldValue('sources', values.length === 0 ? undefined : values);
            }}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button onClick={onClearFiters}>
          {formatMessage('TRACKING_NUMBERS.CLEAR_ALL_FILTERS')}
        </Button>
        <div>
          <Button type="dashed" onClick={onCancel}>
            {formatMessage('CANCEL')}
          </Button>
          <Button type="primary" onClick={onApply}>
            {formatMessage('TRACKING_NUMBERS.APPLY')}
          </Button>
        </div>
      </div>
    </>
  );
};

const styles = {
  filterColumnWrapper: tss({
    display: 'flex',
    padding: '12px 16px',
  }),
  filterColumn: tss({
    display: 'flex',
    flexDirection: 'column',
    marginRight: '16px',
    $nest: {
      '&:last-child': {
        marginRight: 0,
      },
    },
  }),
  label: tss({
    marginBottom: '6px',
  }),
  input: tss({
    marginBottom: '8px',
    width: '256px',
  }),
  footer: tss({
    borderTop: '1px solid #F0F0F0',
    padding: '12px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    $nest: {
      '& > div': {
        display: 'flex',
      },
      '& > div > button:first-child': {
        marginRight: '8px',
      },
    },
  }),
};
