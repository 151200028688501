import {
  DeliveryModel,
  EditableNotesModel,
  ShipmentCreateModel,
  ShipmentEditModel,
  ShipmentModel,
} from '../../models';

import { deprecated } from 'typesafe-actions';

const { createAction } = deprecated;

const GET_SHIPMENT_REQUEST = '@shipments/GET_SHIPMENT_REQUEST';
const GET_SHIPMENT_SUCCESS = '@shipments/GET_SHIPMENT_SUCCESS';
const GET_SHIPMENT_ERROR = '@shipments/GET_SHIPMENT_ERROR';
const CREATE_SHIPMENT_REQUEST = '@shipments/CREATE_SHIPMENT_REQUEST';
const CREATE_SHIPMENT_SUCCESS = '@shipments/CREATE_SHIPMENT_SUCCESS';
const CREATE_SHIPMENT_ERROR = '@shipments/CREATE_SHIPMENT_ERROR';
const CANCEL_SHIPMENT_REQUEST = '@shipments/CANCEL_SHIPMENT_REQUEST';
const CANCEL_SHIPMENT_SUCCESS = '@shipments/CANCEL_SHIPMENT_SUCCESS';
const CANCEL_SHIPMENT_ERROR = '@shipments/CANCEL_SHIPMENT_ERROR';
const BOOK_AND_PRINT_PARCELS_REQUEST = '@shipments/BOOK_AND_PRINT_PARCELS_REQUEST';
const BOOK_PARCELS_REQUEST = '@shipments/BOOK_PARCELS_REQUEST';
const BOOK_PARCELS_SUCCESS = '@shipments/BOOK_PARCELS_SUCCESS';
const BOOK_PARCELS_ERROR = '@shipments/BOOK_PARCELS_ERROR';
const BOOK_PICKUP_DELIVERIES_REQUEST = '@shipments/BOOK_PICKUP_DELIVERIES_REQUEST';
const BOOK_PICKUP_DELIVERIES_SUCCESS = '@shipments/BOOK_PICKUP_DELIVERIES_SUCCESS';
const BOOK_PICKUP_DELIVERIES_ERROR = '@shipments/BOOK_PICKUP_DELIVERIES_ERROR';
const DUPLICATE_SHIPMENT = '@shipments/DUPLICATE_SHIPMENT';
const CREATE_RETURN = '@shipments/CREATE_RETURN';
const CANCEL_AND_DUPLICATE_SHIPMENT = '@shipments/CANCEL_AND_DUPLICATE_SHIPMENT';
const DRAFT_SHIPMENT_LOAD = '@shipments/DRAFT_SHIPMENT_LOAD';
const DRAFT_SHIPMENT_CLEAR = '@shipments/DRAFT_SHIPMENT_CLEAR';
const EDIT_SHIPMENT_REQUEST = '@shipments/EDIT_SHIPMENT_REQUEST';
const EDIT_SHIPMENT_SUCCESS = '@shipments/EDIT_SHIPMENT_SUCCESS';
const EDIT_SHIPMENT_ERROR = '@shipments/EDIT_SHIPMENT_ERROR';
const CREATE_AND_BOOK_SHIPMENTS_REQUEST = '@shipments/CREATE_AND_BOOK_SHIPMENTS_REQUEST';
const CREATE_AND_BOOK_SHIPMENTS_SUCCESS = '@shipments/CREATE_AND_BOOK_SHIPMENTS_SUCCESS';
const CREATE_AND_BOOK_SHIPMENTS_PARTIAL_SUCCESS =
  '@shipments/CREATE_AND_BOOK_SHIPMENTS_PARTIAL_SUCCESS';
const CREATE_AND_BOOK_SHIPMENTS_ERROR = '@shipments/CREATE_AND_BOOK_SHIPMENTS_ERROR';
const CREATE_AND_BOOK_SHIPMENTS_INCREASE_PROGRESS =
  '@shipments/CREATE_AND_BOOK_SHIPMENTS_INCREASE_PROGRESS';
const CREATE_AND_BOOK_SHIPMENTS_RESET = '@shipments/CREATE_AND_BOOK_SHIPMENTS_RESET';
const UPDATE_SHIPMENT_LIST_PAGINATION = '@shipments/UPDATE_SHIPMENT_LIST_PAGINATION';
const EDIT_NOTE_REQUEST = '@shipments/EDIT_NOTE_REQUEST';
const EDIT_NOTE_SUCCESS = '@shipments/EDIT_NOTE_SUCCESS';
const EDIT_NOTE_ERROR = '@shipments/EDIT_NOTE_ERROR';

export const getShipmentRequest = createAction(
  GET_SHIPMENT_REQUEST,
  resolve => (payload: { id: string }) => resolve(payload)
);
export const getShipmentSuccess = createAction(
  GET_SHIPMENT_SUCCESS,
  resolve => (shipment: ShipmentModel) => resolve(shipment)
);
export const getShipmentError = createAction(
  GET_SHIPMENT_ERROR,
  resolve => (error: string) => resolve(error)
);

export const createShipmentRequest = createAction(
  CREATE_SHIPMENT_REQUEST,
  resolve => (payload: { siteId: string; shipment: ShipmentCreateModel; redirectId?: string }) =>
    resolve(payload)
);
export const createShipmentSuccess = createAction(
  CREATE_SHIPMENT_SUCCESS,
  resolve => (shipment: ShipmentModel) => resolve(shipment)
);
export const createShipmentError = createAction(
  CREATE_SHIPMENT_ERROR,
  resolve => (error: string) => resolve(error)
);

export const cancelShipmentRequest = createAction(
  CANCEL_SHIPMENT_REQUEST,
  resolve => (payload: { siteId: string; shipmentId: string }) => resolve(payload)
);
export const cancelShipmentSuccess = createAction(
  CANCEL_SHIPMENT_SUCCESS,
  resolve => (payload: string) => resolve(payload)
);
export const cancelShipmentError = createAction(
  CANCEL_SHIPMENT_ERROR,
  resolve => (error: string) => resolve(error)
);

export const duplicateShipment = createAction(
  DUPLICATE_SHIPMENT,
  resolve => (payload: { shipmentModel: ShipmentModel }) => resolve(payload)
);

export const createReturn = createAction(
  CREATE_RETURN,
  resolve => (payload: { shipmentModel: ShipmentModel }) => resolve(payload)
);

export const cancelAndDuplicateShipment = createAction(
  CANCEL_AND_DUPLICATE_SHIPMENT,
  resolve => (payload: { siteId: string; shipmentId: string; shipmentModel: ShipmentModel }) =>
    resolve(payload)
);

export const draftShipmentLoad = createAction(
  DRAFT_SHIPMENT_LOAD,
  resolve => (draft: ShipmentModel) => resolve(draft)
);
export const draftShipmentClear = createAction(DRAFT_SHIPMENT_CLEAR);

export const editShipmentRequest = createAction(
  EDIT_SHIPMENT_REQUEST,
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      shipment: ShipmentEditModel;
      onComplete: () => void;
    }) =>
      resolve(payload, { onComplete })
);
export const editShipmentSuccess = createAction(
  EDIT_SHIPMENT_SUCCESS,
  resolve => (shipment: ShipmentModel) => resolve(shipment)
);
export const editShipmentError = createAction(
  EDIT_SHIPMENT_ERROR,
  resolve => (error: string) => resolve(error)
);

export const bookAndPrintParcelsRequest = createAction(
  BOOK_AND_PRINT_PARCELS_REQUEST,
  resolve => (payload: { siteId: string; shipmentId: string; tosId: string }) => resolve(payload)
);
export const bookParcelsRequest = createAction(
  BOOK_PARCELS_REQUEST,
  resolve => (payload: { siteId: string; shipmentId: string }) => resolve(payload)
);
export const bookParcelsSuccess = createAction(
  BOOK_PARCELS_SUCCESS,
  resolve => (shipment: ShipmentModel) => resolve(shipment)
);
export const bookParcelError = createAction(
  BOOK_PARCELS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const bookPickupDeliveriesRequest = createAction(
  BOOK_PICKUP_DELIVERIES_REQUEST,
  resolve => (payload: { siteId: string; shipmentId: string; trackingNumbers: string[] }) =>
    resolve(payload)
);
export const bookPickupDeliveriesSuccess = createAction(
  BOOK_PICKUP_DELIVERIES_SUCCESS,
  resolve => (payload: { shipmentId: string; deliveries: DeliveryModel[] }) => resolve(payload)
);
export const bookPickupDeliveriesError = createAction(
  BOOK_PICKUP_DELIVERIES_ERROR,
  resolve => (error: string) => resolve(error)
);

export const createAndBookShipmentsRequest = createAction(
  CREATE_AND_BOOK_SHIPMENTS_REQUEST,
  resolve =>
    (payload: { numberOfShipments: number; siteId: string; shipmentModel: ShipmentCreateModel }) =>
      resolve(payload)
);
export const createAndBookShipmentsSuccess = createAction(CREATE_AND_BOOK_SHIPMENTS_SUCCESS);
export const createAndBookShipmentsPartialSuccess = createAction(
  CREATE_AND_BOOK_SHIPMENTS_PARTIAL_SUCCESS,
  resolve => (ids: string[]) => resolve(ids)
);
export const createAndBookShipmentsError = createAction(
  CREATE_AND_BOOK_SHIPMENTS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const createAndBookShipmentsIncreaseProgress = createAction(
  CREATE_AND_BOOK_SHIPMENTS_INCREASE_PROGRESS,
  resolve => (payload: { shipmentId: string }) => resolve(payload)
);
export const createAndBookShipmentsReset = createAction(CREATE_AND_BOOK_SHIPMENTS_RESET);
export const updateShipmentListPagination = createAction(
  UPDATE_SHIPMENT_LIST_PAGINATION,
  resolve => (page: number) => resolve(page)
);

export const editNoteRequest = createAction(
  EDIT_NOTE_REQUEST,
  resolve => (payload: { siteId: string; model: EditableNotesModel }) => resolve(payload)
);
export const editNoteSuccess = createAction(EDIT_NOTE_SUCCESS);
export const editNoteError = createAction(
  EDIT_NOTE_ERROR,
  resolve => (error: string) => resolve(error)
);
