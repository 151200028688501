import { Input } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import * as React from 'react';

export interface IProps extends InputProps {
  autoFocus?: boolean;
  forwardedInputRef?: React.RefObject<Input>;
}

const trimWhitespace = (value: string) => value && value.trim();

const Component: React.StatelessComponent<IProps> = props => {
  const { onBlur, forwardedInputRef, ...restProps } = props;

  const handleBlur = (ev: React.FormEvent<any>) => {
    const trimmed = trimWhitespace(ev.currentTarget.value);
    if (trimmed && trimmed !== props.value && props.onChange) {
      props.onChange({ target: { value: trimmed } } as any);
    }
  };

  return (
    <Input
      {...restProps}
      onBlur={onBlur || handleBlur}
      placeholder={props.placeholder || '-'}
      ref={forwardedInputRef}
    />
  );
};

export default Component;
