import {
  CustomEventModel,
  DeleteCustomEventTranslationModel,
  UpdateInternalEventModel,
  UpsertCustomEventModel,
  UpsertCustomEventTranslationModel,
} from '@src/models';
import { getRandomNumber } from '@src/utils/number';
import { createOptimisticAction } from '@src/utils/optimistic-updates';
import { BEGIN, COMMIT, REVERT } from 'redux-optimistic-ui';
import { createAction } from 'typesafe-actions';

export const updateCustomEventRequest = createOptimisticAction(
  '@trackingWidget/UPDATE_CUSTOM_EVENT_REQUEST',
  resolve => (payload: { siteId: string; upsertModel: UpsertCustomEventModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);

export const updateCustomEventSuccess = createOptimisticAction(
  '@trackingWidget/UPDATE_CUSTOM_EVENT_SUCCESS',
  resolve =>
    ({ transationId }: { transationId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transationId } })
);

export const updateCustomEventError = createOptimisticAction(
  '@trackingWidget/UPDATE_CUSTOM_EVENT_ERROR',
  resolve =>
    ({ error, transationId }: { error: string; transationId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transationId } })
);

export const deleteCustomEventRequest = createOptimisticAction(
  '@trackingWidget/DELETE_CUSTOM_EVENT_REQUEST',
  resolve => (payload: { id: string; siteId: string }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);

export const deleteCustomEventSuccess = createOptimisticAction(
  '@trackingWidget/DELETE_CUSTOM_EVENT_SUCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);

export const deleteCustomEventError = createOptimisticAction(
  '@trackingWidget/DELETE_CUSTOM_EVENT_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const updateInternalEventRequest = createOptimisticAction(
  '@trackingWidget/UPDATE_INTERNAL_EVENT_REQUEST',
  resolve => (payload: { siteId: string; updateModel: UpdateInternalEventModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);

export const updateInternalEventSuccess = createOptimisticAction(
  '@trackingWidget/UPDATE_INTERNAL_EVENT_SUCCESS',
  resolve =>
    ({ transationId }: { transationId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transationId } })
);

export const updateInternalEventError = createOptimisticAction(
  '@trackingWidget/UPDATE_INTERNAL_EVENT_ERROR',
  resolve =>
    ({ error, transationId }: { error: string; transationId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transationId } })
);

export const createCustomEventRequest = createAction(
  '@trackingWidget/CREATE_CUSTOM_EVENT_REQUEST',
  (payload: { siteId: string; upsertModel: UpsertCustomEventModel }) => payload
)();

export const createCustomEventSuccess = createAction(
  '@trackingWidget/CREATE_CUSTOM_EVENT_SUCCESS',
  (payload: { siteId: string; displayModel: CustomEventModel }) => payload
)();

export const createCustomEventError = createAction(
  '@trackingWidget/CREATE_CUSTOM_EVENT_ERROR',
  (error: string) => error
)();

export const updateCustomEventTranslationRequest = createOptimisticAction(
  '@trackingWidget/UPDATE_CUSTOM_EVENT_TRANSLATION_REQUEST',
  resolve => (payload: { siteId: string; upsertModel: UpsertCustomEventTranslationModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);

export const updateCustomEventTranslationSuccess = createOptimisticAction(
  '@trackingWidget/UPDATE_CUSTOM_EVENT_TRANSLATION_SUCCESS',
  resolve =>
    ({ transationId }: { transationId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transationId } })
);

export const updateCustomEventTranslationError = createOptimisticAction(
  '@trackingWidget/UPDATE_CUSTOM_EVENT_TRANSLATION_ERROR',
  resolve =>
    ({ error, transationId }: { error: string; transationId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transationId } })
);

export const deleteCustomEventTranslationRequest = createOptimisticAction(
  '@trackingWidget/DELETE_CUSTOM_EVENT_TRANSLATION_REQUEST',
  resolve => (payload: { siteId: string; deleteModel: DeleteCustomEventTranslationModel }) =>
    resolve(payload, { optimistic: { type: BEGIN, id: getRandomNumber() } })
);

export const deleteCustomEventTranslationSuccess = createOptimisticAction(
  '@trackingWidget/DELETE_CUSTOM_EVENT_TRANSLATION_SUCESS',
  resolve =>
    ({ transactionId }: { transactionId: number }) =>
      resolve(undefined, { optimistic: { type: COMMIT, id: transactionId } })
);

export const deleteCustomEventTranslationError = createOptimisticAction(
  '@trackingWidget/DELETE_CUSTOM_EVENT_TRANSLATION_ERROR',
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);
