import { InputFieldForm } from '@src/forms';
import { formFieldLayout } from '@src/forms/form-layouts';
import { requiredFieldWithMessage } from '@src/utils/validation';
import { FieldState, FormState } from 'formstate';
import * as React from 'react';

type Props = {
  formState: FormState<{ inputFieldState: FieldState<string> }>;
  formMode?: 'Create' | 'Edit';
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
};
export const TagForm = (props: Props) => {
  return (
    <form
      onSubmit={e => {
        if (props.onSubmit) {
          props.onSubmit(e);
        }
      }}
    >
      <InputFieldForm
        required={true}
        label="Name"
        formState={props.formState}
        validators={[requiredFieldWithMessage('Field is required')]}
        {...formFieldLayout}
      />
    </form>
  );
};
