import { combineEpics, Epic } from 'redux-observable';
import { from as observableFrom, of as observableOf } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  mapTo,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { ERRORS } from '@src/dictionaries';
import { MADError } from '@src/utils';
import { isEmpty } from 'ramda';
import { tagsActions } from '.';
import { RootAction, Services } from '..';
import { RootState } from '../root-reducer';

const constants = {
  pageNumber: 1,
  pageSize: 50,
  order: 'DESCENDING' as 'DESCENDING',
};

const createSiteTagEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(tagsActions.createSiteTagRequest)),
    switchMap(({ payload: { name, siteId } }) =>
      observableFrom(userProfileService.createSiteTag(siteId, name)).pipe(
        map(tagsActions.createSiteTagSuccess),
        catchError((error: MADError) =>
          observableOf(tagsActions.createSiteTagError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const deleteSiteTagsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(tagsActions.deleteSiteTagsRequest)),
    switchMap(({ payload: { siteId, ids } }) =>
      observableFrom(userProfileService.deleteSiteTags(siteId, ids)).pipe(
        mapTo(tagsActions.deleteSiteTagsSuccess({ ids })),
        catchError((error: MADError) =>
          observableOf(tagsActions.createSiteTagError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const getShipmentsIdsByTags: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(tagsActions.getShipmentIdsByTagsRequest)),
    switchMap(({ payload: { siteId, tags }, meta: { onComplete } }) =>
      observableFrom(userProfileService.getShipmentIdsByTags(siteId, tags)).pipe(
        tap(res => onComplete(res)),
        switchMap(res => observableOf(tagsActions.getShipmentIdsByTagsSuccess(res))),
        catchError((error: MADError) =>
          observableOf(tagsActions.getShipmentIdsByTagsError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const listSiteTagsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(tagsActions.listSiteTagsRequest)),
    switchMap(({ payload: { siteId, userId }, meta }) =>
      observableFrom(userProfileService.listSiteTags(siteId, userId)).pipe(
        tap(res => {
          if (meta.onComplete) {
            meta.onComplete(res);
          }
        }),
        map(tagsActions.listSiteTagsSuccess),
        catchError((error: MADError) =>
          observableOf(tagsActions.listSiteTagsError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const listSiteTagsForUserEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(tagsActions.listSiteTagsPerUserRequest)),
    switchMap(({ payload: { siteId, userId } }) =>
      observableFrom(userProfileService.listSiteTags(siteId, userId)).pipe(
        map(res => tagsActions.listSiteTagsPerUserSuccess(res)),
        catchError((error: MADError) =>
          observableOf(tagsActions.listSiteTagsPerUserError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const getShipmentsByTagId: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { loggingService, somSearchService }
) =>
  action$.pipe(
    filter(isActionOf(tagsActions.getShipmentsByTagIdRequest)),
    concatMap(action =>
      observableFrom(
        somSearchService.getShipmentList(
          action.payload.siteId,
          [''],
          constants.order,
          constants.pageNumber,
          constants.pageSize,
          !isEmpty(action.payload.shipmentIds)
            ? {
                shipmentIds: action.payload.shipmentIds,
              }
            : undefined
        )
      ).pipe(
        map(res =>
          tagsActions.getShipmentsByTagIdSuccess({
            shipments: res.shipments,
            tagId: action.payload.tagId,
          })
        ),
        catchError((error: MADError) =>
          observableOf(tagsActions.getShipmentsByTagIdError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const renameSiteTagEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(tagsActions.renameSiteTagRequest)),
    switchMap(({ payload: { siteId, id, name } }) =>
      observableFrom(userProfileService.renameSiteTag(siteId, id, name)).pipe(
        map(tagsActions.renameSiteTagSuccess),
        catchError((error: MADError) =>
          observableOf(tagsActions.renameSiteTagError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const listUserTagsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(tagsActions.listUserTagsRequest)),
    switchMap(({ payload: { siteId }, meta }) =>
      observableFrom(userProfileService.listUserTags(siteId)).pipe(
        tap(res => meta.onComplete(res)),
        mergeMap(res => observableOf(tagsActions.listUserTagsSuccess(res))),
        catchError((error: MADError) =>
          observableOf(tagsActions.listUserTagsError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const updateUserWithTagsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(tagsActions.updateUserWithTagsRequest)),
    switchMap(({ payload: { userId, tagsIds, siteId } }) =>
      observableFrom(userProfileService.updateUserWithTags(userId, tagsIds, siteId)).pipe(
        map(() => tagsActions.updateUserWithTagsSuccess()),
        catchError((error: MADError) =>
          observableOf(tagsActions.updateUserWithTagsError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const addTagsToShipmentsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, loggingService, messageService }
) =>
  action$.pipe(
    filter(isActionOf(tagsActions.addTagsToShipmentsRequest)),
    switchMap(({ payload: { siteId, tagIds, shipmentIds }, meta }) =>
      observableFrom(userProfileService.addTagsToShipments(siteId, tagIds, shipmentIds)).pipe(
        tap(() => meta.onComplete()),
        map(tagsActions.addTagsToShipmentsSuccess),
        tap(() => {
          messageService.success(ERRORS.ADD_TAGS_TO_SHIPMENTS_SUCCESS);
        }),
        catchError((error: MADError) =>
          observableOf(tagsActions.addTagsToShipmentsError(error.message)).pipe(
            tap(() => {
              messageService.error(ERRORS.ADD_TAGS_TO_SHIPMENTS_ERROR);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const removeTagFromShipmentEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, loggingService, messageService }
) =>
  action$.pipe(
    filter(isActionOf(tagsActions.removeTagFromShipmentsRequest)),
    switchMap(({ payload: { siteId, tagId, shipmentIds }, meta }) =>
      observableFrom(userProfileService.removeTagFromShipments(siteId, tagId, shipmentIds)).pipe(
        tap(() => meta.onComplete()),
        map(tagsActions.removeTagFromShipmentsSuccess),
        tap(() => {
          messageService.success(ERRORS.REMOVE_TAGS_TO_SHIPMENTS_SUCCESS);
        }),
        catchError((error: MADError) =>
          observableOf(tagsActions.removeTagFromShipmentsError(error.message)).pipe(
            tap(() => {
              messageService.error(ERRORS.REMOVE_TAGS_TO_SHIPMENTS_ERROR);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

export const tagsEpics = combineEpics(
  createSiteTagEpic,
  deleteSiteTagsEpic,
  listSiteTagsEpic,
  renameSiteTagEpic,
  listUserTagsEpic,
  updateUserWithTagsEpic,
  addTagsToShipmentsEpic,
  removeTagFromShipmentEpic,
  getShipmentsIdsByTags,
  getShipmentsByTagId,
  listSiteTagsForUserEpic
);
