import { useFormatMessage } from '@src/i18n';
import { SuggestedCarrierProductMapping } from '@src/models';
import { configSelectors } from '@src/modules/config';
import { useSelector } from '@src/utils/hooks';
import { Switch, Table } from 'antd';
import * as React from 'react';
import { style as tss } from 'typestyle';
import { selectGlobalFetching } from '../utils';
import { useCreateCarrierProductMappingMutation } from './mutations';

type Props = {
  onSetClicked: (carrierProductRef: string) => void;
  productMappingList: SuggestedCarrierProductMapping[];
  isLoading: boolean;
};

export const AutogeneratedCarrierProductMappingsTable: React.FC<Props> = ({
  onSetClicked,
  productMappingList,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();
  const isGlobalFetching = useSelector(selectGlobalFetching);

  const isCreatingDraft = useSelector(configSelectors.getIsCreatingDraft);

  const createCarrierProductMappingMutation = useCreateCarrierProductMappingMutation();

  return (
    <Table<SuggestedCarrierProductMapping>
      className={styles.table}
      dataSource={productMappingList}
      pagination={false}
      loading={isCreatingDraft || isGlobalFetching || isLoading}
      rowKey="carrier_product_ref"
    >
      <Table.Column<SuggestedCarrierProductMapping>
        title={formatMessage('CARRIER_PRODUCT_REF')}
        dataIndex="carrier_product_ref"
        key="carrier_product_ref"
        defaultSortOrder="ascend"
        sorter={(mappingA, mappingB) =>
          mappingA.carrier_product_ref.localeCompare(mappingB.carrier_product_ref)
        }
        width={270}
      />
      <Table.Column<SuggestedCarrierProductMapping>
        title={formatMessage('CARRIER_PRODUCT', { multiple: false })}
        dataIndex="carrier_product_id"
        key="carrier_product_id"
        width={250}
        render={(_, { carrier_product_carrier, carrier_product_name, carrier_product_id }) => {
          if (!carrier_product_id) {
            return '—';
          }
          if (!carrier_product_carrier && !carrier_product_name) {
            return carrier_product_id;
          }
          return `${carrier_product_carrier} ${carrier_product_name}`;
        }}
      />
      <Table.Column<SuggestedCarrierProductMapping>
        title={formatMessage('TRACKING_CONFIG_STATUS')}
        key="tracking_config_status"
        render={() => <span>&mdash;</span>}
      />
      <Table.Column<SuggestedCarrierProductMapping>
        title={formatMessage('ALWAYS_SHOW_TRACKING_LINKS')}
        key="always_show_tracking_links"
        render={(_, record) => {
          return <Switch disabled checked={record.always_show_tracking_links} />;
        }}
      />
      <Table.Column<SuggestedCarrierProductMapping>
        title={formatMessage('ACTIONS')}
        key="actions"
        render={(_, record) => (
          <a
            onClick={e => {
              e.preventDefault();
              if (record.carrier_product_id) {
                createCarrierProductMappingMutation.mutate({
                  carrier_product_ref: record.carrier_product_ref,
                  carrier_product_id: record.carrier_product_id,
                  custom: false,
                  always_show_tracking_links: record.always_show_tracking_links || false,
                });
              } else {
                onSetClicked(record.carrier_product_ref);
              }
            }}
          >
            {record.carrier_product_id ? 'Apply' : 'Set'}
          </a>
        )}
      />
    </Table>
  );
};

const styles = {
  table: tss({
    $nest: {
      '.ant-table-tbody tr td': {
        borderBottom: '1px solid rgba(0,0,0,0.09)',
      },
      '.ant-table-tbody tr:hover td.group-header': {
        background: 'unset',
      },
      '.ant-table-thead tr th': {
        background: 'rgba(0,0,0,0.02)',
        borderBottom: '1px solid rgba(0,0,0,0.09)',
      },
    },
  }),
};
