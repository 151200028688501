import * as React from 'react';
import { style as tss } from 'typestyle';

interface Props {
  id: string;
  index: number;
}

export const ExternalID: React.FunctionComponent<Props> = ({ id, index }) => {
  const idFragments = id
    .split(/[,;]/)
    .map(fragment => fragment.trim())
    .filter(fragment => fragment !== '');
  return (
    <div className={styles.flexContainer}>
      <div className={styles.header}>{`External ID ${index}:`}</div>
      <div>
        {idFragments.map((idFragment, idx) => (
          <div className={styles.fragment} key={`${idFragment}-${idx}`}>
            {idFragment}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  flexContainer: tss({
    display: 'flex',
    paddingBottom: '10px',
  }),
  header: tss({
    fontWeight: 'bold',
    paddingRight: '10px',
    lineHeight: '24px',
  }),
  fragment: tss({
    lineHeight: '24px',
  }),
};
