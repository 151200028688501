import jwtDecode from 'jwt-decode';
import { any, curry, propEq } from 'ramda';
import { isNotEmpty } from 'ramda-adjunct';
import { createSelector } from 'reselect';

import { AuthenticationType, TokenClaims, UserGetModel } from '@src/models';
import { RootState } from '../root-reducer';
import { Route } from '../router/types';
import { SitesSelectors } from '../sites';
import { EnabledDashboards } from '@src/containers/dashboard';

export const getCurrentTimestamp = (state: RootState) => Date.now();
export const getAuth = (state: RootState) => state.auth;

export const getAuthToken = createSelector(
  (state: RootState) => state,
  state => state.auth.authToken
);

export const getAuthTokenClaims = createSelector(getAuthToken, authToken => {
  let result: TokenClaims | undefined;

  if (authToken) {
    result = (jwtDecode(authToken) as TokenClaims) || undefined;
  }

  return result;
});

export const isAuthTokenExpired = createSelector(
  getAuthTokenClaims,
  getCurrentTimestamp,
  (claims, timeStamp) => {
    const MILLISECONDS = 1000;
    const expirationTimestamp = claims && claims.exp;

    if (expirationTimestamp == null) {
      return true;
    }

    return timeStamp > expirationTimestamp * MILLISECONDS;
  }
);

export const getAuthUser = createSelector(getAuth, auth => {
  return auth?.authUser;
});

export const getCurrentUserType = createSelector(
  getAuthUser,
  currentUser =>
    currentUser && (currentUser.superUser || UserGetModel.getUserGroup(currentUser.groups).name)
);

export const getAuthUserId = createSelector(getAuthUser, currentUser => {
  return currentUser ? currentUser.id : '';
});

export const isAuthUserSuperUser = createSelector(
  getAuthUser,
  currentUser => currentUser && currentUser.superUser
);

export const isAuthUserConfigUser = createSelector(
  getCurrentUserType,
  currentUserGroup => currentUserGroup === 'configEditor'
);

export const isAuthUserEnterpriseUser = createSelector(getAuthUser, currentUser =>
  currentUser ? currentUser.authenticationType === AuthenticationType.OTHER : false
);

export const getAccountTypes = createSelector(isAuthUserEnterpriseUser, isEnterpriseUser => {
  if (isEnterpriseUser) {
    return [{ label: 'Enterprise (ADFS)', value: AuthenticationType.OTHER }];
  } else {
    return [
      { label: 'Email / Password', value: AuthenticationType.PASSWORD },
      { label: 'Enterprise (ADFS)', value: AuthenticationType.OTHER },
    ];
  }
});

export const getUserRoles = createSelector(getAuthUser, user => (user ? user.roles || [] : []));

const insertIf = <T>(condition: boolean, ...elements: T[]) => {
  return condition ? elements : ([] as T[]);
};

export const getHasAccessTo = createSelector(
  getUserRoles,
  isAuthUserSuperUser,
  (roles, isSuperUser) =>
    curry((userRoles: typeof roles, to: string) => {
      if (isSuperUser) {
        return true;
      } else {
        return any(propEq('name', to), userRoles);
      }
    })(roles)
);

type GetSideMenuOptions = {
  enabledDashboards?: EnabledDashboards;
};

export const getSideMenuByUserRole = createSelector(
  isAuthUserSuperUser,
  SitesSelectors.getSelectedSiteBookingEnabled,
  getHasAccessTo,
  (_state: any, options: GetSideMenuOptions | undefined) => options,
  (
    isSuperUser,
    isBookingEnabled,
    hasAccessTo,
    options
  ): {
    group?: { label: string; route?: Route };
    items: { key: string; label: string; icon: string; route: Route }[];
  }[] => {
    return [
      {
        items: [
          ...insertIf(hasAccessTo('mad:transport-orders'), {
            key: 'transport-orders',
            label: 'Transport Orders',
            icon: 'AppstoreOutlined',
            route: { name: 'TRANSPORT_ORDER_LIST' } as const,
          }),
        ],
      },
      {
        group: {
          label: 'Transport Administration',
          route: options?.enabledDashboards?.transport_administration
            ? ({ name: 'TRANSPORT_ADMINISTRATION_DASHBOARD' } as const)
            : undefined,
        },
        items: [
          ...insertIf(hasAccessTo('mad:shipments') && isBookingEnabled, {
            key: 'shipments',
            label: 'Shipments',
            icon: 'InboxOutlined',
            route: { name: 'SHIPMENT_LIST' } as const,
          }),
          ...insertIf(hasAccessTo('mad:watchlists') && isBookingEnabled, {
            key: 'watchlists',
            label: 'Watchlists',
            icon: 'BookOutlined',
            route: { name: 'WATCHLIST_LIST' } as const,
          }),
          ...insertIf(hasAccessTo('mad:templates') && isBookingEnabled, {
            key: 'templates',
            label: 'Templates',
            icon: 'FileTextOutlined',
            route: { name: 'TEMPLATE_LIST' } as const,
          }),
          ...insertIf(hasAccessTo('mad:addresses') && isBookingEnabled, {
            key: 'address-book',
            label: 'Addresses',
            icon: 'BookOutlined',
            route: { name: 'ADDRESS_BOOK' } as const,
          }),
          ...insertIf(hasAccessTo('mad:tags') && isBookingEnabled, {
            key: 'user-tags',
            label: 'User Tags',
            icon: 'TagsOutlined',
            route: { name: 'TAGS_LIST' } as const,
          }),
          ...insertIf(hasAccessTo('mad:site-config') && isBookingEnabled, {
            key: 'booking-rules',
            label: 'Booking rules',
            icon: 'ControlOutlined',
            route: { name: 'BOOKING_RULES' } as const,
          }),
        ],
      },
      {
        group: { label: 'Product Page' },
        items: [
          ...insertIf(hasAccessTo('mad:product-page-widget'), {
            key: 'product-page-features',
            label: 'Features',
            icon: 'GlobalOutlined',
            route: { name: 'PRODUCT_PAGE_FEATURES' } as const,
          }),
          ...insertIf(hasAccessTo('mad:product-page-widget'), {
            key: 'product-page-widget',
            label: 'Test product page widget',
            icon: 'ShoppingOutlined',
            route: { name: 'PRODUCT_PAGE_WIDGET_TEST_CLIENT' } as const,
          }),
        ],
      },
      {
        group: {
          label: 'Delivery checkout',
          route: options?.enabledDashboards?.checkout
            ? ({ name: 'CHECKOUT_DASHBOARD' } as const)
            : undefined,
        },
        items: [
          ...insertIf(hasAccessTo('mad:site-config'), {
            key: 'features',
            label: 'Features',
            icon: 'GlobalOutlined',
            route: { name: 'COUNTRY_LIST' } as const,
          }),
          ...insertIf(hasAccessTo('mad:site-config'), {
            key: 'regions',
            label: 'Regions',
            icon: 'CompassOutlined',
            route: { name: 'REGIONS_LIST' } as const,
          }),
          ...insertIf(hasAccessTo('mad:site-config'), {
            key: 'warehouses',
            label: 'Warehouses',
            icon: 'ShopOutlined',
            route: { name: 'WAREHOUSES_LIST' } as const,
          }),
          ...insertIf(hasAccessTo('mad:experiments'), {
            key: 'experiments',
            label: 'Experiments',
            icon: 'ExperimentOutlined',
            route: { name: 'EXPERIMENT_LIST' } as const,
          }),
          ...insertIf(hasAccessTo('mad:checkout-widget'), {
            key: 'checkout-widget',
            label: 'Test checkout widget',
            icon: 'ShoppingCartOutlined',
            route: { name: 'CHECKOUT_WIDGET_TEST_CLIENT' } as const,
          }),
        ],
      },
      {
        group: { label: 'Delivery upsell' },
        items: [
          ...insertIf(hasAccessTo('mad:upsell-widget'), {
            key: 'upsell-features',
            label: 'Features',
            icon: 'GlobalOutlined',
            route: { name: 'UPSELL_FEATURES' } as const,
          }),
          ...insertIf(hasAccessTo('mad:upsell-widget'), {
            key: 'upsell-widget',
            label: 'Test upsell widget',
            icon: 'DashboardOutlined',
            route: { name: 'UPSELL_WIDGET_TEST_CLIENT' } as const,
          }),
        ],
      },
      {
        group: { label: 'Delivery FAQ' },
        items: [
          ...insertIf(hasAccessTo('mad:faq-widget'), {
            key: 'faq-features',
            label: 'Features',
            icon: 'GlobalOutlined',
            route: { name: 'FAQ_WIDGET_FEATURES' } as const,
          }),
          ...insertIf(hasAccessTo('mad:faq-widget'), {
            key: 'faq-widget',
            label: 'Test FAQ widget',
            icon: 'ScheduleOutlined',
            route: { name: 'FAQ_WIDGET_TEST_CLIENT' } as const,
          }),
        ],
      },
      {
        group: { label: 'Delivery receipt' },
        items: [
          ...insertIf(hasAccessTo('mad:receipt-widget'), {
            key: 'receipt-features',
            label: 'Features',
            icon: 'GlobalOutlined',
            route: { name: 'RECEIPT_FEATURES' } as const,
          }),
          ...insertIf(hasAccessTo('mad:receipt-widget'), {
            key: 'receipt-widget',
            label: 'Test receipt widget',
            icon: 'ShoppingOutlined',
            route: { name: 'RECEIPT_WIDGET_TEST_CLIENT' } as const,
          }),
        ],
      },
      {
        group: {
          label: 'Delivery tracking',
          route: options?.enabledDashboards?.tracking
            ? ({ name: 'TRACKING_DASHBOARD' } as const)
            : undefined,
        },

        items: [
          ...insertIf(hasAccessTo('tracking-feature:view'), {
            key: 'product-mappings',
            label: 'Product mappings',
            icon: 'ScheduleOutlined',
            route: { name: 'PRODUCT_MAPPINGS' } as const,
          }),
          ...insertIf(hasAccessTo('mad:tracking_numbers'), {
            key: 'tracking-numbers',
            label: 'Tracking numbers',
            icon: 'ProjectOutlined',
            route: { name: 'TRACKING_NUMBERS' } as const,
          }),
          ...insertIf(hasAccessTo('tracking-feature:view'), {
            key: 'notifications',
            label: 'Notifications',
            icon: 'MailOutlined',
            route: { name: 'NOTIFICATIONS' } as const,
          }),
          ...insertIf(hasAccessTo('mad:tracking-widget'), {
            key: 'tracking-playground',
            label: 'Tracking playground',
            icon: 'EnvironmentOutlined',
            route: { name: 'TRACKING_PLAYGROUND' } as const,
          }),
        ],
      },
      {
        group: { label: 'Administration' },
        items: [
          ...insertIf(hasAccessTo('mad:users'), {
            key: 'users',
            label: 'Users',
            icon: 'UserOutlined',
            route: { name: 'USERS' } as const,
          }),
          ...insertIf(!!isSuperUser, {
            key: 'groups',
            label: 'Groups',
            icon: 'TeamOutlined',
            route: { name: 'GROUPS_LIST' } as const,
          }),
          ...insertIf(!!isSuperUser, {
            key: 'session-events',
            label: 'Session Events',
            icon: 'HistoryOutlined',
            route: { name: 'SESSION_EVENTS' } as const,
          }),
          ...insertIf(!!isSuperUser, {
            key: 'merchants',
            label: 'Merchants',
            icon: 'RobotOutlined',
            route: { name: 'MERCHANTS_LIST' } as const,
          }),
        ],
      },
    ].filter(menu => isNotEmpty(menu.items) || menu.group?.route);
  }
);

export const canGetPrivateKey = createSelector(getHasAccessTo, hasAccessTo =>
  hasAccessTo('privatekey:view')
);
