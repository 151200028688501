import { Button } from 'antd';
import { Form, Formik, useField } from 'formik';
import React, { FC } from 'react';
import { stylesheet } from 'typestyle';

import { Input } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { LABELS } from '@src/dictionaries';
import { useFormatMessage } from '@src/i18n';
import { bigLabelLayout } from '@src/forms';
import { getFormikError } from '@src/utils/forms';
import { FormValues } from './types';
import { validationSchema } from './validation-schema';

type Props = {
  onSubmit: (onSubmit: FormValues) => void;
};

export const FindTrackingForm: FC<Props> = ({ onSubmit }) => {
  const formatMessage = useFormatMessage();

  return (
    <Formik
      initialValues={{ trackingNumber: '', externalId: '', contact: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <FormikInputField name="trackingNumber" label={formatMessage('TRACKING_NUMBER')} />
        <FormikInputField name="externalId" label={formatMessage('EXTERNAL_ID')} />
        <FormikInputField name="contact" label={formatMessage('CUSTOMER_EMAIL')} />
        <div className={styles.buttonWrapper}>
          <Button type="primary" htmlType="submit">
            {LABELS.SUBMIT}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

const InputField = withFormItem(Input, bigLabelLayout);

type FormikInputFieldProps = {
  name: string;
  label: string;
};

const FormikInputField: FC<FormikInputFieldProps> = ({ name, label }) => {
  const [field, meta] = useField(name);

  return <InputField {...field} label={label} error={getFormikError(meta.touched, meta.error)} />;
};

const styles = stylesheet({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
});
