import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import * as React from 'react';
import { Formik } from 'formik';

import { useFormatMessage } from '@src/i18n';

import { TrackingRecordFiltering } from './types';

const { Search } = Input;

type Props = {
  searchValue: string | undefined;
  onSearch: (newSearchValue: TrackingRecordFiltering['id']) => void;
};

export const FilteringSearchBar: React.FC<Props> = ({ searchValue, onSearch }) => {
  const formatMessage = useFormatMessage();

  return (
    <Formik<{ id: TrackingRecordFiltering['id'] }>
      initialValues={{ id: searchValue }}
      onSubmit={values => onSearch(values.id)}
    >
      {formik => (
        <Search
          id="id"
          allowClear
          value={formik.values.id}
          prefix={<SearchOutlined />}
          placeholder={formatMessage('TRACKING_NUMBERS.SEARCH_PLACEHOLDER')}
          enterButton={<Button>{formatMessage('TRACKING_NUMBERS.SEARCH')}</Button>}
          onChange={formik.handleChange}
          onSearch={() => formik.submitForm()}
        />
      )}
    </Formik>
  );
};
