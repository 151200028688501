import { FieldState, FormState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';

import { services } from '@src/services';
import { Select } from '../controls';
import { withFieldStateSelect, withFormItem } from '../decorators';
import { formFieldLayout } from '../forms/form-layouts';
import { requiredSelectFieldWithMessage } from '../utils/validation';

const SelectField = withFormItem(withFieldStateSelect(Select));

export class CountrySelectorFormState extends FormState<{
  countryFieldState: FieldState<string>;
}> {
  static create = (country: string = ''): CountrySelectorFormState => {
    return new FormState({
      countryFieldState: new FieldState(country).validators(
        requiredSelectFieldWithMessage('Field is required')
      ),
    });
  };
}

// COMPONENT

type Props = {
  formState: CountrySelectorFormState;
  autoFocus?: boolean;
  required?: boolean;
};

@observer
export class CountrySelectorForm extends React.Component<Props, {}> {
  render() {
    const { autoFocus, required = false } = this.props;
    const { countryFieldState } = this.props.formState.$;
    return (
      <SelectField
        required={required}
        autoFocus={autoFocus}
        label="Country"
        placeholder="please select..."
        fieldState={countryFieldState}
        error={countryFieldState.error}
        options={services.dictionariesService.getCountriesOptions()}
        showSearch={true}
        caseInsensitiveSearch={true}
        className="t_country-selector"
        dropdownClassName="t_country-selector-dropdown"
        {...formFieldLayout}
      />
    );
  }
}
