import { services } from '@src/services';
import type { ColumnType } from 'antd/lib/table';
import type { CustomsDeclarationItem } from '../types';

export const columns: ColumnType<CustomsDeclarationItem>[] = [
  {
    title: 'Amount',
    dataIndex: 'quantity',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
  },
  {
    title: 'HS Tariff Code',
    dataIndex: 'hs_tariff_number',
  },
  {
    title: 'Category',
    dataIndex: 'category',
  },
  {
    title: 'Value',
    dataIndex: 'unit_value',
  },
  {
    title: 'Net/Gross weight (g)',
    width: 200,
    dataIndex: 'unit_gross_weight',
    render: (_, { unit_net_weight, unit_gross_weight }) =>
      `${unit_net_weight ?? '-'}g/${unit_gross_weight ?? '-'}g`,
  },
  {
    title: 'Country of origin',
    dataIndex: 'country_of_origin',
    render: (_, { country_of_origin }) => {
      const countries = services.dictionariesService.getAllCountries();

      return countries.find(({ code }) => code === country_of_origin)?.name;
    },
  },
];
