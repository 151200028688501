import { hasParam, updateParamWithValue } from '@src/utils/url';
import { pipe, replace } from 'ramda';

import { store } from '@src/store';
import { RouterPaths } from './paths';
import { Route } from './types';

function withParamsFromReduxState(path: string) {
  const selectedMerchantId = store.getState().merchants.selectedMerchantId || '';
  const selectedSiteId = store.getState().sites.selectedSiteId || '';

  let mutatedPath = path;
  if (hasParam('merchants', mutatedPath)) {
    mutatedPath = updateParamWithValue('merchants', selectedMerchantId, mutatedPath);
  }
  if (hasParam('sites', mutatedPath)) {
    mutatedPath = updateParamWithValue('sites', selectedSiteId, mutatedPath);
  }

  return mutatedPath;
}

export const getFullPathForRoute = (route: Route) => {
  return withParamsFromReduxState(
    (() => {
      switch (route.name) {
        case 'TRANSPORT_ORDER_DETAILS':
          return RouterPaths.TRANSPORT_ORDER_DETAILS.replace(':transportOrderId', route.tosId);

        case 'SHIPMENT_EDIT':
          return RouterPaths.SHIPMENT_EDIT.replace(':shipmentId', route.shipmentId).replace(
            ':tosId?/',
            route.tosId ? route.tosId + '/' : ''
          );

        case 'SHIPMENT_BOOK_AND_PRINT':
          return pipe(
            replace(':parcelId', route.parcelId),
            replace(':shipmentId', route.shipmentId),
            replace(':tosId', route.tosId)
          )(RouterPaths.SHIPMENT_BOOK_AND_PRINT);

        case 'SHIPMENT_CREATE':
          return pipe(
            replace(':tosId?/', route.tosId ? route.tosId + '/' : ''),
            replace('/:return?', route.return ? '?return=true' : '')
          )(RouterPaths.SHIPMENT_CREATE);

        case 'ADDRESS_BOOK_EDIT_CONTACT':
          return RouterPaths.ADDRESS_BOOK_EDIT_CONTACT.replace(':contactId', route.contactId);

        case 'TEMPLATE_EDIT':
          return RouterPaths.TEMPLATE_EDIT.replace(':templateId', route.templateId);

        case 'EXPERIMENT_DETAILS':
          return RouterPaths.EXPERIMENT_DETAILS.replace(':experimentId', route.experimentId);

        case 'REGION_DETAILS':
          return RouterPaths.REGION_DETAILS.replace(':regionId', route.regionId);

        case 'WAREHOUSE_DETAILS':
          return RouterPaths.WAREHOUSE_DETAILS.replace(':warehouseId', route.warehouseId);

        case 'COUNTRY_DETAILS':
          return RouterPaths.COUNTRY_DETAILS.replace(':country', route.country);

        case 'UPSELL_COUNTRY_DETAILS':
          return RouterPaths.UPSELL_COUNTRY_DETAILS.replace(':country', route.country);

        case 'FAQ_WIDGET_COUNTRY_DETAILS':
          return RouterPaths.FAQ_WIDGET_COUNTRY_DETAILS.replace(':country', route.country);

        case 'PRODUCT_PAGE_COUNTRY_DETAILS':
          return RouterPaths.PRODUCT_PAGE_COUNTRY_DETAILS.replace(':country', route.country);

        case 'USERS_EDIT':
          return RouterPaths.USERS_EDIT.replace(':userId', route.userId);

        case 'MERCHANT_DETAILS':
          return RouterPaths.MERCHANT_DETAILS.replace(
            ':detailsMerchantId',
            route.detailsMerchantId
          );

        case 'TRACKING_NUMBER_DETAILS':
          return RouterPaths.TRACKING_NUMBER_DETAILS.replace(
            ':trackingNumber',
            route.trackingNumber
          );

        case 'RECEIPT_COUNTRY_SETTINGS':
          return RouterPaths.RECEIPT_COUNTRY_SETTINGS.replace(':country', route.country);

        default:
          return RouterPaths[route.name] || '';
      }
    })()
  );
};
