import { ProjectOutlined } from '@ant-design/icons';
import { ListFixedHeader } from '@src/components';
import { Card } from '@src/controls';
import { DOMAIN, LABELS } from '@src/dictionaries';
import { routerActions } from '@src/modules/router';
import { SitesSelectors } from '@src/modules/sites';
import { services } from '@src/services';
import { useDispatch, useSelector } from '@src/utils/hooks';
import { Table } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import moment from 'moment';
import React, { FC, Fragment } from 'react';
import { useQuery } from 'react-query';
import { style as tss } from 'typestyle';
import { columns } from './columns';
import { Pagination, TrackingNumbersHeader } from './components';
import { TrackingPreviewDrawer } from './tracking-preview-drawer/tracking-preview-drawer';
import { TrackingNumbersFiltering } from './tracking-numbers-filtering';
import { TrackingRecord } from './types';
import { useShowTrackingPreviewDrawer } from './use-show-preview-drawer';
import { useTrackingNumberState } from './use-tracking-number-state';
import { sourceOptions } from '@src/containers/tracking-numbers/source-options';
import { fetchTrackingRecordsCount } from '@src/services/tracking-numbers-service';

const { fetchTrackingNumbers } = services.trackingNumberService;

export const TrackingNumbers: FC = () => {
  const dispatch = useDispatch();

  const [isDrawerVisible, drawerData, showDrawerWith, closeDrawer] = useShowTrackingPreviewDrawer();
  const [trackingNumbersState, trackingNumbersDispatch] = useTrackingNumberState();
  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);

  const { pagination, filters, search, orderByDirection } = trackingNumbersState;
  const { limit } = pagination;

  const allFilterSourcesSelected = sourceOptions.map(source => source.value);

  const chooseFilterSources = () => {
    if (filters.sources) {
      if (filters.sources.length !== 0) {
        return filters.sources;
      } else return allFilterSourcesSelected;
    } else return allFilterSourcesSelected;
  };

  const trackingNumbersData = useQuery({
    queryKey: ['trackingNumbers', pagination, search, filters, orderByDirection, siteId],
    queryFn: () =>
      fetchTrackingNumbers({
        pagination,
        order_by_direction: orderByDirection,
        siteId,
        filtering: {
          ...filters,
          id: search,
          sources: chooseFilterSources(),
          created_at_range: {
            start: filters.created_at_range?.start || moment('1970-01-01').format(),
            end: filters.created_at_range?.end || moment().format(),
          },
          ...(filters.updated_at_range
            ? {
                updated_at_range: {
                  start: filters.updated_at_range?.start || moment('1970-01-01').format(),
                  end: filters.updated_at_range?.end || moment().format(),
                },
              }
            : {}),
        },
      }),
  });

  const trackingNumbersCount = useQuery({
    queryKey: ['trackingRecordsCount', filters, siteId],
    queryFn: () => {
      if (Object.keys(filters).length > 0)
        return fetchTrackingRecordsCount(
          {
            ...filters,
            sources: chooseFilterSources(),
          },
          siteId
        );
      return {
        tracking_records_count: {
          count_value: undefined,
        },
      };
    },
  });

  const nextPageToken = trackingNumbersData.data?.cursor?.next_page;

  const previousPageToken = trackingNumbersData.data?.cursor?.previous_page;

  const goToDetails = (trackingNumber: string) =>
    dispatch(
      routerActions.push({
        name: 'TRACKING_NUMBER_DETAILS',
        // workaroud for `history` package issue: https://github.com/remix-run/history/issues/505
        // TODO: update react-router-dom to at least v6 as an ultimate solution - TRACK-1051

        // double encode trackingNumer to make sure the URL param is actually encoded
        trackingNumber: encodeURIComponent(encodeURIComponent(trackingNumber)),
        // passing additional state to avoid unconsistent history.push encoding/decoding and ensure constant value
        additionalState: {
          trackingNumber,
        },
      })
    );

  const onNextPage = () => {
    trackingNumbersDispatch({
      type: 'SET_PAGE',
      payload: {
        search_before: '',
        search_after: nextPageToken,
      },
    });
  };

  const onOrderDirectionChange = () =>
    trackingNumbersDispatch({
      type: 'TOGGLE_DIRECTION',
      payload: { search_after: '', search_before: '' },
    });

  const onPrevPage = () =>
    trackingNumbersDispatch({
      type: 'SET_PAGE',
      payload: {
        search_after: '',
        search_before: previousPageToken,
      },
    });

  const onLimitChanged = (newLimit: number) => {
    trackingNumbersDispatch({ type: 'SET_LIMIT', payload: { limit: newLimit } });
    trackingNumbersDispatch({
      type: 'SET_PAGE',
      payload: {
        search_after: '',
        search_before: '',
      },
    });
  };

  const isNextDisabled = Boolean(trackingNumbersData.data?.cursor?.next_page);

  const isPrevDisabled = Boolean(trackingNumbersData.data?.cursor?.previous_page);

  const isFetching = trackingNumbersData.isFetching;

  return (
    <Fragment>
      <ListFixedHeader
        title={DOMAIN.TRACKING_NUMBERS}
        IconComponent={ProjectOutlined}
        isLoading={false}
        searchPlaceholder={LABELS.TRACKING_NUMBERS_SEARCH_PLACEHOLDER}
        showSearch={false}
      />
      <TrackingNumbersFiltering
        searchValue={search}
        filterValues={filters}
        onSearch={newSearchValue =>
          trackingNumbersDispatch({ type: 'SET_SEARCH', payload: { search: newSearchValue } })
        }
        onFilter={newFilterValues =>
          trackingNumbersDispatch({ type: 'SET_FILTERING', payload: { filters: newFilterValues } })
        }
        onClearFilters={() =>
          trackingNumbersDispatch({ type: 'CLEAR_FILTERS', payload: undefined })
        }
      />
      <Card bordered={false}>
        <div className={styles.header}>
          <TrackingNumbersHeader
            onClearFilters={() =>
              trackingNumbersDispatch({ type: 'CLEAR_FILTERS', payload: undefined })
            }
            filters={filters}
          />
          <Pagination
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            isNextDisabled={!isNextDisabled}
            isPrevDisabled={!isPrevDisabled}
            limit={limit}
            onLimitChanged={onLimitChanged}
            recordsCount={trackingNumbersCount.data?.tracking_records_count?.count_value}
            isRecordsCountLoading={trackingNumbersCount.isLoading}
          />
        </div>
        <Table
          onRow={({ tracking_number }) => ({
            onClick: () => goToDetails(tracking_number),
          })}
          onChange={(_, __, { order }: SorterResult<TrackingRecord>) => {
            if (order && order !== orderByDirection) {
              onOrderDirectionChange();
            }
          }}
          size="small"
          loading={isFetching}
          rowKey="tracking_number"
          dataSource={trackingNumbersData.data?.tracking_records}
          columns={columns(orderByDirection, showDrawerWith)}
          pagination={false}
          rowClassName={styles.antRowStyles}
        />
      </Card>
      <TrackingPreviewDrawer
        isVisible={isDrawerVisible}
        drawerData={drawerData}
        onClose={closeDrawer}
      />
    </Fragment>
  );
};

const styles = {
  antRowStyles: tss({
    $nest: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
  header: tss({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  }),
};
