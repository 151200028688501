import { routerActions } from '@src/modules/router';
import * as React from 'react';
import { connect } from 'react-redux';
import { services } from '../services';

const MISSING_ERROR = 'Error was swallowed during propagation.';
const env = services.envService.getEnvironmentalVariables();

interface IWrappedComponentProps {
  onReset: () => void;
}

interface IState {
  error: Error | null | undefined;
}

const dispatchProps = {
  reactRouterPush: routerActions.push,
};

export function withErrorBoundary(WrappedComponent: React.ComponentType<IWrappedComponentProps>) {
  const HOC = class extends React.Component<
    typeof dispatchProps & { children: React.ReactChild },
    IState
  > {
    state: IState = {
      error: undefined,
    };

    componentDidCatch(error: Error | null, info: React.ErrorInfo) {
      this.setState({ error: error || new Error(MISSING_ERROR) });
      this.logErrorToCloud(error, info);
    }

    logErrorToCloud = (error: Error | null, info: React.ErrorInfo) => {
      switch (env.MAD_DEPLOY_TARGET) {
        case 'production':
        case 'stage':
          if (error) {
            services.errorsLoggingService.sendError(error);
          }
          break;

        default:
          // tslint:disable-next-line:no-console
          console.info({ error, info });
          break;
      }
    };

    handleReset = () => {
      this.setState({ error: undefined });
      this.props.reactRouterPush({ name: 'HOME' });
      location.reload();
    };

    render() {
      const { children } = this.props;
      const { error } = this.state;

      if (error) {
        return <WrappedComponent onReset={this.handleReset} />;
      }

      return children;
    }
  };

  return connect(null, dispatchProps)(HOC);
}
