import { AppIntlProvider } from '@src/i18n';
import { BaseLayer, RouterLayer } from '@src/layers';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import './styles/antd.default.less';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { browserHistory, store } from './store';
import { services } from './services';
import { Posthog } from './containers/posthog/posthog';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const env = services.envService.getEnvironmentalVariables();

export class Wrapper extends React.Component {
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <AppIntlProvider>
          <Provider store={store}>
            <Posthog />
            <DndProvider backend={HTML5Backend}>
              <ConnectedRouter history={browserHistory}>
                <BaseLayer>
                  <RouterLayer />
                </BaseLayer>
              </ConnectedRouter>
            </DndProvider>
          </Provider>
        </AppIntlProvider>
        <ReactQueryDevtools
          initialIsOpen={false}
          position="bottom-right"
          toggleButtonProps={{ style: { position: 'fixed', bottom: 80, right: 20 } }}
        />
      </QueryClientProvider>
    );
  }
}

export const App = withLDProvider({ clientSideID: env.MAD_LAUNCHDARKLY_CLIENT_SIDE_ID })(Wrapper);
