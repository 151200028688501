import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { classes, style as tss } from 'typestyle';
import { number, object, ref } from 'yup';

import { InputNumber, Select, Switch, Tooltip } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveFormikEnhancer, inputStyle } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { CarrierProductModel, DeliveryTimeUnit } from '@src/models';
import { updateCarrierProductRequest } from '@src/modules/config/actions';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { getFormikError } from '@src/utils/forms';

const SDALayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
  colon: false,
};

const NumberField = withFormItem(InputNumber, SDALayout);
const SelectField = withFormItem(Select, SDALayout);

interface Props {
  regionId: string;
  product: CarrierProductModel;
}

interface FormValues {
  SDAStart?: number;
  SDAEnd?: number;
  unit: DeliveryTimeUnit;
}

export const ShippingDateAdjustments: React.FunctionComponent<Props> = ({ product, regionId }) => {
  const [addingAdjustments, setAddingAdjustments] = React.useState(
    !!product.shippingDateAdjustment?.adjustStart || !!product.shippingDateAdjustment?.adjustEnd
  );

  const updateSDA = (values: FormValues) =>
    dispatchOnDraftSiteId(updateCarrierProductRequest)({
      model: {
        regionId,
        method: product.shippingMethod,
        shippingDateAdjustment: {
          unit: values.unit,
          adjustStart: values.SDAStart,
          adjustEnd: values.SDAEnd,
        },
        deliveryTypes: product.deliveryTypes,
      },
    });

  return (
    <Formik
      initialValues={{
        SDAStart: product.shippingDateAdjustment?.adjustStart ?? 0,
        SDAEnd: product.shippingDateAdjustment?.adjustEnd ?? 0,
        unit: product.shippingDateAdjustment?.unit || 'day',
      }}
      validationSchema={() => {
        return object().shape({
          SDAStart: number()
            .integer('Adjustments can only be whole numbers')
            .max(ref('SDAEnd'), 'Start date adjustment cannot be bigger than end date adjustment'),
          SDAEnd: number()
            .integer('Adjustments can only be whole numbers')
            .min(ref('SDAStart'), 'End date adjustment cannot be lower than start date adjustment'),
        });
      }}
      validateOnBlur={true}
      // Values should be stale until next mount
      enableReinitialize={false}
      onSubmit={() => undefined}
    >
      {formik => (
        <Row>
          <Col span={6}>
            <div className={styles.label}>
              <FormattedMessage id="SHIPPING_DATE_ADJUSTMENT" />
              <Tooltip title={<FormattedMessage id="SHIPPING_DATE_ADJUSTMENT_TOOLTIP" />}>
                <InfoCircleOutlined style={{ marginBottom: 'auto' }} />
              </Tooltip>
            </div>
          </Col>
          <Col span={18}>
            <Switch
              checked={addingAdjustments}
              onChange={nextSwitchState => {
                setAddingAdjustments(nextSwitchState);
                if (nextSwitchState === false) {
                  updateSDA({
                    SDAStart: 0,
                    SDAEnd: 0,
                    unit: 'day',
                  });
                } else {
                  if (formik.values.SDAStart || formik.values.SDAEnd) {
                    updateSDA(formik.values);
                  }
                }
              }}
              className={styles.switch}
            />
            {addingAdjustments && (
              <div className={styles.SDAWrapper}>
                <AutoSaveFormikEnhancer
                  name="unit"
                  onSave={values => updateSDA(values)}
                  render={({ onInstantChange }) => (
                    <Field name="unit">
                      {({ field }: FieldProps<FormValues['unit']>) => (
                        <SelectField
                          {...field}
                          onChange={(value: string) => onInstantChange(value)}
                          colon={true}
                          options={[
                            { value: 'day', label: 'Day (00:00)' },
                            { value: 'full_day', label: 'Full day (+24h)' },
                            {
                              value: 'business_day',
                              label: 'Business day (00:00, Mon-Fri)',
                            },
                            {
                              value: 'full_business_day',
                              label: 'Full business day (+24h, Mon-Fri)',
                            },
                            { value: 'hour', label: <FormattedMessage id="HOUR" /> },
                            { value: 'minute', label: <FormattedMessage id="MINUTE" /> },
                          ]}
                          label={<FormattedMessage id="UNIT" />}
                          className={classes(inputStyle, styles.unitPicker)}
                        />
                      )}
                    </Field>
                  )}
                />
                <AutoSaveFormikEnhancer
                  name="SDAStart"
                  onSave={values => updateSDA(values)}
                  validateAllFields={true}
                  render={({ onBlur, onKeyDown }) => (
                    <Field name="SDAStart">
                      {({ field }: FieldProps<FormValues['SDAStart']>) => (
                        <NumberField
                          {...field}
                          label="Adjust start date"
                          className={inputStyle}
                          value={formik.values.SDAStart || 0}
                          onBlur={onBlur}
                          onKeyDown={onKeyDown}
                          onChange={value => formik.setFieldValue('SDAStart', value)}
                          error={getFormikError(formik.touched.SDAStart, formik.errors.SDAStart)}
                        />
                      )}
                    </Field>
                  )}
                />
                <AutoSaveFormikEnhancer
                  name="SDAEnd"
                  onSave={values => updateSDA(values)}
                  validateAllFields={true}
                  render={({ onKeyDown, onBlur }) => (
                    <Field name="SDAEnd">
                      {({ field }: FieldProps<FormValues['SDAEnd']>) => (
                        <NumberField
                          {...field}
                          label="Adjust end date"
                          className={inputStyle}
                          value={formik.values.SDAEnd || 0}
                          onBlur={onBlur}
                          onKeyDown={onKeyDown}
                          onChange={value => formik.setFieldValue('SDAEnd', value)}
                          error={getFormikError(formik.touched.SDAEnd, formik.errors.SDAEnd)}
                        />
                      )}
                    </Field>
                  )}
                />
              </div>
            )}
          </Col>
        </Row>
      )}
    </Formik>
  );
};

const styles = {
  label: tss({
    color: 'rgba(0, 0, 0, 0.5)',
    top: '25px',
    display: 'flex',
  }),
  SDAWrapper: tss({
    $nest: {
      '.ant-form-item-label': {
        width: '150px !important',
      },
      '.ant-form-item-control.has-error .ant-form-explain': {
        paddingLeft: '0',
      },
    },
  }),
  deleteAdjustmentsButton: tss({
    paddingLeft: 0,
  }),
  dayLabel: tss({
    marginLeft: '5px',
    color: 'rgba(0, 0, 0, 0.5)',
    marginBottom: '8px',
    alignSelf: 'center',
  }),
  unitPicker: tss({
    width: '100%',
  }),
  switch: tss({
    $nest: {
      '&.ant-switch': {
        marginLeft: 0,
      },
    },
  }),
};
