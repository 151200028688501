export class BlobsModel {
  payload: string;

  static createFromDTO(dto: BlobsDTO): BlobsModel {
    return {
      payload: dto.payload,
    };
  }
}

export class BlobsDTO {
  payload: string;

  static createFromModel(model: BlobsModel): BlobsDTO {
    return {
      payload: model.payload,
    };
  }
}
