import { inputStyle } from '@src/forms';
import { listTrackingLinksForCarrierProduct } from '@src/services/selfcare-service';
import { useSiteId } from '@src/utils/hooks';
import { Col, Input, Row } from 'antd';
import * as React from 'react';
import { useQuery } from 'react-query';
import { classes, stylesheet } from 'typestyle';
import { buildTrackingLinksFormValue } from '../form-commons';
import { CONTENT_SPAN, LABEL_SPAN } from '../form-components/fields';

type Props = {
  carrierProductId: string;
  initialTrackingLinks?: { [locale: string]: string };
};

export const DefaultTrackingLinks: React.FC<Props> = ({
  carrierProductId,
  initialTrackingLinks,
}) => {
  const siteId = useSiteId();
  const { data } = useQuery(
    ['tracking_links_for_carrier_product.list', carrierProductId],
    () => listTrackingLinksForCarrierProduct(siteId, carrierProductId),
    { enabled: !initialTrackingLinks || !!carrierProductId }
  );

  const trackingLinks =
    data?.tracking_links || initialTrackingLinks
      ? buildTrackingLinksFormValue(data?.tracking_links ?? initialTrackingLinks)
      : [];

  return (
    <Row className={styles.container}>
      <Col offset={LABEL_SPAN} span={CONTENT_SPAN}>
        {trackingLinks.map(item => (
          <div key={item.locale} className={styles.rowContainer}>
            <Input
              disabled
              value={item.locale === 'defaultlink' ? 'Default' : item.locale}
              className={classes(inputStyle, styles.item, styles.localeItem)}
            />
            <Input
              disabled
              value={item.url}
              className={classes(inputStyle, styles.item, styles.urlItem)}
            />
          </div>
        ))}
      </Col>
    </Row>
  );
};

const styles = stylesheet({
  container: {
    marginBottom: '8px',
  },
  rowContainer: {
    display: 'flex',
    marginBottom: '4px',
  },
  item: {
    backgroundColor: 'hsla(0, 0%, 50%, 0.05) !important',
  },
  localeItem: {
    width: `88px !important`,
    marginRight: '8px',
  },
  urlItem: {
    width: `372px !important`,
  },
});
