import { Button, Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { LABELS } from '@src/dictionaries';
import { getFormikError } from '@src/utils/forms';
import { Field, FieldProps, FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { classes, style as tss } from 'typestyle';

interface Props {
  userTagsOptions: { value: string; label: string }[];
  onSubmit: ({ selectedTagsIds }: { selectedTagsIds: string[] }) => void;
  onCancel: () => void;
}

interface FormValues {
  selectedTagsIds: string[];
}

const SelectField = withFormItem(Select);

const styles = {
  form: tss({ display: 'flex', flexDirection: 'column' }),
  buttonsWrapper: tss({ display: 'flex', justifyContent: 'flex-end' }),
  submitButton: tss({ marginLeft: 10 }),
};

const InnerForm: React.SFC<Props & FormikProps<FormValues>> = ({
  userTagsOptions,
  handleSubmit,
  onCancel,
  touched,
  errors,
  setFieldValue,
}) => (
  <form onSubmit={handleSubmit}>
    <Field name="selectedTagsIds">
      {({ field }: FieldProps<FormValues['selectedTagsIds']>) => (
        <SelectField
          label="Select tags"
          options={userTagsOptions}
          mode="multiple"
          placeholder={LABELS.PLACEHOLDER_SELECT}
          value={field.value}
          error={getFormikError(touched.selectedTagsIds, errors.selectedTagsIds)}
          onChange={value => setFieldValue('selectedTagsIds', value)}
          caseInsensitiveSearch={true}
        />
      )}
    </Field>
    <div className={styles.buttonsWrapper}>
      <Button onClick={onCancel}>{LABELS.CANCEL}</Button>
      <Button
        type="primary"
        htmlType="submit"
        className={classes(styles.submitButton, 't_merchant-site-select-submit')}
      >
        {LABELS.SAVE}
      </Button>
    </div>
  </form>
);

export const WatchlistModalForm = withFormik<Props, FormValues>({
  mapPropsToValues: () => ({
    selectedTagsIds: [],
  }),
  handleSubmit: (values, { props }) => props.onSubmit(values),
})(InnerForm);
