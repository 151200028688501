import { CopyOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Tabs } from 'antd';
import * as React from 'react';
import * as CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { stylesheet } from 'typestyle';
import { useQuery } from 'react-query';

import { configActions, configSelectors } from '@src/modules/config';
import { SitesSelectors } from '@src/modules/sites';
import { services } from '@src/services';
import { authSelectors } from '@src/modules/auth';

export const SiteCredentials: React.FunctionComponent = () => {
  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);
  const privateKey = useSelector(configSelectors.getPrivateKeyFromConfig);
  const draftPrivateKey = useSelector(SitesSelectors.getDraftSitePrivateKeyOrEmpty);
  const isSuperuser = useSelector(authSelectors.isAuthUserSuperUser);
  const showDraftPrivateKey = draftPrivateKey && isSuperuser;
  const hasAccessTo = useSelector(authSelectors.getHasAccessTo);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (siteId) {
      dispatch(configActions.getPrivateKeyRequest({ siteId }));
    }
  }, [siteId]);

  const upsellQuery = useQuery({
    queryKey: ['upsell-token', siteId],
    queryFn: () => {
      return services.tokenAuthService.getAuthToken(siteId);
    },
    retry: false,
    enabled: !!privateKey && !!siteId,
    staleTime: Infinity,
  });

  const faqQuery = useQuery({
    queryKey: ['faq-auth-token', siteId],
    queryFn: () => {
      return services.tokenAuthService.getFaqAuthToken(siteId);
    },
    retry: false,
    enabled: !!privateKey && !!siteId,
    staleTime: Infinity,
  });

  const productPageQuery = useQuery({
    queryKey: ['pdp-auth-token', siteId],
    queryFn: () => {
      return services.tokenAuthService.getProductPageAuthToken(siteId);
    },
    retry: false,
    enabled: !!privateKey && !!siteId,
    staleTime: Infinity,
  });

  const genRow = (label: string, text: string) => (
    <>
      <div>{label}</div>
      <div>
        {text}
        &nbsp;
        <CopyToClipboard
          text={text}
          onCopy={() => services.messageService.success(`${label} copied to clipboard.`)}
        >
          <CopyOutlined onClick={(e: React.MouseEvent) => e.stopPropagation()} />
        </CopyToClipboard>
      </div>
    </>
  );

  if (!privateKey) {
    return null;
  }

  const content = (
    <Tabs defaultActiveKey="checkout">
      <Tabs.TabPane tab="Checkout" key="checkout">
        <div className={styles.popoverWrapper}>
          {genRow('Site ID', siteId)}
          {genRow('Private key', privateKey)}
          {genRow('Private key (base64)', btoa(privateKey))}
          {showDraftPrivateKey && genRow('Draft private key', draftPrivateKey)}
          {showDraftPrivateKey && genRow('Draft private key (base64)', btoa(draftPrivateKey))}
        </div>
      </Tabs.TabPane>
      {hasAccessTo('mad:upsell-widget') && (
        <Tabs.TabPane tab="Upsell" key="upsell">
          <div className={styles.popoverWrapper}>
            {upsellQuery.status === 'success' &&
              genRow('Upsell widget token', upsellQuery.data.token)}
          </div>
        </Tabs.TabPane>
      )}
      {hasAccessTo('mad:faq-widget') && (
        <Tabs.TabPane tab="Faq" key="faq">
          <div className={styles.popoverWrapper}>
            {faqQuery.status === 'success' && genRow('FAQ widget token', faqQuery.data.token)}
          </div>
        </Tabs.TabPane>
      )}
      {hasAccessTo('mad:product-page-widget') && (
        <Tabs.TabPane tab="Product page" key="pdp">
          <div className={styles.popoverWrapper}>
            {productPageQuery.status === 'success' &&
              genRow('Product page widget token', productPageQuery.data.token)}
          </div>
        </Tabs.TabPane>
      )}
      {hasAccessTo('mad:tracking-widget') && (
        <Tabs.TabPane tab="Tracking" key="tracking">
          <div className={styles.popoverWrapper}>
            {genRow('Site ID', siteId)}
            {genRow('Private key', privateKey)}
            {genRow('Private key (base64)', btoa(privateKey))}
            <span>Integration guide:</span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://developer.ingrid.com/delivery_tracking/integration"
            >
              https://developer.ingrid.com/delivery_tracking/integration
            </a>
          </div>
        </Tabs.TabPane>
      )}
      {hasAccessTo('mad:receipt-widget') && (
        <Tabs.TabPane tab="Receipt" key="receipt">
          <div className={styles.popoverWrapper}>
            {genRow('Site ID', siteId)}
            {genRow('Private key', privateKey)}
            {genRow('Private key (base64)', btoa(privateKey))}
            <span>To receive auth token please refer to:</span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://developer.ingrid.com/receipt/integration.html"
            >
              https://developer.ingrid.com/receipt/integration.html
            </a>
          </div>
        </Tabs.TabPane>
      )}
    </Tabs>
  );

  return (
    <div className={styles.root}>
      <Popover
        title="Your Ingrid credentials"
        trigger="click"
        placement="bottomRight"
        arrowPointAtCenter={true}
        getPopupContainer={e => e!.parentElement!}
        overlayClassName={styles.popoverOverlay}
        content={content}
      >
        <InfoCircleOutlined className={styles.icon} />
      </Popover>
    </div>
  );
};

const styles = stylesheet({
  root: {
    cursor: 'pointer',
    position: 'relative',
  },
  popoverOverlay: {
    width: '600px',
  },
  icon: {
    fontSize: '23px',
    color: '#fff',
    marginRight: 8,
  },
  popoverWrapper: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    placeItems: 'center start',
    gridGap: 10,
  },
});
