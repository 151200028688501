import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { useSiteId } from '@src/utils/hooks';
import { FormattedMessage } from '@src/i18n';
import { services } from '@src/services';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { useMutationOnDraft } from '@src/utils/queries';
import { FeatureFlags as ProductPageWidgetFeatures } from '@src/api/product-page-widget';

import {
  BreadcrumbSection,
  Header,
  FeaturesTable,
} from '@src/containers/product-page-widget/components';
import { mapCountryNameToCode } from '@src/dictionaries/mapping';
import { stringUtils } from '@src/utils';
import { ProductPageDrawer } from '@src/containers/product-page-widget/product-page-drawer';

interface ProductPageCountrySettingsProps {
  country: string;
}

export const ProductPageCountrySettings: React.FC<ProductPageCountrySettingsProps> = ({
  country,
}) => {
  const siteId = useSiteId();
  const queryClient = useQueryClient();

  const productPageFeatures = useQuery(
    ['site', siteId],
    () => services.configService.getSite(siteId),
    {
      enabled: !!siteId,
      staleTime: Infinity,
      select: data => data?.productPageWidgetConfiguration,
      keepPreviousData: true,
    }
  );

  const countryCode = mapCountryNameToCode(country);
  const countryFeatures = productPageFeatures.data?.by_country?.[countryCode]?.features;

  const onSubmitDraft = useMutationOnDraft<ProductPageWidgetFeatures, unknown>(
    (siteId, payload) =>
      services.selfCareService.upsertProductPageCountryConfiguration(siteId, countryCode, {
        features: payload,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(['site']),
    }
  );

  const countryName = stringUtils.capitalize(country);

  return (
    <>
      <StatusBarContainer />
      <BreadcrumbSection>{countryName}</BreadcrumbSection>
      <Header>
        <FormattedMessage id="COUNTRY" />
      </Header>
      <FeaturesTable
        onSubmit={onSubmitDraft.mutate}
        loading={productPageFeatures.isFetching || onSubmitDraft.isLoading}
        values={countryFeatures}
      />
      <ProductPageDrawer />
    </>
  );
};
