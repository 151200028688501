import { Popover, Switch, Table } from 'antd';
import React from 'react';
import { DataType } from '../configuration/table-columns';
import { ProgressNotifications } from '@src/models';
import { useFormatMessage } from '@src/i18n';
import { compose, replace } from 'ramda';
import { humanize } from '@src/utils/string';

type Props = {
  progress_notification_settings?: ProgressNotifications[];
  isLoading?: boolean;
};
export const FeatureList = ({ progress_notification_settings, isLoading }: Props) => {
  const formatMessage = useFormatMessage();

  const data: DataType[] | undefined = progress_notification_settings?.map((value, index) => {
    const mailStatus = value.channel_settings.find(
      channel => channel.channel_type === 'NOTIFICATION_CHANNEL_TYPE_EMAIL'
    );
    const smsStatus = value.channel_settings.find(
      channel => channel.channel_type === 'NOTIFICATION_CHANNEL_TYPE_SMS'
    );
    const getTrackingStepName = compose(humanize, replace('STEP_', ''));

    return {
      key: `${value.tracking_step}-${index}`,
      name: getTrackingStepName(value.tracking_step),
      emailStatus: mailStatus?.enabled,
      smsStatus: smsStatus?.enabled,
    };
  });

  return (
    <Table<DataType> pagination={false} dataSource={data} loading={isLoading}>
      <Table.Column
        title={formatMessage('NOTIFICATIONS.SETTINGS.STEP_HEADER')}
        key="name"
        dataIndex="name"
      />
      <Table.Column
        title={formatMessage('NOTIFICATIONS.SETTINGS.MAIL_HEADER')}
        key="emailStatus"
        dataIndex="emailStatus"
        render={emailStatus => {
          return (
            <Popover
              content={formatMessage('NOTIFICATIONS.SETTINGS.SWITCH_POPOVER_MESSAGE')}
              placement="bottom"
            >
              <Switch disabled defaultChecked={emailStatus} />
            </Popover>
          );
        }}
      />
      <Table.Column
        title={formatMessage('NOTIFICATIONS.SETTINGS.SMS_HEADER')}
        key="smsStatus"
        dataIndex="smsStatus"
        render={smsStatus => (
          <Popover
            content={formatMessage('NOTIFICATIONS.SETTINGS.SWITCH_POPOVER_MESSAGE')}
            placement="bottom"
          >
            <Switch disabled defaultChecked={smsStatus} />
          </Popover>
        )}
      />
    </Table>
  );
};
