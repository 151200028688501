import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { Reducer } from '..';
import { SessionEventModel } from '../../models';
import * as sessionHistoryActions from './actions';

// SessionHistoryState
export type State = Readonly<{
  isFetching: boolean;
  events: SessionEventModel[];
  rawEvents: any[];
}>;

const isFetching: Reducer<State['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(sessionHistoryActions.listSessionEventsRequest):
      return true;

    case getType(sessionHistoryActions.listSessionEventsSuccess):
    case getType(sessionHistoryActions.listSessionEventsError):
      return false;

    default:
      return state;
  }
};

const events: Reducer<State['events']> = (state = [], action) => {
  switch (action.type) {
    case getType(sessionHistoryActions.listSessionEventsSuccess):
      return action.payload.events;
    case getType(sessionHistoryActions.listSessionEventsRequest):
    case getType(sessionHistoryActions.listSessionEventsError):
      return [];
    default:
      return state;
  }
};

const rawEvents: Reducer<State['rawEvents']> = (state = [], action) => {
  switch (action.type) {
    case getType(sessionHistoryActions.listSessionEventsSuccess):
      return action.payload.rawEvents;
    case getType(sessionHistoryActions.listSessionEventsRequest):
    case getType(sessionHistoryActions.listSessionEventsError):
      return [];
    default:
      return state;
  }
};

const reducer: Reducer<State> = combineReducers({
  isFetching,
  events,
  rawEvents,
});

export default reducer;
