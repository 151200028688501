import { apiUtils, authFetch } from '@src/utils';

const URL = apiUtils.getApiUrl() + '/moirai';

type GenerateTokenParams = {
  siteId: string;
  externalId?: string;
  trackingNumber?: string;
  locale: string;
  tosId?: string;
};

type MoiraiGenerateTokenResponse = {
  token: string;
};

export const generateToken = async ({
  siteId,
  trackingNumber,
  externalId,
  tosId,
}: GenerateTokenParams) => {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify({
      site_id: siteId,
      order_id: tosId ?? externalId,
      tracking_number: trackingNumber,
    }),
  };

  return await authFetch<MoiraiGenerateTokenResponse>(`${URL}/token.generate`, options);
};
