import { isEmpty, pipe } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { FormattedMessage } from '@src/i18n';
import {
  CategoryAdditionalOptionsModel,
  CategoryRequirementsModel,
  ConfigFilterRuleModel,
  UpdateCategoryDetailsModel,
} from '@src/models';
import { RootAction, RootState } from '@src/modules';
import { configActions, configSelectors } from '@src/modules/config';
import { SitesSelectors } from '@src/modules/sites';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { checkIfPreselectionOrderComplete } from '@src/utils/preselection-order-utils';
import { stylesheet } from 'typestyle';
import { createUpdateCategoryDetailsModel } from '.';
import { FilterRules } from '../components/filter-rules';
import {
  CategoryDetailsFormValues,
  CategoryTags,
  MaxChoicesCount,
  Properties,
  ShippingCategoryForm,
  ShippingMethodsForm,
} from './components';
import { DeliveryAddonContainer } from './delivery-addons-container';
import { DeliveryTimeContainer } from './delivery-time-container';
import { LabelsContainer } from './labels-container';
import { CategoryPreselectionOrderSection } from './category-preselection-order-section';
import { TranslationsContainer } from './translations-container';

interface OwnProps {
  categoryId: string;
  regionId: string;
  changeVisibility: (to: boolean) => void;
}

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  ...bindActionCreators(
    {
      updateCategoryDetails: configActions.updateCategoryDetailsRequest,
      createDraftSite: configActions.createDraftRequest,
    },
    dispatch
  ),
  updateCategoryDetailsDispatch: dispatchOnDraftSiteId(configActions.updateCategoryDetailsRequest),
  updateCategoryFilterRulesRequest: dispatchOnDraftSiteId(
    configActions.updateCategoryFilterRulesRequest
  ),
  addCategoryTagsRequest: dispatchOnDraftSiteId(configActions.addCategoryTagsRequest),
  removeCategoryTagsRequest: dispatchOnDraftSiteId(configActions.removeCategoryTagsRequest),
});

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  regions: configSelectors.getRegionsByDraftOrCurrentSiteId(state),
  category: configSelectors.getCategoryById(state, props.categoryId),
  draftSiteId: SitesSelectors.getDraftSiteIdOrEmpty(state),
  shippingMethods: configSelectors.getShippingMethodsDictByDraftOrCurrentSite(state),
  isChainDelivery: configSelectors.isCategoryChainDelivery(state, props.categoryId),
  chainDelivery: configSelectors.getCategoryChainDelivery(state, props.categoryId),
  methodsToRender: configSelectors.getMethodsBasedOnRegionCarrierProducts(
    state,
    props.categoryId,
    props.regionId
  ),
  methodsAvailableToAdd: configSelectors.getUnusedMethodOptionsBasedOnRegionCarrierProducts(
    state,
    props.categoryId,
    props.regionId
  ),
  deliveryTypes: configSelectors.getDeliveryTypesOptionsLimitedByMethods(
    state,
    props.categoryId,
    props.regionId
  ),
  categoryPreselectionOrder: configSelectors.getCategoryPreselectionOrder(state, props.categoryId),
  categoryRegions: configSelectors.getCategoryRegions(state, props.categoryId),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

interface State {
  isAddingMethod: boolean;
  temporaryMethod: null | string;
}

class Component extends React.Component<Props, State> {
  state: State = {
    isAddingMethod: false,
    temporaryMethod: null,
  };

  setTemporaryMethod = (method: string | null) => this.setState({ temporaryMethod: method });
  toggleAddingMethod = (bool: boolean) => this.setState({ isAddingMethod: bool });

  createDraftOrSendToBackend = (model: UpdateCategoryDetailsModel) => {
    this.props.updateCategoryDetailsDispatch({ model });
  };

  createModelAndSendToBackend = (fields: Partial<UpdateCategoryDetailsModel>) =>
    pipe(createUpdateCategoryDetailsModel, this.createDraftOrSendToBackend)(
      fields,
      this.props.category
    );

  handleUpdateCategoryDetails = (values: CategoryDetailsFormValues) =>
    this.createModelAndSendToBackend({ ...values });

  handleUpdateCategoryProperties = (values: {
    preselected?: boolean;
    requirements?: Partial<CategoryRequirementsModel>;
    additionalOptions?: CategoryAdditionalOptionsModel;
  }) => this.createModelAndSendToBackend({ ...values });

  handleAddCategoryTags = (newTag: string) => {
    const { categoryId, addCategoryTagsRequest } = this.props;
    addCategoryTagsRequest({
      model: { categoryId, tagName: newTag },
    });
  };

  handleRemoveCategoryTags = (removeTag: string) => {
    const { categoryId, removeCategoryTagsRequest } = this.props;
    removeCategoryTagsRequest({
      model: { categoryId, tagName: removeTag },
    });
  };

  handleAddShippingMethod = (method: string) =>
    this.createModelAndSendToBackend({
      shippingMethods: [...this.props.category.shippingMethods, method],
    });

  handleRemoveShippingMethod = (newMethod: string) =>
    this.createModelAndSendToBackend({
      shippingMethods: this.props.category.shippingMethods.filter(method => method !== newMethod),
    });

  handleUpdateFilterRules = (rules: ConfigFilterRuleModel[]) => {
    const { categoryId, updateCategoryFilterRulesRequest } = this.props;
    updateCategoryFilterRulesRequest({
      model: {
        categoryId,
        filterRules: rules,
      },
    });
  };

  render() {
    const {
      regions,
      category,
      isChainDelivery,
      chainDelivery,
      methodsAvailableToAdd,
      methodsToRender,
      deliveryTypes,
      changeVisibility,
      categoryPreselectionOrder,
      categoryRegions,
    } = this.props;

    const preselectionOrderSectionRef = React.createRef<HTMLDivElement>();

    const possibleMethod = methodsAvailableToAdd.find(
      option => option.value === this.state.temporaryMethod
    );

    const canSendToBackend =
      this.state.temporaryMethod && possibleMethod ? !possibleMethod.disabled : false;

    const isPreselectionOrderComplete = checkIfPreselectionOrderComplete(categoryPreselectionOrder);

    const showPreselectionOrderSection = !!categoryRegions.find(
      region =>
        region.regionType === 'zipcode' ||
        (region.regionType === 'country' && region.regionCountryConfig?.included)
    );
    const showPreselectedFlag = !!categoryRegions.find(
      region => region.regionType === 'country' && region.regionCountryConfig?.excluded
    );

    return (
      <>
        <ShippingCategoryForm
          regions={regions}
          category={category}
          onCategoryDetailChange={this.handleUpdateCategoryDetails}
          deliveryTypes={deliveryTypes}
          showTooltip={!isEmpty(methodsToRender)}
          changeVisibility={() => changeVisibility(false)}
          isPreselectionOrderComplete={isPreselectionOrderComplete}
          preselectionOrderSectionForwardedRef={preselectionOrderSectionRef}
        />
        <DeliveryTimeContainer category={category} className={styles.marginBottom} />
        <ShippingMethodsForm
          isChainDelivery={isChainDelivery}
          chainDelivery={chainDelivery}
          onShippingMethodAdd={this.handleAddShippingMethod}
          onRemoveShippingMethod={this.handleRemoveShippingMethod}
          isAddingMethod={this.state.isAddingMethod}
          temporaryMethod={this.state.temporaryMethod}
          setTemporaryMethod={this.setTemporaryMethod}
          toggleAddingMethod={this.toggleAddingMethod}
          canSendToBackend={canSendToBackend}
          methodsToRender={methodsToRender}
          methodsAvailableToAdd={methodsAvailableToAdd}
        />
        <TranslationsContainer category={category} />
        <LabelsContainer category={category} />
        <DeliveryAddonContainer category={category} />
        <FilterRules
          filterRules={category.filterRules}
          toolTip={<FormattedMessage id="CATEGORY_FILTER_RULES_TOOLTIP" />}
          onSave={this.handleUpdateFilterRules}
        />
        <CategoryTags
          categoryTags={category.tags}
          handleAddCategoryTags={this.handleAddCategoryTags}
          handleRemoveCategoryTags={this.handleRemoveCategoryTags}
        />
        {showPreselectionOrderSection && (
          <CategoryPreselectionOrderSection
            categoryPreselectionOrder={categoryPreselectionOrder}
            categoryId={category.id}
            forwardedRef={preselectionOrderSectionRef}
          />
        )}
        <MaxChoicesCount
          category={category}
          onCategoryDetailChange={this.handleUpdateCategoryProperties}
        />
        <Properties
          category={category}
          handleUpdate={this.handleUpdateCategoryProperties}
          showPreselectedFlag={showPreselectedFlag}
        />
      </>
    );
  }
}

const styles = stylesheet({
  marginBottom: {
    marginBottom: '15px',
  },
});

export const ShippingCategoryContainer = connect(mapStateToProps, mapDispatchToProps)(Component);
