import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;
import { DeliveryEventModel, DeliveryStatusModel } from '../../models';

const GET_SHIPMENT_LIST_STATUSES_REQUEST = '@deliveries/GET_SHIPMENT_LIST_STATUSES_REQUEST';
const GET_SHIPMENT_LIST_STATUSES_SUCCESS = '@deliveries/GET_SHIPMENT_LIST_STATUSES_SUCCESS';
const GET_SHIPMENT_LIST_STATUSES_ERROR = '@deliveries/GET_SHIPMENT_LIST_STATUSES_ERROR';
const GET_DELIVERIES_STATUSES_REQUEST = '@deliveries/GET_DELIVERIES_STATUSES_REQUEST';
const GET_DELIVERIES_STATUSES_SUCCESS = '@deliveries/GET_DELIVERIES_STATUSES_SUCCESS';
const GET_DELIVERIES_STATUSES_ERROR = '@deliveries/GET_DELIVERIES_STATUSES_ERROR';

export const getShipmentListStatusesRequest = createAction(
  GET_SHIPMENT_LIST_STATUSES_REQUEST,
  resolve => (ids: string[]) => resolve(ids)
);
export const getShipmentListStatusesSuccess = createAction(
  GET_SHIPMENT_LIST_STATUSES_SUCCESS,
  resolve => (payload: DeliveryStatusModel[]) => resolve(payload)
);
export const getShipmentListStatusesError = createAction(
  GET_SHIPMENT_LIST_STATUSES_ERROR,
  resolve => (payload: string) => resolve(payload)
);

export const getDeliveryStatusesRequest = createAction(
  GET_DELIVERIES_STATUSES_REQUEST,
  resolve => (payload: { siteId: string; deliveryId: string }) => resolve(payload)
);
export const getDeliveryStatusesSuccess = createAction(
  GET_DELIVERIES_STATUSES_SUCCESS,
  resolve => (payload: { deliveryId: string; models: DeliveryEventModel[] }) => resolve(payload)
);
export const getDeliveryStatusesError = createAction(
  GET_DELIVERIES_STATUSES_ERROR,
  resolve => (error: string) => resolve(error)
);
