import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;
import { ShipmentListFiltersModel } from '../../models';

// Shipments
const APPLY_SHIPMENTS_FILTERS_CLICK = '@amplitude/APPLY_SHIPMENTS_FILTERS_CLICK';
const SEARCH_SHIPMENT_BUTTON_CLICK = '@amplitude/SEARCH_SHIPMENT_BUTTON_CLICK';
const VIEW_SHIPMENT_PAGE = '@amplitude/VIEW_SHIPMENT_PAGE';
const DOWNLOAD_SHIPMENT_LABEL = '@amplitude/DOWNLOAD_SHIPMENT_LABEL';
const VIEW_SHIPMENT_CREATE_PAGE = '@amplitude/VIEW_SHIPMENT_CREATE_PAGE';
const SELECT_SHIPMENT_TEMPLATE = '@amplitude/SELECT_SHIPMENT_TEMPLATE';
const SELECT_TRANSPORT_ORDER = '@amplitude/SELECT_TRANSPORT_ORDER';
const EDIT_SHIPMENT_BUTTON_CLICK = '@amplitude/EDIT_SHIPMENT_BUTTON_CLICK';
const SHIPMENT_PARCEL_DETAILS_CLICK = '@amplitude/SHIPMENT_PARCEL_DETAILS_CLICK';
const CANCEL_SHIPMENT_CLICK = '@amplitude/CANCEL_SHIPMENT_CLICK';

export const applyShipmentsFiltersClick = createAction(
  APPLY_SHIPMENTS_FILTERS_CLICK,
  resolve => (filters: ShipmentListFiltersModel) => resolve(filters)
);

export const searchShipmentButtonClick = createAction(SEARCH_SHIPMENT_BUTTON_CLICK);
export const viewShipmentPage = createAction(VIEW_SHIPMENT_PAGE);
export const viewShipmentCreatePage = createAction(VIEW_SHIPMENT_CREATE_PAGE);
export const downloadShipmentLabel = createAction(DOWNLOAD_SHIPMENT_LABEL);
export const selectShipmentTemplate = createAction(SELECT_SHIPMENT_TEMPLATE);
export const selectTransportOrder = createAction(SELECT_TRANSPORT_ORDER);
export const editShipmentButtonClick = createAction(EDIT_SHIPMENT_BUTTON_CLICK);
export const shipmentParcelDetailsClick = createAction(SHIPMENT_PARCEL_DETAILS_CLICK);
export const cancelShipmentClick = createAction(CANCEL_SHIPMENT_CLICK);

// Addressbook
const VIEW_ADDRESS_BOOK_PAGE = '@amplitude/VIEW_ADDRESS_BOOK_PAGE';
const SEARCH_CONTACTS = '@amplitude/SEARCH_CONTACTS';
const VIEW_ADDRESS_CREATE_PAGE = '@amplitude/VIEW_ADDRESS_CREATE_PAGE';
const TOGGLE_ADDITIONAL_CONTACT_INFO = '@amplitude/TOGGLE_ADDITIONAL_CONTACT_INFO';

export const viewAddressBookPage = createAction(VIEW_ADDRESS_BOOK_PAGE);
export const searchContacts = createAction(SEARCH_CONTACTS);
export const viewAddressCreatePage = createAction(VIEW_ADDRESS_CREATE_PAGE);
export const toggleAdditionalContactInfo = createAction(TOGGLE_ADDITIONAL_CONTACT_INFO);

// Transport orders
const VIEW_TO_LIST_PAGE = '@amplitude/VIEW_TO_LIST_PAGE';
const VIEW_TO_EDIT_PAGE = '@amplitude/VIEW_TO_EDIT_PAGE';

export const viewTOListPage = createAction(VIEW_TO_LIST_PAGE);
export const viewTOEditPage = createAction(VIEW_TO_EDIT_PAGE);

// Userprofile
const TEMPLATE_LIST_PAGE_VIEW = '@amplitude/TEMPLATE_LIST_PAGE_VIEW';
const VIEW_TEMPLATE_CREATE_PAGE = '@amplitude/VIEW_TEMPLATE_CREATE_PAGE';
const VIEW_TEMPLATE_PAGE = '@amplitude/VIEW_TEMPLATE_PAGE';

export const templateListPageView = createAction(TEMPLATE_LIST_PAGE_VIEW);
export const viewTemplateCreatePage = createAction(VIEW_TEMPLATE_CREATE_PAGE);
export const viewTemplatePage = createAction(VIEW_TEMPLATE_PAGE);
