import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { style } from 'typestyle';

import { Link } from '@src/components/link';
import { PaginationProps } from 'antd/lib/pagination';
import { Button, Spinner, Table } from '../../../controls';
import { MESSAGES } from '../../../dictionaries';
import { TemplateModel } from '../../../models';
import { RootState } from '../../../modules';

type ComponentProps = Readonly<{
  addressBookError: string | null;
  isLoading: boolean;
  total: number;
  pageSize: number;
  items: TemplateModel[];
  currentPage: number;
  onPaginationChange: (nextPage: number, direction: string) => void;
  onDelete: (items: string[], onDeleteCb: () => void) => void;
}>;

interface IState {
  selectedRowKeys: string[];
}

@observer
class TemplateList extends React.Component<ComponentProps, {}> {
  state: IState = {
    selectedRowKeys: [],
  };

  @observable
  sortingState = {
    order: 'descend' as 'descend',
    columnKey: 'createdAt',
  };

  setSortingState = (sorting: typeof TemplateList.prototype.sortingState) => {
    this.sortingState.columnKey = sorting.columnKey;
    this.sortingState.order = sorting.order;
  };

  handleTableChange: typeof Table.prototype.props.onChange = (pagination, filters, sorter: any) => {
    if (
      typeof pagination === 'object' &&
      (pagination.current !== this.props.currentPage || this.sortingState.order !== sorter.order)
    ) {
      this.props.onPaginationChange(
        pagination.current || 1,
        sorter.order === 'ascend' ? 'ASCENDING' : 'DESCENDING'
      );
    }

    this.setSortingState(sorter as any);
  };

  handleDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys, () => this.setState({ selectedRowKeys: [] }));
  };

  onSelectChange = (selectedRowKeys: any) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const { isLoading, pageSize, currentPage, total, items } = this.props;

    const paginationConfig: PaginationProps = {
      defaultCurrent: currentPage,
      current: currentPage,
      pageSize,
      total,
      size: 'small',
      showQuickJumper: true,
      showSizeChanger: true,
      showTotal: (count: number, range: number[]) => `${range[0]}-${range[1]} of ${count} items`,
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: 'all-data',
          text: 'Select All Data',
          onSelect: () => this.setState({ selectedRowKeys: items.map(item => item.id) }),
          onDisSelect: () => {
            this.setState({ selectedRowKeys: [] });
          },
        },
        {
          key: 'diselect-all',
          text: 'Diselect All Data',
          onSelect: (selected: any) => this.setState({ selectedRowKeys: [] }),
        },
        {
          key: 'current',
          text: 'Select Current Page',
          onSelect: (selected: any) => this.setState({ selectedRowKeys: selected }),
        },
      ],
    };

    const emptyMessage = isLoading ? (
      <Spinner delay={0} active={isLoading} />
    ) : (
      MESSAGES.EMPTY_TEMPLATE_LIST
    );

    return (
      <section className={styles.section}>
        <div style={{ paddingTop: '1em', paddingBottom: '1em' }}>
          <Button
            type="primary"
            danger
            onClick={this.handleDelete}
            disabled={!(this.state.selectedRowKeys.length > 0)}
          >
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {this.state.selectedRowKeys.length > 0
              ? `Selected ${this.state.selectedRowKeys.length} of ${this.props.items.length}` +
                (this.props.items.length === 1 ? ` item` : ` items`)
              : ''}
          </span>
        </div>

        <Table<TemplateModel>
          loading={isLoading}
          dataSource={items}
          rowKey={record => record.id}
          pagination={paginationConfig}
          onChange={this.handleTableChange}
          emptyMessage={emptyMessage}
          rowSelection={rowSelection}
          hideTitle={true}
          sortDirections={['ascend', 'descend']}
        >
          <Table.Column<TemplateModel>
            title="Name"
            key="name"
            render={(text, record) => (
              <Link route={{ name: 'TEMPLATE_EDIT', templateId: record.id }}>{record.name}</Link>
            )}
          />
        </Table>
      </section>
    );
  }
}

// Connected Type
type OwnProps = Pick<ComponentProps, 'isLoading' | 'items'>;

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  pageSize: state.app.pageSize,
});

export default connect(mapStateToProps, {})(TemplateList);

const styles = {
  section: style({ position: 'relative' }),
};
