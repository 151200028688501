import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { Reducer } from '../../modules';
import * as labelMergerActions from './actions';

export type State = Readonly<{
  isFetching: boolean;
  error: string | null;
  merged: string | null;
}>;

const isFetching: Reducer<State['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(labelMergerActions.mergeLabelsRequest):
      return true;

    case getType(labelMergerActions.mergeLabelsSuccess):
    case getType(labelMergerActions.mergeLabelsError):
      return false;

    default:
      return state;
  }
};

const error: Reducer<State['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(labelMergerActions.mergeLabelsError):
      return action.payload;

    case getType(labelMergerActions.mergeLabelsRequest):
    case getType(labelMergerActions.mergeLabelsSuccess):
      return null;

    default:
      return state;
  }
};

const merged: Reducer<State['merged']> = (state = null, action) => {
  switch (action.type) {
    case getType(labelMergerActions.mergeLabelsSuccess):
      return action.payload;

    case getType(labelMergerActions.mergeLabelsRequest):
    case getType(labelMergerActions.mergeLabelsError):
    case getType(labelMergerActions.resetMerged):
      return null;

    default:
      return state;
  }
};

const reducer: Reducer<State> = combineReducers({
  isFetching,
  error,
  merged,
});

export default reducer;
