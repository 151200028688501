import * as React from 'react';
import {
  AppstoreOutlined,
  BookOutlined,
  CompassOutlined,
  DashboardOutlined,
  EnvironmentOutlined,
  ExperimentOutlined,
  FileTextOutlined,
  GlobalOutlined,
  HistoryOutlined,
  InboxOutlined,
  MailOutlined,
  ProjectOutlined,
  RobotOutlined,
  ScheduleOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  TagsOutlined,
  TeamOutlined,
  UserOutlined,
  ControlOutlined,
} from '@ant-design/icons';

/** This icon component can be used when dynamic Icon behaviour is needed, i.e. icon type is determined at runtime */
export const DynamicIcon: React.FC<{ type: string; className?: string }> = ({
  type,
  className,
}) => {
  switch (type) {
    case 'AppstoreOutlined':
      return <AppstoreOutlined className={className} />;
    case 'BookOutlined':
      return <BookOutlined className={className} />;
    case 'CompassOutlined':
      return <CompassOutlined className={className} />;
    case 'DashboardOutlined':
      return <DashboardOutlined className={className} />;
    case 'EnvironmentOutlined':
      return <EnvironmentOutlined className={className} />;
    case 'ExperimentOutlined':
      return <ExperimentOutlined className={className} />;
    case 'FileTextOutlined':
      return <FileTextOutlined className={className} />;
    case 'GlobalOutlined':
      return <GlobalOutlined className={className} />;
    case 'HistoryOutlined':
      return <HistoryOutlined className={className} />;
    case 'InboxOutlined':
      return <InboxOutlined className={className} />;
    case 'ProjectOutlined':
      return <ProjectOutlined className={className} />;
    case 'RobotOutlined':
      return <RobotOutlined className={className} />;
    case 'ScheduleOutlined':
      return <ScheduleOutlined className={className} />;
    case 'ShopOutlined':
      return <ShopOutlined className={className} />;
    case 'ShoppingCartOutlined':
      return <ShoppingCartOutlined className={className} />;
    case 'ShoppingOutlined':
      return <ShoppingOutlined className={className} />;
    case 'TagsOutlined':
      return <TagsOutlined className={className} />;
    case 'TeamOutlined':
      return <TeamOutlined className={className} />;
    case 'UserOutlined':
      return <UserOutlined className={className} />;
    case 'MailOutlined':
      return <MailOutlined className={className} />;
    case 'ControlOutlined':
      return <ControlOutlined className={className} />;
    default:
      return null;
  }
};
