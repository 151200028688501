import { UploadOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { intersperse } from 'ramda';
import * as React from 'react';
import { style } from 'typestyle';

import { UploadRequestOption } from 'rc-upload/lib/interface';

import { Button, Upload } from '../controls';
import { LABELS } from '../dictionaries';

type ActionItem = {
  handler: () => void;
  label: string;
  popconfirm?: boolean;
};

class Component {
  static Create: React.StatelessComponent<{
    onCreate: () => void;
    onCreateLabel?: string;
    className?: string;
  }> = props => {
    return (
      <div className={props.className}>
        <Button onClick={props.onCreate} type="primary">
          {props.onCreateLabel || LABELS.CREATE}
        </Button>
      </div>
    );
  };

  static Upload: React.StatelessComponent<{
    onUpload: (base64File: string) => void;
    onUploadLabel?: string;
  }> = props => {
    const wrapperStyle = style({ paddingLeft: 15 });
    const handleUpload = (uploadObject: UploadRequestOption) => {
      const { file } = uploadObject;
      const reader = new FileReader();
      reader.readAsBinaryString(file as Blob);
      reader.onload = () => {
        props.onUpload(btoa(unescape(encodeURIComponent(reader.result as string))));
      };
    };

    return (
      <Upload
        className={wrapperStyle}
        customRequest={handleUpload}
        showUploadList={false}
        accept={'text/csv'}
      >
        <Button>
          <UploadOutlined /> {props.onUploadLabel || LABELS.IMPORT_CSV}
        </Button>
      </Upload>
    );
  };

  static ActionsGroup: React.StatelessComponent<{
    actionItems: ActionItem[];
  }> = props => {
    const PopconfirmButton = (actionItem: ActionItem) => (
      <Popconfirm
        key={actionItem.label}
        title={LABELS.CONFIRMATION_HEADER}
        okText={LABELS.OK}
        cancelText={LABELS.NO}
        onConfirm={actionItem.handler}
      >
        <a>{actionItem.label}</a>
      </Popconfirm>
    );

    const LinkButton = (actionItem: ActionItem) => (
      <a key={actionItem.label} onClick={actionItem.handler}>
        {actionItem.label}
      </a>
    );

    const Actions = intersperse(
      <span key={props.actionItems.length}> | </span>,
      props.actionItems.map(ai => (ai.popconfirm ? PopconfirmButton(ai) : LinkButton(ai)))
    );

    return <>{Actions}</>;
  };
}

export default Component;
