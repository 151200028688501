import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from 'antd';
import { useMutation } from 'react-query';
import { stylesheet } from 'typestyle';

import { useFormatMessage } from '@src/i18n';
import { services } from '@src/services';
import { useMasterSiteId } from '@src/utils/hooks';
import { DeliveryTypeEnum, GenerateTrackingRecordResponse } from '@src/models';
import { TrackingData } from './hooks';
import { ParcelTabs } from './tabs';
import { FormValues, initialParcel } from './common';
import { MADError } from '@src/utils';
import * as yup from 'yup';

export const GenerateForm = ({
  setTrackingData,
}: {
  setTrackingData: React.Dispatch<React.SetStateAction<TrackingData>>;
}) => {
  const formatMessage = useFormatMessage();
  const siteId = useMasterSiteId();
  const generateToken = useMutation<GenerateTrackingRecordResponse, MADError, FormValues>({
    mutationFn: ({ deliveryType, parcels, notificationType, emailAddress, phoneNumber }) =>
      services.demoService.generateTrackingRecord(siteId, {
        delivery_type: deliveryType,
        parcel_states: parcels.map(parcel => ({
          desired_step: parcel.progress,
        })),
        email: notificationType.email ? emailAddress : '',
        phone_number: notificationType.sms ? phoneNumber : '',
      }),
    mutationKey: ['generateTrackingRecord', siteId],
    onError: ({ traceId }) => {
      services.notificationService.error(
        formatMessage('TRACKING_PLAYGROUND_ERROR_TRACE_ID', { traceId }),
        formatMessage('TRACKING_PLAYGROUND_ERROR_GENERATING')
      );
    },
    onSuccess: data => {
      setTrackingData({
        externalId: data.external_id,
        contact: data.contact,
        trackingNumbers: data.tracking_numbers,
        trackingPageToken: data.tracking_page_token,
        trackingWidgetToken: data.tracking_widget_token,
      });
    },
  });

  const validationSchema = yup.object().shape({
    phoneNumber: yup.number().when('notificationType', {
      is: notificationType => notificationType.sms === true,
      then: yup.number().required('Phone number is required'),
    }),
    emailAddress: yup.string().when('notificationType', {
      is: notificationType => notificationType.email === true,
      then: yup.string().email('Invalid email format').required('Email address is required'),
    }),
  });

  return (
    <Formik<FormValues>
      validationSchema={validationSchema}
      initialValues={{
        parcels: [initialParcel],
        deliveryType: DeliveryTypeEnum.DELIVERY,
        notificationType: { email: false, sms: false },
        phoneNumber: '',
        emailAddress: '',
      }}
      onSubmit={values => generateToken.mutate(values)}
    >
      <Form>
        <div className={styles.contentWrapper}>
          <ParcelTabs isGeneratingToken={generateToken.isLoading} />
          <div className={styles.buttonWrapper}>
            <Button type="primary" htmlType="submit" loading={generateToken.isLoading}>
              {formatMessage('GENERATE')}
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

const styles = stylesheet({
  contentWrapper: {
    position: 'sticky',
    top: '10px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
});
