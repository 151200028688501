import * as React from 'react';
import { connect } from 'react-redux';

import { EmptyState, ErrorState } from '../components';
import { MESSAGES } from '../dictionaries';
import { RootState } from '../modules';
import * as CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { services } from '@src/services';

type OwnProps = {};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  selectedMerchantId: state.merchants.selectedMerchantId,
  selectedSiteId: state.sites.selectedSiteId,
  errorMerchants: state.merchants.error,
  errorSites: state.sites.error,
  errorTransportOrders: state.transportOrders.error,
  errorShipments: state.shipments.error,
});

type ConnectedProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & ConnectedProps;

export const ErrorLayer: React.StatelessComponent<Props> = props => {
  const { selectedMerchantId, selectedSiteId, errorMerchants, errorSites } = props;

  if (errorMerchants || errorSites) {
    if (!errorSites?.traceId) {
      return <ErrorState />;
    }

    return (
      <ErrorState
        title={'Something went wrong'}
        message={
          <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            <p>
              <span style={{ marginRight: '10px' }}>
                Here is your unique ID of the issue: {errorSites.traceId}
              </span>
              <CopyToClipboard
                text={errorSites.traceId}
                onCopy={() =>
                  services.messageService.success(`Copied ${errorSites.traceId} to clipboard.`)
                }
              >
                <CopyOutlined onClick={(e: React.MouseEvent) => e.stopPropagation()} />
              </CopyToClipboard>
            </p>
            <p>
              Please contact Ingrid Support, provide a short description of what just happend and
              attach the Issue ID.
            </p>
          </div>
        }
      />
    );
  }

  if (!selectedMerchantId || !selectedSiteId) {
    return <EmptyState title={''} message={MESSAGES.SELECT_MERCHANT_SITE} />;
  }

  return <>{props.children}</>;
};

const Connected = connect(mapStateToProps)(ErrorLayer);
Connected.displayName = 'ModulesErrorLayer';
export default Connected;
