import { Epic, combineEpics } from 'redux-observable';
import {
  combineLatest as observableCombineLatest,
  concat as observableConcat,
  from as observableFrom,
  of as observableOf,
  range as observableRange,
} from 'rxjs';
import {
  catchError,
  concat,
  filter,
  finalize,
  first,
  map,
  mapTo,
  merge,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { RootAction, RootState, Services } from '../../modules';

import { routerActions } from '@src/modules/router';
import { flatten } from 'ramda';
import { isActionOf } from 'typesafe-actions';
import { ERRORS } from '../../dictionaries';
import { sitesActions } from '../../modules/sites';
import { MADError } from '../../utils';
import { shipmentsActions } from './';
import { RouterPaths } from '../router/paths';

const getShipmentEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { loggingService, somService }
) =>
  observableCombineLatest(
    action$.pipe(filter(isActionOf(sitesActions.getSitesSuccess)), first()),
    action$.pipe(filter(isActionOf(shipmentsActions.getShipmentRequest)))
  ).pipe(
    switchMap(([_, shipmentsAction]) =>
      observableOf([state$.value.sites.selectedSiteId]).pipe(
        map(([maybeSelectedSiteId]) => {
          if (maybeSelectedSiteId == null) {
            throw new Error('No selectedSiteId in the store');
          }
          return maybeSelectedSiteId;
        }),
        switchMap(selectedSiteId =>
          observableFrom(somService.getShipment(selectedSiteId, shipmentsAction.payload.id)).pipe(
            map(res => shipmentsActions.getShipmentSuccess(res)),
            catchError((error: MADError) => {
              loggingService.logError(error);
              return observableOf(shipmentsActions.getShipmentError(error.message));
            })
          )
        )
      )
    )
  );

const createShipmentEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { loggingService, somService, messageService }
) =>
  action$.pipe(
    filter(isActionOf(shipmentsActions.createShipmentRequest)),
    switchMap(({ payload: { siteId, shipment, redirectId } }) =>
      observableFrom(somService.createShipment(siteId, shipment)).pipe(
        tap(() => messageService.success(ERRORS.CREATE_SHIPMENT_SUCCESS)),
        mergeMap(res => {
          return observableFrom([
            shipmentsActions.createShipmentSuccess(res),
            ...(redirectId
              ? [routerActions.push({ name: 'TRANSPORT_ORDER_DETAILS', tosId: redirectId })]
              : [routerActions.push({ name: 'SHIPMENT_EDIT', shipmentId: res.id })]),
          ]);
        }),
        catchError((error: MADError) =>
          observableOf(shipmentsActions.createShipmentError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              messageService.error(ERRORS.CREATE_FAILED);
            })
          )
        )
      )
    )
  );

const cancelShipment: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { somService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(shipmentsActions.cancelShipmentRequest)),
    switchMap(action =>
      observableFrom(
        somService.cancelShipment(action.payload.siteId, action.payload.shipmentId)
      ).pipe(
        mapTo(shipmentsActions.cancelShipmentSuccess(action.payload.shipmentId)),
        tap(() => messageService.success(ERRORS.CANCEL_SUCCESS)),
        catchError((err: Error) =>
          observableOf(shipmentsActions.cancelShipmentError(err.message)).pipe(
            tap(() => {
              loggingService.logError(err);
              messageService.error(ERRORS.CANCEL_FAILED);
            })
          )
        )
      )
    )
  );

const duplicateShipment: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(shipmentsActions.duplicateShipment)),
    switchMap(({ payload: { shipmentModel } }) =>
      observableFrom([
        shipmentsActions.draftShipmentLoad(shipmentModel),
        routerActions.push({ name: 'SHIPMENT_CREATE' }),
      ])
    )
  );

const createReturn: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(shipmentsActions.createReturn)),
    switchMap(({ payload: { shipmentModel } }) =>
      observableFrom([
        shipmentsActions.draftShipmentLoad(shipmentModel),
        routerActions.push({ name: 'SHIPMENT_CREATE', return: true }),
      ])
    )
  );

const cancelAndDuplicateShipment: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(shipmentsActions.cancelAndDuplicateShipment)),
    switchMap(({ payload: { shipmentId, siteId, shipmentModel } }) =>
      action$.pipe(
        filter(isActionOf(shipmentsActions.cancelShipmentSuccess)),
        first(),
        mapTo(routerActions.push({ name: 'SHIPMENT_CREATE' })),
        merge(
          observableFrom([
            shipmentsActions.cancelShipmentRequest({ siteId, shipmentId }),
            shipmentsActions.draftShipmentLoad(shipmentModel),
          ])
        )
      )
    )
  );

const createAndBookMultipleShipments: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { somService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(shipmentsActions.createAndBookShipmentsRequest)),
    switchMap(({ payload: { numberOfShipments, siteId, shipmentModel } }) =>
      observableRange(1, numberOfShipments).pipe(
        mergeMap(() =>
          observableFrom(somService.createAndBook(siteId, shipmentModel)).pipe(
            switchMap(shipment => {
              const labelsUrls = flatten(shipment.parcels.map(parcel => parcel.deliveries)).map(
                delivery => delivery.labelUrl
              );

              return observableConcat(
                observableOf(shipmentsActions.createAndBookShipmentsPartialSuccess(labelsUrls)),
                observableOf(
                  shipmentsActions.createAndBookShipmentsIncreaseProgress({
                    shipmentId: shipment.id,
                  })
                )
              );
            })
          )
        ),
        concat(observableOf(shipmentsActions.createAndBookShipmentsSuccess())),
        catchError((error: MADError) =>
          observableOf(shipmentsActions.createAndBookShipmentsError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const bookAndPrintParcelsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { loggingService, somService, messageService }
) =>
  action$.pipe(
    filter(isActionOf(shipmentsActions.bookAndPrintParcelsRequest)),
    switchMap(({ payload: { siteId, shipmentId, tosId } }) =>
      observableFrom(somService.bookParcels(siteId, shipmentId)).pipe(
        map(res => shipmentsActions.bookParcelsSuccess(res)),
        tap(({ payload }) => {
          payload.parcels.forEach(parcel =>
            parcel.deliveries.forEach(delivery =>
              window.open(
                `${window.location.origin}${RouterPaths.SHIPMENT_BOOK_AND_PRINT.replace(
                  ':parcelId',
                  delivery.parcelId
                )
                  .replace(':shipmentId', shipmentId)
                  .replace(':tosId', tosId)
                  .replace(':merchantId', state$.value.merchants.selectedMerchantId ?? '')
                  .replace(':siteId', state$.value.sites.selectedSiteId ?? '')}`
              )
            )
          );

          return messageService.success(ERRORS.BOOKING_PARCELS_SUCCESS);
        }),
        catchError((error: MADError) =>
          observableOf(shipmentsActions.bookParcelError(error.message)).pipe(
            tap(() => {
              messageService.error(ERRORS.BOOKING_PARCELS_ERROR);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const bookParcelsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { loggingService, somService, messageService }
) =>
  action$.pipe(
    filter(isActionOf(shipmentsActions.bookParcelsRequest)),
    switchMap(({ payload: { siteId, shipmentId } }) =>
      observableFrom(somService.bookParcels(siteId, shipmentId)).pipe(
        map(res => shipmentsActions.bookParcelsSuccess(res)),
        tap(() => messageService.success(ERRORS.BOOKING_PARCELS_SUCCESS)),
        catchError((error: MADError) =>
          observableOf(shipmentsActions.bookParcelError(error.message)).pipe(
            tap(() => {
              messageService.error(ERRORS.BOOKING_PARCELS_ERROR);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const editShipmentEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { somService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(shipmentsActions.editShipmentRequest)),
    switchMap(({ payload: { siteId, shipment }, meta }) =>
      observableFrom(somService.editShipment(siteId, shipment)).pipe(
        map(shipmentsActions.editShipmentSuccess),
        tap(() => {
          messageService.success(ERRORS.UPDATE_SUCCESS);
        }),
        catchError((error: MADError) =>
          observableOf(shipmentsActions.editShipmentError(error.message)).pipe(
            tap(() => {
              messageService.error(ERRORS.UPDATE_FAILED);
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          meta.onComplete();
        })
      )
    )
  );

const bookPickupDeliveriesEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { messageService, loggingService, somService }
) =>
  action$.pipe(
    filter(isActionOf(shipmentsActions.bookPickupDeliveriesRequest)),
    switchMap(action =>
      somService
        .bookPickupDeliveries(action.payload.siteId, action.payload.trackingNumbers)
        .then(res =>
          shipmentsActions.bookPickupDeliveriesSuccess({
            shipmentId: action.payload.shipmentId,
            deliveries: res,
          })
        )
    ),
    tap(() => messageService.success(ERRORS.BOOK_PICKUP_DELIVERY_SUCCESS)),
    catchError((error: MADError) => {
      loggingService.logError(error);
      return observableOf(shipmentsActions.bookPickupDeliveriesError(error.message)).pipe(
        tap(() => messageService.error(ERRORS.BOOK_PICKUP_DELIVERY_ERROR))
      );
    })
  );

const editNoteEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { somService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(shipmentsActions.editNoteRequest)),
    switchMap(action =>
      observableFrom(somService.editNote(action.payload.siteId, action.payload.model)).pipe(
        map(shipmentsActions.editNoteSuccess),
        catchError((error: MADError) => {
          loggingService.logError(error);
          return observableOf(shipmentsActions.editNoteError(error.message));
        })
      )
    )
  );

export const shipmentsEpics = combineEpics(
  getShipmentEpic,
  createShipmentEpic,
  cancelShipment,
  duplicateShipment,
  createReturn,
  cancelAndDuplicateShipment,
  bookAndPrintParcelsEpic,
  bookParcelsEpic,
  bookPickupDeliveriesEpic,
  editShipmentEpic,
  createAndBookMultipleShipments,
  editNoteEpic
);
