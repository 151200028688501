import { useState } from 'react';
import { TrackingPreviewDrawerData } from './types';

const defaultDrawerData = {
  externalId: '',
  trackingNumber: '',
  tosId: '',
};

export const useShowTrackingPreviewDrawer = () => {
  const [isDrawerVisible, setDrawerVisibility] = useState(false);
  const [drawerData, setDrawerData] = useState<TrackingPreviewDrawerData>(defaultDrawerData);

  const showDrawerWith = (data: TrackingPreviewDrawerData) => {
    setDrawerData(data);
    setDrawerVisibility(true);
  };

  const closeDrawer = () => {
    setDrawerVisibility(false);
  };

  return [isDrawerVisible, drawerData, showDrawerWith, closeDrawer] as const;
};
