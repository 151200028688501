import type { CustomsDeclarationItem, FormValues } from './types';

export const emptyItem: CustomsDeclarationItem = {
  quantity: undefined,
  name: undefined,
  sku: undefined,
  brand: undefined,
  hs_tariff_number: undefined,
  category: undefined,
  unit_value: undefined,
  unit_net_weight: undefined,
  unit_gross_weight: undefined,
  country_of_origin: undefined,
};

export const emptyFormValues: FormValues = {
  items: [emptyItem],
};

export const contentTypes = [
  'DOCUMENTS',
  'GIFT',
  'COMMERCIAL_SAMPLE',
  'SALE',
  'RETURN',
  'OTHER',
] as const;

export const InCoTerms = {
  EXW: 'Ex Works',
  FCA: 'Free Carrier',
  FAS: 'Free Alongside Ship',
  FOB: 'Free On Board',
  CFR: 'Cost And Freight',
  CIF: 'Cost, Insurance and Freight',
  CPT: 'Carriage Paid To',
  CIP: 'Carriage Insurance Paid To',
  DAT: 'Delivered At Terminal',
  DAP: 'Delivered At Place',
  DDP: 'Delivered Duty Paid',
  DAF: 'Delivered At Frontier',
  DDU: 'Delivery Duty Unpaid',
  DEQ: 'Delivered Ex Quay',
  DES: 'Delivered Ex Ship',
};
