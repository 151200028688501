import * as React from 'react';

import { Modal } from '@src/controls';
import { defaultTheme } from '@src/styles';
import { ShippingCategoryContainer } from '.';

interface OwnProps {
  categoryId: string | null;
  isVisible: boolean;
  changeVisibility: (to: boolean) => void;
  regionId: string;
}

export const ShippingCategoryModal: React.FunctionComponent<OwnProps> = ({
  categoryId,
  isVisible,
  changeVisibility,
  regionId,
}) => (
  <Modal
    visible={isVisible}
    onOk={() => changeVisibility(false)}
    onCancel={() => changeVisibility(false)}
    closable={false}
    footer={null}
    destroyOnClose={true}
    bodyStyle={{
      backgroundColor: defaultTheme.regionForm.color.modalBackground,
    }}
    width={defaultTheme.largeModalWidth}
  >
    <ShippingCategoryContainer
      changeVisibility={() => changeVisibility(false)}
      categoryId={categoryId!}
      regionId={regionId}
    />
  </Modal>
);
