import { useFormatMessage } from '@src/i18n';
import { Form, Popover } from 'antd';
import { Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { style as tss } from 'typestyle';
import {
  buildCarrierProductMappingsPayload,
  buildFormValues,
  buildValidationSchema,
  CarrierProductMappingForm,
} from '../form-commons';
import {
  AddNewRowButtonConnected,
  CancelButtonConnected,
  DeleteRowButtonConnected,
  SubmitButtonConnected,
} from '../form-components/buttons';
import {
  AlwaysShowTrackingLinksFieldConnected,
  CarrierProductFieldConnected,
  CarrierProductRefFieldConnected,
  LocaleFieldConnected,
  TrackingConfigStatusFieldConnected,
  UrlFieldConnected,
  UseCustomMappingFieldConnected,
} from '../form-components/connected-fields';
import { useCreateMappingMutation } from './mutations';
import { ListCarrierProductMappingResponse } from '@src/models';
import { commonFormItemProps } from '@src/containers/product-mappings/form-components/fields';
import {
  handleCustomMappingChange,
  handleDisabled,
  useHandleOnCarrierProductChange,
} from '@src/containers/product-mappings/utils/create';

type Props = {
  siteId: string;
  carrierProductMapping?: ListCarrierProductMappingResponse;
};

export const CarrierProductMappingsCreate = ({ siteId, carrierProductMapping }: Props) => {
  const formatMessage = useFormatMessage();

  const createMappingMutation = useCreateMappingMutation();

  const { onProductCarrierChange, isLoadingTrackingConfigStatus } =
    useHandleOnCarrierProductChange(siteId);

  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  return (
    <Popover
      overlayClassName={styles.popover}
      title={formatMessage('ADD_NEW_CARRIER_PRODUCT_MAPPING')}
      placement="bottomLeft"
      visible={isPopoverVisible}
      content={
        <Formik<CarrierProductMappingForm>
          initialValues={buildFormValues()}
          onSubmit={(values, formikBag) => {
            createMappingMutation.mutate(buildCarrierProductMappingsPayload(values));
            setIsPopoverVisible(false);
            formikBag.resetForm();
          }}
          validationSchema={buildValidationSchema(carrierProductMapping)}
        >
          {formik => (
            <div className={styles.wrapper}>
              <CarrierProductRefFieldConnected />
              <UseCustomMappingFieldConnected
                onChange={value => handleCustomMappingChange(formik, value)}
              />
              {formik.values.custom ? (
                <Form.Item label={formatMessage('TRACKING_LINKS_URLS')} {...commonFormItemProps}>
                  {formik.values.tracking_links.map((_, idx) => {
                    return (
                      <div key={idx} className={styles.trackingLinkRow}>
                        <div className={styles.fieldSpacing}>
                          <LocaleFieldConnected idx={idx} disabled={idx === 0} />
                        </div>
                        <div className={styles.fieldSpacing}>
                          <UrlFieldConnected idx={idx} />
                        </div>
                        {idx !== 0 && (
                          <DeleteRowButtonConnected
                            idx={idx}
                            className={tss({ marginTop: '5px' })}
                          />
                        )}
                      </div>
                    );
                  })}
                  <AddNewRowButtonConnected />
                </Form.Item>
              ) : (
                <>
                  <CarrierProductFieldConnected
                    onChange={value => onProductCarrierChange(value, formik)}
                  />
                  <TrackingConfigStatusFieldConnected isLoading={isLoadingTrackingConfigStatus} />
                </>
              )}
              <AlwaysShowTrackingLinksFieldConnected disabled={handleDisabled(formik)} />
              <div className={styles.buttonsContainer}>
                <SubmitButtonConnected />
                <CancelButtonConnected onClick={() => setIsPopoverVisible(false)} />
              </div>
            </div>
          )}
        </Formik>
      }
    >
      <a
        className={styles.addNew}
        onClick={e => {
          e.preventDefault();
          setIsPopoverVisible(value => !value);
        }}
      >
        {formatMessage('ADD_NEW')}
      </a>
    </Popover>
  );
};

const styles = {
  popover: tss({
    $nest: {
      '.ant-popover-title': {
        padding: '12px',
      },
    },
  }),
  wrapper: tss({
    width: '680px',
  }),
  trackingLinkRow: tss({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'start',
    paddingBottom: '16px',
    $nest: {
      '& .ant-form-item': {
        margin: '0 !important',
      },
    },
  }),
  fieldSpacing: tss({
    paddingRight: '8px',
  }),
  addNew: tss({
    marginTop: '20px',
    display: 'inline-block',
  }),
  buttonsContainer: tss({
    display: 'flex',
    $nest: {
      '* + *': {
        marginLeft: '12px',
      },
    },
  }),
};
