import { CopyOutlined } from '@ant-design/icons';
import * as React from 'react';
import * as CopyToClipboard from 'react-copy-to-clipboard';
import ReactJson from 'react-json-view';
import { style as tss } from 'typestyle';

import { Table, Tag } from '@src/controls';
import { sessionEventsSelectors } from '@src/modules/session-events';
import { services } from '@src/services';
import { stringUtils } from '@src/utils';
import { useSelector } from '@src/utils/hooks';

type TableItem = ReturnType<typeof sessionEventsSelectors['getEvents']>[number];

const getTraceID = (record: TableItem) => (
  <div>
    {stringUtils.truncateId(record.traceID)}
    <CopyToClipboard
      text={record.traceID}
      onCopy={() => services.messageService.success(`TraceID copied to clipboard.`)}
    >
      <CopyOutlined
        className={tss({ fontSize: '16px' })}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      />
    </CopyToClipboard>
  </div>
);

const getRawEvent = (record: TableItem) => {
  return (
    <ReactJson
      collapsed
      name="event"
      src={record.json}
      displayDataTypes={false}
      displayObjectSize={false}
    />
  );
};

export const SessionEventsList: React.FunctionComponent = () => {
  const isFetching = useSelector(sessionEventsSelectors.isFetching);
  const items = useSelector(sessionEventsSelectors.getEvents);

  return (
    <Table<TableItem>
      loading={isFetching}
      dataSource={items}
      rowKey={record => record.id}
      hideTitle={true}
      pagination={false}
    >
      <Table.Column<TableItem>
        title="Time"
        key="happenedAt"
        width="230px"
        render={record => record.happenedAt}
      />
      <Table.Column<TableItem>
        title="Trace ID"
        key="traceID"
        width="150px"
        render={record => getTraceID(record)}
      />
      <Table.Column<TableItem>
        title="Trigger"
        key="name"
        width="150px"
        render={record => <Tag>{record.triggeredBy}</Tag>}
      />
      <Table.Column<TableItem> title="Raw" key="raw" render={record => getRawEvent(record)} />
    </Table>
  );
};
