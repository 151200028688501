import React, { VFC } from 'react';
import { useQuery } from 'react-query';
import { getTransportOrder } from '@src/services/tos-service';
import { useMasterSiteId } from '@src/utils/hooks';
import { WidgetSection } from '../../tracking-playground/find-section/delivery-tracking';
import { TrackingPreviewDrawerData } from '../types';

type Props = {
  drawerData: TrackingPreviewDrawerData;
};

export const DeliveryTrackingContent: VFC<Props> = ({ drawerData }) => {
  const siteId = useMasterSiteId();
  const { tosId, trackingNumber, externalId } = drawerData;

  const { data } = useQuery({
    queryKey: ['find-transport-order', siteId, tosId],
    queryFn: () => getTransportOrder(siteId, tosId),
    select: ({ receiver }) => ({
      contact: receiver.contactInfo.email ?? receiver.contactInfo.phone ?? '',
      tosId,
      trackingNumbers: [trackingNumber],
      trackingIdentifier: 'trackingNumber' as const,
      externalId,
    }),
  });

  return <WidgetSection trackingData={data} />;
};
