import { Select } from '@src/controls';
import { SelectOption } from '@src/controls/select';
import { withFormItem } from '@src/decorators';
import { ConfigShippingCategoryModel } from '@src/models';
import { getFieldArrayError } from '@src/utils/forms';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { string } from 'yup';
import { RuleAddingValues } from '../rule-adding-container';

const SelectField = withFormItem(Select);

interface Props {
  name: string;
  errors: FormikProps<RuleAddingValues>['errors'];
  touched: FormikProps<RuleAddingValues>['touched'];
  setFieldValue: FormikProps<RuleAddingValues>['setFieldValue'];
  categories: ConfigShippingCategoryModel[];
}

const CategoryRefValidate = async (value: string, categoriesOptions: SelectOption[]) => {
  try {
    await string()
      .oneOf(
        categoriesOptions.map(option => option.value),
        args =>
          args.value
            ? `${args.value} is not recognized as a category connected to this region`
            : `Category cannot be empty`
      )
      .validate(value);
  } catch (err) {
    return err;
  }
};

export const CategoryRef: React.FunctionComponent<Props> = ({
  name,
  errors,
  touched,
  setFieldValue,
  categories,
}) => {
  const categoriesOptions = categories.map(category => ({
    value: category.id,
    label: category.name,
  }));
  return (
    <div className={styles.wrapper}>
      <Field
        name={`${name}.value`}
        validate={(value: string) => CategoryRefValidate(value, categoriesOptions)}
      >
        {({ field }: FieldProps<string>) => (
          <SelectField
            className={styles.label}
            options={categoriesOptions}
            placeholder={'Please select...'}
            filterOption={true}
            optionFilterProp={'label'}
            label="If Category is"
            error={getFieldArrayError(touched, errors, `${name}.value`)}
            {...field}
            showSearch
            onChange={value => {
              setFieldValue(`${name}.value`, value);
            }}
            style={{ width: '300px' }}
          />
        )}
      </Field>
    </div>
  );
};

const styles = stylesheet({
  wrapper: {
    padding: '5px 0',
    $nest: {
      '.ant-row': {
        display: 'flex',
        flexWrap: 'nowrap',
        marginBottom: 0,
      },
      '.ant-form-explain': {
        width: '200px',
      },
    },
  },
  label: {
    $nest: {
      '&.ant-select': {
        border: '1px solid #d9d9d9',
        borderRadius: '3px',
      },
      '&.ant-select:hover': {
        border: '1px solid #5bc2b6',
      },
      '&.ant-select-open': {
        border: '1px solid #5bc2b6',
      },
    },
  },
});
