import * as React from 'react';
import * as moment from 'moment';
import { stylesheet } from 'typestyle';
import { Form, InputNumber } from 'antd';
import { Input } from '@src/controls';
import { formStyle } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { inputStyle } from '@src/forms';
import { VariantModel, UpdateVariantModel } from '@src/models';

interface OwnProps {
  data: VariantModel;
  siteId: string;
  experimentId: string;
  original?: boolean;
  disabled?: boolean;
  externalTrafficBalancingEnabled?: boolean;
  onSubmit: (values: UpdateVariantModel) => void;
  onShowChanges: (values: VariantModel) => void;
}

export const VariantDetails: React.FC<OwnProps> = ({
  siteId,
  experimentId,
  data,
  original,
  disabled,
  externalTrafficBalancingEnabled,
  onSubmit,
  onShowChanges,
}) => {
  const [dirty, setDirty] = React.useState<boolean>(false);
  const [values, setValues] = React.useState({
    ...data,
    siteId,
    experimentId,
  });

  const onValuesChange = (newValues: UpdateVariantModel) => {
    setValues({
      ...values,
      ...newValues,
    });
    setDirty(true);
  };

  const onBlur = () => {
    if (!dirty) {
      return;
    }
    onSubmit(values);
    setDirty(false);
  };

  return (
    <Form
      initialValues={data}
      labelCol={{ span: 12 }}
      labelAlign="left"
      className={formStyle}
      onValuesChange={onValuesChange}
      onBlur={onBlur}
    >
      <Form.Item name="name" label={<FormattedMessage id="NAME" />}>
        <Input disabled={disabled || original} className={inputStyle} />
      </Form.Item>

      <Form.Item name="description" label={<FormattedMessage id="DESCRIPTION" />}>
        <Input disabled={disabled} className={inputStyle} />
      </Form.Item>

      { !externalTrafficBalancingEnabled && (
        <Form.Item name="weight" label={<FormattedMessage id="WEIGHT" />}>
          <InputNumber
            formatter={(value: number) => (value !== undefined ? `${value / 10}%` : '')}
            className={inputStyle}
            disabled
          />
        </Form.Item>
      )}

      { externalTrafficBalancingEnabled && (
        <Form.Item name="externalTrafficBalancingId" label="External traffic balancing ID">
          <Input disabled={disabled} className={inputStyle} />
        </Form.Item>
      )}

      <Form.Item
        name="createdAt"
        label={<FormattedMessage id="CREATED_AT" />}
        getValueProps={value => ({ value: moment(value).format('YYYY-MM-DD HH:mm:ss') })}
      >
        <Input disabled />
      </Form.Item>

      {!original && (
        <Form.Item label="Changes" shouldUpdate>
          {({ getFieldValue }) => {
            if (!data.diff.no_of_changes) {
              return <span className={styles.label}>0</span>;
            }

            return (
              <a onClick={() => onShowChanges(data)} style={{ marginLeft: '12px' }}>
                {data.diff.no_of_changes}
              </a>
            );
          }}
        </Form.Item>
      )}
    </Form>
  );
};

const styles = stylesheet({
  label: {
    color: 'rgba(0, 0, 0, 0.5)',
    marginLeft: '12px',
  },
});
