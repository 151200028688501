import { useFormatMessage } from '@src/i18n';
import { Popover, Switch, Table } from 'antd';
import React from 'react';
import { style } from 'typestyle';
import { ExpandableRowEvent } from './expandable-row-event';
import { useGetSmartNotificationsTableData } from '@src/modules/delivery-tracking-notifications/smart-notifications-settings/use-get-smart-notifications-data-table';
import {
  SmartDataType,
  SmartNotifications,
} from '@src/modules/delivery-tracking-notifications/smart-notifications-settings/smart-notifications.types';

type Props = {
  smartNotifications?: SmartNotifications;
  isLoading?: boolean;
};

export const SmartNotificationsList = ({ isLoading, smartNotifications }: Props) => {
  const formatMessage = useFormatMessage();
  const smartTableData = useGetSmartNotificationsTableData(smartNotifications);

  return (
    <Table<SmartDataType>
      pagination={false}
      dataSource={smartTableData}
      className={styles.tableContainer}
      loading={isLoading}
      expandable={{
        expandedRowRender: record => (
          <>
            <ExpandableRowEvent
              key={record.eventKey}
              eventLogic={record.eventKey}
              threshold={record.threshold}
            />
          </>
        ),
        rowExpandable: record => !!record.eventKey,
      }}
    >
      <Table.Column
        title={formatMessage('NOTIFICATIONS.SETTINGS.STEP_HEADER')}
        key="name"
        dataIndex="name"
      />
      <Table.Column
        title={formatMessage('NOTIFICATIONS.SETTINGS.MAIL_HEADER')}
        key="emailStatus"
        dataIndex="emailStatus"
        render={emailStatus => {
          return (
            <Popover
              content={formatMessage('NOTIFICATIONS.SETTINGS.SWITCH_POPOVER_MESSAGE')}
              placement="bottom"
            >
              <Switch disabled defaultChecked={emailStatus} />
            </Popover>
          );
        }}
      />
      <Table.Column
        title={formatMessage('NOTIFICATIONS.SETTINGS.SMS_HEADER')}
        key="smsStatus"
        dataIndex="smsStatus"
        render={smsStatus => (
          <Popover
            content={formatMessage('NOTIFICATIONS.SETTINGS.SWITCH_POPOVER_MESSAGE')}
            placement="bottom"
          >
            <Switch disabled defaultChecked={smsStatus} />
          </Popover>
        )}
      />
    </Table>
  );
};

export const styles = {
  tableContainer: style({
    $nest: {
      '.ant-table-expanded-row': {
        $nest: {
          '.ant-table-cell': {
            backgroundColor: 'white',
          },
        },
      },
    },
  }),
  descriptionContainer: style({
    display: 'flex',
    fontSize: '12px',
    lineHeight: '16px',

    $nest: {
      '.ant-table': {
        color: 'white',
      },
    },
  }),
  cardWrapper: style({
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: '16px',
    width: 'auto',
    padding: '16px',
    marginTop: '5px',
    backgroundColor: '#F9F7F6',
  }),
  card: style({
    fontSize: '12px',
    lineHeight: '16px',
    width: 'auto',
    height: '50px',
    padding: '8px 12px',
    marginRight: '16px',
    marginLeft: '16px',

    $nest: {
      '.ant-card-body': {
        padding: 0,
      },
    },
  }),

  cardTitle: style({
    fontWeight: 500,
    margin: 0,
  }),

  cardDescription: style({
    margin: 0,
    color: 'lightgrey',
  }),
};
