import { AddonModel } from '.';

export class AddressModel {
  name: string = '';
  careOf?: string;
  attn?: string;
  addressLines: string[] = [];
  city: string = '';
  region?: string;
  postalCode: string = '';
  country: string = '';
  coordinates?: CoordinatesModel = new CoordinatesModel();
  doorCode?: string;

  static createFromDTO(dto: AddressDTO): AddressModel {
    return {
      name: dto.name,
      careOf: dto.care_of,
      attn: dto.attn,
      addressLines: dto.address_lines || [],
      city: dto.city,
      region: dto.region,
      postalCode: dto.postal_code,
      country: dto.country,
      coordinates: CoordinatesModel.createFromDTO(dto.coordinates),
      doorCode: dto.door_code,
    };
  }
}

export class AddressDTO {
  name: string;
  care_of?: string;
  attn?: string;
  address_lines?: string[];
  city: string;
  region?: string;
  postal_code: string;
  country: string;
  coordinates?: CoordinatesDTO;
  door_code?: string;
  static createFromModel(model: AddressModel): AddressDTO {
    return {
      name: model.name,
      care_of: model.careOf,
      attn: model.attn,
      address_lines: model.addressLines,
      city: model.city,
      region: model.region,
      postal_code: model.postalCode,
      country: model.country,
      coordinates: model.coordinates && CoordinatesDTO.createFromModel(model.coordinates),
      door_code: model.doorCode,
    };
  }
}

export class CoordinatesModel {
  lat: string;
  lng: string;

  static createFromDTO(dto: CoordinatesDTO | undefined): CoordinatesModel {
    return {
      lat: (dto && dto.lat && dto.lat.toString()) || '',
      lng: (dto && dto.lng && dto.lng.toString()) || '',
    };
  }
}

export class CoordinatesDTO {
  lat: number;
  lng: number;
  static createFromModel(model: CoordinatesModel): CoordinatesDTO {
    return {
      lat: parseFloat(model.lat) || 0,
      lng: parseFloat(model.lng) || 0,
    };
  }
}

export enum ParcelTypeEnum {
  PALLET = 'PALLET',
  PACKAGE = 'PACKAGE',
  NONE = 'NONE',
  LETTER = 'LETTER',
}

export enum StateEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum DeliveryTypeEnum {
  UNKNOWN = 'UNKNOWN',
  DELIVERY = 'DELIVERY',
  PICKUP = 'PICKUP',
  MAILBOX = 'MAILBOX',
  INSTORE = 'INSTORE',
}

export const createDeliveryTypeEnum = (deliveryType: string): DeliveryTypeEnum => {
  if (deliveryType != null && DeliveryTypeEnum[deliveryType as DeliveryTypeEnum]) {
    return DeliveryTypeEnum[deliveryType as DeliveryTypeEnum];
  }

  return DeliveryTypeEnum.UNKNOWN;
};

export type CarrierAddonsModel = AddonModel & {
  carrier: string;
  isOn: boolean;
};
