import * as React from 'react';
import { useDispatch } from 'react-redux';

import { siwActions } from '@src/modules/siw';
import { useSelector } from '@src/utils/hooks';

import { routerActions } from '@src/modules/router';
import { merchantsActions, MerchantsSelectors } from '../../modules/merchants';
import { SitesSelectors } from '../../modules/sites';
import { HeaderInfo, SiteMerchantChangeModal } from './components';

export const SelectorMenuContainer: React.FunctionComponent = () => {
  const isFetchingMerchants = useSelector(state => state.merchants.isFetching);
  const isFetchingSites = useSelector(state => state.sites.isFetching);
  const selectedSiteName = useSelector(state => SitesSelectors.getSelectedSiteName(state));
  const merchantsOptions = useSelector(state => MerchantsSelectors.getMerchantsOptions(state));
  const selectedMerchantId = useSelector(state => state.merchants.selectedMerchantId);
  const selectedMerchantName = useSelector(state =>
    MerchantsSelectors.getSelectedMerchantName(state)
  );
  const sitesOptions = useSelector(state => MerchantsSelectors.getSitesOptionsWithNoDrafts(state));
  const selectedSiteId = useSelector(state => state.sites.selectedSiteId);

  const [currentSiteName, setCurrentSiteName] = React.useState<string>('');
  React.useEffect(() => {
    if (selectedSiteName) {
      setCurrentSiteName(selectedSiteName);
    }
  }, [selectedSiteName]);
  const dispatch = useDispatch();

  const [isModalVisible, setModalVisible] = React.useState(false);
  const toggleModalState = () => setModalVisible(!isModalVisible);

  const handleSelectedMerchantChange = (id: string) =>
    dispatch(merchantsActions.changeActiveMerchant(id));

  const handleSiteAndMerchantChange = ({
    merchantId,
    siteId,
  }: {
    merchantId: string;
    siteId: string;
  }) => {
    toggleModalState();
    dispatch(merchantsActions.changeActiveMerchantAndSite({ merchantId, siteId }));
    dispatch(siwActions.resetWidgetSession());
    backToListsViews();
  };

  const backToListsViews = () => {
    if (location.pathname.search('region') >= 0) {
      dispatch(routerActions.push({ name: 'REGIONS_LIST' }));
    } else if (location.pathname.search('warehouse') >= 0) {
      dispatch(routerActions.push({ name: 'WAREHOUSES_LIST' }));
    }
  };

  return (
    <>
      <HeaderInfo
        merchantName={selectedMerchantName}
        siteName={currentSiteName || selectedSiteName}
        isFetching={isFetchingMerchants || isFetchingSites}
        onClick={toggleModalState}
      />
      <SiteMerchantChangeModal
        isFetching={isFetchingSites}
        visible={isModalVisible}
        onCancel={toggleModalState}
        onOk={toggleModalState}
        merchantsOptions={merchantsOptions}
        selectedMerchantId={selectedMerchantId}
        onChange={handleSelectedMerchantChange}
        sitesOptions={sitesOptions}
        selectedSiteId={selectedSiteId}
        onFormSubmit={handleSiteAndMerchantChange}
      />
    </>
  );
};
