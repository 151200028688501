import { Link } from '@src/components';
import { useFormatMessage } from '@src/i18n';
import theme from '@src/styles/theme.default';
import { Col } from 'antd';
import React, { FC, Fragment } from 'react';
import { style as tss } from 'typestyle';
import { StackRecord, TextWithCopy } from '../components';
import { TrackingRecord } from '../types';

type Props = {
  shipmentInfoAvailable: boolean;
  record?: TrackingRecord;
};

export const ReferencesInfoRow: FC<Props> = ({ shipmentInfoAvailable, record }) => {
  const formatMessage = useFormatMessage();

  return (
    <Fragment>
      <StackRecord
        label={formatMessage('TRACKING_NUMBERS.DETAILS.LABELS.TRACKING_NUMBER')}
        colSpan={6}
      >
        <TextWithCopy
          text={record?.tracking_number ?? ''}
          onCopyText="Tracking Number (SSCC) copied to clipboard."
        >
          <span className={styles.spanStyle}>{record?.tracking_number}</span>
        </TextWithCopy>
      </StackRecord>
      <StackRecord label={formatMessage('TRACKING_NUMBERS.DETAILS.LABELS.EXTERNAL_ID')} colSpan={6}>
        {(record?.external_id && (
          <TextWithCopy
            text={record?.external_id ?? ''}
            onCopyText="External ID copied to clipboard."
          >
            <span className={styles.spanStyle}>{record?.external_id}</span>
          </TextWithCopy>
        )) ??
          '-'}
      </StackRecord>
      <StackRecord label={formatMessage('TRACKING_NUMBERS.DETAILS.LABELS.TOS_ID')} colSpan={6}>
        {(record?.tos_id && (
          <TextWithCopy text={record.tos_id} onCopyText="TOS ID copied to clipboard.">
            <Link
              className={styles.linkStyles}
              route={{ name: 'TRANSPORT_ORDER_DETAILS', tosId: record.tos_id }}
            >
              {record.tos_id}
            </Link>
          </TextWithCopy>
        )) ??
          '-'}
      </StackRecord>
      {shipmentInfoAvailable ? (
        <StackRecord
          label={formatMessage('TRACKING_NUMBERS.DETAILS.LABELS.SHIPMENT_ID')}
          colSpan={6}
        >
          <TextWithCopy text={record!.shipment_id} onCopyText="Shipment ID copied to clipboard.">
            <Link
              className={styles.linkStyles}
              route={{ name: 'SHIPMENT_EDIT', shipmentId: record!.shipment_id }}
            >
              {record!.shipment_id}
            </Link>
          </TextWithCopy>
        </StackRecord>
      ) : (
        <Col span={6} />
      )}
    </Fragment>
  );
};

const styles = {
  spanStyle: tss({
    wordBreak: 'break-all',
  }),
  linkStyles: tss({
    cursor: 'pointer',
    color: theme.color.primary,
    wordBreak: 'break-all',
    $nest: {
      '&:hover': {
        color: theme.color.primaryLight,
      },
    },
  }),
};
