import { Col, Row, Tooltip } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { mapCountryCodeToName } from '@src/dictionaries/mapping';
import { FormattedMessage } from '@src/i18n';
import { WidgetFeaturesModel } from '@src/models';
import { configActions, configSelectors } from '@src/modules/config';
import { siwActions } from '@src/modules/siw';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { useDispatch, useSelector } from '@src/utils/hooks';

import { Spinner } from '@src/controls';
import { FeaturesTable } from './components';

type OwnProps = {
  countryCode: string;
};

export const CountrySettings: React.FunctionComponent<OwnProps> = ({ countryCode }) => {
  const dispatch = useDispatch();
  const widgetConfiguration = useSelector(
    configSelectors.getWidgetConfigurationByDraftOrCurrentSiteId
  );
  const widgetCountryConfiguration = useSelector(state =>
    configSelectors.getWidgetCountryConfiguration(state, countryCode)
  );
  const isCreatingDraft = useSelector(state => state.config.isCreatingDraft);

  const updateWidgetCountryConfiguration = (features: WidgetFeaturesModel) => {
    dispatchOnDraftSiteId(configActions.updateWidgetCountryConfigurationRequest)({
      model: {
        country: countryCode,
        features,
      },
      onComplete: () => dispatch(siwActions.resetWidgetSession()),
    });
  };
  const createWidgetCountryConfiguration = () => {
    dispatchOnDraftSiteId(configActions.createWidgetCountryConfigurationRequest)({
      model: {
        country: countryCode,
        features: widgetConfiguration.features ?? {}, // copy the default ones
      },
      onComplete: () => dispatch(siwActions.resetWidgetSession()),
    });
  };
  const countryName = mapCountryCodeToName(countryCode);
  return (
    <Row className={styles.wrapper}>
      <Col span={4} className={widgetCountryConfiguration ? styles.tableLabel : undefined}>
        <FormattedMessage id="FEATURES" />
      </Col>
      <Col span={20}>
        {widgetCountryConfiguration ? (
          <FeaturesTable
            isLoading={isCreatingDraft}
            featuresHeaderName={countryName}
            features={widgetCountryConfiguration?.features ?? {}}
            onFeatureChange={updateWidgetCountryConfiguration}
            referenceFeaturesHeaderName={'Default'}
            referenceFeatures={widgetConfiguration.features ?? {}}
          />
        ) : (
          <CreateFeatures onClick={createWidgetCountryConfiguration} isLoading={isCreatingDraft} />
        )}
      </Col>
    </Row>
  );
};

const CreateFeatures: React.FunctionComponent<{ onClick: () => void; isLoading: boolean }> = ({
  onClick,
  isLoading,
}) => (
  <Tooltip title={<FormattedMessage id="SET_COUNTRY_SETTINGS_TOOLTIP" />}>
    {isLoading ? (
      <Spinner delay={0} />
    ) : (
      <a onClick={e => onClick()}>
        <FormattedMessage id="SET_COUNTRY_SETTINGS" />
      </a>
    )}
  </Tooltip>
);

const styles = stylesheet({
  wrapper: {
    paddingTop: '24px',
  },
  tableLabel: {
    display: 'flex',
    alignItems: 'center',
    height: '54px',
  },
});
