import { comparator, sort, toLower, update } from 'ramda';

import { intl } from '@src/i18n';
import { ConfigRegionModel, DeliveryTypeEnum } from '@src/models';
import { services } from '@src/services';
import { RegionDetailsFormValues } from './region-details';

export const getCountriesByRegionType = (region: ConfigRegionModel) => {
  const regionType = getRegionTypeByCountryConfig(
    region.regionCountryConfig,
    region.regionPostalCodeConfig
  );
  switch (regionType) {
    case 'COUNTRIES_INCLUDED':
      return getRegionCountriesIncluded(region);
    case 'COUNTRIES_EXCLUDED':
      return getRegionCountriesExcluded(region);
    case 'ZIP_CODES_INCLUDED':
    case 'ZIP_CODES_EXCLUDED':
      return getRegionZipCodesCountryDetails(region);
  }
};

export const getTagColors = (
  regionCountryConfig: ConfigRegionModel['regionCountryConfig'],
  regionPostalCodeConfig: ConfigRegionModel['regionPostalCodeConfig']
) => {
  if (regionCountryConfig) {
    return regionCountryConfig.included
      ? { border: '#FFD749', background: '#FFF4CE', text: '#333' }
      : { border: '#7B61FF', background: '#F3F0FF', text: '#333' };
  }
  if (regionPostalCodeConfig) {
    return { border: '#A1DC00', background: '#F3FFD2', text: '#333' };
  }
  return {};
};

export const getRegionTypeByCountryConfig = (
  regionCountryConfig: ConfigRegionModel['regionCountryConfig'],
  regionPostalCodeConfig: ConfigRegionModel['regionPostalCodeConfig']
) => {
  if (regionCountryConfig) {
    return regionCountryConfig.included ? 'COUNTRIES_INCLUDED' : 'COUNTRIES_EXCLUDED';
  } else {
    return regionPostalCodeConfig?.postalCodes ? 'ZIP_CODES_INCLUDED' : 'ZIP_CODES_EXCLUDED';
  }
};

export const getRegionCountriesIncluded = (region: ConfigRegionModel) => {
  const type = getRegionTypeByCountryConfig(
    region.regionCountryConfig,
    region.regionPostalCodeConfig
  );
  if (type === 'COUNTRIES_INCLUDED') {
    return sort(
      comparator((a, b) => toLower(a) < toLower(b)),
      region.regionCountryConfig!.included!
    );
  } else {
    return [];
  }
};

export const getRegionCountriesExcluded = (region: ConfigRegionModel) => {
  const type = getRegionTypeByCountryConfig(
    region.regionCountryConfig,
    region.regionPostalCodeConfig
  );
  if (type === 'COUNTRIES_EXCLUDED') {
    return sort(
      comparator((a, b) => toLower(a) < toLower(b)),
      region.regionCountryConfig!.excluded!
    );
  }
  return [];
};

export const getRegionZipCodesCountryDetails = (region: ConfigRegionModel) => {
  const type = getRegionTypeByCountryConfig(
    region.regionCountryConfig,
    region.regionPostalCodeConfig
  );
  if (type === 'ZIP_CODES_INCLUDED' && region.regionPostalCodeConfig) {
    return [region.regionPostalCodeConfig?.country ?? ''];
  }
  if (type === 'ZIP_CODES_EXCLUDED' && region.regionPostalCodeConfig) {
    return [region.regionPostalCodeConfig?.country ?? ''];
  }
  return [];
};

export const getRegionZipCodesCodesDetails = (region: ConfigRegionModel) => {
  const type = getRegionTypeByCountryConfig(
    region.regionCountryConfig,
    region.regionPostalCodeConfig
  );
  if (type === 'ZIP_CODES_INCLUDED' && region.regionPostalCodeConfig) {
    return region.regionPostalCodeConfig.postalCodes?.join(',') ?? '';
  }
  if (type === 'ZIP_CODES_EXCLUDED' && region.regionPostalCodeConfig) {
    return region.regionPostalCodeConfig.excludedPostalCodes?.join(',') ?? '';
  }
  return '';
};

export const deliveryTypesOptions = [
  {
    value: DeliveryTypeEnum.DELIVERY,
    label: 'Delivery',
  },
  { value: DeliveryTypeEnum.INSTORE, label: 'Instore' },
  { value: DeliveryTypeEnum.MAILBOX, label: 'Mailbox' },
  { value: DeliveryTypeEnum.PICKUP, label: 'Pickup' },
];

export const sortCountriesCodesByTheirNames = (countriesCodes: string[] | undefined) => {
  const countriesWithNames = services.dictionariesService.getCountryCodeToCountryNameMapping();
  if (countriesCodes) {
    return sort(
      comparator((a, b) => toLower(countriesWithNames[a]) < toLower(countriesWithNames[b])),
      countriesCodes
    );
  }
  return undefined;
};

export const regionTypeOptions: {
  value: RegionDetailsFormValues['type'];
  label: string;
  labelTooltip?: string;
  disabled?: boolean;
}[] = [
  {
    value: 'COUNTRIES_INCLUDED',
    label: intl.formatMessage({ id: 'REGION_COUNTRIES_INCLUDED_LABEL' }),
    labelTooltip: intl.formatMessage({ id: 'REGION_COUNTRIES_INCLUDED_TOOLTIP' }),
  },
  {
    value: 'COUNTRIES_EXCLUDED',
    label: intl.formatMessage({ id: 'REGION_COUNTRIES_EXCLUDED_LABEL' }),
    labelTooltip: intl.formatMessage({ id: 'REGION_COUNTRIES_EXCLUDED_TOOLTIP' }),
  },
  {
    value: 'ZIP_CODES_INCLUDED',
    label: intl.formatMessage({ id: 'REGION_ZIP_CODES_INCLUDED_LABEL' }),
    labelTooltip: intl.formatMessage({ id: 'REGION_ZIP_CODES_INCLUDED_TOOLTIP' }),
  },
  {
    value: 'ZIP_CODES_EXCLUDED',
    label: intl.formatMessage({ id: 'REGION_ZIP_CODES_EXCLUDED_LABEL' }),
    labelTooltip: intl.formatMessage({ id: 'REGION_ZIP_CODES_EXCLUDED_TOOLTIP' }),
  },
];

export const regionTypeOptionsWithDisabledZipCodes = update(
  2,
  {
    ...regionTypeOptions[2],
    disabled: true,
    labelTooltip: intl.formatMessage({ id: 'REGION_ZIP_CODES_INCLUDED_TOOLTIP_DISABLED' }),
  },
  regionTypeOptions
);
