import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { style as tss } from 'typestyle';
import { useSelector } from '@src/utils/hooks';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { Button } from 'antd';
import { LABELS } from '../dictionaries';
import { authActions } from '../modules/auth';
import { getAuthUser } from '../modules/auth/auth-selectors';
import { defaultTheme } from '@src/styles';
import { Avatar, Tooltip } from '../../src/controls';
import {
  getMasterSiteIdOrEmpty,
  getMasterSiteNameOrEmpty,
} from '@src/modules/sites/sites-selectors';

export const UserCard = () => {
  const ldClient = useLDClient();
  const siteId = useSelector(getMasterSiteIdOrEmpty);
  const siteName = useSelector(getMasterSiteNameOrEmpty);
  const user = useSelector(getAuthUser);
  const dispatch = useDispatch();

  React.useEffect(() => {
    ldClient?.identify({
      key: user?.id,
      name: user?.name,
      email: user?.email,
      custom: { isSuperUser: !!user?.superUser, currentSiteId: siteId, currentSiteName: siteName },
    });
  }, [user, siteId, siteName]);

  return (
    <div className={classStyles.root}>
      <Avatar
        size="small"
        icon={<UserOutlined />}
        src={(user && user.picture) || ''}
        className={classStyles.avatar}
      />
      <span className={classStyles.userName}>
        {user ? user.name || user.email || LABELS.DEFAULT_NAME : LABELS.DEFAULT_NAME}
      </span>
      <Tooltip placement="top" title={LABELS.LOG_OUT_TOOLTIP}>
        <Button
          size="small"
          onClick={() => dispatch(authActions.logout())}
          className={classStyles.logout}
        >
          <LogoutOutlined />
        </Button>
      </Tooltip>
    </div>
  );
};

const classStyles = {
  root: tss({
    display: 'flex',
    alignItems: 'center',
    padding: '15px 20px',
    backgroundColor: defaultTheme.color.neutralDark,
    marginTop: 'auto',
    width: '100%',
  }),
  avatar: tss({
    backgroundColor: defaultTheme.color.primary,
    marginRight: '6px',
  }),
  logout: tss({
    marginLeft: 'auto',
    backgroundColor: defaultTheme.color.neutralDark,
  }),
  userName: tss({
    maxWidth: '90%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
};
