import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { getAllCountries } from '../../services/dictionaries-service';

export const filterAddressSelectOptions = (
  stringQuery: string,
  optionFromList: OptionData | OptionGroupData | undefined,
  optionsFromState: { title: string; desc: string; value: string }[]
) => {
  if (optionFromList === undefined) {
    return false;
  }
  if (!optionFromList.value) {
    return false;
  }
  const optionProps = optionsFromState.filter(address => address.value === optionFromList.value)[0];

  return optionProps
    ? `${optionProps.title} ${optionProps.desc}`.toUpperCase().includes(stringQuery.toUpperCase())
    : false;
};

export const getCountryConfigForAlpha2Code = (alpha2Code: string) =>
  getAllCountries().find(c => c.code === alpha2Code);
