import { Button, Input, Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { widerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { RBACGroupModel, RoleModel, ScopeType } from '@src/models';
import { getFormikError } from '@src/utils/forms';
import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { array, object, string } from 'yup';

const InputField = withFormItem(Input, widerLayout);
const SelectField = withFormItem(Select, widerLayout);

const ScopeOptions = [ScopeType.MERCHANT, ScopeType.SITE];

interface Props {
  model?: RBACGroupModel;
  roles: RoleModel[];
  onSubmit: (values: GroupFormValues) => void;
}

export interface GroupFormValues {
  name: string;
  scope: ScopeType;
  roleIds: RoleModel['id'][];
}

const GroupSchema = object({
  name: string().required(),
  scope: string().required(),
  roleIds: array().min(1, 'roles field must have at least 1 item').required(),
});

export const GroupForm: React.FunctionComponent<Props> = ({ onSubmit, roles, model }) => {
  return (
    <Formik
      onSubmit={values => onSubmit(values)}
      initialValues={{
        name: model ? model.name : '',
        scope: model ? model.scope.type : ScopeType.SITE,
        roleIds: model && model.roles ? model.roles.map(role => role.id) : [],
      }}
      validationSchema={GroupSchema}
    >
      {({ handleSubmit, errors, touched, setFieldValue }) => (
        <form onSubmit={handleSubmit} className={styles.formWrapper}>
          <Field name="name">
            {({ field }: FieldProps<GroupFormValues['name']>) => (
              <InputField
                {...field}
                labelAlign="left"
                label={<FormattedMessage id="NAME" />}
                error={getFormikError(touched.name, errors.name)}
                width={300}
              />
            )}
          </Field>
          <Field name="scope">
            {({ field }: FieldProps<GroupFormValues['scope']>) => (
              <SelectField
                {...field}
                labelAlign="left"
                options={ScopeOptions.map(o => ({ value: o, label: o }))}
                label={<FormattedMessage id="SCOPE" />}
                onChange={value => setFieldValue('scope', value)}
                disabled={!!model}
                error={getFormikError(touched.scope, errors.scope)}
              />
            )}
          </Field>
          <Field name="roleIds">
            {({ field }: FieldProps<GroupFormValues['roleIds']>) => (
              <SelectField
                {...field}
                filterOption={(stringQuery, { props: { children } }) =>
                  children ? children.toString().includes(stringQuery) : true
                }
                mode="multiple"
                labelAlign="left"
                options={roles.map(r => ({ label: r.name, value: r.id }))}
                label={<FormattedMessage id="ROLES" />}
                onChange={value => setFieldValue('roleIds', value)}
                error={getFormikError(touched.roleIds, errors.roleIds)}
              />
            )}
          </Field>
          <div className={styles.buttonContainer}>
            <Button type="primary" htmlType="submit" className={styles.submitButton}>
              <FormattedMessage id="SAVE" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

const styles = stylesheet({
  buttonContainer: {
    display: 'flex',
  },
  submitButton: {
    marginLeft: 'auto',
    marginTop: '10px',
  },
  formWrapper: {
    width: 450,
  },
});
