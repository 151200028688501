import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;

import { ContactModel } from '../../models';

const GET_CONTACTS_LIST_REQUEST = '@addressBook/GET_CONTACTS_LIST_REQUEST';
const GET_CONTACTS_LIST_SUCCESS = '@addressBook/GET_CONTACTS_LIST_SUCCESS';
const GET_CONTACTS_LIST_ERROR = '@addressBook/GET_CONTACTS_LIST_ERROR';
const CREATE_CONTACT_REQUEST = '@addressBook/CREATE_CONTACT_REQUEST';
const CREATE_TEMP_CONTACT = '@addressBook/CREATE_TEMP_CONTACT';
const CREATE_CONTACT_FROM_MODAL_REQUEST = '@addressBook/CREATE_CONTACT_FROM_MODAL_REQUEST';
const CREATE_CONTACT_SUCCESS = '@addressBook/CREATE_CONTACT_SUCCESS';
const CREATE_CONTACT_ERROR = '@addressBook/CREATE_CONTACT_ERROR';
const UPDATE_CONTACT_REQUEST = '@addressBook/UPDATE_CONTACT_REQUEST';
const UPDATE_CONTACT_FROM_MODAL_REQUEST = '@addressBook/UPDATE_CONTACT_FROM_MODAL_REQUEST';
const UPDATE_CONTACT_SUCCESS = '@addressBook/UPDATE_CONTACT_SUCCESS';
const UPDATE_CONTACT_ERROR = '@addressBook/UPDATE_CONTACT_ERROR';
const DELETE_CONTACTS_REQUEST = '@addressBook/DELETE_CONTACT_REQUEST';
const DELETE_CONTACTS_SUCCESS = '@addressBook/DELETE_CONTACT_SUCCESS';
const DELETE_CONTACTS_ERROR = '@addressBook/DELETE_CONTACT_ERROR';
const CREATE_CONTACTS_WITH_CSV_REQUEST = '@addressBook/CREATE_CONTACTS_WITH_CSV_REQUEST';
const CREATE_CONTACTS_WITH_CSV_SUCCESS = '@addressBook/CREATE_CONTACTS_WITH_CSV_SUCCESS';
const CREATE_CONTACTS_WITH_CSV_ERROR = '@addressBook/CREATE_CONTACTS_WITH_CSV_ERROR';
const CREATE_CONTACTS_WITH_CSV_PARSE_ERROR = '@addressBook/CREATE_CONTACTS_WITH_CSV_PARSE_ERROR';
const GET_CUSTOMER_CONTACTS_REQUEST = '@addressBook/GET_CUSTOMER_CONTACTS_REQUEST';
const GET_CUSTOMER_CONTACTS_SUCCESS = '@addressBook/GET_CUSTOMER_CONTACTS_SUCCESS';
const GET_CUSTOMER_CONTACTS_ERROR = '@addressBook/GET_CUSTOMER_CONTACTS_ERROR';
const GET_SENDER_CONTACTS_REQUEST = '@addressBook/GET_SENDER_CONTACTS_REQUEST';
const GET_SENDER_CONTACTS_SUCCESS = '@addressBook/GET_SENDER_CONTACTS_SUCCESS';
const GET_SENDER_CONTACTS_ERROR = '@addressBook/GET_SENDER_CONTACTS_ERROR';
const GET_DELIVERY_CONTACTS_REQUEST = '@addressBook/GET_DELIVERY_CONTACTS_REQUEST';
const GET_DELIVERY_CONTACTS_SUCCESS = '@addressBook/GET_DELIVERY_CONTACTS_SUCCESS';
const GET_DELIVERY_CONTACTS_ERROR = '@addressBook/GET_DELIVERY_CONTACTS_ERROR';

export const getContactsListRequest = createAction(
  GET_CONTACTS_LIST_REQUEST,
  resolve =>
    ({
      searchQuery = '',
      pageLimit = 200,
      pageNumber = 1,
      onComplete,
    }: {
      searchQuery?: string;
      pageLimit?: number;
      pageNumber?: number;
      onComplete?: () => void;
    }) =>
      resolve({ searchQuery, pageLimit, pageNumber }, { onComplete })
);
export const getContactsListSuccess = createAction(
  GET_CONTACTS_LIST_SUCCESS,
  resolve => (payload: { contacts: ContactModel[]; count: number }) => resolve(payload)
);
export const getContactsListError = createAction(
  GET_CONTACTS_LIST_ERROR,
  resolve => (error: string) => resolve(error)
);

export const getCustomerContactsRequest = createAction(
  GET_CUSTOMER_CONTACTS_REQUEST,
  resolve =>
    ({
      searchQuery = '',
      pageLimit = 200,
      pageNumber = 1,
    }: {
      searchQuery?: string;
      pageLimit?: number;
      pageNumber?: number;
    }) =>
      resolve({ searchQuery, pageLimit, pageNumber })
);
export const getCustomerContactsSuccess = createAction(
  GET_CUSTOMER_CONTACTS_SUCCESS,
  resolve => (payload: { contacts: ContactModel[]; count: number }) => resolve(payload)
);
export const getCustomerContactsError = createAction(
  GET_CUSTOMER_CONTACTS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const getSenderContactsRequest = createAction(
  GET_SENDER_CONTACTS_REQUEST,
  resolve =>
    ({
      searchQuery = '',
      pageLimit = 200,
      pageNumber = 1,
    }: {
      searchQuery?: string;
      pageLimit?: number;
      pageNumber?: number;
    }) =>
      resolve({ searchQuery, pageLimit, pageNumber })
);
export const getSenderContactsSuccess = createAction(
  GET_SENDER_CONTACTS_SUCCESS,
  resolve => (payload: { contacts: ContactModel[]; count: number }) => resolve(payload)
);
export const getSenderContactsError = createAction(
  GET_SENDER_CONTACTS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const getDeliveryContactsRequest = createAction(
  GET_DELIVERY_CONTACTS_REQUEST,
  resolve =>
    ({
      searchQuery = '',
      pageLimit = 200,
      pageNumber = 1,
    }: {
      searchQuery?: string;
      pageLimit?: number;
      pageNumber?: number;
    }) =>
      resolve({ searchQuery, pageLimit, pageNumber })
);
export const getDeliveryContactsSuccess = createAction(
  GET_DELIVERY_CONTACTS_SUCCESS,
  resolve => (payload: { contacts: ContactModel[]; count: number }) => resolve(payload)
);
export const getDeliveryContactsError = createAction(
  GET_DELIVERY_CONTACTS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const createContactRequest = createAction(
  CREATE_CONTACT_REQUEST,
  resolve => (payload: ContactModel) => resolve(payload)
);

export const createContactFromModalRequest = createAction(
  CREATE_CONTACT_FROM_MODAL_REQUEST,
  resolve => (payload: ContactModel) => resolve(payload)
);

export const createTempContact = createAction(
  CREATE_TEMP_CONTACT,
  resolve => (payload: ContactModel) => resolve(payload)
);

export const createContactSuccess = createAction(
  CREATE_CONTACT_SUCCESS,
  resolve => (payload: ContactModel) => resolve(payload)
);

export const createContactError = createAction(
  CREATE_CONTACT_ERROR,
  resolve => (payload: string) => resolve(payload)
);

export const updateContactRequest = createAction(
  UPDATE_CONTACT_REQUEST,
  resolve => (payload: ContactModel) => resolve(payload)
);

export const updateContactFromModalRequest = createAction(
  UPDATE_CONTACT_FROM_MODAL_REQUEST,
  resolve => (payload: ContactModel) => resolve(payload)
);

export const updateContactSuccess = createAction(
  UPDATE_CONTACT_SUCCESS,
  resolve => (payload: ContactModel) => resolve(payload)
);

export const updateContactError = createAction(
  UPDATE_CONTACT_ERROR,
  resolve => (payload: string) => resolve(payload)
);

export const deleteContactsRequest = createAction(
  DELETE_CONTACTS_REQUEST,
  resolve => (payload: string[]) => resolve(payload)
);

export const deleteContactsSuccess = createAction(
  DELETE_CONTACTS_SUCCESS,
  resolve => (payload: { count: number; deletedIds: string[] }) => resolve(payload)
);

export const deleteContactsError = createAction(
  DELETE_CONTACTS_ERROR,
  resolve => (payload: string) => resolve(payload)
);

export const createContactsWithCSVRequest = createAction(
  CREATE_CONTACTS_WITH_CSV_REQUEST,
  resolve => (base64File: string) => resolve(base64File)
);

export const createContactsWithCSVSuccess = createAction(
  CREATE_CONTACTS_WITH_CSV_SUCCESS,
  resolve => (payload: { contacts: ContactModel[]; count: number }) => resolve(payload)
);

export const createContactsWithCSVError = createAction(
  CREATE_CONTACTS_WITH_CSV_ERROR,
  resolve => (payload: string) => resolve(payload)
);

export const uploadContactsWithCSVError = createAction(CREATE_CONTACTS_WITH_CSV_PARSE_ERROR);
