import { Field, FieldProps, Formik, FormikProps } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { ComponentReturningChildren } from '@src/components';
import { Input, InputNumber, Select, Switch, TimePicker } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { inputStyle, MakeAutoSaveFormikEnhancer, widerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import {
  DeliveryTimeFormat,
  DeliveryTimeModel,
  DeliveryTimePropertiesModel,
  DeliveryTimeRange,
  DeliveryTimeUnit,
  UpdateCategoryDetailsModel,
  UpdateDeliveryTimeModel,
} from '@src/models';
import { defaultTheme } from '@src/styles';
import { getFormikError } from '@src/utils/forms';
import { stylesheet } from 'typestyle';
import { number, object, ref } from 'yup';

const LabelWrapper = withFormItem(ComponentReturningChildren, widerLayout);
const NumberField = withFormItem(InputNumber, widerLayout);
const SelectField = withFormItem(Select, widerLayout);
const SwitchField = withFormItem(Switch, widerLayout);
const InputField = withFormItem(Input, widerLayout);
const TimePickerField = withFormItem(TimePicker, widerLayout);

interface DeliveryTimePropertiesFormValues {
  format?: DeliveryTimeFormat;
  range?: DeliveryTimeRange;
  customText?: string;
  timespanFrom?: Date;
  timespanTo?: Date;
  useTimespan?: boolean;
  ignoreCutoffs?: boolean;
  keepDeliveryTimeAfterAddress?: boolean;
}

interface DeliveryTimeFormValues {
  unit: string;
  max: number;
  min: number;
}

type FormValues = DeliveryTimeFormValues & DeliveryTimePropertiesFormValues;

const TypedAutoSaveEnhancer = MakeAutoSaveFormikEnhancer<FormValues>();

interface Props {
  deliveryTimeProperties: DeliveryTimePropertiesModel;
  deliveryTime: DeliveryTimeModel;
  unit: DeliveryTimeUnit;
  onDeliveryTimeChange: ({
    deliveryTime,
    deliveryTimeProperties,
  }: {
    deliveryTime?: UpdateDeliveryTimeModel;
    deliveryTimeProperties?: UpdateCategoryDetailsModel['deliveryTimeProperties'];
  }) => void;
}

const FormSchema = object().shape({
  min: number().max(ref('max'), 'Minimum delivery time cannot be bigger than maximum'),
  max: number().min(ref('min'), 'Maximum delivery time cannot be lower than minimum'),
});

export const DeliveryTimeForm: React.FunctionComponent<Props> = ({
  deliveryTimeProperties,
  deliveryTime,
  onDeliveryTimeChange,
  unit,
}) => {
  const [useCustomText, setUseCustomText] = React.useState(!!deliveryTimeProperties.customText);

  return (
    <Formik
      // tslint:disable-next-line: no-empty
      onSubmit={() => {}}
      initialValues={{
        unit,
        min: deliveryTime.min,
        max: deliveryTime.max,
        format: deliveryTimeProperties.format || 'days',
        range: deliveryTimeProperties.range || 'INTERVAL',
        customText: deliveryTimeProperties.customText,
        timespanFrom:
          deliveryTimeProperties.timespan && deliveryTimeProperties.timespan.from
            ? moment(deliveryTimeProperties.timespan.from, 'HH:mm').toDate()
            : undefined,
        timespanTo:
          deliveryTimeProperties.timespan && deliveryTimeProperties.timespan.to
            ? moment(deliveryTimeProperties.timespan.to, 'HH:mm').toDate()
            : undefined,
        useTimespan: deliveryTimeProperties.useTimespan,
        ignoreCutoffs: deliveryTimeProperties.ignoreCutoffs,
        keepDeliveryTimeAfterAddress: deliveryTimeProperties.keepDeliveryTimeAfterAddress,
      }}
      enableReinitialize={true}
      validationSchema={FormSchema}
      validateOnBlur={true}
      isInitialValid={true}
    >
      {({ setFieldValue, values: formValues, touched, errors }: FormikProps<FormValues>) => {
        return (
          <>
            <SwitchField
              label="Use custom text"
              onChange={value => {
                setUseCustomText(value);
                if (!value) {
                  onDeliveryTimeChange({
                    deliveryTimeProperties: {
                      customText: '',
                    },
                  });
                }
              }}
              checked={useCustomText}
              labelTooltip={<FormattedMessage id="CUSTOM_TEXT_TOOLTIP" />}
            />
            {useCustomText ? (
              <TypedAutoSaveEnhancer
                name="customText"
                onSave={values =>
                  onDeliveryTimeChange({
                    deliveryTimeProperties: { customText: values.customText },
                  })
                }
                render={({ name, onBlur, onKeyDown }) => (
                  <Field name={name}>
                    {({ field }: FieldProps<DeliveryTimePropertiesFormValues['customText']>) => (
                      <InputField
                        label={<FormattedMessage id="CUSTOM_TEXT" />}
                        className={inputStyle}
                        {...field}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                      />
                    )}
                  </Field>
                )}
              />
            ) : (
              <>
                <TypedAutoSaveEnhancer
                  name="keepDeliveryTimeAfterAddress"
                  onSave={values =>
                    onDeliveryTimeChange({
                      deliveryTimeProperties: {
                        keepDeliveryTimeAfterAddress: values.keepDeliveryTimeAfterAddress,
                      },
                    })
                  }
                  render={({ name, onInstantChange }) => (
                    <Field name={name}>
                      {({
                        field,
                      }: FieldProps<
                        DeliveryTimePropertiesFormValues['keepDeliveryTimeAfterAddress']
                      >) => (
                        <SwitchField
                          checked={field.value}
                          label={<FormattedMessage id="KEEP_DELIVERY_TIME_AFTER_ADDRESS" />}
                          labelTooltip={
                            <FormattedMessage id="KEEP_DELIVERY_TIME_AFTER_ADDRESS_TOOLTIP" />
                          }
                          onChange={value => {
                            onInstantChange(value);
                          }}
                        />
                      )}
                    </Field>
                  )}
                />
                <TypedAutoSaveEnhancer
                  name="unit"
                  onSave={values => {
                    onDeliveryTimeChange({
                      deliveryTime: {
                        unit: values.unit as DeliveryTimeModel['unit'],
                      },
                      deliveryTimeProperties: {
                        format: values.unit === 'week' ? 'weeks' : 'days',
                      },
                    });
                  }}
                  render={({ name, onInstantChange }) => (
                    <Field name={name}>
                      {({ field }: FieldProps<DeliveryTimeFormValues['unit']>) => (
                        <SelectField
                          {...field}
                          options={[
                            { value: 'day', label: <FormattedMessage id="DAY" /> },
                            {
                              value: 'business_day',
                              label: <FormattedMessage id="BUSINESS_DAY" />,
                            },
                            { value: 'hour', label: <FormattedMessage id="HOUR" /> },
                            { value: 'minute', label: <FormattedMessage id="MINUTE" /> },
                            {
                              value: 'week',
                              label: <FormattedMessage id="CATEGORY_MODAL.DELIVERY_TIME.WEEK" />,
                            },
                          ]}
                          onChange={(value: string) => onInstantChange(value)}
                          label={<FormattedMessage id="UNIT" />}
                          className={inputStyle}
                        />
                      )}
                    </Field>
                  )}
                />
                <TypedAutoSaveEnhancer
                  name="min"
                  onSave={values =>
                    onDeliveryTimeChange({ deliveryTime: { min: values.min, max: values.max } })
                  }
                  render={({ name, onBlur, onKeyDown }) => (
                    <Field name={name}>
                      {({ field }: FieldProps<DeliveryTimeFormValues['min']>) => (
                        <NumberField
                          min={0}
                          {...field}
                          onChange={value => setFieldValue(name, value)}
                          label={<FormattedMessage id="MINIMUM" />}
                          className={inputStyle}
                          onBlur={onBlur}
                          onKeyDown={onKeyDown}
                          error={getFormikError(touched.min, errors.min)}
                        />
                      )}
                    </Field>
                  )}
                />
                <TypedAutoSaveEnhancer
                  name="max"
                  onSave={values =>
                    onDeliveryTimeChange({ deliveryTime: { max: values.max, min: values.min } })
                  }
                  render={({ name, onBlur, onKeyDown }) => (
                    <Field name={name}>
                      {({ field }: FieldProps<DeliveryTimeFormValues['max']>) => (
                        <NumberField
                          min={0}
                          {...field}
                          onChange={value => setFieldValue(name, value)}
                          label={<FormattedMessage id="MAXIMUM" />}
                          className={inputStyle}
                          onBlur={onBlur}
                          onKeyDown={onKeyDown}
                          error={getFormikError(touched.max, errors.max)}
                        />
                      )}
                    </Field>
                  )}
                />
                {(formValues.unit === 'day' ||
                  formValues.unit === 'business_day' ||
                  formValues.unit === 'week') && (
                  <TypedAutoSaveEnhancer
                    name="format"
                    onSave={values =>
                      onDeliveryTimeChange({ deliveryTimeProperties: { format: values.format } })
                    }
                    render={({ name, onInstantChange }) => (
                      <Field name={name}>
                        {({ field }: FieldProps<DeliveryTimePropertiesFormValues['format']>) => (
                          <SelectField
                            {...field}
                            options={
                              formValues.unit === 'day' || formValues.unit === 'business_day'
                                ? [
                                    {
                                      value: 'days',
                                      label: (
                                        <FormattedMessage id="DAY" values={{ multiple: true }} />
                                      ),
                                      rightText: <FormattedMessage id="DAY_EG" />,
                                    },
                                    {
                                      value: 'date',
                                      label: <FormattedMessage id="DATE" />,
                                      rightText: <FormattedMessage id="DATE_EG" />,
                                    },
                                    {
                                      value: 'weekday',
                                      label: <FormattedMessage id="WEEKDAY" />,
                                      rightText: <FormattedMessage id="WEEKDAY_EG" />,
                                    },
                                    {
                                      value: 'weekday_date',
                                      label: <FormattedMessage id="WEEKDAY_DATE" />,
                                      rightText: <FormattedMessage id="WEEKDAY_DATE_EG" />,
                                    },
                                  ]
                                : [
                                    {
                                      value: 'weeks',
                                      label: (
                                        <FormattedMessage id="CATEGORY_MODAL.DELIVERY_TIME.WEEKS" />
                                      ),
                                      rightText: (
                                        <FormattedMessage id="CATEGORY_MODAL.DELIVERY_TIME.WEEKS_EG" />
                                      ),
                                    },
                                    {
                                      value: 'weeks_numbers',
                                      label: (
                                        <FormattedMessage id="CATEGORY_MODAL.DELIVERY_TIME.WEEK_NUMBERS" />
                                      ),
                                      rightText: (
                                        <FormattedMessage id="CATEGORY_MODAL.DELIVERY_TIME.WEEK_NUMBERS_EG" />
                                      ),
                                    },
                                  ]
                            }
                            onChange={(value: string) => onInstantChange(value)}
                            label={<FormattedMessage id="FORMAT" />}
                            className={inputStyle}
                            error={getFormikError(touched.format || touched.unit, errors.format)}
                            labelTooltip={<FormattedMessage id="FORMAT_TOOLTIP" />}
                          />
                        )}
                      </Field>
                    )}
                  />
                )}
                <TypedAutoSaveEnhancer
                  name="range"
                  onSave={values => {
                    onDeliveryTimeChange({ deliveryTimeProperties: { range: values.range } });
                  }}
                  render={({ name, onInstantChange }) => (
                    <Field name={name}>
                      {({ field }: FieldProps<DeliveryTimePropertiesFormValues['range']>) => (
                        <SelectField
                          {...field}
                          options={[
                            { value: 'INTERVAL', label: 'Min - Max date interval' },
                            { value: 'MAX', label: 'Max date' },
                          ]}
                          onChange={(value: string) => onInstantChange(value)}
                          label={<FormattedMessage id="RANGE" />}
                          className={inputStyle}
                          error={getFormikError(touched.range || touched.unit, errors.range)}
                          labelTooltip={<FormattedMessage id="RANGE_TOOLTIP" />}
                        />
                      )}
                    </Field>
                  )}
                />

                {(formValues.unit === 'day' || formValues.unit === 'business_day') && (
                  <TypedAutoSaveEnhancer
                    name="useTimespan"
                    onSave={values =>
                      onDeliveryTimeChange({
                        deliveryTimeProperties: { useTimespan: values.useTimespan },
                      })
                    }
                    render={({ onInstantChange }) => (
                      <Field name="useTimespan">
                        {({
                          field,
                        }: FieldProps<DeliveryTimePropertiesFormValues['useTimespan']>) => (
                          <SwitchField
                            checked={field.value}
                            label={<FormattedMessage id="USE_TIMESPAN" />}
                            onChange={value => {
                              onInstantChange(value);
                            }}
                            labelTooltip={<FormattedMessage id="USE_TIMESPAN_TOOLTIP" />}
                          />
                        )}
                      </Field>
                    )}
                  />
                )}

                {(formValues.unit === 'hour' || formValues.unit === 'minute') && (
                  <TypedAutoSaveEnhancer
                    name="ignoreCutoffs"
                    onSave={values =>
                      onDeliveryTimeChange({
                        deliveryTimeProperties: { ignoreCutoffs: values.ignoreCutoffs },
                      })
                    }
                    render={({ onInstantChange }) => (
                      <Field name="ignoreCutoffs">
                        {({
                          field,
                        }: FieldProps<DeliveryTimePropertiesFormValues['ignoreCutoffs']>) => (
                          <SwitchField
                            checked={field.value}
                            label={<FormattedMessage id="IGNORE_CUTOFFS" />}
                            onChange={value => onInstantChange(value)}
                            labelTooltip={<FormattedMessage id="IGNORE_CUTOFFS_TOOLTIP" />}
                          />
                        )}
                      </Field>
                    )}
                  />
                )}

                {formValues.useTimespan && (
                  <LabelWrapper label="Timespan" className={styles.timeRange}>
                    <TypedAutoSaveEnhancer
                      name="timespanFrom"
                      onSave={(values: DeliveryTimePropertiesFormValues) => {
                        if (values.timespanFrom && values.timespanTo) {
                          onDeliveryTimeChange({
                            deliveryTimeProperties: {
                              timespan: {
                                from: moment(values.timespanFrom).format('HH:mm'),
                                to: moment(values.timespanTo).format('HH:mm'),
                              },
                            },
                          });
                        }
                      }}
                      render={({ name, onOpenChange }) => (
                        <Field name={name}>
                          {({
                            field,
                          }: FieldProps<DeliveryTimePropertiesFormValues['timespanFrom']>) => (
                            <TimePickerField
                              format="HH:mm"
                              placeholder="Start"
                              {...field}
                              value={field.value && moment(field.value, 'HH:mm')}
                              onBlur={event => {
                                if (event.currentTarget.value) {
                                  setFieldValue(
                                    name,
                                    moment(event.currentTarget.value, 'HH:mm').toDate()
                                  );
                                }
                              }}
                              onChange={momentValue => {
                                setFieldValue(name, momentValue);
                              }}
                              onOpenChange={onOpenChange}
                              minuteStep={15}
                              allowClear={false}
                              labelCol={{ span: 24 }}
                              className={tss({ width: '116px' })}
                            />
                          )}
                        </Field>
                      )}
                    />
                    <TypedAutoSaveEnhancer
                      name="timespanTo"
                      onSave={(values: DeliveryTimePropertiesFormValues) => {
                        if (values.timespanFrom && values.timespanTo) {
                          onDeliveryTimeChange({
                            deliveryTimeProperties: {
                              timespan: {
                                from: moment(values.timespanFrom).format('HH:mm'),
                                to: moment(values.timespanTo).format('HH:mm'),
                              },
                            },
                          });
                        }
                      }}
                      render={({ name, onOpenChange }) => (
                        <Field name={name}>
                          {({
                            field,
                          }: FieldProps<DeliveryTimePropertiesFormValues['timespanTo']>) => (
                            <TimePickerField
                              format="HH:mm"
                              minuteStep={15}
                              placeholder="End"
                              {...field}
                              onBlur={event => {
                                if (event.currentTarget.value) {
                                  setFieldValue(
                                    name,
                                    moment(event.currentTarget.value, 'HH:mm').toDate()
                                  );
                                }
                              }}
                              value={field.value && moment(field.value, 'HH:mm')}
                              onChange={momentValue => {
                                setFieldValue(name, momentValue);
                              }}
                              onOpenChange={onOpenChange}
                              allowClear={false}
                              labelCol={{ span: 24 }}
                              className={tss({ width: '116px' })}
                            />
                          )}
                        </Field>
                      )}
                    />
                    {(formValues.timespanFrom || formValues.timespanTo) && (
                      <button
                        className={styles.clearButton}
                        onClick={() => {
                          setFieldValue('timespanFrom', undefined);
                          setFieldValue('timespanTo', undefined);
                          onDeliveryTimeChange({
                            deliveryTimeProperties: {
                              timespan: null,
                            },
                          });
                        }}
                      >
                        <FormattedMessage id="CLEAR" />
                      </button>
                    )}
                  </LabelWrapper>
                )}
              </>
            )}
          </>
        );
      }}
    </Formik>
  );
};

const styles = stylesheet({
  timeRange: {
    display: 'flex',
  },
  clearButton: {
    background: 'transparent',
    border: 0,
    color: defaultTheme.color.primary,
    cursor: 'pointer',
  },
});
