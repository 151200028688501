import { AppstoreOutlined } from '@ant-design/icons';
import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { Descriptions, Empty, Row } from 'antd';
import { pathOr } from 'ramda';
import { useQuery } from 'react-query';

import { ContactDetails } from '@src/components/contact-details';
import { FormattedMessage } from '@src/i18n';
import { routerActions, routerSelectors } from '@src/modules/router';
import { commons } from '@src/styles';
import { useMasterSiteId, useSelector } from '@src/utils/hooks';
import { classes, stylesheet } from 'typestyle';
import { ContainerContent, ContainerFixedHeader } from '@src/components';
import { Card, Col } from '@src/controls';
import { LABELS } from '@src/dictionaries';
import { AddressModel, TransportOrderModel } from '@src/models';
import { parsePriceNumber } from '../regions/carrier-products/helpers';
import { CartItemsTable, OrderInformation, ShipmentsTable } from './components';
import { DeliveryInformation } from './components/delivery-information';
import { services } from '@src/services';
import { MADError } from '@src/utils';
import {
  transportOrdersActionCreators,
  TransportOrdersSelectors,
} from '@src/modules/transport-orders';

export const TransportOrderDetailsContainer: FunctionComponent<{ itemId: string }> = ({
  itemId,
}) => {
  const dispatch = useDispatch();
  const siteId = useMasterSiteId();
  const routerState = useSelector(routerSelectors.getRouterState);
  const shipments = useSelector(state =>
    TransportOrdersSelectors.getShipmentSummariesByTosId(state, itemId)
  );

  const {
    data: transportOrder,
    isFetching: isFetchingTransportOrders,
    error,
  } = useQuery<TransportOrderModel, MADError>({
    queryKey: ['getTransportOrder', itemId, siteId],
    queryFn: () => services.tosService.getTransportOrder(siteId, itemId),
  });

  const { isFetching: isFetchingShipmentSummaries } = useQuery({
    queryKey: ['getShipmentsSummariesByTosId', itemId, siteId],
    queryFn: () =>
      services.somSearchService.getShipmentList(
        siteId,
        transportOrder?.id ? [transportOrder.id] : []
      ),
    enabled: !!transportOrder?.id,
    staleTime: 1000 * 60 * 1, // one minute
    onError: () => dispatch(transportOrdersActionCreators.getShipmentSummariesForTosError()),
    onSuccess: data =>
      dispatch(
        transportOrdersActionCreators.getShipmentSummariesForTosSuccess({
          tosId: itemId,
          shipments: data.shipments,
        })
      ),
  });

  const { data: session } = useQuery({
    queryKey: ['getSessionByTos', itemId, siteId],
    queryFn: () =>
      services.siwService.getSession(siteId, transportOrder?.metadata.session.sessionId ?? ''),
    enabled:
      !!transportOrder?.metadata.session.sessionId && transportOrder?.metadata.source !== 'COS:v2',
  });

  return (
    <form>
      <ContainerFixedHeader
        hideMainButton
        title={<FormattedMessage id="TRANSPORT_ORDER" />}
        subtitle={`ID: ${itemId}`}
        createdAt={transportOrder?.createdAt}
        IconComponent={AppstoreOutlined}
        onBack={
          routerState.useCustomBack
            ? () => dispatch(routerActions.push({ name: 'TRANSPORT_ORDER_LIST' }))
            : () => dispatch(routerActions.goBack())
        }
        onBackLabel={LABELS.CANCEL}
        isLoading={isFetchingTransportOrders}
        error={error?.message}
      />
      <ContainerContent>
        <Row gutter={[16, 0]}>
          <Col span={8}>
            <Card
              title="Customer Address"
              className={classes(styles.addressCard, styles.card)}
              loading={isFetchingTransportOrders}
            >
              <ContactDetails
                address={transportOrder?.receiver.contactInfo.address ?? new AddressModel()}
                email={transportOrder?.receiver.contactInfo.email}
                phone={transportOrder?.receiver.contactInfo.phone}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title="Sender Address"
              className={classes(styles.addressCard, styles.card)}
              loading={isFetchingTransportOrders}
            >
              <ContactDetails
                address={
                  (transportOrder && generateSenderAddress(transportOrder)) || new AddressModel()
                }
                email={transportOrder?.sender.contactInfo.email}
                phone={transportOrder?.sender.contactInfo.phone}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title="Delivery Address"
              className={classes(styles.addressCard, styles.card)}
              loading={isFetchingTransportOrders}
            >
              <ContactDetails
                address={
                  transportOrder?.receiver.destination?.address ||
                  transportOrder?.receiver.contactInfo.address ||
                  new AddressModel()
                }
                email={transportOrder?.receiver.contactInfo.email}
                phone={transportOrder?.receiver.contactInfo.phone}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Card
              title="Order Information"
              className={styles.card}
              loading={isFetchingTransportOrders}
            >
              {transportOrder ? (
                <OrderInformation order={transportOrder} sessionDTO={session?.dto} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title="Delivery Information"
              className={styles.card}
              loading={isFetchingTransportOrders}
            >
              {transportOrder ? (
                <DeliveryInformation order={transportOrder} />
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Card>
          </Col>
        </Row>
        <Card title="Order Content" className={styles.card} loading={isFetchingTransportOrders}>
          <>
            <Descriptions>
              <Descriptions.Item label="Total value" className={commons.descriptionList}>
                {transportOrder?.cart.totalValue
                  ? parsePriceNumber(transportOrder?.cart.totalValue)
                  : '-'}
                &nbsp;
                {transportOrder?.cart.currency ?? ''}
              </Descriptions.Item>
            </Descriptions>
            {transportOrder?.cart.items ? (
              <CartItemsTable
                cartItems={transportOrder?.cart.items}
                currency={transportOrder?.cart.currency}
              />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </>
        </Card>
        <Card title="Shipments" loading={isFetchingTransportOrders || isFetchingShipmentSummaries}>
          <ShipmentsTable items={shipments || []} tosId={transportOrder?.id} />
        </Card>
      </ContainerContent>
    </form>
  );
};

/**
 * Generates actual sender address if shipping legs are present.

 * @param model Represents a data model that contains transportOrder data.
 * @return The from.address from the last element of shipping legs array, or
 *        a sender.address if not present.
 */
const generateSenderAddress = (model: TransportOrderModel) => {
  return pathOr(
    model.sender.contactInfo.address,
    ['route', 'shippingLegs', -1, 'from', 'address'],
    model
  );
};

const styles = stylesheet({
  addressCard: {
    $nest: {
      '.ant-card-body': {
        minHeight: '100px',
      },
    },
  },
  card: {
    marginBottom: '1.5em',
  },
});
