export class SessionEventDTO {
  id: string;
  site_id: string;
  triggered_by: string;
  trace_id: string;
  happened_at: string;
}

export class SessionEventModel {
  id: string;
  siteID: string;
  triggeredBy: string;
  traceID: string;
  happenedAt: string;

  static createFromDTO(dto: SessionEventDTO): SessionEventModel {
    return {
      id: dto.id,
      siteID: dto.site_id,
      triggeredBy: dto.triggered_by,
      traceID: dto.trace_id,
      happenedAt: dto.happened_at,
    };
  }
}
