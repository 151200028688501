import * as React from 'react';
import { style } from 'typestyle';

import { UsersTableInfo } from '.';

const styles = {
  userItem: style({
    display: 'flex',
    width: '100%',
  }),
  image: style({
    width: 60,
    height: 60,
  }),
};

interface IProps {
  data: {
    email?: UsersTableInfo['email'];
    name?: UsersTableInfo['name'];
    picture?: UsersTableInfo['picture'];
    externalId?: UsersTableInfo['externalId'];
  };
}

const EMPTY_VALUE = '(empty)';

export class UsersItem extends React.Component<IProps, any> {
  render() {
    const { email = EMPTY_VALUE, name, externalId } = this.props.data;

    return (
      <div className={styles.userItem}>
        <div>
          {name && (
            <b>
              {name}
              <br />
            </b>
          )}
          <div>{email}</div>
          <div>{externalId}</div>
        </div>
      </div>
    );
  }
}
