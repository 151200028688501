import React, { FC } from 'react';
import { style as tss } from 'typestyle';
import { Col } from '@src/controls';

type Props = {
  label: string;
  colSpan: number;
};

export const StackRecord: FC<Props> = ({ colSpan, label, children }) => (
  <Col span={colSpan}>
    <div className={styles.blockStyles}>
      <span>{label}</span>
      {children}
    </div>
  </Col>
);

const styles = {
  blockStyles: tss({
    display: 'flex',
    gap: '20px',
    flexDirection: 'column',
  }),
};
