import { InfoCircleOutlined } from '@ant-design/icons';
import { Radio, Tooltip } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio/interface';
import * as React from 'react';
import { stylesheet } from 'typestyle';

const RadioGroup = Radio.Group;

export interface IProps extends RadioGroupProps {
  buttons?: boolean;
  options: { value: string; label: string; labelTooltip?: string; disabled?: boolean }[];
}

const Component: React.FunctionComponent<IProps> = props => {
  const { children, buttons, options, ...restProps } = props;
  const Option = !!buttons ? Radio.Button : Radio;

  const renderOptions = options.map((item, index) => (
    <div key={item.value + index} className={styles.wrapper}>
      <Option value={item.value} disabled={item.disabled}>
        {item.label}
      </Option>
      {item.labelTooltip && (
        <Tooltip title={item.labelTooltip}>
          <InfoCircleOutlined style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.5)' }} />
        </Tooltip>
      )}
    </div>
  ));

  return <RadioGroup {...restProps}>{options ? renderOptions : children}</RadioGroup>;
};

const styles = stylesheet({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 24px',
    alignItems: 'center',
  },
});

export default Component;
