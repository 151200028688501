import { Card as AntdCard } from 'antd';
import { CardProps } from 'antd/lib/card';
import * as React from 'react';

const Component: React.StatelessComponent<CardProps> = props => {
  const { children, ...restProps } = props;

  return <AntdCard {...restProps}>{children}</AntdCard>;
};

export default Component;
