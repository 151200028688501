import * as React from 'react';

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}

const Component: React.StatelessComponent<IProps> = props => {
  const { children, ...restProps } = props;

  return <div {...restProps}>{children}</div>;
};

export default Component;
