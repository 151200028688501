import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';

import { TemplateModelResponse } from '../../models';
import { Reducer } from '../../modules';
import { appActions } from '../app';
import * as userprofileActions from './actions';

// STATE
export type State = Readonly<{
  isFetching: boolean;
  error: string | null;
  templates: TemplateModelResponse[];
  shipmentIds: string[] | undefined;
}>;

const isFetching: Reducer<State['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(userprofileActions.getTemplateListRequest):
    case getType(userprofileActions.createTemplateRequest):
    case getType(userprofileActions.deleteTemplatesRequest):
    case getType(userprofileActions.updateTemplateRequest):
    case getType(userprofileActions.createShipmentActionRequest):
    case getType(userprofileActions.getShipmentIdsRequest):
      return true;

    case getType(userprofileActions.getTemplateListSuccess):
    case getType(userprofileActions.getTemplateListError):
    case getType(userprofileActions.createTemplateSuccess):
    case getType(userprofileActions.createTemplateError):
    case getType(userprofileActions.deleteTemplatesSuccess):
    case getType(userprofileActions.deleteTemplatesError):
    case getType(userprofileActions.updateTemplateSuccess):
    case getType(userprofileActions.updateTemplateError):
    case getType(userprofileActions.createShipmentActionSuccess):
    case getType(userprofileActions.createShipmentActionError):
    case getType(userprofileActions.getShipmentIdsSuccess):
    case getType(userprofileActions.getShipmentIdsError):
      return false;

    default:
      return state;
  }
};

const error: Reducer<State['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(userprofileActions.getTemplateListError):
    case getType(userprofileActions.createTemplateError):
    case getType(userprofileActions.deleteTemplatesError):
    case getType(userprofileActions.updateTemplateError):
    case getType(userprofileActions.createShipmentActionError):
    case getType(userprofileActions.getShipmentIdsError):
      return action.payload;

    case getType(userprofileActions.getTemplateListRequest):
    case getType(userprofileActions.createTemplateRequest):
    case getType(userprofileActions.deleteTemplatesRequest):
    case getType(userprofileActions.updateTemplateRequest):
    case getType(userprofileActions.createShipmentActionRequest):
    case getType(userprofileActions.getShipmentIdsRequest):
    case getType(appActions.resetErrors):
      return null;

    default:
      return state;
  }
};

const templates: Reducer<State['templates']> = (state = [], action) => {
  switch (action.type) {
    case getType(userprofileActions.getTemplateListSuccess):
      return action.payload;

    case getType(userprofileActions.createTemplateSuccess):
      return state.concat(action.payload);

    case getType(userprofileActions.updateTemplateSuccess):
      return state.map(t => (t.id !== action.payload.id ? t : action.payload));

    case getType(userprofileActions.deleteTemplatesSuccess):
      return state.filter(t => !action.payload.includes(t.id));

    default:
      return state;
  }
};

const shipmentIds: Reducer<State['shipmentIds']> = (state = [], action) => {
  switch (action.type) {
    case getType(userprofileActions.getShipmentIdsSuccess):
      return action.payload.shipmentIds;

    default:
      return state;
  }
};

const reducer: Reducer<State> = combineReducers({
  isFetching,
  error,
  templates,
  shipmentIds,
});

export default reducer;
