import { combineEpics, Epic } from 'redux-observable';
import { from as observableFrom, of as observableOf, timer as observableTimer } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { ERRORS } from '@src/dictionaries';
import { RootAction, RootState, Services } from '@src/modules';
import { MADError } from '@src/utils';
import { shipmentNotificationsActions } from '.';
import { authActions, authSelectors } from '../auth';
import { merchantsActions } from '../merchants';
import { SitesSelectors } from '../sites';

const FIVE_MINUTES = 300000;
const FIRST_EMISSION = 0;

const createTrigger: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { shipmentNotificationsService, loggingService, messageService }
) =>
  action$.pipe(
    filter(isActionOf(shipmentNotificationsActions.createTriggerRequest)),
    switchMap(action =>
      observableFrom(shipmentNotificationsService.createTrigger(action.payload)).pipe(
        map(() => shipmentNotificationsActions.createTriggerSuccess()),
        tap(() => messageService.success(ERRORS.CREATE_TRIGGER_SUCCESS)),
        catchError((error: MADError) =>
          observableOf(shipmentNotificationsActions.createTriggerError(error.message)).pipe(
            tap(() => {
              messageService.error(ERRORS.CANCEL_FAILED);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const setShipmentNotificationsTimer: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { shipmentNotificationsService, loggingService }
) =>
  action$.pipe(
    filter(
      () =>
        !!SitesSelectors.getSelectedSiteIdOrEmpty(state$.value) &&
        !!authSelectors.getAuthUser(state$.value)
    ),
    take(1),
    switchMap(() =>
      observableTimer(FIRST_EMISSION, FIVE_MINUTES).pipe(
        switchMap(() =>
          observableFrom(
            shipmentNotificationsService.listShipmentNotifications({
              siteId: SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
              userId: authSelectors.getAuthUser(state$.value)!.id,
            })
          ).pipe(
            map(shipmentNotificationsActions.listNotificationsSuccess),
            catchError((error: MADError) =>
              observableOf(shipmentNotificationsActions.listNotificationsError(error.message)).pipe(
                tap(() => loggingService.logError(error))
              )
            )
          )
        ),
        takeUntil(action$.pipe(filter(isActionOf(authActions.logout))))
      )
    )
  );

const listShipmentNotifications: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { shipmentNotificationsService, loggingService }
) =>
  action$.pipe(
    filter(
      isActionOf([
        merchantsActions.changeActiveMerchantAndSite,
        shipmentNotificationsActions.listNotificationsRequest,
      ])
    ),
    switchMap(action =>
      observableFrom(
        shipmentNotificationsService.listShipmentNotifications({
          siteId: action.payload.siteId,
          userId: authSelectors.getAuthUser(state$.value)!.id,
        })
      ).pipe(
        map(shipmentNotificationsActions.listNotificationsSuccess),
        catchError((error: MADError) =>
          observableOf(shipmentNotificationsActions.listNotificationsError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const markNotificationAsRead: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { shipmentNotificationsService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(shipmentNotificationsActions.markNotificationsAsReadRequest)),
    mergeMap(({ payload }) =>
      observableFrom(shipmentNotificationsService.markShipmentNotificationsAsRead(payload)).pipe(
        map(shipmentNotificationsActions.markNotificationsAsReadSuccess),
        catchError((error: MADError) =>
          observableOf(
            shipmentNotificationsActions.markNotificationsAsReadError({
              error: error.message,
              ids: payload.ids,
            })
          ).pipe(
            tap(() => {
              messageService.error(ERRORS.MARK_NOTIFICATIONS_AS_READ_ERROR);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const listNotificationTriggers: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { shipmentNotificationsService, loggingService }
) =>
  action$.pipe(
    filter(
      isActionOf([
        shipmentNotificationsActions.listNotificationTriggersRequest,
        shipmentNotificationsActions.deleteNotificationTriggerError,
        shipmentNotificationsActions.createTriggerSuccess,
      ])
    ),
    switchMap(() =>
      observableFrom(
        shipmentNotificationsService.getNotificationTriggersList({
          siteId: SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
          userId: authSelectors.getAuthUser(state$.value)!.id,
        })
      ).pipe(
        map(shipmentNotificationsActions.listNotificationTriggersSuccess),
        catchError((error: MADError) =>
          observableOf(
            shipmentNotificationsActions.listNotificationTriggersError(error.message)
          ).pipe(tap(() => loggingService.logError(error)))
        )
      )
    )
  );

const deleteNotificationTrigger: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { shipmentNotificationsService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(shipmentNotificationsActions.deleteNotificationTriggerRequest)),
    switchMap(action =>
      observableFrom(
        shipmentNotificationsService.deleteNotificationTrigger({
          siteId: SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
          userId: authSelectors.getAuthUser(state$.value)!.id,
          triggerId: action.payload,
        })
      ).pipe(
        map(shipmentNotificationsActions.deleteNotificationTriggerSuccess),
        catchError((error: MADError) =>
          observableOf(
            shipmentNotificationsActions.deleteNotificationTriggerError(error.message)
          ).pipe(
            tap(() => {
              messageService.error(ERRORS.DELETE_TRIGGER_ERROR);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

export const shipmentNotificationsEpics = combineEpics(
  createTrigger,
  setShipmentNotificationsTimer,
  markNotificationAsRead,
  listShipmentNotifications,
  listNotificationTriggers,
  deleteNotificationTrigger
);
