import type { BookingStep } from '@src/models';
import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { style as tss } from 'typestyle';
import { TextStack } from './text-stack';

type Props = {
  step: BookingStep;
};

export const MethodCard: FC<Props> = ({ step }) => (
  <div className={styles.card}>
    <Row gutter={8}>
      <Col span={6}>
        <TextStack title="Name" content={[step.name]} />
      </Col>
      <Col span={12}>
        <TextStack
          title="Conditions"
          content={step.filters?.map(({ expression }) => expression) ?? []}
        />
      </Col>
      <Col span={6}>
        <TextStack title="Actions" content={step.actions.map(({ expression }) => expression)} />
      </Col>
    </Row>
  </div>
);

const styles = {
  card: tss({
    padding: '16px 24px',
    backgroundColor: 'white',
    borderRadius: '2px',
  }),
};
