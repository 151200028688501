import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../modules';

type Props = {
  appAuth: { show: () => void; hide: () => void };
};

class Auth0Login extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.appAuth.show();
  }

  componentWillUnmount() {
    this.props.appAuth.hide();
  }

  render() {
    return null;
  }
}

function mapStateToProps(state: RootState) {
  return {};
}

const Connected = connect(mapStateToProps, {})(Auth0Login);
export default Connected;
