import { InputProps } from 'antd/lib/input/Input';
import * as React from 'react';
import { Input } from '../controls';

export interface IProps extends InputProps {
  editable: boolean;
  value: string;
  onChangeHandler: (value: string) => void;
}

// This component should be removed, use TableEditableCell instead
export const EditableTableCell: React.SFC<IProps> = props => {
  const { editable, value, onChangeHandler, ...restProps } = props;

  return (
    <div>
      {editable ? (
        <Input
          {...restProps}
          defaultValue={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.value)}
        />
      ) : (
        value
      )}
    </div>
  );
};
