import * as React from 'react';

import { Spinner } from '@src/controls';
import { FormattedMessage } from '@src/i18n';

type OwnProps = {
  onClick: () => void;
  updateMode: boolean;
  isLoading: boolean;
};

export const PreselectionOrderLink: React.FC<OwnProps> = ({ onClick, updateMode, isLoading }) => {
  return isLoading ? (
    <Spinner delay={0} />
  ) : (
    <a onClick={e => onClick()}>
      {updateMode ? (
        <FormattedMessage id="UPDATE_PRESELECTION_ORDER" />
      ) : (
        <FormattedMessage id="SET_PRESELECTION_ORDER" />
      )}
    </a>
  );
};
