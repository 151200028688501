import * as React from 'react';

import { ComponentReturningChildren } from '@src/components';
import { Select, TabPane, Tabs } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, inputStyle, widerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { CategoryLabelModel, LabelLocalizationModel } from '@src/models';
import { configActions } from '@src/modules/config';
import { getAvailableLocalesOptions } from '@src/modules/dictionaries/selectors';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { useSelector } from '@src/utils/hooks';

import { LabelTranslationEdit } from './label-translation-edit';

const LabelWrapper = withFormItem(ComponentReturningChildren, widerLayout);

interface Props {
  label: CategoryLabelModel;
  categoryId: string;
}

export const LabelTranslationsEdit: React.FC<Props> = ({ label, categoryId }) => {
  const availableLocalesOptions = useSelector(getAvailableLocalesOptions);

  const [translations, updateTranslations] = React.useState<{
    [key: string]: LabelLocalizationModel | undefined;
  }>(label.localization ?? {});

  React.useEffect(() => {
    updateTranslations(label.localization ?? {});
  }, [label]);

  const emptyTranslation: LabelLocalizationModel = {
    name: '',
    description: '',
  };

  const deleteTranslations = (value: string) => {
    dispatchOnDraftSiteId(configActions.deleteCategoryLabelTranslationsRequest)({
      model: {
        categoryId,
        labelId: label.id,
        locale: value,
      },
    });
    return null;
  };

  return (
    <AutoSaveForm>
      <LabelWrapper
        label={<FormattedMessage id="CATEGORY_LABEL_MODAL.TRANSLATIONS" />}
        labelTooltip={<FormattedMessage id="CATEGORY_LABEL_MODAL.TRANSLATIONS_TOOLTIP" />}
      >
        <Select
          placeholder="Select locales"
          mode="multiple"
          options={availableLocalesOptions}
          value={Object.keys(translations)}
          onChange={(val: string[]) => {
            const newLocales = val.reduce((allLocales, locale) => {
              return {
                ...allLocales,
                [locale]:
                  label.localization && label.localization[locale]
                    ? label.localization[locale]
                    : emptyTranslation,
              };
            }, {});
            updateTranslations(newLocales);
          }}
          onDeselect={deleteTranslations}
          className={inputStyle}
        />
        <Tabs defaultActiveKey={Object.keys(translations)[0]}>
          {Object.entries(translations).map(
            ([language, translation]: [string, LabelLocalizationModel]) => (
              <TabPane tab={language} key={language}>
                <LabelTranslationEdit
                  language={language}
                  categoryId={categoryId}
                  labelId={label.id}
                  translation={translation}
                />
              </TabPane>
            )
          )}
        </Tabs>
      </LabelWrapper>
    </AutoSaveForm>
  );
};
