import { QuestionCircleOutlined } from '@ant-design/icons';
import * as React from 'react';
import { classes, style as tss } from 'typestyle';

import { Tooltip } from '../controls';

export interface ILabelProps {
  text: string;
  tooltip?: string;
  className?: string;
  required?: boolean;
}

export const Label: React.FunctionComponent<ILabelProps> = props => {
  const { text, tooltip, className, required } = props;

  const tooltipOrNull = tooltip && (
    <Tooltip title={tooltip}>
      <QuestionCircleOutlined style={{ fontSize: '16px' }} />
    </Tooltip>
  );

  return (
    <span className={required ? classes(className, styles.required) : className}>
      {text} {tooltipOrNull}
    </span>
  );
};

const styles = {
  required: tss({
    $nest: {
      '&::before': {
        display: 'inline-block',
        marginRight: '4px',
        color: '#ff4d4f',
        fontSize: '14px',
        fontFamily: 'SimSun, sans-serif',
        lineHeight: 1,
        content: '"*"',
      },
    },
  }),
};
