import { Button, Col, Popconfirm, Row } from 'antd';
import { isEmpty } from 'ramda';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { style as tss } from 'typestyle';

import { ShippingMethodWithLogo } from '@src/components';
import { Link } from '@src/components/link';
import { Tooltip } from '@src/controls';
import { ConfigRegionModel, CutoffTimesModel, Weekdays } from '@src/models';
import { WarehouseCutoffTimesFormTimePicker } from './warehouse-cutoff-times-form-timePicker';
import { WarehouseCutoffTimesSelect } from './warehouse-cutoff-times-select';
import { WarehouseCutoffTimesToggle } from './warehouse-cutoff-times-toggle';

const dayMap: { [key in Weekdays]: string } = {
  mon: 'Monday',
  tue: 'Tuesday',
  wed: 'Wednesday',
  thu: 'Thursday',
  fri: 'Friday',
  sat: 'Saturday',
  sun: 'Sunday',
};
interface CutoffTimesFormValue {
  time: string;
}

interface OwnProps {
  method: string;
  cutoffTimes?: CutoffTimesModel;
  carrierCutoff?: boolean;
  onChangeDate: (
    values: CutoffTimesFormValue,
    method: string,
    dayKey: string,
    index: number
  ) => void;
  onAddCutoff: (dayKey: string, method: string, time: string) => void;
  onRemoveCutoff: (dayKey: string, method: string, index: number) => void;
  onCopyTimeForCutoffTimes: (method: string, time: string, copy: string) => void;
  onToggleCutoffs: (method: string, carrierCutoff: boolean) => void;
  regionsWithSelectedMethodForWarehouse: ConfigRegionModel[];
  onDeleteClick: () => void;
}

export const WarehouseCutoffTimesForm: React.FunctionComponent<OwnProps> = ({
  method,
  cutoffTimes,
  carrierCutoff,
  onChangeDate,
  onAddCutoff,
  onCopyTimeForCutoffTimes,
  onRemoveCutoff,
  onToggleCutoffs,
  regionsWithSelectedMethodForWarehouse,
  onDeleteClick,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Row className={styles.wrapper}>
      <Col span={8}>
        <ShippingMethodWithLogo method={method} />
      </Col>
      <Col span={16}>
        <Row>
          <Col flex="1" className={styles.toggleText}>
            {formatMessage({ id: 'TOGGLE_CUTOFF_TIMES' })}
          </Col>
          <Col className={styles.switchWrapper}>
            <WarehouseCutoffTimesToggle
              method={method}
              carrierCutoff={carrierCutoff}
              onToggleCutoffs={onToggleCutoffs}
            />
          </Col>
        </Row>
        <div className={styles.buttonWrapper}>
          <Tooltip
            title={
              !isEmpty(regionsWithSelectedMethodForWarehouse) && (
                <>
                  {formatMessage(
                    {
                      id: 'WAREHOUSE_DETAILS.SCHEDULE_REMOVE_TOOLTIP',
                    },
                    { method }
                  )}
                  <div>
                    <strong>
                      {regionsWithSelectedMethodForWarehouse.map((region, index) => (
                        <>
                          <Link
                            route={{ name: 'REGION_DETAILS', regionId: region.id }}
                            usePrimaryColors={true}
                            showIcon={true}
                          >
                            {region.name}
                          </Link>
                          {index === regionsWithSelectedMethodForWarehouse.length - 1 ? null : (
                            <span className={styles.linksSeparator}>,</span>
                          )}
                        </>
                      ))}
                    </strong>
                  </div>
                </>
              )
            }
          >
            <Popconfirm
              title={formatMessage({ id: 'CONFIRMATION_HEADER' })}
              okText={formatMessage({ id: 'YES' })}
              cancelText={formatMessage({ id: 'NO' })}
              onConfirm={onDeleteClick}
              disabled={!isEmpty(regionsWithSelectedMethodForWarehouse)}
            >
              <Button type="link" disabled={!isEmpty(regionsWithSelectedMethodForWarehouse)}>
                {formatMessage({ id: 'DELETE' })}
              </Button>
            </Popconfirm>
          </Tooltip>
        </div>
        {!carrierCutoff && cutoffTimes && (
          <>
            {Object.entries(dayMap).map(([dayKey, dayName]: [keyof CutoffTimesModel, string]) => (
              <>
                <Row key={dayKey}>
                  <Col span={8}>
                    <div className={styles.dayName}>{dayName}</div>
                  </Col>
                  <Col span={16} className={styles.timePickerWrapper}>
                    {cutoffTimes[dayKey] &&
                    !isEmpty(cutoffTimes[dayKey]) &&
                    cutoffTimes[dayKey]!.times ? (
                      cutoffTimes[dayKey]!.times!.map((time, index) => (
                        <WarehouseCutoffTimesFormTimePicker
                          key={index}
                          time={time}
                          onChangeDate={values => onChangeDate(values, method, dayKey, index)}
                          onAddCutoff={() => onAddCutoff(dayKey, method, time)}
                          onRemoveCutoff={() => onRemoveCutoff(dayKey, method, index)}
                          onCopyTimeForCutoffTimes={(copy: string) =>
                            onCopyTimeForCutoffTimes(
                              method,
                              cutoffTimes[dayKey]!.times![index],
                              copy
                            )
                          }
                        />
                      ))
                    ) : (
                      <div className={styles.emptyMethodIconSelectorWrapper}>
                        <div className={styles.placeholder} />
                        <div className={styles.iconSelectorWrapper}>
                          <WarehouseCutoffTimesSelect
                            onAddCutoff={() => onAddCutoff(dayKey, method, '00:00')}
                          />
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            ))}
          </>
        )}
      </Col>
    </Row>
  );
};

const styles = {
  timePickerWrapper: tss({
    bottom: '4px',
  }),
  wrapper: tss({
    borderTop: '1px solid rgba(0, 0, 0, 0.09)',
    paddingTop: '10px',
    marginBottom: '10px',
  }),
  shippingMethod: tss({
    paddingLeft: '11px',
    paddingRight: '36px',
  }),
  dayName: tss({
    color: 'rgba(0, 0, 0, 0.5)',
    paddingLeft: '11px',
  }),
  icon: tss({
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 4px',
    cursor: 'pointer',
  }),
  placeholder: tss({
    width: '90%',
  }),
  iconSelectorWrapper: tss({
    width: '10%',
  }),
  emptyMethodIconSelectorWrapper: tss({
    display: 'flex',
    $nest: {
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },
  }),
  buttonWrapper: tss({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '5px',
  }),
  linksSeparator: tss({
    marginRight: '6px',
    marginLeft: '1px',
  }),
  toggleText: tss({
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.5)',
    paddingLeft: '11px',
  }),
  switchWrapper: tss({
    marginRight: '14px',
  }),
};
