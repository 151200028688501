import { InfoCircleOutlined } from '@ant-design/icons';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { Col, InputNumber, Tooltip } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveFormikEnhancer, biggerLabelLayout, inputStyle } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { ConfigWarehouseModel, ShippingDateAdjustmentModel } from '@src/models';
import { commons } from '@src/styles';

const NumberField = withFormItem(InputNumber, biggerLabelLayout);

interface OwnProps {
  warehouse: ConfigWarehouseModel;
  setWarehouseSDA: (payload: { model: ShippingDateAdjustmentModel; warehouseId: string }) => void;
}

type FormValues = {
  SDAStart?: number;
  SDAEnd?: number;
};

export const WarehouseShippingDateAdjustment: React.FunctionComponent<OwnProps> = ({
  warehouse,
  setWarehouseSDA,
}) => {
  const [error, setError] = React.useState('');

  const handleSetWarehouseSDA = (values: FormValues) => {
    setWarehouseSDA({
      warehouseId: warehouse.id,
      model: {
        adjustStart: values.SDAStart,
        adjustEnd: values.SDAEnd,
        unit: 'full_day',
      },
    });
  };

  const validateSDA = (fields: FormValues) => {
    if ((fields.SDAStart || 0) > (fields.SDAEnd || 0)) {
      setError('Minimum must be less or equal than maximum');
      return false;
    }

    setError('');
    return true;
  };

  return (
    <Formik
      initialValues={{
        SDAStart: warehouse.shippingDateAdjustment && warehouse.shippingDateAdjustment.adjustStart,
        SDAEnd: warehouse.shippingDateAdjustment && warehouse.shippingDateAdjustment.adjustEnd,
      }}
      // tslint:disable-next-line: no-empty
      onSubmit={() => {}}
      enableReinitialize={true}
    >
      {(renderProps: FormikProps<FormValues>) => (
        <div className={commons.flex}>
          <Col span={6}>
            <div className={styles.label}>
              <FormattedMessage id="WAREHOUSE_BACKLOG_ADJUSTMENT" />
              <Tooltip title={<FormattedMessage id="WAREHOUSE_BACKLOG_ADJUSTMENT_TOOLTIP" />}>
                <InfoCircleOutlined className={styles.icon} />
              </Tooltip>
            </div>
          </Col>
          <Col span={18}>
            <div className={styles.SDAWrapper}>
              <AutoSaveFormikEnhancer
                name="SDAStart"
                onSave={values => {
                  if (validateSDA(values)) {
                    handleSetWarehouseSDA(values);
                  }
                }}
                render={({ onBlur, onKeyDown }) => (
                  <Field name="SDAStart">
                    {({ field }: FieldProps<FormValues['SDAStart']>) => (
                      <div className={commons.flex}>
                        <NumberField
                          {...field}
                          label={<FormattedMessage id="ADJUST_MIN_DATE" />}
                          className={error ? styles.errorInput : inputStyle}
                          value={renderProps.values.SDAStart || 0}
                          onBlur={onBlur}
                          onKeyDown={onKeyDown}
                          onChange={value => renderProps.setFieldValue('SDAStart', value)}
                        />
                        <span className={styles.dayLabel}>
                          <FormattedMessage
                            id="DAY"
                            modification="lowerCase"
                            values={{
                              multiple:
                                renderProps.values.SDAStart && renderProps.values.SDAStart > 1,
                            }}
                          />
                        </span>
                      </div>
                    )}
                  </Field>
                )}
              />
              <AutoSaveFormikEnhancer
                name="SDAEnd"
                onSave={values => {
                  if (validateSDA(values)) {
                    handleSetWarehouseSDA(values);
                  }
                }}
                render={({ onKeyDown, onBlur }) => (
                  <Field name="SDAEnd">
                    {({ field }: FieldProps<FormValues['SDAEnd']>) => (
                      <div className={commons.flex}>
                        <NumberField
                          {...field}
                          label={<FormattedMessage id="ADJUST_MAX_DATE" />}
                          className={error ? styles.errorInput : inputStyle}
                          value={renderProps.values.SDAEnd || 0}
                          onBlur={onBlur}
                          onKeyDown={onKeyDown}
                          onChange={value => renderProps.setFieldValue('SDAEnd', value)}
                        />
                        <span className={styles.dayLabel}>
                          <FormattedMessage
                            id="DAY"
                            modification="lowerCase"
                            values={{
                              multiple: renderProps.values.SDAEnd && renderProps.values.SDAEnd > 1,
                            }}
                          />
                        </span>
                      </div>
                    )}
                  </Field>
                )}
              />
              <p className={styles.errorMessage}>{error}</p>
            </div>
          </Col>
        </div>
      )}
    </Formik>
  );
};

const styles = {
  errorInput: tss({
    background: '#f5222d0d',
    border: '1px solid #f5222d',
    $nest: {
      '&:hover': {
        background: '#f5222d0d',
        border: '1px solid #f5222d',
      },
    },
  }),
  errorMessage: tss({
    color: '#f5222d',
    fontSize: '12px',
  }),
  deleteButton: tss({
    position: 'absolute',
    right: '10px',
    top: '4px',
  }),
  SDAWrapper: tss({
    paddingLeft: '11px',
    paddingBottom: '5px',
    $nest: {
      '.ant-form-item-label': {
        width: '150px !important',
      },
      '.ant-form-item-label > label': {
        color: 'rgba(0, 0, 0, 0.5) !important',
      },
    },
  }),
  dayLabel: tss({
    marginLeft: '5px',
    color: 'rgba(0, 0, 0, 0.5)',
    paddingTop: '4px',
  }),
  adjustButton: tss({
    paddingLeft: '11px',
    lineHeight: '22px',
  }),
  deleteAdjustmentsButton: tss({
    paddingLeft: 0,
  }),
  label: tss({
    color: 'rgba(0, 0, 0, 0.5)',
    paddingTop: '5px',
    display: 'flex',
  }),
  addButton: tss({
    display: 'inline-block',
  }),
  icon: tss({
    marginBottom: 'auto',
    paddingLeft: '2px',
  }),
};
