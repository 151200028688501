import React from 'react';
import { Form, Tabs } from 'antd';

interface OwnProps {
  name: string | string[];
  children: (name: string) => React.ReactNode;
  itemFactory?: () => any;
}

export const TabbedFormList: React.FC<OwnProps> = ({
  name,
  children,
  itemFactory = () => ({}),
}) => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => {
        return (
          <Tabs
            type="editable-card"
            onEdit={(targetKey, action) =>
              action === 'add'
                ? add(itemFactory())
                : action === 'remove'
                ? remove(Number(targetKey))
                : null
            }
          >
            {fields.map(field => {
              return (
                <Tabs.TabPane key={field.name} tab={String(field.key)}>
                  {children(String(field.name /* convert from number */))}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        );
      }}
    </Form.List>
  );
};
