import { useReducer } from 'react';

import { TrackingRecordFiltering } from './types';

const TRACKING_NUMBERS_FILTERS_STORAGE_KEY = 'trackingNumbersFilters';

type TrackingNumbersState = {
  pagination: {
    search_after?: string;
    search_before?: string;
    limit: number;
  };
  orderByDirection: 'ascend' | 'descend';
  filters: Omit<TrackingRecordFiltering, 'id'>;
  search: TrackingRecordFiltering['id'];
};

export type ActionType =
  | 'SET_PAGE'
  | 'SET_LIMIT'
  | 'TOGGLE_DIRECTION'
  | 'SET_FILTERING'
  | 'SET_SEARCH'
  | 'CLEAR_FILTERS';

type Action<T extends ActionType, P = undefined> = { type: T; payload: P };

export type TrackingNumberActions =
  | Action<'SET_PAGE', { search_after?: string; search_before?: string }>
  | Action<'SET_LIMIT', { limit: number }>
  | Action<'TOGGLE_DIRECTION', { search_after?: string; search_before?: string }>
  | Action<'SET_FILTERING', { filters: Omit<TrackingRecordFiltering, 'id'> }>
  | Action<'SET_SEARCH', { search: string | undefined }>
  | Action<'CLEAR_FILTERS', undefined>;

const initialState: TrackingNumbersState = {
  pagination: {
    limit: 20,
  },
  orderByDirection: 'descend',
  filters: {},
  search: undefined,
};

const setInitialTrackingNumbersFiltersState = () => {
  if (!sessionStorage) {
    return initialState;
  }

  if (TRACKING_NUMBERS_FILTERS_STORAGE_KEY in sessionStorage) {
    const filtersFromSessionStorage = sessionStorage.getItem(TRACKING_NUMBERS_FILTERS_STORAGE_KEY);

    if (filtersFromSessionStorage) {
      return { ...initialState, filters: JSON.parse(filtersFromSessionStorage) };
    } else return initialState;
  }

  return initialState;
};

const reducer = (
  state: TrackingNumbersState,
  action: TrackingNumberActions
): TrackingNumbersState => {
  switch (action.type) {
    case 'SET_PAGE': {
      return { ...state, pagination: { ...state.pagination, ...action.payload } };
    }
    case 'SET_LIMIT': {
      return { ...state, pagination: { ...state.pagination, limit: action.payload.limit } };
    }
    case 'TOGGLE_DIRECTION': {
      return {
        ...state,
        orderByDirection: state.orderByDirection === 'descend' ? 'ascend' : 'descend',
        pagination: {
          ...state.pagination,
          search_after: '',
          search_before: '',
        },
      };
    }
    case 'SET_FILTERING': {
      const { filters } = action.payload;
      sessionStorage.setItem(TRACKING_NUMBERS_FILTERS_STORAGE_KEY, JSON.stringify(filters));

      return {
        ...state,
        pagination: {
          ...state.pagination,
          search_after: '',
          search_before: '',
        },
        filters: filters,
      };
    }
    case 'SET_SEARCH': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
        },
        search: action.payload.search,
      };
    }
    case 'CLEAR_FILTERS': {
      sessionStorage.removeItem(TRACKING_NUMBERS_FILTERS_STORAGE_KEY);
      return {
        ...state,
        filters: {},
      };
    }
    default:
      return state;
  }
};

export const useTrackingNumberState = () => {
  return useReducer(reducer, setInitialTrackingNumbersFiltersState());
};
