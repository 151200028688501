import { TimePicker } from 'antd';
import { TimePickerProps } from 'antd/lib/time-picker/index';
import * as React from 'react';

const Component: React.StatelessComponent<TimePickerProps> = props => {
  const { ...restProps } = props;

  return <TimePicker {...restProps} />;
};

export default Component;
