import React from 'react';
import { stylesheet } from 'typestyle';
import { defaultTheme } from '@src/styles';
import { useFormatMessage } from '@src/i18n';
import { channelColumns } from '@src/modules/delivery-tracking-notifications/notifications-features/configuration/table-columns';
import { Table } from 'antd';
import { useGetChannelData } from '@src/modules/delivery-tracking-notifications/notifications-channels/utils/use-get-channel-data';

export const NotificationsChannels = () => {
  const formatMessage = useFormatMessage();
  const { channelsData, isDataLoading } = useGetChannelData();

  return (
    <>
      <h3 className={styles.sectionHeader}>{formatMessage('NOTIFICATIONS.CHANNELS')}</h3>
      <div className={styles.container}>
        <div className={styles.emailSection}>
          <h3 className={styles.sectionHeader}>{formatMessage('NOTIFICATIONS.CHANNELS.EMAIL')}</h3>
          <Table
            columns={channelColumns}
            dataSource={channelsData?.email}
            pagination={false}
            loading={isDataLoading}
          />
        </div>
        <div className={styles.emailSection}>
          <h3 className={styles.sectionHeader}>{formatMessage('NOTIFICATIONS.CHANNELS.SMS')}</h3>
          <Table
            columns={channelColumns}
            dataSource={channelsData?.sms}
            pagination={false}
            loading={isDataLoading}
          />
        </div>
      </div>
    </>
  );
};

const styles = stylesheet({
  container: {
    width: '100%',
    marginBottom: '2rem',
    padding: '40px 40px 46px 40px',
    background: defaultTheme.color.white,
  },
  emailSection: {
    marginBottom: '1.5rem',
  },
  sectionHeader: {
    borderBottom: '1px solid rgba(0,0,0,0.09)',
    marginBottom: '1rem',
    paddingBottom: '1rem',
  },
});
