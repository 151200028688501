import { FeatureDefinition } from './types';

/**
 * NOTE: commented out features are not yet implemented in FAQ widget
 * If you happen to update widget to support these features, simply remove the comment
 */

export const featureDefinitions: FeatureDefinition[] = [
  {
    name: 'enable_country_selector',
    title: 'Show country selector',
    type: 'checkbox',
  },
  { name: 'show_carrier_icons', title: 'Show carrier icons', type: 'checkbox' },
  {
    name: 'display_currency_iso_code',
    title: 'Display currency as ISO code',
    type: 'checkbox',
    description:
      'When enabled currency is presented as ISO code, e.g. SEK, EUR or USD. By default currency symbols are used, e.g. kr, € or $.',
  },
  { name: 'enable_price_zero_value', title: 'Display zero price as number', type: 'checkbox' },
  {
    name: 'show_delivery_times_as_estimates',
    title: 'Show delivery times as estimated',
    type: 'checkbox',
    description:
      'Adds information that the delivery time is an estimation, for example ‘4 business days’ will be shown as ‘4 business days (estimated)’',
  },
  { name: 'show_currency_before_price', title: 'Show currency before price', type: 'checkbox' },
  // {
  //   name: 'enable_accent_color_for_widget_elements',
  //   title: 'Enable accent color for widget elements',
  //   type: 'checkbox',
  // },
  { name: 'enable_widget_border', title: 'Enable widget border', type: 'checkbox' },
  // {
  //   name: 'accent_color_1',
  //   title: 'Accent color (HEX)',
  //   type: 'hex',
  //   description: 'Affects postal code submit button border and background',
  // },
  // {
  //   name: 'make_widget_transparent',
  //   title: 'Make widget background transparent',
  //   type: 'checkbox',
  // },
];
