import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { observable } from 'mobx';
import * as React from 'react';
// styles
import { style } from 'typestyle';
import { Spinner } from '.';
import { LABELS } from '../dictionaries';
import { defaultTheme } from '../styles';

const Option = Select.Option;

const classStyles = {
  root: style({
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    margin: '5px 0px 0px',
  }),
  selectWrap: style({
    flex: '1 205px',
  }),
  editButton: style({
    marginLeft: '8px',
    flex: 2,
  }),
  additionalActions: style({
    flex: '3 300px',
    marginLeft: '8px',
  }),
  optionLabelTitle: style({
    fontWeight: defaultTheme.font.bold,
  }),
  optionLabelDesc: style({
    fontSize: '12px',
  }),
  spinner: style({
    padding: '5px 12px',
  }),
  createNewButton: style({
    backgroundColor: '#F5F5F5',
    padding: '5px 12px',
    $nest: {
      '&:hover': {
        backgroundColor: '#F0F0F0',
      },
    },
  }),
  option: style({
    $nest: {
      '&.ant-select-item-option-active': {
        backgroundColor: '#F5F3F2',
      },
      '&.ant-select-item-option-selected': {
        backgroundColor: '#000',
      },
    },
  }),
};

interface IProps extends SelectProps<any> {
  autoFocus?: boolean;
  caseInsensitiveSearch?: boolean;
  additionalActions?: JSX.Element;
  options: { value: string; title: string; desc: string }[];
  onCreateNew: () => void;
  onEdit: () => void;
  isFetching?: boolean;
}

class Component extends React.Component<IProps, {}> {
  @observable isEditButtonVisible = false;

  caseInsensitiveFilter = (inputValue: string, option: any) => {
    return true;
  };

  render() {
    const {
      children,
      autoFocus,
      options,
      value,
      caseInsensitiveSearch,
      additionalActions,
      isFetching,
      ...restProps
    } = this.props;
    const nonEmptyValue = value || undefined;

    this.isEditButtonVisible = !!value;

    const CreateNewButton = (
      <div className={classStyles.createNewButton}>
        <a onClick={this.props.onCreateNew}>
          {`+ ${LABELS.CREATE} ${LABELS.NEW} ${LABELS.ADDRESS}`}
        </a>
      </div>
    );

    const CreateLoadingSpinner = isFetching ? (
      <div className={classStyles.spinner}>
        <Spinner size="small" />
      </div>
    ) : null;

    const renderOptions = options.map(item => (
      <Option className={classStyles.option} key={item.value} value={item.value} title={item.title}>
        <p className={classStyles.optionLabelTitle}>{item.title}</p>
        <p className={classStyles.optionLabelDesc}>{item.desc}</p>
      </Option>
    ));

    const overriddenProps: Pick<SelectProps<any>, 'filterOption'> = {};
    if (caseInsensitiveSearch) {
      const { filterOption } = this.props;
      overriddenProps.filterOption = filterOption ? filterOption : this.caseInsensitiveFilter;
    }

    const EditButton = this.isEditButtonVisible && !this.props.disabled && (
      <a onClick={this.props.onEdit} className={classStyles.editButton}>
        {LABELS.EDIT}
      </a>
    );

    return (
      <div className={classStyles.root}>
        <div className={classStyles.selectWrap}>
          <Select
            {...restProps}
            value={nonEmptyValue}
            optionLabelProp="title"
            dropdownMatchSelectWidth={true}
            dropdownRender={menu => (
              <div>
                {CreateNewButton}
                {CreateLoadingSpinner}
                {menu}
              </div>
            )}
            getPopupContainer={() =>
              document.getElementById('select-scroll-helper') || document.body
            }
            {...overriddenProps}
            notFoundContent={isFetching ? <Spinner size="small" /> : 'Not found'}
          >
            {options ? renderOptions : children}
          </Select>
        </div>
        {EditButton}
        <div className={classStyles.additionalActions}>
          {additionalActions && additionalActions}
        </div>
      </div>
    );
  }
}

export default Component;
