import * as React from 'react';
import { useSelector } from 'react-redux';
import { stylesheet } from 'typestyle';

import { configSelectors } from '@src/modules/config';

import { ShippingLogo } from '@src/components/shipping-logo';

type Props = {
  method: string;
};

export const ShippingMethodWithLogo: React.FunctionComponent<Props> = ({ method }) => {
  const availableShippingMethods = useSelector(
    configSelectors.getShippingMethodsDictByDraftOrCurrentSite
  );
  return (
    <div className={styles.wrapper}>
      <ShippingLogo className={styles.shippingLogo} shippingMethod={method} />
      <div className={styles.shippingMethod}>{availableShippingMethods[method]}</div>
      <div>{method}</div>
    </div>
  );
};

const styles = stylesheet({
  shippingMethod: {
    fontWeight: 'bold',
  },
  shippingLogo: {
    marginBottom: '10px',
  },
  wrapper: {
    width: '160px',
  },
});
