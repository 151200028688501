import { Spin } from 'antd';
import React, { useEffect } from 'react';

import { useMasterSiteId, useSelector } from '@src/utils/hooks';
import { useQuery, useQueryClient } from 'react-query';
import { apiUtils, authFetch } from '@src/utils';
import { GetDashboardResponse, ListEnabledDashboardsResponse } from '@src/api/dashboard';
import { getHasAccessTo } from '@src/modules/auth/auth-selectors';

export type DashboardType = 'checkout' | 'tracking' | 'transport_administration';

type DashboardProps = {
  type: DashboardType;
  fallback: React.ReactNode;
};

export const Dashboard = ({ type, fallback }: DashboardProps) => {
  const { data, isLoading, error, isStale, refetch } = useDashboardData(type);

  useEffect(() => {
    if (isStale) {
      refetch();
    }
  }, [isStale]);

  if (isLoading) {
    return <Spin />;
  }

  if (!data?.enabled || error) {
    return <>{fallback}</>;
  }

  return (
    <iframe
      id="dashboard"
      title="dashboard"
      style={{ height: '100%', border: 0 }}
      src={data?.iframe_url}
    />
  );
};

export const useDashboardData = (type: DashboardType) => {
  const mainSiteId = useMasterSiteId();
  const queryKey = ['dashboard', { mainSiteId, type }];
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: queryKey,
    onSuccess: response => {
      const staleTime =
        response.enabled && response?.expires_at
          ? new Date(response.expires_at).getTime() - new Date().getTime()
          : Infinity;
      queryClient.setQueryDefaults(queryKey, {
        staleTime,
      });
    },
    enabled: !!(mainSiteId && type),
    retry: 1,
    queryFn: () =>
      authFetch<GetDashboardResponse>(
        apiUtils.getApiUrl() + `/frontend/dashboard/dashboard/${mainSiteId}/get?type=${type}`
      ),
  });
};

export type EnabledDashboards = Record<DashboardType, boolean>;

export const useEnabledDashboards = (): EnabledDashboards => {
  const siteId = useMasterSiteId();
  const queryKey = ['enabled_dashboards', { siteId }];

  const hasAccessTo = useSelector(getHasAccessTo);

  // If someone wants to use the dashboard on a site, they need the right permissions.
  // There is already a backend validation on the BE so you can't generate dashboard url without mad:dashboard.
  const hasAccessToDashboard = hasAccessTo('mad:dashboard');

  const { data } = useQuery({
    queryKey: queryKey,
    queryFn: () =>
      authFetch<ListEnabledDashboardsResponse>(
        apiUtils.getApiUrl() + `/frontend/dashboard/enabled_dashboards/${siteId}`
      ),
    staleTime: Infinity,
    enabled: hasAccessToDashboard && !!siteId,

    // rerender dependant components only when the `data` changes
    // hook depends only on the `data` value, so there is no point to rerender for the other fields
    // NOTE: test carefully when changing as it can cause infinite render loop in case of request error.
    notifyOnChangeProps: ['data'],
  });

  const enabledFlags: Record<DashboardType, boolean> = {
    checkout: false,
    tracking: false,
    transport_administration: false,
  };

  if (!hasAccessToDashboard) {
    return enabledFlags;
  }

  const canAccessDashboardFlags: Record<DashboardType, boolean> = {
    tracking: hasAccessTo('tracking-feature:view') && hasAccessTo('mad:tracking_numbers'),
    checkout: true,
    transport_administration: true,
  };

  for (const dashboard of data?.dashboards ?? []) {
    enabledFlags[dashboard as DashboardType] = canAccessDashboardFlags[dashboard as DashboardType];
  }

  return enabledFlags;
};
