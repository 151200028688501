import { SelectOption } from '@src/controls/select';
import { ConfigRegionModel } from '@src/models';
import { getRegionsFromAllCountries } from '@src/services/dictionaries-service';
import { FieldArrayRenderProps } from 'formik';
import { PriceRuleConditionFormValue, PriceRuleType } from '../helpers';

const uniqueConditionTypes = [
  PriceRuleType.REGION,
  PriceRuleType.CATEGORY_REF,
  PriceRuleType.COUNTRY,
];

export const getValidSelectOptions = (
  selectOptions: SelectOption[],
  conditions: PriceRuleConditionFormValue[],
  region?: ConfigRegionModel
) => {
  // some conditions that have == equality check would brick entire filter rule
  // if merchant would set up two of them with different values, thats why we ensure
  // there is only one condition of that type in the price rule
  const duplicateFreeSelectOptions = selectOptions.filter(
    option =>
      !(
        uniqueConditionTypes.includes(option.value as PriceRuleType) &&
        conditions.find(condition => condition.type === option.value)
      )
  );

  const filteredOptions = duplicateFreeSelectOptions.filter(option =>
    priceRuleHasDependenciesMet(option.value as PriceRuleType, region)
  );

  return filteredOptions;
};

const isRegionSupportingRegions = (region?: ConfigRegionModel) => {
  const countries = Object.keys(getRegionsFromAllCountries());
  return region?.regionCountryConfig?.included?.some(country => countries.includes(country));
};

export const priceRuleHasDependenciesMet = (
  priceRule: PriceRuleType,
  region?: ConfigRegionModel
) => {
  switch (priceRule) {
    case PriceRuleType.REGION:
      return isRegionSupportingRegions(region);
    default:
      return true;
  }
};

// in case when there is multiple countries this tells if that particular region
// has its country in the region
const isRegionFromTheCountriesInRegion = (conditionRegion: string, region?: ConfigRegionModel) => {
  const allRegions = getRegionsFromAllCountries();

  return region?.regionCountryConfig?.included?.find((country: string) =>
    allRegions[country]?.find(({ value }) => value === conditionRegion)
  );
};

export const conditionHasDependenciesMet = (
  condition: PriceRuleConditionFormValue,
  region?: ConfigRegionModel
) => {
  switch (condition.type) {
    case PriceRuleType.REGION:
      if (condition.value === '') {
        return true;
      }

      const regionSupportsRegions = isRegionFromTheCountriesInRegion(condition.value, region);

      return !!regionSupportsRegions;
    default:
      return true;
  }
};

export const removeConditionAndItsDependants = (
  priceRule: PriceRuleType,
  conditions: PriceRuleConditionFormValue[],
  arrayHelpers: FieldArrayRenderProps
) => {
  const conditionIndex = conditions.findIndex(condition => condition.type === priceRule);

  if (conditionIndex >= 0) {
    arrayHelpers.remove(conditionIndex);
  }
};
