import * as qs from 'query-string';

import {
  CreateGroupDTO,
  CreateGroupModel,
  RBACGroupDTO,
  RBACGroupModel,
  RoleModel,
  UpdateGroupDTO,
  UpdateGroupModel,
  UserCreateDTO,
  UserCreateModel,
  UserGetDTO,
  UserGetModel,
  UserSummaryDTO,
  UserSummaryModel,
} from '../models';
import { apiUtils, authFetch } from '../utils';

const AUTH_URL = apiUtils.getApiUrl() + '/frontend/auth';

export async function listUsers(merchantId: string, siteId: string): Promise<UserGetModel[]> {
  const options: RequestInit = {
    method: 'GET',
    headers: { 'x-merchant-id': merchantId, 'x-site-id': siteId },
  };

  return authFetch<{ users?: UserGetDTO[] }>(`${AUTH_URL}/merchant/users.list`, options).then(res =>
    res.users ? res.users.map(user => UserGetModel.createFromDTO(user)) : []
  );
}

export async function createUser(
  data: UserCreateModel,
  merchantId: string,
  siteId: string
): Promise<UserGetModel> {
  const payload = UserCreateDTO.createFromModel(data);
  const options: RequestInit = {
    method: 'POST',
    headers: { 'x-merchant-id': merchantId, 'x-site-id': siteId },
    body: JSON.stringify(payload),
  };
  return authFetch<{
    user: UserGetDTO;
  }>(`${AUTH_URL}/merchant/users.create`, options).then(res =>
    UserGetModel.createFromDTO(res.user)
  );
}

export function deleteUser(id: string, merchantId: string, siteId: string): Promise<{}> {
  const options: RequestInit = {
    method: 'POST',
    headers: { 'x-merchant-id': merchantId, 'x-site-id': siteId },
    body: JSON.stringify({ id }),
  };
  return authFetch<{}>(`${AUTH_URL}/merchant/users.delete`, options).then(res => res);
}

export function updateUser(
  data: UserCreateModel,
  merchantId: string,
  siteId: string
): Promise<UserGetModel> {
  const payload = UserCreateDTO.createFromModel(data);
  const options: RequestInit = {
    method: 'POST',
    headers: { 'x-merchant-id': merchantId, 'x-site-id': siteId },
    body: JSON.stringify(payload),
  };
  return authFetch<{ user: UserGetDTO }>(`${AUTH_URL}/merchant/users.update`, options).then(res =>
    UserGetModel.createFromDTO(res.user)
  );
}

export function getWithToken(token: string | null): Promise<UserGetModel> {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify({ token }),
  };
  return authFetch<{
    user: UserGetDTO;
  }>(`${AUTH_URL}/users.get_with_token`, options).then(res => UserGetModel.createFromDTO(res.user));
}

export async function getUserWith(
  userId: string,
  merchantId: string,
  siteId: string
): Promise<UserGetModel> {
  const options: RequestInit = {
    method: 'GET',
    headers: { 'x-merchant-id': merchantId, 'x-site-id': siteId },
  };

  return authFetch<{ user: UserGetDTO }>(
    `${AUTH_URL}/admin/users.get?${qs.stringify({ id: userId })}`,
    options
  ).then(res => UserGetModel.createFromDTO(res.user));
}

export async function getGroups(merchantId: string, siteId: string): Promise<RBACGroupModel[]> {
  return authFetch<{ auth_group: RBACGroupDTO[] }>(
    `${AUTH_URL}/admin/groups.list?${qs.stringify({ merchant_id: merchantId })}`,
    { headers: { 'x-site-id': siteId } }
  ).then(res =>
    res.auth_group ? res.auth_group.map(group => RBACGroupModel.createFromDTO(group)) : []
  );
}

export async function assignUserToGroup(
  userId: string,
  groupId: string,
  siteId: string,
  merchantId: string
): Promise<{}> {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
      'x-merchant-id': merchantId,
    },
    body: JSON.stringify({ user_id: userId, group_id: groupId }),
  };

  return authFetch<{}>(`${AUTH_URL}/admin/users.assign_group`, options);
}

export async function removeGroupsFromUser(
  userId: string,
  groupId: string,
  siteId: string,
  merchantId: string
): Promise<{}> {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
      'x-merchant-id': merchantId,
    },
    body: JSON.stringify({ user_id: userId, group_id: groupId }),
  };

  return authFetch<{}>(`${AUTH_URL}/admin/users.remove_group`, options);
}

export async function listRoles(): Promise<{ roles: RoleModel[] }> {
  return authFetch<{ roles: RoleModel[] }>(`${AUTH_URL}/admin/roles.list`);
}

export async function createGroup(model: CreateGroupModel): Promise<{ groupId: string }> {
  const payload = CreateGroupDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify(payload),
  };

  return authFetch<{ group_id: string }>(`${AUTH_URL}/admin/groups.create`, options).then(res => ({
    groupId: res.group_id,
  }));
}

export async function deleteGroup(groupId: string) {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify({ id: groupId }),
  };

  return authFetch<{}>(`${AUTH_URL}/admin/groups.delete`, options);
}

export async function updateGroup(model: UpdateGroupModel): Promise<{}> {
  const payload = UpdateGroupDTO.createFromModel(model);

  return authFetch<{}>(`${AUTH_URL}/admin/groups.update`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
}

export async function listUserSummaries(userIds: string[]): Promise<UserSummaryModel[]> {
  const url = `${AUTH_URL}/admin/user_summaries.list?${qs.stringify({ ids: userIds })}`;

  return authFetch<{ summaries: UserSummaryDTO[] }>(url).then(res =>
    res.summaries.map(UserSummaryModel.createFromDTO)
  );
}

export async function listMerchantUserSummaries(
  userIds: string[],
  siteId: string,
  merchantId: string
): Promise<UserSummaryModel[]> {
  const url = `${AUTH_URL}/merchant/user_summaries.list?${qs.stringify({ ids: userIds })}`;

  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
      'x-merchant-id': merchantId,
    },
  };

  return authFetch<{ summaries: UserSummaryDTO[] }>(url, options).then(res =>
    res.summaries.map(UserSummaryModel.createFromDTO)
  );
}
