import { ShipmentNotificationModel } from '@src/models';
import * as moment from 'moment';
import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { shipmentNotificationsActions } from '.';
import { Reducer } from '..';

export type NotificationsState = Readonly<{
  items: ShipmentNotificationModel[];
  error: null | string;
  isFetching: boolean;
}>;

const items: Reducer<NotificationsState['items']> = (state = [], action) => {
  switch (action.type) {
    case getType(shipmentNotificationsActions.listNotificationsSuccess):
      return action.payload;
    case getType(shipmentNotificationsActions.markNotificationsAsReadRequest):
      return state.map(notification =>
        action.payload.ids.includes(notification.id)
          ? { ...notification, readAt: moment().format() }
          : notification
      );
    case getType(shipmentNotificationsActions.markNotificationsAsReadError):
      return state.map(notification =>
        action.payload.ids.includes(notification.id)
          ? { ...notification, readAt: '' }
          : notification
      );
    default:
      return state;
  }
};

const error: Reducer<NotificationsState['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(shipmentNotificationsActions.listNotificationsRequest):
    case getType(shipmentNotificationsActions.listNotificationsSuccess):
    case getType(shipmentNotificationsActions.createTriggerRequest):
    case getType(shipmentNotificationsActions.createTriggerSuccess):
    case getType(shipmentNotificationsActions.markNotificationsAsReadRequest):
      return null;

    case getType(shipmentNotificationsActions.listNotificationsError):
    case getType(shipmentNotificationsActions.createTriggerError):
      return action.payload;
    case getType(shipmentNotificationsActions.markNotificationsAsReadError):
      return action.payload.error;

    default:
      return state;
  }
};

const isFetching: Reducer<NotificationsState['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(shipmentNotificationsActions.listNotificationsRequest):
      return true;
    case getType(shipmentNotificationsActions.listNotificationsError):
    case getType(shipmentNotificationsActions.listNotificationsSuccess):
      return false;
    default:
      return state;
  }
};

export const notificationsReducer: Reducer<NotificationsState> = combineReducers({
  items,
  error,
  isFetching,
});
