import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;

const STORE_FILE_REQUEST = '@blobs/STORE_FILE_REQUEST';
const STORE_FILE_SUCCESS = '@blobs/STORE_FILE_SUCCESS';
const STORE_FILE_ERROR = '@blobs/STORE_FILE_ERROR';

const GET_TOKEN_REQUEST = '@blobs/GET_TOKEN_REQUEST';
const GET_TOKEN_SUCCESS = '@blobs/GET_TOKEN_SUCCESS';
const GET_TOKEN_ERROR = '@blobs/GET_TOKEN_ERROR';

const RESET_ATTACHMENT = '@blobs/RESET_ATTACHMENT';

export const storeFileRequest = createAction(
  STORE_FILE_REQUEST,
  resolve => (payload: { siteId: string; file: File }) => resolve(payload)
);
export const storeFileSuccess = createAction(
  STORE_FILE_SUCCESS,
  resolve => (id: string) => resolve(id)
);
export const storeFileError = createAction(
  STORE_FILE_ERROR,
  resolve => (error: string) => resolve(error)
);

export const getTokenRequest = createAction(
  GET_TOKEN_REQUEST,
  resolve => (payload: { siteId: string; url: string }) => resolve(payload)
);
export const getTokenSuccess = createAction(
  GET_TOKEN_SUCCESS,
  resolve => (token: string) => resolve(token)
);
export const getTokenError = createAction(
  GET_TOKEN_ERROR,
  resolve => (error: string) => resolve(error)
);

export const resetAttachment = createAction(RESET_ATTACHMENT);
