import { GlobalOutlined } from '@ant-design/icons';
import * as React from 'react';
import { useSelector } from '@src/utils/hooks';
import { stylesheet } from 'typestyle';

import { ListFixedHeader } from '@src/components';
import { Link } from '@src/components/link';
import { DOMAIN } from '@src/dictionaries';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { configSelectors } from '@src/modules/config';
import { FormattedMessage } from '@src/i18n';

import { CountryList } from '@src/containers/features/components';
import { UpsellWidgetDrawer } from '@src/components/widget-drawers/upsell-widget-drawer';

export const UpsellFeatures: React.FC = () => {
  const countries = useSelector(configSelectors.getCountryCount);
  const isFetching = useSelector(state => state.sites.isFetching);
  return (
    <>
      <StatusBarContainer />
      <ListFixedHeader
        title={DOMAIN.FEATURES}
        IconComponent={GlobalOutlined}
        isLoading={false}
        showSearch={false}
      />
      <Link
        showIcon
        baseStyles
        className={styles.defaultSettingsLink}
        route={{ name: 'UPSELL_DEFAULT_SETTINGS' }}
      >
        <FormattedMessage id="DEFAULT_SETTINGS" />
      </Link>
      <FormattedMessage className={styles.countriesSettingsHeader} id="COUNTRY_SETTINGS" />
      <CountryList
        isFetching={isFetching}
        countries={countries}
        renderLink={({ countryName }) => (
          <Link
            showIcon
            baseStyles
            route={{ name: 'UPSELL_COUNTRY_DETAILS', country: countryName.toLowerCase() }}
          >
            {countryName}
          </Link>
        )}
      />
      <UpsellWidgetDrawer />
    </>
  );
};

const styles = stylesheet({
  componentWrapper: {
    padding: '24px',
  },
  defaultSettingsLink: {
    borderBottom: '1px solid rgba(0,0,0,0.09)',
    padding: '22px',
    display: 'block',
  },
  countriesSettingsHeader: {
    margin: '36px 0 14px',
    display: 'block',
    color: 'rgba(0,0,0,0.85)',
  },
});
