import {
  SmartDataType,
  SmartNotifications,
} from '@src/modules/delivery-tracking-notifications/smart-notifications-settings/smart-notifications.types';

export const useGetSmartNotificationsTableData = (smartNotifications?: SmartNotifications) => {
  if (!smartNotifications) {
    return [];
  }
  const smartNotificationsArrayData = Object.entries(smartNotifications)
    .filter(([_, data]) => typeof data === 'object')
    .map(([eventKey, data]) => {
      return {
        eventKey,
        ...data,
      };
    });

  const smartTableData: SmartDataType[] | undefined = smartNotificationsArrayData?.map(
    (smartNotification, index) => {
      const mailStatus = smartNotification.channel_settings.find(
        channel => channel.channel_type === 'NOTIFICATION_CHANNEL_TYPE_EMAIL'
      );
      const smsStatus = smartNotification.channel_settings.find(
        channel => channel.channel_type === 'NOTIFICATION_CHANNEL_TYPE_SMS'
      );
      return {
        key: `${smartNotification.display_name}-${index}`,
        name: smartNotification.display_name,
        emailStatus: mailStatus?.enabled,
        smsStatus: smsStatus?.enabled,
        eventKey: smartNotification.eventKey,
        threshold: smartNotification.threshold,
      };
    }
  );

  return smartTableData;
};
