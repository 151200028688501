import { FormState, ValidatableMapOrArray } from 'formstate';
import * as moment from 'moment';

export default {
  required: { required: true, message: 'This field is required' },
  email: { type: 'email', message: 'Should be valid e-mail' },
  token: { type: 'token', message: 'Should be valid token' },
  dateRange: { type: 'array', message: 'Should be valid date range' },
  url: { type: 'url', message: 'Should be valid url' },
  domain: {
    type: 'enum',
    message: 'Only valid clients domains are supported (in format: "example.com")',
    enum: ['apoteket.se'],
  },
};

export const requiredSelectFieldWithMessage = (message: string) => (value: string | string[]) =>
  typeof value !== 'string' && message;

export const requiredMultipleSelectWithMessage = (message: string) => (value: string[]) =>
  !value ? message : undefined;

export const requiredFieldWithMessage =
  (message: string) => (value: string | string[] | undefined) => {
    return (
      (value === undefined || (typeof value === 'string' && !value.trim()) || !value.length) &&
      message
    );
  };

export const requiredDatePickerWithMessage =
  (message: string) => (value: moment.Moment | undefined) => {
    return !value && message;
  };

export const maxLength = (length: number) => (value: string) =>
  value.length > length && `max length should not be higher than ${length}`;

export const minLength = (length: number) => (value: string) => {
  if (value.length < length && value.length !== 0) {
    return `min length should not be lower than ${length}`;
  }
  return false;
};

const alphanumericalPattern = /^(([a-zA-Z0-9]+)-)+[a-zA-Z0-9]+$/;
export const alphanumericalWithDash = () => (value: string) =>
  value &&
  !alphanumericalPattern.test(value) &&
  'only this pattern [xxx]-[xxx] with alphanumerical characters is allowed';

export const validJSONFormatWithMessage = (message: string) => (value: string) => {
  try {
    JSON.parse(value);
  } catch (e) {
    return message;
  }
  return undefined;
};

export type FormStateContainer<T extends ValidatableMapOrArray = any> = {
  [key: string]: FormState<T>;
};

export const isValidFormState = async (formState: FormState<any>): Promise<boolean> => {
  const res = await formState.validate();
  return !res.hasError;
};

export const isValidFormStateList = async (formStateList: FormState<any>[]): Promise<boolean> => {
  const validatedForms = await Promise.all(
    formStateList.map(async formState => {
      return await isValidFormState(formState);
    })
  );

  return !validatedForms.some(isValid => !isValid);
};

export const isValidFormStateContainer = async (
  formStatesContainer: FormStateContainer<any>
): Promise<boolean> => {
  const formStates = [...Object.values(formStatesContainer)].filter(form => form !== undefined);
  return await isValidFormStateList(formStates);
};

export const getPostalCodeValidationByCountry = (countryCode: string) => {
  /* Validation taken from backend.*/
  switch (countryCode) {
    case 'SE':
      return RegExp(/^\d{3}[ ]?\d{2}$/);

    case 'NO':
      return RegExp(/^\d{4}$/);

    case 'FI':
      return RegExp(/^\d{5}$/);

    case 'NO':
      return RegExp(/^\d{4}$/);

    default:
      return RegExp('');
  }
};
