import { CustomsDeclaration, ShipmentCustoms } from '@src/containers/shipments/components/customs-declaration/types';
import { Direction } from '@src/containers/tracking-numbers/types';
import { v4 } from 'uuid';
import {
  AddressDTO,
  AddressModel,
  DeliveryStatus,
  DimensionsDTO,
  DimensionsModel,
  UserTagModel,
} from './';
import { DeliveryTypeEnum } from './merchants-models';

/**
 * Describes an additional service provided by shipping method.
 */
export class AddonModel {
  /**
   * Internal name of an addon.
   */
  name: string;
  /**
   * External code, as defined in carrier's system.
   */
  code: string;
  /**
   * External description, as defined in carrier's system.
   */
  description: string;
  /**
   * Describes shipping methods mapped to the addon.
   */
  shipping_methods?: string[] = [];
}

/**
 * Contents contains all goods sent in a Shipment or Parcel. Goods can either be defined directly by providing the full details of the goods, it can be referenced by transport order id or an external reference to goods defined by an external system.
 */
export class ContentsModel {
  /**
   * Goods a.k.a. Line items.
   */
  goods: LineItemModel[] = [];
  /**
   * Transport orders contained in this shipment.
   */
  transportOrders: TransportOrderContentItemModel[] = [];
  /**
   * External orders contained in this shipment.
   */
  externalOrders: ExternalOrderContentItemModel[] = [];

  static createFromDTO(model: ContentsDTO): ContentsModel {
    return {
      goods: model.goods && model.goods.map(item => LineItemModel.createFromDTO(item)),
      transportOrders:
        model.transport_orders &&
        model.transport_orders.map(item => TransportOrderContentItemModel.createFromDTO(item)),
      externalOrders:
        model.external_orders &&
        model.external_orders.map(item => ExternalOrderContentItemModel.createFromDTO(item)),
    };
  }
}

export class ContentsDTO {
  /**
   * Goods a.k.a. Line items.
   */
  goods: LineItemDTO[] = [];
  /**
   * Transport orders contained in this shipment.
   */
  transport_orders: TransportOrderContentItemDTO[] = [];
  /**
   * External orders contained in this shipment.
   */
  external_orders: ExternalOrderContentItemDTO[] = [];

  static createFromModel(model: ContentsModel): ContentsDTO {
    return {
      goods: (model.goods && model.goods.map(item => LineItemDTO.createFromModel(item))) || [],
      transport_orders:
        (model.transportOrders &&
          model.transportOrders.map(item => TransportOrderContentItemDTO.createFromModel(item))) ||
        [],
      external_orders:
        (model.externalOrders &&
          model.externalOrders.map(item => ExternalOrderContentItemDTO.createFromModel(item))) ||
        [],
    };
  }
}

export class CustomerInfoModel {
  /**
   * Customer's home address.
   */
  address: AddressModel = new AddressModel();
  /**
   * Customer's mobile phone number.
   */
  phone?: string = '';
  /**
   * Customer's email.
   */
  email: string = '';

  static createFromDTO(model: CustomerInfoDTO): CustomerInfoModel {
    return {
      address: (model.address && AddressModel.createFromDTO(model.address)) || new AddressModel(),
      phone: model.phone,
      email: model.email,
    };
  }
}

export class CustomerInfoDTO {
  address: AddressDTO;
  phone?: string;
  email: string;

  static createFromModel(model: CustomerInfoModel): CustomerInfoDTO {
    return {
      address: AddressDTO.createFromModel(model.address),
      phone: model.phone,
      email: model.email,
    };
  }
}

export class DeliveryModel {
  /**
   * Unique id of the delivery (uuid).
   */
  id: string;
  /**
   * ID of the parcel which the delivery belongs to.
   */
  parcelId: string;
  /**
   * Tracking number for the delivery.
   */
  trackingNumber: string;
  /**
   * Consolidated tracking number for all deliveries in a shipment.
   */
  shipmentTrackingNumber: string;
  /**
   * URL of the delivery's shipping label.
   */
  labelUrl: string;
  /**
   * Origin address for this delivery.
   */
  addressFrom: AddressModel;
  /**
   * Destination address for this delivery.
   */
  addressTo: AddressModel;
  /**
   * Shipping product ID for the delivery.
   */
  shippingMethod: string;
  /**
   * ID of the service point location if applicable.
   */
  locationRef: string;
  /**
   * Transit time [deprecated].
   */
  transitTime: TransitTimeModel;
  /**
   * State of the delivery. Possible values are 'created','booked','pickup'.
   */
  deliveryStatus: DeliveryStatus;
  /**
   * The return address to which a delivery that is returned should be sent.
   */
  addressReturn: AddressModel;
  /**
   * Weight of the delivery in grams.
   */
  weight: number;
  /**
   * Optional instructions to the courier.
   */
  courierInstructions?: string;
  /**
   * Tracking number for the return delivery.
   */
  returnTrackingNumber: string;
  /**
   * URL of the delivery's return label.
   */
  returnLabelUrl: string;

  deliveryTime?: {
    start: string;
    end: string;
  };

  static createFromDTO(model: DeliveryDTO): DeliveryModel {
    return {
      id: model.id,
      parcelId: model.parcel_id,
      trackingNumber: model.tracking_number,
      shipmentTrackingNumber: model.shipment_tracking_number,
      labelUrl: model.label_url,
      addressFrom:
        (model.address_from && AddressModel.createFromDTO(model.address_from)) ||
        new AddressModel(),
      addressTo:
        (model.address_return && AddressModel.createFromDTO(model.address_to)) ||
        new AddressModel(),
      addressReturn:
        (model.address_return && AddressModel.createFromDTO(model.address_return)) ||
        new AddressModel(),
      shippingMethod: model.shipping_method,
      locationRef: model.location_ref,
      transitTime: model.transit_time,
      deliveryStatus: model.delivery_status ? model.delivery_status : 'UNKNOWN',
      weight: model.weight,
      courierInstructions: model.courier_instructions,
      returnTrackingNumber: model.return_tracking_number,
      returnLabelUrl: model.return_label_url,
      deliveryTime: model.delivery_time,
    };
  }
}

export class DeliveryDTO {
  id: string;
  parcel_id: string;
  tracking_number: string;
  shipment_tracking_number: string;
  label_url: string;
  address_from: AddressDTO;
  address_to: AddressDTO;
  shipping_method: string;
  location_ref: string;
  transit_time: TransitTimeDTO;
  delivery_status: DeliveryStatus;
  address_return: AddressDTO;
  weight: number;
  courier_instructions?: string;
  return_tracking_number: string;
  return_label_url: string;
  delivery_time?: {
    start: string;
    end: string;
  };

  static createFromModel(model: DeliveryModel): DeliveryDTO {
    return {
      id: model.id,
      parcel_id: model.parcelId,
      tracking_number: model.trackingNumber,
      shipment_tracking_number: model.shipmentTrackingNumber,
      label_url: model.labelUrl,
      address_from: AddressDTO.createFromModel(model.addressFrom),
      address_to: AddressDTO.createFromModel(model.addressTo),
      shipping_method: model.shippingMethod,
      location_ref: model.locationRef,
      transit_time: model.transitTime,
      delivery_status: model.deliveryStatus,
      address_return: AddressDTO.createFromModel(model.addressReturn),
      weight: model.weight,
      courier_instructions: model.courierInstructions,
      return_tracking_number: model.returnTrackingNumber,
      return_label_url: model.returnLabelUrl,
      delivery_time: model.deliveryTime,
    };
  }
}

/**
 * ExternalOrderContentItem is a reference to goods defined by an external system.
 */
export class ExternalOrderContentItemModel {
  /**
   * A reference to an external order.
   */
  externalId: string;

  static createFromDTO(model: ExternalOrderContentItemDTO): ExternalOrderContentItemModel {
    return {
      externalId: model.external_id,
    };
  }
}

export class ExternalOrderContentItemDTO {
  /**
   * A reference to an external order.
   */
  external_id: string;

  static createFromModel(model: ExternalOrderContentItemModel): ExternalOrderContentItemDTO {
    return {
      external_id: model.externalId,
    };
  }
}

/**
 * Physical item of the parcel. A parcel can contain many line items. The parameters on the line item are used by the shipping rules framework to make better shipping decisions.
 */
export class LineItemModel {
  /**
   * Unique product identifier.
   */
  sku: string = v4();
  /**
   * Product name or title.
   */
  name: string = '';
  /**
   * Description of the product.
   */
  description: string = '';
  /**
   * Number of items.
   */
  quantity: number = 0;
  /**
   * Dimensions of the line item.
   */
  dimensions: DimensionsModel = new DimensionsModel();
  /**
   * Price of the line item.
   */
  price: number = 0;
  /**
   * Currency of the line item.
   */
  currency: string = '';
  /**
   * List of tags or attributes that can be attached to this line item.
   */
  tags: string[] = [];
  /**
   * Weight of the line item in grams.
   */
  weight: number = 0;
  hsTariffNumber?: string;

  static createFromDTO(model: LineItemDTO): LineItemModel {
    return {
      sku: model.sku,
      name: model.name,
      description: model.description,
      quantity: model.quantity || 0,
      dimensions:
        (model.dimensions && DimensionsModel.createFromDTO(model.dimensions)) ||
        new DimensionsModel(),
      price: model.price || 0,
      currency: model.currency,
      tags: model.tags,
      weight: (model.weight && model.weight) || 0,
      hsTariffNumber: model.hs_tariff_number,
    };
  }
}

export class LineItemDTO {
  sku: string;
  name: string;
  description: string;
  quantity: number;
  dimensions: DimensionsDTO;
  price: number;
  currency: string;
  tags: string[];
  weight: number;
  hs_tariff_number?: string;

  static createFromModel(model: LineItemModel): LineItemDTO {
    return {
      sku: model.sku,
      name: model.name,
      description: model.description,
      quantity: model.quantity,
      dimensions: DimensionsDTO.createFromModel(model.dimensions),
      price: model.price,
      currency: model.currency,
      tags: model.tags,
      weight: model.weight,
      hs_tariff_number: model.hsTariffNumber,
    };
  }
}

/**
 * Parcel is the physical package. One parcel can have at least one delivery at a time.
 */
export class ParcelModel {
  /**
   * Unique ID of the parcel (uuid).
   */
  id: string = '';
  /**
   * ID of the shipment that parcel belongs to (uuid).
   */
  shipmentId: string = '';
  /**
   * All line items that are part of the parcel.
   */
  lineItems: LineItemModel[] = [];
  /**
   * Deliveries associated with the parcel.
   */
  deliveries: DeliveryModel[] = [];
  /**
   * Timestamp when parcel was created.
   */
  createdAt: string = '';
  /**
   * Timestamp when parcel was last updated.
   */
  updatedAt: string = '';
  /**
   * Length, height and width [optional].
   */
  dimensions: DimensionsModel = new DimensionsModel();
  /**
   * Index number of the individual parcel in a shipment, i.e parcel 3 of 5 [deprecated].
   */
  parcelNumber: number = 0;
  /**
   * Not used [deprecated].
   */
  parcelTotal: number = 0;
  /**
   * Total weight of the parcel in grams.
   */
  weight: string = '0';
  /**
   * Content of parcel.
   */
  contents: ContentsModel = new ContentsModel();

  static createFromDTO(model: ParcelDTO): ParcelModel {
    return {
      id: model.id,
      shipmentId: model.shipment_id,
      lineItems:
        (model.line_items && model.line_items.map(item => LineItemModel.createFromDTO(item))) || [],
      deliveries:
        (model.deliveries && model.deliveries.map(item => DeliveryModel.createFromDTO(item))) || [],
      createdAt: model.created_at,
      updatedAt: model.updated_at,
      dimensions:
        (model.dimensions && DimensionsModel.createFromDTO(model.dimensions)) ||
        new DimensionsModel(),
      parcelNumber: model.parcel_number,
      parcelTotal: model.parcel_total,
      weight: model.weight,
      contents:
        (model.contents && ContentsModel.createFromDTO(model.contents)) || new ContentsModel(),
    };
  }
}

export class ParcelDTO {
  /**
   * Unique ID of the parcel (uuid).
   */
  id: string = '';
  /**
   * ID of the shipment that parcel belongs to (uuid).
   */
  shipment_id: string = '';
  /**
   * All line items that are part of the parcel.
   */
  line_items: LineItemDTO[];
  /**
   * Deliveries associated with the parcel.
   */
  deliveries: DeliveryDTO[] = [];
  /**
   * Timestamp when parcel was created.
   */
  created_at: string = '';
  /**
   * Timestamp when parcel was last updated.
   */
  updated_at: string = '';
  /**
   * Length, height and width [optional].
   */
  dimensions: DimensionsDTO;
  /**
   * Index number of the individual parcel in a shipment, i.e parcel 3 of 5 [deprecated].
   */
  parcel_number: number = 0;
  /**
   * Not used [deprecated].
   */
  parcel_total: number = 0;
  /**
   * Total weight of the parcel in grams.
   */
  weight: string = '0';
  /**
   * Content of parcel.
   */
  contents: ContentsDTO;

  static createFromModel(model: ParcelModel): ParcelDTO {
    return {
      id: model.id,
      shipment_id: model.shipmentId,
      line_items: model.lineItems.map(item => LineItemDTO.createFromModel(item)),
      deliveries: model.deliveries.map(item => DeliveryDTO.createFromModel(item)),
      created_at: model.createdAt,
      updated_at: model.updatedAt,
      dimensions: DimensionsDTO.createFromModel(model.dimensions),
      parcel_number: model.parcelNumber,
      parcel_total: model.parcelTotal,
      weight: model.weight,
      contents: ContentsDTO.createFromModel(model.contents),
    };
  }
}

export class ShipmentDTO {
  id: string;
  site_id: string;
  customer_info: CustomerInfoDTO;
  meta: { [key: string]: string };
  shipping_date?: string;
  address_from: AddressDTO;
  address_to: AddressDTO;
  address_return: AddressDTO;
  line_items?: LineItemDTO[];
  parcels: ParcelDTO[];
  external_id: string;
  shipment_value: string | undefined;
  with_return: boolean;
  tos_id: string;
  contents: ContentsDTO;
  addons: AddonModel[];
  created_at: string;
  updated_at: string;
  direction_type?: Direction;
  customs?: ShipmentCustoms;

  static createFromModel(model: ShipmentModel): ShipmentDTO {
    return {
      id: model.id || v4(),
      site_id: model.siteId,
      customer_info:
        (model.customerInfo && CustomerInfoDTO.createFromModel(model.customerInfo)) ||
        new CustomerInfoModel(),
      meta: model.meta,
      shipping_date: model.shippingDate,
      created_at: model.createdAt,
      updated_at: model.updatedAt,
      address_from:
        (model.addressFrom && AddressDTO.createFromModel(model.addressFrom)) || new AddressModel(),
      address_to:
        (model.addressTo && AddressDTO.createFromModel(model.addressTo)) || new AddressModel(),
      address_return:
        (model.addressReturn && AddressDTO.createFromModel(model.addressReturn)) ||
        new AddressModel(),
      line_items:
        (model.lineItems && model.lineItems.map(item => LineItemDTO.createFromModel(item))) || [],
      parcels: (model.parcels && model.parcels.map(item => ParcelDTO.createFromModel(item))) || [],
      external_id: model.externalId,
      shipment_value: model.shipmentValue,
      with_return: model.withReturn,
      tos_id: model.tosId,
      contents:
        (model.contents && ContentsDTO.createFromModel(model.contents)) || new ContentsDTO(),
      addons: model.addons,
      direction_type: model.directionType,
    };
  }
}

export class ShipmentCreateDTO {
  shipping_method: string;
  dimensions: DimensionsDTO | undefined;
  weight: number | undefined;
  address_from: AddressDTO;
  address_to: AddressDTO;
  address_return?: AddressDTO;
  line_items?: LineItemDTO[];
  customer_info: CustomerInfoDTO;
  shipping_date?: string;
  shipment_value: string | undefined;
  number_of_parcels: number | undefined;
  meta: { [key: string]: string };
  location_ref?: string;
  external_id: string;
  delivery_time?: {
    start: string;
    end: string;
  };
  courier_instructions: string | undefined;
  with_return: boolean;
  contents: ContentsDTO;
  tos_id?: string;
  addons: AddonModel[];
  parcel_free_text?: string;
  shipping_category_ref?: string;
  address_from_location_ref?: string;
  direction_type?: 'OUTBOUND' | 'RETURN' | 'UNSPECIFIED';
  outbound_shipment_id?: string;
  customs_declaration?: CustomsDeclaration;

  static createFromModel(model: ShipmentCreateModel): ShipmentCreateDTO {
    return {
      customer_info:
        (model.customerInfo && CustomerInfoDTO.createFromModel(model.customerInfo)) ||
        new CustomerInfoModel(),
      meta: model.meta,
      shipping_date: model.shippingDate,
      shipping_method: model.shippingMethod,
      address_from:
        (model.addressFrom && AddressDTO.createFromModel(model.addressFrom)) || new AddressModel(),
      address_to:
        (model.addressTo && AddressDTO.createFromModel(model.addressTo)) || new AddressModel(),
      address_return: model.addressReturn && AddressDTO.createFromModel(model.addressReturn),
      external_id: model.externalId,
      shipment_value: model.shipmentValue,
      with_return: model.withReturn,
      tos_id: model.tosId,
      contents:
        (model.contents && ContentsDTO.createFromModel(model.contents)) || new ContentsDTO(),
      addons: model.addons,
      number_of_parcels: model.numberOfParcels,
      dimensions:
        (model.dimensions && DimensionsDTO.createFromModel(model.dimensions)) ||
        new DimensionsModel(),
      weight: model.weight,
      courier_instructions: model.courierInstructions,
      ...(model.deliveryTime
        ? { delivery_time: { start: model.deliveryTime.start, end: model.deliveryTime.end } }
        : {}),
      location_ref: model.locationRef,
      parcel_free_text: model.parcelFreeText,
      direction_type: model.directionType,
      outbound_shipment_id: model.outboundShipmentId,
      customs_declaration: model.customsDeclaration,
    };
  }
}

export class ShipmentEditDTO {
  shipment_id: string;
  shipping_method: string;
  weight: number | undefined;
  address_from: AddressDTO;
  address_to: AddressDTO;
  address_return?: AddressDTO;
  customer_info: CustomerInfoDTO;
  shipping_date?: string;
  shipment_value: string | undefined;
  meta: { [key: string]: string };
  location_ref?: string;
  external_id: string;
  delivery_time?: {
    start: string;
    end: string;
  };
  courier_instructions: string | undefined;
  with_return: boolean;
  addons: AddonModel[];
  parcel_free_text?: string;

  static createFromModel(model: ShipmentEditModel): ShipmentEditDTO {
    return {
      shipment_id: model.shipmentId,
      shipping_method: model.shippingMethod,
      weight: model.weight,
      address_from: model.addressFrom && AddressDTO.createFromModel(model.addressFrom),
      address_to: model.addressTo && AddressDTO.createFromModel(model.addressTo),
      address_return: model.addressReturn && AddressDTO.createFromModel(model.addressReturn),
      customer_info: model.customerInfo && CustomerInfoDTO.createFromModel(model.customerInfo),
      shipping_date: model.shippingDate,
      shipment_value: model.shipmentValue,
      meta: model.meta,
      location_ref: model.locationRef,
      external_id: model.externalId,
      delivery_time: model.deliveryTime,
      courier_instructions: model.courierInstructions,
      with_return: model.withReturn,
      addons: model.addons,
      parcel_free_text: model.parcelFreeText,
    };
  }
}

export class ShipmentEditModel {
  shipmentId: string;
  shippingMethod: string;
  weight: number | undefined;
  addressFrom: AddressModel;
  addressTo: AddressModel;
  addressReturn?: AddressModel;
  customerInfo: CustomerInfoModel;
  shippingDate?: string;
  shipmentValue: string | undefined;
  meta: { [key: string]: string };
  locationRef?: string;
  externalId: string;
  deliveryTime?: {
    start: string;
    end: string;
  };
  courierInstructions: string | undefined;
  withReturn: boolean;
  addons: AddonModel[];
  parcelFreeText?: string;
}

export class ShipmentCreateModel {
  shippingMethod: string;
  dimensions?: DimensionsModel;
  weight: number | undefined;
  addressFrom: AddressModel;
  addressTo: AddressModel;
  addressReturn?: AddressModel;
  customerInfo: CustomerInfoModel;
  shippingDate?: string;
  shipmentValue: string | undefined;
  numberOfParcels?: number;
  meta: { [key: string]: string };
  locationRef?: string;
  externalId: string;
  deliveryTime?: {
    start: string;
    end: string;
  };
  courierInstructions: string | undefined;
  withReturn: boolean;
  contents?: ContentsModel;
  tosId?: string;
  addons: AddonModel[];
  parcelFreeText?: string;
  shippingCategoryRef?: string;
  addressFromLocationRef?: string;
  directionType?: Direction;
  outboundShipmentId?: string;
  customsDeclaration?: CustomsDeclaration;

  static createFromDTO(dto: ShipmentCreateDTO): ShipmentCreateModel {
    const validatedLineItems = dto.contents.goods
      ? dto.contents.goods.map(item => LineItemModel.createFromDTO(item))
      : [];
    return {
      customerInfo:
        (dto.customer_info && CustomerInfoModel.createFromDTO(dto.customer_info)) ||
        new CustomerInfoModel(),
      meta: dto.meta,
      shippingDate: dto.shipping_date,
      shippingMethod: dto.shipping_method,
      addressFrom:
        (dto.address_from && AddressModel.createFromDTO(dto.address_from)) || new AddressModel(),
      addressTo:
        (dto.address_to && AddressModel.createFromDTO(dto.address_to)) || new AddressModel(),
      addressReturn: dto.address_return && AddressModel.createFromDTO(dto.address_return),
      externalId: dto.external_id,
      shipmentValue: dto.shipment_value,
      withReturn: dto.with_return,
      tosId: dto.tos_id,
      contents: dto.contents && ContentsModel.createFromDTO(dto.contents),
      addons: dto.addons,
      numberOfParcels: dto.number_of_parcels,
      dimensions:
        (dto.dimensions && DimensionsModel.createFromDTO(dto.dimensions)) ||
        getLineItem(validatedLineItems).dimensions,
      weight: dto.weight || getLineItem(validatedLineItems).weight,
      courierInstructions: dto.courier_instructions,
      deliveryTime: dto.delivery_time && {
        start: dto.delivery_time.start,
        end: dto.delivery_time.end,
      },
      locationRef: dto.location_ref,
      parcelFreeText: dto.parcel_free_text,
      directionType: dto.direction_type,
      outboundShipmentId: dto.outbound_shipment_id,
      customsDeclaration: dto.customs_declaration,
    };
  }
}
/**
 * Shipment is the top level object in SOM. A shipment can be composed of multiple parcels and each parcel can contain many deliveries. Only one delivery can be active at a time.
 */
export class ShipmentModel {
  /**
   * ID of the shipment (uuid).
   */
  id: string = v4();
  /**
   * Site ID to which the shipment belongs to [internal].
   */
  siteId: string = '';
  /**
   * Contains the customer information such as name, email, phone and address [required].
   */
  customerInfo: CustomerInfoModel = new CustomerInfoModel();
  /**
   * Generic key-value object that can be used to attach additional information to the shipment.
   */
  meta: { [key: string]: string } = {};
  /**
   * The date that the shipment will most likely be dispatched from the warehouse [required].
   */
  shippingDate?: string;
  /**
   * Address where the shipment will be dispatched from. Most often the address of the warehouse [required].
   */
  addressFrom: AddressModel = new AddressModel();
  /**
   * Destination address. For example address of the service point or the click and collect store [required].
   */
  addressTo: AddressModel = new AddressModel();
  /**
   * Address where deliveries of this shipment should be returned to.
   */
  addressReturn: AddressModel = new AddressModel();
  /**
   * Line items associated with this shipment.
   */
  lineItems: LineItemModel[] = [];
  /**
   * Parcels associated with this shipment.
   */
  parcels: ParcelModel[] = [];
  /**
   * Can be used to store a unique identifier from the merchant. For example external order ID or external shipment ID.
   */
  externalId: string = '';
  /**
   * Total value of the shipment including tax. Example 10000 is 100 SEK.
   */
  shipmentValue?: string;
  /**
   * Indicates whether a return shipment should be created.
   */
  withReturn: boolean = false;
  /**
   * ID of a corresponding order.
   */
  tosId: string = '';
  /**
   * Contents of shipment.
   */
  contents: ContentsModel = new ContentsModel();
  /**
   * Addons available for the shipment.
   */
  addons: AddonModel[] = [];
  /**
   * Timestamp when shipment was created.
   */
  createdAt: string = '';
  /**
   * Timestamp when shipment was last updated.
   */
  updatedAt: string = '';

  directionType?: Direction;

  customs?: ShipmentCustoms;

  static createFromDTO(dto: ShipmentDTO): ShipmentModel {
    const validatedLineItems = dto.line_items
      ? dto.line_items.map(item => LineItemModel.createFromDTO(item))
      : [];
    return {
      id: dto.id,
      siteId: dto.site_id,
      customerInfo:
        (dto.customer_info && CustomerInfoModel.createFromDTO(dto.customer_info)) ||
        new CustomerInfoModel(),
      meta: dto.meta,
      shippingDate: dto.shipping_date,
      createdAt: dto.created_at,
      updatedAt: dto.updated_at,
      addressFrom:
        (dto.address_from && AddressModel.createFromDTO(dto.address_from)) || new AddressModel(),
      addressTo:
        (dto.address_to && AddressModel.createFromDTO(dto.address_to)) || new AddressModel(),
      addressReturn:
        (dto.address_return && AddressModel.createFromDTO(dto.address_return)) ||
        new AddressModel(),
      lineItems: validatedLineItems,
      parcels: (dto.parcels && dto.parcels.map(item => ParcelModel.createFromDTO(item))) || [],
      externalId: dto.external_id,
      shipmentValue: dto.shipment_value,
      withReturn: dto.with_return,
      tosId: dto.tos_id,
      contents: dto.contents && ContentsModel.createFromDTO(dto.contents),
      addons: dto.addons,
      directionType: dto.direction_type,
      customs: dto.customs,
    };
  }
}

const getLineItem = (lineItems: LineItemModel[]) => {
  if (lineItems.length > 0) {
    return LineItemModel.createFromDTO(lineItems[0]);
  }
  return new LineItemModel();
};

export class TransitTimeModel {
  start: string;
  end: string;
}

export class TransitTimeDTO {
  start: string;
  end: string;
}

/**
 * TransportOrderContentItem is a reference to goods defined by a transport order.
 */
export class TransportOrderContentItemModel {
  /**
   * The transport order id.
   */
  tosId: string;
  /**
   * An additional reference which can be used freely by the client.
   */
  externalRef: string;

  static createFromDTO(model: TransportOrderContentItemDTO): TransportOrderContentItemModel {
    return {
      tosId: model.tos_id,
      externalRef: model.external_ref,
    };
  }
}

export class TransportOrderContentItemDTO {
  /**
   * The transport order id.
   */
  tos_id: string;
  /**
   * An additional reference which can be used freely by the client.
   */
  external_ref: string;

  static createFromModel(model: TransportOrderContentItemModel): TransportOrderContentItemDTO {
    return {
      tos_id: model.tosId,
      external_ref: model.externalRef,
    };
  }
}

export class ShipmentListFiltersModel {
  shippingMethods?: string[];
  statuses?: DeliveryStatus[];
  createdAtRange?: { start: string; end: string };
  shippingDateRange?: { start: string; end: string };
  shipmentIds?: string[];
  id?: string;
  addressToCountries?: string[];
  addressFromCountries?: string[];
  deliveryTypes?: DeliveryTypeEnum[];
  directionTypes?: Direction[];
  failedBulkActionsType?: 'booking' | 'printing';
}

export class ShipmentsListFiltersDTO {
  shipping_methods?: string[];
  shipping_date_range?: { start: string; end: string };
  created_at_range?: { start: string; end: string };
  statuses?: DeliveryStatus[];
  shipment_ids?: string[];
  id?: string;
  address_to_countries?: string[];

  address_from_countries?: string[];
  delivery_types?: DeliveryTypeEnum[];
  direction_types?: Direction[];

  static createFromModel(model: ShipmentListFiltersModel): ShipmentsListFiltersDTO {
    return {
      shipping_methods: model.shippingMethods || [],
      shipping_date_range: model.shippingDateRange
        ? model.shippingDateRange.start && model.shippingDateRange.end
          ? model.shippingDateRange
          : undefined
        : undefined,
      created_at_range: model.createdAtRange
        ? model.createdAtRange.start && model.createdAtRange.end
          ? model.createdAtRange
          : undefined
        : undefined,
      statuses: model.statuses || [],
      shipment_ids: model.shipmentIds || [],
      id: model.id,
      address_to_countries: model.addressToCountries || [],
      address_from_countries: model.addressFromCountries || [],
      delivery_types: model.deliveryTypes || [],
      direction_types: model.directionTypes || [],
    };
  }
}

export class TemplateDTO {
  template: {
    id: string;
    data: {
      name?: string;
      dimensions?: DimensionsDTO;
      weight?: number;
      number_of_parcels?: number;
      customer_info?: CustomerInfoDTO;
      address_from?: AddressDTO;
      address_to?: AddressDTO;
      address_return?: AddressDTO;
      contents?: ContentsDTO;
      shipment_value?: string;
      shipping_method?: string;
      meta?: { [key: string]: string };
      courier_instructions?: string;
      with_return?: boolean;
      addons?: AddonModel[];
    };
  };

  static createFromModel(model: TemplateModel): TemplateDTO {
    return {
      template: {
        id: model.id,
        data: {
          name: model.name,
          dimensions: model.dimensions
            ? DimensionsDTO.createFromModel(model.dimensions)
            : new DimensionsModel(),
          weight: model.weight,
          number_of_parcels: model.numberOfParcels,
          customer_info: CustomerInfoDTO.createFromModel(model.customerInfo),
          address_from: AddressDTO.createFromModel(model.addressFrom),
          address_to: AddressDTO.createFromModel(model.addressTo),
          address_return: AddressDTO.createFromModel(model.addressReturn),
          contents: ContentsDTO.createFromModel(model.contents),
          shipment_value: model.shipmentValue,
          shipping_method: model.shippingMethod,
          meta: model.meta,
          courier_instructions: model.courierInstructions,
          with_return: model.withReturn,
          addons: model.addons,
        },
      },
    };
  }
}

export class TemplateModel {
  id: string = v4();
  name: string = '';
  dimensions: DimensionsModel = new DimensionsModel();
  weight: number = 0;
  numberOfParcels: number = 0;
  customerInfo: CustomerInfoModel = new CustomerInfoModel();
  addressFrom: AddressModel = new AddressModel();
  addressTo: AddressModel = new AddressModel();
  addressReturn: AddressModel = new AddressModel();
  contents: ContentsModel = new ContentsModel();
  shipmentValue: string | undefined;
  shippingMethod: string = '';
  meta: { [key: string]: string } = {};
  courierInstructions?: string = '';
  withReturn: boolean = false;
  addons: AddonModel[] = [];

  static createFromDTO(wrapper: TemplateDTO): TemplateModel {
    const model = wrapper.template.data || {};
    return {
      id: wrapper.template.id || v4(),
      name: model.name || '',
      dimensions: model.dimensions
        ? DimensionsModel.createFromDTO(model.dimensions)
        : new DimensionsModel(),
      weight: model.weight || 0,
      numberOfParcels: model.number_of_parcels || 0,
      customerInfo: model.customer_info
        ? CustomerInfoModel.createFromDTO(model.customer_info)
        : new CustomerInfoModel(),
      addressFrom: model.address_from
        ? AddressModel.createFromDTO(model.address_from)
        : new AddressModel(),
      addressTo: model.address_to
        ? AddressModel.createFromDTO(model.address_to)
        : new AddressModel(),
      addressReturn: model.address_return
        ? AddressModel.createFromDTO(model.address_return)
        : new AddressModel(),
      contents: model.contents ? ContentsModel.createFromDTO(model.contents) : new ContentsModel(),
      shipmentValue: model.shipment_value,
      shippingMethod: model.shipping_method || '',
      meta: model.meta || {},
      courierInstructions: model.courier_instructions || '',
      withReturn: model.with_return || false,
      addons: model.addons || [],
    };
  }
}

export class TemplateModelResponse extends TemplateModel {
  tags: UserTagModel[];

  static createFromDTO(dto: TemplateDTOResponse): TemplateModelResponse {
    return {
      ...super.createFromDTO(dto),
      tags: dto.tags || [],
    };
  }
}

export class TemplateDTOResponse extends TemplateDTO {
  tags: UserTagModel[];

  static createFromModel(model: TemplateModelResponse): TemplateDTORequest {
    return { ...super.createFromModel(model), tags: model.tags.map(tag => tag.id) };
  }
}

export class TemplateDTORequest extends TemplateDTO {
  tags: string[];

  static createFromModel(model: TemplateModelRequest): TemplateDTORequest {
    return { ...super.createFromModel(model), tags: model.tags || [] };
  }
}

export class TemplateModelRequest extends TemplateModel {
  tags: string[];

  static createFromDTO(model: TemplateDTOResponse): TemplateModelResponse {
    return { ...super.createFromDTO(model), tags: model.tags || [] };
  }
}

export class EditableNotesDTO {
  shipment_id: string;
  notes: string;
  static createFromModel(model: EditableNotesModel): EditableNotesDTO {
    return {
      shipment_id: model.shipmentId,
      notes: model.notes,
    };
  }
}

export class EditableNotesModel {
  shipmentId: string;
  notes: string;
}
