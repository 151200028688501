import { Modal, Spinner } from '@src/controls';
import { AutoSaveForm } from '@src/forms';
import { configSelectors } from '@src/modules/config';
import { useSelector } from '@src/utils/hooks';
import { Formik } from 'formik';
import * as React from 'react';
import { DefaultTrackingLinks } from '../components/default-tracking-links';
import {
  buildCarrierProductMappingsPayload,
  buildFormValues,
  buildValidationSchema,
  CarrierProductMappingForm,
  modalFormStyles,
} from '../form-commons';
import {
  AlwaysShowTrackingLinksFieldAutoSave,
  CarrierProductFieldAutoSave,
  CarrierProductRefFieldAutoSave,
  UseCustomMappingFieldAutoSave,
} from '../form-components/autosave-fields';
import { TrackingConfigStatusFieldConnected } from '../form-components/connected-fields';
import { useUpdateMappingMutation } from './mutations';
import { ListCarrierProductMappingResponse } from '@src/models';
import { TrackingLinksTable } from '@src/containers/product-mappings/components/tracking-links-table';
import { useTrackingLinksToggleDisability } from '@src/containers/product-mappings/utils/control-tracking-links-toggle';

type Props = {
  isVisible: boolean;
  carrierProductMappingId: string;
  onCancel: () => void;
  siteId: string;
  data?: ListCarrierProductMappingResponse;
};

export const CarrierProductMappingsUpdate: React.FC<Props> = ({
  isVisible,
  carrierProductMappingId,
  onCancel,
  data,
}) => {
  const updateMappingMutation = useUpdateMappingMutation();

  const mapping = (data?.carrier_product_mappings || []).find(
    mapping => mapping.id === carrierProductMappingId
  );

  const { disabled } = useTrackingLinksToggleDisability(
    updateMappingMutation.isLoading,
    mapping?.tracking_links
  );

  const handleSave = (values: CarrierProductMappingForm) => {
    if (!mapping) {
      return; // this will actually never happen
    }

    if (values.carrier_product_id !== mapping.carrier_product_id) {
      values = { ...values, always_show_tracking_links: false };
    }
    updateMappingMutation.mutate(buildCarrierProductMappingsPayload(values, mapping));
  };

  const isCreatingDraft = useSelector(configSelectors.getIsCreatingDraft);

  return (
    <Modal visible={isVisible} footer={null} onCancel={onCancel} closable={false}>
      {(isCreatingDraft || !mapping) && <Spinner />}
      {mapping && (
        <>
          <span className={modalFormStyles.modalHeader}>{mapping.carrier_product_ref}</span>
          <Formik<CarrierProductMappingForm>
            initialValues={buildFormValues(mapping)}
            enableReinitialize
            validationSchema={buildValidationSchema(data, mapping)}
            onSubmit={_ => {}}
          >
            {formik => (
              <AutoSaveForm>
                <CarrierProductRefFieldAutoSave handleSave={handleSave} />
                <UseCustomMappingFieldAutoSave handleSave={handleSave} />
                {formik.values.custom ? (
                  <TrackingLinksTable handleSave={handleSave} />
                ) : (
                  <>
                    <CarrierProductFieldAutoSave handleSave={handleSave} />
                    <TrackingConfigStatusFieldConnected isLoading={isCreatingDraft} />
                    {formik.values.carrier_product_id && (
                      <DefaultTrackingLinks
                        carrierProductId={formik.values.carrier_product_id}
                        initialTrackingLinks={mapping.tracking_links}
                      />
                    )}
                  </>
                )}
                <AlwaysShowTrackingLinksFieldAutoSave handleSave={handleSave} disabled={disabled} />
              </AutoSaveForm>
            )}
          </Formik>
        </>
      )}
    </Modal>
  );
};
