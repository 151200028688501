import { InputProps } from 'antd/lib/input/Input';
import * as React from 'react';

export interface IProps extends InputProps {
  editable: boolean;
  readOnlyRenderer: JSX.Element;
  editableRenderer?: JSX.Element;
}

export const TableEditableCell: React.SFC<IProps> = props => {
  const { editable, editableRenderer, readOnlyRenderer } = props;

  return editable ? editableRenderer || null : readOnlyRenderer;
};
