import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { services } from '@src/services';
import { stylesheet } from 'typestyle';

type Props = {
  textToCopy: string;
  notificationText: string;
};

export const CopyRow: FC<Props> = ({ textToCopy, notificationText, children }) => (
  <div className={styles.row}>
    {children}
    <CopyIcon textToCopy={textToCopy} notificationText={notificationText} />
  </div>
);

const CopyIcon: FC<Props> = ({ textToCopy, notificationText }) => (
  <CopyToClipboard
    text={textToCopy}
    onCopy={() => services.messageService.success(notificationText)}
  >
    <CopyOutlined className={styles.icon} onClick={(e: React.MouseEvent) => e.stopPropagation()} />
  </CopyToClipboard>
);

const styles = stylesheet({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    fontSize: '16px',
  },
});
