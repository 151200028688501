import { sanitizeUrl } from '@braintree/sanitize-url';
import { useFormatMessage } from '@src/i18n';
import { getLabelToken } from '@src/services/gentoken-service';
import { bulkPrintLabels } from '@src/services/som-search-service';
import { bulkBookShipments } from '@src/services/som-service';
import { useMasterSiteId } from '@src/utils/hooks';
import { message, notification } from 'antd';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { additionalActionsContext } from './context';

export const useBulkBookingMutation = () => {
  const siteId = useMasterSiteId();
  const context = useContext(additionalActionsContext);
  const formatMessage = useFormatMessage();

  return useMutation({
    mutationKey: ['shipments.book_parcels_bulk', siteId],
    mutationFn: (shipmentIds: string[]) => bulkBookShipments(siteId, shipmentIds),
    onSuccess: ({ failed_shipment_ids }, shipmentIds) => {
      if (failed_shipment_ids) {
        context?.handleFiltersApply({
          shipmentIds: failed_shipment_ids,
          failedBulkActionsType: 'booking',
        });

        notification.warning({
          placement: 'topRight',
          message: formatMessage('UNABLE_BOOK_SHIPMENTS_MESSAGE', {
            failedShipmentIds: failed_shipment_ids.length,
            shipmentIds: shipmentIds.length,
          }),
          description: formatMessage('UNABLE_BOOK_SHIPMENTS_DESCRIPTION'),
        });

        return;
      }

      message.success(formatMessage('BULK_BOOKING_SUCCESS_MESSAGE'));
      context?.clearFilters();
    },
    useErrorBoundary: true,
  });
};

export const useBulkPrintingMutation = () => {
  const siteId = useMasterSiteId();
  const context = useContext(additionalActionsContext);
  const formatMessage = useFormatMessage();

  return useMutation({
    mutationKey: ['shipments.get_labels', siteId],
    mutationFn: async (shipmentIds: string[]) => {
      const { labels_urls, not_included_shipments_ids } = await bulkPrintLabels(
        siteId,
        shipmentIds
      );

      if (labels_urls) {
        const data = await Promise.all(labels_urls.map(url => getLabelToken(siteId, url, '.pdf')));

        const labelUrls = data.map(({ url }) => sanitizeUrl(url));

        return { labelUrls, notIncludedShipmentIds: not_included_shipments_ids };
      }

      return { labelUrls: labels_urls, notIncludedShipmentIds: not_included_shipments_ids };
    },
    onSuccess: ({ labelUrls, notIncludedShipmentIds }, shipmentIds) => {
      if (labelUrls) {
        labelUrls.map(url => window.open(url));
      }

      if (notIncludedShipmentIds) {
        context?.handleFiltersApply({
          shipmentIds: notIncludedShipmentIds,
          failedBulkActionsType: 'printing',
        });

        notification.warning({
          duration: 15,
          placement: 'topRight',
          message: formatMessage('UNABLE_PRINT_LABELS_MESSAGE', {
            notIncludedShipmentIds: notIncludedShipmentIds.length,
            shipmentIds: shipmentIds.length,
          }),
          description: formatMessage('UNABLE_PRINT_LABELS_DESCRIPTION'),
        });
        return;
      }

      message.success(formatMessage('BULK_PRINTING_SUCCESS_MESSAGE'));
    },
    useErrorBoundary: true,
  });
};
