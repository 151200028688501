import { isNil } from 'ramda';
import { createSelector } from 'reselect';

import { RootState } from '../root-reducer';

const getTokenAuthState = (state: RootState) => state.tokenAuth;

export const getToken = createSelector(getTokenAuthState, state =>
  isNil(state.token) ? null : state.token.token
);
export const isFetching = createSelector(getTokenAuthState, state => state.isFetching);
