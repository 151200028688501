import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { useSiteId } from '@src/utils/hooks';
import { FormattedMessage } from '@src/i18n';
import { services } from '@src/services';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { useMutationOnDraft } from '@src/utils/queries';
import { FeatureFlags as FaqWidgetFeatures } from '@src/api/faq-widget';

import { BreadcrumbSection, Header, FeaturesTable } from '@src/containers/faq-widget/components';
import { FaqWidgetDrawer } from '@src/containers/faq-widget/faq-widget-drawer';

export const FaqWidgetDefaultSettings: React.FC = () => {
  const siteId = useSiteId();
  const queryClient = useQueryClient();

  const faqWidgetFeatures = useQuery(
    ['site', siteId],
    () => services.configService.getSite(siteId),
    {
      enabled: !!siteId,
      staleTime: Infinity,
      select: data => data?.faqWidgetConfiguration?.features,
      keepPreviousData: true,
    }
  );

  const onSubmitDraft = useMutationOnDraft<FaqWidgetFeatures, unknown>(
    (siteId, payload) =>
      services.selfCareService.updateFaqWidgetConfiguration(siteId, { features: payload }),
    {
      onSuccess: () => queryClient.invalidateQueries(['site']),
    }
  );

  return (
    <>
      <StatusBarContainer />
      <BreadcrumbSection>
        <FormattedMessage id="DEFAULT_SETTINGS" />
      </BreadcrumbSection>
      <Header>
        <FormattedMessage id="DEFAULT_SETTINGS" />
      </Header>
      <FeaturesTable
        key={faqWidgetFeatures.dataUpdatedAt}
        onSubmit={onSubmitDraft.mutate}
        loading={faqWidgetFeatures.isFetching}
        values={faqWidgetFeatures.data}
      />
      <FaqWidgetDrawer />
    </>
  );
};
