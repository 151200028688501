import { PaginationProps } from 'antd/lib/pagination';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { Link } from '@src/components/link';
import { Button, Spinner, Table } from '@src/controls';
import { LABELS, MESSAGES } from '@src/dictionaries';
import { ContactModel } from '@src/models';

type Props = {
  isLoading: boolean;
  total: number;
  items: ContactModel[];
  currentPage: number;
  selectedRowKeys: string[];
  setSelectedRowKeys: (rowKeys: string[]) => void;
  onPaginationChange: (nextPage: number, pageSize: number | undefined) => void;
  onDelete: (items: string[], onOkCallback: (args: string[]) => void) => void;
};

export const AddressBookContactList: React.FunctionComponent<Props> = ({
  items,
  isLoading,
  onDelete,
  currentPage,
  total,
  selectedRowKeys,
  setSelectedRowKeys,
  onPaginationChange,
}) => {
  const [pageSize, setPageSize] = React.useState(10);

  const paginationConfig: PaginationProps = {
    defaultCurrent: currentPage,
    current: currentPage,
    pageSize,
    total,
    size: 'small',
    showQuickJumper: true,
    showSizeChanger: true,
    showTotal: (count, range) => `${range[0]}-${range[1]} of ${count} items`,
    onChange: (changedPageNumber, currentPageSize) => {
      onPaginationChange(changedPageNumber, currentPageSize);
      setPageSize(currentPageSize ?? 10);
    },
    onShowSizeChange: (currentPageNumber, changedPageSize) => {
      onPaginationChange(currentPageNumber, changedPageSize);
      setPageSize(changedPageSize);
    },
  };

  const emptyMessage = isLoading ? (
    <Spinner delay={0} active={isLoading} />
  ) : (
    MESSAGES.EMPTY_ADDRESS_BOOK
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys: string[]) => setSelectedRowKeys(selectedKeys),
    hideDefaultSelections: true,
    selections: [
      {
        key: 'all-data',
        text: 'Select All Data',
        onSelect: () => setSelectedRowKeys(items.map(item => item.id)),
        onDisSelect: () => {
          setSelectedRowKeys([]);
        },
      },
      {
        key: 'deselect-all',
        text: 'Deselect All Data',
        onSelect: () => setSelectedRowKeys([]),
      },
      {
        key: 'current',
        text: 'Select Current Page',
        onSelect: (selectedKeys: string[]) => setSelectedRowKeys(selectedKeys),
      },
    ],
  };

  return (
    <section className={styles.section}>
      <div className={styles.wrapper}>
        <Button
          type="primary"
          danger
          onClick={() => onDelete(selectedRowKeys, setSelectedRowKeys)}
          disabled={!(selectedRowKeys.length > 0)}
        >
          Delete
        </Button>
        <span style={{ marginLeft: 8 }}>
          {selectedRowKeys.length > 0
            ? `Selected ${selectedRowKeys.length} of ${items.length}` +
              (items.length === 1 ? ` ${LABELS.ITEM}` : ` ${LABELS.ITEMS}`)
            : ''}
        </span>
      </div>

      <Table<ContactModel>
        loading={isLoading}
        dataSource={items}
        rowKey={record => record.id}
        pagination={paginationConfig}
        emptyMessage={emptyMessage}
        rowSelection={rowSelection}
        hideTitle={true}
      >
        <Table.Column<ContactModel>
          title="Name"
          key="name"
          width="180px"
          render={record => (
            <Link
              baseStyles
              showIcon
              route={{ name: 'ADDRESS_BOOK_EDIT_CONTACT', contactId: record.id }}
            >
              {record.address.name}
            </Link>
          )}
        />
        <Table.Column<ContactModel>
          title="Email"
          key="email"
          width="180px"
          render={record => record.email}
        />
        <Table.Column<ContactModel>
          title="Address Line"
          key="address-line"
          width="180px"
          render={record =>
            record.address && record.address.addressLines && record.address.addressLines[0]
          }
        />
        <Table.Column<ContactModel>
          title="Postal Code"
          key="postal-code"
          width="180px"
          render={record => record.address.postalCode}
        />
        <Table.Column<ContactModel>
          title="City"
          key="city"
          width="180px"
          render={record => record.address.city}
        />
        <Table.Column<ContactModel>
          title="Country"
          key="county"
          width="180px"
          render={record => record.address.country}
        />
        <Table.Column<ContactModel>
          title="Phone"
          key="phone"
          width="180px"
          render={record => record.phone}
        />
        <Table.Column<ContactModel>
          title="External ID"
          key="externalId"
          width="180px"
          render={record => record.additionalFields.id}
        />
      </Table>
    </section>
  );
};

const styles = stylesheet({
  section: { position: 'relative' },
  wrapper: { paddingTop: '1em', paddingBottom: '1em' },
});
