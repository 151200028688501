import * as React from 'react';

import * as css from 'csstips';
import * as tss from 'typestyle';

interface ColumnProps {
  justifyContent: tss.types.CSSProperties['justifyContent'];
  alignItems?: tss.types.CSSProperties['alignItems'];
  style?: React.CSSProperties;
}

const Column: React.StatelessComponent<ColumnProps> = props => {
  const { children, justifyContent, alignItems, style } = props;

  return (
    <div className={styles.row} style={{ justifyContent, alignItems, ...style } as any}>
      {children}
    </div>
  );
};

export default Column;

const styles = {
  row: tss.style(css.fillParent, css.vertical),
};
