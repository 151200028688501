import { comparator, sort, toLower } from 'ramda';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { ComponentReturningChildren } from '@src/components';
import { Spinner, Tag } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { formStyle, regionFormContainerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { ConfigMerchantModel } from '@src/models';
import { defaultTheme } from '@src/styles';
import { RenameForm, SitesTable } from '.';

const LabelWrapper = withFormItem(ComponentReturningChildren, regionFormContainerLayout);

interface Props {
  merchant: ConfigMerchantModel | undefined;
  isFetching: boolean;
  onItemClick: (itemId: string) => void;
  forbidenNames?: string[];
  handleChangeName: (name: string) => void;
}
export const MerchantDetails: React.FunctionComponent<Props> = ({
  merchant,
  isFetching,
  onItemClick,
  forbidenNames,
  handleChangeName,
}) => {
  if (isFetching || !merchant) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner size="large" delay={0} />
      </div>
    );
  }

  const sortedSites = sort(
    comparator((a, b) => toLower(a.name) < toLower(b.name)),
    merchant.sites
  );

  return (
    <>
      <div>
        <Tag color={defaultTheme.color.primary} nonClickable={true}>
          <FormattedMessage id="MERCHANT" />
        </Tag>
        <h2 className={styles.header}>{merchant.name}</h2>
      </div>
      <div className={formStyle}>
        <LabelWrapper label={<FormattedMessage id="NAME" />}>
          <RenameForm
            name={merchant.name}
            forbidenNames={forbidenNames}
            handleChangeName={handleChangeName}
          />
        </LabelWrapper>
        <SitesTable sites={sortedSites} isFetching={isFetching} onItemClick={onItemClick} />
      </div>
    </>
  );
};

const styles = stylesheet({
  header: {
    marginTop: '10px',
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
});
