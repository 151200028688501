import { CutoffTimesModel, OperatingScheduleModel, Weekdays } from '@src/models';
import { hasPath, insert, pathOr, remove } from 'ramda';

const hasTimes = (dayKey: Weekdays, method: string, operatingSchedule: OperatingScheduleModel) => {
  return hasPath([method, 'cutoffTimes', dayKey, 'times'])(operatingSchedule);
};

export const editDateCutoffTimes = (
  dayKey: Weekdays,
  method: string,
  index: number,
  time: string,
  operatingSchedule: OperatingScheduleModel
) => {
  if (hasTimes(dayKey, method, operatingSchedule)) {
    const times = operatingSchedule[method]!.cutoffTimes![dayKey]!.times!;
    return {
      ...operatingSchedule[method]!.cutoffTimes,
      [dayKey]: {
        times: insert(index, time, remove(index, 1, times)),
      },
    };
  }
  return {};
};

export const addCutoffTimes = (
  dayKey: Weekdays,
  method: string,
  time: string,
  operatingSchedule: OperatingScheduleModel
) => {
  let cutoffTimesEdited = {};
  if (hasTimes(dayKey, method, operatingSchedule)) {
    const times = operatingSchedule[method]!.cutoffTimes![dayKey]!.times!;
    cutoffTimesEdited = {
      ...operatingSchedule[method]!.cutoffTimes,
      [dayKey]: {
        times: [...times, time],
      },
    };
  } else {
    cutoffTimesEdited = {
      ...operatingSchedule[method]!.cutoffTimes,
      [dayKey]: {
        times: [time],
      },
    };
  }
  return cutoffTimesEdited;
};

export const removeCutoffTimes = (
  dayKey: Weekdays,
  method: string,
  index: number,
  operatingSchedule: OperatingScheduleModel
) => {
  let cutoffTimesEdited: CutoffTimesModel = {};
  if (hasTimes(dayKey, method, operatingSchedule)) {
    const times = operatingSchedule[method]!.cutoffTimes![dayKey]!.times!;
    if (times.length <= 1) {
      cutoffTimesEdited = {
        ...operatingSchedule[method]!.cutoffTimes,
      };
      delete cutoffTimesEdited[dayKey];
    } else {
      cutoffTimesEdited = {
        ...operatingSchedule[method]!.cutoffTimes,
        [dayKey]: { times: remove(index, 1, times) },
      };
    }
  }
  return cutoffTimesEdited;
};

export const copyTimeForCutoffTimes = (
  method: string,
  time: string,
  copyFor: 'existingDays' | 'weekWithoutWeekend' | 'fullWeek',
  operatingSchedule: OperatingScheduleModel
) => {
  let cutoffTimesEdited: CutoffTimesModel = {};
  if (hasPath([method, 'cutoffTimes'])(operatingSchedule)) {
    const cutoffTimes = operatingSchedule[method]!.cutoffTimes!;
    const weekWithoutWeekend = ['mon', 'tue', 'wed', 'thu', 'fri'];
    switch (copyFor) {
      case 'existingDays':
        return (cutoffTimesEdited = Object.entries(cutoffTimes).reduce(
          (prevCutoffTime, nextCutoffTime) => {
            return {
              ...prevCutoffTime,
              ...{
                [nextCutoffTime[0]]: {
                  times: pathOr([], [1, 'times'], nextCutoffTime).map(() => time),
                },
              },
            };
          },
          {}
        ));
      case 'weekWithoutWeekend':
        return (cutoffTimesEdited = weekWithoutWeekend.reduce(
          (prev, day) => ({
            ...prev,
            ...{
              [day]: {
                times: cutoffTimes[day as Weekdays]
                  ? pathOr([], [day, 'times'], cutoffTimes).map(() => time)
                  : [time],
              },
            },
          }),
          {}
        ));
      case 'fullWeek':
        return (cutoffTimesEdited = Object.keys(Weekdays).reduce(
          (prev, day) => ({
            ...prev,
            ...{
              [day]: {
                times: cutoffTimes[day as Weekdays]
                  ? pathOr([], [day, 'times'], cutoffTimes).map(() => time)
                  : [time],
              },
            },
          }),
          {}
        ));
    }
  }
  return cutoffTimesEdited;
};
