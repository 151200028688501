import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';

import { SiteTagModel, UserTagModel } from '@src/models';
import { ShipmentSummaryModel } from '@src/models';
import { isEmpty } from 'ramda';
import { Reducer } from '../../modules';
import * as tagsActions from './actions';

export type State = Readonly<{
  isFetching: boolean;
  error: string | null;
  userTags: UserTagModel[];
  siteTags: SiteTagModel[];
  siteTagsForUserEdit: SiteTagModel[];
  taggedShipments: { [id: string]: string[] };
  shipmentsByTagId: { [id: string]: ShipmentSummaryModel[] };
}>;

const isFetching: Reducer<State['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(tagsActions.createSiteTagRequest):
    case getType(tagsActions.deleteSiteTagsRequest):
    case getType(tagsActions.listUserTagsRequest):
    case getType(tagsActions.renameSiteTagRequest):
    case getType(tagsActions.listSiteTagsRequest):
    case getType(tagsActions.addTagsToShipmentsRequest):
    case getType(tagsActions.deleteSiteTagsRequest):
    case getType(tagsActions.renameSiteTagRequest):
    case getType(tagsActions.getShipmentsByTagIdRequest):
    case getType(tagsActions.listSiteTagsPerUserRequest):
      return true;

    case getType(tagsActions.createSiteTagError):
    case getType(tagsActions.createSiteTagSuccess):
    case getType(tagsActions.deleteSiteTagsError):
    case getType(tagsActions.deleteSiteTagsSuccess):
    case getType(tagsActions.listUserTagsError):
    case getType(tagsActions.listUserTagsSuccess):
    case getType(tagsActions.renameSiteTagError):
    case getType(tagsActions.renameSiteTagSuccess):
    case getType(tagsActions.listSiteTagsSuccess):
    case getType(tagsActions.listSiteTagsError):
    case getType(tagsActions.addTagsToShipmentsSuccess):
    case getType(tagsActions.addTagsToShipmentsError):
    case getType(tagsActions.removeTagFromShipmentsSuccess):
    case getType(tagsActions.removeTagFromShipmentsError):
    case getType(tagsActions.getShipmentIdsByTagsError):
    case getType(tagsActions.getShipmentIdsByTagsSuccess):
    case getType(tagsActions.getShipmentsByTagIdError):
    case getType(tagsActions.getShipmentsByTagIdSuccess):
    case getType(tagsActions.listSiteTagsPerUserError):
    case getType(tagsActions.listSiteTagsPerUserSuccess):
      return false;

    default:
      return state;
  }
};

const error: Reducer<State['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(tagsActions.createSiteTagError):
    case getType(tagsActions.deleteSiteTagsError):
    case getType(tagsActions.listUserTagsError):
    case getType(tagsActions.renameSiteTagError):
    case getType(tagsActions.addTagsToShipmentsError):
    case getType(tagsActions.removeTagFromShipmentsError):
    case getType(tagsActions.getShipmentIdsByTagsError):
    case getType(tagsActions.getShipmentsByTagIdError):
    case getType(tagsActions.listSiteTagsPerUserError):
      return action.payload;

    case getType(tagsActions.createSiteTagRequest):
    case getType(tagsActions.createSiteTagSuccess):
    case getType(tagsActions.deleteSiteTagsRequest):
    case getType(tagsActions.deleteSiteTagsSuccess):
    case getType(tagsActions.listUserTagsRequest):
    case getType(tagsActions.listUserTagsSuccess):
    case getType(tagsActions.renameSiteTagRequest):
    case getType(tagsActions.renameSiteTagSuccess):
    case getType(tagsActions.removeTagFromShipmentsRequest):
    case getType(tagsActions.removeTagFromShipmentsSuccess):
    case getType(tagsActions.addTagsToShipmentsRequest):
    case getType(tagsActions.addTagsToShipmentsSuccess):
    case getType(tagsActions.getShipmentIdsByTagsSuccess):
    case getType(tagsActions.getShipmentsByTagIdSuccess):
    case getType(tagsActions.listSiteTagsPerUserRequest):
    case getType(tagsActions.listSiteTagsPerUserSuccess):
      return null;

    default:
      return state;
  }
};

const siteTags: Reducer<State['siteTags']> = (state = [], action) => {
  switch (action.type) {
    case getType(tagsActions.listSiteTagsSuccess):
      return action.payload;

    case getType(tagsActions.createSiteTagSuccess):
      return [action.payload, ...state];

    case getType(tagsActions.deleteSiteTagsSuccess):
      return state.filter(tag => !action.payload.ids.includes(tag.id));

    case getType(tagsActions.renameSiteTagSuccess):
      return state.map(tag => (tag.id === action.payload.id ? action.payload : tag));

    default:
      return state;
  }
};

const userTags: Reducer<State['userTags']> = (state = [], action) => {
  switch (action.type) {
    case getType(tagsActions.listUserTagsSuccess):
      return action.payload;

    case getType(tagsActions.listUserTagsError):
    case getType(tagsActions.listUserTagsRequest):
      return [];
    default:
      return state;
  }
};

const taggedShipments: Reducer<State['taggedShipments']> = (state = {}, action) => {
  switch (action.type) {
    case getType(tagsActions.getShipmentIdsByTagsSuccess):
      return {
        ...state,
        ...action.payload.reduce(
          (prev, curr) => ({ ...prev, [curr.tag.id]: curr.shipmentIds }),
          {}
        ),
      };
    case getType(tagsActions.resetTaggedShipments):
      return {};
    default:
      return state;
  }
};

const shipmentsByTagId: Reducer<State['shipmentsByTagId']> = (state = {}, action) => {
  switch (action.type) {
    case getType(tagsActions.getShipmentsByTagIdSuccess):
      return isEmpty(action.payload.shipments)
        ? { ...state, [action.payload.tagId]: action.payload.shipments }
        : {
            ...state,
            ...action.payload.shipments.reduce<State['shipmentsByTagId']>(
              result => ((result[action.payload.tagId] = action.payload.shipments), result),
              {}
            ),
          };
    default:
      return state;
  }
};

const siteTagsForUserEdit: Reducer<State['siteTagsForUserEdit']> = (state = [], action) => {
  switch (action.type) {
    case getType(tagsActions.listSiteTagsPerUserSuccess):
      return action.payload;

    default:
      return state;
  }
};

const reducer: Reducer<State> = combineReducers({
  isFetching,
  error,
  siteTags,
  userTags,
  taggedShipments,
  shipmentsByTagId,
  siteTagsForUserEdit,
});

export default reducer;
