import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';

import { CarrierAddonsModel, DeliveryTypeEnum } from '../../models';
import { Reducer } from '../../modules';

import { dictionariesActions } from './actions';

// STATE
export type State = Readonly<{
  deliveryTypes: DeliveryTypeEnum[];
  carrierAddons: CarrierAddonsModel[];
  availableLocales: string[];
}>;

const DELIVERY_TYPES = [
  DeliveryTypeEnum.DELIVERY,
  DeliveryTypeEnum.PICKUP,
  DeliveryTypeEnum.MAILBOX,
  DeliveryTypeEnum.INSTORE,
];

const deliveryTypes: Reducer<State['deliveryTypes']> = (state = DELIVERY_TYPES, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const carrierAddons: Reducer<State['carrierAddons']> = (state = [], action) => {
  switch (action.type) {
    case getType(dictionariesActions.getCarrierAddonsSuccess):
      return action.payload;

    default:
      return state;
  }
};

const availableLocales: Reducer<State['availableLocales']> = (state = [], action) => {
  switch (action.type) {
    case getType(dictionariesActions.getAvailableLocalesSuccess):
      return action.payload;

    default:
      return state;
  }
};

const reducer: Reducer<State> = combineReducers({
  deliveryTypes,
  carrierAddons,
  availableLocales,
});

export default reducer;
