import React, { FC } from 'react';
import { useQueryClient } from 'react-query';
import { stylesheet } from 'typestyle';

import theme from '@src/styles/theme.default';
import { TrackingRecordFiltering } from '@src/containers/tracking-numbers/types';

type Props = {
  onClearFilters: () => void;
  filters: Omit<TrackingRecordFiltering, 'id'>;
};

export const TrackingNumbersHeader: FC<Props> = ({ onClearFilters, filters }) => {
  const queryClient = useQueryClient();

  const numberOfAppliedFilters = Object.keys(filters).length;
  const showClearAllFilters = numberOfAppliedFilters > 0;

  return (
    <div className={styles.headerContainer}>
      <div>
        <span
          role="button"
          className={styles.buttonStyles}
          onClick={() => queryClient.invalidateQueries('trackingNumbers')}
        >
          Refresh
        </span>
        {' • '}
        {numberOfAppliedFilters === 1 ? (
          <span>{`${numberOfAppliedFilters} filter applied`}</span>
        ) : (
          <span>{`${numberOfAppliedFilters} filters applied`}</span>
        )}
        {showClearAllFilters && (
          <>
            {' • '}
            <span onClick={onClearFilters} role="button" className={styles.buttonStyles}>
              Clear all filters
            </span>
          </>
        )}
      </div>
    </div>
  );
};

const styles = stylesheet({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonStyles: {
    cursor: 'pointer',
    color: theme.color.primary,
    '&:hover': {
      color: theme.color.primaryLight,
    },
  },
  selectStyles: {
    width: '200px',
  },
  hiddenOption: {
    display: 'none',
  },
});
