import { InfoCircleOutlined } from '@ant-design/icons';
import { Input as AntdInput } from 'antd';
import { Field, FieldProps, useField, useFormikContext } from 'formik';
import * as React from 'react';

import { Input, InputNumber, PriceInput, TabPane, Tabs, Tooltip } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { widerLayout } from '@src/forms';
import { SIWItemModel } from '@src/models';
import { getFormikError } from '@src/utils/forms';
import { stylesheet } from 'typestyle';
import { v4 } from 'uuid';
import { WidgetFormValues } from '../helpers';
import { FormikInputField } from './fields';

const InputNumberField = withFormItem(InputNumber, widerLayout);
const InputGroupField = withFormItem(AntdInput.Group, widerLayout);
const InputField = withFormItem(Input, widerLayout);
const PriceField = withFormItem(PriceInput, widerLayout);

export const CartItems: React.FunctionComponent = () => {
  const { setFieldValue, values } = useFormikContext<WidgetFormValues>();

  const handleEdit = (targetKey: string, action: 'add' | 'remove') => {
    switch (action) {
      case 'add':
        const model = new SIWItemModel();
        model.sku = v4();
        model.quantity = 1;
        setFieldValue('items', [...values.items, model]);
        break;
      case 'remove':
        const idx = parseInt(targetKey, 10);
        setFieldValue('items', [...values.items.slice(0, idx), ...values.items.slice(idx + 1)]);
        break;
    }
  };

  return (
    <>
      <Field name="items">
        {({ form }: FieldProps<WidgetFormValues['items'], WidgetFormValues>) => (
          <Tabs type="editable-card" onEdit={handleEdit} className={styles.tabs}>
            {form.values.items.map((item, idx) => (
              <TabPane key={`${idx}`} tab={`Item ${idx + 1}`}>
                <FormikInputField name={`items[${idx}].sku`} label="Sku" />
                <FormikInputField name={`items[${idx}].name`} label="Name" />
                <FormikInputNumberField name={`items[${idx}].weight`} label="Weight" />
                <InputGroupField
                  compact={true}
                  label={
                    <>
                      <span>Dimensions</span>
                      <Tooltip title="Length × Width × Height">
                        <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                      </Tooltip>
                    </>
                  }
                  className={styles.inputGroup}
                >
                  <FormikInputNumberField
                    name={`items[${idx}].dimensions.length`}
                    placeholder="L"
                  />
                  <span className={styles.dimensionsSeparator}>×</span>
                  <FormikInputNumberField name={`items[${idx}].dimensions.width`} placeholder="W" />
                  <span className={styles.dimensionsSeparator}>×</span>
                  <FormikInputNumberField
                    name={`items[${idx}].dimensions.height`}
                    placeholder="H"
                  />
                </InputGroupField>
                <Field name={`items[${idx}].price`}>
                  {({ field }: FieldProps<WidgetFormValues['items'][0]['price']>) => (
                    <PriceField
                      {...field}
                      label="Price"
                      required={true}
                      onChange={value => setFieldValue(`items[${idx}].price`, value)}
                    />
                  )}
                </Field>
                <Field name={`items[${idx}].attributes`}>
                  {({ field }: FieldProps<SIWItemModel['attributes']>) => (
                    <InputField
                      {...field}
                      label="Attributes"
                      onChange={event => setFieldValue(field.name, event.target.value.split(','))}
                      placeholder="Comma separated list of attributes"
                    />
                  )}
                </Field>
              </TabPane>
            ))}
          </Tabs>
        )}
      </Field>
    </>
  );
};

const styles = stylesheet({
  tabs: { marginTop: '10px' },
  inputGroup: {
    display: 'flex !important',
    alignItems: 'center',
    $nest: {
      '.ant-form-item': {
        margin: 0,
      },
    },
  },
  dimensionsSeparator: {
    margin: '0 10px !important',
  },
});

const FormikInputNumberField: React.FunctionComponent<{
  name: string;
  label?: React.ReactNode;
  placeholder?: string;
}> = ({ name, label, placeholder }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <InputNumberField
      {...field}
      onChange={value => helpers.setValue(value)}
      min={0}
      label={label}
      placeholder={placeholder}
      error={getFormikError(meta.touched, meta.error)}
    />
  );
};
