import { TriggerListItemModel } from '@src/models';
import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { shipmentNotificationsActions } from '.';
import { Reducer } from '..';

export type TriggersState = Readonly<{
  isFetching: boolean;
  items: TriggerListItemModel[];
  error: null | string;
}>;

const isFetching: Reducer<TriggersState['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(shipmentNotificationsActions.listNotificationTriggersRequest):
      return true;
    case getType(shipmentNotificationsActions.listNotificationTriggersError):
    case getType(shipmentNotificationsActions.listNotificationTriggersSuccess):
      return false;
    default:
      return state;
  }
};

const items: Reducer<TriggersState['items']> = (state = [], action) => {
  switch (action.type) {
    case getType(shipmentNotificationsActions.listNotificationTriggersSuccess):
      return action.payload;
    case getType(shipmentNotificationsActions.deleteNotificationTriggerRequest):
      return state.filter(item => item.id !== action.payload);
    default:
      return state;
  }
};

const error: Reducer<TriggersState['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(shipmentNotificationsActions.deleteNotificationTriggerRequest):
    case getType(shipmentNotificationsActions.deleteNotificationTriggerSuccess):
    case getType(shipmentNotificationsActions.listNotificationTriggersSuccess):
    case getType(shipmentNotificationsActions.listNotificationTriggersRequest):
      return null;
    case getType(shipmentNotificationsActions.listNotificationTriggersError):
    case getType(shipmentNotificationsActions.deleteNotificationTriggerError):
      return action.payload;
    default:
      return state;
  }
};

export const triggersReducer: Reducer<TriggersState> = combineReducers({
  isFetching,
  items,
  error,
});
