import * as qs from 'query-string';
import {
  DeliveryStatus,
  ShipmentListFiltersModel,
  ShipmentsListFiltersDTO,
  ShipmentSummaryDTO,
  ShipmentSummaryModel,
} from '../models';
import { apiUtils, authFetch } from '../utils';

const SOM_SEARCH_URL = apiUtils.getApiUrl() + '/frontend/som_search';

type Direction = 'ASCENDING' | 'DESCENDING';

export async function getShipmentList(
  siteId: string,
  tosIds: string[],
  direction: Direction = 'DESCENDING',
  pageNumber: number = 1,
  pageSize: number = 10,
  filters?: ShipmentListFiltersModel
): Promise<{
  shipments: ShipmentSummaryModel[];
}> {
  if (!siteId) {
    throw new Error('siteId is missing');
  }

  tosIds = tosIds.filter(tosId => tosId !== '');

  let filtersDTO;
  if (filters) {
    filtersDTO = ShipmentsListFiltersDTO.createFromModel(filters);
  }

  if (tosIds) {
    filtersDTO = { ...filtersDTO, tos_ids: tosIds };
  }

  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({
      pagination: {
        offset: (pageNumber - 1) * pageSize,
        limit: pageSize,
      },
      order_by_direction: direction,
      filtering: filtersDTO,
    }),
  };

  return authFetch<{
    shipments?: ShipmentSummaryDTO[];
  }>(`${SOM_SEARCH_URL}/shipments_summaries.get`, options).then(res => ({
    shipments: res.shipments ? res.shipments.map(ShipmentSummaryModel.createFromDTO) : [],
  }));
}

export async function getShipmentMetadata(
  siteId: string,
  shipmentId: string
): Promise<{ orderNumber: string; status: DeliveryStatus }> {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<{ order_number: string; status: DeliveryStatus }>(
    `${SOM_SEARCH_URL}/shipment_metadata.get?` + qs.stringify({ shipment_id: shipmentId }),
    options
  ).then(res => ({
    orderNumber: res.order_number || '',
    status: (res.status === 'INVALID_BOOKING' ? 'CREATED' : res.status) || 'UNKNOWN',
  }));
}

type BulkPrintLabelsResponse = {
  labels_urls?: string[];
  not_included_shipments_ids?: string[];
};

export const bulkPrintLabels = (siteId: string, shipmentIds: string[]) => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<BulkPrintLabelsResponse>(
    `${SOM_SEARCH_URL}/shipments.get_labels?${qs.stringify({
      shipment_ids: shipmentIds,
      format: 'PDF',
      merge_labels: true,
      convert_labels: true,
    })}`,
    options
  );
};

const createGetStatusRequest = (siteId: string, id: string) => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };
  return authFetch<{ orderNumber: string; status: DeliveryStatus }>(
    `${SOM_SEARCH_URL}/shipment_metadata.get?` + qs.stringify({ shipment_id: id }),
    options
  ).then(res => ({
    shipmentId: id,
    status: res.status || 'UNKNOWN',
  }));
};

export function getShipmentsStatuses(
  siteId: string,
  ids: string[]
): Promise<
  {
    status: DeliveryStatus;
    shipmentId: string;
  }[]
> {
  const requests = ids.map(id => createGetStatusRequest(siteId, id));
  return Promise.all(requests);
}
