export const RouterPaths = {
  HOME: '/',
  HOME_PAGE: '/merchants/:metchantId/sites/:siteId',
  USERS: '/merchants/:merchantId/sites/:siteId/users',
  USERS_CREATE: '/merchants/:merchantId/sites/:siteId/users/create',
  USERS_EDIT: '/merchants/:merchantId/sites/:siteId/users/edit/:userId',
  EXPERIMENT_LIST: '/merchants/:merchantId/sites/:siteId/experiments',
  EXPERIMENT_DETAILS: '/merchants/:merchantId/sites/:siteId/experiments/:experimentId',
  TRANSPORT_ORDER_LIST: '/merchants/:merchantId/sites/:siteId/transport-orders',
  TRANSPORT_ORDER_DETAILS:
    '/merchants/:merchantId/sites/:siteId/transport-orders/details/:transportOrderId',
  SHIPMENT_LIST: '/merchants/:merchantId/sites/:siteId/shipments',
  SHIPMENT_BOOK_AND_PRINT:
    '/merchants/:merchantId/sites/:siteId/transport-orders/:tosId/shipments/:shipmentId/book-and-print/:parcelId',
  SHIPMENT_CREATE:
    '/merchants/:merchantId/sites/:siteId/transport-orders/:tosId?/shipments/create/:return?',
  SHIPMENT_EDIT:
    '/merchants/:merchantId/sites/:siteId/transport-orders/:tosId?/shipments/edit/:shipmentId',
  ADDRESS_BOOK: '/merchants/:merchantId/sites/:siteId/address-book',
  ADDRESS_BOOK_CREATE_CONTACT: '/merchants/:merchantId/sites/:siteId/address-book/create-contact',
  ADDRESS_BOOK_EDIT_CONTACT:
    '/merchants/:merchantId/sites/:siteId/address-book/edit-contact/:contactId',
  TEMPLATE_LIST: '/merchants/:merchantId/sites/:siteId/templates',
  TEMPLATE_CREATE: '/merchants/:merchantId/sites/:siteId/templates/create',
  TEMPLATE_EDIT: '/merchants/:merchantId/sites/:siteId/templates/edit/:templateId',
  CHECKOUT_WIDGET_TEST_CLIENT: '/merchants/:merchantId/sites/:siteId/checkout-widget',
  UPSELL_FEATURES: '/merchants/:merchantId/sites/:siteId/upsell-features',
  UPSELL_COUNTRY_DETAILS: '/merchants/:merchantId/sites/:siteId/upsell-features/:country',
  UPSELL_DEFAULT_SETTINGS: '/merchants/:merchantId/sites/:siteId/upsell-features/default',
  UPSELL_WIDGET_TEST_CLIENT: '/merchants/:merchantId/sites/:siteId/upsell-widget',
  FAQ_WIDGET_FEATURES: '/merchants/:merchantId/sites/:siteId/faq-widget-features',
  FAQ_WIDGET_COUNTRY_DETAILS: '/merchants/:merchantId/sites/:siteId/faq-widget/:country',
  FAQ_WIDGET_DEFAULT_SETTINGS: '/merchants/:merchantId/sites/:siteId/faq-widget/default',
  FAQ_WIDGET_TEST_CLIENT: '/merchants/:merchantId/sites/:siteId/faq-widget',
  PRODUCT_PAGE_FEATURES: '/merchants/:merchantId/sites/:siteId/product-page-features',
  PRODUCT_PAGE_COUNTRY_DETAILS:
    '/merchants/:merchantId/sites/:siteId/product-page-features/:country',
  PRODUCT_PAGE_DEFAULT_SETTINGS:
    '/merchants/:merchantId/sites/:siteId/product-page-features/default',
  PRODUCT_PAGE_WIDGET_TEST_CLIENT: '/merchants/:merchantId/sites/:siteId/product-page-widget',
  TRACKING_NUMBERS: '/merchants/:merchantId/sites/:siteId/tracking-numbers',
  TRACKING_NUMBER_DETAILS: '/merchants/:merchantId/sites/:siteId/tracking-numbers/:trackingNumber',
  TRACKING_WIDGET_TEST_CLIENT: '/merchants/:merchantId/sites/:siteId/tracking-widget',
  WATCHLIST_LIST: '/merchants/:merchantId/sites/:siteId/watchlists',
  TAGS_LIST: '/merchants/:merchantId/sites/:siteId/tags',
  REGIONS_LIST: '/merchants/:merchantId/sites/:siteId/regions',
  REGION_DETAILS: '/merchants/:merchantId/sites/:siteId/regions/:regionId',
  WAREHOUSES_LIST: '/merchants/:merchantId/sites/:siteId/warehouses',
  WAREHOUSE_DETAILS: '/merchants/:merchantId/sites/:siteId/warehouses/:warehouseId',
  GROUPS_LIST: '/merchants/:merchantId/sites/:siteId/groups',
  COUNTRY_LIST: '/merchants/:merchantId/sites/:siteId/features',
  COUNTRY_DETAILS: '/merchants/:merchantId/sites/:siteId/features/:country',
  DEFAULT_SETTINGS: '/merchants/:merchantId/sites/:siteId/features/default',
  SESSION_EVENTS: '/merchants/:merchantId/sites/:siteId/sessionEvents',
  MERCHANTS_LIST: '/merchants/:merchantId/sites/:siteId/merchants',
  MERCHANT_DETAILS: '/merchants/:merchantId/sites/:siteId/merchants/:detailsMerchantId',
  PRODUCT_MAPPINGS: '/merchants/:merchantId/sites/:siteId/product-mappings',
  RECEIPT_WIDGET_TEST_CLIENT: '/merchants/:merchantId/sites/:siteId/receipt-widget',
  RECEIPT_FEATURES: '/merchants/:merchantId/sites/:siteId/receipt-features',
  RECEIPT_DEFAULT_SETTINGS: '/merchants/:merchantId/sites/:siteId/receipt-features/default',
  RECEIPT_COUNTRY_SETTINGS: '/merchants/:merchantId/sites/:siteId/receipt-features/:country',
  RECEIPT_WIDGET_INTEGRATION_GUIDE:
    '/merchants/:merchantId/sites/:siteId/receipt-widget-integration-guide',
  TRACKING_PLAYGROUND: '/merchants/:merchantId/sites/:siteId/tracking-playground',
  TRACKING_DASHBOARD: '/merchants/:merchantId/sites/:siteId/tracking-daily',
  CHECKOUT_DASHBOARD: '/merchants/:merchantId/sites/:siteId/checkout-daily',
  TRANSPORT_ADMINISTRATION_DASHBOARD:
    '/merchants/:merchantId/sites/:siteId/transport-administration-daily',
  NOTIFICATIONS: '/merchants/:merchantId/sites/:siteId/notifications',
  BOOKING_RULES: '/merchants/:merchantId/sites/:siteId/booking-rules',
};
