import React, { FC, useEffect } from 'react';

import { services } from '@src/services';
import { stylesheet } from 'typestyle';

const env = services.envService.getEnvironmentalVariables();

type Props = {
  initTrackingWidget: () => void;
};

export const Widget: FC<Props> = ({ initTrackingWidget }) => {
  const trackingWidgetScript = document.createElement('script');

  const createTrackingWidgetScript = () => {
    trackingWidgetScript.type = 'text/javascript';
    trackingWidgetScript.async = false;
    trackingWidgetScript.dataset.source = 'ingrid';
    trackingWidgetScript.src = env.MAD_DELIVERY_TRACKING_URL;
    document.body.appendChild(trackingWidgetScript);
    trackingWidgetScript.onload = initTrackingWidget;
  };

  useEffect(() => {
    createTrackingWidgetScript();
    return () => trackingWidgetScript.remove();
  }, []);

  return <div id="widget" className={styles.deliveryTrackingContainer} />;
};

const styles = stylesheet({
  deliveryTrackingContainer: {
    margin: 'auto',
    width: '420px',
  },
});
