import * as React from 'react';
import { style as tss } from 'typestyle';

import { LimitedNumberOfItems, LinkStyleButton } from '@src/components';
import { Tag } from '@src/controls';
import { LABELS } from '@src/dictionaries';
import { FormattedMessage } from '@src/i18n';
import { RBACGroupModel } from '@src/models';
import { defaultTheme } from '@src/styles';
import { List } from 'antd';

interface Props {
  groups: RBACGroupModel[];
  isFetching: boolean;
  onClickItem: (group: RBACGroupModel) => void;
  onDeleteItem: (groupId: string) => void;
}

interface ListItem {
  content: JSX.Element;
}

export const GroupsList: React.FunctionComponent<Props> = ({
  isFetching,
  groups,
  onClickItem,
  onDeleteItem,
}) => {
  const data: ListItem[] = groups.map(group => {
    return {
      content: (
        <div className={styles.listItem}>
          <LinkStyleButton onClick={() => onClickItem(group)} className={styles.groupName}>
            {group.name}
          </LinkStyleButton>
          <div className={styles.subRegions}>
            <Tag
              color={defaultTheme.color.white}
              textColor={defaultTheme.color.primary}
              borderColor={defaultTheme.color.primary}
              nonClickable={true}
            >
              <FormattedMessage id="ROLES" />
            </Tag>
            {group.roles && group.roles.length > 0 && (
              <div className={styles.roles}>
                <LimitedNumberOfItems list={group.roles.map(role => role.name)} />
              </div>
            )}
          </div>
          <a onClick={() => onDeleteItem(group.id)} className={styles.delete}>
            {LABELS.DELETE}
          </a>
        </div>
      ),
    };
  });
  return (
    <List
      dataSource={data}
      loading={isFetching}
      renderItem={(item: ListItem) => (
        <List.Item>
          <List.Item.Meta description={item.content} />
        </List.Item>
      )}
    />
  );
};

const styles = {
  listItem: tss({
    display: 'flex',
    paddingTop: '5px',
  }),
  groupName: tss({
    flexBasis: '30%',
  }),
  subRegions: tss({
    marginRight: 'auto',
    paddingLeft: '20px',
  }),
  roles: tss({
    marginTop: '2px',
    display: 'flex',
    flexWrap: 'wrap',
  }),
  delete: tss({
    width: '50px',
    marginRight: '20px',
    textAlign: 'left',
  }),
};
