import * as React from 'react';
import { FormattedMessage as IntlFormatedMessage } from 'react-intl';

import { classes, stylesheet } from 'typestyle';
import * as enUSMessages from '../../../locales/en-US.json';

interface Props extends React.ComponentProps<typeof IntlFormatedMessage> {
  id: keyof typeof enUSMessages;
  className?: string;
  modification?: 'lowerCase';
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const FormattedMessage: React.FunctionComponent<Props> = ({
  children,
  className,
  values,
  modification,
  onClick,
  ...restProps
}) => (
  <IntlFormatedMessage values={{ multiple: false, count: '', ...values }} {...restProps}>
    {children
      ? children
      : formattedMessages => (
          <span
            className={classes(className, modification && styles.messageLowerCase)}
            onClick={onClick}
          >
            {formattedMessages}
          </span>
        )}
  </IntlFormatedMessage>
);

const styles = stylesheet({
  messageLowerCase: {
    textTransform: 'lowercase',
  },
});
