import { Spinner } from '@src/controls';
import * as css from 'csstips';
import * as React from 'react';
import { classes, style as tss } from 'typestyle';

type Props = {
  noPadding?: boolean;
  className?: string;
  isLoading?: boolean;
};

const styles = {
  root: tss(css.vertical, {
    padding: 24,
    overflowY: 'auto',
    position: 'relative',
  }),
  rootNoPadding: tss(css.vertical, {
    overflowY: 'auto',
    position: 'relative',
  }),
};

// id="select-scroll-helpers" allows <Select /> components to find the right scroll area.
// If select dropdown menu moves along with the scroll and that looks bad, use this component to wrap the entire scrolling content
const Component: React.FunctionComponent<Props> = ({
  children,
  noPadding,
  className,
  isLoading,
}) => {
  return (
    <section
      id="select-scroll-helper"
      className={classes(noPadding ? styles.rootNoPadding : styles.root, className)}
    >
      {isLoading ? <Spinner /> : children}
    </section>
  );
};

export default Component;
