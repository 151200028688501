import * as React from 'react';
import { stylesheet } from 'typestyle';

import { LABELS } from '@src/dictionaries';
import { FormattedMessage } from '@src/i18n';
import { DeliveryAddonModel } from '@src/models';
import { configActions } from '@src/modules/config';
import themeDefault from '@src/styles/theme.default';

import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { Popconfirm } from 'antd';

type Props = {
  categoryId: string;
  addon: DeliveryAddonModel;
};

export const DeleteAddonPopover: React.FC<Props> = ({ categoryId, addon: { id, name } }) => {
  return (
    <Popconfirm
      title={
        <FormattedMessage
          id="CATEGORY_MODAL.DELIVERY_ADDONS.CONFIRM_DELETE"
          values={{ addonName: name }}
        />
      }
      okText={LABELS.YES}
      cancelText={LABELS.NO}
      onConfirm={() => {
        dispatchOnDraftSiteId(configActions.deleteDeliveryAddonRequest)({
          model: { categoryId, addonId: id },
        });
      }}
    >
      <FormattedMessage id="DELETE" className={styles.deleteButton} />
    </Popconfirm>
  );
};

const styles = stylesheet({
  deleteButton: {
    color: themeDefault.color.primary,
    cursor: 'pointer',
  },
});
