import { ShipmentListFiltersModel, TransportOrderListFiltersModel } from '../models';

export const getNumberOfShipmentFilters = (filters: ShipmentListFiltersModel) => {
  return Object.entries(filters).reduce((initial, [key, value]) => {
    if (key === 'createdAtRange' || key === 'shippingDateRange') {
      if (value) {
        return value.start && value.end ? initial + 1 : initial;
      }
    }
    return value?.length ? initial + 1 : initial;
  }, 0);
};

export const getNumberOfToFilters = (filters: TransportOrderListFiltersModel) => {
  return Object.entries(filters).reduce((initial, [key, value]) => {
    return value?.length ? initial + 1 : initial;
  }, 0);
};
