import { head } from 'ramda';
import { combineEpics, Epic } from 'redux-observable';
import { from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { getType, isActionOf } from 'typesafe-actions';

import { RootAction, RootState, Services } from '../../modules';
import { MADError } from '../../utils';
import { shipmentsActions } from '../shipments';
import { deliveriesActions } from './';

const triggersForDeliveriesHistory: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(
      isActionOf([
        shipmentsActions.getShipmentSuccess,
        shipmentsActions.bookParcelsSuccess,
        shipmentsActions.bookPickupDeliveriesSuccess,
      ])
    ),
    mergeMap(action => {
      const siteId = state$.value.sites.selectedSiteId || '';
      switch (action.type) {
        case getType(shipmentsActions.bookPickupDeliveriesSuccess):
          return action.payload.deliveries.map(delivery =>
            deliveriesActions.getDeliveryStatusesRequest({
              siteId,
              deliveryId: delivery.id,
            })
          );
        default:
          return action.payload.parcels.map(parcel => {
            const delivery = head(parcel.deliveries);
            return deliveriesActions.getDeliveryStatusesRequest({
              siteId,
              deliveryId: delivery ? delivery.id : '',
            });
          });
      }
    })
  );

const fetchDeliveriesStatuses: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { loggingService, wimoService }
) =>
  action$.pipe(
    filter(isActionOf(deliveriesActions.getDeliveryStatusesRequest)),
    mergeMap(({ payload: { siteId, deliveryId } }) =>
      observableFrom(wimoService.getDeliveryStatusHistoryForDeliveryWith(siteId, deliveryId)).pipe(
        map(res => deliveriesActions.getDeliveryStatusesSuccess({ models: res, deliveryId })),
        catchError((error: MADError) =>
          observableOf(deliveriesActions.getDeliveryStatusesError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

export const deliveriesEpics = combineEpics(triggersForDeliveriesHistory, fetchDeliveriesStatuses);
