import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { classes, stylesheet } from 'typestyle';

import { Button, Input, Radio, Select, TextArea } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, bigLabelLayout, inputStyle } from '@src/forms';
import { useFormatMessage } from '@src/i18n';
import { ConfigWarehouseModel } from '@src/models';
import { commons } from '@src/styles';
import { getFormikError } from '@src/utils/forms';
import { services } from '@src/services';
import {
  regionTypeOptions,
  regionTypeOptionsWithDisabledZipCodes,
  sortCountriesCodesByTheirNames,
} from './helpers';
import { RegionDetailsFormValues } from './region-details';

const InputField = withFormItem(Input, bigLabelLayout);
const SelectField = withFormItem(Select, bigLabelLayout);
const RadioField = withFormItem(Radio, bigLabelLayout);
const TextAreaField = withFormItem(TextArea, bigLabelLayout);

interface Props {
  warehouses: ConfigWarehouseModel[];
  onSubmit: (values: CreateRegionFormValues) => void;
  regionsNames: string[];
}

export interface CreateRegionFormValues {
  name: string;
  warehouseId: string;
  type: RegionDetailsFormValues['type'];
  countries: string[];
  zipCodes: string;
}

export const CreateRegionForm: React.FunctionComponent<Props> = ({
  onSubmit,
  warehouses,
  regionsNames,
}) => {
  const formattedMessage = useFormatMessage();

  const warehouseOptions = warehouses.map(warehouse => ({
    value: warehouse.id,
    label: warehouse.address.name || warehouse.id,
  }));

  const validateWarehouse = (value: string) => {
    if (!value) {
      return formattedMessage('FIELD_REQUIRED_VALIDATION', { field: 'Warehouse' });
    }
    return '';
  };

  const validateCountries = (value: string[], type: CreateRegionFormValues['type']) => {
    if (value.length === 0) {
      return formattedMessage('COUNTRY_FIELD_REQUIRED', {
        multiple: type !== 'ZIP_CODES_INCLUDED' && type !== 'ZIP_CODES_EXCLUDED',
      });
    }
    return '';
  };

  const validateRegionName = (value: string) => {
    const regionNamesInCapitals = regionsNames.map(regionName => regionName.toUpperCase());
    if (regionNamesInCapitals.includes(value.toUpperCase().trim())) {
      return formattedMessage('FIELD_ALREADY_USED_VALIDATION', { field: 'Region name' });
    } else if (value.trim() === '') {
      return formattedMessage('FIELD_REQUIRED_VALIDATION', { field: 'Name' });
    }
    return '';
  };

  const validatieZipCodes = (value: string) => {
    if (value.length === 0) {
      return formattedMessage('FIELD_REQUIRED_VALIDATION', { field: 'Zip codes' });
    }
    return '';
  };

  return (
    <Formik<CreateRegionFormValues>
      onSubmit={values => onSubmit(values)}
      initialValues={{
        name: '',
        warehouseId:
          warehouses.filter(w => w.state === 'ACTIVE').length === 1 ? warehouses?.[0]?.id : '',
        type: 'COUNTRIES_INCLUDED',
        countries: [],
        zipCodes: '',
      }}
    >
      {({ handleSubmit, errors, touched, setFieldValue, values: { type, countries } }) => (
        <AutoSaveForm onFinish={handleSubmit} className={styles.formWrapper}>
          <Field name="name" validate={validateRegionName}>
            {({ field }: FieldProps<CreateRegionFormValues['name']>) => (
              <InputField
                {...field}
                label={formattedMessage('NAME')}
                labelAlign="left"
                error={getFormikError(touched.name, errors.name)}
                className={inputStyle}
                required={true}
              />
            )}
          </Field>
          <Field name="type">
            {({ field }: FieldProps<CreateRegionFormValues['type']>) => (
              <RadioField
                {...field}
                onChange={event => {
                  setFieldValue('type', event.target.value);
                  if (event.target.value === 'zipCodesIncluded') {
                    setFieldValue('countries', []);
                  }
                }}
                value={field.value}
                options={
                  countries.length > 1 ? regionTypeOptionsWithDisabledZipCodes : regionTypeOptions
                }
                label={formattedMessage('TYPE')}
                labelAlign="left"
                className={styles.radioInput}
                error={getFormikError(touched.type, errors.type)}
              />
            )}
          </Field>
          <Field name="countries" validate={(value: string[]) => validateCountries(value, type)}>
            {({ field }: FieldProps<CreateRegionFormValues['countries']>) => (
              <SelectField
                onChange={(value: string[] | string) => {
                  setFieldValue(
                    'countries',
                    sortCountriesCodesByTheirNames(Array.isArray(value) ? value : [value])
                  );
                }}
                groupOptions={services.dictionariesService.getCountriesGroupedByRegion()}
                label={formattedMessage('COUNTRY', {
                  multiple: type !== 'ZIP_CODES_INCLUDED' && type !== 'ZIP_CODES_EXCLUDED',
                })}
                className={classes(inputStyle, styles.countriesInput)}
                labelAlign="left"
                required={true}
                value={field.value}
                key={type}
                showSearch
                filterOption={true}
                mode={
                  type === 'ZIP_CODES_INCLUDED' || type === 'ZIP_CODES_EXCLUDED'
                    ? undefined
                    : 'multiple'
                }
                optionFilterProp="children"
                error={getFormikError(touched.countries, errors.countries)}
              />
            )}
          </Field>
          {(type === 'ZIP_CODES_INCLUDED' || type === 'ZIP_CODES_EXCLUDED') && (
            <Field name="zipCodes" validate={validatieZipCodes}>
              {({ field }: FieldProps<CreateRegionFormValues['zipCodes']>) => (
                <TextAreaField
                  label={formattedMessage('ZIP_CODES')}
                  labelAlign="left"
                  rows={5}
                  error={getFormikError(touched.zipCodes, errors.zipCodes)}
                  {...field}
                  placeholder={formattedMessage('ZIP_CODES_TEXTAREA_PLACEHOLDER')}
                  className={inputStyle}
                  required={true}
                />
              )}
            </Field>
          )}
          <Field name="warehouseId" validate={validateWarehouse}>
            {({ field }: FieldProps<CreateRegionFormValues['warehouseId']>) => (
              <SelectField
                label={formattedMessage('WAREHOUSE', { multiple: false })}
                options={warehouseOptions}
                labelAlign="left"
                error={getFormikError(touched.warehouseId, errors.warehouseId)}
                {...field}
                onChange={value => setFieldValue('warehouseId', value)}
                className={inputStyle}
                required={true}
              />
            )}
          </Field>
          <div className={commons.flex}>
            <Button type="primary" htmlType="submit" className={styles.submitButton}>
              {formattedMessage('ADD')}
            </Button>
          </div>
        </AutoSaveForm>
      )}
    </Formik>
  );
};

const styles = stylesheet({
  submitButton: {
    marginLeft: 'auto',
    marginTop: '10px',
  },
  formWrapper: {
    width: 500,
  },
  countriesInput: {
    $nest: {
      '.ant-select-selection__rendered': {
        marginRight: 0,
      },
    },
  },
  radioInput: {
    $nest: {
      '> * + *': {
        marginTop: '8px',
      },
    },
  },
});
