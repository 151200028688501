import { Affix as AntAffix } from 'antd';
import { AffixProps } from 'antd/lib/affix';
import * as React from 'react';

const Affix: React.FunctionComponent<{ children: React.ReactNode } & Omit<AffixProps, 'children'>> =
  props => {
    return <AntAffix {...(props as AffixProps)} />;
  };

export default Affix;
