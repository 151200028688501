import { BookOutlined } from '@ant-design/icons';
import { isEmpty } from 'ramda';
import { isNotEmpty } from 'ramda-adjunct';
import * as React from 'react';
import { connect } from 'react-redux';
import { style as tss } from 'typestyle';

import { ListFixedHeader } from '@src/components';
import { Card, TabPane, Tabs } from '@src/controls';
import { DOMAIN } from '@src/dictionaries';
import { RootState } from '@src/modules/';
import { tagsActions, tagsSelectors } from '@src/modules/tags';
import { WatchlistContainer } from '.';

const styles = {
  root: tss({
    display: 'flex',
    flexDirection: 'column',
  }),
  card: tss({
    overflowY: 'auto',
    minHeight: '650px',
  }),
};

interface OwnProps {
  siteId: string;
}

const mapStateToProps = (state: RootState) => ({
  tags: tagsSelectors.getUserTagsWithUserPersonalTagFirst(state),
  taggedShipments: state.tags.taggedShipments,
});

const mapDispatchToProps = {
  getTagsForUser: tagsActions.listUserTagsRequest,
  getShipmentIdsByTags: tagsActions.getShipmentIdsByTagsRequest,
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & OwnProps;

class Component extends React.Component<Props> {
  componentDidMount() {
    const { getTagsForUser, getShipmentIdsByTags, siteId } = this.props;
    getTagsForUser({
      siteId,
      onComplete: tags => getShipmentIdsByTags({ siteId, tags: tags.map(tag => tag.id) }),
    });
  }

  renameUsertagToMyWatchlist = (name: string) => (name === 'user tag' ? 'My Watchlist' : name);

  render() {
    const { tags, taggedShipments } = this.props;
    return (
      <div className={styles.root}>
        <ListFixedHeader
          title={DOMAIN.WATCHLISTS}
          IconComponent={BookOutlined}
          isLoading={false}
          showSearch={false}
        />
        <Card bordered={false} className={styles.card}>
          {isNotEmpty(tags) && (
            <Tabs defaultActiveKey={tags[0].id}>
              {tags.map(tag => (
                <TabPane key={tag.id} tab={this.renameUsertagToMyWatchlist(tag.name)}>
                  {!isEmpty(taggedShipments) ? (
                    <WatchlistContainer tagId={tag.id} />
                  ) : (
                    'No shipments found on this list'
                  )}
                </TabPane>
              ))}
            </Tabs>
          )}
        </Card>
      </div>
    );
  }
}

export const WatchlistListContainer = connect(mapStateToProps, mapDispatchToProps)(Component);
