import { Tag } from '@src/controls';
import React, { FC } from 'react';

import { sourceOptions } from '../source-options';
import { TrackingRecordSource } from '../types';

type Props = {
  source: TrackingRecordSource;
};

const allSourceOptions = sourceOptions.concat({
  label: 'External Source Generator',
  value: 'TRACKING_RECORD_SOURCE_GENERATOR',
});

export const SourceTag: FC<Props> = ({ source }) => (
  <Tag textColor="#7B61FF" borderColor="#7B61FF" style={{ width: 'min-content' }}>
    {allSourceOptions.find(({ value }) => value === source)?.label ?? source ?? 'UNSET'}
  </Tag>
);
