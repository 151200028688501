import { useQueryClient } from 'react-query';
import { append, assoc } from 'ramda';
import { v4 } from 'uuid';
import type {
  ListCarrierProductMappingResponse,
  ListSuggestedCarrierProductMappingsResponse,
} from '@src/models';
import { createCarrierProductMapping } from '@src/services/selfcare-service';
import { useSiteId } from '@src/utils/hooks';
import { useOptimisticMutationOnDraft } from '@src/utils/queries';
import type { Context } from './types';

export const useCreateCarrierProductMappingMutation = () => {
  const queryClient = useQueryClient();
  const siteId = useSiteId();

  return useOptimisticMutationOnDraft(createCarrierProductMapping, {
    onMutate: async (effectiveSiteId, payload) => {
      await queryClient.cancelQueries('suggested_carrier_product_mappings.list');
      await queryClient.cancelQueries('carrier_product_mapping.list');

      const previousSuggestedMappingsList = queryClient.getQueryData<
        ListSuggestedCarrierProductMappingsResponse | undefined
      >(['suggested_carrier_product_mappings.list', siteId]);

      const previousMappedProductsList = queryClient.getQueryData<
        ListCarrierProductMappingResponse | undefined
      >(['carrier_product_mapping.list', siteId]);

      queryClient.setQueryData<ListSuggestedCarrierProductMappingsResponse | undefined>(
        ['suggested_carrier_product_mappings.list', effectiveSiteId],
        () => {
          if (!previousSuggestedMappingsList) {
            return undefined;
          }

          const mappings = Object.entries(previousSuggestedMappingsList);

          const filteredMappings = mappings.map(([key, productSection]) => [
            key,
            productSection.filter(
              ({ carrier_product_ref }) => carrier_product_ref !== payload.carrier_product_ref
            ),
          ]);

          return Object.fromEntries(filteredMappings);
        }
      );

      queryClient.setQueryData<ListCarrierProductMappingResponse | undefined>(
        ['carrier_product_mapping.list', effectiveSiteId],
        () => {
          const mappings = previousMappedProductsList?.carrier_product_mappings ?? [];

          if (!mappings.length) {
            return previousMappedProductsList;
          }

          const newMapping = { id: v4(), ...payload };

          const updatedMappings = append(newMapping, mappings);

          return assoc('carrier_product_mappings', updatedMappings, previousMappedProductsList);
        }
      );

      return { previousSuggestedMappingsList, previousMappedProductsList, effectiveSiteId };
    },

    onError: (_, __, context: Context) => {
      queryClient.setQueryData(
        ['suggested_carrier_product_mappings.list', context.effectiveSiteId],
        context.previousSuggestedMappingsList
      );
      queryClient.setQueryData(
        ['carrier_product_mapping.list', context.effectiveSiteId],
        context.previousMappedProductsList
      );
    },

    onSettled: () => {
      queryClient.invalidateQueries('suggested_carrier_product_mappings.list');
      queryClient.invalidateQueries('carrier_product_mapping.list');
    },
  });
};
