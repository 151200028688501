import {
  CategoryRequirementsDTO,
  CategoryRequirementsModel,
  ConfigChangeModel,
  ConfigRegionModel,
  ConfigShippingCategoryModel,
  ConfigWarehouseModel,
  CustomBookingMethodModel,
  CustomShippingMethodModel,
  DeliveryTimePropertiesModel,
  ExtendedShippingMethodModel,
  WidgetConfigurationModel,
} from '@src/models';
import { Reducer } from '@src/modules';
import { sitesActions } from '@src/modules/sites';
import { mergeWith } from 'lodash';
import { dissoc, equals, fromPairs } from 'ramda';
import { combineReducers } from 'redux';
import { optimistic, OptimisticState } from 'redux-optimistic-ui';
import { getType } from 'typesafe-actions';
import * as trackingWidgetActions from '../tracking-widget/actions';
import * as configActions from './actions';

export type ConfigState = Readonly<{
  regionsBySiteId: OptimisticState<{ [siteId: string]: ConfigRegionModel[] }>;
  shippingCategoryBySiteId: OptimisticState<{ [siteId: string]: ConfigShippingCategoryModel[] }>;
  warehousesBySiteId: OptimisticState<{ [siteId: string]: ConfigWarehouseModel[] }>;
  widgetConfigurationBySiteId: OptimisticState<{
    [siteId: string]: WidgetConfigurationModel;
  }>;
  customBookingMethodsBySiteId: { [key: string]: CustomBookingMethodModel[] };
  shippingMethodsBySiteId: { [key: string]: ExtendedShippingMethodModel[] };
  customShippingMethodsBySiteId: { [key: string]: CustomShippingMethodModel[] };
  isFetching: boolean;
  isSendingChanges: boolean;
  changes: ConfigChangeModel[];
  error: string | null;
  isSynced: boolean;
  isCreatingDraft: boolean;
  copyCutoffsProgress: {
    isCopying: boolean;
    progress: number;
  };
  privateKey: string | null;
  variantSiteId: string | null;
}>;

const filterAndJoinObjectEntries = (
  state:
    | ConfigState['regionsBySiteId']['current']
    | ConfigState['shippingCategoryBySiteId']['current']
    | ConfigState['warehousesBySiteId']['current'],
  idToFilterOut: string
) =>
  Object.entries(state)
    .filter(([siteId]) => siteId !== idToFilterOut)
    .reduce((wholeState, [key, value]) => ({ ...wholeState, [key]: value }), {});

const customShippingMethodsBySiteId: Reducer<ConfigState['customShippingMethodsBySiteId']> = (
  state = {},
  action
) => {
  switch (action.type) {
    case getType(sitesActions.getSitesSuccess):
      return action.payload.reduce(
        (result, item) => ({
          ...result,
          [item.id]: item.customShippingMethods?.methods,
        }),
        {}
      );
    default:
      return state;
  }
};

const customBookingMethodsBySiteId: Reducer<ConfigState['customBookingMethodsBySiteId']> = (
  state = {},
  action
) => {
  switch (action.type) {
    case getType(sitesActions.getSitesSuccess):
      return action.payload.reduce(
        (result, item) => ({
          ...result,
          [item.id]: item.customBookingMethods ?? [],
        }),
        {}
      );
    default:
      return state;
  }
};

const variantSiteId: Reducer<ConfigState['variantSiteId']> = (state = null, action) => {
  switch (action.type) {
    case getType(configActions.setVariantSiteId):
      return action.payload.siteId;
    default:
      return state;
  }
};

const widgetConfigurationBySiteId: Reducer<ConfigState['widgetConfigurationBySiteId']['current']> =
  (state = {}, action) => {
    switch (action.type) {
      case getType(sitesActions.getSitesSuccess):
        return action.payload.reduce(
          (result, item) => ({
            ...result,
            [item.id]: item.widgetConfiguration,
          }),
          {}
        );

      case getType(configActions.createDraftSuccess):
        return { ...state, [action.payload.siteId]: state[action.payload.master.siteId] };

      case getType(configActions.updateWidgetConfigurationRequest):
        const prevState = state[action.payload.siteId] ?? { features: {}, style: {} };
        return {
          ...state,
          [action.payload.siteId]: {
            ...prevState,
            features: { ...prevState.features, ...action.payload.model.features },
            style: { ...prevState.style, ...action.payload.model.style },
          },
        };

      case getType(configActions.updateWidgetCountryConfigurationRequest):
      case getType(configActions.createWidgetCountryConfigurationRequest):
        const {
          siteId,
          model: { country, features, carrierLogos },
        } = action.payload;
        const prevFeatures = state[siteId].byCountry?.[country]?.features ?? {};
        const prevCarrierLogos = state[siteId].byCountry?.[country]?.carrierLogos;
        return {
          ...state,
          [siteId]: {
            ...state[siteId],
            byCountry: {
              ...state[siteId].byCountry,
              [country]: {
                features: { ...prevFeatures, ...features },
                carrierLogos: carrierLogos ? carrierLogos : prevCarrierLogos,
              },
            },
          },
        };
      default:
        return state;
    }
  };

const regionsBySiteId: Reducer<ConfigState['regionsBySiteId']['current']> = (
  state = {},
  action
) => {
  switch (action.type) {
    case getType(sitesActions.getSitesSuccess):
      return action.payload.reduce<ConfigState['regionsBySiteId']['current']>(
        (result, item) => ((result[item.id] = item.regions), result),
        {}
      );

    case getType(configActions.deleteRegionRequest):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].filter(
          region => region.id !== action.payload.regionId
        ),
      };

    case getType(configActions.getSiteSuccess):
      return { ...state, [action.payload.siteId]: action.payload.site.regions };

    case getType(configActions.createDraftSuccess):
      return { ...state, [action.payload.siteId]: state[action.payload.master.siteId] };

    case getType(configActions.updateCarrierProductRequest):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(region => {
          const { model } = action.payload;
          return region.id === model.regionId
            ? {
                ...region,
                carrierProducts: region.carrierProducts.map(product =>
                  equals(product.shippingMethod, model.method) &&
                  equals(model.deliveryTypes, product.deliveryTypes)
                    ? {
                        ...product,
                        shippingMethod: model.method,
                        priceRules: model.priceRules || product.priceRules,
                        externalMethodId: model.externalMethodId || product.externalMethodId,
                        shippingDateAdjustment:
                          model.shippingDateAdjustment || product.shippingDateAdjustment,
                      }
                    : product
                ),
              }
            : region;
        }),
      };

    case getType(configActions.updateCarrierProductFilterRulesRequest):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(region => {
          const { model } = action.payload;
          return region.id === model.regionId
            ? {
                ...region,
                carrierProducts: region.carrierProducts.map(product =>
                  equals(product.shippingMethod, model.method) &&
                  equals(model.deliveryTypes, product.deliveryTypes)
                    ? {
                        ...product,
                        shippingMethod: model.method,
                        filterRules: model.filterRules,
                      }
                    : product
                ),
              }
            : region;
        }),
      };

    case getType(configActions.createCarrierProductSuccess):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(region => {
          const { model } = action.payload;
          return region.id === model.regionId
            ? {
                ...region,
                carrierProducts: [
                  ...region.carrierProducts,
                  {
                    shippingMethod: model.method,
                    externalMethodId: model.externalMethodId,
                    deliveryTypes: model.deliveryTypes,
                    priceRules: model.priceRules,
                    deliveryTypesText: model.deliveryTypes,
                  },
                ],
              }
            : region;
        }),
      };

    case getType(configActions.deleteCarrierProductRequest):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(region => {
          const { model } = action.payload;
          return region.id === model.regionId
            ? {
                ...region,
                carrierProducts: region.carrierProducts.filter(
                  product =>
                    !equals(product.deliveryTypes, model.deliveryTypes) ||
                    !equals(product.shippingMethod, model.method)
                ),
              }
            : region;
        }),
      };

    case getType(configActions.updateRegionDetailRequest):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(stateRegion => {
          const { model } = action.payload;
          if (stateRegion.id === model.regionId) {
            return {
              ...stateRegion,
              name: model.name,
              regionType: model.regionType,
              regionCountryConfig: model.countryConfig,
              regionPostalCodeConfig: model.regionPostalCodeConfig,
              state: model.state,
              warehouseId: model.warehouseId,
              features: model.features,
            };
          } else {
            return stateRegion;
          }
        }),
      };

    case getType(configActions.deleteDraftSuccess):
      return filterAndJoinObjectEntries(state, action.payload);

    default:
      return state;
  }
};

const warehouseBySiteId: Reducer<ConfigState['warehousesBySiteId']['current']> = (
  state = {},
  action
) => {
  switch (action.type) {
    case getType(sitesActions.getSitesSuccess):
      return action.payload.reduce<ConfigState['warehousesBySiteId']['current']>(
        (result, item) => ((result[item.id] = item.warehouses), result),
        {}
      );

    case getType(configActions.createDraftSuccess):
      return { ...state, [action.payload.siteId]: state[action.payload.master.siteId] };

    case getType(configActions.deleteDraftSuccess):
      return filterAndJoinObjectEntries(state, action.payload);

    case getType(configActions.updateWarehouseDetailsRequest): {
      const { siteId, model } = action.payload;
      return {
        ...state,
        [siteId]: state[siteId].map(warehouse => {
          if (warehouse.id !== model.warehouseId) {
            return warehouse;
          }
          return {
            ...warehouse,
            address: model.address,
            state: model.state,
            timezone: model.timezone,
          };
        }),
      };
    }
    case getType(configActions.updateCutoffTimesRequest): {
      const { siteId, model } = action.payload;
      return {
        ...state,
        [siteId]: state[siteId].map(warehouse => {
          return warehouse.id === model.warehouseId
            ? {
                ...warehouse,
                operatingSchedule: {
                  ...warehouse.operatingSchedule,
                  [model.method]: {
                    cutoffTimes: model.cutoffTimes,
                    carrierCutoff: model.carrierCutoff,
                  },
                },
              }
            : warehouse;
        }),
      };
    }
    case getType(configActions.updateCarrierCutoffRequest): {
      const { siteId, model } = action.payload;
      return {
        ...state,
        [siteId]: state[siteId].map(warehouse => {
          return warehouse.id === model.warehouseId
            ? {
                ...warehouse,
                operatingSchedule: {
                  ...warehouse.operatingSchedule,
                  [model.method]: {
                    ...warehouse.operatingSchedule?.[model.method],
                    carrierCutoff: model.carrierCutoff,
                  },
                },
              }
            : warehouse;
        }),
      };
    }
    case getType(configActions.createWarehouseSuccess): {
      return {
        ...state,
        [action.payload.siteId]: [
          ...state[action.payload.siteId],
          {
            ...action.payload.model,
            createdAt: new Date(),
          },
        ],
      };
    }

    case getType(configActions.deleteWarehouseRequest): {
      const newState: ConfigState['warehousesBySiteId']['current'] = {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].filter(
          warehouse => warehouse.id !== action.payload.warehouseId
        ),
      };
      return newState;
    }

    case getType(configActions.createWarehouseCutoffTimesRequest): {
      const { siteId, model } = action.payload;
      return {
        ...state,
        [siteId]: state[siteId].map(warehouse => {
          return warehouse.id === model.warehouseId
            ? {
                ...warehouse,
                operatingSchedule: {
                  ...warehouse.operatingSchedule,
                  [model.method]: { cutoffTimes: model.cutoffTimes },
                },
              }
            : warehouse;
        }),
      };
    }

    case getType(configActions.deleteWarehouseCutoffTimesRequest): {
      const { siteId, model } = action.payload;
      const newState: ConfigState['warehousesBySiteId']['current'] = {
        ...state,
        [siteId]: state[siteId].map(warehouse => {
          if (warehouse.id === model.warehouseId) {
            const { cutoffTimes, ...scheduleWithNoCutoffTimes } =
              warehouse.operatingSchedule![model.method]!;
            return {
              ...warehouse,
              operatingSchedule: {
                ...warehouse.operatingSchedule,
                [model.method]: scheduleWithNoCutoffTimes,
              },
            };
          }
          return warehouse;
        }),
      };

      return newState;
    }

    case getType(configActions.createOrUpdateCutoffTimesRequest): {
      const { siteId, models, warehouseId } = action.payload;
      return {
        ...state,
        [siteId]: state[siteId].map(warehouse => {
          return warehouse.id === warehouseId
            ? {
                ...warehouse,
                operatingSchedule: {
                  ...warehouse.operatingSchedule,
                  ...fromPairs(
                    models.map(({ model }) => [
                      model.method,
                      { cutoffTimes: model.cutoffTimes, carrierCutoff: false },
                    ])
                  ),
                },
              }
            : warehouse;
        }),
      };
    }

    case getType(configActions.setWarehouseShippingDateAdjustmentRequest): {
      const { siteId, model, warehouseId } = action.payload;
      return {
        ...state,
        [siteId]: state[siteId].map(warehouse => {
          return warehouse.id === warehouseId
            ? {
                ...warehouse,
                shippingDateAdjustment: model,
              }
            : warehouse;
        }),
      };
    }

    default:
      return state;
  }
};

const shippingCategoryBySiteId: Reducer<ConfigState['shippingCategoryBySiteId']['current']> = (
  state = {},
  action
) => {
  switch (action.type) {
    case getType(sitesActions.getSitesSuccess):
      return action.payload.reduce<ConfigState['shippingCategoryBySiteId']['current']>(
        (result, item) => ((result[item.id] = item.shippingCategories), result),
        {}
      );

    case getType(configActions.createDraftSuccess):
      return { ...state, [action.payload.siteId]: state[action.payload.master.siteId] };

    case getType(configActions.deleteDraftSuccess):
      return filterAndJoinObjectEntries(state, action.payload);

    case getType(configActions.updateCategoryFilterRulesRequest):
    case getType(configActions.updateCategoryDetailsRequest): {
      const { model } = action.payload;
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(category => {
          return category.id === model.categoryId
            ? mergeWith({}, category, model, (_, sourceVal, key) => {
                if (key === 'shippingMethods') {
                  return [...sourceVal];
                }
                return undefined;
              })
            : category;
        }),
      };
    }

    case getType(configActions.updateCountrySettingsRequest):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(category => {
          const { model } = action.payload;

          const match = model.countrySettings.find(set => set.categoryId === category.id);

          if (match) {
            return {
              ...category,
              countrySettings: {
                ...category.countrySettings,
                [model.country]: {
                  ...(!!category.countrySettings ? category.countrySettings![model.country] : {}),
                  preselectionOrder: match.preselectionOrder,
                  useForFallback: match.useForFallback,
                },
              },
            };
          }
          return category;
        }),
      };

    case getType(configActions.updateCategoryTranslationsRequest):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(category => {
          const { model } = action.payload;
          return category.id === model.categoryId
            ? {
                ...category,
                localization: {
                  ...category.localization,
                  [model.locale]: {
                    name: model.name,
                    customText: model.customText,
                    customInfoText: model.customInfoText,
                  },
                },
              }
            : category;
        }),
      };

    case getType(configActions.deleteCategoryTranslationsRequest):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(category => {
          const { model } = action.payload;
          return category.id === model.categoryId
            ? { ...category, localization: dissoc(model.locale, category.localization) }
            : category;
        }),
      };

    case getType(configActions.addCategoryTagsRequest):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(category => {
          const { model } = action.payload;
          return category.id === model.categoryId
            ? { ...category, tags: [...category.tags, { name: model.tagName }] }
            : category;
        }),
      };

    case getType(configActions.removeCategoryTagsRequest):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(category => {
          const { model } = action.payload;
          return category.id === model.categoryId
            ? { ...category, tags: category.tags.filter(tag => tag.name !== model.tagName) }
            : category;
        }),
      };

    case getType(configActions.createCategorySuccess): {
      return {
        ...state,
        [action.payload.siteId]: [
          ...state[action.payload.siteId],
          {
            ...action.payload.model,
            requirements: CategoryRequirementsModel.createFromDTO(
              CategoryRequirementsDTO.createFromModel(action.payload.model.requirements)
            ),
            deliveryTimeProperties: DeliveryTimePropertiesModel.createFromDTO(),
            tags: [],
          },
        ],
      };
    }

    case getType(configActions.createCategoryLabelSuccess):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(category => {
          const { model } = action.payload;
          return category.id === model.categoryId
            ? {
                ...category,
                labels: [
                  ...(category.labels || []),
                  { id: model.id, name: model.name, type: model.type, state: model.state },
                ],
              }
            : category;
        }),
      };

    case getType(configActions.deleteCategoryRequest): {
      const newState: ConfigState['shippingCategoryBySiteId']['current'] = {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].filter(
          category => category.id !== action.payload.categoryId
        ),
      };
      return newState;
    }

    case getType(configActions.updateCategoryLabelDetailsRequest): {
      const { siteId, model } = action.payload;
      const newState: ConfigState['shippingCategoryBySiteId']['current'] = {
        ...state,
        [siteId]: state[siteId].map(category =>
          category.id === model.categoryId
            ? {
                ...category,
                labels: category.labels?.map(label =>
                  label.id === model.labelId
                    ? {
                        ...label,
                        ...model,
                      }
                    : label
                ),
              }
            : category
        ),
      };
      return newState;
    }

    case getType(configActions.updateCategoryLabelTranslationsRequest): {
      const { siteId, model } = action.payload;
      const newState: ConfigState['shippingCategoryBySiteId']['current'] = {
        ...state,
        [siteId]: state[siteId].map(category =>
          category.id === model.categoryId
            ? {
                ...category,
                labels: category.labels?.map(label =>
                  label.id === model.labelId
                    ? {
                        ...label,
                        localization: {
                          ...label.localization,
                          [model.locale]: {
                            name:
                              model.name !== undefined
                                ? model.name
                                : label.localization?.[model.locale]?.name,
                            description:
                              model.description !== undefined
                                ? model.description
                                : label.localization?.[model.locale]?.description,
                          },
                        },
                      }
                    : label
                ),
              }
            : category
        ),
      };
      return newState;
    }

    case getType(configActions.deleteCategoryLabelTranslationsRequest): {
      const { siteId, model } = action.payload;
      const newState: ConfigState['shippingCategoryBySiteId']['current'] = {
        ...state,
        [siteId]: state[siteId].map(category =>
          category.id === model.categoryId
            ? {
                ...category,
                labels: category.labels?.map(label =>
                  label.id === model.labelId
                    ? {
                        ...label,
                        localization: dissoc(model.locale, label.localization),
                      }
                    : label
                ),
              }
            : category
        ),
      };
      return newState;
    }

    case getType(configActions.changeCategoryLabelsOrderRequest): {
      const newState: ConfigState['shippingCategoryBySiteId']['current'] = {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(category => {
          return category.id === action.payload.model.categoryId
            ? {
                ...category,
                labels: action.payload.model.labelsIds.map(
                  id => (category.labels || []).find(label => label.id === id)!
                ),
              }
            : category;
        }),
      };
      return newState;
    }

    case getType(configActions.deleteCategoryLabelRequest):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(category => {
          const { model } = action.payload;
          return category.id === model.categoryId
            ? {
                ...category,
                labels: (category.labels || []).filter(label => label.id !== model.labelId),
              }
            : category;
        }),
      };

    case getType(configActions.createDeliveryAddonSuccess):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(category => {
          const { model } = action.payload;
          return category.id === model.categoryId
            ? {
                ...category,
                deliveryAddons: [
                  ...(category.deliveryAddons || []),
                  {
                    id: model.addonId,
                    name: model.name,
                    type: model.type,
                    externalId: model.externalId,
                    price: { value: model.price.value, vatRate: model.price.vatRate },
                    defaults: {
                      selected: false,
                    },
                    state: model.state,
                  },
                ],
              }
            : category;
        }),
      };

    case getType(configActions.updateDeliveryAddonRequest): {
      const { siteId, model } = action.payload;
      const newState: ConfigState['shippingCategoryBySiteId']['current'] = {
        ...state,
        [siteId]: state[siteId].map(category =>
          category.id === model.categoryId
            ? {
                ...category,
                deliveryAddons: category.deliveryAddons?.map(addon =>
                  addon.id === model.addonId
                    ? {
                        ...addon,
                        ...model,
                      }
                    : addon
                ),
              }
            : category
        ),
      };
      return newState;
    }

    case getType(configActions.deleteDeliveryAddonRequest):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(category => {
          const { model } = action.payload;
          return category.id === model.categoryId
            ? {
                ...category,
                deliveryAddons: (category.deliveryAddons || []).filter(
                  addon => addon.id !== model.addonId
                ),
              }
            : category;
        }),
      };

    case getType(configActions.adjustDeliveryAddonsOrderRequest): {
      const newState: ConfigState['shippingCategoryBySiteId']['current'] = {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(category => {
          return category.id === action.payload.model.categoryId
            ? {
                ...category,
                deliveryAddons: action.payload.model.addonIds.map(
                  addonId => (category.deliveryAddons || []).find(addon => addon.id === addonId)!
                ),
              }
            : category;
        }),
      };
      return newState;
    }

    case getType(configActions.upsertDeliveryAddonTranslationRequest): {
      const { siteId, model } = action.payload;
      const newState: ConfigState['shippingCategoryBySiteId']['current'] = {
        ...state,
        [siteId]: state[siteId].map(category =>
          category.id === model.categoryId
            ? {
                ...category,
                deliveryAddons: category.deliveryAddons?.map(addon =>
                  addon.id === model.addonId
                    ? {
                        ...addon,
                        localization: {
                          ...addon.localization,
                          [model.locale]: {
                            name:
                              model.name !== undefined
                                ? model.name
                                : addon.localization?.[model.locale]?.name,
                            description:
                              model.description !== undefined
                                ? model.description
                                : addon.localization?.[model.locale]?.description,
                          },
                        },
                      }
                    : addon
                ),
              }
            : category
        ),
      };
      return newState;
    }

    case getType(configActions.deleteDeliveryAddonTranslationRequest): {
      const { siteId, model } = action.payload;
      const newState: ConfigState['shippingCategoryBySiteId']['current'] = {
        ...state,
        [siteId]: state[siteId].map(category =>
          category.id === model.categoryId
            ? {
                ...category,
                deliveryAddons: category.deliveryAddons?.map(addon =>
                  addon.id === model.addonId
                    ? {
                        ...addon,
                        localization: dissoc(model.locale, addon.localization),
                      }
                    : addon
                ),
              }
            : category
        ),
      };
      return newState;
    }

    default:
      return state;
  }
};

const isFetching: Reducer<ConfigState['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(sitesActions.getSitesRequest):
    case getType(configActions.deleteDraftRequest):
      return true;

    case getType(configActions.deleteDraftSuccess):
    case getType(configActions.deleteDraftError):
    case getType(sitesActions.getSitesSuccess):
    case getType(sitesActions.getSitesError):
      return false;

    default:
      return state;
  }
};

const isSendingChanges: Reducer<ConfigState['isSendingChanges']> = (state = false, action) => {
  switch (action.type) {
    case getType(configActions.genericConfigModificationRequest):
    case getType(configActions.deleteCategoryLabelRequest):
    case getType(configActions.createDraftRequest):
    case getType(configActions.updateCarrierProductRequest):
    case getType(configActions.deleteDraftRequest):
    case getType(configActions.promoteDraftRequest):
    case getType(configActions.updateCategoryDetailsRequest):
    case getType(configActions.addCategoryTagsRequest):
    case getType(configActions.removeCategoryTagsRequest):
    case getType(configActions.updateRegionDetailRequest):
    case getType(configActions.updateCategoryTranslationsRequest):
    case getType(configActions.updateWarehouseDetailsRequest):
    case getType(configActions.updateCutoffTimesRequest):
    case getType(configActions.updateCarrierCutoffRequest):
    case getType(configActions.deleteCategoryTranslationsRequest):
    case getType(configActions.createCategoryRequest):
    case getType(configActions.createCategoryLabelRequest):
    case getType(configActions.getDraftSiteRequest):
    case getType(configActions.createWarehouseRequest):
    case getType(configActions.createRegionRequest):
    case getType(configActions.deleteRegionRequest):
    case getType(configActions.deleteWarehouseRequest):
    case getType(configActions.updateWidgetConfigurationRequest):
    case getType(configActions.setWarehouseShippingDateAdjustmentRequest):
    case getType(configActions.updateCountrySettingsRequest):
    case getType(configActions.createWidgetCountryConfigurationRequest):
    case getType(configActions.updateWidgetCountryConfigurationRequest):
    case getType(configActions.updateCategoryLabelDetailsRequest):
    case getType(configActions.updateCategoryLabelTranslationsRequest):
    case getType(configActions.deleteCategoryLabelTranslationsRequest):
    case getType(configActions.changeCategoryLabelsOrderRequest):
    case getType(configActions.deleteWarehouseCutoffTimesRequest):
    case getType(configActions.deleteCategoryRequest):
    case getType(configActions.createCarrierProductRequest):
    case getType(configActions.deleteCarrierProductRequest):
    case getType(configActions.createDeliveryAddonRequest):
    case getType(configActions.updateDeliveryAddonRequest):
    case getType(configActions.deleteDeliveryAddonRequest):
    case getType(configActions.adjustDeliveryAddonsOrderRequest):
    case getType(configActions.upsertDeliveryAddonTranslationRequest):
    case getType(configActions.deleteDeliveryAddonTranslationRequest):
    case getType(trackingWidgetActions.createCustomEventRequest):
    case getType(trackingWidgetActions.updateCustomEventRequest):
    case getType(trackingWidgetActions.deleteCustomEventRequest):
    case getType(trackingWidgetActions.updateCustomEventTranslationRequest):
    case getType(trackingWidgetActions.deleteCustomEventTranslationRequest):
    case getType(trackingWidgetActions.updateInternalEventRequest):
      return true;

    case getType(configActions.genericConfigModificationSuccess):
    case getType(configActions.genericConfigModificationError):
    case getType(configActions.deleteCategoryLabelSuccess):
    case getType(configActions.deleteCategoryLabelError):
    case getType(configActions.createCategoryLabelSuccess):
    case getType(configActions.createCategoryLabelError):
    case getType(configActions.removeCategoryTagsSuccess):
    case getType(configActions.removeCategoryTagsError):
    case getType(configActions.addCategoryTagsSuccess):
    case getType(configActions.addCategoryTagsError):
    case getType(configActions.updateCategoryTranslationsSuccess):
    case getType(configActions.updateCategoryTranslationsError):
    case getType(configActions.updateRegionDetailError):
    case getType(configActions.updateRegionDetailSuccess):
    case getType(configActions.updateCategoryDetailsSuccess):
    case getType(configActions.updateCategoryDetailsError):
    case getType(configActions.promoteDraftError):
    case getType(configActions.promoteDraftSuccess):
    case getType(configActions.deleteDraftSuccess):
    case getType(configActions.deleteDraftError):
    case getType(configActions.createDraftError):
    case getType(configActions.createDraftSuccess):
    case getType(configActions.updateCarrierProductSuccess):
    case getType(configActions.updateCarrierProductError):
    case getType(configActions.updateWarehouseDetailsSuccess):
    case getType(configActions.updateWarehouseDetailsError):
    case getType(configActions.updateCutoffTimesSuccess):
    case getType(configActions.updateCutoffTimesError):
    case getType(configActions.updateCarrierCutoffSuccess):
    case getType(configActions.updateCarrierCutoffError):
    case getType(configActions.deleteCategoryTranslationsSuccess):
    case getType(configActions.deleteCategoryTranslationsError):
    case getType(configActions.createCategoryError):
    case getType(configActions.createCategorySuccess):
    case getType(configActions.getDraftSiteError):
    case getType(configActions.getDraftSiteSuccess):
    case getType(configActions.createWarehouseSuccess):
    case getType(configActions.createWarehouseError):
    case getType(configActions.deleteWarehouseSuccess):
    case getType(configActions.deleteWarehouseError):
    case getType(configActions.deleteRegionSuccess):
    case getType(configActions.deleteRegionError):
    case getType(configActions.createRegionSuccess):
    case getType(configActions.createRegionError):
    case getType(configActions.updateWidgetConfigurationSuccess):
    case getType(configActions.updateWidgetConfigurationError):
    case getType(configActions.setWarehouseShippingDateAdjustmentSuccess):
    case getType(configActions.setWarehouseShippingDateAdjustmentError):
    case getType(configActions.updateCountrySettingsSuccess):
    case getType(configActions.updateCountrySettingsError):
    case getType(configActions.createWidgetCountryConfigurationSuccess):
    case getType(configActions.createWidgetCountryConfigurationError):
    case getType(configActions.updateWidgetCountryConfigurationSuccess):
    case getType(configActions.updateWidgetCountryConfigurationError):
    case getType(configActions.updateCategoryLabelDetailsSuccess):
    case getType(configActions.updateCategoryLabelDetailsError):
    case getType(configActions.updateCategoryLabelTranslationsSuccess):
    case getType(configActions.updateCategoryLabelTranslationsError):
    case getType(configActions.deleteCategoryLabelTranslationsSuccess):
    case getType(configActions.deleteCategoryLabelTranslationsError):
    case getType(configActions.changeCategoryLabelsOrderSuccess):
    case getType(configActions.changeCategoryLabelsOrderError):
    case getType(configActions.deleteWarehouseCutoffTimesError):
    case getType(configActions.deleteWarehouseCutoffTimesSuccess):
    case getType(configActions.deleteCategoryError):
    case getType(configActions.deleteCategorySuccess):
    case getType(configActions.createCarrierProductError):
    case getType(configActions.createCarrierProductSuccess):
    case getType(configActions.deleteCarrierProductError):
    case getType(configActions.deleteCarrierProductSuccess):
    case getType(configActions.createDeliveryAddonSuccess):
    case getType(configActions.createDeliveryAddonError):
    case getType(configActions.updateDeliveryAddonSuccess):
    case getType(configActions.updateDeliveryAddonError):
    case getType(configActions.deleteDeliveryAddonSuccess):
    case getType(configActions.deleteDeliveryAddonError):
    case getType(configActions.adjustDeliveryAddonsOrderSuccess):
    case getType(configActions.adjustDeliveryAddonsOrderError):
    case getType(configActions.upsertDeliveryAddonTranslationSuccess):
    case getType(configActions.upsertDeliveryAddonTranslationError):
    case getType(configActions.deleteDeliveryAddonTranslationSuccess):
    case getType(configActions.deleteDeliveryAddonTranslationError):
    case getType(trackingWidgetActions.createCustomEventError):
    case getType(trackingWidgetActions.createCustomEventSuccess):
    case getType(trackingWidgetActions.updateCustomEventError):
    case getType(trackingWidgetActions.updateCustomEventSuccess):
    case getType(trackingWidgetActions.updateCustomEventTranslationError):
    case getType(trackingWidgetActions.updateCustomEventTranslationSuccess):
    case getType(trackingWidgetActions.deleteCustomEventError):
    case getType(trackingWidgetActions.deleteCustomEventSuccess):
    case getType(trackingWidgetActions.deleteCustomEventTranslationError):
    case getType(trackingWidgetActions.deleteCustomEventTranslationSuccess):
    case getType(trackingWidgetActions.updateInternalEventError):
    case getType(trackingWidgetActions.updateInternalEventSuccess):
      return false;

    default:
      return state;
  }
};

const changes: Reducer<ConfigState['changes']> = (state = [], action) => {
  switch (action.type) {
    case getType(configActions.getChangesListSuccess):
      return action.payload.changes;

    case getType(configActions.deleteDraftSuccess):
      return [];
    default:
      return state;
  }
};

const error: Reducer<ConfigState['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(configActions.createDraftError):
    case getType(configActions.promoteDraftError):
    case getType(configActions.getPrivateKeyError):
      return action.payload;

    case getType(configActions.createDraftRequest):
    case getType(configActions.createDraftSuccess):
    case getType(sitesActions.getSitesRequest):
    case getType(configActions.promoteDraftSuccess):
    case getType(configActions.promoteDraftRequest):
    case getType(configActions.deleteDraftSuccess):
    case getType(configActions.getPrivateKeyRequest):
    case getType(configActions.getPrivateKeySuccess):
      return null;
    default:
      return state;
  }
};

const isSynced: Reducer<ConfigState['isSynced']> = (state = true, action) => {
  switch (action.type) {
    case getType(configActions.getDraftSiteSuccess):
      return action.payload.isSynced;
    case getType(configActions.deleteDraftSuccess):
    case getType(configActions.createDraftRequest):
      return true;
    default:
      return state;
  }
};

const isCreatingDraft: Reducer<ConfigState['isCreatingDraft']> = (state = false, action) => {
  switch (action.type) {
    case getType(configActions.createDraftRequest):
      return true;
    case getType(configActions.createDraftError):
    case getType(configActions.createDraftSuccess):
      return false;

    default:
      return state;
  }
};

const copyCutoffsProgress: Reducer<ConfigState['copyCutoffsProgress']> = (
  state = { isCopying: false, progress: 0 },
  action
) => {
  switch (action.type) {
    case getType(configActions.createOrUpdateCutoffTimesRequest):
      return {
        isCopying: true,
        progress: 0,
      };
    case getType(configActions.createOrUpdateCutoffTimesError):
      return {
        isCopying: false,
        progress: 0,
      };
    case getType(configActions.createOrUpdateCutoffTimesSuccess):
      return {
        isCopying: false,
        progress: 1,
      };
    case getType(configActions.createOrUpdateCutoffTimesPartialSuccess):
      return {
        isCopying: true,
        progress: action.payload,
      };
    default:
      return state;
  }
};

const shippingMethodsBySiteId: Reducer<ConfigState['shippingMethodsBySiteId']> = (
  state = {},
  action
) => {
  switch (action.type) {
    case getType(configActions.listShippingMethodSuccess):
      return {
        ...state,
        ...action.payload,
      };

    case getType(configActions.createCarrierProductSuccess):
      return {
        ...state,
        [action.payload.siteId]: state[action.payload.siteId].map(method => {
          const { model } = action.payload;
          return method.id === model.method
            ? {
                ...method,
                shippingConfigStatus: model.status,
              }
            : method;
        }),
      };

    case getType(configActions.createDraftSuccess):
      return { ...state, [action.payload.siteId]: state[action.payload.master.siteId] };

    default:
      return state;
  }
};

const privateKey: Reducer<ConfigState['privateKey']> = (state = null, action) => {
  switch (action.type) {
    case getType(configActions.getPrivateKeySuccess):
      return action.payload.privateKey;
    case getType(configActions.getPrivateKeyRequest):
    case getType(configActions.getPrivateKeyError):
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  regionsBySiteId: optimistic(regionsBySiteId),
  shippingCategoryBySiteId: optimistic(shippingCategoryBySiteId),
  customBookingMethodsBySiteId,
  customShippingMethodsBySiteId,
  warehousesBySiteId: optimistic(warehouseBySiteId),
  isFetching,
  isSendingChanges,
  changes,
  error,
  isSynced,
  isCreatingDraft,
  widgetConfigurationBySiteId: optimistic(widgetConfigurationBySiteId),
  copyCutoffsProgress,
  shippingMethodsBySiteId,
  privateKey,
  variantSiteId,
});
