import { emptyFormValues } from './constants';
import type { FormValues, GeneralCustomsDeclaration } from './types';

export const mapToFormValues = (
  customsDeclaration: GeneralCustomsDeclaration | undefined
): FormValues => {
  if (customsDeclaration) {
    return {
      address_lines: customsDeclaration.sold_to?.address_lines?.join(', '),
      city: customsDeclaration.sold_to?.city,
      country: customsDeclaration.sold_to?.country,
      region: customsDeclaration?.sold_to?.region,
      postal_code: customsDeclaration.sold_to?.postal_code,
      payers_name: customsDeclaration?.sold_to?.name,
      contents_type: customsDeclaration.contents_type,
      contents_explanation: customsDeclaration.contents_explanation,
      total_gross_weight: customsDeclaration.total_gross_weight,
      incoterms: customsDeclaration.incoterms,
      currency: customsDeclaration.currency,
      invoice_number: customsDeclaration.invoice_number,
      invoice_date: customsDeclaration.invoice_date,
      seller_tax_id_number: customsDeclaration.seller_tax_id_number,
      buyer_tax_id_number: customsDeclaration.buyer_tax_id_number,
      items: customsDeclaration.items,
    };
  }

  return emptyFormValues;
};

export const mapToCustomsDeclaration = (formValues: FormValues): GeneralCustomsDeclaration => {
  const { address_lines, city, country, postal_code, items, payers_name, region, ...rest } =
    formValues;

  const filteredItems = items?.filter(item => {
    const values = Object.values(item);
    return values.some(value => value);
  });

  return {
    ...rest,
    items: filteredItems,
    sold_to: {
      name: payers_name,
      address_lines: address_lines ? [address_lines] : undefined,
      city,
      region,
      country,
      postal_code,
    },
  };
};

export const getAddress = (customsDeclaration: GeneralCustomsDeclaration | undefined) => {
  const city = customsDeclaration?.sold_to?.city;
  const postalCode = customsDeclaration?.sold_to?.postal_code;
  const country = customsDeclaration?.sold_to?.country;
  const region = customsDeclaration?.sold_to?.region;

  return [city, postalCode, region, country].filter(value => value).join(', ');
};
