import { createSelector } from 'reselect';
import { Option } from 'space-lift';
import { ConfigSiteModel } from '../../models';
import { RootState } from '../root-reducer';

const getState = (state: RootState) => state.sites;

export const getSelectedSiteIdOrEmpty = createSelector(
  getState,
  state => state.selectedSiteId || ''
);

export const getMasterSite = createSelector(getSelectedSiteIdOrEmpty, getState, (siteId, state) => {
  return state.sites.find(site => {
    return site.id === siteId;
  });
});

export const getMasterSiteIdOrEmpty = createSelector(getMasterSite, masterSite => {
  return masterSite ? masterSite.id : '';
});

export const getMasterSiteNameOrEmpty = createSelector(getMasterSite, masterSite => {
  return masterSite ? masterSite.name : '';
});

const getDraftSite = createSelector(getSelectedSiteIdOrEmpty, getState, (siteId, state) => {
  return state.sites.find(site => {
    if (site.metadata && site.metadata.draft) {
      return site.metadata.draft.masterSiteId === siteId;
    }
    return false;
  });
});

export const getDraftSiteIdOrEmpty = createSelector(getDraftSite, draftSite => {
  return draftSite ? draftSite.id : '';
});

export const getDraftSitePrivateKeyOrEmpty = createSelector(getDraftSite, draftSite => {
  return draftSite ? draftSite.privateKey : '';
});

export const getDraftSiteVersionOrEmpty = createSelector(getDraftSite, draftSite => {
  return draftSite ? draftSite.version : 0;
});

export const getDraftSiteIdOrMasterSiteId = createSelector(
  getDraftSiteIdOrEmpty,
  getMasterSiteIdOrEmpty,
  (draftSiteId, masterSiteId) => draftSiteId || masterSiteId
);

export const draftSiteExists = createSelector(getDraftSiteIdOrEmpty, draftSiteId => !!draftSiteId);

export const getSelectedSiteOrUndefined = createSelector(getState, state =>
  state.sites.find(item => item.id === state.selectedSiteId)
);

export const getDraftSiteOrSelectedSite = createSelector(
  getDraftSiteIdOrEmpty,
  getSelectedSiteIdOrEmpty,
  getState,
  (draftSiteId, selectedSiteId, state) =>
    state.sites.find(site => site.id === draftSiteId) ||
    state.sites.find(site => site.id === selectedSiteId)
);

export const getSelectedSitePrivateKey = createSelector(
  getSelectedSiteOrUndefined,
  // syntax to avoid modules conflicts
  (state: RootState) => state.auth.authUser?.superUser,
  (state: RootState) => state.config.privateKey,
  (selectedSiteOrUndefined, isUserSuperUser, privateKeyFromConfig) => {
    if (!!isUserSuperUser) {
      return selectedSiteOrUndefined && selectedSiteOrUndefined.privateKey;
    }
    return privateKeyFromConfig;
  }
);

export const getSelectedSiteName = createSelector(
  getSelectedSiteOrUndefined,
  selectedSite => Option(selectedSite).getOrElse(new ConfigSiteModel()).name
);

export const getIsFetchingSites = createSelector(getState, state => state.isFetching);

export const getSelectedSiteBookingEnabled = createSelector(
  getSelectedSiteOrUndefined,
  selectedSite => !!selectedSite?.bookingEnabled
);
