import { Col, Row } from 'antd';
import * as React from 'react';

import { Button } from '@src/controls';
import { LABELS } from '@src/dictionaries';

export const SubmitButtonWithLayout: React.FunctionComponent = () => (
  <Row>
    <Col span={6} />
    <Col span={12}>
      <Button htmlType="submit" type="primary">
        {LABELS.SUBMIT}
      </Button>
    </Col>
  </Row>
);
