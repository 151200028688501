import * as React from 'react';
import { stylesheet } from 'typestyle';

import { isEmpty } from 'lodash';
import { useSelector } from '@src/utils/hooks';

import { Modal } from '@src/controls';
import { LinkStyleButton } from '@src/components';
import { FormattedMessage } from '@src/i18n';
import { configSelectors } from '@src/modules/config';
import { CountryList } from './components';
import { CountryDetails } from './country-page';
import { DefaultSettings } from './default-settings-page';

export const CountryListEmbedded: React.FC<{ countryToFilterBy: string | undefined }> = ({
  countryToFilterBy,
}) => {
  const countries = useSelector(state =>
    configSelectors.getCountryCountForSpecificCountry(state, countryToFilterBy)
  );
  const isFetching = useSelector(state => state.sites.isFetching);

  /* true - means default settings */
  const [selectedCountry, setSelectedCountry] = React.useState<string | undefined>(undefined);

  const getDetails = () => {
    if (selectedCountry === 'default') {
      return <DefaultSettings />;
    } else if (selectedCountry !== undefined) {
      return <CountryDetails country={selectedCountry} />;
    }

    return null;
  };

  return (
    <>
      <LinkStyleButton
        onClick={() => setSelectedCountry('default')}
        className={styles.defaultSettingsLink}
      >
        <FormattedMessage id="DEFAULT_SETTINGS" />
      </LinkStyleButton>
      {!isEmpty(countries) && (
        <>
          <FormattedMessage className={styles.countriesSettingsHeader} id="COUNTRY_SETTINGS" />
          <CountryList
            isFetching={isFetching}
            countries={countries}
            renderLink={({ countryName }) => (
              <LinkStyleButton onClick={() => setSelectedCountry(countryName)}>
                {countryName}
              </LinkStyleButton>
            )}
          />
        </>
      )}
      <Modal
        visible={selectedCountry !== undefined}
        onCancel={() => setSelectedCountry(undefined)}
        footer={null}
        destroyOnClose={true}
        width="900px"
      >
        {getDetails()}
      </Modal>
    </>
  );
};

const styles = stylesheet({
  defaultSettingsLink: {
    padding: '22px 0 0 22px',
    display: 'block',
  },
  countriesSettingsHeader: {
    margin: '36px 0 14px',
    display: 'block',
    color: 'rgba(0,0,0,0.85)',
  },
});
