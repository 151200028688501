import { FieldState, FormState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Input } from '@src/controls';
import { withFieldStateInput, withFormItem } from '../../../decorators';

const InputField = withFormItem(withFieldStateInput(Input));

export class InvoiceNumberFormState extends FormState<{
  invoiceNumberFieldState: FieldState<string | undefined>;
}> {
  static create = ({
    invoiceNumber,
  }: {
    invoiceNumber?: string;
  } = {}): InvoiceNumberFormState =>
    new FormState({
      invoiceNumberFieldState: new FieldState(invoiceNumber),
    });
}

interface OwnProps {
  formState: InvoiceNumberFormState;
  label: string;
  disabled?: boolean;
}

@observer
export class InvoiceNumber extends React.Component<OwnProps, {}> {
  render() {
    const { formState, label, ...restProps } = this.props;

    return (
      <InputField
        labelCol={{ span: 24 }}
        style={{ width: '200px' }}
        label={label}
        fieldState={formState.$.invoiceNumberFieldState}
        error={formState.$.invoiceNumberFieldState.error}
        {...restProps}
      />
    );
  }
}
