export class DimensionsDTO {
  // Height of the object in millimeters.
  height: number;
  // Length of the object in millimeters.
  length: number;
  // Width of the object in millimeters.
  width: number;

  static createFromModel(model: DimensionsModel): DimensionsDTO {
    return {
      height: model.height,
      length: model.length,
      width: model.width,
    };
  }
}

export class DimensionsModel {
  // Height of the object in millimeters.
  height: number = 0;
  // Length of the object in millimeters.
  length: number = 0;
  // Width of the object in millimeters.
  width: number = 0;

  static createFromDTO(model: DimensionsDTO): DimensionsModel {
    return {
      height: (model.height && model.height) || 0,
      length: (model.length && model.length) || 0,
      width: (model.width && model.width) || 0,
    };
  }
}
