import { isEmpty, isNil } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { style as tss } from 'typestyle';

import { ShipmentsTable } from '@src/components';
import { Button, modalConfirm } from '@src/controls';
import { LABELS } from '@src/dictionaries';
import { ShipmentSummaryModel } from '@src/models';
import { RootState } from '@src/modules';
import { configSelectors } from '@src/modules/config';
import { routerActions } from '@src/modules/router';
import { tagsActions, tagsSelectors } from '@src/modules/tags';
import { arrayUtils } from '@src/utils';
import { ShipmentNotificationsModalContainer, WatchlistModalContainer } from '.';

interface State {
  sortingState: {
    order: 'descend';
    columnKey: string;
  };
  selectedRowKeys: string[];
  isModalVisible: boolean;
  isModalForShipmentMonitorVisible: boolean;
  shipmentForMonitoring: ShipmentSummaryModel | null;
}

const styles = {
  buttonWrapper: tss({
    marginBottom: '10px',
  }),
  button: tss({
    marginRight: '10px',
  }),
};

interface OwnProps {
  tagId: string;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  siteId: state.sites.selectedSiteId,
  shipments: tagsSelectors.getShipmentsByTagId(state, props.tagId),
  isFetching: state.tags.isFetching,
  shipmentIdsToFetch: tagsSelectors.getShipmentIdsByTagId(state, props.tagId),
  shippingMethodsDict: configSelectors.getShippingMethodsDict(state),
});

const mapDispatchToProps = {
  getShipments: tagsActions.getShipmentsByTagIdRequest,
  getShipmentsSuccess: tagsActions.getShipmentsByTagIdSuccess,
  addTagsToShipments: tagsActions.addTagsToShipmentsRequest,
  removeTagFromShipments: tagsActions.removeTagFromShipmentsRequest,
  getShipmentIdsByTags: tagsActions.getShipmentIdsByTagsRequest,
  pushRouterAction: routerActions.push,
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & OwnProps;

class Component extends React.Component<Props, State> {
  state = {
    sortingState: {
      order: 'descend' as State['sortingState']['order'],
      columnKey: 'createdAt',
    },
    selectedRowKeys: [],
    isModalVisible: false,
    shipmentForMonitoring: null,
    isModalForShipmentMonitorVisible: false,
  };

  componentDidMount() {
    const { siteId, getShipments, shipmentIdsToFetch, tagId } = this.props;

    if (siteId && !isEmpty(shipmentIdsToFetch)) {
      getShipments({ siteId, tagId, shipmentIds: shipmentIdsToFetch });
    }
  }

  handleItemClick = (itemId: string) => {
    this.props.pushRouterAction({
      name: 'SHIPMENT_EDIT',
      shipmentId: itemId,
      additionalState: { useCustomBack: true },
    });
  };

  getShippingMethodName = (record: ShipmentSummaryModel) => {
    const { shippingMethodsDict } = this.props;

    return shippingMethodsDict[record.shippingMethod];
  };

  findShipmentForMonitoring = (selectedRowKeys: State['selectedRowKeys']) => {
    if (selectedRowKeys.length === 1) {
      const possibleShipment = this.props.shipments.find(
        shipment => selectedRowKeys[0] === shipment.id
      );
      if (possibleShipment && arrayUtils.hasNoEmptyStrings(possibleShipment.trackingNumbers)) {
        return possibleShipment;
      }
    }
    return null;
  };

  getRowSelection = () => ({
    selectedRowKeys: this.state.selectedRowKeys,
    onChange: (selectedRowKeys: string[]) => {
      this.setState({
        selectedRowKeys,
        shipmentForMonitoring: this.findShipmentForMonitoring(selectedRowKeys),
      });
    },
    hideDefaultSelections: true,
    selections: [
      {
        key: 'current',
        text: 'Select Current Page',
        onSelect: (selected: any) => this.setState({ selectedRowKeys: selected }),
      },
    ],
  });

  handleShareButtonClick = () =>
    this.setState(prevState => ({ isModalVisible: !prevState.isModalVisible }));

  changeModalState = () => {
    this.setState(prevState => ({ isModalVisible: !prevState.isModalVisible }));
  };

  handleMonitorButtonClick = () =>
    this.setState(prevState => ({
      isModalForShipmentMonitorVisible: !prevState.isModalForShipmentMonitorVisible,
    }));

  changeShipmentMonitorModalState = () =>
    this.setState(prevState => ({
      isModalForShipmentMonitorVisible: !prevState.isModalForShipmentMonitorVisible,
    }));

  resetSelectedRowsAndShipment = () =>
    this.setState({ selectedRowKeys: [], shipmentForMonitoring: null });

  onModalOkClick = (selectedTags: string[]) => {
    const { addTagsToShipments, getShipmentIdsByTags, siteId, getShipments } = this.props;
    this.changeModalState();
    if (siteId) {
      addTagsToShipments({
        siteId,
        tagIds: selectedTags,
        shipmentIds: this.state.selectedRowKeys,
        onComplete: () =>
          getShipmentIdsByTags({
            siteId,
            tags: selectedTags,
            onComplete: taggedShipments =>
              selectedTags.map(tagId => {
                const taggedShipment = taggedShipments.find(shipment => shipment.tag.id === tagId);
                if (taggedShipment) {
                  return getShipments({
                    siteId,
                    tagId: taggedShipment.tag.id,
                    shipmentIds: taggedShipment.shipmentIds,
                  });
                }
                return null;
              }),
          }),
      });
    }
  };

  handleRemoveClick = () => {
    modalConfirm({
      title: 'Remove from watchlist',
      content: 'You are about to remove shipments from watchlist. Do you want to continue?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        const {
          removeTagFromShipments,
          getShipments,
          siteId,
          tagId,
          getShipmentIdsByTags,
          getShipmentsSuccess,
        } = this.props;
        if (siteId) {
          removeTagFromShipments({
            siteId,
            tagId,
            shipmentIds: this.state.selectedRowKeys,
            onComplete: () =>
              getShipmentIdsByTags({
                siteId,
                tags: [tagId],
                onComplete: ([taggedShipment]) => {
                  return !isEmpty(taggedShipment.shipmentIds)
                    ? getShipments({
                        siteId,
                        tagId,
                        shipmentIds: taggedShipment.shipmentIds,
                      })
                    : getShipmentsSuccess({
                        shipments: [],
                        tagId,
                      });
                },
              }),
          });
        }
      },
    });
  };

  render() {
    const { isFetching, shipments, shipmentIdsToFetch } = this.props;
    const {
      isModalVisible,
      isModalForShipmentMonitorVisible,
      selectedRowKeys,
      sortingState,
      shipmentForMonitoring,
    } = this.state;
    return (
      <>
        <WatchlistModalContainer
          isVisible={isModalVisible}
          onCancel={this.changeModalState}
          onOk={this.onModalOkClick}
        />
        <ShipmentNotificationsModalContainer
          isVisible={isModalForShipmentMonitorVisible}
          changeModalVisibility={this.changeShipmentMonitorModalState}
          shipmentForMonitoring={shipmentForMonitoring}
          sideEffectOnSubmit={this.resetSelectedRowsAndShipment}
        />
        <div className={styles.buttonWrapper}>
          <Button
            onClick={this.handleShareButtonClick}
            disabled={!(selectedRowKeys.length > 0)}
            className={styles.button}
          >
            {LABELS.SHARE}
          </Button>
          <Button
            className={styles.button}
            onClick={this.handleMonitorButtonClick}
            disabled={isNil(shipmentForMonitoring)}
          >
            {LABELS.MONITOR_SHIPMENT}
          </Button>
          <Button
            type="primary"
            danger
            onClick={this.handleRemoveClick}
            disabled={!(selectedRowKeys.length > 0)}
          >
            {LABELS.REMOVE_FROM_WATCHLIST}
          </Button>
        </div>
        {!isEmpty(shipments) ? (
          <ShipmentsTable
            items={isEmpty(shipmentIdsToFetch) ? [] : shipments}
            onRowClick={this.handleItemClick}
            sortingState={sortingState}
            getShippingMethodName={this.getShippingMethodName}
            rowSelection={this.getRowSelection()}
            isLoading={isFetching}
          />
        ) : (
          'No shipments found on this list'
        )}
      </>
    );
  }
}

export const WatchlistContainer = connect(mapStateToProps, mapDispatchToProps)(Component);
