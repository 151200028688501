import { ShipmentListFiltersModel, ShipmentsListFiltersDTO } from './som-models';

export class CreateShipmentReportModel {
  filtering?: ShipmentListFiltersModel;
  ids?: string[];
}

export class CreateShipmentReportDTO {
  filtering?: ShipmentsListFiltersDTO;
  ids?: string[];

  static createFromModel(model: CreateShipmentReportModel): CreateShipmentReportDTO {
    return {
      filtering: model.filtering ? ShipmentsListFiltersDTO.createFromModel(model.filtering) : undefined,
      ids: model.ids ? model.ids : undefined,
    };
  }
}

export interface CreateOrderReportModel {
  filtering?: OrderListFiltersModel;
}

export class CreateOrderReportDTO {
  filtering?: OrdersListFiltersDTO;

  static createFromModel(model: CreateOrderReportModel): CreateOrderReportDTO {
    return {
      filtering: model.filtering ? OrdersListFiltersDTO.createFromModel(model.filtering) : undefined,
    };
  }
}

export interface OrderListFiltersModel {
  id: string;
  sources?: string[];
  createdAtRange?: { start: string; end: string };
}

export class OrdersListFiltersDTO {
  id?: string;
  sources?: string[];
  created_at_range?: { start: string; end: string };

  static createFromModel(model: OrderListFiltersModel): OrdersListFiltersDTO {
    return {
      id: model.id,
      sources: model.sources,
      created_at_range: model.createdAtRange,
    };
  }
}

export class CreateSpecialReportModel {
  report_type ?: SpecialReportType
  filtering?: ShipmentListFiltersModel;
  shipment_ids?: string[];
  tos_ids?: string[];

  static createFromShipmentModel(reportType: SpecialReportType, model: CreateShipmentReportModel): CreateSpecialReportModel {
    return {
      report_type: reportType,
      filtering: model.filtering ? model.filtering : undefined,
      shipment_ids: model.ids ? model.ids : undefined,
    };
  }
}

export class CreateSpecialReportDTO {
  report_type ?: SpecialReportType
  filtering?: ShipmentListFiltersModel;
  shipment_ids?: string[];
  tos_ids?: string[];

  static createFromModel(model: CreateSpecialReportModel): CreateSpecialReportDTO {
    return {
      report_type: model.report_type ? model.report_type : undefined,
      filtering: model.filtering ? ShipmentsListFiltersDTO.createFromModel(model.filtering) : undefined,
      shipment_ids: model.shipment_ids ? model.shipment_ids : undefined,
      tos_ids: model.tos_ids ? model.tos_ids : undefined,
    };
  }
}

export enum SpecialReportType {
  REPORT_TYPE_UNKNOWN = 'REPORT_TYPE_UNKNOWN',
  BBX_INVOICE_SHIPMENTS = 'BBX_INVOICE_SHIPMENTS',
}

export class CreateReportResponseModel {
  id: string;

  static createFromDTO(dto: CreateReportResponseDTO): CreateReportResponseModel {
    return {
      id: dto.id,
    };
  }
}

export class CreateReportResponseDTO {
  id: string;
}

export class ReportModel {
  id: string;
  status: ReportStatus;
  uri: string;

  static createFromDTO(dto: ReportDTO): ReportModel {
    return {
      id: dto.id,
      status: dto.status,
      uri: dto.uri,
    };
  }
}

export class ReportDTO {
  id: string;
  status: ReportStatus;
  uri: string;
}

export enum ReportStatus {
  UNKNOWN_STATUS = 'UNKNOWN_STATUS',
  PENDING = 'PENDING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}
