export class TokenAuthModel {
  token: string;

  static createFromDTO(dto: TokenAuthDTO): TokenAuthModel {
    return {
      token: dto.token,
    };
  }
}

export class TokenAuthDTO {
  token: string;

  static createFromModel(model: TokenAuthModel): TokenAuthDTO {
    return {
      token: model.token,
    };
  }
}
