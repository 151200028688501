import { getFieldArrayError } from '@src/utils/forms';
import { FormikProps, Field, FieldProps } from 'formik';
import React, { useEffect, useRef } from 'react';
import { RuleAddingValues } from '..';
import { stylesheet } from 'typestyle';
import { withFormItem } from '@src/decorators/with-form-item';
import { Input } from '@src/controls';
import { number } from 'yup';
import { ConditionInPostalCodeRange, getPostalCodesFromString } from '../../helpers';
import { FormattedMessage } from '@src/i18n';

const InputField = withFormItem(Input);

interface InPostalCodeRangeProps {
  name: string;
  errors: FormikProps<RuleAddingValues>['errors'];
  touched: FormikProps<RuleAddingValues>['touched'];
  setFieldValue: FormikProps<RuleAddingValues>['setFieldValue'];
  postalCodesCondition?: ConditionInPostalCodeRange;
}

export const InPostalCodeRange = ({
  name,
  postalCodesCondition,
  errors,
  touched,
  setFieldValue,
}: InPostalCodeRangeProps) => {
  const conditions = useRef<{ from: number | null; to: number | null }>({
    from: null,
    to: null,
  });

  useEffect(() => {
    if (postalCodesCondition?.value) {
      const [from, to] = getPostalCodesFromString(postalCodesCondition.value);
      setFieldValue(`${name}.postalcode.from.value`, from);
      conditions.current.from = from;
      setFieldValue(`${name}.postalcode.to.value`, to);
      conditions.current.to = to;
    }
  }, []);

  const postalCodeValidate = async (value: number) => {
    try {
      await number()
        .required()
        .positive()
        .integer()
        .typeError('Please provide a postal code')
        .validate(value);
    } catch (err) {
      return err;
    }
  };

  const handleOnChange = () => {
    if (conditions.current.from && conditions.current.to) {
      const templateString = `in_range(postal_code, ${conditions.current.from}, ${conditions.current.to})`;
      setFieldValue(`${name}.value`, templateString);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div style={{ marginBottom: '10px' }}>
          <FormattedMessage id="IN_POSTAL_CODE_RANGE" />:
        </div>
        <Field
          name={`${name}.postalcode.from.value`}
          validate={(value: number) => postalCodeValidate(value)}
        >
          {({ field }: FieldProps<string[]>) => (
            <InputField
              {...field}
              label="From"
              type="number"
              className={styles.input}
              onChange={value => {
                setFieldValue(`${name}.postalcode.from.value`, value.target.value);
                conditions.current.from = value.target.valueAsNumber;
                handleOnChange();
              }}
              min={0}
              error={getFieldArrayError(touched, errors, `${name}.postalcode.from.value`)}
            />
          )}
        </Field>

        <Field
          name={`${name}.postalcode.to.value`}
          validate={(value: number) => postalCodeValidate(value)}
        >
          {({ field }: FieldProps<string[]>) => (
            <InputField
              {...field}
              label="To"
              type="number"
              className={styles.input}
              onChange={value => {
                setFieldValue(`${name}.postalcode.to.value`, value.target.value);
                conditions.current.to = value.target.valueAsNumber;
                handleOnChange();
              }}
              min={0}
              error={getFieldArrayError(touched, errors, `${name}.postalcode.to.value`)}
            />
          )}
        </Field>
      </div>
    </>
  );
};

const styles = stylesheet({
  wrapper: {
    padding: '5px 0',
    $nest: {
      '.ant-col': {
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
      },
      '.ant-row': {
        display: 'flex',
        marginBottom: '10px',
      },
      '.ant-form-explain': {
        width: '200px',
      },
      '.ant-form-item-label': {
        minWidth: '50px',
        textAlign: 'left',
      },
      '.ant-form-item-explain-error': {
        fontSize: '13px',
      },
    },
  },
  label: {
    paddingRight: '10px',
    whiteSpace: 'nowrap',
  },
  input: {
    width: '96px',
  },
});
