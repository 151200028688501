import * as React from 'react';
import * as ReactDOM from 'react-dom';

// tslint:disable:no-import-side-effect
import 'space-lift/es/all';
import { forceRenderStyles } from 'typestyle';

import { App } from './app';

forceRenderStyles();
ReactDOM.render(<App />, document.getElementById('app'));
