import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { style as tss } from 'typestyle';
import { Omit } from 'utility-types';

import { Link } from '@src/components/link';
import { ContainerActions } from '../../../components';
import { EditableTableCell, Table } from '../../../controls';
import { LABELS } from '../../../dictionaries';
import { TransportOrderContentItemModel } from '../../../models';
import { RootState } from '../../../modules';
import { TransportOrdersSelectors } from '../../../modules/transport-orders';

interface IComponentProps {
  pageSize: number;
  itemToParcelMap?: { [key: string]: string };
  onGoToParcel?: (id: string) => void;
  size?: 'middle' | 'small';
  showEmptyMessage?: boolean;
  items: TransportOrderContentItemModel[];
  onItemDelete?: (index: number) => void;
  onItemUpdate?: (index: number, record: TransportOrderContentItemModel) => void;
  isInShipmentEditView?: boolean;
  itemsWithAddress: [{ id: string; name: string }];
}

@observer
class Component extends React.Component<IComponentProps, {}> {
  @observable
  sortingState = {
    order: 'descend' as 'descend',
    columnKey: 'created',
  };

  @observable tempRecords = new Map<number, TransportOrderContentItemModel>();

  setSortingState = (sorting: typeof Component.prototype.sortingState) => {
    this.sortingState.columnKey = sorting.columnKey;
    this.sortingState.order = sorting.order;
  };

  createEditableCell = (
    index: number,
    record: TransportOrderContentItemModel,
    prop: keyof TransportOrderContentItemModel
  ) => {
    const EditableCell = observer(() => {
      return (
        <EditableTableCell
          editable={this.tempRecords.has(index)}
          value={record[prop]}
          className="t_transport-order-id-cell"
          onChangeHandler={v => {
            const temp = this.tempRecords.get(index)!;
            this.tempRecords.set(index, { ...temp, [prop]: v });
          }}
        />
      );
    });
    return <EditableCell />;
  };

  createTransportOrderIdLink = (index: number, record: TransportOrderContentItemModel) => (
    <Link
      className="t_transport-order-id-cell"
      route={{
        name: 'TRANSPORT_ORDER_DETAILS',
        tosId: record.tosId,
        additionalState: { useCustomBack: false },
      }}
    >
      {record.tosId}
    </Link>
  );

  createAddressCell = (index: number, record: TransportOrderContentItemModel) => {
    const { itemsWithAddress } = this.props;
    const matchingItem = itemsWithAddress!.find(item => item.id === record.tosId);
    return <div> {matchingItem ? matchingItem.name : null} </div>;
  };

  createActions = (index: number) => {
    const Actions = observer(() => {
      const normalStateActions = [
        { handler: () => this.handleEditAction(index), label: LABELS.EDIT },
        { handler: () => this.handleRemoveAction(index), label: LABELS.REMOVE },
      ];
      const editStateActions = [
        { handler: () => this.handleSaveAction(index), label: LABELS.SAVE },
        { handler: () => this.handleCancelAction(index), label: LABELS.CANCEL },
      ];

      return (
        <div style={{ display: 'flex' }}>
          <ContainerActions.ActionsGroup
            actionItems={this.tempRecords.has(index) ? editStateActions : normalStateActions}
          />
        </div>
      );
    });
    return <Actions />;
  };

  handleEditAction = (index: number) => {
    this.tempRecords.set(index, this.props.items[index]);
  };

  handleRemoveAction = (index: number) => {
    const { onItemDelete } = this.props;
    if (onItemDelete) {
      onItemDelete(index);
    }
  };

  handleCancelAction = (index: number) => {
    this.tempRecords.delete(index);
  };

  handleSaveAction = (index: number) => {
    const { onItemUpdate } = this.props;
    if (onItemUpdate && this.tempRecords.has(index)) {
      const data = this.tempRecords.get(index)!;
      onItemUpdate(index, data);
    }
    this.tempRecords.delete(index);
  };

  render() {
    const {
      items,
      onItemDelete,
      onItemUpdate,
      size = 'middle',
      showEmptyMessage,
      isInShipmentEditView = false,
    } = this.props;

    return (
      <section className={styles.section}>
        <h4 className={styles.header}>
          Transport Orders ({items.length})<br />
        </h4>
        <Table<TransportOrderContentItemModel>
          size={size}
          title={() => ''}
          dataSource={items}
          rowKey={(record, index) => (index ? index.toString() : '')}
          pagination={false}
          onChange={(paging, filtering, sorting) => {
            this.setSortingState(sorting as any);
          }}
          showEmptyMessage={showEmptyMessage}
          emptyMessage={<p>List is empty</p>}
          sortDirections={['ascend', 'descend']}
        >
          <Table.Column<TransportOrderContentItemModel>
            title="Transport order ID"
            key="name"
            width="100px"
            render={(text, record, index) => {
              return isInShipmentEditView
                ? this.createTransportOrderIdLink(index, record)
                : this.createEditableCell(index, record, 'tosId');
            }}
          />
          {isInShipmentEditView && (
            <Table.Column<TransportOrderContentItemModel>
              title="Customer"
              key="address"
              width="100px"
              className="t_transport-order-customer-cell"
              render={(text, record, index) => this.createAddressCell(index, record)}
            />
          )}
          <Table.Column<TransportOrderContentItemModel>
            title="External reference"
            key="eref"
            width="100px"
            className="t_transport-order-external-ref-cell"
            render={(text, record, index) => this.createEditableCell(index, record, 'externalRef')}
          />
          {onItemDelete && onItemUpdate && (
            <Table.Column<TransportOrderContentItemModel>
              title="Actions"
              key="actions"
              width="100px"
              render={(text, record, index) => this.createActions(index)}
            />
          )}
        </Table>
      </section>
    );
  }
}

const styles = {
  section: tss({
    paddingBottom: 20,
  }),
  header: tss({
    marginBottom: '-5px',
  }),
};

// Connected Type
type OwnProps = Omit<IComponentProps, 'pageSize' | 'itemsWithAddress'>;

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  pageSize: state.app.pageSize,
  itemsWithAddress: TransportOrdersSelectors.getTransportOrdersWithAddresses(state, ownProps.items),
});

export const TransportOrdersTable = connect(mapStateToProps, {})(Component);
TransportOrdersTable.displayName = 'TransportOrdersTable';
