import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Table, Tag, Dropdown } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { configSelectors } from '@src/modules/config';
import { Button} from 'antd';
import { TableRowSelection as TableRowSelectionType } from 'antd/lib/table/interface';
import { omitBy } from 'lodash';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { classes, style } from 'typestyle';
import { ShipmentsTable } from '../../../components';
import { LABELS } from '../../../dictionaries';
import { ShipmentListFiltersModel, ShipmentSummaryModel, SpecialReportType } from '../../../models';
import { RootState } from '../../../modules';
import { deliveriesSelectors } from '../../../modules/deliveries';
import { defaultTheme } from '../../../styles';
import { stringUtils } from '../../../utils';
import { getNumberOfShipmentFilters } from '../../../utils/counters';
import { BulkBooking, BulkPrinting } from './additional-actions';
import { ShipmentsFilterPanel } from './shipment-filters';

const mapStateToProps = (state: RootState) => ({
  filters: state.somSearch.filters,
  shippingMethodsOptions: configSelectors.getRealBookingMethodsGroupedByCarrier(state),
  deliveriesStatuses: deliveriesSelectors.getDeliveries(state),
  siteId: state.sites.selectedSiteId,
});

interface OwnProps {
  isLoading: boolean;
  onItemClick: (id: string) => void;
  shippingMethodsDict: { [key: string]: string };
  onFiltersApply: (filters: ShipmentListFiltersModel, showUserShipments: boolean) => void;
  onFiltersClear: () => void;
  currentPage: number;
  tableSelectedInfo?: string;
  myShipmentsFilterAllowed: boolean;
  rowSelection: TableRowSelectionType<ShipmentSummaryModel>;
  isAnyItemSelected: boolean;
  onExportItems: (reportType: SpecialReportType) => void;
  onAddToWatchlist: () => void;
  isFetchingStatuses: boolean;
  shipmentSummaries: ShipmentSummaryModel[];
  addToWatchlistDisabled: boolean;
  onPaginationButtonClick: (
    sortDirection: 'ASCENDING' | 'DESCENDING',
    mode: 'forward' | 'backward'
  ) => void;
  selectedRowKeys: string[];
}

type Props = OwnProps & ReturnType<typeof mapStateToProps>;

@observer
class Component extends React.Component<Props> {
  @observable
  sortingState = {
    order: 'descend' as 'descend' | 'ascend',
    columnKey: 'createdAt',
  };

  currentSearchValue = '';

  setSortingState = (sorting: typeof Component.prototype.sortingState) => {
    this.sortingState.columnKey = sorting.columnKey;
    this.sortingState.order = sorting.order;
  };
  handleItemClick = (id: string) => {
    this.props.onItemClick(id);
  };

  getShippingMethod = (record: ShipmentSummaryModel) => {
    const { shippingMethodsDict } = this.props;

    const shippingMethod = shippingMethodsDict[record.shippingMethod];
    return shippingMethod;
  };

  handleTableChange: typeof Table.prototype.props.onChange = (pagination, _, sorter: any) =>
    this.setSortingState(sorter as any);

  onSearchValueHandler = (value: string) => {
    this.currentSearchValue = value;
  };

  render() {
    const {
      sortingState: { order },
    } = this;
    const {
      isLoading,
      currentPage,
      filters,
      onFiltersApply,
      onFiltersClear,
      shippingMethodsOptions,
      isFetchingStatuses,
      onExportItems,
      onAddToWatchlist,
      isAnyItemSelected,
      rowSelection,
      tableSelectedInfo,
      shipmentSummaries,
      addToWatchlistDisabled,
      onPaginationButtonClick,
      selectedRowKeys,
    } = this.props;

    const { getPlurarFormOf } = stringUtils;

    const selectedShipments = shipmentSummaries.filter(({ id }) => selectedRowKeys.includes(id));

    const filtersWithoutFailedState = omitBy(filters, (_, key) => key === 'failedBulkActionsType');

    return (
      <section className={styles.section}>
        <div className={styles.additionalActions}>
          <div className={styles.actions}>
            <Button
              onClick={onAddToWatchlist}
              disabled={!isAnyItemSelected || addToWatchlistDisabled}
            >
              {LABELS.ADD_TO_WATCHLIST}
            </Button>
            <BulkBooking selectedShipments={selectedShipments} />
            <BulkPrinting selectedShipments={selectedShipments} />
          </div>

          <Button.Group>
              <Button onClick={() => onExportItems(SpecialReportType.REPORT_TYPE_UNKNOWN)} className={styles.exportButton} disabled={!isAnyItemSelected}>{LABELS.EXPORT_TO_CSV}</Button>
              <Dropdown.Button
                className={styles.exportDropdownWrapper}
                onMainActionClick={null}
                overlayConfig={[
                  {label: LABELS.DHL_BBX_INVOICE_SHIPMENTS, handler: () => onExportItems(SpecialReportType.BBX_INVOICE_SHIPMENTS)},
                ]}
              />
          </Button.Group>
        </div>
        <div className={styles.selectedInfo}>{tableSelectedInfo}</div>
        <div className={styles.filtersInfo}>
          <div>
            {!isLoading}
            {filters && getNumberOfShipmentFilters(filtersWithoutFailedState) ? (
              <div>
                {getPlurarFormOf('filter', getNumberOfShipmentFilters(filtersWithoutFailedState))}{' '}
                applied &nbsp;•
                {getFailedBulkActionTag(filters)}
                <span className={styles.clearFiltersButton} onClick={onFiltersClear}>
                  &nbsp; {LABELS.CLEAR_ALL_FILTERS}
                </span>
              </div>
            ) : (
              false
            )}
          </div>
          <ShipmentsFilterPanel
            filters={this.props.filters}
            handleApply={onFiltersApply}
            handleClear={onFiltersClear}
            shippingMethodsOptions={shippingMethodsOptions}
            disabled={isLoading}
            allowMyShipmentsFilter={this.props.myShipmentsFilterAllowed}
          />
        </div>
        <ShipmentsTable
          isLoading={isLoading}
          items={shipmentSummaries}
          pagination={false}
          onChange={this.handleTableChange}
          className={classes(styles.table, 't_shipment-list-table')}
          rowSelection={rowSelection}
          sortingState={this.sortingState}
          getShippingMethodName={this.getShippingMethod}
          isFetchingStatuses={isFetchingStatuses}
          onRowClick={this.handleItemClick}
        />
        {shipmentSummaries.length !== 0 && (
          <Button.Group className={styles.buttonGroup}>
            <Button
              size="small"
              onClick={() =>
                onPaginationButtonClick(order === 'ascend' ? 'ASCENDING' : 'DESCENDING', 'backward')
              }
              disabled={currentPage === 1}
              icon={<LeftOutlined />}
            />
            <Button
              size="small"
              onClick={() =>
                onPaginationButtonClick(order === 'ascend' ? 'ASCENDING' : 'DESCENDING', 'forward')
              }
              disabled={shipmentSummaries.length < 50}
              icon={<RightOutlined />}
            />
          </Button.Group>
        )}
      </section>
    );
  }
}

const getFailedBulkActionTag = (filters: ShipmentListFiltersModel | null) => {
  if (filters?.shipmentIds?.length && filters?.failedBulkActionsType) {
    return (
      <>
        <Tag
          style={{ marginLeft: '8px' }}
          color={defaultTheme.color.statusYellow}
          textColor={defaultTheme.color.palePrimary}
          borderColor={defaultTheme.color.statusOrange}
          nonClickable
        >
          <FormattedMessage
            id={
              filters?.failedBulkActionsType === 'booking'
                ? 'NOT_BOOKABLE_SHIPMENTS'
                : 'NOT_PRINTABLE_SHIPMENTS_LABELS'
            }
          />
        </Tag>
        {' •'}
      </>
    );
  }

  return null;
};

export const ShipmentList = connect(mapStateToProps, {})(Component);
ShipmentList.displayName = 'ShipmentList';
const styles = {
  section: style({ position: 'relative' }),
  additionalActions: style({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
  }),
  actions: style({
    display: 'flex',
    gap: '16px',
  }),
  clearFiltersButton: style({ color: defaultTheme.color.primary, cursor: 'pointer' }),
  exportButton: style({ marginLeft: '10px' }),
  exportDropdownWrapper: style({
    paddingRight: 0,
    marginBottom: 17,
    display: 'flex',
  }),
  table: style({
    $nest: {
      '& .ant-table-body': {
        overflowX: 'auto',
      },
    },
  }),
  selectedInfo: style({ minHeight: defaultTheme.lineHeight }),
  filtersInfo: style({
    display: 'flex',
    padding: '12px 0',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  buttonGroup: style({
    marginTop: '12px',
    display: 'flex',
    justifyContent: 'flex-end',
    $nest: {
      '& button:first-child': {
        marginRight: '4px',
      },
    },
  }),
};
