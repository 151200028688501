import { FieldState, FormState } from 'formstate';
import { IObservableArray, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { classes, style } from 'typestyle';
import isEmpty from 'lodash/isEmpty';
import { Omit } from 'utility-types';
import { ContainerActions } from '../../../components';
import {
  Badge,
  CurrencyInput,
  EditableTagGroup,
  Input,
  InputNumber,
  Label,
  Table,
  TableEditableCell,
} from '../../../controls';
import {
  withFieldStateInput,
  withFieldStateInputNumber,
  withFieldStateTagGroup,
} from '../../../decorators';
import { LABELS } from '../../../dictionaries';
import { LineItemModel } from '../../../models';
import { RootState } from '../../../modules';
import { defaultTheme } from '../../../styles';
import { maxLength, minLength, requiredFieldWithMessage } from '../../../utils/validation';

const InputField = withFieldStateInput(Input);
const CurrencyInputField = withFieldStateInput(CurrencyInput);
const InputNumberField = withFieldStateInputNumber(InputNumber);
const EditableTagGroupField = withFieldStateTagGroup(EditableTagGroup);

export type LineItemFormState = FormState<{
  active: FieldState<boolean>;
  parcelId: FieldState<string>;
  lineItem: FormState<{
    skuFieldState: FieldState<string>;
    nameFieldState: FieldState<string>;
    descriptionFieldState: FieldState<string>;
    dimensionsFieldState: FormState<{
      heightFieldState: FieldState<string>;
      lengthFieldState: FieldState<string>;
      widthFieldState: FieldState<string>;
    }>;
    quantityFieldState: FieldState<string>;
    priceFieldState: FieldState<string>;
    lengthFieldState: FieldState<string>;
    heightFieldState: FieldState<string>;
    widthFieldState: FieldState<string>;
    weightFieldState: FieldState<string>;
    currencyFieldState: FieldState<string>;
    tagsFieldState: FieldState<string[]>;
    hsTariffNumberFieldState: FieldState<string>;
  }>;
}>;

export const createLineItemFormState = (
  active: boolean,
  lineItem: LineItemModel,
  parcelId?: string
) => {
  return new FormState({
    active: new FieldState(active),
    parcelId: new FieldState(parcelId || ''),
    lineItem: new FormState({
      skuFieldState: new FieldState(lineItem.sku || ''),
      nameFieldState: new FieldState(lineItem.name || '').validators(
        requiredFieldWithMessage('Required')
      ),
      descriptionFieldState: new FieldState((lineItem.description && lineItem.description) || ''),
      quantityFieldState: new FieldState(
        (lineItem.quantity && lineItem.quantity.toString()) || '0'
      ),
      dimensionsFieldState: new FormState({
        heightFieldState: new FieldState(
          (lineItem.dimensions.height && lineItem.dimensions.height.toString()) || '0'
        ),
        lengthFieldState: new FieldState(
          (lineItem.dimensions.length && lineItem.dimensions.length.toString()) || '0'
        ),
        widthFieldState: new FieldState(
          (lineItem.dimensions.width && lineItem.dimensions.width.toString()) || '0'
        ),
      }),
      priceFieldState: new FieldState((lineItem && lineItem.price.toString()) || '0').validators(
        requiredFieldWithMessage('Required')
      ),
      lengthFieldState: new FieldState(
        (!isEmpty(lineItem.dimensions) && lineItem.dimensions.length.toString()) || '0'
      ).validators(),
      heightFieldState: new FieldState(
        (!isEmpty(lineItem.dimensions) && lineItem.dimensions.height.toString()) || '0'
      ),
      widthFieldState: new FieldState(
        (!isEmpty(lineItem.dimensions) && lineItem.dimensions.width.toString()) || '0'
      ),
      weightFieldState: new FieldState((lineItem && lineItem.weight.toString()) || '0'),
      currencyFieldState: new FieldState((lineItem && lineItem.currency) || ''),
      tagsFieldState: new FieldState((lineItem && lineItem.tags) || []),
      hsTariffNumberFieldState: new FieldState(
        (lineItem && lineItem.hsTariffNumber) || ''
      ).validators(minLength(6), maxLength(20)),
    }),
  });
};

export const unwrapLineItemFormState = (item: LineItemFormState) => ({
  active: item.$.active.$,
  lineItem: {
    sku: item.$.lineItem.$.skuFieldState.$,
    name: item.$.lineItem.$.nameFieldState.$,
    description: item.$.lineItem.$.descriptionFieldState.$,
    quantity: parseInt(item.$.lineItem.$.quantityFieldState.$, 10),
    dimensions: {
      height: parseInt(item.$.lineItem.$.dimensionsFieldState.$.heightFieldState.$, 10),
      width: parseInt(item.$.lineItem.$.dimensionsFieldState.$.widthFieldState.$, 10),
      length: parseInt(item.$.lineItem.$.dimensionsFieldState.$.lengthFieldState.$, 10),
    },
    price: parseInt(item.$.lineItem.$.priceFieldState.$, 10),
    currency: item.$.lineItem.$.currencyFieldState.$,
    tags: item.$.lineItem.$.tagsFieldState.$.slice(),
    weight: parseInt(item.$.lineItem.$.weightFieldState.$, 10),
    hsTariffNumber: item.$.lineItem.$.hsTariffNumberFieldState.$,
  },
});

const createFormState = (
  model: { active: boolean; parcelId?: string; lineItem: LineItemModel }[] = []
): LineItemsTableFormState => {
  return new FormState(
    observable(
      model.map(item => createLineItemFormState(item.active, item.lineItem, item.parcelId))
    )
  );
};

export class LineItemsTableFormState extends FormState<IObservableArray<LineItemFormState>> {
  static create = (
    model?: { active: boolean; parcelId?: string; lineItem: LineItemModel }[]
  ): LineItemsTableFormState => {
    if (!model) {
      const lineItemModel = new LineItemModel();
      lineItemModel.name = 'Item';
      const newModel = {
        active: false,
        lineItem: lineItemModel,
      };
      return createFormState([newModel]);
    }

    return createFormState(model);
  };
}

const TableItemWithErrors = ({
  children,
  error,
}: {
  children: JSX.Element;
  error?: FieldState<string>;
}) => {
  const errorInfo = error ? (
    <p style={{ color: defaultTheme.color.error, position: 'absolute', fontSize: '0.9em' }}>
      {error}
    </p>
  ) : null;
  return (
    <div style={{ position: 'relative' }}>
      {children}
      {errorInfo}
    </div>
  );
};

type ComponentProps = {
  pageSize: number;
  formState: LineItemsTableFormState;
  items?: { parcelId?: string; lineItem: LineItemModel }[];
  onItemRemove?: (item: any) => void;
  onItemEdit?: (item: { parcelId?: string; lineItem: LineItemModel }) => void;
  disabled?: boolean;
  showTitle?: boolean;
  onGoToParcel?: (id: string) => void;
  size?: 'middle' | 'small';
  showEmptyMessage?: boolean;
};

@observer
class Component extends React.Component<ComponentProps, {}> {
  @observable
  sortingState = {
    order: 'descend' as 'descend',
    columnKey: 'created',
  };

  setSortingState = (sorting: typeof Component.prototype.sortingState) => {
    this.sortingState.columnKey = sorting.columnKey;
    this.sortingState.order = sorting.order;
  };

  handleItemRemove = (record: LineItemFormState) => {
    const { onItemRemove } = this.props;
    if (onItemRemove) {
      onItemRemove(record);
    }
  };

  handleRecordSave = (record: LineItemFormState) => {
    const validate = record.validate();
    validate.then(result => record.$.active.onChange(result.hasError));
  };

  handleRecordEdit = (record: LineItemFormState) => {
    record.$.active.onChange(true);
  };

  getActions = (record: LineItemFormState) => {
    const actions = [];

    if (record.$.active.value) {
      actions[0] = { handler: () => this.handleRecordSave(record), label: LABELS.SAVE };
    } else {
      actions[0] = { handler: () => this.handleRecordEdit(record), label: LABELS.EDIT };
    }
    actions.push({ handler: () => this.handleItemRemove(record), label: LABELS.REMOVE });
    return actions;
  };

  render() {
    const { disabled, size = 'middle', showTitle = true, showEmptyMessage } = this.props;

    const ActionsGroup = observer(({ record }) => (
      <ContainerActions.ActionsGroup actionItems={this.getActions(record)} />
    ));

    const ActionsColumn = !disabled && (
      <Table.Column<LineItemFormState>
        title="Actions"
        key="actions"
        render={(text, record) => <ActionsGroup record={record} />}
      />
    );

    const tableTitle = showTitle && (
      <h4 className={classes(styles.header, 'ant-form-item-required')}>
        {`${LABELS.LINE_ITEM}s (${this.props.formState.$.length})`}
        <br />
      </h4>
    );

    const TableInputCell = observer(({ active, fieldState, testID }) => (
      <TableEditableCell
        editable={active.value}
        readOnlyRenderer={
          (fieldState.value && <Label text={fieldState.value} />) || <Badge.Empty />
        }
        editableRenderer={
          <TableItemWithErrors error={fieldState.error}>
            <InputField name={testID} fieldState={fieldState} />
          </TableItemWithErrors>
        }
      />
    ));

    const TableCurrencyCell = observer(({ active, fieldState, testID }) => (
      <TableEditableCell
        editable={active.value}
        readOnlyRenderer={<Label text={fieldState.value} />}
        editableRenderer={
          <TableItemWithErrors error={fieldState.error}>
            <CurrencyInputField name={testID} fieldState={fieldState} />
          </TableItemWithErrors>
        }
      />
    ));

    const TableNumericCell = observer(({ active, fieldState, testID }) => (
      <TableEditableCell
        editable={active.value}
        readOnlyRenderer={<Label text={fieldState.value} />}
        editableRenderer={
          <TableItemWithErrors error={fieldState.error}>
            <InputNumberField
              min={0}
              name={testID}
              defaultValue={0}
              fieldState={fieldState}
              style={{ width: 'auto', marginRight: 0 }}
            />
          </TableItemWithErrors>
        }
      />
    ));

    const EditableTableTagGroupCell = observer(({ active, fieldState, testID }) => (
      <TableEditableCell
        editable={active.value}
        readOnlyRenderer={
          (fieldState.value.length > 0 && <EditableTagGroup tags={fieldState.value} />) || (
            <Badge.Empty />
          )
        }
        editableRenderer={
          <TableItemWithErrors error={fieldState.error}>
            <EditableTagGroupField name={testID} editable={active.value} fieldState={fieldState} />
          </TableItemWithErrors>
        }
      />
    ));

    const DimensionsColumn = observer(({ record, testID }) => {
      const { heightFieldState, widthFieldState, lengthFieldState } =
        record.$.lineItem.$.dimensionsFieldState.$;

      const className = {
        activeStyle: style({ width: 'auto', display: 'flex', alignItems: 'center' }),
        inactiveStyle: style({ width: '130px', display: 'flex', alignItems: 'center' }),
        dimensionSeparator: style({ paddingRight: 3, paddingLeft: 3, paddingTop: 7 }),
      };

      const DimensionSeparator = record.$.active.value ? (
        <p className={className.dimensionSeparator}>x</p>
      ) : (
        <Label text={'x'} />
      );

      return (
        <div className={record.$.active.value ? className.activeStyle : className.inactiveStyle}>
          <TableNumericCell
            testID="dimensions-height-field"
            active={record.$.active}
            fieldState={heightFieldState}
          />
          {DimensionSeparator}
          <TableNumericCell
            testID="dimensions-width-field"
            active={record.$.active}
            fieldState={widthFieldState}
          />
          {DimensionSeparator}
          <TableNumericCell
            testID="dimensions-length-field"
            active={record.$.active}
            fieldState={lengthFieldState}
          />
        </div>
      );
    });

    const tableBodyStyle = style({
      $nest: {
        '& .ant-table-body': {
          overflowX: 'auto',
          whiteSpace: 'nowrap',
        },
      },
    });

    return (
      <section className={styles.section}>
        {tableTitle}
        <Table<LineItemFormState>
          showHeader={true}
          size={size}
          title={() => ''}
          pagination={false}
          dataSource={this.props.formState.$.slice()}
          rowKey={(record, index) => record.$.lineItem.$.skuFieldState.$ + index}
          showEmptyMessage={showEmptyMessage}
          emptyMessage={<p>List is empty</p>}
          className={tableBodyStyle}
          sortDirections={['ascend', 'descend']}
        >
          <Table.Column<LineItemFormState>
            title="SKU"
            key="sku"
            render={(text, record) => (
              <TableInputCell
                testID="sku"
                active={record.$.active}
                fieldState={record.$.lineItem.$.skuFieldState}
              />
            )}
          />
          <Table.Column<LineItemFormState>
            title={<Label text="Name" required={true} />}
            key="name"
            render={(text, record) => (
              <TableInputCell
                testID="name"
                active={record.$.active}
                fieldState={record.$.lineItem.$.nameFieldState}
              />
            )}
          />
          <Table.Column<LineItemFormState>
            title="Description"
            key="description"
            render={(text, record) => (
              <TableInputCell
                testID="description"
                active={record.$.active}
                fieldState={record.$.lineItem.$.descriptionFieldState}
              />
            )}
          />
          <Table.Column<LineItemFormState>
            title="Qty"
            key="qty"
            render={(text, record) => (
              <TableNumericCell
                testID="qty"
                active={record.$.active}
                fieldState={record.$.lineItem.$.quantityFieldState}
              />
            )}
          />
          <Table.Column<LineItemFormState>
            title="Dimensions [mm]"
            key="dimensions"
            render={(text, record) => <DimensionsColumn record={record} /> || <Badge.Empty />}
          />
          <Table.Column<LineItemFormState>
            title="Price"
            key="price"
            render={(text, record) => (
              <TableCurrencyCell
                testID="price"
                active={record.$.active}
                fieldState={record.$.lineItem.$.priceFieldState}
              />
            )}
          />
          <Table.Column<LineItemFormState>
            title="Currency"
            key="currency"
            render={(text, record) => (
              <TableInputCell
                testID="currency"
                active={record.$.active}
                fieldState={record.$.lineItem.$.currencyFieldState}
              />
            )}
          />
          <Table.Column<LineItemFormState>
            title="Weight [gm]"
            key="weight"
            render={(text, record) => (
              <TableNumericCell
                testID="weight"
                active={record.$.active}
                fieldState={record.$.lineItem.$.weightFieldState}
              />
            )}
          />
          <Table.Column<LineItemFormState>
            title="Tags"
            key="tags"
            render={(text, record) => (
              <EditableTableTagGroupCell
                active={record.$.active}
                testID="tag"
                fieldState={record.$.lineItem.$.tagsFieldState}
              />
            )}
          />
          <Table.Column<LineItemFormState>
            title={<Label text="HS Tariff Code" />}
            key="hsTariffNumber"
            render={(text, record) => (
              <TableInputCell
                testID="hsTariffNumber"
                active={record.$.active}
                fieldState={record.$.lineItem.$.hsTariffNumberFieldState}
              />
            )}
          />
          {ActionsColumn}
        </Table>
      </section>
    );
  }
}

const styles = {
  section: style({
    paddingBottom: 20,
  }),
  header: style({
    marginBottom: '-5px',
  }),
};

// Connected Type
type OwnProps = Omit<ComponentProps, 'pageSize'>;

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  pageSize: state.app.pageSize,
});

export const LineItemsTableForm = connect(mapStateToProps, {})(Component);
LineItemsTableForm.displayName = 'LineItemsTableForm';
