import * as React from 'react';
import { classes, stylesheet } from 'typestyle';

import { Input } from '@src/controls';
import { inputStyle } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { defaultTheme } from '@src/styles';

const isValidHexColor = (value: string) => value.match(/^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/);

type OwnProps = {
  value: string;
  onSave: (value: string) => void;
  disabled?: boolean;
};

export const HexColorInput: React.FunctionComponent<OwnProps> = ({ value, onSave, disabled }) => {
  const [inputState, setInputState] = React.useState(value);
  const [errorState, setErrorState] = React.useState(false);
  React.useEffect(() => {
    setInputState(value);
    setErrorState(false);
  }, [value]);
  return (
    <div>
      <Input
        className={classes(inputStyle, styles.hexInput, errorState && styles.hexInputError)}
        value={inputState}
        onChange={e => {
          setInputState(e.target.value);
        }}
        onBlur={_ => {
          if (inputState === value) {
            return;
          }
          if (isValidHexColor(inputState) || inputState === '') {
            onSave(inputState);
            setErrorState(false);
          } else {
            setErrorState(true);
          }
        }}
        disabled={disabled}
      />
      {errorState && <FormattedMessage className={styles.errorMessage} id="HEX_VALUE_ERROR" />}
    </div>
  );
};

const styles = stylesheet({
  hexInput: {
    width: '128px',
  },
  hexInputError: {
    border: `1px solid ${defaultTheme.color.error}`,
    background: 'rgba(245, 34, 45, 0.05)',
  },
  errorMessage: {
    color: defaultTheme.color.error,
    fontSize: '12px',
    paddingLeft: '8px',
  },
});
