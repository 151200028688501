import * as React from 'react';
import {
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DropTarget,
  DropTargetMonitor,
} from 'react-dnd';
import { classes, stylesheet } from 'typestyle';

import { defaultTheme } from '@src/styles';

let dragingIndex = -1;

interface DnDProps {
  connectDropTarget: ConnectDropTarget;
  isOver: boolean;
  connectDragSource: ConnectDragSource;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  className?: string;
}

const BodyRow: React.FunctionComponent<DnDProps> = ({
  isOver,
  connectDragSource,
  connectDropTarget,
  index,
  moveRow,
  className,
  ...restProps
}) => {
  // Remember to set borderCollapse: 'collapse' on target table for borders to work on hover
  let hoverClassname = '';
  if (isOver) {
    if (index > dragingIndex) {
      hoverClassname = 'is-over-top';
    }
    if (index < dragingIndex) {
      hoverClassname = 'is-over-bottom';
    }
  }

  return connectDragSource(
    connectDropTarget(
      <tr {...restProps} className={classes(styles.row, hoverClassname, className)} />
    )
  );
};

const rowSource = {
  beginDrag(props: DnDProps) {
    dragingIndex = props.index;
    return {
      index: props.index,
    };
  },
};

const rowTarget = {
  drop(props: DnDProps, monitor: DropTargetMonitor) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }
    props.moveRow(dragIndex, hoverIndex);

    monitor.getItem().index = hoverIndex;
  },
};

export const DragableRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);

const styles = stylesheet({
  row: {
    cursor: 'move',
    transform: 'translateZ(0)', // fixes safari issue with displaying drag ghost on cell
    $nest: {
      '&.is-over-top td': {
        borderTop: `2px dashed ${defaultTheme.color.primary} !important`,
      },
      '&.is-over-bottom td': {
        borderBottom: `2px dashed ${defaultTheme.color.primary} !important`,
      },
    },
  },
});
