import { combineEpics, Epic } from 'redux-observable';
import { from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { tokenAuthActions } from '.';
import { RootAction, RootState, Services } from '../../modules';
import { MADError } from '../../utils';

const getToken: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { tokenAuthService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(tokenAuthActions.getAuthTokenRequest)),
    switchMap(({ payload: { siteId, resource, sitePrivateKey } }) =>
      observableFrom(tokenAuthService.getTokenFor(resource, siteId, sitePrivateKey)).pipe(
        map(res => tokenAuthActions.getAuthTokenSuccess(res)),
        catchError((error: MADError) =>
          observableOf(tokenAuthActions.getAuthTokenError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

export const tokenAuthEpics = combineEpics(getToken);
