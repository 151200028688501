import { AxiosError } from 'axios';

import React from 'react';
import { useParams } from 'react-router-dom';
import { getLabel } from './helpers';
import { getLabelToken } from '@src/services/gentoken-service';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { DocumentModel } from '@src/models';
import { useShipment } from './components/customs-declaration/queries';
import { useQuery } from 'react-query';
import type { PDFDocumentProxy } from 'pdfjs-dist';

import { Document, Page, pdfjs } from 'react-pdf';

const FILE_FORMAT = '.pdf';

export const ShipmentBookAndPrint = () => {
  const { parcelId, shipmentId, siteId } =
    useParams<{ parcelId: string; shipmentId: string; siteId: string }>();

  const shipment = useShipment(shipmentId);
  const parcel = shipment.data?.parcels.find(parcel => parcel.id === parcelId);

  const loadFile = async (model: DocumentModel | undefined) => {
    if (!model) {
      return null;
    }

    return getLabelToken(siteId, model.url, FILE_FORMAT).then(res => sanitizeUrl(res.url));
  };

  const {
    data: labelUrl,
    error,
    isFetching,
    isLoading,
  } = useQuery<string | null, AxiosError>({
    queryFn: async () => {
      if (!parcel) return null;

      const model = getLabel(parcel);
      const url = await loadFile(model);
      return url;
    },
    queryKey: [parcel, siteId],
    enabled: !!parcel,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const [numPages, setNumPages] = React.useState<number>();

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy): void => {
    setNumPages(numPages);
  };

  React.useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
  }, []);

  if (!labelUrl && !isFetching && !isLoading) {
    return <div>Label not found</div>;
  }

  if (error) {
    console.error(error);
  }

  return (
    <>
      {labelUrl && (
        <>
          <style>{printStyles}</style>
          <Document file={labelUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                onRenderSuccess={window.print}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            ))}
          </Document>
        </>
      )}
    </>
  );
};

const printStyles = `
  @media print {
    @page {
      margin: 0;
    }
  }
`;
