import { apiUtils, authFetch } from '@src/utils';
import * as qs from 'query-string';

const RECEIPT_URL = `${apiUtils.getApiUrl()}/receipt_widget`;

export const getAuthToken = async (
  sessionId: string,
  siteId: string,
  privateKey: string
): Promise<string> => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
      Authorization: `Bearer ${btoa(privateKey)}`,
    },
  };

  return authFetch<{ token: string }>(
    `${RECEIPT_URL}/token?` + qs.stringify({ site_id: siteId, session_id: sessionId }),
    options
  ).then(res => res.token);
};
