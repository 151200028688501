import moment from 'moment';
import { ConfigSiteModel } from '@src/models';
import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { Reducer } from '..';
import { extractParam } from '../../utils/url';
import * as appActions from '../app/app-actions';
import * as configActions from '../config/actions';
import { merchantsActions } from '../merchants';
import * as sitesActions from './sites-actions';
import { MADError } from '@src/utils';

// STATE
export type SitesState = Readonly<{
  isFetching: boolean;
  error: MADError | null;
  sites: Omit<
    ConfigSiteModel,
    'regions' | 'warehouses' | 'shippingCategories' | 'widgetConfiguration'
  >[];
  selectedSiteId: string | null;
}>;

const isFetchingReducer: Reducer<SitesState['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(sitesActions.getSitesRequest):
      return true;

    case getType(sitesActions.getSitesSuccess):
    case getType(sitesActions.getSitesError):
      return false;

    default:
      return state;
  }
};

const errorReducer: Reducer<SitesState['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(sitesActions.getSitesError):
      return action.payload;

    case getType(sitesActions.getSitesRequest):
    case getType(appActions.resetErrors):
      return null;

    default:
      return state;
  }
};

export const sitesReducer: Reducer<SitesState['sites']> = (state = [], action) => {
  switch (action.type) {
    case getType(merchantsActions.selectActiveMerchant):
      return [];

    case getType(sitesActions.getSitesSuccess):
      return action.payload.map(site => ({
        id: site.id,
        name: site.name,
        privateKey: site.privateKey,
        metadata: site.metadata,
        updatedAt: site.updatedAt,
        createdAt: site.createdAt,
        version: site.version,
        bookingEnabled: site.bookingEnabled,
      }));

    case getType(configActions.createDraftSuccess):
      return [
        ...state,
        {
          id: action.payload.siteId,
          name: `Draft for ${action.payload.master.siteId}`,
          privateKey: `same as for ${action.payload.master.siteId}`,
          metadata: {
            draft: {
              isDraft: true,
              masterSiteId: action.payload.master.siteId,
            },
          },
          createdAt: moment().format(),
          updatedAt: moment().format(),
          version: action.payload.version,
        },
      ];

    case getType(configActions.promoteDraftSuccess):
      return state.filter(site => !site.metadata?.draft);

    case getType(configActions.updateCarrierProductSuccess):
      return state.map(site => {
        if (site.metadata && site.metadata.draft && site.metadata.draft.isDraft) {
          return { ...site, updatedAt: moment().format() };
        } else {
          return site;
        }
      });

    case getType(configActions.deleteDraftSuccess):
      return state.filter(site => site.id !== action.payload);

    default:
      return state;
  }
};

const selectedSiteIdReducer: Reducer<SitesState['selectedSiteId']> = (state = null, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      if (action.payload && action.payload.location.pathname) {
        const newState = extractParam(action.payload.location.pathname, 'sites');
        if (newState !== state && newState !== ':siteId') {
          return newState;
        }
      }
      return state;

    default:
      return state;
  }
};

// REDUCER
const reducer: Reducer<SitesState> = combineReducers({
  isFetching: isFetchingReducer,
  error: errorReducer,
  sites: sitesReducer,
  selectedSiteId: selectedSiteIdReducer,
});

export default reducer;
