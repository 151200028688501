import * as React from 'react';
import { style } from 'typestyle';

import { MESSAGES } from '@src/dictionaries';
import { UserTagModel } from '@src/models';
import { PaginationProps } from 'antd/lib/pagination';
import { Button, Spinner, Table } from '../../../controls';

type ComponentProps = Readonly<{
  onDelete: (items: string[]) => void;
  onEditClick: (record: UserTagModel) => void;
  tags: UserTagModel[];
  tagsError: string | null;
  isLoading: boolean;
  total: number;
  pageSize: number;
  currentPage: number;
  onPaginationChange: (nextPage: number) => void;
}>;

type State = {
  selectedRowKeys: string[];
};

export class TagsTable extends React.Component<ComponentProps, State> {
  state = {
    selectedRowKeys: [],
  };

  handleDelete = () => {
    this.setState({ selectedRowKeys: [] });
    this.props.onDelete(this.state.selectedRowKeys);
  };

  onSelectChange = (selectedRowKeys: any) => {
    this.setState({ selectedRowKeys });
  };

  handleTableChange: typeof Table.prototype.props.onChange = pagination => {
    if (typeof pagination === 'object' && pagination.current !== this.props.currentPage) {
      this.props.onPaginationChange(pagination.current || 1);
    }
  };

  render() {
    const { tags, isLoading, pageSize, currentPage, total, onEditClick } = this.props;

    const paginationConfig: PaginationProps = {
      defaultCurrent: currentPage,
      current: currentPage,
      pageSize,
      total,
      size: 'small',
      showQuickJumper: true,
      showSizeChanger: true,
      showTotal: (count: number, range: number[]) => `${range[0]}-${range[1]} of ${count} items`,
    };

    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: 'all-data',
          text: 'Select All Data',
          onSelect: () => this.setState({ selectedRowKeys: tags.map(item => item.id) }),
          onDisSelect: () => {
            this.setState({ selectedRowKeys: [] });
          },
        },
        {
          key: 'diselect-all',
          text: 'Diselect All Data',
          onSelect: (selected: any) => this.setState({ selectedRowKeys: [] }),
        },
        {
          key: 'current',
          text: 'Select Current Page',
          onSelect: (selected: any) => this.setState({ selectedRowKeys: selected }),
        },
      ],
    };

    const emptyMessage = isLoading ? <Spinner active={isLoading} /> : MESSAGES.EMPTY_TAGS_LIST;

    return (
      <section className={styles.section}>
        <div style={{ paddingTop: '1em', paddingBottom: '1em' }}>
          <Button
            type="primary"
            danger
            onClick={this.handleDelete}
            disabled={this.state.selectedRowKeys.length === 0}
          >
            Delete
          </Button>
          <span style={{ marginLeft: 8 }}>
            {this.state.selectedRowKeys.length > 0
              ? `Selected ${this.state.selectedRowKeys.length} of ${tags.length}` +
                (tags.length === 1 ? ` item` : ` items`)
              : ''}
          </span>
        </div>

        <Table<UserTagModel>
          loading={isLoading}
          dataSource={tags}
          rowKey={record => record.id}
          pagination={paginationConfig}
          onChange={this.handleTableChange}
          emptyMessage={emptyMessage}
          rowSelection={rowSelection}
          hideTitle={true}
          sortDirections={['ascend', 'descend']}
        >
          <Table.Column<UserTagModel>
            title="Name"
            key="name"
            width="180px"
            render={(_text, { name }) => name}
          />
          <Table.Column<UserTagModel>
            title="Action"
            key="action"
            render={(_text, record) => <a onClick={() => onEditClick(record)}>Edit</a>}
          />
        </Table>
      </section>
    );
  }
}

const styles = {
  section: style({ position: 'relative' }),
};
