import { combineEpics, Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, concatMap, filter, map, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { MADError } from '@src/utils';
import { trackingWidgetActions } from '.';
import { RootAction, RootState, Services } from '..';

const updateCustomEventDetails: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(trackingWidgetActions.updateCustomEventRequest)),
    concatMap(({ payload: { siteId, upsertModel }, meta: { optimistic } }) =>
      from(selfCareService.upsertCustomEvent(siteId, upsertModel)).pipe(
        map(() => trackingWidgetActions.updateCustomEventSuccess({ transationId: optimistic.id })),
        catchError((error: MADError) =>
          of(
            trackingWidgetActions.updateCustomEventError({
              error: error.message,
              transationId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const deleteCustomEventDetails: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(trackingWidgetActions.deleteCustomEventRequest)),
    concatMap(({ payload: { siteId, id }, meta: { optimistic } }) =>
      from(selfCareService.deleteCustomEvent(siteId, id)).pipe(
        map(() => trackingWidgetActions.deleteCustomEventSuccess({ transactionId: optimistic.id })),
        catchError((error: MADError) =>
          of(
            trackingWidgetActions.deleteCustomEventError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const updateInternalEventDetails: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(trackingWidgetActions.updateInternalEventRequest)),
    concatMap(({ payload: { siteId, updateModel }, meta: { optimistic } }) =>
      from(selfCareService.updateInternalEvent(siteId, updateModel)).pipe(
        map(() =>
          trackingWidgetActions.updateInternalEventSuccess({ transationId: optimistic.id })
        ),
        catchError((error: MADError) =>
          of(
            trackingWidgetActions.updateCustomEventError({
              error: error.message,
              transationId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const createCustomEvent: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(trackingWidgetActions.createCustomEventRequest)),
    concatMap(({ payload: { siteId, upsertModel } }) =>
      from(selfCareService.upsertCustomEvent(siteId, upsertModel)).pipe(
        map(id =>
          trackingWidgetActions.createCustomEventSuccess({
            siteId,
            displayModel: {
              customEventTypeId: id,
              name: upsertModel.name,
              state: upsertModel.state,
            },
          })
        ),
        catchError((error: MADError) =>
          of(trackingWidgetActions.createCustomEventError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const upsertCustomEventTranslation: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(trackingWidgetActions.updateCustomEventTranslationRequest)),
    concatMap(({ payload, meta: { optimistic } }) =>
      from(selfCareService.upsertCustomEventTranslation(payload.siteId, payload.upsertModel)).pipe(
        map(() =>
          trackingWidgetActions.updateCustomEventTranslationSuccess({
            transationId: optimistic.id,
          })
        ),
        catchError((error: MADError) =>
          of(
            trackingWidgetActions.updateCustomEventTranslationError({
              error: error.message,
              transationId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(error.message);
            })
          )
        )
      )
    )
  );

const deleteCustomEventTranslation: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(trackingWidgetActions.deleteCustomEventTranslationRequest)),
    concatMap(({ payload, meta: { optimistic } }) =>
      from(selfCareService.deleteCustomEventTranslation(payload.siteId, payload.deleteModel)).pipe(
        map(() =>
          trackingWidgetActions.deleteCustomEventTranslationSuccess({
            transactionId: optimistic.id,
          })
        ),
        catchError((error: MADError) =>
          of(
            trackingWidgetActions.deleteCustomEventTranslationError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

export const trackingWidgetEpics = combineEpics(
  updateCustomEventDetails,
  deleteCustomEventDetails,
  updateInternalEventDetails,
  createCustomEvent,
  upsertCustomEventTranslation,
  deleteCustomEventTranslation
);
