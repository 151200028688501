import React, { useState } from 'react';
import { useIsMutating } from 'react-query';

import { Select } from '@src/controls';
import { useFormatMessage } from '@src/i18n';
import { AvailableTrackingWidgetLanguage } from '@src/models';
import { withFormItem } from '@src/decorators';
import { Widget } from '../../components';
import { Details } from './details';
import { TrackingData } from '../hooks';
import { stylesheet } from 'typestyle';
import { WidgetCard } from '../../components/widget-card';
import { useMasterSiteId } from '@src/utils/hooks';

const SelectField = withFormItem(Select);
const languageOptions = Object.keys(AvailableTrackingWidgetLanguage).map(key => ({
  value: key,
  label: key,
}));

export const WidgetSection = ({ trackingData }: { trackingData: TrackingData }) => {
  const [locale, setLocale] = useState('en-US');
  const formatMessage = useFormatMessage();
  const siteId = useMasterSiteId();
  const generatingTrackingRecordCount = useIsMutating(['generateTrackingRecord']);

  const { externalId, trackingNumbers, trackingWidgetToken, trackingPageToken } = trackingData;

  return (
    <WidgetCard isLoading={generatingTrackingRecordCount >= 1}>
      {trackingWidgetToken ? (
        <>
          <Widget
            initTrackingWidget={() => {
              window.IngridDeliveryTrackingWidgetApi.renderPrivateMode({
                containerId: 'widget',
                locale,
                siteId,
                token: trackingPageToken,
              });
            }}
            key={locale}
          />
          <SelectField
            caseInsensitiveSearch={true}
            label={formatMessage('LOCALE')}
            options={languageOptions}
            value={locale}
            onChange={setLocale}
          />
          <Details
            locale={locale}
            orderNumber={externalId}
            trackingNumbers={trackingNumbers}
            authToken={trackingPageToken}
          />
        </>
      ) : (
        <span className={styles.placeholder}>
          {formatMessage('TRACKING_PLAYGROUND_WIDGET_SECTION_EMPTY_PLACEHOLDER')}
        </span>
      )}
    </WidgetCard>
  );
};

const styles = stylesheet({
  placeholder: {
    placeSelf: 'center',
  },
});
