import { Modal, Table, Tag, Tooltip } from '@src/controls';
import { mapping } from '@src/dictionaries';
import { mapCountryCodeToName } from '@src/dictionaries/mapping';
import { FormattedMessage } from '@src/i18n';
import {
  ConfigChangeModel,
  ConfigShippingCategoryModel,
  ExperimentModel,
  WidgetFeaturesModel,
  FaqWidgetConfiguration,
  ProductPageWidgetConfiguration,
} from '@src/models';
import { RootState } from '@src/modules';
import { getHasAccessTo } from '@src/modules/auth/auth-selectors';
import { configActions, configSelectors } from '@src/modules/config';
import { SitesSelectors } from '@src/modules/sites';
import { siwActions } from '@src/modules/siw';
import { services } from '@src/services';
import { defaultTheme } from '@src/styles';
import { useSelector } from '@src/utils/hooks';
import { Alert, Spin } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { classes, stylesheet } from 'typestyle';

interface OwnProps {
  isModalVisible: boolean;
  toggleVisibility: (to: boolean) => void;
}

const mapStateToProps = (state: RootState) => ({
  isSynced: state.config.isSynced,
  categories: configSelectors.getShippingCategoriesByDraftOrCurrentSite(state),
  changes: configSelectors.getChangesWithEmails(state),
  siteId: SitesSelectors.getSelectedSiteIdOrEmpty(state),
});

const dispatchProps = {
  promoteDraft: configActions.promoteDraftRequest,
  resetWidgetSession: siwActions.resetWidgetSession,
};

const getDetails = ({ command }: ConfigChangeModel, categories: ConfigShippingCategoryModel[]) => {
  if (command.updateCountrySettingsRequest) {
    const { country, countrySettings } = command.updateCountrySettingsRequest;
    const shippingCategories = countrySettings
      .map(
        countrySetting =>
          categories.find(category => category.id === countrySetting.categoryId)?.name
      )
      .join(', ');
    const countryName = mapping.mapCountryCodeToName(country);
    return [
      <FormattedMessage id="COUNTRY">
        {text => <span>{`${text}: ${countryName}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="PRESELECTION_ORDER">
        {text => <span>{`${text}: ${shippingCategories}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.createCategoryLabelRequest) {
    const { categoryId, name } = command.createCategoryLabelRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LABEL">{text => <span>{`${text}: ${name}`}</span>}</FormattedMessage>,
    ];
  }
  if (command.deleteCategoryLabelRequest) {
    const { categoryId, labelId } = command.deleteCategoryLabelRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LABEL_ID">
        {text => <span>{`${text}: ${labelId}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.updateCategoryLabelRequest) {
    const { categoryId, labelId } = command.updateCategoryLabelRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LABEL_ID">
        {text => <span>{`${text}: ${labelId}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.upsertCategoryLabelTranslationRequest) {
    const { categoryId, labelId, locale } = command.upsertCategoryLabelTranslationRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LABEL_ID">
        {text => <span>{`${text}: ${labelId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LOCALE">
        {text => <span>{`${text}: ${locale}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.deleteCategoryLabelTranslationRequest) {
    const { categoryId, labelId, locale } = command.deleteCategoryLabelTranslationRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LABEL_ID">
        {text => <span>{`${text}: ${labelId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LOCALE">
        {text => <span>{`${text}: ${locale}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.adjustCategoryLabelsOrderRequest) {
    const { categoryId } = command.adjustCategoryLabelsOrderRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.createDeliveryAddonRequest) {
    const { categoryId, name } = command.createDeliveryAddonRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LABEL">{text => <span>{`${text}: ${name}`}</span>}</FormattedMessage>,
    ];
  }
  if (command.deleteDeliveryAddonRequest) {
    const { categoryId, addonId } = command.deleteDeliveryAddonRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LABEL_ID">
        {text => <span>{`${text}: ${addonId}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.updateDeliveryAddonRequest) {
    const { categoryId, addonId } = command.updateDeliveryAddonRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LABEL_ID">
        {text => <span>{`${text}: ${addonId}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.upsertDeliveryAddonTranslationRequest) {
    const { categoryId, addonId, locale } = command.upsertDeliveryAddonTranslationRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LABEL_ID">
        {text => <span>{`${text}: ${addonId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LOCALE">
        {text => <span>{`${text}: ${locale}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.deleteDeliveryAddonTranslationRequest) {
    const { categoryId, addonId, locale } = command.deleteDeliveryAddonTranslationRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LABEL_ID">
        {text => <span>{`${text}: ${addonId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LOCALE">
        {text => <span>{`${text}: ${locale}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.adjustDeliveryAddonsOrderRequest) {
    const { categoryId } = command.adjustDeliveryAddonsOrderRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.addCategoryTagsRequest) {
    const { categoryId, tagName } = command.addCategoryTagsRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="TAG">{text => <span>{`${text}: ${tagName}`}</span>}</FormattedMessage>,
    ];
  }
  if (command.removeCategoryTagsRequest) {
    const { categoryId, tagName } = command.removeCategoryTagsRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="TAG">{text => <span>{`${text}: ${tagName}`}</span>}</FormattedMessage>,
    ];
  }
  if (command.updateRegionProduct) {
    const { regionId, method } = command.updateRegionProduct;
    return [
      <FormattedMessage id="REGION_ID">
        {text => <span>{`${text}: ${regionId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="CARRIER_PRODUCT">
        {text => <span>{`${text}: ${method}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.updateCategoryDetails) {
    const { categoryId, name } = command.updateCategoryDetails;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="NAME">
        {text => <span>{`${text}: ${name || ''}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.updateRegionDetails) {
    const { name, regionId } = command.updateRegionDetails;
    return [
      <FormattedMessage id="REGION_ID">
        {text => <span>{`${text}: ${regionId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="NAME">{text => <span>{`${text}: ${name}`}</span>}</FormattedMessage>,
    ];
  }
  if (command.updateCategoryTranslations) {
    const { categoryId, locale } = command.updateCategoryTranslations;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LOCALE">
        {text => <span>{`${text}: ${locale}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.updateWarehouseDetails) {
    const { warehouseId } = command.updateWarehouseDetails;
    return [
      <FormattedMessage id="WAREHOUSE">
        {text => <span>{`${text}: ${warehouseId}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.updateWarehouseCutoffTimes) {
    const { warehouseId, method, carrierCutoff } = command.updateWarehouseCutoffTimes;
    return [
      <FormattedMessage id="WAREHOUSE">
        {text => <span>{`${text}: ${warehouseId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="CARRIER_PRODUCT">
        {text => <span>{`${text}: ${method}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="CARRIER_CUTOFF">
        {text => <span>{`${text}: ${carrierCutoff}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.deleteCategoryTranslations) {
    const { categoryId, locale } = command.deleteCategoryTranslations;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="LOCALE">
        {text => <span>{`${text}: ${locale}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.createCategoryRequest) {
    const { name, deliveryType } = command.createCategoryRequest;
    return [
      <FormattedMessage id="NAME">{text => <span>{`${text}: ${name}`}</span>}</FormattedMessage>,
      <FormattedMessage id="DELIVERY_TYPE">
        {text => <span>{`${text}: ${deliveryType}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.deleteCategoryRequest) {
    const { categoryId } = command.deleteCategoryRequest;
    return [
      <FormattedMessage id="CATEGORY_ID">
        {text => <span>{`${text}: ${categoryId}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.createRegionRequest) {
    const { name } = command.createRegionRequest;
    return [
      <FormattedMessage id="NAME">{text => <span>{`${text}: ${name}`}</span>}</FormattedMessage>,
    ];
  }
  if (command.deleteRegionRequest) {
    const { regionId } = command.deleteRegionRequest;
    return [
      <FormattedMessage id="REGION_ID">
        {text => <span>{`${text}: ${regionId}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.createRegionProductRequest) {
    const { regionId, method } = command.createRegionProductRequest;
    return [
      <FormattedMessage id="REGION_ID">
        {text => <span>{`${text}: ${regionId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="CARRIER_PRODUCT">
        {text => <span>{`${text}: ${method}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.deleteRegionProductRequest) {
    const { regionId, method } = command.deleteRegionProductRequest;
    return [
      <FormattedMessage id="REGION_ID">
        {text => <span>{`${text}: ${regionId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="CARRIER_PRODUCT">
        {text => <span>{`${text}: ${method}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.createWarehouseRequest) {
    const {
      address: { name },
    } = command.createWarehouseRequest;
    return [
      <FormattedMessage id="NAME">{text => <span>{`${text}: ${name}`}</span>}</FormattedMessage>,
    ];
  }
  if (command.deleteWarehouseRequest) {
    const { id } = command.deleteWarehouseRequest;
    return [
      <FormattedMessage id="WAREHOUSE_ID">
        {text => <span>{`${text}: ${id}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.createWarehouseCutoffTimesRequest) {
    const { warehouseId, method } = command.createWarehouseCutoffTimesRequest;
    return [
      <FormattedMessage id="WAREHOUSE_ID">
        {text => <span>{`${text}: ${warehouseId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="CARRIER_PRODUCT">
        {text => <span>{`${text}: ${method}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.updateWidgetConfigurationRequest) {
    const {
      widgetConfiguration: { features, style },
    } = command.updateWidgetConfigurationRequest;
    if (typeof style?.accentColor1 === 'string') {
      return [
        <span key="accent-color-1">{`accentColor1: ${
          style.accentColor1 ? style.accentColor1 : '-'
        }`}</span>,
      ];
    }
    if (typeof style?.accentColor2 === 'string') {
      return [
        <span key="accent-color-2">{`accentColor2: ${
          style.accentColor2 ? style.accentColor2 : '-'
        }`}</span>,
      ];
    }
    if (!features) {
      return [];
    }
    const updatedFeature = Object.keys(features).find(
      (key: keyof WidgetFeaturesModel) => features[key] !== undefined
    );
    if (!updatedFeature) {
      return [];
    }
    return [
      <span key={`${updatedFeature}`}>{`${updatedFeature}: ${
        features[updatedFeature as keyof WidgetFeaturesModel]
      }`}</span>,
    ];
  }
  if (command.createWidgetCountryConfigurationRequest) {
    const { country } = command.createWidgetCountryConfigurationRequest;
    return [<span>{`Country: ${mapping.mapCountryCodeToName(country)}`}</span>];
  }
  if (command.updateWidgetCountryConfigurationRequest) {
    const { country, features, carrierLogos } = command.updateWidgetCountryConfigurationRequest;
    if (features) {
      const updatedFeature = Object.keys(features).find(
        (key: keyof WidgetFeaturesModel) => features[key] !== undefined
      );
      return [
        <span>{`Country: ${mapping.mapCountryCodeToName(country)}`}</span>,
        <span>{`${updatedFeature}: ${
          features[updatedFeature as keyof WidgetFeaturesModel]
        }`}</span>,
      ];
    } else {
      return [
        <span>{`Country: ${mapping.mapCountryCodeToName(country)}`}</span>,
        <span>{`Carrier logos: ${carrierLogos!.methods}`}</span>,
      ];
    }
  }
  if (command.setWarehouseShippingDateAdjustmentRequest) {
    const { warehouseId, shippingDateAdjustment } =
      command.setWarehouseShippingDateAdjustmentRequest;
    return [
      <FormattedMessage id="WAREHOUSE_ID">
        {text => (
          <span>
            {text}: {warehouseId}
          </span>
        )}
      </FormattedMessage>,
      <FormattedMessage id="START">
        {text => (
          <span>
            {text}: {shippingDateAdjustment.adjustStart || 0}
          </span>
        )}
      </FormattedMessage>,
      <FormattedMessage id="END">
        {text => (
          <span>
            {text}: {shippingDateAdjustment.adjustEnd || 0}
          </span>
        )}
      </FormattedMessage>,
    ];
  }
  if (command.deleteWarehouseShippingDateAdjustmentRequest) {
    const { warehouseId } = command.deleteWarehouseShippingDateAdjustmentRequest;
    return [
      <FormattedMessage id="WAREHOUSE_ID">
        {text => (
          <span>
            {text}: {warehouseId}
          </span>
        )}
      </FormattedMessage>,
    ];
  }
  if (command.deleteWarehouseCutoffTimesRequest) {
    const { method, warehouseId } = command.deleteWarehouseCutoffTimesRequest;
    return [
      <FormattedMessage id="WAREHOUSE_ID">
        {text => <span>{`${text}: ${warehouseId}`}</span>}
      </FormattedMessage>,
      <FormattedMessage id="SHIPPING_METHOD">
        {text => <span>{`${text}: ${method}`}</span>}
      </FormattedMessage>,
    ];
  }

  if (command.upsertCustomEventTypeRequest) {
    const { id, name } = command.upsertCustomEventTypeRequest;
    return [
      <FormattedMessage id="NAME">{text => <span>{`${text}: ${name}`}</span>}</FormattedMessage>,
      <FormattedMessage id="ID">{text => <span>{`${text}: ${id}`}</span>}</FormattedMessage>,
    ];
  }
  if (command.deleteCustomEventTypeRequest) {
    const { id } = command.deleteCustomEventTypeRequest;
    return [<FormattedMessage id="ID">{text => <span>{`${text}: ${id}`}</span>}</FormattedMessage>];
  }
  if (command.upsertCustomEventTypeTranslationRequest) {
    const { id, name, locale } = command.upsertCustomEventTypeTranslationRequest;
    return [
      <FormattedMessage id="NAME">{text => <span>{`${text}: ${name}`}</span>}</FormattedMessage>,
      <FormattedMessage id="ID">{text => <span>{`${text}: ${id}`}</span>}</FormattedMessage>,
      <FormattedMessage id="LOCALE">
        {text => <span>{`${text}: ${locale}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.deleteCustomEventTypeTranslationRequest) {
    const { id, locale } = command.deleteCustomEventTypeTranslationRequest;
    return [
      <FormattedMessage id="ID">{text => <span>{`${text}: ${id}`}</span>}</FormattedMessage>,
      <FormattedMessage id="LOCALE">
        {text => <span>{`${text}: ${locale}`}</span>}
      </FormattedMessage>,
    ];
  }
  if (command.configureInternalEventTypeRequest) {
    const { id, state } = command.configureInternalEventTypeRequest;
    return [
      <FormattedMessage id="ID">{text => <span>{`${text}: ${id}`}</span>}</FormattedMessage>,
      <FormattedMessage id="STATE">{text => <span>{`${text}: ${state}`}</span>}</FormattedMessage>,
    ];
  }
  if (command.deleteCarrierProductMappingRequest) {
    const { id } = command.deleteCarrierProductMappingRequest;
    return [<span>{`Mapping ID: ${id}`}</span>];
  }
  if (command.createCarrierProductMappingRequest) {
    const { carrier_product_ref } = command.createCarrierProductMappingRequest;
    return [<span>{`Carrier product reference: ${carrier_product_ref}`}</span>];
  }
  if (command.updateCarrierProductMappingRequest) {
    const { carrier_product_ref, id } = command.updateCarrierProductMappingRequest;
    return [
      <span>{`Mapping ID: ${id}`}</span>,
      <span>{`Carrier product reference: ${carrier_product_ref}`}</span>,
    ];
  }
  if (command.upsertCarrierProductSettingsRequest) {
    const { carrier_product_id } = command.upsertCarrierProductSettingsRequest;
    return [<span>{`Carrier product ID: ${carrier_product_id}`}</span>];
  }
  if (command.updateDeliveryUpsellWidgetConfigurationRequest) {
    const {
      widget_configuration: { features },
    } = command.updateDeliveryUpsellWidgetConfigurationRequest;
    if (!features) {
      return [];
    }
    const updatedFeature = Object.keys(features).find(
      (key: keyof WidgetFeaturesModel) => features[key] !== undefined
    );
    if (!updatedFeature) {
      return [];
    }
    return [
      <span key={`${updatedFeature}`}>{`${updatedFeature}: ${
        features[updatedFeature as keyof WidgetFeaturesModel]
      }`}</span>,
    ];
  }
  if (command.createDeliveryUpsellWidgetCountryConfigurationRequest) {
    const { country } = command.createDeliveryUpsellWidgetCountryConfigurationRequest;
    return [<span>{`Country: ${mapping.mapCountryCodeToName(country)}`}</span>];
  }
  if (command.updateDeliveryUpsellWidgetCountryConfigurationRequest) {
    const { country, features } = command.updateDeliveryUpsellWidgetCountryConfigurationRequest;
    if (features && features.features) {
      const updatedFeature = Object.keys(features.features).find(
        (key: keyof WidgetFeaturesModel) => features.features[key] !== undefined
      );
      return [
        <span>{`Country: ${mapping.mapCountryCodeToName(country)}`}</span>,
        <span>{`${updatedFeature}: ${
          features.features[updatedFeature as keyof WidgetFeaturesModel]
        }`}</span>,
      ];
    }
  }
  if (command.updateFaqWidgetDefaultConfigurationRequest) {
    const { features } = command.updateFaqWidgetDefaultConfigurationRequest;
    if (!features) {
      return [];
    }

    const updatedFeature = Object.keys(features).find(
      (key: keyof FaqWidgetConfiguration['features']) => features[key] !== undefined
    );

    if (!updatedFeature) {
      return [];
    }

    return [
      <span key={`${updatedFeature}`}>{`${updatedFeature}: ${
        features[updatedFeature as keyof FaqWidgetConfiguration['features']]
      }`}</span>,
    ];
  }
  if (command.upsertFaqWidgetCountryConfigurationRequest) {
    const { country, features } = command.upsertFaqWidgetCountryConfigurationRequest;

    if (!features) {
      return [];
    }

    const updatedFeature = Object.keys(features).find(
      (key: keyof FaqWidgetConfiguration['features']) => features[key] !== undefined
    );

    if (!updatedFeature) {
      return [];
    }

    return [
      <span>{`Country: ${mapping.mapCountryCodeToName(country)}`}</span>,
      <span>{`${updatedFeature}: ${
        features[updatedFeature as keyof FaqWidgetConfiguration['features']]
      }`}</span>,
    ];
  }
  if (command.updateProductPageWidgetDefaultConfigurationRequest) {
    const { features } = command.updateProductPageWidgetDefaultConfigurationRequest;
    if (!features) {
      return [];
    }

    const updatedFeature = Object.keys(features).find(
      (key: keyof ProductPageWidgetConfiguration['features']) => features[key] !== undefined
    );

    if (!updatedFeature) {
      return [];
    }

    return [
      <span key={`${updatedFeature}`}>{`${updatedFeature}: ${
        features[updatedFeature as keyof ProductPageWidgetConfiguration['features']]
      }`}</span>,
    ];
  }
  if (command.upsertProductPageWidgetCountryConfigurationRequest) {
    const { country, features } = command.upsertProductPageWidgetCountryConfigurationRequest;
    if (!features) {
      return [];
    }

    const updatedFeature = Object.keys(features).find(
      (key: keyof ProductPageWidgetConfiguration['features']) => features[key] !== undefined
    );

    if (!updatedFeature) {
      return [];
    }

    return [
      <span>{`Country: ${mapping.mapCountryCodeToName(country)}`}</span>,
      <span>{`${updatedFeature}: ${
        features[updatedFeature as keyof ProductPageWidgetConfiguration['features']]
      }`}</span>,
    ];
  }
  if (command.updateReceiptWidgetDefaultConfigurationRequest) {
    const { features } = command.updateReceiptWidgetDefaultConfigurationRequest;
    if (!features) {
      return [];
    }

    const featuresEntries = Object.entries<object>(features);

    const updatedFeatures = featuresEntries.map(([key, nested]) =>
      Object.entries(nested)
        .map(([fieldName, fieldValue]) => `${key}_${fieldName}: ${fieldValue}`)
        .join(', ')
    );

    const updatedFeaturesList = updatedFeatures.map(updatedFeature => (
      <span key={updatedFeature}>{updatedFeature}</span>
    ));

    return updatedFeaturesList;
  }
  if (command.upsertReceiptWidgetCountryConfigurationRequest) {
    const { country, features } = command.upsertReceiptWidgetCountryConfigurationRequest;
    if (!features) {
      return [];
    }

    const featuresEntries = Object.entries<object>(features);

    const updatedFeatures = featuresEntries.map(([key, nested]) =>
      Object.entries(nested)
        .map(([fieldName, fieldValue]) => `${key}_${fieldName}: ${fieldValue}`)
        .join(', ')
    );

    const countryName = mapCountryCodeToName(country);

    const updatedFeaturesList = updatedFeatures.map(updatedFeature => (
      <span key={updatedFeature}>{updatedFeature}</span>
    ));

    return [<span>{`Country: ${countryName}`}</span>, ...updatedFeaturesList];
  }
  return [];
};

type Props = OwnProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Component: React.FunctionComponent<Props> = ({
  isModalVisible,
  changes,
  toggleVisibility,
  promoteDraft,
  isSynced,
  categories,
  resetWidgetSession,
  siteId,
}) => {
  const hasAccessTo = useSelector(getHasAccessTo);

  const handleModalOk = () => {
    promoteDraft();
    resetWidgetSession();
    toggleVisibility(!isModalVisible);
  };

  const query = useQuery<ExperimentModel[]>({
    queryKey: ['experiments', siteId],
    queryFn: async () => {
      return await services.experimentService.listExperiments(siteId);
    },
    enabled: isModalVisible && hasAccessTo('mad:experiments'),
    initialData: [],
    cacheTime: 0,
  });

  const runningExperiment = (query.data as ExperimentModel[]).find(
    experiment => experiment.status === 'RUNNING'
  );

  let experimentAlert = null;

  if (runningExperiment) {
    const message =
      runningExperiment.traffic === 1000
        ? 'An experiment is running on 100% of site total traffic. Publishing your configuration draft will have no impact on site traffic until the experiment is finished.'
        : `An experiment is running on ${
            runningExperiment.traffic / 10
          }% of site total traffic. Publishing your configuration draft will impact ${
            (1000 - runningExperiment.traffic) / 10
          }% of total traffic.`;
    experimentAlert = <Alert message={message} type="warning" style={{ marginBottom: '16px' }} />;
  }

  return (
    <Modal
      width={960}
      visible={isModalVisible}
      title={<FormattedMessage id="PUBLISH_CHANGES" values={{ count: changes.length }} />}
      onCancel={() => toggleVisibility(!isModalVisible)}
      destroyOnClose={true}
      onOk={handleModalOk}
      okButtonProps={{ disabled: !isSynced }}
      okText={<FormattedMessage id="PUBLISH" />}
      extendFooterWith={
        !isSynced ? (
          <FormattedMessage id="DRAFT_OUT_OF_SYNC">
            {msg => (
              <Tooltip
                trigger="hover"
                placement="bottom"
                title={<FormattedMessage id="DRAFT_OUT_OF_SYNC_TOOLTIP_MSG" />}
              >
                <span className={styles.outOfSync}>{msg}</span>
              </Tooltip>
            )}
          </FormattedMessage>
        ) : undefined
      }
    >
      {query.isLoading && <Spin spinning />}
      {experimentAlert}
      <Table<ConfigChangeModel>
        dataSource={changes}
        hideTitle={true}
        rowKey={change => `${JSON.stringify(change)}`}
      >
        <Table.Column<ConfigChangeModel>
          title={<FormattedMessage id="COMMAND" />}
          key="command"
          render={(text, change) => {
            return (
              <Tag color={defaultTheme.color.primary} textColor="#fff" nonClickable={true}>
                {Object.keys(change.command).filter(command => !!command)}
              </Tag>
            );
          }}
        />
        <Table.Column<ConfigChangeModel>
          title={<FormattedMessage id="DETAILS" />}
          key="details"
          render={(text, change) => {
            return (
              <div className={defaultTheme.styles.flexColumn}>
                {getDetails(change, categories).map((detail, i) => (
                  <span key={i} className={classes(styles.cell, styles.detailCell)}>
                    {detail}
                  </span>
                ))}
              </div>
            );
          }}
        />
        <Table.Column<ConfigChangeModel>
          title={<FormattedMessage id="EDITED_AT" />}
          key="editedAt"
          render={(text, change) => {
            return (
              <span className={styles.cell}>
                {moment(change.updatedAt).format('YYYY-MM-DD HH:mm')}
              </span>
            );
          }}
        />
        <Table.Column<ConfigChangeModel>
          title={<FormattedMessage id="EDITED_BY" />}
          key="editedBy"
          render={(text, change) => {
            return <span className={styles.cell}>{change.email}</span>;
          }}
        />
      </Table>
    </Modal>
  );
};

export const PublishDraftModalContainer = connect(mapStateToProps, dispatchProps)(Component);

const styles = stylesheet({
  cell: {
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: '12px',
    fontWeight: 500,
  },
  detailCell: {
    wordBreak: 'break-word',
  },
  outOfSync: {
    fontWeight: 500,
    marginLeft: 'auto',
    color: 'rgba(0, 0, 0, 0.5)',
    marginRight: '15px',
  },
});
