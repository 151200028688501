import type { ListCarrierProductMappingResponse } from '@src/models';
import { deleteCarrierProductMapping } from '@src/services/selfcare-service';
import { useSiteId } from '@src/utils/hooks';
import { useOptimisticMutationOnDraft } from '@src/utils/queries';
import { useQueryClient } from 'react-query';
import type { Context } from './types';

export const useDeleteMappingMutation = () => {
  const queryClient = useQueryClient();
  const siteId = useSiteId();

  return useOptimisticMutationOnDraft(
    (effectiveSiteId, id: string) => deleteCarrierProductMapping(effectiveSiteId, { id }),
    {
      onMutate: async (effectiveSiteId, id) => {
        await queryClient.cancelQueries('carrier_product_mapping.list');

        const previousMappingsList = queryClient.getQueryData<
          ListCarrierProductMappingResponse | undefined
        >(['carrier_product_mapping.list', siteId]);

        queryClient.setQueryData<ListCarrierProductMappingResponse | undefined>(
          ['carrier_product_mapping.list', effectiveSiteId],
          () => {
            const mappings = previousMappingsList?.carrier_product_mappings ?? [];

            if (!mappings.length) {
              return previousMappingsList;
            }

            const updatedMappings = mappings.filter(mapping => mapping.id !== id);

            return { carrier_product_mappings: updatedMappings };
          }
        );

        return { previousMappingsList, effectiveSiteId };
      },

      onError: (_, __, context: Context) =>
        queryClient.setQueryData(
          ['carrier_product_mapping.list', context.effectiveSiteId],
          context.previousMappingsList
        ),

      onSettled: () => {
        queryClient.invalidateQueries('suggested_carrier_product_mappings.list');
        queryClient.invalidateQueries('carrier_product_mapping.list');
      },
    }
  );
};
