import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { LABELS } from '@src/dictionaries';
import { FormattedMessage, useFormatMessage } from '@src/i18n';
import { ConfigCategoryTagsModel } from '@src/models';
import { Input, message, Popconfirm, Tag } from 'antd';

interface Props {
  categoryTags: ConfigCategoryTagsModel[];
  handleAddCategoryTags: (newTag: string) => void;
  handleRemoveCategoryTags: (removeTag: string) => void;
}

export const CategoryTags: React.FC<Props> = ({
  categoryTags,
  handleAddCategoryTags,
  handleRemoveCategoryTags,
}) => {
  const [inputVisible, setInputVisible] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [isAddNewPopoverVisible, setAddNewPopoverVisibility] = React.useState(false);
  const formatMessage = useFormatMessage();

  const handleInputConfirm = () => {
    if (categoryTags.some(tag => tag.name === inputValue.trim())) {
      setInputVisible(false);
      message.error({
        content: formatMessage('TAG_WITH_SUCH_A_NAME_ALREADY_EXISTS'),
      });
      setInputValue('');
    } else if (inputValue.trim() === '') {
      setInputVisible(false);
      setInputValue('');
    } else {
      setInputVisible(false);
      setInputValue('');
      handleAddCategoryTags(inputValue);
    }
  };

  const newTagInput = React.useRef<Input | null>(null);
  React.useEffect(() => {
    if (inputVisible && newTagInput && newTagInput.current) {
      newTagInput.current.focus();
    }
  }, [inputVisible]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <FormattedMessage id="TAG" values={{ multiple: true }} />
      </div>
      <div className={styles.tagsWrapper}>
        {categoryTags.map((tag, idx) => (
          <Tag key={idx} className={styles.tag}>
            {tag.name}
            <Popconfirm
              title={
                <FormattedMessage
                  id="ARE_YOU_SURE_YOU_WANT_TO_DELETE_TAG_NAMED"
                  values={{ tag: tag.name }}
                />
              }
              okText={LABELS.YES}
              cancelText={LABELS.NO}
              onConfirm={() => {
                handleRemoveCategoryTags(tag.name);
              }}
            >
              <CloseOutlined className={styles.removeIcon} />
            </Popconfirm>
          </Tag>
        ))}
        <span>
          {inputVisible && (
            <Popconfirm
              visible={isAddNewPopoverVisible}
              title={<FormattedMessage id="ARE_YOU_SURE_YOU_WANT_TO_ADD_TAG_NAMED" />}
              onVisibleChange={setAddNewPopoverVisibility}
              onConfirm={() => {
                handleInputConfirm();
              }}
              onCancel={() => {
                setInputVisible(false);
                setInputValue('');
              }}
              destroyTooltipOnHide={true}
              trigger="contextMenu"
            >
              <Input
                ref={newTagInput}
                type="text"
                size="small"
                value={inputValue}
                onChange={(e: { target: HTMLInputElement }) => {
                  setInputValue(e.target.value);
                }}
                onBlur={() => setAddNewPopoverVisibility(true)}
                onPressEnter={() => setAddNewPopoverVisibility(true)}
                className={styles.inputStyle}
              />
            </Popconfirm>
          )}
          {!inputVisible && (
            <Tag
              className={styles.coverTag}
              onClick={() => {
                setInputVisible(true);
              }}
            >
              <PlusOutlined type="plus" className={styles.plusIcon} />
              <FormattedMessage id="ADD_NEW_TAG" />
            </Tag>
          )}
        </span>
      </div>
    </div>
  );
};

const styles = stylesheet({
  wrapper: {
    display: 'flex',
    marginBottom: '30px',
    marginTop: '10px',
    minHeight: '23px',
  },
  label: { flexBasis: '16.7%', color: 'rgba(0, 0, 0, 0.5)' },
  tagsWrapper: {
    flexBasis: '75%',
  },
  tag: {
    $nest: {
      '.anticon-close': {
        color: '#000000',
      },
    },
  },
  coverTag: {
    border: '1px dashed #d9d9d9',
  },
  inputStyle: {
    width: '100px',
    height: '21px',
    position: 'relative',
  },
  removeIcon: {
    fontSize: '9px !important',
    marginLeft: '4px',
    color: '#000000',
  },
  plusIcon: {
    fontSize: '11px !important',
    color: '#000000',
  },
});
