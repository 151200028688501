export const LABELS = {
  APPLY: 'Apply',
  EMPTY: '-',
  EXTERNAL_ID: 'External Ids',
  CARRIER_SERVICE: 'Carrier Product',
  ENABLED: 'Active',
  DISABLED: 'Inactive',
  COMPLETE: 'Complete',
  INCOMPLETE: 'Incomplete',
  DEFAULT_NAME: 'Default name',
  NEXT: 'Next',
  PREV: 'Previous',
  SUBMIT: 'Submit',
  BACK: 'Back',
  OK: 'Ok',
  NO: 'No',
  CANCEL: 'Cancel',
  CREATE: 'Create',
  DELETE: 'Delete',
  ADD: 'Add',
  ADD_TO_WATCHLIST: 'Add to watchlist',
  EDIT: 'Edit',
  EDIT_SHIPMENT: 'Edit shipment',
  SAVE: 'Save',
  CLEAR_ALL_FILTERS: 'Clear all filters',
  REMOVE: 'Remove',
  LINK: 'Link',
  DETAILS: 'Details',
  UNTITLED: 'Untitled',
  PLACEHOLDER_SELECT: 'Please select...',
  PRICING: 'Pricing',
  AVAILABILITY: 'Availability',
  SCHEDULE: 'Schedule',
  NORMAL: 'Normal',
  SPECIAL: 'Special',
  CONDITION: 'Condition',
  SHIPPING: 'Shipping',
  ADDRESS: 'Address',
  NEW: 'New',
  LINE_ITEM: 'Line Item',
  ITEM: 'item',
  ITEMS: 'items',
  CREATED_ON: 'Created on',
  FROM: 'From',
  TO: 'To',
  SEARCH: 'Search',
  USE: 'Use',
  RECIPIENT: 'Recipient',
  IMPORT_CSV: 'Import CSV',
  BOOK: 'Book',
  SCHEDULE_PICKUP: 'Schedule pickup',
  SHIPMENT: 'Shipment',
  DUPLICATE: 'Duplicate',
  CANCEL_DUPLICATE: 'Cancel & Duplicate',
  CREATE_RETURN: 'Create Return',
  ACTIVATE: 'Activate',
  CREATE_AND_BOOK_MULTIPLE_ACTION: 'Create & Book Multiple',
  CONFIRMATION_HEADER: 'Are you sure?',
  PROCEED: 'Proceed',
  CONFIRMATION_TEXT: 'Ok, I got it',
  CREATE_AND_BOOK_MULTIPLE_HEADER: 'Create and book multiple shipments',
  SELECT_ATTACHMENT: 'Click or drag file to this area',
  GET_ATTACHMENT: 'Get attachment',
  CONTENT: 'Content',
  TRACK_AND_TRACE: 'Track and trace',
  SEE_DETAILS: 'See details',
  LATEST_ACTIVITY: 'Latest activity',
  NO_ACTIVITY: 'No activity',
  ACTION: 'Action',
  SSCC_FULL: 'Tracking number (SSCC)',
  SSCC: 'SSCC',
  ORDER_NUMBER: 'Order number',
  STATUS: 'Status',
  LABEL: 'Label',
  DIMENSIONS: 'Dimensions',
  WEIGHT: 'Weight',
  MY_SHIPMENTS_FILTER_NOT_AVAILABLE: 'Only available for users having at least one shipment',
  MY_SHIPMENTS_FILTER_LABEL: 'Show only my shipments',
  SHIPMENTS_SEARCH_PLACEHOLDER:
    'Filter shipment by ID, Transport order ID, External ID, SSCC, Customer name and Order Number',
  TRANSPORT_ORDERS_SEARCH_PLACEHOLDER:
    'Filter by Order ID, External ID, Session ID and Customer Email',
  TRACKING_NUMBERS_SEARCH_PLACEHOLDER:
    'Filter by Tracking Number, External ID, TOS ID, Shipment ID',
  ADDRESSES_SEARCH_PLACEHOLDER: 'Search contacts',
  EXTRA_INFORMATION: 'Extra information',
  NEWEST: 'Newest',
  OLDEST: 'Oldest',
  WIDGET_GO_BACK: 'Go back to widget configuration',
  WIDGET_GO_FORWARD: 'Go to widget',
  CHANGE: 'Change',
  SELECT_SITE_MERCHANT_MODAL: 'Select merchant and site',
  EXPORT_TO_CSV: 'Export to CSV',
  DOWNLOAD_CSV: 'Download CSV',
  EXPORT_TO_CSV_ACTIVE: 'Exporting CSV in progress',
  ATTACHMENT: 'Attachment',
  UPDATE_CART: 'Update cart',
  TOTAL_VALUE: 'Total Value',
  CURRENCY: 'Currency',
  VOUCHER: 'Voucher',
  CART_ITEMS: 'Cart items:',
  SHIPMENT_EDIT_TITLE: 'Ingrid Shipment',
  LOG_OUT_TOOLTIP: 'Log out',
  DOWNLOAD_CONSOLIDATED_LABEL: 'Download consolidated PDF label',
  PREPARE_CONSOLIDATED_LABEL: 'Prepare consolidated PDF label',
  SHARE: 'Share',
  REMOVE_FROM_WATCHLIST: 'Remove from watchlist',
  USERS_MANAGEMENT: 'Users Management',
  CREATE_USER: 'Create User',
  USER: 'User',
  SUPER_USER: 'Super User',
  ADMIN: 'Admin',
  MERCHANT_ADMIN: 'Merchant Admin',
  UNKNOWN: 'Unknown',
  MONITOR_SHIPMENT: 'Monitor Shipment',
  MARK_ALL_AS_READ: 'Mark all as read',
  REFRESH: 'Refresh',
  CONFIG_EDITOR: 'Config editor',
  USERS_SEARCH_PLACEHOLDER: 'Search users by e-mail or name',
  NEW_WAREHOUSE: 'Add new warehouse',
  CHANGE_WAREHOUSE: 'Change warehouse',
  NEW_OPERATING_SCHEDULES: 'Add new operating schedule',
  SELECT_METHOD: 'Select shipping method',
  ACCEPT: 'Accept',
  YES: 'Yes',
  EDIT_NAME: 'Edit name',
  SENDER: 'Sender',
  CLEAR_ADDRESS: 'Clear address',
  DIRECTION: 'Direction',
  SEND_FREIGHT_DOCUMENTS: 'Send freight documents',
  DOWNLOAD_CUSTOMS_DECLARATION: 'Download customs declaration',
  DHL_BBX_INVOICE_SHIPMENTS: "DHL BBX Invoice shipments",
};
