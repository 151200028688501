import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;
import { CarrierAddonsModel } from '../../models';

export const GET_SHIPPING_METHODS_REQUEST = '@dictionaries/GET_SHIPPING_METHODS_REQUEST';
export const GET_SHIPPING_METHODS_SUCCESS = '@dictionaries/GET_SHIPPING_METHODS_SUCCESS';
export const GET_SHIPPING_METHODS_ERROR = '@dictionaries/GET_SHIPPING_METHODS_ERROR';
export const GET_CARRIER_ADDONS_REQUEST = '@dictionaries/GET_CARRIER_ADDONS_REQUEST';
export const GET_CARRIER_ADDONS_SUCCESS = '@dictionaries/GET_CARRIER_ADDONS_SUCCESS';
export const GET_CARRIER_ADDONS_ERROR = '@dictionaries/GET_CARRIER_ADDONS_ERROR';
export const GET_AVAILABLE_LOCALES_REQUEST = '@dictionaries/GET_AVAILABLE_LOCALES_REQUEST';
export const GET_AVAILABLE_LOCALES_SUCCESS = '@dictionaries/GET_AVAILABLE_LOCALES_SUCCESS';
export const GET_AVAILABLE_LOCALES_ERROR = '@dictionaries/GET_AVAILABLE_LOCALES_ERROR';

export const dictionariesActions = {
  getCarrierAddonsRequest: createAction(GET_CARRIER_ADDONS_REQUEST),
  getCarrierAddonsSuccess: createAction(
    GET_CARRIER_ADDONS_SUCCESS,
    resolve => (carrierAddons: CarrierAddonsModel[]) => resolve(carrierAddons)
  ),
  getCarrierAddonsError: createAction(
    GET_CARRIER_ADDONS_ERROR,
    resolve => (error: string) => resolve(error)
  ),
  getAvailableLocalesRequest: createAction(GET_AVAILABLE_LOCALES_REQUEST),
  getAvailableLocalesSuccess: createAction(
    GET_AVAILABLE_LOCALES_SUCCESS,
    resolve => (locales: string[]) => resolve(locales)
  ),
  getAvailableLocalesError: createAction(
    GET_AVAILABLE_LOCALES_ERROR,
    resolve => (error: string) => resolve(error)
  ),
};
