import { isNotNil } from 'ramda-adjunct';
import { combineEpics, Epic } from 'redux-observable';
import { from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, filter, finalize, map, mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';
import { getType, isActionOf } from 'typesafe-actions';
import { ERRORS } from '../../dictionaries';
import { UserProfileCreateShipmentModel } from '../../models';
import { RootAction, RootState, Services } from '../../modules';
import { MADError } from '../../utils';
import { routerActions } from '../router';
import { shipmentsActions } from '../shipments';
import { userprofileActions } from './';

const createTemplateWithApi: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(userprofileActions.createTemplateRequest)),
    switchMap(({ payload: { siteId, template } }) =>
      observableFrom(userProfileService.createWith(siteId, template)).pipe(
        tap(() => {
          messageService.success(ERRORS.CREATE_TEMPLATE_SUCCESS);
        }),
        mergeMap(res =>
          observableFrom([
            userprofileActions.createTemplateSuccess(res),
            routerActions.push({ name: 'TEMPLATE_LIST' }),
          ])
        ),
        catchError((error: MADError) =>
          observableOf(userprofileActions.createTemplateError(error.message)).pipe(
            tap(() => {
              messageService.error(error.message ? error.message : ERRORS.CREATE_FAILED);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const deleteTemplatesWithApi: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(userprofileActions.deleteTemplatesRequest)),
    switchMap(({ payload: { siteId, templateIds } }) =>
      observableFrom(userProfileService.deleteByIds(siteId, templateIds)).pipe(
        mapTo(userprofileActions.deleteTemplatesSuccess(templateIds)),
        tap(() => {
          messageService.success(ERRORS.DELETE_SUCCESS);
        }),
        catchError((error: MADError) =>
          observableOf(userprofileActions.deleteTemplatesError(error.message)).pipe(
            tap(() => {
              messageService.error(error.message ? error.message : ERRORS.DELETE_FAILED);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const listTemplatesWithApi: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(userprofileActions.getTemplateListRequest)),
    switchMap(({ payload: { siteId, tags }, meta }) =>
      observableFrom(userProfileService.listAll(siteId, tags)).pipe(
        map(userprofileActions.getTemplateListSuccess),
        catchError((error: MADError) =>
          observableOf(userprofileActions.getTemplateListError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          if (meta.onComplete) {
            meta.onComplete();
          }
        })
      )
    )
  );

const updateTemplateWithApi: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(userprofileActions.updateTemplateRequest)),
    switchMap(({ payload: { siteId, template } }) =>
      observableFrom(userProfileService.updateWith(siteId, template)).pipe(
        tap(() => {
          messageService.success(ERRORS.UPDATE_SUCCESS);
        }),
        mergeMap(res =>
          observableFrom([
            userprofileActions.updateTemplateSuccess(res),
            routerActions.push({ name: 'TEMPLATE_LIST' }),
          ])
        ),
        catchError((error: MADError) =>
          observableOf(userprofileActions.updateTemplateError(error.message)).pipe(
            tap(() => {
              messageService.error(error.message ? error.message : ERRORS.UPDATE_FAILED);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const getShipmentIds: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(userprofileActions.getShipmentIdsRequest)),
    switchMap(({ payload: { siteId } }) =>
      observableFrom(userProfileService.getShipmentIds(siteId)).pipe(
        map(res => userprofileActions.getShipmentIdsSuccess(res)),
        catchError((error: MADError) =>
          observableOf(userprofileActions.getShipmentIdsError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const createShipmentActionTriggers: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    map(action => {
      const model = new UserProfileCreateShipmentModel();
      model.action = action.type;
      switch (action.type) {
        case getType(shipmentsActions.bookParcelsRequest):
          model.shipmentId = action.payload.shipmentId;
          return userprofileActions.createShipmentActionRequest({
            model,
            siteId: action.payload.siteId,
          });
        case getType(shipmentsActions.bookPickupDeliveriesRequest):
          model.shipmentId = action.payload.shipmentId;
          return userprofileActions.createShipmentActionRequest({
            model,
            siteId: action.payload.siteId,
          });
        case getType(shipmentsActions.cancelShipmentRequest):
          model.shipmentId = action.payload.shipmentId;
          return userprofileActions.createShipmentActionRequest({
            model,
            siteId: action.payload.siteId,
          });
        case getType(shipmentsActions.createShipmentSuccess):
          model.shipmentId = action.payload.id;
          return userprofileActions.createShipmentActionRequest({
            model,
            siteId: action.payload.siteId,
          });
        case getType(shipmentsActions.editShipmentSuccess):
          model.shipmentId = action.payload.id;
          return userprofileActions.createShipmentActionRequest({
            model,
            siteId: action.payload.siteId,
          });
        case getType(shipmentsActions.createAndBookShipmentsIncreaseProgress):
          const multipleRequestModel = new UserProfileCreateShipmentModel();
          multipleRequestModel.action = getType(shipmentsActions.createShipmentSuccess);
          multipleRequestModel.shipmentId = action.payload.shipmentId;
          return userprofileActions.createShipmentActionRequest({
            model: multipleRequestModel,
            siteId: state$.value.sites.selectedSiteId || '',
          });
        default:
          return;
      }
    }),
    filter(isNotNil)
  );

const createShipmentAction: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { userProfileService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(userprofileActions.createShipmentActionRequest)),
    switchMap(({ payload: { model, siteId } }) =>
      observableFrom(userProfileService.createShipmentAction(siteId, model)).pipe(
        mapTo(userprofileActions.createShipmentActionSuccess()),
        catchError((error: MADError) =>
          observableOf(userprofileActions.createShipmentActionError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const getShipmentIdsTrigger: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf([userprofileActions.createShipmentActionSuccess])),
    map(payload =>
      userprofileActions.getShipmentIdsRequest({
        siteId: state$.value.sites.selectedSiteId || '',
      })
    )
  );

export const userdataEpics = combineEpics(
  createShipmentActionTriggers,
  createTemplateWithApi,
  deleteTemplatesWithApi,
  listTemplatesWithApi,
  updateTemplateWithApi,
  createShipmentAction,
  getShipmentIds,
  getShipmentIdsTrigger
);
