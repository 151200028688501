import { ConfigRegionModel, ConfigWarehouseModel, ExtendedShippingMethodModel } from '@src/models';
import { RootState } from '@src/modules';
import {
  getCustomShippingMethodsByDraftOrCurrentSite,
  getRegionById,
  getRegionsByCountry,
  getRegionsByDraftOrCurrentSiteId,
  getShippingMethodsByCurrentSite,
  getWarehouseState,
  groupShippingMethodsOptionsByCarrier,
} from '@src/modules/config/selectors';
import { SitesSelectors } from '@src/modules/sites';
import { configSelectors } from '@src/modules/config';
import { flatten, pipe, uniq } from 'ramda';
import { createSelector } from 'reselect';

const getWarehouseId = (state: RootState, id: string) => id;

export const getWarehousesByDraftOrCurrentSite = createSelector(
  configSelectors.getVariantSiteIdOrEmpty,
  SitesSelectors.getDraftSiteIdOrEmpty,
  SitesSelectors.getSelectedSiteIdOrEmpty,
  getWarehouseState,
  (variantSiteId, draftSiteId, siteId, state) => {
    return state[variantSiteId] || state[draftSiteId] || state[siteId] || [];
  }
);

const getWarehousesForCountry = (state: RootState, countryISO: string | undefined) => {
  const warehouses = getWarehousesByDraftOrCurrentSite(state);
  if (countryISO) {
    const warehousesUsedInRegionsForCountry = getRegionsByCountry(state, countryISO).map(
      region => region.warehouseId
    );

    return warehouses.filter(warehouse => warehousesUsedInRegionsForCountry.includes(warehouse.id));
  }
  return warehouses;
};

export const getWarehouseById = createSelector(
  getWarehousesByDraftOrCurrentSite,
  getWarehouseId,
  (warehouses, id) => warehouses.find(warehouse => warehouse.id === id)
);

export const getWarehouseByLinkedRegion = createSelector(
  getRegionById,
  getWarehousesByDraftOrCurrentSite,
  (region, warehouses) =>
    warehouses.find(warehouse => region && warehouse.id === region.warehouseId)
);

export const getWarehousesRegionsMap = createSelector(
  getRegionsByDraftOrCurrentSiteId,
  getWarehousesByDraftOrCurrentSite,
  (regions, warehouses) => {
    const warehousesRegionsMap: Record<string, string[]> = {};
    warehouses.map(w => {
      warehousesRegionsMap[w.id] = regions.filter(r => r.warehouseId === w.id).map(r => r.name);
    });
    return warehousesRegionsMap;
  }
);

export const getWarehouseIdtoRegionIdsMap = createSelector(
  getRegionsByDraftOrCurrentSiteId,
  getWarehousesByDraftOrCurrentSite,
  (regions, warehouses) =>
    warehouses.reduce(
      (whole, warehouse) => ({
        ...whole,
        [warehouse.id]: regions
          .filter(region => region.warehouseId === warehouse.id)
          .map(region => region.id),
      }),
      {} as Record<string, string[]>
    )
);

export const getRegionsIdsByWarehouseId = (state: RootState, warehouseId: string) => {
  const warehouseRegionsIdMap = getWarehouseIdtoRegionIdsMap(state);
  return warehouseRegionsIdMap[warehouseId] || [];
};

export const getRegionNameToWarehouseIdMap = createSelector(
  getRegionsByDraftOrCurrentSiteId,
  getRegionsIdsByWarehouseId,
  (regions, regionsIdsByWarehouseId) => {
    const regionNameToWarehouseIdMap = regions.reduce(
      (whole, region) => ({
        ...whole,
        [region.name]: regionsIdsByWarehouseId.find(regionId => regionId.includes(region.id)) || '',
      }),
      {} as { [key: string]: string }
    );
    return Object.keys(regionNameToWarehouseIdMap).reduce((acc, key) => {
      const _acc = acc;
      if (regionNameToWarehouseIdMap[key] !== '') {
        _acc[key] = regionNameToWarehouseIdMap[key];
      }
      return _acc;
    }, {} as { [key: string]: string });
  }
);

const getShippingMethodsOptionsForWarehouse = (
  state: RootState,
  regionId: string | undefined,
  warehouseId: string
) => {
  const regionsIds = regionId ? [regionId] : getRegionsIdsByWarehouseId(state, warehouseId);
  const warehouse = getWarehouseById(state, warehouseId);
  const shippingMethods = getShippingMethodsByCurrentSite(state);
  const regions = regionsIds.map(id => getRegionById(state, id));
  const customShippingMethods = getCustomShippingMethodsByDraftOrCurrentSite(state);

  const shippingMethodsFromRegions = pipe(
    (reg: (ConfigRegionModel | undefined)[]) =>
      reg.map(region => region?.carrierProducts.map(cp => cp.shippingMethod)),
    flatten,
    uniq
  )(regions);

  const filteredShippingMethods = shippingMethods.filter(sm =>
    shippingMethodsFromRegions.includes(sm.id)
  );

  return pipe(
    (sm: ExtendedShippingMethodModel[]) =>
      filterShippingMethodByWarehouseOperatingSchedule(sm, warehouse),
    groupShippingMethodsOptionsByCarrier(customShippingMethods)
  )(filteredShippingMethods);
};

const filterShippingMethodByWarehouseOperatingSchedule = (
  methods: ExtendedShippingMethodModel[],
  warehouse: ConfigWarehouseModel | undefined
) =>
  methods.filter(
    method => !(warehouse?.operatingSchedule && warehouse.operatingSchedule[method.id])
  );

const makeGetRegionsWithSelectedMethodForWarehouse = () =>
  createSelector(
    getRegionsByDraftOrCurrentSiteId,
    (_: RootState, shippingMethod: string) => shippingMethod,
    (_, __, warehouseId: string) => warehouseId,
    // Those two allow accesing props in the outer selector
    (regions, shippingMethod, warehouseId) =>
      regions.filter(
        region =>
          region.warehouseId === warehouseId &&
          region.carrierProducts.map(product => product.shippingMethod).includes(shippingMethod)
      )
  );

export const warehouseSelectors = {
  getShippingMethodsOptionsForWarehouse,
  getWarehousesByDraftOrCurrentSite,
  getWarehouseByLinkedRegion,
  getWarehousesRegionsMap,
  getWarehouseById,
  getRegionNameToWarehouseIdMap,
  makeGetRegionsWithSelectedMethodForWarehouse,
  getWarehousesForCountry,
};
