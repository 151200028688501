import { useFlags as useFlagsLD } from 'launchdarkly-react-client-sdk';

export type FeatureFlags = {
  v2Integrated: boolean;
  posthogEnabled: boolean;
}; // add your feature flags here

export const useFlags = () => {
  const featureFlags = useFlagsLD();

  return featureFlags as FeatureFlags; // LD do not gives other way to type feature flags
};
