import { useQuery } from 'react-query';
import {
  getNotificationsSetting,
  getSmartNotificationsSetting,
} from '@src/services/selfcare-service';
import { useMasterSiteId } from '@src/utils/hooks';

export const useGetNotificationsFeatures = () => {
  const siteId = useMasterSiteId();

  const { data, isLoading, isFetching } = useQuery(
    ['delivery_notification_settings/progress.list', siteId],
    () => {
      return getNotificationsSetting(siteId);
    },
    { enabled: !!siteId }
  );

  return { data, isLoading, isFetching };
};

export const useGetSmartNotificationsList = () => {
  const siteId = useMasterSiteId();

  const {
    data: smartNotificationsData,
    isLoading,
    isFetching,
  } = useQuery(
    ['delivery_notification_settings/smart.list', siteId],
    () => {
      return getSmartNotificationsSetting(siteId);
    },
    { enabled: !!siteId }
  );

  const isSmartLoading = isLoading || isFetching;

  return { smartNotificationsData, isSmartLoading };
};
