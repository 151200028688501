import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { DeliveryStatus, ShipmentListFiltersModel, ShipmentSummaryModel } from '../../models';
import { Reducer } from '../../modules';
import { shipmentsActions } from '../shipments';
import * as somSearchActions from './actions';

export type State = Readonly<{
  isFetching: boolean;
  error: string | null;
  shipmentSummaries: ShipmentSummaryModel[];
  filters: ShipmentListFiltersModel | null;
  orderNumber: string | null;
  temporaryShipmentStatus: {
    shipmentId: string;
    status: DeliveryStatus;
  } | null;
  shipmentsStatuses: {
    shipmentId: string;
    status: DeliveryStatus;
  }[];
}>;

const temporaryShipmentStatus: Reducer<State['temporaryShipmentStatus']> = (
  state = null,
  action
) => {
  switch (action.type) {
    case getType(shipmentsActions.createShipmentSuccess):
      return {
        shipmentId: action.payload.id,
        status: 'CREATED',
      };
    case getType(shipmentsActions.cancelShipmentSuccess):
      return {
        shipmentId: action.payload,
        status: 'CANCELED',
      };
    case getType(shipmentsActions.bookParcelsSuccess):
      return {
        shipmentId: action.payload.id,
        status: 'BOOKED',
      };
    case getType(shipmentsActions.bookPickupDeliveriesSuccess):
      return {
        shipmentId: action.payload.shipmentId,
        status: 'PICKUP_SCHEDULED',
      };
    default:
      return state;
  }
};

const isFetching: Reducer<State['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(somSearchActions.getShipmentSummariesRequest):
    case getType(somSearchActions.getShipmentMetadataRequest):
      return true;

    case getType(somSearchActions.getShipmentSummariesSuccess):
    case getType(somSearchActions.getShipmentSummariesError):
    case getType(somSearchActions.getShipmentMetadataSuccess):
    case getType(somSearchActions.getShipmentMetadataError):
      return false;

    default:
      return state;
  }
};

const error: Reducer<State['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(somSearchActions.getShipmentSummariesError):
    case getType(somSearchActions.getShipmentMetadataError):
      return action.payload;

    case getType(somSearchActions.getShipmentSummariesRequest):
    case getType(somSearchActions.getShipmentSummariesSuccess):
    case getType(somSearchActions.getShipmentMetadataSuccess):
    case getType(somSearchActions.getShipmentMetadataRequest):
      return null;

    default:
      return state;
  }
};

const shipmentSummaries: Reducer<State['shipmentSummaries']> = (state = [], action) => {
  switch (action.type) {
    case getType(somSearchActions.getShipmentSummariesSuccess):
      return action.payload.shipments;

    case getType(somSearchActions.getShipmentSummariesRequest):
    case getType(somSearchActions.getShipmentSummariesError):
      return [];

    default:
      return state;
  }
};

const filters: Reducer<State['filters']> = (state = null, action) => {
  switch (action.type) {
    case getType(somSearchActions.setActiveFilters):
      return action.payload;

    case getType(somSearchActions.clearActiveFilters):
      return null;

    default:
      return state;
  }
};

const orderNumber: Reducer<State['orderNumber']> = (state = null, action) => {
  switch (action.type) {
    case getType(somSearchActions.getShipmentMetadataSuccess):
      return action.payload.orderNumber;

    case getType(somSearchActions.getShipmentMetadataError):
    case getType(somSearchActions.getShipmentMetadataRequest):
      return null;

    default:
      return state;
  }
};

const shipmentsStatuses: Reducer<State['shipmentsStatuses']> = (state = [], action) => {
  switch (action.type) {
    case getType(somSearchActions.getShipmentsStatusesSuccess):
      return action.payload;

    case getType(somSearchActions.getShipmentMetadataSuccess):
      const { shipmentId, status } = action.payload;
      return [...state, { shipmentId, status }];

    case getType(somSearchActions.getShipmentsStatusesRequest):
    case getType(somSearchActions.getShipmentsStatusesError):
      return [];

    default:
      return state;
  }
};

const reducer: Reducer<State> = combineReducers({
  temporaryShipmentStatus,
  isFetching,
  error,
  shipmentSummaries,
  filters,
  orderNumber,
  shipmentsStatuses,
});

export default reducer;
