import * as shipmentsActions from './actions';

import { inc } from 'ramda';
import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { ShipmentModel } from '../../models';
import { Reducer } from '../../modules';
import { appActions } from '../app';
import { RequestState } from '../reports/reducers';

// STATE
export type State = Readonly<{
  draft: ShipmentModel | null;
  isFetching: boolean;
  error: string | null;
  byId: { [id: string]: ShipmentModel | undefined };
  multipleRequests: {
    requestsState: RequestState;
    progress: number;
    labelsFileNames: string[] | null;
  };
}>;

const multipleRequests: Reducer<State['multipleRequests']> = (
  state = { requestsState: RequestState.pending, progress: 0, labelsFileNames: null },
  action
) => {
  switch (action.type) {
    case getType(shipmentsActions.createAndBookShipmentsRequest):
      return { requestsState: RequestState.active, progress: 0, labelsFileNames: [] };

    case getType(shipmentsActions.createAndBookShipmentsIncreaseProgress):
      return { ...state, progress: inc(state.progress) };

    case getType(shipmentsActions.createAndBookShipmentsError):
      return { ...state, requestsState: RequestState.exception };

    case getType(shipmentsActions.createAndBookShipmentsSuccess):
      return { ...state, requestsState: RequestState.success };

    case getType(shipmentsActions.createAndBookShipmentsPartialSuccess):
      const labelsFileNames = state.labelsFileNames || [];
      return { ...state, labelsFileNames: [...labelsFileNames, ...action.payload] };

    case getType(shipmentsActions.createAndBookShipmentsReset):
      return { requestsState: RequestState.pending, progress: 0, labelsFileNames: null };

    default:
      return state;
  }
};

const isFetching: Reducer<State['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(shipmentsActions.getShipmentRequest):
    case getType(shipmentsActions.createShipmentRequest):
    case getType(shipmentsActions.cancelShipmentRequest):
    case getType(shipmentsActions.cancelAndDuplicateShipment):
    case getType(shipmentsActions.bookAndPrintParcelsRequest):
    case getType(shipmentsActions.bookParcelsRequest):
    case getType(shipmentsActions.bookPickupDeliveriesRequest):
      return true;

    case getType(shipmentsActions.getShipmentSuccess):
    case getType(shipmentsActions.getShipmentError):
    case getType(shipmentsActions.createShipmentSuccess):
    case getType(shipmentsActions.createShipmentError):
    case getType(shipmentsActions.cancelShipmentSuccess):
    case getType(shipmentsActions.cancelShipmentError):
    case getType(shipmentsActions.bookParcelsSuccess):
    case getType(shipmentsActions.bookParcelError):
    case getType(shipmentsActions.bookPickupDeliveriesError):
    case getType(shipmentsActions.bookPickupDeliveriesSuccess):
      return false;

    default:
      return state;
  }
};

const error: Reducer<State['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(shipmentsActions.createShipmentError):
    case getType(shipmentsActions.cancelShipmentError):
    case getType(shipmentsActions.bookParcelError):
    case getType(shipmentsActions.bookPickupDeliveriesError):
    case getType(shipmentsActions.createAndBookShipmentsError):
    case getType(shipmentsActions.editNoteError):
      return action.payload;

    case getType(shipmentsActions.getShipmentRequest):
    case getType(shipmentsActions.createShipmentRequest):
    case getType(shipmentsActions.cancelShipmentRequest):
    case getType(shipmentsActions.cancelAndDuplicateShipment):
    case getType(shipmentsActions.bookAndPrintParcelsRequest):
    case getType(shipmentsActions.bookParcelsRequest):
    case getType(shipmentsActions.bookPickupDeliveriesRequest):
    case getType(shipmentsActions.createAndBookShipmentsRequest):
    case getType(appActions.resetErrors):
    case getType(shipmentsActions.editNoteRequest):
    case getType(shipmentsActions.editNoteSuccess):
      return null;

    default:
      return state;
  }
};

const byId: Reducer<State['byId']> = (state = {}, action) => {
  switch (action.type) {
    case getType(shipmentsActions.getShipmentSuccess):
    case getType(shipmentsActions.createShipmentSuccess):
    case getType(shipmentsActions.bookParcelsSuccess):
    case getType(shipmentsActions.editShipmentSuccess):
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    default:
      return state;
  }
};

const draft: Reducer<State['draft']> = (state = null, action) => {
  switch (action.type) {
    case getType(shipmentsActions.draftShipmentClear):
      return null;

    case getType(shipmentsActions.draftShipmentLoad):
      return action.payload;

    default:
      return state;
  }
};

const reducer: Reducer<State> = combineReducers({
  isFetching,
  error,
  byId,
  draft,
  multipleRequests,
});

export default reducer;
