import { Popover } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { isNotEmpty } from 'ramda-adjunct';

import { AddButton, ComponentReturningChildren } from '@src/components';
import { Spinner } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { formStyle, regionFormContainerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { ConfigRegionModel, ConfigWarehouseModel } from '@src/models';
import { CarrierProductFormContainer } from './carrier-product-form-container';
import {
  CreateCarrierProductForm,
  CreateCarrierProductValues,
} from './components/create-carrier-product-form';

interface Props {
  region: ConfigRegionModel;
  createCarrierProduct: (values: CreateCarrierProductValues, onComplete: () => void) => void;
  isCreatingDraft: boolean;
  currentWarehouse: ConfigWarehouseModel | undefined;
}

const LabelWrapper = withFormItem(ComponentReturningChildren, regionFormContainerLayout);

export const CarrierProductsListContainer: React.FunctionComponent<Props> = ({
  region,
  createCarrierProduct,
  isCreatingDraft,
  currentWarehouse,
}) => {
  const [createProductModalVisible, setCreateProductModalVisible] = React.useState(false);
  const [isSendingRequest, setIsSendingRequest] = React.useState(false);

  return (
    <div className={formStyle}>
      <Spinner active={isSendingRequest || isCreatingDraft} delay={0}>
        <LabelWrapper
          label={<FormattedMessage id="CARRIER_PRODUCT" values={{ multiple: true }} />}
          className={formStyle}
        >
          {isNotEmpty(region.carrierProducts) ? (
            region.carrierProducts.map(carrierProduct => (
              <CarrierProductFormContainer
                product={carrierProduct}
                key={`${carrierProduct.shippingMethod}_${carrierProduct.deliveryTypes}`}
                regionId={region.id}
              />
            ))
          ) : (
            <div className={styles.emptyList}>
              <FormattedMessage id="CARRIER_PRODUCTS_EMPTY_LIST" />
            </div>
          )}
          <Popover
            content={
              <CreateCarrierProductForm
                regionId={region.id}
                createCarrierProduct={createCarrierProduct}
                isCreatingDraft={isCreatingDraft}
                setIsSendingRequest={setIsSendingRequest}
                setCreateProductModalVisible={setCreateProductModalVisible}
                currentWarehouse={currentWarehouse}
              />
            }
            title={<FormattedMessage id="NEW_CARRIER_PRODUCT" />}
            trigger="click"
            visible={createProductModalVisible}
            onVisibleChange={setCreateProductModalVisible}
            getPopupContainer={e => e.parentElement || document.body}
            destroyTooltipOnHide={true}
          >
            <AddButton
              className={
                isNotEmpty(region.carrierProducts) ? styles.addButton : styles.addButtonEmptyList
              }
            />
          </Popover>
        </LabelWrapper>
      </Spinner>
    </div>
  );
};

const styles = stylesheet({
  emptyList: {
    display: 'grid',
    placeContent: 'center',
    backgroundColor: 'hsla(0, 0%, 50%, 0.05)',
    padding: '15px 15px 0px 15px',
    color: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '4px',
  },
  addButton: {
    display: 'inline-block',
    paddingTop: 0,
  },
  addButtonEmptyList: {
    display: 'inline-block',
    paddingTop: 0,
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'hsla(0, 0%, 50%, 0.05)',
  },
});
