import { useFormatMessage } from '@src/i18n';
import { useSiteId } from '@src/utils/hooks';
import { Alert, Col, Row } from 'antd';
import * as React from 'react';
import { style as tss } from 'typestyle';
import { useSuggestedCarrierProductMappingsQuery } from '../query-commons';
import { AutogeneratedCarrierProductMappingsCreateModal } from './create';
import { AutogeneratedCarrierProductMappingsTable } from './table';

export const AutogeneratedCarrierProductMappings = () => {
  const formatMessage = useFormatMessage();
  const siteId = useSiteId();
  const { data, isLoading } = useSuggestedCarrierProductMappingsQuery(siteId);

  const [modalState, setModalState] = React.useState({ isVisible: false, carrierProductRef: '' });

  if (!data || !data.length) {
    return null;
  }

  return (
    <React.Fragment>
      {data.length && (
        <Alert
          message={formatMessage('SOME_PRODUCTS_NOT_MAPPED')}
          showIcon={false}
          type="warning"
        />
      )}
      <Row className={styles.section} data-cy="product-mappings-external-autogenerated-section">
        <Col span="4">{formatMessage('NON_MAPPED_PRODUCTS')}</Col>
        <Col span="20">
          <AutogeneratedCarrierProductMappingsTable
            onSetClicked={carrierProductRef => {
              setModalState({ isVisible: true, carrierProductRef });
            }}
            productMappingList={data}
            isLoading={isLoading}
          />
          <AutogeneratedCarrierProductMappingsCreateModal
            isVisible={modalState.isVisible}
            carrierProductRef={modalState.carrierProductRef}
            onCancel={() => setModalState({ isVisible: false, carrierProductRef: '' })}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

const styles = {
  section: tss({
    marginTop: '16px',
    marginBottom: '32px',
  }),
};
