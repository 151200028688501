import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { object, string } from 'yup';

import { Button, Input, Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { bigLabelLayout, inputStyle } from '@src/forms';
import { FormattedMessage } from '@src/i18n';

import { commons } from '@src/styles';
import { getFormikError } from '@src/utils/forms';
import { deliveryTypesOptions } from './helpers';

const InputField = withFormItem(Input, bigLabelLayout);
const SelectField = withFormItem(Select, bigLabelLayout);

interface Props {
  onSubmit: (values: CreateCategoryFormValues) => void;
}

export interface CreateCategoryFormValues {
  name: string;
  deliveryType: string;
}

const CreateRegionSchema = object({
  name: string().required(),
  deliveryType: string().required(),
});

export const CreateCategoryForm: React.FunctionComponent<Props> = ({ onSubmit }) => {
  return (
    <Formik
      onSubmit={values => onSubmit(values)}
      initialValues={{ name: '', deliveryType: '' }}
      validationSchema={CreateRegionSchema}
    >
      {({ handleSubmit, errors, touched, setFieldValue }) => (
        <form onSubmit={handleSubmit} className={styles.formWrapper}>
          <Field name="name">
            {({ field }: FieldProps<CreateCategoryFormValues['name']>) => (
              <InputField
                {...field}
                label={<FormattedMessage id="NAME" />}
                labelAlign="left"
                error={getFormikError(touched.name, errors.name)}
                className={inputStyle}
              />
            )}
          </Field>
          <Field name="deliveryType">
            {({ field }: FieldProps<CreateCategoryFormValues['deliveryType']>) => (
              <SelectField
                label={<FormattedMessage id="DELIVERY_TYPE" />}
                options={deliveryTypesOptions}
                labelAlign="left"
                error={getFormikError(touched.deliveryType, errors.deliveryType)}
                {...field}
                onChange={value => setFieldValue('deliveryType', value)}
                className={inputStyle}
              />
            )}
          </Field>
          <div className={commons.flex}>
            <Button type="primary" htmlType="submit" className={styles.submitButton}>
              <FormattedMessage id="ADD" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

const styles = stylesheet({
  submitButton: {
    marginLeft: 'auto',
    marginTop: '10px',
  },
  formWrapper: {
    width: 300,
  },
});
