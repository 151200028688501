import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';
import * as React from 'react';

const Component: React.FunctionComponent<ButtonProps> = props => {
  const { children, ...restProps } = props;

  return <Button {...restProps}>{children}</Button>;
};

export default Component;
