import * as qs from 'query-string';
import { SessionEventDTO, SessionEventModel } from '../models';
import { apiUtils, authFetch } from '../utils';

const COS_EVENTS_URL = apiUtils.getApiUrl() + '/frontend/cos_events';

export async function listEvents(
  sessionID: string
): Promise<{ events: SessionEventModel[]; rawEvents: {}[] }> {
  const options: RequestInit = {
    method: 'GET',
  };

  return authFetch<{ events: SessionEventDTO[] }>(
    `${COS_EVENTS_URL}/events.list?` + qs.stringify({ session_id: sessionID }),
    options
  ).then(res => ({
    events: res.events ? res.events.map(SessionEventModel.createFromDTO) : [],
    rawEvents: res.events,
  }));
}
