import { DatePicker, Modal } from 'antd';
import moment from 'moment';
import * as React from 'react';

type Props = {
  visible: boolean;
  onCancel: () => void;
  onOk: (dateRange: moment.Moment[]) => void;
};

export const ExportAllShipmentsModal: React.FunctionComponent<Props> = ({
  visible,
  onCancel,
  onOk,
}) => {
  const [dateRange, setDateRange] = React.useState([moment(Date.now()), moment(Date.now())]);
  return (
    <Modal
      title="Export to CSV"
      visible={visible}
      okText="Export"
      onCancel={onCancel}
      onOk={() => onOk(dateRange)}
    >
      <>
        <div style={{ marginBottom: '10px' }}>Created at:</div>
        <DatePicker.RangePicker
          onChange={date => setDateRange(date as moment.Moment[])}
          defaultValue={[moment(Date.now()), moment(Date.now())]}
        />
      </>
    </Modal>
  );
};
