import { Modal as AntModal } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { Button } from '../controls';
import { LABELS } from '../dictionaries';
import { defaultTheme } from '../styles';

export interface IProps extends ModalProps {
  extendFooterWith?: JSX.Element;
  hideOkButton?: boolean;
  hideCancelButton?: boolean;
}

const Modal: React.StatelessComponent<IProps> = props => {
  const { children, extendFooterWith, hideOkButton, hideCancelButton, ...restProps } = props;

  const Footer = (
    <div className={styles.footerWrapper}>
      {extendFooterWith}
      <div className={extendFooterWith ? '' : styles.footerDefaultButtons}>
        {!hideCancelButton && (
          <Button {...props.cancelButtonProps} key="back" onClick={props.onCancel}>
            {props.cancelText || LABELS.CANCEL}
          </Button>
        )}
        {!hideOkButton && (
          <Button {...props.okButtonProps} key="submit" type="primary" onClick={props.onOk}>
            {props.okText || LABELS.OK}
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <AntModal width={defaultTheme.modalWidth} footer={Footer} {...restProps}>
      {children}
    </AntModal>
  );
};

const styles = {
  footerWrapper: tss({
    display: 'flex',
    alignItems: 'center',
  }),
  footerDefaultButtons: tss({
    marginLeft: 'auto',
  }),
};

export default Modal;
