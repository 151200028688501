import { combineEpics, Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { ERRORS } from '@src/dictionaries';
import { MADError } from '@src/utils';
import { isNotNil } from 'ramda-adjunct';
import { usersActions } from '.';
import { RootAction, RootState, Services } from '..';
import { MerchantsSelectors } from '../merchants';
import { routerActions } from '../router';
import { SitesSelectors } from '../sites';

const listUsers: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { usersService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf([usersActions.fetchListUsers])),
    switchMap(() => {
      const merchantId = MerchantsSelectors.getSelectedMerchantId(state$.value);
      const siteId = SitesSelectors.getSelectedSiteIdOrEmpty(state$.value);

      return from(usersService.listUsers(merchantId, siteId)).pipe(
        map(res => usersActions.fetchListUsersSuccess(res)),
        catchError((error: MADError) =>
          of(usersActions.fetchListUsersError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      );
    })
  );

const createUser: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { usersService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(usersActions.fetchCreateUser)),
    switchMap(action =>
      from(
        usersService.createUser(
          action.payload,
          MerchantsSelectors.getSelectedMerchantId(state$.value),
          SitesSelectors.getSelectedSiteIdOrEmpty(state$.value)
        )
      ).pipe(
        tap(() => messageService.success('Creating new user completed successfully')),
        switchMap(user =>
          from(
            [usersActions.fetchCreateUserSuccess(user), ...action.meta.onComplete(user.id)].filter(
              isNotNil
            )
          )
        ),
        catchError(error =>
          of(usersActions.fetchCreateUserError(error.message)).pipe(
            tap(() => {
              messageService.error(ERRORS.CREATE_FAILED);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const createUserRedirect: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf([usersActions.fetchCreateUserSuccess, usersActions.updateUserSuccess])),
    map(() => routerActions.push({ name: 'USERS' }))
  );

const deleteUser: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { usersService, loggingService, messageService }
) =>
  action$.pipe(
    filter(isActionOf(usersActions.fetchDeleteUser)),
    switchMap(action => {
      const merchantId = MerchantsSelectors.getSelectedMerchantId(state$.value);
      const siteId = SitesSelectors.getSelectedSiteIdOrEmpty(state$.value);

      return from(usersService.deleteUser(action.payload, merchantId, siteId)).pipe(
        map(() => usersActions.fetchDeleteUserSuccess(action.payload)),
        tap(() => messageService.success(ERRORS.DELETE_SUCCESS)),
        catchError((error: MADError) =>
          of(usersActions.fetchDeleteUserError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              messageService.error(ERRORS.DELETE_FAILED);
            })
          )
        )
      );
    })
  );

const getUser: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { usersService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(usersActions.getUserRequest)),
    switchMap(action =>
      from(
        usersService.getUserWith(
          action.payload.userId,
          MerchantsSelectors.getSelectedMerchantId(state$.value),
          SitesSelectors.getSelectedSiteIdOrEmpty(state$.value)
        )
      ).pipe(
        map(res => usersActions.getUserSuccess(res)),
        catchError((error: MADError) =>
          of(usersActions.getUserError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const updateUser: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { usersService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(usersActions.updateUserRequest)),
    switchMap(action =>
      from(
        usersService.updateUser(
          action.payload,
          MerchantsSelectors.getSelectedMerchantId(state$.value),
          SitesSelectors.getSelectedSiteIdOrEmpty(state$.value)
        )
      ).pipe(
        tap(() => messageService.success(ERRORS.USER_UPDATE_SUCCESS)),
        switchMap(user =>
          from(
            [usersActions.updateUserSuccess(user), ...action.meta.onComplete(user.id)].filter(
              isNotNil
            )
          )
        ),
        catchError((error: MADError) =>
          of(usersActions.updateUserError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              messageService.error(ERRORS.USER_UPDATE_ERROR);
            })
          )
        )
      )
    )
  );

const getGroupList: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { usersService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(usersActions.getGroupListRequest)),
    switchMap(({ payload: { siteId, merchantId } }) => {
      return from(usersService.getGroups(merchantId, siteId)).pipe(
        map(res => usersActions.getGroupListSuccess(res)),
        catchError((error: MADError) =>
          of(usersActions.getGroupListError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      );
    })
  );

const assignUserToGroup: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { usersService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(usersActions.assignUserToGroupRequest)),
    concatMap(action =>
      from(
        usersService.assignUserToGroup(
          action.payload.userId,
          action.payload.groupId,
          SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
          MerchantsSelectors.getSelectedMerchantId(state$.value)
        )
      ).pipe(
        map(() => usersActions.assignUserToGroupSuccess()),
        catchError((error: MADError) =>
          of(usersActions.assignUserToGroupError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const removeGroupFromUser: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { usersService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(usersActions.removeGroupFromUserRequest)),
    concatMap(action =>
      from(
        usersService.removeGroupsFromUser(
          action.payload.userId,
          action.payload.groupId,
          SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
          MerchantsSelectors.getSelectedMerchantId(state$.value)
        )
      ).pipe(
        map(() => usersActions.removeGroupFromUserSuccess()),
        catchError((error: MADError) =>
          of(usersActions.removeGroupFromUserError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const createGroup: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { usersService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(usersActions.createGroupRequest)),
    switchMap(({ payload: { model }, meta: { onComplete } }) =>
      from(usersService.createGroup(model)).pipe(
        map(res => usersActions.createGroupSuccess({ ...model, id: res.groupId })),
        catchError((error: MADError) =>
          of(usersActions.createGroupError(error.message)).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete();
          }
        })
      )
    )
  );

const deleteGroup: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { usersService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(usersActions.deleteGroupRequest)),
    switchMap(({ payload: { id }, meta: { optimistic } }) =>
      from(usersService.deleteGroup(id)).pipe(
        map(() => usersActions.deleteGroupSuccess({ id, transactionId: optimistic.id })),
        catchError((error: MADError) =>
          of(
            usersActions.deleteGroupError({ error: error.message, transactionId: optimistic.id })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const listRoles: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { usersService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(usersActions.listRolesRequest)),
    switchMap(() =>
      from(usersService.listRoles()).pipe(
        map(usersActions.listRolesSuccess),
        catchError((error: MADError) =>
          of(usersActions.listRolesError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const updateGroup: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { usersService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(usersActions.updateGroupRequest)),
    switchMap(({ payload: { model }, meta: { onComplete } }) =>
      from(usersService.updateGroup(model)).pipe(
        map(usersActions.updateGroupSuccess),
        catchError((error: MADError) =>
          of(usersActions.updateGroupError(error.message)).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete();
          }
        })
      )
    )
  );

const listUserSummaries: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { usersService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(usersActions.listUserSummariesRequest)),
    switchMap(({ payload: { userIds, siteId, isSuperuser, merchantId } }) =>
      from(
        isSuperuser
          ? usersService.listUserSummaries(userIds)
          : usersService.listMerchantUserSummaries(userIds, siteId, merchantId)
      ).pipe(
        map(usersActions.listUserSummariesSuccess),
        catchError((error: MADError) =>
          of(usersActions.listUserSummariesError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

export const usersEpics = combineEpics(
  listUsers,
  createUser,
  createUserRedirect,
  deleteUser,
  getUser,
  updateUser,
  getGroupList,
  assignUserToGroup,
  createGroup,
  deleteGroup,
  listRoles,
  updateGroup,
  removeGroupFromUser,
  listUserSummaries
);
