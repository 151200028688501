import { LABELS } from '@src/dictionaries';
import { useFormatMessage } from '@src/i18n';
import { sendFreightDocuments } from '@src/services/som-service';
import { useMasterSiteId } from '@src/utils/hooks';
import { Button, Form, Input, message, Modal } from 'antd';
import type { Rule } from 'antd/lib/form';
import React, { FC, Fragment, useState } from 'react';
import { useMutation } from 'react-query';
import { style as tss } from 'typestyle';

const { Item, useForm } = Form;

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const rules: Rule[] = [
  { required: true, message: 'Email is required!' },
  { type: 'email', message: 'This is not a valid email!' },
];

type FormValues = {
  email: string;
};

type Props = {
  returnShipmentId: string;
};

export const LinkToPrint: FC<Props> = ({ returnShipmentId }) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [form] = useForm<FormValues>();
  const siteId = useMasterSiteId();
  const formatMessage = useFormatMessage();

  const { isLoading, mutate } = useMutation({
    mutationKey: ['shipments.send_freight_documents', siteId, returnShipmentId],
    mutationFn: ({ email }: FormValues) => sendFreightDocuments(email, returnShipmentId, siteId),
    onSuccess: () => {
      setModalVisibility(false);
      message.success(formatMessage('LINK_TO_PRINT.SUCCESS_MESSAGE'));
    },
  });

  return (
    <Fragment>
      <Button onClick={() => setModalVisibility(true)} className={styles.downloadButton}>
        {LABELS.SEND_FREIGHT_DOCUMENTS}
      </Button>
      <Modal
        title={formatMessage('LINK_TO_PRINT.MODAL_TITLE')}
        centered
        visible={isModalVisible}
        okText={formatMessage('LINK_TO_PRINT.SEND_EMAIL')}
        onOk={form.submit}
        confirmLoading={isLoading}
        onCancel={() => setModalVisibility(false)}
      >
        <Form requiredMark={false} form={form} onFinish={mutate} {...formLayout}>
          <Item label={formatMessage('LINK_TO_PRINT.FORM_ITEM.EMAIL')} name="email" rules={rules}>
            <Input />
          </Item>
          <Item label={formatMessage('LINK_TO_PRINT.FORM_ITEM.DOCUMENTS')}>
            <span className="ant-form-text">return-label.pdf</span>
          </Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

const styles = {
  downloadButton: tss({
    width: 'min-content',
  }),
};
