import { RobotOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { comparator, sort, toLower } from 'ramda';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { AddButton, ListFixedHeader } from '@src/components';
import { DOMAIN } from '@src/dictionaries';
import { FormattedMessage } from '@src/i18n';
import { merchantsActions, MerchantsSelectors } from '@src/modules/merchants';
import { routerActions } from '@src/modules/router';
import { services } from '@src/services';
import { MADError } from '@src/utils';
import { useDispatch, useSelector } from '@src/utils/hooks';
import { CreateForm, MerchantsList } from './components';

export const MerchantsListContainer: React.FunctionComponent = () => {
  const merchants = useSelector(MerchantsSelectors.getMerchantsWithSitesWithoutDrafts);
  const [createPopoverVisible, setCreatePopoverVisible] = React.useState(false);

  const dispatch = useDispatch();

  const handleCreateMerchant = (name: string) => {
    setCreatePopoverVisible(false);
    services.selfCareService
      .createMerchant(name)
      .then(({ id }) => {
        services.notificationService.success(`Merchant '${name}' created`);
        dispatch(routerActions.push({ name: 'MERCHANT_DETAILS', detailsMerchantId: id }));
        dispatch(merchantsActions.listMerchants());
      })
      .catch((err: MADError) => {
        services.loggingService.logError(err);
        services.notificationService.error('Failed to create merchant', 'Error', 4);
      });
  };

  const merchantNames = merchants.map(m => m.name).map(toLower);

  const sortedMerchants = sort(
    comparator((a, b) => toLower(a.name) < toLower(b.name)),
    merchants
  );

  return (
    <>
      <ListFixedHeader title={DOMAIN.MERCHANTS} IconComponent={RobotOutlined} showSearch={false} />
      <Popover
        destroyTooltipOnHide
        trigger="click"
        title={<FormattedMessage id="NEW_MERCHANT" />}
        visible={createPopoverVisible}
        onVisibleChange={setCreatePopoverVisible}
        content={<CreateForm onSubmit={handleCreateMerchant} forbidenNames={merchantNames} />}
      >
        <AddButton text={<FormattedMessage id="ADD_NEW" />} className={styles.addButton} />
      </Popover>
      <MerchantsList isFetching={!merchants} merchants={sortedMerchants} />
    </>
  );
};

const styles = stylesheet({
  addButton: {
    width: '100px',
    padding: 0,
    margin: '15px 0px',
  },
});
