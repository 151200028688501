import React from 'react';
import { Tooltip } from 'antd';
import { stylesheet } from 'typestyle';

import { services } from '@src/services';
import { useFormatMessage } from '@src/i18n';
import { TrackingData } from '../hooks';
import { CopyRow } from '../../components';
import { trimTrackingPageUrl } from '../../utils';

const env = services.envService.getEnvironmentalVariables();

export const Details = ({
  locale,
  orderNumber,
  trackingNumbers,
  authToken,
}: {
  locale: string;
  orderNumber: TrackingData['externalId'];
  trackingNumbers: TrackingData['trackingNumbers'];
  authToken: TrackingData['trackingPageToken'];
}) => {
  const formatMessage = useFormatMessage();
  const trackingPageUrl = new URL(`${locale}/${authToken}`, env.MAD_TRACKING_PAGE_LINK).href;

  return (
    <div className={styles.rows}>
      {trackingNumbers?.map((trackingNumber, idx) => (
        <CopyRow
          key={`${idx}-${trackingNumber}`}
          textToCopy={trackingNumber}
          notificationText={formatMessage('TRACKING_NUMBER_COPY_NOTIFICATION')}
        >
          {formatMessage('TRACKING_NUMBER_WITH_LABEL', {
            trackingNumber,
          })}
        </CopyRow>
      ))}
      {orderNumber && (
        <CopyRow
          textToCopy={orderNumber}
          notificationText={formatMessage('EXTERNAL_ID_COPY_NOTIFICATION')}
        >
          {formatMessage('EXTERNAL_ID_WITH_LABEL', { externalId: orderNumber })}
        </CopyRow>
      )}
      {authToken && (
        <CopyRow
          textToCopy={trackingPageUrl}
          notificationText={formatMessage('TRACKING_PAGE_LINK_COPY_NOTIFICATION')}
        >
          <Tooltip title={trackingPageUrl}>
            <a href={trackingPageUrl} target="_blank" rel="noopener noreferrer">
              {trimTrackingPageUrl(trackingPageUrl)}
            </a>
          </Tooltip>
        </CopyRow>
      )}
    </div>
  );
};

const styles = stylesheet({
  rows: { display: 'grid', gap: '8px' },
});
