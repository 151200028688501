import { Form as AntdForm, FormProps as AntdFormProps } from 'antd';
import * as React from 'react';

const Form: React.FC<AntdFormProps> = props => {
  const { children, ...restProps } = props;

  return <AntdForm {...restProps}>{children}</AntdForm>;
};

export default Form;
