import { Button, Tooltip } from 'antd';
import { all, insert, without } from 'ramda';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { Modal, Alert } from '@src/controls';
import { LABELS } from '@src/dictionaries';
import { FormattedMessage } from '@src/i18n';
import { ConfigShippingCategoryModel } from '@src/models';

import { defaultTheme } from '@src/styles';
import { useDeepCompareEffect } from '@src/utils/hooks';
import {
  CategoryRowPlaceholder,
  PreselectionOrderModalTable,
} from './preselection-order-modal-table';

const getFallbackState = (
  categories: ConfigShippingCategoryModel[],
  countryISO: string
): { [categoryId: string]: boolean } =>
  categories.reduce((acc, category) => {
    if (category.countrySettings && category.countrySettings[countryISO]) {
      return {
        ...acc,
        [category.id]: category.countrySettings[countryISO]!.useForFallback || false,
      };
    }
    return {
      ...acc,
      [category.id]: false,
    };
  }, {});

type OwnProps = {
  isVisible: boolean;
  sortedCategories: ConfigShippingCategoryModel[];
  unsortedCategories: ConfigShippingCategoryModel[];
  countryISO: string;
  editedCategoryId?: string;
  onAccept: (categoryOrder: string[], fallbacks: { [categoryId: string]: boolean }) => void;
  onCancel: () => void;
};

export const PreselectionOrderModal: React.FC<OwnProps> = ({
  isVisible,
  sortedCategories,
  unsortedCategories,
  countryISO,
  editedCategoryId,
  onAccept,
  onCancel,
}) => {
  const allCategories = [...sortedCategories, ...unsortedCategories];

  const [sortedCategoriesState, setSortedCategoriesState] = React.useState(
    sortedCategories.map(c => c.id)
  );
  const [unsortedCategoriesState, setUnsortedCategoriesState] = React.useState(
    unsortedCategories.map(c => c.id)
  );
  const [fallbackState, setFallbackState] = React.useState(
    getFallbackState(allCategories, countryISO)
  );

  useDeepCompareEffect(() => {
    setSortedCategoriesState(sortedCategories.map(c => c.id));
  }, [sortedCategories]);
  useDeepCompareEffect(() => {
    setUnsortedCategoriesState(unsortedCategories.map(c => c.id));
  }, [unsortedCategories]);
  useDeepCompareEffect(() => {
    setFallbackState(getFallbackState(allCategories, countryISO));
  }, [sortedCategories, unsortedCategories]);

  const getDataSource = (order: string[]) => order.map(id => allCategories.find(c => c.id === id)!);

  const moveCategory = (source: string, target: string) => {
    const isSourceUnsorted = unsortedCategoriesState.includes(source);
    if (isSourceUnsorted) {
      setFallbackState(prevState => {
        return {
          ...prevState,
          [source]: false,
        };
      });
      setUnsortedCategoriesState(prevState => without([source], prevState));
      setSortedCategoriesState(prevState => {
        const targetIndex = prevState.indexOf(target);
        return insert(targetIndex, source, prevState);
      });
    } else {
      setSortedCategoriesState(prevState => {
        if (source === target) {
          return prevState;
        }
        const withoutSource = without([source], prevState);
        const targetIndex = withoutSource.indexOf(target);
        if (prevState.indexOf(source) < prevState.indexOf(target)) {
          return insert(targetIndex + 1, source, withoutSource);
        } else {
          return insert(targetIndex, source, withoutSource);
        }
      });
    }
  };

  const toggleFallback = (categoryId: string) => {
    setFallbackState(prevState => {
      return {
        ...prevState,
        [categoryId]: !prevState[categoryId],
      };
    });
  };

  const isSortingInProgress = unsortedCategoriesState.length > 0;
  const hasNoFallback = all(categoryId => !fallbackState[categoryId], sortedCategoriesState);

  return (
    <Modal
      className={styles.modal}
      visible={isVisible}
      closable={false}
      footer={null}
      width={defaultTheme.mediumModalWidth}
    >
      <FormattedMessage className={styles.modalHeader} id="SET_PRESELECTION_ORDER" />
      <FormattedMessage
        className={styles.instructionText}
        id="DRAG_AND_DROP_ALL_CATEGORIES_TO_SET_THE_PRESELECTION_ORDER"
      />
      <Alert
        type="info"
        showIcon
        message={<FormattedMessage id="PRESELECTION_ORDER_DISCLAIMER" />}
      />
      <FormattedMessage
        className={styles.tableHeader}
        id="CATEGORIES_WITH_NO_PRESELECTION_ORDER_SET"
      />
      <PreselectionOrderModalTable
        dataSource={getDataSource(unsortedCategoriesState)}
        categoryType="unsorted"
        moveCategory={moveCategory}
        fallbacks={fallbackState}
        toggleFallback={toggleFallback}
        editedCategoryId={editedCategoryId}
      />
      <FormattedMessage
        className={styles.tableHeader}
        id="CATEGORIES_WITH_PRESELECTION_ORDER_SET"
        values={{ count: unsortedCategoriesState.length }}
      />
      <PreselectionOrderModalTable
        dataSource={getDataSource(sortedCategoriesState)}
        categoryType="sorted"
        moveCategory={moveCategory}
        fallbacks={fallbackState}
        toggleFallback={toggleFallback}
        editedCategoryId={editedCategoryId}
      />
      {isSortingInProgress && <CategoryRowPlaceholder />}
      <div className={styles.footerWrapper}>
        <Button className={styles.footerCancelButton} type="link" onClick={onCancel}>
          {LABELS.CANCEL}
        </Button>
        <Tooltip
          title={
            (isSortingInProgress || hasNoFallback) && (
              <FormattedMessage id="PRESELECTION_ORDER_MUST_BE_SET" />
            )
          }
        >
          <Button
            className={styles.footerAcceptButton}
            disabled={isSortingInProgress || hasNoFallback}
            type="primary"
            onClick={() => {
              onAccept(sortedCategoriesState, fallbackState);
            }}
          >
            {LABELS.ACCEPT}
          </Button>
        </Tooltip>
      </div>
    </Modal>
  );
};

const styles = {
  modal: tss({
    $nest: {
      '.ant-modal-body': {
        background: '#FAF8F7',
      },
    },
  }),
  modalHeader: tss({
    display: 'block',
    height: '28px',
    lineHeight: '28px',
    fontSize: '20px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
    marginBottom: '16px',
  }),
  tableHeader: tss({
    display: 'block',
    height: '20px',
    lineHeight: '20px',
    fontSize: '14px',
    fontWeight: 500,
    color: '#000000',
    marginBottom: '8px',
    marginTop: '24px',
  }),
  instructionText: tss({
    display: 'block',
    marginBottom: '24px',
    color: 'rgba(0, 0, 0, 0.5)',
  }),
  footerWrapper: tss({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px',
  }),
  footerAcceptButton: tss({
    marginLeft: '10px',
    $nest: {
      '& [disabled]': {
        background: 'rgba(0,0,0,0.25)',
        color: defaultTheme.color.white,
      },
    },
  }),
  footerCancelButton: tss({
    color: 'rgba(0, 0, 0, 0.5)',
  }),
};
