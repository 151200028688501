import { DeliveryStatus } from '@src/models';
import { Button, Card } from 'antd';
import React, { FC, Fragment, useState } from 'react';
import { style as tss } from 'typestyle';
import { CustomsDeclarationFormModal } from './customs-declaration-form-modal';
import { useShipment, useShipmentCustomsDeclaration } from './queries';
import { GeneralInformation, ItemsDetails } from './view';
import { prefillCustomsDeclaration } from './prefill-customs-declaration';
import type { GeneralCustomsDeclaration } from './types';

type Props = {
  id: string;
  shipmentStatus: DeliveryStatus;
};

export const CustomsDeclaration: FC<Props> = ({ id, shipmentStatus }) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [defaultCustomsDeclaration, setDefaultCustomsDeclaration] = useState<GeneralCustomsDeclaration | undefined>(undefined);
  const { isLoading, data } = useShipmentCustomsDeclaration(id);
  const { data: shipment } = useShipment(id);

  const customsDeclaration = data?.declaration?.general_customs_declaration;

  if (!customsDeclaration && shipmentStatus !== 'CREATED') {
    return null;
  }

  const canPrefill = !customsDeclaration && shipment;

  return (
    <Fragment>
      <CustomsDeclarationFormModal
        customsDeclaration={defaultCustomsDeclaration}
        visible={isModalVisible}
        shipmentId={id}
        onClose={() => setModalVisibility(false)}
      />
      <Card
        loading={isLoading}
        bodyStyle={{ display: !customsDeclaration || isLoading ? 'none' : 'block' }}
        title={
          <div className={styles.cardContent}>
            <span>Customs Declaration</span>
            { canPrefill && (
              <Button
                loading={isLoading}
                onClick={() => {
                  const defaultDeclaration = prefillCustomsDeclaration(shipment);
                  setDefaultCustomsDeclaration(defaultDeclaration);
                  setModalVisibility(true)
                }}
                disabled={shipmentStatus !== 'CREATED'}
                style={{ marginLeft: 'auto', marginRight: '16px' }}
              >
                Prefill declaration
              </Button>
            )}
            <Button
              loading={isLoading}
              onClick={() => {
                setDefaultCustomsDeclaration(customsDeclaration);
                setModalVisibility(true)
              }}
              disabled={shipmentStatus !== 'CREATED'}
            >
              {customsDeclaration ? 'Edit' : 'Add'} declaration
            </Button>
          </div>
        }
      >
        <div className={styles.customsDeclarationView}>
          <GeneralInformation customsDeclaration={customsDeclaration} />
          <ItemsDetails items={customsDeclaration?.items ?? []} />
        </div>
      </Card>
    </Fragment>
  );
};

const styles = {
  cardTitle: tss({
    margin: 0,
  }),
  cardContent: tss({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  customsDeclarationView: tss({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  }),
};
