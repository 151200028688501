import { Option } from 'space-lift';
import { TransportOrderContentItemModel } from '../../models';
import { RootState } from '../root-reducer';

export const getTransportOrderById = (state: RootState, id: string) => {
  return state.transportOrders.byId[id];
};

export const getTransportOrderList = (state: RootState) =>
  state.transportOrders.listIds.map(id => state.transportOrders.byId[id]!);

export const getTransportOrdersWithAddresses = (
  state: RootState,
  items: TransportOrderContentItemModel[]
) =>
  items.map(transportOrderContent =>
    Option(getTransportOrderById(state, transportOrderContent.tosId)).fold(
      () => ({}),
      transportOrder => ({
        id: transportOrder.id,
        name: transportOrder.receiver.contactInfo.address.name,
      })
    )
  );

export const getShipmentSummariesByTosId = (state: RootState, id: string) =>
  state.transportOrders.shipmentSummariesByTosId[id];
