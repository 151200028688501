import React, { Fragment, useState, VFC } from 'react';
import { head } from 'ramda';
import { stylesheet } from 'typestyle';
import { Alert, Select, Switch } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { useFormatMessage } from '@src/i18n';
import { AvailableTrackingWidgetLanguage } from '@src/models';
import { SitesSelectors } from '@src/modules/sites';
import { useMasterSiteId, useSelector } from '@src/utils/hooks';
import { Widget } from '../../components';
import { useDraftToggle } from '../use-draft-toggle';
import { Details } from './details-view';
import { FindTrackingData } from './use-find-tracking';

const SelectField = withFormItem(Select);
const SwitchField = withFormItem(Switch);

const languageOptions = Object.keys(AvailableTrackingWidgetLanguage).map(key => ({
  value: key,
  label: key,
}));

type Props = {
  trackingData: FindTrackingData | undefined;
};

export const WidgetSection: VFC<Props> = ({ trackingData }) => {
  const masterSiteId = useMasterSiteId();
  const draftSiteId = useSelector(SitesSelectors.getDraftSiteIdOrEmpty);
  const [isUsingDraft, isDraftAvailable, toggleUsingDraft] = useDraftToggle();
  const [locale, setLocale] = useState('en-US');
  const formatMessage = useFormatMessage();

  const siteId = isUsingDraft ? draftSiteId : masterSiteId;

  return trackingData ? (
    <Fragment>
      {trackingData.contact ? (
        <Widget
          key={`${locale}${siteId}`}
          initTrackingWidget={() => {
            window.IngridDeliveryTrackingWidgetApi.renderPrivateMode({
              containerId: 'widget',
              locale,
              siteId,
              identifier:
                trackingData.trackingIdentifier === 'externalId'
                  ? trackingData.externalId
                  : head(trackingData.trackingNumbers),
              contact: trackingData?.contact,
            });
          }}
        />
      ) : (
        <div className={styles.alert}>
          <Alert
            message={formatMessage('TRACKING_PLAYGROUND_UNSUPPORTED_EXTERNAL_BOOKING')}
            type="warning"
            showIcon
          />
        </div>
      )}
      <SwitchField
        checked={isUsingDraft}
        onChange={toggleUsingDraft}
        defaultChecked={isDraftAvailable}
        label={formatMessage('USE_DRAFT')}
        disabled={!isDraftAvailable}
      />
      <SelectField
        caseInsensitiveSearch={true}
        label={formatMessage('LOCALE')}
        options={languageOptions}
        value={locale}
        onChange={setLocale}
      />
      <Details trackingData={trackingData} locale={locale} />
    </Fragment>
  ) : (
    <span className={styles.placeholder}>
      {formatMessage('TRACKING_PLAYGROUND_WIDGET_FIND_SECTION_EMPTY_PLACEHOLDER')}
    </span>
  );
};

const styles = stylesheet({
  alert: {
    margin: '16px 0',
  },
  placeholder: {
    placeSelf: 'center',
  },
});
