import * as React from 'react';

import * as css from 'csstips';
import * as tss from 'typestyle';

interface RowProps {
  justifyContent?: tss.types.CSSProperties['justifyContent'];
  alignItems?: tss.types.CSSProperties['alignItems'];
  style?: React.CSSProperties;
  className?: string;
}

const Row: React.StatelessComponent<RowProps> = props => {
  const { children, className, justifyContent, alignItems, style } = props;

  return (
    <div
      className={tss.classes(styles.row, className)}
      style={{ justifyContent, alignItems, ...style } as any}
    >
      {children}
    </div>
  );
};

export default Row;

const styles = {
  row: tss.style(css.fillParent, css.horizontal),
};
