// tslint:disable:jsx-alignment
import * as React from 'react';
import { connect } from 'react-redux';

import { Spinner } from '../controls';
import { RootState } from '../modules';

type Props = {
  authLoading: boolean;
};

const LoadingContainer: React.FunctionComponent<Props> = ({ authLoading, children }) => {
  return (
    <section style={style}>
      <Spinner active={authLoading} size="large" style={{ marginTop: '40%' }}>
        {children}
      </Spinner>
    </section>
  );
};

const mapStateToProps = (state: RootState) => ({
  authLoading: state.auth.isLoading,
});

const Connected = connect(mapStateToProps, {})(LoadingContainer);
export default Connected;

const style: React.CSSProperties = {
  background: '#000',
  width: '100vw',
  minHeight: '100vh',
};
