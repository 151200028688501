import { CompassOutlined } from '@ant-design/icons';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { defaultTheme } from '@src/styles';
import { SiteCredentials } from '.';
import { Button, Tooltip } from '../../../controls';
import { LABELS } from '../../../dictionaries';

interface Props {
  merchantName?: string;
  siteName: string;
  isFetching: boolean;
  onClick: () => void;
}

const styles = {
  root: tss({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
    marginTop: 15,
    marginRight: 50,
  }),
  wrapper: tss({
    display: 'flex',
    flexDirection: 'column',
    marginRight: 24,
    maxWidth: 200,
  }),
  merchant: tss({
    color: defaultTheme.color.neutralLight,
    fontSize: 12,
    textTransform: 'uppercase',
    fontWeight: 600,
    letterSpacing: 0.5,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
  site: tss({
    fontSize: 14,
    fontWeight: 400,
    color: '#FFFFFF',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
  changeButton: tss({
    border: '1px solid',
    $nest: {
      '&:hover': {
        backgroundColor: '#FFFFFF',
        color: '#000',
      },
    },
  }),
};

export const HeaderInfo: React.FunctionComponent<Props> = ({
  merchantName,
  siteName,
  isFetching,
  onClick,
}) => (
  <div className={styles.root}>
    <CompassOutlined
      style={{
        fontSize: 20,
        color: '#FFFFFF',
        marginRight: 8,
      }}
    />
    <div className={styles.wrapper}>
      <div className={styles.merchant}>
        <Tooltip title={merchantName} placement="bottomLeft">
          {isFetching ? 'Loading merchants...' : merchantName}
        </Tooltip>
      </div>
      <div className={styles.site}>
        <Tooltip title={siteName} placement="bottomLeft">
          {isFetching ? 'Loading sites...' : siteName}
        </Tooltip>
      </div>
    </div>
    <SiteCredentials />
    <Button type="primary" className={styles.changeButton} loading={isFetching} onClick={onClick}>
      {LABELS.CHANGE}
    </Button>
  </div>
);
