import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch/index';
import * as React from 'react';

const Component: React.StatelessComponent<SwitchProps> = props => {
  const { children, ...restProps } = props;

  return <Switch {...restProps}>{children}</Switch>;
};

export default Component;
