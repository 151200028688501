import { getIn } from 'formik';
import { FieldState, FormState, ValidatableMapOrArray } from 'formstate';
import { IObservableArray, isObservableArray } from 'mobx';

export function serializeFormState<T extends ValidatableMapOrArray>(
  formState: FormState<T>
): { [K in keyof T]: {} } {
  throw new Error('not implemented');
  // return {} as any;
}

export function isFormStateContainerDirty(formStateContainer: {
  [P: string]:
    | FormState<IObservableArray<FormState<{}>>>
    | FormState<{}>
    | FieldState<{} | {}[]>
    | undefined;
}): boolean {
  return Object.entries(formStateContainer).some(([key, value]) => isFormStateDirty(value));
}

export function isFormStateDirty(
  formState: FormState<{} | IObservableArray<FormState<{}>>> | FieldState<{} | {}[]> | undefined
): boolean {
  if (formState == null) {
    return false;
  }

  if (formState instanceof FormState) {
    if (isObservableArray(formState.$)) {
      return formState.$.some(fs => isFormStateDirty(fs));
    }

    return Object.entries(formState.$).some(([key, value]) => {
      if (value instanceof FormState) {
        return isFormStateDirty(value);
      } else if (value instanceof FieldState) {
        return value.dirty || false;
      } else {
        return false;
      }
    });
  } else if (formState instanceof FieldState) {
    return formState.dirty || false;
  } else {
    return false;
  }
}

export function getFormikError<T, E>(formikTouched: T, formikError: E) {
  if (formikTouched && formikError) {
    if (typeof formikError === 'string') {
      return formikError;
    }
  }
  return undefined;
}

export function getFieldArrayError<T, E>(formikTouched: T, formikErrors: E, name: string) {
  const error = getIn(formikErrors, name);
  const touched = getIn(formikTouched, name);
  if (touched && typeof error === 'object' && error) {
    return error.message;
  }
}
