import { inputStyle, MakeAutoSaveFormikEnhancer } from '@src/forms';
import { FormikProps } from 'formik';
import { isEmpty } from 'ramda';
import * as React from 'react';
import { CarrierProductMappingForm } from '../form-commons';
import {
  AlwaysShowTrackingLinksFieldConnected,
  CarrierProductFieldConnected,
  CarrierProductRefFieldConnected,
  LocaleFieldConnected,
  UrlFieldConnected,
  UseCustomMappingFieldConnected,
} from './connected-fields';

const AutoSaveFormikEnhancer = MakeAutoSaveFormikEnhancer<CarrierProductMappingForm>();

type AutoSaveProps = {
  handleSave: (values: CarrierProductMappingForm) => void;
  disabled?: boolean;
};

export const CarrierProductFieldAutoSave: React.FC<AutoSaveProps> = ({ handleSave }) => {
  return (
    <AutoSaveFormikEnhancer
      name="carrier_product_id"
      onSave={handleSave}
      validateAllFields
      render={({ onInstantChange }) => (
        <CarrierProductFieldConnected onChange={onInstantChange} className={inputStyle} />
      )}
    />
  );
};

export const CarrierProductRefFieldAutoSave: React.FC<AutoSaveProps> = ({ handleSave }) => {
  return (
    <AutoSaveFormikEnhancer
      name="carrier_product_ref"
      onSave={handleSave}
      validateAllFields
      render={({ onBlur, onKeyDown }) => (
        <CarrierProductRefFieldConnected
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          className={inputStyle}
        />
      )}
    />
  );
};

export const AlwaysShowTrackingLinksFieldAutoSave: React.FC<AutoSaveProps> = ({
  handleSave,
  disabled,
}) => {
  return (
    <AutoSaveFormikEnhancer
      name="always_show_tracking_links"
      onSave={handleSave}
      validateAllFields
      render={({ onInstantChange }) => (
        <AlwaysShowTrackingLinksFieldConnected
          onChange={onInstantChange}
          className={inputStyle}
          disabled={disabled}
        />
      )}
    />
  );
};

export const UseCustomMappingFieldAutoSave: React.FC<AutoSaveProps> = ({ handleSave }) => {
  return (
    <AutoSaveFormikEnhancer
      name="custom"
      onSave={handleSave}
      validateAllFields
      render={({ onInstantChange }) => (
        <UseCustomMappingFieldConnected onChange={onInstantChange} className={inputStyle} />
      )}
    />
  );
};

export const LocaleFieldAutoSave: React.FC<
  AutoSaveProps & {
    idx: number;
    disabled: boolean;
    formik: FormikProps<CarrierProductMappingForm>;
  }
> = ({ idx, disabled, formik, handleSave }) => {
  return (
    <LocaleFieldConnected
      idx={idx}
      disabled={disabled}
      onChange={(value: string) => {
        formik.setFieldValue(`tracking_links[${idx}]`, {
          locale: value,
          url: formik.values.tracking_links[idx].url,
        });
        const updatedTrackingLinks = [...formik.values.tracking_links];
        updatedTrackingLinks[idx] = {
          locale: value,
          url: formik.values.tracking_links[idx].url,
        };
        const nextValues = {
          ...formik.values,
          tracking_links: updatedTrackingLinks,
        };
        formik.validateForm(nextValues).then(errors => {
          if (isEmpty(errors)) {
            handleSave(nextValues);
          }
        });
      }}
    />
  );
};

export const UrlFieldAutoSave: React.FC<AutoSaveProps & { idx: number }> = ({
  idx,
  handleSave,
}) => {
  return (
    <AutoSaveFormikEnhancer
      name={`tracking_links[${idx}].url` as any} // because AutoSaveFormikEnhancer name prop's type does not support nested form values
      onSave={handleSave}
      validateAllFields
      render={({ onBlur, onKeyDown }) => (
        <UrlFieldConnected onBlur={onBlur} onKeyDown={onKeyDown} idx={idx} className={inputStyle} />
      )}
    />
  );
};
