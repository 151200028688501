import React, { FC } from 'react';
import { classes, style as tss } from 'typestyle';

type Props = {
  title: string;
  content: string[];
  boldContent?: boolean;
};

export const TextStack: FC<Props> = ({ title, content, boldContent = false }) => (
  <div className={styles.stack}>
    <span className={styles.title}>{title}</span>
    {content.map(item => (
      <span
        className={classes(styles.content, boldContent ? styles.contentBold : styles.contentNormal)}
        key={item}
      >
        {item}
      </span>
    ))}
  </div>
);

const styles = {
  stack: tss({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  }),
  title: tss({
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#555555',
  }),
  content: tss({
    wordBreak: 'break-word',
    color: 'black',
    lineHeight: '20px',
  }),
  contentBold: tss({
    fontWeight: 600,
    fontSize: '16px',
  }),
  contentNormal: tss({
    fontWeight: 400,
    fontSize: '14px',
  }),
};
