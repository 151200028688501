import * as React from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { useIntl } from 'react-intl';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  FormInstance,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Switch,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { stylesheet } from 'typestyle';
import { routerActions } from '@src/modules/router';

import { LinkStyleButton } from '@src/components';
import { apiUtils, authFetch } from '@src/utils';
import { services } from '@src/services';
import { FormattedMessage } from '@src/i18n';
import { Card as AntCard, PriceInput, RangePicker } from '@src/controls';
import { SitesSelectors } from '@src/modules/sites';
import { mainScrollContainerId } from '@src/layouts/context-layout';
import { getAvailableLocalesOptions } from '@src/modules/dictionaries/selectors';
import { WidgetTestClient } from './components';
import { TabbedFormList } from './tabbed-form-list';

import {
  Address,
  CompleteSessionRequest,
  Coordinates,
  CreateSessionRequest,
  CreateSessionResponse,
  CustomerAddress,
  UpdateSessionRequest,
  UpdateSessionResponse,
} from '@src/api/delivery-checkout';
import { CartV4, mapCart } from './helpers';
import { supportedRegionInCountry } from '@src/utils/address';
import {
  AddressFormConfigurationModel,
  ParsedCosSessionSettingsConfigurationData,
} from '@src/models';

type DeliveryAddress = Address & {
  email?: CustomerAddress['email'];
  phone?: CustomerAddress['phone'];
};

interface FormValues extends Omit<CreateSessionRequest, 'cart'> {
  cart: CartV4;
  delivery_address?: DeliveryAddress;
  customer?: CustomerAddress;
}

interface WidgetFormValues extends Omit<FormValues, 'meta'> {
  meta: { key: string; values: string }[];
}

interface UpdateSessionPayload extends UpdateSessionRequest {
  search_address?: Address;
  delivery_address?: DeliveryAddress;
}

export interface Recipient {
  address_lines?: string[];
  apartment_number?: string;
  city?: string;
  coordinates?: Coordinates;
  country?: string;
  email?: string;
  id?: string;
  label?: string;
  name?: string;
  phone_number?: string;
  postal_code?: string;
  region?: string;
  street?: string;
  street_number?: string;
}

type CompleteSessionPayload = CompleteSessionRequest;

const initialValues: WidgetFormValues = {
  purchase_country: 'SE',
  purchase_currency: 'SEK',
  locales: ['en-US'],
  cart: {
    total_value: '100.00',
    total_discount: '0',
    external_id: undefined,
    shipping_date: undefined,
    groups: [],
    items: [
      {
        name: 'Keyboard',
        sku: v4(),
        quantity: 1,
        discount: '0',
        price: '10.00',
        shipping_date: undefined,
      },
    ],
    cart_id: v4(),
  },
  delivery_address: {
    name: 'Ingrid Tester',
    email: 'test@ingrid.com',
    city: '',
    postal_code: '',
    address_lines: undefined,
  },
  meta: [
    { key: 'fulfilment_check_endpoint', values: '' },
    { key: 'fulfilment_check_ignore', values: '' },
  ],
};

interface ConfigurationFormProps {
  formInstance: FormInstance<WidgetFormValues>;
  onFinish: (values: WidgetFormValues) => void;
  addressFormEnabled: boolean;
  setDeliveryAddressEmpty: React.Dispatch<React.SetStateAction<boolean>>;
  setExternalIdEmpty: React.Dispatch<React.SetStateAction<boolean>>;
  addressFormMode: boolean;
  setAddressFormMode: React.Dispatch<React.SetStateAction<boolean>>;
  cosConfiguration: ParsedCosSessionSettingsConfigurationData;
  addressFormConfiguration?: AddressFormConfigurationModel['byCountry'];
}

const ConfigurationForm: React.FC<ConfigurationFormProps> = ({
  formInstance,
  onFinish,
  addressFormEnabled,
  setDeliveryAddressEmpty,
  setExternalIdEmpty,
  addressFormMode,
  setAddressFormMode,
  cosConfiguration,
  addressFormConfiguration,
}) => {
  const availableLocalesOptions = useSelector(getAvailableLocalesOptions);

  const handlePurchaseCountryChange = (purchase_country: string) => {
    if (
      cosConfiguration.multi_session_settings?.countries_with_recipient_address_form_enabled?.[
        purchase_country
      ] ||
      addressFormConfiguration?.[purchase_country]?.enabled ||
      (addressFormConfiguration?.['XW']?.enabled &&
        addressFormConfiguration?.[purchase_country]?.enabled !== false) // country specific setup takes precedence
    ) {
      setAddressFormMode(false);
    } else {
      setAddressFormMode(true);
    }
  };

  const handleFieldsChange = () => {
    const {
      delivery_address,
      cart: { external_id },
    } = formInstance.getFieldsValue([['delivery_address'], ['cart', 'external_id']]);

    if (!external_id) {
      setExternalIdEmpty(true);
      return;
    } else {
      setExternalIdEmpty(false);
    }

    const deliveryAddressFieldsNotValid = Object.values({
      ...delivery_address,
    }).some(val => !val);

    setDeliveryAddressEmpty(deliveryAddressFieldsNotValid);
  };

  React.useEffect(() => {
    const purchase_country = formInstance.getFieldValue('purchase_country');
    handlePurchaseCountryChange(purchase_country);
  }),
    [];

  return (
    <Form
      form={formInstance}
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      onFinish={onFinish}
      onFieldsChange={handleFieldsChange}
    >
      <Collapse defaultActiveKey="general">
        <Collapse.Panel key="general" header="General" forceRender>
          <Form.Item name="purchase_country" label="Purchase country" rules={[{ required: true }]}>
            <Select
              onChange={handlePurchaseCountryChange}
              showSearch
              optionFilterProp="label"
              options={services.dictionariesService.getCountriesOptions()}
            />
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prev, current) => prev.purchase_country !== current.purchase_country}
            rules={[{ required: true }]}
          >
            {({ getFieldValue }) =>
              supportedRegionInCountry(getFieldValue('purchase_country')) && addressFormMode ? (
                <Form.Item name={['search_address', 'region']} label="Region" required>
                  <Select
                    showSearch
                    optionFilterProp="label"
                    options={services.dictionariesService.getRegionsOptions(
                      getFieldValue('purchase_country')
                    )}
                  />
                </Form.Item>
              ) : null
            }
          </Form.Item>

          <Form.Item
            name="purchase_currency"
            label="Purchase currency"
            rules={[{ required: true }]}
          >
            <Select showSearch options={services.dictionariesService.getCurrenciesOptions()} />
          </Form.Item>

          <Form.Item name={['locales', 0]} label="Locale" rules={[{ required: true }]}>
            <Select options={availableLocalesOptions} />
          </Form.Item>
        </Collapse.Panel>

        {addressFormMode && (
          <Collapse.Panel key="search_address" header="Search address" forceRender>
            <Form.Item name={['search_address', 'address_lines', 0]} label="Address line">
              <Input />
            </Form.Item>

            <Form.Item name={['search_address', 'postal_code']} label="Postal code">
              <Input />
            </Form.Item>

            <Form.Item name={['search_address', 'city']} label="City">
              <Input />
            </Form.Item>
          </Collapse.Panel>
        )}

        {addressFormEnabled || (
          <Collapse.Panel key="delivery_address" header="Delivery address" forceRender>
            <Row>
              <Col span={18} style={{ paddingRight: 16 }}>
                <Form.Item name={['delivery_address', 'address_lines', 0]} label="Address line">
                  <Input />
                </Form.Item>

                <Form.Item name={['delivery_address', 'postal_code']} label="Postal code">
                  <Input />
                </Form.Item>

                <Form.Item name={['delivery_address', 'city']} label="City">
                  <Input />
                </Form.Item>

                <Form.Item name={['delivery_address', 'name']} label="Customer name">
                  <Input />
                </Form.Item>

                <Form.Item name={['delivery_address', 'email']} label="Customer email">
                  <Input />
                </Form.Item>

                <Form.Item name={['delivery_address', 'phone']} label="Customer phone">
                  <Input />
                </Form.Item>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    const values = formInstance.getFieldsValue();
                    formInstance.setFieldsValue({
                      delivery_address: {
                        ...values.search_address,
                      },
                    });
                  }}
                >
                  <FormattedMessage id="USE_SEARCH_ADDRESS" />
                </Button>
              </Col>
            </Row>
          </Collapse.Panel>
        )}

        <Collapse.Panel key="additional" header="Additional" forceRender>
          <Form.Item
            name={['cart', 'external_id']}
            label={
              <>
                <span>External ID</span>
                <Tooltip title="External ID has to be provided before or on complete session request">
                  <InfoCircleOutlined className={styles.tooltipIcon} />
                </Tooltip>
              </>
            }
          >
            <Input />
          </Form.Item>

          <Form.Item name={['cart', 'vouchers']} label="Vouchers">
            <Select mode="tags" tokenSeparators={[',']} />
          </Form.Item>

          <Form.Item name={['cart', 'attributes']} label="Cart attributes">
            <Select mode="tags" tokenSeparators={[',']} />
          </Form.Item>
        </Collapse.Panel>

        <Collapse.Panel key="meta" header="Meta" forceRender>
          <Form.Item
            label="Meta Key 1"
            name={['meta', '0', 'key']}
            fieldKey={['meta', '0', 'key']}
            rules={[{ required: true, message: 'Missing meta key' }]}
          >
            <Input placeholder="Meta Key" style={{ width: '400px' }} />
          </Form.Item>
          <Form.Item
            label="Meta Values 1"
            name={['meta', '0', 'values']}
            fieldKey={['meta', '0', 'values']}
          >
            <Input placeholder="Comma separated values" />
          </Form.Item>

          <Form.Item
            label="Meta Key 2"
            name={['meta', '1', 'key']}
            fieldKey={['meta', '1', 'key']}
            rules={[{ required: true, message: 'Missing meta key' }]}
          >
            <Input placeholder="Meta Key" style={{ width: '400px' }} />
          </Form.Item>
          <Form.Item
            label="Meta Values 2"
            name={['meta', '1', 'values']}
            fieldKey={['meta', '1', 'values']}
          >
            <Input placeholder="Comma separated values" />
          </Form.Item>
        </Collapse.Panel>
        <Collapse.Panel key="cart" header="Cart" forceRender>
          <>
            <Form.Item name={['cart', 'total_value']} label="Total value">
              <PriceInput />
            </Form.Item>

            <Form.Item name={['cart', 'total_discount']} label="Total discount">
              <PriceInput />
            </Form.Item>

            <Form.Item
              name={['cart', 'shipping_date']}
              label={<FormattedMessage id="SHIPPING_DATE" />}
            >
              <RangePicker allowClear showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
            </Form.Item>

            <Form.Item name={['cart', 'cart_id']} label="Cart ID" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <div style={{ marginBottom: '8px' }}>Cart items</div>

            <TabbedFormList
              name={['cart', 'items']}
              itemFactory={() => ({
                name: 'Keyboard',
                sku: v4(),
                quantity: 1,
                discount: '0',
                price: '10.00',
              })}
            >
              {(itemName: string) => (
                <>
                  <Form.Item name={[itemName, 'name']} label="Name" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name={[itemName, 'sku']} label="Sku" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name={[itemName, 'shipping_date']}
                    label={<FormattedMessage id="SHIPPING_DATE" />}
                  >
                    <RangePicker
                      allowClear
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                    />
                  </Form.Item>

                  <Form.Item name={[itemName, 'price']} label="Price" rules={[{ required: true }]}>
                    <PriceInput />
                  </Form.Item>

                  <Form.Item
                    name={[itemName, 'discount']}
                    label="Discount"
                    rules={[{ required: true }]}
                  >
                    <PriceInput />
                  </Form.Item>

                  <Form.Item
                    name={[itemName, 'quantity']}
                    label="Quantity"
                    rules={[{ required: true }]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>

                  <Form.Item
                    name={[itemName, 'weight']}
                    label={
                      <>
                        <span>Weight</span>
                        <Tooltip title="Weight in grams">
                          <InfoCircleOutlined className={styles.tooltipIcon} />
                        </Tooltip>
                      </>
                    }
                  >
                    <InputNumber min={0} />
                  </Form.Item>

                  <Form.Item
                    label={
                      <>
                        <span>Dimensions</span>
                        <Tooltip title="Length × Width × Height">
                          <InfoCircleOutlined className={styles.tooltipIcon} />
                        </Tooltip>
                      </>
                    }
                    className={styles.dimensionsInputGroup}
                  >
                    <Form.Item
                      name={[itemName, 'dimensions', 'length']}
                      className={styles.dimensionsInput}
                    >
                      <InputNumber placeholder="L" min={0} />
                    </Form.Item>
                    <span className={styles.dimensionsSeparator}>&times;</span>
                    <Form.Item
                      name={[itemName, 'dimensions', 'width']}
                      className={styles.dimensionsInput}
                    >
                      <InputNumber placeholder="W" min={0} />
                    </Form.Item>
                    <span className={styles.dimensionsSeparator}>&times;</span>
                    <Form.Item
                      name={[itemName, 'dimensions', 'height']}
                      className={styles.dimensionsInput}
                    >
                      <InputNumber placeholder="H" min={0} />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item name={[itemName, 'attributes']} label="Attributes">
                    <Select mode="tags" tokenSeparators={[',']} />
                  </Form.Item>

                  <Form.Item name={[itemName, 'image_url']} label="Image URL">
                    <Input />
                  </Form.Item>
                </>
              )}
            </TabbedFormList>

            <div style={{ marginBottom: '8px' }}>Groups</div>

            <TabbedFormList
              name={['cart', 'groups']}
              itemFactory={() => ({
                group_id: v4(),
                header: 'Delivery',
              })}
            >
              {(groupName: string) => (
                <>
                  <Form.Item
                    name={[groupName, 'group_id']}
                    label="Group ID"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item name={[groupName, 'attributes']} label="Group attributes">
                    <Select mode="tags" tokenSeparators={[',']} />
                  </Form.Item>

                  <Form.Item name={[groupName, 'header']} label="Header">
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name={[groupName, 'consolidatable']}
                    label="Allow consolidation"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Checkbox />
                  </Form.Item>

                  <div style={{ marginBottom: '8px' }}>Group items</div>
                  <TabbedFormList name={[groupName, 'contents']}>
                    {(potato: string) => {
                      return (
                        <>
                          <Form.Item
                            name={[potato, 'sku']}
                            label="Sku"
                            rules={[{ required: true }]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name={[potato, 'quantity']}
                            label="Quantity"
                            initialValue={1}
                            rules={[{ required: true }]}
                          >
                            <InputNumber min={0} />
                          </Form.Item>
                        </>
                      );
                    }}
                  </TabbedFormList>
                </>
              )}
            </TabbedFormList>
          </>
        </Collapse.Panel>
      </Collapse>
    </Form>
  );
};

interface WidgetConfigurationV4Props {
  doNotUseDraft?: boolean;
}

export const WidgetConfigurationV4: React.FC<WidgetConfigurationV4Props> = ({ doNotUseDraft }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm<WidgetFormValues>();

  const draftSiteId = useSelector(SitesSelectors.getDraftSiteIdOrEmpty);
  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);
  const draftPrivateKey = useSelector(SitesSelectors.getDraftSitePrivateKeyOrEmpty);
  const isFetchingSites = useSelector(SitesSelectors.getIsFetchingSites);

  const effectiveSiteId = draftSiteId || siteId;

  const [useDraft, setUseDraft] = React.useState<boolean>(!!draftPrivateKey && !doNotUseDraft);
  const [externalIdEmpty, setExternalIdEmpty] = React.useState<boolean>(false);
  const [recipientAddress, setRecipientAddres] = React.useState<Recipient | undefined>();
  const [deliveryAddressEmpty, setDeliveryAddressEmpty] = React.useState<boolean>(false);
  const [addressFormMode, setAddressFormMode] = React.useState(false);

  const siteQuery = useQuery({
    queryKey: ['site', effectiveSiteId],
    queryFn: async () => {
      return await services.configService.getSite(effectiveSiteId);
    },
    staleTime: Infinity,
  });

  const cosSettings = siteQuery.data?.serviceConfigurations?.find(
    config => config.configurationType === 'cos-session-settings'
  );

  const cosConfiguration = JSON.parse(
    cosSettings?.configurationData || '{}'
  ) as ParsedCosSessionSettingsConfigurationData;

  const createMutationFn = async (values: FormValues): Promise<CreateSessionResponse> => {
    const request: CreateSessionRequest = {
      ...values,
      cart: mapCart(values.cart),
      ...(addressFormMode && {
        search_address: {
          ...values.search_address,
          country: values.purchase_country,
        },
      }),
    };

    return await authFetch(`${apiUtils.getApiUrl()}/delivery_checkout/session.create`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        'x-site-id': useDraft ? draftSiteId : siteId,
      },
    });
  };

  const createMutation = useMutation<CreateSessionResponse, any, FormValues>(createMutationFn, {
    onError: error => services.notificationService.error(error.message),
  });

  const updateMutationFn = async (values: FormValues): Promise<UpdateSessionResponse> => {
    const request: UpdateSessionPayload = {
      checkout_session_id: createMutation.data!.session!.checkout_session_id!,
      locales: values.locales,
      purchase_country: values.purchase_country,
      purchase_currency: values.purchase_currency,
      external_id: values.cart.external_id,
      cart: mapCart(values.cart),
      search_address: {
        ...values.search_address,
        country: values.purchase_country,
      },
      meta: values.meta,
    };

    services.siwService.suspendWidget();

    const data = await authFetch(`${apiUtils.getApiUrl()}/delivery_checkout/session.update`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        'x-site-id': useDraft ? draftSiteId : siteId,
      },
    });

    services.siwService.resumeWidget();
    return data as UpdateSessionResponse;
  };

  const updateMutation = useMutation<UpdateSessionResponse, any, FormValues>(updateMutationFn, {
    onError: error => services.notificationService.error(error.message),
  });

  const completeMutationFn = async (): Promise<void> => {
    const values = formInstance.getFieldsValue();
    const address = values.delivery_address ?? {};

    const request: CompleteSessionPayload = {
      checkout_session_id: createMutation.data!.session!.checkout_session_id!,
      external_id: values.cart.external_id,
      ...(!recipientAddress && {
        customer: {
          email: values.delivery_address?.email,
          phone: values.delivery_address?.phone,
          region: values.search_address?.region,
          ...address,
          country: values.purchase_country,
        },
      }),
    };

    return await authFetch(`${apiUtils.getApiUrl()}/delivery_checkout/session.complete`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
        'x-site-id': useDraft ? draftSiteId : siteId,
      },
    });
  };

  const completeMutation = useMutation<any, any, any>(completeMutationFn, {
    onError: error => services.notificationService.error(error.message),
    onSuccess: result => {
      result.session.delivery_groups.forEach((deliveryGroup: any) => {
        const tosId = deliveryGroup.tos_id;
        notification.success({
          key: tosId,
          placement: 'topRight',
          duration: 0,
          description: (
            <div>
              {formatMessage(
                { id: 'CHECKOUT_TESTCLIENT.COMPLETE_SESSION_NOTIFICATION.MESSAGE' },
                {
                  TEST_ORDER_LINK: (
                    <LinkStyleButton
                      showIcon={false}
                      className={styles.newOrderButton}
                      onClick={() => {
                        notification.close(tosId);
                        dispatch(routerActions.push({ name: 'TRANSPORT_ORDER_DETAILS', tosId }));
                      }}
                    >
                      {formatMessage({ id: 'TEST_ORDER_LINK' })}
                    </LinkStyleButton>
                  ),
                }
              )}
            </div>
          ),
          message: formatMessage({
            id: 'CHECKOUT_TESTCLIENT.COMPLETE_SESSION_NOTIFICATION.HEADER',
          }),
        });
      });
      createMutation.reset();
    },
  });

  const finishFlag = React.useRef('create');

  const prepareMetaData = (
    metaValues: {
      key: string;
      values: string;
    }[]
  ): Record<string, string> => {
    const meta: Record<string, string> = {};

    Object.values(metaValues).forEach(item => {
      meta[item.key] = item.values;
    });

    return meta;
  };

  const onFinish = (values: WidgetFormValues) => {
    const meta = !!values.meta ? prepareMetaData(values.meta) : undefined;

    const payload = {
      ...values,
      meta,
    };

    if (finishFlag.current === 'update') {
      updateMutation.mutate(payload);
    } else {
      createMutation.mutate(payload);
    }
  };

  React.useEffect(() => {
    createMutation.reset();
  }, [effectiveSiteId]);

  const getTooltipTitle = React.useCallback(() => {
    switch (true) {
      case useDraft:
        return <FormattedMessage id="CHECKOUT_TESTCLIENT.CREATE_ORDER_BUTTON.NO_DRAFT_ERROR" />;
      case deliveryAddressEmpty && !recipientAddress:
        return (
          <FormattedMessage id="CHECKOUT_TESTCLIENT.CREATE_ORDER_BUTTON.NO_DELIVERY_ADDRESS_ERROR" />
        );
      case externalIdEmpty:
        return (
          <FormattedMessage id="CHECKOUT_TESTCLIENT.CREATE_ORDER_MODAL.NO_EXTERNAL_ID_ERROR" />
        );
      default:
        return null;
    }
  }, [useDraft, deliveryAddressEmpty, recipientAddress, externalIdEmpty]);

  return (
    <>
      {createMutation.isSuccess && (
        <WidgetTestClient
          htmlSnippet={createMutation.data!.html_snippet!}
          session={{} as any}
          handleRecipientAddress={(address?: Recipient) => {
            setRecipientAddres(address);
          }}
        />
      )}
      <AntCard
        title={<FormattedMessage id="SETTINGS" />}
        extra={
          <>
            {createMutation.isSuccess && (
              <Button
                type="primary"
                htmlType="button"
                style={{ marginRight: '10px' }}
                onClick={createMutation.reset}
              >
                <FormattedMessage id="CLOSE_WIDGET_SESSION" />
              </Button>
            )}
            {createMutation.isSuccess && (
              <Tooltip
                getPopupContainer={() =>
                  document.getElementById(mainScrollContainerId) || document.body
                }
                title={getTooltipTitle()}
              >
                <Button
                  disabled={
                    recipientAddress
                      ? externalIdEmpty || useDraft
                      : deliveryAddressEmpty || externalIdEmpty || useDraft
                  }
                  htmlType="button"
                  style={{ marginRight: '10px' }}
                  onClick={completeMutation.mutate}
                  loading={completeMutation.status === 'loading'}
                >
                  <FormattedMessage id="CHECKOUT_TESTCLIENT.CREATE_ORDER_BUTTON.TITLE" />
                </Button>
              </Tooltip>
            )}
            {createMutation.isSuccess && addressFormMode && (
              <Button
                htmlType="button"
                loading={updateMutation.isLoading}
                onClick={() => {
                  finishFlag.current = 'update';
                  formInstance.submit();
                }}
              >
                <FormattedMessage id="UPDATE" />
              </Button>
            )}
            {!createMutation.isSuccess && (
              <Button
                type="primary"
                htmlType="button"
                loading={createMutation.isLoading || isFetchingSites}
                onClick={() => {
                  finishFlag.current = 'create';
                  formInstance.submit();
                }}
              >
                <FormattedMessage id="GO_TO_WIDGET" />
              </Button>
            )}
          </>
        }
      >
        <Form.Item label={<FormattedMessage id="USE_DRAFT" />} labelCol={{ span: 6 }}>
          <Switch
            disabled={doNotUseDraft || !draftPrivateKey}
            checked={useDraft}
            onChange={checked => setUseDraft(checked)}
          />
        </Form.Item>

        <ConfigurationForm
          formInstance={formInstance}
          onFinish={onFinish}
          addressFormEnabled={!!recipientAddress}
          setDeliveryAddressEmpty={setDeliveryAddressEmpty}
          setExternalIdEmpty={setExternalIdEmpty}
          addressFormMode={addressFormMode}
          setAddressFormMode={setAddressFormMode}
          cosConfiguration={cosConfiguration}
          addressFormConfiguration={siteQuery?.data?.addressFormConfiguration?.byCountry}
        />
      </AntCard>
    </>
  );
};

const styles = stylesheet({
  dimensionsInputGroup: {
    $nest: {
      '.ant-form-item-control-input-content': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  dimensionsInput: {
    display: 'inline-block',
    marginBottom: 0,
  },
  dimensionsSeparator: {
    margin: '0 10px',
  },
  tooltipIcon: {
    marginLeft: '5px',
  },
  alert: {
    marginBottom: '10px',
  },
  newOrderButton: {
    padding: '0',
  },
});
