import { createSelector } from 'reselect';

import { RootState } from '../root-reducer';

const getSIWState = (state: RootState) => state.siw;

export const isFetching = createSelector(getSIWState, state => state.isFetching);
export const getSession = createSelector(getSIWState, state => state.session);
export const getHtmlSnippet = createSelector(getSIWState, state => state.htmlSnippet);
export const widgetReady = createSelector(
  getHtmlSnippet,
  getSession,
  (snippet, session) => !!snippet && !!session
);

export const getSessionId = createSelector(getSession, session => (session ? session.id : ''));

export const getLocationData = createSelector(getSession, session => {
  return {
    name: session?.result.shipping?.location?.name ?? '',
    externalId: session?.result.shipping?.location?.externalId ?? '',
    shippingMethod: session?.result.shipping?.shippingMethod ?? '',
  };
});

export const getLocationAddress = createSelector(getSession, session => {
  const address = session?.result.shipping?.location?.address;
  return address
    ? {
        ...address,
        name: (address.name || session?.result.shipping?.location?.name) ?? '',
      }
    : null;
});

export const getDeliveryTime = createSelector(
  getSession,
  session => session?.result.shipping?.deliveryTime
);
