import { ComponentReturningChildren } from '@src/components';
import { Link } from '@src/components/link';
import { Alert, Input, InputNumber, Select, Tag, Tooltip, FormItem } from '@src/controls';
import { ActivateSwitch } from '@src/containers/regions/components';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, AutoSaveFormikEnhancer, inputStyle, wideLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import {
  ConfigRegionModel,
  ConfigShippingCategoryModel,
  DeliveryTypeEnum,
  UpdateCategoryDetailsModel,
} from '@src/models';
import { defaultTheme } from '@src/styles';
import { getBooleanAsState, getStateAsBoolean } from '@src/utils/data-transformations';
import { getFormikError } from '@src/utils/forms';
import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { style as tss } from 'typestyle';
import { object, string } from 'yup';
import { InfoCircleOutlined } from '@ant-design/icons';

const LabelWrapper = withFormItem(ComponentReturningChildren, wideLayout);
const InputField = withFormItem(Input, wideLayout);
const NumberField = withFormItem(InputNumber, wideLayout);
const SelectField = withFormItem(Select, wideLayout);

interface OwnProps {
  regions: ConfigRegionModel[];
  category: ConfigShippingCategoryModel;
  onCategoryDetailChange: (values: Partial<UpdateCategoryDetailsModel>) => void;
  deliveryTypes: { value: string; label: string }[];
  showTooltip: boolean;
  changeVisibility: (to: boolean) => void;
  isPreselectionOrderComplete: boolean;
  preselectionOrderSectionForwardedRef: React.RefObject<HTMLElement>;
}

export interface CategoryDetailsFormValues {
  id: string;
  name: string;
  deliveryType: DeliveryTypeEnum;
  sortOrder?: number;
}

const CategoryDetailsSchema = object().shape({
  name: string().required(),
});

const getRegionName = (regions: ConfigRegionModel[], regionId: string) => {
  return regions.find(region => region.id === regionId)?.name || '';
};

export const ShippingCategoryForm: React.FunctionComponent<OwnProps> = ({
  regions,
  category,
  onCategoryDetailChange,
  deliveryTypes,
  showTooltip,
  changeVisibility,
  isPreselectionOrderComplete,
  preselectionOrderSectionForwardedRef,
}) => (
  <Formik
    initialValues={{
      id: category.id,
      name: category.name,
      deliveryType: category.deliveryType,
      sortOrder: category.sortOrder,
    }}
    enableReinitialize={true}
    // tslint:disable-next-line: no-empty
    onSubmit={() => {}}
    validateOnBlur={true}
    validationSchema={CategoryDetailsSchema}
  >
    {renderProps => {
      return (
        <>
          <div>
            <Tag color={defaultTheme.color.primary} nonClickable={true}>
              <FormattedMessage id="DELIVERY_CATEGORY" />
            </Tag>
            <h2 className={styles.header}>{category.name}</h2>
          </div>
          {!isPreselectionOrderComplete && !getStateAsBoolean(category.state) && (
            <Alert
              className={styles.preselectionOrderActivateAlert}
              message={
                <div className={styles.preselectionOrderActivateAlertContentWrapper}>
                  <FormattedMessage id="PRESELECTION_ORDER_ACTIVATE_ALERT" />
                  <button
                    className={styles.preselectionOrderActivateAlertButton}
                    onClick={() => {
                      preselectionOrderSectionForwardedRef.current?.scrollIntoView();
                    }}
                  >
                    <FormattedMessage id="SET_PRESELECTION_ORDER" />
                  </button>
                </div>
              }
              type="warning"
            />
          )}
          <AutoSaveForm>
            <Field name="id">
              {({ field }: FieldProps<CategoryDetailsFormValues['id']>) => (
                <InputField
                  label={<FormattedMessage id="ID" />}
                  {...field}
                  className={inputStyle}
                  disabled={true}
                />
              )}
            </Field>
            <LabelWrapper label={<FormattedMessage id="LINKED_REGIONS" />}>
              <div className={styles.regionsWrapper}>
                {category.regionIds.map((id, index) => (
                  <span key={id} className={styles.linkWrapper}>
                    <Link
                      route={{ name: 'REGION_DETAILS', regionId: id }}
                      onClick={() => {
                        changeVisibility(false);
                      }}
                      baseStyles
                      showIcon
                      className={styles.link}
                    >
                      {getRegionName(regions, id)}
                    </Link>
                    {index === category.regionIds.length - 1 ? '' : ','}
                  </span>
                ))}
              </div>
            </LabelWrapper>
            <FormItem label={<FormattedMessage id="ACTIVE" />} {...wideLayout}>
              <ActivateSwitch
                category={category}
                onChange={value => {
                  onCategoryDetailChange({
                    ...renderProps.values,
                    state: getBooleanAsState(value),
                  });
                }}
              />
            </FormItem>
            <AutoSaveFormikEnhancer
              name="name"
              onSave={values => onCategoryDetailChange(values)}
              render={({ name, onBlur, onKeyDown }) => (
                <Field name={name}>
                  {({ field }: FieldProps<CategoryDetailsFormValues['name']>) => (
                    <InputField
                      label={<FormattedMessage id="CATEGORY_NAME" />}
                      className={inputStyle}
                      {...field}
                      error={getFormikError(renderProps.touched.name, renderProps.errors.name)}
                      onBlur={onBlur}
                      onKeyDown={onKeyDown}
                    />
                  )}
                </Field>
              )}
            />
            <AutoSaveFormikEnhancer
              name="deliveryType"
              onSave={values => onCategoryDetailChange(values)}
              render={({ name, onBlur }) => (
                <Field name={name}>
                  {({ field }: FieldProps<CategoryDetailsFormValues['deliveryType']>) => (
                    <SelectField
                      label={
                        <>
                          <FormattedMessage id="DELIVERY_TYPE" values={{ multiple: false }} />
                          {showTooltip && (
                            <Tooltip title={<FormattedMessage id="DELIVERY_TYPE_CHANGE" />}>
                              <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                            </Tooltip>
                          )}
                        </>
                      }
                      {...field}
                      options={deliveryTypes}
                      onChange={value => renderProps.setFieldValue(name, value)}
                      className={inputStyle}
                      onBlur={onBlur}
                    />
                  )}
                </Field>
              )}
            />
            <AutoSaveFormikEnhancer
              name="sortOrder"
              onSave={values => {
                if (values.name === '') {
                  values.name = category.name;
                }
                onCategoryDetailChange(values);
              }}
              render={({ name, onBlur, onKeyDown }) => (
                <Field name={name}>
                  {({ field }: FieldProps<CategoryDetailsFormValues['sortOrder']>) => (
                    <NumberField
                      label={<FormattedMessage id="SORT_ORDER" />}
                      className={inputStyle}
                      {...field}
                      onChange={value => renderProps.setFieldValue(name, value)}
                      min={0}
                      onBlur={onBlur}
                      onKeyDown={onKeyDown}
                    />
                  )}
                </Field>
              )}
            />
          </AutoSaveForm>
        </>
      );
    }}
  </Formik>
);

const styles = {
  header: tss({
    marginTop: '10px',
  }),
  regionsWrapper: tss({
    marginLeft: '11px',
  }),
  linkWrapper: tss({
    marginRight: '8px',
  }),
  link: tss({
    whiteSpace: 'nowrap',
  }),
  preselectionOrderActivateAlert: tss({
    margin: '10px 0',
  }),
  preselectionOrderActivateAlertContentWrapper: tss({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  preselectionOrderActivateAlertButton: tss({
    background: 'unset',
    border: '1px solid lightgrey',
    borderRadius: '3px',
    cursor: 'pointer',
    height: '32px',
  }),
  clickableAreaWrapper: tss({
    position: 'relative',
  }),
  clickableArea: tss({
    height: '28px',
    width: '55px',
    position: 'absolute',
    zIndex: 999,
  }),
};
