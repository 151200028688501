import { Field, FieldProps, Formik, useField } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { object, string, StringSchema } from 'yup';

import { Select, Button, Input } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { bigLabelLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { services } from '@src/services';
import { getFormikError } from '@src/utils/forms';
import { getPostalCodeValidationByCountry } from '@src/utils/validation';

const InputField = withFormItem(Input, bigLabelLayout);
const SelectField = withFormItem(Select, bigLabelLayout);

const timezonePerCountry = services.dictionariesService.getFirstTimezonePerCountry();

interface Props {
  onSubmit: (values: CreateWarehouseFormValues) => void;
}

export interface CreateWarehouseFormValues {
  name: string;
  country: string;
  timezone: string;
  city: string;
  postalCode: string;
}

const CreateWarehouseSchema = object({
  name: string().required(),
  country: string().required(),
  timezone: string().required(),
  city: string().required(),
  postalCode: string()
    .when('country', (country: string, schema: StringSchema) =>
      schema.matches(
        getPostalCodeValidationByCountry(country),
        `Postal code is not valid for ${country}`
      )
    )
    .required(),
});

export const CreateWarehouseForm: React.FunctionComponent<Props> = ({ onSubmit }) => {
  return (
    <Formik
      onSubmit={values => onSubmit(values)}
      initialValues={{ name: '', country: '', timezone: '', city: '', postalCode: '' }}
      validationSchema={CreateWarehouseSchema}
    >
      {({ handleSubmit, errors, touched, setFieldValue }) => (
        <form onSubmit={handleSubmit} className={styles.formWrapper}>
          <FormikInputField name="name" label={<FormattedMessage id="NAME" />} />
          <Field name="country">
            {({ field }: FieldProps<CreateWarehouseFormValues['country']>) => (
              <SelectField
                {...field}
                labelAlign="left"
                label={<FormattedMessage id="COUNTRY" />}
                options={services.dictionariesService.getCountriesOptions()}
                error={getFormikError(touched.country, errors.country)}
                onChange={(value: string) => {
                  setFieldValue(field.name, value);
                  setFieldValue('timezone', timezonePerCountry[value]);
                }}
                filterOption={true}
                optionFilterProp={'label'}
                showSearch
                placeholder={<FormattedMessage id="SELECT_COUNTRY" />}
              />
            )}
          </Field>
          <Field name="timezone">
            {({ field }: FieldProps<CreateWarehouseFormValues['timezone']>) => (
              <SelectField
                {...field}
                labelAlign="left"
                label={<FormattedMessage id="TIMEZONE" />}
                placeholder={<FormattedMessage id="SELECT_TIMEZONE" />}
                options={services.dictionariesService.getTimezonesOptions()}
                error={getFormikError(touched.timezone, errors.timezone)}
                onChange={value => setFieldValue(field.name, value)}
                filterOption={true}
                optionFilterProp={'label'}
                showSearch
              />
            )}
          </Field>
          <FormikInputField name="city" label={<FormattedMessage id="CITY" />} />
          <FormikInputField name="postalCode" label={<FormattedMessage id="POSTAL_CODE" />} />
          <div className={styles.buttonContainer}>
            <Button type="primary" htmlType="submit" className={styles.submitButton}>
              <FormattedMessage id="ADD" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

const FormikInputField: React.FunctionComponent<{ name: string; label: React.ReactElement }> = ({
  name,
  label,
}) => {
  const [field, meta] = useField(name);
  return (
    <InputField
      {...field}
      labelAlign="left"
      label={label}
      error={getFormikError(meta.touched, meta.error)}
    />
  );
};

const styles = stylesheet({
  buttonContainer: {
    display: 'flex',
  },
  submitButton: {
    marginLeft: 'auto',
    marginTop: '10px',
  },
  formWrapper: {
    minWidth: '335px',
  },
});
