import { useQuery } from 'react-query';
import { listCarrierProducts } from '@src/services/selfcare-service';
import { useSiteId } from './hooks';
import { groupBy } from 'lodash';

// 24 hours in milliseconds
const CACHE_STALE_TIME = 24 * 60 * 60 * 1000;

export const useGlobalCarrierProducts = () => {
  const siteId = useSiteId();

  return useQuery(
    ['carrier_products.list', siteId],
    async () => {
      const carrierList = await listCarrierProducts(siteId);

      const groupedByCarrier = groupBy(carrierList.carrier_products, item => item.carrier);

      const sortedByProduct = Object.values(groupedByCarrier)
        .map(carrier => {
          return carrier.sort((a, b) => a.product.localeCompare(b.product));
        })
        .flat();

      return { carrier_products: sortedByProduct };
    },
    {
      enabled: !!siteId,
      cacheTime: CACHE_STALE_TIME,
      staleTime: CACHE_STALE_TIME,
      keepPreviousData: true,
    }
  );
};
