import { action } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Col, Row } from 'antd';
import { FormState } from 'formstate';
import { eqBy, prop, unionWith } from 'ramda';
import { ContainerActions } from '../../../components';
import { Label } from '../../../controls';
import { LABELS } from '../../../dictionaries';
import { AddressPanel, AddressSelectorFormState } from '../../../forms';
import { ContactModel, ShipmentModel, TemplateModel } from '../../../models';

export class ShipmentAddressSelectorFormState extends FormState<{
  customerAddressSelectorFormState: AddressSelectorFormState;
  fromAddressSelectorFormState: AddressSelectorFormState;
  toAddressSelectorFormState: AddressSelectorFormState;
  returnAddressSelectorFormState: AddressSelectorFormState;
}> {
  static create = (isAddressRequired: boolean = true): ShipmentAddressSelectorFormState => {
    return new FormState({
      customerAddressSelectorFormState: AddressSelectorFormState.create({}, isAddressRequired),
      fromAddressSelectorFormState: AddressSelectorFormState.create({}, isAddressRequired),
      toAddressSelectorFormState: AddressSelectorFormState.create({}, isAddressRequired),
      returnAddressSelectorFormState: AddressSelectorFormState.create({}, false),
    });
  };

  static createWithData = (
    shipment?: ShipmentModel | TemplateModel,
    contactsList?: ContactModel[],
    isAddressRequired: boolean = true
  ): ShipmentAddressSelectorFormState => {
    const newFormState = ShipmentAddressSelectorFormState.create(isAddressRequired);

    if (shipment != null && contactsList != null) {
      const {
        customerAddressSelectorFormState,
        fromAddressSelectorFormState,
        toAddressSelectorFormState,
        returnAddressSelectorFormState,
      } = newFormState.$;

      const customerContactModel = new ContactModel();
      customerContactModel.address = shipment.customerInfo.address;
      customerContactModel.email = shipment.customerInfo.email;
      customerContactModel.phone = shipment.customerInfo.phone;
      contactsList.push(customerContactModel);
      customerAddressSelectorFormState.$.addressIdFieldState.value = customerContactModel.id;

      const fromContactModel = new ContactModel();
      fromContactModel.address = shipment.addressFrom;
      contactsList.push(fromContactModel);
      fromAddressSelectorFormState.$.addressIdFieldState.value = fromContactModel.id;

      const toContactModel = new ContactModel();
      toContactModel.address = shipment.addressTo;
      contactsList.push(toContactModel);
      toAddressSelectorFormState.$.addressIdFieldState.value = toContactModel.id;

      const returnContactModel = new ContactModel();
      returnContactModel.address = shipment.addressReturn;
      contactsList.push(returnContactModel);
      returnAddressSelectorFormState.$.addressIdFieldState.value = returnContactModel.id;
    }
    return newFormState;
  };
}

// COMPONENT

type Props = {
  formState: ShipmentAddressSelectorFormState;
  onCreateContact: (contact: ContactModel, shouldSaveInAddressBook: boolean) => void;
  onUpdateContact: (contact: ContactModel) => void;
  disabled?: boolean;
  isFetching?: boolean;
  detailsVisible?: boolean;
  autoFocus?: boolean;
  handleSearchCustomerContact?: (searchQuery: string) => void;
  handleSearchSenderContact?: (searchQuery: string) => void;
  handleSearchDeliveryAddress?: (searchQuery: string) => void;
  customerContacts: ContactModel[];
  senderContacts: ContactModel[];
  deliveryContacts: ContactModel[];
  isAddressFieldsRequired?: boolean;
  isReturnShipment?: boolean;
};

type State = {
  deliveryContacts: ContactModel[];
  senderContacts: ContactModel[];
};

@observer
export class ShipmentAddressSection extends React.Component<Props, State> {
  state = {
    deliveryContacts: this.props.deliveryContacts,
    senderContacts: this.props.senderContacts,
  };

  static getDerivedStateFromProps(props: Props, state: Props) {
    if (props.deliveryContacts !== state.deliveryContacts) {
      return {
        senderContacts: props.senderContacts,
        deliveryContacts: unionWith(
          eqBy(prop('id')),
          props.deliveryContacts,
          state.deliveryContacts
        ),
      };
    }

    return null;
  }

  @action
  handlePopulateCustomerAddress = () => {
    const { customerAddressSelectorFormState, toAddressSelectorFormState } = this.props.formState.$;

    const customerId = customerAddressSelectorFormState.$.addressIdFieldState.value;
    const contact = this.props.customerContacts.find(c => c.id === customerId);
    if (contact) {
      this.setState({ deliveryContacts: [...this.state.deliveryContacts, contact] });
    }
    toAddressSelectorFormState.$.addressIdFieldState.onChange(customerId);
  };

  @action
  handlePopulateFromAddress = () => {
    const { fromAddressSelectorFormState, returnAddressSelectorFormState } = this.props.formState.$;

    const customerId = fromAddressSelectorFormState.$.addressIdFieldState.value;
    const contact = this.props.senderContacts.find(c => c.id === customerId);
    if (contact) {
      this.setState({ senderContacts: [...this.state.senderContacts, contact] });
    }
    returnAddressSelectorFormState.$.addressIdFieldState.onChange(customerId);
  };

  @action
  handleClearAddress = (addressSelectorFormState: AddressSelectorFormState) => {
    addressSelectorFormState.$.addressIdFieldState.onChange('');
  };

  render() {
    const {
      onCreateContact,
      onUpdateContact,
      isFetching,
      disabled,
      handleSearchCustomerContact,
      handleSearchSenderContact,
      handleSearchDeliveryAddress,
      customerContacts,
      senderContacts,
      isAddressFieldsRequired = true,
      isReturnShipment = false,
    } = this.props;
    const {
      customerAddressSelectorFormState,
      fromAddressSelectorFormState,
      toAddressSelectorFormState,
      returnAddressSelectorFormState,
    } = this.props.formState.$;

    const ClearAddress = observer(({ addressSelectorFormState }) => {
      const addressId = addressSelectorFormState.$.addressIdFieldState.value;

      return (
        addressId && (
          <ContainerActions.ActionsGroup
            actionItems={[
              {
                handler: () => this.handleClearAddress(addressSelectorFormState),
                label: `${LABELS.CLEAR_ADDRESS}`,
              },
            ]}
          />
        )
      );
    });

    const ToAddressAdditionalActions = observer(
      ({ customerAddressSelectorFormState, toAddressSelectorFormState }) => {
        const customerId = customerAddressSelectorFormState.$.addressIdFieldState.value;
        const toAddressId = toAddressSelectorFormState.$.addressIdFieldState.value;

        const actionItems = [];

        if (customerId) {
          actionItems.push({
            handler: this.handlePopulateCustomerAddress,
            label: `${LABELS.USE} ${LABELS.RECIPIENT} ${LABELS.ADDRESS}`,
          });
        }

        if (toAddressId) {
          actionItems.push({
            handler: () => this.handleClearAddress(toAddressSelectorFormState),
            label: `${LABELS.CLEAR_ADDRESS}`,
          });
        }

        return <ContainerActions.ActionsGroup actionItems={actionItems} />;
      }
    );

    const ReturnAddressAdditionalActions = observer(
      ({ fromAddressFormState, returnAddressFormState }) => {
        const customerId = fromAddressFormState.$.addressIdFieldState.value;
        const returnAddressId = returnAddressFormState.$.addressIdFieldState.value;

        const actionItems = [];

        if (customerId) {
          actionItems.push({
            handler: this.handlePopulateFromAddress,
            label: `${LABELS.USE} ${LABELS.SENDER} ${LABELS.ADDRESS}`,
          });
        }

        if (returnAddressId) {
          actionItems.push({
            handler: () => this.handleClearAddress(returnAddressFormState),
            label: `${LABELS.CLEAR_ADDRESS}`,
          });
        }

        return <ContainerActions.ActionsGroup actionItems={actionItems} />;
      }
    );

    return (
      <section>
        <Row align="top" gutter={[0, 32]}>
          <Col sm={24} md={12}>
            <Label text="Recipient" required={isAddressFieldsRequired} />
            <AddressPanel
              detailsVisible={true}
              disabled={disabled}
              additionalActions={
                <ClearAddress addressSelectorFormState={customerAddressSelectorFormState} />
              }
              isFetching={isFetching}
              formState={customerAddressSelectorFormState}
              onCreateContact={onCreateContact}
              onUpdateContact={onUpdateContact}
              contacts={customerContacts}
              filterType="consignee"
              handleSearch={handleSearchCustomerContact}
            />
          </Col>
          <Col sm={24} md={12}>
            <Label text="Sender Address" required={isAddressFieldsRequired} />
            <AddressPanel
              detailsVisible={true}
              disabled={disabled}
              additionalActions={
                <ClearAddress addressSelectorFormState={fromAddressSelectorFormState} />
              }
              isFetching={isFetching}
              formState={fromAddressSelectorFormState}
              onCreateContact={onCreateContact}
              onUpdateContact={onUpdateContact}
              contacts={senderContacts}
              filterType="consignor"
              handleSearch={handleSearchSenderContact}
            />
          </Col>
          <Col sm={24} md={12}>
            <Label text="Delivery Address" required={isAddressFieldsRequired} />
            <AddressPanel
              detailsVisible={true}
              disabled={disabled}
              additionalActions={
                <ToAddressAdditionalActions
                  customerAddressSelectorFormState={customerAddressSelectorFormState}
                  toAddressSelectorFormState={toAddressSelectorFormState}
                />
              }
              isFetching={isFetching}
              formState={toAddressSelectorFormState}
              onCreateContact={onCreateContact}
              onUpdateContact={onUpdateContact}
              contacts={this.state.deliveryContacts}
              filterType="delivery"
              handleSearch={handleSearchDeliveryAddress}
            />
          </Col>
          <Col sm={24} md={12}>
            <Label text="Return Address" />
            <AddressPanel
              detailsVisible={true}
              disabled={disabled || isReturnShipment}
              additionalActions={
                !isReturnShipment ? (
                  <ReturnAddressAdditionalActions
                    fromAddressFormState={fromAddressSelectorFormState}
                    returnAddressFormState={returnAddressSelectorFormState}
                  />
                ) : undefined
              }
              isFetching={isFetching}
              formState={returnAddressSelectorFormState}
              onCreateContact={onCreateContact}
              onUpdateContact={onUpdateContact}
              contacts={this.state.senderContacts}
              filterType="consignor"
              handleSearch={handleSearchSenderContact}
            />
          </Col>
        </Row>
      </section>
    );
  }
}
