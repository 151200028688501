import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;

const INIT_APP = '@app/INIT_APP';
const INIT_AUTH = '@app/INIT_AUTH';
const RESET_ERRORS = '@app/RESET_ERRORS';

export const initApp = createAction(INIT_APP);
export const initAuth = createAction(INIT_AUTH);
export const resetErrors = createAction(RESET_ERRORS);
