import React, { FC } from 'react';
import { Row, Col } from 'antd';
import { stylesheet as tss } from 'typestyle';

import { FormattedMessage } from '@src/i18n';

export const TableGrid: FC = ({ children }) => (
  <Row className={styles.row}>
    <Col className={styles.tableLabel} span={4}>
      <FormattedMessage id="FEATURES" />
    </Col>
    <Col span={20}>{children}</Col>
  </Row>
);

const styles = tss({
  row: {
    paddingBottom: '22px',
  },
  tableLabel: {
    display: 'flex',
    alignItems: 'flex-start',
  },
});
