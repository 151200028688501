import { TrackingStatus } from '@src/models';

export const directions = ['OUTBOUND', 'RETURN', 'UNSPECIFIED'] as const;

export type Direction = typeof directions[number];

export type TrackingRecord = {
  tracking_number: string;
  external_id?: string;
  shipment_id: string;
  tos_id?: string;
  shipping_method: string;
  carrier_product_ref?: string;
  latest_tracking_status: TrackingStatus;
  tracking_record_source?: TrackingRecordSource;
  created_at: string;
  updated_at: string;
  direction_type: Direction;
  carrier_product_name?: string;
  carrier_product_carrier?: string;
  carrier_product_id?: string;
};

export type TrackingRecordSource =
  | 'TRACKING_RECORD_SOURCE_INTERNAL'
  | 'TRACKING_RECORD_SOURCE_EXTERNAL'
  | 'TRACKING_RECORD_SOURCE_UNKNOWN'
  | 'TRACKING_RECORD_SOURCE_GENERATOR';

export type ListTrackingRecordsResponse = {
  tracking_records: TrackingRecord[];
  cursor?: PaginationTokens;
};

export type PaginationTokens = {
  next_page?: string;
  previous_page?: string;
};

export type TrackingRecordFiltering = {
  id?: string;
  created_at_range?: {
    start?: string;
    end?: string;
  };
  updated_at_range?: {
    start?: string;
    end?: string;
  };
  direction_types?: Direction[];
  carrier_product_ids?: string;
  carrier_product_refs?: string;
  statuses?: TrackingStatus[];
  sources?: TrackingRecordSource[];
};

export type Carrier = {
  name: string;
  carrier_products: {
    id: string;
    name: string;
  }[];
};

export type AvailableCarrierProductList = {
  available_carriers: Carrier[];
};

export type TrackingPreviewDrawerData = {
  externalId: string;
  trackingNumber: string;
  tosId: string;
};

export type TrackingRecordsTotalCount = {
  tracking_records_count: {
    count_value: string | undefined;
  };
};
