import { ArrowLeftOutlined } from '@ant-design/icons';
// this button import needs to be here in order for tooltip to work
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { stylesheet } from 'typestyle';
import { WidgetFormValues } from '../helpers';

import { Card as AntCard, Modal, Tooltip } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { mainScrollContainerId } from '@src/layouts/context-layout';
import { services } from '@src/services';

const env = services.envService.getEnvironmentalVariables();

type Props = {
  widgetReady: boolean;
  onBack: () => void;
  sessionCompleteEnabled: boolean;
  isClosingSession: boolean;
  isFetching: boolean;
  createTestOrder: (values: WidgetFormValues) => void;
};

export const Card: React.FunctionComponent<Props> = ({
  children,
  widgetReady,
  onBack,
  sessionCompleteEnabled,
  createTestOrder,
  isClosingSession,
  isFetching,
}) => {
  const { formatMessage } = useIntl();
  const { values, errors, setFieldValue } = useFormikContext<WidgetFormValues>();

  const [draftError, setDraftError] = React.useState(false);
  const [deliveryAddressError, setDeliveryAddressError] = React.useState(false);
  const [createOrderModalVisible, setCreateOrderModalVisibility] = React.useState(false);

  const deliveryAddressIsNotValid = Object.values(values.deliveryAddress).some(
    addressPart => !addressPart
  );

  React.useEffect(() => {
    setDeliveryAddressError(deliveryAddressIsNotValid);
  }, [deliveryAddressIsNotValid]);

  React.useEffect(() => {
    setDraftError(values.useDraft);
  }, [values.useDraft]);

  const createTestOrderCB = () => {
    createTestOrder(values);
    setFieldValue('deliveryAddress.addressLine1', '');
    setFieldValue('deliveryAddress.postalCode', '');
    setFieldValue('deliveryAddress.city', '');
    setFieldValue('deliveryAddress.name', 'Ingrid Tester');
    setFieldValue('deliveryAddress.email', 'test@ingrid.com');
  };

  return (
    <AntCard
      title={
        widgetReady ? (
          <div onClick={onBack} className={styles.back}>
            <ArrowLeftOutlined style={{ fontSize: '16px' }} />{' '}
            <FormattedMessage id="CLOSE_WIDGET_SESSION" />
          </div>
        ) : (
          <FormattedMessage id="SETTINGS" />
        )
      }
      extra={
        <>
          {sessionCompleteEnabled && widgetReady ? (
            <Tooltip
              getPopupContainer={() =>
                document.getElementById(mainScrollContainerId) || document.body
              }
              title={
                (draftError &&
                  formatMessage({
                    id: 'CHECKOUT_TESTCLIENT.CREATE_ORDER_BUTTON.NO_DRAFT_ERROR',
                  })) ||
                (deliveryAddressError &&
                  formatMessage({
                    id: 'CHECKOUT_TESTCLIENT.CREATE_ORDER_BUTTON.NO_DELIVERY_ADDRESS_ERROR',
                  })) ||
                (errors.deliveryAddress?.postalCode &&
                  formatMessage({ id: 'CHECKOUT_TESTCLIENT.CREATE_ORDER_BUTTON.FORM_ERROR' }))
              }
            >
              <Button
                type="primary"
                className={styles.completeSessionButton}
                disabled={
                  draftError || deliveryAddressError || !!errors.deliveryAddress?.postalCode
                }
                onClick={() => {
                  if (env.MAD_DEPLOY_TARGET === 'production') {
                    setCreateOrderModalVisibility(true);
                  } else {
                    createTestOrderCB();
                  }
                }}
                loading={isClosingSession}
              >
                <FormattedMessage id="CHECKOUT_TESTCLIENT.CREATE_ORDER_BUTTON.TITLE" />
              </Button>
            </Tooltip>
          ) : null}
          <Modal
            title={<FormattedMessage id="CHECKOUT_TESTCLIENT.CREATE_ORDER_MODAL.HEADER" />}
            visible={createOrderModalVisible}
            okText={<FormattedMessage id="CHECKOUT_TESTCLIENT.CREATE_ORDER_MODAL.CREATE_BUTTON" />}
            onOk={() => {
              setCreateOrderModalVisibility(false);
              createTestOrderCB();
            }}
            onCancel={() => setCreateOrderModalVisibility(false)}
          >
            <FormattedMessage id="CHECKOUT_TESTCLIENT.CREATE_ORDER_MODAL.DESCRIPTION" />
          </Modal>
          <Button type="primary" htmlType="submit" loading={isFetching}>
            <FormattedMessage id={widgetReady ? 'UPDATE' : 'GO_TO_WIDGET'} />
          </Button>
        </>
      }
    >
      {children}
    </AntCard>
  );
};

const styles = stylesheet({
  completeSessionButton: {
    marginRight: '10px',
  },
  back: { cursor: 'pointer' },
});
