import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { object, string } from 'yup';

import { Input, TextArea } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { inputStyle, MakeAutoSaveFormikEnhancer, widerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { DeliveryAddonTranslationModel } from '@src/models';
import { configActions } from '@src/modules/config';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { getFormikError } from '@src/utils/forms';

const TypedAutoSaveEnhancer = MakeAutoSaveFormikEnhancer<TranslationsFormValues>();

const InputField = withFormItem(Input, widerLayout);
const TextAreaField = withFormItem(TextArea, widerLayout);

interface Props {
  language: string;
  translation?: DeliveryAddonTranslationModel;
  categoryId: string;
  addonId: string;
}

interface TranslationsFormValues {
  name?: string;
  description?: string;
}

const TranslationsFormValuesSchema = object({
  description: string().max(360),
});

export const DeliveryAddonTranslationEdit: React.FunctionComponent<Props> = ({
  translation,
  language,
  categoryId,
  addonId,
}) => {
  const handleUpdate = (values: TranslationsFormValues) => {
    dispatchOnDraftSiteId(configActions.upsertDeliveryAddonTranslationRequest)({
      model: {
        categoryId,
        addonId,
        locale: language,
        ...values,
      },
    });
  };

  return (
    <Formik<TranslationsFormValues>
      // tslint:disable-next-line: no-empty
      onSubmit={() => {}}
      initialValues={{
        name: translation?.name,
        description: translation?.description,
      }}
      enableReinitialize={true}
      validationSchema={TranslationsFormValuesSchema}
    >
      {({ errors, touched }) => (
        <>
          <TypedAutoSaveEnhancer
            name="name"
            onSave={({ name }) => handleUpdate({ name })}
            render={({ name, onBlur, onKeyDown }) => (
              <Field name={name}>
                {({ field }: FieldProps<TranslationsFormValues['name']>) => (
                  <InputField
                    label={
                      <FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.TRANSLATION_NAME" />
                    }
                    labelTooltip={
                      <FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.TRANSLATION_NAME_TOOLTIP" />
                    }
                    className={inputStyle}
                    {...field}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    placeholder={'-'}
                  />
                )}
              </Field>
            )}
          />
          <TypedAutoSaveEnhancer
            name="description"
            onSave={({ description }) => handleUpdate({ description })}
            render={({ name, onBlur }) => (
              <Field name={name}>
                {({ field }: FieldProps<TranslationsFormValues['description']>) => (
                  <TextAreaField
                    label={
                      <FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.TRANSLATION_DESCRIPTION" />
                    }
                    labelTooltip={
                      <FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.TRANSLATION_DESCRIPTION_TOOLTIP" />
                    }
                    className={inputStyle}
                    {...field}
                    error={getFormikError(touched.description, errors.description)}
                    onBlur={onBlur}
                    placeholder={'-'}
                  />
                )}
              </Field>
            )}
          />
        </>
      )}
    </Formik>
  );
};
