import { Modal } from 'antd';
import { Formik } from 'formik';
import React, { FC } from 'react';
import { GeneralInformation } from './general-information';
import { ItemsDetails } from './items-details';
import { useUpsertCustomsDeclaration } from './queries';
import type { FormValues, GeneralCustomsDeclaration } from './types';
import { mapToFormValues } from './utils';

type Props = {
  onClose: () => void;
  visible: boolean;
  shipmentId: string;
  customsDeclaration: GeneralCustomsDeclaration | undefined;
};

export const CustomsDeclarationFormModal: FC<Props> = ({
  customsDeclaration,
  onClose,
  visible,
  shipmentId,
}) => {
  const initialValues = mapToFormValues(customsDeclaration);

  const { isLoading, mutateAsync } = useUpsertCustomsDeclaration();

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={async formValues => {
        await mutateAsync({
          formValues,
          unmodifiedCustomsDeclaration: customsDeclaration ?? {},
          shipmentId,
        });
        onClose();
      }}
      enableReinitialize
    >
      {({ submitForm }) => (
        <Modal
          title="Add customs declaratons"
          width={1200}
          okText="Send custom declaration"
          confirmLoading={isLoading}
          onOk={submitForm}
          visible={visible}
          onCancel={onClose}
          centered
        >
          <form>
            <GeneralInformation />
            <ItemsDetails />
          </form>
        </Modal>
      )}
    </Formik>
  );
};
