import {
  buildTrackingLinksFormValue,
  CarrierProductMappingForm,
} from '@src/containers/product-mappings/form-commons';
import { listTrackingLinksForCarrierProduct } from '@src/services/selfcare-service';
import { FormikHelpers } from 'formik/dist/types';
import { useTrackingConfigStatusMutation } from '@src/containers/product-mappings/query-commons';
import { FormikProps } from 'formik';

export const useHandleOnCarrierProductChange = (siteId: string) => {
  const { getTrackingConfigStatus, isLoadingTrackingConfigStatus } =
    useTrackingConfigStatusMutation(siteId);

  const onProductCarrierChange = async (
    newCarrierProductId: string,
    formik: FormikHelpers<CarrierProductMappingForm>
  ) => {
    const status = await getTrackingConfigStatus(newCarrierProductId);
    const trackingLinks = await listTrackingLinksForCarrierProduct(siteId, newCarrierProductId);
    formik.setFieldValue('always_show_tracking_links', false);

    if (trackingLinks) {
      formik.setFieldValue(
        'tracking_links',
        buildTrackingLinksFormValue(trackingLinks.tracking_links)
      );
    }

    formik.setFieldValue('tracking_config_status', status);
    formik.setFieldValue('carrier_product_id', newCarrierProductId);
  };

  return { onProductCarrierChange, isLoadingTrackingConfigStatus };
};

export const handleDisabled = (formik: FormikProps<CarrierProductMappingForm>) => {
  return (
    Object.keys(formik.values.tracking_links).length === 0 ||
    formik.values.tracking_links?.[0]?.url === ''
  );
};

export const handleCustomMappingChange = (
  formik: FormikHelpers<CarrierProductMappingForm>,
  customMappingValue: boolean
) => {
  formik.setFieldValue('carrier_product_id', '');
  formik.setFieldValue('always_show_tracking_links', false);
  formik.setFieldValue('tracking_config_status', '');
  formik.setFieldValue('tracking_links', [{ locale: 'default', url: '' }]);
  formik.setFieldValue('custom', customMappingValue);
};
