import { createIntl, createIntlCache } from 'react-intl';

import * as enUSMessages from '../../locales/en-US.json';

const cache = createIntlCache();

export const intl = createIntl(
  {
    locale: 'en-US',
    messages: enUSMessages,
  },
  cache
);
