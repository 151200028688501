import { Col, Row } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { FormattedMessage } from '@src/i18n';

import { PlaceholderCarrierLogosFeatureFlag } from './placeholder-carrier-logos-feature-flag';
import { PlaceholderCarrierLogosTable } from './placeholder-carrier-logos-table';

type Props = {
  availableCarrierLogos: string[];
  carrierLogos: string[];
  featureFlag: boolean;
  updateCarrierLogos: (carrierLogos: string[]) => void;
  updateFeatureFlag: (featureFlag: boolean) => void;
};

export const PlaceholderCarrierLogos: React.FC<Props> = ({
  availableCarrierLogos,
  carrierLogos,
  featureFlag,
  updateCarrierLogos,
  updateFeatureFlag,
}) => {
  return (
    <Row className={styles.wrapper}>
      <Col span={4}>
        <FormattedMessage id="CARRIER_LOGOS" />
      </Col>
      <Col span={20}>
        <div className={styles.contentWrapper}>
          <PlaceholderCarrierLogosFeatureFlag
            featureFlag={featureFlag}
            updateFeatureFlag={updateFeatureFlag}
          />
          {featureFlag && (
            <PlaceholderCarrierLogosTable
              availableCarrierLogos={availableCarrierLogos}
              carrierLogos={carrierLogos}
              updateCarrierLogos={updateCarrierLogos}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

const styles = stylesheet({
  wrapper: {
    paddingTop: '32px',
  },
  contentWrapper: {
    maxWidth: '660px',
  },
});
