import {
  GenerateTrackingRecordPayload,
  GenerateTrackingRecordResponse,
  TrackingNumberOptionResponse,
} from '@src/models';
import { apiUtils, authFetch } from '../utils';

const URL = `${apiUtils.getApiUrl()}/demo`;

export const getTrackingNumberOptions = async (siteId: string) =>
  await authFetch<TrackingNumberOptionResponse>(`${URL}/tracking_number_options.get`, {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  });

export const generateTrackingRecord = async (
  siteId: string,
  payload: GenerateTrackingRecordPayload
) =>
  await authFetch<GenerateTrackingRecordResponse>(`${URL}/generate_tracking_record`, {
    method: 'POST',
    body: JSON.stringify({ record_info: payload }),
    headers: {
      'x-site-id': siteId,
    },
  });
