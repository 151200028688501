export class UserProfileShipmentsModel {
  shipmentIds?: string[] = [];

  static createFromDTO(dto: UserProfileShipmentsDTO): UserProfileShipmentsModel {
    return {
      shipmentIds: dto.shipment_ids ? dto.shipment_ids : [],
    };
  }
}

export class UserProfileShipmentsDTO {
  shipment_ids?: string[];
}

export class UserProfileCreateShipmentModel {
  shipmentId: string;
  action: string;
}

export class UserProfileCreateShipmentDTO {
  shipment_id: string;
  action: string;

  static createFromModel(model: UserProfileCreateShipmentModel): UserProfileCreateShipmentDTO {
    return {
      shipment_id: model.shipmentId,
      action: model.action,
    };
  }
}

export class UserTagModel {
  id: string;
  name: string;
  userId?: string;

  static createFromDTO(dto: UserTagDTO): UserTagModel {
    return {
      id: dto.id,
      name: dto.name,
      userId: dto.user_id,
    };
  }
}

export class UserTagDTO {
  id: string;
  name: string;
  user_id?: string;

  static createFromModel(model: UserTagModel): UserTagDTO {
    return {
      id: model.id,
      name: model.name,
      user_id: model.userId,
    };
  }
}

export class SiteTagModel extends UserTagModel {}

export class SiteTagDTO extends UserTagDTO {}

export class ShipmentIdsByTagDTO {
  tag: UserTagDTO;
  shipment_ids: string[];
}

export class ShipmentIdsByTagModel {
  tag: UserTagModel;
  shipmentIds: string[];

  static createFromDTO(model: ShipmentIdsByTagDTO): ShipmentIdsByTagModel {
    return {
      tag: {
        id: model.tag.id,
        name: model.tag.name,
        userId: model.tag.user_id,
      },
      shipmentIds: model.shipment_ids,
    };
  }
}
