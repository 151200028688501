import { prop, sortBy } from 'ramda';
import { combineEpics, Epic } from 'redux-observable';
import { from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, filter, map, mergeMap, reduce, switchMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { ExtendedShippingMethodModel } from '@src/models';
import { RootAction, RootState, Services } from '..';
import { MADError } from '../../utils';
// Check if we can import this action other way
import { listShippingMethodSuccess } from '../config/actions';
import { merchantsActions } from '../merchants';
import { sitesActions } from '../sites';

const getSites: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(merchantsActions.changeActiveMerchantAndSite)),
    map(({ payload }) => sitesActions.getSitesRequest(payload))
  );

const getSiteonLogIn: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(merchantsActions.selectActiveMerchant)),
    map(({ payload }) => sitesActions.getSitesRequest(payload))
  );

const getSitesByMerchantId: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { configService, loggingService, selfCareService }
) =>
  action$.pipe(
    filter(isActionOf(sitesActions.getSitesRequest)),
    switchMap(action => {
      const siteIds = [action.payload.siteId];

      const merchant = (state$.value.merchants.merchants || []).find(
        merchant => merchant.id === action.payload.merchantId
      );
      if (merchant) {
        const draftSite = merchant.sites.find(
          site => site.isDraft && site.masterSiteId === action.payload.siteId
        );
        if (draftSite) {
          siteIds.push(draftSite.id);
        }
      }

      const promises = siteIds.map(siteId => configService.getSite(siteId));
      return observableFrom(Promise.all(promises)).pipe(
        mergeMap(sites => {
          return observableFrom(sites).pipe(
            mergeMap(site =>
              observableFrom(selfCareService.listShippingMethods(site.id)).pipe(
                map(methods => ({ siteId: site.id, methods }))
              )
            ),
            reduce((acc, { siteId, methods }) => {
              return { ...acc, [siteId]: methods };
            }, {} as { [key: string]: ExtendedShippingMethodModel[] }),
            mergeMap(siteIdToMethodsMap => {
              const newSites = sortBy(prop('name'), sites);
              return observableFrom([
                listShippingMethodSuccess(siteIdToMethodsMap),
                sitesActions.getSitesSuccess(newSites),
              ]);
            })
          );
        }),
        tap(() => action.meta.onComplete()),
        catchError((error: MADError) =>
          observableOf(sitesActions.getSitesError(error)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      );
    })
  );

export const sitesEpics = combineEpics(getSites, getSiteonLogIn, getSitesByMerchantId);
