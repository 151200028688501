import { getIn, useFormikContext } from 'formik';
import * as React from 'react';
import { CarrierProductMappingForm } from '../form-commons';
import {
  AlwaysShowTrackingLinksField,
  CarrierProductField,
  CarrierProductRefField,
  LocaleField,
  TrackingConfigStatusField,
  UrlField,
  UseCustomMappingField,
} from './fields';

type InputFieldProps = {
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
};

type SwitchFieldProps = {
  onChange?: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
};

type SelectFieldProps = {
  onChange?: (value: string) => void;
  className?: string;
};

export const CarrierProductFieldConnected: React.FC<SelectFieldProps> = ({
  onChange,
  className,
}) => {
  const formik = useFormikContext<CarrierProductMappingForm>();
  const onChangeDefault = (value: string) => formik.setFieldValue(`carrier_product_id`, value);
  return (
    <CarrierProductField
      value={formik.values.carrier_product_id}
      error={formik.touched.carrier_product_id ? formik.errors.carrier_product_id : undefined}
      onChange={onChange || onChangeDefault}
      className={className}
    />
  );
};

export const TrackingConfigStatusFieldConnected: React.FC<{ isLoading: boolean }> = ({
  isLoading,
}) => {
  const formik = useFormikContext<CarrierProductMappingForm>();
  return (
    <TrackingConfigStatusField
      status={formik.values.tracking_config_status}
      isLoading={isLoading}
    />
  );
};

export const CarrierProductRefFieldConnected: React.FC<InputFieldProps> = props => {
  const formik = useFormikContext<CarrierProductMappingForm>();
  return (
    <CarrierProductRefField
      value={formik.values.carrier_product_ref}
      error={formik.touched.carrier_product_ref ? formik.errors.carrier_product_ref : undefined}
      onChange={event => formik.setFieldValue('carrier_product_ref', event.currentTarget.value)}
      {...props}
    />
  );
};

export const AlwaysShowTrackingLinksFieldConnected: React.FC<SwitchFieldProps> = ({
  onChange,
  disabled,
}) => {
  const formik = useFormikContext<CarrierProductMappingForm>();

  const onChangeDefault = (checked: boolean) =>
    formik.setFieldValue('always_show_tracking_links', checked);

  return (
    <AlwaysShowTrackingLinksField
      checked={formik.values.always_show_tracking_links}
      error={formik.errors.always_show_tracking_links}
      disabled={disabled}
      onChange={onChange || onChangeDefault}
    />
  );
};

export const UseCustomMappingFieldConnected: React.FC<SwitchFieldProps> = ({ onChange }) => {
  const formik = useFormikContext<CarrierProductMappingForm>();
  const onChangeDefault = (checked: boolean) => formik.setFieldValue('custom', checked);

  return (
    <UseCustomMappingField
      checked={formik.values.custom}
      error={formik.errors.custom}
      onChange={onChange || onChangeDefault}
    />
  );
};

type LocaleFieldProps = {
  idx: number;
  disabled: boolean;
  onChange?: (value: string) => void;
  className?: string;
};

export const LocaleFieldConnected: React.FC<LocaleFieldProps> = ({
  idx,
  onChange,
  className,
  disabled,
}) => {
  const formik = useFormikContext<CarrierProductMappingForm>();

  const onChangeDefault = (value: string) =>
    formik.setFieldValue(`tracking_links[${idx}]`, {
      locale: value,
      url: formik.values.tracking_links[idx].url,
    });

  return (
    <LocaleField
      idx={idx}
      value={formik.values.tracking_links[idx].locale}
      error={
        getIn(formik.touched, `tracking_links[${idx}].locale`)
          ? getIn(formik.errors, `tracking_links[${idx}].locale`)
          : undefined
      }
      onChange={onChange || onChangeDefault}
      disabled={disabled}
      className={className}
    />
  );
};

export const UrlFieldConnected: React.FC<InputFieldProps & { idx: number }> = ({
  idx,
  ...restProps
}) => {
  const formik = useFormikContext<CarrierProductMappingForm>();

  return (
    <UrlField
      value={formik.values.tracking_links[idx].url}
      error={
        getIn(formik.touched, `tracking_links[${idx}].url`)
          ? getIn(formik.errors, `tracking_links[${idx}].url`)
          : undefined
      }
      onChange={e =>
        formik.setFieldValue(`tracking_links[${idx}]`, {
          locale: formik.values.tracking_links[idx].locale,
          url: e.currentTarget.value,
        })
      }
      idx={idx}
      {...restProps}
    />
  );
};
