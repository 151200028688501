import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;

import {
  TemplateModelRequest,
  TemplateModelResponse,
  UserProfileCreateShipmentModel,
  UserProfileShipmentsModel,
} from '../../models';

const CREATE_TEMPLATE_REQUEST = '@addressBook/CREATE_TEMPLATE_REQUEST';
const CREATE_TEMPLATE_SUCCESS = '@addressBook/CREATE_TEMPLATE_SUCCESS';
const CREATE_TEMPLATE_ERROR = '@addressBook/CREATE_TEMPLATE_ERROR';
const DELETE_TEMPLATES_REQUEST = '@addressBook/DELETE_TEMPLATES_REQUEST';
const DELETE_TEMPLATES_SUCCESS = '@addressBook/DELETE_TEMPLATES_SUCCESS';
const DELETE_TEMPLATES_ERROR = '@addressBook/DELETE_TEMPLATES_ERROR';
const GET_TEMPLATE_LIST_REQUEST = '@addressBook/GET_TEMPLATE_LIST_REQUEST';
const GET_TEMPLATE_LIST_SUCCESS = '@addressBook/GET_TEMPLATE_LIST_SUCCESS';
const GET_TEMPLATE_LIST_ERROR = '@addressBook/GET_TEMPLATE_LIST_ERROR';
const UPDATE_TEMPLATE_REQUEST = '@addressBook/UPDATE_TEMPLATE_REQUEST';
const UPDATE_TEMPLATE_SUCCESS = '@addressBook/UPDATE_TEMPLATE_SUCCESS';
const UPDATE_TEMPLATE_ERROR = '@addressBook/UPDATE_TEMPLATE_ERROR';
const CREATE_SHIPMENT_ACTION_REQUEST = '@addressBook/CREATE_SHIPMENT_ACTION_REQUEST';
const CREATE_SHIPMENT_ACTION_SUCCESS = '@addressBook/CREATE_SHIPMENT_ACTION_SUCCESS';
const CREATE_SHIPMENT_ACTION_ERROR = '@addressBook/CREATE_SHIPMENT_ACTION_ERROR';
const GET_SHIPMENT_IDS_REQUEST = '@addressBook/GET_SHIPMENT_IDS_REQUEST';
const GET_SHIPMENT_IDS_SUCCESS = '@addressBook/GET_SHIPMENT_IDS_SUCCESS';
const GET_SHIPMENT_IDS_ERROR = '@addressBook/GET_SHIPMENT_IDS_ERROR';

export const createTemplateRequest = createAction(
  CREATE_TEMPLATE_REQUEST,
  resolve => (payload: { siteId: string; template: TemplateModelRequest }) => resolve(payload)
);
export const createTemplateSuccess = createAction(
  CREATE_TEMPLATE_SUCCESS,
  resolve => (template: TemplateModelResponse) => resolve(template)
);
export const createTemplateError = createAction(
  CREATE_TEMPLATE_ERROR,
  resolve => (error: string) => resolve(error)
);

export const deleteTemplatesRequest = createAction(
  DELETE_TEMPLATES_REQUEST,
  resolve => (payload: { siteId: string; templateIds: string[] }) => resolve(payload)
);
export const deleteTemplatesSuccess = createAction(
  DELETE_TEMPLATES_SUCCESS,
  resolve => (ids: string[]) => resolve(ids)
);
export const deleteTemplatesError = createAction(
  DELETE_TEMPLATES_ERROR,
  resolve => (error: string) => resolve(error)
);

export const getTemplateListRequest = createAction(
  GET_TEMPLATE_LIST_REQUEST,
  resolve =>
    ({ onComplete, ...payload }: { siteId: string; tags?: string[]; onComplete?: () => void }) =>
      resolve(payload, { onComplete })
);
export const getTemplateListSuccess = createAction(
  GET_TEMPLATE_LIST_SUCCESS,
  resolve => (templateList: TemplateModelResponse[]) => resolve(templateList)
);
export const getTemplateListError = createAction(
  GET_TEMPLATE_LIST_ERROR,
  resolve => (error: string) => resolve(error)
);

export const updateTemplateRequest = createAction(
  UPDATE_TEMPLATE_REQUEST,
  resolve => (payload: { siteId: string; template: TemplateModelRequest }) => resolve(payload)
);
export const updateTemplateSuccess = createAction(
  UPDATE_TEMPLATE_SUCCESS,
  resolve => (template: TemplateModelResponse) => resolve(template)
);
export const updateTemplateError = createAction(
  UPDATE_TEMPLATE_ERROR,
  resolve => (error: string) => resolve(error)
);

export const createShipmentActionRequest = createAction(
  CREATE_SHIPMENT_ACTION_REQUEST,
  resolve => (payload: { siteId: string; model: UserProfileCreateShipmentModel }) =>
    resolve(payload)
);
export const createShipmentActionSuccess = createAction(CREATE_SHIPMENT_ACTION_SUCCESS);
export const createShipmentActionError = createAction(
  CREATE_SHIPMENT_ACTION_ERROR,
  resolve => (error: string) => resolve(error)
);

export const getShipmentIdsRequest = createAction(
  GET_SHIPMENT_IDS_REQUEST,
  resolve => (payload: { siteId: string }) => resolve(payload)
);
export const getShipmentIdsSuccess = createAction(
  GET_SHIPMENT_IDS_SUCCESS,
  resolve => (ids: UserProfileShipmentsModel) => resolve(ids)
);
export const getShipmentIdsError = createAction(
  GET_SHIPMENT_IDS_ERROR,
  resolve => (error: string) => resolve(error)
);
