import { uniqBy } from 'ramda';
import * as React from 'react';

import { ConfigShippingCategoryModel } from '@src/models';
import { configActions, configSelectors } from '@src/modules/config';
import { siwActions } from '@src/modules/siw';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { useDispatch, useSelector } from '@src/utils/hooks';

import { PlaceholderCarrierLogos } from './components';

const getCarrier = (shippingMethod: string) => shippingMethod.split('-')[0];

const getCarrierLogos = (categories: ConfigShippingCategoryModel[]) => {
  const shippingMethods = categories
    .flatMap(category => category.shippingMethods)
    .filter(shippingMethod => !shippingMethod.startsWith('ics'));
  return uniqBy(getCarrier, shippingMethods);
};

type Props = {
  countryCode: string;
};

export const PlaceholderCarrierLogosContainer: React.FC<Props> = ({ countryCode }) => {
  const availableCarrierLogos = useSelector(state =>
    getCarrierLogos(configSelectors.filterCategoriesByCountry(state, countryCode))
  );
  const widgetCountryConfiguration = useSelector(state =>
    configSelectors.getWidgetCountryConfiguration(state, countryCode)
  );

  const dispatch = useDispatch();
  const updateFeatureFlag = (value: boolean) => {
    dispatchOnDraftSiteId(configActions.updateWidgetCountryConfigurationRequest)({
      model: {
        country: countryCode,
        features: {
          showCarrierLogosInShippingCategoriesPlaceholder: value,
        },
      },
      onComplete: () => dispatch(siwActions.resetWidgetSession()),
    });
  };
  const updateCarrierLogos = (carrierLogos: string[]) => {
    dispatchOnDraftSiteId(configActions.updateWidgetCountryConfigurationRequest)({
      model: {
        country: countryCode,
        carrierLogos: { methods: carrierLogos },
      },
      onComplete: () => dispatch(siwActions.resetWidgetSession()),
    });
  };

  if (!widgetCountryConfiguration) {
    return null;
  }
  return (
    <PlaceholderCarrierLogos
      availableCarrierLogos={availableCarrierLogos}
      carrierLogos={widgetCountryConfiguration.carrierLogos?.methods ?? []}
      featureFlag={
        !!widgetCountryConfiguration.features.showCarrierLogosInShippingCategoriesPlaceholder
      }
      updateCarrierLogos={updateCarrierLogos}
      updateFeatureFlag={updateFeatureFlag}
    />
  );
};
