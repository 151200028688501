import { GentokenModel } from '../models';
import { apiUtils, authFetch } from '../utils';
import { getLabelToken } from './gentoken-service';

const LABEL_MERGER_URL = apiUtils.getApiUrl() + '/label_merger';

export async function mergeLabels(siteId: string, urls: string[]): Promise<GentokenModel> {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({
      site_id: siteId,
      file_names: urls,
    }),
  };

  return authFetch<{ url: string }>(`${LABEL_MERGER_URL}/merge_labels`, options, true).then(
    ({ url }) => getLabelToken(siteId, url, '.pdf')
  );
}
