import { Field, FieldProps, useFormikContext } from 'formik';
import React from 'react';
import { Checkbox, Tabs } from 'antd';
import { append, remove } from 'ramda';

import { useFormatMessage } from '@src/i18n';
import { FormValues, initialParcel, useGetGenerateOptions } from './common';
import { Select } from '@src/controls';
import { bigLabelLayout } from '@src/forms';
import { withFormItem } from '@src/decorators';
import { getFormikError } from '@src/utils/forms';
import { DeliveryTypeEnum, Step } from '@src/models';
import { ComponentReturningChildren } from '@src/components';
import { MessagingNotificationTypeVariants } from '@src/containers/tracking-playground/generate-section/notification-type-variants';

const SelectField = withFormItem(Select, bigLabelLayout);
const LabelWrapper = withFormItem(ComponentReturningChildren, bigLabelLayout);
const FIRST_PARCEL_INDEX = 0;

type Props = {
  isGeneratingToken?: boolean;
};

export const ParcelTabs = ({ isGeneratingToken }: Props) => {
  const {
    setFieldValue,
    resetForm,
    values: { parcels, deliveryType },
  } = useFormikContext<FormValues>();
  const { data, isLoading } = useGetGenerateOptions();
  const formatMessage = useFormatMessage();

  const handleEdit = (targetKey: string, action: 'add' | 'remove') => {
    if (isGeneratingToken) {
      return;
    }
    switch (action) {
      case 'add':
        setFieldValue('parcels', append(initialParcel, parcels));
        break;
      case 'remove':
        const idx = parseInt(targetKey, 10);
        setFieldValue('parcels', remove(idx, 1, parcels));
        break;
    }
  };

  return (
    <Tabs type="editable-card" onEdit={handleEdit} tabPosition="top" hideAdd={parcels.length >= 4}>
      {parcels.map((_parcel, idx) => (
        <Tabs.TabPane
          key={`${idx}`}
          tab={formatMessage('PARCEL_WITH_NUMBER', { number: idx + 1 })}
          closable={parcels.length > 1}
        >
          <Field name="deliveryType">
            {({
              field,
              form: { values, touched, errors },
            }: FieldProps<FormValues['deliveryType']>) => (
              <SelectField
                {...field}
                label={formatMessage('DELIVERY_TYPE', { multiple: false })}
                labelTooltip={formatMessage(
                  idx != 0 ? 'DELIVERY_TYPE_DISABLED_TOOLTIP' : 'DELIVERY_TYPE_CHANGE_TOOLTIP'
                )}
                options={data.deliveryTypeOptions ?? []}
                filterOption={true}
                showSearch
                loading={isLoading}
                disabled={idx != 0 || isLoading || isGeneratingToken}
                optionFilterProp="label"
                error={getFormikError(touched, errors)}
                onChange={(value: DeliveryTypeEnum) => {
                  resetForm({
                    values: {
                      deliveryType: value,
                      parcels: [
                        {
                          progress:
                            data.progressOptionsPerDeliveryType[value]?.[0].value ?? 'STEP_UNKNOWN',
                        },
                      ],
                      notificationType: {
                        sms: values.notificationType.sms,
                        email: values.notificationType.email,
                      },
                      emailAddress: values.notificationType.email ? values.emailAddress : '',
                      phoneNumber: values.notificationType.sms ? values.phoneNumber : '',
                    },
                  });
                }}
                getPopupContainer={() =>
                  document.getElementById('select-scroll-helper') || document.body
                }
                required
              />
            )}
          </Field>
          <Field name={`parcels[${idx}].progress`}>
            {({ field, form: { touched, errors } }: FieldProps<Step>) => (
              <SelectField
                {...field}
                label={formatMessage('PROGRESS')}
                options={data.progressOptionsPerDeliveryType[deliveryType] ?? []}
                filterOption={true}
                showSearch
                loading={isLoading}
                disabled={isLoading || isGeneratingToken}
                optionFilterProp="label"
                error={getFormikError(touched, errors)}
                onChange={value => {
                  setFieldValue(`parcels[${idx}].progress`, value);
                }}
                getPopupContainer={() =>
                  document.getElementById('select-scroll-helper') || document.body
                }
                required
              />
            )}
          </Field>
          {idx === FIRST_PARCEL_INDEX && (
            <>
              <LabelWrapper
                label={formatMessage('TRACKING_PLAYGROUND.NOTIFICATIONS.NOTIFICATION_TYPE')}
              >
                <Field name="notificationType">
                  {() => (
                    <Checkbox.Group name="notification-types">
                      <Checkbox
                        name="notificationType.email"
                        value="Email"
                        onChange={value => {
                          setFieldValue('notificationType.email', value.target.checked);
                        }}
                        disabled={isGeneratingToken}
                      >
                        {formatMessage('TRACKING_PLAYGROUND.NOTIFICATIONS.NOTIFICATION_TYPE_EMAIL')}
                      </Checkbox>
                      <Checkbox
                        name="notificationType.text"
                        value="Text"
                        onChange={value => {
                          setFieldValue('notificationType.sms', value.target.checked);
                        }}
                        disabled={isGeneratingToken}
                      >
                        {formatMessage('TRACKING_PLAYGROUND.NOTIFICATIONS.NOTIFICATION_TYPE_TEXT')}
                      </Checkbox>
                    </Checkbox.Group>
                  )}
                </Field>
              </LabelWrapper>
              <MessagingNotificationTypeVariants isGeneratingToken={isGeneratingToken} />
            </>
          )}
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};
