import { object, string } from 'yup';

export const nameValidation = (forbidenNames: string[]) =>
  object({
    name: string()
      .trim()
      .required()
      .lowercase()
      .notOneOf(forbidenNames, 'This name already exists')
      .max(255),
  });
