export const getEnvironmentalVariables = (): Config => {
  const injectedConfig = window.CONFIG;
  const MAD_APP_VERSION = process.env.VERSION || 'default';

  if (injectedConfig && 'development' in injectedConfig) {
    // local env
    return injectedConfig[process.env.API_ENV || 'development'];
  }
  // k8s env
  return {
    ...injectedConfig,
    MAD_APP_VERSION,
  };
};
