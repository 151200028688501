import { isNil } from 'ramda';
import { combineEpics, Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { RootAction, RootState, Services } from '../../modules';
import { MADError } from '../../utils';
import { dictionariesActions } from '../dictionaries';

const fetchAvailableLocalesEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { localesService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(dictionariesActions.getAvailableLocalesRequest)),
    switchMap(() =>
      from(localesService.getAvailableLocales()).pipe(
        map(res => dictionariesActions.getAvailableLocalesSuccess(res)),
        catchError((error: MADError) =>
          of(dictionariesActions.getAvailableLocalesError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const loadCarrierAddonsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { configService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf([dictionariesActions.getCarrierAddonsRequest])),
    filter(() => state$.value.sites.selectedSiteId != null),
    switchMap(() => {
      const siteId = state$.value.sites.selectedSiteId;
      if (isNil(siteId)) {
        throw new Error('No selectedSiteId in the store');
      }
      return configService
        .getCarrierAddons(siteId)
        .then(res => dictionariesActions.getCarrierAddonsSuccess(res));
    }),
    catchError((error: MADError) => {
      loggingService.logError(error);
      return of(dictionariesActions.getCarrierAddonsError(error.message));
    })
  );

export const dictionariesEpics = combineEpics(loadCarrierAddonsEpic, fetchAvailableLocalesEpic);
