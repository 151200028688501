import { clone, isNil, mapObjIndexed } from 'ramda';
import { isNotEmpty, isNotNil, isUndefined } from 'ramda-adjunct';
import {
  createDeliveryTypeEnum,
  DeactivateShippingMethodDTO,
  DeactivateShippingMethodModel,
  DeliveryAddonType,
  DeliveryTypeEnum,
  GenericAddressEntityDTO,
  GenericAddressEntityModel,
  ParcelTypeEnum,
  StateEnum,
  UpdateCarrierProductDTO,
  UpdateCarrierProductModel,
  UpdateCategoryDetailsDTO,
  UpdateCategoryDetailsModel,
  UpdateCategoryTranslationsDTO,
  UpdateCategoryTranslationsModel,
  UpdateConfigRegionDTO,
  UpdateConfigRegionModel,
  UpdateDeliveryTimeDTO,
  UpdateDeliveryTimeModel,
  UpdateWarehouseCutoffTimesDTO,
  UpdateWarehouseCutoffTimesModel,
  UpdateWarehouseDetailsDTO,
  UpdateWarehouseDetailsModel,
} from './';
import {
  AddCategoryTagsDTO,
  AddCategoryTagsModel,
  AdjustDeliveryAddonsOrderDTO,
  AdjustDeliveryAddonsOrderModel,
  ContactDataDTO,
  ContactDataModel,
  CreateCarrierProductDTO,
  CreateCarrierProductMappingRequest,
  CreateCarrierProductModel,
  CreateCategoryDTO,
  CreateCategoryLabelDTO,
  CreateCategoryLabelModel,
  CreateCategoryModel,
  CreateDeliveryAddonDTO,
  CreateDeliveryAddonModel,
  CreateRegion,
  CreateWarehouseCutoffTimesDTO,
  CreateWarehouseCutoffTimesModel,
  CreateWarehouseDTO,
  CreateWarehouseModel,
  DeleteCarrierProductDTO,
  DeleteCarrierProductMappingRequest,
  DeleteCarrierProductModel,
  DeleteCategoryLabelDTO,
  DeleteCategoryLabelModel,
  DeleteCategoryLabelTranslationsDTO,
  DeleteCategoryLabelTranslationsModel,
  DeleteCustomEventTranslationDTO,
  DeleteCustomEventTranslationModel,
  DeleteDeliveryAddonDTO,
  DeleteDeliveryAddonModel,
  DeleteDeliveryAddonTranslationDTO,
  DeleteDeliveryAddonTranslationModel,
  DeleteTranslationDTO,
  DeleteTranslationModel,
  DeleteWarehouseCutoffTimesDTO,
  DeleteWarehouseCutoffTimesModel,
  RemoveCategoryTagsDTO,
  RemoveCategoryTagsModel,
  UpdateCarrierProductMappingRequest,
  UpdateCategoryLabelDetailsDTO,
  UpdateCategoryLabelDetailsModel,
  UpdateCategoryLabelsOrderDTO,
  UpdateCategoryLabelsOrderModel,
  UpdateCategoryLabelTranslationsDTO,
  UpdateCategoryLabelTranslationsModel,
  UpdateDeliveryAddonDTO,
  UpdateDeliveryAddonModel,
  UpdateInternalEventDTO,
  UpdateInternalEventModel,
  UpdatePreselectionOrderDTO,
  UpdatePreselectionOrderModel,
  UpdateWidgetCountryConfigurationDTO,
  UpdateWidgetCountryConfigurationModel,
  UpsertCarrierProductSettingsRequest,
  UpsertCustomEventDTO,
  UpsertCustomEventModel,
  UpsertCustomEventTranslationDTO,
  UpsertCustomEventTranslationModel,
  UpsertDeliveryAddonTranslationDTO,
  UpsertDeliveryAddonTranslationModel,
} from './selfcare-models';
import { TrackingStatus } from './wimo-models';
import { FeatureFlags as ProductPageWidgetFeatures } from '@src/api/product-page-widget';
import { FeatureFlags as FaqWidgetFeatures } from '@src/api/faq-widget';

export class ConfigSiteModel {
  id: string;
  name: string;
  privateKey: string;
  regions: ConfigRegionModel[];
  warehouses: ConfigWarehouseModel[];
  shippingCategories: ConfigShippingCategoryModel[];
  metadata?: SiteMetadataModel;
  createdAt: string;
  updatedAt: string;
  version: number;
  upsellWidgetConfiguration?: any;
  faqWidgetConfiguration?: FaqWidgetConfiguration & {
    by_country?: {
      [key: string]: FaqWidgetConfiguration;
    };
  };
  productPageWidgetConfiguration?: ProductPageWidgetConfiguration & {
    by_country?: {
      [key: string]: ProductPageWidgetConfiguration;
    };
  };
  widgetConfiguration?: WidgetConfigurationModel;
  bookingEnabled?: boolean;
  tracking?: {
    carrierProductMappings?: CarrierProductMappingModel[];
    eventTypes?: EventTypesModel;
  };
  customShippingMethods?: {
    methods?: CustomShippingMethodModel[];
  };
  receiptWidgetConfiguration?: ReceiptWidgetConfiguration & {
    by_country?: {
      [key: string]: ReceiptWidgetConfiguration;
    };
  };
  customBookingMethods?: CustomBookingMethodModel;
  serviceConfigurations?: ServiceConfigurationModel[];
  addressFormConfiguration?: AddressFormConfigurationModel;

  static createFromDTO(dto: ConfigSiteDTO): ConfigSiteModel {
    return {
      id: dto.id,
      name: dto.name,
      privateKey: dto.private_key,
      regions: dto.regions
        ? dto.regions.map(region => ConfigRegionModel.createFromDTO(region))
        : [],
      shippingCategories: dto.shipping_categories
        ? dto.shipping_categories.map(category =>
            ConfigShippingCategoryModel.createFromDTO(category)
          )
        : [],
      metadata: dto.metadata && SiteMetadataModel.createFromDTO(dto.metadata),
      createdAt: dto.created_at,
      updatedAt: dto.updated_at,
      version: dto.version,
      warehouses: dto.warehouses
        ? dto.warehouses.map(warehouse => ConfigWarehouseModel.createFromDTO(warehouse))
        : [],
      upsellWidgetConfiguration: dto.delivery_upsell_widget_configuration,
      widgetConfiguration:
        dto.widget_configuration &&
        WidgetConfigurationModel.createFromDTO(dto.widget_configuration),
      bookingEnabled: dto.booking_enabled,
      tracking: dto.tracking && {
        carrierProductMappings: dto.tracking.carrier_product_mappings?.map(dto =>
          CarrierProductMappingModel.createFromDTO(dto)
        ),
        eventTypes:
          dto.tracking.event_types && EventTypesModel.createFromDTO(dto.tracking.event_types),
      },
      customShippingMethods: {
        methods: dto.custom_shipping_methods?.methods?.map(method =>
          CustomShippingMethodModel.createFromDTO(method)
        ),
      },
      receiptWidgetConfiguration: dto.receipt_widget_configuration,
      customBookingMethods: dto.custom_booking_methods,
      faqWidgetConfiguration: dto.faq_widget_configuration,
      productPageWidgetConfiguration: dto.product_page_widget_configuration,
      serviceConfigurations: dto.service_configurations
        ? dto.service_configurations.map(dto => ServiceConfigurationModel.createFromDTO(dto))
        : [],
      addressFormConfiguration:
        dto.address_form_configuration &&
        AddressFormConfigurationModel.createFromDTO(dto.address_form_configuration),
    };
  }
}

export class SiteMetadataModel {
  draft?: {
    isDraft?: boolean;
    masterSiteId?: string;
  };
  isVerified?: boolean;

  static createFromDTO(dto: SiteMetadataDTO): SiteMetadataModel {
    return {
      draft: {
        isDraft: dto.draft && dto.draft.is_draft,
        masterSiteId: dto.draft && dto.draft.master_site_id,
      },
      isVerified: dto.is_verified,
    };
  }
}

class SiteMetadataDTO {
  draft?: {
    is_draft?: boolean;
    master_site_id?: string;
  };
  is_verified?: boolean;
}

type SectionSettings = {
  enable_header: boolean;
  enable_section: boolean;
};

type HeaderSettings = {
  enable_section: boolean;
};

export type ReceiptWidgetConfiguration = {
  features: {
    enable_transparent_background?: boolean;
    enable_accent_color_for_widget_elements?: boolean;
    dark_color_enabled_for_ingrid_slogan?: boolean;
    enable_widget_border?: boolean;
    rating: SectionSettings;
    footer: {
      enable_section: boolean;
      enable_ingrid_logo: boolean;
      show_slogan: boolean;
      show_privacy_policy: boolean;
    };
    header: HeaderSettings;
    order_details: SectionSettings;
    order_summary: SectionSettings;
    delivery: SectionSettings & {
      is_courier_instructions_editable: boolean;
      is_door_code_editable: boolean;
    };
  };
};

export type FaqWidgetConfiguration = {
  features: FaqWidgetFeatures;
};

export type ProductPageWidgetConfiguration = {
  features: ProductPageWidgetFeatures;
};

export class ConfigSiteDTO {
  id: string;
  name: string;
  private_key: string;
  regions?: ConfigRegionDTO[];
  shipping_categories?: ConfigShippingCategoryDTO[];
  metadata?: SiteMetadataDTO;
  warehouses?: ConfigWarehouseDTO[];
  created_at: string;
  updated_at: string;
  version: number;
  delivery_upsell_widget_configuration: any;
  faq_widget_configuration: FaqWidgetConfiguration;
  product_page_widget_configuration: ProductPageWidgetConfiguration;
  widget_configuration?: WidgetConfigurationDTO;
  booking_enabled?: boolean;
  tracking?: {
    carrier_product_mappings: CarrierProductMappingDTO[];
    event_types?: EventTypesDTO;
  };
  custom_shipping_methods?: {
    methods?: CustomShippingMethodDTO[];
  };
  receipt_widget_configuration?: ReceiptWidgetConfiguration & {
    by_country?: { [key: string]: ReceiptWidgetConfiguration };
  };
  custom_booking_methods?: CustomBookingMethodDTO;
  service_configurations?: ServiceConfigurationDTO[];
  address_form_configuration: AddressFormConfigurationDTO;
}

export class EventTypesModel {
  custom: CustomEventModel[];
  internal: InternalEventModel[];

  static createFromDTO(dto: EventTypesDTO): EventTypesModel {
    return {
      custom: dto?.custom?.map(custom_event => CustomEventModel.createFromDTO(custom_event)) ?? [],
      internal:
        dto?.internal.map(internal_event => InternalEventModel.createFromDTO(internal_event)) ?? [],
    };
  }
}

export class CustomEventModel {
  customEventTypeId: string;
  name: string;
  state: 'ACTIVE' | 'INACTIVE';
  localization?: { [key: string]: CustomEventLocalizationModel };

  static createFromDTO(dto: CustomEventDTO): CustomEventModel {
    return {
      customEventTypeId: dto.custom_event_type_id,
      name: dto.name,
      state: dto.state,
      localization:
        dto.localization &&
        Object.entries(dto.localization).reduce(
          (prev, [key, value]: [string, CustomEventLocationDTO]) => ({
            ...prev,
            [key]: CustomEventLocalizationModel.createFromDTO(value),
          }),
          {}
        ),
    };
  }
}

export class CustomEventLocalizationModel {
  name?: string;
  description?: string;

  static createFromDTO(dto: CustomEventLocationDTO): CustomEventLocalizationModel {
    return {
      name: dto.name,
      description: dto.description,
    };
  }
}

export class CustomEventLocalizationDTO {
  name?: string;
  description?: string;
}

export class CustomEventLocationDTO {
  name?: string;
  description?: string;
}

export class InternalEventModel {
  id: TrackingStatus;
  state: 'ACTIVE' | 'INACTIVE' | 'UNKNOWN';

  static createFromDTO(dto: InternalEventDTO): InternalEventModel {
    return {
      id: dto.id,
      state: dto.state ? dto.state : 'UNKNOWN',
    };
  }
}

export class InternalEventDTO {
  id: TrackingStatus;
  state?: 'ACTIVE' | 'INACTIVE' | 'UNKNOWN';
}

export class EventTypesDTO {
  custom?: CustomEventDTO[];
  internal: InternalEventDTO[];
}

export class CustomEventDTO {
  custom_event_type_id: string;
  name: string;
  state: 'ACTIVE' | 'INACTIVE';
  localization?: { [key: string]: CustomEventLocalizationDTO };
}

export class CarrierProductMappingModel {
  id: string;
  custom: boolean;
  carrierProductRef: string;
  carrierProductId: string;
  trackingLinks: { [langCode: string]: string };
  alwaysShowTrackingLinks: boolean;

  static createFromDTO(dto: CarrierProductMappingDTO): CarrierProductMappingModel {
    return {
      id: dto.id,
      custom: dto.custom,
      carrierProductRef: dto.carrier_product_ref,
      carrierProductId: dto.carrier_product_id,
      trackingLinks: { ...dto.tracking_links },
      alwaysShowTrackingLinks: dto.always_show_tracking_links,
    };
  }
}

export class CarrierProductMappingDTO {
  id: string;
  custom: boolean;
  carrier_product_ref: string;
  carrier_product_id: string;
  tracking_links: { [langCode: string]: string };
  always_show_tracking_links: boolean;

  static createFromModel(model: CarrierProductMappingModel): CarrierProductMappingDTO {
    return {
      id: model.id,
      custom: model.custom,
      carrier_product_ref: model.carrierProductRef,
      carrier_product_id: model.carrierProductId,
      tracking_links: { ...model.trackingLinks },
      always_show_tracking_links: model.alwaysShowTrackingLinks,
    };
  }
}

export class ConfigWarehouseModel {
  id: string;
  address: GenericAddressEntityModel;
  operatingSchedule?: OperatingScheduleModel;
  state?: StateEnum;
  timezone: string;
  createdAt: string;
  shippingDateAdjustment?: ShippingDateAdjustmentModel;
  contactData?: ContactDataModel;

  static createFromDTO(dto: ConfigWarehouseDTO): ConfigWarehouseModel {
    return {
      id: dto.id,
      address: GenericAddressEntityModel.createFromDTO(dto.address),
      operatingSchedule:
        dto.operating_schedule && OperatingScheduleModel.createFromDTO(dto.operating_schedule),
      state: isUndefined(dto.state) ? dto.status : dto.state,
      timezone: dto.timezone,
      createdAt: dto.created_at,
      shippingDateAdjustment:
        dto.shipping_date_adjustment &&
        ShippingDateAdjustmentModel.createFromDTO(dto.shipping_date_adjustment),
      contactData: dto.contact_data && ContactDataModel.createFromDTO(dto.contact_data),
    };
  }
}

export class ConfigWarehouseDTO {
  id: string;
  address: GenericAddressEntityDTO;
  operating_schedule?: OperatingScheduleDTO;
  status?: StateEnum;
  state?: StateEnum;
  timezone: string;
  created_at: string;
  shipping_date_adjustment?: ShippingDateAdjustmentDTO;
  contact_data?: ContactDataDTO;
}

export class LocalizationModel {
  name?: string;
  customText?: string;
  customInfoText?: string;

  static createFromDTO(dto: LocalizationDTO): LocalizationModel {
    return {
      name: dto.name,
      customText: dto.custom_text,
      customInfoText: dto.custom_info_text,
    };
  }
}

export class LocalizationDTO {
  name?: string;
  custom_text?: string;
  custom_info_text?: string;

  static createFromModel(model: LocalizationModel): LocalizationDTO {
    return {
      name: model.name,
      custom_text: model.customText,
      custom_info_text: model.customInfoText,
    };
  }
}

export class OperatingScheduleModel {
  [shippingMethod: string]:
    | {
        cutoffTimes?: CutoffTimesModel;
        carrierCutoff?: boolean;
      }
    | undefined;

  static createFromDTO(dto: OperatingScheduleDTO): OperatingScheduleModel {
    return Object.entries(dto).reduce(
      (prev, [shippingMethod, value]) => ({
        ...prev,
        [shippingMethod]: { cutoffTimes: value.cutoff_times, carrierCutoff: value.carrier_cutoff },
      }),
      {}
    );
  }
}

class OperatingScheduleDTO {
  [shipping_method: string]: {
    cutoff_times?: CutoffTimesModel;
    carrier_cutoff?: boolean;
  };
}

export enum Weekdays {
  mon = 'mon',
  tue = 'tue',
  wed = 'wed',
  thu = 'thu',
  fri = 'fri',
  sat = 'sat',
  sun = 'sun',
}

export type CutoffTimesModel = {
  [key in Weekdays]?: {
    times?: string[];
  };
};

export class CategoryRequirementsModel {
  address?: boolean;
  usePriceFrom?: boolean;
  customerNumber?: boolean;
  showCarrierLogos?: boolean;
  enableChoiceSelection?: boolean;
  enableInstoreLocationsSearch?: boolean;

  static createFromDTO(dto: CategoryRequirementsDTO = {}): CategoryRequirementsModel {
    return {
      address: isNil(dto.address) ? false : dto.address,
      showCarrierLogos: isNil(dto.show_carrier_logos) ? false : dto.show_carrier_logos,
      enableChoiceSelection: isNil(dto.enable_choice_selection)
        ? false
        : dto.enable_choice_selection,
      usePriceFrom: isNil(dto.use_price_from) ? false : dto.use_price_from,
      customerNumber: isNil(dto.customer_number) ? false : dto.customer_number,
    };
  }
}

export class CategoryRequirementsDTO {
  address?: boolean;
  use_price_from?: boolean;
  customer_number?: boolean;
  show_carrier_logos?: boolean;
  enable_choice_selection?: boolean;
  enable_instore_locations_search?: boolean;

  static createFromModel(model: CategoryRequirementsModel): CategoryRequirementsDTO {
    return {
      address: model.address,
      use_price_from: model.usePriceFrom,
      customer_number: model.customerNumber,
      show_carrier_logos: model.showCarrierLogos,
      enable_choice_selection: model.enableChoiceSelection,
    };
  }
}

export class CategoryLabelModel {
  id: string;
  name: string;
  type:
    | 'LABEL_TYPE_PRIORITY'
    | 'LABEL_TYPE_SUSTAINABILITY'
    | 'LABEL_TYPE_CUSTOM'
    | 'LABEL_TYPE_SWAN';
  state: 'ACTIVE' | 'INACTIVE' | 'UNKNOWN';
  localization?: {
    [key: string]: LabelLocalizationModel | undefined;
  };
}

export class LabelLocalizationModel {
  description?: string;
  name?: string;
}

export class CategoryLabelDTO extends CategoryLabelModel {}

export class DeliveryAddonTranslationModel {
  description?: string;
  name?: string;
}

export class DeliveryAddonTranslationDTO {
  description?: string;
  name?: string;
}

export class DeliveryAddonModel {
  id: string;
  name: string;
  type: DeliveryAddonType;
  externalId: string;
  defaults: {
    selected: boolean;
  };
  price: {
    value: string;
    vatRate: number;
  };
  localization?: {
    [locale: string]: DeliveryAddonTranslationModel;
  };
  state: 'ACTIVE' | 'INACTIVE';

  static createFromDTO(dto: DeliveryAddonDTO): DeliveryAddonModel {
    return {
      id: dto.id,
      name: dto.name,
      type: dto.type,
      externalId: dto.external_id,
      defaults: {
        selected: dto.defaults.selected,
      },
      price: {
        value: dto.price.value,
        vatRate: dto.price.vat_rate,
      },
      localization: dto.localization,
      state: dto.state,
    };
  }
}

export class DeliveryAddonDTO {
  id: string;
  name: string;
  type: DeliveryAddonType;
  external_id: string;
  defaults: {
    selected: boolean;
  };
  price: {
    value: string;
    vat_rate: number;
  };
  localization?: {
    [locale: string]: DeliveryAddonTranslationModel;
  };
  state: 'ACTIVE' | 'INACTIVE';

  static createFromModel(model: DeliveryAddonModel): DeliveryAddonDTO {
    return {
      id: model.id,
      name: model.name,
      type: model.type,
      external_id: model.externalId,
      defaults: {
        selected: model.defaults.selected,
      },
      price: {
        value: model.price.value,
        vat_rate: model.price.vatRate,
      },
      localization: model.localization,
      state: model.state,
    };
  }
}

export class ConfigShippingCategoryModel {
  id: string;
  name: string;
  sortOrder?: number;
  shippingMethods: string[];
  deliveryType: DeliveryTypeEnum;
  deliveryTime: DeliveryTimeModel;
  filterRules: ConfigFilterRuleModel[];
  countrySettings?: CountrySettingsModel;
  tags: ConfigCategoryTagsModel[];
  regionIds: string[];
  deliveryChain?: ConfigDeliveryChainModel;
  timeFormatting: DeliveryTimeRange;
  localization?: { [key: string]: LocalizationModel };
  preselected?: boolean;
  state?: StateEnum;
  requirements: CategoryRequirementsModel;
  deliveryTimeProperties: DeliveryTimePropertiesModel;
  additionalOptions: CategoryAdditionalOptionsModel;
  labels?: CategoryLabelModel[];
  deliveryAddons?: DeliveryAddonModel[];
  maxChoicesCount?: number;

  static createFromDTO(dto: ConfigShippingCategoryDTO): ConfigShippingCategoryModel {
    return {
      id: dto.id,
      name: dto.name,
      sortOrder: dto.sort_order,
      shippingMethods: dto.shipping_methods || [],
      deliveryType: dto.delivery_type,
      deliveryTime: {
        min: dto.delivery_time.min || 0,
        max: dto.delivery_time.max || 0,
        unit: dto.delivery_time.unit,
      },
      countrySettings:
        dto.country_settings && CountrySettingsModel.createFromDTO(dto.country_settings),
      filterRules: dto.filter_rules
        ? dto.filter_rules.map(ConfigFilterRuleModel.createFromDTO)
        : [],
      tags: dto.tags || [],
      regionIds: dto.region_ids || [],
      deliveryChain:
        dto.delivery_chain && ConfigDeliveryChainModel.createFromDTO(dto.delivery_chain),
      timeFormatting: dto.time_formatting,
      localization:
        dto.localization &&
        Object.entries(dto.localization).reduce(
          (prev, [key, value]: [string, LocalizationDTO]) => ({
            ...prev,
            [key]: LocalizationModel.createFromDTO(value),
          }),
          {}
        ),
      preselected: dto.preselected,
      state: isUndefined(dto.state) ? dto.status : dto.state,
      requirements: CategoryRequirementsModel.createFromDTO(dto.requirements),
      deliveryTimeProperties: DeliveryTimePropertiesModel.createFromDTO(
        dto.delivery_time_properties
      ),
      additionalOptions: CategoryAdditionalOptionsModel.createFromDTO(dto.additional_options),
      labels: dto.labels,
      deliveryAddons: dto.delivery_addons?.map(delivery_addon =>
        DeliveryAddonModel.createFromDTO(delivery_addon)
      ),
      maxChoicesCount: dto.max_choices_count,
    };
  }
}

export class CategoryAdditionalOptionsModel {
  instoreSearchOptions: {
    enabled: boolean;
    locationsLimit: number;
  };

  static createFromDTO(
    dto: CategoryAdditionalOptionsDTO | undefined
  ): CategoryAdditionalOptionsModel {
    return {
      instoreSearchOptions: {
        enabled: dto?.instore_search_options?.enabled ?? false,
        locationsLimit: dto?.instore_search_options?.locations_limit ?? 10,
      },
    };
  }
}

export class CategoryAdditionalOptionsDTO {
  instore_search_options?: {
    enabled: boolean;
    locations_limit: number;
  };

  static createFromModel(
    model: CategoryAdditionalOptionsModel | undefined
  ): CategoryAdditionalOptionsDTO {
    return {
      instore_search_options: {
        enabled: model?.instoreSearchOptions.enabled ?? false,
        locations_limit: model?.instoreSearchOptions.locationsLimit ?? 0,
      },
    };
  }
}

export type DeliveryTimeRange = 'UNKNOWN_TIME_FORMATTING' | 'MIN' | 'MAX' | 'INTERVAL';

export type DeliveryTimeFormat =
  | 'days'
  | 'date'
  | 'weekday'
  | 'weekday_date'
  | 'weeks'
  | 'weeks_numbers';

export class DeliveryTimePropertiesDTO {
  format?: DeliveryTimeFormat;
  range?: DeliveryTimeRange;
  use_timespan?: boolean;
  custom_text?: string;
  timespan?: {
    from?: string;
    to?: string;
  } | null;
  ignore_cutoffs?: boolean;
  keep_delivery_time_after_address?: boolean;

  static createFromModel(model: DeliveryTimePropertiesModel): DeliveryTimePropertiesDTO {
    return {
      format: model.format,
      range: model.range,
      use_timespan: model.useTimespan,
      custom_text: model.customText,
      timespan: model.timespan,
      ignore_cutoffs: model.ignoreCutoffs,
      keep_delivery_time_after_address: model.keepDeliveryTimeAfterAddress,
    };
  }
}

export class CountrySettingsModel {
  [country: string]:
    | {
        preselectionOrder: number;
        useForFallback?: boolean;
      }
    | undefined;

  static createFromDTO(dto: CountrySettingsDTO): CountrySettingsModel {
    const stuff = Object.entries(dto);
    const result = stuff.reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: {
          preselectionOrder: value.preselection_order,
          useForFallback: value.use_for_fallback,
        },
      }),
      {}
    );
    return result;
  }
}

export class DeliveryTimePropertiesModel {
  format?: DeliveryTimeFormat;
  range?: DeliveryTimeRange;
  customText?: string;
  timespan?: {
    from?: string;
    to?: string;
  } | null;
  useTimespan?: boolean;
  ignoreCutoffs?: boolean;
  keepDeliveryTimeAfterAddress?: boolean;

  static createFromDTO(dto: DeliveryTimePropertiesDTO = {}): DeliveryTimePropertiesModel {
    return {
      format: dto.format,
      range: dto.range,
      useTimespan: dto.use_timespan || false,
      customText: dto.custom_text,
      timespan: dto.timespan,
      ignoreCutoffs: dto.ignore_cutoffs === undefined ? false : dto.ignore_cutoffs,
      keepDeliveryTimeAfterAddress: dto.keep_delivery_time_after_address,
    };
  }
}

export class ConfigShippingCategoryDTO {
  id: string;
  name: string;
  sort_order: number;
  shipping_methods: string[];
  delivery_type: DeliveryTypeEnum;
  delivery_time: DeliveryTimeDTO;
  country_settings?: CountrySettingsDTO;
  filter_rules?: ConfigFilterRuleDTO[];
  region_ids: string[];
  price?: number;
  tags?: ConfigCategoryTagsDTO[];
  delivery_chain?: ConfigDeliveryChainDTO;
  time_formatting: DeliveryTimeRange;
  localization: { [key: string]: LocalizationDTO };
  preselected?: boolean;
  state?: StateEnum;
  status?: StateEnum;
  requirements?: CategoryRequirementsDTO;
  delivery_time_properties?: DeliveryTimePropertiesDTO;
  additional_options?: CategoryAdditionalOptionsDTO;
  labels?: CategoryLabelDTO[];
  delivery_addons?: DeliveryAddonDTO[];
  max_choices_count?: number;
}

export class CountrySettingsDTO {
  [country: string]: {
    preselection_order: number;
    use_for_fallback?: boolean;
  };
}

export class ConfigCategoryTagsDTO {
  name: string;
}

export class ConfigCategoryTagsModel {
  name: string;

  static createFromDTO(dto: ConfigCategoryTagsDTO): ConfigCategoryTagsModel {
    return {
      name: dto.name,
    };
  }
}

export class ConfigFilterRuleDTO {
  condition: string;
}

export class ConfigFilterRuleModel {
  condition: string;

  static createFromDTO(dto: ConfigFilterRuleDTO): ConfigFilterRuleModel {
    return {
      condition: dto.condition,
    };
  }
}

export class ConfigDeliveryChainModel {
  firstMile: ConfigChainStepModel;
  lastMiles: ConfigChainStepModel[];

  static createFromDTO(dto: ConfigDeliveryChainDTO): ConfigDeliveryChainModel {
    return {
      firstMile: dto?.first_mile
        ? ConfigChainStepModel.createFromDTO(dto.first_mile)
        : new ConfigChainStepModel(),
      lastMiles: dto?.last_miles
        ? dto.last_miles.map(entry => ConfigChainStepModel.createFromDTO(entry))
        : [],
    };
  }
}

export class ConfigDeliveryChainDTO {
  first_mile?: ConfigChainStepDTO;
  last_miles?: ConfigChainStepDTO[];
}

export class ConfigChainStepModel {
  /*Represents a part of a chained delivery.*/

  shippingMethod: string;

  static createFromDTO(dto: ConfigChainStepDTO): ConfigChainStepModel {
    return {
      shippingMethod: dto.shipping_method,
    };
  }
}

export class ConfigChainStepDTO {
  shipping_method: string;
}

export type DeliveryTimeUnit = 'full_day' | 'business_day' | 'day' | 'hour' | 'minute' | 'week';

export class DeliveryTimeModel {
  min: number;
  max: number;
  unit: DeliveryTimeUnit;
}

export class DeliveryTimeDTO {
  min: number;
  max: number;
  unit: DeliveryTimeUnit;
}

export class PriceRulesDTO {
  condition?: string;
  price?: number;
  valid_from?: string;
  valid_until?: string;
  conditions?: string[];

  static createFromModel(model: PriceRulesModel): PriceRulesDTO {
    return {
      condition: model.condition,
      price: model.price,
      valid_from: model.validFrom,
      valid_until: model.validUntil,
      conditions: model.conditions,
    };
  }
}

class CarrierProductDTO {
  shipping_method: string;
  delivery_types_text: string[];
  supported_shipping_attributes: string[];
  price_rules?: PriceRulesDTO[];
  filter_rules?: {
    condition: string;
  }[];
  delivery_types: DeliveryTypeEnum[];
  external_method_id?: string;
  operating_schedule?: {
    cutoffs_plan: {
      day: Days;
      cutoff_times: string[];
      carrier_cutoff: boolean;
    };
  };
  delivery_time: {
    min: number;
    max: number;
    unit: string;
  };
  shipping_date_adjustment?: ShippingDateAdjustmentDTO;
  generate_default_shipping_options_on_error: boolean;
}

export class PriceRulesModel {
  condition?: string;
  price?: number;
  validFrom?: string;
  validUntil?: string;
  conditions?: string[];

  static createFromDTO(dto: PriceRulesDTO): PriceRulesModel {
    return {
      condition: dto.condition,
      price: dto.price,
      validUntil: dto.valid_until,
      validFrom: dto.valid_from,
      conditions: dto.conditions,
    };
  }
}

export class CarrierProductModel {
  shippingMethod: string;
  deliveryTypesText: string[];
  supportedShippingAttributes?: string[];
  priceRules: PriceRulesModel[];
  filterRules?: {
    condition: string;
  }[];
  deliveryTypes: DeliveryTypeEnum[];
  externalMethodId?: string;
  operatingSchedule?: {
    cutoffsPlan: {
      day: Days;
      cutoffTimes: string[];
    };
    carrierCutoff: boolean;
  };
  deliveryTime?: {
    min: number;
    max: number;
    unit: string;
  };
  generateDefaultShippingOptionsOnError?: boolean;
  shippingDateAdjustment?: ShippingDateAdjustmentModel;

  static createFromDTO(dto: CarrierProductDTO): CarrierProductModel {
    return {
      shippingMethod: dto.shipping_method,
      deliveryTypesText: dto.delivery_types_text,
      supportedShippingAttributes: dto.supported_shipping_attributes,
      priceRules: dto.price_rules
        ? dto.price_rules.map(rule => PriceRulesModel.createFromDTO(rule))
        : [{ conditions: ['true'], price: 0 }],
      filterRules: dto.filter_rules
        ? dto.filter_rules.map(rule => ({
            condition: rule.condition,
          }))
        : undefined,
      deliveryTypes: dto.delivery_types,
      externalMethodId: dto.external_method_id,
      operatingSchedule: dto.operating_schedule
        ? {
            cutoffsPlan: {
              day: dto.operating_schedule.cutoffs_plan.day,
              cutoffTimes: dto.operating_schedule.cutoffs_plan.cutoff_times,
            },
            carrierCutoff: dto.operating_schedule.cutoffs_plan.carrier_cutoff,
          }
        : undefined,
      deliveryTime: dto.delivery_time,
      generateDefaultShippingOptionsOnError: dto.generate_default_shipping_options_on_error,
      shippingDateAdjustment:
        dto.shipping_date_adjustment &&
        ShippingDateAdjustmentModel.createFromDTO(dto.shipping_date_adjustment),
    };
  }
}

export class ConfigRegionDTO {
  id: string;
  name: string;
  region_type: RegionType;
  region_country_config?: {
    included?: string[];
    excluded?: string[];
  };
  region_postal_code_config?: {
    country?: string;
    postal_codes?: string[];
    excluded_postal_codes?: string[];
  };
  created_at: string;
  status?: StateEnum;
  state?: StateEnum;
  carrier_products: CarrierProductDTO[];
  warehouse_id: string;
  features?: {
    show_high_demand_affects_delivery_disclaimer_message: boolean;
  };
}

const isNotEmptyOrNil = (value: any) => isNotNil(value) && isNotEmpty(value);

export class ConfigRegionModel {
  id: string;
  name: string;
  regionType: RegionType;
  regionCountryConfig?: {
    included?: string[];
    excluded?: string[];
  };
  regionPostalCodeConfig?: {
    country?: string;
    postalCodes?: string[];
    excludedPostalCodes?: string[];
  };
  state?: StateEnum;
  createdAt: string;
  carrierProducts: CarrierProductModel[];
  warehouseId: string;
  features?: {
    showHighDemandAffectsDeliveryDisclaimerMessage?: boolean;
  };

  static createFromDTO(dto: ConfigRegionDTO): ConfigRegionModel {
    return {
      id: dto.id,
      name: dto.name,
      regionType: dto.region_type,
      regionCountryConfig: dto.region_country_config,
      regionPostalCodeConfig: isNotEmptyOrNil(dto.region_postal_code_config)
        ? {
            country: dto.region_postal_code_config!.country,
            postalCodes: dto.region_postal_code_config!.postal_codes,
            excludedPostalCodes: dto.region_postal_code_config!.excluded_postal_codes,
          }
        : undefined,
      state: isUndefined(dto.state) ? dto.status : dto.state,
      createdAt: dto.created_at,
      carrierProducts: dto.carrier_products
        ? dto.carrier_products.map(carrier_product =>
            CarrierProductModel.createFromDTO(carrier_product)
          )
        : [],
      warehouseId: dto.warehouse_id,
      features: isNotEmptyOrNil(dto.features)
        ? {
            showHighDemandAffectsDeliveryDisclaimerMessage:
              dto.features?.show_high_demand_affects_delivery_disclaimer_message,
          }
        : undefined,
    };
  }
}

export type RegionType = 'country' | 'zipcode';

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export class ConfigMerchantSummaryModel {
  id: string;
  name: string;
  sites: ConfigSiteSummaryModel[];

  static createFromDTO(dto: ConfigMerchantSummaryDTO) {
    return {
      id: dto.id,
      name: dto.name,
      sites: dto.sites ? dto.sites.map(site => ConfigSiteSummaryModel.createFromDTO(site)) : [],
    };
  }
}

export class ConfigMerchantSummaryDTO {
  id: string;
  name: string;
  sites?: ConfigSiteSummaryDTO[];
}

export class ConfigSiteSummaryModel {
  id: string;
  name: string;
  isDraft?: boolean;
  version: number;
  masterSiteId?: string;

  static createFromDTO(dto: ConfigSiteSummaryDTO): ConfigSiteSummaryModel {
    return {
      id: dto.id,
      name: dto.name,
      isDraft: dto.is_draft,
      version: dto.version,
      masterSiteId: dto.master_site_id,
    };
  }
}

export class ConfigSiteSummaryDTO {
  id: string;
  name: string;
  is_draft?: boolean;
  version: number;
  master_site_id?: string;
}

export class ConfigMerchantModel {
  id: string;
  name: string;
  sites: ConfigSiteModel[];

  static createFromDTO(dto: ConfigMerchantDTO) {
    return {
      id: dto.id,
      name: dto.name,
      sites: dto.sites ? dto.sites.map(site => ConfigSiteModel.createFromDTO(site)) : [],
    };
  }
}

export class ConfigMerchantDTO {
  id: string;
  name: string;
  sites?: ConfigSiteDTO[];
}

export class ConfigShippingMethodModel {
  id: string;
  name?: string;
  carrier?: string;
  deliveryTypes: DeliveryTypeEnum[] = [];
  parcelType: ParcelTypeEnum;

  static createFromDTO(dto: ConfigShippingMethodDTO): ConfigShippingMethodModel {
    return {
      id: dto.id,
      name: dto.name,
      carrier: dto.carrier,
      deliveryTypes: dto.delivery_types.map(type => createDeliveryTypeEnum(type)),
      parcelType: ParcelTypeEnum[dto.parcel_type as ParcelTypeEnum],
    };
  }
}

export class ConfigShippingMethodDTO {
  id: string;
  name?: string;
  carrier?: string;
  delivery_types: DeliveryTypeEnum[];
  parcel_type: ParcelTypeEnum;
}

export enum Days {
  UNKNOWN_DAY = 'UNKNOWN_DAY',
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

class CommandDTO {
  update_category_country_settings_request?: UpdatePreselectionOrderDTO;
  update_category_details?: UpdateCategoryDetailsDTO;
  update_category_delivery_time?: UpdateDeliveryTimeDTO;
  update_region_details?: UpdateConfigRegionDTO;
  update_region_product?: UpdateCarrierProductDTO;
  deactivate_shipping_method?: DeactivateShippingMethodDTO;
  update_category_translations?: UpdateCategoryTranslationsDTO;
  update_warehouse_details?: UpdateWarehouseDetailsDTO;
  update_warehouse_cutoff_times?: UpdateWarehouseCutoffTimesDTO;
  add_category_tag_request?: AddCategoryTagsDTO;
  remove_category_tag_request?: RemoveCategoryTagsDTO;
  delete_category_translations?: DeleteTranslationDTO;
  create_category_label_request?: CreateCategoryLabelDTO;
  delete_category_label_request?: DeleteCategoryLabelDTO;
  create_category_request?: CreateCategoryDTO;
  delete_category_request?: { category_id: string };
  create_region_request?: CreateRegion['dto'];
  delete_region_request?: { region_id: string };
  create_region_product_request?: CreateCarrierProductDTO;
  delete_region_product_request?: DeleteCarrierProductDTO;
  create_warehouse_request?: CreateWarehouseDTO;
  delete_warehouse_request?: { id: string };
  create_warehouse_cutoff_times_request?: CreateWarehouseCutoffTimesDTO;
  update_widget_configuration_request?: { widget_configuration: WidgetConfigurationDTO };
  set_warehouse_shipping_date_adjustment_request?: SetWarehouseShippingDateAdjustmentDTO;
  delete_warehouse_shipping_date_adjustment_request?: DeleteWarehouseShippingDateAdjustmentDTO;
  create_widget_country_configuration_request?: UpdateWidgetCountryConfigurationDTO;
  update_widget_country_configuration_request?: UpdateWidgetCountryConfigurationDTO;
  update_category_label_request?: UpdateCategoryLabelDetailsDTO;
  upsert_category_label_translation_request?: UpdateCategoryLabelTranslationsDTO;
  delete_category_label_translation_request?: DeleteCategoryLabelTranslationsDTO;
  adjust_category_labels_order_request?: UpdateCategoryLabelsOrderDTO;
  delete_warehouse_cutoff_times_request?: DeleteWarehouseCutoffTimesDTO;
  create_delivery_addon_request?: CreateDeliveryAddonDTO;
  update_delivery_addon_request?: UpdateDeliveryAddonDTO;
  delete_delivery_addon_request?: DeleteDeliveryAddonDTO;
  adjust_delivery_addons_order_request?: AdjustDeliveryAddonsOrderDTO;
  upsert_delivery_addon_translation_request?: UpsertDeliveryAddonTranslationDTO;
  delete_delivery_addon_translation_request?: DeleteDeliveryAddonTranslationDTO;
  upsert_custom_event_type_request?: UpsertCustomEventDTO;
  delete_custom_event_type_request?: { id: string };
  upsert_custom_event_type_translation_request?: UpsertCustomEventTranslationDTO;
  delete_custom_event_type_translation_request?: DeleteCustomEventTranslationDTO;
  configure_internal_event_type_request?: UpdateInternalEventDTO;
  delete_carrier_product_mapping_request?: DeleteCarrierProductMappingRequest;
  create_carrier_product_mapping_request?: CreateCarrierProductMappingRequest;
  update_carrier_product_mapping_request?: UpdateCarrierProductMappingRequest;
  update_delivery_upsell_widget_configuration_request: any;
  create_delivery_upsell_widget_country_configuration_request: any;
  update_delivery_upsell_widget_country_configuration_request: any;
  update_faq_widget_default_configuration_request?: FaqWidgetConfiguration;
  upsert_faq_widget_country_configuration_request?: {
    country: string;
  } & FaqWidgetConfiguration;
  update_product_page_widget_default_configuration_request?: ProductPageWidgetConfiguration;
  upsert_product_page_widget_country_configuration_request?: {
    country: string;
  } & ProductPageWidgetConfiguration;
  upsert_carrier_product_settings_request?: UpsertCarrierProductSettingsRequest;
  update_receipt_widget_default_configuration_request: { features: {} };
  upsert_receipt_widget_country_configuration_request: { country: string; features: {} };
}

export class CommandModel {
  updateCountrySettingsRequest?: UpdatePreselectionOrderModel;
  updateCategoryDetails?: UpdateCategoryDetailsModel;
  updateCategoryDeliveryTime?: UpdateDeliveryTimeModel;
  updateRegionDetails?: UpdateConfigRegionModel;
  updateRegionProduct?: UpdateCarrierProductModel;
  deactivateShippingMethod?: DeactivateShippingMethodModel;
  updateCategoryTranslations?: UpdateCategoryTranslationsModel;
  updateWarehouseDetails?: UpdateWarehouseDetailsModel;
  updateWarehouseCutoffTimes?: UpdateWarehouseCutoffTimesModel;
  deleteCategoryTranslations?: DeleteTranslationModel;
  addCategoryTagsRequest?: AddCategoryTagsModel;
  createCategoryLabelRequest?: CreateCategoryLabelModel;
  deleteCategoryLabelRequest?: DeleteCategoryLabelModel;
  removeCategoryTagsRequest?: RemoveCategoryTagsModel;
  createCategoryRequest?: CreateCategoryModel;
  deleteCategoryRequest?: { categoryId: string };
  createRegionRequest?: CreateRegion['model'];
  deleteRegionRequest?: { regionId: string };
  createRegionProductRequest?: CreateCarrierProductModel;
  deleteRegionProductRequest?: DeleteCarrierProductModel;
  createWarehouseRequest?: CreateWarehouseModel;
  deleteWarehouseRequest?: { id: string };
  createWarehouseCutoffTimesRequest?: CreateWarehouseCutoffTimesModel;
  updateWidgetConfigurationRequest?: { widgetConfiguration: WidgetConfigurationModel };
  setWarehouseShippingDateAdjustmentRequest?: SetWarehouseShippingDateAdjustmentModel;
  deleteWarehouseShippingDateAdjustmentRequest?: DeleteWarehouseShippingDateAdjustmentModel;
  createWidgetCountryConfigurationRequest?: UpdateWidgetCountryConfigurationModel;
  updateWidgetCountryConfigurationRequest?: UpdateWidgetCountryConfigurationModel;
  updateCategoryLabelRequest?: UpdateCategoryLabelDetailsModel;
  upsertCategoryLabelTranslationRequest?: UpdateCategoryLabelTranslationsModel;
  deleteCategoryLabelTranslationRequest?: DeleteCategoryLabelTranslationsModel;
  adjustCategoryLabelsOrderRequest?: UpdateCategoryLabelsOrderModel;
  deleteWarehouseCutoffTimesRequest?: DeleteWarehouseCutoffTimesModel;
  createDeliveryAddonRequest?: CreateDeliveryAddonModel;
  updateDeliveryAddonRequest?: UpdateDeliveryAddonModel;
  deleteDeliveryAddonRequest?: DeleteDeliveryAddonModel;
  adjustDeliveryAddonsOrderRequest?: AdjustDeliveryAddonsOrderModel;
  upsertDeliveryAddonTranslationRequest?: UpsertDeliveryAddonTranslationModel;
  deleteDeliveryAddonTranslationRequest?: DeleteDeliveryAddonTranslationModel;
  upsertCustomEventTypeRequest?: UpsertCustomEventModel;
  deleteCustomEventTypeRequest?: { id: string };
  upsertCustomEventTypeTranslationRequest?: UpsertCustomEventTranslationModel;
  deleteCustomEventTypeTranslationRequest?: DeleteCustomEventTranslationModel;
  configureInternalEventTypeRequest?: UpdateInternalEventModel;
  deleteCarrierProductMappingRequest?: DeleteCarrierProductMappingRequest;
  createCarrierProductMappingRequest?: CreateCarrierProductMappingRequest;
  updateCarrierProductMappingRequest?: UpdateCarrierProductMappingRequest;
  updateDeliveryUpsellWidgetConfigurationRequest?: any;
  createDeliveryUpsellWidgetCountryConfigurationRequest?: any;
  updateDeliveryUpsellWidgetCountryConfigurationRequest?: any;
  updateFaqWidgetDefaultConfigurationRequest?: FaqWidgetConfiguration;
  upsertFaqWidgetCountryConfigurationRequest?: {
    country: string;
  } & FaqWidgetConfiguration;
  updateProductPageWidgetDefaultConfigurationRequest?: ProductPageWidgetConfiguration;
  upsertProductPageWidgetCountryConfigurationRequest?: {
    country: string;
  } & ProductPageWidgetConfiguration;
  upsertCarrierProductSettingsRequest?: UpsertCarrierProductSettingsRequest;
  updateReceiptWidgetDefaultConfigurationRequest?: { features: {} };
  upsertReceiptWidgetCountryConfigurationRequest?: { country: string; features: {} };

  static createFromDTO(dto: CommandDTO): CommandModel {
    return Object.assign(
      {},
      dto.update_category_country_settings_request && {
        updateCountrySettingsRequest: UpdatePreselectionOrderModel.createFromDTO(
          dto.update_category_country_settings_request
        ),
      },
      dto.delete_category_label_request && {
        deleteCategoryLabelRequest: DeleteCategoryLabelModel.createFromDTO(
          dto.delete_category_label_request
        ),
      },
      dto.create_category_label_request && {
        createCategoryLabelRequest: CreateCategoryLabelModel.createFromDTO(
          dto.create_category_label_request
        ),
      },
      dto.update_category_details && {
        updateCategoryDetails: UpdateCategoryDetailsModel.createFromDTO(
          dto.update_category_details
        ),
      },
      dto.add_category_tag_request && {
        addCategoryTagsRequest: AddCategoryTagsModel.createFromDTO(dto.add_category_tag_request),
      },
      dto.remove_category_tag_request && {
        removeCategoryTagsRequest: RemoveCategoryTagsModel.createFromDTO(
          dto.remove_category_tag_request
        ),
      },
      dto.update_category_delivery_time && {
        updateCategoryDeliveryTime: UpdateDeliveryTimeModel.createFromDTO(
          dto.update_category_delivery_time
        ),
      },
      dto.update_region_details && {
        updateRegionDetails: UpdateConfigRegionModel.createFromDTO(dto.update_region_details),
      },
      dto.update_region_product && {
        updateRegionProduct: UpdateCarrierProductModel.createFromDTO(dto.update_region_product),
      },
      dto.update_category_translations && {
        updateCategoryTranslations: UpdateCategoryTranslationsModel.createFromDTO(
          dto.update_category_translations
        ),
      },
      dto.update_warehouse_details && {
        updateWarehouseDetails: UpdateWarehouseDetailsModel.createFromDTO(
          dto.update_warehouse_details
        ),
      },
      dto.update_warehouse_cutoff_times && {
        updateWarehouseCutoffTimes: UpdateWarehouseCutoffTimesModel.createFromDTO(
          dto.update_warehouse_cutoff_times
        ),
      },
      dto.delete_category_translations && {
        deleteCategoryTranslations: DeleteTranslationModel.createFromDTO(
          dto.delete_category_translations
        ),
      },
      dto.create_category_request && {
        createCategoryRequest: CreateCategoryModel.createFromDTO(dto.create_category_request),
      },
      dto.delete_category_request && {
        deleteCategoryRequest: { categoryId: dto.delete_category_request.category_id },
      },
      dto.create_region_request && {
        createRegionRequest: CreateRegion.createFromDTO(dto.create_region_request),
      },
      dto.delete_region_request && {
        deleteRegionRequest: { regionId: dto.delete_region_request.region_id },
      },
      dto.create_region_product_request && {
        createRegionProductRequest: CreateCarrierProductModel.createFromDTO(
          dto.create_region_product_request
        ),
      },
      dto.delete_region_product_request && {
        deleteRegionProductRequest: DeleteCarrierProductModel.createFromDTO(
          dto.delete_region_product_request
        ),
      },
      dto.create_warehouse_request && {
        createWarehouseRequest: CreateWarehouseModel.createFromDTO(dto.create_warehouse_request),
      },
      dto.delete_warehouse_request && {
        deleteWarehouseRequest: { id: dto.delete_warehouse_request.id },
      },
      dto.create_warehouse_cutoff_times_request && {
        createWarehouseCutoffTimesRequest: CreateWarehouseCutoffTimesModel.createFromDTO(
          dto.create_warehouse_cutoff_times_request
        ),
      },
      dto.update_widget_configuration_request && {
        updateWidgetConfigurationRequest: {
          widgetConfiguration: WidgetConfigurationModel.createFromDTO(
            dto.update_widget_configuration_request.widget_configuration
          ),
        },
      },
      dto.set_warehouse_shipping_date_adjustment_request && {
        setWarehouseShippingDateAdjustmentRequest:
          SetWarehouseShippingDateAdjustmentModel.createFromDTO(
            dto.set_warehouse_shipping_date_adjustment_request
          ),
      },
      dto.delete_warehouse_shipping_date_adjustment_request && {
        deleteWarehouseShippingDateAdjustmentRequest:
          DeleteWarehouseShippingDateAdjustmentModel.createFromDTO(
            dto.delete_warehouse_shipping_date_adjustment_request
          ),
      },
      dto.create_widget_country_configuration_request && {
        createWidgetCountryConfigurationRequest:
          UpdateWidgetCountryConfigurationModel.createFromDTO(
            dto.create_widget_country_configuration_request
          ),
      },
      dto.update_widget_country_configuration_request && {
        updateWidgetCountryConfigurationRequest:
          UpdateWidgetCountryConfigurationModel.createFromDTO(
            dto.update_widget_country_configuration_request
          ),
      },
      dto.update_category_label_request && {
        updateCategoryLabelRequest: UpdateCategoryLabelDetailsModel.createFromDTO(
          dto.update_category_label_request
        ),
      },
      dto.upsert_category_label_translation_request && {
        upsertCategoryLabelTranslationRequest: UpdateCategoryLabelTranslationsModel.createFromDTO(
          dto.upsert_category_label_translation_request
        ),
      },
      dto.delete_category_label_translation_request && {
        deleteCategoryLabelTranslationRequest: DeleteCategoryLabelTranslationsModel.createFromDTO(
          dto.delete_category_label_translation_request
        ),
      },
      dto.adjust_category_labels_order_request && {
        adjustCategoryLabelsOrderRequest: UpdateCategoryLabelsOrderModel.createFromDTO(
          dto.adjust_category_labels_order_request
        ),
      },
      dto.delete_warehouse_cutoff_times_request && {
        deleteWarehouseCutoffTimesRequest: DeleteWarehouseCutoffTimesModel.createFromDTO(
          dto.delete_warehouse_cutoff_times_request
        ),
      },
      dto.create_delivery_addon_request && {
        createDeliveryAddonRequest: CreateDeliveryAddonModel.createFromDTO(
          dto.create_delivery_addon_request
        ),
      },
      dto.update_delivery_addon_request && {
        updateDeliveryAddonRequest: UpdateDeliveryAddonModel.createFromDTO(
          dto.update_delivery_addon_request
        ),
      },
      dto.delete_delivery_addon_request && {
        deleteDeliveryAddonRequest: DeleteDeliveryAddonModel.createFromDTO(
          dto.delete_delivery_addon_request
        ),
      },
      dto.adjust_delivery_addons_order_request && {
        adjustDeliveryAddonsOrderRequest: AdjustDeliveryAddonsOrderModel.createFromDTO(
          dto.adjust_delivery_addons_order_request
        ),
      },
      dto.upsert_delivery_addon_translation_request && {
        upsertDeliveryAddonTranslationRequest: UpsertDeliveryAddonTranslationModel.createFromDTO(
          dto.upsert_delivery_addon_translation_request
        ),
      },
      dto.delete_delivery_addon_translation_request && {
        deleteDeliveryAddonTranslationRequest: DeleteDeliveryAddonTranslationModel.createFromDTO(
          dto.delete_delivery_addon_translation_request
        ),
      },
      dto.upsert_custom_event_type_request && {
        upsertCustomEventTypeRequest: UpsertCustomEventModel.createFromDTO(
          dto.upsert_custom_event_type_request
        ),
      },
      dto.delete_custom_event_type_request && {
        deleteCustomEventTypeRequest: { id: dto.delete_custom_event_type_request },
      },
      dto.upsert_custom_event_type_translation_request && {
        upsertCustomEventTypeTranslationRequest: UpsertCustomEventTranslationModel.createFromDTO(
          dto.upsert_custom_event_type_translation_request
        ),
      },
      dto.delete_custom_event_type_translation_request && {
        deleteCustomEventTypeTranslationRequest: DeleteCustomEventTranslationModel.createFromDTO(
          dto.delete_custom_event_type_translation_request
        ),
      },
      dto.configure_internal_event_type_request && {
        configureInternalEventTypeRequest: UpdateInternalEventModel.createFromDTO(
          dto.configure_internal_event_type_request
        ),
      },
      dto.delete_carrier_product_mapping_request && {
        deleteCarrierProductMappingRequest: clone(dto.delete_carrier_product_mapping_request),
      },
      dto.create_carrier_product_mapping_request && {
        createCarrierProductMappingRequest: clone(dto.create_carrier_product_mapping_request),
      },
      dto.update_carrier_product_mapping_request && {
        updateCarrierProductMappingRequest: clone(dto.update_carrier_product_mapping_request),
      },
      dto.update_delivery_upsell_widget_configuration_request && {
        updateDeliveryUpsellWidgetConfigurationRequest: clone(
          dto.update_delivery_upsell_widget_configuration_request
        ),
      },
      dto.update_delivery_upsell_widget_country_configuration_request && {
        updateDeliveryUpsellWidgetCountryConfigurationRequest: clone(
          dto.update_delivery_upsell_widget_country_configuration_request
        ),
      },
      dto.create_delivery_upsell_widget_country_configuration_request && {
        createDeliveryUpsellWidgetCountryConfigurationRequest: clone(
          dto.create_delivery_upsell_widget_country_configuration_request
        ),
      },
      dto.update_faq_widget_default_configuration_request && {
        updateFaqWidgetDefaultConfigurationRequest: clone(
          dto.update_faq_widget_default_configuration_request
        ),
      },
      dto.upsert_faq_widget_country_configuration_request && {
        upsertFaqWidgetCountryConfigurationRequest: clone(
          dto.upsert_faq_widget_country_configuration_request
        ),
      },
      dto.update_product_page_widget_default_configuration_request && {
        updateProductPageWidgetDefaultConfigurationRequest: clone(
          dto.update_product_page_widget_default_configuration_request
        ),
      },
      dto.upsert_product_page_widget_country_configuration_request && {
        upsertProductPageWidgetCountryConfigurationRequest: clone(
          dto.upsert_product_page_widget_country_configuration_request
        ),
      },
      dto.upsert_carrier_product_settings_request && {
        upsertCarrierProductSettingsRequest: clone(dto.upsert_carrier_product_settings_request),
      },
      dto.update_receipt_widget_default_configuration_request && {
        updateReceiptWidgetDefaultConfigurationRequest: clone(
          dto.update_receipt_widget_default_configuration_request
        ),
      },
      dto.upsert_receipt_widget_country_configuration_request && {
        upsertReceiptWidgetCountryConfigurationRequest: clone(
          dto.upsert_receipt_widget_country_configuration_request
        ),
      }
    );
  }
}

export class ConfigChangeDTO {
  user_id: string;
  updated_at: string;
  command: CommandDTO;
}

export class ConfigChangeModel {
  userId: string;
  email?: string;
  updatedAt: string;
  command: CommandModel;

  static createFromDTO(dto: ConfigChangeDTO): ConfigChangeModel {
    return {
      userId: dto.user_id,
      updatedAt: dto.updated_at,
      command: CommandModel.createFromDTO(dto.command),
    };
  }
}

export class ShippingDateAdjustmentDTO {
  adjust_start?: number;
  adjust_end?: number;
  unit: DeliveryTimeUnit;

  static createFromModel(model: ShippingDateAdjustmentModel): ShippingDateAdjustmentDTO {
    return {
      adjust_start: model.adjustStart,
      adjust_end: model.adjustEnd,
      unit: model.unit,
    };
  }
}

export class ShippingDateAdjustmentModel {
  adjustStart?: number;
  adjustEnd?: number;
  unit: DeliveryTimeUnit;

  static createFromDTO(dto: ShippingDateAdjustmentDTO): ShippingDateAdjustmentModel {
    return {
      adjustStart: dto.adjust_start,
      adjustEnd: dto.adjust_end,
      unit: dto.unit,
    };
  }
}

export class WidgetFeaturesDTO {
  /* Deprecated */
  hide_toc?: boolean;
  show_shipping_price_on_service_points_view?: boolean;
  /* Deprecated */
  show_carrier_icons?: boolean;
  /* Deprecated */
  new_search_address?: boolean;
  /* Deprecated */
  door_code?: boolean;
  show_map?: boolean;
  /* Deprecated */
  hide_street_on_address_fields?: boolean;
  disable_address_form?: boolean;
  /* Deprecated */
  show_city_on_address_fields?: boolean;
  display_upfront_address?: boolean;
  autocomplete_street?: boolean;
  display_location_type?: boolean;
  /* Deprecated */
  dont_require_street_on_address_fields?: boolean;
  show_state_on_address_fields?: boolean;
  show_delivered_by?: boolean;
  show_street_on_address_module?: boolean;
  require_street_on_address_module?: boolean;
  show_city_on_address_module?: boolean;
  show_region?: boolean;
  require_region?: boolean;
  enable_ingrid_logo?: boolean;
  enable_widget_border?: boolean;
  enable_transparent_background?: boolean;
  show_shipping_categories_before_address?: boolean;
  enable_accent_color_for_widget_elements?: boolean;
  dark_color_enabled?: boolean;
  show_slogan?: boolean;
  show_carrier_logos_in_shipping_categories_placeholder?: boolean;
  enable_free_shipping_indicator?: boolean;
  enable_free_shipping_indicator_carrier_logo?: boolean;
  enable_free_shipping_indicator_category_name_message?: boolean;
  shipping_categories_visible_when_folded_count?: number;
  display_currency_iso_code?: boolean;
  enable_price_zero_value?: boolean;
  show_base_price?: boolean;
  enable_short_delivery_times?: boolean;
  show_delivery_times_as_estimates?: boolean;

  static createFromModel(model: WidgetFeaturesModel): WidgetFeaturesDTO {
    return {
      hide_toc: model.hideToc,
      show_shipping_price_on_service_points_view: model.showShippingPriceOnServicePointsView,
      show_carrier_icons: model.showCarrierIcons,
      new_search_address: model.newSearchAddress,
      door_code: model.doorCode,
      show_map: model.showMap,
      hide_street_on_address_fields: model.hideStreetOnAddressFields,
      disable_address_form: model.disableAddressForm,
      show_city_on_address_fields: model.showCityOnAddressFields,
      display_upfront_address: model.displayUpfrontAddress,
      autocomplete_street: model.autocompleteStreet,
      display_location_type: model.displayLocationType,
      dont_require_street_on_address_fields: model.dontRequireStreetOnAddressFields,
      show_state_on_address_fields: model.showStateOnAddressFields,
      show_delivered_by: model.showDeliveredBy,
      show_street_on_address_module: model.showStreetOnAddressModule,
      require_street_on_address_module: model.requireStreetOnAddressModule,
      show_city_on_address_module: model.showCityOnAddressModule,
      show_region: model.showRegion,
      require_region: model.requireRegion,
      enable_ingrid_logo: model.enableIngridLogo,
      enable_widget_border: model.enableWidgetBorder,
      enable_transparent_background: model.enableTransparentBackground,
      show_shipping_categories_before_address: model.showShippingCategoriesBeforeAddress,
      enable_accent_color_for_widget_elements: model.enableAccentColorForWidgetElements,
      dark_color_enabled: model.darkColorEnabled,
      show_slogan: model.showSlogan,
      show_carrier_logos_in_shipping_categories_placeholder:
        model.showCarrierLogosInShippingCategoriesPlaceholder,
      enable_free_shipping_indicator: model.enableFreeShippingIndicator,
      enable_free_shipping_indicator_carrier_logo: model.enableFreeShippingIndicatorCarrierLogo,
      enable_free_shipping_indicator_category_name_message:
        model.enableFreeShippingIndicatorCategoryNameMessage,
      shipping_categories_visible_when_folded_count: model.shippingCategoriesVisibleWhenFoldedCount,
      display_currency_iso_code: model.displayCurrencyIsoCode,
      enable_price_zero_value: model.enablePriceZeroValue,
      show_base_price: model.showBasePrice,
      enable_short_delivery_times: model.enableShortDeliveryTimes,
      show_delivery_times_as_estimates: model.showDeliveryTimesAsEstimates,
    };
  }
}

export class WidgetFeaturesModel {
  /* Deprecated */
  hideToc?: boolean;
  showShippingPriceOnServicePointsView?: boolean;
  /* Deprecated */
  showCarrierIcons?: boolean;
  /* Deprecated */
  newSearchAddress?: boolean;
  /* Deprecated */
  doorCode?: boolean;
  showMap?: boolean;
  /* Deprecated */
  hideStreetOnAddressFields?: boolean;
  disableAddressForm?: boolean;
  /* Deprecated */
  showCityOnAddressFields?: boolean;
  displayUpfrontAddress?: boolean;
  autocompleteStreet?: boolean;
  displayLocationType?: boolean;
  /* Deprecated */
  dontRequireStreetOnAddressFields?: boolean;
  showStateOnAddressFields?: boolean;
  showDeliveredBy?: boolean;
  showStreetOnAddressModule?: boolean;
  requireStreetOnAddressModule?: boolean;
  showCityOnAddressModule?: boolean;
  showRegion?: boolean;
  requireRegion?: boolean;
  enableIngridLogo?: boolean;
  enableWidgetBorder?: boolean;
  enableTransparentBackground?: boolean;
  showShippingCategoriesBeforeAddress?: boolean;
  enableAccentColorForWidgetElements?: boolean;
  darkColorEnabled?: boolean;
  showSlogan?: boolean;
  showCarrierLogosInShippingCategoriesPlaceholder?: boolean;
  enableFreeShippingIndicator?: boolean;
  enableFreeShippingIndicatorCarrierLogo?: boolean;
  enableFreeShippingIndicatorCategoryNameMessage?: boolean;
  shippingCategoriesVisibleWhenFoldedCount?: number;
  displayCurrencyIsoCode?: boolean;
  enablePriceZeroValue?: boolean;
  showBasePrice?: boolean;
  enableShortDeliveryTimes?: boolean;
  showDeliveryTimesAsEstimates?: boolean;

  static createFromDTO(dto: WidgetFeaturesDTO): WidgetFeaturesModel {
    return {
      hideToc: dto.hide_toc,
      showShippingPriceOnServicePointsView: dto.show_shipping_price_on_service_points_view,
      showCarrierIcons: dto.show_carrier_icons,
      newSearchAddress: dto.new_search_address,
      doorCode: dto.door_code,
      showMap: dto.show_map,
      hideStreetOnAddressFields: dto.hide_street_on_address_fields,
      disableAddressForm: dto.disable_address_form,
      showCityOnAddressFields: dto.show_city_on_address_fields,
      displayUpfrontAddress: dto.display_upfront_address,
      autocompleteStreet: dto.autocomplete_street,
      displayLocationType: dto.display_location_type,
      dontRequireStreetOnAddressFields: dto.dont_require_street_on_address_fields,
      showStateOnAddressFields: dto.show_state_on_address_fields,
      showDeliveredBy: dto.show_delivered_by,
      showStreetOnAddressModule: dto.show_street_on_address_module,
      requireStreetOnAddressModule: dto.require_street_on_address_module,
      showCityOnAddressModule: dto.show_city_on_address_module,
      showRegion: dto.show_region,
      requireRegion: dto.require_region,
      enableIngridLogo: dto.enable_ingrid_logo,
      enableWidgetBorder: dto.enable_widget_border,
      enableTransparentBackground: dto.enable_transparent_background,
      showShippingCategoriesBeforeAddress: dto.show_shipping_categories_before_address,
      enableAccentColorForWidgetElements: dto.enable_accent_color_for_widget_elements,
      darkColorEnabled: dto.dark_color_enabled,
      showSlogan: dto.show_slogan,
      showCarrierLogosInShippingCategoriesPlaceholder:
        dto.show_carrier_logos_in_shipping_categories_placeholder,
      enableFreeShippingIndicator: dto.enable_free_shipping_indicator,
      enableFreeShippingIndicatorCarrierLogo: dto.enable_free_shipping_indicator_carrier_logo,
      enableFreeShippingIndicatorCategoryNameMessage:
        dto.enable_free_shipping_indicator_category_name_message,
      shippingCategoriesVisibleWhenFoldedCount: dto.shipping_categories_visible_when_folded_count,
      displayCurrencyIsoCode: dto.display_currency_iso_code,
      enablePriceZeroValue: dto.enable_price_zero_value,
      showBasePrice: dto.show_base_price,
      enableShortDeliveryTimes: dto.enable_short_delivery_times,
      showDeliveryTimesAsEstimates: dto.show_delivery_times_as_estimates,
    };
  }
}

export class WidgetStyleDTO {
  accent_color_1?: string;
  accent_color_2?: string;
  upsell_color?: string;

  static createFromModel(model: WidgetStyleModel): WidgetStyleDTO {
    return {
      accent_color_1: model.accentColor1,
      accent_color_2: model.accentColor2,
      upsell_color: model.upsellColor,
    };
  }
}

export class WidgetStyleModel {
  accentColor1?: string;
  accentColor2?: string;
  upsellColor?: string;

  static createFromDTO(dto: WidgetStyleDTO): WidgetStyleModel {
    return {
      accentColor1: dto.accent_color_1,
      accentColor2: dto.accent_color_2,
      upsellColor: dto.upsell_color,
    };
  }
}

export class WidgetCountryConfigurationDTO {
  features: WidgetFeaturesDTO;
  carrier_logos: { methods: string[] };

  static createFromModel(model: WidgetCountryConfigurationModel): WidgetCountryConfigurationDTO {
    return {
      features: WidgetFeaturesDTO.createFromModel(model.features),
      carrier_logos: model.carrierLogos,
    };
  }
}

export class WidgetCountryConfigurationModel {
  features: WidgetFeaturesModel;
  carrierLogos: { methods: string[] };

  static createFromDTO(dto: WidgetCountryConfigurationDTO): WidgetCountryConfigurationModel {
    return {
      features: WidgetFeaturesModel.createFromDTO(dto.features),
      carrierLogos: dto.carrier_logos,
    };
  }
}

export class WidgetConfigurationModel {
  features?: WidgetFeaturesModel;
  style?: WidgetStyleModel;
  byCountry?: { [countryCode: string]: WidgetCountryConfigurationModel };
  releaseLabel: 'facelift' | 'v4' | 'botox';

  static createFromDTO(dto: WidgetConfigurationDTO): WidgetConfigurationModel {
    return {
      features: dto.features && WidgetFeaturesModel.createFromDTO(dto.features),
      style: dto.style && WidgetStyleModel.createFromDTO(dto.style),
      byCountry:
        dto.by_country &&
        mapObjIndexed(
          country_config => WidgetCountryConfigurationModel.createFromDTO(country_config),
          dto.by_country
        ),
      releaseLabel: dto.release_label,
    };
  }
}

export class WidgetConfigurationDTO {
  features?: WidgetFeaturesDTO;
  style?: WidgetStyleDTO;
  by_country?: { [country_code: string]: WidgetCountryConfigurationDTO };
  release_label: 'facelift' | 'v4' | 'botox';

  static createFromModel(model: WidgetConfigurationModel): WidgetConfigurationDTO {
    return {
      features: model.features && WidgetFeaturesDTO.createFromModel(model.features),
      style: model.style && WidgetStyleDTO.createFromModel(model.style),
      by_country:
        model.byCountry &&
        mapObjIndexed(
          countryConfig => WidgetCountryConfigurationDTO.createFromModel(countryConfig),
          model.byCountry
        ),
      release_label: model.releaseLabel,
    };
  }
}

export class SetWarehouseShippingDateAdjustmentDTO {
  warehouse_id: string;
  shipping_date_adjustment: ShippingDateAdjustmentDTO;
}

export class SetWarehouseShippingDateAdjustmentModel {
  warehouseId: string;
  shippingDateAdjustment: ShippingDateAdjustmentModel;

  static createFromDTO(
    dto: SetWarehouseShippingDateAdjustmentDTO
  ): SetWarehouseShippingDateAdjustmentModel {
    return {
      warehouseId: dto.warehouse_id,
      shippingDateAdjustment:
        dto.shipping_date_adjustment &&
        ShippingDateAdjustmentModel.createFromDTO(dto.shipping_date_adjustment),
    };
  }
}

export class DeleteWarehouseShippingDateAdjustmentDTO {
  warehouse_id: string;
}

export class DeleteWarehouseShippingDateAdjustmentModel {
  warehouseId: string;

  static createFromDTO(
    dto: DeleteWarehouseShippingDateAdjustmentDTO
  ): DeleteWarehouseShippingDateAdjustmentModel {
    return {
      warehouseId: dto.warehouse_id,
    };
  }
}

export class GetPrivateKeyDTO {
  private_key: string;
}

export class GetPrivateKeyModel {
  privateKey: string;

  static createFromDTO(dto: GetPrivateKeyDTO): GetPrivateKeyModel {
    return {
      privateKey: dto.private_key,
    };
  }
}

export enum AvailableTrackingWidgetLanguage {
  'cs-CZ' = 'cs-CZ',
  'da-DK' = 'da-DK',
  'de-DE' = 'de-DE',
  'de-AT' = 'de-AT',
  'de-CH' = 'de-CH',
  'el-GR' = 'el-GR',
  'en-US' = 'en-US',
  'en-AU' = 'en-AU',
  'en-CA' = 'en-CA',
  'en-IE' = 'en-IE',
  'en-GB' = 'en-GB',
  'et-EE' = 'et-EE',
  'es-ES' = 'es-ES',
  'fr-BE' = 'fr-BE',
  'fr-FR' = 'fr-FR',
  'fi-SE' = 'fi-SE',
  'fi-FI' = 'fi-FI',
  'hr-HR' = 'hr-HR',
  'is-IS' = 'is-IS',
  'it-IT' = 'it-IT',
  'ja-JP' = 'ja-JP',
  'ko-KR' = 'ko-KR',
  'lt-LT' = 'lt-LT',
  'lv-LV' = 'lv-LV',
  'nb-NO' = 'nb-NO',
  'nl-NL' = 'nl-NL',
  'nl-BE' = 'nl-BE',
  'nn-NO' = 'nn-NO',
  'no-NO' = 'no-NO',
  'pl-PL' = 'pl-PL',
  'pt-PT' = 'pt-PT',
  'ru-RU' = 'ru-RU',
  'sk-SK' = 'sk-SK',
  'sl-SI' = 'sl-SI',
  'sv-SE' = 'sv-SE',
  'sv-FI' = 'sv-FI',
  'zh-CN' = 'zh-CN',
}

export class CustomShippingMethodDTO {
  shipping_method: string;
  delivery_type: DeliveryTypeEnum;
  product?: string;
  carrier_name?: string;

  static createFromModel(model: CustomShippingMethodModel): CustomShippingMethodDTO {
    return {
      shipping_method: model.shippingMethod,
      delivery_type: model.deliveryType,
      product: model.product,
      carrier_name: model.carrierName,
    };
  }
}

export class CustomShippingMethodModel {
  shippingMethod: string;
  deliveryType: DeliveryTypeEnum;
  product?: string;
  carrierName?: string;

  static createFromDTO(dto: CustomShippingMethodDTO): CustomShippingMethodModel {
    return {
      shippingMethod: dto.shipping_method,
      deliveryType: dto.delivery_type,
      product: dto.product,
      carrierName: dto.carrier_name,
    };
  }
}

export type BookingStep = {
  actions: Array<{ expression: string }>;
  filters?: Array<{ expression: string }>;
  name: string;
};

export class CustomBookingMethodDTO {
  method: string;
  booking_method?: string;
  steps?: BookingStep[];

  static createFromModel(model: CustomBookingMethodModel): CustomBookingMethodDTO {
    return {
      method: model.method,
      booking_method: model.bookingMethod,
      steps: model.steps,
    };
  }
}

export class CustomBookingMethodModel {
  method: string;
  bookingMethod?: string;
  steps?: BookingStep[];

  static createFromDTO(dto: CustomBookingMethodDTO): CustomBookingMethodModel {
    return {
      method: dto.method,
      bookingMethod: dto.booking_method,
      steps: dto.steps,
    };
  }
}

export class ServiceConfigurationDTO {
  configuration_type: string;
  configuration_data: string;

  static createFromModel(model: ServiceConfigurationModel): ServiceConfigurationDTO {
    return {
      configuration_type: model.configurationType,
      configuration_data: model.configurationData,
    };
  }
}

export class ServiceConfigurationModel {
  configurationType: string;
  configurationData: string;

  static createFromDTO(dto: ServiceConfigurationDTO): ServiceConfigurationModel {
    return {
      configurationType: dto.configuration_type,
      configurationData: dto.configuration_data,
    };
  }
}

export type ParsedCosSessionSettingsConfigurationData = {
  fail_on_no_options?: boolean;
  multi_session_settings?: {
    countries_with_recipient_address_form_enabled?: { [key: string]: boolean };
    enable_all_countries_recipient_address_form?: boolean;
  };
};

export type AddressFormCountryConfiguration = {
  enabled: boolean;
  features: string;
  layout: string;
  validation: string;
};

type FieldName =
  | 'FIRST_NAME'
  | 'LAST_NAME'
  | 'NAME'
  | 'POSTAL_CODE'
  | 'ADDRESS_LINE'
  | 'STREET'
  | 'STREET_NUMBER'
  | 'APARTMENT_NUMBER'
  | 'CITY'
  | 'EMAIL'
  | 'PHONE_NUMBER'
  | 'VAT';

type FieldLayoutEntry = {
  name: FieldName;
  span: number;
};

type AddressFormLayouts = { fields: FieldLayoutEntry[] };

type RequiredFieldValidation = {
  fields: FieldName[];
  min?: number;
};

type LengthFieldValidation = {
  fields: FieldName[];
  min?: number;
  max: number;
};

export type AddressFormValidations = {
  required: { [key: string]: RequiredFieldValidation };
  length: { [key: string]: LengthFieldValidation };
};

export class AddressFormConfigurationDTO {
  by_country: { [key: string]: AddressFormCountryConfiguration };
  features?: { [key: string]: { address_book_provider: string } };
  layouts?: { [key: string]: AddressFormLayouts };
  validations?: { [key: string]: AddressFormValidations };

  static createFromModel(model: AddressFormConfigurationModel): AddressFormConfigurationDTO {
    return {
      by_country: model.byCountry,
      features: model.features,
      layouts: model.layouts,
      validations: model.validations,
    };
  }
}

export class AddressFormConfigurationModel {
  byCountry: { [key: string]: AddressFormCountryConfiguration };
  features?: { [key: string]: { address_book_provider: string } };
  layouts?: { [key: string]: AddressFormLayouts };
  validations?: { [key: string]: AddressFormValidations };

  static createFromDTO(dto: AddressFormConfigurationDTO): AddressFormConfigurationModel {
    return {
      byCountry: dto.by_country,
      features: dto.features,
      layouts: dto.layouts,
      validations: dto.validations,
    };
  }
}
