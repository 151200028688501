import { ShopOutlined } from '@ant-design/icons';
import * as React from 'react';
import { connect } from 'react-redux';
import { stylesheet } from 'typestyle';
import { isNotEmpty } from 'ramda-adjunct';

import { Link } from '@src/components/link';
import { AddButton, ListFixedHeader, CheckoutWidgetDrawer, LinkStyleButton } from '@src/components';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { Modal } from '@src/controls';
import { DOMAIN } from '@src/dictionaries';
import { FormattedMessage } from '@src/i18n';
import { StateEnum } from '@src/models';
import { RootState } from '@src/modules';
import { configActions } from '@src/modules/config';
import { SitesSelectors } from '@src/modules/sites';
import { commons } from '@src/styles';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { Popover } from 'antd';
import { CreateWarehouseForm, CreateWarehouseFormValues, WarehousesList } from './components';
import { warehouseSelectors } from './selectors';
import { ConfigWarehouseModel } from '@src/models';
import { WarehouseContainer } from '.';

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  warehouses: warehouseSelectors.getWarehousesForCountry(state, props.countryToFilterWarehousesBy),
  warehousesRegionsMap: warehouseSelectors.getWarehousesRegionsMap(state),
  isFetching: state.sites.isFetching,
  draftSiteId: SitesSelectors.getDraftSiteIdOrEmpty(state),
  merchantId: state.merchants.selectedMerchantId,
  siteId: SitesSelectors.getSelectedSiteIdOrEmpty(state),
});

const mapDispatchToProps = () => ({
  deleteWarehouse: dispatchOnDraftSiteId(configActions.deleteWarehouseRequest),
  addWarehouse: dispatchOnDraftSiteId(configActions.createWarehouseRequest),
});

interface OwnProps {
  renderLink: (warehouse: ConfigWarehouseModel) => React.ReactNode;
  countryToFilterWarehousesBy?: string;
}

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Component: React.FunctionComponent<Props> = ({
  isFetching,
  warehouses,
  renderLink,
  deleteWarehouse,
  warehousesRegionsMap,
  addWarehouse,
}) => {
  const [createWarehousePopoverVisible, setCreateWarehousePopoverVisible] = React.useState(false);
  const [warehouseToDelete, setWarehouseToDelete] = React.useState('');
  const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);

  return (
    <>
      <WarehousesList
        isFetching={isFetching}
        warehouses={warehouses}
        renderLink={renderLink}
        onDeleteItem={(warehouseId: string) => {
          setDeleteModalVisible(true);
          setWarehouseToDelete(warehouseId);
        }}
        warehousesRegionsMap={warehousesRegionsMap}
      />
      <Modal
        visible={deleteModalVisible}
        onOk={() => {
          deleteWarehouse({ warehouseId: warehouseToDelete });
          setDeleteModalVisible(false);
        }}
        onCancel={() => setDeleteModalVisible(false)}
        title={<FormattedMessage id="DELETE_WAREHOUSE" />}
        width="600px"
        className={commons.modal}
      >
        <FormattedMessage id="WAREHOUSE_DELETE_CONFIRM_BODY" />
      </Modal>
      {isFetching ? null : (
        <Popover
          destroyTooltipOnHide
          trigger="click"
          title={<FormattedMessage id="ADD_NEW_WAREHOUSE" />}
          visible={createWarehousePopoverVisible}
          onVisibleChange={setCreateWarehousePopoverVisible}
          placement={isNotEmpty(warehouses) ? 'bottomLeft' : 'bottom'}
          content={
            <CreateWarehouseForm
              onSubmit={(values: CreateWarehouseFormValues) => {
                setCreateWarehousePopoverVisible(false);
                addWarehouse({
                  model: {
                    address: {
                      name: values.name,
                      city: values.city,
                      country: values.country,
                      postalCode: values.postalCode,
                      addressLines: [''],
                      region: '',
                      doorCode: '',
                    },
                    timezone: values.timezone,
                    state: StateEnum.INACTIVE,
                  },
                });
              }}
            />
          }
        >
          <AddButton
            text={<FormattedMessage id="ADD_NEW" />}
            className={
              isNotEmpty(warehouses)
                ? styles.addWarehouseButton
                : styles.addWarehouseButtonEmptyList
            }
          />
        </Popover>
      )}
    </>
  );
};

const styles = stylesheet({
  warehousesListWrapper: {
    padding: '24px',
  },
  addWarehouseButton: {
    marginTop: '20px',
    display: 'inline-block',
  },
  addWarehouseButtonEmptyList: {
    display: 'inline-block',
    textAlign: 'center',
    width: '100%',
  },
  warehouseName: {
    flexBasis: '30%',
  },
});

const WarehousesContainer = connect(mapStateToProps, mapDispatchToProps)(Component);

export const WarehouseListPage = () => {
  return (
    <>
      <StatusBarContainer />
      <div className={styles.warehousesListWrapper}>
        <ListFixedHeader
          title={DOMAIN.WAREHOUSES}
          IconComponent={ShopOutlined}
          showSearch={false}
        />
        <WarehousesContainer
          renderLink={(warehouse: ConfigWarehouseModel) => (
            <Link
              showIcon
              baseStyles
              className={styles.warehouseName}
              route={{ name: 'WAREHOUSE_DETAILS', warehouseId: warehouse.id }}
            >
              {warehouse.address.name}
            </Link>
          )}
        />
        <CheckoutWidgetDrawer />
      </div>
    </>
  );
};

export const WarehouseListEmbedded: React.FC<{ countryToFilterBy: string | undefined }> = ({
  countryToFilterBy,
}) => {
  const [warehouseId, setWarehouseId] = React.useState<string | undefined>();

  return (
    <>
      <WarehousesContainer
        countryToFilterWarehousesBy={countryToFilterBy}
        renderLink={(warehouse: ConfigWarehouseModel) => (
          <LinkStyleButton onClick={() => setWarehouseId(warehouse.id)}>
            {warehouse.address.name}
          </LinkStyleButton>
        )}
      />
      {warehouseId !== undefined && (
        <Modal
          visible
          onCancel={() => setWarehouseId(undefined)}
          footer={null}
          destroyOnClose={true}
          width="900px"
        >
          <WarehouseContainer warehouseId={warehouseId} />
        </Modal>
      )}
    </>
  );
};
