import { List } from 'antd';
import * as moment from 'moment';
import { isEmpty } from 'ramda';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { Link } from '@src/components/link';
import { LABELS, mapping } from '@src/dictionaries';
import { NotificationType, ShipmentNotificationModel } from '@src/models';
import { defaultTheme } from '@src/styles';
import { humanize } from '@src/utils/string';

const getMiddleText = (type: NotificationType) => {
  switch (type) {
    case NotificationType.EXPECT_STATUS_AT_TIME:
      return 'is delayed to reach status';
    case NotificationType.STATUS_CHANGED_TO:
      return 'has reached the status';
    case NotificationType.UNKNOWN:
    case NotificationType.STATUS_AT_TIME:
      return 'has status';
  }
};

interface ListItem {
  title: JSX.Element;
  description: JSX.Element;
  onClick: () => void;
  className: string;
}

interface Props {
  notifications: ShipmentNotificationModel[];
  markAsRead: (id?: string) => void;
  thereIsNoUnreadNotifications: boolean;
  refreshNotifications: () => void;
  isLoading: boolean;
}

export const NotificationList: React.FunctionComponent<Props> = ({
  notifications,
  markAsRead,
  thereIsNoUnreadNotifications,
  refreshNotifications,
  isLoading,
}) => {
  const data: ListItem[] = notifications.map(notification => {
    return {
      title: (
        <div className={styles.notificationTitle}>
          {mapping.mapNotificationTypeToDescription(notification.type)}
        </div>
      ),
      description: (
        <>
          Delivery&nbsp;
          {notification.shipmentId ? (
            <Link route={{ name: 'SHIPMENT_EDIT', shipmentId: notification.shipmentId }}>
              {notification.trackingNumber}&nbsp;
            </Link>
          ) : (
            <span>{notification.trackingNumber}&nbsp;</span>
          )}
          {getMiddleText(notification.type)}&nbsp;
          <span className={styles.status}>
            {humanize(
              notification.type === NotificationType.STATUS_AT_TIME
                ? notification.actualStatus
                : notification.expectedStatus!
            )}
          </span>
          {notification.meta && notification.meta.customerName && (
            <div>Customer: {notification.meta.customerName}</div>
          )}
          <div className={styles.createdAt}>{`${moment(notification.createdAt).format(
            'dddd, DD/MM/YYYY hh:mm A'
          )}`}</div>
        </>
      ),
      onClick: () => (notification.readAt ? null : markAsRead(notification.id)),
      className: notification.readAt
        ? styles.notificationWrapper
        : styles.notificationWrapperActive,
    };
  });

  return (
    <>
      <div className={styles.buttonsWrapper}>
        <button
          onClick={refreshNotifications}
          className={`${styles.button} ${styles.refreshButton}`}
          disabled={isLoading}
        >
          {LABELS.REFRESH}
        </button>
        {!isEmpty(notifications) && (
          <button
            onClick={() => markAsRead()}
            disabled={thereIsNoUnreadNotifications}
            className={styles.button}
          >
            {LABELS.MARK_ALL_AS_READ}
          </button>
        )}
      </div>
      <List
        dataSource={data}
        loading={isLoading}
        className={styles.listWrapper}
        renderItem={(item: ListItem) => (
          <List.Item onClick={item.onClick} className={item.className}>
            <List.Item.Meta title={item.title} description={item.description} />
          </List.Item>
        )}
      />
    </>
  );
};

const styles = {
  listWrapper: tss({
    maxHeight: '500px',
    overflow: 'auto',
  }),
  buttonsWrapper: tss({
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '5px 10px 10px 10px',
  }),
  button: tss({
    border: 'none',
    fontSize: '14px',
    cursor: 'pointer',
    color: defaultTheme.color.primary,
    paddingBottom: '4px',
    $nest: {
      '&:focus': {
        outline: 0,
      },
      '&:disabled': {
        color: '#d3d3d3',
      },
    },
  }),
  refreshButton: tss({
    marginRight: '5px',
  }),
  notificationWrapper: tss({
    borderBottom: '1px solid #e2e2e2',
    padding: '10px',
    position: 'relative',
    textAlign: 'left',
    paddingRight: '15px',
  }),
  notificationWrapperActive: tss({
    borderBottom: '1px solid #e2e2e2',
    padding: '10px',
    position: 'relative',
    background: '#900b3d0d',
    cursor: 'pointer',
    paddingRight: '35px',
    marginBottom: '5px',
    $nest: {
      '&::after': {
        color: '#f5222dcf',
        content: `'●'`,
        position: 'absolute',
        right: '15px',
        zIndex: 10,
        fontSize: '11px',
        top: 'calc(50% - 7.5px)',
      },
    },
  }),
  notificationTitle: tss({
    fontWeight: 600,
    fontSize: '16px',
  }),
  status: tss({
    fontWeight: 600,
  }),
  createdAt: tss({
    color: '#9c9c9c',
    fontSize: '13px',
  }),
};
