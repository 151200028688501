import * as React from 'react';
import { style as tss } from 'typestyle';

import { SideMenu } from './components/side-menu';

const mainMenuStyle = tss({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 9,
});

function MainMenuContainer() {
  return (
    <nav className={mainMenuStyle}>
      <SideMenu />
    </nav>
  );
}

export default MainMenuContainer;
