import { InfoCircleOutlined } from '@ant-design/icons';
import * as React from 'react';
import { Table, Tabs, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { stylesheet } from 'typestyle';
import { ExperimentWithVariantModel, ExperimentVariantResultDTO } from '@src/models';
import {
  computeRateWithConfidence,
  DisplayValueWithConfidence,
  getValueWithConfidence,
  ValueWithConfidence,
} from './value-with-confidence';

interface ResultDetailsProps {
  value: ValueWithConfidence;
  pValue: number;
  showPercent?: boolean;
}
function getConversionRate(result: ExperimentVariantResultDTO): ResultDetailsProps {
  const { total, converted, std } = result.result_summary.conversion.details;
  const value = (converted / total) * 100;
  const pValue = result.result_summary.conversion.significance?.p_value;
  return {
    value: getValueWithConfidence(value, std),
    pValue: Number(pValue),
    showPercent: true,
  };
}
function getAverageOrderValue(result: ExperimentVariantResultDTO): ResultDetailsProps {
  const { avg: value, std } = result.result_summary.order_revenue.details;
  const pValue = result.result_summary.order_revenue.significance?.p_value;
  return {
    value: getValueWithConfidence(value / 10000, std / 10000),
    pValue: Number(pValue),
  };
}
function getShippingReveneu(result: ExperimentVariantResultDTO): ResultDetailsProps {
  const { avg: value, std } = result.result_summary.shipping_revenue.details;
  const pValue = result.result_summary.shipping_revenue.significance?.p_value;
  return {
    value: getValueWithConfidence(value / 10000, std / 10000),
    pValue: Number(pValue),
  };
}
function getReveneu(result: ExperimentVariantResultDTO): ResultDetailsProps {
  const { avg: value, std } = result.result_summary.total_revenue.details;
  const pValue = result.result_summary.total_revenue.significance?.p_value;
  return {
    value: getValueWithConfidence(value / 10000, std / 10000),
    pValue: Number(pValue),
  };
}
const significanceTooltip =
  'A result being statistically significant refers to the claim that a result from experimentation is not likely to occur randomly or by chance, and is instead likely to be attributable to a specific cause. This value indicates the achieved significance. To increase the significance, more sessions need to be gathered in the experiment.';
const revenueTooltip =
  'Average is computed as a trimmed mean, discarding the top and bottom 10% of sessions in terms of revenue. It is applied to eliminate outliers that in some cases skew the picture of the general trend.';
interface ImprovementTableProps {
  experiment: ExperimentWithVariantModel;
}
export const ImprovementTable: React.FC<ImprovementTableProps> = ({ experiment }) => {
  const { originalResult, variantResults = [] } = experiment.result!;
  const results = [originalResult, ...variantResults];
  const columns: ColumnType<ExperimentVariantResultDTO>[] = [
    {
      title: 'Variant',
      key: 'name',
      render: (dummy: string, result: ExperimentVariantResultDTO, idx: number) => {
        if (idx === 0) {
          return 'Original';
        }
        const variant = experiment.variants.find(
          variant => variant.variantSiteId === result.site_id
        );
        return variant ? variant.name : 'Variant';
      },
    },
    {
      title: 'Conversion rate',
      key: 'conversion_rate',
      align: 'right',
      render: (dummy: string, variantResult: ExperimentVariantResultDTO, idx: number) => {
        if (idx === 0) {
          return 'Baseline';
        }
        const originalValue = getConversionRate(originalResult).value;
        const variantValue = getConversionRate(variantResult).value;
        const improvedValue = computeRateWithConfidence(originalValue, variantValue);
        return <DisplayValueWithConfidence value={improvedValue} showExplicitPlus showPercent />;
      },
    },
    {
      title: 'Average Order Value',
      key: 'average_order_value',
      align: 'right',
      render: (dummy: string, variantResult: ExperimentVariantResultDTO, idx: number) => {
        if (idx === 0) {
          return 'Baseline';
        }
        const originalValue = getAverageOrderValue(originalResult).value;
        const variantValue = getAverageOrderValue(variantResult).value;
        const improvedValue = computeRateWithConfidence(originalValue, variantValue);
        return <DisplayValueWithConfidence value={improvedValue} showExplicitPlus showPercent />;
      },
    },
    {
      title: 'Average Shipping Revenue',
      key: 'shipping_revenue',
      align: 'right',
      render: (dummy: string, variantResult: ExperimentVariantResultDTO, idx: number) => {
        if (idx === 0) {
          return 'Baseline';
        }
        const originalValue = getShippingReveneu(originalResult).value;
        const variantValue = getShippingReveneu(variantResult).value;
        const improvedValue = computeRateWithConfidence(originalValue, variantValue);
        return <DisplayValueWithConfidence value={improvedValue} showExplicitPlus showPercent />;
      },
    },
    {
      title: 'Average Total Revenue',
      key: 'revenue',
      align: 'right',
      render: (dummy: string, variantResult: ExperimentVariantResultDTO, idx: number) => {
        if (idx === 0) {
          return 'Baseline';
        }
        const originalValue = getReveneu(originalResult).value;
        const variantValue = getReveneu(variantResult).value;
        const improvedValue = computeRateWithConfidence(originalValue, variantValue);
        return <DisplayValueWithConfidence value={improvedValue} showExplicitPlus showPercent />;
      },
    },
  ];
  return (
    <Table
      dataSource={results || []}
      rowKey="result_summary.site_id"
      columns={columns}
      loading={false}
      pagination={false}
      className={styles.table}
    />
  );
};
interface ResultTableProps {
  title: string;
  experiment: ExperimentWithVariantModel;
  tooltip?: string;
  getProps: (result: ExperimentVariantResultDTO) => ResultDetailsProps;
}
export const ResultTable: React.FC<ResultTableProps> = ({
  title,
  experiment,
  tooltip,
  getProps,
}) => {
  const { originalResult, variantResults = [] } = experiment.result!;
  const results = [originalResult, ...variantResults];
  const columns: ColumnType<ExperimentVariantResultDTO>[] = [
    {
      title: 'Variant',
      key: 'name',
      render: (dummy: string, result: ExperimentVariantResultDTO, idx: number) => {
        if (idx === 0) {
          return 'Original';
        }
        const variant = experiment.variants.find(
          variant => variant.variantSiteId === result.site_id
        );
        return variant ? variant.name : 'Variant';
      },
    },
    {
      title:
        tooltip !== undefined ? (
          <>
            {title}
            <Tooltip title={tooltip}>
              <InfoCircleOutlined style={{ marginLeft: '3px' }} />
            </Tooltip>
          </>
        ) : (
          title
        ),
      key: 'baseline',
      align: 'right',
      render: (dummy: string, result: ExperimentVariantResultDTO, idx: number) => {
        return <DisplayValueWithConfidence {...getProps(result)} />;
      },
    },
    {
      title: 'Improvement',
      key: 'improvement',
      align: 'right',
      render: (dummy: string, variantResult: ExperimentVariantResultDTO, idx: number) => {
        if (idx === 0) {
          return 'Baseline';
        }
        const originalValue = getProps(originalResult).value;
        const variantValue = getProps(variantResult).value;
        const improvedValue = computeRateWithConfidence(originalValue, variantValue);
        return <DisplayValueWithConfidence value={improvedValue} showExplicitPlus showPercent />;
      },
    },
    {
      title: (
        <>
          Significance
          <Tooltip title={significanceTooltip}>
            <InfoCircleOutlined style={{ marginLeft: '3px' }} />
          </Tooltip>
        </>
      ),
      key: 'significance',
      align: 'right',
      render: (dummy: string, variantResult: ExperimentVariantResultDTO, idx: number) => {
        if (idx === 0) {
          return 'Baseline';
        }
        const { pValue } = getProps(variantResult);
        if (pValue === undefined) {
          return null;
        }
        const value = { middleValue: 100 - pValue, lowerBound: 0, upperBound: 0 };
        return (
          <DisplayValueWithConfidence
            value={value}
            showBounds={false}
            showPercent
            color={pValue <= 5 ? '#0BB276' : '#E35709'}
          />
        );
      },
    },
    {
      title: 'Sessions',
      key: 'sessions',
      align: 'right',
      render: (dummy: string, result: ExperimentVariantResultDTO, idx: number) => {
        const value = result.result_summary.conversion.details.total;
        if (value === undefined) {
          return <div>0</div>;
        }
        return value;
      },
      className: styles.separator,
    },
    {
      title: 'Completed sessions',
      key: 'completed_sessions',
      align: 'right',
      render: (dummy: string, result: ExperimentVariantResultDTO, idx: number) => {
        const value = result.result_summary.conversion.details.converted;
        if (value === undefined) {
          return <div>0</div>;
        }
        return value;
      },
    },
  ];
  return (
    <Table
      dataSource={results || []}
      rowKey="result_summary.site_id"
      columns={columns}
      loading={false}
      pagination={false}
      className={styles.table}
    />
  );
};
interface ResultTableWithTabsProps {
  experiment: ExperimentWithVariantModel;
}
export const ResultTableWithTabs: React.FC<ResultTableWithTabsProps> = ({ experiment }) => {
  return (
    <Tabs className={styles.tabs}>
      <Tabs.TabPane tab="Conversion Rate" key={1}>
        <ResultTable title="Conversion Rate" experiment={experiment} getProps={getConversionRate} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Average Order Value" key={2}>
        <ResultTable
          title="Average Order Value"
          experiment={experiment}
          tooltip={revenueTooltip}
          getProps={getAverageOrderValue}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Average Shipping Revenue" key={3}>
        <ResultTable
          title="Average Shipping Revenue"
          experiment={experiment}
          tooltip={revenueTooltip}
          getProps={getShippingReveneu}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Average Total Revenue" key={4}>
        <ResultTable
          title="Average Total Revenue"
          experiment={experiment}
          tooltip={revenueTooltip}
          getProps={getReveneu}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};
const styles = stylesheet({
  table: {
    marginBottom: '16px',
    $nest: {
      th: {
        padding: '14px !important',
        borderBottom: 'initial !important',
        borderTop: '1px solid #dadada',
        backgroundColor: '#f2f1f0 !important',
      },
      td: {
        padding: '14px !important',
        borderBottom: 'initial !important',
        color: 'black',
      },
    },
  },
  tabs: {
    $nest: {
      '.ant-tabs-nav': {
        margin: '0 !important',
      },
    },
  },
  separator: {
    borderLeft: '2px solid #dadada',
  },
});
