import { head, keys, values } from 'ramda';
import { FieldNames } from './types';

export const mapFormValuesToRequestPayload = (param: { [K in FieldNames]: boolean }) => {
  const fieldName = head(keys(param));
  const value = head(values(param));

  switch (fieldName) {
    case 'header.enable':
      return {
        features: {
          header: {
            enable_section: value,
          },
        },
      };
    case 'order_details.enable':
      return {
        features: {
          order_details: {
            enable_section: value,
            enable_header: value,
          },
        },
      };
    case 'delivery.enable':
      return {
        features: {
          delivery: {
            enable_section: value,
            enable_header: value,
          },
        },
      };
    case 'delivery.is_courier_instructions_editable':
      return {
        features: {
          delivery: {
            is_courier_instructions_editable: value,
          },
        },
      };
    case 'delivery.is_door_code_editable':
      return {
        features: {
          delivery: {
            is_door_code_editable: value,
          },
        },
      };
    case 'order_summary.enable':
      return {
        features: {
          order_summary: {
            enable_header: value,
            enable_section: value,
          },
        },
      };
    case 'rating.enable':
      return {
        features: {
          rating: {
            enable_header: value,
            enable_section: value,
          },
        },
      };
    case 'footer.show_slogan':
      return {
        features: {
          footer: {
            show_slogan: value,
          },
        },
      };

    default:
      return { features: {} };
  }
};
