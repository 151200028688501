import { SelectOption } from '@src/controls/select';
import { FeatureDefinition } from './types';

export const fontWeightOptions: SelectOption[] = [
  {
    label: '100',
    value: '100',
  },
  {
    label: '200',
    value: '200',
  },
  {
    label: '300',
    value: '300',
  },
  {
    label: '400',
    value: '400',
  },
  {
    label: '500',
    value: '500',
  },
  {
    label: '600',
    value: '600',
  },
  {
    label: '700',
    value: '700',
  },
  {
    label: '800',
    value: '800',
  },
  {
    label: '900',
    value: '900',
  },
];

export const featureDefinitions: FeatureDefinition[] = [
  {
    name: 'enable_short_delivery_times',
    title: 'Enable short delivery times',
    type: 'checkbox',
    description:
      'Enables shorter delivery time descriptions. Eg. ‘Delivered between Thursday (Mar 25th) and Friday (Mar 26th)’ will be shown as ‘Wed, Mar 25 - Fri, Mar 26’',
  },
  {
    name: 'show_delivery_times_as_estimates',
    title: 'Show delivery times as estimated',
    type: 'checkbox',
    description:
      'Adds information that the delivery time is an estimation, for example ‘4 business days’ will be shown as ‘4 business days (estimated)’',
  },
  {
    name: 'enable_analytics',
    title: 'Enable analytics',
    type: 'checkbox',
    description: 'Enable Product Page Widget analytics',
  },
  {
    name: 'preserve_postal_code',
    title: 'Save postal code',
    type: 'checkbox',
    description: "Saves consumer's postal code on device local storage",
  },
  {
    name: 'shipping_categories_visible_when_folded_count',
    title: 'Fold shipping categories',
    type: 'checkbox',
    description:
      'Hides shipping categories other than preselected one, adds "show more" button to expand widget and make all categories visible.',
  },
  {
    name: 'shipping_categories_visible_when_folded_count',
    title: 'Categories visible when folded',
    type: 'number',
    description: 'Controls how many categories vill be shown when widget is folded',
    disabledIf: (features, formValues) =>
      !features.shipping_categories_visible_when_folded_count &&
      formValues.shipping_categories_visible_when_folded_count! < 1,
  },
  {
    name: 'custom_font_family_name',
    title: 'Custom font family name',
    type: 'text',
    description: 'Specify together with "Custom font URL" to use nonstandard font',
  },
  {
    name: 'custom_font_family_url',
    title: 'Custom font URL',
    type: 'text',
    description:
      'For example: "https://fonts.googleapis.com/css2?family=Roboto". Specify together with "Font family" flag',
  },
  {
    name: 'font_weight_default',
    title: 'Default font weight',
    type: 'select',
    options: fontWeightOptions,
    description:
      'Affects initial call-to-action button, postal code: input label, input error message, input value, category: location name, delivery time and labels',
  },
  {
    name: 'font_weight_stronger',
    title: 'Stronger font weight',
    type: 'select',
    options: fontWeightOptions,
    description:
      'Affects: postal code submit button label, category: name and price, show more / show less button label',
  },
  {
    name: 'font_size_primary',
    title: 'Primary font size (px)',
    type: 'text',
    description:
      'Affects initial call-to-action button, postal code input: value and submit button label, category: name, price, initial price, location name and delivery time',
  },
  {
    name: 'font_size_secondary',
    title: 'Secondary font size (px)',
    type: 'text',
    description: 'Affects postal code: input label and error message, category labels',
  },
  {
    name: 'neutral_color',
    title: 'Primary neutral color (HEX)',
    type: 'hex',
    description:
      'Affects initial call-to-action button, postal code input value, category name, category price, show more / show less button',
  },
  {
    name: 'secondary_color',
    title: 'Secondary neutral color (HEX)',
    type: 'hex',
    description:
      'Affects postal code: input label and clear icon, category: location name, delivery time and labels (other than sustainability-related)',
  },
  {
    name: 'accent_color',
    title: 'Accent color (HEX)',
    type: 'hex',
    description: 'Affects postal code submit button border and background',
  },
];
