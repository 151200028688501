import { Switch, Tooltip } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';

const featureName = 'Show carrier logos in delivery options placeholder';
const featureDescription =
  'When Delivery Checkout is set to load only as address field this feature shows carrier logos below the address field';

type Props = {
  featureFlag: boolean;
  updateFeatureFlag: (featureFlag: boolean) => void;
};

export const PlaceholderCarrierLogosFeatureFlag: React.FC<Props> = ({
  featureFlag,
  updateFeatureFlag,
}) => {
  return (
    <Tooltip className={styles.tooltip} title={featureDescription}>
      <span className={styles.text}>{featureName}</span>
      <Switch checked={featureFlag} onChange={updateFeatureFlag} />
    </Tooltip>
  );
};

const styles = stylesheet({
  tooltip: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 16px 16px',
  },
  text: {
    flexGrow: 1,
  },
});
