import { RegionError } from '@src/containers/regions/region-error-handling-container';
import { ERRORS } from '@src/dictionaries';
import { MADError } from '@src/utils';
import { isNotEmpty, isNotNil } from 'ramda-adjunct';
import { combineEpics, Epic } from 'redux-observable';
import { from, of, range as observableRange } from 'rxjs';
import {
  catchError,
  concat,
  concatMap,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { configActions } from '.';
import { RootAction, RootState, Services } from '..';
import { authSelectors } from '../auth';
import { merchantsActions, MerchantsSelectors } from '../merchants';
import { sitesActions, SitesSelectors } from '../sites';

const updateRegionProducts: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateCarrierProductRequest)),
    concatMap(({ payload: { model, siteId }, meta: { optimistic } }) =>
      from(selfCareService.regionCarrierProductUpdate(model, siteId)).pipe(
        map(() => configActions.updateCarrierProductSuccess({ transactionId: optimistic.id })),
        catchError(error =>
          of(
            configActions.updateCarrierProductError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(error.message);
            })
          )
        )
      )
    )
  );

const updateRegionProductFilterRules: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateCarrierProductFilterRulesRequest)),
    concatMap(({ payload: { model, siteId }, meta: { optimistic } }) =>
      from(selfCareService.regionCarrierProductFilterRulesUpdate(model, siteId)).pipe(
        map(() =>
          configActions.updateCarrierProductFilterRulesSuccess({ transactionId: optimistic.id })
        ),
        catchError(error =>
          of(
            configActions.updateCarrierProductFilterRulesError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(error.message);
            })
          )
        )
      )
    )
  );

const createDraft: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { configDraftService, loggingService, notificationService, configService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.createDraftRequest)),
    switchMap(action =>
      from(
        configDraftService.createDraft(SitesSelectors.getSelectedSiteIdOrEmpty(state$.value))
      ).pipe(
        switchMap(draft =>
          from(
            [configActions.createDraftSuccess(draft), action.meta.onComplete(draft.siteId)].filter(
              isNotNil
            )
          )
        ),
        catchError((error: MADError) => {
          if (error.message === RegionError.draftExist) {
            return from(
              configService.getMerchant(MerchantsSelectors.getSelectedMerchantId(state$.value))
            ).pipe(
              switchMap(merchant => {
                const selectedId = SitesSelectors.getSelectedSiteIdOrEmpty(state$.value);
                const master = merchant.sites.find(site => site.id === selectedId)!;
                const draft = merchant.sites.find(
                  site =>
                    site.metadata?.draft?.isDraft && site.metadata.draft.masterSiteId === selectedId
                );
                // in case somehow draft was deleted before we get merchant sites
                if (!draft) throw new Error();
                return from(
                  [
                    // stiching object that would normaly come from create draft call
                    configActions.createDraftSuccess({
                      siteId: draft?.id!,
                      master: {
                        siteId: master.id,
                        updatedAt: master.updatedAt,
                        version: master?.version,
                      },
                      isSynced: true,
                      version: master.version,
                    }),
                    merchantsActions.getMerchantSuccess(merchant),
                    action.meta.onComplete(draft.id),
                  ].filter(isNotNil)
                );
              }),
              catchError((error: MADError) =>
                of(configActions.createDraftError(error.message)).pipe(
                  tap(() => {
                    notificationService.error(error.message);
                    loggingService.logError(error);
                  })
                )
              )
            );
          }

          return of(configActions.createDraftError(error.message)).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          );
        })
      )
    )
  );

const deleteDraft: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { configDraftService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.deleteDraftRequest)),
    switchMap(() =>
      from(
        configDraftService.deleteDraft(
          SitesSelectors.getDraftSiteIdOrEmpty(state$.value),
          SitesSelectors.getDraftSiteVersionOrEmpty(state$.value)
        )
      ).pipe(
        map(() =>
          configActions.deleteDraftSuccess(SitesSelectors.getDraftSiteIdOrEmpty(state$.value))
        ),
        tap(() => notificationService.success(ERRORS.REMOVE_DRAFT_SUCCESS)),
        catchError((error: MADError) =>
          of(configActions.deleteDraftError(error.message)).pipe(
            tap(() => {
              notificationService.error(ERRORS.REMOVE_DRAFT_ERROR);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const promoteDraft: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { configDraftService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.promoteDraftRequest)),
    switchMap(() =>
      from(
        configDraftService.draftPromote(
          SitesSelectors.getDraftSiteIdOrEmpty(state$.value),
          SitesSelectors.getDraftSiteVersionOrEmpty(state$.value)
        )
      ).pipe(
        tap(() => notificationService.success(ERRORS.PUBLISH_DRAFT_SUCCESS)),
        concatMap(() => [
          configActions.promoteDraftSuccess(SitesSelectors.getDraftSiteIdOrEmpty(state$.value)),
          sitesActions.getSitesRequest({
            merchantId: MerchantsSelectors.getSelectedMerchantId(state$.value),
            siteId: SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
          }),
        ]),
        catchError((error: MADError) =>
          of(configActions.promoteDraftError(error.message)).pipe(
            tap(() => {
              notificationService.error(ERRORS.PUBLISH_DRAFT_ERROR);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const getDraftSite: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { loggingService, configDraftService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.getDraftSiteRequest)),
    switchMap(() =>
      from(configDraftService.getDraft(SitesSelectors.getDraftSiteIdOrEmpty(state$.value))).pipe(
        map(draft => configActions.getDraftSiteSuccess(draft)),
        catchError((error: MADError) =>
          of(configActions.promoteDraftError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const updateCutoffTimes: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateCutoffTimesRequest)),
    concatMap(({ payload, meta: { optimistic, onComplete } }) => {
      let possibleError: string;
      return from(selfCareService.updateCutoffTimes(payload.siteId, payload.model)).pipe(
        map(() => configActions.updateCutoffTimesSuccess({ transactionId: optimistic.id })),
        catchError((error: MADError) =>
          of(
            configActions.updateCutoffTimesError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              possibleError = error.message;
              loggingService.logError(error);
              notificationService.error(ERRORS.UPDATE_CUTOFF_TIMES_ERROR);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete(possibleError);
          }
        })
      );
    })
  );

const updateCarrierCutoff: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateCarrierCutoffRequest)),
    concatMap(({ payload, meta: { optimistic, onComplete } }) => {
      let possibleError: string;
      return from(selfCareService.updateCarrierCutoff(payload.siteId, payload.model)).pipe(
        map(() => configActions.updateCarrierCutoffSuccess({ transactionId: optimistic.id })),
        catchError((error: MADError) =>
          of(
            configActions.updateCarrierCutoffError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              possibleError = error.message;
              loggingService.logError(error);
              notificationService.error(ERRORS.UPDATE_CUTOFF_TIMES_ERROR);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete(possibleError);
          }
        })
      );
    })
  );

const updatePreselectionOrder: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state,
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateCountrySettingsRequest)),
    concatMap(({ payload, meta: { optimistic, onComplete } }) => {
      return from(selfCareService.updateCountrySettings(payload.siteId, payload.model)).pipe(
        map(() => configActions.updateCountrySettingsSuccess({ transactionId: optimistic.id })),
        catchError((error: MADError) =>
          of(
            configActions.updateCountrySettingsError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(ERRORS.UPDATE_COUNTRY_SETTINGS_ERROR);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete();
          }
        })
      );
    })
  );

const createCategory: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.createCategoryRequest)),
    concatMap(({ payload: { siteId, model }, meta: { onComplete } }) => {
      let possibleCategoryId: null | string = null;
      return from(selfCareService.createCategory(siteId, model)).pipe(
        map(categoryId => {
          possibleCategoryId = categoryId;
          return configActions.createCategorySuccess({
            model: { ...model, id: categoryId },
            siteId,
          });
        }),
        catchError((error: MADError) =>
          of(configActions.createCategoryError(error.message)).pipe(
            tap(() => {
              notificationService.error(error.message, 'Failed to create category');
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          if (onComplete && possibleCategoryId) {
            onComplete(possibleCategoryId);
          }
        })
      );
    })
  );

const updateCategoryFilterRules: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateCategoryFilterRulesRequest)),
    concatMap(({ payload: { model, siteId }, meta: { optimistic } }) =>
      from(selfCareService.categoryFilterRulesUpdate(model, siteId)).pipe(
        map(() => configActions.updateCategoryFilterRulesSuccess({ transactionId: optimistic.id })),
        catchError(error =>
          of(
            configActions.updateCategoryFilterRulesError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(error.message);
            })
          )
        )
      )
    )
  );

const createCategoryLabel: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.createCategoryLabelRequest)),
    concatMap(({ payload: { siteId, model }, meta: { onComplete } }) => {
      return from(selfCareService.createCategoryLabel(siteId, model)).pipe(
        map(categoryLabelId => {
          return configActions.createCategoryLabelSuccess({
            model: { ...model, id: categoryLabelId },
            siteId,
          });
        }),
        catchError((error: MADError) =>
          of(configActions.createCategoryLabelError(error.message)).pipe(
            tap(() => {
              notificationService.error(error.message, 'Failed to create category label');
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete();
          }
        })
      );
    })
  );

const addCategoryTags: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.addCategoryTagsRequest)),
    concatMap(({ payload: { model, siteId }, meta: { optimistic } }) =>
      from(selfCareService.addCategoryTags(siteId, model)).pipe(
        map(() => configActions.addCategoryTagsSuccess({ transactionId: optimistic.id })),
        catchError(error =>
          of(
            configActions.addCategoryTagsError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(error.message);
            })
          )
        )
      )
    )
  );

const removeCategoryTags: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.removeCategoryTagsRequest)),
    concatMap(({ payload: { model, siteId }, meta: { optimistic } }) =>
      from(selfCareService.removeCategoryTags(siteId, model)).pipe(
        map(() => configActions.removeCategoryTagsSuccess({ transactionId: optimistic.id })),
        catchError(error =>
          of(
            configActions.removeCategoryTagsError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(error.message);
            })
          )
        )
      )
    )
  );

const updateRegionDetails: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateRegionDetailRequest)),
    concatMap(({ payload, meta: { optimistic } }) =>
      from(selfCareService.updateRegionDetails(payload.siteId, payload.model)).pipe(
        map(() => configActions.updateRegionDetailSuccess({ transactionId: optimistic.id })),
        catchError((error: MADError) =>
          of(
            configActions.updateRegionDetailError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(error.message);
            })
          )
        )
      )
    )
  );

const getChangesList: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { selfCareService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.getChangesListRequest)),
    switchMap(({ payload: { onComplete } }) => {
      let ids: string[] | null = null;
      return from(
        selfCareService.getConfigChanges(SitesSelectors.getDraftSiteIdOrEmpty(state$.value))
      ).pipe(
        map(changes => {
          ids = changes.map(ch => ch.userId);
          return configActions.getChangesListSuccess({ changes });
        }),
        catchError((error: MADError) =>
          of(configActions.getChangesListError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        ),
        finalize(() => {
          if (ids && isNotEmpty(ids)) {
            onComplete(ids);
          }
        })
      );
    })
  );

const updateCategoryDetails: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _state$,
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateCategoryDetailsRequest)),
    concatMap(({ payload, meta: { optimistic } }) =>
      from(selfCareService.updateCategoryDetails(payload.siteId, payload.model)).pipe(
        map(() => configActions.updateCategoryDetailsSuccess({ transactionId: optimistic.id })),
        catchError((error: MADError) =>
          of(
            configActions.updateCategoryDetailsError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(error.message);
            })
          )
        )
      )
    )
  );

const updateCategoryTranslations: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _state$,
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateCategoryTranslationsRequest)),
    concatMap(({ payload, meta: { optimistic } }) =>
      from(selfCareService.updateCategoryTranslations(payload.siteId, payload.model)).pipe(
        map(() =>
          configActions.updateCategoryTranslationsSuccess({
            transactionId: optimistic.id,
          })
        ),
        catchError((error: MADError) =>
          of(
            configActions.updateCategoryTranslationsError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(error.message);
            })
          )
        )
      )
    )
  );

const deleteCategoryTranslations: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.deleteCategoryTranslationsRequest)),
    concatMap(({ payload, meta: { optimistic } }) =>
      from(selfCareService.deleteCategoryTranslations(payload.siteId, payload.model)).pipe(
        map(() =>
          configActions.deleteCategoryTranslationsSuccess({ transactionId: optimistic.id })
        ),
        catchError((error: MADError) =>
          of(
            configActions.deleteCategoryTranslationsError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const deleteCategory: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.deleteCategoryRequest)),
    concatMap(({ payload, meta: { optimistic } }) =>
      from(selfCareService.deleteCategory(payload.siteId, payload.categoryId)).pipe(
        map(() => configActions.deleteCategorySuccess({ transactionId: optimistic.id })),
        catchError((error: MADError) =>
          of(
            configActions.deleteCategoryError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const updateWarehouseDetails: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _state$,
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateWarehouseDetailsRequest)),
    concatMap(({ payload, meta: { optimistic } }) =>
      from(selfCareService.updateWarehouseDetails(payload.siteId, payload.model)).pipe(
        map(() => configActions.updateWarehouseDetailsSuccess({ transactionId: optimistic.id })),
        catchError((error: MADError) =>
          of(
            configActions.updateWarehouseDetailsError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(ERRORS.UPDATE_WAREHOUSE_DETAILS_ERROR);
            })
          )
        )
      )
    )
  );

const createCarrierProduct: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _state$,
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.createCarrierProductRequest)),
    concatMap(action =>
      from(selfCareService.createCarrierProduct(action.payload.siteId, action.payload.model)).pipe(
        map(({ status }) =>
          configActions.createCarrierProductSuccess({
            siteId: action.payload.siteId,
            model: { ...action.payload.model, status },
          })
        ),
        catchError((error: MADError) =>
          of(configActions.createCarrierProductError(error.message)).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => action.meta.onComplete())
      )
    )
  );

const deleteCarrierProduct: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.deleteCarrierProductRequest)),
    concatMap(({ payload, meta: { optimistic } }) =>
      from(selfCareService.deleteCarrierProduct(payload.siteId, payload.model)).pipe(
        map(() => configActions.deleteCarrierProductSuccess({ transactionId: optimistic.id })),
        catchError((error: MADError) =>
          of(
            configActions.deleteCarrierProductError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const createWarehouse: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.createWarehouseRequest)),
    switchMap(({ payload: { siteId, model }, meta: { onComplete } }) => {
      let possibleRegionId: null | string = null;
      return from(selfCareService.createWarehouse(siteId, model)).pipe(
        map(res => {
          possibleRegionId = res.id;
          return configActions.createWarehouseSuccess({
            siteId,
            model: {
              ...model,
              id: res.id,
            },
          });
        }),
        catchError((error: MADError) =>
          of(configActions.createWarehouseError(error.message)).pipe(
            tap(() => {
              notificationService.error(error.message, 'Failed to create warehouse');
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          if (onComplete && possibleRegionId) {
            onComplete(possibleRegionId);
          }
        })
      );
    })
  );

const deleteWarehouse: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _state$,
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.deleteWarehouseRequest)),
    switchMap(({ payload: { siteId, warehouseId }, meta: { onComplete } }) =>
      from(selfCareService.deleteWarehouse(siteId, warehouseId)).pipe(
        map(() =>
          configActions.deleteWarehouseSuccess({
            warehouseId,
            siteId,
          })
        ),
        catchError((error: MADError) =>
          of(configActions.deleteWarehouseError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(error.message, ERRORS.DELETE_WAREHOUSE_ERROR);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete();
          }
        })
      )
    )
  );

const createWarehouseCutoffTimes: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  _state$,
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.createWarehouseCutoffTimesRequest)),
    concatMap(({ payload: { siteId, model }, meta: { optimistic, onComplete } }) => {
      let possibleError: string;
      return from(selfCareService.createWarehouseCutoffTimes(siteId, model)).pipe(
        map(() =>
          configActions.createWarehouseCutoffTimesSuccess({ model, transactionId: optimistic.id })
        ),
        catchError((error: MADError) =>
          of(
            configActions.createWarehouseCutoffTimesError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              possibleError = error.message;
              loggingService.logError(error), notificationService.error(error.message);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete(possibleError);
          }
        })
      );
    })
  );

const createRegion: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.createRegionRequest)),
    switchMap(({ payload, meta }) => {
      let possibleRegionId: null | string = null;
      return from(selfCareService.createRegion(payload.siteId, payload.model)).pipe(
        map(regionId => {
          possibleRegionId = regionId;
          return configActions.createRegionSuccess({
            siteId: payload.siteId,
            regionId,
          });
        }),
        catchError((error: MADError) =>
          of(configActions.createRegionError(error.message)).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          if (possibleRegionId) {
            meta.onComplete(possibleRegionId);
          }
        })
      );
    })
  );

const getSite: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { configService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.getSiteRequest)),
    switchMap(({ payload, meta }) =>
      from(configService.getSite(payload.siteId)).pipe(
        map(site =>
          configActions.getSiteSuccess({
            siteId: payload.siteId,
            site,
          })
        ),
        catchError((error: MADError) =>
          of(configActions.getSiteError(error.message)).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => meta.onComplete())
      )
    )
  );

const deleteRegion: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.deleteRegionRequest)),
    concatMap(({ payload, meta: { optimistic } }) =>
      from(selfCareService.deleteRegion(payload.siteId, payload.regionId)).pipe(
        map(() => configActions.deleteRegionSuccess({ transactionId: optimistic.id })),
        catchError((error: MADError) =>
          of(
            configActions.deleteCarrierProductError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const updateWidgetConfiguration: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateWidgetConfigurationRequest)),
    concatMap(({ payload, meta: { optimistic, onComplete } }) =>
      from(selfCareService.updateWidgetConfiguration(payload.siteId, payload.model)).pipe(
        map(() => configActions.updateWidgetConfigurationSuccess({ transactionId: optimistic.id })),
        catchError((error: MADError) =>
          of(
            configActions.updateWidgetConfigurationError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete();
          }
        })
      )
    )
  );

const updateWidgetCountryConfiguration: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateWidgetCountryConfigurationRequest)),
    concatMap(({ payload, meta: { optimistic, onComplete } }) =>
      from(selfCareService.updateWidgetCountryConfiguration(payload.siteId, payload.model)).pipe(
        map(() =>
          configActions.updateWidgetCountryConfigurationSuccess({ transactionId: optimistic.id })
        ),
        catchError((error: MADError) =>
          of(
            configActions.updateWidgetCountryConfigurationError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete();
          }
        })
      )
    )
  );

const createWidgetCountryConfiguration: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.createWidgetCountryConfigurationRequest)),
    concatMap(({ payload, meta: { optimistic, onComplete } }) =>
      from(selfCareService.createWidgetCountryConfiguration(payload.siteId, payload.model)).pipe(
        map(() =>
          configActions.createWidgetCountryConfigurationSuccess({ transactionId: optimistic.id })
        ),
        catchError((error: MADError) =>
          of(
            configActions.createWidgetCountryConfigurationError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete();
          }
        })
      )
    )
  );

const createOrUpdateCutoffTimes: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.createOrUpdateCutoffTimesRequest)),
    switchMap(({ payload: { siteId, models }, meta: { optimistic, onComplete } }) => {
      let possibleError: string;
      const modelsCount = models.length;
      return observableRange(0, modelsCount).pipe(
        concatMap(index =>
          from(
            (() => {
              if (models[index].action === 'CREATE') {
                return selfCareService.createWarehouseCutoffTimes(siteId, models[index].model);
              }
              return selfCareService.updateCutoffTimes(siteId, models[index].model);
            })()
          ).pipe(
            map(() => configActions.createOrUpdateCutoffTimesPartialSuccess(index / modelsCount))
          )
        ),
        concat(
          of(
            configActions.createOrUpdateCutoffTimesSuccess({
              transactionId: optimistic.id,
            })
          )
        ),
        catchError((error: MADError) =>
          of(
            configActions.createOrUpdateCutoffTimesError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              possibleError = error.message;
              loggingService.logError(error);
              notificationService.error(ERRORS.CREATE_OR_UPDATE_CUTOFF_TIMES);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete(possibleError);
          }
        })
      );
    })
  );

const setWarehouseShippingDateAdjustment: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.setWarehouseShippingDateAdjustmentRequest)),
    concatMap(({ payload, meta: { optimistic } }) =>
      from(
        selfCareService.setWarehouseShippingDateAdjustment(
          payload.siteId,
          payload.warehouseId,
          payload.model
        )
      ).pipe(
        map(() =>
          configActions.setWarehouseShippingDateAdjustmentSuccess({ transactionId: optimistic.id })
        ),
        catchError((error: MADError) =>
          of(
            configActions.setWarehouseShippingDateAdjustmentError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const getPrivateKey: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { configService, loggingService, messageService }
) =>
  action$.pipe(
    filter(() => authSelectors.canGetPrivateKey(state$.value)),
    filter(isActionOf(configActions.getPrivateKeyRequest)),
    switchMap(({ payload: { siteId }, meta: { onComplete } }) => {
      let possiblePrivateKey: string;
      return from(configService.getPrivateKey(siteId)).pipe(
        map(result => {
          possiblePrivateKey = result.privateKey;
          return configActions.getPrivateKeySuccess(result);
        }),
        catchError((error: MADError) =>
          of(configActions.getPrivateKeyError(error.message)).pipe(
            tap(() => {
              messageService.error(`Error: ${error.message}; Trace ID: ${error.traceId}`);
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete(possiblePrivateKey);
          }
        })
      );
    })
  );

const deleteCategoryLabel: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.deleteCategoryLabelRequest)),
    concatMap(({ payload: { model, siteId }, meta: { optimistic } }) =>
      from(selfCareService.deleteCategoryLabel(siteId, model)).pipe(
        map(() => configActions.deleteCategoryLabelSuccess({ transactionId: optimistic.id })),
        catchError(error =>
          of(
            configActions.deleteCategoryLabelError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(error.message);
            })
          )
        )
      )
    )
  );

const changeCategoryLabelsOrder: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.changeCategoryLabelsOrderRequest)),
    concatMap(({ payload: { siteId, model }, meta: { optimistic } }) =>
      from(selfCareService.changeCategoryLabelsOrder(siteId, model)).pipe(
        map(() =>
          configActions.changeCategoryLabelsOrderSuccess({
            transactionId: optimistic.id,
          })
        ),
        catchError((error: MADError) =>
          of(
            configActions.changeCategoryLabelsOrderError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const updateCategoryLabelsDetails: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateCategoryLabelDetailsRequest)),
    concatMap(({ payload: { siteId, model }, meta: { optimistic } }) =>
      from(selfCareService.updateCategoryLabelDetails(siteId, model)).pipe(
        map(() =>
          configActions.updateCategoryLabelDetailsSuccess({
            transactionId: optimistic.id,
          })
        ),
        catchError((error: MADError) =>
          of(
            configActions.updateCategoryLabelDetailsError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const updateCategoryLabelsTranslations: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateCategoryLabelTranslationsRequest)),
    concatMap(({ payload: { siteId, model }, meta: { optimistic } }) =>
      from(selfCareService.updateCategoryLabelTranslationsModel(siteId, model)).pipe(
        map(() =>
          configActions.updateCategoryLabelTranslationsSuccess({
            transactionId: optimistic.id,
          })
        ),
        catchError((error: MADError) =>
          of(
            configActions.updateCategoryLabelTranslationsError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const deleteCategoryLabelsTranslations: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.deleteCategoryLabelTranslationsRequest)),
    concatMap(({ payload: { siteId, model }, meta: { optimistic } }) =>
      from(selfCareService.deleteCategoryLabelTranslationsModel(siteId, model)).pipe(
        map(() =>
          configActions.deleteCategoryLabelTranslationsSuccess({
            transactionId: optimistic.id,
          })
        ),
        catchError((error: MADError) =>
          of(
            configActions.deleteCategoryLabelTranslationsError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const deleteWarehouseCutoffTimes: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.deleteWarehouseCutoffTimesRequest)),
    concatMap(({ payload: { siteId, model }, meta: { optimistic } }) =>
      from(selfCareService.deleteWarehouseCutoffTimes(siteId, model)).pipe(
        map(() =>
          configActions.deleteWarehouseCutoffTimesSuccess({
            transactionId: optimistic.id,
          })
        ),
        catchError((error: MADError) =>
          of(
            configActions.deleteWarehouseCutoffTimesError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const createDeliveryAddon: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.createDeliveryAddonRequest)),
    concatMap(({ payload: { siteId, model }, meta: { onComplete } }) => {
      return from(selfCareService.createDeliveryAddon(siteId, model)).pipe(
        map(addonId => {
          return configActions.createDeliveryAddonSuccess({
            model: { ...model, addonId },
            siteId,
          });
        }),
        catchError((error: MADError) =>
          of(configActions.createDeliveryAddonError(error.message)).pipe(
            tap(() => {
              notificationService.error(error.message, 'Failed to create category label');
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          if (onComplete) {
            onComplete();
          }
        })
      );
    })
  );

const updateDeliveryAddon: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.updateDeliveryAddonRequest)),
    concatMap(({ payload: { siteId, model }, meta: { optimistic } }) =>
      from(selfCareService.updateDeliveryAddon(siteId, model)).pipe(
        map(() =>
          configActions.updateDeliveryAddonSuccess({
            transactionId: optimistic.id,
          })
        ),
        catchError((error: MADError) =>
          of(
            configActions.updateDeliveryAddonError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const deleteDeliveryAddon: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.deleteDeliveryAddonRequest)),
    concatMap(({ payload: { model, siteId }, meta: { optimistic } }) =>
      from(selfCareService.deleteDeliveryAddon(siteId, model)).pipe(
        map(() => configActions.deleteDeliveryAddonSuccess({ transactionId: optimistic.id })),
        catchError(error =>
          of(
            configActions.deleteDeliveryAddonError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(error.message);
            })
          )
        )
      )
    )
  );

const adjustDeliveryAddonsOrder: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.adjustDeliveryAddonsOrderRequest)),
    concatMap(({ payload: { siteId, model }, meta: { optimistic } }) =>
      from(selfCareService.adjustDeliveryAddonsOrder(siteId, model)).pipe(
        map(() =>
          configActions.adjustDeliveryAddonsOrderSuccess({
            transactionId: optimistic.id,
          })
        ),
        catchError((error: MADError) =>
          of(
            configActions.adjustDeliveryAddonsOrderError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const upsertDeliveryAddonTranslation: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.upsertDeliveryAddonTranslationRequest)),
    concatMap(({ payload: { siteId, model }, meta: { optimistic } }) =>
      from(selfCareService.upsertDeliveryAddonTranslation(siteId, model)).pipe(
        map(() =>
          configActions.upsertDeliveryAddonTranslationSuccess({
            transactionId: optimistic.id,
          })
        ),
        catchError((error: MADError) =>
          of(
            configActions.upsertDeliveryAddonTranslationError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const deleteDeliveryAddonTranslation: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { selfCareService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(configActions.deleteDeliveryAddonTranslationRequest)),
    concatMap(({ payload: { siteId, model }, meta: { optimistic } }) =>
      from(selfCareService.deleteDeliveryAddonTranslation(siteId, model)).pipe(
        map(() =>
          configActions.deleteDeliveryAddonTranslationSuccess({
            transactionId: optimistic.id,
          })
        ),
        catchError((error: MADError) =>
          of(
            configActions.deleteDeliveryAddonTranslationError({
              error: error.message,
              transactionId: optimistic.id,
            })
          ).pipe(
            tap(() => {
              notificationService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

export const configEpics = combineEpics(
  createCategoryLabel,
  createDeliveryAddon,
  updateDeliveryAddon,
  deleteDeliveryAddon,
  adjustDeliveryAddonsOrder,
  upsertDeliveryAddonTranslation,
  deleteDeliveryAddonTranslation,
  updateRegionProducts,
  createDraft,
  deleteDraft,
  promoteDraft,
  getDraftSite,
  updateCutoffTimes,
  updateCarrierCutoff,
  updateRegionDetails,
  createCategory,
  updateCategoryDetails,
  getChangesList,
  updateCategoryTranslations,
  updateWarehouseDetails,
  deleteCategoryTranslations,
  deleteCategory,
  createCarrierProduct,
  deleteCarrierProduct,
  addCategoryTags,
  removeCategoryTags,
  createWarehouse,
  deleteWarehouse,
  createWarehouseCutoffTimes,
  createRegion,
  getSite,
  deleteRegion,
  updatePreselectionOrder,
  updateWidgetConfiguration,
  updateWidgetCountryConfiguration,
  createWidgetCountryConfiguration,
  createOrUpdateCutoffTimes,
  updateRegionProductFilterRules,
  updateCategoryFilterRules,
  setWarehouseShippingDateAdjustment,
  getPrivateKey,
  changeCategoryLabelsOrder,
  deleteCategoryLabel,
  updateCategoryLabelsDetails,
  updateCategoryLabelsTranslations,
  deleteCategoryLabelsTranslations,
  deleteWarehouseCutoffTimes
);
