import * as React from 'react';
import { MerchantSiteForm } from '.';
import { Modal } from '../../../controls';
import { LABELS } from '../../../dictionaries';

interface Props {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  merchantsOptions: { value: string; label: string }[];
  selectedMerchantId: string | null;
  onChange: (id: string) => void;
  sitesOptions: { value: string; label: string }[];
  selectedSiteId: string | null;
  onFormSubmit: ({ merchantId, siteId }: { merchantId: string; siteId: string }) => void;
  isFetching: boolean;
}

export const SiteMerchantChangeModal: React.StatelessComponent<Props> = ({
  visible,
  onCancel,
  onOk,
  merchantsOptions,
  selectedMerchantId,
  onChange,
  sitesOptions,
  selectedSiteId,
  onFormSubmit,
  isFetching,
}) => (
  <Modal
    title={LABELS.SELECT_SITE_MERCHANT_MODAL}
    visible={visible}
    closable={true}
    onCancel={onCancel}
    onOk={onOk}
    footer={null}
    width="560px"
  >
    <MerchantSiteForm
      merchantsOptions={merchantsOptions}
      isFetching={isFetching}
      selectedMerchantId={selectedMerchantId}
      sitesOptions={sitesOptions}
      onMerchantChange={onChange}
      selectedSiteId={selectedSiteId}
      onSubmit={onFormSubmit}
      onCancel={onCancel}
    />
  </Modal>
);
