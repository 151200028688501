import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import { reverse } from 'ramda';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { DeliveriesHistoryTimeline, ShipmentStatusTag } from '.';
import { LabelDownload } from '../../../components';
import { Badge, Card, Col } from '../../../controls';
import { LABELS } from '../../../dictionaries';
import { DeliveryEventModel, DeliveryStatus, DimensionsModel, ParcelModel } from '../../../models';
import { defaultTheme } from '../../../styles';
import { getLabel } from '../helpers';

const styles = {
  card: tss({
    marginBottom: defaultTheme.lineHeight,
  }),
  row: tss({
    marginTop: defaultTheme.lineHeight,
  }),
  icon: tss({ marginRight: '.5em', cursor: 'pointer', fontSize: '16px' }),
};

interface OwnProps {
  parcel: ParcelModel;
  deliveriesHistory: DeliveryEventModel[];
  status: DeliveryStatus;
}

interface State {
  deliveryReverseOrder: boolean;
}

export class TrackAndTraceModalContent extends React.Component<OwnProps, State> {
  state = { deliveryReverseOrder: true };

  renderLabel = () => {
    const { parcel } = this.props;
    const documentModel = getLabel(parcel);
    return (documentModel && <LabelDownload record={documentModel} />) || <Badge.Empty />;
  };

  renderStatus = () => <ShipmentStatusTag status={this.props.status} />;

  changeSortingOrder = () => {
    this.setState(prevState => ({ deliveryReverseOrder: !prevState.deliveryReverseOrder }));
  };

  renderDimensions = (dimensions: DimensionsModel) => {
    return (
      <span>
        {dimensions.height}x{dimensions.width}x{dimensions.length}
      </span>
    );
  };

  render() {
    const { deliveriesHistory, parcel } = this.props;
    const { deliveryReverseOrder } = this.state;
    const { renderLabel, renderStatus, renderDimensions, changeSortingOrder } = this;

    return (
      <Row gutter={16}>
        <Col span={18}>
          <Row>
            <Card title="General" className={styles.card}>
              <Row gutter={8}>
                <Col span={12}>
                  <div>{LABELS.STATUS}:</div>
                  <div>{renderStatus()}</div>
                </Col>
                <Col span={12}>
                  <div>{renderLabel()}</div>
                </Col>
              </Row>
              <Row gutter={8} className={styles.row}>
                <Col span={12}>
                  <div>{LABELS.DIMENSIONS}:</div>
                  <div>{parcel.dimensions ? renderDimensions(parcel.dimensions) : '-'}</div>
                </Col>
                <Col span={12}>
                  <div>{LABELS.WEIGHT}:</div>
                  <div>{parcel.weight || '-'}</div>
                </Col>
              </Row>
            </Card>
          </Row>
        </Col>
        <Col span={6}>
          <Card
            title="Timeline"
            className={styles.card}
            extra={
              <>
                {deliveryReverseOrder ? (
                  <CaretUpOutlined className={styles.icon} onClick={changeSortingOrder} />
                ) : (
                  <CaretDownOutlined className={styles.icon} onClick={changeSortingOrder} />
                )}
                {deliveryReverseOrder ? LABELS.NEWEST : LABELS.OLDEST}
              </>
            }
          >
            <DeliveriesHistoryTimeline
              deliveryHistory={
                deliveryReverseOrder ? reverse(deliveriesHistory) : deliveriesHistory
              }
            />
          </Card>
        </Col>
      </Row>
    );
  }
}
