import * as addressBookService from './address-book-service';
import * as blobsService from './blobs-service';
import * as configDraftService from './config-draft-service';
import * as configService from './config-service';
import * as cookiesService from './cookies-service';
import * as cosEventsService from './cos-events-service';
import * as dictionariesService from './dictionaries-service';
import * as envService from './env-service';
import * as errorsLoggingService from './errors-logging-service';
import * as eventTrackingService from './event-tracking-service';
import * as experimentService from './experiment-service';
import * as gentokenService from './gentoken-service';
import * as labelMergerService from './label-merger-service';
import * as localStorageService from './local-storage-service';
import * as localesService from './locales-service';
import * as loggingService from './logging-service';
import { messageService } from './message-service';
import * as notificationService from './notification-service';
import * as receiptService from './receipt-service';
import * as reportsService from './reports-service';
import * as selfCareService from './selfcare-service';
import * as shipmentNotificationsService from './shipment-notifications-service';
import * as siwService from './siw-service';
import * as somSearchService from './som-search-service';
import * as somService from './som-service';
import * as tokenAuthService from './tokenauth-service';
import * as tosService from './tos-service';
import * as userProfileService from './userprofile-service';
import * as usersService from './users-service';
import * as wimoService from './wimo-service';
import * as trackingNumberService from './tracking-numbers-service';
import * as trackingEventsService from './tracking-events-service';
import * as demoService from './demo-service';
import * as moiraiService from './moirai-service';
import * as posthogService from './posthog-service';

export const services = {
  addressBookService,
  blobsService,
  configDraftService,
  configService,
  cookiesService,
  cosEventsService,
  dictionariesService,
  envService,
  errorsLoggingService,
  eventTrackingService,
  experimentService,
  gentokenService,
  labelMergerService,
  localStorageService,
  localesService,
  loggingService,
  messageService,
  notificationService,
  reportsService,
  selfCareService,
  shipmentNotificationsService,
  siwService,
  somSearchService,
  somService,
  tokenAuthService,
  tosService,
  userProfileService,
  usersService,
  wimoService,
  receiptService,
  trackingNumberService,
  trackingEventsService,
  demoService,
  moiraiService,
  posthogService,
};

export type Services = typeof services;
