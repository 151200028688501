import { notification } from 'antd';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { EmptyState, LinkStyleButton } from '@src/components';
import { useFormatMessage } from '@src/i18n';
import { routerActions } from '@src/modules/router';
import { getHasAccessTo } from '@src/modules/auth/auth-selectors';
import { listSuggestedCarrierProductMappings } from '@src/services/selfcare-service';
import { useSiteId } from '@src/utils/hooks';
import { Dashboard, EnabledDashboards, useEnabledDashboards } from './dashboard';
import { MESSAGES } from '@src/dictionaries';

const EmptyContent = () => <EmptyState title={''} message={MESSAGES.SELECT_MAIN_MENU_SECTION} />;

const findLandingDashboard = (dashboards: EnabledDashboards) =>
  // Get the first enabled dashboard. The order is crucial here.
  (['checkout', 'tracking', 'transport_administration'] as const).find(type => dashboards[type]);

export const HomeContainer: React.FC = () => {
  const siteId = useSiteId();
  useCheckForUnmappedCarrierProductMappings(siteId);

  const enabledDashboards = useEnabledDashboards();
  const landingPageDashbord = findLandingDashboard(enabledDashboards);

  if (!landingPageDashbord) {
    return <EmptyContent />;
  }

  return <Dashboard type={landingPageDashbord} fallback={<EmptyContent />} />;
};

const useCheckForUnmappedCarrierProductMappings = (siteId: string) => {
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const hasAccessTo = useSelector(getHasAccessTo);
  const notificationKey = v4();
  React.useEffect(() => {
    if (!hasAccessTo('tracking-feature:view') || !siteId) {
      return;
    }
    listSuggestedCarrierProductMappings(siteId).then(data => {
      if (
        data?.automapped_carrier_product_refs?.length ||
        data?.unmapped_carrier_product_refs?.length
      ) {
        notification.info({
          key: notificationKey,
          message: formatMessage('SOME_PRODUCTS_NOT_MAPPED'),
          description: (
            <div>
              <LinkStyleButton
                showIcon={false}
                onClick={() => {
                  notification.close(notificationKey);
                  dispatch(routerActions.push({ name: 'PRODUCT_MAPPINGS' }));
                }}
              >
                {formatMessage('SET_PRODUCT_MAPPINGS')}
              </LinkStyleButton>
            </div>
          ),
          placement: 'topRight',
          duration: 0,
        });
      }
    });
  }, [siteId]);
};
