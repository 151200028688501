import { isNil } from 'ramda';

export class ConfigDraftDTO {
  site_id: string;
  master: {
    site_id: string;
    updated_at: string;
    version: number;
  };
  is_synced?: boolean;
  version: number;
}

export class ConfigDraftModel {
  siteId: string;
  master: {
    siteId: string;
    updatedAt: string;
    version: number;
  };
  isSynced: boolean;
  version: number;

  static createFromDTO(dto: ConfigDraftDTO): ConfigDraftModel {
    return {
      siteId: dto.site_id,
      master: {
        siteId: dto.master.site_id,
        updatedAt: dto.master.updated_at,
        version: dto.master.version,
      },
      isSynced: isNil(dto.is_synced) ? false : true,
      version: dto.version,
    };
  }
}
