import { EllipsisOutlined } from '@ant-design/icons';
import { FormattedMessage } from '@src/i18n';
import { Dropdown, Menu } from 'antd';
import * as React from 'react';
import { style as tss } from 'typestyle';

interface WarehouseCutoffTimesSelectProps {
  onAddCutoff: () => void;
  onRemoveCutoff?: () => void;
  onCopyTimeForCutoffTimes?: (copy: string) => void;
}

export class WarehouseCutoffTimesSelect extends React.Component<
  WarehouseCutoffTimesSelectProps,
  {}
> {
  render() {
    const { onRemoveCutoff, onAddCutoff, onCopyTimeForCutoffTimes } = this.props;
    const addCutoffMenuItem = (
      <Menu.Item onClick={onAddCutoff} key="1">
        <FormattedMessage id="ADD_NEW" />
      </Menu.Item>
    );
    const menu =
      onRemoveCutoff && onCopyTimeForCutoffTimes ? (
        <Menu className={styles.dropMenu}>
          {addCutoffMenuItem}
          <Menu.Item onClick={onRemoveCutoff} key="2">
            <FormattedMessage id="REMOVE_CUTOFF_TIME" />
          </Menu.Item>
          <Menu.Item onClick={() => onCopyTimeForCutoffTimes('existingDays')} key="3">
            <FormattedMessage id="COPY_FOR_EXISTING_CUTOFF_TIMES" />
          </Menu.Item>
          <Menu.Item onClick={() => onCopyTimeForCutoffTimes('weekWithoutWeekend')} key="4">
            <FormattedMessage id="COPY_CUTOFF_MON_FRI" />
          </Menu.Item>
          <Menu.Item onClick={() => onCopyTimeForCutoffTimes('fullWeek')} key="5">
            <FormattedMessage id="COPY_CUTOFF_MON_SUN" />
          </Menu.Item>
        </Menu>
      ) : (
        <Menu>{addCutoffMenuItem}</Menu>
      );
    return (
      <Dropdown className={styles.wrapper} overlay={menu}>
        <EllipsisOutlined className={styles.icon} />
      </Dropdown>
    );
  }
}

const styles = {
  wrapper: tss({
    cursor: 'pointer',
  }),
  icon: tss({
    paddingTop: '10px',
    paddingBottom: '10px',
  }),
  dropMenu: tss({
    borderRadius: '6px',
  }),
};
