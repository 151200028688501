import { customsDeclarationUpsert, getShipment } from '@src/services/som-service';
import { MADError } from '@src/utils';
import { useMasterSiteId } from '@src/utils/hooks';
import { notification } from 'antd';
import { mergeRight } from 'ramda';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FormValues, GeneralCustomsDeclaration } from './types';
import { mapToCustomsDeclaration } from './utils';

export const useShipment = (id: string) => {
  const siteId = useMasterSiteId();

  return useQuery({
    queryKey: ['get.shipment', siteId, id],
    queryFn: () => getShipment(siteId, id),
  });
};

export const useShipmentCustomsDeclaration = (id: string) => {
  const siteId = useMasterSiteId();

  return useQuery({
    queryKey: ['get.shipment', siteId, id],
    queryFn: () => getShipment(siteId, id),
    select: ({ customs }) => customs,
  });
};

type UseUpsertCustomsDeclarationParams = {
  formValues: FormValues;
  unmodifiedCustomsDeclaration: GeneralCustomsDeclaration;
  shipmentId: string;
};

export const useUpsertCustomsDeclaration = () => {
  const siteId = useMasterSiteId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['upsert.shipment', siteId],
    mutationFn: ({
      unmodifiedCustomsDeclaration,
      formValues,
      shipmentId,
    }: UseUpsertCustomsDeclarationParams) => {
      const mappedFormValues = mapToCustomsDeclaration(formValues);

      const upsertDeclaration = mergeRight(unmodifiedCustomsDeclaration, mappedFormValues);

      return customsDeclarationUpsert(upsertDeclaration, siteId, shipmentId);
    },
    onSuccess: () => queryClient.invalidateQueries('get.shipment'),

    onError: (error: MADError) =>
      notification.error({
        message: 'Error!',
        description: error.message,
        placement: 'topRight',
        duration: 15,
      }),
  });
};
