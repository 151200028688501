import { UserOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';

import { ListFixedHeader } from '@src/components';
import EmptyState from '@src/components/empty-state';
import ErrorState from '@src/components/error-state';
import { Link } from '@src/components/link';
import { Button } from '@src/controls';
import { LABELS } from '@src/dictionaries';
import { RootState } from '@src/modules';
import { merchantsActions } from '@src/modules/merchants';
import { usersActions, usersSelectors } from '@src/modules/users';
import { UsersTable } from './components';
import { selectedUsersTableInfo } from './components/helpers/helpers';

const mapStateToProps = (state: RootState) => ({
  error: state.users.error,
  usersTableItems: usersSelectors.getUsersTableInfo(state),
});

const mapDispatchToProps = {
  fetchMerchants: merchantsActions.listMerchants,
  fetchListUsers: usersActions.fetchListUsers,
  fetchDeleteUser: usersActions.fetchDeleteUser,
};

type State = {
  searchQuery: string;
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class Component extends React.Component<Props, State> {
  state = {
    searchQuery: '',
  };

  componentDidMount() {
    const { fetchListUsers, fetchMerchants } = this.props;
    fetchListUsers();
    fetchMerchants();
  }

  deleteUserHandler = (userId: string) => {
    this.props.fetchDeleteUser(userId);
  };

  handleSearch = (searchQuery: string) => {
    this.setState({
      searchQuery,
    });
  };

  render() {
    const { error, usersTableItems } = this.props;
    const { searchQuery } = this.state;
    if (error) {
      return <ErrorState message={<p>{error}</p>} />;
    }
    return (
      <div>
        <ListFixedHeader
          onSearchItems={this.handleSearch}
          title={LABELS.USERS_MANAGEMENT}
          IconComponent={UserOutlined}
          searchPlaceholder={LABELS.USERS_SEARCH_PLACEHOLDER}
          actions={
            <Link route={{ name: 'USERS_CREATE' }}>
              <Button type="primary">{LABELS.CREATE_USER}</Button>
            </Link>
          }
        />
        <Card bordered={false}>
          {usersTableItems.length > 0 ? (
            <UsersTable
              onRecordDelete={this.deleteUserHandler}
              usersTableItems={selectedUsersTableInfo(searchQuery, usersTableItems)}
            />
          ) : (
            <EmptyState />
          )}
        </Card>
      </div>
    );
  }
}

export const UsersListContainer = connect(mapStateToProps, mapDispatchToProps)(Component);
