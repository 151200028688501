import { FieldState, FormState } from 'formstate';
import { observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { DatePicker } from '@src/controls';
import { withFieldStateDatePicker, withFormItem } from '../../../decorators';

const DatePickerField = withFormItem(withFieldStateDatePicker(DatePicker));

export class DeliveryTimeFormState extends FormState<{
  start: FieldState<moment.Moment | undefined>;
  end: FieldState<moment.Moment | undefined>;
}> {
  static create = ({
    start,
    end,
  }: { start?: string; end?: string } = {}): DeliveryTimeFormState => {
    return new FormState({
      start: new FieldState(start ? moment(start) : undefined),
      end: new FieldState(end ? moment(end) : undefined),
    }).validators($ => {
      if ($.start.$ && $.end.$) {
        return $.end.$?.isBefore($.start.$) ? 'End date is earlier than start date' : undefined;
      }
      if ((!$.start.$ && $.end.$) || ($.start.$ && !$.end.$)) {
        return 'Both start and end values must be present';
      }
      return;
    });
  };
}

interface OwnProps {
  formState: DeliveryTimeFormState;
  disabled?: boolean;
}

@observer
export class DeliveryTime extends React.Component<OwnProps> {
  render() {
    const { start, end } = this.props.formState.$;

    return (
      <div className={styles.wrapper}>
        <div className={styles.spacing}>
          <DatePickerField
            fieldState={start}
            label="Start"
            showTime={{ format: 'HH:mm' }}
            format="DD.MM.YYYY HH:mm"
            disabled={this.props.disabled}
            error={this.props.formState.error}
            onOpenChange={open =>
              !open && this.props.formState.error && this.props.formState.validate()
            }
            // Apparently validation is run before the value changes, timeout somewhat forces validation to be run last
            onErase={() => window.setTimeout(() => this.props.formState.validate(), 0)}
            labelCol={{ span: 24 }}
          />
        </div>
        <DatePickerField
          fieldState={end}
          label="End"
          showTime={{ format: 'HH:mm' }}
          format="DD.MM.YYYY HH:mm"
          disabled={this.props.disabled}
          error={this.props.formState.error}
          onOpenChange={open =>
            !open && this.props.formState.error && this.props.formState.validate()
          }
          // Apparently validation is run before the value changes, timeout somewhat forces validation to be run last
          onErase={() => window.setTimeout(() => this.props.formState.validate(), 0)}
          labelCol={{ span: 24 }}
        />
      </div>
    );
  }
}

const styles = stylesheet({
  wrapper: {
    display: 'flex',
  },
  spacing: {
    marginRight: '35px',
  },
});
