export type DocumentModel = {
  id: string;
  type: string;
  fileType: string;
  createdAt: string;
  parcelId: string;
  url: string;
  carrier: string;
};

export type TokenClaims = {
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  name: string;
  nickname?: string;
  picture?: string;
  sub?: string;
};

export enum AuthenticationType {
  PASSWORD = 'PASSWORD',
  OTHER = 'OTHER',
}

abstract class UserModel {
  name?: string;
  email?: string;
  authenticationType?: AuthenticationType;
  nickname?: string;
  picture?: string;
  password?: string;
  externalId?: string;
  merchantId?: string;

  static createFromDTO(dto: UserDTO): UserModel {
    return {
      name: dto.name,
      email: dto.email,
      authenticationType: dto.authentication_type,
      nickname: dto.nickname,
      picture: dto.picture,
      password: dto.password,
      externalId: dto.external_id,
      merchantId: dto.merchant_id,
    };
  }
}

abstract class UserDTO {
  name?: string;
  email?: string;
  authentication_type?: AuthenticationType;
  nickname?: string;
  picture?: string;
  password?: string;
  external_id?: string;
  merchant_id?: string;

  static createFromModel(model: UserModel): UserDTO {
    return {
      name: model.name,
      email: model.email,
      authentication_type: model.authenticationType,
      nickname: model.nickname,
      picture: model.picture,
      password: model.password,
      external_id: model.externalId,
      merchant_id: model.merchantId,
    };
  }
}

export class UserGetDTO extends UserDTO {
  roles?: RoleDTO[];
  groups?: RBACGroupDTO[];
  id: string;
  super_user?: boolean;

  static createFromModel(model: UserGetModel): UserGetDTO {
    return {
      ...super.createFromModel(model),
      id: model.id,
      roles: model.roles && model.roles.map(role => RoleDTO.createFromModel(role)),
      groups: model.groups && model.groups.map(group => RBACGroupDTO.createFromModel(group)),
      super_user: model.superUser,
    };
  }
}

export class UserCreateModel extends UserModel {
  id?: string;

  static createFromDTO(dto: UserCreateDTO): UserCreateModel {
    return {
      ...super.createFromDTO(dto),
      id: dto.id,
    };
  }
}
export class UserCreateDTO extends UserDTO {
  id?: string;
  static createFromModel(model: UserCreateModel): UserCreateDTO {
    return {
      ...super.createFromModel(model),
      id: model.id,
    };
  }
}

export class UserGetModel extends UserModel {
  roles?: RoleModel[];
  groups?: RBACGroupModel[];
  id: string;
  superUser?: boolean;

  static createFromDTO(dto: UserGetDTO): UserGetModel {
    return {
      ...super.createFromDTO(dto),
      id: dto.id,
      roles: dto.roles && dto.roles.map(role => RoleModel.createFromDTO(role)),
      groups: dto.groups && dto.groups.map(group => RBACGroupModel.createFromDTO(group)),
      superUser: dto.super_user,
    };
  }

  static filterOutBaseGroup(groups: RBACGroupModel[]) {
    return groups.filter(group => group.name !== 'base');
  }

  static getUserGroup(groups?: RBACGroupModel[]) {
    if (groups) {
      const filteredGroups = this.filterOutBaseGroup(groups);
      if (filteredGroups.length === 0) {
        return { name: 'base', id: '0' };
      } else {
        return filteredGroups[0];
      }
    } else {
      return { name: ' - ', id: '0' };
    }
  }
}

export class UserGroupDTO {
  merchant_id?: string;
  site_ids?: string[];

  static createFromModel(model: UserGroupModel): UserGroupDTO {
    return {
      merchant_id: model.merchantId,
      site_ids: model.siteIds,
    };
  }
}

export class UserGroupModel {
  merchantId?: string;
  siteIds?: string[];

  static createFromDTO(dto: UserGroupDTO): UserGroupModel {
    return {
      merchantId: dto.merchant_id,
      siteIds: dto.site_ids,
    };
  }
}

export class AdminGroupDTO {
  merchant_id?: string;

  static createFromModel(model: AdminGroupModel): AdminGroupDTO {
    return {
      merchant_id: model.merchantId,
    };
  }
}

export class AdminGroupModel {
  merchantId?: string;

  static createFromDTO(dto: AdminGroupDTO): AdminGroupModel {
    return {
      merchantId: dto.merchant_id,
    };
  }
}

export class ConfigEditorGroupModel {
  merchantId?: string;
  siteIds?: string[];

  static createFromDTO(dto: ConfigEditorGroupDTO): ConfigEditorGroupModel {
    return {
      merchantId: dto.merchant_id,
      siteIds: dto.site_ids,
    };
  }
}

export class ConfigEditorGroupDTO {
  merchant_id?: string;
  site_ids?: string[];

  static createFromModel(model: ConfigEditorGroupModel): ConfigEditorGroupDTO {
    return {
      merchant_id: model.merchantId,
      site_ids: model.siteIds,
    };
  }
}

export class RoleModel {
  id: string;
  name: string;

  static createFromDTO(dto: RoleDTO): RoleModel {
    return {
      id: dto.id,
      name: dto.name,
    };
  }
}

export class RoleDTO {
  id: string;
  name: string;

  static createFromModel(model: RoleModel): RoleDTO {
    return {
      id: model.id,
      name: model.name,
    };
  }
}

export class RBACGroupDTO {
  id: string;
  name: string;
  scope: {
    id: string;
    type: ScopeType;
  };
  roles?: RoleModel[];
  static createFromModel(model: RBACGroupModel): RBACGroupDTO {
    return {
      id: model.id,
      name: model.name,
      scope: model.scope,
      roles: model.roles && model.roles.map(role => RoleModel.createFromDTO(role)),
    };
  }
}

export class RBACGroupModel {
  id: string;
  name: string;
  scope: {
    id: string;
    type: ScopeType;
  };
  roles?: RoleDTO[];

  static createFromDTO(dto: RBACGroupDTO): RBACGroupModel {
    return {
      id: dto.id,
      name: dto.name,
      scope: dto.scope,
      roles: dto.roles && dto.roles.map(role => RoleDTO.createFromModel(role)),
    };
  }
}

export enum ScopeType {
  SITE = 'SITE',
  MERCHANT = 'MERCHANT',
  UNKNOWN = 'UNKNOWN',
}

export class CreateGroupDTO {
  name: string;
  scope: {
    id: string;
    type: ScopeType;
  };
  role_ids: string[];

  static createFromModel(model: CreateGroupModel): CreateGroupDTO {
    return {
      name: model.name,
      scope: model.scope,
      role_ids: model.roleIds,
    };
  }
}

export class CreateGroupModel {
  name: string;
  scope: {
    id: string;
    type: ScopeType;
  };
  roleIds: string[];
}

export class UpdateGroupDTO {
  id: string;
  name: string;
  role_ids: string[];

  static createFromModel(model: UpdateGroupModel): UpdateGroupDTO {
    return {
      id: model.id,
      name: model.name,
      role_ids: model.roleIds,
    };
  }
}

export class UpdateGroupModel {
  id: string;
  name: string;
  roleIds: string[];
}

export class UserSummaryDTO {
  id: string;
  email?: string;
  is_superuser?: boolean;

  static createFromModel(model: UserSummaryModel): UserSummaryDTO {
    return {
      id: model.id,
      email: model.email,
      is_superuser: model.isSuperuser,
    };
  }
}
export class UserSummaryModel {
  id: string;
  email?: string;
  isSuperuser?: boolean;

  static createFromDTO(dto: UserSummaryDTO): UserSummaryModel {
    return {
      id: dto.id,
      email: dto.email,
      isSuperuser: dto.is_superuser,
    };
  }
}
