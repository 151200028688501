import { combineEpics, Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  concat,
  delay,
  filter,
  ignoreElements,
  map,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { RootAction, RootState, Services } from '../../modules';
// import * as UsersService from '../../services/users-service';
import { MINUTE } from '../../utils/date';
import { authActions } from '../auth';

const setSessionActive: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { cookiesService }
) =>
  action$.pipe(
    filter(isActionOf([authActions.authenticateSuccess])),
    tap(() => cookiesService.setSessionActive()),
    ignoreElements()
  );

const getAuthUser: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { usersService, errorsLoggingService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf([authActions.fetchAuthUser, authActions.authenticateSuccess])),
    switchMap(action =>
      from(usersService.getWithToken(action.payload)).pipe(
        map(res => authActions.fetchAuthUserSuccess(res)),
        tap(userSuccessAction => errorsLoggingService.setUserContext(userSuccessAction.payload)),
        catchError(err => {
          loggingService.logError(err);
          return of(authActions.fetchAuthUserError(err.message));
        })
      )
    )
  );

const INACTIVE_LIMIT = 20 * MINUTE;
const hasExceedInactiveLimit = (timestamp: number) => {
  const limit = Date.now() - INACTIVE_LIMIT;
  return timestamp < limit;
};

const updateLastActivityOrLogout: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { errorsLoggingService }
) =>
  action$.pipe(
    throttleTime(2000),
    switchMap(() => {
      const lastActive = state$.value.auth.lastActiveDate || Date.now();
      if (hasExceedInactiveLimit(lastActive)) {
        return of(authActions.logout()).pipe(tap(() => errorsLoggingService.clearUserContext()));
      }
      return of(authActions.updateLastActivity(Date.now())).pipe(
        concat(
          of(authActions.logout()).pipe(
            delay(INACTIVE_LIMIT),
            tap(() => errorsLoggingService.clearUserContext())
          )
        )
      );
    })
  );

export const authEpics = combineEpics(getAuthUser, updateLastActivityOrLogout, setSessionActive);
