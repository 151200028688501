import { mergeRight } from 'ramda';
import {
  AvailableCarrierProductList,
  ListTrackingRecordsResponse,
  TrackingRecordFiltering,
  TrackingRecordsTotalCount,
} from '@src/containers/tracking-numbers/types';
import { apiUtils, authFetch, MADError } from '../utils';
import { allTrackingStatuses } from '@src/models';

import { logError } from './logging-service';
import { difference, map, uniq } from 'lodash';

const mapSortingDirections = (direction: 'ascend' | 'descend') =>
  direction === 'ascend' ? 'ASCENDING' : 'DESCENDING';

const URL = apiUtils.getApiUrl() + '/frontend/tracking_records_search';

type FetchTrackingNumbersParams = {
  pagination: {
    search_after?: string;
    search_before?: string;
    limit: number;
  };
  filtering?: TrackingRecordFiltering;
  order_by_direction: 'ascend' | 'descend';
  siteId: string;
};

const fetchTrackingRecords = (options: RequestInit) =>
  authFetch<ListTrackingRecordsResponse>(`${URL}/tracking_records`, options).then(
    detectUnknownStatusMiddleware
  );

const detectUnknownStatusMiddleware = (data: ListTrackingRecordsResponse) => {
  const invalidStatuses = difference(
    uniq(map(data.tracking_records, record => record.latest_tracking_status)),
    allTrackingStatuses
  );

  invalidStatuses.forEach(status => {
    logError(new MADError(`Unknown tracking status: "${status}"`));
  });

  return data;
};

export const fetchTrackingNumber = async (trackingNumber: string, siteId: string) => {
  const params = {
    pagination: {
      offset: 0,
      limit: 1,
    },
    filtering: {
      id: trackingNumber,
    },
    order_by_direction: 'DESCENDING',
    siteId,
  };

  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify(params),
  };

  return fetchTrackingRecords(options);
};

export const fetchByExternalIdOrTrackingNumber = async (
  id: string,
  siteId: string,
  unlimited = false
) => {
  const limit = unlimited ? {} : { limit: 1 };

  const params = {
    pagination: mergeRight({ offset: 0 }, limit),
    filtering: {
      id,
    },
    order_by_direction: 'DESCENDING',
    siteId,
  };

  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify(params),
  };

  return fetchTrackingRecords(options);
};

export const fetchTrackingNumbers = async (params: FetchTrackingNumbersParams) => {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify({
      ...params,
      order_by_direction: mapSortingDirections(params.order_by_direction),
    }),
  };

  return fetchTrackingRecords(options);
};

export const fetchAvailableCarrierProducts = async (siteId: string) => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };
  return await authFetch<AvailableCarrierProductList>(
    `${URL}/filtering_options?site_id=${siteId}`,
    options
  );
};

export const fetchTrackingRecordsCount = async (
  filtering: Omit<TrackingRecordFiltering, 'id'>,
  siteId: string
) => {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify({
      filtering,
      siteId,
    }),
  };

  return await authFetch<TrackingRecordsTotalCount>(`${URL}/tracking_records_count`, options);
};
