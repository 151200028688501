import { TokenAuthDTO, TokenAuthModel } from '../models';
import { apiUtils, authFetch } from '../utils';

const TOKENAUTH_URL = apiUtils.getApiUrl() + '/tokenauth';

export const getTokenFor = async (
  resource: string,
  siteId: string,
  sitePrivateKey: string
): Promise<TokenAuthModel> => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
      Authorization: `Bearer ${btoa(sitePrivateKey)}`,
    },
    body: JSON.stringify({ site_id: siteId, resource }),
  };

  return authFetch<TokenAuthDTO>(`${TOKENAUTH_URL}/token.ensure`, options).then(res =>
    TokenAuthModel.createFromDTO(res)
  );
};

export const getAuthToken = async (siteId: string): Promise<TokenAuthModel> => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ site_id: siteId }),
  };

  return authFetch<TokenAuthDTO>(`${apiUtils.getApiUrl()}/auth_http/token`, options);
};

export const getFaqAuthToken = async (siteId: string): Promise<TokenAuthModel> => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ site_id: siteId }),
  };

  return authFetch<TokenAuthDTO>(`${apiUtils.getApiUrl()}/auth_http_faq/token`, options);
};

export const getProductPageAuthToken = async (siteId: string): Promise<TokenAuthModel> => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ site_id: siteId }),
  };

  return authFetch<TokenAuthDTO>(`${apiUtils.getApiUrl()}/auth_http_product_page/token`, options);
};
