import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { useSiteId } from '@src/utils/hooks';
import { FormattedMessage } from '@src/i18n';
import { services } from '@src/services';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { useMutationOnDraft } from '@src/utils/queries';
import { FeatureFlags as FaqWidgetFeatures } from '@src/api/faq-widget';

import { BreadcrumbSection, Header, FeaturesTable } from '@src/containers/faq-widget/components';
import { mapCountryNameToCode } from '@src/dictionaries/mapping';
import { stringUtils } from '@src/utils';
import { FaqWidgetDrawer } from '@src/containers/faq-widget/faq-widget-drawer';

interface FaqWidgetCountrySettingsProps {
  country: string;
}

export const FaqWidgetCountrySettings: React.FC<FaqWidgetCountrySettingsProps> = ({ country }) => {
  const siteId = useSiteId();
  const queryClient = useQueryClient();

  const faqWidgetFeatures = useQuery(
    ['site', siteId],
    () => services.configService.getSite(siteId),
    {
      enabled: !!siteId,
      staleTime: Infinity,
      select: data => data?.faqWidgetConfiguration,
      keepPreviousData: true,
    }
  );

  const countryCode = mapCountryNameToCode(country);
  const countryFeatures = faqWidgetFeatures.data?.by_country?.[countryCode]?.features;

  const onSubmitDraft = useMutationOnDraft<FaqWidgetFeatures, unknown>(
    (siteId, payload) =>
      services.selfCareService.upsertFaqWidgetCountryConfiguration(siteId, countryCode, {
        features: payload,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(['site']),
    }
  );

  const countryName = stringUtils.capitalize(country);

  return (
    <>
      <StatusBarContainer />
      <BreadcrumbSection>{countryName}</BreadcrumbSection>
      <Header>
        <FormattedMessage id="COUNTRY" />
      </Header>
      <FeaturesTable
        onSubmit={onSubmitDraft.mutate}
        loading={faqWidgetFeatures.isFetching || onSubmitDraft.isLoading}
        values={countryFeatures}
      />
      <FaqWidgetDrawer />
    </>
  );
};
