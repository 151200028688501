import { Form, FormItemProps } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from '@src/controls';
import * as React from 'react';

const FormItem = Form.Item;

export type WithFormItemProps = FormItemProps & {
  error?: string | null;
  labelClassName?: string;
  labelTooltip?: React.ReactNode;
};

export function withFormItem<
  WrappedComponentProps extends object,
  D extends FormItemProps = FormItemProps
>(WrappedComponent: React.ComponentType<WrappedComponentProps>, defaultProps?: D) {
  const HOC: React.FunctionComponent<WithFormItemProps & WrappedComponentProps> = props => {
    const {
      label,
      labelCol,
      wrapperCol,
      required,
      help,
      validateStatus,
      colon,
      error,
      labelClassName,
      labelTooltip,
      labelAlign,
      ...passThroughProps
    } = props;

    const formItemProps: FormItemProps = Object.entries({
      label,
      labelCol,
      wrapperCol,
      required,
      help,
      validateStatus,
      colon,
      labelAlign,
    }).reduce((definedProps: any, [key, value]) => {
      if (value !== undefined) {
        definedProps[key] = value;
      }
      return definedProps;
    }, {});

    if (error) {
      formItemProps.help = error;
      formItemProps.validateStatus = 'error';
    }

    return (
      <FormItem
        {...defaultProps}
        {...formItemProps}
        hasFeedback={true}
        className={labelClassName}
        label={
          labelTooltip ? (
            <>
              {label}
              <Tooltip title={labelTooltip} overlayStyle={{ maxWidth: '500px' }}>
                <InfoCircleOutlined
                  style={{ fontSize: '14px', marginLeft: '5px', color: 'rgba(0, 0, 0, 0.5)' }}
                />
              </Tooltip>
            </>
          ) : (
            label
          )
        }
      >
        <WrappedComponent {...(passThroughProps as WrappedComponentProps)} />
      </FormItem>
    );
  };

  HOC.displayName = `WithFormItem(${WrappedComponent})`;
  return HOC;
}
