import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;

import { UserGetModel } from '../../models';

const LOGIN = '@auth/LOGIN';
const LOGOUT = '@auth/LOGOUT';
const UPDATE_LAST_ACTIVITY = '@auth/UPDATE_LAST_ACTIVITY';

const AUTHENTICATE = '@auth/AUTHENTICATE';
const AUTHENTICATE_SUCCESS = '@auth/AUTHENTICATE_SUCCESS';
const AUTHENTICATE_ERROR = '@auth/AUTHENTICATE_ERROR';
const PASSWORD_RESET = '@auth/PASSWORD_RESET';
const PASSWORD_RESET_SUCCESS = '@auth/PASSWORD_RESET_SUCCESS';
const PASSWORD_RESET_ERROR = '@auth/PASSWORD_RESET_ERROR';
const FETCH_AUTH_USER = '@auth/FETCH_AUTH_USER';
const FETCH_AUTH_USER_SUCCESS = '@auth/FETCH_AUTH_USER_SUCCESS';
const FETCH_AUTH_USER_ERROR = '@auth/FETCH_AUTH_USER_ERROR';

export const login = createAction(
  LOGIN,
  resolve => (username: string, password: string) =>
    resolve({
      username,
      password,
    })
);
export const logout = createAction(LOGOUT);

export const authenticate = createAction(AUTHENTICATE);
export const authenticateSuccess = createAction(
  AUTHENTICATE_SUCCESS,
  resolve => (token: string) => resolve(token)
);
export const authenticateError = createAction(
  AUTHENTICATE_ERROR,
  resolve => (message: string) => resolve(message)
);

export const passwordReset = createAction(
  PASSWORD_RESET,
  resolve => (password: string) => resolve(password)
);
export const passwordResetSuccess = createAction(PASSWORD_RESET_SUCCESS);
export const passwordResetError = createAction(
  PASSWORD_RESET_ERROR,
  resolve => (message: string) => resolve(message)
);

export const fetchAuthUser = createAction(
  FETCH_AUTH_USER,
  resolve => (token: string) => resolve(token)
);
export const fetchAuthUserSuccess = createAction(
  FETCH_AUTH_USER_SUCCESS,
  resolve => (user: UserGetModel) => resolve(user)
);
export const fetchAuthUserError = createAction(
  FETCH_AUTH_USER_ERROR,
  resolve => (error: string) => resolve(error)
);

export const updateLastActivity = createAction(
  UPDATE_LAST_ACTIVITY,
  resolve => (date: number) => resolve(date)
);
