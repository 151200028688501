import { RootState } from '..';
import { DeliveryStatus } from '../../models/tracking-models';

export const getShipmentStatus = (state: RootState, shipmentId: string) => {
  const tmpStatus = state.somSearch.temporaryShipmentStatus;
  if (tmpStatus && tmpStatus.shipmentId === shipmentId) {
    return tmpStatus.status;
  }
  const shipmentStatus = state.somSearch.shipmentsStatuses.find(s => s.shipmentId === shipmentId);
  return (shipmentStatus && shipmentStatus.status) || 'UNKNOWN';
};

export type ShipmentStatuses = { [id: string]: DeliveryStatus };

export const getShipmentsStatuses = (state: RootState): ShipmentStatuses => {
  const shipmentsStatuses = state.somSearch.shipmentsStatuses;
  const shipmentsStatusesDict = {} as ShipmentStatuses;

  shipmentsStatuses.map(item => {
    shipmentsStatusesDict[item.shipmentId] = item.status;
  });

  return shipmentsStatusesDict;
};
