import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag/index';
import * as React from 'react';
import { style as tss } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';

interface Props extends TagProps {
  textColor?: string;
  borderColor?: string;
  nonClickable?: boolean;
}

const Component: React.StatelessComponent<Props> = props => {
  const { children, textColor, borderColor, nonClickable, ...restProps } = props;
  const className = getStyles(textColor, borderColor, nonClickable);

  return (
    <Tag className={className} {...restProps}>
      {children}
    </Tag>
  );
};

const getStyles = (
  textColor: Props['textColor'],
  borderColor: Props['borderColor'],
  nonClickable: Props['nonClickable']
) => {
  const commonStyles: NestedCSSProperties = {
    color: textColor,
    borderColor,
    height: '22px',
    whiteSpace: 'nowrap',
  };
  if (nonClickable) {
    return tss({
      ...commonStyles,
      cursor: 'default',
    });
  } else {
    return tss({ ...commonStyles });
  }
};

export default Component;
