import { Col, DatePicker, Input, Select } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import moment from 'moment';
import React, { FC, Fragment } from 'react';
import { style as tss } from 'typestyle';
import { CustomsDeclarationFormItem, FormValues, OptionsType } from './types';

const getInput = (
  id: CustomsDeclarationFormItem['id'],
  inputType: CustomsDeclarationFormItem['inputType'],
  context: FormikContextType<FormValues>,
  options?: OptionsType,
  showSearch?: boolean
) => {
  const { handleBlur, handleChange, values, setFieldValue } = context;

  switch (inputType) {
    case 'date':
      return (
        <DatePicker
          name={id}
          id={id}
          value={values[id] ? moment(values[id] as string) : undefined}
          className={styles.fullWidth}
          placeholder="Value"
          onBlur={handleBlur}
          onChange={value => setFieldValue(id, value?.toISOString() ?? undefined)}
        />
      );

    case 'select':
      return (
        <Select
          id={id}
          options={options}
          showSearch={showSearch}
          value={values[id] as string}
          className={styles.fullWidth}
          placeholder="Value"
          onBlur={handleBlur}
          onChange={value => setFieldValue(id, value)}
        />
      );

    case 'number':
      return (
        <Input
          name={id}
          id={id}
          value={values[id] as number}
          placeholder="Value"
          type="number"
          min={0}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      );

    default:
      return (
        <Input
          name={id}
          id={id}
          value={values[id] as string | undefined}
          placeholder="Value"
          onBlur={handleBlur}
          onChange={handleChange}
        />
      );
  }
};

export const FormItem: FC<CustomsDeclarationFormItem> = ({ label, id, inputType, options, showSearch }) => {
  const context = useFormikContext<FormValues>();

  const input = getInput(id, inputType, context, options, showSearch);

  return (
    <Fragment>
      <Col span={8} className={styles.labelCol}>
        <label htmlFor={id}>{label}</label>
      </Col>
      <Col span={16}>{input}</Col>
    </Fragment>
  );
};

const styles = {
  fullWidth: tss({
    width: '100%',
  }),
  labelCol: tss({
    display: 'flex',
    alignItems: 'center',
  }),
};
