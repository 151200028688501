import Auth0Lock from 'auth0-lock';

import { services } from '@src/services';
import { authActions } from './modules/auth';
import { store } from './store';
import { apiUtils } from './utils';

const env = services.envService.getEnvironmentalVariables();

const EnterpriseConnections: { [k: string]: string } = {
  '/apoteket': env.MAD_APOTEKET_ADFS,
};

export const getEnterpriseConnectionWithPathname = (pathname: string) =>
  EnterpriseConnections[pathname];

export const configureAuth = ({
  connection = 'Username-Password-Authentication',
  authDomain,
}: {
  connection?: string;
  authDomain: string;
}) => {
  const lock = new Auth0Lock(env.MAD_AUTH0_CLIENT_ID, authDomain, {
    auth: {
      redirectUrl: apiUtils.getRedirectionUrl(),
      responseType: 'id_token',
      params: {
        scope: 'openid email',
      },
    },
    theme: {
      logo: 'https://cdn.ingrid.com/static/assets/ingrid-black.svg',
      authButtons: {
        ApoteketSIT: {
          displayName: 'Log in with Apoteket account',
          icon: 'https://yt3.ggpht.com/a-/AN66SAyT0Q-r3rnq3rC3_zJPJWTz5RIp65wuhIWNqg=s288-mo-c-c0xffffffff-rj-k-no',
        },
        Apoteket: {
          displayName: 'Log in with Apoteket account',
          icon: 'https://yt3.ggpht.com/a-/AN66SAyT0Q-r3rnq3rC3_zJPJWTz5RIp65wuhIWNqg=s288-mo-c-c0xffffffff-rj-k-no',
        },
      },
      primaryColor: '#000',
    },
    languageDictionary: {
      title: 'Merchant Platform',
    },
    allowedConnections: [connection],
    allowSignUp: false,
    closable: false,
  });

  lock.on('signin submit', () => {
    store.dispatch(authActions.authenticate());
  });
  lock.on('authenticated', res => {
    store.dispatch(authActions.authenticateSuccess(res.idToken));
  });
  lock.on('authorization_error', err => {
    store.dispatch(authActions.authenticateError('Authentication Error: ' + err.errorDescription));
  });

  return lock;
};
