import { Popover } from 'antd';
import * as React from 'react';
import { style as tss } from 'typestyle';
import { useFormik } from 'formik';
import { useFormatMessage } from '@src/i18n';

import { FilteringFormOpenButton } from './filtering-form-open-button';
import { FilteringFormPopover } from './filtering-form-popover';
import { TrackingRecordFiltering } from './types';

type Props = {
  filterValues: Omit<TrackingRecordFiltering, 'id'>;
  onFilter: (newFilterValues: Omit<TrackingRecordFiltering, 'id'>) => void;
  onClearFilters: () => void;
};

export const FilteringForm: React.FC<Props> = ({ filterValues, onFilter, onClearFilters }) => {
  const formatMessage = useFormatMessage();

  const [isPopoverVisible, setIsPopoverVisible] = React.useState(false);
  const formik = useFormik<Omit<TrackingRecordFiltering, 'id'>>({
    initialValues: filterValues,
    onSubmit: newValues => {
      onFilter(newValues);
      setIsPopoverVisible(false);
    },
  });

  React.useEffect(() => {
    formik.resetForm({ values: filterValues });
  }, [filterValues]);

  return (
    <Popover
      overlayClassName={styles.popover}
      title={formatMessage('TRACKING_NUMBERS.FILTERS')}
      content={
        <FilteringFormPopover
          formik={formik}
          values={formik.values}
          setFieldValue={formik.setFieldValue}
          onApply={() => formik.submitForm()}
          onCancel={() => {
            formik.resetForm({ values: filterValues });
            setIsPopoverVisible(false);
          }}
          onClearFiters={onClearFilters}
        />
      }
      placement="bottomRight"
      visible={isPopoverVisible}
    >
      <FilteringFormOpenButton
        onClick={() => {
          setIsPopoverVisible(isVisible => {
            if (isVisible) {
              formik.resetForm({ values: filterValues });
            }
            return !isVisible;
          });
        }}
        filterValues={filterValues}
      />
    </Popover>
  );
};

const styles = {
  popover: tss({
    $nest: {
      '.ant-popover-inner-content': {
        padding: 0,
      },
    },
  }),
};
