import { message } from 'antd';

const DURATION = 3;

message.config({ duration: DURATION });

const messageWrapper = (type: keyof typeof messageService, content: string) =>
  message[type](content);

export const messageService = {
  success: (content: string) => messageWrapper('success', content),
  error: (content: string) => messageWrapper('error', content),
};
