import { ExperimentOutlined } from '@ant-design/icons';
import * as React from 'react';
import * as moment from 'moment';
import { useSelector } from 'react-redux';
import { useQuery, useMutation } from 'react-query';
import { stylesheet } from 'typestyle';
import { Button, Tabs } from 'antd';

import { SmartPopover } from '@src/controls';
import { services } from '@src/services';
import { ListFixedHeader } from '@src/components';
import { DOMAIN } from '@src/dictionaries';
import { SitesSelectors } from '@src/modules/sites';
import { CreateExperimentForm } from './create-experiment-form';
import { ExperimentList } from './experiment-list';
import { CreateExperimentModel, ExperimentModel } from '@src/models';

export const ExperimentListContainer: React.FC = () => {
  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);

  const query = useQuery<ExperimentModel[]>({
    queryKey: ['experiments', siteId],
    queryFn: async () => {
      return await services.experimentService.listExperiments(siteId);
    },
    initialData: [],
    refetchOnWindowFocus: false,
  });

  const createMutation = useMutation(
    (values: CreateExperimentModel) => {
      return services.experimentService.createExperiment(values);
    },
    {
      onSuccess: () => {
        query.refetch();
      },
      onError: () => services.notificationService.error('Failed to create experiment'),
    }
  );

  const deleteMutation = useMutation(
    (id: string) => services.experimentService.deleteExperiment(siteId, id),
    {
      onSuccess: () => {
        query.refetch();
      },
      onError: () => services.notificationService.error('Failed to delete experiment'),
    }
  );

  const loading = query.isFetching || createMutation.isLoading || deleteMutation.isLoading;

  const experiments = query.data!.sort((a, b) => {
    return moment(b.createdAt).diff(moment(a.createdAt));
  });

  const createForm = (
    <SmartPopover
      render={onClose => (
        <CreateExperimentForm
          siteId={siteId}
          onSubmit={(values: CreateExperimentModel) => {
            createMutation.mutate(values);
            onClose();
          }}
        />
      )}
    >
      <Button disabled={query.isLoading} type="primary">
        Create
      </Button>
    </SmartPopover>
  );

  return (
    <>
      <div>
        <ListFixedHeader
          title={DOMAIN.EXPERIMENTS}
          IconComponent={ExperimentOutlined}
          showSearch={false}
          actions={createForm}
        />

        <Tabs className={styles.tabs}>
          <Tabs.TabPane tab="Running" key={1}>
            <ExperimentList
              data={experiments.filter(data => data.status === 'RUNNING')}
              loading={loading}
              disableDelete
              onDelete={deleteMutation.mutate}
            />
          </Tabs.TabPane>
        </Tabs>
        <Tabs className={styles.tabs}>
          <Tabs.TabPane tab="Drafts" key={1}>
            <ExperimentList
              data={experiments.filter(data => data.status === 'CREATED')}
              loading={loading}
              onDelete={deleteMutation.mutate}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Finished" key={2}>
            <ExperimentList
              data={experiments.filter(data => data.status === 'COMPLETED')}
              loading={loading}
              onDelete={deleteMutation.mutate}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
};
const styles = stylesheet({
  tabs: {
    marginTop: '16px',
  },
  subtitle: {
    margin: '36px 0 14px',
    fontSize: '16px',
  },
  listWrapper: {
    padding: '24px',
  },
  addButton: {
    marginTop: '20px',
    display: 'inline-block',
  },
});
