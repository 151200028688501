import { Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { FormattedMessage, useFormatMessage } from '@src/i18n';
import { TrackingConfigurationStatus } from '@src/models';
import { getAvailableLocalesOptions } from '@src/modules/dictionaries/selectors';
import { useGlobalCarrierProducts } from '@src/utils';
import { useSelector } from '@src/utils/hooks';
import { Form, Input, Select as AntdSelect, Spin, Switch } from 'antd';
import * as React from 'react';
import { classes, style as tss } from 'typestyle';
import { TrackingConfigStatus } from '../components/tracking-config-status';
import { buildCarrierProductOptions } from '../form-commons';

export const LABEL_SPAN = 8;
export const CONTENT_SPAN = 16;

export const commonFormItemProps = {
  labelAlign: 'right' as const,
  colon: false,
  labelCol: {
    span: LABEL_SPAN,
  },
  wrapperCol: {
    span: CONTENT_SPAN,
  },
};

const AntdSelectField = withFormItem(AntdSelect);
const InputField = withFormItem(Input);
const SelectField = withFormItem(Select);
const SwitchField = withFormItem(Switch);

type InputFieldProps = {
  value: string | undefined;
  error: string | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
};

type SwitchFieldProps = {
  checked: boolean;
  error: string | undefined;
  onChange: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
};

type LocaleFieldProps = {
  idx: number;
  value: string | undefined;
  error: string | undefined;
  onChange: (value: string) => void;
  disabled: boolean;
  className?: string;
};

type SelectFieldProps = {
  value: string | undefined;
  error: string | undefined;
  onChange: (value: string) => void;
  className?: string;
};

export const CarrierProductField: React.FC<SelectFieldProps> = ({ className, ...restProps }) => {
  const { data: carrierProducts, isLoading } = useGlobalCarrierProducts();
  const options = buildCarrierProductOptions(carrierProducts);

  return (
    <SelectField
      disabled={isLoading}
      loading={isLoading}
      label="Carrier product"
      name="carrier_product_id"
      placeholder="Please select..."
      required={true}
      groupOptions={options}
      showSearch={true}
      filterOption={true}
      optionFilterProp={'label'}
      className={classes(tss({ width: '100%' }), className)}
      {...commonFormItemProps}
      {...restProps}
    />
  );
};

export const TrackingConfigStatusField: React.FC<{
  status?: TrackingConfigurationStatus;
  isLoading: boolean;
}> = ({ status, isLoading }) => {
  return (
    <Form.Item label={<FormattedMessage id="TRACKING_CONFIG_STATUS" />} {...commonFormItemProps}>
      {isLoading ? <Spin /> : <TrackingConfigStatus status={status} />}
    </Form.Item>
  );
};

export const CarrierProductRefField: React.FC<InputFieldProps> = props => {
  return (
    <InputField
      label="Carrier product reference"
      name="carrier_product_ref"
      required={true}
      {...commonFormItemProps}
      {...props}
    />
  );
};

export const AlwaysShowTrackingLinksField: React.FC<SwitchFieldProps> = ({
  className,
  ...restProps
}) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <SwitchField
        label="Always show tracking links"
        name="always_show_tracking_links"
        labelTooltip={formatMessage('ALWAYS_SHOW_TRACKING_LINKS_TOGGLE_TOOLTIP')}
        className={classes(
          tss({
            $nest: {
              '&.ant-switch': {
                marginLeft: '0',
              },
            },
          }),
          className
        )}
        {...commonFormItemProps}
        {...restProps}
      />
    </>
  );
};

export const UseCustomMappingField: React.FC<SwitchFieldProps> = ({ className, ...restProps }) => {
  return (
    <SwitchField
      label="Use custom mapping"
      name="custom"
      className={classes(
        tss({
          $nest: {
            '&.ant-switch': {
              marginLeft: '0',
            },
          },
        }),
        className
      )}
      {...commonFormItemProps}
      {...restProps}
    />
  );
};

const LOCALE_WIDTH = '116px';
const URL_WIDTH = '300px';

export const LocaleField: React.FC<LocaleFieldProps> = ({
  idx,
  value,
  className,
  ...restProps
}) => {
  const locales = useSelector(getAvailableLocalesOptions);

  return (
    <AntdSelectField
      value={value === 'default' ? 'Default' : value}
      name={`tracking_links[${idx}].locale`}
      className={classes(
        tss({
          width: `${LOCALE_WIDTH} !important`, // antd hack
          backgroundColor: 'hsla(0, 0%, 50%, 0.05) !important',
        }),
        className
      )}
      options={locales}
      placeholder="Please select..."
      showSearch={true}
      filterOption={true}
      optionFilterProp={'label'}
      {...restProps}
    />
  );
};

export const UrlField: React.FC<InputFieldProps & { idx: number }> = ({
  idx,
  className,
  ...restProps
}) => {
  return (
    <InputField
      name={`tracking_links[${idx}].url`}
      labelClassName={tss({
        width: `${URL_WIDTH} !important`, // antd hack
      })}
      className={classes(
        tss({
          height: '32px !important',
        }),
        className
      )}
      {...restProps}
    />
  );
};
