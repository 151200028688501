import * as React from 'react';
import { Button } from '../controls';

// styles
import * as csstips from 'csstips';
import { style } from 'typestyle';
const classStyles = {
  root: style(csstips.fillParent, csstips.horizontal, csstips.betweenJustified, {
    margin: '20px 0px 0px',
  }),
  button: style({
    width: 120,
  }),
};

interface IProps {
  isLoading?: boolean;
  onSubmitDisabled?: boolean;
  onSubmitLabel?: string;
  onBack?: () => void;
  onBackLabel?: string;
  onDelete?: () => void;
  onDeleteLabel?: string;
}

const Component: React.StatelessComponent<IProps> = props => {
  const { isLoading = false, onSubmitLabel, onBack, onBackLabel, onDelete, onDeleteLabel } = props;

  const DeleteButton = (
    <Button
      htmlType="button"
      type="ghost"
      className={classStyles.button}
      onClick={onDelete}
      disabled={true}
    >
      {onDeleteLabel || 'Delete'}
    </Button>
  );

  const BackButton = (
    <Button
      htmlType="button"
      type="ghost"
      className={classStyles.button}
      onClick={onBack}
      disabled={isLoading}
    >
      {onBackLabel || 'Back'}
    </Button>
  );

  const SubmitButton = (
    <Button
      htmlType="submit"
      type="primary"
      className={classStyles.button}
      loading={isLoading}
      disabled={true}
    >
      {onSubmitLabel || 'Submit'}
    </Button>
  );

  return (
    <div className={classStyles.root}>
      {onDelete ? DeleteButton : <div />}
      <div />
      <div />
      <div />
      {onBack ? BackButton : <div />}
      {SubmitButton}
    </div>
  );
};

export default Component;
