import { ControlOutlined } from '@ant-design/icons';
import { ListFixedHeader } from '@src/components';
import { Card } from '@src/controls';
import { DOMAIN } from '@src/dictionaries';
import { getCustomBookingMethodsByCurrentSite } from '@src/modules/config/selectors';
import { useSelector } from '@src/utils/hooks';
import React, { FC, Fragment } from 'react';
import { style as tss } from 'typestyle';
import { EmptyList } from './empty-list';
import { BookingMethodCard } from './shipment-method-card';

export const BookingRules: FC = () => {
  const customBookingMethods = useSelector(getCustomBookingMethodsByCurrentSite);
  const isFetching = useSelector(state => state.config.isFetching);

  const isEmptyList = customBookingMethods.every(bookingMethodModel => !bookingMethodModel.steps);

  const virtualMethodsList = customBookingMethods.map(bookingMethodModel => (
    <BookingMethodCard key={bookingMethodModel.method} bookingMethodModel={bookingMethodModel} />
  ));

  return (
    <Fragment>
      <ListFixedHeader
        title={DOMAIN.BOOKING_RULES}
        IconComponent={ControlOutlined}
        isLoading={false}
        showSearch={false}
      />
      <Card loading={isFetching} className={styles.card}>
        {!isEmptyList && !isFetching ? virtualMethodsList : <EmptyList />}
      </Card>
    </Fragment>
  );
};

const styles = {
  card: tss({
    $nest: {
      '.ant-card-body': {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      },
    },
  }),
};
