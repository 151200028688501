import { InboxOutlined } from '@ant-design/icons';
import { FieldState, FormState } from 'formstate';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { eqBy, isEmpty, path, prop, unionWith } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import * as React from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { style as tss } from 'typestyle';

import { ContainerActions, ContainerContent, ContainerFixedHeader } from '../../components';
import { DOMAIN, LABELS, MESSAGES } from '../../dictionaries';
import {
  AddonModel,
  AddressModel,
  ContactModel,
  CustomerInfoModel,
  LineItemModel,
  TemplateModel,
  TemplateModelRequest,
  TemplateModelResponse,
} from '../../models';
import { RootState } from '../../modules';
import { addressBookActions } from '../../modules/address-book';
import { blobsActions, blobsSelectors } from '../../modules/blobs';
import { dictionariesActions } from '../../modules/dictionaries';
import { userprofileActions } from '../../modules/userprofile';

import { routerActions } from '@src/modules/router';
import { tagsActions, tagsSelectors } from '@src/modules/tags';
import { Card, Input, Select } from '../../controls';
import { withFieldStateInput, withFieldStateSelect, withFormItem } from '../../decorators';
import { eventTrackingActions } from '../../modules/event-tracking';
import { defaultTheme } from '../../styles';
import { decimalToStringWithoutFactorial } from '../../utils/currency';
import { isFormStateContainerDirty } from '../../utils/forms';
import { isValidFormState, requiredFieldWithMessage } from '../../utils/validation';
import {
  ConsignmentDetailsFormState,
  ConsignmentDetailsTable,
  createLineItemFormState,
  ExtraInformation,
  ExtraInformationFormState,
  LineItemFormState,
  LineItemsTableForm,
  LineItemsTableFormState,
  ShipmentAddressSection,
  ShipmentAddressSelectorFormState,
  ShipmentOptionsForm,
  ShipmentOptionsFormState,
  unwrapLineItemFormState,
} from '../shipments/components';

const InputField = withFormItem(withFieldStateInput(Input));
const SelectField = withFormItem(withFieldStateSelect(Select));

const styles = {
  card: tss({
    marginBottom: defaultTheme.lineHeight,
  }),
  cardContents: tss({ marginBottom: defaultTheme.lineHeight }),
};

// Component API
type OwnProps = {
  templateId: string;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  isFetching: state.transportOrders.isFetching,
  isFetchingContacts: state.addressBook.isFetching,
  carrierAddons: state.dictionaries.carrierAddons,
  contactsList: state.addressBook.contacts,
  customerContacts: state.addressBook.customerContacts,
  senderContacts: state.addressBook.senderContacts,
  deliveryContacts: state.addressBook.deliveryContacts,
  siteId: state.sites.selectedSiteId,
  templates: state.userprofile.templates,
  attachmentField: blobsSelectors.attachmentField(state),
  attachmentUrl: blobsSelectors.attachmentUrl(state),
  siteTags: tagsSelectors.getSiteTags(state),
});
const dispatchProps = {
  getContacts: addressBookActions.getContactsListRequest,
  getCustomerContacts: addressBookActions.getCustomerContactsRequest,
  getSenderContacts: addressBookActions.getSenderContactsRequest,
  getDeliveryContacts: addressBookActions.getDeliveryContactsRequest,
  createContactFromModal: addressBookActions.createContactFromModalRequest,
  createTempContact: addressBookActions.createTempContact,
  updateContactFromModal: addressBookActions.updateContactFromModalRequest,
  getCarrierAddons: dictionariesActions.getCarrierAddonsRequest,
  getTemplates: userprofileActions.getTemplateListRequest,
  updateTemplate: userprofileActions.updateTemplateRequest,
  storeFile: blobsActions.storeFileRequest,
  storeFileSuccess: blobsActions.storeFileSuccess,
  getToken: blobsActions.getTokenRequest,
  resetAttachment: blobsActions.resetAttachment,
  viewTemplatePage: eventTrackingActions.viewTemplatePage,
  getSiteTags: tagsActions.listSiteTagsRequest,
  routerPush: routerActions.push,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & OwnProps;

@observer
export class Component extends React.Component<Props, {}> {
  @observable
  formStateContainer = {
    lineItemsTableFormState: LineItemsTableFormState.create(),
    shipmentOptionsFormState: ShipmentOptionsFormState.create(),
    shipmentAddressSelectorFormState: ShipmentAddressSelectorFormState.create(false),
    templateFormState: new FormState({
      templateName: new FieldState('').validators(
        requiredFieldWithMessage('Template name is required')
      ),
    }),
    selectedTags: new FieldState(observable<string>([])),
    extraInformationFormState: ExtraInformationFormState.create(),
    consignmentDetailsFormState: ConsignmentDetailsFormState.create(),
  };

  @observable shippingMethodMetadata = observable<string>([]);
  @observable isPreorder = observable<boolean>();
  templateContacts = observable<ContactModel>([]);

  @observable formSubmitted = false;

  @computed
  get formStateDirty() {
    return isFormStateContainerDirty(this.formStateContainer);
  }

  componentDidMount() {
    const { siteId, templates, templateId, viewTemplatePage, siteTags, getSiteTags } = this.props;

    if (siteId) {
      const template = templates.find(t => t.id === templateId);
      if (template == null) {
        this.props.getTemplates({ siteId });
      } else {
        this.initializeFormState(template);
      }
      if (isEmpty(siteTags)) {
        getSiteTags({ siteId });
      }
      this.props.getContacts({});
      this.props.getCarrierAddons();
    }

    this.props.resetAttachment();
    viewTemplatePage();
  }

  componentDidUpdate(prevProps: Props) {
    const { templates, templateId, contactsList, carrierAddons } = this.props;

    if (templates != null && contactsList != null && carrierAddons != null) {
      if (
        templates !== prevProps.templates ||
        contactsList !== prevProps.contactsList ||
        carrierAddons !== prevProps.carrierAddons
      ) {
        this.initializeFormState(templates.find(t => t.id === templateId));
      }
    }
  }

  initializeFormState = (model?: TemplateModelResponse) => {
    this.formStateContainer.lineItemsTableFormState = LineItemsTableFormState.create(
      model && model.contents.goods
        ? model.contents.goods.map(lI => ({ active: false, lineItem: lI }))
        : []
    );
    this.formStateContainer.shipmentOptionsFormState = ShipmentOptionsFormState.createFromTemplate(
      model,
      this.props.carrierAddons,
      undefined,
      undefined
    );
    this.formStateContainer.shipmentAddressSelectorFormState =
      ShipmentAddressSelectorFormState.create(false);
    this.loadAddresses(model);

    this.formStateContainer.templateFormState.$.templateName.value = model ? model.name : '';
    this.formStateContainer.extraInformationFormState = ExtraInformationFormState.create({
      notes: model?.meta?.notes,
      attachment: model?.meta?.attachment,
    });
    this.formStateContainer.consignmentDetailsFormState = ConsignmentDetailsFormState.create(model);

    this.formStateContainer.selectedTags.$.replace(model ? model.tags.map(tag => tag.id) : []);

    if (
      model &&
      path(['meta', 'attachment'], model) &&
      this.props.siteId &&
      this.props.attachmentUrl === ''
    ) {
      this.props.storeFileSuccess(model.meta.attachment);
      this.props.getToken({ siteId: this.props.siteId, url: model.meta.attachment });
    }
  };

  loadAddresses = (model?: TemplateModel) => {
    if (model == null) {
      return;
    }

    this.templateContacts.clear();
    const {
      customerAddressSelectorFormState,
      fromAddressSelectorFormState,
      toAddressSelectorFormState,
      returnAddressSelectorFormState,
    } = this.formStateContainer.shipmentAddressSelectorFormState.$;

    if (model.customerInfo.address.addressLines.length) {
      const customerContactModel = new ContactModel();
      customerContactModel.address = { ...model.customerInfo.address };
      if (model.customerInfo.address.name) {
        customerContactModel.address.name = model.customerInfo.address.name;
      }
      customerContactModel.email = model.customerInfo.email;
      customerContactModel.phone = model.customerInfo.phone;
      this.templateContacts.push(customerContactModel);
      customerAddressSelectorFormState.$.addressIdFieldState.value = customerContactModel.id;
    }

    if (model.addressFrom.addressLines.length) {
      const fromContactModel = new ContactModel();
      fromContactModel.address = { ...model.addressFrom };
      if (model.addressFrom.name) {
        fromContactModel.address.name = model.addressFrom.name;
      }
      this.templateContacts.push(fromContactModel);
      fromAddressSelectorFormState.$.addressIdFieldState.value = fromContactModel.id;
    }

    if (model.addressReturn.addressLines.length) {
      const returnContactModel = new ContactModel();
      returnContactModel.address = { ...model.addressReturn };
      if (model.addressReturn.name) {
        returnContactModel.address.name = model.addressReturn.name;
      }
      this.templateContacts.push(returnContactModel);
      returnAddressSelectorFormState.$.addressIdFieldState.value = returnContactModel.id;
    }

    if (model.addressTo.addressLines.length) {
      const toContactModel = new ContactModel();
      toContactModel.address = { ...model.addressTo };
      if (model.addressTo.name) {
        toContactModel.address.name = model.addressTo.name;
      }
      this.templateContacts.push(toContactModel);
      toAddressSelectorFormState.$.addressIdFieldState.value = toContactModel.id;
    }
  };

  handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const addons =
      this.formStateContainer.shipmentOptionsFormState.$.carrierAddonsFormState.$.filter(
        a => a.$.isOn
      ).map(a => ({ code: a.$.code } as AddonModel));

    if (!(await isValidFormState(this.formStateContainer.templateFormState))) {
      return;
    }

    const {
      contactsList,
      siteId,
      templateId,
      templates,
      attachmentField,
      customerContacts,
      deliveryContacts,
      senderContacts,
    } = this.props;
    if (siteId == null) {
      return;
    }

    const {
      lineItemsTableFormState,
      shipmentOptionsFormState,
      shipmentAddressSelectorFormState,
      templateFormState,
      extraInformationFormState,
    } = this.formStateContainer;

    const {
      fromAddressSelectorFormState,
      toAddressSelectorFormState,
      customerAddressSelectorFormState,
      returnAddressSelectorFormState,
    } = shipmentAddressSelectorFormState.$;

    const template = templates.find(t => t.id === templateId) as any as TemplateModelRequest;
    if (template == null) {
      return;
    }

    const allContacts = [
      ...contactsList,
      ...customerContacts,
      ...deliveryContacts,
      ...senderContacts,
      ...this.templateContacts.slice(),
    ];

    template.name = templateFormState.$.templateName.$;
    const customerModel = new CustomerInfoModel();
    const customerId = customerAddressSelectorFormState.$.addressIdFieldState.value;
    const customerAddressBookModel = allContacts.find(item => item.id === customerId);
    customerModel.address =
      (customerAddressBookModel && customerAddressBookModel.address) || new AddressModel();
    customerModel.email = (customerAddressBookModel && customerAddressBookModel.email) || '';
    customerModel.phone = (customerAddressBookModel && customerAddressBookModel.phone) || '';
    template.customerInfo = customerModel;

    const toAddressId = toAddressSelectorFormState.$.addressIdFieldState.value;
    const fromAddressId = fromAddressSelectorFormState.$.addressIdFieldState.value;
    const returnAddressId = returnAddressSelectorFormState.$.addressIdFieldState.value;

    const toAddressBookModel = allContacts.find(item => item.id === toAddressId);
    const fromAddressBookModel = allContacts.find(item => item.id === fromAddressId);
    const returnAddressBookModel = allContacts.find(item => item.id === returnAddressId);

    template.addressFrom =
      (fromAddressBookModel && fromAddressBookModel.address) || new AddressModel();
    template.addressTo = (toAddressBookModel && toAddressBookModel.address) || new AddressModel();
    template.addressReturn =
      (returnAddressBookModel && returnAddressBookModel.address) || new AddressModel();

    template.shippingMethod =
      shipmentOptionsFormState.$.shippingMethodSelectorFormState.$.shippingMethodFieldState.$.toString();
    template.shipmentValue = decimalToStringWithoutFactorial(
      shipmentOptionsFormState.$.valueFieldState.$
    );
    template.courierInstructions =
      shipmentOptionsFormState.$.shipmentOptionsDetailsFormState.$.courierInstructions.$;
    template.addressTo.doorCode =
      shipmentOptionsFormState.$.shipmentOptionsDetailsFormState.$.doorCode.$;

    const { ...meta } = shipmentOptionsFormState.$.metadataFieldState.value;
    template.meta = meta;

    if (attachmentField) {
      template.meta = { ...template.meta, attachment: attachmentField };
    }

    const notes = extraInformationFormState.$.notesFieldState.$;
    if (notes) {
      template.meta = { ...template.meta, notes };
    }

    template.contents = {
      externalOrders: [],
      transportOrders: [],
      goods: [...lineItemsTableFormState.$.peek().map(lI => unwrapLineItemFormState(lI).lineItem)],
    };

    template.addons = addons;

    template.numberOfParcels =
      this.formStateContainer.consignmentDetailsFormState.$.numberOfParcels.$;
    template.dimensions = this.formStateContainer.consignmentDetailsFormState.$.dimensions.$;
    template.weight = this.formStateContainer.consignmentDetailsFormState.$.weight.$;

    template.tags = this.formStateContainer.selectedTags.value;

    this.props.updateTemplate({ siteId, template });

    this.formSubmitted = true;
  };

  handleBack = () => {
    this.props.routerPush({ name: 'TEMPLATE_LIST' });
  };

  handleSearchCustomerContact = (searchQuery: string) => {
    this.props.getCustomerContacts({ searchQuery });
  };

  handleSearchSenderContact = (searchQuery: string) => {
    this.props.getSenderContacts({ searchQuery });
  };

  handleSearchDeliveryContact = (searchQuery: string) => {
    this.props.getDeliveryContacts({ searchQuery });
  };

  handleChangePreorder = (b: boolean) => {
    this.isPreorder.set(b);
  };

  handleAddLineItemItem = () => {
    const { lineItemsTableFormState } = this.formStateContainer;
    lineItemsTableFormState.$.push(createLineItemFormState(true, new LineItemModel()));
  };

  handleDeleteLineItem = (item: LineItemFormState) => {
    const { lineItemsTableFormState } = this.formStateContainer;

    lineItemsTableFormState.$.remove(item);
  };

  handleStoreFile = (file: File) => {
    const { siteId, storeFile } = this.props;
    if (siteId) {
      storeFile({ siteId, file });
    }
  };

  handleCreateContactFromModal = (contact: ContactModel, shouldSaveInAddressBook: boolean) => {
    shouldSaveInAddressBook
      ? this.props.createContactFromModal(contact)
      : this.props.createTempContact(contact);
  };

  handleUpdateContactFromModal = (contact: ContactModel) =>
    isNotNil(contact.createdAt) && this.props.updateContactFromModal(contact);

  render() {
    const {
      isFetching,
      isFetchingContacts,
      contactsList,
      attachmentField,
      attachmentUrl,
      siteTags,
      customerContacts,
      senderContacts,
      deliveryContacts,
    } = this.props;
    const allContacts = [...contactsList, ...this.templateContacts.slice()];

    const {
      lineItemsTableFormState,
      shipmentOptionsFormState,
      shipmentAddressSelectorFormState,
      templateFormState,
      extraInformationFormState,
    } = this.formStateContainer;

    return (
      <form onSubmit={this.handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <ContainerFixedHeader
          title={[LABELS.EDIT, DOMAIN.TEMPLATE].join(' ')}
          IconComponent={InboxOutlined}
          mainActionType="submit"
          mainActionLabel={LABELS.SAVE}
          onBack={this.handleBack}
          onBackLabel={LABELS.CANCEL}
          isLoading={isFetching}
        />

        <ContainerContent>
          <Card title="Template details" className={styles.card}>
            <InputField
              fieldState={templateFormState.$.templateName}
              error={templateFormState.$.templateName.error}
              placeholder="Template name"
            />
            <SelectField
              placeholder="Select tags"
              mode="multiple"
              options={siteTags.map(tag => ({ value: tag.id, label: tag.name }))}
              value={this.formStateContainer.selectedTags.value.peek()}
              onSelect={(item: string) => {
                this.formStateContainer.selectedTags.$.push(item);
              }}
              onDeselect={(item: string) => {
                this.formStateContainer.selectedTags.$.remove(item);
              }}
            />
          </Card>

          <Card title="Addresses" className={styles.card}>
            <ShipmentAddressSection
              isAddressFieldsRequired={false}
              isFetching={isFetchingContacts}
              formState={shipmentAddressSelectorFormState}
              onCreateContact={this.handleCreateContactFromModal}
              onUpdateContact={this.handleUpdateContactFromModal}
              handleSearchCustomerContact={this.handleSearchCustomerContact}
              handleSearchSenderContact={this.handleSearchSenderContact}
              handleSearchDeliveryAddress={this.handleSearchDeliveryContact}
              customerContacts={unionWith(eqBy(prop('id')), allContacts, customerContacts)}
              senderContacts={unionWith(eqBy(prop('id')), allContacts, senderContacts)}
              deliveryContacts={unionWith(eqBy(prop('id')), allContacts, deliveryContacts)}
            />
          </Card>

          <Card title="Contents" className={styles.card}>
            <div style={{ position: 'relative' }}>
              <ContainerActions.Create
                onCreateLabel={LABELS.ADD}
                onCreate={this.handleAddLineItemItem}
                className={styles.cardContents}
              />
              <LineItemsTableForm
                onItemRemove={this.handleDeleteLineItem}
                showEmptyMessage={false}
                formState={lineItemsTableFormState}
                disabled={false}
              />
            </div>
          </Card>

          <Card title="Consignment details" className={styles.card}>
            <ConsignmentDetailsTable
              formState={this.formStateContainer.consignmentDetailsFormState}
            />
          </Card>

          <Card title="Shipment options" className={styles.card}>
            <ShipmentOptionsForm
              hidePickupPointSelection={true}
              formState={shipmentOptionsFormState}
              carrierAddons={this.props.carrierAddons}
              showAddons={true}
              isPreorder={this.isPreorder.get()}
              changePreorder={this.handleChangePreorder}
              showCustomBookingMethods
            />
          </Card>

          <ExtraInformation
            formState={extraInformationFormState}
            storeFile={this.handleStoreFile}
            attachmentField={attachmentField}
            attachmentUrl={attachmentUrl}
            isLoading={false}
          />
        </ContainerContent>

        <Prompt
          when={this.formStateDirty && !this.formSubmitted}
          message={MESSAGES.LEAVING_DIRTY_FORM}
        />
      </form>
    );
  }
}

const Connected = connect(mapStateToProps, dispatchProps)(Component);
export default Connected;
