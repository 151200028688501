import { ConfigProvider } from 'antd';
import * as React from 'react';
import { RawIntlProvider } from 'react-intl';
import en_US from 'antd/lib/locale-provider/en_US';

import { intl } from './intl';

export const AppIntlProvider: React.FunctionComponent = ({ children }) => {
  return (
    <RawIntlProvider value={intl}>
      <ConfigProvider locale={en_US}>{children}</ConfigProvider>
    </RawIntlProvider>
  );
};

AppIntlProvider.displayName = 'AppIntlProvider';
