import { Popover } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { AddButton, ComponentReturningChildren } from '@src/components';
import { Modal } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { wideLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { ConfigShippingCategoryModel } from '@src/models';
import { defaultTheme } from '@src/styles';

import { configActions } from '@src/modules/config';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import {
  AddCategoryLabelForm,
  AddCategoryLabelsFormValues,
  LabelEdit,
  LabelsTable,
} from './components/labels';

const LabelWrapper = withFormItem(ComponentReturningChildren, wideLayout);

interface Props {
  category: ConfigShippingCategoryModel;
}

export const LabelsContainer: React.FC<Props> = ({ category }) => {
  const [isCreatePopoverVisible, setIsCreatePopoverVisible] = React.useState(false);
  const [isCreatingLabel, setIsCreatingLabel] = React.useState(false);

  const handleCreateCategoryLabel = (values: AddCategoryLabelsFormValues) => {
    setIsCreatingLabel(true);
    dispatchOnDraftSiteId(configActions.createCategoryLabelRequest)({
      model: { categoryId: category.id, name: values.name, type: values.type, state: 'ACTIVE' },
      onComplete: () => {
        setIsCreatingLabel(false);
      },
    });
  };
  const [labelModalConfig, setLabelModalConfig] = React.useState<{
    visible: boolean;
    labelId: string;
  }>({
    visible: false,
    labelId: '',
  });

  return (
    <LabelWrapper
      className={styles.wrapper}
      labelClassName={styles.label}
      label={<FormattedMessage id="CATEGORY_MODAL.LABELS.TITLE" />}
      labelTooltip={<FormattedMessage id="CATEGORY_MODAL.LABELS.TITLE_TOOLTIP" />}
      labelAlign="left"
    >
      {category.labels && category.labels.length ? (
        <LabelsTable
          isCreatingLabel={isCreatingLabel}
          labels={category.labels}
          categoryId={category.id}
          onLabelClick={labelId =>
            setLabelModalConfig({
              visible: true,
              labelId,
            })
          }
        />
      ) : null}
      <Popover
        destroyTooltipOnHide
        trigger="click"
        title={<FormattedMessage id="ADD_NEW_LABEL" />}
        visible={isCreatePopoverVisible}
        onVisibleChange={setIsCreatePopoverVisible}
        getPopupContainer={e => e.parentElement || document.body}
        content={
          <AddCategoryLabelForm
            onSubmit={values => {
              handleCreateCategoryLabel(values);
              setIsCreatePopoverVisible(false);
            }}
          />
        }
      >
        <AddButton text={<FormattedMessage id="ADD_NEW" />} className={styles.addButton} />
      </Popover>
      <Modal
        visible={labelModalConfig.visible}
        footer={null}
        onCancel={() => setLabelModalConfig({ visible: false, labelId: '' })}
      >
        <LabelEdit labelId={labelModalConfig.labelId} categoryId={category.id} />
      </Modal>
    </LabelWrapper>
  );
};

const styles = stylesheet({
  wrapper: {
    margin: 0,
  },
  label: {
    $nest: {
      label: { color: defaultTheme.regionForm.color.label },
    },
  },
  addButton: {
    width: '100px',
    padding: 0,
    margin: '15px 15px 0 15px',
    $nest: {
      '.anticon-plus': {
        color: '#000000',
      },
    },
  },
});
