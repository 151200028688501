import { Col, Row } from 'antd';
import { remove } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { stylesheet } from 'typestyle';

import { ShippingMethodWithLogo } from '@src/components';
import { Alert, Modal } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { CarrierProductModel, ConfigFilterRuleModel, UpdateCarrierProductModel } from '@src/models';
import { configActions, configSelectors } from '@src/modules/config';
import { commons } from '@src/styles';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { useSelector } from '@src/utils/hooks';
import { CarrierProductForm, CarrierProductFormValue } from './components';
import { getPriceRuleFromFormValue, parsePriceString } from './helpers';
import { ShippingDateAdjustments } from './shipping-date-adjustment';

interface OwnProps {
  product: CarrierProductModel;
  regionId: string;
}

const mapDispatchToProps = () => ({
  updateCarrierProduct: dispatchOnDraftSiteId(configActions.updateCarrierProductRequest),
  deleteCarrierProduct: dispatchOnDraftSiteId(configActions.deleteCarrierProductRequest),
  updateCarrierProductFilterRules: dispatchOnDraftSiteId(
    configActions.updateCarrierProductFilterRulesRequest
  ),
});

type Props = OwnProps & ReturnType<typeof mapDispatchToProps>;

const Component: React.FunctionComponent<Props> = ({
  product,
  regionId,
  deleteCarrierProduct,
  updateCarrierProduct,
  updateCarrierProductFilterRules,
}) => {
  const isShippingMethodConfigured = useSelector(state =>
    configSelectors.isShippingMethodConfigured(state, product.shippingMethod)
  );
  const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);

  const sendProductsToBackend = (model: UpdateCarrierProductModel) =>
    updateCarrierProduct({ model });

  const handleProductChange = (values: CarrierProductFormValue) => {
    const { deliveryTypes } = product;

    sendProductsToBackend({
      regionId,
      method: values.shippingMethod,
      priceRules: [
        ...values.priceRules.map(getPriceRuleFromFormValue),
        { conditions: ['true'], price: parsePriceString(values.basePrice) },
      ],
      externalMethodId: values.externalMethodId,
      deliveryTypes,
      ...((values.SDAStart !== undefined || values.SDAEnd !== undefined) && {
        shippingDateAdjustment: {
          adjustStart: values.SDAStart,
          adjustEnd: values.SDAEnd,
          unit: 'day',
        },
      }),
    });
  };

  const handleCarrierProductFilterRulesChange = (rules: ConfigFilterRuleModel[]) => {
    updateCarrierProductFilterRules({
      model: {
        regionId,
        method: product.shippingMethod,
        deliveryTypes: product.deliveryTypes,
        filterRules: rules,
      },
    });
  };

  const handleRemoveRule = (values: CarrierProductFormValue, ruleIndex: number) => {
    const { externalMethodId, deliveryTypes } = product;

    sendProductsToBackend({
      regionId,
      method: values.shippingMethod,
      priceRules: [
        ...remove(ruleIndex, 1, values.priceRules).map(getPriceRuleFromFormValue),
        { conditions: ['true'], price: parsePriceString(values.basePrice) },
      ],
      externalMethodId,
      deliveryTypes,
    });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Row>
          <Col span={6} className={styles.shippingMethodInfo}>
            <ShippingMethodWithLogo method={product.shippingMethod} />
          </Col>
          <Col span={18}>
            {!isShippingMethodConfigured && (
              <Alert
                message={<FormattedMessage id="UNCONFIGURED_CARRIER_PRODUCT" />}
                type="warning"
                showIcon
                className={styles.alert}
              />
            )}
            <CarrierProductForm
              product={product}
              regionId={regionId}
              onCarrierProductChange={handleProductChange}
              onCarrierProductFilterRuleChange={handleCarrierProductFilterRulesChange}
              onPriceRuleRemove={handleRemoveRule}
              onCarrierProductDelete={() => setDeleteModalVisible(true)}
            />
            <ShippingDateAdjustments product={product} regionId={regionId} />
          </Col>
        </Row>
      </div>
      <Modal
        visible={deleteModalVisible}
        onOk={() => {
          deleteCarrierProduct({
            model: {
              method: product.shippingMethod,
              deliveryTypes: product.deliveryTypes,
              regionId,
            },
          });
        }}
        onCancel={() => setDeleteModalVisible(false)}
        title={<FormattedMessage id="DELETE_CARRIER_PRODUCT" />}
        width="600px"
        className={commons.modal}
      >
        <FormattedMessage id="CARRIER_PRODUCT_DELETE_CONFIRM_BODY" />
      </Modal>
    </>
  );
};

export const CarrierProductFormContainer = connect(null, mapDispatchToProps)(Component);

const styles = stylesheet({
  wrapper: {
    borderTop: '1px solid #D9D9D9',
    paddingTop: '8px',
    marginBottom: '15px',
    lineHeight: 1.5,
  },

  alert: {
    marginBottom: '5px',
    $nest: {
      '.anticon': {
        // since primary color is set for all icons in autosave-form, we have to overwrite it here
        color: '#faad14',
      },
    },
  },
  shippingMethodInfo: {
    paddingTop: '10px',
  },
});
