import * as React from 'react';
import { stylesheet } from 'typestyle';
import { isNotEmpty } from 'ramda-adjunct';

import { AddButton, ComponentReturningChildren, LinkStyleButton } from '@src/components';
import { ActivateSwitch } from '@src/containers/regions/components';
import { Modal, Table } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, regionFormContainerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { ConfigShippingCategoryModel } from '@src/models';
import { commons, defaultTheme } from '@src/styles';
import { humanize } from '@src/utils/string';
import { Popover } from 'antd';
import { CreateCategoryForm, CreateCategoryFormValues } from './create-category-form';

const LabelWrapper = withFormItem(ComponentReturningChildren, regionFormContainerLayout);

interface OwnProps {
  categories: ConfigShippingCategoryModel[];
  onItemClick: (itemId: string) => void;
  onCategoryAdd: (values: CreateCategoryFormValues) => void;
  onCategoryDelete: (categoryId: string) => void;
  onCategoryStatusUpdate: (category: ConfigShippingCategoryModel, active: boolean) => void;
  isLoading: boolean;
}

export const ShippingCategoriesList: React.FunctionComponent<OwnProps> = ({
  categories,
  onItemClick,
  onCategoryAdd,
  onCategoryDelete,
  onCategoryStatusUpdate,
  isLoading,
}) => {
  const [isAddingCategoryPopoverVisible, setIsAddingCategoryPopoverVisible] = React.useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);
  const [categoryToDelete, setCategoryToDelete] = React.useState('');

  return (
    <AutoSaveForm className={styles.form}>
      <LabelWrapper label={<FormattedMessage id="DELIVERY_CATEGORY" values={{ multiple: true }} />}>
        <Table<ConfigShippingCategoryModel>
          dataSource={categories}
          hideTitle={true}
          emptyMessage={
            <div className={styles.emptyTable}>
              <FormattedMessage id="DELIVERY_CATEGORY_EMPTY_LIST" />
            </div>
          }
          pagination={false}
          rowKey={record => `${record.name}${record.id}`}
          loading={isLoading}
        >
          <Table.Column<ConfigShippingCategoryModel>
            title={<FormattedMessage id="CATEGORY_NAME" />}
            key="name"
            render={({}, record) => (
              <LinkStyleButton onClick={() => onItemClick(record.id)}>
                {record.name}
              </LinkStyleButton>
            )}
          />
          <Table.Column<ConfigShippingCategoryModel>
            title={<FormattedMessage id="SORT_ORDER" />}
            key="sortOrder"
            render={({}, record) => (record.sortOrder ? record.sortOrder : undefined)}
          />
          <Table.Column<ConfigShippingCategoryModel>
            title={<FormattedMessage id="CARRIER_PRODUCT" values={{ multiple: true }} />}
            key="carrierProducts"
            render={({}, record) => record.shippingMethods.join(', ')}
          />
          <Table.Column<ConfigShippingCategoryModel>
            title={<FormattedMessage id="DELIVERY_TYPE" />}
            key="deliveryType"
            render={({}, record) => humanize(record.deliveryType)}
          />
          <Table.Column<ConfigShippingCategoryModel>
            title={<FormattedMessage id="ACTIVE" />}
            key="active"
            render={({}, record) => {
              return (
                <ActivateSwitch
                  category={record}
                  onChange={value => {
                    onCategoryStatusUpdate(record, value);
                  }}
                />
              );
            }}
          />
          <Table.Column<ConfigShippingCategoryModel>
            title={<FormattedMessage id="DELETE" />}
            key="delete"
            render={({}, record) => (
              <a
                onClick={() => {
                  setCategoryToDelete(record.id);
                  setDeleteModalVisible(true);
                }}
              >
                <FormattedMessage id="DELETE" />
              </a>
            )}
          />
        </Table>
        <Modal
          visible={deleteModalVisible}
          onOk={() => {
            onCategoryDelete(categoryToDelete);
            setDeleteModalVisible(false);
          }}
          onCancel={() => setDeleteModalVisible(false)}
          title={<FormattedMessage id="DELETE_DELIVERY_CATEGORY" />}
          width="600px"
          className={commons.modal}
        >
          <FormattedMessage id="DELIVERY_CATEGORY_DELETE_CONFIRM_BODY" />
        </Modal>
        <Popover
          trigger="click"
          title={<FormattedMessage id="NEW_CATEGORY" />}
          destroyTooltipOnHide={true}
          visible={isAddingCategoryPopoverVisible}
          onVisibleChange={setIsAddingCategoryPopoverVisible}
          getPopupContainer={e => e!.parentElement!}
          content={
            <CreateCategoryForm
              onSubmit={values => {
                onCategoryAdd(values);
                setIsAddingCategoryPopoverVisible(false);
              }}
            />
          }
        >
          <AddButton
            className={isNotEmpty(categories) ? styles.addButton : styles.addButtonEmptyList}
          />
        </Popover>
      </LabelWrapper>
    </AutoSaveForm>
  );
};

const styles = stylesheet({
  form: {
    $nest: {
      '.ant-table-thead th': {
        backgroundColor: defaultTheme.regionForm.color.tableHeader,
      },
      '& .ant-table-body': {
        overflowX: 'auto',
      },
      '.ant-switch': {
        marginLeft: '0',
      },
    },
  },
  addButton: {
    display: 'inline-block',
  },
  addButtonEmptyList: {
    paddingTop: 0,
    display: 'inline-block',
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'hsla(0, 0%, 50%, 0.05)',
  },
  emptyTable: {
    display: 'grid',
    placeContent: 'center',
    backgroundColor: 'hsla(0, 0%, 50%, 0.05)',
    color: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '4px',
    padding: '15px 15px 0px 15px',
  },
});
