import {
  CarrierProductMapping,
  CreateCarrierProductMappingRequest,
  GlobalCarrierProductList,
  ListCarrierProductMappingResponse,
  TrackingConfigurationStatus,
  UpdateCarrierProductMappingRequest,
} from '@src/models';
import { stylesheet } from 'typestyle';
import * as yup from 'yup';

export type CarrierProductMappingForm = {
  custom: boolean;
  carrier_product_ref?: string;
  carrier_product_id?: string;
  always_show_tracking_links: boolean;
  tracking_links: {
    locale: string | undefined;
    url: string;
  }[];
  tracking_config_status?: TrackingConfigurationStatus;
};

export const buildValidationSchema = (
  data: ListCarrierProductMappingResponse | undefined,
  currentMapping?: CarrierProductMapping
) => {
  const refsInUse = (data?.carrier_product_mappings ?? [])
    .map(({ carrier_product_ref }) => carrier_product_ref)
    .filter(ref => ref !== currentMapping?.carrier_product_ref);
  return yup.object({
    carrier_product_ref: yup
      .string()
      .required('Carrier product reference is required')
      .notOneOf(refsInUse, 'This carrier product reference is already in use'),
    carrier_product_id: yup.string().when('custom', {
      is: false,
      then: yup.string().required('Carrier product is required'),
      otherwise: yup.string(),
    }),
    tracking_links: getTrackingLinksValidationSchema(),
  });
};

export const getTrackingLinksValidationSchema = () => {
  return yup.array().when('custom', {
    is: true,
    then: yup.array().of(
      yup.object({
        locale: yup.string().required('Tracking link locale is required'),
        url: yup
          .string()
          .required('Tracking link is required')
          .matches(
            /<tracking_number>/,
            'Tracking link must contain "<tracking_number>" placeholder'
          )
          .test(
            'match-<tracking_number>-once',
            'Tracking link can contain only one "<tracking_number>" placeholder',
            value => ((value ?? '').match(/<tracking_number>/g) || []).length === 1
          )
          .test(
            'do-not-contain-whitespace',
            'URL must not contain whitespace',
            value => ((value ?? '').match(/\s/g) || []).length === 0
          )
          .matches(RegExp('^https?://.*'), 'Tracking link must start with "https://" or "http://"'),
      })
    ),
    otherwise: yup.array(),
  });
};

/** In-place move array[from] item to array[to] */
const moveItem = (array: any[], from: number, to: number) => {
  array.splice(to, 0, array.splice(from, 1)[0]);
};

export const buildTrackingLinksFormValue = (
  trackingLinks: CarrierProductMapping['tracking_links'] | undefined
): CarrierProductMappingForm['tracking_links'] => {
  // build tracking_links form structure from API data structure
  const trackingLinksFormValue = Object.entries(trackingLinks ?? { default: '' }).map(
    ([locale, url]) => ({
      locale,
      url,
    })
  );

  // move default tracking link to first position in tracking_links array
  const defaultIndex = trackingLinksFormValue.findIndex(
    item => item.locale === 'default' || item.locale === 'defaultlink'
  );
  if (defaultIndex >= 0) {
    moveItem(trackingLinksFormValue, defaultIndex, 0);
  }

  return trackingLinksFormValue;
};

/** Build carrier product mapping form values from API data (complete or potentially partial mapping) or default initial values*/
export const buildFormValues = (
  mapping?: Partial<CarrierProductMapping>
): CarrierProductMappingForm => {
  if (!mapping) {
    // build initial values for Create form
    return {
      custom: false,
      carrier_product_id: undefined,
      carrier_product_ref: undefined,
      always_show_tracking_links: false,
      tracking_links: [{ locale: 'default', url: '' }],
      tracking_config_status: undefined,
    };
  }

  const tracking_links = buildTrackingLinksFormValue(mapping.tracking_links);

  return {
    custom: mapping.custom ?? false,
    carrier_product_id: mapping.carrier_product_id,
    carrier_product_ref: mapping.carrier_product_ref,
    always_show_tracking_links:
      (mapping.tracking_links && mapping.always_show_tracking_links) || false,
    tracking_links: tracking_links,
    tracking_config_status: mapping.tracking_config_status,
  };
};

type BuildRequestPayloadFn = {
  (
    formValues: CarrierProductMappingForm,
    mapping: CarrierProductMapping
  ): UpdateCarrierProductMappingRequest; // for use in Update form
  (formValues: CarrierProductMappingForm): CreateCarrierProductMappingRequest; // for use in Create form
};

/** Build create/update request payload from form values */
export const buildCarrierProductMappingsPayload: BuildRequestPayloadFn = (
  formValues: CarrierProductMappingForm,
  mapping?: CarrierProductMapping
): any => {
  const tracking_links = convertTrackingLinksToMapping(formValues.tracking_links);
  return {
    id: mapping?.id,
    custom: formValues.custom,
    carrier_product_ref: formValues.carrier_product_ref,
    carrier_product_id: !formValues.custom ? formValues.carrier_product_id : undefined,
    tracking_links: formValues.custom ? tracking_links : undefined,
    always_show_tracking_links: formValues.always_show_tracking_links,
  };
};

export const convertTrackingLinksToMapping = (
  trackingLinks: { locale: string | undefined; url: string }[]
) =>
  trackingLinks.reduce((acc, next) => {
    if (next.locale !== undefined) {
      acc[next.locale] = next.url;
      return acc;
    }
    return acc;
  }, {} as { [lang: string]: string });

export type GroupedGlobalCarrierProductList = {
  [carrierName: string]: {
    value: string;
    label: string;
    optionLabel: string;
  }[];
};

export const buildCarrierProductOptions = (
  carrierProductList: GlobalCarrierProductList | undefined = { carrier_products: [] }
) => {
  return carrierProductList?.carrier_products?.reduce((result, carrierProduct) => {
    if (!(carrierProduct.carrier in result)) {
      result[carrierProduct.carrier] = [];
    }
    result[carrierProduct.carrier].push({
      value: carrierProduct.id,
      label: `${carrierProduct.carrier} ${carrierProduct.product}`,
      optionLabel: `${carrierProduct.carrier} ${carrierProduct.product}`,
    });
    return result;
  }, {} as GroupedGlobalCarrierProductList);
};

/** Styles common for carrier product mapping forms presented in modals, specifically used in:
 * - carrier product mappings edit form
 * - autogenerated mappings form (in modal invoked when Set is clicked) */
export const modalFormStyles = stylesheet({
  modalHeader: {
    display: 'block',
    height: '28px',
    lineHeight: '28px',
    fontSize: '20px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
    marginBottom: '16px',
  },
  trackingLinkRow: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'start',
    paddingBottom: '8px',
    $nest: {
      '& .ant-form-item': {
        margin: '0 !important',
      },
    },
  },
  fieldSpacing: {
    paddingRight: '8px',
  },
});
