import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';

import { Input } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, inputStyle, widerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';

const InputField = withFormItem(Input, widerLayout);

interface OwnProps {
  id: string;
}

interface FormValues {
  id: string;
}

export const WarehouseID: React.FunctionComponent<OwnProps> = ({ id }) => (
  <Formik
    initialValues={{
      id,
    }}
    enableReinitialize={true}
    // tslint:disable-next-line: no-empty
    onSubmit={() => {}}
  >
    {() => (
      <AutoSaveForm>
        <Field name="id">
          {({ field }: FieldProps<FormValues['id']>) => (
            <InputField
              label={<FormattedMessage id="ID" />}
              {...field}
              className={inputStyle}
              disabled={true}
            />
          )}
        </Field>
      </AutoSaveForm>
    )}
  </Formik>
);
