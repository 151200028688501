import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { classes, stylesheet } from 'typestyle';
import { object, string } from 'yup';

import { Button, Input, Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { bigLabelLayout, inputStyle } from '@src/forms';
import { FormattedMessage } from '@src/i18n';

import { commons } from '@src/styles';
import { getFormikError } from '@src/utils/forms';

const InputField = withFormItem(Input, bigLabelLayout);
const SelectField = withFormItem(Select, bigLabelLayout);

interface Props {
  onSubmit: (values: AddCategoryLabelsFormValues) => void;
}

export interface AddCategoryLabelsFormValues {
  name: string;
  type: string;
}

const CreateRegionSchema = object({
  name: string().required(),
  type: string().required(),
});

export const AddCategoryLabelForm: React.FC<Props> = ({ onSubmit }) => {
  const labelTypesOptions = [
    {
      value: 'LABEL_TYPE_SUSTAINABILITY',
      label: 'Sustainability',
    },
    { value: 'LABEL_TYPE_PRIORITY', label: 'Priority' },
    { value: 'LABEL_TYPE_CUSTOM', label: 'Custom' },
    { value: 'LABEL_TYPE_SWAN', label: 'Swan' },
  ];

  return (
    <Formik
      onSubmit={values => onSubmit(values)}
      initialValues={{ name: '', type: '' }}
      validationSchema={CreateRegionSchema}
    >
      {({ handleSubmit, errors, touched, setFieldValue, values }) => (
        <form onSubmit={handleSubmit} className={styles.formWrapper}>
          <Field name="name">
            {({ field }: FieldProps<AddCategoryLabelsFormValues['name']>) => (
              <InputField
                {...field}
                disabled={values.type === 'LABEL_TYPE_SWAN'}
                label={<FormattedMessage id="NAME" />}
                labelAlign="left"
                error={getFormikError(touched.name, errors.name)}
                className={classes(
                  inputStyle,
                  getFormikError(touched.name, errors.name) && styles.nameInputError
                )}
              />
            )}
          </Field>
          <Field name="type">
            {({ field }: FieldProps<AddCategoryLabelsFormValues['type']>) => (
              <SelectField
                label={<FormattedMessage id="TYPE" />}
                options={labelTypesOptions}
                labelAlign="left"
                error={getFormikError(touched.type, errors.type)}
                {...field}
                onChange={value => {
                  if (value === 'LABEL_TYPE_SWAN') {
                    setFieldValue('type', value);
                    setFieldValue('name', 'Nordic Swan Ecolabel Delivery');
                  } else if (values.type === 'LABEL_TYPE_SWAN') {
                    // if previous value === swan label - clear input
                    setFieldValue('type', value);
                    setFieldValue('name', '');
                  } else {
                    setFieldValue('type', value);
                  }
                }}
                className={classes(inputStyle, styles.typeInput)}
              />
            )}
          </Field>
          <div className={commons.flex}>
            <Button type="primary" htmlType="submit" className={styles.submitButton}>
              <FormattedMessage id="ADD" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

const styles = stylesheet({
  submitButton: {
    marginLeft: 'auto',
    marginTop: '10px',
  },
  formWrapper: {
    width: 300,
  },
  nameInputError: {
    border: '1px solid #f5222d',
    $nest: {
      '&:hover': {
        border: '1px solid #F5222D',
      },
    },
  },
  typeInput: {
    $nest: {
      '&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
        border: 'none',
        backgroundColor: '#FAF8F7',
      },
      '&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:hover': {
        backgroundColor: 'white',
      },
    },
  },
});
