import { findIndex, propEq, update } from 'ramda';
import { useQueryClient } from 'react-query';
import { upsertIntegratedProductMapping } from '@src/services/selfcare-service';
import type { CarrierProductSettingsMappingResponse } from '@src/models';
import { useSiteId } from '@src/utils/hooks';
import { useOptimisticMutationOnDraft } from '@src/utils/queries';
import type { Context } from './types';

export const useUpsertIntegratedMappingMutation = () => {
  const queryClient = useQueryClient();
  const siteId = useSiteId();

  return useOptimisticMutationOnDraft(upsertIntegratedProductMapping, {
    onMutate: async (effectiveSiteId, payload) => {
      await queryClient.cancelQueries('carrier_product_settings.list');

      const previousMappingsList = queryClient.getQueryData<
        CarrierProductSettingsMappingResponse | undefined
      >(['carrier_product_settings.list', siteId]);

      queryClient.setQueryData(['carrier_product_settings.list', effectiveSiteId], () => {
        const mappings = previousMappingsList?.carrier_product_settings ?? [];

        if (!mappings.length) {
          return previousMappingsList;
        }

        if (!payload.custom) delete payload.tracking_links;

        const updatedMappings = update(
          findIndex(propEq('carrier_product_id', payload.carrier_product_id), mappings),
          payload,
          mappings
        );

        return { carrier_product_settings: updatedMappings };
      });

      return { previousMappingsList, effectiveSiteId };
    },

    onError: (_, __, context: Context) => {
      queryClient.setQueryData(
        ['carrier_product_settings.list', context.effectiveSiteId],
        context.previousMappingsList
      );
    },

    onSettled: () => queryClient.invalidateQueries('carrier_product_settings.list'),
  });
};
