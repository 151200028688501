import { CompassOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { stylesheet } from 'typestyle';
import { isNotEmpty } from 'ramda-adjunct';

import { Link } from '@src/components/link';
import {
  LinkStyleButton,
  AddButton,
  ListFixedHeader,
  SimpleList,
  CheckoutWidgetDrawer,
} from '@src/components';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { Modal } from '@src/controls';
import { DOMAIN } from '@src/dictionaries';
import { FormattedMessage } from '@src/i18n';
import { ConfigRegionModel, StateEnum } from '@src/models';
import { RootState } from '@src/modules';
import { configActions, configSelectors } from '@src/modules/config';
import { SitesSelectors } from '@src/modules/sites';
import { commons } from '@src/styles';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { warehouseSelectors } from '../warehouse/selectors';
import { RegionsList } from './components';
import { RegionDetailsEmbedded } from './region-details-container';
import { CreateRegionForm, CreateRegionFormValues } from './components/create-region-form';

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  regions: configSelectors.getRegionsByCountryAlphabetically(state, props.countryToFilterRegionsBy),
  regionsNames: configSelectors.getRegionsNames(state),
  isFetching: state.sites.isFetching,
  draftSiteId: SitesSelectors.getDraftSiteIdOrEmpty(state),
  merchantId: state.merchants.selectedMerchantId,
  siteId: SitesSelectors.getSelectedSiteIdOrEmpty(state),
  categories: configSelectors.getShippingCategoriesByRegionIdForSite(state),
  isCreatingDraft: state.config.isCreatingDraft,
  warehouses: warehouseSelectors.getWarehousesByDraftOrCurrentSite(state),
});

const mapDispatchToProps = () => ({
  createRegion: dispatchOnDraftSiteId(configActions.createRegionRequest),
  deleteRegion: dispatchOnDraftSiteId(configActions.deleteRegionRequest),
  getSite: dispatchOnDraftSiteId(configActions.getSiteRequest),
});

interface OwnProps {
  countryToFilterRegionsBy?: string;
  renderLink: (region: ConfigRegionModel) => React.ReactNode;
}

type Props = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Component: React.FunctionComponent<Props> = ({
  regions,
  regionsNames,
  isFetching,
  createRegion,
  getSite,
  deleteRegion,
  categories,
  isCreatingDraft,
  warehouses,
  renderLink,
}) => {
  const [createRegionPopoverVisible, setCreateRegionPopoverVisible] = React.useState(false);
  const [isCreatingRegion, setIsCreatingRegion] = React.useState(false);
  const [isDeletingModalVisible, setIsDeletingModalVisible] = React.useState(false);
  const [regionIdToDelete, setRegionIdToDelete] = React.useState('');

  const handleCreateRegion = (values: CreateRegionFormValues) => {
    setCreateRegionPopoverVisible(false);
    setIsCreatingRegion(true);

    if (values.type === 'COUNTRIES_INCLUDED') {
      createRegion({
        model: {
          name: values.name.trim(),
          warehouseId: values.warehouseId,
          regionType: 'country',
          state: StateEnum.INACTIVE,
          countryConfig: {
            included: values.countries,
          },
        },
        onComplete: () => {
          getSite({ onComplete: () => setIsCreatingRegion(false) });
        },
      });
    } else if (values.type === 'COUNTRIES_EXCLUDED') {
      createRegion({
        model: {
          name: values.name.trim(),
          warehouseId: values.warehouseId,
          regionType: 'country',
          state: StateEnum.INACTIVE,
          countryConfig: {
            excluded: values.countries,
          },
        },
        onComplete: () => {
          getSite({ onComplete: () => setIsCreatingRegion(false) });
        },
      });
    } else if (values.type === 'ZIP_CODES_INCLUDED') {
      createRegion({
        model: {
          name: values.name.trim(),
          warehouseId: values.warehouseId,
          regionType: 'zipcode',
          state: StateEnum.INACTIVE,
          postalcodeConfig: {
            postalCodes: values.zipCodes.split(','),
            country: values.countries[0],
          },
        },
        onComplete: () => {
          getSite({ onComplete: () => setIsCreatingRegion(false) });
        },
      });
    } else if (values.type === 'ZIP_CODES_EXCLUDED') {
      createRegion({
        model: {
          name: values.name.trim(),
          warehouseId: values.warehouseId,
          regionType: 'zipcode',
          state: StateEnum.INACTIVE,
          postalcodeConfig: {
            excludedPostalCodes: values.zipCodes.split(','),
            country: values.countries[0],
          },
        },
        onComplete: () => {
          getSite({ onComplete: () => setIsCreatingRegion(false) });
        },
      });
    }
  };

  return (
    <>
      <RegionsList
        isFetching={isFetching || isCreatingRegion || isCreatingDraft}
        regions={regions}
        onRegionDelete={(regionId: string) => {
          setIsDeletingModalVisible(true);
          setRegionIdToDelete(regionId);
        }}
        warehouses={warehouses}
        renderLink={renderLink}
      />
      <Modal
        visible={isDeletingModalVisible}
        onOk={() => {
          deleteRegion({ regionId: regionIdToDelete });
          setIsDeletingModalVisible(false);
        }}
        onCancel={() => setIsDeletingModalVisible(false)}
        title={<FormattedMessage id="REGION_DELETE_CONFIRM_TITLE" />}
        width="600px"
        className={commons.modal}
      >
        <FormattedMessage id="REGION_DELETE_CONFIRM_BODY" />

        {categories[regionIdToDelete] && categories[regionIdToDelete].length ? (
          <SimpleList
            title={
              <FormattedMessage
                id="REGION_DELETE_CONFIRM_CATEGORIES"
                values={{ count: categories[regionIdToDelete].length }}
              />
            }
          >
            {categories[regionIdToDelete].map(category => (
              <SimpleList.Li key={category.id}>{category.name}</SimpleList.Li>
            ))}
          </SimpleList>
        ) : null}
      </Modal>
      {isNotEmpty(warehouses) && (
        <Popover
          trigger="click"
          title={<FormattedMessage id="NEW_REGION" />}
          destroyTooltipOnHide={true}
          visible={createRegionPopoverVisible}
          onVisibleChange={setCreateRegionPopoverVisible}
          getPopupContainer={e => e.parentElement || document.body}
          content={
            <CreateRegionForm
              regionsNames={regionsNames}
              warehouses={warehouses}
              onSubmit={handleCreateRegion}
            />
          }
          placement={isNotEmpty(regions) ? 'bottomLeft' : 'bottom'}
        >
          <AddButton
            className={isNotEmpty(regions) ? styles.addButton : styles.addButtonEmptyList}
          />
        </Popover>
      )}
    </>
  );
};

const styles = stylesheet({
  regionListWrapper: {
    padding: '24px',
  },
  addButton: {
    display: 'inline-block',
  },
  addButtonEmptyList: {
    display: 'inline-block',
    textAlign: 'center',
    width: '100%',
  },
});

export const RegionsListContainer = connect(mapStateToProps, mapDispatchToProps)(Component);

export const RegionsListPage = () => {
  return (
    <>
      <StatusBarContainer />
      <div className={styles.regionListWrapper}>
        <ListFixedHeader
          title={DOMAIN.REGIONS}
          titleTooltip={<FormattedMessage id="REGIONS_LIST_TOOLTIP" />}
          IconComponent={CompassOutlined}
          isLoading={false}
          showSearch={false}
        />
        <RegionsListContainer
          renderLink={(region: ConfigRegionModel) => (
            <Link route={{ name: 'REGION_DETAILS', regionId: region.id }} showIcon baseStyles>
              {region.name}
            </Link>
          )}
        />
        <CheckoutWidgetDrawer />
      </div>
    </>
  );
};

export const RegionsListEmbedded: React.FC<{ countryToFilterBy: string | undefined }> = ({
  countryToFilterBy,
}) => {
  const [regionId, setRegionId] = React.useState<string | undefined>();

  return (
    <>
      <RegionsListContainer
        countryToFilterRegionsBy={countryToFilterBy}
        renderLink={(region: ConfigRegionModel) => (
          <LinkStyleButton onClick={() => setRegionId(region.id)}>{region.name}</LinkStyleButton>
        )}
      />
      {regionId !== undefined && (
        <Modal
          visible
          onCancel={() => setRegionId(undefined)}
          footer={null}
          destroyOnClose={true}
          width="900px"
        >
          <RegionDetailsEmbedded regionId={regionId} />
        </Modal>
      )}
    </>
  );
};
