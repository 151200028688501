import { Breadcrumb } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { CheckoutWidgetDrawer } from '@src/components';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { mapCountryNameToCode } from '@src/dictionaries/mapping';

import { stringUtils } from '@src/utils';
import { Link } from '@src/components/link';
import { Alert, Spinner, Tag } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { configSelectors } from '@src/modules/config';
import { defaultTheme } from '@src/styles';
import { useSelector } from '@src/utils/hooks';
import { CountrySettings, PlaceholderCarrierLogosContainer } from './';
import { Country } from './components';

interface Props {
  country: string;
}

export const CountryDetails: React.FC<Props> = ({ country }) => {
  const countryCode = mapCountryNameToCode(country);
  const isFetchingSite = useSelector(state => state.config.isFetching);

  const capitalizedCountry = stringUtils.capitalize(country);
  const countryISO = mapCountryNameToCode(capitalizedCountry);

  const sortedCategories = useSelector(state =>
    configSelectors.getCategoriesWithPreselectionOrder(state, countryISO)
  );
  const unsortedCategories = useSelector(state =>
    configSelectors.getCategoriesWithoutPreselectionOrder(state, countryISO)
  );

  const isNoFallbackSet =
    sortedCategories.length > 0 &&
    sortedCategories.reduce(
      (acc, c) => acc + Number(!!c.countrySettings?.[countryISO]?.useForFallback),
      0
    ) === 0;

  return (
    <>
      {unsortedCategories.length === 0 && isNoFallbackSet && (
        <Alert
          className={styles.fallbackAlert}
          message={<FormattedMessage className={styles.fallbackAlertMessage} id="FALLBACK_ALERT" />}
          type="warning"
          showIcon
        />
      )}
      <Tag color={defaultTheme.color.primary} nonClickable={true}>
        <FormattedMessage id="COUNTRY" />
      </Tag>
      <h2>{capitalizedCountry}</h2>
      {/* it should be one boolean check, but site fetching is delayed causing flashes on the UI */}
      {/* FIXME: removing the check mentioned above, cause it is causing problems in experiments.*/}
      {isFetchingSite ? (
        <Spinner delay={0} />
      ) : (
        <>
          <Country country={country} />
          <CountrySettings countryCode={countryCode} />
          <PlaceholderCarrierLogosContainer countryCode={countryCode} />
          <CheckoutWidgetDrawer />
        </>
      )}
    </>
  );
};

const BreadcrumbSection: React.FunctionComponent<{ countryName: string }> = ({ countryName }) => (
  <Breadcrumb className={styles.breadcrumb}>
    <Breadcrumb.Item>
      <Link route={{ name: 'COUNTRY_LIST' }}>
        <FormattedMessage id="COUNTRY_SETTINGS" className={styles.breadcrumbLink} />
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>{countryName}</Breadcrumb.Item>
  </Breadcrumb>
);

export const CountryPage: React.FC<Props> = ({ country }) => {
  const capitalizedCountry = stringUtils.capitalize(country);
  return (
    <>
      <StatusBarContainer />
      <BreadcrumbSection countryName={capitalizedCountry} />
      <div className={styles.wrapper}>
        <CountryDetails country={country} />
      </div>
    </>
  );
};

const styles = stylesheet({
  wrapper: {
    padding: '24px',
  },
  header: {
    marginTop: '10px',
    marginBottom: '24px',
  },
  breadcrumb: {
    padding: '24px 24px 0',
  },
  breadcrumbLink: {
    cursor: 'pointer',
  },
  fallbackAlert: {
    border: '0',
  },
  fallbackAlertMessage: {
    paddingLeft: '5px',
  },
});
