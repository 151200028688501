import { color } from 'csx';
import { reverse, takeLast } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { style as tss } from 'typestyle';

import { SomSearchSelectors } from '@src/modules/som-search';
import { TrackAndTraceModalContainer } from '.';
import { LabelDownload } from '../../components';
import { Badge, Button, Card, Table } from '../../controls';
import { LABELS } from '../../dictionaries';
import { ParcelModel } from '../../models';
import { RootState } from '../../modules';
import { deliveriesSelectors } from '../../modules/deliveries';
import { eventTrackingActions } from '../../modules/event-tracking';
import { labelMergerActions, LabelMergerSelectors } from '../../modules/label-merger';
import { ShipmentsSelectors } from '../../modules/shipments';
import { defaultTheme } from '../../styles';
import { DeliveriesHistoryTimeline, LinkToPrint, ReprintCustomsDeclaration } from './components';
import { getLabel, getTrackingId } from './helpers';

interface OwnProps {
  itemId: string;
  isLoading?: boolean;
}

interface State {
  isTrackAndTraceModalVisible: boolean;
  currentlySelectedParcelId: null | string;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  deliveriesFetching: deliveriesSelectors.isDeliveriesFetching(state),
  deliveriesHistoryByIds: deliveriesSelectors.getDeliveryHistoryByIds(state),
  shipmentStatus: SomSearchSelectors.getShipmentStatus(state, ownProps.itemId),
  shipment: ShipmentsSelectors.getShipmentById(state, ownProps.itemId),
  isFetchingMergedLabel: LabelMergerSelectors.getIsFetchingMergedLabel(state),
  mergedLabel: LabelMergerSelectors.getMergedLabel(state),
  siteId: state.sites.selectedSiteId,
  labelsFileNames: LabelMergerSelectors.getLabelsFileNames(state, ownProps.itemId),
  canMergeLabels: LabelMergerSelectors.getCanMerge(state, ownProps.itemId),
});

const dispatchProps = {
  downloadShipmentLabel: eventTrackingActions.downloadShipmentLabel,
  shipmentParcelDetailsClick: eventTrackingActions.shipmentParcelDetailsClick,
  mergeLabels: labelMergerActions.mergeLabelsRequest,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & OwnProps;

const styles = {
  card: tss({
    marginBottom: defaultTheme.lineHeight,
  }),
  firstColumn: tss({ display: 'flex', flexDirection: 'column' }),
  sscc: {
    wrapper: tss({ marginBottom: '20px' }),
    text: tss({
      color: color('#000000').fade('60%').toString(),
    }),
  },
  label: tss({ fontWeight: defaultTheme.font.bold, marginRight: '.5em' }),
  table: tss({
    $nest: {
      '& :hover > td': { background: 'none !important' },
      '& tr:not:(:first-child)': { marginTop: '8px' },
    },
  }),
  row: tss({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '8px',
  }),
  labelsSections: tss({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  }),
  columnSSCC: tss({ flex: 1 }),
  columnActivity: tss({ flex: 2.5 }),
  columnActions: tss({ flex: 0.5 }),
};
class Component extends React.Component<Props, State> {
  state = {
    isTrackAndTraceModalVisible: false,
    currentlySelectedParcelId: null,
  };

  handleMergeLabels = () => {
    const { siteId, mergeLabels, labelsFileNames } = this.props;
    if (siteId) {
      mergeLabels({ siteId, fileNames: labelsFileNames });
    }
  };

  handleTrackAndTraceDetailsClick = (item: ParcelModel) => {
    const { isTrackAndTraceModalVisible } = this.state;
    this.setState({
      currentlySelectedParcelId: item.id,
      isTrackAndTraceModalVisible: !isTrackAndTraceModalVisible,
    });
    this.props.shipmentParcelDetailsClick();
  };

  handleTrackAndTraceDetailsCancel = () => {
    const { isTrackAndTraceModalVisible } = this.state;
    this.setState({
      currentlySelectedParcelId: null,
      isTrackAndTraceModalVisible: !isTrackAndTraceModalVisible,
    });
  };

  renderTimeline = (item: ParcelModel) => {
    const { deliveriesHistoryByIds } = this.props;
    const delivery = ShipmentsSelectors.getFirstDeliveryForParcel(item);
    const deliveryHistoryForParcel = delivery ? deliveriesHistoryByIds[delivery.id] : null;
    if (deliveryHistoryForParcel) {
      return (
        <DeliveriesHistoryTimeline
          deliveryHistory={reverse(takeLast(3, deliveryHistoryForParcel))}
        />
      );
    }
    return null;
  };

  renderFirstColumn = (parcel: ParcelModel) => {
    const documentModel = getLabel(parcel);
    const labelDownloadLink = (documentModel && (
      <LabelDownload onDownload={this.props.downloadShipmentLabel} record={documentModel} />
    )) || <Badge.Empty />;
    const trackingId = getTrackingId(parcel);

    const { shipment } = this.props;

    const showLinkToPrint = shipment?.id && shipment.directionType === 'RETURN';

    const customsDeclarationDoc = shipment?.customs?.document;

    return (
      <div className={styles.firstColumn}>
        <div className={styles.sscc.wrapper}>
          <span className={styles.label}>{LABELS.SSCC}:</span>
          <span className={styles.sscc.text}>{trackingId}</span>
        </div>
        <div className={styles.labelsSections}>
          <span>{labelDownloadLink}</span>
          {showLinkToPrint && <LinkToPrint returnShipmentId={shipment.id} />}
          {customsDeclarationDoc && <ReprintCustomsDeclaration document={customsDeclarationDoc} />}
        </div>
      </div>
    );
  };

  renderExtra = () => {
    const { isFetchingMergedLabel, mergedLabel, canMergeLabels } = this.props;
    if (!canMergeLabels) {
      return;
    }
    return mergedLabel ? (
      <Button href={mergedLabel} target="_blank">
        {LABELS.DOWNLOAD_CONSOLIDATED_LABEL}
      </Button>
    ) : (
      <Button onClick={this.handleMergeLabels} loading={isFetchingMergedLabel}>
        {LABELS.PREPARE_CONSOLIDATED_LABEL}
      </Button>
    );
  };

  render() {
    const { isTrackAndTraceModalVisible, currentlySelectedParcelId } = this.state;
    const { itemId, shipmentStatus, shipment, deliveriesFetching, isLoading } = this.props;
    return (
      <>
        <TrackAndTraceModalContainer
          visible={isTrackAndTraceModalVisible}
          onCancel={this.handleTrackAndTraceDetailsCancel}
          onOk={this.handleTrackAndTraceDetailsCancel}
          shipmentId={itemId}
          parcelId={currentlySelectedParcelId}
        />
        <Card
          title={LABELS.TRACK_AND_TRACE}
          className={styles.card}
          extra={this.renderExtra()}
          loading={isLoading}
        >
          <Table
            dataSource={shipmentStatus !== 'CREATED' ? shipment && shipment.parcels : []}
            emptyMessage={<p>{LABELS.NO_ACTIVITY}</p>}
            rowKey={record => record.id}
            pagination={false}
            title={() => ''}
            size="small"
            showHeader={false}
            loading={deliveriesFetching}
            className={styles.table}
            onRow={() => ({ className: styles.row })}
            sortDirections={['ascend', 'descend']}
          >
            <Table.Column
              title={LABELS.SSCC}
              key="sscc"
              className={styles.firstColumn}
              render={record => this.renderFirstColumn(record)}
              onCell={() => ({ className: styles.columnSSCC })}
            />
            <Table.Column
              title={LABELS.LATEST_ACTIVITY}
              key="activity"
              render={record => this.renderTimeline(record)}
              onCell={() => ({ className: styles.columnActivity })}
            />
            <Table.Column
              title={LABELS.ACTION}
              key="action"
              render={record => (
                <a onClick={() => this.handleTrackAndTraceDetailsClick(record)}>
                  {LABELS.SEE_DETAILS}
                </a>
              )}
              onCell={() => ({ className: styles.columnActions })}
            />
          </Table>
        </Card>
      </>
    );
  }
}

const TrackAndTraceContainer = connect(mapStateToProps, dispatchProps)(Component);

export default TrackAndTraceContainer;
