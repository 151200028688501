import { ConfigShippingCategoryModel, UpdateCategoryDetailsModel } from '@src/models';

export const createUpdateCategoryDetailsModel = (
  fields: Partial<UpdateCategoryDetailsModel>,
  category: ConfigShippingCategoryModel
): UpdateCategoryDetailsModel => {
  return {
    categoryId: category.id,
    name: fields.name === undefined ? category.name : fields.name,
    regionIds: category.regionIds,
    shippingMethods:
      fields.shippingMethods === undefined ? category.shippingMethods : fields.shippingMethods,
    deliveryType: fields.deliveryType === undefined ? category.deliveryType : fields.deliveryType,
    sortOrder: fields.sortOrder === undefined ? category.sortOrder : fields.sortOrder,
    state: fields.state === undefined ? category.state : fields.state,
    preselected: fields.preselected === undefined ? category.preselected : fields.preselected,
    requirements:
      fields.requirements === undefined
        ? category.requirements
        : { ...category.requirements, ...fields.requirements },
    additionalOptions:
      fields.additionalOptions === undefined
        ? category.additionalOptions
        : fields.additionalOptions,
    maxChoicesCount: fields.maxChoicesCount === undefined ? category.maxChoicesCount : fields.maxChoicesCount,
  };
};
