import * as React from 'react';
import * as CopyToClipboard from 'react-copy-to-clipboard';
import { stylesheet } from 'typestyle';

import { ComponentReturningChildren, LinkStyleButton } from '@src/components';
import { Table } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { regionFormContainerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { ConfigSiteModel } from '@src/models';
import { services } from '@src/services';
import { defaultTheme } from '@src/styles';
import { CopyOutlined } from '@ant-design/icons';

const LabelWrapper = withFormItem(ComponentReturningChildren, regionFormContainerLayout);

interface Props {
  sites: ConfigSiteModel[];
  isFetching: boolean;
  onItemClick: (itemId: string) => void;
}
export const SitesTable: React.FunctionComponent<Props> = ({ sites, isFetching, onItemClick }) => {
  return (
    <div className={styles.sites}>
      <LabelWrapper label={<FormattedMessage id="SITE" values={{ multiple: true }} />}>
        <Table<ConfigSiteModel>
          dataSource={sites}
          hideTitle
          pagination={false}
          rowKey={({ id }) => id}
          loading={isFetching}
        >
          <Table.Column<ConfigSiteModel>
            title={<FormattedMessage id="NAME" />}
            key="name"
            render={({}, { id, name }) => (
              <LinkStyleButton onClick={() => onItemClick(id)}>{name}</LinkStyleButton>
            )}
          />
          <Table.Column<ConfigSiteModel>
            title={<FormattedMessage id="PRIVATE_KEY" />}
            key="pk"
            render={({}, { privateKey }) => (
              <div>
                {privateKey}
                &nbsp;
                <CopyToClipboard
                  text={privateKey}
                  onCopy={() => services.messageService.success('Private key copied to clipboard.')}
                >
                  <CopyOutlined
                    style={{ fontSize: '16px' }}
                    onClick={(e: React.MouseEvent) => e.stopPropagation()}
                  />
                </CopyToClipboard>
              </div>
            )}
          />
        </Table>
      </LabelWrapper>
    </div>
  );
};

const styles = stylesheet({
  sites: {
    $nest: {
      '.ant-table-thead th': {
        backgroundColor: defaultTheme.regionForm.color.tableHeader,
      },
      '& .ant-table-body': {
        overflowX: 'auto',
      },
      '.ant-switch': {
        marginLeft: '0',
      },
    },
  },
});
