import { FieldState } from 'formstate';
import { observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';

type BaseProps = {
  value?: moment.Moment;
  onChange?: (date: moment.Moment, dateString: string) => void;
};

type HOCProps = {
  fieldState?: FieldState<moment.Moment | undefined>;
  onErase?: () => void;
};

export function withFieldStateDatePicker<WrappedComponentProps extends {}>(
  WrappedComponent: React.ComponentType<WrappedComponentProps>
) {
  const HOC: React.StatelessComponent<HOCProps & WrappedComponentProps> = props => {
    const { fieldState, onErase, ...passThroughProps } = props as HOCProps;

    const overriddenBaseProps: BaseProps = {};
    if (fieldState) {
      overriddenBaseProps.value = fieldState.value;
      overriddenBaseProps.onChange = (date: moment.Moment, dateString: string) => {
        if (!date && onErase) {
          onErase();
        }
        return fieldState.onChange(date);
      };
    }

    return <WrappedComponent {...(passThroughProps as any)} {...overriddenBaseProps} />;
  };

  return observer(HOC);
}
