import { Input, Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { formItemLayout } from '@src/forms/form-layouts';
import { AuthenticationType, UserCreateModel, UserGetModel } from '@src/models';
import { usersSelectors } from '@src/modules/users';
import { getFormikError } from '@src/utils/forms';
import { Field, FieldProps, FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { array, object, ref, string } from 'yup';
import { SubmitButtonWithLayout } from './button-with-layout';

const InputField = withFormItem(Input);
const SelectField = withFormItem(Select);

interface Props {
  groupMembershipOptions: ReturnType<typeof usersSelectors.getGroupMembershipOptions>;
  siteTagsOptions: { value: string; label: string }[];
  onSubmit: (user: UserCreateModel, groupIds: string[], tagsIds: string[]) => void;
  mode: 'create' | 'edit';
  userToEdit?: UserGetModel;
  userToEditGroups?: ReturnType<typeof usersSelectors.getUserGroups>;
  userTagsIds?: string[];
  isLoading: boolean;
  groupsIdsWithAccessToWatchlists: ReturnType<
    typeof usersSelectors.getGroupsIdsThatHaveWatchlistRole
  >;
}

interface FormValues {
  email?: string;
  password?: string;
  passwordConfirm?: string;
  name?: string;
  groupIds?: string[];
  selectedTagsIds?: string[];
  mode: string;
}

const InnerForm: React.FunctionComponent<Props & FormikProps<FormValues>> = ({
  handleSubmit,
  touched,
  errors,
  groupMembershipOptions,
  setFieldValue,
  values,
  siteTagsOptions,
  isLoading,
  groupsIdsWithAccessToWatchlists,
  mode,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field name="email">
        {({ field }: FieldProps<FormValues['email']>) => (
          <InputField
            label="Email"
            required={true}
            {...field}
            {...formItemLayout}
            error={getFormikError(touched.email, errors.email)}
          />
        )}
      </Field>
      <Field name="password">
        {({ field }: FieldProps<FormValues['password']>) => (
          <InputField
            label="Password"
            required={mode === 'create' ? true : false}
            type="password"
            {...field}
            {...formItemLayout}
            error={getFormikError(touched.password, errors.password)}
          />
        )}
      </Field>
      <Field name="passwordConfirm">
        {({ field }: FieldProps<FormValues['passwordConfirm']>) => (
          <InputField
            label="Password confirmation"
            required={mode === 'create' ? true : false}
            type="password"
            {...field}
            {...formItemLayout}
            error={getFormikError(touched.passwordConfirm, errors.passwordConfirm)}
          />
        )}
      </Field>
      <Field name="name">
        {({ field }: FieldProps<FormValues['name']>) => (
          <InputField
            label="Name"
            {...field}
            {...formItemLayout}
            error={getFormikError(touched.name, errors.name)}
          />
        )}
      </Field>
      <Field name="groupIds">
        {({ field }: FieldProps<FormValues['groupIds']>) => (
          <SelectField
            label="Group Membership"
            mode="multiple"
            required={true}
            style={{ width: '350px' }}
            groupOptions={groupMembershipOptions}
            {...formItemLayout}
            placeholder={'Please select...'}
            error={getFormikError(touched.groupIds, errors.groupIds)}
            defaultValue={field.value}
            onChange={value => setFieldValue('groupIds', value)}
          />
        )}
      </Field>
      {groupsIdsWithAccessToWatchlists.some(
        group => values.groupIds && values.groupIds.includes(group)
      ) && (
        <Field name="selectedTagsIds">
          {({ field }: FieldProps<FormValues['selectedTagsIds']>) => (
            <SelectField
              label="Tags"
              mode="multiple"
              loading={isLoading}
              style={{ width: '300px' }}
              options={siteTagsOptions}
              {...formItemLayout}
              placeholder={'Please select...'}
              error={getFormikError(touched.selectedTagsIds, errors.selectedTagsIds)}
              value={field.value}
              onChange={value => setFieldValue('selectedTagsIds', value)}
              caseInsensitiveSearch={true}
            />
          )}
        </Field>
      )}
      <SubmitButtonWithLayout />
    </form>
  );
};

export const PasswordEmailForm = withFormik<Props, FormValues>({
  mapPropsToValues: ({ userToEdit, mode, userToEditGroups, userTagsIds }) => {
    if (userToEdit && userToEditGroups) {
      return {
        email: userToEdit.email,
        password: '',
        passwordConfirm: '',
        name: userToEdit.name,
        groupIds: userToEditGroups,
        selectedTagsIds: userTagsIds || [],
        mode,
      };
    } else {
      return {
        email: undefined,
        password: '',
        passwordConfirm: '',
        name: undefined,
        groupIds: [],
        selectedTagsIds: [],
        mode,
      };
    }
  },
  validationSchema: object().shape<FormValues>({
    mode: string(),
    email: string().email().required('Email is required'),
    password: string()
      .min(8)
      .when('mode', { is: 'create', then: string().required(), otherwise: string().notRequired() }),
    passwordConfirm: string()
      .oneOf([ref('password')], "Passwords don't match")
      .when('mode', {
        is: 'create',
        then: string().required(),
        otherwise: string().when('password', {
          // require field only when there is at least one character in password
          is: (val: string) => val && val.length > 0,
          then: string().required(),
          otherwise: string().notRequired(),
        }),
      }),
    name: string(),
    groupIds: array().of(string().required()).required('Group membership is required'),
    selectedTagsIds: array().of(string().required()),
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(
      {
        email: values.email,
        password: values.password,
        name: values.name,
        authenticationType: AuthenticationType.PASSWORD,
        id: props.userToEdit && props.userToEdit.id,
      },
      values.groupIds!,
      values.selectedTagsIds || []
    );
  },
  enableReinitialize: true,
  displayName: 'PasswordEmailForm',
})(InnerForm);
