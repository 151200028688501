import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { PriceInput, Select } from '@src/controls';
import { inequalityOptions } from '@src/controls/select';
import { withFormItem } from '@src/decorators';
import { RuleAddingValues } from '../rule-adding-container';

const SelectItem = withFormItem(Select);

interface Props {
  name: string;
  setFieldValue: FormikProps<RuleAddingValues>['setFieldValue'];
  label: React.ReactNode;
}

export const TotalValue: React.FunctionComponent<Props> = ({ setFieldValue, name, label }) => (
  <div className={styles.wrapper}>
    <Field name={`${name}.operator`}>
      {({ field }: FieldProps<string>) => (
        <SelectItem
          label={label}
          className={styles.operator}
          options={inequalityOptions}
          {...field}
          onChange={(value: string) => setFieldValue(`${name}.operator`, value)}
        />
      )}
    </Field>
    <Field name={`${name}.value`}>
      {({ field }: FieldProps<string>) => (
        <PriceInput
          {...field}
          onChange={(value: string) => setFieldValue(`${name}.value`, value)}
        />
      )}
    </Field>
  </div>
);

const styles = stylesheet({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0',
    $nest: {
      '.ant-row': {
        display: 'flex',
        marginBottom: 0,
      },
    },
  },
  operator: {
    width: '100px !important',
    paddingRight: '10px',
  },
  label: {
    whiteSpace: 'nowrap',
  },
});
