import { RootAction, RootState } from '@src/modules';
import { equals } from 'ramda';
import * as React from 'react';
import {
  TypedUseSelectorHook,
  useDispatch as ReactReduxUseDispatch,
  useSelector as ReactReduxUseSelector,
} from 'react-redux';
import { Dispatch } from 'redux';

import { SitesSelectors } from '@src/modules/sites';

export const useSelector: TypedUseSelectorHook<RootState> = ReactReduxUseSelector;
export const useDispatch = () => ReactReduxUseDispatch<Dispatch<RootAction>>();

const useDeepCompareMemoize = (value: ([] | object)[]) => {
  const ref = React.useRef<typeof value>();

  if (!equals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

// Use for deep equality check with useEffect
export const useDeepCompareEffect = (callback: () => void, deps: ([] | object)[]) => {
  React.useEffect(callback, useDeepCompareMemoize(deps));
};

export const useSiteId = () => {
  return useSelector(SitesSelectors.getDraftSiteIdOrMasterSiteId);
};

export const useMasterSiteId = () => {
  return useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);
};
