import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';

import { CreateReportResponseModel } from '../../models';
import { Reducer } from '../../modules';
import * as reportsActions from './actions';

export type ReportsState = {
  status: RequestState;
  reportIdByUserId: { [id: string]: CreateReportResponseModel };
  error: string | null;
  uriByUserId: { [id: string]: string };
};

export enum RequestState {
  pending = 'pending',
  active = 'active',
  success = 'success',
  exception = 'exception',
}

const status: Reducer<ReportsState['status']> = (state = RequestState.pending, action) => {
  switch (action.type) {
    case getType(reportsActions.createShipmentsReportRequest):
    case getType(reportsActions.createOrdersReportRequest):
    case getType(reportsActions.createSpecialReportRequest):
    case getType(reportsActions.getShipmentsReport):
    case getType(reportsActions.getOrdersReport):
    case getType(reportsActions.getSpecialReport):
      return RequestState.active;

    case getType(reportsActions.getReportSuccess):
      return RequestState.success;

    case getType(reportsActions.createReportError):
    case getType(reportsActions.getReportError):
      return RequestState.exception;

    default:
      return state;
  }
};

const error: Reducer<ReportsState['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(reportsActions.createReportError):
      return action.payload;
    case getType(reportsActions.createShipmentsReportRequest):
    case getType(reportsActions.createOrdersReportRequest):
    case getType(reportsActions.createSpecialReportRequest):
      return null;

    default:
      return state;
  }
};

const reportIdByUserId: Reducer<ReportsState['reportIdByUserId']> = (state = {}, action) => {
  switch (action.type) {
    case getType(reportsActions.createShipmentsReportSuccess):
    case getType(reportsActions.createOrdersReportSuccess):
    case getType(reportsActions.createSpecialReportSuccess):
      return { ...state, [action.payload.userId]: action.payload.model };
    default:
      return state;
  }
};

const uriByUserId: Reducer<ReportsState['uriByUserId']> = (state = {}, action) => {
  switch (action.type) {
    case getType(reportsActions.getReportSuccess):
      return { ...state, [action.payload.userId]: action.payload.uri };
    default:
      return state;
  }
};

const reducer: Reducer<ReportsState> = combineReducers({
  status,
  error,
  reportIdByUserId,
  uriByUserId,
});

export default reducer;
