import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '@src/modules';
import { authSelectors } from '@src/modules/auth';
import { shipmentNotificationsActions } from '@src/modules/shipment-notifications';
import { TriggersList } from './components';

const mapStateToProps = (state: RootState) => ({
  userId: authSelectors.getAuthUserId(state),
  siteId: state.sites.selectedSiteId,
  notificationTriggers: state.shipmentNotifications.triggers.items,
  isFetching: state.shipmentNotifications.triggers.isFetching,
});

const dispatchProps = {
  listNotificationTriggers: shipmentNotificationsActions.listNotificationTriggersRequest,
  deleteNotificationTrigger: shipmentNotificationsActions.deleteNotificationTriggerRequest,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

class Component extends React.Component<Props> {
  componentDidMount() {
    const { listNotificationTriggers, siteId } = this.props;
    if (siteId) {
      listNotificationTriggers();
    }
  }
  handleDeleteNotificationTrigger = (triggerId: string) => {
    this.props.deleteNotificationTrigger(triggerId);
  };
  render() {
    const { notificationTriggers, isFetching } = this.props;
    return (
      <TriggersList
        triggers={notificationTriggers}
        deleteTrigger={this.handleDeleteNotificationTrigger}
        isFetching={isFetching}
      />
    );
  }
}

export const TriggersListContainer = connect(mapStateToProps, dispatchProps)(Component);
