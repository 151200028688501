import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';

import { DatePicker, Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { LABELS } from '@src/dictionaries';
import { NotificationType } from '@src/models';
import { getFormikError } from '@src/utils/forms';
import { expectedStatusOptions, FormValues } from '.';

const SelectField = withFormItem(Select);
const DatePickerField = withFormItem(DatePicker);

export const FormFields: React.FunctionComponent<FormikProps<FormValues>> = props => {
  switch (props.values.notificationType) {
    case NotificationType.EXPECT_STATUS_AT_TIME:
      return (
        <>
          <DueDateField {...props} />
          <ExpectedStatusField {...props} />
        </>
      );

    case NotificationType.STATUS_CHANGED_TO:
      return <ExpectedStatusField {...props} />;

    case NotificationType.STATUS_AT_TIME:
      return <DueDateField {...props} />;

    default:
      return null;
  }
};

const DueDateField: React.FunctionComponent<FormikProps<FormValues>> = props => (
  <Field name="dueDate">
    {({ field }: FieldProps<FormValues['dueDate']>) => (
      <DatePickerField
        label="Select date"
        value={field.value}
        required={true}
        format="YYYY-MM-DD HH:mm"
        showTime={{ format: 'HH:mm' }}
        error={getFormikError(props.touched.dueDate, props.errors.dueDate)}
        onChange={value => props.setFieldValue('dueDate', value)}
      />
    )}
  </Field>
);

const ExpectedStatusField: React.FunctionComponent<FormikProps<FormValues>> = props => (
  <Field name="expectedStatus">
    {({ field }: FieldProps<FormValues['expectedStatus']>) => (
      <SelectField
        label="Select Status"
        options={expectedStatusOptions}
        placeholder={LABELS.PLACEHOLDER_SELECT}
        value={field.value ?? ''}
        required={true}
        error={getFormikError(props.touched.expectedStatus, props.errors.expectedStatus)}
        onChange={value => props.setFieldValue('expectedStatus', value)}
      />
    )}
  </Field>
);
