import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import * as React from 'react';

const { RangePicker } = DatePicker;

const Component: React.StatelessComponent<RangePickerProps> = props => {
  const { ...restProps } = props;
  return <RangePicker {...restProps} />;
};

export default Component;
