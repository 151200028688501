import { FieldState, FormState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Select } from '../controls';
import { withFieldStateSelect, withFormItem } from '../decorators';
import { validationUtils } from '../utils';
import { GroupSelectOption } from '@src/controls/select';

const SelectField = withFormItem(withFieldStateSelect(Select));

export class ShippingMethodSelectorFormState extends FormState<{
  shippingMethodFieldState: FieldState<string>;
}> {
  static create = (shippingMethod = ''): ShippingMethodSelectorFormState => {
    return new FormState({
      shippingMethodFieldState: new FieldState(shippingMethod).validators(
        validationUtils.requiredFieldWithMessage('Carrier Product is required')
      ),
    });
  };
}

// COMPONENT

type Props = {
  formState: ShippingMethodSelectorFormState;
  shippingMethods: GroupSelectOption;
  disabled?: boolean;
  autoFocus?: boolean;
  loading?: boolean;
  onChange: () => void;
};

@observer
export class ShippingMethodSelectorForm extends React.Component<Props> {
  render() {
    const { autoFocus, disabled, loading, onChange, shippingMethods } = this.props;
    const { shippingMethodFieldState } = this.props.formState.$;
    return (
      <SelectField
        required={true}
        autoFocus={autoFocus}
        disabled={disabled}
        label="Carrier Product"
        placeholder="please select..."
        fieldState={shippingMethodFieldState}
        error={shippingMethodFieldState.error}
        groupOptions={shippingMethods}
        showSearch={true}
        filterOption={true}
        optionFilterProp={'label'}
        caseInsensitiveSearch={true}
        loading={loading}
        onChange={onChange}
        labelCol={{ span: 24 }}
      />
    );
  }
}
