import { List } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { LimitedNumberOfItems } from '@src/components';
import { Link } from '@src/components/link';
import { Tag } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { ConfigMerchantSummaryModel } from '@src/models';
import { defaultTheme } from '@src/styles';

interface Props {
  merchants: ConfigMerchantSummaryModel[];
  isFetching: boolean;
}

export const MerchantsList: React.FunctionComponent<Props> = ({ isFetching, merchants }) => (
  <List
    dataSource={merchants}
    loading={isFetching}
    renderItem={(merchant: ConfigMerchantSummaryModel) => (
      <List.Item key={merchant.id}>
        <List.Item.Meta
          description={
            <div className={styles.listItem}>
              <Link
                showIcon
                baseStyles
                route={{ name: 'MERCHANT_DETAILS', detailsMerchantId: merchant.id }}
                className={styles.merchantName}
              >
                {merchant.name}
              </Link>
              <div className={styles.subMerchants}>
                <Tag
                  color={defaultTheme.color.white}
                  textColor={defaultTheme.color.primary}
                  borderColor={defaultTheme.color.primary}
                  nonClickable
                >
                  <FormattedMessage id="SITE" values={{ multiple: true }} />
                </Tag>
                <div className={styles.sites}>
                  <LimitedNumberOfItems list={merchant.sites.map(site => site.name)} />
                </div>
              </div>
            </div>
          }
        />
      </List.Item>
    )}
  />
);

const styles = stylesheet({
  listItem: {
    display: 'flex',
    paddingTop: '5px',
  },
  merchantName: {
    flexBasis: '30%',
    flexShrink: 0,
  },
  subMerchants: {
    marginRight: 'auto',
    paddingLeft: '20px',
  },
  sites: {
    marginTop: '2px',
    display: 'flex',
    flexWrap: 'wrap',
  },
});
