import { Spin } from 'antd';
import * as React from 'react';

type Props = {
  caption?: string;
  size?: 'default' | 'small' | 'large';
  delay?: number;
  active?: boolean;
  style?: React.CSSProperties;
};

class Component extends React.Component<Props, {}> {
  render() {
    const { children, active = true, caption, size = 'default', delay = 900, style } = this.props;

    return (
      <Spin spinning={active} tip={caption} size={size} delay={delay} style={style}>
        {children}
      </Spin>
    );
  }
}

export default Component;
