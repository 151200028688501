import { Table, Tabs } from 'antd';
import { compose, prop, sortBy } from 'ramda';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { ComponentReturningChildren } from '@src/components';
import { PreselectionOrderModal } from '@src/containers/features/components/preselection-order-modal';
import { Alert } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, wideLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { updateCountrySettingsRequest } from '@src/modules/config/actions';
import { siwActions } from '@src/modules/siw';
import { getCountryCodeToCountryNameMapping } from '@src/services/dictionaries-service';
import { defaultTheme } from '@src/styles';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { useDispatch } from '@src/utils/hooks';
import {
  CategoryPreselectionOrder,
  checkIfPreselectionOrderComplete,
  getCountriesWithCompletePreselectionOrder,
  getCountriesWithPartialPreselectionOrder,
  getCountriesWithNonePreselectionOrder,
  getStatus,
} from '@src/utils/preselection-order-utils';

const countyCodeToNameMapping = getCountryCodeToCountryNameMapping();

const LabelWrapper = withFormItem(ComponentReturningChildren, wideLayout);

const { TabPane } = Tabs;
const { Column } = Table;

type Props = {
  categoryPreselectionOrder: CategoryPreselectionOrder;
  categoryId: string;
  forwardedRef: React.RefObject<HTMLDivElement>;
};

export const CategoryPreselectionOrderSection: React.FC<Props> = ({
  categoryPreselectionOrder,
  categoryId,
  forwardedRef,
}) => {
  const sortedCategoryPreselectionOrder = sortBy(
    compose((country: string) => countyCodeToNameMapping[country], prop('country')),
    categoryPreselectionOrder
  );
  const countriesWithCompletePreselectionOrder = getCountriesWithCompletePreselectionOrder(
    sortedCategoryPreselectionOrder
  );
  const countriesWithPartialPreselectionOrder = getCountriesWithPartialPreselectionOrder(
    sortedCategoryPreselectionOrder
  );
  const countriesWithNonePreselectionOrder = getCountriesWithNonePreselectionOrder(
    sortedCategoryPreselectionOrder
  );

  const isPreselectionOrderComplete = checkIfPreselectionOrderComplete(categoryPreselectionOrder);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalCountry, setModalCountry] = React.useState('');

  const setPreselectionOrder = (country: string) => {
    setIsModalVisible(true);
    setModalCountry(country);
  };

  const dispatch = useDispatch();

  return (
    <AutoSaveForm>
      <div ref={forwardedRef}>
        <LabelWrapper
          label={<FormattedMessage id="PRESELECTION_ORDER" />}
          labelTooltip={<FormattedMessage id="PRESELECTION_ORDER_DISCLAIMER" />}
          className={styles.wrapper}
        >
          {!isPreselectionOrderComplete && (
            <Alert
              message={<FormattedMessage id="PRESELECTION_ORDER_SECTION_ALERT" />}
              type="warning"
            />
          )}
          <Tabs defaultActiveKey="1">
            <TabPane
              tab={<Status count={sortedCategoryPreselectionOrder.length} status="all" />}
              key="0"
            >
              <CountryTable
                categoryPreselectionOrder={sortedCategoryPreselectionOrder}
                onButtonClick={setPreselectionOrder}
              />
            </TabPane>
            {countriesWithCompletePreselectionOrder.length && (
              <TabPane
                tab={
                  <Status
                    count={countriesWithCompletePreselectionOrder.length}
                    status="completePreselectionOrder"
                  />
                }
                key="1"
              >
                <CountryTable
                  categoryPreselectionOrder={countriesWithCompletePreselectionOrder}
                  onButtonClick={setPreselectionOrder}
                />
              </TabPane>
            )}
            {countriesWithPartialPreselectionOrder.length && (
              <TabPane
                tab={
                  <Status
                    count={countriesWithPartialPreselectionOrder.length}
                    status="partialPreselectionOrder"
                  />
                }
                key="2"
              >
                <CountryTable
                  categoryPreselectionOrder={countriesWithPartialPreselectionOrder}
                  onButtonClick={setPreselectionOrder}
                />
              </TabPane>
            )}
            {countriesWithNonePreselectionOrder.length && (
              <TabPane
                tab={
                  <Status
                    count={countriesWithNonePreselectionOrder.length}
                    status="nonePreselectionOrder"
                  />
                }
                key="3"
              >
                <CountryTable
                  categoryPreselectionOrder={countriesWithNonePreselectionOrder}
                  onButtonClick={setPreselectionOrder}
                />
              </TabPane>
            )}
          </Tabs>
          <PreselectionOrderModal
            key={modalCountry}
            isVisible={isModalVisible}
            sortedCategories={
              categoryPreselectionOrder.find(({ country }) => country === modalCountry)?.sorted ||
              []
            }
            unsortedCategories={
              categoryPreselectionOrder.find(({ country }) => country === modalCountry)?.unsorted ||
              []
            }
            countryISO={modalCountry}
            editedCategoryId={categoryId}
            onAccept={(categoryOrder, fallbackState) => {
              setIsModalVisible(false);
              dispatchOnDraftSiteId(updateCountrySettingsRequest)({
                model: {
                  country: modalCountry,
                  countrySettings: categoryOrder.map((orderedCategoryId, idx) => ({
                    categoryId: orderedCategoryId,
                    preselectionOrder: idx + 1,
                    useForFallback: fallbackState[orderedCategoryId],
                  })),
                },
                onComplete: () => dispatch(siwActions.resetWidgetSession()),
              });
            }}
            onCancel={() => {
              setIsModalVisible(false);
            }}
          />
        </LabelWrapper>
      </div>
    </AutoSaveForm>
  );
};

const styles = stylesheet({
  wrapper: {
    $nest: {
      '& > *': {
        marginBottom: '8px',
      },
      '& > *:last-child': {
        marginBottom: 0,
      },
    },
  },
});

const { dotStatusGreen, dotStatusOrange, dotStatusRed } = defaultTheme.color;

const Status: React.FC<{
  count?: number;
  status:
    | 'completePreselectionOrder'
    | 'partialPreselectionOrder'
    | 'nonePreselectionOrder'
    | 'all';
}> = ({ count = 0, status }) => {
  const dotColor =
    status === 'completePreselectionOrder'
      ? dotStatusGreen
      : status === 'partialPreselectionOrder'
      ? dotStatusOrange
      : status === 'nonePreselectionOrder'
      ? dotStatusRed
      : '';
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {status !== 'all' && (
        <div
          style={{
            width: '6px',
            height: '6px',
            borderRadius: '3px',
            background: dotColor,
            marginRight: '6px',
          }}
        />
      )}
      {status === 'all' && <FormattedMessage id={'ALL'} values={{ count }} />}
      {status === 'completePreselectionOrder' && (
        <FormattedMessage id={'PRESELECTION_ORDER_STATUS_COMPLETE'} values={{ count }} />
      )}
      {status === 'partialPreselectionOrder' && (
        <FormattedMessage id={'PRESELECTION_ORDER_STATUS_PARTIAL'} values={{ count }} />
      )}
      {status === 'nonePreselectionOrder' && (
        <FormattedMessage id={'PRESELECTION_ORDER_STATUS_NONE'} values={{ count }} />
      )}
    </div>
  );
};

type TableItem = CategoryPreselectionOrder[0];

const CountryTable: React.FC<{
  categoryPreselectionOrder: CategoryPreselectionOrder;
  onButtonClick: (country: string) => void;
}> = ({ categoryPreselectionOrder, onButtonClick }) => {
  return (
    <Table<TableItem> dataSource={categoryPreselectionOrder}>
      <Column<TableItem>
        title={<FormattedMessage id="COUNTRY_NAME" />}
        key="country"
        render={(_, record) => countyCodeToNameMapping[record.country]}
        width={350}
      />
      <Column<TableItem>
        title={<FormattedMessage id="PRESELECTION_ORDER_STATUS" />}
        key="status"
        render={(_, record) => <Status status={getStatus(record)} />}
      />
      <Column<TableItem>
        title={''}
        key="button"
        className={countryTableStyles.setPreselectionOrderRow}
        render={(_, record) => (
          <a
            onClick={() => {
              onButtonClick(record.country);
            }}
          >
            <FormattedMessage id="SET_PRESELECTION_ORDER" />
          </a>
        )}
      />
    </Table>
  );
};

const countryTableStyles = stylesheet({
  setPreselectionOrderRow: {
    textAlign: 'right',
  },
});
