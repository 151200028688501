export const supportedLocales = [
  'cs-CZ',
  'da-DK',
  'de-DE',
  'el-GR',
  'es-ES',
  'en-AU',
  'en-GB',
  'en-IE',
  'en-US',
  'et-EE',
  'fi-FI',
  'fr-FR',
  'hr-HR',
  'is-IS',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'lt-LT',
  'lv-LV',
  'nb-NO',
  'nl-NL',
  'no-NO',
  'nn-NO',
  'pl-PL',
  'ru-RU',
  'sl-SI',
  'sk-SK',
  'sv-SE',
  'sv-FI',
  'zh-CN',
];
