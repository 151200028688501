import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { Button, Input } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { bigLabelLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { getFormikError } from '@src/utils/forms';
import { nameValidation } from '.';

const InputField = withFormItem(Input, bigLabelLayout);

interface Props {
  onSubmit: (name: string) => void;
  forbidenNames?: string[];
}

export const CreateForm: React.FunctionComponent<Props> = ({ onSubmit, forbidenNames = [] }) => (
  <Formik
    onSubmit={({ name }) => onSubmit(name.trim())}
    initialValues={{ name: '' }}
    validationSchema={nameValidation(forbidenNames)}
  >
    {({ handleSubmit, errors, touched }) => (
      <form onSubmit={handleSubmit} className={styles.formWrapper}>
        <Field name="name">
          {({ field }: FieldProps<string>) => (
            <InputField
              {...field}
              labelAlign="left"
              label={<FormattedMessage id="NAME" />}
              error={getFormikError(touched.name, errors.name)}
              width={300}
            />
          )}
        </Field>
        <div className={styles.buttonContainer}>
          <Button type="primary" htmlType="submit" className={styles.submitButton}>
            <FormattedMessage id="ADD" />
          </Button>
        </div>
      </form>
    )}
  </Formik>
);

const styles = stylesheet({
  buttonContainer: {
    display: 'flex',
  },
  submitButton: {
    marginLeft: 'auto',
    marginTop: '10px',
  },
  formWrapper: {
    width: 250,
  },
});
