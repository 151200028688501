import { Button, Input, Select } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import type { FieldArrayRenderProps, FormikContextType } from 'formik';
import React from 'react';
import { style as tss } from 'typestyle';
import type { CustomsDeclarationItem, FormValues, OptionsType } from './types';

type RowItem = CustomsDeclarationItem & {
  index: number;
};

export const buildColumns = (
  context: FormikContextType<FormValues>,
  arrayHelpers: FieldArrayRenderProps,
  countriesOptions: OptionsType
): ColumnType<RowItem>[] => {
  const { handleBlur, handleChange, values, setFieldValue } = context;

  return [
    {
      title: 'Amount',
      dataIndex: 'quantity',
      ellipsis: true,
      render: (_, item) => (
        <Input
          placeholder="-"
          type="number"
          onChange={handleChange}
          onBlur={handleBlur}
          min={1}
          value={item.quantity}
          name={`items.${item.index}.quantity`}
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, item) => (
        <Input
          placeholder="-"
          onChange={handleChange}
          onBlur={handleBlur}
          value={item.name}
          name={`items.${item.index}.name`}
        />
      ),
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      render: (_, item) => (
        <Input
          placeholder="-"
          onChange={handleChange}
          onBlur={handleBlur}
          value={item.sku}
          name={`items.${item.index}.sku`}
        />
      ),
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      render: (_, item) => (
        <Input
          placeholder="-"
          onChange={handleChange}
          onBlur={handleBlur}
          value={item.brand}
          name={`items.${item.index}.brand`}
        />
      ),
    },
    {
      title: 'HS Tariff Code',
      width: 150,
      dataIndex: 'hs_tariff_number',
      render: (_, item) => (
        <Input
          placeholder="-"
          onChange={handleChange}
          onBlur={handleBlur}
          value={item.hs_tariff_number}
          name={`items.${item.index}.hs_tariff_number`}
        />
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      ellipsis: true,
      render: (_, item) => (
        <Input
          placeholder="-"
          onChange={handleChange}
          onBlur={handleBlur}
          value={item.category}
          name={`items.${item.index}.category`}
        />
      ),
    },
    {
      title: 'Value',
      dataIndex: 'unit_value',
      render: (_, item) => (
        <Input
          type="number"
          min={0}
          placeholder="-"
          onChange={handleChange}
          onBlur={handleBlur}
          value={item.unit_value}
          name={`items.${item.index}.unit_value`}
        />
      ),
    },
    {
      title: 'Net/Gross weight (g)',
      width: 250,
      dataIndex: 'unit_gross_weight',
      render: (_, item) => (
        <div className={styles.weightContainer}>
          <Input
            type="number"
            placeholder="-"
            min={0}
            onChange={handleChange}
            onBlur={handleBlur}
            value={item.unit_net_weight}
            name={`items.${item.index}.unit_net_weight`}
          />
          <Input
            type="number"
            placeholder="-"
            min={0}
            onChange={handleChange}
            onBlur={handleBlur}
            value={item.unit_gross_weight}
            name={`items.${item.index}.unit_gross_weight`}
          />
        </div>
      ),
    },
    {
      title: 'Country of origin',
      dataIndex: 'country_of_origin',
      width: 150,
      render: (_, item) => (
        <Select
          id={`items.${item.index}.country_of_origin`}
          options={countriesOptions}
          value={item.country_of_origin}
          className={styles.fullWidth}
          optionFilterProp="label"
          placeholder="-"
          showSearch
          onBlur={handleBlur}
          onChange={value => setFieldValue(`items.${item.index}.country_of_origin`, value)}
        />
      ),
    },
    {
      render: (_, { index }) => (
        <Button
          type="link"
          disabled={(values.items?.length ?? 0) <= 1}
          onClick={() => arrayHelpers.remove(index)}
        >
          Remove
        </Button>
      ),
    },
  ];
};

const styles = {
  fullWidth: tss({
    width: '100%',
  }),
  weightContainer: tss({
    display: 'flex',
    gap: '8px',
  }),
};
