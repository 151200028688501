import { PlusOutlined } from '@ant-design/icons';
import * as React from 'react';
import { classes, stylesheet } from 'typestyle';

import { FormattedMessage } from '@src/i18n';
import themeDefault from '@src/styles/theme.default';

interface Props extends React.InputHTMLAttributes<HTMLDivElement> {
  onClick?: (...args: any[]) => void;
  text?: string | JSX.Element;
  hideIcon?: boolean;
  className?: string;
}

export const AddButton: React.FunctionComponent<Props> = ({
  text = <FormattedMessage id="ADD_NEW" />,
  onClick,
  hideIcon,
  className,
  ...rest
}) => (
  <div onClick={onClick} className={classes(styles.baseStyles, className)} {...rest}>
    {!hideIcon && <PlusOutlined className={styles.plusIcon} />} {text}
  </div>
);

const styles = stylesheet({
  baseStyles: {
    padding: '15px',
    fontWeight: 500,
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  plusIcon: {
    fontSize: '16px',
    marginRight: '4px',
    color: themeDefault.color.primary,
  },
});
