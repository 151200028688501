import { DownOutlined } from '@ant-design/icons';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { Button, Dropdown, Menu, Spin } from 'antd';
import * as React from 'react';

import { SitesSelectors } from '@src/modules/sites';
import { getLabelToken } from '@src/services/gentoken-service';
import { useSelector } from '@src/utils/hooks';
import { DocumentModel } from '../models';

interface Props {
  record: DocumentModel;
  onDownload?: () => void;
}

export const LabelDownload: React.FunctionComponent<Props> = props => {
  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);

  const [isInitialized, setIsInitialized] = React.useState(false);
  const [possibleFormats, setPossibleFormats] = React.useState([
    { format: '.pdf' as const, link: '' },
    { format: '.png' as const, link: '' },
    { format: '.svg' as const, link: '' },
    { format: '.zpl' as const, link: '' },
  ]);

  const loadFile = (format: '.pdf' | '.png' | '.svg' | '.zpl') => {
    return getLabelToken(siteId, props.record.url, format).then(res => sanitizeUrl(res.url));
  };

  return (
    <Dropdown
      onVisibleChange={isVisible => {
        if (isVisible && !isInitialized) {
          Promise.all(
            possibleFormats.map(async formatObj => {
              const url = await loadFile(formatObj.format);
              return { format: formatObj.format, link: url };
            })
          ).then(newState => {
            setPossibleFormats(newState);
            setIsInitialized(true);
          });
        }
      }}
      overlay={
        <Menu>
          {isInitialized ? (
            possibleFormats.map(formatObj => {
              return (
                <Menu.Item key={formatObj.format}>
                  <a href={formatObj.link} target="_blank">
                    {formatObj.format}
                  </a>
                </Menu.Item>
              );
            })
          ) : (
            <Menu.Item>
              <Spin size="small" />
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Button>
        Download label as: <DownOutlined />
      </Button>
    </Dropdown>
  );
};
