import * as React from 'react';

export const useScrollIntoView = () => {
  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, [window.location.pathname]);
  return scrollRef;
};
