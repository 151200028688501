import { services } from '@src/services';
import { Button, Table } from 'antd';
import { FieldArray, useFormikContext } from 'formik';
import React, { FC, Fragment } from 'react';
import { style as tss } from 'typestyle';
import { buildColumns } from './build-columns';
import { emptyItem } from './constants';
import type { FormValues } from './types';

export const ItemsDetails: FC = () => {
  const context = useFormikContext<FormValues>();

  const countriesOptions = services.dictionariesService.getCountriesOptions();

  return (
    <div className={styles.detailsSection}>
      <h3>Items details</h3>
      <FieldArray
        name="items"
        render={arrayHelpers => {
          const columns = buildColumns(context, arrayHelpers, countriesOptions);

          const dataSource = context.values.items?.map((item, index) => ({ ...item, index }));

          return (
            <Fragment>
              <Table
                className={styles.headerCell}
                columns={columns}
                dataSource={dataSource}
                pagination={false}
              />
              <Button
                className={styles.addNewItemButton}
                onClick={() => arrayHelpers.push(emptyItem)}
              >
                Add new item
              </Button>
            </Fragment>
          );
        }}
      />
    </div>
  );
};

const styles = {
  detailsSection: tss({
    marginTop: '16px',
  }),
  addNewItemButton: tss({
    marginTop: '16px',
  }),
  headerCell: tss({
    $nest: {
      '.ant-table-thead th': {
        padding: '8px',
        lineHeight: '16px',
        fontWeight: 500,
        fontSize: '14px',
      },
      '.ant-table-tbody td': {
        padding: '8px 4px',
      },
    },
  }),
};
