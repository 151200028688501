import { SelectProps } from '@src/controls';
import { SelectOption } from '@src/controls/select.js';
import * as moment from 'moment';
import { fromPairs, groupBy, mergeAll, pipe, prop, reduce, sortBy, toPairs, uniq } from 'ramda';
// countries are taken from https://restcountries.eu/rest/v2?fields=name;alpha2Code;currencies;region
import countriesJSON from '../../fixtures/countries.json';
import regionsJSON from '../../fixtures/regions.json';

export const getAllCountries = () =>
  sortBy(
    prop('name'),
    countriesJSON.map(country => ({
      name: country.name,
      code: country.alpha2Code,
      region: country.region,
    }))
  );

type Country = ReturnType<typeof getAllCountries>[number];

export const getCountriesOptions = (): SelectOption<string>[] =>
  getAllCountries().map(country => ({ value: country.code, label: country.name }));

export const getCountryCodeToCountryNameMapping = () =>
  fromPairs(getAllCountries().map(country => [country.code, country.name]));

export const getCountryNameToCountryCodeMapping = () =>
  fromPairs(getAllCountries().map(country => [country.name, country.code]));

export const groupCountriesByRegion = pipe(
  groupBy<Country>(country => country.region),
  toPairs,
  reduce<
    [string, Country[]],
    { [region: string]: { label: string; value: string; optionLabel: string }[] }
  >(
    (whole, [region, cn]) => ({
      ...whole,
      [region]: cn.map(country => ({
        value: country.code,
        label: country.name,
        optionLabel: `${country.name}`,
      })),
    }),
    {}
  )
);

export const getCountriesGroupedByRegion = () => groupCountriesByRegion(getAllCountries());

export const getCountryCodes = () => getAllCountries().map(country => country.code);

export const getCountryNames = () => getAllCountries().map(country => country.name);

const getCurrencyCodes = () =>
  countriesJSON.flatMap(country => country.currencies.map(currency => currency.code))
    .filter(currency => currency !== '(none)')
    .sort();

const getCountriesDistinctCurrenciesCodes = pipe(getCurrencyCodes, uniq);

export const getCurrenciesOptions = (): SelectProps['options'] =>
  getCountriesDistinctCurrenciesCodes().map(currency => ({ value: currency, label: currency }));

export const getTimezonesOptions = () => moment.tz.names().map(tz => ({ label: tz, value: tz }));

export const getFirstTimezonePerCountry = () =>
  mergeAll(
    moment.tz.countries().map(country => ({ [country]: moment.tz.zonesForCountry(country)[0] }))
  );

export const getRegionsOptions = (country: string): SelectOption<string>[] => {
  switch (country) {
    case 'US':
      return regionsJSON.US.map(({ code, name }) => ({ value: code, label: name }));
    case 'CA':
      return regionsJSON.CA.map(({ code, name }) => ({ value: code, label: name }));
    default:
      return [];
  }
};

export const getRegionsFromAllCountries = (): Record<string, SelectOption<string>[]> => ({
  US: getRegionsOptions('US'),
  CA: getRegionsOptions('CA'),
});

export const isCountryRegionSupported = (country: string) => {
  return Object.keys(regionsJSON).includes(country);
};
