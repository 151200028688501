import { flatten } from 'ramda';
import { createSelector } from 'reselect';
import lift, { Option } from 'space-lift';

import { stringUtils } from '@src/utils';
import { RootState } from '..';
import { ParcelModel, ShipmentModel } from '../../models';
import { configSelectors } from '../config';

export const getShipmentById = (state: RootState, id: string) => {
  return state.shipments.byId[id];
};

export const getShipmentLineItems = createSelector(getShipmentById, shipment => {
  if (!shipment) {
    return;
  }

  const shipmentLineItems = Option(shipment.lineItems)
    .getOrElse([])
    .map(lI => ({ parcelId: '', lineItem: lI }));

  const parcelsLineItems = lift(shipment.parcels)
    .map(p =>
      lift(p)
        .get('lineItems')
        .getOrElse([])
        .map(lI => ({ parcelId: p.id, lineItem: lI }))
    )
    .flatten()
    .value();

  return shipmentLineItems.concat(parcelsLineItems);
});

export const getShippingMethodForShipment = (shipment: ShipmentModel) =>
  shipment.parcels.reduce(
    (accumulator: string, { deliveries }) =>
      deliveries.reduce((result: string, { shippingMethod }) => (result = shippingMethod), ''),
    ''
  );

export const getShippingTypeForShipment = (state: RootState, shipmentId: string = '') => {
  const shipment = getShipmentById(state, shipmentId);
  const parcelTypes = configSelectors.getShippingMethodsParcelTypes(state);
  if (shipment && parcelTypes) {
    return configSelectors.getShippingMethodsParcelTypes(state)[
      getShippingMethodForShipment(shipment)
    ];
  }
  return undefined;
};

const getMultipleRequests = (state: RootState) => state.shipments.multipleRequests;
export const getMultipleRequestsStatus = createSelector(
  getMultipleRequests,
  state => state.requestsState
);
export const getMultipleRequestsProgress = createSelector(
  getMultipleRequests,
  state => state.progress
);

export const getMultipleRequestsLabelsFileNames = createSelector(getMultipleRequests, state =>
  state.labelsFileNames
    ? state.labelsFileNames.map(fileName => stringUtils.getFileStem(fileName) + '.pdf')
    : []
);

export const getParcelById = (state: RootState, parcelId: string | null, shipmentId: string) => {
  const shipment = getShipmentById(state, shipmentId);
  return shipment ? shipment.parcels.find(parcel => parcel.id === parcelId) : null;
};

export const getDeliveryForParcel = createSelector(getParcelById, possibleParcel =>
  Option(possibleParcel).fold(
    () => null,
    parcel => getFirstDeliveryForParcel(parcel)
  )
);

export const getFirstDeliveryForParcel = (parcel: ParcelModel) =>
  lift(parcel.deliveries).first().get();

export const getDeliveryTrackingNumbersForShipment = (state: RootState, shipmentId: string) => {
  const shipment = getShipmentById(state, shipmentId);
  return shipment
    ? flatten(
        shipment.parcels.map(
          parcel =>
            parcel &&
            parcel.deliveries
              .map(delivery => delivery.trackingNumber)
              .filter(trackingNumber => trackingNumber)
        )
      )
    : [];
};

export const getExternalIdForApoteket = createSelector(getShipmentById, shipment =>
  shipment ? shipment.externalId : ''
);

export const getExternalOrderIds = createSelector(getShipmentById, shipment => {
  const externalOrders = shipment ? shipment.contents.externalOrders || [] : [];
  return externalOrders.map(order => order.externalId);
});
