import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;

import {
  ShipmentSummaryModel,
  TransportOrderListFiltersModel,
  TransportOrderModel,
} from '../../models';
import {
  CLEAR_ACTIVE_TRANSPORT_ORDER_FILTERS,
  CLEAR_TRANSPORT_ORDERS_LIST,
  CREATE_TRANSPORT_ORDER_ERROR,
  CREATE_TRANSPORT_ORDER_REQUEST,
  CREATE_TRANSPORT_ORDER_SUCCESS,
  GET_TRANSPORT_ORDER_ERROR,
  GET_TRANSPORT_ORDER_LIST_ERROR,
  GET_TRANSPORT_ORDER_LIST_REQUEST,
  GET_TRANSPORT_ORDER_LIST_SUCCESS,
  GET_TRANSPORT_ORDER_REQUEST,
  GET_TRANSPORT_ORDER_SUCCESS,
  SET_ACTIVE_TRANSPORT_ORDER_FILTERS,
  UPDATE_TRANSPORT_ORDER_ERROR,
  UPDATE_TRANSPORT_ORDER_LIST_PAGINATION,
  UPDATE_TRANSPORT_ORDER_REQUEST,
  UPDATE_TRANSPORT_ORDER_SUCCESS,
  GET_SHIPMENTS_SUMMARIES_LIST_BY_TOS_SUCCESS,
  GET_SHIPMENTS_SUMMARIES_LIST_BY_TOS_ERROR,
} from './types';

export const actionCreators = {
  getTransportOrderListRequest: createAction(
    GET_TRANSPORT_ORDER_LIST_REQUEST,
    resolve =>
      (
        {
          pageSize = 10,
          pageNumber = 1,
          direction,
          searchQuery,
          filters,
        }: {
          pageSize: number;
          pageNumber: number;
          direction?: string;
          searchQuery?: string;
          filters?: TransportOrderListFiltersModel;
        } = { pageSize: 10, pageNumber: 1 }
      ) =>
        resolve({
          pageSize,
          pageNumber,
          direction,
          searchQuery,
          filters,
        })
  ),
  getTransportOrderListSuccess: createAction(
    GET_TRANSPORT_ORDER_LIST_SUCCESS,
    resolve => (payload: { orders: TransportOrderModel[]; count: number }) => resolve(payload)
  ),
  getTransportOrderListError: createAction(
    GET_TRANSPORT_ORDER_LIST_ERROR,
    resolve => (error: string) => resolve(error)
  ),

  getTransportOrderRequest: createAction(
    GET_TRANSPORT_ORDER_REQUEST,
    resolve =>
      ({
        id,
        onComplete,
      }: {
        id: string;
        onComplete?: (transportOrder: TransportOrderModel | null) => void;
      }) =>
        resolve({ id }, { onComplete })
  ),
  getTransportOrderSuccess: createAction(
    GET_TRANSPORT_ORDER_SUCCESS,
    resolve => (transportOrder: TransportOrderModel) => resolve(transportOrder)
  ),
  getTransportOrderError: createAction(
    GET_TRANSPORT_ORDER_ERROR,
    resolve => (error: string) => resolve(error)
  ),

  createTransportOrderRequest: createAction(
    CREATE_TRANSPORT_ORDER_REQUEST,
    resolve => (transportOrder: TransportOrderModel) => resolve(transportOrder)
  ),
  createTransportOrderSuccess: createAction(
    CREATE_TRANSPORT_ORDER_SUCCESS,
    resolve => (transportOrder: TransportOrderModel) => resolve(transportOrder)
  ),
  createTransportOrderError: createAction(
    CREATE_TRANSPORT_ORDER_ERROR,
    resolve => (error: string) => resolve(error)
  ),

  updateTransportOrderRequest: createAction(
    UPDATE_TRANSPORT_ORDER_REQUEST,
    resolve => (transportOrder: TransportOrderModel) => resolve(transportOrder)
  ),
  updateTransportOrderSuccess: createAction(
    UPDATE_TRANSPORT_ORDER_SUCCESS,
    resolve => (transportOrder: TransportOrderModel) => resolve(transportOrder)
  ),
  updateTransportOrderError: createAction(
    UPDATE_TRANSPORT_ORDER_ERROR,
    resolve => (error: string) => resolve(error)
  ),

  setActiveTransportOrderFilters: createAction(
    SET_ACTIVE_TRANSPORT_ORDER_FILTERS,
    resolve => (filters: TransportOrderListFiltersModel) => resolve(filters)
  ),
  clearActiveTransportOrderFilters: createAction(CLEAR_ACTIVE_TRANSPORT_ORDER_FILTERS),
  updateTransportOrderListPagination: createAction(
    UPDATE_TRANSPORT_ORDER_LIST_PAGINATION,
    resolve => (page: number) => resolve(page)
  ),
  clearTransportOrdersList: createAction(CLEAR_TRANSPORT_ORDERS_LIST),
  getShipmentSummariesForTosSuccess: createAction(
    GET_SHIPMENTS_SUMMARIES_LIST_BY_TOS_SUCCESS,
    resolve => (payload: { tosId: string; shipments: ShipmentSummaryModel[] }) => resolve(payload)
  ),
  getShipmentSummariesForTosError: createAction(GET_SHIPMENTS_SUMMARIES_LIST_BY_TOS_ERROR),
};
