import { UploadOutlined } from '@ant-design/icons';
import { isEmpty } from 'ramda';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { Button, FormItem, UploadDragger } from '../../../controls';
import { LABELS, MESSAGES } from '../../../dictionaries';

type Props = {
  storeFile?: (file: File) => void | null;
};

type State = {
  fileList: File[];
  uploading: boolean;
};

export class Uploader extends React.Component<Props, State> {
  state = {
    fileList: [],
    uploading: false,
  };

  handleUpload = () => {
    if (!this.props.storeFile) {
      return;
    }

    const { fileList } = this.state;
    if (isEmpty(fileList)) {
      return;
    }

    this.setState({
      uploading: true,
    });

    const file = fileList[0];
    if (this.props.storeFile && file) {
      this.props.storeFile(file);
    }

    this.setState({
      uploading: false,
    });
  };

  handleRemove = (file: any) => {
    this.setState(({ fileList }) => {
      const idx = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(idx, 1);
      return {
        fileList: newFileList,
      };
    });
  };

  beforeUpload = (file: any) => {
    this.setState({
      fileList: [file],
    });
    return false;
  };

  render() {
    const { uploading, fileList } = this.state;

    return (
      <FormItem label={LABELS.ATTACHMENT} labelCol={{ span: 24 }}>
        <UploadDragger
          onRemove={this.handleRemove}
          beforeUpload={this.beforeUpload}
          fileList={fileList}
          className="t_upload-file"
        >
          <Button>
            <UploadOutlined style={{ fontSize: '16px' }} /> {LABELS.SELECT_ATTACHMENT}
          </Button>
          <p className={styles.paragraph}> {MESSAGES.MAX_FILE_SIZE(20)} </p>
        </UploadDragger>
        <Button
          style={{ margin: '10px 0' }}
          type="primary"
          onClick={this.handleUpload}
          disabled={isEmpty(fileList)}
          loading={uploading}
        >
          {uploading ? 'Uploading' : 'Start Upload'}
        </Button>
      </FormItem>
    );
  }
}

const styles = {
  paragraph: tss({ paddingTop: '10px' }),
};
