import { useFormatMessage } from '@src/i18n';
import { Col, Row } from 'antd';
import * as React from 'react';
import { style as tss } from 'typestyle';
import { CarrierProductMappingsCreate } from './create';
import { CarrierProductMappingsTable } from './table';
import { CarrierProductMappingsUpdate } from './update';
import { useSiteId } from '@src/utils/hooks';
import { useQuery } from 'react-query';
import { listCarrierProductMapping } from '@src/services/selfcare-service';

export const CarrierProductMappings = () => {
  const formatMessage = useFormatMessage();

  const [updateModalState, setUpdateModalState] = React.useState({
    isVisible: false,
    carrierProductMappingId: '',
  });

  const siteId = useSiteId();

  const { data, isLoading } = useQuery(
    ['carrier_product_mapping.list', siteId],
    () => listCarrierProductMapping(siteId),
    { enabled: !!siteId, keepPreviousData: true }
  );

  return (
    <Row className={styles.section} data-cy="product-mappings-external-section">
      <Col span="4">{formatMessage('MAPPED_PRODUCTS')}</Col>
      <Col span="20">
        <CarrierProductMappingsTable
          onRefClick={carrierProductMappingId => {
            setUpdateModalState({ isVisible: true, carrierProductMappingId });
          }}
          isLoading={isLoading}
          carrierProductMappings={data}
        />
        <CarrierProductMappingsCreate siteId={siteId} carrierProductMapping={data} />
        <CarrierProductMappingsUpdate
          isVisible={updateModalState.isVisible}
          carrierProductMappingId={updateModalState.carrierProductMappingId}
          onCancel={() => setUpdateModalState({ isVisible: false, carrierProductMappingId: '' })}
          data={data}
          siteId={siteId}
        />
      </Col>
    </Row>
  );
};

const styles = {
  section: tss({
    marginBottom: '32px',
  }),
};
