import { TagsOutlined } from '@ant-design/icons';
// tslint:disable:jsx-no-multiline-js
import { Card } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { ContainerActions, ListFixedHeader } from '@src/components';
import { Modal, modalConfirm } from '@src/controls';
import { InputFieldForm } from '@src/forms';
import { UserTagModel } from '@src/models';
import { tagsActions, tagsSelectors } from '@src/modules/tags';
import { isValidFormState } from '@src/utils/validation';
import EmptyState from '../../components/empty-state';
import ErrorState from '../../components/error-state';
import { RootState } from '../../modules';
import { TagForm } from './components/create-tag-form';
import { TagsTable } from './components/tags-table';
import { intl } from '@src/i18n';

const mapStateToProps = (state: RootState) => ({
  siteTags: tagsSelectors.getSiteTags(state),
  error: state.tags.error,
  isLoading: state.tags.isFetching,
  pageSize: state.app.pageSize,
  siteId: state.sites.selectedSiteId,
});

const dispatchProps = {
  listSiteTags: tagsActions.listSiteTagsRequest,
  createSiteTag: tagsActions.createSiteTagRequest,
  renameSiteTag: tagsActions.renameSiteTagRequest,
  deleteSiteTags: tagsActions.deleteSiteTagsRequest,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;
type State = {
  currentPage: number;
  modalOpen: boolean;
  modalMode?: 'Edit' | 'Create';
  editingKey?: string;
};

@observer
class TagsContainer extends React.Component<Props, State> {
  state: State = {
    currentPage: 1,
    modalOpen: false,
  };

  @observable tagFormState = InputFieldForm.createFormState();

  componentDidMount() {
    this.props.listSiteTags({ siteId: this.props.siteId || '' });
  }

  updateTagsList = () => {
    const { listSiteTags, siteId } = this.props;
    listSiteTags({ siteId: siteId || '' });
  };

  deleteTagsHandler = (ids: string[]) => {
    modalConfirm({
      title: intl.formatMessage({ id: 'DELETE_TAGS_MODAL_TITLE' }, { tags: ids.length }),
      content: intl.formatMessage({ id: 'DELETE_TAGS_MODAL_CONTENT' }, { tags: ids.length }),
      okText: intl.formatMessage({ id: 'YES' }),
      okType: 'danger',
      cancelText: intl.formatMessage({ id: 'NO' }),
      onOk: () => {
        this.props.deleteSiteTags({ siteId: this.props.siteId || '', ids });
      },
    });
  };

  handlePaginationChange = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
  };

  handleCreateTagClick = () => {
    this.tagFormState.$.inputFieldState.value = '';
    this.setState({ modalOpen: true, modalMode: 'Create' });
  };

  handleEditTagClick = (record: UserTagModel) => {
    this.tagFormState.$.inputFieldState.value = record.name;
    this.setState({ modalOpen: true, modalMode: 'Edit', editingKey: record.id });
  };

  handleSubmit = async (
    event: React.MouseEvent<HTMLElement> | React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    if (!(await isValidFormState(this.tagFormState))) {
      return;
    }
    const { modalMode, editingKey } = this.state;
    const { siteId, createSiteTag, renameSiteTag } = this.props;

    const name = this.tagFormState.$.inputFieldState.$;
    if (modalMode === 'Create') {
      createSiteTag({ siteId: siteId || '', name });
    } else {
      renameSiteTag({ siteId: siteId || '', name, id: editingKey || '' });
    }

    this.setState({ modalOpen: false });
  };

  handleCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const { siteTags, error, isLoading, pageSize } = this.props;
    const { modalMode, modalOpen } = this.state;

    if (error) {
      return <ErrorState message={<p>{error}</p>} />;
    }

    return (
      <div>
        <Modal
          title={`${modalMode} tag`}
          visible={modalOpen}
          onOk={this.handleSubmit}
          onCancel={this.handleCloseModal}
        >
          <TagForm
            formMode={modalMode}
            formState={this.tagFormState}
            onSubmit={this.handleSubmit}
          />
        </Modal>
        <ListFixedHeader
          title="Tags Management"
          isLoading={isLoading}
          IconComponent={TagsOutlined}
          actions={
            <>
              <ContainerActions.Create onCreate={this.handleCreateTagClick} />
            </>
          }
          showSearch={false}
        />
        <Card bordered={false}>
          {siteTags.length > 0 ? (
            <TagsTable
              currentPage={this.state.currentPage}
              onPaginationChange={this.handlePaginationChange}
              total={siteTags.length}
              pageSize={pageSize}
              isLoading={isLoading}
              tagsError={error}
              tags={siteTags}
              onDelete={this.deleteTagsHandler}
              onEditClick={this.handleEditTagClick}
            />
          ) : (
            <EmptyState />
          )}
        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps, dispatchProps)(TagsContainer);
