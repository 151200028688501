import React, { FC, Fragment } from 'react';
import { GlobalOutlined } from '@ant-design/icons';
import { stylesheet as tss } from 'typestyle';
import { ListFixedHeader, Link } from '@src/components';
import { DOMAIN } from '@src/dictionaries';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { CountryList } from '@src/containers/features/components';
import { FormattedMessage } from '@src/i18n';
import { configSelectors } from '@src/modules/config';
import { useSelector } from '@src/utils/hooks';

export const ReceiptFeatures: FC = () => {
  const countries = useSelector(configSelectors.getCountryCount);
  const isFetching = useSelector(state => state.sites.isFetching);

  return (
    <Fragment>
      <StatusBarContainer />
      <ListFixedHeader
        title={DOMAIN.FEATURES}
        IconComponent={GlobalOutlined}
        isLoading={false}
        showSearch={false}
      />
      <Link
        showIcon
        baseStyles
        className={styles.defaultSettingsLink}
        route={{ name: 'RECEIPT_DEFAULT_SETTINGS' }}
      >
        <FormattedMessage id="DEFAULT_SETTINGS" />
      </Link>
      <FormattedMessage className={styles.countriesSettingsHeader} id="COUNTRY_SETTINGS" />
      <CountryList
        isFetching={isFetching}
        countries={countries}
        renderLink={({ countryName }) => (
          <Link
            showIcon
            baseStyles
            route={{ name: 'RECEIPT_COUNTRY_SETTINGS', country: countryName.toLowerCase() }}
          >
            {countryName}
          </Link>
        )}
      />
    </Fragment>
  );
};

const styles = tss({
  defaultSettingsLink: {
    borderBottom: '1px solid rgba(0,0,0,0.09)',
    padding: '22px',
    display: 'block',
  },
  countriesSettingsHeader: {
    margin: '36px 0 14px',
    display: 'block',
    color: 'rgba(0,0,0,0.85)',
  },
});
