import { Timeline } from 'antd';
import { TimelineProps } from 'antd/lib/timeline';
import * as React from 'react';

interface Props extends TimelineProps {
  className?: string;
}

class Component extends React.Component<Props> {
  render() {
    const { children, ...restProps } = this.props;
    return <Timeline {...restProps}>{children}</Timeline>;
  }
}

export default Component;
