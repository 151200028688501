import { GlobalOutlined } from '@ant-design/icons';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { useSelector } from '@src/utils/hooks';

import { ListFixedHeader } from '@src/components';
import { Link } from '@src/components/link';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { DOMAIN } from '@src/dictionaries';
import { FormattedMessage } from '@src/i18n';
import { configSelectors } from '@src/modules/config';
import { CountryList } from './components';

export const CountryListPage: React.FC = () => {
  const countries = useSelector(configSelectors.getCountryCount);
  const isFetching = useSelector(state => state.sites.isFetching);
  return (
    <>
      <StatusBarContainer />
      <div className={styles.componentWrapper}>
        <ListFixedHeader
          title={DOMAIN.FEATURES}
          IconComponent={GlobalOutlined}
          isLoading={false}
          showSearch={false}
        />
        <Link
          showIcon
          baseStyles
          className={styles.defaultSettingsLink}
          route={{ name: 'DEFAULT_SETTINGS' }}
        >
          <FormattedMessage id="DEFAULT_SETTINGS" />
        </Link>
        <FormattedMessage className={styles.countriesSettingsHeader} id="COUNTRY_SETTINGS" />
        <CountryList
          isFetching={isFetching}
          countries={countries}
          renderLink={({ countryName }) => (
            <Link
              showIcon
              baseStyles
              route={{ name: 'COUNTRY_DETAILS', country: countryName.toLowerCase() }}
            >
              {countryName}
            </Link>
          )}
        />
      </div>
    </>
  );
};

const styles = stylesheet({
  componentWrapper: {
    padding: '24px',
  },
  defaultSettingsLink: {
    borderBottom: '1px solid rgba(0,0,0,0.09)',
    padding: '22px',
    display: 'block',
  },
  countriesSettingsHeader: {
    margin: '36px 0 14px',
    display: 'block',
    color: 'rgba(0,0,0,0.85)',
  },
});
