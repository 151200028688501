import { useQuery } from 'react-query';
import { compose, mergeAll, replace } from 'ramda';

import { DeliveryTypeEnum, Step } from '@src/models';
import { useMasterSiteId } from '@src/utils/hooks';
import { MADError } from '@src/utils';
import { humanize } from '@src/utils/string';
import { useFormatMessage } from '@src/i18n';
import { services } from '@src/services';

export const initialParcel: Parcel = {
  progress: 'STEP_PREPARED_BY_MERCHANT',
};

export type Parcel = {
  progress: Step;
};

export type FormValues = {
  deliveryType: DeliveryTypeEnum;
  parcels: Parcel[];
  notificationType: { email: boolean; sms: boolean };
  phoneNumber?: string;
  emailAddress?: string;
};

const getLabelForProgressOption = compose(humanize, replace('STEP_', ''));

type Option<T> = { value: T; label: string };

type GenerateOptionsData = {
  deliveryTypeOptions: Option<DeliveryTypeEnum>[];
  progressOptionsPerDeliveryType: { [name in DeliveryTypeEnum]?: Option<Step>[] };
};

const defaultValues: GenerateOptionsData = {
  deliveryTypeOptions: [],
  progressOptionsPerDeliveryType: {},
};

export const useGetGenerateOptions = (): { data: GenerateOptionsData; isLoading: boolean } => {
  const siteId = useMasterSiteId();
  const formatMessage = useFormatMessage();

  const { data, isLoading } = useQuery({
    queryKey: ['getTrackingNumberOptions', siteId],
    queryFn: () => services.demoService.getTrackingNumberOptions(siteId),
    onError: (error: MADError) => {
      services.notificationService.error(
        formatMessage('TRACKING_PLAYGROUND_ERROR_TRACE_ID', { traceId: error.traceId }),
        formatMessage('TRACKING_PLAYGROUND_ERROR_FETCHING_OPTIONS')
      );
    },
    select: (data): GenerateOptionsData => {
      if (!data.options) {
        return defaultValues;
      }
      return {
        deliveryTypeOptions: data.options
          .map(option => option.delivery_type)
          .map(value => ({ value, label: humanize(value) }))
          .sort((a, b) => a.label.localeCompare(b.label, 'en-US')),
        progressOptionsPerDeliveryType: mergeAll(
          data.options.map(option => ({
            [option.delivery_type]: option.progress.map(value => ({
              value,
              label: getLabelForProgressOption(value),
            })),
          }))
        ),
      };
    },
  });

  if (data) {
    return { data, isLoading };
  }
  return {
    data: defaultValues,
    isLoading: false,
  };
};
