import { TimePicker } from 'antd';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { classes, style as tss } from 'typestyle';

import { AutoSaveFormikEnhancer, inputStyle } from '@src/forms';
import { commons } from '@src/styles';
import { WarehouseCutoffTimesSelect } from './warehouse-cutoff-times-select';

const timeFormat = 'HH:mm';
const fieldName = 'time';

export interface CutoffTimesFormValue {
  time: string;
}

interface OwnProps {
  onChangeDate: (values: CutoffTimesFormValue) => void;
  onAddCutoff: () => void;
  onRemoveCutoff: () => void;
  onCopyTimeForCutoffTimes: (copy: string) => void;
  time: string;
}

export const WarehouseCutoffTimesFormTimePicker: React.FunctionComponent<OwnProps> = ({
  onChangeDate,
  onAddCutoff,
  onRemoveCutoff,
  onCopyTimeForCutoffTimes,
  time,
}) => (
  <div className={classes(styles.timePickerWrapper, inputStyle)}>
    <Formik
      initialValues={{ time }}
      enableReinitialize={true}
      // tslint:disable-next-line: no-empty
      onSubmit={() => {}}
    >
      {(renderProps: FormikProps<CutoffTimesFormValue>) => (
        <AutoSaveFormikEnhancer
          name={fieldName}
          onSave={values => onChangeDate(values)}
          render={({ onBlur }) => (
            <Field name={fieldName}>
              {({ field }: FieldProps<CutoffTimesFormValue>) => (
                <TimePicker
                  getPopupContainer={node => node.parentElement || document.body}
                  {...field}
                  value={
                    renderProps.values.time
                      ? moment(renderProps.values.time, timeFormat)
                      : undefined
                  }
                  className={commons.timePicker}
                  placeholder={'-'}
                  onChange={value => {
                    const formattedValue = value
                      ? moment(value).format(timeFormat)
                      : moment().startOf('day').format(timeFormat);
                    renderProps.setFieldValue(fieldName, formattedValue);
                    onBlur(formattedValue);
                  }}
                  onBlur={event => {
                    const formattedValue = event.currentTarget.value
                      ? event.currentTarget.value
                      : moment().startOf('day').format(timeFormat);
                    renderProps.setFieldValue(fieldName, formattedValue);
                    onBlur(formattedValue);
                  }}
                  showNow={false}
                  format={timeFormat}
                  allowClear={true}
                />
              )}
            </Field>
          )}
        />
      )}
    </Formik>
    <div className={styles.iconSelectorWrapper}>
      <WarehouseCutoffTimesSelect
        onRemoveCutoff={onRemoveCutoff}
        onAddCutoff={onAddCutoff}
        onCopyTimeForCutoffTimes={onCopyTimeForCutoffTimes}
      />
    </div>
  </div>
);
const styles = {
  timePickerWrapper: tss({
    display: 'flex',

    $nest: {
      '.ant-time-picker-input': {
        $nest: {
          '&:hover': {
            backgroundColor: 'inherit',
            boxShadow: 'none',
          },
        },
      },
    },
  }),
  iconSelectorWrapper: tss({
    width: '10%',
  }),
};
