import * as React from 'react';
import { Checkbox, Form, Input, InputNumber, Table, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { FormItemProps } from 'antd/lib/form';
import { Select } from '@src/controls';
import { SelectOption } from '@src/controls/select';
import { matchHexColor } from '@src/utils';
import { InfoCircleOutlined } from '@ant-design/icons';

type BaseFeature = {
  name: string;
  title: string;
  description?: string;
  type: 'checkbox' | 'number' | 'hex' | 'text';
};

interface SelectFeature extends Omit<BaseFeature, 'type'> {
  type: 'select';
  options: SelectOption[];
}

type FeatureDefinition = BaseFeature | SelectFeature;

const featureDefs: Array<FeatureDefinition> = [
  {
    name: 'enable_free_shipping_indicator',
    title: 'Disable multi-step upselling',
    type: 'checkbox',
    description: 'When checked, only first free shipping threshold is taken into account',
  },
  {
    name: 'free_shipping_indicator_message',
    title: 'Message type',
    type: 'select',
    description: 'Controls what message type is displayed',
    options: [
      {
        label: 'Category name',
        value: 'FREE_SHIPPING_INDICATOR_MESSAGE_CATEGORY_NAME',
        rightText: 'Home delivery is free if you shop for additional 200 SEK',
      },
      {
        label: 'Delivery type',
        value: 'FREE_SHIPPING_INDICATOR_MESSAGE_DELIVERY_TYPE',
        rightText: 'Shop for additional 200 SEK to get free home delivery with Budbee',
      },
      {
        label: 'Basic',
        value: 'FREE_SHIPPING_INDICATOR_MESSAGE_BASIC',
        rightText: 'You are 101 SEK away from free shipping',
      },
    ],
  },
  {
    name: 'display_currency_iso_code',
    title: 'Display currency as ISO code',
    description:
      'When enabled currency is presented as ISO code, e.g. SEK, EUR or USD. By default currency symbols are used, e.g. kr, € or $.',
    type: 'checkbox',
  },
  {
    name: 'show_carrier_logos',
    title: 'Show carrier logo',
    type: 'checkbox',
  },
  {
    name: 'bar_visibility',
    title: 'Show progress bar',
    type: 'checkbox',
  },
  {
    name: 'hide_free_text',
    title: 'Hide "Free" in price message',
    description: 'Hide "Free" text when threshold is reached',
    type: 'checkbox',
  },
  { name: 'enable_animation', title: 'Enable animations', type: 'checkbox' },
  {
    name: 'font_family',
    title: 'Custom font family name',
    description: 'Specify together with "Custom font URL" to use nonstandard font',
    type: 'text',
  },
  {
    name: 'custom_font_url',
    title: 'Custom font URL',
    description:
      'For example: "https://fonts.googleapis.com/css2?family=Roboto". Specify together with "Font family" flag',
    type: 'text',
  },
  {
    name: 'font_size',
    title: 'Font size (px)',
    description: 'Set the font size (in pixels)',
    type: 'number',
  },
  {
    name: 'font_weight',
    title: 'Font weight',
    type: 'select',
    options: [
      { value: '400', label: '400' },
      { value: '500', label: '500' },
      { value: '600', label: '600' },
      { value: '700', label: '700' },
    ],
  },
  {
    name: 'bar_color',
    title: 'Neutral color (HEX)',
    description: 'Affects progress bar color when free delivery is not yet reached',
    type: 'hex',
  },
  {
    name: 'bar_color_success',
    title: 'Accent color (HEX)',
    description: 'Affects progress bar color when free delivery is reached',
    type: 'hex',
  },
  {
    name: 'disable_padding',
    title: 'Disable padding',
    description: "Removes the space between widget's content and it's border",
    type: 'checkbox',
  },
];

interface OwnProps {
  initialValues: Record<string, any>;
  loading?: boolean;
  headerTitle?: string;
  onSubmit: (values: Record<string, any>) => void;
}

export const FeaturesTable: React.FC<OwnProps> = ({
  initialValues,
  loading,
  headerTitle,
  onSubmit,
}) => {
  async function callSubmit(name: string) {
    const value = form.getFieldValue(name);
    if (value !== initialValues[name]) {
      await form.validateFields([name]);
      onSubmit({ [name]: value });
    }
  }

  const columns: ColumnType<FeatureDefinition>[] = [
    {
      title: 'Feature name',
      dataIndex: 'title',
      render: (title, record) => {
        if (!record.description) {
          return title;
        }

        return record.description ? (
          <>
            {title}
            <Tooltip title={record.description} overlayStyle={{ maxWidth: '500px' }}>
              <InfoCircleOutlined
                style={{ fontSize: '14px', marginLeft: '5px', color: 'rgba(0, 0, 0, 0.5)' }}
              />
            </Tooltip>
          </>
        ) : (
          title
        );
      },
    },
    {
      title: headerTitle ? headerTitle : 'Default',
      dataIndex: 'name',
      render: (name, record) => {
        if (record.type === 'checkbox') {
          const props = {
            onChange: () => callSubmit(name),
          } as FormItemProps;
          return (
            <Form.Item name={name} valuePropName="checked" style={{ margin: 0 }} {...props}>
              <Checkbox />
            </Form.Item>
          );
        }

        if (record.type === 'select') {
          return (
            <Form.Item name={name} style={{ margin: 0 }}>
              <Select
                options={record.options}
                onChange={() => {
                  callSubmit(name);
                }}
              />
            </Form.Item>
          );
        }

        const props = {
          onBlur: () => callSubmit(name),
        } as FormItemProps;
        return (
          <Form.Item
            name={name}
            style={{ margin: 0 }}
            rules={[
              {
                validator: (rule, value) => {
                  if (record.type === 'hex' && !matchHexColor(value)) {
                    return Promise.reject(
                      new Error('The color should be entered in hex format (starts with "#")')
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
            validateTrigger="onBlur"
            {...props}
          >
            {record.type === 'number' ? <InputNumber /> : <Input />}
          </Form.Item>
        );
      },
    },
  ];

  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      initialValues={{
        ...initialValues,
      }}
    >
      <Table
        rowKey="name"
        dataSource={featureDefs}
        columns={columns}
        loading={loading}
        pagination={false}
      />
    </Form>
  );
};
