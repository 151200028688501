import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';
import * as React from 'react';

const Component: React.StatelessComponent<TooltipProps> = props => {
  const { children, ...restProps } = props;

  return (
    <Tooltip
      getPopupContainer={() => document.getElementById('select-scroll-helper') || document.body}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

export default Component;
