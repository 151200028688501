/* eslint-disable no-console */
import * as React from 'react';
import { style as tss } from 'typestyle';

import { Card } from '@src/controls';
import { SIWSessionModel } from '@src/models';
import { Recipient } from '../widget-configuration-v4';

interface Props {
  htmlSnippet: string | null;
  session: SIWSessionModel | null;
  handleRecipientAddress?: (address?: Recipient) => void;
}

export class WidgetTestClient extends React.Component<Props> {
  componentDidMount() {
    const { htmlSnippet } = this.props;
    const element = document.createElement('div');
    element.innerHTML = htmlSnippet ?? '';
    const wrapper = document.getElementById('widget-wrapper');
    if (wrapper) {
      wrapper.appendChild(element);
      this.cloneTag();
    }
    this.setupJSEvents();
  }

  cloneTag = () => {
    const container = document.getElementById('shipwallet-container');
    this.replaceScriptNode(container);
  };

  setupJSEvents = () => {
    this.props.handleRecipientAddress?.(undefined);
    if (window._sw) {
      window._sw(api => {
        api.on('address_changed', address => console.log('address changed: ', address));
        api.on('shipping_option_changed', option =>
          console.log('shipping option changed: ', option)
        );
        api.on('loaded', () => console.log('widget loaded'));
        api.on('door_code_changed', doorCode => console.log('door code changed: ', doorCode));
        api.on('courier_instructions_changed', courierInstructions =>
          console.log('courier instructions changed: ', courierInstructions)
        );
        api.on('no_shipping_options', () => console.log('no shipping options'));
        api.on('summary_changed', (data, meta) => {
          console.log('summary_changed', data, meta);
          if (data.delivery_address || meta.delivery_address_changed) {
            this.props.handleRecipientAddress?.(data.delivery_address);
          }
        });
        // tslint:enable:no-console
      });
    }
  };

  replaceScriptNode = (node: any) => {
    if (this.isScriptNode(node) && !this.isExternalScript(node)) {
      if (node.parentNode) {
        node.parentNode.replaceChild(this.cloneScriptNode(node), node);
      }
    } else {
      let i = 0;
      const children = node.childNodes;
      while (i < children.length) {
        this.replaceScriptNode(children[i++]);
      }
    }
    return node;
  };

  isScriptNode = (node: HTMLScriptElement) => {
    return node.tagName === 'SCRIPT';
  };

  isExternalScript = (node: HTMLScriptElement) => {
    return !!node.src && node.src !== '';
  };

  cloneScriptNode = (node: HTMLScriptElement) => {
    const script = document.createElement('script');
    script.text = node.innerHTML;
    for (let i = node.attributes.length - 1; i >= 0; i--) {
      script.setAttribute(node.attributes[i].name, node.attributes[i].value);
    }
    return script;
  };

  render() {
    return (
      <Card>
        <div id="widget-wrapper" className={styles.widget} />
      </Card>
    );
  }
}

const styles = {
  widget: tss({
    $nest: {
      '& #shipwallet-container': {
        maxWidth: '640px',
        margin: '0 auto',
      },
    },
  }),
};
