import { withFieldStateInput, withFieldStateInputNumber, withFormItem } from '@src/decorators';
import { FieldState, FormState } from 'formstate';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { style, stylesheet } from 'typestyle';

import { Input, InputNumber, Label, Table, TableEditableCell } from '../../../controls';
import { DimensionsModel } from '../../../models';

const InputField = withFormItem(withFieldStateInput(Input));
const NumberField = withFormItem(withFieldStateInputNumber(InputNumber));

export class ConsignmentDetailsFormState extends FormState<{
  numberOfParcels: FieldState<number>;
  dimensions: FieldState<DimensionsModel>;
  weight: FieldState<number>;
  freeText: FieldState<string>;
}> {
  static create = (
    {
      numberOfParcels,
      dimensions,
      weight,
    }: {
      numberOfParcels: number;
      dimensions: DimensionsModel;
      weight: number;
    } = { numberOfParcels: 0, dimensions: { height: 0, width: 0, length: 0 }, weight: 0 }
  ): ConsignmentDetailsFormState => {
    return new FormState({
      numberOfParcels: new FieldState(numberOfParcels),
      dimensions: new FieldState(dimensions),
      weight: new FieldState(weight),
      freeText: new FieldState(''),
    });
  };
}

interface Props {
  size?: 'middle' | 'small';
  formState: ConsignmentDetailsFormState;
  readOnly?: boolean;
  initialMode?: 'edit' | 'save';
}

@observer
export class ConsignmentDetailsTable extends React.Component<Props, {}> {
  public static defaultProps: Partial<Props> = {
    readOnly: false,
    size: 'middle',
    initialMode: 'edit',
  };

  @observable editable = this.props.initialMode === 'edit';

  componentDidUpdate(prevProps: Props) {
    const { initialMode } = this.props;

    if (initialMode !== prevProps.initialMode) {
      this.editable = initialMode === 'edit';
    }
  }

  createDimensionsCell = () => {
    const className = {
      activeStyle: style({
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '1px',
      }),
      inactiveStyle: style({ width: '130px', display: 'flex', alignItems: 'center' }),
      dimensionSeparator: style({
        paddingRight: 8,
        paddingLeft: 3,
        paddingTop: 7,
        marginBottom: '0.5em',
      }),
      input: style({ marginLeft: '8px' }),
    };

    const DimensionSeparator =
      !this.props.readOnly && this.editable ? (
        <p className={className.dimensionSeparator}>x</p>
      ) : (
        <Label text={'x'} className={className.dimensionSeparator} />
      );

    const EditableDimensionCell = (key: 'height' | 'width' | 'length') => {
      const DimensionCell = observer(() => {
        return (
          <TableEditableCell
            editable={!this.props.readOnly && this.editable}
            editableRenderer={
              <InputNumber
                defaultValue={this.props.formState.$.dimensions.value[key]}
                min={0}
                onChange={(e: number) => {
                  if (this.props.formState.$.dimensions) {
                    this.props.formState.$.dimensions.$[key] = e;
                  }
                }}
              />
            }
            readOnlyRenderer={
              <Label text={this.props.formState.$.dimensions.value[key].toString()} />
            }
          />
        );
      });
      return <DimensionCell />;
    };

    return (
      <div
        className={
          !this.props.readOnly && this.editable ? className.activeStyle : className.inactiveStyle
        }
      >
        {EditableDimensionCell('height')}
        {DimensionSeparator}
        {EditableDimensionCell('width')}
        {DimensionSeparator}
        {EditableDimensionCell('length')}
      </div>
    );
  };

  render() {
    const { size } = this.props;
    return (
      <section>
        <Table
          // This is a table only because somebody liked how it looked. Fell free to refactor
          size={size}
          title={() => ''}
          dataSource={[{}]}
          rowKey={(_, index) => (index ? index.toString() : '')}
          pagination={false}
          sortDirections={['ascend', 'descend']}
          className={styles.noMargin}
        >
          <Table.Column
            title="Number of Parcels"
            key="number"
            width="100px"
            render={() => {
              const EditableCell = observer(() => {
                return (
                  <NumberField
                    min={0}
                    max={15}
                    fieldState={this.props.formState.$.numberOfParcels}
                  />
                );
              });
              return <EditableCell />;
            }}
          />
          <Table.Column
            title="Dimensions [mm]"
            key="dimensions"
            width="300px"
            render={() => this.createDimensionsCell()}
          />
          <Table.Column
            title="Weight [gm]"
            key="weight"
            width="100px"
            render={() => {
              const EditableCell = observer(() => {
                return <NumberField min={0} fieldState={this.props.formState.$.weight} />;
              });
              return <EditableCell />;
            }}
          />
          <Table.Column
            title="Free text"
            key="text"
            width="200px"
            render={() => {
              const EditableCell = observer(() => {
                return <InputField fieldState={this.props.formState.$.freeText} />;
              });
              return <EditableCell />;
            }}
          />
        </Table>
      </section>
    );
  }
}

const styles = stylesheet({
  noMargin: {
    $nest: {
      '.ant-form-item': {
        margin: 0,
      },
    },
  },
});
