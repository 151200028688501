import * as React from 'react';

export class ValueWithConfidence {
  middleValue: number;
  lowerBound: number;
  upperBound: number;

  constructor(middleValue?: number, lowerBound?: number, upperBound?: number) {
    this.middleValue = Number(middleValue);
    this.lowerBound = Number(lowerBound);
    this.upperBound = Number(upperBound);
  }
}

export function getValueWithConfidence(value?: number | string, std?: number | string) {
  if (value === undefined) {
    return new ValueWithConfidence();
  }

  const numValue = Number(value);

  if (std === undefined) {
    return new ValueWithConfidence(numValue);
  }

  const numStd = Number(std);
  return new ValueWithConfidence(numValue, numValue - numStd, numValue + numStd);
}

export function computeRateWithConfidence(
  original: ValueWithConfidence,
  variant: ValueWithConfidence
): ValueWithConfidence {
  const middleValue = (variant.middleValue / original.middleValue - 1) * 100;
  const lowerBound = (variant.lowerBound / original.lowerBound - 1) * 100;
  const upperBound = (variant.upperBound / original.upperBound - 1) * 100;

  return new ValueWithConfidence(middleValue, lowerBound, upperBound);
}

function formatNumber(value: number, showExplicitPlus?: boolean, showPercent?: boolean) {
  const sign = showExplicitPlus && value > 0 ? '+' : '';
  const percent = showPercent ? '%' : '';

  return `${sign}${Number(value.toFixed(2)) / 1}${percent}`;
}

export interface DisplayValueWithConfidenceProps {
  value: ValueWithConfidence;
  color?: string;
  markGreen?: boolean;
  markRed?: boolean;
  showBounds?: boolean;
  showExplicitPlus?: boolean;
  showPercent?: boolean;
}

export const DisplayValueWithConfidence: React.FC<DisplayValueWithConfidenceProps> = ({
  value,
  color = 'inherit',
  showBounds = true,
  showExplicitPlus,
  showPercent,
}) => {
  if (Number.isNaN(value.middleValue)) {
    return <div>0</div>;
  }

  const middleValue = formatNumber(value.middleValue, showExplicitPlus, showPercent);

  if (!showBounds || Number.isNaN(value.lowerBound) || Number.isNaN(value.upperBound)) {
    return <div style={{ color }}>{middleValue}</div>;
  }

  const lowerBound = formatNumber(value.lowerBound, showExplicitPlus, showPercent);
  const upperBound = formatNumber(value.upperBound, showExplicitPlus, showPercent);

  return (
    <>
      <div style={{ color }}>{middleValue}</div>

      {showBounds && value.lowerBound && value.upperBound && (
        <div style={{ color: '#aaa', fontSize: '13px' }}>{`(${lowerBound}, ${upperBound})`}</div>
      )}
    </>
  );
};
