import { createSelector } from 'reselect';

import { DELIVERY_TYPE_LABELS } from '../../dictionaries';
import { RootState } from '../root-reducer';

const getState = (state: RootState) => state.dictionaries;

export const getDeliveryOptions = (state: RootState) =>
  state.dictionaries.deliveryTypes.map(dt => ({
    value: dt.toString(),
    label: DELIVERY_TYPE_LABELS[dt],
  }));

export const getAvailableLocales = createSelector(getState, state => state.availableLocales);

export const getAvailableLocalesOptions = createSelector(getAvailableLocales, state =>
  state.map(locale => ({
    value: locale,
    label: locale,
  }))
);
