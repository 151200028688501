import { useQueryClient } from 'react-query';
import { v4 } from 'uuid';
import type { ListCarrierProductMappingResponse } from '@src/models';
import { createCarrierProductMapping } from '@src/services/selfcare-service';
import { useSiteId } from '@src/utils/hooks';
import { useOptimisticMutationOnDraft } from '@src/utils/queries';
import type { Context } from './types';

export const useCreateMappingMutation = () => {
  const queryClient = useQueryClient();
  const siteId = useSiteId();

  return useOptimisticMutationOnDraft(createCarrierProductMapping, {
    onMutate: async (effectiveSiteId, payload) => {
      await queryClient.cancelQueries('carrier_product_mapping.list');

      const previousMappingsList = queryClient.getQueryData<
        ListCarrierProductMappingResponse | undefined
      >(['carrier_product_mapping.list', siteId]);

      queryClient.setQueryData<ListCarrierProductMappingResponse | undefined>(
        ['carrier_product_mapping.list', effectiveSiteId],
        () => {
          const mappings = previousMappingsList?.carrier_product_mappings ?? [];

          if (!mappings.length) {
            return previousMappingsList;
          }

          const newMapping = {
            id: v4(),
            ...payload,
          };

          const updatedMappings = [...mappings, newMapping];

          return { carrier_product_mappings: updatedMappings };
        }
      );

      return { previousMappingsList, effectiveSiteId };
    },

    onError: (_, __, context: Context) =>
      queryClient.setQueryData(
        ['carrier_product_mapping.list', context.effectiveSiteId],
        context.previousMappingsList
      ),

    onSettled: () => queryClient.invalidateQueries('carrier_product_mapping.list'),
  });
};
