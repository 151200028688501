import { FilterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { useFormatMessage } from '@src/i18n';
import { TrackingRecordFiltering } from './types';

type Props = {
  onClick: () => void;
  filterValues: Omit<TrackingRecordFiltering, 'id'>;
};

export const FilteringFormOpenButton: React.FC<Props> = ({ onClick, filterValues }) => {
  const formatMessage = useFormatMessage();

  const showFilterIndicator =
    filterValues.carrier_product_refs ||
    filterValues.carrier_product_ids ||
    filterValues.sources ||
    filterValues.statuses ||
    filterValues.updated_at_range?.start ||
    filterValues.updated_at_range?.end ||
    filterValues.created_at_range?.start ||
    filterValues.created_at_range?.end ||
    filterValues.direction_types;

  return (
    // this div is a necessary evil, without it antd's Popover would remove Button's class on open
    <div>
      <Button onClick={onClick} className={styles.filterButton}>
        <FilterOutlined className={styles.filterIcon} />
        <span className={styles.filterText}>{formatMessage('TRACKING_NUMBERS.FILTERS')}</span>
        {showFilterIndicator && <div className={styles.filterIndicator} />}
      </Button>
    </div>
  );
};

const styles = {
  filterButton: tss({
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  }),
  filterIcon: tss({
    paddingTop: '3px',
  }),
  filterText: tss({
    marginLeft: '4px !important',
    marginRight: '2px',
  }),
  filterIndicator: tss({
    height: '4px',
    width: '4px',
    borderRadius: '2px',
    backgroundColor: '#000000',
    marginBottom: '14px',
  }),
  filterPopoverContentWrapper: tss({
    display: 'flex',
  }),
};
