import { List } from 'antd';
import { prop, sortBy } from 'ramda';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { mapping } from '@src/dictionaries';
import { FormattedMessage } from '@src/i18n';
import { configSelectors } from '@src/modules/config';

interface Props {
  isFetching: boolean;
  countries: ReturnType<typeof configSelectors.getCountryCount>;
  renderLink: (item: ListItem) => React.ReactNode;
}

interface ListItem {
  countryName: string;
  count: number;
}

export const CountryList: React.FunctionComponent<Props> = ({
  isFetching,
  countries,
  renderLink,
}) => {
  const datasource = sortBy(
    prop('countryName'),
    countries.map(({ countryCode, count }) => ({
      countryName: mapping.mapCountryCodeToName(countryCode),
      count,
    }))
  );
  return (
    <List
      split={false}
      dataSource={datasource}
      loading={isFetching}
      renderItem={({ countryName, count }: ListItem) => {
        return (
          <List.Item className={styles.item}>
            {renderLink({ countryName, count })}
            <FormattedMessage id="REGIONS" values={{ count }} />
          </List.Item>
        );
      }}
    />
  );
};

const styles = {
  item: tss({
    borderBottom: '1px solid rgba(0,0,0,0.09)',
    padding: '22px',
  }),
};
