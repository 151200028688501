import { createSelector } from 'reselect';

import { RootState } from '../root-reducer';
import { ShipmentsSelectors } from '../shipments';

const getDeliveriesState = (state: RootState) => state.deliveries;

export const getDeliveries = createSelector([getDeliveriesState], state => state.deliveries);

export const getDeliveryHistoryByIds = createSelector(
  getDeliveriesState,
  deliveriesState => deliveriesState.deliveriesHistoryById
);

export const isDeliveriesFetching = createSelector([getDeliveriesState], state => state.isFetching);

export const getDeliveryHistoryForCurrentParcel = (
  state: RootState,
  parcelId: string | null,
  shipmentId: string
) => {
  const delivery = ShipmentsSelectors.getDeliveryForParcel(state, parcelId, shipmentId);
  const deliveriesHistoryByIds = getDeliveryHistoryByIds(state);
  return delivery ? deliveriesHistoryByIds[delivery.id] : null;
};
