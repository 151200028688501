import { BookOutlined } from '@ant-design/icons';
import { services } from '@src/services';
import { getFormikError } from '@src/utils/forms';
import { Field, FieldProps, FormikProps, withFormik } from 'formik';
import { either, isEmpty, isNil, reject } from 'ramda';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { object, string } from 'yup';
import { ContainerContent, ContainerFixedHeader, ErrorAlert } from '../../../components';
import { Select, Card, Input, Switch } from '../../../controls';
import { withFormItem } from '../../../decorators';
import { ERRORS } from '../../../dictionaries';
import { AdditionalFields, AddressModel, ContactModel } from '../../../models';
import { AddressBookContactAdditionalFieldsConnected } from './';

const InputField = withFormItem(Input);
const SelectField = withFormItem(Select);

interface Props {
  addressBookError: string | null;
  isLoading?: boolean;
  contactToEdit?: ContactModel;
  createContact?: (contact: ContactModel) => void;
  updateContact?: (contact: ContactModel) => void;
  title: string;
  onSubmitLabel: string;
  onBack: () => void;
  onBackLabel: string;
  additionalActionsConfig?: { label: string; handler: (ev: any) => any }[];
  toggleAdditionalInfo: () => void;
}

interface FormValues {
  name: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  region: string;
  postalCode: string;
  city: string;
  country: string;
  email: string;
  phoneNumber: string;
  additionalFields: AdditionalFields;
}

class InnerForm extends React.Component<Props & FormikProps<FormValues>> {
  state = {
    isAdditionalFieldsVisible: false,
  };

  handleShowHideClick = () => {
    this.setState({
      isAdditionalFieldsVisible: !this.state.isAdditionalFieldsVisible,
    });
  };

  componentDidMount() {
    const { contactToEdit } = this.props;
    if (contactToEdit && !isEmpty(contactToEdit.additionalFields)) {
      this.handleShowHideClick();
    }
  }

  render() {
    const {
      touched,
      errors,
      setFieldValue,
      addressBookError,
      additionalActionsConfig,
      toggleAdditionalInfo,
    } = this.props;

    const countryOptions = services.dictionariesService.getCountriesOptions();

    const title =
      (this.props.contactToEdit &&
        this.props.contactToEdit.address &&
        this.props.contactToEdit.address.name) ||
      this.props.title;

    const additionalFieldsLabel = this.state.isAdditionalFieldsVisible ? 'Hide' : 'Show';
    return (
      <form onSubmit={this.props.handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <ErrorAlert
          error={addressBookError}
          visible={!!addressBookError}
          showIcon={true}
          banner={true}
          closeText={ERRORS.CLOSE_ALERT}
        />

        <ContainerFixedHeader
          title={title}
          IconComponent={BookOutlined}
          mainActionLabel={this.props.onSubmitLabel}
          onBack={this.props.onBack}
          onBackLabel={this.props.onBackLabel}
          additionalActionsConfig={additionalActionsConfig}
          isLoading={this.props.isLoading}
          mainActionType={'submit'}
        />

        <ContainerContent>
          <Card bordered={false}>
            <div style={{ width: '350px' }}>
              <Field name="name">
                {({ field }: FieldProps<FormValues['name']>) => (
                  <InputField
                    label="Name"
                    required={true}
                    {...field}
                    error={getFormikError(touched.name, errors.name)}
                    labelCol={{ span: 24 }}
                  />
                )}
              </Field>

              <Field name="addressLine1">
                {({ field }: FieldProps<FormValues['addressLine1']>) => (
                  <InputField
                    label="Address Line 1"
                    required={true}
                    {...field}
                    error={getFormikError(touched.addressLine1, errors.addressLine1)}
                    labelCol={{ span: 24 }}
                  />
                )}
              </Field>

              <Field name="addressLine2">
                {({ field }: FieldProps<FormValues['addressLine2']>) => (
                  <InputField
                    label="Address Line 2"
                    required={false}
                    {...field}
                    error={getFormikError(touched.addressLine2, errors.addressLine2)}
                    labelCol={{ span: 24 }}
                  />
                )}
              </Field>

              <Field name="addressLine3">
                {({ field }: FieldProps<FormValues['addressLine3']>) => (
                  <InputField
                    label="Address Line 3"
                    required={false}
                    {...field}
                    error={getFormikError(touched.addressLine3, errors.addressLine3)}
                    labelCol={{ span: 24 }}
                  />
                )}
              </Field>

              <Field name="region">
                {({ field }: FieldProps<FormValues['region']>) => (
                  <InputField
                    label="Region"
                    required={false}
                    {...field}
                    error={getFormikError(touched.region, errors.region)}
                    labelCol={{ span: 24 }}
                  />
                )}
              </Field>

              <Field name="postalCode">
                {({ field }: FieldProps<FormValues['postalCode']>) => (
                  <InputField
                    label="Postal code"
                    required={true}
                    {...field}
                    error={getFormikError(touched.postalCode, errors.postalCode)}
                    labelCol={{ span: 24 }}
                  />
                )}
              </Field>

              <Field name="city">
                {({ field }: FieldProps<FormValues['city']>) => (
                  <InputField
                    label="City"
                    required={true}
                    {...field}
                    error={getFormikError(touched.city, errors.city)}
                    labelCol={{ span: 24 }}
                  />
                )}
              </Field>
              <Field name="country">
                {({ field }: FieldProps<FormValues['country']>) => (
                  <SelectField
                    label="Country"
                    options={countryOptions}
                    required={true}
                    error={getFormikError(touched.country, errors.country)}
                    placeholder={'Please select...'}
                    value={field.value}
                    onChange={value => {
                      setFieldValue('country', value);
                    }}
                    filterOption={true}
                    optionFilterProp={'label'}
                    showSearch
                    labelCol={{ span: 24 }}
                  />
                )}
              </Field>

              <Field name="email">
                {({ field }: FieldProps<FormValues['email']>) => (
                  <InputField
                    label="Email"
                    error={getFormikError(touched.email, errors.email)}
                    {...field}
                    labelCol={{ span: 24 }}
                  />
                )}
              </Field>

              <Field name="phoneNumber">
                {({ field }: FieldProps<FormValues['phoneNumber']>) => (
                  <InputField
                    label="Phone number"
                    error={getFormikError(touched.phoneNumber, errors.phoneNumber)}
                    {...field}
                    labelCol={{ span: 24 }}
                  />
                )}
              </Field>
            </div>
          </Card>

          <br />

          <Card
            bordered={false}
            title="Additional Information"
            extra={
              <>
                <Switch
                  onChange={() => {
                    toggleAdditionalInfo();
                    this.handleShowHideClick();
                  }}
                  checked={this.state.isAdditionalFieldsVisible}
                />
                <span className={styles.additionalFieldsSwitch} onClick={this.handleShowHideClick}>
                  {additionalFieldsLabel}
                </span>
              </>
            }
          >
            {this.state.isAdditionalFieldsVisible && (
              <div style={{ width: '350px' }}>
                <Field name="additionalFields">
                  {({ field }: FieldProps<FormValues['additionalFields']>) => (
                    <AddressBookContactAdditionalFieldsConnected
                      {...field}
                      onBlur={value => {
                        setFieldValue('additionalFields', value);
                      }}
                      onChange={value => {
                        setFieldValue('additionalFields', value);
                      }}
                    />
                  )}
                </Field>
              </div>
            )}
          </Card>

          <br />
        </ContainerContent>
      </form>
    );
  }
}

const styles = stylesheet({
  additionalFieldsSwitch: {
    marginLeft: '10px',
  },
});

export const AddressBookContactForm = withFormik<Props, FormValues>({
  mapPropsToValues: ({ contactToEdit }) => {
    if (contactToEdit) {
      return {
        name: contactToEdit.address.name,
        addressLine1: contactToEdit.address.addressLines[0],
        addressLine2: contactToEdit.address.addressLines[1],
        addressLine3: contactToEdit.address.addressLines[2],
        region: contactToEdit.address.region || '',
        postalCode: contactToEdit.address.postalCode,
        city: contactToEdit.address.city,
        country: contactToEdit.address.country,
        email: contactToEdit.email,
        phoneNumber: contactToEdit.phone || '',
        additionalFields: contactToEdit.additionalFields,
      };
    }

    return {
      name: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      region: '',
      postalCode: '',
      city: '',
      country: '',
      email: '',
      phoneNumber: '',
      additionalFields: {},
    };
  },
  enableReinitialize: true,
  validationSchema: () =>
    object({
      email: string().email(),
      name: string().required(),
      addressLine1: string().required(),
      postalCode: string().required(),
      city: string().required(),
      country: string().required(),
      phoneNumber: string(),
    }),
  handleSubmit: (values, { props }) => {
    const contact = new ContactModel();
    contact.email = values.email;
    contact.phone = values.phoneNumber;
    contact.additionalFields = values.additionalFields;

    const addressLines: string[] = [values.addressLine1, values.addressLine2, values.addressLine3];

    const addressModel = new AddressModel();
    addressModel.addressLines = reject(either(isNil, isEmpty))(addressLines);
    addressModel.name = values.name;
    addressModel.region = values.region;
    addressModel.postalCode = values.postalCode;
    addressModel.country = values.country;
    addressModel.city = values.city;
    contact.address = addressModel;

    if (props.contactToEdit && props.updateContact) {
      contact.id = props.contactToEdit.id;
      props.updateContact(contact);
    } else if (props.createContact) {
      props.createContact(contact);
    }
  },
})(InnerForm);
