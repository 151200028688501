import React, { FC } from 'react';
import { Button, Select } from 'antd';
import { style as tss } from 'typestyle';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Spinner } from '@src/controls';

const { Group } = Button;
const { Option } = Select;

type Props = {
  onNextPage: () => void;
  onPrevPage: () => void;
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
  limit: number;
  onLimitChanged: (newLimit: number) => void;
  isRecordsCountLoading: boolean;
  recordsCount?: string;
};

export const Pagination: FC<Props> = ({
  onNextPage,
  onPrevPage,
  isNextDisabled,
  isPrevDisabled,
  limit,
  onLimitChanged,
  isRecordsCountLoading,
  recordsCount,
}) => {
  return (
    <div className={styles.paginationContainer}>
      {isRecordsCountLoading ? (
        <div className={styles.totalCount}>
          <Spinner delay={0} size="small" />
        </div>
      ) : (
        recordsCount && (
          <div className={styles.totalCount}>
            <span>{`${recordsCount} matching ${recordsCount === '1' ? 'entry' : 'entries'}`}</span>
          </div>
        )
      )}
      <Group>
        <Button
          disabled={isPrevDisabled}
          onClick={onPrevPage}
          size="small"
          icon={<LeftOutlined />}
        />
        <Button
          disabled={isNextDisabled}
          onClick={onNextPage}
          size="small"
          icon={<RightOutlined />}
        />
      </Group>
      <Select onChange={onLimitChanged} value={limit} size="small" className={styles.selectStyles}>
        <Option value={20}>20 / page</Option>
        <Option value={50}>50 / page</Option>
        <Option value={100}>100 / page</Option>
      </Select>
    </div>
  );
};

const styles = {
  paginationContainer: tss({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
  selectStyles: tss({
    width: '120px',
    marginLeft: '8px',
  }),
  totalCount: tss({
    marginRight: '10px',
  }),
};
