import { Menu } from 'antd';
import React, { FunctionComponent } from 'react';
import { stylesheet } from 'typestyle';

import { Link } from '@src/components/link';
import { SitesSelectors } from '@src/modules/sites';
import { authSelectors } from '@src/modules/auth';
import { defaultTheme } from '@src/styles';
import * as urlUtils from '@src/utils/url';
import { DynamicIcon } from '@src/components/dynamic-icon';
import { useSelector } from '@src/utils/hooks';
import { SkeletonSideMenu } from './skeleton-side-menu';
import { useEnabledDashboards } from '@src/containers/dashboard';

export const SideMenu: FunctionComponent = () => {
  const location = useSelector(state => state.router.location);

  const enabledDashboards = useEnabledDashboards();

  const sideMenuItems = useSelector(state =>
    authSelectors.getSideMenuByUserRole(state, { enabledDashboards })
  );

  const isFetchingSites = useSelector(SitesSelectors.getIsFetchingSites);
  const selectedItem = location == null ? '' : urlUtils.getCurrentPageName(location.pathname);

  if (isFetchingSites) {
    return <SkeletonSideMenu />;
  }

  return (
    <Menu defaultSelectedKeys={[selectedItem]} mode="inline" className={styles.topMenu}>
      {sideMenuItems.map(menu =>
        menu.group ? (
          <Menu.ItemGroup
            title={
              menu.group.route ? (
                <Link route={menu.group.route}>{menu.group.label}</Link>
              ) : (
                menu.group.label
              )
            }
            key={menu.group.label}
          >
            {menu.items.map(menuItem => (
              <Menu.Item key={menuItem.key} data-cy={`${menuItem.key}`}>
                <Link route={menuItem.route}>
                  <DynamicIcon type={menuItem.icon} /> {menuItem.label}
                </Link>
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        ) : (
          menu.items.map(menuItem => (
            <Menu.Item key={menuItem.key} data-cy={`${menuItem.key}`}>
              <Link route={menuItem.route}>
                <DynamicIcon type={menuItem.icon} /> {menuItem.label}
              </Link>
            </Menu.Item>
          ))
        )
      )}
    </Menu>
  );
};

const styles = stylesheet({
  topMenu: {
    $nest: {
      '& .ant-menu-item': {
        lineHeight: '20px !important',
        height: '20px !important',
      },
      '& .ant-menu-item-selected': {
        backgroundColor: 'unset !important',
      },
      '& .ant-menu-item:after': {
        borderRight: 'unset !important',
      },
      '& .ant-menu-item-group-title': {
        fontSize: 12,
        fontWeight: defaultTheme.font.bold,
        letterSpacing: 0.5,
        textTransform: 'uppercase',
      },
      '& .ant-menu-item-group:not(:nth-child(2))': {
        paddingTop: 10,
      },
    },
    paddingTop: '5px',
    height: '100%',
    backgroundColor: defaultTheme.color.neutralDark,
  },
});
