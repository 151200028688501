import { sanitizeUrl } from '@braintree/sanitize-url';
import { LABELS } from '@src/dictionaries';
import { SitesSelectors } from '@src/modules/sites';
import { getLabelToken } from '@src/services/gentoken-service';
import { useSelector } from '@src/utils/hooks';
import { Button } from 'antd';
import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { style as tss } from 'typestyle';
import { CustomsDocument } from './customs-declaration/types';

type Props = {
  document: CustomsDocument;
};

export const ReprintCustomsDeclaration: FC<Props> = ({ document }) => {
  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);

  const { url, format } = document;

  const { data, isLoading } = useQuery({
    queryKey: ['getLabelToken', siteId, url, format],
    queryFn: () =>
      getLabelToken(siteId, url, `.${format.toLowerCase() as CustomsDocument['format']}`),
    select: data => sanitizeUrl(data.url),
  });

  return (
    <Button loading={isLoading} className={styles.downloadButton} href={data} target="_blank">
      {LABELS.DOWNLOAD_CUSTOMS_DECLARATION}
    </Button>
  );
};

const styles = {
  downloadButton: tss({
    width: 'min-content',
  }),
};
