import { stylesheet } from "typestyle";

export const commons = stylesheet({
    flex: {
        display: 'flex'
    },
    alignCenter: {
      alignItems: 'center'
    },
    flexWrap: {
      flexWrap: 'wrap'
    },
    timePicker: {
        cursor: 'pointer',
        top: '2px',
        width: '90%',
        $nest: {
          '&.ant-picker': {
            backgroundColor: 'unset',
            border: 'none',
            $nest: {
              '&.ant-picker-focused': {
                boxShadow: 'none',
              },
              '.ant-picker-suffix': {
                display: 'none',
              },
              '.ant-picker-clear': {
                display: 'none',
              },
              '.ant-picker-input input:hover': {
                boxShadow: 'none',
              }
            }
          },
        },
      },
      modal: {
        $nest: {
          '.ant-modal-body': {
            padding: '14px 24px',
          },
        },
      },
      descriptionList: {
        $nest: {
          '.ant-descriptions-item-label': {
            fontWeight: 500,
          },
        },
      },
})

