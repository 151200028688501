import { Button, Input, PriceInput, Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { bigLabelLayout, inputStyle } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { DeliveryAddonType } from '@src/models/selfcare-models';
import { commons } from '@src/styles';
import { getFormikError } from '@src/utils/forms';
import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { classes, stylesheet } from 'typestyle';
import { number, object, string } from 'yup';
import * as enUSMessages from '../../../../../../locales/en-US.json';

const InputField = withFormItem(Input, bigLabelLayout);
const SelectField = withFormItem(Select, bigLabelLayout);
const PriceField = withFormItem(PriceInput, bigLabelLayout);

interface Props {
  allExternalAddonIds: string[];
  onSubmit: (values: AddDeliveryAddonFormValues) => void;
}

export interface AddDeliveryAddonFormValues {
  name: string;
  type: DeliveryAddonType;
  price: string;
  externalAddonId: string;
}

const buildValidationSchema = (allExternalAddonIds: Props['allExternalAddonIds']) =>
  object({
    name: string().required(),
    type: string().required(),
    price: number(),
    externalAddonId: string()
      .required()
      .notOneOf(
        allExternalAddonIds,
        enUSMessages['CATEGORY_MODAL.DELIVERY_ADDONS.EXTERNAL_ADDON_ID_VALIDATION']
      ),
  });

export const AddDeliveryAddonForm: React.FC<Props> = ({ allExternalAddonIds, onSubmit }) => {
  const deliveryAddonOptions: { value: DeliveryAddonType; label: string }[] = [
    {
      value: 'TYPE_CUSTOM',
      label: 'Custom',
    },
    {
      value: 'TYPE_CARRY_IN',
      label: 'Carry in',
    },
    {
      value: 'TYPE_LEAVE_AT_DOOR',
      label: 'Leave at door',
    },
    {
      value: 'TYPE_UNWRAPPING',
      label: 'Unwrapping',
    },
  ];

  return (
    <Formik
      onSubmit={values => onSubmit(values as AddDeliveryAddonFormValues)}
      initialValues={{ name: '', type: 'TYPE_CUSTOM', price: '0.00', externalAddonId: '' }}
      validationSchema={buildValidationSchema(allExternalAddonIds)}
    >
      {({ handleSubmit, errors, touched, setFieldValue }) => (
        <form onSubmit={handleSubmit} className={styles.formWrapper}>
          <Field name="name">
            {({ field }: FieldProps<AddDeliveryAddonFormValues['name']>) => (
              <InputField
                {...field}
                label={<FormattedMessage id="NAME" />}
                labelAlign="left"
                error={getFormikError(touched.name, errors.name)}
                className={classes(
                  inputStyle,
                  getFormikError(touched.name, errors.name) && styles.textInputError
                )}
              />
            )}
          </Field>
          <Field name="type">
            {({ field }: FieldProps<AddDeliveryAddonFormValues['type']>) => (
              <SelectField
                label={<FormattedMessage id="TYPE" />}
                options={deliveryAddonOptions}
                labelAlign="left"
                error={getFormikError(touched.type, errors.type)}
                {...field}
                onChange={value => setFieldValue('type', value)}
                className={classes(inputStyle, styles.typeInput)}
              />
            )}
          </Field>
          <Field name="price">
            {({ field }: FieldProps<AddDeliveryAddonFormValues['price']>) => (
              <PriceField
                {...field}
                value={field.value}
                onChange={value => setFieldValue('price', value)}
                label={<FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.PRICE" />}
                labelAlign="left"
                error={getFormikError(touched.price, errors.price)}
                className={classes(
                  inputStyle,
                  getFormikError(touched.price, errors.price) && styles.textInputError
                )}
              />
            )}
          </Field>
          <Field name="externalAddonId">
            {({ field }: FieldProps<AddDeliveryAddonFormValues['externalAddonId']>) => (
              <InputField
                {...field}
                label={<FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.EXTERNAL_ADDON_ID" />}
                labelAlign="left"
                error={getFormikError(touched.externalAddonId, errors.externalAddonId)}
                className={classes(
                  inputStyle,
                  getFormikError(touched.externalAddonId, errors.externalAddonId) &&
                    styles.textInputError
                )}
              />
            )}
          </Field>
          <div className={commons.flex}>
            <Button type="primary" htmlType="submit" className={styles.submitButton}>
              <FormattedMessage id="ADD" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

const styles = stylesheet({
  submitButton: {
    marginLeft: 'auto',
    marginTop: '10px',
  },
  formWrapper: {
    width: 350,
  },
  textInputError: {
    border: '1px solid #f5222d',
    $nest: {
      '&:hover': {
        border: '1px solid #F5222D',
      },
    },
  },
  typeInput: {
    $nest: {
      '& .ant-select-selection': {
        backgroundColor: '#FAF8F7',
        border: 'none',
        $nest: {
          '&:hover': {
            backgroundColor: 'white',
          },
        },
      },
    },
  },
});
