import { color } from 'csx';
import { pathOr } from 'ramda';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { DeliveryEventTag } from '.';
import { Timeline, TimelineItem } from '../../../controls';
import { DeliveryEventModel } from '../../../models';
import { dateUtils, getColorForStatus } from '../../../utils';

interface Props {
  deliveryHistory: DeliveryEventModel[];
  className?: string;
}

const styles = {
  description: tss({
    color: color('#000000').fade('60%').toString(),
  }),
  date: tss({ marginRight: '1em' }),
};

export const DeliveriesHistoryTimeline: React.StatelessComponent<Props> = props => {
  const { deliveryHistory, className } = props;
  return (
    <Timeline className={className}>
      {deliveryHistory.map((delivery, idx) => {
        const statusColor = getColorForStatus(delivery.trackingStatus);
        return (
          <TimelineItem color={statusColor} key={`${delivery.createdAt}${idx}`}>
            <div>
              <DeliveryEventTag tracking_status={delivery.trackingStatus} tagColor={statusColor} />
            </div>
            <div>
              <span className={styles.date}>{dateUtils.formatIsoDate(delivery.createdAt)}</span>
              <span>{pathOr('', ['location', 'name'], delivery)}</span>
            </div>
            <div className={styles.description}>{delivery.originStatusDescription}</div>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};
