import { Field } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { Switch } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { FormattedMessage } from '@src/i18n';

const SwitchField = withFormItem(Switch);

interface Props {
  name: string;
}

export const HasAnyDiscount: React.FunctionComponent<Props> = ({ name }) => {
  return (
    <div className={styles.wrapper}>
      <Field name={`${name}.value`}>
        {() => <SwitchField label={<FormattedMessage id="HAS_ANY_DISOUNT" />} disabled checked />}
      </Field>
    </div>
  );
};

const styles = stylesheet({
  wrapper: {
    display: 'flex',
    padding: '5px 0',
    $nest: {
      '.ant-row': {
        display: 'flex',
        marginBottom: 0,
      },
      '.ant-form-explain': {
        width: '120px',
      },
    },
  },
});
