import { Popover } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { AddButton, ComponentReturningChildren } from '@src/components';
import { parsePriceString } from '@src/containers/regions/carrier-products/helpers';
import { Modal } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { wideLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { ConfigShippingCategoryModel } from '@src/models';
import { defaultTheme } from '@src/styles';

import { configActions } from '@src/modules/config';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import {
  AddDeliveryAddonForm,
  AddDeliveryAddonFormValues,
  DeliveryAddonEdit,
  DeliveryAddonList,
} from './components/delivery-addons';

const DeliveryAddonsWrapper = withFormItem(ComponentReturningChildren, wideLayout);

interface Props {
  category: ConfigShippingCategoryModel;
}

export const DeliveryAddonContainer: React.FC<Props> = ({ category }) => {
  const [isCreatePopoverVisible, setIsCreatePopoverVisible] = React.useState(false);
  const [isCreatingAddon, setIsCreatingAddon] = React.useState(false);

  const handleCreateAddon = (values: AddDeliveryAddonFormValues) => {
    setIsCreatingAddon(true);
    dispatchOnDraftSiteId(configActions.createDeliveryAddonRequest)({
      model: {
        categoryId: category.id,
        name: values.name,
        type: values.type,
        price: { value: String(parsePriceString(values.price)), vatRate: 0 },
        externalId: values.externalAddonId,
        defaults: {
          selected: false,
        },
        state: 'ACTIVE',
      },
      onComplete: () => {
        setIsCreatingAddon(false);
      },
    });
  };
  const [addonModalConfig, setAddonModalConfig] = React.useState<{
    visible: boolean;
    addonId: string;
  }>({
    visible: false,
    addonId: '',
  });

  const allExternalAddonIds = category.deliveryAddons?.map(addon => addon.externalId) || [];

  return (
    <DeliveryAddonsWrapper
      className={styles.wrapper}
      labelClassName={styles.label}
      label={<FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.TITLE" />}
      labelTooltip={<FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.TITLE_TOOLTIP" />}
      labelAlign="left"
    >
      {category.deliveryAddons && category.deliveryAddons.length ? (
        <DeliveryAddonList
          isCreatingAddon={isCreatingAddon}
          deliveryAddons={category.deliveryAddons}
          categoryId={category.id}
          onAddonClick={addonId =>
            setAddonModalConfig({
              visible: true,
              addonId,
            })
          }
        />
      ) : null}
      <Popover
        destroyTooltipOnHide
        trigger="click"
        title={<FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.ADD_NEW_ADDON" />}
        visible={isCreatePopoverVisible}
        onVisibleChange={setIsCreatePopoverVisible}
        getPopupContainer={e => e.parentElement || document.body}
        content={
          <AddDeliveryAddonForm
            allExternalAddonIds={allExternalAddonIds}
            onSubmit={values => {
              handleCreateAddon(values);
              setIsCreatePopoverVisible(false);
            }}
          />
        }
      >
        <AddButton text={<FormattedMessage id="ADD_NEW" />} className={styles.addButton} />
      </Popover>
      <Modal
        visible={addonModalConfig.visible}
        footer={null}
        onCancel={() => setAddonModalConfig({ visible: false, addonId: '' })}
        destroyOnClose
      >
        <DeliveryAddonEdit
          addonId={addonModalConfig.addonId}
          categoryId={category.id}
          allExternalAddonIds={allExternalAddonIds}
        />
      </Modal>
    </DeliveryAddonsWrapper>
  );
};

const styles = stylesheet({
  wrapper: {
    margin: 0,
  },
  label: {
    $nest: {
      label: { color: defaultTheme.regionForm.color.label },
    },
  },
  addButton: {
    width: '100px',
    padding: 0,
    margin: '15px 15px 0 15px',
    $nest: {
      '.anticon-plus': {
        color: '#000000',
      },
    },
  },
});
