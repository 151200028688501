import { Col, Row } from 'antd';
import { FieldState, FormState } from 'formstate';
import { debounce } from 'lodash';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { style } from 'typestyle';

import { ShippingAddressCreateModal } from '../containers/transport-orders/components/shipping-address-create-modal';
import { ShippingAddressEditModal } from '../containers/transport-orders/components/shipping-address-edit-modal';
import { AddressSelect, Spinner } from '../controls';
import { withFieldStateSelect, withFormItem } from '../decorators';
import { formFieldWideLayout } from '../forms/form-layouts';
import { AddressModel, ContactModel } from '../models';
import { requiredFieldWithMessage } from '../utils/validation';
import { filterAddressSelectOptions, getCountryConfigForAlpha2Code } from './utils/utils';

const AddressSelectField = withFormItem(withFieldStateSelect(AddressSelect));

export class AddressSelectorFormState extends FormState<{
  addressIdFieldState: FieldState<string>;
}> {
  static create = (
    model: Partial<ContactModel | any> = {},
    required: boolean = true
  ): AddressSelectorFormState => {
    return new FormState({
      addressIdFieldState: required
        ? new FieldState((model && model.id) || model.externalId || '').validators(
            requiredFieldWithMessage('Field Required')
          )
        : new FieldState((model && model.id) || model.externalId || ''),
    });
  };
}

type ContactInfoProps = {
  contact?: ContactModel;
};
const ContactInfo = ({ contact }: ContactInfoProps) => {
  if (!(contact && contact.address)) {
    return null;
  }

  const {
    address: { addressLines = [], city = '', country = '', postalCode = '', name },
    email,
    phone,
  } = contact;

  const countryConfig = getCountryConfigForAlpha2Code(country);
  const countryName = countryConfig ? countryConfig.name : '';
  return (
    <section>
      {name && <p>{name}</p>}
      <p>
        {addressLines.join(', ')}, {city}, {postalCode}, {countryName}
      </p>
      {phone && <p>{phone}</p>}
      {email && <p>{email}</p>}
    </section>
  );
};

type Props = {
  required?: boolean;
  formState: AddressSelectorFormState;
  contacts: ContactModel[];
  onCreateContact: (contact: ContactModel, shouldSaveInAddressBook: boolean) => void;
  onUpdateContact: (contact: ContactModel) => void;
  additionalActions?: JSX.Element;
  addressModel?: AddressModel;
  isFetching?: boolean;
  disabled?: boolean;
  detailsVisible?: boolean;
  autoFocus?: boolean;
  filterType: 'consignor' | 'consignee' | 'delivery';
  className?: string;
  dropdownClassName?: string;
  handleSearch?: (value: string) => void;
};

@observer
export class AddressPanel extends React.Component<Props, {}> {
  @observable isAddressDetailsVisible = false;
  @observable isAddressCreateModalVisible = false;
  @observable isAddressEditModalVisible = false;
  @observable waitingForNewContact = false;

  handleSearchDebounced = debounce(
    (value: string) => this.props.handleSearch && this.props.handleSearch(value),
    600
  );

  componentDidMount() {
    this.isAddressDetailsVisible = !!this.props.detailsVisible;
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { contacts } = this.props;
    const nextContacts = nextProps.contacts;

    if (this.waitingForNewContact && nextContacts.length && nextContacts.length > contacts.length) {
      const newContact = nextContacts.slice()[0];
      this.props.formState.$.addressIdFieldState.onChange(newContact.id);
      this.waitingForNewContact = false;
    }
  }

  onAddressChange = (value: string) => {
    this.isAddressDetailsVisible = true;
  };

  onCreateNewAddressClick = () => {
    this.isAddressCreateModalVisible = true;
  };

  onEditAddressClick = () => {
    this.isAddressEditModalVisible = true;
  };

  closeLinkModal = () => {
    this.isAddressCreateModalVisible = false;
    this.isAddressEditModalVisible = false;
  };

  onAddressCreateModalOk = (contact: ContactModel, shouldSaveInAddressBook: boolean) => {
    const { onCreateContact } = this.props;
    this.waitingForNewContact = true;
    onCreateContact(contact, shouldSaveInAddressBook);
    this.props.formState.$.addressIdFieldState.onChange(contact.id);
    this.isAddressCreateModalVisible = false;
    this.isAddressDetailsVisible = true;
  };

  onAddressEditModalOk = (contact: ContactModel) => {
    const { onUpdateContact } = this.props;
    onUpdateContact(contact);
    this.isAddressEditModalVisible = false;
  };

  renderContactInfo = (contactId: string) => {
    return (
      this.isAddressDetailsVisible && (
        <ContactInfo contact={this.props.contacts.find(item => item.id === contactId)} />
      )
    );
  };

  render() {
    const { autoFocus, disabled, contacts, isFetching, filterType, handleSearch, required } =
      this.props;
    const { addressIdFieldState } = this.props.formState.$;

    const addressBookContactsDictionary = this.props.contacts
      .filter(c => c.additionalFields[filterType] !== '0')
      .map(c => ({
        value: c.id,
        title: c.address.name,
        desc: `${c.address.city}`,
      }));

    const AddressCreateModal = this.isAddressCreateModalVisible && (
      <ShippingAddressCreateModal
        visible={this.isAddressCreateModalVisible}
        onOk={this.onAddressCreateModalOk}
        onCancel={this.closeLinkModal}
      />
    );

    const contact = contacts.find(c => c.id === addressIdFieldState.value);
    const AddressEditModal = this.isAddressEditModalVisible && (
      <ShippingAddressEditModal
        visible={this.isAddressEditModalVisible}
        onOk={this.onAddressEditModalOk}
        onCancel={this.closeLinkModal}
        contact={contact}
      />
    );

    const LoadingIndicator = isFetching && this.waitingForNewContact && <Spinner size="small" />;
    const contactInfo = this.renderContactInfo(addressIdFieldState.value);

    return (
      <Row align="top">
        <Col className={style({ width: '100%' })}>
          <AddressSelectField
            required={required ?? true}
            disabled={disabled}
            autoFocus={autoFocus}
            additionalActions={this.props.additionalActions}
            onChange={this.onAddressChange}
            onCreateNew={this.onCreateNewAddressClick}
            onEdit={this.onEditAddressClick}
            placeholder="Please select..."
            fieldState={addressIdFieldState}
            error={addressIdFieldState.error}
            options={addressBookContactsDictionary}
            showSearch={true}
            caseInsensitiveSearch={true}
            filterOption={(stringQuery, option) =>
              filterAddressSelectOptions(stringQuery, option, addressBookContactsDictionary)
            }
            {...formFieldWideLayout}
            {...this.props}
            isFetching={isFetching}
            onSearch={value => {
              if (handleSearch) {
                this.handleSearchDebounced(value);
              }
            }}
          />
          {LoadingIndicator}
          {contactInfo}
          {AddressCreateModal}
          {AddressEditModal}
        </Col>
      </Row>
    );
  }
}
