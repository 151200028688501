import { Input, Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { formItemLayout } from '@src/forms/form-layouts';
import { AuthenticationType, UserCreateModel, UserGetModel } from '@src/models';
import { usersSelectors } from '@src/modules/users';
import { getFormikError } from '@src/utils/forms';
import { Field, FieldProps, FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { array, object, string } from 'yup';
import { SubmitButtonWithLayout } from '.';

const InputField = withFormItem(Input);
const SelectField = withFormItem(Select);

interface Props {
  groupMembershipOptions: ReturnType<typeof usersSelectors.getGroupMembershipOptions>;
  siteTagsOptions: { value: string; label: string }[];
  onSubmit: (user: UserCreateModel, groupIds: string[], tagsIds: string[]) => void;
  userToEdit?: UserGetModel;
  userToEditGroups?: ReturnType<typeof usersSelectors.getUserGroups>;
  userTagsIds?: string[];
  isLoading: boolean;
  groupsIdsWithAccessToWatchlists: ReturnType<
    typeof usersSelectors.getGroupsIdsThatHaveWatchlistRole
  >;
}

interface FormValues {
  enterpriseEmail?: string;
  externalId?: string;
  name?: string;
  groupIds?: string[];
  selectedTagsIds?: string[];
}

const InnerForm: React.FunctionComponent<Props & FormikProps<FormValues>> = ({
  handleSubmit,
  touched,
  errors,
  groupMembershipOptions,
  setFieldValue,
  values,
  siteTagsOptions,
  isLoading,
  groupsIdsWithAccessToWatchlists,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field name="enterpriseEmail">
        {({ field }: FieldProps<FormValues['enterpriseEmail']>) => (
          <InputField
            label="Enterprise Email"
            {...field}
            {...formItemLayout}
            error={getFormikError(touched.enterpriseEmail, errors.enterpriseEmail)}
          />
        )}
      </Field>
      <Field name="externalId">
        {({ field }: FieldProps<FormValues['externalId']>) => (
          <InputField
            label="External Id"
            {...formItemLayout}
            {...field}
            error={getFormikError(touched.externalId, errors.externalId)}
          />
        )}
      </Field>
      <Field name="name">
        {({ field }: FieldProps<FormValues['name']>) => (
          <InputField
            label="Name"
            {...field}
            {...formItemLayout}
            error={getFormikError(touched.name, errors.name)}
          />
        )}
      </Field>
      <Field name="groupIds">
        {(props: FieldProps<FormValues['groupIds']>) => (
          <SelectField
            label="Group Membership"
            required={true}
            mode="multiple"
            style={{ width: '350px' }}
            groupOptions={groupMembershipOptions}
            {...formItemLayout}
            placeholder={'Please select...'}
            error={getFormikError(touched.groupIds, errors.groupIds)}
            defaultValue={props.field.value}
            onChange={value => setFieldValue('groupIds', value)}
          />
        )}
      </Field>
      {groupsIdsWithAccessToWatchlists.some(
        group => values.groupIds && values.groupIds.includes(group)
      ) && (
        <Field name="selectedTagsIds">
          {({ field }: FieldProps<FormValues['selectedTagsIds']>) => (
            <SelectField
              label="Tags"
              mode="multiple"
              loading={isLoading}
              style={{ width: '350px' }}
              options={siteTagsOptions}
              {...formItemLayout}
              placeholder={'Please select...'}
              error={getFormikError(touched.selectedTagsIds, errors.selectedTagsIds)}
              value={field.value}
              onChange={value => setFieldValue('selectedTagsIds', value)}
              caseInsensitiveSearch={true}
            />
          )}
        </Field>
      )}
      <SubmitButtonWithLayout />
    </form>
  );
};

export const EnterpriseForm = withFormik<Props, FormValues>({
  mapPropsToValues: ({ userToEdit, userToEditGroups, userTagsIds }) => {
    if (userToEdit && userToEditGroups) {
      return {
        enterpriseEmail: userToEdit.email,
        externalId: userToEdit.externalId,
        name: userToEdit.name,
        groupIds: userToEditGroups,
        selectedTagsIds: userTagsIds || [],
      };
    } else {
      return {
        enterpriseEmail: '',
        externalId: undefined,
        name: '',
        groupIds: [],
        selectedTagsIds: [],
      };
    }
  },
  validationSchema: () =>
    object()
      .shape<FormValues>(
        {
          enterpriseEmail: string()
            .email()
            .when(['externalId'], {
              is: externalId => !externalId,
              then: string().required('Either enterprise login or external id is required'),
            }),
          externalId: string().when(['enterpriseEmail'], {
            is: enterpriseEmail => !enterpriseEmail,
            then: string().required('Either enterprise login or external id is required'),
          }),
          name: string(),
          groupIds: array().of(string().required()).required('Group membership is required'),
          selectedTagsIds: array().of(string().required()),
        },
        [['enterpriseEmail', 'externalId']]
      )
      .required(),
  handleSubmit: (values, { props }) => {
    props.onSubmit(
      {
        externalId: values.externalId,
        email: values.enterpriseEmail,
        name: values.name,
        authenticationType: AuthenticationType.OTHER,
        id: props.userToEdit && props.userToEdit.id,
      },
      values.groupIds!,
      values.selectedTagsIds || []
    );
  },
  enableReinitialize: true,
  displayName: 'EnterpriseForm',
})(InnerForm);
