import * as React from 'react';
import { Checkbox, Form, FormItemProps, Input, InputNumber, Table, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { matchHexColor } from '@src/utils';

import { FeatureFlags as ProductPageWidgetFeatures } from '@src/api/product-page-widget';
import Select from '@src/controls/select';
import { FeatureDefinition } from '../types';
import { featureDefinitions } from '../constants';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface FeaturesTableProps {
  loading: boolean;
  values?: ProductPageWidgetFeatures;
  onSubmit: (param: ProductPageWidgetFeatures) => void;
  country?: string;
}

export const FeaturesTable: React.FC<FeaturesTableProps> = ({
  loading,
  values = {},
  onSubmit,
  country,
}) => {
  const [form] = Form.useForm<ProductPageWidgetFeatures>();

  React.useEffect(() => {
    form.resetFields();
  }, [values]);

  async function handleSubmit(name: keyof ProductPageWidgetFeatures) {
    const value = form.getFieldValue(name);
    if (value !== values[name]) {
      await form.validateFields([name]);
      onSubmit({ [name]: value });
    }
  }

  const columns: ColumnType<FeatureDefinition>[] = [
    {
      title: 'Feature name',
      dataIndex: 'title',
      render: (title, record) => {
        if (!record.description) {
          return title;
        }

        return <Tooltip title={record.description}>{title}</Tooltip>;
      },
    },
    {
      title: country ? country : 'Default',
      dataIndex: 'name',
      render: (name, record) => {
        if (record.type === 'checkbox') {
          const props = {
            onChange: (e: CheckboxChangeEvent) => {
              if (record.name === 'shipping_categories_visible_when_folded_count') {
                form.setFieldsValue({ [record.name]: e.target.checked ? 1 : 0 });
              }
              handleSubmit(name);
            },
          } as FormItemProps;

          return (
            <Form.Item name={name} valuePropName="checked" style={{ margin: 0 }} {...props}>
              <Checkbox
                checked={
                  record.name === 'shipping_categories_visible_when_folded_count' &&
                  values.shipping_categories_visible_when_folded_count === undefined
                    ? true
                    : form.getFieldValue(name)
                }
              />
            </Form.Item>
          );
        }

        if (record.type === 'select') {
          return (
            <Form.Item name={name} style={{ margin: 0 }}>
              <Select
                options={record.options}
                onChange={() => {
                  handleSubmit(name);
                }}
              />
            </Form.Item>
          );
        }

        const props = {
          onBlur: () => {
            if (record.name === 'shipping_categories_visible_when_folded_count') {
              const value = form.getFieldValue('shipping_categories_visible_when_folded_count');
              value === null &&
                form.setFieldsValue({ shipping_categories_visible_when_folded_count: 0 });
            }
            handleSubmit(name);
          },
        } as FormItemProps;
        return (
          <Form.Item
            name={name}
            style={{ margin: 0 }}
            rules={[
              {
                validator: (_, value) => {
                  if (record.type === 'hex' && !matchHexColor(value || '')) {
                    return Promise.reject(
                      new Error('The color should be entered in hex format (starts with "#")')
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
            validateTrigger="onBlur"
            {...props}
          >
            {record.type === 'number' ? (
              <InputNumber
                disabled={record.disabledIf?.(values, form.getFieldsValue())}
                {...(record.name === 'shipping_categories_visible_when_folded_count' && {
                  min: 0,
                })}
              />
            ) : (
              <Input />
            )}
          </Form.Item>
        );
      },
    },
  ];

  return (
    <Form
      form={form}
      initialValues={{
        font_weight_default: '400',
        font_weight_stronger: '500',
        shipping_categories_visible_when_folded_count: 1,
        ...values,
      }}
    >
      <Table
        rowKey={record => `${record.name}-${record.type}`}
        dataSource={featureDefinitions}
        columns={columns}
        loading={loading}
        pagination={false}
      />
    </Form>
  );
};
//
