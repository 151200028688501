import { compose, toLower } from 'ramda';
import { Option } from 'space-lift';

export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const decapitalize = (str: string | undefined): string | undefined =>
  str && str.charAt(0).toLowerCase() + str.slice(1);

export const decamelize = (str: string | undefined): string | undefined =>
  str && decapitalize(str)!.replace(/([A-Z])/g, $1 => ` ${$1.toLowerCase()}`);

export const titelize = (str: string | undefined): string | undefined =>
  str && str.replace(/(^[a-z]|\s[a-z])/g, $1 => `${$1.toUpperCase()}`);

export const truncateId = (str: string | undefined): string | undefined =>
  str && `${str.substring(0, 4)}...${str.substring(str.length - 4)}`;

export const getFileExtension = (str: string | undefined): string | undefined =>
  str && `${str.split('.').reverse()[0]}`;

export const getFileStem = (str: string | undefined): string | undefined =>
  str && `${str.split(/[.//]/).reverse()[1]}`;

export const getEncodedFileName = (str: string | undefined): string | undefined => {
  if (!str) {
    return str;
  }
  return Option(str)
    .map(s => new URL(s).search)
    .map(s => new URLSearchParams(s).get('file'))
    .map(s => s.split('/').pop())
    .get();
};

export const getURLParameter = (str: string | undefined, param: string): string | undefined => {
  if (!str) {
    return str;
  }
  return Option(str)
    .map(s => new URL(s).search)
    .map(s => new URLSearchParams(s).get(param))
    .get();
};

export const getPlurarFormOf = (word: string, amount: number) =>
  amount !== 1 ? `${amount} ${word}s` : `${amount} ${word}`;

export const removeUnderscores = (text: string) => text.replace(/_/g, ' ');

export const capitalizeEachWord = (text: string) =>
  text
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

export const humanize = (input: string | undefined) =>
  compose(capitalize, toLower, removeUnderscores, (s: string | undefined) => s || '')(input);

export const removeSingleQuotes = (str: string) => str.replace(/['"\s]/g, '');
