import { DashboardOutlined } from '@ant-design/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';
import merge from 'lodash/merge';
import { useQuery } from 'react-query';

import {
  Button,
  Card as AntCard,
  Collapse,
  Form,
  message,
  Switch,
  Select as AntSelect,
  Space,
} from 'antd';
import { Card, PriceInput, Select } from '@src/controls';

import { FormattedMessage } from '@src/i18n';

import { ListFixedHeader } from '@src/components';
import { services } from '@src/services';
import { supportedLocales } from './locale';
import { SitesSelectors } from '@src/modules/sites';
import { stylesheet } from 'typestyle';

const env = services.envService.getEnvironmentalVariables();

export const UpsellWidgetConfiguration: React.FC<{ visible?: boolean }> = ({ visible }) => {
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [widgetReady, setWidgetReady] = React.useState<boolean>(false);

  const widgetPlaceholderRef = React.useRef<HTMLDivElement>(null);

  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);
  const draftSiteId = useSelector(SitesSelectors.getDraftSiteIdOrEmpty);

  const effectiveSiteId = !!draftSiteId ? draftSiteId : siteId;
  const [useDraft, setUseDraft] = React.useState<boolean>(false);

  React.useEffect(() => {
    // A way to force update on prop change instead of useImperativeHandle
    // It's safer to completely restart the widget instead of updating
    // since we don't know which features might have changed
    if (visible && widgetReady) {
      widgetPlaceholderRef.current!.innerHTML = '';
      onCreate(formInstance.getFieldsValue());
    }
  }, [visible]);

  const upsellQuery = useQuery({
    queryKey: ['upsell-token', effectiveSiteId, { useDraft }],
    queryFn: () => {
      return services.tokenAuthService.getAuthToken(useDraft ? effectiveSiteId : siteId);
    },
    retry: false,
    staleTime: Infinity,
  });

  const initialValues = {
    country: 'SE',
    locales: 'en-US',
    cart: {
      currency: 'SEK',
      total_value: 0,
    },
  };

  const loadScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = env.MAD_UPSELL_WIDGET_URL;
    script.onload = () => {
      setLoaded(true);
      window._ingridUpsellWidgetApi.addListener('error', error => {
        setWidgetReady(false);
        message.error(error);
      });
    };
    document.head.appendChild(script);
  };

  React.useEffect(() => {
    loadScript();
  }, []);

  React.useEffect(() => {
    // clear widget when we change site.
    return () => {
      if (!widgetPlaceholderRef.current) {
        return;
      }
      widgetPlaceholderRef.current.innerHTML = '';

      setWidgetReady(false);
    };
  }, [effectiveSiteId]);

  React.useEffect(() => {
    setUseDraft(!!draftSiteId || !siteId);
  }, [draftSiteId]);

  const convertValues = (values: any) => {
    const total_value = Math.round(100 * values.cart.total_value);
    return merge({}, values, {
      cart: { total_value },
      locales: [values.locales],
      auth_token: upsellQuery.status === 'success' ? upsellQuery.data.token : '',
    });
  };

  const onCreate = (values: any) => {
    window._ingridUpsellWidgetApi.render('upsell-widget-placeholder', convertValues(values));
    setWidgetReady(true);
  };

  const onUpdate = (values: any) => {
    window._ingridUpsellWidgetApi.updateCart(convertValues(values));
  };

  const onReset = () => {
    widgetPlaceholderRef.current!.innerHTML = '';
    setWidgetReady(false);
  };

  const [formInstance] = Form.useForm();

  React.useEffect(() => {
    if (upsellQuery.status === 'success') {
      formInstance.setFieldsValue({ auth_token: upsellQuery.data.token });
    }
  }, [upsellQuery.dataUpdatedAt]);

  return (
    <>
      <ListFixedHeader
        title="Upsell Widget Test Client"
        IconComponent={DashboardOutlined}
        showSearch={false}
      />
      <AntCard>
        <div
          id="upsell-widget-placeholder"
          ref={widgetPlaceholderRef}
          style={{ textAlign: 'center' }}
        />
      </AntCard>
      <Form
        form={formInstance}
        initialValues={initialValues}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        onFinish={widgetReady ? onUpdate : onCreate}
      >
        <Card
          title={<FormattedMessage id="SETTINGS" />}
          extra={
            <Space>
              {widgetReady && (
                <Button type="primary" onClick={onReset}>
                  Close session
                </Button>
              )}
              <Button
                disabled={!loaded}
                type={widgetReady ? 'default' : 'primary'}
                htmlType="submit"
              >
                <FormattedMessage id={widgetReady ? 'UPDATE' : 'GO_TO_WIDGET'} />
              </Button>
            </Space>
          }
        >
          <Collapse defaultActiveKey={['1']}>
            <Collapse.Panel header="General" key="1">
              <div className={`${styles.switchContainer} ant-row ant-form-item`}>
                <div className="ant-col ant-col-4 ant-form-item-label">
                  <label className=".ant-form-item-label">Use draft</label>
                </div>
                <Switch disabled={!draftSiteId} checked={useDraft} onChange={setUseDraft} />
              </div>
              <Form.Item
                name="country"
                label={<FormattedMessage id="COUNTRY" />}
                rules={[{ required: true, message: '' }]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  options={services.dictionariesService.getCountriesOptions()}
                />
              </Form.Item>

              <Form.Item
                name="locales"
                label={<FormattedMessage id="LOCALE" />}
                rules={[{ required: true, message: '' }]}
              >
                <Select options={supportedLocales.map(value => ({ value, label: value }))} />
              </Form.Item>

              <Form.Item
                name={['cart', 'currency']}
                label={<FormattedMessage id="CURRENCY" />}
                rules={[{ required: true, message: '' }]}
              >
                <Select options={services.dictionariesService.getCurrenciesOptions()} />
              </Form.Item>

              <Form.Item name={['cart', 'attributes']} label="Cart attributes">
                <AntSelect mode="tags" tokenSeparators={[',']} />
              </Form.Item>

              <Form.Item name={['cart', 'vouchers']} label="Vouchers">
                <AntSelect mode="tags" tokenSeparators={[',']} />
              </Form.Item>

              <Form.Item
                name={['cart', 'total_value']}
                label="Total cart value"
                rules={[{ required: true, message: '' }]}
              >
                <PriceInput />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </Card>
      </Form>
    </>
  );
};

const styles = stylesheet({
  switchContainer: {
    alignItems: 'center',
  },
});
