import * as qs from 'query-string';
import { isEmpty } from 'ramda';

import {
  ShipmentIdsByTagDTO,
  ShipmentIdsByTagModel,
  SiteTagDTO,
  SiteTagModel,
  TemplateDTORequest,
  TemplateDTOResponse,
  TemplateModelRequest,
  TemplateModelResponse,
  UserProfileCreateShipmentDTO,
  UserProfileCreateShipmentModel,
  UserProfileShipmentsDTO,
  UserProfileShipmentsModel,
  UserTagDTO,
  UserTagModel,
} from '../models';
import { apiUtils, authFetch } from '../utils';

const USERPROFILE_URL = apiUtils.getApiUrl() + '/frontend/userprofile';

const getHeaders = (siteId: string): { [key: string]: string } => {
  if (siteId == null) {
    throw new Error('missing site_id');
  }
  return { 'x-site-id': siteId };
};

export function createWith(
  siteId: string,
  data: TemplateModelRequest
): Promise<TemplateModelResponse> {
  const payload = TemplateDTORequest.createFromModel(data);

  const options: RequestInit = {
    method: 'POST',
    headers: getHeaders(siteId),
    body: JSON.stringify({ template: payload.template.data, tag_ids: payload.tags }),
  };

  return authFetch<TemplateDTOResponse>(
    `${USERPROFILE_URL}/shipment_templates.create`,
    options
  ).then(res => TemplateModelResponse.createFromDTO(res));
}

const createDeleteRequest = (siteId: string, id: string): Promise<void> => {
  const options: RequestInit = { method: 'DELETE', headers: getHeaders(siteId) };

  return authFetch<void>(
    `${USERPROFILE_URL}/shipment_templates.delete?` + qs.stringify({ id }),
    options
  );
};

export function deleteByIds(siteId: string, ids: string[]): Promise<void[]> {
  const requests = ids.map(id => createDeleteRequest(siteId, id));

  return Promise.all(requests);
}

export function listAll(siteId: string, tags: string[] = []): Promise<TemplateModelResponse[]> {
  const options: RequestInit = {
    method: 'GET',
    headers: getHeaders(siteId),
  };

  const queryObject = isEmpty(tags)
    ? {}
    : {
        tag_ids: tags,
      };

  return authFetch<{ shipment_templates_with_tags: TemplateDTOResponse[] }>(
    `${USERPROFILE_URL}/shipment_templates.list?` + qs.stringify(queryObject),
    options
  ).then(res =>
    res && res.shipment_templates_with_tags
      ? res.shipment_templates_with_tags.map(templateDTO =>
          TemplateModelResponse.createFromDTO(templateDTO)
        )
      : []
  );
}

export function updateWith(
  siteId: string,
  dataModel: TemplateModelRequest
): Promise<TemplateModelResponse> {
  const {
    template: { data, id },
    tags,
  } = TemplateDTORequest.createFromModel(dataModel);

  const options: RequestInit = {
    method: 'POST',
    headers: getHeaders(siteId),
    body: JSON.stringify({ template: { data, id }, tag_ids: tags }),
  };
  return authFetch<TemplateDTOResponse>(
    `${USERPROFILE_URL}/shipment_templates.update`,
    options
  ).then(res => TemplateModelResponse.createFromDTO(res));
}

export function createShipmentAction(siteId: string, data: UserProfileCreateShipmentModel) {
  const payload = UserProfileCreateShipmentDTO.createFromModel(data);
  const options: RequestInit = {
    method: 'POST',
    headers: getHeaders(siteId),
    body: JSON.stringify(payload),
  };

  return authFetch<{}>(`${USERPROFILE_URL}/shipment_actions.create`, options);
}

export function getShipmentIds(siteId: string) {
  const options: RequestInit = {
    method: 'GET',
    headers: getHeaders(siteId),
  };

  return authFetch<UserProfileShipmentsDTO>(
    `${USERPROFILE_URL}/shipment_actions.get_shipments`,
    options
  ).then(res => UserProfileShipmentsModel.createFromDTO(res));
}

export function getShipmentIdsByTags(
  siteId: string,
  tags: string[]
): Promise<ShipmentIdsByTagModel[]> {
  const options: RequestInit = {
    method: 'GET',
    headers: getHeaders(siteId),
  };

  return authFetch<{ tagged_shipments?: ShipmentIdsByTagDTO[] }>(
    `${USERPROFILE_URL}/tags.getShipments?` + qs.stringify({ tag_ids: tags }),
    options
  ).then(res => {
    return res.tagged_shipments
      ? res.tagged_shipments.map(taggedShipment =>
          ShipmentIdsByTagModel.createFromDTO(taggedShipment)
        )
      : tags.map(tagId =>
          ShipmentIdsByTagModel.createFromDTO({
            tag: UserTagDTO.createFromModel({ id: tagId, name: tagId }),
            shipment_ids: [],
          })
        );
  });
}

export function createSiteTag(siteId: string, name: string): Promise<SiteTagModel> {
  const options: RequestInit = {
    method: 'POST',
    headers: getHeaders(siteId),
    body: JSON.stringify({ name }),
  };

  return authFetch<{ tag: SiteTagDTO }>(`${USERPROFILE_URL}/tags.create`, options).then(res =>
    SiteTagModel.createFromDTO(res.tag)
  );
}

export function deleteSiteTags(siteId: string, ids: string[]) {
  const options: RequestInit = {
    method: 'DELETE',
    headers: getHeaders(siteId),
  };

  return authFetch(`${USERPROFILE_URL}/tags.delete?` + qs.stringify({ ids }), options);
}

export function listSiteTags(siteId: string, userId?: string): Promise<SiteTagModel[]> {
  const options: RequestInit = {
    method: 'GET',
    headers: getHeaders(siteId),
  };
  return authFetch<{ tags?: SiteTagDTO[] }>(
    `${USERPROFILE_URL}/tags.list?${qs.stringify({ user_id: userId })}`,
    options
  ).then(res => (res.tags ? res.tags.map(tag => SiteTagModel.createFromDTO(tag)) : []));
}

export function listUserTags(siteId: string): Promise<UserTagModel[]> {
  const options: RequestInit = {
    method: 'GET',
    headers: getHeaders(siteId),
  };

  return authFetch<{ tags?: UserTagDTO[] }>(`${USERPROFILE_URL}/user_tags.list`, options).then(
    res => (res.tags ? res.tags.map(tag => UserTagModel.createFromDTO(tag)) : [])
  );
}

export function renameSiteTag(siteId: string, id: string, name: string): Promise<SiteTagModel> {
  const payload = SiteTagModel.createFromDTO({ id, name });
  const options: RequestInit = {
    method: 'PUT',
    headers: getHeaders(siteId),
    body: JSON.stringify({ tag: payload }),
  };

  return authFetch<{ tag: SiteTagDTO }>(
    `${USERPROFILE_URL}/tags.rename?` + qs.stringify({ id, name }),
    options
  ).then(res => SiteTagModel.createFromDTO(res.tag));
}

export const updateUserWithTags = async (userId: string, tagsIds: string[], siteId: string) => {
  const options: RequestInit = {
    method: 'POST',
    headers: getHeaders(siteId),
    body: JSON.stringify({ user_id: userId, tag_ids: tagsIds }),
  };

  return authFetch<{}>(`${USERPROFILE_URL}/user_tags.update`, options);
};

export function addTagsToShipments(siteId: string, tagIds: string[], shipmentIds: string[]) {
  const options: RequestInit = {
    method: 'POST',
    headers: getHeaders(siteId),
    body: JSON.stringify({ tag_ids: tagIds, shipment_ids: shipmentIds }),
  };

  return authFetch(`${USERPROFILE_URL}/tags.tagShipments`, options);
}

export function removeTagFromShipments(siteId: string, tagId: string, shipmentIds: string[]) {
  const options: RequestInit = {
    method: 'DELETE',
    headers: getHeaders(siteId),
  };

  return authFetch(
    `${USERPROFILE_URL}/tags.removeFromShipment?` +
      qs.stringify({ shipment_ids: shipmentIds, tag_id: tagId }),
    options
  );
}
