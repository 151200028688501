import { createAction } from 'typesafe-actions';

import { ConfigMerchantSummaryModel } from '../../models';

export const getMerchant = createAction(
  '@merchants/GET_MERCHANT',
  (merchantId: string) => merchantId
)();
export const getMerchantSuccess = createAction(
  '@merchants/GET_MERCHANT_SUCCESS',
  (merchant: ConfigMerchantSummaryModel) => merchant
)();
export const getMerchantError = createAction(
  '@merchants/GET_MERCHANT_ERROR',
  (error: string) => error
)();

export const listMerchants = createAction('@merchants/LIST_MERCHANTS_REQUEST')();

export const listMerchantsSuccess = createAction(
  '@merchants/LIST_MERCHANTS_SUCCESS',
  (merchants: ConfigMerchantSummaryModel[]) => merchants
)();

export const listMerchantsError = createAction(
  '@merchants/LIST_MERCHANTS_ERROR',
  (error: string) => error
)();

export const selectActiveMerchant = createAction(
  '@merchants/SELECT_ACTIVE_MERCHANT',
  (payload: { merchantId: string; siteId: string }) => payload
)();

export const changeActiveMerchantAndSite = createAction(
  '@merchants/CHANGE_ACTIVE_MERCHANT_AND_SITE',
  (payload: { merchantId: string; siteId: string }) => payload
)();

export const changeActiveMerchant = createAction(
  '@merchants/CHANGE_ACTIVE_MERCHANT',
  (merchantId: string) => merchantId
)();
