import { Col, Form as AntForm, Popover, Row } from 'antd';
import { Field, FieldProps, Formik, FormikProps } from 'formik';
import { isEmpty } from 'ramda';
import * as React from 'react';
import { style as tss } from 'typestyle';
import { number, object } from 'yup';

import { AddButton } from '@src/components';
import { Button, Input, Modal, PriceInput, Tag } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, inputStyle, MakeAutoSaveFormikEnhancer, rowStyle } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { CarrierProductModel, ConfigFilterRuleModel } from '@src/models';
import { getFormikError } from '@src/utils/forms';

import { FilterRules } from '../../components/filter-rules';
import {
  getBasePriceFormValue,
  getPriceRuleFormValues,
  parsePriceRules,
  PriceRuleFormValue,
} from '../helpers';
import { RuleAddingContainer } from '../rule-adding-container/rule-adding-container';
import { PriceRulesTable } from './price-rules-table';

const PriceField = withFormItem(PriceInput);
const InputField = withFormItem(Input);

const AutoSaveFormikEnhancer = MakeAutoSaveFormikEnhancer<CarrierProductFormValue>();

const FormItem = AntForm.Item;

export interface CarrierProductFormValue {
  shippingMethod: string;
  deliveryTypes: string[];
  basePrice: string;
  priceRules: PriceRuleFormValue[];
  externalMethodId?: string;
  filterRules?: ConfigFilterRuleModel[];
  SDAStart?: number;
  SDAEnd?: number;
}

const CarrierProductDetailsSchema = object().shape({
  basePrice: number().min(0),
});

interface Props {
  product: CarrierProductModel;
  regionId: string;
  onCarrierProductChange: (values: CarrierProductFormValue) => void;
  onCarrierProductDelete: () => void;
  onPriceRuleRemove: (values: CarrierProductFormValue, ruleIndex: number) => void;
  onCarrierProductFilterRuleChange: (rules: ConfigFilterRuleModel[]) => void;
}

export const CarrierProductForm: React.FunctionComponent<Props> = ({
  product,
  regionId,
  onCarrierProductChange,
  onCarrierProductDelete,
  onPriceRuleRemove,
  onCarrierProductFilterRuleChange,
}) => {
  const [isAddingPriceRule, setIsAddingPriceRule] = React.useState(false);
  const [isChangingExternalId, setIsChangingExternalId] = React.useState(false);

  const priceRuleFormValues: PriceRuleFormValue[] = parsePriceRules(product.priceRules);
  return (
    <Formik
      initialValues={{
        shippingMethod: product.shippingMethod,
        deliveryTypes: product.deliveryTypes,
        basePrice: getBasePriceFormValue(priceRuleFormValues),
        priceRules: getPriceRuleFormValues(priceRuleFormValues),
        externalMethodId: product.externalMethodId,
        SDAStart: product.shippingDateAdjustment && product.shippingDateAdjustment.adjustStart,
        SDAEnd: product.shippingDateAdjustment && product.shippingDateAdjustment.adjustEnd,
      }}
      enableReinitialize={true}
      // tslint:disable-next-line: no-empty
      onSubmit={() => {}}
      validationSchema={CarrierProductDetailsSchema}
    >
      {(renderProps: FormikProps<CarrierProductFormValue>) => {
        return (
          <AutoSaveForm className={styles.form}>
            {renderProps.values.deliveryTypes && (
              <FormItem>
                <Field name={`deliveryTypes`}>
                  {({ field }: FieldProps<CarrierProductFormValue['deliveryTypes']>) => (
                    <Row className={rowStyle}>
                      <Col span={6}>
                        <label className="ant-form-item-label">
                          <FormattedMessage id="DELIVERY_TYPE" />
                        </label>
                      </Col>
                      <Col span={18}>
                        {field.value.map((deliveryType: string) => (
                          <Tag key={deliveryType} nonClickable={true}>
                            {deliveryType}
                          </Tag>
                        ))}
                      </Col>
                    </Row>
                  )}
                </Field>
              </FormItem>
            )}
            <AutoSaveFormikEnhancer
              name="externalMethodId"
              onSave={values => onCarrierProductChange(values)}
              render={({ name, onInstantChange }) => (
                <Field name={name}>
                  {({ field }: FieldProps<CarrierProductFormValue['externalMethodId']>) => (
                    <>
                      <InputField
                        label={<FormattedMessage id="EXTERNAL_METHOD_ID" />}
                        className={inputStyle}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        {...field}
                        onBlur={() => {
                          if (field.value !== product.externalMethodId) {
                            setIsChangingExternalId(true);
                          }
                        }}
                        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                          if (event.key === 'Enter' && field.value !== product.externalMethodId) {
                            setIsChangingExternalId(true);
                          }
                        }}
                      />
                      <Modal
                        title={<FormattedMessage id="EDIT_EXTERNAL_METHOD_ID" />}
                        visible={isChangingExternalId}
                        onOk={() => {
                          onInstantChange(field.value);
                          setIsChangingExternalId(false);
                        }}
                        onCancel={() => {
                          renderProps.setFieldValue(name, product.externalMethodId);
                          setIsChangingExternalId(false);
                        }}
                      >
                        <FormattedMessage id="EXTERNAL_METHOD_ID_EDIT_CONFIRM_BODY" />
                      </Modal>
                    </>
                  )}
                </Field>
              )}
            />
            <AutoSaveFormikEnhancer
              name="basePrice"
              onSave={values => onCarrierProductChange(values)}
              render={({ name, onBlur, onKeyDown }) => (
                <Field name={name}>
                  {({ field }: FieldProps<CarrierProductFormValue['basePrice']>) => (
                    <PriceField
                      label={<FormattedMessage id="BASE_PRICE" />}
                      className={inputStyle}
                      name={field.name}
                      value={field.value}
                      onChange={value => renderProps.setFieldValue(name, value)}
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 18 }}
                      onBlur={onBlur}
                      onKeyDown={onKeyDown}
                      error={getFormikError(
                        renderProps.touched.basePrice,
                        renderProps.errors.basePrice
                      )}
                    />
                  )}
                </Field>
              )}
            />
            <Row>
              <Col span={6}>
                <label className="ant-form-item-label">
                  <FormattedMessage id="PRICE_RULES" />
                </label>
              </Col>
              <Col span={18}>
                {!isEmpty(renderProps.values.priceRules) && (
                  <PriceRulesTable
                    regionId={regionId}
                    onPriceRuleChange={onCarrierProductChange}
                    formProps={renderProps}
                    onRuleRemove={onPriceRuleRemove}
                    product={product}
                  />
                )}
                <Popover
                  trigger="click"
                  title={<FormattedMessage id="ADD_PRICE_RULE" />}
                  visible={isAddingPriceRule}
                  onVisibleChange={setIsAddingPriceRule}
                  getPopupContainer={e => e.parentElement || document.body}
                  content={
                    <RuleAddingContainer
                      regionId={regionId}
                      product={product}
                      onSaveClick={() => setIsAddingPriceRule(false)}
                    />
                  }
                  destroyTooltipOnHide={true}
                >
                  <AddButton className={styles.addButton} />
                </Popover>
              </Col>
            </Row>
            <FilterRules
              filterRules={product.filterRules}
              onSave={onCarrierProductFilterRuleChange}
            />
            <Button type="link" className={styles.deleteButton} onClick={onCarrierProductDelete}>
              <FormattedMessage id="DELETE" />
            </Button>
          </AutoSaveForm>
        );
      }}
    </Formik>
  );
};

const styles = {
  wrapper: tss({
    borderTop: '1px solid #D9D9D9',
    paddingTop: '8px',
    marginBottom: '15px',
    lineHeight: 1.5,
  }),
  form: tss({
    position: 'relative',
  }),
  subdued: tss({
    color: 'rgba(0, 0, 0, 0.25)',
    cursor: 'default',
    $nest: {
      '&:hover': {
        color: 'rgba(0, 0, 0, 0.25)',
        textDecoration: 'underline',
      },
    },
  }),
  deleteButton: tss({
    position: 'absolute',
    right: '10px',
    top: '4px',
  }),
  SDAWrapper: tss({
    $nest: {
      '.ant-form-item-label': {
        width: '150px !important',
      },
    },
  }),
  dayLabel: tss({
    marginLeft: '5px',
    color: 'rgba(0, 0, 0, 0.5)',
    marginBottom: '8px',
    alignSelf: 'center',
  }),
  adjustButton: tss({
    paddingLeft: 0,
    lineHeight: '22px',
  }),
  deleteAdjustmentsButton: tss({
    paddingLeft: 0,
  }),
  label: tss({
    color: 'rgba(0, 0, 0, 0.5)',
    top: '25px',
    display: 'flex',
  }),
  addButton: tss({
    display: 'inline-block',
  }),
};
