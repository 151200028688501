import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { either, isEmpty, isNil, reject } from 'ramda';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { Modal, Switch } from '../../../controls';
import { LABELS } from '../../../dictionaries';
import { ShippingAddressForm, ShippingAddressFormState } from '../../../forms';
import { AddressModel, ContactModel } from '../../../models';
import { isValidFormStateContainer } from '../../../utils/validation';

// Component API

type Props = {
  visible: boolean;
  onOk: (contact: ContactModel, shouldSaveInAddressBook: boolean) => void;
  onCancel: () => void;
};

@observer
export class ShippingAddressCreateModal extends React.Component<Props, {}> {
  @observable shouldSaveInAddressBook = false;
  @observable
  formStateContainer = {
    shippingAddressFormState: ShippingAddressFormState.create(),
  };

  submitHandler = async () => {
    if (!(await isValidFormStateContainer(this.formStateContainer))) {
      return;
    }
    const { shippingAddressFormState } = this.formStateContainer;

    const addressLines: string[] = [
      shippingAddressFormState.$.addressLine1FieldState.$,
      shippingAddressFormState.$.addressLine2FieldState.$,
      shippingAddressFormState.$.addressLine3FieldState.$,
    ];

    const addressModel = new AddressModel();
    addressModel.addressLines = reject(either(isNil, isEmpty))(addressLines);
    addressModel.name = shippingAddressFormState.$.nameFieldState.$;
    addressModel.region = shippingAddressFormState.$.regionFieldState.$;
    addressModel.postalCode = shippingAddressFormState.$.postalCodeFieldState.$;
    addressModel.country =
      shippingAddressFormState.$.countrySelectorFormState.$.countryFieldState.$.toString();
    addressModel.city = shippingAddressFormState.$.cityFieldState.$;

    const contact = new ContactModel();
    contact.email = shippingAddressFormState.$.emailFieldState.$;
    contact.phone = shippingAddressFormState.$.phoneNumberFieldState.$;
    contact.address = addressModel;

    this.props.onOk(contact, this.shouldSaveInAddressBook);
  };

  cancelHandler = () => {
    this.props.onCancel();
  };

  updateSwitchState = () => {
    this.shouldSaveInAddressBook = !this.shouldSaveInAddressBook;
  };

  render() {
    return (
      <Modal
        visible={this.props.visible}
        title={[LABELS.CREATE, LABELS.NEW, LABELS.ADDRESS].join(' ')}
        onOk={this.submitHandler}
        onCancel={this.cancelHandler}
        okText={LABELS.SAVE}
        extendFooterWith={
          <>
            <span className={tss({ paddingRight: '15px' })}>Save in address book?</span>
            <Switch
              className="t_save-in-address-book-switch"
              style={{ marginRight: 'auto' }}
              onChange={this.updateSwitchState}
              checked={this.shouldSaveInAddressBook}
            />
          </>
        }
      >
        <ShippingAddressForm formState={this.formStateContainer.shippingAddressFormState} />
      </Modal>
    );
  }
}
