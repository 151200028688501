import * as React from 'react';
import { Drawer } from 'antd';
import { setVariantSiteId } from '@src/modules/config/actions';
import { useDispatch } from '@src/utils/hooks';
import { resetWidgetSession } from '@src/modules/siw/actions';
import { VariantModel } from '@src/models';

import { CheckoutWidgetConfiguration } from '@src/containers/checkout-widget';

interface OwnProps {
  variant?: VariantModel;
  onClose: () => void;
}

export const VariantWidgetDrawer: React.FC<OwnProps> = ({ variant, onClose }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!variant) {
      return;
    }

    dispatch(setVariantSiteId({ siteId: variant.variantSiteId }));
    dispatch(resetWidgetSession());
    return () => {
      dispatch(setVariantSiteId({ siteId: null }));
      dispatch(resetWidgetSession());
    };
  }, [variant]);

  const content = <CheckoutWidgetConfiguration />;

  return (
    <Drawer visible={!!variant} onClose={onClose} width={780}>
      {!!variant && content}
    </Drawer>
  );
};
