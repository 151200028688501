import * as React from 'react';

import { Modal } from '@src/controls';
import { defaultTheme } from '@src/styles';
import { WarehouseContainer } from '.';

interface OwnProps {
  warehouseId: string;
  regionId?: string;
  isVisible: boolean;
  changeVisibility: (to: boolean) => void;
}

export const WarehouseModal: React.FunctionComponent<OwnProps> = ({
  warehouseId,
  isVisible,
  changeVisibility,
  regionId,
}) => (
  <Modal
    visible={isVisible}
    onOk={() => changeVisibility(false)}
    onCancel={() => changeVisibility(false)}
    closable={false}
    footer={null}
    destroyOnClose={true}
    bodyStyle={{
      backgroundColor: defaultTheme.regionForm.color.modalBackground,
      minWidth: '856px',
    }}
  >
    <WarehouseContainer warehouseId={warehouseId} regionId={regionId} />
  </Modal>
);
