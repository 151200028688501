import type { GeneralCustomsDeclaration } from './types';
import { ShipmentModel, LineItemModel } from '@src/models';

export function prefillCustomsDeclaration(shipment: ShipmentModel): GeneralCustomsDeclaration {
  const { address } = shipment.customerInfo;

  return {
    items: (shipment.lineItems ?? []).map((item: LineItemModel) => ({
      sku: item.sku,
      name: item.name,
      quantity: item.quantity,
      unit_value: item.price,
      unit_gross_weight: item.weight,
      hs_tariff_number: item.hsTariffNumber,
    })),
    sold_to: {
      address_lines: address.addressLines,
      attn: address.attn,
      care_of: address.careOf,
      city: address.city,
      country: address.country,
      door_code: address.doorCode,
      name: address.name,
      postal_code: address.postalCode,
      region: address.region,
    },
  };
}
