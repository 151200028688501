import { pathOr } from 'ramda';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { ComponentReturningChildren } from '@src/components';
import { TextArea, Tooltip } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { inputStyle, MakeAutoSaveFormikEnhancer, noLabelLayout, wideLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { ConfigFilterRuleModel } from '@src/models';
import { authSelectors } from '@src/modules/auth';
import { commons, defaultTheme } from '@src/styles';
import { Field, FieldProps, Formik } from 'formik';
import { filter, ifElse, isEmpty, map, pipe } from 'ramda';
import { useSelector } from 'react-redux';
import { ConfirmationModal } from './filter-rules-confirmation-modal';

interface FilterRulesProps {
  onSave: (rules: ConfigFilterRuleModel[]) => void;
  filterRules?: ConfigFilterRuleModel[];
  toolTip?: React.ReactNode;
  className?: string;
}

export interface FilterRulesValues {
  conditions: string[];
}

const AutoSaveFormikEnhancer = MakeAutoSaveFormikEnhancer<FilterRulesValues>();

const InputItem = withFormItem(TextArea, noLabelLayout);
const LabelWrapper = withFormItem(ComponentReturningChildren, wideLayout);

export const FilterRules: React.FunctionComponent<FilterRulesProps> = ({
  filterRules = [],
  toolTip,
  onSave,
  className,
}) => {
  const hasAccessTo = useSelector(authSelectors.getHasAccessTo);
  const [isChangingFilterRule, setIsChangingFilterRule] = React.useState(false);

  const handleSave = (updatedValues: FilterRulesValues) =>
    onSave(
      pipe<string[], string[], ConfigFilterRuleModel[]>(
        list => filter(value => !!value, list),
        ifElse(
          isEmpty,
          () => [{ condition: 'true' }],
          map(updatedCondition => ({ condition: updatedCondition }))
        )
      )(updatedValues.conditions)
    );

  return (
    <>
      <Formik
        initialValues={{
          conditions: filterRules
            .filter(rule => rule.condition !== 'true')
            .map(rule => rule.condition),
        }}
        // tslint:disable-next-line: no-empty
        onSubmit={() => {}}
      >
        {({ values }) => (
          <LabelWrapper
            label={<FormattedMessage className={styles.label} id="FILTER_RULES_LABEL" />}
            labelTooltip={toolTip}
            labelAlign="left"
            className={className}
          >
            {!hasAccessTo('filter-rules:edit') ? (
              values.conditions.length ? (
                values.conditions.map(condition => (
                  <Tooltip title={condition}>
                    <InputItem
                      disabled={true}
                      rows={3}
                      value={condition}
                      className={inputStyle}
                    />
                  </Tooltip>
                ))
              ) : (
                <div className={commons.flex}>
                  <FormattedMessage className={styles.noFilterRules} id="EMPTY_FILTER_RULES" />
                </div>
              )
            ) : values.conditions.length ? (
              values.conditions.map((_condition, index) => (
                <div className={styles.filterRule} key={index}>
                  <AutoSaveFormikEnhancer
                    onSave={handleSave}
                    name={`conditions[${index}]` as keyof FilterRulesValues}
                    render={({ name }) => (
                      <Field name={name}>
                        {({ field }: FieldProps<FilterRulesValues['conditions']>) => (
                          <>
                            <InputItem
                              {...field}
                              rows={3}
                              onBlur={() => {
                                // pathOr for initalValue when there is no condition
                                if (
                                  field.value !==
                                  pathOr(undefined, [index, 'condition'], filterRules)
                                ) {
                                  setIsChangingFilterRule(true);
                                }
                              }}
                              className={inputStyle}
                              placeholder="Add new filter rule"
                            />
                            <ConfirmationModal
                              isVisible={isChangingFilterRule}
                              changeVisibility={setIsChangingFilterRule}
                              values={values}
                              handleSave={handleSave}
                              filterRules={filterRules}
                              index={index}
                              name={name}
                            />
                          </>
                        )}
                      </Field>
                    )}
                  />
                </div>
              ))
            ) : (
              <div className={styles.filterRule} key={0}>
                <AutoSaveFormikEnhancer
                  onSave={handleSave}
                  name={`conditions[${0}]` as keyof FilterRulesValues}
                  render={({ name }) => (
                    <Field name={name}>
                      {({ field }: FieldProps<FilterRulesValues['conditions']>) => (
                        <>
                          <InputItem
                            {...field}
                            rows={3}
                            onBlur={() => {
                              // pathOr for initalValue when there is no condition
                              if (
                                field.value !== pathOr(undefined, [0, 'condition'], filterRules)
                              ) {
                                setIsChangingFilterRule(true);
                              }
                            }}
                            className={inputStyle}
                            placeholder="Add new filter rule"
                          />
                          <ConfirmationModal
                            isVisible={isChangingFilterRule}
                            changeVisibility={setIsChangingFilterRule}
                            values={values}
                            handleSave={handleSave}
                            filterRules={filterRules}
                            index={0}
                            name={name}
                          />
                        </>
                      )}
                    </Field>
                  )}
                />
              </div>
            )}
          </LabelWrapper>
        )}
      </Formik>
    </>
  );
};

const styles = stylesheet({
  filterRule: {
    $nest: {
      '&:first-child:not(:only-child)': {
        borderTop: '1px solid rgb(233, 233, 233)',
        paddingTop: '15px',
      },
      '&:last-child:not(:only-child)': {
        borderBottom: '1px solid rgb(233, 233, 233)',
        paddingBottom: '15px',
      },
      '.ant-row': {
        marginBottom: '0',
      },
    },
  },
  addIcon: {
    padding: '0 15px',
  },
  label: {
    color: defaultTheme.regionForm.color.label,
  },
  noFilterRules: {
    color: defaultTheme.regionForm.color.label,
  },
});
