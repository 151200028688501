import React from 'react';
import { FeaturesStatus } from '@src/modules/delivery-tracking-notifications/notifications-features/components/features-status';
import { FeatureList } from '@src/modules/delivery-tracking-notifications/notifications-features/components/feature-list';
import { stylesheet } from 'typestyle';
import { defaultTheme } from '@src/styles';
import {
  useGetNotificationsFeatures,
  useGetSmartNotificationsList,
} from '@src/modules/delivery-tracking-notifications/notifications-features/api';
import { useFormatMessage } from '@src/i18n';
import { SmartNotificationsList } from '@src/modules/delivery-tracking-notifications/smart-notifications-settings/components/smart-notifications-list';

export const NotificationsFeatures = () => {
  const formatMessage = useFormatMessage();

  const { data, isLoading, isFetching } = useGetNotificationsFeatures();
  const { smartNotificationsData, isSmartLoading } = useGetSmartNotificationsList();

  return (
    <>
      <h3 className={styles.containerHeader}>{formatMessage('NOTIFICATIONS.SETTINGS')}</h3>
      <div className={styles.container}>
        <div className={styles.featuresSection}>
          <div className={styles.sectionHeader}>
            <FeaturesStatus
              textHeader="NOTIFICATIONS.NOTIFICATIONS"
              featureStatus={data?.enabled}
              isLoading={isLoading || isFetching}
            />
          </div>
          <FeatureList
            progress_notification_settings={data?.progress_notifications_settings}
            isLoading={isLoading || isFetching}
          />
        </div>
        <div>
          <div className={styles.sectionHeader}>
            <FeaturesStatus
              textHeader="NOTIFICATIONS.SMART_NOTIFICATIONS"
              featureStatus={smartNotificationsData?.enabled}
              isLoading={isSmartLoading}
            />
          </div>
          <SmartNotificationsList
            smartNotifications={smartNotificationsData}
            isLoading={isSmartLoading}
          />
        </div>
      </div>
    </>
  );
};

const styles = stylesheet({
  container: {
    width: '100%',
    padding: '40px 40px 46px 40px',
    background: defaultTheme.color.white,
  },
  containerHeader: {
    borderBottom: '1px solid rgba(0,0,0,0.09)',
    paddingBottom: '16px',
    marginBottom: '16px',
  },
  featuresSection: {
    marginBottom: '2rem',
  },
  sectionHeader: {
    borderBottom: '1px solid rgba(0,0,0,0.09)',
    marginBottom: '1rem',
  },
});
