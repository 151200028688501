import { Timeline } from 'antd';
import { TimeLineItemProps } from 'antd/lib/timeline/TimelineItem';
import * as React from 'react';

const Component: React.StatelessComponent<TimeLineItemProps> = props => {
  const { children, ...restProps } = props;

  return <Timeline.Item {...restProps}>{children}</Timeline.Item>;
};

export default Component;
