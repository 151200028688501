export const GET_TRANSPORT_ORDER_LIST_REQUEST = '@transportOrders/GET_TRANSPORT_ORDER_LIST_REQUEST';
export const GET_TRANSPORT_ORDER_LIST_SUCCESS = '@transportOrders/GET_TRANSPORT_ORDER_LIST_SUCCESS';
export const GET_TRANSPORT_ORDER_LIST_ERROR = '@transportOrders/GET_TRANSPORT_ORDER_LIST_ERROR';
export const GET_TRANSPORT_ORDER_REQUEST = '@transportOrders/GET_TRANSPORT_ORDER_REQUEST';
export const GET_TRANSPORT_ORDER_SUCCESS = '@transportOrders/GET_TRANSPORT_ORDER_SUCCESS';
export const GET_TRANSPORT_ORDER_ERROR = '@transportOrders/GET_TRANSPORT_ORDER_ERROR';
export const CREATE_TRANSPORT_ORDER_REQUEST = '@transportOrders/CREATE_TRANSPORT_ORDER_REQUEST';
export const CREATE_TRANSPORT_ORDER_SUCCESS = '@transportOrders/CREATE_TRANSPORT_ORDER_SUCCESS';
export const CREATE_TRANSPORT_ORDER_ERROR = '@transportOrders/CREATE_TRANSPORT_ORDER_ERROR';
export const UPDATE_TRANSPORT_ORDER_REQUEST = '@transportOrders/UPDATE_TRANSPORT_ORDER_REQUEST';
export const UPDATE_TRANSPORT_ORDER_SUCCESS = '@transportOrders/UPDATE_TRANSPORT_ORDER_SUCCESS';
export const UPDATE_TRANSPORT_ORDER_ERROR = '@transportOrders/UPDATE_TRANSPORT_ORDER_ERROR';
export const SET_ACTIVE_TRANSPORT_ORDER_FILTERS =
  '@transportOrders/SET_ACTIVE_TRANSPORT_ORDER_FILTERS';
export const CLEAR_ACTIVE_TRANSPORT_ORDER_FILTERS =
  '@transportOrders/CLEAR_ACTIVE_TRANSPORT_ORDER_FILTERS';
export const UPDATE_TRANSPORT_ORDER_LIST_PAGINATION =
  '@transportOrders/UPDATE_TRANSPORT_ORDER_LIST_PAGINATION';
export const CLEAR_TRANSPORT_ORDERS_LIST = '@transportOrders/CLEAR_TRANSPORT_ORDERS_LIST';
export const GET_SHIPMENTS_SUMMARIES_LIST_BY_TOS_SUCCESS =
  '@transportOrders/GET_SHIPMENTS_SUMMARIES_LIST_BY_TOS_SUCCESS';
export const GET_SHIPMENTS_SUMMARIES_LIST_BY_TOS_ERROR =
  '@transportOrders/GET_SHIPMENTS_SUMMARIES_LIST_BY_TOS_ERROR';
