import * as React from 'react';
import { connect } from 'react-redux';

import { ComponentReturningChildren } from '@src/components';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, wideLayout } from '@src/forms';
import {
  ConfigShippingCategoryModel,
  UpdateCategoryDetailsModel,
  UpdateDeliveryTimeModel,
} from '@src/models';
import { configActions } from '@src/modules/config';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { FormattedMessage } from 'react-intl';
import { classes, stylesheet } from 'typestyle';
import { DeliveryTimeForm } from './components/delivery-time/delivery-time-form';

const LabelWrapper = withFormItem(ComponentReturningChildren, wideLayout);

interface OwnProps {
  category: ConfigShippingCategoryModel;
  className?: string;
}

const mapDispatchToProps = () => ({
  updateCategoryDetails: dispatchOnDraftSiteId(configActions.updateCategoryDetailsRequest),
});

type Props = ReturnType<typeof mapDispatchToProps> & OwnProps;

const Component: React.FunctionComponent<Props> = ({
  category,
  updateCategoryDetails,
  className,
}) => {
  const handleDeliveryTimeChange = ({
    deliveryTime,
    deliveryTimeProperties,
  }: {
    deliveryTime?: UpdateDeliveryTimeModel;
    deliveryTimeProperties?: UpdateCategoryDetailsModel['deliveryTimeProperties'];
  }) => {
    updateCategoryDetails({
      model: {
        categoryId: category.id,
        deliveryTime: { ...deliveryTime },
        deliveryTimeProperties: {
          ...((deliveryTime?.unit === 'hour' ||
            deliveryTime?.unit === 'minute' ||
            deliveryTime?.unit === 'week') && {
            useTimespan: false,
            timespan: null,
          }),
          ...((deliveryTime?.unit === 'business_day' ||
            deliveryTime?.unit === 'day' ||
            deliveryTime?.unit === 'week') && {
            ignoreCutoffs: false,
          }),
          ...deliveryTimeProperties,
        },
      },
    });
  };

  return (
    <AutoSaveForm>
      <LabelWrapper
        className={classes(styles.wrapper, className)}
        label={<FormattedMessage id="DELIVERY_TIME" />}
      >
        <DeliveryTimeForm
          deliveryTimeProperties={category.deliveryTimeProperties}
          deliveryTime={category.deliveryTime}
          onDeliveryTimeChange={handleDeliveryTimeChange}
          unit={category.deliveryTime.unit}
        />
      </LabelWrapper>
    </AutoSaveForm>
  );
};

export const DeliveryTimeContainer = connect(null, mapDispatchToProps)(Component);

const styles = stylesheet({
  wrapper: {
    marginLeft: '11px',
  },
});
