import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';

import { Reducer } from '../../modules';
import { appActions } from '../app';
import * as blobsActions from './actions';

// STATE
export type State = Readonly<{
  error: string | null;
  attachmentField: string | null;
  attachmentUrl: string | null;
}>;

const error: Reducer<State['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(blobsActions.storeFileError):
    case getType(blobsActions.getTokenError):
      return action.payload;

    case getType(blobsActions.storeFileRequest):
    case getType(appActions.resetErrors):
      return null;

    default:
      return state;
  }
};

const attachmentField: Reducer<State['attachmentField']> = (state = null, action) => {
  switch (action.type) {
    case getType(blobsActions.storeFileSuccess):
      return action.payload;

    case getType(blobsActions.resetAttachment):
      return null;

    default:
      return state;
  }
};

const attachmentUrl: Reducer<State['attachmentUrl']> = (state = null, action) => {
  switch (action.type) {
    case getType(blobsActions.getTokenSuccess):
      return action.payload;

    case getType(blobsActions.resetAttachment):
      return null;

    default:
      return state;
  }
};

const reducer: Reducer<State> = combineReducers({
  error,
  attachmentField,
  attachmentUrl,
});

export default reducer;
