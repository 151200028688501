import * as qs from 'query-string';
import {
  CustomerInfoDTO,
  CustomerInfoModel,
  SIWSessionCreateDTO,
  SIWSessionCreateModel,
  SIWSessionCreateResponseDTO,
  SIWSessionCreateResponseModel,
  SIWSessionDTO,
  SIWSessionModel,
  SIWSessionUpdateDTO,
  SIWSessionUpdateModel,
} from '../models';
import { apiUtils, authFetch } from '../utils';

const SIW_URL = apiUtils.getApiUrl() + '/frontend/siw';

export const createSessionWith = async (
  model: SIWSessionCreateModel,
  siteId: string
): Promise<SIWSessionCreateResponseModel> => {
  const payload = SIWSessionCreateDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };

  return authFetch<SIWSessionCreateResponseDTO>(`${SIW_URL}/session.create`, options).then(res =>
    SIWSessionCreateResponseModel.createFromDTO(res)
  );
};

export const updateSessionWith = async (
  model: SIWSessionUpdateModel,
  siteId: string
): Promise<SIWSessionModel> => {
  const payload = SIWSessionUpdateDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: { 'x-site-id': siteId },
    body: JSON.stringify({ ...payload }),
  };

  return authFetch<{ session: SIWSessionDTO }>(`${SIW_URL}/session.update`, options).then(res =>
    SIWSessionModel.createFromDTO(res.session)
  );
};

export const suspendWidget = () => window._sw && window._sw(api => api.suspend());
export const resumeWidget = () => window._sw && window._sw(api => api.resume());

export const getSession = async (
  siteId: string,
  sessionId: string
): Promise<{ model: SIWSessionModel; dto: SIWSessionDTO }> => {
  const options = {
    method: 'GET',
    headers: { 'x-site-id': siteId },
  };

  return authFetch<{ session: SIWSessionDTO }>(
    `${SIW_URL}/session.get?${qs.stringify({ id: sessionId })}`,
    options
  ).then(res => ({ model: SIWSessionModel.createFromDTO(res.session), dto: res.session }));
};

export const completeSession = async ({
  siteId,
  session,
  externalId,
}: {
  siteId: string;
  session: { id: string; customer: CustomerInfoModel };
  externalId?: string;
}) => {
  const options = {
    method: 'POST',
    headers: { 'x-site-id': siteId },
    body: JSON.stringify({
      ...session,
      external_id: externalId,
      customer: CustomerInfoDTO.createFromModel(session.customer),
    }),
  };

  return authFetch<{ session: SIWSessionDTO; tos_id: string }>(
    `${SIW_URL}/session.complete`,
    options
  ).then(res => ({ tosId: res.tos_id }));
};
