import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';

import { Modal } from '@src/controls';
import {
  DeliveryStatus,
  NotificationType,
  ShipmentSummaryModel,
  TriggerCreateModel,
} from '@src/models';
import { RootState } from '@src/modules';
import { authSelectors } from '@src/modules/auth';
import { shipmentNotificationsActions } from '@src/modules/shipment-notifications';
import { SitesSelectors } from '@src/modules/sites';
import { ShipmentNotificationsCreateForm } from './components';

const mapStateToProps = (state: RootState) => ({
  siteId: SitesSelectors.getSelectedSiteIdOrEmpty(state),
  userId: authSelectors.getAuthUserId(state),
});

const mapDispatchToProps = {
  createTrigger: shipmentNotificationsActions.createTriggerRequest,
};

interface OwnProps {
  isVisible: boolean;
  changeModalVisibility: () => void;
  shipmentForMonitoring: ShipmentSummaryModel | null;
  sideEffectOnSubmit: () => void;
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & OwnProps;

class Component extends React.Component<Props> {
  handleSubmit = (
    notificationType: NotificationType | null,
    expectedStatus: DeliveryStatus | null,
    dueDate: moment.Moment | null
  ) => {
    const {
      shipmentForMonitoring,
      siteId,
      createTrigger,
      userId,
      changeModalVisibility,
      sideEffectOnSubmit,
    } = this.props;
    if (shipmentForMonitoring) {
      const model = new TriggerCreateModel();
      model.siteId = siteId;
      model.userId = userId;
      model.type = notificationType || undefined;
      model.expectedStatus = expectedStatus || undefined;
      model.dueDate = dueDate ? dueDate.utc().format() : undefined;
      model.trackingNumbers = shipmentForMonitoring.trackingNumbers!;
      model.shipmentId = shipmentForMonitoring.id;
      model.meta = {
        customerName: shipmentForMonitoring.customerInfo.address.name,
      };

      createTrigger(model);
    }
    changeModalVisibility();
    sideEffectOnSubmit();
  };

  render() {
    const { isVisible, changeModalVisibility } = this.props;
    return (
      <Modal
        visible={isVisible}
        closable={true}
        footer={null}
        onCancel={changeModalVisibility}
        title="Set notifications"
      >
        <ShipmentNotificationsCreateForm
          onCancel={changeModalVisibility}
          onSubmit={this.handleSubmit}
        />
      </Modal>
    );
  }
}

export const ShipmentNotificationsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
