import React, { FC, Fragment } from 'react';

export const EmptyList: FC = () => (
  <Fragment>
    <span>
      Booking rules feature is a rule based decision making of what shipping method to book for the
      specific case.
    </span>
    <span>
      You don’t have any booking rules set yet. Please contact our customer support for further
      information.
    </span>
  </Fragment>
);
