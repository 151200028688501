import * as React from 'react';

import { DashboardOutlined, LeftOutlined } from '@ant-design/icons';
import { classes, style as tss } from 'typestyle';
import { Alert, Button, Dropdown } from '../controls';

import { Spin } from 'antd';
import { LABELS } from '../dictionaries';
import { defaultTheme } from '../styles';

type Props = {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  IconComponent?: React.ComponentType;
  isLoading?: boolean;
  onMainActionClick?: null | ((ev: any) => any);
  secondaryButton?: JSX.Element;
  mainActionLabel?: string;
  mainActionType?: 'submit' | 'button';
  onBack?: (ev: any) => any;
  additionalActionsConfig?: { label: string; handler: (ev: any) => any }[];
  statusComponent?: JSX.Element | null;
  status?: string | null;
  error?: string | null;
  lastUpdated?: string;
  createdAt?: string;
  hideMainButton?: boolean;
  onBackLabel?: string;
  className?: string;
};

type State = 'actionable' | 'readonly';

const getInitialState = (status: string | null, hideMainButton: Props['hideMainButton']): State => {
  if (hideMainButton) {
    return 'readonly';
  } else {
    switch (status) {
      case 'CANCELED':
      case 'UNKNOWN':
        return 'readonly';

      default:
        return 'actionable';
    }
  }
};

const ContainerFixedHeader: React.StatelessComponent<Props> = ({
  title,
  subtitle,
  IconComponent = DashboardOutlined,
  isLoading = false,
  onMainActionClick = () => null,
  secondaryButton,
  mainActionLabel = LABELS.SUBMIT,
  mainActionType = 'button' as Props['mainActionType'],
  onBack,
  additionalActionsConfig = [],
  statusComponent = null,
  status = null,
  error,
  lastUpdated,
  hideMainButton,
  createdAt,
  className,
}) => {
  const state: State = getInitialState(status, hideMainButton);

  return (
    <>
      <div className={styles.root}>
        {onBack && (
          <Button
            type="ghost"
            disabled={isLoading}
            onClick={onBack}
            icon={<LeftOutlined />}
            className={classes('t_container-header-back', styles.backButton)}
          />
        )}
        <div className={classes(styles.infoWrapper, className)}>
          <div className={styles.iconWrapper}>
            <IconComponent className={styles.icon} />
          </div>
          <div>
            <div className={styles.title}>{title}</div>
            {subtitle && <div>{subtitle}</div>}
            <div>{lastUpdated && `Last updated at: ${new Date(lastUpdated).toLocaleString()}`}</div>
            {createdAt && <div> {`Created at: ${new Date(createdAt).toLocaleString()}`} </div>}
            {state !== 'actionable' && isLoading && <Spin size="small" />}
          </div>
          {statusComponent}
        </div>

        {state === 'actionable' && (
          <>
            {secondaryButton}

            <Dropdown.Button
              disabled={isLoading}
              htmlType={mainActionType}
              onMainActionClick={onMainActionClick}
              overlayConfig={additionalActionsConfig}
              mainActionLabel={mainActionLabel}
            />
          </>
        )}
      </div>
      {error && (
        <Alert
          className={styles.errorsInfo}
          message="Error"
          description={error}
          type="error"
          closable={true}
        />
      )}
    </>
  );
};

const styles = {
  root: tss({
    display: 'flex',
    alignItems: 'center',
    minHeight: '120px',
    position: 'sticky',
    top: 0,
    zIndex: 2,
    backgroundColor: defaultTheme.color.neutralLight,
  }),
  backButton: tss({
    marginLeft: 24,
    marginBottom: 17,
    border: 'unset',
  }),
  title: tss({
    fontSize: defaultTheme.font.large,
  }),
  infoWrapper: tss({
    display: 'flex',
    height: 48,
    flex: 2,
    paddingLeft: 8,
  }),
  iconWrapper: tss({
    height: 32,
    width: 32,
    backgroundColor: defaultTheme.color.primary,
    borderRadius: 4,
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  icon: tss({
    fontSize: 16,
    color: defaultTheme.icon.color,
  }),
  errorsInfo: tss({
    margin: '0 25px',
  }),
};

export default ContainerFixedHeader;
