import { FormattedMessage } from '@src/i18n';
import { getMerchantSelected } from '@src/services/cookies-service';
import { APOTEKET_MERCHANT_ID } from '@src/utils/constants';
import { Col, Row, Switch } from 'antd';
import { FieldState } from 'formstate';
import { isEqual } from 'lodash';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { Card, Input, Select } from '../../../controls';
import { withFieldStateSelect, withFormItem } from '../../../decorators';
import { defaultTheme } from '../../../styles';
import { ShippingMethodMetadata } from '../shipping-method-options';

const MetadataInputField = withFormItem(Input);
const MetadataSelectField = withFormItem(withFieldStateSelect(Select));

interface MetadataInputsProps {
  fieldState: FieldState<{ [name: string]: string }>;
  metadataFields: ShippingMethodMetadata[];
  disabled?: boolean;
}

interface MetadataInputState {
  [name: string]: string;

  customInput: string;
  customInputValue: string;
  pnlSnapshot: string;
}

export class MetadataInputs extends React.Component<MetadataInputsProps, MetadataInputState> {
  state: MetadataInputState = {
    ...this.props.fieldState.$,
    customInput: 'undefined',
    customInputValue: '',
    pnlSnapshot: '',
  };

  PNLFieldName = 'pnl.customer_id';

  isPNLValueCustom(value: string, field: ShippingMethodMetadata) {
    return !field.options?.some(option => option.value === value);
  }

  componentDidUpdate(prevProps: MetadataInputsProps) {
    this.initialCustomPNLSwitchStateSet();

    if (!isEqual(prevProps, this.props)) {
      this.setState(this.props.fieldState.$);
    }
  }

  propagateStateChange = () => {
    const { fieldState } = this.props;
    const cleanState = JSON.parse(JSON.stringify(this.state));
    delete cleanState.customInput;
    delete cleanState.customInputValue;
    delete cleanState.pnlSnapshot;
    fieldState.onChange(cleanState);
  };

  onChangeHandlers = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ [name]: value }, this.propagateStateChange);
  };

  onChangeSelectHandlers = (name: string) => (value: string) => {
    this.setState({ [name]: value }, this.propagateStateChange);
  };

  toggleCustomPNLSwitch(on: boolean) {
    // on toggle flip we take snapshot of normal pnl input
    // we have custom value for custom input that at the same time updates pnl value
    // if we decide to flip back to normal it will update it with the snapshot
    // if we will go back to custom it will update with custom value
    // that way we still operate on the same variable but with two inputs that seem disconnected from each other
    this.setState(
      {
        pnlSnapshot: on ? this.state[this.PNLFieldName] : this.state.pnlSnapshot,
        [this.PNLFieldName]: on ? this.state.customInputValue : this.state.pnlSnapshot,
        customInput: `${on}`,
      },
      this.propagateStateChange
    );
  }

  initialCustomPNLSwitchStateSet() {
    const { metadataFields, fieldState } = this.props;
    const PNLField = metadataFields.find(({ name }) => name === this.PNLFieldName);
    const currentValue = fieldState.$[this.PNLFieldName];
    if (
      getMerchantSelected() === APOTEKET_MERCHANT_ID &&
      PNLField &&
      currentValue &&
      this.state.customInput === 'undefined'
    ) {
      const isCurrentValueCustom = this.isPNLValueCustom(currentValue, PNLField);

      this.setState({
        customInput:
          this.state.customInput !== `${isCurrentValueCustom}`
            ? `${isCurrentValueCustom}`
            : this.state.customInput,
        customInputValue: isCurrentValueCustom ? currentValue : this.state.customInputValue,
      });
    }
  }

  render() {
    const { metadataFields, disabled } = this.props;

    return (
      metadataFields.length > 0 && (
        <Card title="Metadata" className={styles.metadataFields}>
          <Row gutter={12}>
            {metadataFields.map(field => {
              const { displayName, name, required, options } = field;

              const customPNLNumberFeatureEnabled =
                name === this.PNLFieldName && getMerchantSelected() === APOTEKET_MERCHANT_ID;
              const showCustomInput = customPNLNumberFeatureEnabled && !disabled;
              const currentValue = this.state[name];
              const isCurrentValueCustom = this.isPNLValueCustom(currentValue, field);

              const optionsAvailable = options && options.length;
              return (
                <Col span={showCustomInput ? 20 : 10} key={name}>
                  <div style={{ width: showCustomInput ? '50%' : undefined }}>
                    {!optionsAvailable ||
                    (customPNLNumberFeatureEnabled && disabled && isCurrentValueCustom) ? (
                      <MetadataInputField
                        disabled={this.state.customInput === 'true' || disabled}
                        key={name}
                        label={displayName}
                        value={currentValue}
                        required={required}
                        onChange={this.onChangeHandlers(name)}
                        labelCol={{ span: 24 }}
                      />
                    ) : (
                      <MetadataSelectField
                        disabled={this.state.customInput === 'true' || disabled}
                        key={name}
                        label={displayName}
                        className={`t_${name.replace('.', '-')}`}
                        dropdownClassName={`t_${name.replace('.', '-')}-dropdown`}
                        required={required}
                        options={options ? options : []}
                        value={this.state.customInput === 'true' ? '' : currentValue}
                        onChange={this.onChangeSelectHandlers(name)}
                        labelCol={{ span: 24 }}
                      />
                    )}
                  </div>
                  {showCustomInput ? (
                    <div className={styles.customInputWrapper}>
                      <div className={styles.customSwitchWrapper}>
                        <FormattedMessage id="ADD_PNL_CUSTOMER_NUMBER_MANUALLY" />
                        <Switch
                          className={styles.customSwitch}
                          onChange={on => this.toggleCustomPNLSwitch(on)}
                          checked={this.state.customInput === 'true' ? true : false}
                        />
                      </div>
                      {this.state.customInput === 'true' ? (
                        <Input
                          className={styles.customInput}
                          value={this.state.customInputValue}
                          onChange={e => {
                            this.setState({ customInputValue: e.target.value });
                            this.onChangeHandlers(name)(e);
                          }}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </Col>
              );
            })}
          </Row>
        </Card>
      )
    );
  }
}

const styles = stylesheet({
  metadataFields: {
    marginTop: defaultTheme.lineHeight,
  },
  customInputWrapper: {
    marginTop: -10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  customSwitchWrapper: { display: 'flex', paddingTop: 5, paddingBottom: 5, alignItems: 'center' },
  customSwitch: { margin: '0px 16px' },
  customInput: { width: '50%' },
});
