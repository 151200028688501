import { Modal } from '@src/controls';
import { AutoSaveForm, AutoSaveFormikEnhancer, inputStyle } from '@src/forms';
import { CarrierProductSettingsMapping, TrackingConfigurationStatus } from '@src/models';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import * as yup from 'yup';
import { DefaultTrackingLinks } from '../components/default-tracking-links';
import {
  buildTrackingLinksFormValue,
  convertTrackingLinksToMapping,
  getTrackingLinksValidationSchema,
} from '../form-commons';
import { AlwaysShowTrackingLinksFieldAutoSave } from '../form-components/autosave-fields';
import { UseCustomMappingFieldConnected } from '../form-components/connected-fields';
import { TrackingConfigStatusField } from '../form-components/fields';
import { TrackingLinksTable } from '@src/containers/product-mappings/components/tracking-links-table';
import { stylesheet } from 'typestyle';
import { useTrackingLinksToggleDisability } from '@src/containers/product-mappings/utils/control-tracking-links-toggle';

type Props = {
  isVisible: boolean;
  onCancel: () => void;
  onHandleMutate: (values: CarrierProductSettingsMapping) => void;
  isLoading: boolean;
  carrierProductId: string;
  carrierProductName?: string;
  carrierProductsSettingsMapping?: CarrierProductSettingsMapping[];
};

type FormValues = {
  custom: boolean;
  always_show_tracking_links: boolean;
  tracking_links: {
    locale: string | undefined;
    url: string;
  }[];
  tracking_config_status?: TrackingConfigurationStatus;
};

export const IntegratedCarrierProductsUpdate: FunctionComponent<Props> = ({
  isVisible,
  onCancel,
  onHandleMutate,
  isLoading,
  carrierProductId,
  carrierProductName,
  carrierProductsSettingsMapping,
}) => {
  const integratedCarrierProduct = (carrierProductsSettingsMapping || []).find(
    mapping => mapping.carrier_product_id === carrierProductId
  );

  const { disabled } = useTrackingLinksToggleDisability(
    isLoading,
    integratedCarrierProduct?.tracking_links
  );

  const handleSave = (values: FormValues) => {
    const trackingLinks = convertTrackingLinksToMapping(values.tracking_links);

    onHandleMutate({
      ...values,
      tracking_links: trackingLinks,
      always_show_tracking_links: trackingLinks ? values.always_show_tracking_links : false,
      carrier_product_id: carrierProductId,
    });
  };

  return (
    <Modal visible={isVisible} footer={null} onCancel={onCancel} closable={false}>
      <>
        <h1 className={styles.modalHeader}>{carrierProductName}</h1>
        <Formik<FormValues>
          initialValues={buildFormValues(integratedCarrierProduct)}
          validationSchema={yup.object({ tracking_links: getTrackingLinksValidationSchema() })}
          onSubmit={_ => {}}
          enableReinitialize
        >
          {formik => (
            <AutoSaveForm>
              <TrackingConfigStatusField
                status={integratedCarrierProduct?.tracking_config_status}
                isLoading={false}
              />
              <AutoSaveFormikEnhancer
                name="custom"
                onSave={handleSave}
                validateAllFields
                render={({ onInstantChange }) => (
                  <UseCustomMappingFieldConnected
                    onChange={onInstantChange}
                    className={inputStyle}
                  />
                )}
              />
              {formik.values.custom ? (
                <TrackingLinksTable handleSave={handleSave} />
              ) : (
                <DefaultTrackingLinks carrierProductId={carrierProductId || ''} />
              )}
              <AlwaysShowTrackingLinksFieldAutoSave handleSave={handleSave} disabled={disabled} />
            </AutoSaveForm>
          )}
        </Formik>
      </>
    </Modal>
  );
};

const styles = stylesheet({
  modalHeader: {
    display: 'block',
    height: '28px',
    lineHeight: '28px',
    fontSize: '20px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.85)',
    marginBottom: '16px',
  },
});

const buildFormValues = (mapping: CarrierProductSettingsMapping | undefined): FormValues => {
  if (!mapping) {
    return {
      custom: false,
      always_show_tracking_links: false,
      tracking_links: [],
      tracking_config_status: undefined,
    };
  }
  const tracking_links = buildTrackingLinksFormValue(mapping.tracking_links);

  return {
    ...mapping,
    always_show_tracking_links: mapping.tracking_links ? mapping.always_show_tracking_links : false,
    tracking_links: tracking_links,
  };
};
