import { SitesSelectors } from '@src/modules/sites';
import { services } from '@src/services';
import { useSelector } from '@src/utils/hooks';
import { useFormikContext } from 'formik';
import React, { FunctionComponent, useEffect } from 'react';
import { stylesheet } from 'typestyle';
import { FormValues } from './testclient';

const env = services.envService.getEnvironmentalVariables();

export const Widget: FunctionComponent<{ token: string }> = ({ token }) => {
  const {
    values: { sessionId, locale },
  } = useFormikContext<FormValues>();
  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);
  const containerId = 'receipt-widget';

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = false;
    script.dataset.source = 'ingrid';
    script.src = env.MAD_RECEIPT_WIDGET_URL;
    document.body.appendChild(script);
    script.onload = initReceiptWidget;
    return () => script.remove();
  }, []);

  const initReceiptWidget = () => {
    window.IngridReceiptWidgetApi.config({
      containerId,
      token,
      siteId,
      sessionId,
      locale,
    });
  };

  return (
    <div className={styles.wrapper}>
      <div id={containerId} className={styles.widgetContainer}></div>
    </div>
  );
};

const styles = stylesheet({
  wrapper: { display: 'grid', placeItems: 'center' },
  widgetContainer: {
    minWidth: '420px',
  },
});
