import { sanitizeUrl } from '@braintree/sanitize-url';
import { combineEpics, Epic } from 'redux-observable';
import { from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { labelMergerActions } from '.';
import { ERRORS } from '../../dictionaries';
import { RootAction, RootState, Services } from '../../modules';
import { MADError } from '../../utils';

const mergeLabelsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { labelMergerService, loggingService, messageService }
) =>
  action$.pipe(
    filter(isActionOf(labelMergerActions.mergeLabelsRequest)),
    switchMap(({ payload: { siteId, fileNames } }) =>
      observableFrom(labelMergerService.mergeLabels(siteId, fileNames)).pipe(
        map(({ url }) => labelMergerActions.mergeLabelsSuccess(sanitizeUrl(url))),
        catchError((error: MADError) =>
          observableOf(labelMergerActions.mergeLabelsError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              messageService.error(ERRORS.STORING_FILE_ERROR);
            })
          )
        )
      )
    )
  );

export const labelMergerEpics = combineEpics(mergeLabelsEpic);
