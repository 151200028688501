import * as qs from 'query-string';

import {
  CarrierAddonsModel,
  ConfigMerchantDTO,
  ConfigMerchantModel,
  ConfigMerchantSummaryDTO,
  ConfigMerchantSummaryModel,
  ConfigShippingMethodDTO,
  ConfigShippingMethodModel,
  ConfigSiteDTO,
  ConfigSiteModel,
  GetPrivateKeyDTO,
  GetPrivateKeyModel,
} from '@src/models';
import { apiUtils, authFetch } from '@src/utils';

const CONFIG_URL = apiUtils.getApiUrl() + '/config';

export async function listMerchants(): Promise<ConfigMerchantSummaryModel[]> {
  const options: RequestInit = {
    method: 'GET',
    headers: {},
  };

  return authFetch<{ merchants: ConfigMerchantSummaryDTO[] }>(
    `${CONFIG_URL}/merchants.list`,
    options
  ).then(res =>
    res.merchants
      ? res.merchants.map(merchantDTO => ConfigMerchantSummaryModel.createFromDTO(merchantDTO))
      : []
  );
}

export async function getShippingMethods(siteId?: string): Promise<ConfigShippingMethodModel[]> {
  const options: RequestInit = {
    method: 'GET',
  };

  return authFetch<{ methods: ConfigShippingMethodDTO[] }>(
    `${CONFIG_URL}/shippingmethods.get?${qs.stringify({ site_id: siteId })}`,
    options
  ).then(res =>
    res.methods ? res.methods.map(item => ConfigShippingMethodModel.createFromDTO(item)) : []
  );
}

export async function getMerchant(merchantId: string): Promise<ConfigMerchantModel> {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-merchant-id': merchantId,
    },
  };

  return authFetch<{ merchant: ConfigMerchantDTO }>(
    `${CONFIG_URL}/merchant.get?` +
      qs.stringify({
        merchant_id: merchantId,
      }),
    options
  ).then(res => ConfigMerchantModel.createFromDTO(res.merchant));
}

export async function getCarrierAddons(siteId: string): Promise<CarrierAddonsModel[]> {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<{ addons: CarrierAddonsModel[] }>(
    `${CONFIG_URL}/carrieraddons.get`,
    options
  ).then(res => res.addons);
}

export async function getSite(siteId: string): Promise<ConfigSiteModel> {
  const options: RequestInit = {
    method: 'GET',
  };

  return authFetch<{ site: ConfigSiteDTO }>(
    `${CONFIG_URL}/site.get?${qs.stringify({ siteId })}`,
    options
  ).then(res => ConfigSiteModel.createFromDTO(res.site));
}

export async function getPrivateKey(siteId: string): Promise<GetPrivateKeyModel> {
  return authFetch<GetPrivateKeyDTO>(
    `${CONFIG_URL}/privatekey.get?${qs.stringify({ site_id: siteId })}`,
    {}
  ).then(res => GetPrivateKeyModel.createFromDTO(res));
}
