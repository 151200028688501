import { SwitchProps } from 'antd/lib/switch/index';
import { FieldState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';

type HOCProps = {
  fieldState?: FieldState<any>;
};

export function withFieldStateSwitch<WrappedComponentProps extends SwitchProps>(
  WrappedComponent:
    | React.StatelessComponent<WrappedComponentProps>
    | React.ComponentClass<WrappedComponentProps>
) {
  const HOC: React.StatelessComponent<HOCProps & WrappedComponentProps> = props => {
    const { fieldState, ...passThroughProps } = props as HOCProps;

    const overriddenProps: SwitchProps = {};
    if (fieldState) {
      overriddenProps.checked = fieldState.value;
      overriddenProps.onChange = (checked: boolean) => {
        fieldState.onChange(checked);
      };
    }

    return <WrappedComponent {...(passThroughProps as any)} {...overriddenProps} />;
  };

  return observer(HOC);
}
