import * as qs from 'query-string';
import { apiUtils, authFetch } from '../utils';

import {
  CreateExperimentDTO,
  CreateExperimentModel,
  CreateVariantDTO,
  CreateVariantModel,
  ExperimentDTO,
  ExperimentModel,
  ExperimentResultDTO,
  ExperimentWithVariantDTO,
  ExperimentWithVariantModel,
  UpdateExperimentDTO,
  UpdateExperimentModel,
  UpdateVariantDTO,
  UpdateVariantModel,
  VariantDTO,
  VariantModel,
} from '../models';

const EXPERIMENT_URL = apiUtils.getApiUrl() + '/config_abtesting';

export function listExperiments(siteId: string) {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<{ experiments: ExperimentDTO[] }>(
    `${EXPERIMENT_URL}/experiments?${qs.stringify({ site_id: siteId })}`,
    options
  ).then(res => {
    if (!res.experiments) {
      return [];
    }
    return res.experiments.map(ExperimentModel.createFromDTO);
  });
}

export function getExperiment(siteId: string, experimentId: string) {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<{ experiment: ExperimentWithVariantDTO; result: ExperimentResultDTO }>(
    `${EXPERIMENT_URL}/experiment/${experimentId}?${qs.stringify({ site_id: siteId })}`,
    options
  ).then(res =>
    ExperimentWithVariantModel.createFromDTO({ ...res.experiment, result: res.result })
  );
}

export function createExperiment(data: CreateExperimentModel) {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify(CreateExperimentDTO.createFromModel(data)),
  };

  return authFetch<{}>(`${EXPERIMENT_URL}/experiment`, options);
}

export function updateExperiment(data: UpdateExperimentModel) {
  const options: RequestInit = {
    method: 'PUT',
    body: JSON.stringify(UpdateExperimentDTO.createFromModel(data)),
  };

  return authFetch<{}>(`${EXPERIMENT_URL}/experiment/${data.id}`, options);
}

export function deleteExperiment(siteId: string, experimentId: string) {
  const options: RequestInit = {
    method: 'DELETE',
  };

  return authFetch<{}>(
    `${EXPERIMENT_URL}/experiment/${experimentId}?${qs.stringify({ site_id: siteId })}`,
    options
  );
}

export function createVariant(experimentId: string, data: CreateVariantModel) {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify(CreateVariantDTO.createFromModel(data)),
  };

  return authFetch<{ variant: VariantDTO }>(
    `${EXPERIMENT_URL}/experiment/${experimentId}/variant`,
    options
  ).then(res => VariantModel.createFromDTO(res.variant));
}

export function updateVariant(data: UpdateVariantModel) {
  const options: RequestInit = {
    method: 'PUT',
    body: JSON.stringify(UpdateVariantDTO.createFromModel(data)),
  };

  return authFetch<{}>(
    `${EXPERIMENT_URL}/experiment/${data.experimentId}/variant/${data.id}`,
    options
  );
}

export function deleteVariant(siteId: string, experimentId: string, variantId: string) {
  const options: RequestInit = {
    method: 'DELETE',
  };

  return authFetch<{}>(
    `${EXPERIMENT_URL}/experiment/${experimentId}/variant/${variantId}?${qs.stringify({
      site_id: siteId,
    })}`,
    options
  );
}

export function startExperiment(siteId: string, experimentId: string) {
  const data = {
    site_id: siteId,
    experiment_id: experimentId,
  };

  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify(data),
  };

  return authFetch<{}>(`${EXPERIMENT_URL}/experiment/${experimentId}/start`, options);
}

export function endExperiment(siteId: string, experimentId: string) {
  const data = {
    site_id: siteId,
    experiment_id: experimentId,
  };

  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify(data),
  };

  return authFetch<{}>(`${EXPERIMENT_URL}/experiment/${experimentId}/end`, options);
}
