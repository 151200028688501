import { Breadcrumb } from 'antd';
import { fromPairs } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { stylesheet } from 'typestyle';

import { CheckoutWidgetDrawer, ContainerFixedHeader } from '@src/components';
import { Link } from '@src/components/link';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import {
  createUpdateCategoryDetailsModel,
  ShippingCategoryModal,
} from '@src/containers/regions/shipping-category';
import { WarehouseModal } from '@src/containers/warehouse';
import { Card, Spinner } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import {
  ConfigShippingCategoryModel,
  CreateOrUpdateWarehouseCutoffTimesModel,
  DeliveryTypeEnum,
  StateEnum,
  UpdateConfigRegionModel,
} from '@src/models';
import { RootState } from '@src/modules';
import { configActions, configSelectors } from '@src/modules/config';
import { routerActions } from '@src/modules/router';
import { SitesSelectors } from '@src/modules/sites';
import { messageService } from '@src/services/message-service';
import { defaultCutoffTimes, scroll } from '@src/utils';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { useDispatch } from '@src/utils/hooks';
import { warehouseSelectors } from '../warehouse/selectors';
import { CarrierProductsListContainer } from './carrier-products/components';
import { CreateCategoryFormValues, RegionDetails, ShippingCategoriesList } from './components';
import { RegionErrorHandlingContainer } from './region-error-handling-container';
import { buildRegionModel } from './region-helpers';

interface OwnProps {
  regionId: string;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  regionsNames: configSelectors.getRegionsNames(state),
  region: configSelectors.getRegionById(state, props.regionId),
  shouldShowSpinner: configSelectors.shouldShowSpinner(state),
  shippingCategories: configSelectors.getCategoriesWithMethodsFromRegionCarrierProducts(
    state,
    props.regionId
  ),
  isSendingChanges: state.config.isSendingChanges,
  merchantId: state.merchants.selectedMerchantId,
  siteId: SitesSelectors.getDraftSiteIdOrMasterSiteId(state),
  isCreatingDraft: state.config.isCreatingDraft,
  warehouses: warehouseSelectors.getWarehousesByDraftOrCurrentSite(state),
  copyCutoffsProgress: state.config.copyCutoffsProgress,
  currentWarehouse: warehouseSelectors.getWarehouseByLinkedRegion(state, props.regionId),
});

const mapDispatchToProps = () => ({
  updateRegionDetails: dispatchOnDraftSiteId(configActions.updateRegionDetailRequest),
  createCategory: dispatchOnDraftSiteId(configActions.createCategoryRequest),
  deleteCategory: dispatchOnDraftSiteId(configActions.deleteCategoryRequest),
  updateCategoryDetails: dispatchOnDraftSiteId(configActions.updateCategoryDetailsRequest),
  createCarrierProduct: dispatchOnDraftSiteId(configActions.createCarrierProductRequest),
  createWarehouse: dispatchOnDraftSiteId(configActions.createWarehouseRequest),
  createCutoffs: dispatchOnDraftSiteId(configActions.createWarehouseCutoffTimesRequest),
  createOrUpdateCutoffs: dispatchOnDraftSiteId(configActions.createOrUpdateCutoffTimesRequest),
});

const stopUserFromLeaving = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  const message = 'Changes you made might not be saved if you leave';
  e.returnValue = message;
  return message;
};

type Props = ReturnType<typeof mapStateToProps> & OwnProps & ReturnType<typeof mapDispatchToProps>;

const RegionDetailsWithShipping: React.FunctionComponent<Props> = ({
  regionsNames,
  region,
  shippingCategories,
  isSendingChanges,
  updateRegionDetails,
  regionId,
  createCategory,
  deleteCategory,
  shouldShowSpinner,
  updateCategoryDetails,
  isCreatingDraft,
  createCarrierProduct,
  warehouses,
  createWarehouse,
  createCutoffs,
  createOrUpdateCutoffs,
  copyCutoffsProgress,
  currentWarehouse,
}) => {
  const [isWidgetCategoryModalVisible, changeWidgetCategoryVisibility] = React.useState(false);
  const [currentWidgetCategoryId, changeCurrentWidgetCategoryId] = React.useState<string | null>(
    null
  );
  const [isAddingCategory, changeIsAddingCategory] = React.useState(false);
  const [isWarehouseModalVisible, changeWarehouseVisibility] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isAddingCategory && !isSendingChanges) {
      changeIsAddingCategory(false);
    }
  }, [isSendingChanges]);

  React.useEffect(() => {
    if (isSendingChanges) {
      window.addEventListener('beforeunload', stopUserFromLeaving);
    } else {
      window.removeEventListener('beforeunload', stopUserFromLeaving);
    }
    return window.removeEventListener('beforeunload', stopUserFromLeaving);
  }, [isSendingChanges]);

  const handleItemClick = (itemId: string) => {
    changeCurrentWidgetCategoryId(itemId);
    changeWidgetCategoryVisibility(!isWidgetCategoryModalVisible);
  };

  const handleRegionUpdate = (model: Partial<UpdateConfigRegionModel>) =>
    updateRegionDetails({ model: buildRegionModel(model, region!) });

  const openWarehouseModal = () => {
    changeWarehouseVisibility(!isWarehouseModalVisible);
  };

  const handleCategoryAdd = (values: CreateCategoryFormValues) => {
    changeIsAddingCategory(true);
    createCategory({
      model: {
        deliveryType: values.deliveryType as DeliveryTypeEnum,
        name: values.name,
        deliveryTime: { min: 0, max: 1, unit: 'business_day' },
        regionIds: [region!.id],
        shippingMethods: [],
        filterRules: [],
        timeFormatting: 'INTERVAL',
        state: StateEnum.INACTIVE,
        requirements: {
          showCarrierLogos: true,
          enableChoiceSelection: true,
        },
        additionalOptions: {
          instoreSearchOptions: {
            enabled: false,
            locationsLimit: 10,
          },
        },
      },
      onComplete: categoryID => {
        changeIsAddingCategory(false);
        handleItemClick(categoryID);
      },
    });
  };

  const handleCategoryDelete = (categoryId: string) => {
    deleteCategory({ categoryId });
  };

  const handleUpdateCategoryStatus = (category: ConfigShippingCategoryModel, active: boolean) => {
    updateCategoryDetails({
      model: createUpdateCategoryDetailsModel(
        { state: active ? StateEnum.ACTIVE : StateEnum.INACTIVE },
        category
      ),
    });
  };

  const handleCreateCarrierProduct = (
    {
      shippingMethod,
      externalMethodId,
      deliveryTypes,
      defaultCutoffs,
    }: {
      shippingMethod: string;
      externalMethodId?: string;
      deliveryTypes: DeliveryTypeEnum[];
      defaultCutoffs: string;
    },
    onCompleteProductCreate: () => void
  ) => {
    const handleCarrierProductCreate = (error?: string) => {
      if (error) {
        onCompleteProductCreate();
        return;
      }
      createCarrierProduct({
        model: {
          externalMethodId,
          regionId,
          deliveryTypes,
          method: shippingMethod,
          priceRules: [{ conditions: ['true'], price: 0 }],
        },
        onComplete: onCompleteProductCreate,
      });
    };

    if (!currentWarehouse) {
      messageService.error('No warehouse is linked to the region');
      return;
    }

    if (currentWarehouse.operatingSchedule && currentWarehouse.operatingSchedule[shippingMethod]) {
      handleCarrierProductCreate();
    } else {
      const cutoffsModel = {
        warehouseId: currentWarehouse.id,
        method: shippingMethod,
        cutoffTimes: fromPairs(
          ['mon', 'tue', 'wed', 'thu', 'fri'].map(day => [day, { times: [defaultCutoffs] }])
        ),
      };
      createCutoffs({
        model: cutoffsModel,
        onComplete: handleCarrierProductCreate,
      });
    }
  };

  const handleWarehouseChange = (newWarehouseId: string) => {
    const newWarehouse = warehouses.find(w => w.id === newWarehouseId);
    if (!newWarehouse) {
      return;
    }
    const prevWarehouse = warehouses.find(w => w.id === region!.warehouseId);
    if (!prevWarehouse) {
      return;
    }
    const methods = region!.carrierProducts.map(p => p.shippingMethod);
    const prevCutoffs = prevWarehouse.operatingSchedule ? prevWarehouse.operatingSchedule : {};

    const cutoffsToCopy = fromPairs(
      methods.map(m => {
        const maybePrevTimes = prevCutoffs[m];
        const cutoffTimes =
          maybePrevTimes && maybePrevTimes.cutoffTimes
            ? maybePrevTimes.cutoffTimes
            : defaultCutoffTimes;
        return [m, { cutoffTimes }];
      })
    );

    const models: CreateOrUpdateWarehouseCutoffTimesModel[] = [];
    const { operatingSchedule } = newWarehouse;
    for (const m of methods) {
      models.push({
        model: {
          warehouseId: newWarehouseId,
          method: m,
          cutoffTimes: cutoffsToCopy[m].cutoffTimes,
        },
        action: operatingSchedule && operatingSchedule[m] ? 'UPDATE' : 'CREATE',
      });
    }

    createOrUpdateCutoffs({
      warehouseId: newWarehouseId,
      models,
      onComplete: (error: string) => {
        if (!error) {
          updateRegionDetails({
            model: buildRegionModel({ warehouseId: newWarehouseId }, region!),
          });
        }
      },
    });
  };

  if (shouldShowSpinner) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner size="large" delay={0} />
      </div>
    );
  } else if (region) {
    return (
      <>
        <RegionDetails
          categories={shippingCategories}
          region={region!}
          regionsNames={regionsNames}
          handleUpdate={handleRegionUpdate}
          openWarehouseModal={openWarehouseModal}
          warehouses={warehouses}
          handleWarehouseChange={handleWarehouseChange}
          copyCutoffsProgress={copyCutoffsProgress}
        />
        <CarrierProductsListContainer
          region={region!}
          createCarrierProduct={handleCreateCarrierProduct}
          isCreatingDraft={isCreatingDraft}
          currentWarehouse={currentWarehouse}
        />
        <ShippingCategoriesList
          isLoading={isAddingCategory || isCreatingDraft}
          categories={shippingCategories}
          onItemClick={handleItemClick}
          onCategoryAdd={handleCategoryAdd}
          onCategoryDelete={handleCategoryDelete}
          onCategoryStatusUpdate={handleUpdateCategoryStatus}
        />
        <ShippingCategoryModal
          categoryId={currentWidgetCategoryId}
          isVisible={isWidgetCategoryModalVisible}
          changeVisibility={changeWidgetCategoryVisibility}
          regionId={regionId}
        />
        <WarehouseModal
          regionId={regionId}
          warehouseId={region.warehouseId}
          isVisible={isWarehouseModalVisible}
          changeVisibility={changeWarehouseVisibility}
        />
      </>
    );
  } else {
    return (
      <>
        <ContainerFixedHeader
          hideMainButton={true}
          onBack={() => dispatch(routerActions.push({ name: 'REGIONS_LIST' }))}
          title={<FormattedMessage id="ERROR" />}
        />
        <Card>
          <div className={styles.error}>
            <h2>
              <FormattedMessage id="REGION_NOT_FOUND" />
            </h2>
            <h3>
              <FormattedMessage id="REGION_NOT_FOUND_MSG" />
            </h3>
          </div>
        </Card>
      </>
    );
  }
};

const styles = stylesheet({
  wrapper: {
    padding: '24px',
  },
  breadcrumb: {
    padding: '10px 0 0 28px',
  },
  breadcrumbLink: {
    cursor: 'pointer',
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  error: {
    color: 'rgba(0, 0, 0, 0.75)',
    textAlign: 'center',
    padding: '25px',
  },
});

export const RegionDetailsWithShippingAndBreadcrumbs: React.FC<Props> = props => {
  const scrollref = scroll.useScrollIntoView();

  return (
    <>
      <RegionErrorHandlingContainer />
      <StatusBarContainer />
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link route={{ name: 'REGIONS_LIST' }} className={styles.breadcrumbLink}>
            <FormattedMessage id="REGION" values={{ multiple: true }} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{props.region?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.wrapper} ref={scrollref}>
        <RegionDetailsWithShipping {...props} />
      </div>
      <CheckoutWidgetDrawer />
    </>
  );
};

export const RegionDetailsEmbedded = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionDetailsWithShipping);

export const RegionDetailsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionDetailsWithShippingAndBreadcrumbs);
