import { routerActions } from 'connected-react-router';
import { combineEpics, Epic } from 'redux-observable';
import { from as observableFrom, of } from 'rxjs';
import { filter, first, ignoreElements, map, mergeMap, tap, throttleTime } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { appActions } from '.';
import { RootAction, RootState, Services } from '..';
import { authActions } from '../auth';
import { dictionariesActions } from '../dictionaries';
import { merchantsActions } from '../merchants';
import { sitesActions } from '../sites';

const initApp: Epic<RootAction, RootAction, RootState, Services> = (action$, {}) =>
  action$.pipe(
    first(),
    map(() => appActions.initApp())
  );

const initAuth: Epic<RootAction, RootAction, RootState, Services> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([appActions.initApp, authActions.fetchAuthUserSuccess])),
    filter(() => state$.value.auth.authUser != null),
    map(() => appActions.initAuth())
  );

const initDictionaries: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf([appActions.initAuth])),
    mergeMap(() => of(dictionariesActions.getAvailableLocalesRequest()))
  );

const initMerchants: Epic<RootAction, RootAction, RootState, Services> = (action$, {}) =>
  action$.pipe(
    filter(isActionOf([appActions.initAuth])),
    mergeMap(() => observableFrom([merchantsActions.listMerchants()]))
  );

const initSites: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([merchantsActions.listMerchantsSuccess])),
    map(() => ({
      siteId: state$.value.sites.selectedSiteId,
      merchantId: state$.value.merchants.selectedMerchantId,
    })),
    filter(({ siteId }) => siteId != null),
    filter(({ merchantId }) => merchantId != null),
    map(payload => {
      const siteId = payload.siteId as string;
      const merchantId = payload.merchantId as string;
      return sitesActions.getSitesRequest({ merchantId, siteId });
    })
  );

const resetUrlOnLogout: Epic<RootAction, RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf([authActions.logout])),
    map(() => routerActions.push('/'))
  );

const saveStateImmediately: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { localStorageService }
) =>
  action$.pipe(
    filter(
      isActionOf([
        authActions.authenticateSuccess,
        authActions.fetchAuthUserSuccess,
        authActions.logout,
      ])
    ),
    tap(() => {
      // handle side-effects
      const state = state$.value;
      localStorageService.saveState(state);
    }),
    ignoreElements()
  );

const saveStatePeriodically: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { localStorageService }
) =>
  action$.pipe(
    throttleTime(2000),
    tap(() => {
      // handle side-effects
      const state = state$.value;
      localStorageService.saveState(state);
    }),
    ignoreElements()
  );

export const appEpics = combineEpics(
  initApp,
  initAuth,
  initDictionaries,
  initMerchants,
  initSites,
  resetUrlOnLogout,
  saveStateImmediately,
  saveStatePeriodically
);
