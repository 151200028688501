import { v4 } from 'uuid';
import { AddressDTO, AddressModel, DimensionsDTO, DimensionsModel, ShipmentModel } from './';

export class TransportOrderDTO {
  id: string;
  sender: SenderDTO;
  receiver: ReceiverDTO;
  cart: CartDTO;
  external: ExternalDTO;
  notes: NotesDTO;
  metadata: MetadataDTO;
  shipping: ShippingDTO;
  site_id: string;
  created_at: string;
  updated_at: string;
  status: 'ACTIVE' | 'ARCHIVED' | 'UNKNOWN';
  route?: RouteDTO;
  source: string;

  static createFromModel(model: TransportOrderModel): TransportOrderDTO {
    return {
      id: model.id,
      sender: SenderDTO.createFromModel(model.sender),
      receiver: ReceiverDTO.createFromModel(model.receiver),
      cart: CartDTO.createFromModel(model.cart),
      external: ExternalDTO.createFromModel(model.external),
      notes: model.notes,
      metadata: MetadataDTO.createFromModel(model.metadata),
      shipping: ShippingDTO.createFromModel(model.shipping),
      site_id: model.siteId,
      created_at: model.createdAt,
      updated_at: model.updatedAt,
      status: model.status,
      route: model.route ? RouteDTO.createFromModel(model.route) : undefined,
      source: 'MAD',
    };
  }
}

export class TransportOrderModel {
  id: string = v4();
  sender: SenderModel = new SenderModel();
  receiver: ReceiverModel = new ReceiverModel();
  cart: CartModel = new CartModel();
  external: ExternalModel = new ExternalModel();
  notes: NotesModel = new NotesModel();
  metadata: MetadataModel = new MetadataModel();
  shipping: ShippingModel = new ShippingModel();
  siteId: string;
  createdAt: string = new Date().toISOString();
  updatedAt: string = new Date().toISOString();
  status: OrderStatusEnum = OrderStatusEnum.UNKNOWN;
  route?: RouteModel = new RouteModel();
  shipments?: ShipmentModel[] | undefined;

  static createFromDTO(dto: TransportOrderDTO): TransportOrderModel {
    return {
      id: dto.id,
      sender: SenderModel.createFromDTO(dto.sender),
      receiver: ReceiverModel.createFromDTO(dto.receiver),
      cart: CartModel.createFromDTO(dto.cart),
      external: ExternalModel.createFromDTO(dto.external),
      notes: dto.notes,
      metadata: MetadataModel.createFromDTO(dto.metadata),
      shipping: (dto.shipping && ShippingModel.createFromDTO(dto.shipping)) || new ShippingModel(),
      siteId: dto.site_id,
      createdAt: dto.created_at,
      updatedAt: dto.updated_at,
      status: OrderStatusEnum[dto.status],
      route: dto.route ? RouteModel.createFromDTO(dto.route) : undefined,
    };
  }
}

export class SenderModel {
  contactInfo: ContactInfoModel = new ContactInfoModel();

  static createFromDTO(dto: SenderDTO): SenderModel {
    return {
      contactInfo: dto.contact_info
        ? ContactInfoModel.createFromDTO(dto.contact_info)
        : new ContactInfoModel(),
    };
  }
}

export class SenderDTO {
  contact_info?: ContactInfoDTO;

  static createFromModel(model: SenderModel): SenderDTO {
    return {
      contact_info: ContactInfoDTO.createFromModel(model.contactInfo),
    };
  }
}

export class ReceiverModel {
  contactInfo: ContactInfoModel = new ContactInfoModel();
  // Destination details. This is an optional field, only needed if shipping location is different then ContactInfo.
  destination?: ContactInfoModel;
  // Billing details. This is an optional field, only needed if billing data is different then ContactInfo.
  billing?: ContactInfoModel;
  courierInstructions?: string;
  doorCode?: string;

  static createFromDTO(dto: ReceiverDTO): ReceiverModel {
    return {
      contactInfo: dto.contact_info
        ? ContactInfoModel.createFromDTO(dto.contact_info)
        : new ContactInfoModel(),
      courierInstructions: dto.courier_instructions,
      doorCode: dto.door_code,
      destination: dto.destination ? ContactInfoModel.createFromDTO(dto.destination) : undefined,
    };
  }
}

export class ReceiverDTO {
  contact_info?: ContactInfoDTO;
  destination?: ContactInfoDTO;
  billing?: ContactInfoDTO;
  courier_instructions?: string;
  door_code?: string;

  static createFromModel(model: ReceiverModel): ReceiverDTO {
    return {
      contact_info: ContactInfoDTO.createFromModel(model.contactInfo),
      destination:
        (model.destination && ContactInfoDTO.createFromModel(model.destination)) ||
        new ContactInfoDTO(),
      billing:
        (model.billing && ContactInfoDTO.createFromModel(model.billing)) || new ContactInfoDTO(),
      courier_instructions: model.courierInstructions,
      door_code: model.doorCode,
    };
  }
}

export class ContactInfoModel {
  address: AddressModel = new AddressModel();
  locationRef: string = '';
  phone?: string = '';
  email: string = '';
  externalId: string;
  name?: string;

  static createFromDTO(dto: ContactInfoDTO): ContactInfoModel {
    return {
      address: dto.address ? AddressModel.createFromDTO(dto.address) : new AddressModel(),
      locationRef: dto.location_ref,
      phone: dto.phone,
      email: dto.email,
      externalId: dto.external_id,
      name: dto.name,
    };
  }
}

export class ContactInfoDTO {
  address: AddressDTO;
  location_ref: string;
  phone?: string;
  email: string;
  external_id: string;
  name?: string;

  static createFromModel(model: ContactInfoModel): ContactInfoDTO {
    return {
      address: AddressDTO.createFromModel(model.address),
      location_ref: model.locationRef,
      phone: model.phone,
      email: model.email,
      external_id: model.externalId,
      name: model.name,
    };
  }
}

export class CartDTO {
  total_value?: number;
  currency?: string;
  items: CartItemDTO[];
  total_discount: number;
  pre_order: boolean;
  voucher: string;

  static createFromModel(model: CartModel): CartDTO {
    return {
      total_value: model.totalValue,
      currency: model.currency,
      items: model.items.map(i => CartItemDTO.createFromModel(i)),
      total_discount: model.totalDiscount,
      pre_order: model.preOrder,
      voucher: model.voucher,
    };
  }
}

export class CartModel {
  totalValue?: number = 0;
  currency?: string = 'SEK';
  items: CartItemModel[] = [];
  totalDiscount: number = 0;
  preOrder: boolean = false;
  voucher: string = '';

  static createFromDTO(dto: CartDTO): CartModel {
    return {
      totalValue: dto.total_value,
      currency: dto.currency,
      items: (dto.items && dto.items.map(i => CartItemModel.createFromDTO(i))) || [],
      totalDiscount: dto.total_discount,
      preOrder: dto.pre_order,
      voucher: dto.voucher,
    };
  }
}

export class CartItemDTO {
  sku: string;
  name: string;
  attributes: string[];
  out_of_stock: boolean;
  dimensions?: DimensionsDTO;
  quantity: number;
  weight: number;
  country_of_origin: string;
  hs_code: string;
  price?: number;
  currency?: string;

  static createFromModel(model: CartItemModel): CartItemDTO {
    return {
      sku: model.sku,
      name: model.name,
      attributes: model.attributes,
      out_of_stock: model.outOfStock,
      dimensions: model.dimensions,
      quantity: model.quantity,
      weight: model.weight,
      country_of_origin: model.countryOfOrigin,
      hs_code: model.hsCode,
      price: model.price,
      currency: model.currency,
    };
  }
}

export class CartItemModel {
  sku: string = '';
  name: string = '';
  attributes: string[] = [];
  outOfStock: boolean = false;
  dimensions?: DimensionsModel = new DimensionsModel();
  quantity: number = 0;
  weight: number = 0;
  countryOfOrigin: string = '';
  hsCode: string = '';
  price?: number;
  currency?: string;

  static createFromDTO(dto: CartItemDTO): CartItemModel {
    return {
      sku: dto.sku,
      name: dto.name,
      attributes: dto.attributes,
      outOfStock: dto.out_of_stock,
      dimensions: dto.dimensions,
      quantity: dto.quantity,
      weight: dto.weight,
      countryOfOrigin: dto.country_of_origin,
      hsCode: dto.hs_code,
      price: dto.price,
      currency: dto.currency,
    };
  }
}

export class ShippingDTO {
  method: string;
  time_slot: TimeSlotDTO;

  static createFromModel(dto: ShippingModel): ShippingDTO {
    return {
      method: dto.method,
      time_slot: dto.timeSlot,
    };
  }
}

export class ShippingModel {
  method: string = '';
  timeSlot: TimeSlotModel;

  static createFromDTO(model: ShippingDTO): ShippingModel {
    return {
      method: model.method,
      timeSlot: model.time_slot,
    };
  }
}

export class TimeSlotDTO {
  id: string;
  expires: string;
  start: string;
  end: string;
}

export class TimeSlotModel {
  id: string = '';
  expires: string = '';
  start: string = '';
  end: string = '';
}

export class ExternalDTO {
  reference_ids: string[] = [];
  references: { name: string; id: string }[] = [];

  static createFromModel(model: ExternalModel): ExternalDTO {
    return {
      reference_ids: model.referenceIds,
      references: model.references,
    };
  }
}

export class ExternalModel {
  referenceIds: string[] = [];
  references: { name: string; id: string }[] = [];

  static createFromDTO(dto: ExternalDTO): ExternalModel {
    return {
      referenceIds: dto.reference_ids,
      references: dto.references,
    };
  }
}

export class NotesDTO {
  description: string;
}

export class NotesModel {
  description: string = '';
}

export class MetadataDTO {
  // Source is the name of the system, which created this order.
  source: string;
  session: SessionDTO;
  master_session_id?: string;

  static createFromModel(o: MetadataModel): MetadataDTO {
    return {
      source: o.source,
      session: SessionDTO.createFromModel(o.session),
    };
  }
}

export class MetadataModel {
  source: string;
  session: SessionModel = new SessionModel();
  masterSessionId?: string;

  static createFromDTO(o: MetadataDTO): MetadataModel {
    return {
      source: o.source,
      session: SessionModel.createFromDTO(o.session),
      masterSessionId: o.master_session_id,
    };
  }
}

export class SessionDTO {
  method: string;
  delivery_type: string;
  custom_shipping_method?: string;
  time_slot: TimeSlotDTO | undefined;
  session_id: string;
  shipping_category_ref: string;
  custom_shipping_locations: CustomShippingLocationDTO[] = [];
  shipping: any;
  pricing: any;

  static createFromModel(o: SessionModel): SessionDTO {
    return {
      method: o.method,
      delivery_type: o.deliveryType,
      custom_shipping_method: o.customShippingMethod,
      time_slot: o.timeSlot,
      session_id: o.sessionId,
      shipping_category_ref: o.shippingCategoryRef,
      custom_shipping_locations:
        o.customShippingLocations == null
          ? []
          : o.customShippingLocations.map(csl => CustomShippingLocationDTO.createFromModel(csl)),
      shipping: o.shipping,
      pricing: o.pricing,
    };
  }
}

export class SessionModel {
  method: string;
  deliveryType: string;
  customShippingMethod?: string;
  timeSlot: TimeSlotModel = new TimeSlotModel();
  sessionId: string;
  shippingCategoryRef: string;
  customShippingLocations: CustomShippingLocationModel[] = [];
  shipping: SessionShippingDTO;
  pricing: SessionPricingDTO;

  static createFromDTO(o: SessionDTO): SessionModel {
    return {
      method: o.method,
      deliveryType: o.delivery_type,
      customShippingMethod: o.custom_shipping_method,
      timeSlot: o.time_slot || new TimeSlotModel(),
      sessionId: o.session_id,
      shippingCategoryRef: o.shipping_category_ref,
      customShippingLocations:
        o.custom_shipping_locations == null
          ? []
          : o.custom_shipping_locations.map(csl => CustomShippingLocationModel.createFromDTO(csl)),
      shipping: o.shipping,
      pricing: o.pricing,
    };
  }
}

export class SessionShippingDTO {
  carrier: string;
  category_name: string;
  external_method_id?: string;
  product: string;
  time_slot: TimeSlotDTO;
}

export class SessionPricingDTO {
  shipping_price?: number;
}

export class CustomShippingLocationDTO {
  shipping_method: string;
  location_ref: string;
  added_at: string;

  static createFromModel(o: CustomShippingLocationModel): CustomShippingLocationDTO {
    return {
      shipping_method: o.shippingMethod,
      location_ref: o.locationRef,
      added_at: o.addedAt,
    };
  }
}

export class CustomShippingLocationModel {
  shippingMethod: string;
  locationRef: string;
  addedAt: string;

  static createFromDTO(o: CustomShippingLocationDTO): CustomShippingLocationModel {
    return {
      shippingMethod: o.shipping_method,
      locationRef: o.location_ref,
      addedAt: o.added_at,
    };
  }
}

export class RouteDTO {
  shipping_legs?: ShippingLegDTO[];

  static createFromModel(model: RouteModel) {
    return {
      shipping_legs: model.shippingLegs
        ? model.shippingLegs.map(shippingLeg => ShippingLegDTO.createFromModel(shippingLeg))
        : undefined,
    };
  }
}

export class RouteModel {
  shippingLegs?: ShippingLegModel[];

  static createFromDTO(dto: RouteDTO) {
    return {
      shippingLegs: dto.shipping_legs
        ? dto.shipping_legs.map(shipping_leg => ShippingLegModel.createFromDTO(shipping_leg))
        : undefined,
    };
  }
}

export class ShippingLegDTO {
  shipping_method: string;
  from: FromModelDTO;

  static createFromModel(model: ShippingLegModel): ShippingLegDTO {
    return {
      shipping_method: model.shippingMethod,
      from: FromModelDTO.createFromModel(model.from),
    };
  }
}

export class ShippingLegModel {
  shippingMethod: string;
  from: FromModel = new FromModel();

  static createFromDTO(o: ShippingLegDTO): ShippingLegModel {
    return {
      shippingMethod: o.shipping_method,
      from: FromModel.createFromDTO(o.from),
    };
  }
}

export class FromModelDTO {
  address: AddressDTO;

  static createFromModel(model: FromModel): FromModelDTO {
    return {
      address: AddressDTO.createFromModel(model.address),
    };
  }
}

export class FromModel {
  address: AddressModel = new AddressModel();

  static createFromDTO(o: FromModelDTO): FromModel {
    return {
      address: o.address ? AddressModel.createFromDTO(o.address) : new AddressModel(),
    };
  }
}

export class TransportOrderListFiltersModel {
  sources: string[];
  createdAtRange: string[];
}

//#region ENUMS
export enum OrderStatusEnum {
  UNKNOWN = 'UNKNOWN',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}
