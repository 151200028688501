import type { TrackingConfigurationStatus } from '@src/models';
import { defaultTheme } from '@src/styles';
import * as React from 'react';

const { dotStatusGreen, dotStatusOrange, dotStatusGrey, dotStatusRed } = defaultTheme.color;

const MAPPING = {
  TRACKING_STATUS_CONFIGURED: [dotStatusGreen, 'Fully configured'],
  TRACKING_STATUS_AVAILABLE: [dotStatusOrange, 'Ready to configure'],
  TRACKING_STATUS_UNAVAILABLE: [dotStatusRed, 'Unavailable'],
  TRACKING_STATUS_UNKNOWN: [dotStatusGrey, 'Unknown'],
};

export const TrackingConfigStatus: React.FC<{
  status: TrackingConfigurationStatus | undefined;
}> = ({ status }) => {
  if (!status) {
    return <> — </>;
  }
  const [dotColor, statusText] = MAPPING[status];

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          width: '6px',
          height: '6px',
          borderRadius: '3px',
          background: dotColor,
          marginRight: '6px',
        }}
      />
      <span>{statusText}</span>
    </div>
  );
};
