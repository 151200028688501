import * as React from 'react';
import { stylesheet } from 'typestyle';
import { Col, Row } from 'antd';

import { Tag } from '@src/controls';
import { defaultTheme } from '@src/styles';

interface OwnProps {
  tag: string;
  title: string;
  extra: React.ReactNode;
}

export const HeaderWithTag: React.FC<OwnProps> = ({ tag, title, extra }) => {
  return (
    <Row justify="space-between">
      <Col>
        <Tag color={defaultTheme.color.primary} nonClickable={true}>
          {tag}
        </Tag>
        <h2 className={styles.header}>{title}</h2>
      </Col>
      <Col>{extra}</Col>
    </Row>
  );
};

const styles = stylesheet({
  header: {
    marginTop: '10px',
  },
});
