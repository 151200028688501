import { combineEpics, Epic } from 'redux-observable';
import { ignoreElements, tap } from 'rxjs/operators';
import { getType } from 'typesafe-actions';

import { RootAction, RootState, Services } from '../../modules';
import { authActions } from '../../modules/auth';
import { merchantsActions, MerchantsSelectors } from '../../modules/merchants';
import { SitesSelectors } from '../../modules/sites';
import { transportOrdersActionCreators } from '../../modules/transport-orders';
import { userprofileActions } from '../../modules/userprofile';
import { addressBookActions } from '../address-book';
import { isAuthUserSuperUser } from '../auth/auth-selectors';
import { configActions } from '../config';
import { shipmentsActions } from '../shipments';
import { getSelectedSiteName } from '../sites/sites-selectors';
import { eventTrackingActions } from './';

const shipmentsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { eventTrackingService }
) =>
  action$.pipe(
    tap(action => {
      switch (action.type) {
        case getType(eventTrackingActions.applyShipmentsFiltersClick):
          return eventTrackingService.logEvent(
            {
              name: 'Filter shipments',
              category: 'Search',
              site: SitesSelectors.getSelectedSiteName(state$.value),
              description: 'Click on apply filter button',
            },
            { filters: action.payload }
          );
        case getType(eventTrackingActions.searchShipmentButtonClick):
          return eventTrackingService.logEvent({
            name: 'Search shipment',
            category: 'Search',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Click on search button or press enter on input',
          });
        case getType(eventTrackingActions.viewShipmentPage):
          return eventTrackingService.logEvent({
            name: 'Open shipment',
            category: 'Search',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Page view of shipment page (already created, no matter the status)',
          });
        case getType(eventTrackingActions.downloadShipmentLabel):
          return eventTrackingService.logEvent({
            name: 'Download label',
            category: 'Search',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Click on download generated label',
          });
        case getType(eventTrackingActions.viewShipmentCreatePage):
          return eventTrackingService.logEvent({
            name: 'Open create shipment page',
            category: 'Book & Edit',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Page view of shipment create',
          });
        case getType(eventTrackingActions.selectShipmentTemplate):
          return eventTrackingService.logEvent({
            name: 'Choose template',
            category: 'Book & Edit',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Select template from the dropdown list in shipment create',
          });
        case getType(eventTrackingActions.selectTransportOrder):
          return eventTrackingService.logEvent({
            name: 'Select transport order',
            category: 'Book & Edit',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Select TO from the list on create shipment page',
          });
        case getType(eventTrackingActions.editShipmentButtonClick):
          return eventTrackingService.logEvent({
            name: 'Open edit shipment',
            category: 'Book & Edit',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Click on edit shipment button',
          });
        case getType(shipmentsActions.createShipmentRequest):
          return eventTrackingService.logEvent(
            {
              name: 'Create shipment',
              category: 'Book & Edit',
              site: SitesSelectors.getSelectedSiteName(state$.value),
              description: 'Create shipment request',
            },
            {
              consigmentType: action.payload.shipment.type,
              shippingMethod: action.payload.shipment.shippingMethod,
              addons: action.payload.shipment.addons,
              numberOfAddons: action.payload.shipment.addons.length,
              numberOfItems: action.payload.shipment.contents.goods.length,
            }
          );
        case getType(shipmentsActions.bookParcelsRequest):
          return eventTrackingService.logEvent({
            name: 'Book shipment',
            category: 'Book & Edit',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Book shipment request',
          });
        case getType(shipmentsActions.bookPickupDeliveriesRequest):
          return eventTrackingService.logEvent({
            name: 'Schedule shipment pickup',
            category: 'Book & Edit',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Schedule shipment pickup request',
          });
        case getType(shipmentsActions.editShipmentRequest):
          return eventTrackingService.logEvent({
            name: 'Save edit shipment',
            category: 'Book & Edit',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Save edited shipment request',
          });
        case getType(eventTrackingActions.cancelShipmentClick):
          return eventTrackingService.logEvent({
            name: 'Cancel shipment',
            category: 'Book & Edit',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Cancel shipment click',
          });
        case getType(shipmentsActions.cancelAndDuplicateShipment):
          return eventTrackingService.logEvent({
            name: 'Cancel & duplicate shipment',
            category: 'Book & Edit',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Cancel and duplicate shipment',
          });
        case getType(shipmentsActions.createShipmentError):
          return eventTrackingService.logEvent(
            {
              name: 'Create shipment error',
              category: 'Book & Edit',
              site: SitesSelectors.getSelectedSiteName(state$.value),
              description: 'Create shipment error returned from the backend',
            },
            { error: action.payload }
          );
        case getType(shipmentsActions.bookParcelError):
          return eventTrackingService.logEvent(
            {
              name: 'Book shipment error',
              category: 'Book & Edit',
              site: SitesSelectors.getSelectedSiteName(state$.value),
              description: 'Book shipment error returned from the backend',
            },
            { error: action.payload }
          );
        case getType(shipmentsActions.bookPickupDeliveriesError):
          return eventTrackingService.logEvent(
            {
              name: 'Schedule pickup error',
              category: 'Book & Edit',
              site: SitesSelectors.getSelectedSiteName(state$.value),
              description: 'Schedule pickup error returned from the backend',
            },
            { error: action.payload }
          );
        case getType(shipmentsActions.createAndBookShipmentsRequest):
          return eventTrackingService.logEvent(
            {
              name: 'Create and book multiple',
              category: 'Book & Edit',
              site: SitesSelectors.getSelectedSiteName(state$.value),
              description: 'Create and book multiple shipments request',
            },
            { error: action.payload }
          );
        case getType(eventTrackingActions.shipmentParcelDetailsClick):
          return eventTrackingService.logEvent({
            name: 'Display parcel details',
            category: 'Search',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Click on the see details button',
          });
        default:
          return;
      }
    }),
    ignoreElements()
  );

const addressBookEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { eventTrackingService }
) =>
  action$.pipe(
    tap(action => {
      switch (action.type) {
        case getType(eventTrackingActions.viewAddressBookPage):
          return eventTrackingService.logEvent({
            name: 'Open addressbook',
            category: 'Addressbook',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Address book page view',
          });
        case getType(addressBookActions.createContactRequest):
          return eventTrackingService.logEvent({
            name: 'Create address',
            category: 'Addressbook',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Create address request',
          });
        case getType(addressBookActions.createContactsWithCSVSuccess):
          return eventTrackingService.logEvent({
            name: 'Success CSV import',
            category: 'Addressbook',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Success feedback shown to user',
          });
        case getType(addressBookActions.createContactsWithCSVError):
          return eventTrackingService.logEvent({
            name: 'Error CSV import',
            category: 'Addressbook',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Error feedback shown to user',
          });
        case getType(addressBookActions.createContactFromModalRequest):
          return eventTrackingService.logEvent({
            name: 'Create address on Shipment Page',
            category: 'Addressbook',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Create new address from modal on shipment page request',
          });
        case getType(addressBookActions.createTempContact):
          return eventTrackingService.logEvent({
            name: 'Create temporary address on Shipment Page',
            category: 'Addressbook',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description:
              'Create new address from modal on shipment page, but do not save it on the backend',
          });
        case getType(addressBookActions.updateContactFromModalRequest):
          return eventTrackingService.logEvent({
            name: 'Edit address on Shipment Page',
            category: 'Addressbook',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Edit address in modal request',
          });
        case getType(addressBookActions.updateContactRequest):
          return eventTrackingService.logEvent({
            name: 'Edit address',
            category: 'Addressbook',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Edit address in addressbook',
          });
        case getType(eventTrackingActions.searchContacts):
          return eventTrackingService.logEvent({
            name: 'Search contacts',
            category: 'Addressbook',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Search contacts in addressbook',
          });
        case getType(eventTrackingActions.viewAddressCreatePage):
          return eventTrackingService.logEvent({
            name: 'Open create address page',
            category: 'Addressbook',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Open create address page',
          });
        case getType(eventTrackingActions.toggleAdditionalContactInfo):
          return eventTrackingService.logEvent({
            name: 'Toggle additional address information',
            category: 'Addressbook',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Toggle additional address information',
          });
        case getType(addressBookActions.createContactsWithCSVRequest):
          return eventTrackingService.logEvent({
            name: 'Try to import address CSV',
            category: 'Addressbook',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Import address via csv request',
          });
      }
    }),
    ignoreElements()
  );

const authEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { eventTrackingService }
) =>
  action$.pipe(
    tap(action => {
      switch (action.type) {
        case getType(authActions.logout):
          return eventTrackingService.logEvent({
            name: 'Log out',
            category: 'Other',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Click on logout button',
          });
        case getType(authActions.fetchAuthUserSuccess):
          return eventTrackingService.setEventTrackingUser(action.payload);
        default:
          return;
      }
    }),
    ignoreElements()
  );

const merchantsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { eventTrackingService }
) =>
  action$.pipe(
    tap(action => {
      switch (action.type) {
        case getType(merchantsActions.changeActiveMerchantAndSite):
          return eventTrackingService.logEvent({
            name: 'Select different site/merchant',
            category: 'Other',
            site: getSelectedSiteName(state$.value),
            description: 'Click on save selection button',
          });
        case getType(merchantsActions.listMerchantsSuccess):
          if (isAuthUserSuperUser(state$.value)) {
            return eventTrackingService.setMerchantName('Merchant not specified');
          }
          return eventTrackingService.setMerchantName(
            (action.payload[0] && action.payload[0].name) || 'Merchant not available'
          );
        default:
          return;
      }
    }),
    ignoreElements()
  );

const transportOrderEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { eventTrackingService }
) =>
  action$.pipe(
    tap(action => {
      switch (action.type) {
        case getType(eventTrackingActions.viewTOListPage):
          return eventTrackingService.logEvent({
            name: 'List transport orders',
            category: 'Transport orders',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Page view',
          });
        case getType(eventTrackingActions.viewTOEditPage):
          return eventTrackingService.logEvent({
            name: 'Open transport order',
            category: 'Transport orders',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Page view',
          });
        case getType(transportOrdersActionCreators.updateTransportOrderRequest):
          return eventTrackingService.logEvent({
            name: 'Edit transport order',
            category: 'Transport orders',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Update transport order request',
          });
        case getType(transportOrdersActionCreators.createTransportOrderRequest):
          return eventTrackingService.logEvent({
            name: 'Create transport order',
            category: 'Transport orders',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Create transport order request',
          });
        default:
          return;
      }
    }),
    ignoreElements()
  );

const userprofileEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { eventTrackingService }
) =>
  action$.pipe(
    tap(action => {
      switch (action.type) {
        case getType(userprofileActions.createTemplateRequest):
          return eventTrackingService.logEvent({
            name: 'Create template',
            category: 'Templates',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Create template request',
          });
        case getType(eventTrackingActions.templateListPageView):
          return eventTrackingService.logEvent({
            name: 'List templates',
            category: 'Templates',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'List templates page view',
          });
        case getType(eventTrackingActions.viewTemplateCreatePage):
          return eventTrackingService.logEvent({
            name: 'Open templates create page',
            category: 'Templates',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Open templates create page',
          });
        case getType(eventTrackingActions.viewTemplatePage):
          return eventTrackingService.logEvent({
            name: 'Open template',
            category: 'Templates',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Page view of created template',
          });
        case getType(userprofileActions.updateTemplateRequest):
          return eventTrackingService.logEvent({
            name: 'Edit template',
            category: 'Templates',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            description: 'Save edited template request',
          });
      }
    }),
    ignoreElements()
  );

const ConfigEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { eventTrackingService }
) =>
  action$.pipe(
    tap(action => {
      switch (action.type) {
        case getType(configActions.createRegionRequest):
          return eventTrackingService.logEvent({
            name: 'Create region',
            category: 'Config',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            merchant: MerchantsSelectors.getSelectedMerchantName(state$.value),
            description: 'Create new region request',
          });
        case getType(configActions.updateRegionDetailRequest):
          return eventTrackingService.logEvent({
            name: 'Update region',
            category: 'Config',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            merchant: MerchantsSelectors.getSelectedMerchantName(state$.value),
            description: 'Update region details request',
          });
        case getType(configActions.createDraftRequest):
          return eventTrackingService.logEvent({
            name: 'Create draft',
            category: 'Config',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            merchant: MerchantsSelectors.getSelectedMerchantName(state$.value),
            description: 'Create draft request',
          });
        case getType(configActions.deleteDraftRequest):
          return eventTrackingService.logEvent({
            name: 'Delete draft',
            category: 'Config',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            merchant: MerchantsSelectors.getSelectedMerchantName(state$.value),
            description: 'Delete draft request',
          });
        case getType(configActions.promoteDraftRequest):
          return eventTrackingService.logEvent({
            name: 'Promote draft',
            category: 'Config',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            merchant: MerchantsSelectors.getSelectedMerchantName(state$.value),
            description: 'Promote draft request',
          });
        case getType(configActions.createWarehouseRequest):
          return eventTrackingService.logEvent({
            name: 'Create warehouse',
            category: 'Config',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            merchant: MerchantsSelectors.getSelectedMerchantName(state$.value),
            description: 'Create new warehouse request',
          });
        case getType(configActions.updateWarehouseDetailsRequest):
          return eventTrackingService.logEvent({
            name: 'Update warehouse',
            category: 'Config',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            merchant: MerchantsSelectors.getSelectedMerchantName(state$.value),
            description: 'Update warehouse request',
          });
        case getType(configActions.updateWidgetConfigurationRequest):
          return eventTrackingService.logEvent({
            name: 'Update widget configuration',
            category: 'Config',
            site: SitesSelectors.getSelectedSiteName(state$.value),
            merchant: MerchantsSelectors.getSelectedMerchantName(state$.value),
            description: 'Update widget configuration request',
          });
        default:
          return;
      }
    }),
    ignoreElements()
  );

export const eventTrackingEpics = combineEpics(
  authEpic,
  addressBookEpic,
  merchantsEpic,
  shipmentsEpic,
  transportOrderEpic,
  userprofileEpic,
  ConfigEpic
);
