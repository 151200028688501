import * as React from 'react';
import { connect } from 'react-redux';

import { ComponentReturningChildren } from '@src/components';
import { Select, TabPane, Tabs } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, inputStyle, wideLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { ConfigShippingCategoryModel, LocalizationModel } from '@src/models';
import { RootState } from '@src/modules';
import { configActions } from '@src/modules/config';
import { getAvailableLocalesOptions } from '@src/modules/dictionaries/selectors';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { Translations, TranslationsFormValues } from './components';

const LabelWrapper = withFormItem(ComponentReturningChildren, wideLayout);

const mapStateToProps = (state: RootState) => ({
  availableLocalesOptions: getAvailableLocalesOptions(state),
});

const mapDispatchToProps = () => ({
  updateTranslations: dispatchOnDraftSiteId(configActions.updateCategoryTranslationsRequest),
  deleteTranslations: dispatchOnDraftSiteId(configActions.deleteCategoryTranslationsRequest),
});

interface OwnProps {
  category: ConfigShippingCategoryModel;
}

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & OwnProps;

const emptyLocale: LocalizationModel = {
  name: '',
  customText: '',
  customInfoText: '',
};

const Component: React.FunctionComponent<Props> = ({
  category,
  updateTranslations,
  deleteTranslations,
  availableLocalesOptions,
}) => {
  const [locales, updateLocales] = React.useState<{ [key: string]: LocalizationModel }>(
    category.localization ? category.localization : {}
  );

  const handleTranslationChange = (values: TranslationsFormValues) => {
    updateTranslations({
      model: {
        categoryId: category.id,
        locale: values.locale,
        name: values.name,
        customText: values.customText,
        customInfoText: values.customInfoText,
      },
    });
  };

  React.useEffect(() => {
    // in case optimistic update fails
    if (category.localization) {
      updateLocales(category.localization);
    }
  }, [category.localization]);

  return (
    <AutoSaveForm>
      <LabelWrapper
        label="Translations"
        labelTooltip={<FormattedMessage id="CATEGORY_TRANSLATIONS_TOOLTIP" />}
      >
        <Select
          placeholder="Select locales"
          mode="multiple"
          options={availableLocalesOptions}
          value={Object.keys(locales)}
          onChange={(val: string[]) => {
            const newLocales = val.reduce((allLocales, locale) => {
              return {
                ...allLocales,
                [locale]:
                  category.localization && category.localization[locale]
                    ? category.localization[locale]
                    : emptyLocale,
              };
            }, {});
            updateLocales(newLocales);
          }}
          onDeselect={(value: string) =>
            deleteTranslations({ model: { categoryId: category.id, locale: value } })
          }
          className={inputStyle}
        />
        <Tabs defaultActiveKey={Object.keys(locales)[0]}>
          {Object.entries(locales).map(([locale, value]: [string, LocalizationModel]) => (
            <TabPane tab={locale} key={locale}>
              <Translations
                translation={value}
                onTranslationChange={handleTranslationChange}
                locale={locale}
              />
            </TabPane>
          ))}
        </Tabs>
      </LabelWrapper>
    </AutoSaveForm>
  );
};

export const TranslationsContainer = connect(mapStateToProps, mapDispatchToProps)(Component);
