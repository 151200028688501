import { AuditOutlined } from '@ant-design/icons';
import { Badge, Button, Popover, Progress } from 'antd';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { reportsSelectors } from '@src/modules/reports';
import { stylesheet } from 'typestyle';

export const ShipmentReports: React.FunctionComponent = () => {
  const status = useSelector(reportsSelectors.getReportBadgeStatus);
  const reportURI = useSelector(reportsSelectors.getUriForCurrentUser);

  const getPopoverContent = () => {
    switch (status) {
      case 'processing':
        return (
          <div className={styles.popoverWrapper}>
            <div>
              <p>Report is being created</p>
              <Progress percent={100} size="small" status="active" showInfo={false} />
            </div>
          </div>
        );
      case 'error':
        return <div className={styles.popoverWrapper}>Report couldn’t have been created</div>;
      case 'success':
        return (
          <div className={styles.popoverWrapper}>
            Report has been created
            <Button type="link" href={reportURI} className={styles.button}>
              Download
            </Button>
          </div>
        );
      case null:
      default:
        return <div className={styles.popoverWrapper}>No reports available at the moment</div>;
    }
  };
  return (
    <div className={styles.root}>
      <Popover
        trigger="click"
        placement="bottomRight"
        arrowPointAtCenter={true}
        getPopupContainer={e => e!.parentElement!}
        overlayClassName={styles.popoverOverlay}
        content={getPopoverContent()}
      >
        <Badge className={styles.badge} status={status}>
          <AuditOutlined />
        </Badge>
      </Popover>
    </div>
  );
};

const styles = stylesheet({
  root: {
    cursor: 'pointer',
    position: 'relative',
  },
  popoverOverlay: {
    width: '300px',
  },
  badge: {
    $nest: {
      '& .anticon': {
        fontSize: '23px',
        color: '#fff',
      },
      '& .ant-badge-dot': {
        position: 'absolute',
      },
    },
  },
  popoverWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    $nest: {
      '& p': {
        margin: 0,
      },
    },
  },
  button: {
    paddingTop: 0,
    lineHeight: '32px',
  },
});
