import { List } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { Link } from '@src/components/link';
import { LABELS } from '@src/dictionaries';
import { mapping } from '@src/dictionaries';
import { TriggerListItemModel } from '@src/models';

import themeDefault from '@src/styles/theme.default';
import { humanize } from '@src/utils/string';

interface Props {
  triggers: TriggerListItemModel[];
  deleteTrigger: (id: string) => void;
  isFetching: boolean;
}
interface ListItem {
  title: JSX.Element;
  description: JSX.Element;
  remove: JSX.Element;
}

export const TriggersList: React.FunctionComponent<Props> = ({
  triggers,
  deleteTrigger,
  isFetching,
}) => {
  const data: ListItem[] = triggers.map(trigger => ({
    title: (
      <div className={styles.notificationTitle}>
        {mapping.mapNotificationTypeToDescription(trigger.type)}
      </div>
    ),
    description: (
      <div>
        <div>
          Delivery&nbsp;
          <Link route={{ name: 'SHIPMENT_EDIT', shipmentId: trigger.shipmentId }}>
            {trigger.trackingNumber}
          </Link>
        </div>
        {trigger.dueDate && (
          <div> Due date: {moment(trigger.dueDate).format('dddd, DD/MM/YYYY hh:mm A')}</div>
        )}
        {trigger.expectedStatus && <div> Expected status: {humanize(trigger.expectedStatus)}</div>}
        {trigger.meta && trigger.meta.customerName && (
          <div>Customer: {trigger.meta.customerName}</div>
        )}
        <div className={styles.createdAt}>
          Created at: {moment(trigger.createdAt).format('dddd, DD/MM/YYYY hh:mm A')}
        </div>
      </div>
    ),
    remove: (
      <button
        key={trigger.id}
        className={styles.removeButton}
        onClick={() => deleteTrigger(trigger.id)}
      >
        {LABELS.REMOVE}
      </button>
    ),
  }));
  return (
    <List
      dataSource={data}
      className={styles.listWrapper}
      loading={isFetching}
      renderItem={(item: ListItem) => (
        <List.Item className={styles.notificationWrapper} actions={[item.remove]}>
          <List.Item.Meta title={item.title} description={item.description} />
        </List.Item>
      )}
    />
  );
};

const styles = {
  listWrapper: tss({
    maxHeight: '500px',
    overflow: 'auto',
  }),
  notificationTitle: tss({
    fontWeight: 600,
    fontSize: '16px',
  }),
  notificationWrapper: tss({
    borderBottom: '1px solid #e2e2e2',
    padding: '10px',
    position: 'relative',
    textAlign: 'left',
  }),
  removeButton: tss({
    border: 'none',
    fontSize: '14px',
    cursor: 'pointer',
    color: themeDefault.color.primary,
    paddingBottom: '4px',
    $nest: {
      '&:focus': {
        outline: 0,
      },
    },
  }),
  createdAt: tss({
    color: '#9c9c9c',
    fontSize: '13px',
  }),
};
