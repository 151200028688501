import * as React from 'react';
import { Button } from 'antd';
import { useQueryClient, useMutation, useIsFetching } from 'react-query';

import { ModalPopover } from '@src/controls';
import { services } from '@src/services';
import { FormattedMessage } from '@src/i18n';
import { ExperimentModel } from '@src/models';

interface OwnProps {
  siteId: string;
  experimentId: string;
  experiment: ExperimentModel;
  status?: string;
}

export const ActionButton: React.FC<OwnProps> = ({ siteId, experimentId, experiment, status }) => {
  const queryClient = useQueryClient();

  const invalidateQuery = () => queryClient.invalidateQueries(['experiment', siteId, experimentId]);

  const isFetching = useIsFetching(['experiment']) > 0;

  const startMutation = useMutation(
    () => services.experimentService.startExperiment(siteId, experimentId),
    {
      onSuccess: invalidateQuery,
      onError: (error: Error) =>
        services.notificationService.error(error.message, 'Failed to start experiment'),
    }
  );

  const endMutation = useMutation(
    () => services.experimentService.endExperiment(siteId, experimentId),
    {
      onSuccess: invalidateQuery,
      onError: (error: Error) =>
        services.notificationService.error(error.message, 'Failed to end experiment'),
    }
  );

  const isLoading = isFetching || startMutation.isLoading || endMutation.isLoading;

  if (status === 'CREATED') {
    const allCountries = services.dictionariesService.getAllCountries();

    const countryName =
      allCountries.find(country => experiment.country === country.code)?.name || experiment.country;

    if (experiment.externalTrafficBalancingEnabled) {
      return (
        <ModalPopover
          title="Start experiment"
          okText="Start"
          content={
            <span>
              You are about to start an experiment with external traffic
              balancing, please confirm starting this experiment.
            </span>
          }
          onOk={() => startMutation.mutate()}
        >
          <Button type="primary" disabled={isLoading}>
            <FormattedMessage id="START" />
          </Button>
        </ModalPopover>
      );
    }

    if (!experiment.traffic) {
      return (
        <ModalPopover
          title="Start experiment"
          content={
            <span>
              You cannot start an experiment running on 0% of checkout traffic in {countryName},
              please change the traffic value.
            </span>
          }
          onOk={() => {}}
        >
          <Button type="primary" disabled={isLoading}>
            <FormattedMessage id="START" />
          </Button>
        </ModalPopover>
      );
    }

    return (
      <ModalPopover
        title="Start experiment"
        okText="Start"
        content={
          <span>
            You are about to start an experiment running on {experiment.traffic / 10}% of checkout
            traffic in {countryName}, please confirm starting this experiment.
          </span>
        }
        onOk={() => startMutation.mutate()}
      >
        <Button type="primary" disabled={isLoading}>
          <FormattedMessage id="START" />
        </Button>
      </ModalPopover>
    );
  }

  if (status === 'RUNNING') {
    return (
      <ModalPopover
        title="End experiment"
        okText="End"
        content={
          <span>
            Are you sure you want to end experiment and get final results? The experiment should run
            for at least 14 days.
          </span>
        }
        onOk={() => endMutation.mutate()}
      >
        <Button type="primary" disabled={isLoading}>
          <FormattedMessage id="END" />
        </Button>
      </ModalPopover>
    );
  }

  return null;
};
