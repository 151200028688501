import * as Cookies from 'js-cookie';

export const setMerchantSelected = (id: string) => Cookies.set('merchantSelected', id);
export const getMerchantSelected = () => Cookies.get('merchantSelected');

export const setSiteSelected = (id: string) => Cookies.set('siteSelected', id);
export const getSiteSelected = () => Cookies.get('siteSelected');

export const setSessionActive = () => Cookies.set('sessionActive', 'true');
export const getSessionActive = () => Cookies.get('sessionActive');
