import posthog from 'posthog-js';
import { getEnvironmentalVariables } from './env-service';

const env = getEnvironmentalVariables();

interface UserProperties {
  email: string;
  siteName: string;
  merchantName: string;
}

export const initPosthog = (
  { email, ...eventProperties }: UserProperties,
  callback: () => void
) => {
  switch (env.MAD_DEPLOY_TARGET) {
    case 'production':
    case 'stage':
      posthog.init(env.MAD_POSTHOG_KEY, {
        // Be careful when editing this option.
        // There are billing and privacy implications:
        // - send only events that provide meaningful insights
        // - avoid sending events that contain sensitive information
        autocapture: false,

        // the following options are enabled by default, but leaving them here for clarity
        capture_pageleave: true,
        capture_pageview: true,
        rageclick: true,

        api_host: 'https://eu.posthog.com',
        loaded: () => {
          posthog.register(eventProperties);
          posthog.identify(email);
          callback();
        },
      });
      break;
    default:
      return;
  }
};

export const registerProperties = (properties: { siteName: string; merchantName: string }) => {
  posthog.register(properties);
};

export const captureEvent = (type: 'Draft Created') => {
  posthog.capture(type);
};
