import * as React from 'react';
import { stylesheet } from 'typestyle';

import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { WarehouseContainer } from './warehouse-container';

interface OwnProps {
  warehouseId: string;
}

export const WarehouseDetailsContainer: React.FC<OwnProps> = ({ warehouseId }) => {
  return (
    <>
      <StatusBarContainer />
      <div className={styles.warehouseConainer}>
        <WarehouseContainer warehouseId={warehouseId} showLinkedRegions />
      </div>
    </>
  );
};

const styles = stylesheet({
  warehouseConainer: {
    padding: '24px',
  },
});
