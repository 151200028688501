import { useMutation } from 'react-query';

import { configActions } from '@src/modules/config';
import { getOrCreateDraft } from '@src/utils/conditional-dispatchers';
import { useDispatch } from '@src/utils/hooks';

/**
 * useMutationOnDraft is a wrapper around useMutation that makes sure any mutation function passed to it is performed on draft site
 * @param mutateFn same meaning as in underlying useMutation. Usage and signature slighly different as it accepts both payload/data and extra argument effectiveSideId
 * @param options same meaning in underlying useMutation. onSuccess accepts response/data from mutation and extra argument effectiveSiteId
 * @returns mutation object returned by the underlying useMutation hook
 */
export const useMutationOnDraft = <Payload extends unknown, Response extends unknown>(
  mutateFn: (effectiveSiteId: string, payload: Payload) => Promise<Response>,
  options?: {
    onSuccess?: ({ data, effectiveSiteId }: { data: Response; effectiveSiteId: string }) => void;
    onError?: () => void;
  }
) => {
  const dispatch = useDispatch();
  const { onSuccess, onError } = options || {};

  const mutation = useMutation(
    async (payload: Payload) => {
      const effectiveSiteId = await getOrCreateDraft();
      dispatch(configActions.genericConfigModificationRequest());
      const data = await mutateFn(effectiveSiteId, payload);
      return { data, effectiveSiteId };
    },
    {
      onSuccess: data => {
        dispatch(configActions.genericConfigModificationSuccess());
        if (onSuccess) {
          onSuccess(data);
        }
      },
      onError: () => {
        dispatch(configActions.genericConfigModificationError());
        if (onError) {
          onError();
        }
      },
    }
  );

  return mutation;
};

/**
 * useOptimisticMutationOnDraft have the same functionality as useMutationOnDraft, but do it in optimistic way
 * @param mutateFn same meaning as in underlying useMutation. Usage and signature slighly different as it accepts both payload/data and extra argument effectiveSideId
 * @param options same meaning in underlying useMutation. onSuccess accepts response/data from mutation and extra argument effectiveSiteId
 * @returns mutation object returned by the underlying useMutation hook
 */
export const useOptimisticMutationOnDraft = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutateFn: (effectiveSiteId: string, payload: TVariables) => Promise<TData>,
  options: {
    onMutate: (effectiveSiteId: string, payload: TVariables) => Promise<TContext>;
    onSuccess?: (data: TData) => void;
    onError: (error: TError, payload: TVariables, context: TContext) => void;
    onSettled: () => void;
  }
) => {
  const dispatch = useDispatch();
  const { onMutate, onSuccess, onError, onSettled } = options;

  return useMutation(
    async (payload: TVariables) => {
      dispatch(configActions.genericConfigModificationRequest());
      const effectiveSiteId = await getOrCreateDraft();
      return mutateFn(effectiveSiteId, payload);
    },

    {
      onMutate: async (payload: TVariables) => {
        const effectiveSiteId = await getOrCreateDraft();
        return onMutate(effectiveSiteId, payload);
      },

      onSuccess: responseData => {
        dispatch(configActions.genericConfigModificationSuccess());
        if (onSuccess) {
          onSuccess(responseData);
        }
      },

      onError: (error: TError, variables: TVariables, context: TContext) => {
        dispatch(configActions.genericConfigModificationError());
        onError(error, variables, context);
      },

      onSettled,
    }
  );
};
