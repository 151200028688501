import { useDispatch, useSelector } from 'react-redux';
import { configActions, configSelectors } from '@src/modules/config';
import { RootAction } from '@src/modules/root-action';
import { SitesSelectors } from '@src/modules/sites';
import { store } from '@src/store';
import { services } from '@src/services';
import { captureEvent } from '@src/services/posthog-service';

export function useCreateDraft() {
  const dispatch = useDispatch();

  const masterSiteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);
  const draftSiteId = useSelector(SitesSelectors.getDraftSiteIdOrEmpty);
  const variantSiteId = useSelector(configSelectors.getVariantSiteIdOrEmpty);

  const currentSiteId = variantSiteId || draftSiteId || masterSiteId;

  async function createDraft() {
    if (currentSiteId !== masterSiteId) {
      return currentSiteId;
    }

    const newDraft = await services.configDraftService.createDraft(masterSiteId);

    dispatch(configActions.createDraftSuccess(newDraft));

    return newDraft.siteId;
  }

  return createDraft;
}

export function dispatchOnDraftSiteId<B extends { siteId: string }>(
  callback: (argument: B) => RootAction
) {
  return (properties: Pick<B, Exclude<keyof B, 'siteId'>>, storeImplementation = store) => {
    const draftSiteId = SitesSelectors.getDraftSiteIdOrEmpty(storeImplementation.getState());
    const variantSiteId = configSelectors.getVariantSiteIdOrEmpty(storeImplementation.getState());

    if (variantSiteId) {
      storeImplementation.dispatch(callback({ ...properties, siteId: variantSiteId } as B));
    } else if (draftSiteId) {
      storeImplementation.dispatch(callback({ ...properties, siteId: draftSiteId } as B));
    } else {
      storeImplementation.dispatch(
        configActions.createDraftRequest({
          onComplete: newDraftSiteId => {
            captureEvent('Draft Created');
            return callback({ ...properties, siteId: newDraftSiteId } as B);
          },
        })
      );
    }
  };
}

/** getOrCreateDraft allows react-query requests to use existing create draft architecture */
export const getOrCreateDraft = (): Promise<string> =>
  new Promise(resolve => {
    const draftSiteId = SitesSelectors.getDraftSiteIdOrEmpty(store.getState());
    const variantSiteId = configSelectors.getVariantSiteIdOrEmpty(store.getState());

    if (variantSiteId) {
      resolve(variantSiteId);
    } else if (draftSiteId) {
      resolve(draftSiteId);
    } else {
      store.dispatch(
        configActions.createDraftRequest({
          onComplete: newDraftSiteId => {
            captureEvent('Draft Created');
            resolve(newDraftSiteId);
            return { type: 'NOOP' }; // hack to satisfy onComplete type signature and plug it in existing redux/epic/dispatch system
          },
        })
      );
    }
  });
