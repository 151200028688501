import * as React from 'react';
import { stylesheet } from 'typestyle';

import { Tag } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { configSelectors } from '@src/modules/config';
import { defaultTheme } from '@src/styles';
import { useSelector } from '@src/utils/hooks';
import { DeliveryAddonDetailsEdit } from './delivery-addon-details-edit';
import { DeliveryAddonTranslationsEdit } from './delivery-addon-translations-edit';

interface Props {
  addonId: string;
  categoryId: string;
  allExternalAddonIds: string[];
}

export const DeliveryAddonEdit: React.FC<Props> = ({
  categoryId,
  addonId,
  allExternalAddonIds,
}) => {
  const deliveryAddon = useSelector(state =>
    configSelectors
      .getCategoryById(state, categoryId)
      ?.deliveryAddons?.find(addon => addon.id === addonId)
  );
  if (!deliveryAddon) {
    return null;
  }
  return (
    <div>
      <Tag color={defaultTheme.color.primary} nonClickable={true}>
        <FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.DETAILS_TITLE" />
      </Tag>
      <h2 className={styles.header}>{deliveryAddon.name}</h2>
      <DeliveryAddonDetailsEdit
        addon={deliveryAddon}
        categoryId={categoryId}
        allExternalAddonIds={allExternalAddonIds}
      />
      <DeliveryAddonTranslationsEdit addon={deliveryAddon} categoryId={categoryId} />
    </div>
  );
};

const styles = stylesheet({
  header: {
    marginTop: '10px',
  },
});
