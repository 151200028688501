import { ShoppingCartOutlined } from '@ant-design/icons';
import { Collapse, notification } from 'antd';
import { Field, FieldProps, Formik } from 'formik';
import { isNotEmpty } from 'ramda-adjunct';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { stylesheet } from 'typestyle';

import { LinkStyleButton, ListFixedHeader } from '@src/components';
import { Spinner, Switch } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { PersistEnhancer, widerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { SIWCartModel, SIWSearchAddressModel, SIWSessionCreateModel } from '@src/models';
import { routerActions } from '@src/modules/router';
import { configSelectors } from '@src/modules/config';
import { SitesSelectors } from '@src/modules/sites';
import { siwActions, siwSelectors } from '@src/modules/siw';
import { services } from '@src/services';
import { parsePriceString } from '../regions/carrier-products/helpers';
import { supportedRegionInCountry } from '@src/utils/address';
import {
  Additional,
  Card,
  CartItems,
  DeliveryAddress,
  General,
  SearchAddress,
  WidgetTestClient,
} from './components';
import { getInitalValues, getValidation, WidgetFormValues } from './helpers';
import { WidgetConfigurationV4 } from './widget-configuration-v4';
import { useFlags } from '@src/utils/feature-flags';

const { MAD_COMPLETE_SESSION_ENABLED } = services.envService.getEnvironmentalVariables();

const SwitchField = withFormItem(Switch, widerLayout);

type Props = {
  doNotUseDraft?: boolean;
  showSwitch?: boolean;
};

export const CheckoutWidgetConfiguration: React.FunctionComponent<Props> = ({ doNotUseDraft }) => {
  const session = useSelector(siwSelectors.getSession);
  const htmlSnippet = useSelector(siwSelectors.getHtmlSnippet);
  const isFetching = useSelector(siwSelectors.isFetching);
  const variantSiteId = useSelector(configSelectors.getVariantSiteIdOrEmpty);
  const draftSiteId = useSelector(SitesSelectors.getDraftSiteIdOrEmpty);
  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);
  const isFetchingSites = useSelector(SitesSelectors.getIsFetchingSites);
  const widgetReady = useSelector(siwSelectors.widgetReady);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [isClosingSession, setIsClosingSession] = React.useState(false);
  const sessionCompleteEnabled = MAD_COMPLETE_SESSION_ENABLED === 'true';

  const createOrUpdateWidgetSession = (values: WidgetFormValues) => {
    let requestSiteId = variantSiteId;
    if (!requestSiteId) {
      requestSiteId = doNotUseDraft || !values.useDraft ? siteId : draftSiteId!;
    }

    const cart: SIWCartModel = {
      totalValue: parsePriceString(values.totalValue),
      currency: values.purchaseCurrency,
      items: values.items.map(item => {
        return { ...item, price: parsePriceString(item.price) };
      }),
      voucher: values.voucher,
      shippingDate: values.shippingDate,
      cartId: values.cartId,
      attributes: values.cartAttributes?.split(','),
    };
    const searchAddress: SIWSearchAddressModel = {
      ...values.searchAddress,
      country: values.purchaseCountry,
      region: values.purchaseRegion,
      addressLines: values.searchAddress.addressLine1
        ? [values.searchAddress.addressLine1!]
        : undefined,
    };

    if (widgetReady) {
      dispatch(
        siwActions.updateSessionRequest({
          siteId: requestSiteId,
          model: {
            id: session!.id,
            cart,
            searchAddress,
          },
        })
      );
    } else {
      const meta: SIWSessionCreateModel['meta'] = {};
      values.meta.forEach((m: any) => {
        if (!m.key || !m.values) {
          return;
        }
        meta[m.key] = m.values;
      });
      const model: SIWSessionCreateModel = {
        purchaseCountry: values.purchaseCountry,
        purchaseCurrency: values.purchaseCurrency,
        locale: values.locale,
        cart,
        searchAddress,
        ...(isNotEmpty(meta) ? { meta } : {}),
      };
      dispatch(siwActions.createSessionRequest({ siteId: requestSiteId, model }));
    }
  };
  const createTestOrderFromWidgetSession = (values: WidgetFormValues) => {
    setIsClosingSession(true);
    dispatch(
      siwActions.completeSessionRequest({
        siteId,
        externalId: values.externalId,
        session: {
          id: session?.id!,
          customer: {
            address: {
              country: values.purchaseCountry,
              region: values.purchaseRegion,
              addressLines: [values.deliveryAddress.addressLine1],
              postalCode: values.deliveryAddress.postalCode,
              city: values.deliveryAddress.city,
              name: values.deliveryAddress.name,
            },
            email: values.deliveryAddress.email,
            phone: values.deliveryAddress.phone,
          },
        },
        onComplete: tosId => {
          setIsClosingSession(false);
          notification.success({
            key: tosId,
            placement: 'topRight',
            duration: 0,
            description: (
              <div>
                {formatMessage(
                  { id: 'CHECKOUT_TESTCLIENT.COMPLETE_SESSION_NOTIFICATION.MESSAGE' },
                  {
                    TEST_ORDER_LINK: (
                      <LinkStyleButton
                        showIcon={false}
                        className={styles.newOrderButton}
                        onClick={() => {
                          notification.close(tosId);
                          dispatch(routerActions.push({ name: 'TRANSPORT_ORDER_DETAILS', tosId }));
                        }}
                      >
                        {formatMessage({ id: 'TEST_ORDER_LINK' })}
                      </LinkStyleButton>
                    ),
                  }
                )}
              </div>
            ),
            message: formatMessage({
              id: 'CHECKOUT_TESTCLIENT.COMPLETE_SESSION_NOTIFICATION.HEADER',
            }),
          });
        },
      })
    );
  };

  const initialValues = getInitalValues(variantSiteId || draftSiteId);

  return (
    <>
      {isFetching ? (
        <Spinner />
      ) : (
        widgetReady && <WidgetTestClient session={session} htmlSnippet={htmlSnippet} />
      )}
      <Formik<WidgetFormValues>
        initialValues={initialValues}
        validate={values => getValidation(values)}
        onSubmit={values => createOrUpdateWidgetSession(values)}
        enableReinitialize={true}
      >
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <Card
              widgetReady={widgetReady}
              onBack={() => dispatch(siwActions.resetWidgetSession())}
              sessionCompleteEnabled={sessionCompleteEnabled}
              isClosingSession={isClosingSession}
              isFetching={isFetching || isFetchingSites}
              createTestOrder={createTestOrderFromWidgetSession}
            >
              <PersistEnhancer doNotReadFromLocalStorageFor={['useDraft']} />
              {!widgetReady && !variantSiteId && (
                <Field name="useDraft">
                  {({ field }: FieldProps<WidgetFormValues['useDraft']>) => (
                    <SwitchField
                      {...field}
                      label={<FormattedMessage id="USE_DRAFT" />}
                      disabled={doNotUseDraft ? true : !draftSiteId}
                      defaultChecked={doNotUseDraft ? false : !!draftSiteId}
                      checked={doNotUseDraft ? false : field.value}
                      onChange={value => formikProps.setFieldValue('useDraft', value)}
                    />
                  )}
                </Field>
              )}
              <Collapse defaultActiveKey={['1']}>
                <Collapse.Panel header="General" key="1">
                  <General
                    widgetReady={widgetReady}
                    showRegion={supportedRegionInCountry(formikProps.values.purchaseCountry)}
                    country={formikProps.values.purchaseCountry}
                  />
                </Collapse.Panel>
                <Collapse.Panel header="Search address" key="2">
                  <SearchAddress />
                </Collapse.Panel>
                {sessionCompleteEnabled && (
                  <Collapse.Panel header="Delivery address" key="3">
                    <DeliveryAddress />
                  </Collapse.Panel>
                )}
                <Collapse.Panel header="Additional" key="4">
                  <Additional widgetReady={widgetReady} />
                </Collapse.Panel>
                <Collapse.Panel header="Cart items" key="5">
                  <CartItems />
                </Collapse.Panel>
              </Collapse>
            </Card>
          </form>
        )}
      </Formik>
    </>
  );
};

export const CheckoutWidgetConfigurationEmbed: React.FC<Props> = ({ doNotUseDraft }) => {
  const [isMultiSession, setMultiSession] = React.useState<boolean>(false);

  const flags = useFlags();

  React.useEffect(() => {
    setMultiSession(flags.v2Integrated);
  }, [flags.v2Integrated]);

  return isMultiSession ? (
    <WidgetConfigurationV4 doNotUseDraft={doNotUseDraft} />
  ) : (
    <CheckoutWidgetConfiguration doNotUseDraft={doNotUseDraft} />
  );
};

export const CheckoutWidgetConfigurationPage: React.FC<Props> = ({ doNotUseDraft }) => {
  return (
    <>
      <ListFixedHeader
        title={<FormattedMessage id="WIDGET_CLIENT_HEADER" />}
        IconComponent={ShoppingCartOutlined}
        showSearch={false}
      />
      <CheckoutWidgetConfigurationEmbed doNotUseDraft={doNotUseDraft} />
    </>
  );
};

const styles = stylesheet({
  newOrderButton: {
    padding: '0',
  },
});
