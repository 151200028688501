// the status list that are available for the tracking number list and filtering

import { Status, TrackingEvent } from '@src/api/wimo';

// it does not contain supplementary events
// Keeping the CARRIER_PRODUCT_ID_ASSIGNED for the filtering.
// WIMO models are not consistent with the internal & filtering statuses.
export const trackingStatusList: (Status | 'CARRIER_PRODUCT_ID_ASSIGNED')[] = [
  'UNKNOWN',
  'ORDER_CREATED',
  'ORDER_BOOKED',
  'PICKUP_BOOKING_SCHEDULED',
  'PICKUP_BOOKED',
  'IN_TRANSIT',
  'DELIVERY_IN_PROGRESS',
  'ARRIVED_AT_PICKUP_POINT',
  'DELIVERED',
  'CANCELED',
  'DELAYED',
  'RETURNED',
  'ERROR',
  'SHIPMENT_CREATED',
  'LOGISTIC_UNIT_CREATED',
  'LOGISTIC_UNIT_RECEIVED_TRACKING_NUMBER',
  'NOTIFICATION_SENT',
  'BOOKING_RECEIVED_BY_CARRIER',
  'FAILED',
  'ARRIVED_AT_DISTRIBUTION_TERMINAL',
  'ON_HOLD',
  'RECEIVED_AT_PICKUP_POINT',
  'DEPARTED_FROM_PICKUP_POINT',
  'SHIPMENT_CANCELED',
  'TRACKING_NUMBER_REGISTERED',
  'CUSTOM',
  'LABEL_CREATED_BY_CARRIER',
  'PICKUP_FAILED',
  'RETURN_PICKUP_SCHEDULED',
  'CARRIER_PRODUCT_ID_ASSIGNED',
  'FORWARDED_TO_THIRD_PARTY',
  'IN_CUSTOMS',
  'REDIRECTED',
  'LOST',
  'DAMAGED',
  'RETURNED_TO_SENDER',
  'PICKUP_DELAYED',
  'PICKED_UP',
  'UNDELIVERABLE',
  'CONTACT_MERCHANT_SUPPORT',
  'CONTACT_CARRIER_SUPPORT',
];

const trackingSupplementaryEvents = [
  'INFO',
  'ESTIMATED_DELIVERY_TIME_EXCEEDED',
  'ESTIMATED_DELIVERY_TIME_UPDATED',
  'ESTIMATED_SUBMISSION_TIME_EXCEEDED',
] as const;

export const allTrackingStatuses: Array<
  typeof trackingStatusList[number] | typeof trackingSupplementaryEvents[number]
> = [...trackingStatusList, ...trackingSupplementaryEvents];

export type TrackingStatus =
  | typeof trackingStatusList[number]
  | typeof trackingSupplementaryEvents[number];

export class DeliveryEventModel {
  trackingStatus: Status;
  createdAt: string;
  originStatusDescription: string;
  location?: TrackingEvent['location'];

  static createFromDTO(dto: TrackingEvent): DeliveryEventModel {
    return {
      trackingStatus: dto.tracking_status ?? 'UNKNOWN',
      createdAt: dto.created_at ?? 'UNKNOWN',
      originStatusDescription: dto.origin_status_description ?? '',
      location: dto.location,
    };
  }
}
