import { mapCountryCodeToName } from '@src/dictionaries/mapping';
import { AddressModel } from '@src/models';
import { commons } from '@src/styles';
import { Descriptions } from 'antd';
import * as React from 'react';

type ContactDetailsProps = {
  address: AddressModel;
  phone?: string;
  email?: string;
};
export const ContactDetails: React.FunctionComponent<ContactDetailsProps> = ({
  address,
  phone,
  email,
}) => {
  const addressLines = (address.addressLines && address.addressLines.join(', ')) || '';

  return (
    <section>
      <Descriptions className={commons.descriptionList} size="small">
        <Descriptions.Item span={3} label="Name">
          {address.name || '-'}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Address lines">
          {addressLines || '-'}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Postal code">
          {address.postalCode || '-'}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="City">
          {address.city || '-'}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Region">
          {address.region || '-'}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Country">
          {mapCountryCodeToName(address.country) || '-'}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Email">
          {email || '-'}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Phone">
          {phone || '-'}
        </Descriptions.Item>
      </Descriptions>
    </section>
  );
};
