import * as qs from 'query-string';

import { ConfigDraftDTO, ConfigDraftModel } from '@src/models';
import { apiUtils, authFetch } from '@src/utils';

const DRAFTS_URL = apiUtils.getApiUrl() + '/frontend/config_draft';

export const getDraft = (siteId: string) => {
  const options: RequestInit = {
    method: 'GET',
    headers: { 'x-site-id': siteId },
  };

  return authFetch<{ draft: ConfigDraftDTO }>(
    `${DRAFTS_URL}/drafts.get?${qs.stringify({ site_id: siteId })}`,
    options
  ).then(res => ConfigDraftModel.createFromDTO(res.draft));
};

export const createDraft = (siteId: string) => {
  const options: RequestInit = {
    method: 'POST',
    headers: { 'x-site-id': siteId },
    body: JSON.stringify({
      site_id: siteId,
    }),
  };

  return authFetch<{ draft: ConfigDraftDTO }>(`${DRAFTS_URL}/drafts.create`, options).then(res =>
    ConfigDraftModel.createFromDTO(res.draft)
  );
};

export const deleteDraft = (siteId: string, version: number) => {
  const options: RequestInit = {
    method: 'DELETE',
    headers: { 'x-site-id': siteId },
  };

  return authFetch(
    `${DRAFTS_URL}/drafts.delete?${qs.stringify({ site_id: siteId, version })}`,
    options
  );
};

export const draftPromote = (siteId: string, version: number) => {
  const options: RequestInit = {
    method: 'POST',
    headers: { 'x-site-id': siteId },
    body: JSON.stringify({
      site_id: siteId,
      version,
    }),
  };

  return authFetch(`${DRAFTS_URL}/drafts.promote`, options);
};
