import React, { FC } from 'react';

import { style } from 'typestyle';

import { DeliveryTracking } from './delivery-tracking';

export const FindSection: FC = () => {
  return (
    <div className={styles.main}>
      <DeliveryTracking />
    </div>
  );
};

const styles = {
  main: style({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '24px',
  }),
};
