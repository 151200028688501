import { Card } from '@src/controls';
import React from 'react';
import { styles } from '@src/modules/delivery-tracking-notifications/smart-notifications-settings/components/smart-notifications-list';
import { useFormatMessage } from '@src/i18n';
import { eventLogicData } from '../configuration/event-logic-data';

type Props = {
  eventLogic?: string;
  threshold?: number;
};

export const ExpandableRowEvent = ({ eventLogic, threshold }: Props) => {
  const formatMessage = useFormatMessage();

  if (!eventLogic) {
    return null;
  }

  const eventLogicToBeDisplayed = eventLogicData[eventLogic].eventLogic;
  const eventLogicToBeDisplayedLength = eventLogicToBeDisplayed.length - 1;

  return (
    <div className={styles.descriptionContainer}>
      <div>
        <span>{formatMessage('NOTIFICATIONS.SMART.EVENT_LOGIC')}</span>
        <div className={styles.cardWrapper}>
          {eventLogicToBeDisplayed.map((event, index) => {
            return (
              <React.Fragment key={`${event.eventHeader}-${index}`}>
                <Card className={styles.card}>
                  <p className={styles.cardTitle}>
                    {event.threshold
                      ? formatMessage(`${event.eventHeader}`, { number: threshold })
                      : formatMessage(`${event.eventHeader}`)}
                  </p>
                  <p className={styles.cardDescription}>{formatMessage(`${event.eventMessage}`)}</p>
                </Card>
                {index < eventLogicToBeDisplayedLength && <span>&</span>}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
