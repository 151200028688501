import * as React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { useSiteId } from '@src/utils/hooks';
import { FormattedMessage } from '@src/i18n';
import { services } from '@src/services';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { useMutationOnDraft } from '@src/utils/queries';
import { FeatureFlags as ProductPageWidgetFeatures } from '@src/api/product-page-widget';

import { BreadcrumbSection, Header } from '@src/containers/product-page-widget/components';
import { FeaturesTable } from '@src/containers/product-page-widget/components';
import { ProductPageDrawer } from '@src/containers/product-page-widget/product-page-drawer';

export const ProductPageDefaultSettings: React.FC = () => {
  const siteId = useSiteId();
  const queryClient = useQueryClient();

  const productPageFeatures = useQuery(
    ['site', siteId],
    () => services.configService.getSite(siteId),
    {
      enabled: !!siteId,
      staleTime: Infinity,
      select: data => data?.productPageWidgetConfiguration?.features,
      keepPreviousData: true,
    }
  );

  const onSubmitDraft = useMutationOnDraft<ProductPageWidgetFeatures, unknown>(
    (siteId, payload) =>
      services.selfCareService.updateProductPageConfiguration(siteId, { features: payload }),
    {
      onSuccess: () => queryClient.invalidateQueries(['site']),
    }
  );

  return (
    <>
      <StatusBarContainer />
      <BreadcrumbSection>
        <FormattedMessage id="DEFAULT_SETTINGS" />
      </BreadcrumbSection>
      <Header>
        <FormattedMessage id="DEFAULT_SETTINGS" />
      </Header>
      <FeaturesTable
        key={productPageFeatures.dataUpdatedAt}
        onSubmit={onSubmitDraft.mutate}
        loading={productPageFeatures.isFetching}
        values={productPageFeatures.data}
      />
      <ProductPageDrawer />
    </>
  );
};
