import { Input } from 'antd';
import { FieldState, FormState } from 'formstate';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { withFieldStateInput, withFormItem } from '@src/decorators';

const TextAreaField = withFormItem(withFieldStateInput(Input.TextArea));
const InputField = withFormItem(withFieldStateInput(Input));

interface Props {
  formState: ShipmentOptionsDetailsFormState;
  disabled?: boolean;
}

export class ShipmentOptionsDetailsFormState extends FormState<{
  courierInstructions: FieldState<string | undefined>;
  doorCode: FieldState<string | undefined>;
}> {
  static create({
    doorCode,
    courierInstructions,
  }: {
    doorCode?: string;
    courierInstructions?: string;
  }): ShipmentOptionsDetailsFormState {
    return new FormState({
      courierInstructions: new FieldState(courierInstructions),
      doorCode: new FieldState(doorCode),
    });
  }
}

export const ShipmentOptionsDetails: React.FunctionComponent<Props> = ({ formState, disabled }) => {
  const { courierInstructions, doorCode } = formState.$;
  return (
    <>
      <TextAreaField
        label="Courier instructions"
        fieldState={courierInstructions}
        className={styles.textarea}
        disabled={disabled}
        labelCol={{ span: 24 }}
      />
      <InputField
        label="Door code"
        fieldState={doorCode}
        disabled={disabled}
        labelCol={{ span: 24 }}
      />
    </>
  );
};

const styles = {
  textarea: tss({
    $nest: {
      '&.ant-input': {
        height: '32px',
      },
    },
  }),
};
