import { FormattedMessage } from '@src/i18n';
import * as React from 'react';
import { FormikInputField } from './fields';

export const SearchAddress: React.FC = () => {
  return (
    <>
      <FormikInputField
        name="searchAddress.postalCode"
        label={<FormattedMessage id="ZIP_CODE" />}
      />
      <FormikInputField
        name="searchAddress.addressLine1"
        label={<FormattedMessage id="ADDRESS_LINE" values={{ num: 1 }} />}
      />
      <FormikInputField name="searchAddress.city" label={<FormattedMessage id="CITY" />} />
    </>
  );
};
