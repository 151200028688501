import * as React from 'react';
import { connect } from 'react-redux';

import { SomSearchSelectors } from '@src/modules/som-search';
import ErrorState from '../../components/error-state';
import { Modal } from '../../controls';
import { LABELS } from '../../dictionaries';
import { RootState } from '../../modules';
import { deliveriesSelectors } from '../../modules/deliveries';
import { ShipmentsSelectors } from '../../modules/shipments';
import { TrackAndTraceModalContent } from './components';

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  parcel: ShipmentsSelectors.getParcelById(state, ownProps.parcelId, ownProps.shipmentId),
  status: SomSearchSelectors.getShipmentStatus(state, ownProps.shipmentId),
  deliveriesHistoryForCurrentParcel: deliveriesSelectors.getDeliveryHistoryForCurrentParcel(
    state,
    ownProps.parcelId,
    ownProps.shipmentId
  ),
});

type OwnProps = {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  shipmentId: string;
  parcelId: string | null;
};
type Props = ReturnType<typeof mapStateToProps> & OwnProps;

class Component extends React.Component<Props> {
  renderModalContent = () => {
    const { parcel, status, deliveriesHistoryForCurrentParcel, visible } = this.props;
    if (parcel && deliveriesHistoryForCurrentParcel && status) {
      return (
        <TrackAndTraceModalContent
          parcel={parcel}
          deliveriesHistory={deliveriesHistoryForCurrentParcel}
          status={status}
        />
      );
    }
    return visible && <ErrorState />;
  };
  render() {
    const { visible, onOk, onCancel } = this.props;

    return (
      <Modal
        title={LABELS.TRACK_AND_TRACE}
        visible={visible}
        closable={true}
        width={1200}
        onOk={onOk}
        onCancel={onCancel}
        hideCancelButton={true}
      >
        {this.renderModalContent()}
      </Modal>
    );
  }
}

const TrackAndTraceModalContainer = connect(mapStateToProps, {})(Component);

export default TrackAndTraceModalContainer;
