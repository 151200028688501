import { createAction, createCustomAction } from 'typesafe-actions';

import {
  CustomerInfoModel,
  SIWSessionCreateModel,
  SIWSessionCreateResponseModel,
  SIWSessionDTO,
  SIWSessionModel,
  SIWSessionUpdateModel,
} from '../../models';

export const createSessionRequest = createAction('@siw/CREATE_SESSION_REQUEST')<{
  siteId: string;
  model: SIWSessionCreateModel;
}>();
export const createSessionSuccess = createAction(
  '@siw/CREATE_SESSION_SUCCESS'
)<SIWSessionCreateResponseModel>();
export const createSessionError = createAction('@siw/CREATE_SESSION_ERROR')<string>();

export const resetWidgetSession = createAction('@siw/RESET_WIDGET_SESSION')();

export const updateSessionRequest = createAction('@siw/UPDATE_SESSION_REQUEST')<{
  siteId: string;
  model: SIWSessionUpdateModel;
}>();
export const updateSessionSuccess = createAction('@siw/UPDATE_SESSION_SUCCESS')<SIWSessionModel>();
export const updateSessionError = createAction('@siw/UPDATE_SESSION_ERROR')<string>();

export const getSessionRequest = createCustomAction(
  '@siw/GET_SESSION_REQUEST',
  ({ onComplete, ...payload }: { siteId: string; sessionId: string; onComplete?: () => void }) => ({
    payload,
    meta: { onComplete },
  })
);
export const getSessionSuccess = createAction('@siw/GET_SESSION_SUCCESS')<{
  model: SIWSessionModel;
  dto: SIWSessionDTO;
}>();
export const getSessionError = createAction('@siw/GET_SESSION_ERROR')<string>();

export const completeSessionRequest = createCustomAction(
  '@siw/COMPLETE_SESSION_REQUEST',
  ({
    onComplete,
    ...payload
  }: {
    siteId: string;
    externalId?: string;
    session: { id: string; customer: CustomerInfoModel };
    onComplete: (tosId: string) => void;
  }) => ({ payload, meta: { onComplete } })
);
export const completeSessionSuccess = createAction('@siw/COMPLETE_SESSION_SUCCESS')<string>();
export const completeSessionError = createAction('@siw/COMPLETE_SESSION_ERROR')<string>();
