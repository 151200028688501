import * as qs from 'query-string';
import { DeepPartial } from 'utility-types';
import {
  AddCategoryTagsDTO,
  AddCategoryTagsModel,
  AdjustDeliveryAddonsOrderDTO,
  AdjustDeliveryAddonsOrderModel,
  CarrierProductSettingsMappingResponse,
  ConfigChangeDTO,
  ConfigChangeModel,
  CreateCarrierProductDTO,
  CreateCarrierProductMappingRequest,
  CreateCarrierProductModel,
  CreateCategoryDTO,
  CreateCategoryLabelDTO,
  CreateCategoryLabelModel,
  CreateCategoryModel,
  CreateDeliveryAddonDTO,
  CreateDeliveryAddonModel,
  CreateMerchantDTO,
  CreateMerchantModel,
  CreateRegion,
  CreateSiteModel,
  CreateWarehouseCutoffTimesDTO,
  CreateWarehouseCutoffTimesModel,
  CreateWarehouseDTO,
  CreateWarehouseModel,
  DeleteCarrierProductDTO,
  DeleteCarrierProductMappingRequest,
  DeleteCarrierProductModel,
  DeleteCategoryLabelDTO,
  DeleteCategoryLabelModel,
  DeleteCategoryLabelTranslationsDTO,
  DeleteCategoryLabelTranslationsModel,
  DeleteCustomEventTranslationDTO,
  DeleteCustomEventTranslationModel,
  DeleteDeliveryAddonDTO,
  DeleteDeliveryAddonModel,
  DeleteDeliveryAddonTranslationDTO,
  DeleteDeliveryAddonTranslationModel,
  DeleteTranslationDTO,
  DeleteTranslationModel,
  DeleteWarehouseCutoffTimesDTO,
  DeleteWarehouseCutoffTimesModel,
  ExtendedShippingMethodDTO,
  ExtendedShippingMethodModel,
  GlobalCarrierProductList,
  ListCarrierProductMappingResponse,
  ListSuggestedCarrierProductMappingsResponse,
  NotificationsSettingsResponse,
  ReceiptWidgetConfiguration,
  RemoveCategoryTagsModel,
  ShippingDateAdjustmentDTO,
  ShippingDateAdjustmentModel,
  ShippingMethodCreateResponseStatus,
  SmartNotificationsSettingsResponse,
  TrackingConfigurationStatusResponse,
  TrackingLinksForCarrierProduct,
  UpdateCarrierProductDTO,
  UpdateCarrierProductFilterRulesDTO,
  UpdateCarrierProductFilterRulesModel,
  UpdateCarrierProductMappingRequest,
  UpdateCarrierProductModel,
  UpdateCategoryDetailsDTO,
  UpdateCategoryDetailsModel,
  UpdateCategoryFilterRulesDTO,
  UpdateCategoryFilterRulesModel,
  UpdateCategoryLabelDetailsDTO,
  UpdateCategoryLabelDetailsModel,
  UpdateCategoryLabelsOrderDTO,
  UpdateCategoryLabelsOrderModel,
  UpdateCategoryLabelTranslationsDTO,
  UpdateCategoryLabelTranslationsModel,
  UpdateCategoryTranslationsDTO,
  UpdateCategoryTranslationsModel,
  UpdateConfigRegionDTO,
  UpdateConfigRegionModel,
  UpdateDeliveryAddonDTO,
  UpdateDeliveryAddonModel,
  UpdateDeliveryTimeDTO,
  UpdateDeliveryTimeModel,
  UpdateInternalEventDTO,
  UpdateInternalEventModel,
  UpdatePreselectionOrderDTO,
  UpdatePreselectionOrderModel,
  UpdateWarehouseCarrierCutoffDTO,
  UpdateWarehouseCarrierCutoffModel,
  UpdateWarehouseCutoffTimesDTO,
  UpdateWarehouseCutoffTimesModel,
  UpdateWarehouseDetailsDTO,
  UpdateWarehouseDetailsModel,
  UpdateWidgetConfigurationDTO,
  UpdateWidgetConfigurationModel,
  UpdateWidgetCountryConfigurationDTO,
  UpdateWidgetCountryConfigurationModel,
  UpsertCustomEventDTO,
  UpsertCustomEventModel,
  UpsertCustomEventTranslationDTO,
  UpsertCustomEventTranslationModel,
  UpsertDeliveryAddonTranslationDTO,
  UpsertDeliveryAddonTranslationModel,
  UpsertIntegratedProductMappingRequest,
} from '../models';
import { apiUtils, authFetch } from '../utils';
import { FeatureFlags as ProductPageWidgetFeatures } from '@src/api/product-page-widget';
import { FeatureFlags as FaqWidgetFeatures } from '@src/api/faq-widget';

const SELFCARE_URL = apiUtils.getApiUrl() + '/frontend/selfcare';

export const regionCarrierProductUpdate = (model: UpdateCarrierProductModel, siteId: string) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(UpdateCarrierProductDTO.createFromModel(model)),
  };

  return authFetch(`${SELFCARE_URL}/region/product.update`, options);
};

export const regionCarrierProductFilterRulesUpdate = (
  model: UpdateCarrierProductFilterRulesModel,
  siteId: string
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(UpdateCarrierProductFilterRulesDTO.createFromModel(model)),
  };

  return authFetch(`${SELFCARE_URL}/region/product_filter_rules.update`, options);
};

export const categoryFilterRulesUpdate = (
  model: UpdateCategoryFilterRulesModel,
  siteId: string
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(UpdateCategoryFilterRulesDTO.createFromModel(model)),
  };

  return authFetch(`${SELFCARE_URL}/category/filter_rules.update`, options);
};

export const updateCategoryDeliveryTime = (siteId: string, model: UpdateDeliveryTimeModel) => {
  const payload = UpdateDeliveryTimeDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/delivery_time.update`, options);
};

export const updateRegionDetails = (siteId: string, model: UpdateConfigRegionModel) => {
  const payload = UpdateConfigRegionDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };

  return authFetch<{}>(`${SELFCARE_URL}/region/details.update`, options);
};

export const updateCategoryDetails = (siteId: string, model: UpdateCategoryDetailsModel) => {
  const payload = UpdateCategoryDetailsDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/details.update`, options);
};

export const createCategory = (siteId: string, model: CreateCategoryModel) => {
  const payload = CreateCategoryDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{ category_id: string }>(`${SELFCARE_URL}/category/create`, options).then(
    res => res.category_id
  );
};

export const createCategoryLabel = (siteId: string, model: CreateCategoryLabelModel) => {
  const payload = CreateCategoryLabelDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{ label_id: string }>(`${SELFCARE_URL}/category/labels.create`, options).then(
    res => res.label_id
  );
};

export const addCategoryTags = (siteId: string, model: AddCategoryTagsModel) => {
  const payload = AddCategoryTagsDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/tags.add`, options);
};

export const removeCategoryTags = (siteId: string, model: RemoveCategoryTagsModel) => {
  const payload = AddCategoryTagsDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/tags.remove`, options);
};

export const deleteCategoryLabel = (siteId: string, model: DeleteCategoryLabelModel) => {
  const payload = DeleteCategoryLabelDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/labels.delete`, options);
};

export const deleteCategory = (siteId: string, categoryId: string) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ category_id: categoryId }),
  };
  return authFetch(`${SELFCARE_URL}/category/delete`, options);
};

export function getConfigChanges(siteId: string) {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<{ changes: ConfigChangeDTO[] }>(
    `${SELFCARE_URL}/configver/changes.list?${qs.stringify({ site_id: siteId })}`,
    options
  ).then(res =>
    res.changes ? res.changes.map(change => ConfigChangeModel.createFromDTO(change)) : []
  );
}

export const updateCategoryTranslations = (
  siteId: string,
  model: UpdateCategoryTranslationsModel
) => {
  const payload = UpdateCategoryTranslationsDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/translations.update`, options);
};

export const deleteCategoryTranslations = (siteId: string, model: DeleteTranslationModel) => {
  const payload = DeleteTranslationDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/translations.delete`, options);
};

export const updateWarehouseDetails = (siteId: string, model: UpdateWarehouseDetailsModel) => {
  const payload = UpdateWarehouseDetailsDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{}>(`${SELFCARE_URL}/warehouse/details.update`, options);
};

export const createCarrierProduct = (siteId: string, model: CreateCarrierProductModel) => {
  const payload = CreateCarrierProductDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{ status: ShippingMethodCreateResponseStatus }>(
    `${SELFCARE_URL}/region/product.create`,
    options
  );
};

export const deleteCarrierProduct = (siteId: string, model: DeleteCarrierProductModel) => {
  const payload = DeleteCarrierProductDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch(`${SELFCARE_URL}/region/product.delete`, options);
};

export const createWarehouse = (siteId: string, model: CreateWarehouseModel) => {
  const payload = CreateWarehouseDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{ id: string }>(`${SELFCARE_URL}/warehouse/create`, options);
};

export const deleteWarehouse = (siteId: string, warehouseId: string) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ id: warehouseId }),
  };
  return authFetch(`${SELFCARE_URL}/warehouse/delete`, options);
};

export const createRegion = (siteId: string, model: CreateRegion['model']) => {
  const payload = CreateRegion.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{ id: string }>(`${SELFCARE_URL}/region/create`, options).then(res => res.id);
};

export const deleteRegion = (siteId: string, regionId: string) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ region_id: regionId }),
  };
  return authFetch(`${SELFCARE_URL}/region/delete`, options);
};

export const updateWidgetConfiguration = (
  siteId: string,
  widgetConfiguration: UpdateWidgetConfigurationModel
) => {
  const payload = widgetConfiguration
    ? UpdateWidgetConfigurationDTO.createFromModel(widgetConfiguration)
    : {};
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({
      widget_configuration: payload,
    }),
  };
  return authFetch(`${SELFCARE_URL}/configuration/widget`, options);
};

export const createWidgetCountryConfiguration = (
  siteId: string,
  model: UpdateWidgetCountryConfigurationModel
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(UpdateWidgetCountryConfigurationDTO.createFromModel(model)),
  };
  return authFetch(`${SELFCARE_URL}/configuration/widget/country.create`, options);
};

export const updateWidgetCountryConfiguration = (
  siteId: string,
  model: UpdateWidgetCountryConfigurationModel
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(UpdateWidgetCountryConfigurationDTO.createFromModel(model)),
  };
  return authFetch(`${SELFCARE_URL}/configuration/widget/country.update`, options);
};

export const updateCutoffTimes = (siteId: string, model: UpdateWarehouseCutoffTimesModel) => {
  const payload = UpdateWarehouseCutoffTimesDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{}>(`${SELFCARE_URL}/warehouse/cutoffs.update`, options);
};

export const updateCarrierCutoff = (siteId: string, model: UpdateWarehouseCarrierCutoffModel) => {
  const payload = UpdateWarehouseCarrierCutoffDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{}>(`${SELFCARE_URL}/warehouse/cutoffs.update`, options);
};

export const updateUpsellConfiguration = (siteId: string, widgetConfiguration: any) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({
      widget_configuration: widgetConfiguration,
    }),
  };
  return authFetch(`${SELFCARE_URL}/configuration/upsell`, options);
};

export const updateReceiptConfiguration = (
  siteId: string,
  widgetConfiguration: {
    features: DeepPartial<ReceiptWidgetConfiguration['features']>;
  }
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(widgetConfiguration),
  };
  return authFetch(`${SELFCARE_URL}/configuration/receipt/default_configuration.update`, options);
};

export const upsertReceiptConfiguration = (
  siteId: string,
  country: string,
  widgetConfiguration: {
    features: DeepPartial<ReceiptWidgetConfiguration['features']>;
  }
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ country, ...widgetConfiguration }),
  };
  return authFetch(`${SELFCARE_URL}/configuration/receipt/country_configuration.upsert`, options);
};

export const createUpsellCountryConfiguration = (
  siteId: string,
  country: string,
  features: any
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ country, features }),
  };
  return authFetch(`${SELFCARE_URL}/configuration/upsell/country.create`, options);
};

export const updateUpsellCountryConfiguration = (
  siteId: string,
  country: string,
  configuration: any
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({
      country,
      features: {
        features: configuration,
      },
    }),
  };
  return authFetch(`${SELFCARE_URL}/configuration/upsell/country.update`, options);
};

export const updateFaqWidgetConfiguration = (
  siteId: string,
  widgetConfiguration: { features: FaqWidgetFeatures }
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(widgetConfiguration),
  };
  return authFetch(`${SELFCARE_URL}/configuration/faq`, options);
};

export const upsertFaqWidgetCountryConfiguration = (
  siteId: string,
  country: string,
  widgetConfiguration: { features: FaqWidgetFeatures }
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ country, ...widgetConfiguration }),
  };
  return authFetch(`${SELFCARE_URL}/configuration/faq/country.upsert`, options);
};

export const deleteFaqWidgetCountryConfiguration = (siteId: string, country: string) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ country }),
  };
  return authFetch(`${SELFCARE_URL}/configuration/faq/country.delete`, options);
};

export const updateProductPageConfiguration = (
  siteId: string,
  widgetConfiguration: { features: ProductPageWidgetFeatures }
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(widgetConfiguration),
  };
  return authFetch(`${SELFCARE_URL}/configuration/product_page`, options);
};

export const upsertProductPageCountryConfiguration = (
  siteId: string,
  country: string,
  widgetConfiguration: { features: ProductPageWidgetFeatures }
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ country, ...widgetConfiguration }),
  };
  return authFetch(`${SELFCARE_URL}/configuration/product_page/country.upsert`, options);
};

export const deleteProductPageCountryConfiguration = (siteId: string, country: string) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ country }),
  };
  return authFetch(`${SELFCARE_URL}/configuration/product_page/country.delete`, options);
};

export const createWarehouseCutoffTimes = (
  siteId: string,
  model: CreateWarehouseCutoffTimesModel
) => {
  const payload = CreateWarehouseCutoffTimesDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return authFetch(`${SELFCARE_URL}/warehouse/cutoffs.create`, options);
};

export const deleteWarehouseCutoffTimes = (
  siteId: string,
  model: DeleteWarehouseCutoffTimesModel
) => {
  const payload = DeleteWarehouseCutoffTimesDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return authFetch(`${SELFCARE_URL}/warehouse/cutoffs.delete`, options);
};

export const setWarehouseShippingDateAdjustment = (
  siteId: string,
  warehouseId: string,
  model: ShippingDateAdjustmentModel
) => {
  const sda = ShippingDateAdjustmentDTO.createFromModel(model);
  const options = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ shipping_date_adjustment: sda, warehouse_id: warehouseId }),
  };
  return authFetch(`${SELFCARE_URL}/warehouse/shipping_date_adjustment.set`, options);
};

export const updateCountrySettings = (siteId: string, model: UpdatePreselectionOrderModel) => {
  const payload = UpdatePreselectionOrderDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/country_settings`, options);
};

export const listShippingMethods = (
  siteId: string,
  withCustomBookingMethods?: boolean
): Promise<ExtendedShippingMethodModel[]> => {
  const options: RequestInit = {
    method: 'GET',
  };

  return authFetch<{ methods: ExtendedShippingMethodDTO[] }>(
    `${SELFCARE_URL}/shipping_methods.list?${qs.stringify({
      site_id: siteId,
      with_custom_booking_methods: !!withCustomBookingMethods,
    })}`,
    options
  ).then(res =>
    res.methods ? res.methods.map(item => ExtendedShippingMethodModel.createFromDTO(item)) : []
  );
};

export const createMerchant = (name: string): Promise<CreateMerchantModel> => {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify({ name }),
  };

  return authFetch<CreateMerchantDTO>(`${SELFCARE_URL}/merchant.create`, options).then(
    CreateMerchantModel.createFromDTO
  );
};

export const createSite = (merchantId: string, name: string): Promise<CreateSiteModel> => {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify({ merchantId, name }),
  };

  return authFetch<CreateMerchantDTO>(`${SELFCARE_URL}/site.create`, options).then(
    CreateSiteModel.createFromDTO
  );
};

export const renameMerchant = (id: string, name: string): Promise<{}> => {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify({ id, name }),
  };

  return authFetch(`${SELFCARE_URL}/merchant.rename`, options);
};

export const renameSite = (id: string, name: string): Promise<{}> => {
  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify({ id, name }),
    headers: {
      'x-site-id': id,
    },
  };

  return authFetch(`${SELFCARE_URL}/site.rename`, options);
};

export const changeCategoryLabelsOrder = (
  siteId: string,
  model: UpdateCategoryLabelsOrderModel
) => {
  const payload = UpdateCategoryLabelsOrderDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/labels.adjust_order`, options);
};

export const updateCategoryLabelDetails = (
  siteId: string,
  model: UpdateCategoryLabelDetailsModel
) => {
  const payload = UpdateCategoryLabelDetailsDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/labels.update`, options);
};

export const updateCategoryLabelTranslationsModel = (
  siteId: string,
  model: UpdateCategoryLabelTranslationsModel
) => {
  const payload = UpdateCategoryLabelTranslationsDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/labels_translation.upsert`, options);
};

export const deleteCategoryLabelTranslationsModel = (
  siteId: string,
  model: DeleteCategoryLabelTranslationsModel
) => {
  const payload = DeleteCategoryLabelTranslationsDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/labels_translation.delete`, options);
};

export const createDeliveryAddon = (siteId: string, model: CreateDeliveryAddonModel) => {
  const payload = CreateDeliveryAddonDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{ addon_id: string }>(
    `${SELFCARE_URL}/category/delivery_addons.create`,
    options
  ).then(res => res.addon_id);
};

export const updateDeliveryAddon = (siteId: string, model: UpdateDeliveryAddonModel) => {
  const payload = UpdateDeliveryAddonDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/delivery_addons.update`, options);
};

export const deleteDeliveryAddon = (siteId: string, model: DeleteDeliveryAddonModel) => {
  const payload = DeleteDeliveryAddonDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/delivery_addons.delete`, options);
};

export const adjustDeliveryAddonsOrder = (
  siteId: string,
  model: AdjustDeliveryAddonsOrderModel
) => {
  const payload = AdjustDeliveryAddonsOrderDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/delivery_addons.adjust_order`, options);
};

export const upsertDeliveryAddonTranslation = (
  siteId: string,
  model: UpsertDeliveryAddonTranslationModel
) => {
  const payload = UpsertDeliveryAddonTranslationDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/delivery_addons_translation.upsert`, options);
};

export const deleteDeliveryAddonTranslation = (
  siteId: string,
  model: DeleteDeliveryAddonTranslationModel
) => {
  const payload = DeleteDeliveryAddonTranslationDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return authFetch<{}>(`${SELFCARE_URL}/category/delivery_addons_translation.delete`, options);
};

export const deleteCustomEvent = (siteId: string, eventId: string) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ id: eventId }),
  };
  return authFetch(`${SELFCARE_URL}/custom_event_type.delete`, options);
};

export const updateInternalEvent = (siteId: string, model: UpdateInternalEventModel) => {
  const payload = UpdateInternalEventDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };

  return authFetch<{ id: string }>(`${SELFCARE_URL}/internal_event_type`, options);
};

export const upsertCustomEvent = (siteId: string, model: UpsertCustomEventModel) => {
  const payload = UpsertCustomEventDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };

  return authFetch<{ id: string }>(`${SELFCARE_URL}/custom_event_type.upsert`, options).then(
    res => res.id
  );
};

export const upsertCustomEventTranslation = (
  siteId: string,
  model: UpsertCustomEventTranslationModel
) => {
  const payload = UpsertCustomEventTranslationDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return authFetch<{}>(`${SELFCARE_URL}/custom_event_type_translation.upsert`, options);
};

export const deleteCustomEventTranslation = (
  siteId: string,
  model: DeleteCustomEventTranslationModel
) => {
  const payload = DeleteCustomEventTranslationDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return authFetch<{}>(`${SELFCARE_URL}/custom_event_type_translation.delete`, options);
};

export const listCarrierProductMapping = async (siteId: string) => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };
  return await authFetch<ListCarrierProductMappingResponse>(
    `${SELFCARE_URL}/carrier_product_mapping.list?site_id=${siteId}`,
    options
  );
};

export const createCarrierProductMapping = async (
  siteId: string,
  payload: CreateCarrierProductMappingRequest
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return await authFetch<{ id: string; version: string }>(
    `${SELFCARE_URL}/carrier_product_mapping.create`,
    options
  );
};

export const updateCarrierProductMapping = async (
  siteId: string,
  payload: UpdateCarrierProductMappingRequest
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return await authFetch<{ id: string; version: string }>(
    `${SELFCARE_URL}/carrier_product_mapping.update`,
    options
  );
};

export const deleteCarrierProductMapping = async (
  siteId: string,
  payload: DeleteCarrierProductMappingRequest
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };
  return await authFetch<{ version: string }>(
    `${SELFCARE_URL}/carrier_product_mapping.delete`,
    options
  );
};

export const listTrackingLinksForCarrierProduct = async (
  siteId: string,
  carrierProductId: string
) => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };
  return await authFetch<TrackingLinksForCarrierProduct>(
    `${SELFCARE_URL}/tracking_links_for_carrier_product.list?carrier_product_id=${carrierProductId}`,
    options
  );
};

export const listCarrierProducts = async (siteId: string) => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };
  return await authFetch<GlobalCarrierProductList>(
    `${SELFCARE_URL}/carrier_products.list`,
    options
  );
};

export const listCarrierProductSettings = async (siteId: string) => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };
  return await authFetch<CarrierProductSettingsMappingResponse>(
    `${SELFCARE_URL}/carrier_product_settings.list?site_id=${siteId}`,
    options
  );
};

export const upsertIntegratedProductMapping = async (
  siteId: string,
  payload: UpsertIntegratedProductMappingRequest
) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };

  return await authFetch<{ version: string }>(
    `${SELFCARE_URL}/carrier_product_settings.upsert`,
    options
  );
};

export const listSuggestedCarrierProductMappings = async (siteId: string) => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };
  return await authFetch<ListSuggestedCarrierProductMappingsResponse>(
    `${SELFCARE_URL}/suggested_product_mappings.list?site_id=${siteId}`,
    options
  );
};

export const getTrackingConfigStatus = async (siteId: string, carrierProductId: string) => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };
  return await authFetch<TrackingConfigurationStatusResponse>(
    `${SELFCARE_URL}/tracking_config_status.get?${qs.stringify({
      site_id: siteId,
      carrier_product_id: carrierProductId,
    })}`,
    options
  );
};

export const getNotificationsSetting = async (siteId: string) => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return await authFetch<NotificationsSettingsResponse>(
    `${SELFCARE_URL}/delivery_notification_settings/progress.list?site_id=${siteId}`,
    options
  );
};

export const getSmartNotificationsSetting = async (siteId: string) => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return await authFetch<SmartNotificationsSettingsResponse>(
    `${SELFCARE_URL}/delivery_notification_settings/smart.list?site_id=${siteId}`,
    options
  );
};
