import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { AddButton, ComponentReturningChildren, ShippingMethodWithLogo } from '@src/components';
import { Select, Tag, Tooltip } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, wideLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { ConfigShippingCategoryModel } from '@src/models';
import { defaultTheme } from '@src/styles';
import * as React from 'react';
import { stylesheet } from 'typestyle';

const LabelWrapper = withFormItem(ComponentReturningChildren, wideLayout);

interface Props {
  isChainDelivery: boolean;
  chainDelivery: ConfigShippingCategoryModel['deliveryChain'];
  onShippingMethodAdd: (method: string) => void;
  onRemoveShippingMethod: (method: string) => void;
  isAddingMethod: boolean;
  temporaryMethod: string | null;
  setTemporaryMethod: (method: string | null) => void;
  toggleAddingMethod: (bool: boolean) => void;
  canSendToBackend: boolean;
  methodsToRender: string[];
  methodsAvailableToAdd: { label: string; value: string }[];
}

export const ShippingMethodsForm: React.FunctionComponent<Props> = ({
  isChainDelivery,
  chainDelivery,
  onShippingMethodAdd,
  onRemoveShippingMethod,
  isAddingMethod,
  temporaryMethod,
  setTemporaryMethod,
  toggleAddingMethod,
  canSendToBackend,
  methodsToRender,
  methodsAvailableToAdd,
}) => {
  return (
    <AutoSaveForm>
      <LabelWrapper
        label={
          <>
            <FormattedMessage id="CARRIER_PRODUCT" values={{ multiple: true }} />
            {isAddingMethod && (
              <Tooltip title={<FormattedMessage id="METHODS_DISABLED" />}>
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            )}
          </>
        }
      >
        <>
          <div className={styles.list}>
            {methodsToRender.map((method, index) => (
              <div className={styles.itemHeader} key={`${method}-${index}`}>
                {isChainDelivery ? (
                  <Tag nonClickable={true} className={styles.mileDeliveryTag}>
                    {chainDelivery!.firstMile.shippingMethod === method ? (
                      <FormattedMessage id="FIRST_MILE" />
                    ) : (
                      <FormattedMessage id="SECOND_MILE" />
                    )}
                  </Tag>
                ) : (
                  <DeleteOutlined
                    className={styles.removeMethodIcon}
                    onClick={() => onRemoveShippingMethod(method)}
                  />
                )}
                <ShippingMethodWithLogo method={method} />
              </div>
            ))}
          </div>
          {isAddingMethod ? (
            <Select
              className={styles.carrierProductSelect}
              placeholder={'Please select...'}
              options={methodsAvailableToAdd}
              defaultOpen={true}
              onChange={(value: string) => setTemporaryMethod(value)}
              onBlur={() => {
                if (canSendToBackend) {
                  onShippingMethodAdd(temporaryMethod!);
                }
                toggleAddingMethod(false);
                setTemporaryMethod(null);
              }}
              filterOption={true}
              optionFilterProp={'label'}
              showSearch
            />
          ) : (
            !isChainDelivery && <AddButton onClick={() => toggleAddingMethod(true)} />
          )}
        </>
      </LabelWrapper>
    </AutoSaveForm>
  );
};

const styles = stylesheet({
  methodNameHeader: {
    lineHeight: '20px',
    fontSize: '14px',
    letterSpacing: '-0.11px',
    color: 'rgba(0, 0, 0, 0.75)',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  itemHeader: {
    backgroundColor: defaultTheme.regionForm.color.shippingMethodCarrierInfo,
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
    margin: '0 4px 8px 4px',
    borderRadius: '4px',
    width: '192px',
    $nest: {
      '.ant-tag': {
        alignSelf: 'flex-start',
      },
    },
  },
  carrierLogo: {
    maxHeight: '55px',
    maxWidth: '75px',
  },
  removeMethodIcon: {
    fontSize: '16px',
    marginLeft: 'auto',
  },
  inputSuffixAddIcon: {
    cursor: 'pointer',
  },
  mileDeliveryTag: {
    marginBottom: '5px',
  },
  carrierProductSelect: {
    $nest: {
      '&.ant-select': {
        border: '1px solid #d9d9d9',
        borderRadius: '3px',
      },
      '&.ant-select:hover': {
        border: '1px solid #5bc2b6',
      },
      '&.ant-select-open': {
        border: '1px solid #5bc2b6',
      },
    },
  },
});
