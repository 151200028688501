import React from 'react';
import { style } from 'typestyle';
import { Table } from 'antd';
import moment from 'moment';

import { Link } from '@src/components/link';
import { Badge, Button, Tooltip } from '@src/controls';
import { ShipmentSummaryModel } from '@src/models';
import { stringUtils } from '@src/utils';
import { ShipmentStatusTag } from '../../shipments/components';
import { useFormatMessage } from '@src/i18n';

const PAGE_SIZE = 10;

export const ShipmentsTable = ({
  items,
  tosId,
}: {
  items: ShipmentSummaryModel[];
  tosId: string | undefined;
}) => {
  const hasPagination = items.length > PAGE_SIZE ? { total: items.length } : false;
  const formatMessage = useFormatMessage();

  return (
    <section className={styles.section}>
      <Table<ShipmentSummaryModel>
        rowKey="id"
        dataSource={items}
        pagination={hasPagination}
        locale={{ emptyText: formatMessage('EMPTY_SHIPMENTS_TABLE') }}
        columns={[
          {
            title: formatMessage('SHIPMENT_ID'),
            dataIndex: 'id',
            key: 'id',
            render: (_, item) => (
              <Tooltip title={item.id}>
                <Link
                  usePrimaryColors
                  route={{ name: 'SHIPMENT_EDIT', shipmentId: item.id, tosId }}
                >
                  {stringUtils.truncateId(item.id) || <Badge.Empty />}
                </Link>
              </Tooltip>
            ),
          },
          {
            title: formatMessage('CREATED_AT'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            sortDirections: ['ascend'],
            sorter: (a, b) => moment(a.createdAt).diff(moment(b.createdAt)),
          },
          {
            title: formatMessage('SENDER_ADDRESS'),
            dataIndex: 'addressFrom',
            key: 'addressFrom',
            render: (_, item) => item.addressFrom?.name ?? '',
          },
          {
            title: formatMessage('DELIVERY_ADDRESS'),
            dataIndex: 'addressTo',
            key: 'addressTo',
            render: (_, item) => item.addressTo?.name ?? '',
          },
          {
            title: formatMessage('STATUS'),
            dataIndex: 'status',
            key: 'status',
            render: (_, item) => <ShipmentStatusTag status={item.status} />,
          },
        ]}
      />
      <Link route={{ name: 'SHIPMENT_CREATE', tosId }}>
        <Button className={styles.createButton} type="primary">
          {formatMessage('CREATE')}
        </Button>
      </Link>
    </section>
  );
};

const styles = {
  section: style({ position: 'relative' }),
  createButton: style({
    marginTop: '10px',
  }),
};
