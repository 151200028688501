import { getFormikError } from '@src/utils/forms';
import { useDeepCompareEffect } from '@src/utils/hooks';
import { Field, FieldProps, FormikProps, withFormik } from 'formik';
import * as React from 'react';
import { classes, style as tss } from 'typestyle';
import { object, string } from 'yup';
import { Button, Select } from '../../../controls';
import { withFormItem } from '../../../decorators';
import { LABELS } from '../../../dictionaries';

interface Props {
  merchantsOptions: { value: string; label: string }[];
  selectedMerchantId: string | null;
  sitesOptions: { value: string; label: string }[];
  selectedSiteId: string | null;
  onMerchantChange: (id: string) => void;
  onSubmit: ({ merchantId, siteId }: { merchantId: string | null; siteId: string | null }) => void;
  onCancel: () => void;
  isFetching: boolean;
}

interface FormValues {
  merchantId: string | null;
  siteId: string | null;
}

const SelectField = withFormItem(Select);

const styles = {
  form: tss({ display: 'flex', flexDirection: 'column' }),
  buttonsWrapper: tss({ display: 'flex', justifyContent: 'flex-end' }),
  submitButton: tss({ marginLeft: 10 }),
};

const InnerForm: React.FunctionComponent<Props & FormikProps<FormValues>> = ({
  merchantsOptions,
  handleSubmit,
  setFieldValue,
  sitesOptions,
  onMerchantChange,
  touched,
  errors,
  setFieldTouched,
  onCancel,
  isFetching,
  values,
}) => {
  useDeepCompareEffect(() => {
    if (!values.siteId) {
      setFieldValue('siteId', sitesOptions[0].value);
    }
  }, [sitesOptions]);
  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Field name="merchantId">
        {({ field }: FieldProps<FormValues['merchantId']>) => (
          <SelectField
            labelCol={{ span: 24 }}
            label="Merchant"
            options={merchantsOptions}
            placeholder={LABELS.PLACEHOLDER_SELECT}
            value={field.value ?? ''}
            caseInsensitiveSearch={true}
            error={getFormikError(touched.merchantId, errors.merchantId)}
            onChange={(value: string) => {
              onMerchantChange(value);
              setFieldValue('merchantId', value);
              setFieldValue('siteId', '');
              setFieldTouched('siteId', false);
            }}
            className="t_merchant-select"
            dropdownClassName="t_merchant-select-dropdown"
            showSearch={true}
          />
        )}
      </Field>
      <Field name="siteId">
        {({ field }: FieldProps<FormValues['siteId']>) => (
          <SelectField
            labelCol={{ span: 24 }}
            label="Site"
            options={sitesOptions}
            disabled={isFetching}
            loading={isFetching}
            placeholder={LABELS.PLACEHOLDER_SELECT}
            value={field.value ?? ''}
            error={getFormikError(touched.siteId, errors.siteId)}
            onChange={value => setFieldValue('siteId', value)}
            caseInsensitiveSearch={true}
            showSearch={true}
            className="t_site-select"
            dropdownClassName="t_site-select-dropdown"
          />
        )}
      </Field>
      <div className={styles.buttonsWrapper}>
        <Button onClick={onCancel}>{LABELS.CANCEL}</Button>
        <Button
          type="primary"
          htmlType="submit"
          className={classes(styles.submitButton, 't_merchant-site-select-submit')}
        >
          {LABELS.SAVE}
        </Button>
      </div>
    </form>
  );
};

export const MerchantSiteForm = withFormik<Props, FormValues>({
  mapPropsToValues: props => ({
    merchantId: props.selectedMerchantId,
    siteId: props.selectedSiteId,
  }),
  validationSchema: () => object({ siteId: string().required('This field is required') }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
})(InnerForm);
