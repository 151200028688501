import * as React from 'react';
import { style as tss } from 'typestyle';

import { FilteringSearchBar } from './filtering-search-bar';
import { FilteringForm } from './filtering-form';
import { TrackingRecordFiltering } from './types';

type Props = {
  searchValue: string | undefined;
  filterValues: Omit<TrackingRecordFiltering, 'id'>;
  onSearch: (newSearchValue: TrackingRecordFiltering['id']) => void;
  onFilter: (newFilterValues: Omit<TrackingRecordFiltering, 'id'>) => void;
  onClearFilters: () => void;
};

export const TrackingNumbersFiltering: React.FC<Props> = ({
  searchValue,
  filterValues,
  onSearch,
  onFilter,
  onClearFilters,
}) => {
  return (
    <div className={styles.wrapper}>
      <FilteringSearchBar searchValue={searchValue} onSearch={onSearch} />
      <FilteringForm
        filterValues={filterValues}
        onFilter={onFilter}
        onClearFilters={onClearFilters}
      />
    </div>
  );
};

const styles = {
  wrapper: tss({
    display: 'flex',
    alignItems: 'center',
    width: '80%',
    paddingBottom: '24px',
  }),
};
