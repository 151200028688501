import * as React from 'react';
import { stylesheet } from 'typestyle';

import { LABELS } from '@src/dictionaries';
import { FormattedMessage } from '@src/i18n';
import { CategoryLabelModel } from '@src/models';
import { configActions } from '@src/modules/config';
import themeDefault from '@src/styles/theme.default';

import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { Popconfirm } from 'antd';

type Props = {
  categoryId: string;
  label: CategoryLabelModel;
};

export const DeleteLabelPopover: React.FC<Props> = ({ categoryId, label: { id, name } }) => {
  return (
    <Popconfirm
      title={
        <FormattedMessage
          id="ARE_YOU_SURE_YOU_WANT_TO_DELETE_LABEL_NAMED"
          values={{ label: name }}
        />
      }
      okText={LABELS.YES}
      cancelText={LABELS.NO}
      onConfirm={() => {
        dispatchOnDraftSiteId(configActions.deleteCategoryLabelRequest)({
          model: { categoryId, labelId: id },
        });
      }}
    >
      <FormattedMessage id="DELETE" className={styles.deleteButton} />
    </Popconfirm>
  );
};

const styles = stylesheet({
  deleteButton: {
    color: themeDefault.color.primary,
    cursor: 'pointer',
  },
});
