import { Badge } from 'antd';
import { BadgeProps } from 'antd/lib/badge';
import * as React from 'react';

import { LABELS } from '../dictionaries';

class Component extends React.Component<BadgeProps, {}> {
  static Empty = () => <div>{LABELS.EMPTY}</div>;
  static Complete = () => <Component status="success" text={LABELS.COMPLETE} />;
  static Incomplete = () => <Component status="error" text={LABELS.INCOMPLETE} />;
  static Enabled = () => <Component status="success" text={LABELS.ENABLED} />;
  static Disabled = () => <Component status="error" text={LABELS.DISABLED} />;

  render() {
    const { children, ...restProps } = this.props;

    return <Badge {...restProps}>{children}</Badge>;
  }
}

export default Component;
