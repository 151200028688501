import * as React from 'react';

import { Tag } from '@src/controls';
import { defaultTheme } from '@src/styles';

interface IProps {
  source: string | undefined;
}

const SOURCE_COLOR = '#7B61FF';

const getSourceLabel = (source: string | undefined) => {
  if (!source) {
    return { text: 'Missing', color: defaultTheme.color.primaryLight };
  }
  switch (source.toUpperCase()) {
    case 'COS':
      return { text: 'Checkout', color: SOURCE_COLOR };
    case 'SOM':
      return { text: 'TA', color: SOURCE_COLOR };
    case 'TRACKING':
      return { text: 'Tracking', color: SOURCE_COLOR };
    default:
      return { text: source, color: SOURCE_COLOR };
  }
};

const TransportOrderSourceTag: React.FC<IProps> = ({ source }) => {
  const { color, text } = getSourceLabel(source);

  return <Tag style={{ color, borderColor: color, backgroundColor: 'transparent' }}>{text}</Tag>;
};

export { TransportOrderSourceTag };
