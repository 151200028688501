import * as qs from 'query-string';

import {
  ShipmentNotificationDTO,
  ShipmentNotificationModel,
  TriggerCreateDTO,
  TriggerCreateModel,
  TriggerListItemDTO,
  TriggerListItemModel,
} from '@src/models';
import { apiUtils, authFetch } from '@src/utils';

const SHIPMENT_NOTIFICATIONS_URL = apiUtils.getApiUrl() + '/frontend/shipment-notifications';

export function listShipmentNotifications({
  siteId,
  userId,
}: {
  siteId: string;
  userId: string;
}): Promise<ShipmentNotificationModel[]> {
  const options: RequestInit = {
    method: 'GET',
    headers: { 'x-site-id': siteId },
  };

  return authFetch<{ notifications: ShipmentNotificationDTO[] }>(
    `${SHIPMENT_NOTIFICATIONS_URL}/notifications.list?${qs.stringify({
      site_id: siteId,
      user_id: userId,
    })}`,
    options
  ).then(res =>
    res.notifications
      ? res.notifications.map(notification => ShipmentNotificationModel.createFromDTO(notification))
      : []
  );
}

export function markShipmentNotificationsAsRead({
  siteId,
  userId,
  ids,
}: {
  siteId: string;
  userId: string;
  ids: string[];
}) {
  const options: RequestInit = {
    method: 'POST',
    headers: { 'x-site-id': siteId },
    body: JSON.stringify({ siteId, userId, notification_ids: ids }),
  };

  return authFetch(`${SHIPMENT_NOTIFICATIONS_URL}/notifications.mark_as_read`, options);
}

export const createTrigger = async (triggerModel: TriggerCreateModel) => {
  const payload = TriggerCreateDTO.createFromModel(triggerModel);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': triggerModel.siteId,
    },
    body: JSON.stringify({ ...payload }),
  };

  return authFetch<{}>(`${SHIPMENT_NOTIFICATIONS_URL}/triggers.create`, options);
};

export const getNotificationTriggersList = async ({
  siteId,
  userId,
}: {
  siteId: string;
  userId: string;
}): Promise<TriggerListItemModel[]> => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<{ triggers?: TriggerListItemDTO[] }>(
    `${SHIPMENT_NOTIFICATIONS_URL}/triggers.list?` +
      qs.stringify({ user_id: userId, site_id: siteId }),
    options
  ).then(res =>
    res.triggers ? res.triggers.map(trigger => TriggerListItemModel.createFromDTO(trigger)) : []
  );
};

export const deleteNotificationTrigger = async ({
  siteId,
  userId,
  triggerId,
}: {
  siteId: string;
  userId: string;
  triggerId: string;
}) => {
  const options: RequestInit = {
    method: 'DELETE',
    headers: { 'x-site-id': siteId },
  };

  return authFetch(
    `${SHIPMENT_NOTIFICATIONS_URL}/triggers.delete?${qs.stringify({
      site_id: siteId,
      user_id: userId,
      trigger_id: triggerId,
    })}`,
    options
  );
};
