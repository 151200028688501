import { InfoCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Col, Row, Tooltip } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { CheckoutWidgetDrawer } from '@src/components';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { FormattedMessage } from '@src/i18n';
import { WidgetConfigurationModel } from '@src/models';
import { configActions, configSelectors } from '@src/modules/config';
import { siwActions } from '@src/modules/siw';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { useDispatch, useSelector } from '@src/utils/hooks';

import { Link } from '@src/components/link';
import { FeaturesTable, HexColorInput } from './components/';

export const DefaultSettings: React.FC<{}> = () => {
  const { features: defaultSettings = {}, style: widgetStyle = {} } = useSelector(
    configSelectors.getWidgetConfigurationByDraftOrCurrentSiteId
  );

  const isCreatingDraft = useSelector(state => state.config.isCreatingDraft);

  const dispatch = useDispatch();
  const updateWidgetConfiguration = (model: Partial<WidgetConfigurationModel>) => {
    dispatchOnDraftSiteId(configActions.updateWidgetConfigurationRequest)({
      model,
      onComplete: () => dispatch(siwActions.resetWidgetSession()),
    });
  };

  return (
    <>
      <Row className={styles.row}>
        <Col className={styles.fieldLabel} span={4}>
          <FormattedMessage id="WIDGET_ACCENT_COLOR" />
          <Tooltip title={<FormattedMessage id="ACCENT_COLOR_TOOLTIP" />}>
            <InfoCircleOutlined
              style={{
                marginBottom: 'auto',
                fontSize: '12px',
                marginLeft: '3px',
                marginTop: '6px',
              }}
            />
          </Tooltip>
        </Col>
        <Col span={20}>
          <HexColorInput
            value={widgetStyle.accentColor1 ?? ''}
            onSave={value => {
              updateWidgetConfiguration({ style: { accentColor1: value } });
            }}
            disabled={isCreatingDraft}
          />
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col className={styles.fieldLabel} span={4}>
          <FormattedMessage id="WIDGET_EFFECTS_COLOR" />
          <Tooltip title={<FormattedMessage id="EFFECTS_COLOR_TOOLTIP" />}>
            <InfoCircleOutlined
              style={{
                marginBottom: 'auto',
                fontSize: '12px',
                marginLeft: '3px',
                marginTop: '6px',
              }}
            />
          </Tooltip>
        </Col>
        <Col span={20}>
          <HexColorInput
            value={widgetStyle.accentColor2 ?? ''}
            onSave={value => {
              updateWidgetConfiguration({ style: { accentColor2: value } });
            }}
            disabled={isCreatingDraft}
          />
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col className={styles.fieldLabel} span={4}>
          <FormattedMessage id="UPSELL_COLOR" />
          <Tooltip title={<FormattedMessage id="UPSELL_COLOR_TOOLTIP" />}>
            <InfoCircleOutlined
              style={{
                marginBottom: 'auto',
                fontSize: '12px',
                marginLeft: '3px',
                marginTop: '6px',
              }}
            />
          </Tooltip>
        </Col>
        <Col span={20}>
          <HexColorInput
            value={widgetStyle.upsellColor ?? ''}
            onSave={value => {
              updateWidgetConfiguration({ style: { upsellColor: value } });
            }}
            disabled={isCreatingDraft}
          />
        </Col>
      </Row>
      <Row className={styles.row}>
        <Col className={styles.tableLabel} span={4}>
          <FormattedMessage id="FEATURES" />
        </Col>
        <Col span={20}>
          <FeaturesTable
            isLoading={isCreatingDraft}
            features={defaultSettings}
            featuresHeaderName={'Default'}
            onFeatureChange={features => {
              updateWidgetConfiguration({ features });
            }}
          />
        </Col>
      </Row>
    </>
  );
};

const BreadcrumbSection: React.FunctionComponent<{}> = () => (
  <Breadcrumb>
    <Breadcrumb.Item>
      <Link route={{ name: 'COUNTRY_LIST' }}>
        <FormattedMessage id="FEATURES" className={styles.breadcrumbLink} />
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <FormattedMessage id="DEFAULT_SETTINGS" />
    </Breadcrumb.Item>
  </Breadcrumb>
);

const Header: React.FunctionComponent<{}> = () => (
  <h2 className={styles.header}>
    <FormattedMessage id="DEFAULT_SETTINGS" />
  </h2>
);

export const DefaultSettingsPage: React.FC<{}> = () => {
  return (
    <>
      <StatusBarContainer />
      <BreadcrumbSection />
      <Header />
      <DefaultSettings />
      <CheckoutWidgetDrawer />
    </>
  );
};

const styles = stylesheet({
  breadcrumbLink: {
    cursor: 'pointer',
  },
  header: {
    margin: '24px 0',
  },
  row: {
    paddingBottom: '22px',
  },
  fieldLabel: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  tableLabel: {
    display: 'flex',
    alignItems: 'center',
    height: '54px',
  },
});
