import { services } from '@src/services';
import { listSuggestedCarrierProductMappings } from '@src/services/selfcare-service';
import { concat } from 'ramda';
import { useMutation, useQuery } from 'react-query';

export const useSuggestedCarrierProductMappingsQuery = (siteId: string) =>
  useQuery(
    ['suggested_carrier_product_mappings.list', siteId],
    () => listSuggestedCarrierProductMappings(siteId),
    {
      enabled: !!siteId,
      keepPreviousData: true,
      select: data =>
        concat(
          data.unmapped_carrier_product_refs ?? [],
          data.automapped_carrier_product_refs ?? []
        ),
    }
  );

export const useTrackingConfigStatusMutation = (siteId: string) => {
  const { mutateAsync: getTrackingConfigStatus, isLoading: isLoadingTrackingConfigStatus } =
    useMutation({
      mutationFn: async (carrierProductId: string) => {
        const data = await services.selfCareService.getTrackingConfigStatus(
          siteId,
          carrierProductId
        );
        return data.tracking_configuration_status.status;
      },
    });

  return {
    getTrackingConfigStatus,
    isLoadingTrackingConfigStatus,
  };
};
