import * as qs from 'query-string';

import { GentokenDTO } from '@src/models';
import { apiUtils, authFetch, stringUtils } from '@src/utils';

const API_URL = apiUtils.getApiUrl() + '/gentoken';

export async function getLabelToken(
  siteId: string,
  url: string,
  format: '.pdf' | '.png' | '.svg' | '.zpl'
): Promise<GentokenDTO> {
  const options: RequestInit = {
    headers: {
      'x-site-id': siteId,
    },
  };

  const fileName = `${stringUtils.getFileStem(url)}${format}`;

  return authFetch<GentokenDTO>(
    `${API_URL}/labels?${qs.stringify({ siteId, file_name: fileName })}`,
    options
  );
}

export async function getBlobToken(siteId: string, url: string): Promise<GentokenDTO> {
  const fileName = stringUtils.getURLParameter(url, 'file');

  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({
      site_id: siteId,
      file_name: fileName,
    }),
  };

  return authFetch<GentokenDTO>(`${API_URL}/blob`, options);
}
