import * as React from 'react';
import { useMutation } from 'react-query';
import { connect } from 'react-redux';
import { stylesheet } from 'typestyle';
import { message } from 'antd';

import { AddButton } from '@src/components';
import { SmartPopover } from '@src/controls';
import { services } from '@src/services';
import { RootState } from '@src/modules';
import { configSelectors } from '@src/modules/config';
import { SitesSelectors } from '@src/modules/sites';
import { FormattedMessage } from '@src/i18n';

import { useExperimentQuery } from './use-experiment-query';
import { VariantDetails } from './variant-details';
import { VariantList } from './variant-list';
import { VariantForm } from './variant-form';
import { VariantChangesModal } from './variant-changes-modal';
import { VariantEditModal } from './variant-modal';
import { VariantWidgetDrawer } from './variant-widget-drawer';
import { CreateVariantModel, UpdateVariantModel, VariantModel, UpdateExperimentModel, ExperimentWithVariantModel } from '@src/models';

const mapStateToProps = (state: RootState) => ({
  merchantId: state.merchants.selectedMerchantId,
  siteId: SitesSelectors.getSelectedSiteIdOrEmpty(state),
  variantSiteId: configSelectors.getVariantSiteIdOrEmpty(state),
});

interface Props {
  merchantId: string;
  siteId: string;
  variantSiteId: string;
  experimentId: string;
  experimentData: UpdateExperimentModel & ExperimentWithVariantModel;
}

const Component: React.FC<Props> = ({ merchantId, siteId, variantSiteId, experimentId, experimentData }) => {
  const [query, invalidateQuery] = useExperimentQuery(siteId, experimentId);

  const createMutation = useMutation(
    (values: CreateVariantModel) => {
      return services.experimentService.createVariant(experimentId, values);
    },
    {
      onSuccess: invalidateQuery,
    }
  );

  const updateMutation = useMutation(
    (values: UpdateVariantModel) => {
      return services.experimentService.updateVariant(values);
    },
    {
      onSuccess: invalidateQuery,
      onError: () => services.notificationService.error('Failed to update variant'),
    }
  );

  const deleteMutation = useMutation(
    (values: VariantModel) => {
      return services.experimentService.deleteVariant(siteId, experimentId, values.id);
    },
    {
      onSuccess: invalidateQuery,
    }
  );

  const [variantInModal, setVariantInModal] = React.useState<VariantModel | undefined>(undefined);
  const [variantInDrawer, setVariantInDrawer] = React.useState<VariantModel | undefined>(undefined);
  const [variantForChanges, setVariantForChanges] = React.useState<VariantModel | undefined>(
    undefined
  );

  if (!query.data) {
    return null;
  }

  const isLoading = createMutation.isLoading || deleteMutation.isLoading;

  const canEdit = query.data.status === 'CREATED';

  let addButton = null;

  if (canEdit) {
    if (query.data.variants.length < 10) {
      addButton = (
        <SmartPopover
          render={onClose => (
            <VariantForm
              siteId={siteId}
              onSubmit={(values: CreateVariantModel) => {
                createMutation.mutate(values);
                onClose();
              }}
            />
          )}
        >
          <AddButton text={<FormattedMessage id="ADD_NEW" />} className={styles.addButton} />
        </SmartPopover>
      );
    } else {
      addButton = (
        <AddButton
          text={<FormattedMessage id="ADD_NEW" />}
          className={styles.addButton}
          onClick={() => message.warning('You can create only 10 variants per experiment.')}
        />
      );
    }
  }

  return (
    <>
      <VariantList
        data={query.data.variants}
        loading={isLoading}
        renderDetails={(data: VariantModel, idx: number) => (
          <VariantDetails
            key={query.dataUpdatedAt}
            disabled={!canEdit}
            data={data}
            original={idx === 0}
            siteId={siteId}
            experimentId={experimentId}
            externalTrafficBalancingEnabled={experimentData.externalTrafficBalancingEnabled}
            onSubmit={updateMutation.mutate}
            onShowChanges={(values: VariantModel) => {
              setVariantForChanges(values);
            }}
          />
        )}
        disableEdit={!canEdit}
        onDelete={deleteMutation.mutate}
        onEdit={(values: VariantModel) => {
          setVariantInModal(values);
        }}
        onShowCart={(values: VariantModel) => {
          setVariantInDrawer(values);
        }}
      />
      {addButton}
      <VariantEditModal
        experimentId={experimentId}
        variant={variantInModal}
        onClose={() => {
          setVariantInModal(undefined);
          // Reload experiment because we might have changed sth.
          invalidateQuery();
        }}
      />
      <VariantChangesModal
        variant={variantForChanges}
        onClose={() => setVariantForChanges(undefined)}
      />
      <VariantWidgetDrawer
        variant={variantInDrawer}
        onClose={() => setVariantInDrawer(undefined)}
      />
    </>
  );
};

const styles = stylesheet({
  addButton: {
    display: 'inline-block',
  },
});

export const VariantListContainer = connect(mapStateToProps)(Component);
