// tslint:disable:jsx-no-multiline-js
import { Alert } from 'antd';
import * as React from 'react';

type Props = {
  visible: boolean;
  error: string | null | undefined;
  banner?: boolean;
  showIcon?: boolean;
  closeText?: string;
  onClose?: () => {};
};

function Component(props: Props) {
  const { visible, error, banner, showIcon, closeText } = props;

  return visible && error ? (
    <Alert
      type="error"
      closable={true}
      message={error.toString()}
      banner={banner}
      showIcon={showIcon || false}
      closeText={closeText}
    />
  ) : (
    <div />
  );
}

export default Component;
