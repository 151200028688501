import { Layout } from 'antd';
import * as React from 'react';
import { style as tss } from 'typestyle';
const { Content, Header, Sider } = Layout;

import { UserCard } from '@src/components';
import MainMenuContainer from '@src/containers/main-menu/main-menu-container';
import { ShipmentReports } from '@src/containers/shipment-reports';
import { ShipmentNotificationsContainer } from '@src/containers/shipments-notifications';
import { defaultTheme } from '@src/styles';

const classStyles = {
  layout: tss({
    minWidth: '1080px',
    height: '100vh',
    $nest: {
      '& .ant-layout-header': {
        lineHeight: 'normal',
      },
    },
  }),
  header: tss({
    paddingLeft: 0,
    display: 'flex',
    alignItems: 'center',
  }),
  sider: tss({
    $nest: {
      '& .ant-menu-inline': {
        borderRight: 'unset !important',
      },
      '& .ant-layout-sider-children': {
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
    backgroundColor: defaultTheme.color.neutralDark,
    height: `calc(100vh - ${defaultTheme.headerHeight})`,
  }),
  content: tss({
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${defaultTheme.headerHeight})`,
  }),
};

const BaseLayout = ({
  showNotifications,
  headerChild,
  contentChild,
}: {
  showNotifications: boolean;
  headerChild: JSX.Element;
  contentChild: JSX.Element;
}) => {
  return (
    <Layout className={classStyles.layout}>
      <Header className={classStyles.header}>
        <>
          {headerChild}
          {showNotifications && <ShipmentNotificationsContainer />}
          <ShipmentReports />
        </>
      </Header>

      <Layout>
        <Sider width={240} className={classStyles.sider}>
          <MainMenuContainer />
          <UserCard />
        </Sider>

        <Content className={classStyles.content}>{contentChild}</Content>
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
