import { Input as AntdInput } from 'antd';
import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { Input, Modal } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { LABELS, MESSAGES } from '@src/dictionaries';
import { AutoSaveFormikEnhancer, noLabelLayout } from '@src/forms';
import { getFormikError } from '@src/utils/forms';
import { nameValidation } from '.';

const InputField = withFormItem(Input, noLabelLayout);

interface Props {
  name: string;
  forbidenNames?: string[];
  handleChangeName: (name: string) => void;
}

export const RenameForm: React.FunctionComponent<Props> = ({
  name,
  forbidenNames = [],
  handleChangeName,
}) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const ref = React.createRef<AntdInput>();

  return (
    <Formik
      initialValues={{ name }}
      onSubmit={values => {
        if (values.name !== name) {
          setIsModalVisible(true);
        }
      }}
      validationSchema={nameValidation(forbidenNames)}
    >
      {({ touched, errors, resetForm, values, submitForm }) => (
        <>
          <AutoSaveFormikEnhancer
            name="name"
            onSave={submitForm}
            render={() => (
              <Field name="name">
                {({ field }: FieldProps<string>) => (
                  <InputField
                    {...field}
                    onBlur={() => {
                      if (field.value !== name) {
                        submitForm();
                      }
                    }}
                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                      if (event.key === 'Enter' && field.value !== name) {
                        submitForm();
                        ref.current?.blur();
                      }
                    }}
                    error={getFormikError(touched.name, errors.name)}
                    forwardedInputRef={ref}
                    className={style.formItem}
                  />
                )}
              </Field>
            )}
          />
          <Modal
            title={LABELS.EDIT_NAME}
            okText={LABELS.OK}
            cancelText={LABELS.CANCEL}
            visible={isModalVisible}
            onOk={() => {
              setIsModalVisible(false);
              handleChangeName(values.name.trim());
            }}
            onCancel={() => {
              setIsModalVisible(false);
              resetForm();
            }}
            width={480}
          >
            {MESSAGES.ARE_YOU_SURE_YOU_WANT_TO_EDIT_NAME}
          </Modal>
        </>
      )}
    </Formik>
  );
};

const style = stylesheet({
  formItem: {
    $nest: {
      '.ant-form-item': {
        margin: 0,
        width: '90%',
      },
    },
  },
});
