import { Select } from 'antd';
import { FieldState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';

type BaseProps = {
  value?: typeof Select.prototype.props.value;
  onSelect?: typeof Select.prototype.props.onSelect;
  onDeselect?: typeof Select.prototype.props.onDeselect;
  mode?: typeof Select.prototype.props.mode;
};

type HOCProps = {
  fieldState?: FieldState<any>;
};

export function withFieldStateSelect<WrappedComponentProps extends BaseProps>(
  WrappedComponent:
    | React.StatelessComponent<WrappedComponentProps>
    | React.ComponentClass<WrappedComponentProps>
) {
  const HOC: React.StatelessComponent<HOCProps & WrappedComponentProps> = props => {
    const { fieldState, ...passThroughProps } = props as HOCProps;

    const overriddenBaseProps: BaseProps = {};
    if (fieldState) {
      overriddenBaseProps.value =
        props.mode === 'multiple'
          ? [...fieldState.value]
          : typeof fieldState.value === 'string'
          ? fieldState.value
          : fieldState.value[0];

      overriddenBaseProps.onSelect = (value: any, option: object) => {
        if (props.mode === 'multiple') {
          return fieldState.onChange([...fieldState.value, value]);
        }
        return fieldState.onChange(value);
      };

      overriddenBaseProps.onDeselect = (value: any) => {
        const valueIndex =
          fieldState.value && fieldState.value.findIndex((item: string) => item === value);
        return fieldState.value.splice(valueIndex, 1);
      };
    }

    return <WrappedComponent {...(passThroughProps as any)} {...overriddenBaseProps} />;
  };

  return observer(HOC);
}
