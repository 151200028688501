import 'whatwg-fetch';
import { loadState } from '../services/local-storage-service';

export type ApiFetch = (url: string, options: RequestInit) => Promise<Response>;
export type AuthFetch = <T>(url: string, options: RequestInit) => Promise<T>;

export class MADError extends Error {
  public code?: string;
  public traceId?: string;
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, MADError.prototype);
  }
}

export function apiFetch(url: string, options: RequestInit = {}): Promise<Response> {
  return fetch(url, options).then(_checkStatus); // to raise errors for wrong status
}

/*
 * performs api calls sending the required authentication headers
 */
export function authFetch<T>(
  url: string,
  options: RequestInit = {},
  isJsonReq: boolean = true
): Promise<T> {
  // extend headers
  const extendedHeaders: { [key: string]: any } = {
    Accept: 'application/json',
    ...options.headers,
  };

  if (isJsonReq) {
    extendedHeaders['Content-Type'] = 'application/json';
  }

  // loading auth headers
  const state = loadState();

  if (!extendedHeaders.Authorization) {
    if (!!state && !!state.auth.authToken) {
      extendedHeaders.Authorization = `Bearer ${state.auth.authToken}`;
    }
  }

  options.headers = extendedHeaders;

  return fetch(url, options)
    .then(_checkContent) // to raise errors for content not JSON
    .then(_checkStatus) // to raise errors for wrong status
    .then(res => res.json()); // to parse the response as json
}

async function _checkStatus(res: Response) {
  // raises an error in case response status is not a success
  if (res.status >= 200 && res.status < 300) {
    return res;
  } else {
    const err = new MADError();
    err.message = res.statusText;

    // check error descriptions from service response
    try {
      const data = await res.json();
      // check error desc from auth0 service
      if (data.error_description != null) {
        err.message = data.error_description;
      } else if (data.message != null) {
        err.message = data.message;
      } else if (data.error != null) {
        err.message = data.error;
      }

      if (data.trace_id) {
        err.traceId = data.trace_id;
      }

      if (data.code) {
        err.code = data.code;
      }
    } catch (error) {
      err.message = `JSON parsing failed when checking response status`;
    }

    throw err;
  }
}

function _checkContent(res: Response) {
  const contentType = res.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return res;
  } else {
    throw new Error('Response content-type is not JSON.');
  }
}
