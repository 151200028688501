import { createSelector } from 'reselect';
import lift from 'space-lift';
import { RootState } from '../root-reducer';

import { ConfigMerchantModel, ConfigMerchantSummaryModel } from '../../models';

const getMerchantsState = (state: RootState) => state.merchants;

export const getMerchants = createSelector(getMerchantsState, merchants => merchants.merchants);

export const getSelectedMerchantId = createSelector(
  getMerchantsState,
  merchants => merchants.selectedMerchantId || ''
);

export const getSelectedMerchantName = createSelector(
  getMerchants,
  getSelectedMerchantId,
  (merchants, merchantId) =>
    lift(merchants)
      .find(merchant => merchant.id === merchantId)
      .getOrElse(new ConfigMerchantModel()).name
);

export const getMerchantsOptions = createSelector(getMerchants, merchants =>
  merchants
    .filter(merchant => merchant.sites.length)
    .sort((a, b) => new Intl.Collator('en').compare(a.name, b.name))
    .map(merchant => ({ value: merchant.id, label: merchant.name }))
);

export const getMerchantsWithSitesWithoutDrafts = createSelector(getMerchants, merchants =>
  merchants.map(
    merchant =>
      ({
        id: merchant.id,
        name: merchant.name,
        sites: merchant.sites.filter(site => !site.isDraft),
      } as ConfigMerchantSummaryModel)
  )
);

export const getError = createSelector(getMerchantsState, state => state.error);

export const getIsFetching = createSelector(getMerchantsState, state => state.isFetching);

const getCurrentlySelectedMerchantId = createSelector(
  getMerchantsState,
  getSelectedMerchantId,
  ({ currentlySelectedMerchantId }, selectedMerchantId) =>
    currentlySelectedMerchantId || selectedMerchantId
);

export const getSitesOptionsWithNoDrafts = createSelector(
  getMerchantsWithSitesWithoutDrafts,
  getCurrentlySelectedMerchantId,
  (merchants, merchantId) =>
    merchants
      .find(merchant => merchant.id === merchantId)
      ?.sites.sort((a, b) => new Intl.Collator('en').compare(a.name, b.name))
      .map(site => ({ value: site.id, label: site.name })) ?? []
);
