import { UserOutlined } from '@ant-design/icons';
import { authSelectors } from '@src/modules/auth';
import { routerActions } from '@src/modules/router';
import { usersSelectors } from '@src/modules/users';
import { useDispatch } from '@src/utils/hooks';
import * as React from 'react';
import { EnterpriseForm, PasswordEmailForm } from '.';
import { ContainerContent, ContainerFixedHeader, ErrorAlert } from '../../../components';
import { Card, Select } from '../../../controls';
import { withFormItem } from '../../../decorators';
import { LABELS } from '../../../dictionaries';
import { formItemLayout } from '../../../forms/form-layouts';
import { AuthenticationType, UserCreateModel, UserGetModel } from '../../../models';

const SelectField = withFormItem(Select);

interface Props {
  groupMembershipOptions: ReturnType<typeof usersSelectors.getGroupMembershipOptions>;
  title: string;
  isLoading: boolean;
  siteTagsOptions: { value: string; label: string }[];
  onSubmit: (user: UserCreateModel, groupIds: string[], tagsIds: string[]) => void;
  mode: 'create' | 'edit';
  userToEdit?: UserGetModel;
  userToEditGroups?: ReturnType<typeof usersSelectors.getUserGroups>;
  userTagsIds?: string[];
  error: string | null;
  accountTypes: ReturnType<typeof authSelectors.getAccountTypes>;
  groupsIdsWithAccessToWatchlists: ReturnType<
    typeof usersSelectors.getGroupsIdsThatHaveWatchlistRole
  >;
}

export const UserForm: React.FunctionComponent<Props> = ({
  title,
  isLoading,
  groupMembershipOptions,
  siteTagsOptions,
  onSubmit,
  mode,
  userToEdit,
  userTagsIds,
  error,
  accountTypes,
  userToEditGroups,
  groupsIdsWithAccessToWatchlists,
}) => {
  const dispatch = useDispatch();

  const getDefaultAuthenticationType = () => {
    if (userToEdit && userToEdit.authenticationType) {
      return userToEdit.authenticationType;
    } else if (accountTypes.length === 1) {
      return AuthenticationType.OTHER;
    } else {
      return AuthenticationType.PASSWORD;
    }
  };
  const [authenticationType, setAuthenticationType] = React.useState(
    getDefaultAuthenticationType()
  );

  const handleCancel = () => dispatch(routerActions.push({ name: 'USERS' }));

  return (
    <>
      <ContainerFixedHeader
        title={title}
        IconComponent={UserOutlined}
        onBack={handleCancel}
        onBackLabel={LABELS.CANCEL}
        isLoading={isLoading}
        hideMainButton={true}
      />
      <ContainerContent>
        <Card loading={isLoading}>
          <ErrorAlert error={error} visible={!!error} />
          <SelectField
            label="Account Type"
            required={true}
            style={{ width: '200px' }}
            options={accountTypes}
            {...formItemLayout}
            value={authenticationType}
            onChange={value => setAuthenticationType(value as AuthenticationType)}
          />
          {authenticationType === AuthenticationType.PASSWORD ? (
            <PasswordEmailForm
              groupMembershipOptions={groupMembershipOptions}
              siteTagsOptions={siteTagsOptions}
              onSubmit={onSubmit}
              mode={mode}
              userToEdit={userToEdit}
              userToEditGroups={userToEditGroups}
              userTagsIds={userTagsIds}
              isLoading={isLoading}
              groupsIdsWithAccessToWatchlists={groupsIdsWithAccessToWatchlists}
            />
          ) : (
            <EnterpriseForm
              groupMembershipOptions={groupMembershipOptions}
              siteTagsOptions={siteTagsOptions}
              onSubmit={onSubmit}
              userToEdit={userToEdit}
              userToEditGroups={userToEditGroups}
              userTagsIds={userTagsIds}
              isLoading={isLoading}
              groupsIdsWithAccessToWatchlists={groupsIdsWithAccessToWatchlists}
            />
          )}
        </Card>
      </ContainerContent>
    </>
  );
};
