import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';

import { Reducer } from '..';
import { ConfigMerchantSummaryModel } from '../../models';
import { extractParam } from '../../utils/url';
import * as appActions from '../app/app-actions';
import { promoteDraftSuccess } from '../config/actions';
import {
  changeActiveMerchant,
  getMerchantSuccess,
  listMerchants,
  listMerchantsError,
  listMerchantsSuccess,
} from './merchants-actions';

export type MerchantsState = Readonly<{
  isFetching: boolean;
  error: string | null;
  merchants: ConfigMerchantSummaryModel[];
  selectedMerchantId: string | null;
  currentlySelectedMerchantId: string | null;
}>;

export const initialState: MerchantsState = {
  isFetching: false,
  error: null,
  merchants: [],
  selectedMerchantId: null,
  currentlySelectedMerchantId: null,
};

const isFetching: Reducer<MerchantsState['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(listMerchants):
      return true;

    case getType(listMerchantsSuccess):
    case getType(listMerchantsError):
      return false;

    default:
      return state;
  }
};

const error: Reducer<MerchantsState['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(listMerchantsError):
      return action.payload;

    case getType(listMerchants):
    case getType(appActions.resetErrors):
      return null;

    default:
      return state;
  }
};

const merchants: Reducer<MerchantsState['merchants']> = (state = [], action) => {
  switch (action.type) {
    case getType(promoteDraftSuccess):
      return state.map(merchant => ({
        ...merchant,
        sites: merchant.sites.filter(site => site.id !== action.payload),
      }));

    case getType(listMerchantsSuccess):
      return action.payload;
    case getType(getMerchantSuccess):
      const newState = state;
      const fetchedMerchantIndex = newState.findIndex(
        merchant => merchant.id === action.payload.id
      );
      if (fetchedMerchantIndex >= 0) {
        newState[fetchedMerchantIndex] = action.payload;
      } else {
        newState.push(action.payload);
      }
      return newState;

    default:
      return state;
  }
};

const selectedMerchantId: Reducer<MerchantsState['selectedMerchantId']> = (
  state = null,
  action
) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      if (action.payload && action.payload.location.pathname) {
        const newState = extractParam(action.payload.location.pathname, 'merchants');
        if (newState !== state && newState !== ':merchantId') {
          return newState;
        }
      }
      return state;

    default:
      return state;
  }
};

const currentlySelectedMerchantId: Reducer<MerchantsState['currentlySelectedMerchantId']> = (
  state = null,
  action
) => {
  switch (action.type) {
    case getType(changeActiveMerchant):
      return action.payload;

    default:
      return state;
  }
};

// REDUCER
const reducer: Reducer<MerchantsState> = combineReducers({
  isFetching,
  error,
  merchants,
  selectedMerchantId,
  currentlySelectedMerchantId,
});

export default reducer;
