import * as React from 'react';

import { Column, Input, Text } from '../../../controls';
import { withFormItem } from '../../../decorators';

const TextField = withFormItem(Text);

interface IProps {
  metadata: { [key: string]: string } | undefined;
}

class MetadataFormBase extends React.Component<IProps, {}> {
  renderItem([key, value]: [string, string]) {
    if (!key) {
      return null;
    }

    return (
      <TextField key={key} label={key}>
        <Input readOnly={true} value={value} />
      </TextField>
    );
  }

  render() {
    const { metadata } = this.props;
    if (metadata == null) {
      return null;
    }

    const items = Object.entries(metadata).map(i => this.renderItem(i));

    if (items.length === 0) {
      return null;
    }

    return (
      <Column justifyContent={'flex-end'} style={{ width: '50%' }}>
        {items}
      </Column>
    );
  }
}

import { RootState } from '@src/modules';
import { connect } from 'react-redux';
const mapStateToProps = (state: RootState) => ({});

export const MetadataForm = connect(mapStateToProps, {})(MetadataFormBase);
