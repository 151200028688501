import { FieldState, FormState } from 'formstate';
import * as React from 'react';

import { TextArea } from '../../../controls';
import { withFieldStateInput, withFormItem } from '../../../decorators';
import { TransportOrderModel } from '../../../models';

const TextAreaField = withFormItem(withFieldStateInput(TextArea));

type IFormState = FormState<{
  descriptionFieldState: FieldState<string>;
}>;

interface IProps {
  formState: IFormState;
}

export class NotesForm extends React.Component<IProps, {}> {
  static createFormState = (model: TransportOrderModel = new TransportOrderModel()): IFormState =>
    new FormState({
      descriptionFieldState: new FieldState(model.notes.description).validators(),
    });

  render() {
    const { formState } = this.props;

    return (
      <TextAreaField
        label="Description"
        autosize={{ minRows: 3, maxRows: 10 }}
        fieldState={formState.$.descriptionFieldState}
        error={formState.$.descriptionFieldState.error}
      />
    );
  }
}
