import { Popconfirm, Table } from 'antd';
import * as React from 'react';

import { Link } from '@src/components/link';
import { Button } from '@src/controls';
import { LABELS } from '@src/dictionaries';
import { RBACGroupModel } from '@src/models';
import { intersperse } from 'ramda';
import { stylesheet } from 'typestyle';
import { UsersItem } from '.';

export type UsersTableInfo = {
  id: string;
  email?: string;
  name?: string;
  picture?: string;
  groups?: RBACGroupModel[];
  merchantName: string;
  externalId?: string;
};

interface Props {
  onRecordDelete: (userId: string) => void;
  usersTableItems: UsersTableInfo[];
}

export class UsersTable extends React.Component<Props> {
  handleRecordDelete = (recordId: string) => this.props.onRecordDelete(recordId);

  render() {
    const { usersTableItems } = this.props;
    return (
      <Table<UsersTableInfo> dataSource={usersTableItems} rowKey={record => record.id}>
        <Table.Column<UsersTableInfo>
          title="Name"
          key="user"
          render={record => <UsersItem data={record} />}
        />
        <Table.Column<UsersTableInfo>
          title="Groups"
          key="groups"
          render={(text, record) =>
            intersperse(', ', record.groups?.map(group => group.name) || [])
          }
        />
        <Table.Column<UsersTableInfo>
          title="Action"
          key="action"
          render={(_, record) => (
            <>
              <Popconfirm
                title={LABELS.CONFIRMATION_HEADER}
                okText={LABELS.OK}
                cancelText={LABELS.NO}
                onConfirm={() => this.props.onRecordDelete(record.id)}
              >
                <Button className={styles.button} type="link">
                  {LABELS.DELETE}
                </Button>
              </Popconfirm>
              |
              <Link route={{ name: 'USERS_EDIT', userId: record.id }}>
                <Button className={styles.button} type="link">
                  {LABELS.EDIT}
                </Button>
              </Link>
            </>
          )}
        />
      </Table>
    );
  }
}

const styles = stylesheet({
  button: {
    padding: '2px',
  },
});
