import * as React from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';

import {
  Button,
  Card as AntCard,
  Collapse,
  Form,
  message,
  Switch,
  Select as AntSelect,
  Input,
  Tooltip,
  InputNumber,
  Space,
} from 'antd';
import { stylesheet } from 'typestyle';
import { v4 } from 'uuid';

import { PriceInput, RangePicker, Select } from '@src/controls';
import { ShoppingOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from '@src/i18n';
import { ListFixedHeader } from '@src/components';
import { services } from '@src/services';
import { SitesSelectors } from '@src/modules/sites';
import { getEnvironmentalVariables } from '@src/services/env-service';
import { getProductPageAuthToken } from '@src/services/tokenauth-service';
import {
  PDPConfigPayload,
  ProductPageWidgetConfig,
  ProductPageWidgetViewedItem,
} from '@src/containers/product-page-widget/types';
import { parsePriceString } from '../regions/carrier-products/helpers';
import { getAvailableLocalesOptions } from '@src/modules/dictionaries/selectors';
import { mapRangePickerArrayToObject } from '../checkout-widget/helpers';

const env = getEnvironmentalVariables();

const item: ProductPageWidgetViewedItem = {
  attributes: [],
  name: 'Keyboard',
  price: '500.00',
  sku: v4(),
  out_of_stock: false,
  quantity: 1,
};

const initialValues: ProductPageWidgetConfig = {
  country: 'SE',
  locales: ['en-US'],
  currency: 'SEK',
  cart: {
    attributes: [],
    cart_id: v4(),
    total_value: '500.00',
    items: [item],
  },
  external_id: '',
  postal_code: undefined,
  viewed_item: item,
};

interface ProductPageWidgetConfigurationProps {
  visible?: boolean;
}

export const ProductPageWidgetConfiguration: React.FC<ProductPageWidgetConfigurationProps> = ({
  visible,
}) => {
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [widgetReady, setWidgetReady] = React.useState<boolean>(false);

  const widgetPlaceholderRef = React.useRef<HTMLDivElement>(null);

  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);
  const draftSiteId = useSelector(SitesSelectors.getDraftSiteIdOrEmpty);
  const availableLocalesOptions = useSelector(getAvailableLocalesOptions);

  const effectiveSiteId = !!draftSiteId ? draftSiteId : siteId;
  const [useDraft, setUseDraft] = React.useState<boolean>(false);

  const productPageQuery = useQuery({
    queryKey: ['pdp-auth-token', effectiveSiteId, { useDraft }],
    queryFn: () => {
      return getProductPageAuthToken(useDraft ? effectiveSiteId : siteId);
    },
    retry: false,
    staleTime: Infinity,
    onError: error => services.notificationService.error((error as any).message),
  });

  const loadScript = () => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = env.MAD_PRODUCT_PAGE_WIDGET_URL;
    script.onload = () => {
      setLoaded(true);
      window._ingridPDPWidgetApi.addListener('error', error => {
        setWidgetReady(false);
        message.error(error || 'An unexpected error occured');
      });
    };
    document.head.appendChild(script);
  };

  React.useEffect(() => {
    loadScript();
  }, []);

  React.useEffect(() => {
    // clear widget when we change site.
    return () => {
      if (!widgetPlaceholderRef.current) {
        return;
      }
      widgetPlaceholderRef.current.innerHTML = '';
      setWidgetReady(false);
    };
  }, [effectiveSiteId]);

  React.useEffect(() => {
    setUseDraft(!!draftSiteId || !siteId);
  }, [draftSiteId]);

  const prepareValues = (values: ProductPageWidgetConfig): PDPConfigPayload => {
    const item = {
      ...values.viewed_item,
      price: parsePriceString(values.viewed_item.price),
      shipping_date: mapRangePickerArrayToObject(values.viewed_item.shipping_date),
      discount: parsePriceString(values.viewed_item.discount),
    };

    return {
      ...values,
      auth_token: productPageQuery.status === 'success' ? productPageQuery.data.token : '',
      cart: {
        ...initialValues.cart,
        total_value: parsePriceString(values.viewed_item.price),
        items: [item],
      },
      viewed_item: item,
    };
  };

  const onCreate = (values: ProductPageWidgetConfig) => {
    if (widgetPlaceholderRef.current) {
      widgetPlaceholderRef.current.innerHTML = '';
    }

    window._ingridPDPWidgetApi.render('product-page-widget-placeholder', prepareValues(values));
    setWidgetReady(true);
  };

  const onClose = () => {
    if (widgetPlaceholderRef.current) {
      widgetPlaceholderRef.current.innerHTML = '';
      setWidgetReady(false);
    }
  };

  const [formInstance] = Form.useForm<ProductPageWidgetConfig>();

  React.useEffect(() => {
    if (productPageQuery.status === 'success') {
      formInstance.setFieldsValue({ auth_token: productPageQuery.data.token });
    }
  }, [productPageQuery.dataUpdatedAt]);

  React.useEffect(() => {
    // A way to force update on prop change instead of useImperativeHandle
    // It's safer to completely restart the widget instead of updating
    // since we don't know which features might have changed
    if (visible && widgetReady) {
      widgetPlaceholderRef.current!.innerHTML = '';
      onCreate(formInstance.getFieldsValue());
    }
  }, [visible]);

  return (
    <>
      <ListFixedHeader
        title="Product Page Widget Test Client"
        IconComponent={ShoppingOutlined}
        showSearch={false}
      />
      <AntCard>
        <div
          className={styles.widgetContainer}
          id="product-page-widget-placeholder"
          ref={widgetPlaceholderRef}
        />
      </AntCard>
      <Form
        form={formInstance}
        initialValues={initialValues}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        onFinish={onCreate}
      >
        <AntCard
          title={<FormattedMessage id="SETTINGS" />}
          extra={
            <Space>
              {widgetReady && (
                <Button type="default" onClick={onClose}>
                  <FormattedMessage id="CLOSE_WIDGET_SESSION" />
                </Button>
              )}
              <Button disabled={!loaded || widgetReady} type="primary" htmlType="submit">
                <FormattedMessage id="GO_TO_WIDGET" />
              </Button>
            </Space>
          }
        >
          <Collapse defaultActiveKey={['1']}>
            <Collapse.Panel header="General" key="1" forceRender>
              <div className={`${styles.switchContainer} ant-row ant-form-item`}>
                <div className="ant-col ant-col-4 ant-form-item-label">
                  <label className=".ant-form-item-label">Use draft</label>
                </div>
                <Switch disabled={!draftSiteId} checked={useDraft} onChange={setUseDraft} />
              </div>
              <Form.Item
                name="country"
                label={<FormattedMessage id="COUNTRY" />}
                rules={[{ required: true, message: '' }]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  options={services.dictionariesService.getCountriesOptions()}
                />
              </Form.Item>
              <Form.Item
                name={['currency']}
                label={<FormattedMessage id="CURRENCY" />}
                rules={[{ required: true, message: '' }]}
              >
                <Select options={services.dictionariesService.getCurrenciesOptions()} />
              </Form.Item>
              <Form.Item
                name={['locales', 0]}
                label={<FormattedMessage id="LOCALE" />}
                rules={[{ required: true, message: '' }]}
              >
                <Select options={availableLocalesOptions} />
              </Form.Item>
            </Collapse.Panel>

            <Collapse.Panel header="Item" key="2" forceRender>
              <Form.Item name={['viewed_item', 'sku']} label="SKU" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item name={['viewed_item', 'name']} label="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item
                name={['viewed_item', 'weight']}
                label={
                  <>
                    <span>Weight</span>
                    <Tooltip title="Weight in grams">
                      <InfoCircleOutlined className={styles.tooltipIcon} />
                    </Tooltip>
                  </>
                }
              >
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item
                label={
                  <>
                    <span>Dimensions</span>
                    <Tooltip title="Length × Width × Height">
                      <InfoCircleOutlined className={styles.tooltipIcon} />
                    </Tooltip>
                  </>
                }
                className={styles.dimensionsInputGroup}
              >
                <Form.Item
                  name={['viewed_item', 'dimensions', 'length']}
                  className={styles.dimensionsInput}
                  normalize={value => value ?? 0}
                >
                  <InputNumber placeholder="L" min={0} />
                </Form.Item>
                <span className={styles.dimensionsSeparator}>&times;</span>
                <Form.Item
                  name={['viewed_item', 'dimensions', 'width']}
                  className={styles.dimensionsInput}
                  normalize={value => value ?? 0}
                >
                  <InputNumber placeholder="W" min={0} />
                </Form.Item>
                <span className={styles.dimensionsSeparator}>&times;</span>
                <Form.Item
                  name={['viewed_item', 'dimensions', 'height']}
                  className={styles.dimensionsInput}
                  normalize={value => value ?? 0}
                >
                  <InputNumber placeholder="H" min={0} />
                </Form.Item>
              </Form.Item>

              <Form.Item name={['viewed_item', 'price']} label="Price" rules={[{ required: true }]}>
                <PriceInput />
              </Form.Item>

              <Form.Item name={['viewed_item', 'discount']} label="Discount">
                <PriceInput />
              </Form.Item>

              <Form.Item name={['viewed_item', 'quantity']} label="Quantity">
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item name={['viewed_item', 'attributes']} label="Attributes">
                <AntSelect mode="tags" tokenSeparators={[',']} />
              </Form.Item>

              <Form.Item
                name={['viewed_item', 'shipping_date']}
                label={<FormattedMessage id="SHIPPING_DATE" />}
              >
                <RangePicker allowClear showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
              </Form.Item>

              <Form.Item
                name={['viewed_item', 'out_of_stock']}
                label="Out of stock"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Collapse.Panel>
          </Collapse>
        </AntCard>
      </Form>
    </>
  );
};

const styles = stylesheet({
  dimensionsInputGroup: {
    $nest: {
      '.ant-form-item-control-input-content': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  dimensionsInput: {
    display: 'inline-block',
    marginBottom: 0,
  },
  dimensionsSeparator: {
    margin: '0 10px',
  },
  tooltipIcon: {
    marginLeft: '5px',
  },
  widgetContainer: {
    width: '640px',
    margin: 'auto',
  },
  switchContainer: {
    alignItems: 'center',
  },
});
