import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { DOMAIN, LABELS } from '@src/dictionaries';
import { UserCreateModel } from '@src/models';
import { authSelectors } from '@src/modules/auth';
import { tagsActions, tagsSelectors } from '@src/modules/tags';
import { usersActions, usersSelectors } from '@src/modules/users';
import { RootAction, RootState } from '../../modules';
import { UserForm } from './components';

const mapStateToProps = (state: RootState) => ({
  usersError: state.users.error,
  usersFetching: state.users.fetching,
  siteTagsOptions: tagsSelectors.getSiteTagOptions(state),
  accountTypes: authSelectors.getAccountTypes(state),
  groupMembershipOptions: usersSelectors.getGroupMembershipOptions(state),
  groupsIdsWithAccessToWatchlists: usersSelectors.getGroupsIdsThatHaveWatchlistRole(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  ...bindActionCreators(
    {
      createUser: usersActions.fetchCreateUser,
      getSiteTagsList: tagsActions.listSiteTagsRequest,
      getGroupsList: usersActions.getGroupListRequest,
    },
    dispatch
  ),
  updateUserWithTags: tagsActions.updateUserWithTagsRequest,
  assignGroupToUser: usersActions.assignUserToGroupRequest,
});

interface OwnProps {
  siteId: string;
  merchantId: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

class Component extends React.Component<Props> {
  componentDidMount() {
    const { getSiteTagsList, siteId, getGroupsList, merchantId } = this.props;
    getSiteTagsList({ siteId });
    getGroupsList({ merchantId, siteId });
  }

  handleCreateUser = (user: UserCreateModel, groupIds: string[], tagsIds: string[]) => {
    const { createUser, assignGroupToUser, updateUserWithTags, siteId, merchantId } = this.props;
    createUser({
      user: { ...user, merchantId },
      onComplete: userId => [
        ...groupIds.map(groupId => assignGroupToUser({ userId, groupId })),
        updateUserWithTags({ userId, tagsIds, siteId }),
      ],
    });
  };

  render() {
    const {
      usersError,
      usersFetching,
      siteTagsOptions,
      groupMembershipOptions,
      accountTypes,
      groupsIdsWithAccessToWatchlists,
    } = this.props;

    return (
      <UserForm
        groupMembershipOptions={groupMembershipOptions}
        onSubmit={this.handleCreateUser}
        error={usersError}
        isLoading={usersFetching}
        siteTagsOptions={siteTagsOptions}
        title={[LABELS.CREATE, DOMAIN.USER].join(' ')}
        mode="create"
        accountTypes={accountTypes}
        groupsIdsWithAccessToWatchlists={groupsIdsWithAccessToWatchlists}
      />
    );
  }
}

export const UsersCreateContainer = connect(mapStateToProps, mapDispatchToProps)(Component);
