import { TransportOrderModel } from '@src/models';
import { Collapse, Descriptions, Divider } from 'antd';
import { propOr } from 'ramda';
import * as React from 'react';
import { style as tss } from 'typestyle';

type Props = {
  order: TransportOrderModel;
};
export const DeliveryInformation: React.FunctionComponent<Props> = ({ order }) => {
  const { receiver, metadata } = order;
  const { session } = metadata;
  const { pricing, shipping } = session;

  function propOrPlaceholder<T>(prop: Extract<keyof T, string>, obj: T | undefined): string {
    if (!obj) {
      return '-';
    }
    return propOr('-', prop as string, obj);
  }

  const getPrice = () => {
    if (!pricing || !pricing.shipping_price) {
      return '0';
    }
    return (pricing.shipping_price / 100).toFixed(2);
  };

  const getDeliveryTypeName = () => {
    switch (session.deliveryType) {
      case 'pickup':
      case 'PICKUP':
        return 'Pickup Point ';
      case 'delivery':
      case 'DELIVERY':
        return 'Home Delivery';
      case 'mailbox':
      case 'MAILBOX':
        return 'To Mailbox';
      case 'instore':
      case 'INSTORE':
        return 'In Store Delivery';
      case 'inwarehouse':
      case 'INWAREHOUSE':
        return 'In Warehouse Delivery';

      default:
        return session.deliveryType;
    }
  };

  /**
   * Workaround for Antd's issue: https://github.com/ant-design/ant-design/issues/4853
   * It's not possible to use React.Fragment to render address fields
   */
  const locationInformation = (() => {
    if (!receiver || !receiver.destination) {
      return [];
    }
    const { locationRef, address } = receiver.destination;
    return [
      {
        label: 'Location Name',
        value: propOrPlaceholder('name', address),
      },
      {
        label: 'Pickup Point ID',
        value: locationRef || '-',
      },
      {
        label: 'City',
        value: propOrPlaceholder('city', address),
      },
      {
        label: 'Country',
        value: propOrPlaceholder('country', address),
      },
      {
        label: 'Address Lines',
        value: propOrPlaceholder('addressLines', address),
      },
      {
        label: 'Postal Code',
        value: propOrPlaceholder('postalCode', address),
      },
    ];
  })();

  return (
    <>
      <Descriptions className={styles.descriptionList} column={2}>
        <Descriptions.Item label="Category name" span={2}>
          {propOrPlaceholder('category_name', shipping)}
        </Descriptions.Item>
        <Descriptions.Item label="Price" span={2}>
          {getPrice()}
        </Descriptions.Item>
        <Descriptions.Item label="Delivery type" span={2}>
          {getDeliveryTypeName()}
        </Descriptions.Item>
        <Descriptions.Item label="Start delivery time">
          {shipping && shipping.time_slot && shipping.time_slot.start
            ? new Date(shipping.time_slot.start).toLocaleDateString()
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="End delivery time">
          {shipping && shipping.time_slot && shipping.time_slot.end
            ? new Date(shipping.time_slot.end).toLocaleDateString()
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Shipping method" span={2}>
          {propOrPlaceholder('method', session)}
        </Descriptions.Item>
        <Descriptions.Item label="External method id" span={2}>
          {propOrPlaceholder('external_method_id', shipping)}
        </Descriptions.Item>
        <Descriptions.Item label="Carrier" span={2}>
          {propOrPlaceholder('carrier', shipping)}
        </Descriptions.Item>
        <Descriptions.Item label="Carrier product" span={2}>
          {propOrPlaceholder('product', shipping)}
        </Descriptions.Item>
      </Descriptions>
      {session.deliveryType === 'PICKUP' && (
        <>
          <Divider className={styles.divider} />
          <Collapse bordered={false} className={styles.location}>
            <Collapse.Panel header="Location" key="location">
              <Descriptions column={1} className={styles.descriptionList}>
                {locationInformation.map(info => (
                  <Descriptions.Item key={info.label} label={info.label}>
                    {info.value}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </Collapse.Panel>
          </Collapse>
        </>
      )}
    </>
  );
};

const styles = {
  descriptionList: tss({
    $nest: {
      '.ant-descriptions-item-label': {
        fontWeight: 500,
      },
    },
  }),
  location: tss({
    $nest: {
      '&.ant-collapse': {
        background: 'white',
      },
      '.ant-collapse-header': {
        padding: '0px !important',
        paddingLeft: '20px !important',
        fontWeight: 500,
      },
      '.ant-collapse-arrow': {
        paddingTop: '0px !important',
        left: '0 !important',
      },
      '.ant-collapse-item': {
        borderBottom: 'none !important',
      },
      '.ant-collapse-content-box': {
        padding: '16px 0 0 0 !important',
      },
    },
  }),
  divider: tss({
    margin: '5px 0 12px 0',
  }),
};
