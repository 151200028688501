import Decimal from 'decimal.js';
import { omit } from 'ramda';

import { PriceRulesModel, UpdatePriceRulesModel } from '@src/models';
import { removeSingleQuotes } from '@src/utils/string';

export interface PriceRuleFormValue {
  conditions: PriceRuleConditionFormValue[];
  price: string;
  validFrom?: string;
  validUntil?: string;
}

export enum PriceRuleType {
  TOTAL_VALUE = 'TOTAL_VALUE',
  HAS_CART_ATTRIBUTE = 'HAS_CART_ATTRIBUTE',
  HAS_CART_ITEM_ATTRIBUTE = 'HAS_CART_ITEM_ATTRIBUTE',
  VOUCHER = 'VOUCHER',
  HAS_VOUCHERS = 'HAS_VOUCHERS',
  COUNTRY = 'COUNTRY',
  REGION = 'REGION',
  GEO_LISTING = 'GEO_LISTING',
  CATEGORY_REF = 'CATEGORY_REF',
  TOTAL_WEIGHT = 'TOTAL_WEIGHT',
  TOTAL_VOLUME = 'TOTAL_VOLUME',
  POSTAL_CODE = 'POSTAL_CODE',
  TOTAL_DISCOUNT = 'TOTAL_DISCOUNT',
  TOTAL_QUANTITY = 'TOTAL_QUANTITY',
  HAS_ANY_DISCOUNT = 'HAS_ANY_DISCOUNT',
  IS_HOLIDAY = 'IS_HOLIDAY',
  UNKNOWN = 'UNKNOWN',
  TRUE = 'TRUE',
  TOTAL_CART_ITEMS_VALUE = 'TOTAL_CART_ITEMS_VALUE',
  CARRIER_PRODUCT_AVAILABILITY = 'CARRIER_PRODUCT_AVAILABILITY',
  IN_POSTAL_CODE_RANGE = 'IN_POSTAL_CODE_RANGE',
}

export type PriceRuleConditionFormValue =
  | ConditionTotalValue
  | ConditionTotalCartItemsValue
  | ConditionVoucher
  | ConditionHasVouchers
  | ConditionTrue
  | ConditionCountry
  | ConditionRegion
  | ConditionGeoListing
  | ConditionUnknown
  | ConditionHasCartAttribute
  | ConditionCategoryRef
  | ConditionTotalWeight
  | ConditionTotalVolume
  | ConditionPostalCode
  | ConditionTotalDiscount
  | ConditionTotalQuantity
  | ConditionHasAnyDiscount
  | ConditionIsHoliday
  | ConditionHasCartItemAttribute
  | ConditionCarrierProductAvailability
  | ConditionInPostalCodeRange;

export type ConditionTotalCartItemsValue = {
  type: PriceRuleType.TOTAL_CART_ITEMS_VALUE;
  operator: '<' | '<=' | '>' | '>=';
  value: string;
};

export type ConditionHasCartItemAttribute = {
  type: PriceRuleType.HAS_CART_ITEM_ATTRIBUTE;
  value: string;
};

export type ConditionHasCartAttribute = {
  type: PriceRuleType.HAS_CART_ATTRIBUTE;
  value: string;
};

export type ConditionCategoryRef = {
  type: PriceRuleType.CATEGORY_REF;
  value: string;
};

export type ConditionVoucher = {
  type: PriceRuleType.VOUCHER;
  values: string[];
};

export type ConditionHasVouchers = {
  type: PriceRuleType.HAS_VOUCHERS;
  values: string[];
};

export type ConditionPostalCode = {
  type: PriceRuleType.POSTAL_CODE;
  values: string[];
};

export type ConditionCountry = {
  type: PriceRuleType.COUNTRY;
  value: string;
};

export type ConditionRegion = {
  type: PriceRuleType.REGION;
  value: string;
};

export type ConditionGeoListing = {
  type: PriceRuleType.GEO_LISTING;
  value: string;
};

export type ConditionTotalValue = {
  type: PriceRuleType.TOTAL_VALUE;
  operator: '<' | '<=' | '>' | '>=';
  value: string;
};

export type ConditionTotalWeight = {
  type: PriceRuleType.TOTAL_WEIGHT;
  operator: '<' | '<=' | '>' | '>=';
  value: number;
};

export type ConditionTotalVolume = {
  type: PriceRuleType.TOTAL_VOLUME;
  operator: '<' | '<=' | '>' | '>=';
  value: number;
};

export type ConditionTotalDiscount = {
  type: PriceRuleType.TOTAL_DISCOUNT;
  operator: '<' | '<=' | '>' | '>=';
  value: string;
};

export type ConditionTotalQuantity = {
  type: PriceRuleType.TOTAL_QUANTITY;
  operator: '<' | '<=' | '>' | '>=';
  value: number;
};

export type ConditionHasAnyDiscount = {
  type: PriceRuleType.HAS_ANY_DISCOUNT;
};

export type ConditionIsHoliday = {
  type: PriceRuleType.IS_HOLIDAY;
};
export type ConditionCarrierProductAvailability = {
  type: PriceRuleType.CARRIER_PRODUCT_AVAILABILITY;
  value: string;
};
export type ConditionInPostalCodeRange = {
  type: PriceRuleType.IN_POSTAL_CODE_RANGE;
  value: string;
};
export type ConditionUnknown = {
  type: PriceRuleType.UNKNOWN;
  value: string; // in case of unknown condition type, keep whole condition string as value
};

export type ConditionTrue = {
  type: PriceRuleType.TRUE;
};

/** From form format to backend format, i.e. from '15.5' to 1550, from '5,99' to 599, etc. */
export const parsePriceString = (price?: string): number => {
  if (!price) {
    price = '0';
  }
  return new Decimal(price.replace(',', '.')).mul(100).toNumber();
};

/** From backend format to form format, i.e. from 1550 to '15.5' */
export const parsePriceNumber = (price: number): string => {
  return new Decimal(price).div(100).toFixed(2);
};

export const priceToFixed = (price: string): string => {
  if (!price) {
    price = '0';
  }
  return new Decimal(price.replace(',', '.')).toFixed(2);
};

const isBasePrice = (priceRule: PriceRuleFormValue): boolean => {
  return !!priceRule.conditions.find(condition => condition.type === PriceRuleType.TRUE);
};

export const getBasePriceFormValue = (priceRules: PriceRuleFormValue[]): string => {
  return priceRules.find(isBasePrice)!.price;
};

export const getPriceRuleFormValues = (priceRules: PriceRuleFormValue[]): PriceRuleFormValue[] => {
  return priceRules.filter(priceRule => !isBasePrice(priceRule));
};

export const parsePriceRule = (rule: PriceRulesModel) => {
  const price = parsePriceNumber(rule.price || 0);
  const conditions = rule.conditions || [rule.condition!];
  return {
    ...omit(['condition'], rule), // to copy "validFrom" and "validUntil" properties
    price,
    conditions: conditions.map(getPriceRuleConditionFormValue),
  };
};

export const parsePriceRules = (priceRules: PriceRulesModel[]): PriceRuleFormValue[] => {
  return priceRules.map(parsePriceRule);
};

export const getPriceRuleFromFormValue = (priceRule: PriceRuleFormValue): UpdatePriceRulesModel => {
  return {
    ...priceRule,
    price: parsePriceString(priceRule.price),
    conditions: priceRule.conditions.map(value => getConditionFromFormValue(value)),
  };
};

export const getPriceRuleType = (rule: string): PriceRuleType => {
  switch (true) {
    case /^\btotal_value\b\s(<|>|<=|>=)\s\d+$/g.test(rule):
      return PriceRuleType.TOTAL_VALUE;
    case /^\btotal_cart_items_value\b\s(<|>|<=|>=)\s\d+$/g.test(rule):
      return PriceRuleType.TOTAL_CART_ITEMS_VALUE;
    case /^\btotal_weight\b\s(<|>|<=|>=)\s[0-9]*[.]?[0-9]+$/g.test(rule):
      return PriceRuleType.TOTAL_WEIGHT;
    case /^\btotal_volume\b\s(<|>|<=|>=)\s[0-9]*[.]?[0-9]+$/g.test(rule):
      return PriceRuleType.TOTAL_VOLUME;
    case /^\btotal_discount\b\s(<|>|<=|>=)\s\d+$/g.test(rule):
      return PriceRuleType.TOTAL_DISCOUNT;
    case /^\btotal_quantity\b\s(<|>|<=|>=)\s[0-9]*[.]?[0-9]+$/g.test(rule):
      return PriceRuleType.TOTAL_QUANTITY;
    case /^\bvoucher\b\s==\s'\S*'( \|\| \bvoucher\b\s==\s'\S*')*$/g.test(rule):
      return PriceRuleType.VOUCHER;
    case /^\bhas_vouchers\b\('\S+'(, '\S+')*\)$/g.test(rule):
      return PriceRuleType.HAS_VOUCHERS;
    case /^\bpostal_code\b\s==\s'\S*'( \|\| \bpostal_code\b\s==\s'\S*')*$/g.test(rule):
      return PriceRuleType.POSTAL_CODE;
    case /^\bcountry\b\s==\s'\S*'$/g.test(rule):
      return PriceRuleType.COUNTRY;
    case /^\bregion\b\s==\s'\S*'$/g.test(rule):
      return PriceRuleType.REGION;
    // todo: in case we get more filter rule names construct this regex from enum
    case /^(\bis_dhl_remote_area\b|\bis_ups_remote_area\b|\bis_pnl_remote_area\b|\bis_ups_extended_remote_area\b)\(\)$/g.test(
      rule
    ):
      return PriceRuleType.GEO_LISTING;
    case /^\bhas_cart_attribute\b\('\S+'\)$/g.test(rule):
      return PriceRuleType.HAS_CART_ATTRIBUTE;
    case /^\bhas_cart_item_attribute\b\('\S+'\)$/g.test(rule):
      return PriceRuleType.HAS_CART_ITEM_ATTRIBUTE;
    case /^\bcategory_ref\b\s==\s'\S*'$/g.test(rule):
      return PriceRuleType.CATEGORY_REF;
    case /^\bhas_any_discount\b$/g.test(rule):
      return PriceRuleType.HAS_ANY_DISCOUNT;
    case /^\bis_holiday\b$/g.test(rule):
      return PriceRuleType.IS_HOLIDAY;
    case /\bavailable_shipping_methods\b/g.test(rule):
      return PriceRuleType.CARRIER_PRODUCT_AVAILABILITY;
    case /\bin_range\b/g.test(rule):
      return PriceRuleType.IN_POSTAL_CODE_RANGE;
    case rule === 'true':
      return PriceRuleType.TRUE;
    default:
      return PriceRuleType.UNKNOWN;
  }
};

export const getPriceRuleConditionFormValue = (condition: string): PriceRuleConditionFormValue => {
  switch (getPriceRuleType(condition)) {
    case PriceRuleType.TOTAL_VALUE: {
      const [operator, value] = condition.split(' ').slice(1, 3);
      return {
        type: PriceRuleType.TOTAL_VALUE,
        operator: operator as ConditionTotalValue['operator'],
        value: parsePriceNumber(parseInt(value, 10)),
      };
    }

    case PriceRuleType.TOTAL_CART_ITEMS_VALUE: {
      const [operator, value] = condition.split(' ').slice(1, 3);
      return {
        type: PriceRuleType.TOTAL_CART_ITEMS_VALUE,
        operator: operator as ConditionTotalValue['operator'],
        value: parsePriceNumber(parseInt(value, 10)),
      };
    }

    case PriceRuleType.TOTAL_WEIGHT: {
      const [operator, value] = condition.split(' ').slice(1, 3);
      return {
        type: PriceRuleType.TOTAL_WEIGHT,
        operator: operator as ConditionTotalWeight['operator'],
        value: parseFloat(value),
      };
    }

    case PriceRuleType.TOTAL_VOLUME: {
      const [operator, value] = condition.split(' ').slice(1, 3);
      return {
        type: PriceRuleType.TOTAL_VOLUME,
        operator: operator as ConditionTotalWeight['operator'],
        value: parseFloat(value),
      };
    }

    case PriceRuleType.TOTAL_DISCOUNT: {
      const [operator, value] = condition.split(' ').slice(1, 3);
      return {
        type: PriceRuleType.TOTAL_DISCOUNT,
        operator: operator as ConditionTotalDiscount['operator'],
        value: parsePriceNumber(parseInt(value, 10)),
      };
    }

    case PriceRuleType.TOTAL_QUANTITY: {
      const [operator, value] = condition.split(' ').slice(1, 3);
      return {
        type: PriceRuleType.TOTAL_QUANTITY,
        operator: operator as ConditionTotalQuantity['operator'],
        value: parseFloat(value),
      };
    }

    case PriceRuleType.VOUCHER: {
      const newValue = condition
        .split('||')
        .map(voucherString => removeSingleQuotes(voucherString.trim().split(' ').pop()!));
      return {
        type: PriceRuleType.VOUCHER,
        values: newValue,
      };
    }

    case PriceRuleType.HAS_VOUCHERS: {
      const vouchersValues = condition
        .replace("has_vouchers('", '')
        .replace("')", '')
        .split("', '");
      return {
        type: PriceRuleType.HAS_VOUCHERS,
        values: vouchersValues,
      };
    }

    case PriceRuleType.POSTAL_CODE: {
      const newValue = condition
        .split('||')
        .map(voucherString => removeSingleQuotes(voucherString.trim().split(' ').pop()!));
      return {
        type: PriceRuleType.POSTAL_CODE,
        values: newValue,
      };
    }

    case PriceRuleType.COUNTRY: {
      const [value] = condition.split(' ').slice(2, 3);
      return {
        type: PriceRuleType.COUNTRY,
        value: removeSingleQuotes(value),
      };
    }

    case PriceRuleType.REGION: {
      const [value] = condition.split(' ').slice(2, 3);
      return {
        type: PriceRuleType.REGION,
        value: removeSingleQuotes(value),
      };
    }

    case PriceRuleType.GEO_LISTING: {
      return {
        type: PriceRuleType.GEO_LISTING,
        value: condition.slice(0, condition.length - 2),
      };
    }

    case PriceRuleType.CATEGORY_REF: {
      const [value] = condition.split(' ').slice(2, 3);
      return {
        type: PriceRuleType.CATEGORY_REF,
        value: removeSingleQuotes(value),
      };
    }

    case PriceRuleType.HAS_CART_ATTRIBUTE: {
      const attribute = condition.split('(').pop()!.split(')').shift()!;
      return {
        type: PriceRuleType.HAS_CART_ATTRIBUTE,
        value: removeSingleQuotes(attribute),
      };
    }

    case PriceRuleType.HAS_CART_ITEM_ATTRIBUTE: {
      const attribute = condition.split("has_cart_item_attribute('").pop()!.split("')").shift()!;
      return {
        type: PriceRuleType.HAS_CART_ITEM_ATTRIBUTE,
        value: removeSingleQuotes(attribute),
      };
    }

    case PriceRuleType.HAS_ANY_DISCOUNT: {
      return {
        type: PriceRuleType.HAS_ANY_DISCOUNT,
      };
    }

    case PriceRuleType.IS_HOLIDAY: {
      return {
        type: PriceRuleType.IS_HOLIDAY,
      };
    }

    case PriceRuleType.CARRIER_PRODUCT_AVAILABILITY: {
      return {
        type: PriceRuleType.CARRIER_PRODUCT_AVAILABILITY,
        value: condition,
      };
    }

    case PriceRuleType.IN_POSTAL_CODE_RANGE: {
      return {
        type: PriceRuleType.IN_POSTAL_CODE_RANGE,
        value: condition,
      };
    }

    case PriceRuleType.TRUE:
      return {
        type: PriceRuleType.TRUE,
      };

    default:
      return {
        type: PriceRuleType.UNKNOWN,
        value: condition,
      };
  }
};

export const getConditionFromFormValue = (
  condition: PriceRuleConditionFormValue,
  shouldParseString = true
) => {
  switch (condition.type) {
    case PriceRuleType.TOTAL_VALUE:
      return `total_value ${condition.operator} ${
        shouldParseString ? parsePriceString(condition.value) : condition.value
      }`;
    case PriceRuleType.TOTAL_CART_ITEMS_VALUE:
      return `total_cart_items_value ${condition.operator} ${
        shouldParseString ? parsePriceString(condition.value) : condition.value
      }`;
    case PriceRuleType.TOTAL_WEIGHT:
      return `total_weight ${condition.operator} ${condition.value}`;
    case PriceRuleType.TOTAL_VOLUME:
      return `total_volume ${condition.operator} ${condition.value}`;
    case PriceRuleType.TOTAL_DISCOUNT:
      return `total_discount ${condition.operator} ${
        shouldParseString ? parsePriceString(condition.value) : condition.value
      }`;
    case PriceRuleType.TOTAL_QUANTITY:
      return `total_quantity ${condition.operator} ${condition.value}`;
    case PriceRuleType.VOUCHER:
      return condition.values.map(value => `voucher == '${value}'`).join(' || ');
    case PriceRuleType.HAS_VOUCHERS:
      return `has_vouchers('${condition.values.join("', '")}')`;
    case PriceRuleType.POSTAL_CODE:
      return condition.values.map(value => `postal_code == '${value}'`).join(' || ');
    case PriceRuleType.COUNTRY:
      return `country == '${condition.value}'`;
    case PriceRuleType.REGION:
      return `region == '${condition.value}'`;
    case PriceRuleType.GEO_LISTING:
      return `${condition.value}()`;
    case PriceRuleType.CATEGORY_REF:
      return `category_ref == '${condition.value}'`;
    case PriceRuleType.HAS_CART_ATTRIBUTE:
      return `has_cart_attribute('${condition.value}')`;
    case PriceRuleType.HAS_CART_ITEM_ATTRIBUTE:
      return `has_cart_item_attribute('${condition.value}')`;
    case PriceRuleType.HAS_ANY_DISCOUNT:
      return `has_any_discount`;
    case PriceRuleType.IS_HOLIDAY:
      return `is_holiday`;
    case PriceRuleType.CARRIER_PRODUCT_AVAILABILITY:
      return condition.value;
    case PriceRuleType.IN_POSTAL_CODE_RANGE:
      return condition.value;
    case PriceRuleType.UNKNOWN:
      return condition.value;
    case PriceRuleType.TRUE:
      return 'true';
  }
};

const NUMBER_PATTERN = /\d+/g;

export const getPostalCodesFromString = (postalCodeString: string): number[] => {
  const postalCodeRangeString = postalCodeString;

  const matches = postalCodeRangeString.match(NUMBER_PATTERN);
  const numbers = matches?.map(Number);

  return numbers || [];
};
