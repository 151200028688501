import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';

import { Input, TextArea } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { inputStyle, MakeAutoSaveFormikEnhancer, widerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { LabelLocalizationModel } from '@src/models';
import { configActions } from '@src/modules/config';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';

const TypedAutoSaveEnhancer = MakeAutoSaveFormikEnhancer<TranslationsFormValues>();

const InputField = withFormItem(Input, widerLayout);
const TextAreaField = withFormItem(TextArea, widerLayout);

interface Props {
  language: string;
  translation?: LabelLocalizationModel;
  categoryId: string;
  labelId: string;
}

interface TranslationsFormValues {
  name: string;
  description: string;
}

export const LabelTranslationEdit: React.FunctionComponent<Props> = ({
  translation,
  language,
  categoryId,
  labelId,
}) => {
  const handleUpdate = (values: Partial<TranslationsFormValues>) => {
    dispatchOnDraftSiteId(configActions.updateCategoryLabelTranslationsRequest)({
      model: {
        categoryId,
        labelId,
        locale: language,
        ...values,
      },
    });
  };

  return (
    <Formik
      // tslint:disable-next-line: no-empty
      onSubmit={() => {}}
      initialValues={{
        name: translation?.name,
        description: translation?.description,
      }}
      enableReinitialize={true}
    >
      <>
        <TypedAutoSaveEnhancer
          name="name"
          onSave={({ name }) => handleUpdate({ name })}
          render={({ name, onBlur, onKeyDown }) => (
            <Field name={name}>
              {({ field }: FieldProps<TranslationsFormValues['name']>) => (
                <InputField
                  label={<FormattedMessage id="CATEGORY_LABEL_MODAL.TRANSLATION_NAME" />}
                  labelTooltip={
                    <FormattedMessage id="CATEGORY_LABEL_MODAL.TRANSLATION_NAME_TOOLTIP" />
                  }
                  className={inputStyle}
                  {...field}
                  onBlur={onBlur}
                  onKeyDown={onKeyDown}
                  placeholder={'-'}
                />
              )}
            </Field>
          )}
        />
        <TypedAutoSaveEnhancer
          name="description"
          onSave={({ description }) => handleUpdate({ description })}
          render={({ name, onBlur }) => (
            <Field name={name}>
              {({ field }: FieldProps<TranslationsFormValues['description']>) => (
                <TextAreaField
                  label={<FormattedMessage id="CATEGORY_LABEL_MODAL.TRANSLATION_DESCRIPTION" />}
                  labelTooltip={
                    <FormattedMessage id="CATEGORY_LABEL_MODAL.TRANSLATION_DESCRIPTION_TOOLTIP" />
                  }
                  className={inputStyle}
                  {...field}
                  onBlur={onBlur}
                  placeholder={'-'}
                />
              )}
            </Field>
          )}
        />
      </>
    </Formik>
  );
};
