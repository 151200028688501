import { createSelector } from 'reselect';

import { RBACGroupModel, ScopeType, UserGetModel } from '@src/models';
import { MerchantsSelectors } from '@src/modules/merchants';
import { fromPairs, groupBy, pipe, reduce, toPairs } from 'ramda';

import { RootState } from '../root-reducer';
import { SitesSelectors } from '../sites';

const getUsers = (state: RootState) => state.users.users || [];
const getGroups = (state: RootState) => state.users.groups.current;
const getRoles = (state: RootState) => state.users.roles;

export const getUserSummaries = (state: RootState) => state.users.userSummaries;

export const getGroupsPerSite = ({ users: { groups }, sites: { selectedSiteId } }: RootState) =>
  groups.current &&
  groups.current.filter(g =>
    g.scope.type === ScopeType.SITE ? g.scope.id === selectedSiteId : true
  );

export const getSortedRoles = createSelector(
  getRoles,
  roles => roles && roles.sort((r1, r2) => (r1.name > r2.name ? 1 : -1))
);

export const getAttachedUsersToGroup = createSelector(getUsers, getGroups, (users, groups) => {
  if (!groups) {
    return {};
  }
  const groupsUsersDict: Record<RBACGroupModel['id'], UserGetModel[]> = fromPairs(
    groups.map(g => [g.id, [] as UserGetModel[]])
  );

  users.map(user => {
    if (!user.groups) {
      return;
    }
    user.groups.map(group => {
      if (groupsUsersDict[group.id]) {
        groupsUsersDict[group.id].push(user);
      }
    });
  });

  return groupsUsersDict;
});

export const getCurrentMerchantUsersWithoutSuperUsers = createSelector(getUsers, users =>
  users.filter(user => !user.superUser)
);

export const getUsersTableInfo = createSelector(
  getCurrentMerchantUsersWithoutSuperUsers,
  MerchantsSelectors.getSelectedMerchantName,
  (users, selectedMerchant) =>
    users.map(user => ({
      id: user.id,
      email: user.email,
      name: user.name,
      picture: user.picture,
      groups: user.groups,
      merchantName: selectedMerchant,
      externalId: user.externalId,
    }))
);

export const getUserById = (state: RootState, userId: string) =>
  getUsers(state).find(user => user.id === userId);

export const getUserGroupsFiltered = createSelector(
  getGroups,
  SitesSelectors.getSelectedSiteIdOrEmpty,
  (groups, siteId) =>
    groups
      ? UserGetModel.filterOutBaseGroup(groups).filter(group => {
          if (group.scope.type === ScopeType.SITE) {
            return group.scope.id === siteId;
          } else {
            return true;
          }
        })
      : []
);

export const getUserGroup = createSelector(getUserById, user => {
  if (user) {
    return UserGetModel.getUserGroup(user.groups);
  } else {
    return undefined;
  }
});

export const getUserGroups = createSelector(getUserById, user => {
  if (user) {
    return user.groups ? user.groups.map(group => group.id) : [];
  }
  return [];
});

const MERCHANT_GROUPS = 'Merchant groups';

export const getGroupMembershipOptions = createSelector(
  getGroups,
  MerchantsSelectors.getSitesOptionsWithNoDrafts,
  (allGroups, sites) => {
    const result = allGroups
      ? pipe(
          groupBy<RBACGroupModel>(group => {
            if (group.scope.type === ScopeType.SITE) {
              const matchedSite = sites.find(site => site.value === group.scope.id);
              return matchedSite ? matchedSite.label : group.scope.id;
            }
            if (group.scope.type === ScopeType.MERCHANT) {
              return MERCHANT_GROUPS;
            }
            return 'Unmatched group';
          }),
          toPairs,
          namedGroupPairs => {
            // We want to move merchantGroup as the last item in the array
            const merchantGroup = namedGroupPairs.find(
              ([siteOrMerchantName]) => siteOrMerchantName === MERCHANT_GROUPS
            );

            return merchantGroup
              ? namedGroupPairs.concat(
                  namedGroupPairs.splice(namedGroupPairs.indexOf(merchantGroup), 1)
                )
              : namedGroupPairs;
          },
          reduce(
            (whole, [siteOrMerchantName, categorizedGroups]) => ({
              ...whole,
              [siteOrMerchantName]: categorizedGroups.map(group => ({
                value: group.id,
                label: group.name,
                optionLabel: `${group.name} - ${siteOrMerchantName}`,
              })),
            }),
            {}
          )
        )(allGroups)
      : [];

    return result;
  }
);

export const getGroupsIdsThatHaveWatchlistRole = createSelector(getUserGroupsFiltered, groups => {
  return groups
    .filter(group => group.roles && group.roles.find(role => role.name === 'mad:watchlists'))
    .map(group => group.id);
});
