import { combineEpics, Epic } from 'redux-observable';
import {
  combineLatest as observableCombineLatest,
  concat as observableConcat,
  forkJoin as observableForkJoin,
  from as observableFrom,
  of as observableOf,
} from 'rxjs';
import { catchError, filter, finalize, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { MADError } from '@src/utils';

import { ERRORS } from '../../dictionaries';
import { ConfigSiteModel, ShipmentModel, TransportOrderModel } from '../../models';
import { RootAction, RootState, Services } from '../../modules';
import { sitesActions } from '../../modules/sites';
import { routerActions } from '../router';
import { transportOrdersActionCreators, TransportOrdersTypes } from './';

const getTransportOrderListEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { loggingService, somService, tosService }
) =>
  action$.ofType(TransportOrdersTypes.GET_TRANSPORT_ORDER_LIST_REQUEST).pipe(
    switchMap(
      (action: {
        type: typeof TransportOrdersTypes.GET_TRANSPORT_ORDER_LIST_REQUEST;
        payload: any;
      }) =>
        observableOf([state$.value.sites.selectedSiteId]).pipe(
          map(([maybeSelectedSiteId]) => {
            if (maybeSelectedSiteId == null) {
              throw new Error('No selectedSiteId in the store');
            }
            return maybeSelectedSiteId;
          }),
          switchMap(selectedSiteId =>
            observableFrom(
              tosService.listTransportOrders(
                selectedSiteId,
                action.payload.pageSize,
                action.payload.pageNumber,
                action.payload.direction as any,
                action.payload.searchQuery,
                action.payload.filters
              )
            ).pipe(
              switchMap(
                ({ orders }) =>
                  observableFrom(
                    orders.length > 0
                      ? somService.getShipmentList(
                          selectedSiteId,
                          orders.map(o => o.id)
                        )
                      : new Promise<{ shipments: ShipmentModel[] }>(resolve =>
                          resolve({ shipments: [] })
                        )
                  ),
                (ordersRes, shipmentsRes) => ({
                  ...ordersRes,
                  orders: ordersRes.orders.map(o => ({
                    ...o,
                    shipments: shipmentsRes.shipments.filter(s => s.tosId === o.id),
                  })),
                })
              )
            )
          ),
          mergeMap(res => {
            const {
              payload: { filters },
            } = action;
            const actionsToReturn = filters
              ? [
                  transportOrdersActionCreators.getTransportOrderListSuccess(res),
                  transportOrdersActionCreators.setActiveTransportOrderFilters(filters),
                ]
              : [transportOrdersActionCreators.getTransportOrderListSuccess(res)];
            return actionsToReturn;
          }),
          catchError((error: MADError) => {
            loggingService.logError(error);
            return observableConcat(
              observableOf(transportOrdersActionCreators.getTransportOrderListError(error.message)),
              observableOf(transportOrdersActionCreators.clearActiveTransportOrderFilters())
            );
          })
        )
    )
  );

const getTransportOrderEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { somService, tosService, loggingService }
) =>
  observableCombineLatest(
    action$.pipe(filter(isActionOf(sitesActions.getSitesSuccess)), first()),
    action$.ofType(TransportOrdersTypes.GET_TRANSPORT_ORDER_REQUEST)
  ).pipe(
    switchMap(
      // FIXME: remove those types when TransportOrdersTypes will be refactored
      ([{}, transportOrdersAction]: [
        { type: any; payload: ConfigSiteModel[] },
        {
          type: typeof TransportOrdersTypes.GET_TRANSPORT_ORDER_REQUEST;
          payload: { id: string };
          meta: { onComplete: ((transportOrder: TransportOrderModel | null) => void) | undefined };
        }
      ]) =>
        observableOf([state$.value.sites.selectedSiteId]).pipe(
          map(([maybeSelectedSiteId]) => {
            if (maybeSelectedSiteId == null) {
              throw new Error('No selectedSiteId in the store');
            }
            return maybeSelectedSiteId;
          }),
          switchMap(selectedSiteId => {
            let possibleTransportOrder: null | TransportOrderModel = null;
            return observableForkJoin(
              tosService.getTransportOrder(selectedSiteId, transportOrdersAction.payload.id),
              somService.getShipmentList(selectedSiteId, [transportOrdersAction.payload.id])
            ).pipe(
              map(([transportOrder, shipmentsRes]) => {
                const shipments = shipmentsRes.shipments;
                const transportOrderWithShipment = {
                  ...transportOrder,
                  shipments,
                };
                possibleTransportOrder = transportOrderWithShipment;
                return transportOrdersActionCreators.getTransportOrderSuccess(
                  transportOrderWithShipment
                );
              }),
              catchError((error: MADError) => {
                loggingService.logError(error);
                return observableOf(
                  transportOrdersActionCreators.getTransportOrderError(error.message)
                );
              }),
              finalize(() => {
                if (transportOrdersAction.meta.onComplete) {
                  transportOrdersAction.meta.onComplete(possibleTransportOrder);
                }
              })
            );
          })
        )
    )
  );

const createTransportOrderEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { tosService, loggingService, messageService }
) =>
  action$.ofType(TransportOrdersTypes.CREATE_TRANSPORT_ORDER_REQUEST).pipe(
    switchMap(
      (action: {
        type: typeof TransportOrdersTypes.CREATE_TRANSPORT_ORDER_REQUEST;
        payload: TransportOrderModel;
      }) =>
        observableOf([state$.value.sites.selectedSiteId]).pipe(
          map(([maybeSelectedSiteId]) => {
            if (maybeSelectedSiteId == null) {
              throw new Error('No selectedSiteId in the store');
            }
            return maybeSelectedSiteId;
          }),
          switchMap(selectedSiteId =>
            observableFrom(tosService.createTransportOrder(selectedSiteId, action.payload))
          ),
          mergeMap(res => {
            messageService.success('Creating new transport order completed successfully');
            return observableFrom([
              transportOrdersActionCreators.createTransportOrderSuccess(res),
              routerActions.push({ name: 'TRANSPORT_ORDER_LIST' }),
            ]);
          }),
          catchError((error: MADError) => {
            messageService.error(ERRORS.CREATE_FAILED);
            loggingService.logError(error);
            return observableOf(
              transportOrdersActionCreators.createTransportOrderError(error.message)
            );
          })
        )
    )
  );

const updateTransportOrderEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { messageService, loggingService, tosService }
) =>
  action$.ofType(TransportOrdersTypes.UPDATE_TRANSPORT_ORDER_REQUEST).pipe(
    switchMap((action: any) =>
      observableFrom(
        tosService.updateTransportOrder(state$.value.sites.selectedSiteId || '', action.payload)
      ).pipe(
        mergeMap(res => {
          messageService.success(ERRORS.UPDATE_SUCCESS);
          return observableFrom([
            transportOrdersActionCreators.updateTransportOrderSuccess(res),
            routerActions.push({ name: 'TRANSPORT_ORDER_LIST' }),
          ]);
        }),
        catchError((error: MADError) => {
          loggingService.logError(error);
          messageService.error(ERRORS.UPDATE_FAILED);
          return observableOf(
            transportOrdersActionCreators.updateTransportOrderError(error.message)
          );
        })
      )
    )
  );

export const transportOrdersEpics = combineEpics(
  getTransportOrderListEpic,
  getTransportOrderEpic,
  createTransportOrderEpic,
  updateTransportOrderEpic
);
