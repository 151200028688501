import * as React from 'react';
import { useDispatch } from 'react-redux';
import { stylesheet } from 'typestyle';

import { ContainerActions, ListFixedHeader } from '@src/components';
import { Link } from '@src/components/link';
import { Button, Card, modalConfirm } from '@src/controls';
import { DOMAIN, LABELS, MESSAGES } from '@src/dictionaries';
import { addressBookActions } from '@src/modules/address-book';
import { eventTrackingActions } from '@src/modules/event-tracking';
import { useSelector } from '@src/utils/hooks';
import { AddressBookContactList } from './components';
import { BookOutlined } from '@ant-design/icons';

export const AddressBookContainer: React.FunctionComponent = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [typedSearchQuery, setTypedSearchQuery] = React.useState('');
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<string[]>([]);

  const isFetching = useSelector(state => state.addressBook.isFetching);
  const contactsCount = useSelector(state => state.addressBook.count);
  const items = useSelector(state => state.addressBook.contacts);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(eventTrackingActions.viewAddressBookPage());
    dispatch(addressBookActions.getContactsListRequest({}));
  }, []);

  const handlePaginationChange = (nextPage: number, pageLimit: number) => {
    dispatch(
      addressBookActions.getContactsListRequest({
        pageNumber: nextPage,
        pageLimit,
        searchQuery: typedSearchQuery,
      })
    );
    setCurrentPage(nextPage);
  };

  const handleSearch = (searchQuery: string) => {
    dispatch(eventTrackingActions.searchContacts());
    setSelectedRowKeys([]);
    setTypedSearchQuery(searchQuery);
    // when search occurs reset pagination to the first page
    setCurrentPage(1);
    dispatch(addressBookActions.getContactsListRequest({ searchQuery }));
  };

  const handleDeleteClick = (itemIds: string[], resetSelectedKeys: (args: string[]) => void) => {
    modalConfirm({
      title: MESSAGES.DELETE_CONTACTS_HEADER_LIST_VIEW(itemIds.length),
      content: MESSAGES.DELETE_CONTACTS_CONTENT_LIST_VIEW(itemIds.length),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        dispatch(addressBookActions.deleteContactsRequest(itemIds));
        resetSelectedKeys([]);
      },
    });
  };

  return (
    <div className={styles.root}>
      <ListFixedHeader
        onSearchItems={handleSearch}
        title={DOMAIN.ADDRESS_BOOK}
        isLoading={isFetching}
        searchPlaceholder={LABELS.ADDRESSES_SEARCH_PLACEHOLDER}
        IconComponent={BookOutlined}
        actions={
          <>
            <Link route={{ name: 'ADDRESS_BOOK_CREATE_CONTACT' }}>
              <Button type="primary">{LABELS.CREATE}</Button>
            </Link>
            <ContainerActions.Upload
              onUpload={file => dispatch(addressBookActions.createContactsWithCSVRequest(file))}
            />
          </>
        }
      />
      <Card bordered={false} className={styles.card}>
        <AddressBookContactList
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          isLoading={isFetching}
          total={contactsCount}
          currentPage={currentPage}
          onPaginationChange={handlePaginationChange}
          items={items}
          onDelete={handleDeleteClick}
        />
      </Card>
    </div>
  );
};

const styles = stylesheet({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    overflowY: 'auto',
  },
});
