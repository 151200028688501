import { getEnvironmentalVariables } from '@src/services/env-service';
import { RootState } from '../modules';

const version = getEnvironmentalVariables().MAD_APP_VERSION;
const MAD_SERIALIZED_STATE_KEY = `__MAD_SERIALIZED_STATE_v${version}__`;
const MAD_ENTERPRISE_CONNECTION = '__MAD_ENTERPRISE_CONNECTION__';
export const MAD_CHECKOUT_WIDGET_CONFIGURATION_FORM_STATE_KEY = `__MAD_CHECKOUT_WIDGET_CONFIGURATION_FORM_v${version}__`;
export const MAD_TRACKING_WIDGET_CONFIGURATION_FORM_STATE_KEY = `__MAD_TRACKING_WIDGET_CONFIGURATION_FORM_v${version}__`;

// INTERNAL API
function saveItem(key: string, item: {}): boolean {
  if (!localStorage) {
    return false;
  }

  try {
    const serializedState = JSON.stringify(item);
    localStorage.setItem(key, serializedState);
    return true;
  } catch (error) {
    throw new Error('store serialization failed');
  }
}

function loadItem<T>(key: string): T | undefined {
  if (!localStorage) {
    return;
  }

  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState == null) {
      return;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    throw new Error('store deserialization failed');
  }
}

// EXTERNAL API

export function saveState(storeState: RootState): boolean {
  // select what should be stored
  const { auth, dictionaries } = storeState;

  return saveItem(MAD_SERIALIZED_STATE_KEY, {
    auth,
    dictionaries,
  });
}

export function loadState(): RootState | undefined {
  return loadItem(MAD_SERIALIZED_STATE_KEY);
}

export function saveEnterpriseConnection(connection: string): boolean {
  return saveItem(MAD_ENTERPRISE_CONNECTION, connection);
}

export function loadEnterpriseConnection(): string | undefined {
  return loadItem(MAD_ENTERPRISE_CONNECTION);
}

export function clearState() {
  localStorage.removeItem(MAD_SERIALIZED_STATE_KEY);
}

export function saveWidgetConfigurationFormState<F extends {}>(
  formState: F,
  storageKey: string
): boolean {
  return saveItem(`${storageKey}v${version}`, formState);
}

export function clearWidgetConfigurationFormState(storageKey: string) {
  return localStorage.removeItem(`${storageKey}v${version}`);
}

export function loadWidgetConfigurationFormState(storageKey: string) {
  return loadItem<{}>(`${storageKey}v${version}`);
}
