import { Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import {
  getCountryCodeToCountryNameMapping,
  getRegionsFromAllCountries,
} from '@src/services/dictionaries-service';
import { getFieldArrayError } from '@src/utils/forms';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { string } from 'yup';
import { RuleAddingValues } from '../rule-adding-container';
import { flatten, fromPairs, pick } from 'lodash';

const SelectItem = withFormItem(Select);

interface Props {
  name: string;
  errors: FormikProps<RuleAddingValues>['errors'];
  touched: FormikProps<RuleAddingValues>['touched'];
  setFieldValue: FormikProps<RuleAddingValues>['setFieldValue'];
  countries?: string[];
}

// should be using reusable component
const RegionValidate = async (value: string) => {
  const allRegionCodes = flatten(Object.values(getRegionsFromAllCountries())).map(
    region => region.value
  );
  try {
    await string()
      .required('Region cannot be empty')
      .oneOf(allRegionCodes, err => `${err.value} is not a valid region`)
      .validate(value);
  } catch (err) {
    return err;
  }
};

export const Region: React.FunctionComponent<Props> = ({
  name,
  errors,
  touched,
  setFieldValue,
  countries,
}) => {
  if (!countries) {
    return null;
  }

  const regionsFromAllCountries = getRegionsFromAllCountries();
  const regionSupportingCountries = Object.keys(regionsFromAllCountries).filter(country =>
    countries.includes(country)
  );
  const regions = pick(regionsFromAllCountries, regionSupportingCountries);
  const countryNameMap = getCountryCodeToCountryNameMapping();
  const regionsWithNames = fromPairs(
    Object.entries(regions).map(([key, value]) => [countryNameMap[key], value])
  );

  return (
    <div className={styles.wrapper}>
      <Field name={`${name}.value`} validate={(value: string) => RegionValidate(value)}>
        {({ field }: FieldProps<string>) => (
          <SelectItem
            className={styles.label}
            filterOption={true}
            showSearch
            groupOptions={regionsWithNames}
            placeholder={'Please select...'}
            optionFilterProp={'label'}
            label="If Region is"
            style={{ width: '300px' }}
            error={getFieldArrayError(touched, errors, `${name}.value`)}
            {...field}
            onChange={(value: string) => {
              setFieldValue(`${name}.value`, value);
            }}
          />
        )}
      </Field>
    </div>
  );
};

const styles = stylesheet({
  wrapper: {
    padding: '5px 0',
    $nest: {
      '.ant-row': {
        display: 'flex',
        flexWrap: 'nowrap',
        marginBottom: 0,
      },
    },
  },
  label: {
    $nest: {
      '&.ant-select': {
        border: '1px solid #d9d9d9',
        borderRadius: '3px',
      },
      '&.ant-select:hover': {
        border: '1px solid #5bc2b6',
      },
      '&.ant-select-open': {
        border: '1px solid #5bc2b6',
      },
    },
  },
});
