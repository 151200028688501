import { FilterOutlined } from '@ant-design/icons';
import { Button } from '@src/controls';
import { ShipmentListFiltersModel } from '@src/models';
import { configSelectors } from '@src/modules/config';
import { getNumberOfShipmentFilters } from '@src/utils/counters';
import { Popover } from 'antd';
import React, { FunctionComponent } from 'react';
import { stylesheet } from 'typestyle';
import { ShipmentsFilterForm } from './form';

export type FilterPanelProps = {
  shippingMethodsOptions: ReturnType<typeof configSelectors.getRealBookingMethodsGroupedByCarrier>;
  handleApply: (payload: ShipmentListFiltersModel, showUserShipments: boolean) => void;
  handleClear: () => void;
  filters: ShipmentListFiltersModel | null;
  disabled?: boolean;
  allowMyShipmentsFilter: boolean;
};

export const ShipmentsFilterPanel: FunctionComponent<FilterPanelProps> = ({
  filters,
  disabled,
  ...restProps
}) => {
  const [isPanelVisible, setPanelVisibility] = React.useState(false);
  return (
    <div>
      <Popover
        visible={isPanelVisible}
        placement="bottomRight"
        arrowPointAtCenter
        getPopupContainer={e => e.parentElement || document.body}
        overlayClassName={styles.popover}
        autoAdjustOverflow={false}
        content={() => (
          <ShipmentsFilterForm
            setPanelVisibility={setPanelVisibility}
            filters={filters}
            {...restProps}
          />
        )}
      >
        <Button
          type={filters && getNumberOfShipmentFilters(filters) ? 'primary' : undefined}
          onClick={() => setPanelVisibility(!isPanelVisible)}
          disabled={disabled}
        >
          <FilterOutlined style={{ fontSize: '16px' }} />
        </Button>
      </Popover>
    </div>
  );
};

const styles = stylesheet({
  popover: {
    width: '500px',
  },
});
