import * as React from 'react';
import { style as tss } from 'typestyle';

import { Button } from '../controls';
import { Link } from './link';

type Props = {
  title?: string;
  message?: React.ReactNode;
};

const DEFAULT_TITLE = 'Unexpected problem';
const DEFAULT_MESSAGE = <p>Please contact with our support to ask for help.</p>;

const ErrorState: React.FC<Props> = ({ title = DEFAULT_TITLE, message = DEFAULT_MESSAGE }) => {
  return (
    <section className={styles.root}>
      <h1> {title} </h1>
      {message}
      <Link route={{ name: 'HOME_PAGE' }}>
        <Button>Go back to homepage</Button>
      </Link>
    </section>
  );
};

const styles = {
  root: tss({
    marginTop: '20px',
    textAlign: 'center',
  }),
};

export default ErrorState;
