import { InboxOutlined } from '@ant-design/icons';
import { FieldState, FormState } from 'formstate';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { style as tss } from 'typestyle';

import { ContainerActions, ContainerContent, ContainerFixedHeader } from '../../components';
import { DOMAIN, LABELS, MESSAGES } from '../../dictionaries';
import {
  AddonModel,
  AddressModel,
  ContactModel,
  CustomerInfoModel,
  LineItemModel,
  ShipmentModel,
  TemplateModelRequest,
} from '../../models';
import { RootState } from '../../modules';
import { addressBookActions } from '../../modules/address-book';
import { blobsActions, blobsSelectors } from '../../modules/blobs';
import { dictionariesActions } from '../../modules/dictionaries';
import { userprofileActions } from '../../modules/userprofile';

import { configSelectors } from '@src/modules/config';
import { routerActions } from '@src/modules/router';
import { tagsActions, tagsSelectors } from '@src/modules/tags';
import { eqBy, isEmpty, prop, unionWith } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import { Card, Input, Select } from '../../controls';
import { withFieldStateInput, withFieldStateSelect, withFormItem } from '../../decorators';
import { eventTrackingActions } from '../../modules/event-tracking';
import { defaultTheme } from '../../styles';
import { decimalToStringWithoutFactorial } from '../../utils/currency';
import { isFormStateContainerDirty } from '../../utils/forms';
import { isValidFormState, requiredFieldWithMessage } from '../../utils/validation';
import {
  ConsignmentDetailsFormState,
  ConsignmentDetailsTable,
  createLineItemFormState,
  ExtraInformation,
  ExtraInformationFormState,
  LineItemFormState,
  LineItemsTableForm,
  LineItemsTableFormState,
  ShipmentAddressSection,
  ShipmentAddressSelectorFormState,
  ShipmentOptionsForm,
  ShipmentOptionsFormState,
  unwrapLineItemFormState,
} from '../shipments/components';

const InputField = withFormItem(withFieldStateInput(Input));
const SelectField = withFormItem(withFieldStateSelect(Select));

const styles = {
  card: tss({
    marginBottom: defaultTheme.lineHeight,
  }),
  cardContents: tss({ marginBottom: defaultTheme.lineHeight }),
};

// Component API
type OwnProps = {};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  isFetching: state.transportOrders.isFetching,
  isFetchingContacts: state.addressBook.isFetching,
  carrierAddons: state.dictionaries.carrierAddons,
  contactsList: state.addressBook.contacts,
  customerContacts: state.addressBook.customerContacts,
  senderContacts: state.addressBook.senderContacts,
  deliveryContacts: state.addressBook.deliveryContacts,
  siteId: state.sites.selectedSiteId,
  attachmentField: blobsSelectors.attachmentField(state),
  attachmentUrl: blobsSelectors.attachmentUrl(state),
  siteTags: tagsSelectors.getSiteTags(state),
  personalTag: tagsSelectors.getUserPersonalTag(state),
  configuredShippingMethodsNames: configSelectors.getShippingMethodNamesConfiguredForBooking(state),
});
const dispatchProps = {
  getContacts: addressBookActions.getContactsListRequest,
  getCustomerContacts: addressBookActions.getCustomerContactsRequest,
  getSenderContacts: addressBookActions.getSenderContactsRequest,
  getDeliveryContacts: addressBookActions.getDeliveryContactsRequest,
  createContactFromModal: addressBookActions.createContactFromModalRequest,
  createTempContact: addressBookActions.createTempContact,
  updateContactFromModal: addressBookActions.updateContactFromModalRequest,
  getCarrierAddons: dictionariesActions.getCarrierAddonsRequest,
  createTemplate: userprofileActions.createTemplateRequest,
  storeFile: blobsActions.storeFileRequest,
  resetAttachment: blobsActions.resetAttachment,
  viewTemplateCreatePage: eventTrackingActions.viewTemplateCreatePage,
  getSiteTags: tagsActions.listSiteTagsRequest,
  getUserTags: tagsActions.listUserTagsRequest,
  routerPush: routerActions.push,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & OwnProps;

@observer
export class Component extends React.Component<Props, {}> {
  @observable
  formStateContainer = {
    lineItemsTableFormState: LineItemsTableFormState.create(),
    shipmentOptionsFormState: ShipmentOptionsFormState.create(),
    shipmentAddressSelectorFormState: ShipmentAddressSelectorFormState.create(false),
    templateFormState: new FormState({
      templateName: new FieldState('').validators(
        requiredFieldWithMessage('Template name is required')
      ),
    }),
    selectedTags: new FieldState(observable<string>([])),
    extraInformationFormState: ExtraInformationFormState.create(),
    consignmentDetailsFormState: ConsignmentDetailsFormState.create(),
  };

  @observable shippingMethodMetadata = observable<string>([]);
  consignmentFormState = observable.map<any>();
  consignmentDetailsModel = observable<ShipmentModel>({ ...new ShipmentModel() });

  @observable isPreorder = observable<boolean>();

  @observable formSubmitted = false;

  @computed
  get formStateDirty() {
    return isFormStateContainerDirty(this.formStateContainer);
  }

  componentDidMount() {
    const { siteId, viewTemplateCreatePage, siteTags, getSiteTags, getUserTags } = this.props;

    if (siteId) {
      this.props.getContacts({});
      this.props.getCarrierAddons();
      if (isEmpty(siteTags)) {
        getSiteTags({ siteId });
        getUserTags({ siteId });
      }
    }

    viewTemplateCreatePage();
    this.props.resetAttachment();
  }

  handleCreateContactFromModal = (contact: ContactModel, shouldSaveInAddressBook: boolean) => {
    shouldSaveInAddressBook
      ? this.props.createContactFromModal(contact)
      : this.props.createTempContact(contact);
  };

  handleUpdateContactFromModal = (contact: ContactModel) =>
    isNotNil(contact.createdAt) && this.props.updateContactFromModal(contact);

  handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const addons =
      this.formStateContainer.shipmentOptionsFormState.$.carrierAddonsFormState.$.filter(
        a => a.$.isOn
      ).map(a => ({ code: a.$.code } as AddonModel));

    if (
      !(await isValidFormState(this.formStateContainer.templateFormState)) ||
      !(await isValidFormState(this.formStateContainer.lineItemsTableFormState))
    ) {
      return;
    }

    const { contactsList, siteId, customerContacts, deliveryContacts, senderContacts } = this.props;
    if (siteId == null) {
      return;
    }

    const allContacts = [
      ...contactsList,
      ...customerContacts,
      ...deliveryContacts,
      ...senderContacts,
    ];

    const {
      lineItemsTableFormState,
      shipmentOptionsFormState,
      shipmentAddressSelectorFormState,
      templateFormState,
      extraInformationFormState,
    } = this.formStateContainer;

    const {
      fromAddressSelectorFormState,
      toAddressSelectorFormState,
      customerAddressSelectorFormState,
      returnAddressSelectorFormState,
    } = shipmentAddressSelectorFormState.$;

    const template = new TemplateModelRequest();

    template.name = templateFormState.$.templateName.$;
    const customerModel = new CustomerInfoModel();
    const customerId = customerAddressSelectorFormState.$.addressIdFieldState.$;
    const customerAddressBookModel = allContacts.find(item => item.id === customerId);
    customerModel.address =
      (customerAddressBookModel && customerAddressBookModel.address) || new AddressModel();
    customerModel.email = (customerAddressBookModel && customerAddressBookModel.email) || '';
    customerModel.phone = (customerAddressBookModel && customerAddressBookModel.phone) || '';
    template.customerInfo = customerModel;

    const toAddressId = toAddressSelectorFormState.$.addressIdFieldState.$;
    const fromAddressId = fromAddressSelectorFormState.$.addressIdFieldState.$;
    const returnAddressId = returnAddressSelectorFormState.$.addressIdFieldState.$;

    const toAddressBookModel = allContacts.find(item => item.id === toAddressId);
    const fromAddressBookModel = allContacts.find(item => item.id === fromAddressId);
    const returnAddressBookModel = allContacts.find(item => item.id === returnAddressId);

    template.addressFrom =
      (fromAddressBookModel && fromAddressBookModel.address) || new AddressModel();
    template.addressTo = (toAddressBookModel && toAddressBookModel.address) || new AddressModel();
    template.addressReturn =
      (returnAddressBookModel && returnAddressBookModel?.address) || new AddressModel();

    template.shippingMethod =
      shipmentOptionsFormState.$.shippingMethodSelectorFormState.$.shippingMethodFieldState.$.toString();
    template.shipmentValue = decimalToStringWithoutFactorial(
      shipmentOptionsFormState.$.valueFieldState.$
    );

    const { ...meta } = shipmentOptionsFormState.$.metadataFieldState.$;
    template.meta = meta;

    const notes = extraInformationFormState.$.notesFieldState.$;
    if (notes) {
      template.meta = { ...template.meta, notes };
    }

    const attachment = extraInformationFormState.$.attachmentFieldState.$;
    if (attachment) {
      template.meta = { ...template.meta, attachment };
    }

    template.courierInstructions =
      shipmentOptionsFormState.$.shipmentOptionsDetailsFormState.$.courierInstructions.$;
    template.addressTo.doorCode =
      shipmentOptionsFormState.$.shipmentOptionsDetailsFormState.$.doorCode.$;

    template.contents = {
      externalOrders: [],
      transportOrders: [],
      goods: [...lineItemsTableFormState.$.peek().map(lI => unwrapLineItemFormState(lI).lineItem)],
    };

    template.addons = addons;

    template.numberOfParcels =
      this.formStateContainer.consignmentDetailsFormState.$.numberOfParcels.$;
    template.dimensions = this.formStateContainer.consignmentDetailsFormState.$.dimensions.$;
    template.weight = this.formStateContainer.consignmentDetailsFormState.$.weight.$;

    if (this.props.personalTag) {
      template.tags = [...this.formStateContainer.selectedTags.value, this.props.personalTag.id];
    } else {
      template.tags = this.formStateContainer.selectedTags.value;
    }

    this.props.createTemplate({ siteId, template });

    this.formSubmitted = true;
  };

  handleBack = () => {
    this.props.routerPush({ name: 'TEMPLATE_LIST' });
  };

  handleSaveConsignmentDetailsTable = (item: any) => {
    this.consignmentFormState.replace(item);
  };

  handleChangePreorder = (b: boolean) => {
    this.isPreorder.set(b);
  };

  handleAddLineItemItem = () => {
    const { lineItemsTableFormState } = this.formStateContainer;
    lineItemsTableFormState.$.push(createLineItemFormState(true, new LineItemModel()));
  };

  handleDeleteLineItem = (item: LineItemFormState) => {
    const { lineItemsTableFormState } = this.formStateContainer;

    lineItemsTableFormState.$.remove(item);
  };

  handleStoreFile = (file: File) => {
    const { siteId, storeFile } = this.props;
    if (siteId) {
      storeFile({ siteId, file });
    }
  };

  handleSearchCustomerContact = (searchQuery: string) => {
    this.props.getCustomerContacts({ searchQuery });
  };

  handleSearchSenderContact = (searchQuery: string) => {
    this.props.getSenderContacts({ searchQuery });
  };

  handleSearchDeliveryContact = (searchQuery: string) => {
    this.props.getDeliveryContacts({ searchQuery });
  };

  render() {
    const {
      isFetching,
      isFetchingContacts,
      contactsList,
      attachmentField,
      attachmentUrl,
      siteTags,
      customerContacts,
      senderContacts,
      deliveryContacts,
    } = this.props;

    const {
      lineItemsTableFormState,
      shipmentOptionsFormState,
      shipmentAddressSelectorFormState,
      templateFormState,
      extraInformationFormState,
    } = this.formStateContainer;

    return (
      <form onSubmit={this.handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <ContainerFixedHeader
          title={[LABELS.CREATE, DOMAIN.TEMPLATE].join(' ')}
          IconComponent={InboxOutlined}
          mainActionType="submit"
          mainActionLabel={LABELS.CREATE}
          onBack={this.handleBack}
          onBackLabel={LABELS.CANCEL}
          isLoading={isFetching}
        />

        <ContainerContent>
          <Card title="Template details" className={styles.card}>
            <InputField
              fieldState={templateFormState.$.templateName}
              error={templateFormState.$.templateName.error}
              placeholder="Template name"
            />
            <SelectField
              placeholder="Tags"
              mode="multiple"
              options={siteTags.map(tag => ({ value: tag.id, label: tag.name }))}
              value={this.formStateContainer.selectedTags.value.peek()}
              onSelect={(value: string) => {
                this.formStateContainer.selectedTags.$.push(value);
              }}
              onDeselect={(value: string) => {
                this.formStateContainer.selectedTags.$.remove(value);
              }}
            />
          </Card>

          <Card title="Addresses" className={styles.card}>
            <ShipmentAddressSection
              isAddressFieldsRequired={false}
              isFetching={isFetchingContacts}
              formState={shipmentAddressSelectorFormState}
              onCreateContact={this.handleCreateContactFromModal}
              onUpdateContact={this.handleUpdateContactFromModal}
              handleSearchCustomerContact={this.handleSearchCustomerContact}
              handleSearchSenderContact={this.handleSearchSenderContact}
              handleSearchDeliveryAddress={this.handleSearchDeliveryContact}
              customerContacts={unionWith(eqBy(prop('id')), contactsList, customerContacts)}
              senderContacts={unionWith(eqBy(prop('id')), contactsList, senderContacts)}
              deliveryContacts={unionWith(eqBy(prop('id')), contactsList, deliveryContacts)}
            />
          </Card>

          <Card title="Contents" className={styles.card}>
            <div style={{ position: 'relative' }}>
              <ContainerActions.Create
                onCreateLabel={LABELS.ADD}
                onCreate={this.handleAddLineItemItem}
                className={styles.cardContents}
              />
              <LineItemsTableForm
                onItemRemove={this.handleDeleteLineItem}
                showEmptyMessage={false}
                formState={lineItemsTableFormState}
                disabled={false}
              />
            </div>
          </Card>

          <Card title="Consignment details" className={styles.card}>
            <ConsignmentDetailsTable
              formState={this.formStateContainer.consignmentDetailsFormState}
            />
          </Card>

          <Card title="Shipment options" className={styles.card}>
            <ShipmentOptionsForm
              hidePickupPointSelection={true}
              formState={shipmentOptionsFormState}
              carrierAddons={this.props.carrierAddons}
              showAddons={true}
              isPreorder={this.isPreorder.get()}
              changePreorder={this.handleChangePreorder}
              showCustomBookingMethods
            />
          </Card>

          <ExtraInformation
            formState={extraInformationFormState}
            storeFile={this.handleStoreFile}
            attachmentField={attachmentField}
            attachmentUrl={attachmentUrl}
            isLoading={false}
          />
        </ContainerContent>

        <Prompt
          when={this.formStateDirty && !this.formSubmitted}
          message={MESSAGES.LEAVING_DIRTY_FORM}
        />
      </form>
    );
  }
}

const Connected = connect(mapStateToProps, dispatchProps)(Component);
export default Connected;
