import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { Input, Select } from '@src/controls';
import { inequalityOptions } from '@src/controls/select';
import { withFormItem } from '@src/decorators';
import { FormattedMessage } from '@src/i18n';
import { getFieldArrayError } from '@src/utils/forms';
import { number } from 'yup';
import { RuleAddingValues } from '../rule-adding-container';

const SelectItem = withFormItem(Select);
const InputField = withFormItem(Input);

interface Props {
  name: string;
  errors: FormikProps<RuleAddingValues>['errors'];
  touched: FormikProps<RuleAddingValues>['touched'];
  setFieldValue: FormikProps<RuleAddingValues>['setFieldValue'];
}

const totalQuantityValidate = async (value: string) => {
  try {
    await number()
      .required()
      .min(0, 'Total quantity should be a non-negative number')
      .validate(value);
  } catch (err) {
    return err;
  }
};

export const TotalQuantity: React.FunctionComponent<Props> = ({
  setFieldValue,
  name,
  errors,
  touched,
}) => {
  return (
    <div className={styles.wrapper}>
      <Field name={`${name}.operator`}>
        {({ field }: FieldProps<string>) => (
          <SelectItem
            label={<FormattedMessage id="TOTAL_QUANTITY_IS" />}
            className={styles.operator}
            options={inequalityOptions}
            {...field}
            onChange={(value: string) => setFieldValue(`${name}.operator`, value)}
          />
        )}
      </Field>
      <Field name={`${name}.value`} validate={totalQuantityValidate}>
        {({ field }: FieldProps<number>) => (
          <InputField
            {...field}
            type="number"
            className={styles.input}
            min={0}
            error={getFieldArrayError(touched, errors, `${name}.value`)}
          />
        )}
      </Field>
    </div>
  );
};

const styles = stylesheet({
  wrapper: {
    display: 'flex',
    padding: '5px 0',
    $nest: {
      '.ant-row': {
        display: 'flex',
        marginBottom: 0,
      },
      '.ant-form-explain': {
        width: '120px',
      },
    },
  },
  operator: {
    width: '100px !important',
    paddingRight: '10px',
  },
  label: {
    whiteSpace: 'nowrap',
  },
  input: {
    width: '96px',
  },
  grams: {
    marginLeft: '5px',
    paddingTop: '7px',
    color: 'rgba(0,0,0,0.5)',
  },
});
