import React from 'react';
import { NotificationsChannels } from '@src/modules/delivery-tracking-notifications/notifications-channels/container';
import { NotificationsFeatures } from '@src/modules/delivery-tracking-notifications/notifications-features/container';
import { ListFixedHeader } from '@src/components';
import { GlobalOutlined } from '@ant-design/icons';
import { useFormatMessage } from '@src/i18n';

export const DeliveryTrackingNotifications = () => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <ListFixedHeader
        title={formatMessage('NOTIFICATIONS.HEADER')}
        IconComponent={GlobalOutlined}
        showSearch={false}
      />
      <NotificationsChannels />
      <NotificationsFeatures />
    </>
  );
};
