import { MADError } from '@src/utils';
import { services } from '.';

export function logError(error: MADError): void {
  const env = services.envService.getEnvironmentalVariables();
  switch (env.MAD_DEPLOY_TARGET) {
    case 'production':
    case 'stage':
      services.errorsLoggingService.sendError(error);
      break;

    default:
      // tslint:disable-next-line:no-console
      console.error(error);
      break;
  }
}
