import { Status, TrackingEvent } from '@src/api/wimo';
import { TrackingEventOf } from './types';

const isType = <T extends Status>(event: TrackingEvent, types: T[]): event is TrackingEventOf<T> =>
  types.includes(event.tracking_status as T);

export const mergeRelatedEvents = <M extends Status, R extends Status>(
  events: TrackingEvent[],
  map: Array<{
    main: M;
    related: R[];
    getId: (event: TrackingEventOf<M | R>) => string;
  }>
): Array<TrackingEvent & { relatedEvent?: TrackingEventOf<R> }> =>
  map.reduce((events, { main, related, getId }) => {
    const mainEventsMap = new Map(
      events.filter((e): e is TrackingEventOf<M> => isType(e, [main])).map(e => [getId(e), e])
    );
    const relatedEventsMap = new Map(
      events.filter((e): e is TrackingEventOf<M> => isType(e, related)).map(e => [getId(e), e])
    );

    return events
      .filter(
        event =>
          // do not include related event that is referenced by the other
          !isType(event, related) || !mainEventsMap.has(getId(event))
      )
      .map(event => {
        // include non-main events & related that are not referenced
        if (!isType(event, [main])) {
          return event;
        }

        // include an optional relatedEvent to the main event
        return {
          ...event,
          relatedEvent: relatedEventsMap.get(getId(event)),
        };
      });
  }, events);
