import * as qs from 'query-string';

import {
  CreateOrderReportDTO,
  CreateOrderReportModel,
  CreateShipmentReportDTO,
  CreateShipmentReportModel,
  CreateSpecialReportDTO,
  CreateSpecialReportModel,
  CreateReportResponseModel,
  ReportDTO,
  ReportModel,
} from '../models';
import { apiUtils, authFetch } from '../utils';

const REPORTS_URL = apiUtils.getApiUrl() + '/reports';

export const createShipmentsReportWith = async (siteId: string, model: CreateShipmentReportModel): Promise<CreateReportResponseModel> => {
  const payload = CreateShipmentReportDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };

  return authFetch<{ id: string }>(`${REPORTS_URL}/shipments.create`, options).then(res =>
    CreateReportResponseModel.createFromDTO(res)
  );
};

export const createOrdersReportWith = async (siteId: string, model: CreateOrderReportModel): Promise<CreateReportResponseModel> => {
  const payload = CreateOrderReportDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };

  return authFetch<{ id: string }>(`${REPORTS_URL}/orders.create`, options).then(res =>
    CreateReportResponseModel.createFromDTO(res)
  );
};

export const createSpecialReportWith = async (siteId: string, model: CreateSpecialReportModel): Promise<CreateReportResponseModel> => {
  const payload = CreateSpecialReportDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };

  return authFetch<{ id: string }>(`${REPORTS_URL}/special-report.create`, options).then(res =>
    CreateReportResponseModel.createFromDTO(res)
  );
};

export const getShipmentsReportWith = async (siteId: string, reportId: string): Promise<ReportModel> => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<{ shipments: ReportDTO }>(
    `${REPORTS_URL}/shipments.get?${qs.stringify({ id: reportId })}`,
    options
  ).then(res => ReportModel.createFromDTO(res.shipments));
};

export const getOrdersReportWith = async (siteId: string, reportId: string): Promise<ReportModel> => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<{ orders: ReportDTO }>(
    `${REPORTS_URL}/orders.get?${qs.stringify({ id: reportId })}`,
    options
  ).then(res => ReportModel.createFromDTO(res.orders));
};

export const getSpecialReportWith = async (siteId: string, reportId: string): Promise<ReportModel> => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<{ report: ReportDTO }>(
    `${REPORTS_URL}/special-report.get?${qs.stringify({ id: reportId })}`,
    options
  ).then(res => ReportModel.createFromDTO(res.report));
};
