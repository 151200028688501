import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal/Modal';
import * as React from 'react';

export interface IProps extends ModalProps {
  content: string | JSX.Element;
}

const modalConfirm = (props: IProps) =>
  Modal.confirm({
    title: props.title,
    content: props.content,
    okText: props.okText,
    cancelText: props.cancelText,
    okType: 'primary',
    okButtonProps: {
      danger: true,
    },
    onOk: props.onOk,
    onCancel: props.onCancel,
    icon: <QuestionCircleOutlined />,
  });

export default modalConfirm;
