import { InboxOutlined } from '@ant-design/icons';
import { configSelectors } from '@src/modules/config';
import { routerActions } from '@src/modules/router';
import { siwActions, siwSelectors } from '@src/modules/siw';
import { tagsActions, tagsSelectors } from '@src/modules/tags';
import { services } from '@src/services';
import { Modal as AntModal } from 'antd';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { eqBy, equals, path, pathOr, prop, unionWith } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import * as React from 'react';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Option } from 'space-lift';
import { classes, style as tss } from 'typestyle';
import { v4 } from 'uuid';

import { ContainerActions, ContainerContent, ContainerFixedHeader } from '../../components';
import { Card, Modal, Select } from '../../controls';
import { DOMAIN, LABELS, MESSAGES } from '../../dictionaries';
import { InputFieldForm } from '../../forms';
import {
  AddonModel,
  AddressModel,
  ContactInfoModel,
  ContactModel,
  CustomerInfoModel,
  ExternalOrderContentItemModel,
  LineItemModel,
  ShipmentCreateModel,
  ShipmentModel,
  TransportOrderContentItemModel,
  TransportOrderModel,
} from '../../models';
import { RootState } from '../../modules';
import { addressBookActions } from '../../modules/address-book';
import { blobsActions, blobsSelectors } from '../../modules/blobs';
import { dictionariesActions } from '../../modules/dictionaries';
import { eventTrackingActions } from '../../modules/event-tracking';
import { labelMergerActions, LabelMergerSelectors } from '../../modules/label-merger';
import { shipmentsActions, ShipmentsSelectors } from '../../modules/shipments';
import {
  transportOrdersActionCreators,
  TransportOrdersSelectors,
} from '../../modules/transport-orders';
import { userprofileActions } from '../../modules/userprofile';
import { defaultTheme } from '../../styles';
import { decimalToStringWithoutFactorial } from '../../utils/currency';
import { isFormStateContainerDirty } from '../../utils/forms';
import { isValidFormStateContainer } from '../../utils/validation';
import { CheckoutWidgetConfiguration } from '../checkout-widget';
import {
  ConsignmentDetailsFormState,
  ConsignmentDetailsTable,
  createLineItemFormState,
  DeliveryTime,
  DeliveryTimeFormState,
  ExternalOrdersTable,
  ExtraInformation,
  ExtraInformationFormState,
  LineItemFormState,
  LineItemsTableForm,
  LineItemsTableFormState,
  MultipleShipmentsCreateAndBookModal,
  ShipmentAddressSection,
  ShipmentAddressSelectorFormState,
  ShipmentOptionsForm,
  ShipmentOptionsFormState,
  TransportOrderIdForm,
  TransportOrdersTable,
  unwrapLineItemFormState,
} from './components';
import { InvoiceNumber, InvoiceNumberFormState } from './components/invoice-number';

const styles = {
  card: tss({
    marginBottom: defaultTheme.lineHeight,
  }),
  cardContents: tss({ marginBottom: defaultTheme.lineHeight }),
};

// Component API
type OwnProps = {
  tosId?: string;
};

const mapStateToProps = (state: RootState) => ({
  isFetching: state.transportOrders.isFetching,
  isFetchingContacts: state.addressBook.isFetching,
  isFetchingTransportOrders: state.transportOrders.isFetching,
  isFetchingTemplates: state.userprofile.isFetching,
  carrierAddons: state.dictionaries.carrierAddons,
  contactsList: state.addressBook.contacts,
  customerContacts: state.addressBook.customerContacts,
  senderContacts: state.addressBook.senderContacts,
  deliveryContacts: state.addressBook.deliveryContacts,
  transportOrders: TransportOrdersSelectors.getTransportOrderList(state),
  shipmentDraft: state.shipments.draft,
  shipmentsError: state.shipments.error,
  templates: state.userprofile.templates,
  siteId: state.sites.selectedSiteId,
  multipleRequestsStatus: ShipmentsSelectors.getMultipleRequestsStatus(state),
  multipleRequestsProgress: ShipmentsSelectors.getMultipleRequestsProgress(state),
  multipleRequestsLabelsFileNames: ShipmentsSelectors.getMultipleRequestsLabelsFileNames(state),
  attachmentField: blobsSelectors.attachmentField(state),
  attachmentUrl: blobsSelectors.attachmentUrl(state),
  mergedLabel: LabelMergerSelectors.getMergedLabel(state),
  isFetchingMergedLabel: LabelMergerSelectors.getIsFetchingMergedLabel(state),
  userTags: tagsSelectors.getUserTags(state),
  userProfileError: state.userprofile.error,
  sessionId: siwSelectors.getSessionId(state),
  locationAddress: siwSelectors.getLocationAddress(state),
  deliveryTime: siwSelectors.getDeliveryTime(state),
  configuredShippingMethodsNames: configSelectors.getShippingMethodNamesConfiguredForBooking(state),
  isReturnShipment: state.router.location.query.return === 'true',
});

const dispatchProps = {
  createShipment: shipmentsActions.createShipmentRequest,
  getContacts: addressBookActions.getContactsListRequest,
  getCustomerContacts: addressBookActions.getCustomerContactsRequest,
  getSenderContacts: addressBookActions.getSenderContactsRequest,
  getDeliveryContacts: addressBookActions.getDeliveryContactsRequest,
  createContactFromModal: addressBookActions.createContactFromModalRequest,
  updateContactFromModal: addressBookActions.updateContactFromModalRequest,
  createTempContact: addressBookActions.createTempContact,
  getTransportOrder: transportOrdersActionCreators.getTransportOrderRequest,
  getTransportOrderList: transportOrdersActionCreators.getTransportOrderListRequest,
  getCarrierAddons: dictionariesActions.getCarrierAddonsRequest,
  getTemplateList: userprofileActions.getTemplateListRequest,
  draftShipmentLoad: shipmentsActions.draftShipmentLoad,
  draftShipmentClear: shipmentsActions.draftShipmentClear,
  createAndBookShipments: shipmentsActions.createAndBookShipmentsRequest,
  resetCreateAndBookModal: shipmentsActions.createAndBookShipmentsReset,
  storeFile: blobsActions.storeFileRequest,
  storeFileSuccess: blobsActions.storeFileSuccess,
  getToken: blobsActions.getTokenRequest,
  resetAttachment: blobsActions.resetAttachment,
  mergeLabels: labelMergerActions.mergeLabelsRequest,
  resetMergedLabels: labelMergerActions.resetMerged,
  viewShipmentCreatePage: eventTrackingActions.viewShipmentCreatePage,
  selectShipmentTemplate: eventTrackingActions.selectShipmentTemplate,
  selectTransportOrder: eventTrackingActions.selectTransportOrder,
  getUserTags: tagsActions.listUserTagsRequest,
  clearTransportOrdersList: transportOrdersActionCreators.clearTransportOrdersList,
  getSession: siwActions.getSessionRequest,
  routerPush: routerActions.push,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & OwnProps;

type State = {
  isSelectPickupPointModalVisible: boolean;
};

@observer
export class Component extends React.Component<Props, State> {
  state: State = {
    isSelectPickupPointModalVisible: false,
  };

  @observable
  formStateContainer = {
    tosIdFormState: TransportOrderIdForm.createFormState(),
    externalIdFormState: InputFieldForm.createFormState(),
    lineItemsTableFormState: LineItemsTableFormState.create(),
    shipmentOptionsFormState: ShipmentOptionsFormState.create(),
    shipmentAddressSelectorFormState: ShipmentAddressSelectorFormState.create(),
    extraInformationFormState: ExtraInformationFormState.create(),
    deliveryTimeFormState: DeliveryTimeFormState.create(),
    consignmentDetailsFormState: ConsignmentDetailsFormState.create(),
    invoiceNumberFormState: InvoiceNumberFormState.create(),
  };

  @observable transportOrders = observable<TransportOrderContentItemModel>([]);
  @observable externalOrders = observable<ExternalOrderContentItemModel>([]);
  consignmentFormState = observable.map<any>();
  @observable isPreorder = observable<boolean>();
  templateContacts = observable<ContactModel>([]);

  @observable formSubmitted = false;
  @observable activeTemplate: string;
  @observable isMultipleShipmentsCreateAndBookModalVisible = false;

  @computed
  get formStateDirty() {
    return isFormStateContainerDirty(this.formStateContainer);
  }

  async componentDidMount() {
    const {
      tosId,
      getTransportOrder,
      siteId,
      viewShipmentCreatePage,
      getContacts,
      getUserTags,
      clearTransportOrdersList,
      getCarrierAddons,
      resetAttachment,
      getTemplateList,
      resetMergedLabels,
    } = this.props;

    viewShipmentCreatePage();

    if (siteId) {
      getUserTags({
        siteId,
        onComplete: tags =>
          getTemplateList({
            siteId,
            tags: tags.map(tag => tag.id),
          }),
      });
      getContacts({});
      getCarrierAddons();

      if (tosId) {
        getTransportOrder({
          id: tosId,
          onComplete: transportOrderResponse => {
            if (transportOrderResponse) {
              this.handleTransportOrderChange(transportOrderResponse);
            }
          },
        });
        this.formStateContainer.tosIdFormState.$.transportOrderIdFieldState.value = tosId;
      }
    }
    clearTransportOrdersList();
    resetAttachment();
    resetMergedLabels();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      shipmentDraft,
      carrierAddons,
      contactsList,
      locationAddress,
      deliveryTime,
      transportOrders,
      sessionId,
      siteId,
      getSession,
    } = this.props;

    if (shipmentDraft != null && contactsList != null && carrierAddons != null) {
      if (
        shipmentDraft !== prevProps.shipmentDraft ||
        contactsList !== prevProps.contactsList ||
        carrierAddons !== prevProps.carrierAddons
      ) {
        this.reinitializeFormState(shipmentDraft, transportOrders[0]?.receiver.destination);
      }
    }

    if (!equals(prevProps.locationAddress, locationAddress) && locationAddress) {
      const { toAddressSelectorFormState } =
        this.formStateContainer.shipmentAddressSelectorFormState.$;
      const toContactModel = new ContactModel();
      toContactModel.address = {
        ...locationAddress,
        coordinates: {
          lat: locationAddress.coordinates?.lat.toString() ?? '0',
          lng: locationAddress.coordinates?.lng.toString() ?? '0',
        },
      };
      toContactModel.id = v4();

      this.templateContacts.push(toContactModel);
      toAddressSelectorFormState.$.addressIdFieldState.value = toContactModel.id;
    }
    if (prevState.isSelectPickupPointModalVisible !== this.state.isSelectPickupPointModalVisible) {
      if (sessionId && siteId) {
        getSession({ siteId, sessionId });
      }
    }

    if (!equals(prevProps.deliveryTime, deliveryTime) && deliveryTime) {
      this.formStateContainer.deliveryTimeFormState = DeliveryTimeFormState.create(deliveryTime);
    }
  }

  componentWillUnmount() {
    this.props.draftShipmentClear();
  }

  getLocationData = (destination: ContactInfoModel | undefined) => {
    const locationExternalId = destination?.locationRef ?? '';
    if (locationExternalId) {
      return {
        locationExternalId,
        locationName: destination?.name ?? '',
      };
    }
    return {
      locationExternalId: '',
      locationName: '',
    };
  };

  reinitializeFormState = (model: ShipmentModel, destination?: ContactInfoModel | undefined) => {
    this.formStateContainer.tosIdFormState = TransportOrderIdForm.createFormState(model.tosId);
    this.formStateContainer.externalIdFormState = InputFieldForm.createFormState(model.externalId);
    this.formStateContainer.lineItemsTableFormState = LineItemsTableFormState.create(
      model &&
        Option(model.contents.goods)
          .getOrElse(model.lineItems)
          .map(lI => ({ active: false, lineItem: lI }))
    );

    const locationData = this.getLocationData(destination);

    this.formStateContainer.shipmentOptionsFormState = ShipmentOptionsFormState.create({
      shipmentValue: model.shipmentValue,
      shippingDate: model.shippingDate,
      shippingMethod: model.parcels?.[0]?.deliveries?.[0]?.shippingMethod,
      availableAddons: this.props.carrierAddons,
      enabledAddons: model.addons,
      locationName: locationData.locationName,
      locationExternalId: locationData.locationExternalId,
      meta: model.meta,
      courierInstructions: model.parcels?.[0]?.deliveries?.[0]?.courierInstructions || '',
      doorCode: pathOr('', ['addressTo', 'doorCode'], model),
    });
    this.formStateContainer.shipmentAddressSelectorFormState =
      ShipmentAddressSelectorFormState.create();
    this.loadAddresses({
      addressFrom: model.addressFrom,
      addressTo: model.addressTo,
      customerInfo: model.customerInfo,
      addressReturn: model.addressReturn,
    });
    this.transportOrders.replace(model.contents.transportOrders);
    this.externalOrders.replace(model.contents.externalOrders);
    this.formStateContainer.extraInformationFormState = ExtraInformationFormState.create({
      notes: model?.meta?.notes,
      attachment: model?.meta?.attachment,
    });
    this.formStateContainer.deliveryTimeFormState = DeliveryTimeFormState.create({
      start: model.parcels?.[0]?.deliveries?.[0]?.deliveryTime?.start,
      end: model.parcels?.[0]?.deliveries?.[0]?.deliveryTime?.end,
    });
    this.formStateContainer.invoiceNumberFormState = InvoiceNumberFormState.create({
      invoiceNumber: model?.meta?.['label_mod_data.invoice_num'],
    });

    if (
      path(['meta', 'attachment'], model) &&
      this.props.siteId &&
      this.props.attachmentUrl === ''
    ) {
      this.props.storeFileSuccess(model.meta.attachment);
      this.props.getToken({ siteId: this.props.siteId, url: model.meta.attachment });
    }
  };

  loadAddresses = ({
    addressFrom,
    addressTo,
    customerInfo,
    addressReturn,
  }: {
    addressFrom: AddressModel;
    addressTo: AddressModel;
    customerInfo: CustomerInfoModel;
    addressReturn?: AddressModel;
  }) => {
    this.templateContacts.clear();
    const {
      customerAddressSelectorFormState,
      fromAddressSelectorFormState,
      toAddressSelectorFormState,
      returnAddressSelectorFormState,
    } = this.formStateContainer.shipmentAddressSelectorFormState.$;

    const customerContactModel = new ContactModel();
    customerContactModel.address = { ...customerInfo.address };

    if (
      !customerContactModel.address.postalCode ||
      !customerContactModel.address.country ||
      !customerContactModel.address.addressLines
    ) {
      customerAddressSelectorFormState.$.addressIdFieldState.value = '';
    } else {
      if (customerInfo.address.name) {
        customerContactModel.address.name = customerInfo.address.name;
      }
      customerContactModel.email = customerInfo.email;
      customerContactModel.phone = customerInfo.phone;
      this.templateContacts.push(customerContactModel);
      customerAddressSelectorFormState.$.addressIdFieldState.value = customerContactModel.id;
    }

    const fromDuplicate = this.templateContacts.find(templateContact =>
      equals(templateContact.address, addressFrom)
    );
    if (fromDuplicate) {
      fromAddressSelectorFormState.$.addressIdFieldState.value = fromDuplicate.id;
    } else {
      const fromContactModel = new ContactModel();
      fromContactModel.address = { ...addressFrom };
      if (addressFrom.name) {
        fromContactModel.address.name = addressFrom.name;
      }
      this.templateContacts.push(fromContactModel);
      fromAddressSelectorFormState.$.addressIdFieldState.value = fromContactModel.id;
    }

    const returnDuplicate = this.templateContacts.find(templateContact =>
      equals(templateContact.address, addressReturn)
    );
    if (returnDuplicate) {
      returnAddressSelectorFormState.$.addressIdFieldState.value = returnDuplicate.id;
    } else if (addressReturn && addressReturn.addressLines.length) {
      const returnContactModel = new ContactModel();
      returnContactModel.address = { ...addressReturn };
      if (addressReturn.name) {
        returnContactModel.address.name = addressReturn.name;
      }
      this.templateContacts.push(returnContactModel);
      returnAddressSelectorFormState.$.addressIdFieldState.value = returnContactModel.id;
    }

    const toDuplicate = this.templateContacts.find(templateContact =>
      equals(templateContact.address, addressTo)
    );
    if (toDuplicate) {
      toAddressSelectorFormState.$.addressIdFieldState.value = toDuplicate.id;
    } else {
      const toContactModel = new ContactModel();
      toContactModel.address = { ...addressTo };
      if (addressTo.name) {
        toContactModel.address.name = addressTo.name;
      }
      this.templateContacts.push(toContactModel);
      toAddressSelectorFormState.$.addressIdFieldState.value = toContactModel.id;
    }
  };

  clearFormState = () => {
    this.formStateContainer.tosIdFormState = TransportOrderIdForm.createFormState();
    this.formStateContainer.externalIdFormState = InputFieldForm.createFormState();
    this.formStateContainer.lineItemsTableFormState = LineItemsTableFormState.create();
    this.formStateContainer.shipmentOptionsFormState = ShipmentOptionsFormState.create();
    this.formStateContainer.consignmentDetailsFormState = ConsignmentDetailsFormState.create();
    this.formStateContainer.shipmentAddressSelectorFormState =
      ShipmentAddressSelectorFormState.create();
    this.transportOrders = observable<TransportOrderContentItemModel>([]);
    this.externalOrders = observable<ExternalOrderContentItemModel>([]);
    this.consignmentFormState = observable.map<any>();
    this.formStateContainer.extraInformationFormState = ExtraInformationFormState.create();
    this.formStateContainer.deliveryTimeFormState = DeliveryTimeFormState.create();
    this.formStateContainer.invoiceNumberFormState = InvoiceNumberFormState.create();
  };

  setModalVisiblity = (to: boolean) => {
    if (to) {
      services.siwService.resumeWidget();
    } else {
      services.siwService.suspendWidget();
    }
    this.setState({
      isSelectPickupPointModalVisible: to,
    });
  };

  createShipmentFromInput = (): ShipmentCreateModel => {
    const {
      tosIdFormState,
      shipmentOptionsFormState,
      shipmentAddressSelectorFormState,
      lineItemsTableFormState,
      externalIdFormState,
      extraInformationFormState,
      deliveryTimeFormState,
      consignmentDetailsFormState,
      invoiceNumberFormState,
    } = this.formStateContainer;

    const { contactsList, customerContacts, deliveryContacts, senderContacts } = this.props;

    const addons = shipmentOptionsFormState.$.carrierAddonsFormState.$.filter(a => a.$.isOn).map(
      a => ({ code: a.$.code } as AddonModel)
    );

    const {
      fromAddressSelectorFormState,
      toAddressSelectorFormState,
      customerAddressSelectorFormState,
      returnAddressSelectorFormState,
    } = shipmentAddressSelectorFormState.$;

    const allContacts = [
      ...contactsList,
      ...customerContacts,
      ...deliveryContacts,
      ...senderContacts,
      ...this.templateContacts.slice(),
    ];

    const shipment = new ShipmentCreateModel();
    shipment.tosId = tosIdFormState.$.transportOrderIdFieldState.$;

    const customerId = customerAddressSelectorFormState.$.addressIdFieldState.value;
    const toAddressId = toAddressSelectorFormState.$.addressIdFieldState.value;
    const fromAddressId = fromAddressSelectorFormState.$.addressIdFieldState.value;
    const returnAddressId = returnAddressSelectorFormState.$.addressIdFieldState.value;

    const customerAddressBookModel = allContacts.find(item => item.id === customerId);
    const toAddressBookModel = allContacts.find(item => item.id === toAddressId);
    const fromAddressBookModel = allContacts.find(item => item.id === fromAddressId);
    const returnAddressBookModel = allContacts.find(item => item.id === returnAddressId);

    const customerModel = new CustomerInfoModel();
    customerModel.address =
      (customerAddressBookModel && customerAddressBookModel.address) || new AddressModel();
    customerModel.email = (customerAddressBookModel && customerAddressBookModel.email) || '';
    customerModel.phone = (customerAddressBookModel && customerAddressBookModel.phone) || '';
    shipment.customerInfo = customerModel;

    shipment.addressFrom =
      (fromAddressBookModel && fromAddressBookModel.address) || new AddressModel();
    shipment.addressReturn = returnAddressBookModel?.address;
    shipment.addressTo = (toAddressBookModel && toAddressBookModel.address) || new AddressModel();

    const shippingDate =
      shipmentOptionsFormState.$.shippingDateSelectorFormState.$.dateFieldState.$;
    shipment.shippingDate = (shippingDate && shippingDate.toISOString()) || '';
    shipment.shippingMethod =
      shipmentOptionsFormState.$.shippingMethodSelectorFormState.$.shippingMethodFieldState.$.toString();
    shipment.shipmentValue = decimalToStringWithoutFactorial(
      shipmentOptionsFormState.$.valueFieldState.$
    );
    shipment.locationRef =
      shipmentOptionsFormState.$.pickupPointFormState.$.locationExternalId.toString();

    shipment.meta = shipmentOptionsFormState.$.metadataFieldState.$;

    shipment.courierInstructions =
      shipmentOptionsFormState.$.shipmentOptionsDetailsFormState.$.courierInstructions.$;
    shipment.addressTo.doorCode =
      shipmentOptionsFormState.$.shipmentOptionsDetailsFormState.$.doorCode.$;

    const notes = extraInformationFormState.$.notesFieldState.$;
    if (notes) {
      shipment.meta = { ...shipment.meta, notes };
    }

    const attachment = extraInformationFormState.$.attachmentFieldState.$;
    if (attachment) {
      shipment.meta = { ...shipment.meta, attachment };
    }

    const invoiceNumber = invoiceNumberFormState.$.invoiceNumberFieldState.$;
    if (invoiceNumber) {
      shipment.meta = {
        ...shipment.meta,
        ['label_mod_data.invoice_num']: invoiceNumber,
      };
    }

    shipment.contents = {
      externalOrders: this.externalOrders.slice(),
      transportOrders: this.transportOrders.slice(),
      goods: [...lineItemsTableFormState.$.peek().map(lI => unwrapLineItemFormState(lI).lineItem)],
    };

    shipment.externalId = externalIdFormState.$.inputFieldState.$;

    shipment.addons = addons;

    shipment.numberOfParcels = consignmentDetailsFormState.$.numberOfParcels.$;
    shipment.dimensions = consignmentDetailsFormState.$.dimensions.$;
    shipment.weight = consignmentDetailsFormState.$.weight.$;
    shipment.parcelFreeText = consignmentDetailsFormState.$.freeText.$;

    if (deliveryTimeFormState.$.start.$ && deliveryTimeFormState.$.end.$) {
      shipment.deliveryTime = {
        start: deliveryTimeFormState.$.start.$.format(),
        end: deliveryTimeFormState.$.end.$.format(),
      };
    }

    shipment.customsDeclaration = this.props.shipmentDraft?.customs?.declaration;

    return shipment;
  };

  handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const { tosId, siteId, createShipment, isReturnShipment, shipmentDraft } = this.props;
    const { shippingMethodSelectorFormState, shippingDateSelectorFormState } =
      this.formStateContainer.shipmentOptionsFormState.$;
    const formStatesToValidate = {
      tosIdFormState: this.formStateContainer.tosIdFormState,
      externalIdFormState: this.formStateContainer.externalIdFormState,
      lineItemsTableFormState: this.formStateContainer.lineItemsTableFormState,
      shipmentAddressSelectorFormState: this.formStateContainer.shipmentAddressSelectorFormState,
      extraInformationFormState: this.formStateContainer.extraInformationFormState,
      shippingMethod: shippingMethodSelectorFormState,
      shippingDate: shippingDateSelectorFormState,
      deliveryTime: this.formStateContainer.deliveryTimeFormState,
    };

    if (!(await isValidFormStateContainer(formStatesToValidate)) || siteId === null) {
      return;
    }

    const returnAddressId =
      formStatesToValidate.shipmentAddressSelectorFormState.$.returnAddressSelectorFormState.$
        .addressIdFieldState.value;

    if (!returnAddressId && !isReturnShipment) {
      AntModal.confirm({
        content: 'You have not submitted a return address. Do you wish to proceed anyway?',
        okText: 'Confirm',
        onOk: () => {
          const shipment = this.createShipmentFromInput();
          createShipment({ shipment, redirectId: tosId, siteId });
          this.formSubmitted = true;
        },
        cancelText: 'Cancel',
      });

      return;
    }

    const shipment = this.createShipmentFromInput();

    const shipmentModel: ShipmentCreateModel = isReturnShipment
      ? { ...shipment, directionType: 'RETURN', outboundShipmentId: shipmentDraft?.id }
      : shipment;

    createShipment({ shipment: shipmentModel, redirectId: tosId, siteId });
    this.formSubmitted = true;
  };

  createAndBookMultipleShipments = async (numberOfShipments: number) => {
    const { siteId, createAndBookShipments } = this.props;

    if (siteId === null) {
      return;
    }

    const shipment = this.createShipmentFromInput();

    createAndBookShipments({
      numberOfShipments,
      siteId,
      shipmentModel: shipment,
    });
    this.formSubmitted = true;
  };

  handleCancel = () => {
    const { tosId, routerPush } = this.props;
    if (tosId) {
      routerPush({ name: 'TRANSPORT_ORDER_DETAILS', tosId });
    } else {
      routerPush({ name: 'SHIPMENT_LIST' });
    }
  };

  handleSearchCustomerContact = (searchQuery: string) => {
    this.props.getCustomerContacts({ searchQuery });
  };

  handleSearchSenderContact = (searchQuery: string) => {
    this.props.getSenderContacts({ searchQuery });
  };

  handleSearchDeliveryContact = (searchQuery: string) => {
    this.props.getDeliveryContacts({ searchQuery });
  };

  handleAddTransportOrderItem = () => {
    this.transportOrders.push({
      externalRef: '',
      tosId: '',
    });
  };

  handleUpdateTransportOrderItem = (index: number, item: any) => {
    this.transportOrders[index] = item;
  };

  handleDeleteTransportOrderItem = (index: number) => {
    this.transportOrders.remove(this.transportOrders[index]);
  };

  handleAddExternalOrderItem = () => {
    this.externalOrders.push({
      externalId: '',
    });
  };

  handleUpdateExternalOrderItem = (index: number, item: any) => {
    this.externalOrders[index] = item;
  };

  handleDeleteExternalOrderItem = (index: number) => {
    this.externalOrders.remove(this.externalOrders[index]);
  };

  handleAddLineItemItem = () => {
    const { lineItemsTableFormState } = this.formStateContainer;
    lineItemsTableFormState.$.push(createLineItemFormState(true, new LineItemModel()));
  };

  handleDeleteLineItem = (item: LineItemFormState) => {
    const { lineItemsTableFormState } = this.formStateContainer;

    lineItemsTableFormState.$.remove(item);
  };

  handleChangePreorder = (b: boolean) => {
    this.isPreorder.set(b);
  };

  handleStoreFile = (file: File) => {
    const { siteId, storeFile } = this.props;
    if (siteId) {
      storeFile({ siteId, file });
    }
  };

  handleMergeLabels = (fileNames: string[]) => {
    const { siteId, mergeLabels } = this.props;
    if (siteId) {
      mergeLabels({ siteId, fileNames });
    }
  };

  handleTemplateChange = (value: string) => {
    this.props.selectShipmentTemplate();
    this.activeTemplate = value;
    if (value == null) {
      this.clearFormState();
      return;
    }
    const { templates } = this.props;
    const template = templates.find(t => t.id === value);
    if (template == null) {
      return;
    }

    this.formStateContainer.lineItemsTableFormState = LineItemsTableFormState.create(
      template && template.contents.goods
        ? template.contents.goods.map(lI => ({ active: false, lineItem: lI }))
        : []
    );
    this.formStateContainer.shipmentOptionsFormState = ShipmentOptionsFormState.createFromTemplate(
      template,
      this.props.carrierAddons,
      undefined,
      undefined
    );
    this.formStateContainer.shipmentAddressSelectorFormState =
      ShipmentAddressSelectorFormState.create();
    this.loadAddresses({
      addressFrom: template.addressFrom,
      addressTo: template.addressTo,
      customerInfo: template.customerInfo,
      addressReturn: template.addressReturn,
    });

    this.formStateContainer.extraInformationFormState = ExtraInformationFormState.create({
      notes: template?.meta?.notes,
      attachment: template?.meta?.attachment,
    });

    this.formStateContainer.invoiceNumberFormState = InvoiceNumberFormState.create({
      invoiceNumber: template?.meta?.['label_mod_data.invoice_num'],
    });

    this.formStateContainer.consignmentDetailsFormState =
      ConsignmentDetailsFormState.create(template);

    if (
      template &&
      path(['meta', 'attachment'], template) &&
      this.props.siteId &&
      this.props.attachmentUrl === ''
    ) {
      this.props.storeFileSuccess(template.meta.attachment);
      this.props.getToken({ siteId: this.props.siteId, url: template.meta.attachment });
    }
  };

  handleCreateAndBookModalButtonClick = async () => {
    if (!(await isValidFormStateContainer(this.formStateContainer))) {
      return;
    }
    this.props.resetCreateAndBookModal();
    this.isMultipleShipmentsCreateAndBookModalVisible = true;
  };

  handleCreateAndBookModalCancel = () =>
    (this.isMultipleShipmentsCreateAndBookModalVisible = false);

  handleTransportOrderChange = (transportOrderToSet?: TransportOrderModel) => {
    if (!transportOrderToSet) {
      this.clearFormState();
      this.props.clearTransportOrdersList();
      return;
    }

    const customerInfo = new CustomerInfoModel();
    customerInfo.address = transportOrderToSet.receiver.contactInfo.address;
    customerInfo.email = transportOrderToSet.receiver.contactInfo.email;
    customerInfo.phone = transportOrderToSet.receiver.contactInfo.phone;

    this.formStateContainer.externalIdFormState = InputFieldForm.createFormState(
      (transportOrderToSet.external.references?.find(ref => ref.name === 'COS') || {}).id || ''
    );
    this.formStateContainer.shipmentAddressSelectorFormState =
      ShipmentAddressSelectorFormState.create();
    this.loadAddresses({
      addressFrom: transportOrderToSet.sender.contactInfo.address,
      addressTo: transportOrderToSet.receiver.destination
        ? transportOrderToSet.receiver.destination.address
        : {
            ...transportOrderToSet.receiver.contactInfo.address,
            doorCode: transportOrderToSet.receiver.doorCode,
          },
      customerInfo,
    });
    this.formStateContainer.tosIdFormState = TransportOrderIdForm.createFormState(
      transportOrderToSet.id
    );
    this.formStateContainer.lineItemsTableFormState = LineItemsTableFormState.create(
      Option(transportOrderToSet.cart.items)
        .fold<LineItemModel[], LineItemModel[]>(
          () => [],
          () =>
            transportOrderToSet.cart.items.map(item => {
              const lineItem = new LineItemModel();
              lineItem.sku = item.sku || '';
              lineItem.name = item.name || '';
              lineItem.quantity = item.quantity || 0;
              lineItem.dimensions = item.dimensions || { height: 0, width: 0, length: 0 };
              lineItem.price = item.price || 0;
              lineItem.currency = item.currency || '';
              lineItem.weight = item.weight || 0;
              lineItem.tags = item.attributes;
              return lineItem;
            })
        )
        .map(lI => ({ active: false, lineItem: lI }))
    );

    let shippingMethod = '';
    if (transportOrderToSet.metadata.session?.customShippingMethod) {
      shippingMethod = transportOrderToSet.metadata.session?.method;
    } else if (transportOrderToSet.route?.shippingLegs) {
      shippingMethod = transportOrderToSet.route.shippingLegs.slice(-1)[0].shippingMethod;
    } else {
      shippingMethod = transportOrderToSet.metadata.session?.method;
    }

    this.formStateContainer.shipmentOptionsFormState = ShipmentOptionsFormState.create({
      availableAddons: this.props.carrierAddons,
      locationName: this.getLocationData(transportOrderToSet.receiver.destination).locationName,
      locationExternalId: this.getLocationData(transportOrderToSet.receiver.destination)
        .locationExternalId,
      courierInstructions: transportOrderToSet.receiver.courierInstructions,
      doorCode: transportOrderToSet.receiver.doorCode,
      shippingMethod,
    });
    this.formStateContainer.deliveryTimeFormState = DeliveryTimeFormState.create(
      transportOrderToSet.metadata.session.timeSlot.start !== '' &&
        transportOrderToSet.metadata.session.timeSlot.end !== ''
        ? {
            start: transportOrderToSet.metadata.session.timeSlot.start,
            end: transportOrderToSet.metadata.session.timeSlot.end,
          }
        : undefined
    );
  };

  handleUpdateContactFromModal = (contact: ContactModel) =>
    isNotNil(contact.createdAt) && this.props.updateContactFromModal(contact);

  handleCreateContactFromModal = (contact: ContactModel, shouldSaveInAddressBook: boolean) =>
    shouldSaveInAddressBook
      ? this.props.createContactFromModal(contact)
      : this.props.createTempContact(contact);

  render() {
    const {
      isFetchingContacts,
      isFetchingTransportOrders,
      contactsList,
      transportOrders,
      tosId,
      templates,
      multipleRequestsProgress,
      multipleRequestsStatus,
      multipleRequestsLabelsFileNames,
      attachmentField,
      attachmentUrl,
      shipmentsError,
      mergedLabel,
      isFetchingMergedLabel,
      resetMergedLabels,
      customerContacts,
      senderContacts,
      deliveryContacts,
      isReturnShipment,
    } = this.props;
    const allContacts = [...contactsList, ...this.templateContacts.slice()];
    const {
      tosIdFormState,
      shipmentOptionsFormState,
      shipmentAddressSelectorFormState,
      lineItemsTableFormState,
      externalIdFormState,
      extraInformationFormState,
      deliveryTimeFormState,
      invoiceNumberFormState,
    } = this.formStateContainer;

    return (
      <>
        <Modal
          title="Select pickup point"
          visible={this.state.isSelectPickupPointModalVisible}
          onOk={() => this.setModalVisiblity(false)}
          onCancel={() => this.setModalVisiblity(false)}
          width={720}
        >
          <CheckoutWidgetConfiguration doNotUseDraft={true} />
        </Modal>
        <form onSubmit={this.handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
          <ContainerFixedHeader
            title={[LABELS.CREATE, DOMAIN.SHIPMENT].join(' ')}
            IconComponent={InboxOutlined}
            mainActionType="submit"
            mainActionLabel={LABELS.CREATE}
            onBack={this.handleCancel}
            onBackLabel={LABELS.CANCEL}
            additionalActionsConfig={[
              {
                handler: this.handleCreateAndBookModalButtonClick,
                label: LABELS.CREATE_AND_BOOK_MULTIPLE_ACTION,
              },
            ]}
            error={shipmentsError}
          />

          <ContainerContent>
            <Card title="Templates" className={styles.card}>
              <Select
                placeholder="please select a template to fill the form with predefined data"
                notFoundContent="No templates found, you could create a new"
                options={templates
                  .filter(template => template.name !== '')
                  .map(({ name, id }) => ({ label: name, value: id }))}
                onChange={this.handleTemplateChange}
                style={{ width: defaultTheme.tosIdFormWidth }}
                value={this.activeTemplate}
                className="t_template-select"
                dropdownClassName="t_template-select-dropdown"
              />
            </Card>
            <Card title="References" className={styles.card}>
              <TransportOrderIdForm
                disabled={!!tosId}
                transportOrders={transportOrders}
                formState={tosIdFormState}
                isFetching={isFetchingTransportOrders}
                onChange={(value: string) => {
                  this.props.selectTransportOrder();
                  const order = this.props.transportOrders.find(
                    transportOrder => transportOrder.id === value
                  );
                  this.handleTransportOrderChange(order);
                }}
                onSearchTransportOrder={searchQuery =>
                  this.props.getTransportOrderList({ searchQuery, pageSize: 10, pageNumber: 1 })
                }
                clearTransportOrdersList={this.props.clearTransportOrdersList}
              />
              <InputFieldForm formState={externalIdFormState} label="Shipment external ID" />
            </Card>
            <Card title="Addresses" className={styles.card}>
              <ShipmentAddressSection
                isFetching={isFetchingContacts}
                formState={shipmentAddressSelectorFormState}
                onCreateContact={this.handleCreateContactFromModal}
                onUpdateContact={this.handleUpdateContactFromModal}
                handleSearchCustomerContact={this.handleSearchCustomerContact}
                handleSearchSenderContact={this.handleSearchSenderContact}
                handleSearchDeliveryAddress={this.handleSearchDeliveryContact}
                customerContacts={unionWith(eqBy(prop('id')), allContacts, customerContacts)}
                senderContacts={unionWith(eqBy(prop('id')), allContacts, senderContacts)}
                deliveryContacts={unionWith(eqBy(prop('id')), allContacts, deliveryContacts)}
                isReturnShipment={isReturnShipment}
              />
            </Card>

            <Card title="Shipment options" className={styles.card}>
              <ShipmentOptionsForm
                formState={shipmentOptionsFormState}
                carrierAddons={this.props.carrierAddons}
                showAddons={true}
                isPreorder={this.isPreorder.get()}
                changePreorder={this.handleChangePreorder}
                onOpenWidgetButtonClick={() => this.setModalVisiblity(true)}
                showCustomBookingMethods
                shipmentId={this.props.shipmentDraft?.id}
              />
            </Card>

            <Card title="Delivery Time" className={styles.card}>
              <DeliveryTime formState={deliveryTimeFormState} />
            </Card>
            <Card title="Order Content" className={styles.card}>
              <div>
                <ContainerActions.Create
                  onCreateLabel={LABELS.ADD}
                  onCreate={this.handleAddLineItemItem}
                  className={classes(styles.cardContents, 't_line-items-add')}
                />
                <LineItemsTableForm
                  onItemRemove={this.handleDeleteLineItem}
                  showEmptyMessage={false}
                  formState={lineItemsTableFormState}
                  disabled={false}
                />
              </div>
              <div>
                <ContainerActions.Create
                  onCreateLabel={LABELS.ADD}
                  onCreate={this.handleAddTransportOrderItem}
                  className={classes(styles.cardContents, 't_transport-orders-add')}
                />
                <TransportOrdersTable
                  size={'middle'}
                  showEmptyMessage={false}
                  items={this.transportOrders || []}
                  onItemDelete={this.handleDeleteTransportOrderItem}
                  onItemUpdate={this.handleUpdateTransportOrderItem}
                />
              </div>
              <div>
                <ContainerActions.Create
                  onCreateLabel={LABELS.ADD}
                  onCreate={this.handleAddExternalOrderItem}
                  className={classes(styles.cardContents, 't_external-orders-add')}
                />
                <ExternalOrdersTable
                  size={'middle'}
                  showEmptyMessage={false}
                  items={this.externalOrders || []}
                  onItemDelete={this.handleDeleteExternalOrderItem}
                  onItemUpdate={this.handleUpdateExternalOrderItem}
                />
              </div>
            </Card>
            <Card title="Consignment details" className={styles.card}>
              <ConsignmentDetailsTable
                formState={this.formStateContainer.consignmentDetailsFormState}
                initialMode="edit"
              />
            </Card>
            <ExtraInformation
              formState={extraInformationFormState}
              storeFile={this.handleStoreFile}
              attachmentField={attachmentField}
              attachmentUrl={attachmentUrl}
            >
              <InvoiceNumber
                disabled={false}
                formState={invoiceNumberFormState}
                label="Invoice number"
              />
            </ExtraInformation>
          </ContainerContent>

          <Prompt
            when={this.formStateDirty && !this.formSubmitted}
            message={MESSAGES.LEAVING_DIRTY_FORM}
          />
          <MultipleShipmentsCreateAndBookModal
            visible={this.isMultipleShipmentsCreateAndBookModalVisible}
            onCancel={this.handleCreateAndBookModalCancel}
            onSubmit={this.createAndBookMultipleShipments}
            requestsStatus={multipleRequestsStatus}
            progress={multipleRequestsProgress}
            labelsFileNames={multipleRequestsLabelsFileNames}
            mergeLabels={this.handleMergeLabels}
            mergedLabel={mergedLabel}
            isFetchingMergedLabel={isFetchingMergedLabel}
            resetMergedLabels={resetMergedLabels}
          />
        </form>
      </>
    );
  }
}

const Connected = connect(mapStateToProps, dispatchProps)(Component);
Connected.displayName = 'ShipmentCreateContainer';
export default Connected;
