import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import { services } from '@src/services';
import { ExperimentWithVariantModel } from '@src/models';

type ReturnType = [UseQueryResult<ExperimentWithVariantModel>, () => void];

export const useExperimentQuery = (siteId: string, experimentId: string): ReturnType => {
  const queryClient = useQueryClient();

  const query = useQuery<ExperimentWithVariantModel>({
    queryKey: ['experiment', siteId, experimentId],
    queryFn: async () => {
      return await services.experimentService.getExperiment(siteId, experimentId);
    },
    refetchOnWindowFocus: false,
    staleTime: 5 * 1000,
  });

  const invalidateQuery = () => queryClient.invalidateQueries(['experiment', siteId, experimentId]);

  return [query, invalidateQuery];
};
