import * as React from 'react';

interface Props {
  className?: string;
  fill?: string;
}

export const LinkIcon: React.FC<Props> = ({ className, fill = 'black' }) => {
  return (
    <svg
      width="12"
      viewBox="0 0 12 12"
      fill="none"
      height="12"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.10016 0C2.72267 -2.01655e-07 2.41665 0.306019 2.41665 0.683513C2.41665 1.06101 2.72267 1.36703 3.10016 1.36703L9.6664 1.36703L0.200257 10.8332C-0.0666712 11.1001 -0.0666709 11.5329 0.200257 11.7998C0.467186 12.0667 0.899963 12.0667 1.16689 11.7998L10.6188 2.34789V8.88567C10.6188 9.26317 10.9248 9.56919 11.3023 9.56919C11.6798 9.56919 11.9858 9.26317 11.9858 8.88567L11.9858 0.822629C12.0309 0.604802 11.9689 0.369241 11.7999 0.200196C11.6646 0.0649182 11.4867 -0.00180145 11.3094 3.7191e-05L11.3023 7.77813e-07L3.10016 0Z"
        fill={fill}
      />
    </svg>
  );
};
