import { TeamOutlined } from '@ant-design/icons';
import { style as tss } from 'typestyle';

import { AddButton, ListFixedHeader, SimpleList } from '@src/components';
import { Button, Modal, Spinner } from '@src/controls';
import { DOMAIN } from '@src/dictionaries';
import { FormattedMessage } from '@src/i18n';
import { RBACGroupModel, ScopeType } from '@src/models';
import { RootState } from '@src/modules';
import { usersActions, usersSelectors } from '@src/modules/users';
import * as React from 'react';
import { connect } from 'react-redux';
import { GroupForm, GroupFormValues, GroupsList } from './components';

type OwnProps = {
  merchantId: string;
  siteId: string;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  isFetching: state.users.fetching,
  groups: usersSelectors.getGroupsPerSite(state),
  roles: usersSelectors.getSortedRoles(state),
  users: state.users.users,
  groupsUsersDict: usersSelectors.getAttachedUsersToGroup(state),
  merchantId: ownProps.merchantId,
  siteId: ownProps.siteId,
});
const dispatchProps = {
  getGroups: usersActions.getGroupListRequest,
  createGroup: usersActions.createGroupRequest,
  updateGroup: usersActions.updateGroupRequest,
  deleteGroup: usersActions.deleteGroupRequest,
  getRoles: usersActions.listRolesRequest,
  getUsers: usersActions.fetchListUsers,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const Component: React.FunctionComponent<Props> = ({
  groups,
  roles,
  siteId,
  merchantId,
  isFetching,
  getGroups,
  getRoles,
  createGroup,
  deleteGroup,
  updateGroup,
  groupsUsersDict,
  users,
  getUsers,
}) => {
  const [localMerchantId, setLocalMerchantId] = React.useState('');
  const [groupModalVisible, setGroupModalVisible] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState<RBACGroupModel | undefined>(undefined);
  const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);
  const [groupToDelete, setGroupToDelete] = React.useState('');

  React.useEffect(() => {
    if (!groups || merchantId !== localMerchantId) {
      setLocalMerchantId(merchantId);
      getGroups({ merchantId, siteId });
    }
  }, [groups, merchantId]);

  React.useEffect(() => {
    if (!roles) {
      getRoles();
    }
  }, [roles]);

  React.useEffect(() => {
    if (!users) {
      getUsers();
    }
  }, [users]);

  const handleCreateGroup = (values: GroupFormValues) => {
    createGroup({
      model: {
        name: values.name,
        roleIds: values.roleIds,
        scope: {
          id: values.scope === ScopeType.MERCHANT ? merchantId : siteId,
          type: values.scope,
        },
      },
      onComplete: () => {
        setGroupModalVisible(false);
        getGroups({ merchantId, siteId });
      },
    });
  };

  const handleUpdateGroup = (values: GroupFormValues) => {
    if (!selectedGroup) {
      return;
    }
    updateGroup({
      model: {
        ...values,
        id: selectedGroup.id,
      },
      onComplete: () => {
        setGroupModalVisible(false);
        getGroups({ merchantId, siteId });
      },
    });
  };

  const handleItemClick = (group: RBACGroupModel) => {
    setSelectedGroup(group);
    setGroupModalVisible(true);
  };

  return (
    <>
      <ListFixedHeader title={DOMAIN.GROUPS} IconComponent={TeamOutlined} showSearch={false} />
      {groups ? (
        <>
          <GroupsList
            groups={groups}
            isFetching={isFetching}
            onClickItem={handleItemClick}
            onDeleteItem={groupId => {
              setDeleteModalVisible(true);
              setGroupToDelete(groupId);
            }}
          />
          {groupToDelete && (
            <Modal
              destroyOnClose
              title={<FormattedMessage id="DELETE_GROUP" />}
              width={500}
              footer={
                groupsUsersDict[groupToDelete].length ? (
                  <Button type="primary" onClick={() => setDeleteModalVisible(false)}>
                    OK
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => {
                      setGroupToDelete('');
                      setDeleteModalVisible(false);
                      deleteGroup({ id: groupToDelete });
                    }}
                  >
                    <FormattedMessage id="DELETE" />
                  </Button>
                )
              }
              onCancel={() => setDeleteModalVisible(false)}
              visible={deleteModalVisible}
              afterClose={() => setGroupToDelete('')}
            >
              {groupsUsersDict[groupToDelete].length ? (
                <SimpleList title={<FormattedMessage id="CANNOT_DELETE_GROUP" />}>
                  {groupsUsersDict[groupToDelete].map(group => (
                    <SimpleList.Li key={group.id}>{group.email}</SimpleList.Li>
                  ))}
                </SimpleList>
              ) : (
                <FormattedMessage id="GROUP_DELETE_CONFIRM_BODY" />
              )}
            </Modal>
          )}
        </>
      ) : (
        <Spinner active />
      )}
      <Modal
        destroyOnClose
        title={<FormattedMessage id={selectedGroup ? 'EDIT_GROUP' : 'NEW_GROUP'} />}
        footer={null}
        width={500}
        onCancel={() => setGroupModalVisible(false)}
        visible={groupModalVisible}
        afterClose={() => setSelectedGroup(undefined)}
      >
        <GroupForm
          onSubmit={selectedGroup ? handleUpdateGroup : handleCreateGroup}
          roles={roles || []}
          model={selectedGroup}
        />
      </Modal>
      <AddButton
        className={styles.addButton}
        onClick={() => setGroupModalVisible(true)}
        text={<FormattedMessage id="NEW_GROUP" />}
      />
    </>
  );
};

const styles = { addButton: tss({ width: '100px', padding: 0, margin: '15px' }) };

export const GroupsListContainer = connect(mapStateToProps, dispatchProps)(Component);
