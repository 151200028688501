import { notification } from 'antd';

notification.config({
  placement: 'bottomRight',
});

export const error = (
  description: React.ReactNode | string,
  message = 'Error - changes not saved',
  duration = 0
) => notification.error({ message, description, duration });

export const success = (description: React.ReactNode | string, message = 'Success', duration = 5) =>
  notification.success({ message, description, duration });

export const info = (description: React.ReactNode | string, message = 'Info', duration = 5) =>
  notification.info({ message, description, duration });
