import { Badge, Popover } from 'antd';
import React from 'react';
import { stylesheet } from 'typestyle';
import { useFormatMessage } from '@src/i18n';
import Skeleton from 'react-loading-skeleton';
import * as enUSMessages from '../../../../../locales/en-US.json';

type Props = {
  textHeader: keyof typeof enUSMessages;
  featureStatus?: boolean;
  isLoading?: boolean;
};

export const FeaturesStatus = ({ textHeader, featureStatus, isLoading }: Props) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <div className={styles.container}>
        <span className={styles.statusText}>{formatMessage(`${textHeader}`)}</span>
        {isLoading ? (
          <Skeleton width="85px" height="20px" />
        ) : (
          <Badge
            status={featureStatus ? 'success' : 'error'}
            className={styles.badge}
            text={
              <Popover
                overlayClassName={styles.popover}
                content={formatMessage('NOTIFICATIONS.SETTINGS.POPOVER_MESSAGE')}
                placement="right"
              >
                {featureStatus
                  ? formatMessage('NOTIFICATIONS.CHANNELS.STATUS.ENABLED')
                  : formatMessage('NOTIFICATIONS.CHANNELS.STATUS.DISABLED')}
              </Popover>
            }
          />
        )}
      </div>
    </>
  );
};

const styles = stylesheet({
  container: {
    display: 'flex',
    marginBottom: '0.5rem',
  },
  statusText: {
    fontSize: '1rem',
    paddingRight: '1rem',
  },
  badge: {
    $nest: {
      '.ant-badge-status-dot': {
        width: '10px',
        height: '10px',
        top: '-2px',
      },
    },
    '.ant-badge-status-text': {
      fontSize: '1rem',
    },
  },
  popover: {
    $nest: {
      '.ant-popover-inner-content': {
        width: '315px',
        whiteSpace: 'break-spaces',
      },
    },
  },
});
