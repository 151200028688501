import { Field, FieldProps, useField, useFormikContext } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Select, PriceInput } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { widerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { getAvailableLocalesOptions } from '@src/modules/dictionaries/selectors';
import { services } from '@src/services';
import { getFormikError } from '@src/utils/forms';
import { WidgetFormValues } from '../helpers';

const SelectField = withFormItem(Select, widerLayout);
const PriceField = withFormItem(PriceInput, widerLayout);

interface OwnProps {
  widgetReady: boolean;
  showRegion: boolean;
  country: string;
}

export const General: React.FunctionComponent<OwnProps> = ({
  widgetReady,
  showRegion,
  country,
}) => {
  const { errors, setFieldValue, touched } = useFormikContext<WidgetFormValues>();
  const availableLocalesOptions = useSelector(getAvailableLocalesOptions);

  return (
    <>
      <FormikSelectField
        name="purchaseCountry"
        label={<FormattedMessage id="COUNTRY" />}
        required
        options={services.dictionariesService.getCountriesOptions()}
      />

      {showRegion && (
        <FormikSelectField
          name="purchaseRegion"
          label={<FormattedMessage id="REGION" />}
          options={services.dictionariesService.getRegionsOptions(country)}
        />
      )}

      {!widgetReady && (
        <>
          <FormikSelectField
            name="purchaseCurrency"
            label={<FormattedMessage id="CURRENCY" />}
            required
            options={services.dictionariesService.getCurrenciesOptions()}
          />
          <FormikSelectField
            name="locale"
            label={<FormattedMessage id="LOCALE" />}
            required
            options={availableLocalesOptions}
          />
        </>
      )}

      <Field name="totalValue">
        {({ field }: FieldProps<WidgetFormValues['totalValue']>) => (
          <PriceField
            {...field}
            label={<FormattedMessage id="TOTAL_CART_VALUE" />}
            required={true}
            error={getFormikError(touched.totalValue, errors.totalValue)}
            value={field.value}
            onChange={value => setFieldValue('totalValue', value)}
          />
        )}
      </Field>
    </>
  );
};

export const FormikSelectField: React.FunctionComponent<{
  name: string;
  label: React.ReactNode;
  required?: boolean;
  options: any[];
}> = ({ name, label, required, options }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <SelectField
      {...field}
      label={label}
      required={required}
      options={options}
      filterOption={true}
      showSearch
      optionFilterProp={'label'}
      error={getFormikError(meta.touched, meta.error)}
      onChange={value => helpers.setValue(value)}
      getPopupContainer={() => document.getElementById('select-scroll-helper') || document.body}
    />
  );
};
