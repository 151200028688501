import { LinkStyleButton } from '@src/components';
import { useFormatMessage } from '@src/i18n';
import { CarrierProductSettingsMapping } from '@src/models';
import { listCarrierProductSettings } from '@src/services/selfcare-service';
import { useSelector, useSiteId } from '@src/utils/hooks';
import { Col, Row, Switch, Table } from 'antd';
import React from 'react';
import { useQuery } from 'react-query';
import { stylesheet } from 'typestyle';
import { TrackingConfigStatus } from '../components/tracking-config-status';
import { IntegratedCarrierProductsUpdate } from './update';
import { useUpsertIntegratedMappingMutation } from './mutations';
import { selectGlobalFetching } from '../utils';

type TableItem = CarrierProductSettingsMapping;

export const IntegratedCarrierProductsTable = () => {
  const { integratedCarrierProducts, isFetching } = useIntegratedCarrierProducts();
  const upsertIntegratedMappingMutation = useUpsertIntegratedMappingMutation();
  const isGlobalFetching = useSelector(selectGlobalFetching);

  const [updateModalState, setUpdateModalState] = React.useState<{
    isVisible: boolean;
    carrierProductId: string;
    carrierProductName: string;
  }>({
    isVisible: false,
    carrierProductId: '',
    carrierProductName: '',
  });

  const formatMessage = useFormatMessage();

  return (
    <>
      <IntegratedCarrierProductsUpdate
        isVisible={updateModalState.isVisible}
        onCancel={() =>
          setUpdateModalState({
            isVisible: false,
            carrierProductId: '',
            carrierProductName: '',
          })
        }
        carrierProductId={updateModalState.carrierProductId}
        carrierProductName={updateModalState.carrierProductName}
        carrierProductsSettingsMapping={integratedCarrierProducts}
        isLoading={upsertIntegratedMappingMutation.isLoading}
        onHandleMutate={values => {
          upsertIntegratedMappingMutation.mutate(values);
        }}
      />
      <Row className={styles.row} data-cy="product-mappings-ingrid-section">
        <Col span="24">
          <Table<TableItem>
            className={styles.table}
            dataSource={integratedCarrierProducts ?? []}
            pagination={false}
            loading={isFetching || isGlobalFetching}
            rowKey={record => record.carrier_product_id}
          >
            <Table.Column<TableItem>
              title={formatMessage('CARRIER_PRODUCT', { multiple: false })}
              key="carrier_product_id"
              defaultSortOrder="ascend"
              sorter={(itemA, itemB) => {
                // sort based on what we display and fallback to what BE returns
                if (
                  itemA.carrier_product_carrier &&
                  itemB.carrier_product_carrier &&
                  itemA.carrier_product_name &&
                  itemB.carrier_product_name
                ) {
                  return `${itemA.carrier_product_carrier} ${itemA.carrier_product_name}`.localeCompare(
                    `${itemB.carrier_product_carrier} ${itemB.carrier_product_name}`
                  );
                }
                return itemA.carrier_product_id.localeCompare(itemB.carrier_product_id);
              }}
              render={(_, record) => {
                const carrierProductName = getCarrierProductName(
                  record.carrier_product_id,
                  record.carrier_product_carrier,
                  record.carrier_product_name
                );

                return (
                  <LinkStyleButton
                    onClick={() =>
                      setUpdateModalState({
                        isVisible: true,
                        carrierProductId: record.carrier_product_id,
                        carrierProductName,
                      })
                    }
                  >
                    {carrierProductName}
                  </LinkStyleButton>
                );
              }}
            />
            <Table.Column<TableItem>
              title={formatMessage('TRACKING_CONFIG_STATUS')}
              key="tracking_config_status"
              render={(_, { custom, tracking_config_status }) =>
                custom ? '' : <TrackingConfigStatus status={tracking_config_status} />
              }
            />
            <Table.Column<TableItem>
              title={formatMessage('ALWAYS_SHOW_TRACKING_LINKS')}
              key="always_show_tracking_links"
              render={(_, record) => (
                <Switch
                  onChange={value => {
                    upsertIntegratedMappingMutation.mutate({
                      ...record,
                      always_show_tracking_links: value,
                    });
                  }}
                  checked={record.tracking_links && record.always_show_tracking_links}
                  disabled={!record.tracking_links || upsertIntegratedMappingMutation.isLoading}
                />
              )}
            />
          </Table>
        </Col>
      </Row>
    </>
  );
};

const getCarrierProductName = (
  carrier_product_id: CarrierProductSettingsMapping['carrier_product_id'],
  carrier_product_carrier: CarrierProductSettingsMapping['carrier_product_carrier'],
  carrier_product_name: CarrierProductSettingsMapping['carrier_product_name']
) => {
  if (!carrier_product_id) {
    return '—';
  }
  if (!carrier_product_carrier && !carrier_product_name) {
    return carrier_product_id;
  }
  return `${carrier_product_carrier} ${carrier_product_name}`;
};

const useIntegratedCarrierProducts = () => {
  const siteId = useSiteId();

  const { data, isLoading: isIntegratedCarrierProductListFetching } = useQuery(
    ['carrier_product_settings.list', siteId],
    () => listCarrierProductSettings(siteId),
    { enabled: !!siteId, keepPreviousData: true }
  );

  return {
    integratedCarrierProducts: data?.carrier_product_settings,
    isFetching: isIntegratedCarrierProductListFetching,
  };
};

const styles = stylesheet({
  row: { marginTop: '20px' },
  table: {
    $nest: {
      '.ant-table-tbody tr td': {
        borderBottom: '1px solid rgba(0,0,0,0.09)',
      },
      '.ant-table-tbody tr:hover td.group-header': {
        background: 'unset',
      },
      '.ant-table-thead tr th': {
        background: 'rgba(0,0,0,0.02)',
        borderBottom: '1px solid rgba(0,0,0,0.09)',
      },
    },
  },
});
