import { getEnvironmentalVariables } from '@src/services/env-service';
import { Skeleton } from 'antd';
import * as React from 'react';
import { useQuery } from 'react-query';

const { MAD_CDN_URL } = getEnvironmentalVariables();

const LOGO_MAP_URL = `${MAD_CDN_URL}/static/shipping-logos/logo-map.json`;
const LOGO_SIZE = 30;

type LogoMapEntry = {
  name: string;
  patterns: string[];
  pathRound30: string;
};

type LogoMap = LogoMapEntry[];

type Props = {
  className?: string;
  shippingMethod: string;
};

export const ShippingLogo: React.FC<Props> = ({ className, shippingMethod }) => {
  const { logoMap, isFetching } = useLogoMap();

  if (isFetching) {
    return <Skeleton.Avatar size={LOGO_SIZE} />;
  }

  const logo = logoMap(shippingMethod);
  if (!logo) {
    return null;
  }

  const path = isAbsolutePath(logo.pathRound30)
    ? logo.pathRound30
    : `${MAD_CDN_URL}${logo.pathRound30}`;

  return (
    <img
      className={className}
      src={path}
      alt={logo.name}
      width={`${LOGO_SIZE}px`}
      height={`${LOGO_SIZE}px`}
    />
  );
};

export const ShippingLogoName: React.FC<Props> = ({ className, shippingMethod }) => {
  const { logoMap } = useLogoMap();

  const logo = logoMap(shippingMethod);
  if (!logo) {
    return null;
  }
  return <span className={className}>{logo.name}</span>;
};

export const fetchLogoMap = () => {
  // do not replace fetch with axios, because axios does not work with preload from index.html
  return fetch(LOGO_MAP_URL).then(response => response.json() as Promise<LogoMap>);
};

export const useLogoMap = () => {
  const query = useQuery('logo_map', fetchLogoMap, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const logoMap = (shippingMethod: string | undefined): LogoMapEntry | undefined => {
    if (shippingMethod === undefined || !query.data) {
      return undefined;
    }
    return query.data.find(({ patterns }) =>
      patterns.some(pattern => shippingMethod.match(pattern))
    );
  };

  return { ...query, logoMap };
};

const isAbsolutePath = (path: string) => {
  const link = document.createElement('a');
  link.href = path;
  return link.origin + link.pathname + link.search + link.hash === path;
};
