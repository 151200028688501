import * as React from 'react';
import { style } from 'typestyle';

import { Tag } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { defaultTheme } from '@src/styles';

type WarehouseHeaderProps = {
  name: string;
};

export const WarehouseHeader: React.FunctionComponent<WarehouseHeaderProps> = ({ name }) => (
  <div>
    <Tag color={defaultTheme.color.primary} nonClickable={true}>
      <FormattedMessage id="WAREHOUSE" />
    </Tag>
    <h2 className={styles.header}>{name}</h2>
  </div>
);

const styles = {
  header: style({
    marginTop: '10px',
  }),
  deleteButton: style({
    float: 'right',
  }),
};
