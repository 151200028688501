import { Col, Row } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { mapCountryNameToCode } from '@src/dictionaries/mapping';
import { FormattedMessage } from '@src/i18n';
import { configSelectors } from '@src/modules/config';
import { updateCountrySettingsRequest } from '@src/modules/config/actions';
import { siwActions } from '@src/modules/siw';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { useDispatch, useSelector } from '@src/utils/hooks';
import { Tooltip } from '@src/controls';
import { InfoCircleOutlined } from '@ant-design/icons';

import { PreselectionOrderLink } from './preselection-order-link';
import { PreselectionOrderModal } from './preselection-order-modal';
import { PreselectionOrderTable } from './preselection-orders-table';

interface OwnProps {
  country: string;
}

export const Country: React.FC<OwnProps> = ({ country }) => {
  const dispatch = useDispatch();
  const capitalizedCountry = country.charAt(0).toUpperCase() + country.substring(1);
  const countryISO = mapCountryNameToCode(capitalizedCountry);

  const sortedCategories = useSelector(state =>
    configSelectors.getCategoriesWithPreselectionOrder(state, countryISO)
  );
  const unsortedCategories = useSelector(state =>
    configSelectors.getCategoriesWithoutPreselectionOrder(state, countryISO)
  );
  const isCreatingDraft = useSelector(state => state.config.isCreatingDraft);

  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const showPreselectionOrderLink = unsortedCategories.length > 0;

  return (
    <div>
      <Row>
        <Col span={4} className={!showPreselectionOrderLink ? styles.tableLabel : undefined}>
          <FormattedMessage id="PRESELECTION_ORDER" />
          <Tooltip title={<FormattedMessage id="PRESELECTION_ORDER_DISCLAIMER" />}>
            <InfoCircleOutlined style={{ marginLeft: '5px' }} />
          </Tooltip>
        </Col>
        <Col span={20}>
          {showPreselectionOrderLink ? (
            <PreselectionOrderLink
              onClick={() => {
                setIsModalVisible(true);
              }}
              isLoading={isCreatingDraft}
              updateMode={sortedCategories.length > 0}
            />
          ) : (
            <PreselectionOrderTable categories={sortedCategories} countryISO={countryISO} />
          )}
          <PreselectionOrderModal
            isVisible={isModalVisible}
            sortedCategories={sortedCategories}
            unsortedCategories={unsortedCategories}
            countryISO={countryISO}
            onAccept={(categoryOrder, fallbackState) => {
              setIsModalVisible(false);
              dispatchOnDraftSiteId(updateCountrySettingsRequest)({
                model: {
                  country: countryISO,
                  countrySettings: categoryOrder.map((categoryId, idx) => ({
                    categoryId,
                    preselectionOrder: idx + 1,
                    useForFallback: fallbackState[categoryId],
                  })),
                },
                onComplete: () => dispatch(siwActions.resetWidgetSession()),
              });
            }}
            onCancel={() => {
              setIsModalVisible(false);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

const styles = stylesheet({
  tableLabel: {
    display: 'flex',
    alignItems: 'center',
    height: '54px',
  },
});
