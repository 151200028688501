import React, { FC, useEffect } from 'react';
import { Checkbox, Form, Table, Tooltip } from 'antd';
import { ColumnType } from 'antd/es/table';
import { stylesheet as tss } from 'typestyle';
import { insert } from 'ramda';

import { ReceiptWidgetConfiguration } from '@src/models';

import {
  featureDefinitions,
  FeatureDefinitions,
  ReceiptFeaturesConfigFormValues,
  getInitialValues,
} from '../helpers';

const { Item } = Form;

type Props = {
  loading: boolean;
  receiptFeatures?: ReceiptWidgetConfiguration['features'];
  onSubmit: (param: ReceiptFeaturesConfigFormValues) => unknown;
  defaultsColumn?: ColumnType<FeatureDefinitions>;
  country?: string;
};

export const FeaturesTable: FC<Props> = ({
  loading,
  receiptFeatures,
  defaultsColumn,
  country,
  onSubmit,
}) => {
  const [form] = Form.useForm();

  const columns: ColumnType<FeatureDefinitions>[] = [
    {
      title: 'Feature name',
      key: 'feature_name',
      render: (_, { description, title }) => <Tooltip title={description}>{title}</Tooltip>,
    },
    {
      title: country ?? 'Default',
      key: country ?? 'default',
      align: 'center',
      render: (_, featureDef) => (
        <Item name={featureDef.name} valuePropName="checked" style={{ margin: 0 }}>
          <Checkbox />
        </Item>
      ),
    },
  ];

  const withDefaultsColumn = (defaultsColumn && insert(1, defaultsColumn, columns)) ?? columns;

  const initialValues = getInitialValues(receiptFeatures);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  return (
    <Form form={form} initialValues={initialValues} onValuesChange={onSubmit}>
      <Table
        className={styles.table}
        rowKey="name"
        dataSource={featureDefinitions}
        columns={withDefaultsColumn}
        loading={loading}
        pagination={false}
      />
    </Form>
  );
};

const styles = tss({
  table: {
    width: '660px',
    $nest: {
      '.ant-table-tbody tr td': {
        borderBottom: '1px solid rgba(0,0,0,0.09)',
      },
      '.ant-table-tbody tr:hover td.group-header': {
        background: 'unset',
      },
      '.ant-table-thead tr th': {
        background: 'rgba(0,0,0,0.02)',
        borderBottom: '1px solid rgba(0,0,0,0.09)',
      },
    },
  },
});
