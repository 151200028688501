import * as React from 'react';
import { Drawer } from 'antd';
import { ShoppingOutlined } from '@ant-design/icons';
import { stylesheet } from 'typestyle';

import { ProductPageWidgetConfiguration } from './widget-configuration';

export const ProductPageDrawer: React.FC = () => {
  const [isWidgetDrawerVisible, setWidgetDrawerVisible] = React.useState(false);
  return (
    <>
      <div
        className={styles.widgetDrawer}
        onClick={() => {
          setWidgetDrawerVisible(true);
        }}
      >
        <ShoppingOutlined style={{ fontSize: '22px' }} />
      </div>
      <Drawer
        visible={isWidgetDrawerVisible}
        onClose={() => {
          setWidgetDrawerVisible(false);
        }}
        width={780}
      >
        <ProductPageWidgetConfiguration visible={isWidgetDrawerVisible} />
      </Drawer>
    </>
  );
};

ProductPageDrawer.displayName = 'ProductPageWidgetDrawer';

const styles = stylesheet({
  widgetDrawer: {
    width: '55px',
    height: '55px',
    borderRadius: '50%',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'fixed',
    right: '20px',
    bottom: '20px',
    background: '#fff',
  },
});
