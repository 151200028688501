import { AppstoreOutlined } from '@ant-design/icons';
import * as React from 'react';
import { connect } from 'react-redux';
import { style as tss } from 'typestyle';

import { DOMAIN, LABELS } from '../../dictionaries';
import { RootState } from '../../modules/';
import { eventTrackingActions } from '../../modules/event-tracking';
import {
  transportOrdersActionCreators,
  TransportOrdersSelectors,
} from '../../modules/transport-orders';
import { isWithinTransportOrdersRoute } from '../../utils/url';

import { ListFixedHeader } from '../../components';
import { Card } from './../../controls';
import { TransportOrderList } from './components/transport-order-list';

import { CreateOrderReportModel, TransportOrderListFiltersModel } from '@src/models';
import { routerActions } from '@src/modules/router';
import { reportsActions, reportsSelectors } from '@src/modules/reports';
import { isEmpty } from 'ramda';

// Component API
const mapStateToProps = (state: RootState) => ({
  isFetching: state.transportOrders.isFetching,
  filters: state.transportOrders.filters,
  pageSize: state.app.pageSize,
  ordersCount: state.transportOrders.count,
  transportOrders: TransportOrdersSelectors.getTransportOrderList(state),
  paginationNumber: state.transportOrders.paginationNumber,
  siteId: state.sites.selectedSiteId,
  reportsRequestStatus: reportsSelectors.getReportRequestStatus(state),
});
const dispatchProps = {
  getTransportOrderList: transportOrdersActionCreators.getTransportOrderListRequest,
  clearActiveFilters: transportOrdersActionCreators.clearActiveTransportOrderFilters,
  updateTransportOrderListPagination:
    transportOrdersActionCreators.updateTransportOrderListPagination,
  viewTOListPage: eventTrackingActions.viewTOListPage,
  routerPush: routerActions.push,
  exportOrders: reportsActions.createOrdersReportRequest,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;
type State = {
  currentSiteId: string | null;
  currentFilterValue: string;
  isModalVisible: boolean;
};

class TransportOrderListContainer extends React.Component<Props, State> {
  state: State = {
    currentSiteId: this.props.siteId,
    currentFilterValue: '',
    isModalVisible: false,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.siteId !== prevState.currentSiteId) {
      const {
        pageSize,
        getTransportOrderList,
        updateTransportOrderListPagination,
        clearActiveFilters,
      } = nextProps;
      updateTransportOrderListPagination(1);
      clearActiveFilters();
      getTransportOrderList({
        pageSize,
        pageNumber: 1,
      });
      return {
        currentFilterValue: '',
        currentSiteId: nextProps.siteId,
      };
    }

    return prevState;
  }

  componentDidMount() {
    const { pageSize, filters, getTransportOrderList, paginationNumber, viewTOListPage } =
      this.props;
    const filtersToPass = filters ? filters : undefined;
    getTransportOrderList({
      pageSize,
      pageNumber: paginationNumber,
      filters: filtersToPass,
    });
    viewTOListPage();
  }

  componentWillUnmount() {
    if (!isWithinTransportOrdersRoute(window.location.pathname)) {
      this.props.updateTransportOrderListPagination(1);
    }
  }

  handleListItemClick = (itemId: string) => {
    this.props.routerPush({ name: 'TRANSPORT_ORDER_DETAILS', tosId: itemId });
  };

  handlePaginationChange = (nextPage: number, direction: string) => {
    const { pageSize, filters, updateTransportOrderListPagination, getTransportOrderList } =
      this.props;
    updateTransportOrderListPagination(nextPage);
    const filtersToPass = filters ? filters : undefined;
    getTransportOrderList({
      pageSize,
      pageNumber: nextPage,
      direction,
      searchQuery: this.state.currentFilterValue,
      filters: filtersToPass,
    });
  };

  handleSearchItems = (value: string) => {
    const { pageSize, filters, updateTransportOrderListPagination, getTransportOrderList } =
      this.props;
    const filtersToPass = filters ? filters : undefined;
    updateTransportOrderListPagination(1);
    this.setState({
      currentFilterValue: value,
    });
    getTransportOrderList({
      pageSize,
      pageNumber: 1,
      searchQuery: value,
      filters: filtersToPass,
    });
  };

  handleFiltersApply = (filters: TransportOrderListFiltersModel) => {
    if (isEmpty(filters.createdAtRange) && isEmpty(filters.sources)) return;

    const { pageSize, updateTransportOrderListPagination, getTransportOrderList } = this.props;
    updateTransportOrderListPagination(1);
    getTransportOrderList({
      pageSize,
      pageNumber: 1,
      searchQuery: this.state.currentFilterValue,
      filters,
    });
  };

  handleFiltersClear = () => {
    const {
      pageSize,
      updateTransportOrderListPagination,
      getTransportOrderList,
      clearActiveFilters,
    } = this.props;
    this.setState({
      currentFilterValue: '',
    });
    updateTransportOrderListPagination(1);
    getTransportOrderList({
      pageSize,
      pageNumber: 1,
      searchQuery: this.state.currentFilterValue,
    });
    clearActiveFilters();
  };

  toggleVisibiltyOfModal = () =>
    this.setState(prevState => ({
      isModalVisible: !prevState.isModalVisible,
    }));

  handleExportItems = () => {
    const { exportOrders, siteId, filters } = this.props;

    if (!filters && this.state.currentFilterValue === '') return;

    const model: CreateOrderReportModel = {
      filtering: {
        id: this.state.currentFilterValue,
        sources: filters?.sources,
        createdAtRange: filters
          ? {
              start: filters.createdAtRange[0],
              end: filters.createdAtRange[1],
            }
          : undefined,
      },
    };

    if (siteId) {
      exportOrders({ siteId, model });
    }
  };

  render() {
    const { transportOrders, isFetching, pageSize, ordersCount, paginationNumber } = this.props;

    return (
      <div className={styles.root}>
        <ListFixedHeader
          title={DOMAIN.TRANSPORT_ORDERS}
          IconComponent={AppstoreOutlined}
          onSearchItems={this.handleSearchItems}
          isLoading={isFetching}
          searchPlaceholder={LABELS.TRANSPORT_ORDERS_SEARCH_PLACEHOLDER}
          filtersSearchValue={this.state.currentFilterValue}
        />
        <Card bordered={false} className={styles.card}>
          <TransportOrderList
            isLoading={isFetching}
            pageSize={pageSize}
            currentPage={paginationNumber}
            total={ordersCount}
            onPaginationChange={this.handlePaginationChange}
            items={transportOrders}
            onItemClick={this.handleListItemClick}
            onFiltersApply={this.handleFiltersApply}
            onFiltersClear={this.handleFiltersClear}
            onExportItems={this.handleExportItems}
            isAnyItemSelected={() =>
              (this.props.filters !== null || this.state.currentFilterValue !== '') &&
              transportOrders.length > 0
            }
          />
        </Card>
      </div>
    );
  }
}

const styles = {
  root: tss({
    display: 'flex',
    flexDirection: 'column',
  }),
  card: tss({
    overflowY: 'auto',
    minHeight: '500px',
  }),
};
const Connected = connect(mapStateToProps, dispatchProps)(TransportOrderListContainer);
export default Connected;
