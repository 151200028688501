import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import * as React from 'react';

import { services } from '@src/services';

type Props = {
  doNotReadFromLocalStorageFor?: string[];
  localStorageKey?: string;
};

export const PersistEnhancer: React.FunctionComponent<Props> = ({
  doNotReadFromLocalStorageFor = [],
  localStorageKey = services.localStorageService.MAD_CHECKOUT_WIDGET_CONFIGURATION_FORM_STATE_KEY,
}) => {
  const { values, setValues, initialValues } = useFormikContext<any>();

  const getNewValues = (newValues: {}) => {
    return Object.entries(newValues).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: doNotReadFromLocalStorageFor.includes(key) ? initialValues[key] : value,
      }),
      {}
    );
  };

  const saveForm = debounce(
    (data: any) =>
      services.localStorageService.saveWidgetConfigurationFormState(data, localStorageKey),
    300
  );

  React.useEffect(() => {
    const maybeValues =
      services.localStorageService.loadWidgetConfigurationFormState(localStorageKey);
    if (maybeValues) {
      setValues({
        ...values,
        ...getNewValues(maybeValues),
      });
    }
  }, []);

  React.useEffect(() => {
    saveForm(values);
  }, [values]);

  return null;
};
