import { DashboardOutlined } from '@ant-design/icons';
import { UpsellWidgetConfiguration } from '@src/containers/upsell-widget';
import { Drawer } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';

export const UpsellWidgetDrawer: React.FC = () => {
  const [isWidgetDrawerVisible, setWidgetDrawerVisible] = React.useState(false);
  return (
    <>
      <div
        className={styles.widgetDrawer}
        onClick={() => {
          setWidgetDrawerVisible(true);
        }}
      >
        <DashboardOutlined style={{ fontSize: '22px' }} />
      </div>
      <Drawer
        visible={isWidgetDrawerVisible}
        onClose={() => {
          setWidgetDrawerVisible(false);
        }}
        width={780}
      >
        <UpsellWidgetConfiguration visible={isWidgetDrawerVisible} />
      </Drawer>
    </>
  );
};

UpsellWidgetDrawer.displayName = 'UpsellWidgetDrawer';

const styles = stylesheet({
  widgetDrawer: {
    width: '55px',
    height: '55px',
    borderRadius: '50%',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'fixed',
    right: '20px',
    bottom: '20px',
    background: '#fff',
  },
});
