import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { classes, stylesheet } from 'typestyle';

import { Input, TextArea } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveFormikEnhancer, inputStyle, widerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { LocalizationModel } from '@src/models';

const InputField = withFormItem(Input, widerLayout);
const TextAreaField = withFormItem(TextArea, widerLayout);

interface Props {
  locale: string;
  translation: LocalizationModel;
  onTranslationChange: (values: TranslationsFormValues) => void;
}

export interface TranslationsFormValues {
  locale: string;
  name: string;
  customText: string;
  customInfoText: string;
}

export const Translations: React.FunctionComponent<Props> = ({
  onTranslationChange,
  translation,
  locale,
}) => {
  return (
    <Formik
      // tslint:disable-next-line: no-empty
      onSubmit={() => {}}
      initialValues={{
        locale,
        name: translation.name,
        customText: translation.customText,
        customInfoText: translation.customInfoText,
      }}
      enableReinitialize={true}
    >
      {() => (
        <>
          <AutoSaveFormikEnhancer
            name="name"
            onSave={values => onTranslationChange(values)}
            render={({ name, onBlur, onKeyDown }) => (
              <Field name={name}>
                {({ field }: FieldProps<TranslationsFormValues['name']>) => (
                  <InputField
                    label={<FormattedMessage id="NAME" />}
                    className={inputStyle}
                    {...field}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    placeholder={'-'}
                  />
                )}
              </Field>
            )}
          />
          <AutoSaveFormikEnhancer
            name="customText"
            onSave={values => onTranslationChange(values)}
            render={({ name, onBlur }) => (
              <Field name={name}>
                {({ field }: FieldProps<TranslationsFormValues['customText']>) => (
                  <TextAreaField
                    label={<FormattedMessage id="CUSTOM_TEXT" />}
                    labelTooltip={<FormattedMessage id="CUSTOM_INFO_TEXT_TOOLTIP" />}
                    className={classes(inputStyle, styles.customText)}
                    {...field}
                    onBlur={onBlur}
                    placeholder={'-'}
                  />
                )}
              </Field>
            )}
          />
          <AutoSaveFormikEnhancer
            name="customInfoText"
            onSave={values => onTranslationChange(values)}
            render={({ name, onBlur }) => (
              <Field name={name}>
                {({ field }: FieldProps<TranslationsFormValues['customInfoText']>) => (
                  <TextAreaField
                    label={<FormattedMessage id="CUSTOM_INFO_TEXT" />}
                    labelTooltip={<FormattedMessage id="CUSTOM_INFO_TEXT_TOOLTIP" />}
                    className={classes(inputStyle, styles.customText)}
                    {...field}
                    onBlur={onBlur}
                    placeholder={'-'}
                  />
                )}
              </Field>
            )}
          />
        </>
      )}
    </Formik>
  );
};

const styles = stylesheet({
  customText: {
    fontFamily: 'monospace',
    fontSize: '12px',
  },
});
