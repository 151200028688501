import * as React from 'react';
import { stylesheet } from 'typestyle';

export const Header: React.FC = ({ children }) => <h2 className={styles.header}>{children}</h2>;

const styles = stylesheet({
  header: {
    margin: '24px 0',
  },
});
