import React, { FC, Fragment } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { useQuery, useQueryClient } from 'react-query';
import { stylesheet as tss } from 'typestyle';

import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { mapCountryNameToCode } from '@src/dictionaries/mapping';
import { services } from '@src/services';
import { defaultTheme } from '@src/styles';
import { stringUtils } from '@src/utils';
import { useSiteId } from '@src/utils/hooks';
import { useMutationOnDraft } from '@src/utils/queries';

import { BreadcrumbSection, FeatureCreator, FeaturesTable, Header } from './components';
import {
  mapFormValuesToRequestPayload,
  getInitialValues,
  ReceiptFeaturesConfigFormValues,
  FeatureDefinitions,
} from './helpers';

type Props = {
  country: string;
};

export const ReceiptCountrySettings: FC<Props> = ({ country }) => {
  const siteId = useSiteId();
  const receiptFeatures = useQuery(['site', siteId], () => services.configService.getSite(siteId), {
    enabled: !!siteId,
    select: data => data?.receiptWidgetConfiguration,
  });

  const queryClient = useQueryClient();

  const countryCode = mapCountryNameToCode(country);

  const countryFeatures = receiptFeatures.data?.by_country?.[countryCode]?.features;

  const defaultFeatures = receiptFeatures.data?.features;

  const onSubmitDraft = useMutationOnDraft<ReceiptFeaturesConfigFormValues, unknown>(
    (siteId, payload) =>
      services.selfCareService.upsertReceiptConfiguration(
        siteId,
        countryCode,
        mapFormValuesToRequestPayload(payload)
      ),

    {
      onSuccess: () => queryClient.invalidateQueries(['site']),
    }
  );

  const countryName = stringUtils.capitalize(country);

  const initialValues = getInitialValues(defaultFeatures);

  const defaultsColumn: ColumnType<FeatureDefinitions> = {
    title: 'Default',
    key: 'default',
    align: 'center',
    render: (_, { name }) =>
      initialValues[name] ? (
        <CheckOutlined className={styles.iconAccent} />
      ) : (
        <CloseOutlined className={styles.iconGrey} />
      ),
  };

  return (
    <Fragment>
      <StatusBarContainer />
      <BreadcrumbSection>{countryName}</BreadcrumbSection>
      <Header withCountryTag>{countryName}</Header>
      <FeatureCreator countryCode={countryCode} siteData={receiptFeatures.data}>
        <FeaturesTable
          onSubmit={onSubmitDraft.mutate}
          loading={receiptFeatures.isLoading}
          receiptFeatures={countryFeatures}
          defaultsColumn={defaultsColumn}
          country={countryName}
        />
      </FeatureCreator>
    </Fragment>
  );
};

const styles = tss({
  iconAccent: {
    color: defaultTheme.color.primary,
  },
  iconGrey: {
    color: '#C0C0C0',
  },
});
