import React, { FC, Fragment, useState } from 'react';
import { stylesheet } from 'typestyle';
import { Select, Switch } from '@src/controls';
import { useFormatMessage } from '@src/i18n';
import { withFormItem } from '@src/decorators';
import { AvailableTrackingWidgetLanguage } from '@src/models';
import { Details } from './details-view';
import { Widget } from '../components';
import { FindTrackingData } from '../find-section/use-find-tracking';
import { useTrackingWidgetToken } from './use-tracking-widget-token';

const SelectField = withFormItem(Select);
const SwitchField = withFormItem(Switch);

const languageOptions = Object.keys(AvailableTrackingWidgetLanguage).map(key => ({
  value: key,
  label: key,
}));

type Props = {
  trackingData: FindTrackingData;
  isUsingDraft: boolean;
  isDraftAvailable: boolean;
  setUsingDraft: () => void;
};

export const WidgetSection: FC<Props> = ({
  trackingData,
  isUsingDraft,
  isDraftAvailable,
  setUsingDraft,
}) => {
  const [locale, setLocale] = useState('en-US');
  const formatMessage = useFormatMessage();

  const resource =
    trackingData.authTokenBy === 'externalId'
      ? trackingData.externalId
      : trackingData.trackingNumber;

  const { data, isLoading } = useTrackingWidgetToken(resource ?? '', isUsingDraft);

  return data ? (
    <Fragment>
      <Widget
        key={`${locale}${data.siteId}`}
        initTrackingWidget={() => {
          const { token, siteId } = data;

          window.IngridTrackingWidget.config({
            elementId: 'widget',
            locale,
            siteId,
            authToken: token,
            ...getWidgetResource(trackingData),
          });
        }}
      />
      <SwitchField
        checked={isUsingDraft}
        onChange={setUsingDraft}
        defaultChecked={isDraftAvailable}
        label={formatMessage('USE_DRAFT')}
        disabled={!isDraftAvailable}
      />
      <SelectField
        caseInsensitiveSearch={true}
        label={formatMessage('LOCALE')}
        options={languageOptions}
        value={locale}
        onChange={setLocale}
      />
      <Details trackingData={trackingData} locale={locale} />
    </Fragment>
  ) : !isLoading ? (
    <span className={styles.placeholder}>
      {formatMessage('TRACKING_PLAYGROUND_WIDGET_FIND_SECTION_EMPTY_PLACEHOLDER')}
    </span>
  ) : null;
};

const getWidgetResource = ({ authTokenBy, trackingNumber, externalId }: FindTrackingData) => {
  if (authTokenBy === 'externalId') {
    return { externalId, trackingNumber: undefined };
  }

  if (authTokenBy === 'trackingNumber') {
    return { trackingNumber, externalId: undefined };
  }
  // should not happen - but you know - in such case tracking widget will throw an error
  return { externalId: undefined, trackingNumber: undefined };
};

const styles = stylesheet({
  placeholder: {
    placeSelf: 'center',
  },
});
