import { mapping } from '@src/dictionaries';
import { deliveryStatusList, NotificationType } from '@src/models';
import { humanize } from '@src/utils/string';

export const getNotificationTypeDescription = (type: NotificationType | null) => {
  switch (type) {
    case NotificationType.STATUS_AT_TIME:
      return "Notification with parcels' current statuses will be sent at specified time";
    case NotificationType.EXPECT_STATUS_AT_TIME:
      return "Notification will be sent if parcels' fail to receive chosen status at specified time";
    case NotificationType.STATUS_CHANGED_TO:
      return "Notification will be sent when parcels' receive chosen status";
    default:
      return '';
  }
};

export const expectedStatusOptions = deliveryStatusList
  .filter(status => status !== 'UNKNOWN')
  .map(status => ({
    label: humanize(status),
    value: status,
  }));

export const notificationTypeOptions = Object.values(NotificationType)
  .filter(status => status !== NotificationType.UNKNOWN)
  .map(type => ({
    label: mapping.mapNotificationTypeToDescription(type),
    value: type,
  }));
