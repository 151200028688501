import { FieldState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';

type BaseProps = {
  value?: any;
  onChange?: React.FormEventHandler<any>;
};

type HOCProps = {
  fieldState?: FieldState<any>;
};

export function withFieldStateInput<WrappedComponentProps extends BaseProps>(
  WrappedComponent:
    | React.StatelessComponent<WrappedComponentProps>
    | React.ComponentClass<WrappedComponentProps>
) {
  const HOC: React.StatelessComponent<HOCProps & WrappedComponentProps> = props => {
    const { fieldState, ...passThroughProps } = props as HOCProps;

    const overriddenBaseProps: BaseProps = {};
    if (fieldState) {
      overriddenBaseProps.value = fieldState.value;
      overriddenBaseProps.onChange = (ev: any) => {
        fieldState.onChange(ev.target.value);
      };
    }

    return <WrappedComponent {...(passThroughProps as any)} {...overriddenBaseProps} />;
  };

  return observer(HOC);
}
