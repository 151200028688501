import { Field, FieldProps, Formik } from 'formik';
import * as moment from 'moment';
import { hasPath } from 'ramda';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { array, object, string } from 'yup';

import { Button, Input, Select, TimePicker } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { wideLabelLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { ConfigWarehouseModel } from '@src/models';
import { configSelectors } from '@src/modules/config';
import { commons } from '@src/styles';
import { getFormikError } from '@src/utils/forms';
import { useSelector } from '@src/utils/hooks';

const timeFormat = 'HH:mm';

export interface CreateCarrierProductValues {
  shippingMethod?: string;
  externalMethodId?: string;
  deliveryTypes?: string[];
  defaultCutoffs?: string;
}

const TimePickerField = withFormItem(TimePicker, wideLabelLayout);
const SelectField = withFormItem(Select, wideLabelLayout);
const InputField = withFormItem(Input, wideLabelLayout);

interface Props {
  createCarrierProduct: (values: CreateCarrierProductValues, onComplete: () => void) => void;
  isCreatingDraft: boolean;
  setIsSendingRequest: (value: boolean) => void;
  setCreateProductModalVisible: (value: boolean) => void;
  currentWarehouse: ConfigWarehouseModel | undefined;
  regionId: string;
}

const createCarrierProductSchema = object({
  shippingMethod: string().required(),
  deliveryTypes: array().min(1, 'At least 1 delivery type is required'),
});

export const CreateCarrierProductForm: React.FunctionComponent<Props> = ({
  createCarrierProduct,
  setCreateProductModalVisible,
  setIsSendingRequest,
  currentWarehouse,
  regionId,
}) => {
  const shippingMethodOptionsGroupedByCarrier = useSelector(state =>
    configSelectors.getShippingMethodsOptionsGroupedByCarrier(state, regionId)
  );

  const carrierProducts = useSelector(state =>
    configSelectors.getUnusedCarrierProductsWithLimitedDeliveryTypes(state, regionId)
  );

  const getDeliveryTypeOptions = (values: CreateCarrierProductValues) => {
    const selectedProduct = carrierProducts.find(product => product.id === values.shippingMethod);
    return selectedProduct
      ? selectedProduct.deliveryTypes.map(dt => ({ value: dt, label: dt }))
      : [];
  };

  return (
    <Formik
      onSubmit={values => {
        setCreateProductModalVisible(false);
        setIsSendingRequest(true);
        createCarrierProduct(values, () => setIsSendingRequest(false));
      }}
      initialValues={{
        shippingMethod: undefined,
        deliveryTypes: undefined,
        defaultCutoffs: '00:00',
      }}
      validationSchema={createCarrierProductSchema}
    >
      {({ setFieldValue, handleSubmit, touched, errors, values }) => (
        <form className={styles.formWrapper} onSubmit={handleSubmit}>
          <Field name="shippingMethod">
            {({ field }: FieldProps<CreateCarrierProductValues['shippingMethod']>) => (
              <SelectField
                {...field}
                onChange={(value: string) => {
                  setFieldValue('shippingMethod', value);
                  const selectedProduct = carrierProducts.find(product => product.id === value);
                  if (selectedProduct) {
                    setFieldValue('deliveryTypes', [selectedProduct.deliveryTypes[0]]);
                  }
                }}
                showSearch
                filterOption={true}
                optionFilterProp={'label'}
                groupOptions={shippingMethodOptionsGroupedByCarrier}
                label="Shipping method:"
                className={styles.inputStyle}
                error={getFormikError(touched.shippingMethod, errors.shippingMethod)}
              />
            )}
          </Field>

          <Field name="externalMethodId">
            {({ field }: FieldProps<CreateCarrierProductValues['externalMethodId']>) => (
              <InputField
                {...field}
                label="External method ID:"
                labelTooltip="Merchant-specific identifier of shipping method used in the carrier product"
                className={styles.inputStyle}
              />
            )}
          </Field>

          <Field name="deliveryTypes">
            {({ field }: FieldProps<CreateCarrierProductValues['deliveryTypes']>) => (
              <SelectField
                {...field}
                onChange={(value: string) => setFieldValue('deliveryTypes', [value])}
                options={getDeliveryTypeOptions(values)}
                label="Delivery type:"
                className={styles.inputStyle}
                error={getFormikError(touched.deliveryTypes, errors.deliveryTypes)}
              />
            )}
          </Field>

          {!hasPath(['operatingSchedule', values.shippingMethod || ''])(currentWarehouse) && (
            <Field name={'defaultCutoffs'}>
              {({ field }: FieldProps<CreateCarrierProductValues['defaultCutoffs']>) => (
                <TimePickerField
                  {...field}
                  value={
                    values.defaultCutoffs ? moment(values.defaultCutoffs, timeFormat) : undefined
                  }
                  label={<FormattedMessage id="DEFAULT_CUTOFFS" />}
                  getPopupContainer={node => node.parentElement || document.body}
                  labelTooltip={<FormattedMessage id="SET_DEFAULT_CUTOFFS_TOOLTIP" />}
                  className={commons.timePicker}
                  placeholder={'-'}
                  onChange={value => {
                    setFieldValue(
                      'defaultCutoffs',
                      value
                        ? moment(value).format(timeFormat)
                        : moment().startOf('day').format(timeFormat)
                    );
                  }}
                  format={timeFormat}
                  allowClear={true}
                />
              )}
            </Field>
          )}

          <div className={styles.flex}>
            <Button type="primary" htmlType="submit" className={styles.submitButton}>
              <FormattedMessage id="ADD" />
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

const styles = stylesheet({
  submitButton: {
    marginLeft: 'auto',
    marginTop: '10px',
  },
  formWrapper: {
    width: 550,
  },
  inputStyle: {
    display: 'block',
  },
  flex: {
    display: 'flex',
  },
  icon: {
    fontSize: '14px !important',
    marginLeft: '5px',
    verticalAlign: 'top',
  },
});
