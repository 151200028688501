import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import { AddressBookState, reducer as addressBook } from './address-book';
import { AppState, default as app } from './app/app-reducer';
import { AuthState, default as auth } from './auth/auth-reducer';
import { BlobsState, reducer as blobs } from './blobs';
import { ConfigState, reducer as config } from './config';
import { DeliveriesState, reducer as deliveries } from './deliveries';
import { DictionariesState, reducer as dictionaries } from './dictionaries';
import { default as labelMerger, LabelMergerState } from './label-merger';
import { default as merchants, MerchantsState } from './merchants/merchants-reducer';
import { default as reports, ReportsState } from './reports/reducers';
import { default as sessionHistory, SessionHistoryState } from './session-events';
import {
  reducer as shipmentNotifications,
  ShipmentNotificationsState,
} from './shipment-notifications';
import { shipmentsReducer as shipments, ShipmentsState } from './shipments';
import { default as sites, SitesState } from './sites/sites-reducer';
import { reducer as siw, SIWState } from './siw';
import { default as somSearch, SomSearchState } from './som-search';
import { default as tags, TagsState } from './tags';
import { reducer as tokenAuth, TokenAuthState } from './tokenauth';
import { reducer as trackingWidget, TrackingWidgetState } from './tracking-widget';
import { reducer as transportOrders, TransportOrdersState } from './transport-orders';
import { default as userprofile, UserProfileState } from './userprofile';
import { default as users, UsersState } from './users/users-reducer';

// tslint:disable-next-line:interface-name
export interface RootState {
  router: RouterState;
  app: AppState;
  auth: AuthState;
  blobs: BlobsState;
  deliveries: DeliveriesState;
  dictionaries: DictionariesState;
  merchants: MerchantsState;
  sites: SitesState;
  transportOrders: TransportOrdersState;
  shipments: ShipmentsState;
  addressBook: AddressBookState;
  userprofile: UserProfileState;
  users: UsersState;
  siw: SIWState;
  reports: ReportsState;
  sessionHistory: SessionHistoryState;
  somSearch: SomSearchState;
  labelMerger: LabelMergerState;
  tokenAuth: TokenAuthState;
  tags: TagsState;
  shipmentNotifications: ShipmentNotificationsState;
  config: ConfigState;
  trackingWidget: TrackingWidgetState;
}

export const getRootReducer = (history: History) => {
  return combineReducers<RootState>({
    router: connectRouter(history),
    app,
    auth,
    blobs,
    deliveries,
    dictionaries,
    merchants,
    sites,
    transportOrders,
    shipments,
    addressBook,
    userprofile,
    users,
    siw,
    reports,
    sessionHistory,
    somSearch,
    labelMerger,
    tokenAuth,
    tags,
    shipmentNotifications,
    config,
    trackingWidget,
  });
};
