import * as React from 'react';
import { Col, Row, Spin } from 'antd';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { stylesheet } from 'typestyle';

import { services } from '@src/services';
import { HeaderWithTag } from '@src/components';
import { SitesSelectors } from '@src/modules/sites';
import { FormattedMessage } from '@src/i18n';
import { DOMAIN } from '@src/dictionaries';
import { UpdateExperimentModel } from '@src/models';

import { ExperimentDetails } from './experiment-details';
import { VariantListContainer } from './variant-list-container';
import { useExperimentQuery } from './use-experiment-query';
import { ActionButton } from './action-button';

interface Props {
  experimentId: string;
}

export const ExperimentDetailsContainer: React.FC<Props> = ({ experimentId }) => {
  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);

  const [query, invalidateQuery] = useExperimentQuery(siteId, experimentId);

  const updateMutation = useMutation(
    (values: UpdateExperimentModel) => {
      return services.experimentService.updateExperiment(values);
    },
    {
      onSuccess: invalidateQuery,
      onError: () => services.notificationService.error('Failed to update experiment'),
    }
  );

  if (!query.data) {
    return <Spin spinning />;
  }

  const actionButton = (
    <ActionButton
      siteId={siteId}
      experimentId={experimentId}
      experiment={query.data || {}}
      status={query.data ? query.data.status : undefined}
    />
  );

  return (
    <>
      <div className={styles.wrapper}>
        <HeaderWithTag tag={DOMAIN.EXPERIMENT} title={query.data.name} extra={actionButton} />
        <Spin spinning={query.isFetching || updateMutation.isLoading}>
          <ExperimentDetails
            key={query.dataUpdatedAt}
            experimentData={query.data}
            disabled={query.data.status !== 'CREATED'}
            onSubmit={updateMutation.mutate}
          />
          <Row>
            <Col span={6}>
              <FormattedMessage className={styles.label} id="VARIANTS" />
            </Col>
            <Col span={18}>
              <VariantListContainer
                experimentId={experimentId}
                experimentData={query.data}
              />
            </Col>
          </Row>
        </Spin>
      </div>
    </>
  );
};

const styles = stylesheet({
  wrapper: {
    padding: '24px',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
});
