import { DeleteOutlined } from '@ant-design/icons';
import { useFormatMessage } from '@src/i18n';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { isEmpty } from 'ramda';
import * as React from 'react';
import { classes, style as tss } from 'typestyle';
import { CarrierProductMappingForm } from '../form-commons';

type DeleteRowProps = {
  idx: number;
  className?: string;
  handleSave?: (values: CarrierProductMappingForm) => void;
};

export const DeleteRowButtonConnected: React.FC<DeleteRowProps> = ({
  idx,
  className,
  handleSave,
}) => {
  const formik = useFormikContext<CarrierProductMappingForm>();

  return (
    <DeleteOutlined
      className={classes(
        tss({
          fontSize: '20px',
          $nest: {
            '&.anticon': { color: '#575757' },
          },
        }),
        className
      )}
      onClick={() => {
        const current = formik.values.tracking_links;
        const next = [...current.slice(0, idx), ...current.slice(idx + 1)];
        formik.setFieldValue('tracking_links', next);
        // perform auto save
        if (handleSave) {
          const nextValues = { ...formik.values, tracking_links: next };
          formik.validateForm(nextValues).then(errors => {
            if (isEmpty(errors)) {
              handleSave(nextValues);
            }
          });
        }
      }}
    />
  );
};

type AddNewProps = {
  handleSave?: (values: CarrierProductMappingForm) => void;
};

export const AddNewRowButtonConnected: React.FC<AddNewProps> = ({ handleSave }) => {
  const formik = useFormikContext<CarrierProductMappingForm>();
  const formatMessage = useFormatMessage();

  return (
    <a
      className={tss({
        paddingTop: '16px',
      })}
      onClick={e => {
        e.preventDefault();
        const next = [...formik.values.tracking_links, { locale: undefined, url: '' }];
        formik.setFieldValue('tracking_links', next);
        // perform auto save
        if (handleSave) {
          const nextValues = { ...formik.values, tracking_links: next };
          formik.validateForm(nextValues).then(errors => {
            if (isEmpty(errors)) {
              handleSave(nextValues);
            }
          });
        }
      }}
    >
      {formatMessage('ADD_NEW')}
    </a>
  );
};

export const SubmitButtonConnected: React.FC = () => {
  const formik = useFormikContext<CarrierProductMappingForm>();
  return (
    <Button
      type="primary"
      onClick={() => {
        formik.submitForm();
      }}
    >
      {'Add'}
    </Button>
  );
};

export const CancelButtonConnected: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const formik = useFormikContext<CarrierProductMappingForm>();
  const formatMessage = useFormatMessage();
  return (
    <Button
      type="dashed"
      onClick={() => {
        formik.resetForm();
        onClick();
      }}
    >
      {formatMessage('CANCEL')}
    </Button>
  );
};
