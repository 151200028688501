import { OptimisticAction, OptimisticState } from 'redux-optimistic-ui';
import { ActionCreator, deprecated, TypeConstant } from 'typesafe-actions';
const { createAction } = deprecated;
import { PayloadMetaAction } from 'typesafe-actions/dist/deprecated/create-action';

export const buildOptimisticState = <T>(state: T): OptimisticState<T> => ({
  history: [],
  beforeState: state,
  current: state,
});

export const createOptimisticAction = <T extends TypeConstant, AC extends ActionCreator<T>>(
  type: T,
  createHandler: (
    actionCallback: <P, M extends OptimisticAction['meta']>(
      payload: P | undefined,
      meta: M
    ) => PayloadMetaAction<T, P, M>
  ) => AC
): AC => createAction(type, createHandler);
