import React from 'react';
import { Field, FieldProps, Formik } from 'formik';

import { Switch } from '@src/controls';
import { AutoSaveForm, AutoSaveFormikEnhancer } from '@src/forms';

type Props = {
  method: string;
  carrierCutoff?: boolean;
  onToggleCutoffs: (method: string, carrierCutoff: boolean) => void;
};

type FormValues = {
  carrierCutoff: boolean;
};

export const WarehouseCutoffTimesToggle: React.FC<Props> = ({
  method,
  carrierCutoff,
  onToggleCutoffs,
}) => {
  return (
    <Formik
      initialValues={{
        carrierCutoff: !!carrierCutoff,
      }}
      onSubmit={() => undefined}
    >
      <AutoSaveForm>
        <AutoSaveFormikEnhancer
          name="carrierCutoff"
          onSave={({ carrierCutoff }) => onToggleCutoffs(method, carrierCutoff)}
          render={({ name, onInstantChange }) => (
            <Field name={name}>
              {({ field }: FieldProps<FormValues['carrierCutoff']>) => (
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={value => onInstantChange(value)}
                />
              )}
            </Field>
          )}
        />
      </AutoSaveForm>
    </Formik>
  );
};
