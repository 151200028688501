import { ConfigRegionModel, UpdateConfigRegionModel } from '@src/models';

export const buildRegionModel = (
  values: Partial<UpdateConfigRegionModel>,
  region: ConfigRegionModel
): UpdateConfigRegionModel => {
  const regionType = values.regionType || region.regionType;
  return {
    regionId: region.id,
    name: values.name || region.name,
    ...(regionType === 'zipcode' && {
      regionPostalCodeConfig: values.regionPostalCodeConfig || region.regionPostalCodeConfig,
    }),
    ...(regionType === 'country' && {
      countryConfig: values.countryConfig || region.regionCountryConfig,
    }),
    warehouseId: values.warehouseId || region.warehouseId,
    state: values.state || region.state,
    regionType,
    features: {
      showHighDemandAffectsDeliveryDisclaimerMessage:
        values.features?.showHighDemandAffectsDeliveryDisclaimerMessage !== undefined
          ? values.features?.showHighDemandAffectsDeliveryDisclaimerMessage
          : region.features?.showHighDemandAffectsDeliveryDisclaimerMessage,
    },
  };
};
