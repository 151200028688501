import { DOMAIN } from '../dictionaries';

export const MESSAGES = {
  PRICE_RULES_DISABLED: `Pricing rules are disabled. Default price is set to {0}`,
  PRICE_RULES_ENABLED: `Pricing rules are enabled. Default price is set to {0}`,
  FILTER_RULES_DISABLED: `Availability rules are disabled`,
  FILTER_RULES_ENABLED: `Availability rules are enabled`,
  SELECT_MERCHANT_SITE: `Please select Merchant and Site`,
  SELECT_MAIN_MENU_SECTION: `<- to start please choose an action from the left menu`,
  LEAVING_DIRTY_FORM: `Are you sure you want to leave?\n(Note: any unsaved data will be lost)`,
  EMPTY_REGION_LIST: `Currently there are no existing {0}. Please create new one.`.replace(
    '{0}',
    DOMAIN.REGIONS
  ),
  EMPTY_WAREHOUSE_LIST: `Currently there are no existing {0}. Please create new one.`.replace(
    '{0}',
    DOMAIN.WAREHOUSES
  ),
  EMPTY_TRANSPORT_ORDERS_LIST:
    `Currently there are no existing {0}. Please create new one.`.replace(
      '{0}',
      DOMAIN.TRANSPORT_ORDERS
    ),
  EMPTY_TABLE: 'Currently there is nothing to display',
  EMPTY_REGION_LINKER_LIST: `No {0} linked for that {1}`
    .replace('{0}', DOMAIN.REGIONS.toLowerCase())
    .replace('{1}', DOMAIN.WAREHOUSE.toLowerCase()),
  NO_UNLINKED_REGIONS_AVAILABLE:
    `Currently there are no {0} that hasn't been already linked to a {1}. Please create a new {2}.`
      .replace('{0}', DOMAIN.REGIONS.toLowerCase())
      .replace('{1}', DOMAIN.WAREHOUSE.toLowerCase())
      .replace('{2}', DOMAIN.REGION.toLowerCase()),
  EMPTY_OPERATING_SCHEDULE_LIST: `No {0} linked for that {1}`
    .replace('{0}', DOMAIN.OPERATING_SCHEDULE.toLowerCase())
    .replace('{1}', DOMAIN.WAREHOUSE.toLowerCase()),
  EMPTY_LINE_ITEM_LIST: `No {0} linked for that {1}`
    .replace('{0}', DOMAIN.LINE_ITEMS.toLowerCase())
    .replace('{1}', DOMAIN.SHIPMENT.toLowerCase()),
  EMPTY_SHIPMENTS_LIST: `Currently there are no existing {0}. Please create new one.`.replace(
    '{0}',
    DOMAIN.SHIPMENTS
  ),
  EMPTY_DOCUMENTS_LIST: `Currently there are no existing documents.`,
  EMPTY_ADDRESS_BOOK:
    `Currently there are no existing {0} contacts. Please create new one.`.replace(
      '{0}',
      DOMAIN.ADDRESS_BOOK
    ),
  EMPTY_TEMPLATE_LIST: `Currently there are no existing shipment templates`,
  EMPTY_TAGS_LIST: `Currently there are no existing tags`,
  SEARCH_CONTACTS: 'Search contacts',
  CANCEL_SHIPMENT_HEADER: `Cancel shipment?`,
  CANCEL_SHIPMENT_CONTENT: `You're about to cancel a shipment. You can't undo this action. Are you sure?`,
  DELETE_CONTACT_HEADER_DETAILS_VIEW: `Delete address?`,
  DELETE_CONTACT_CONTENT_DETAILS_VIEW: `You're about to delete an address. You can't undo this action. Are you sure?`,
  DELETE_CONTACTS_HEADER_LIST_VIEW: (itemCount: number) =>
    `Delete ${itemCount}` + (itemCount === 1 ? ` address?` : ` addresses?`),
  DELETE_CONTACTS_CONTENT_LIST_VIEW: (itemCount: number) =>
    `You're about to delete ${itemCount} ${
      itemCount === 1 ? ` address.` : ` addresses.`
    } You can't undo this action. Are you sure?`,
  DELETE_TEMPLATES_HEADER_LIST_VIEW: (itemCount: number) =>
    `Delete ${itemCount}` + (itemCount === 1 ? ` template?` : ` templates?`),
  DELETE_TEMPLATES_CONTENT_LIST_VIEW: (itemCount: number) =>
    `You're about to delete ${itemCount} ${
      itemCount === 1 ? ` template.` : ` templates.`
    } You can't undo this action. Are you sure?`,
  MAX_FILE_SIZE: (size: number) => `Max file size: ${size}MB`,
  IN_PROGRESS: 'In progress',
  SUCCESS: 'Success',
  ERROR: 'Error',
  PAGE_LEAVE_PROMPT_TITLE: 'You are about to leave the page',
  ARE_YOU_SURE_YOU_WANT_TO_EDIT_NAME: 'Are you sure you want to edit name?',
};
