import * as React from 'react';
import { connect } from 'react-redux';

import { DOMAIN, LABELS, MESSAGES } from '../../dictionaries';
import { RootState } from '../../modules';

import { routerActions } from '@src/modules/router';
import { modalConfirm } from '../../controls';
import { ContactModel } from '../../models';
import { addressBookActions } from '../../modules/address-book';
import { eventTrackingActions } from '../../modules/event-tracking';
import { AddressBookContactForm } from './components/address-book-contact-form';

// Component API
type OwnProps = {
  contactId: string;
};

// Component API
const mapStateToProps = (state: RootState) => ({
  contacts: state.addressBook.contacts,
  addressBookError: state.addressBook.error,
  isFetching: state.addressBook.isFetching,
});
const dispatchProps = {
  updateContact: addressBookActions.updateContactRequest,
  getContactsList: addressBookActions.getContactsListRequest,
  deleteContacts: addressBookActions.deleteContactsRequest,
  toggleAdditionalContactInfo: eventTrackingActions.toggleAdditionalContactInfo,
  routerPush: routerActions.push,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & OwnProps;
type State = {};

class AddressBookContactEditContainer extends React.Component<Props, State> {
  componentDidMount() {
    if (this.props.contacts.length === 0) {
      this.props.getContactsList({});
    }
  }

  handleCancel = () => {
    this.props.routerPush({ name: 'ADDRESS_BOOK' });
  };

  handleDeleteClick = () => {
    modalConfirm({
      title: MESSAGES.DELETE_CONTACT_HEADER_DETAILS_VIEW,
      content: MESSAGES.DELETE_CONTACT_CONTENT_DETAILS_VIEW,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.props.deleteContacts([this.props.contactId]);
      },
    });
  };

  render() {
    const { contacts, contactId, addressBookError, isFetching, toggleAdditionalContactInfo } =
      this.props;

    const contact = contacts.find((item: ContactModel) => item.id === contactId);
    if (contact == null) {
      return null;
    }

    return (
      <AddressBookContactForm
        addressBookError={addressBookError}
        isLoading={isFetching}
        contactToEdit={contact}
        updateContact={this.props.updateContact}
        title={[LABELS.EDIT, DOMAIN.ADDRESS_BOOK].join(' ')}
        additionalActionsConfig={[{ label: LABELS.DELETE, handler: this.handleDeleteClick }]}
        onBack={this.handleCancel}
        onBackLabel={LABELS.CANCEL}
        onSubmitLabel={LABELS.SAVE}
        toggleAdditionalInfo={toggleAdditionalContactInfo}
      />
    );
  }
}

export default connect(mapStateToProps, dispatchProps)(AddressBookContactEditContainer);
