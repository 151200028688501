import { useFormatMessage } from '@src/i18n';
import { humanize } from '@src/utils/string';
import React, { FC, Fragment } from 'react';
import { StackRecord, SourceTag, CarrierProductWithLogo } from '../components';
import { TrackingRecord } from '../types';

type Props = {
  record?: TrackingRecord;
};

export const GeneralInfoRow: FC<Props> = ({ record }) => {
  const formatMessage = useFormatMessage();

  return (
    <Fragment>
      <StackRecord
        label={formatMessage('TRACKING_NUMBERS.DETAILS.LABELS.CARRIER_PRODUCT')}
        colSpan={6}
      >
        <CarrierProductWithLogo
          name={record?.carrier_product_name}
          carrier={record?.carrier_product_carrier}
          carrierProductId={record?.carrier_product_id}
        />
      </StackRecord>
      <StackRecord
        label={formatMessage('TRACKING_NUMBERS.DETAILS.LABELS.CARRIER_PRODUCT_REF')}
        colSpan={6}
      >
        <span>{record?.carrier_product_ref ?? '-'}</span>
      </StackRecord>
      <StackRecord label={formatMessage('TRACKING_NUMBERS.DETAILS.LABELS.SOURCE')} colSpan={6}>
        {(record?.tracking_record_source && <SourceTag source={record.tracking_record_source} />) ??
          '-'}
      </StackRecord>
      <StackRecord label={formatMessage('TRACKING_NUMBERS.DETAILS.LABELS.DIRECTION')} colSpan={6}>
        {humanize(record?.direction_type ?? '-')}
      </StackRecord>
    </Fragment>
  );
};
