import * as qs from 'query-string';

import { ContactDTO, ContactModel } from '../models';
import { apiUtils, authFetch } from '../utils';

const API_URL = apiUtils.getApiUrl() + '/frontend/address_book';

export async function fetchContactsList(
  siteId: string,
  searchQuery: string,
  pageLimit: number,
  pageNumber: number
): Promise<{ contacts: ContactModel[]; count: number }> {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<{ contacts?: ContactDTO[]; count: string }>(
    `${API_URL}/contacts.list?` +
      qs.stringify({
        site_id: siteId,
        ['query']: searchQuery,
        ['pagination.limit']: pageLimit,
        ['pagination.offset']: (pageNumber - 1) * pageLimit,
      }),
    options
  ).then(res => {
    return {
      contacts: res.contacts
        ? res.contacts
            .reverse()
            .map(order => order && ContactModel.deserialize(order))
            .filter(order => order != null && order.id != null)
        : [],
      count: parseInt(res.count, 10),
    };
  });
}

export async function createContact(siteId: string, model: ContactModel): Promise<ContactModel> {
  const payload = ContactModel.serialize(model);
  // stub site_id
  payload.site_id = siteId;

  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };

  return authFetch<{ contact: ContactDTO }>(API_URL + '/contacts.add', options).then(res => {
    if (res == null || res.contact == null) {
      throw new Error('Resource not found!');
    }
    return ContactModel.deserialize(res.contact);
  });
}

export async function updateContact(siteId: string, model: ContactModel): Promise<ContactModel> {
  const payload = ContactModel.serialize(model);
  const updatePayload = {
    ...payload,
    additional_fields: {
      additional_fields: payload.additional_fields,
    },
  };
  // stub site_id
  updatePayload.site_id = siteId;

  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(updatePayload),
  };

  return authFetch<{ contact: ContactDTO }>(API_URL + '/contacts.edit', options).then(res => {
    if (res == null || res.contact == null) {
      throw new Error('Resource not found!');
    }
    return ContactModel.deserialize(res.contact);
  });
}

export async function importContacts(
  siteId: string,
  base64File: string
): Promise<{ contacts: ContactModel[]; count: number }> {
  const options = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ data: base64File, site_id: siteId }),
  };
  return authFetch<{ contacts?: ContactDTO[]; count?: string }>(
    API_URL + '/apoteket-csv-upload',
    options
  ).then(res => {
    return {
      contacts: res.contacts
        ? res.contacts
            .reverse()
            .map(order => order && ContactModel.deserialize(order))
            .filter(order => order != null && order.id != null)
        : [],
      count: res.count ? parseInt(res.count, 10) : 0,
    };
  });
}

export async function deleteContacts(siteId: string, modelIds: string[]): Promise<any> {
  const options = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ contact_ids: modelIds, site_id: siteId }),
  };
  return authFetch<{}>(API_URL + '/contacts.delete', options).then(res => {
    if (res == null) {
      throw new Error('Resource not found');
    }
    return;
  });
}
