import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { style as tss } from 'typestyle';
import { object, string } from 'yup';

import { Button, Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { LABELS } from '@src/dictionaries';
import { DeliveryStatus, NotificationType } from '@src/models';
import { getFormikError } from '@src/utils/forms';
import { FormFields, notificationTypeOptions } from '.';
import { getNotificationTypeDescription } from './helpers';

const SelectField = withFormItem(Select);

export interface FormValues {
  notificationType: NotificationType | null;
  expectedStatus: DeliveryStatus | null;
  dueDate: moment.Moment | null;
}

interface Props {
  onSubmit: (
    notificationType: FormValues['notificationType'],
    expectedStatus: FormValues['expectedStatus'],
    dueData: FormValues['dueDate']
  ) => void;
  onCancel: () => void;
}

export const ShipmentNotificationsCreateForm: React.FunctionComponent<Props> = ({
  onCancel,
  onSubmit,
}) => (
  <Formik
    initialValues={{
      notificationType: null,
      expectedStatus: null,
      dueDate: null,
    }}
    validationSchema={object({
      notificationType: string().required().nullable(true),
      expectedStatus: string()
        .when('notificationType', {
          is: value =>
            value === NotificationType.EXPECT_STATUS_AT_TIME ||
            value === NotificationType.STATUS_CHANGED_TO,
          then: string().required(),
          otherwise: string(),
        })
        .nullable(true),
      dueDate: string()
        .when('notificationType', {
          is: value =>
            value === NotificationType.EXPECT_STATUS_AT_TIME ||
            value === NotificationType.STATUS_AT_TIME,
          then: string().required(),
          otherwise: string(),
        })
        .nullable(true),
    })}
    onSubmit={({ notificationType, expectedStatus, dueDate }, props) => {
      onSubmit(notificationType, expectedStatus, dueDate);
      props.resetForm();
    }}
  >
    {(renderProps: FormikProps<FormValues>) => (
      <Form>
        <Field name="notificationType">
          {({ field }: FieldProps<FormValues['notificationType']>) => (
            <SelectField
              label="Select Type"
              options={notificationTypeOptions}
              placeholder={LABELS.PLACEHOLDER_SELECT}
              required={true}
              value={field.value ?? ''}
              error={getFormikError(
                renderProps.touched.notificationType,
                renderProps.errors.notificationType
              )}
              description={getNotificationTypeDescription(field.value)}
              onChange={value => {
                renderProps.setFieldValue('notificationType', value);
                renderProps.setFieldValue('dueDate', null);
                renderProps.setFieldValue('expectedStatus', null);
              }}
            />
          )}
        </Field>
        <FormFields {...renderProps} />
        <div className={styles.buttonsWrapper}>
          <Button onClick={onCancel}>{LABELS.CANCEL}</Button>
          <Button type="primary" htmlType="submit" className={styles.submitButton}>
            {LABELS.SAVE}
          </Button>
        </div>
      </Form>
    )}
  </Formik>
);

const styles = {
  form: tss({ display: 'flex', flexDirection: 'column' }),
  buttonsWrapper: tss({ display: 'flex', justifyContent: 'flex-end' }),
  submitButton: tss({ marginLeft: 10 }),
};
