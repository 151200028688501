import { TransportOrderModel, SIWSessionDTO } from '@src/models';
import { commons } from '@src/styles';
import { Collapse, Descriptions, Divider } from 'antd';
import * as React from 'react';
import ReactJson from 'react-json-view';
import { stylesheet } from 'typestyle';

type Props = {
  order: TransportOrderModel;
  sessionDTO?: SIWSessionDTO;
};
export const OrderInformation: React.FunctionComponent<Props> = ({ order, sessionDTO }) => (
  <>
    <Descriptions className={commons.descriptionList}>
      <Descriptions.Item label="Session ID" span={3}>
        {order.metadata.session.sessionId}
      </Descriptions.Item>
      { order.metadata.masterSessionId && (
        <Descriptions.Item label="Master Session ID" span={3}>
          {order.metadata.masterSessionId}
        </Descriptions.Item>
      )}
      <Descriptions.Item label="External ID" span={3}>
        {order.external.referenceIds?.[0] || '-'}
      </Descriptions.Item>
    </Descriptions>
    { sessionDTO && (
      <>
        <Divider />
        <Collapse bordered={false} className={styles.collapse}>
          <Collapse.Panel header="Session result" key="session">
            <ReactJson
              collapsed
              name="session"
              src={sessionDTO}
              displayDataTypes={false}
              theme="grayscale:inverted"
            />
          </Collapse.Panel>
        </Collapse>
      </>
    )}
  </>
);

const styles = stylesheet({
  collapse: {
    $nest: {
      '&.ant-collapse': {
        background: 'white',
      },
      '.ant-collapse-header': {
        padding: '0px !important',
        paddingLeft: '20px !important',
        fontWeight: 500,
      },
      '.ant-collapse-arrow': {
        paddingTop: '0px !important',
        left: '0 !important',
      },
      '.ant-collapse-item': {
        borderBottom: 'none !important',
      },
      '.ant-collapse-content-box': {
        padding: '16px 0 0 0 !important',
      },
    },
  },
});
