import { InfoCircleOutlined } from '@ant-design/icons';
import { directions } from '@src/containers/tracking-numbers/types';
import { Button, Label, RangePicker, Select, Switch } from '@src/controls';
import { GroupSelectOption, SelectOption } from '@src/controls/select';
import { useFormatMessage } from '@src/i18n';
import { deliveryStatusList, ShipmentListFiltersModel } from '@src/models';
import { services } from '@src/services';
import { getNumberOfShipmentFilters } from '@src/utils/counters';
import { humanize } from '@src/utils/string';
import { Divider, Tooltip } from 'antd';
import { Field, FieldProps, Formik, useField } from 'formik';
import moment from 'moment';
import { isNotEmpty } from 'ramda-adjunct';
import React, { FunctionComponent } from 'react';
import { stylesheet } from 'typestyle';
import { FilterPanelProps } from './panel';

const statusOptions = deliveryStatusList.map(key => ({
  value: key,
  label: humanize(key),
}));

const deliveryTypeOptions = directions.map(direction => ({
  value: direction,
  label: humanize(direction),
}));

type FormValues = {
  shippingMethods: ShipmentListFiltersModel['shippingMethods'];
  createdAtRange: ShipmentListFiltersModel['createdAtRange'];
  shippingDateRange: ShipmentListFiltersModel['shippingDateRange'];
  statuses: ShipmentListFiltersModel['statuses'];
  showMyShipments: boolean;
  addressToCountries: ShipmentListFiltersModel['addressToCountries'];
  addressFromCountries: ShipmentListFiltersModel['addressFromCountries'];
  directionTypes: ShipmentListFiltersModel['directionTypes'];
};

type Props = Omit<FilterPanelProps, 'disabled'> & { setPanelVisibility: (to: boolean) => void };

export const ShipmentsFilterForm: FunctionComponent<Props> = ({
  shippingMethodsOptions,
  allowMyShipmentsFilter,
  handleApply,
  setPanelVisibility,
  handleClear,
  filters,
}) => {
  const formatMessage = useFormatMessage();
  return (
    <Formik<FormValues>
      initialValues={{
        shippingMethods: filters?.shippingMethods ?? [],
        createdAtRange: filters?.createdAtRange,
        shippingDateRange: filters?.shippingDateRange,
        statuses: filters?.statuses ?? [],
        showMyShipments: isNotEmpty(filters?.shipmentIds || []) && !filters?.failedBulkActionsType,
        addressToCountries: filters?.addressToCountries ?? [],
        addressFromCountries: filters?.addressFromCountries ?? [],
        directionTypes: filters?.directionTypes ?? [],
      }}
      enableReinitialize
      onSubmit={values => {
        setPanelVisibility(false);
        if (getNumberOfShipmentFilters(values) === 0 && !values.showMyShipments) {
          handleClear();
        }
        handleApply(
          { ...values, failedBulkActionsType: undefined, shipmentIds: undefined },
          values.showMyShipments
        );
      }}
    >
      {({ setFieldValue, handleSubmit, handleReset }) => (
        <form onReset={handleReset} onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.dateFilteringWrapper}>
            <FormikRangePickerField name="createdAtRange" label={formatMessage('CREATED_AT')} />
            <FormikRangePickerField
              name="shippingDateRange"
              label={formatMessage('SHIPPING_DATE')}
            />
          </div>
          <FormikSelectField
            name="shippingMethods"
            label={formatMessage('CARRIER_PRODUCTS')}
            groupOptions={shippingMethodsOptions}
          />
          <FormikSelectField
            name="statuses"
            label={formatMessage('STATUSES')}
            options={statusOptions}
            optionFilterProp="label"
          />
          <div className={styles.showMyShipmentsField}>
            <Field name="showMyShipments">
              {({ field }: FieldProps<FormValues['showMyShipments']>) => (
                <>
                  <div>
                    <Label text={formatMessage('MY_SHIPMENTS_FILTER_LABEL')} />
                    <Tooltip
                      title={formatMessage('MY_SHIPMENTS_FILTER_TOOLTIP')}
                      overlayStyle={{ maxWidth: '500px' }}
                    >
                      <InfoCircleOutlined className={styles.labelTooltipIcon} />
                    </Tooltip>
                  </div>
                  <Switch
                    checked={field.value}
                    disabled={!allowMyShipmentsFilter}
                    onChange={value => setFieldValue(field.name, value)}
                  />
                </>
              )}
            </Field>
          </div>
          <FormikSelectField
            name="addressToCountries"
            label={formatMessage('DELIVERY_COUNTRIES')}
            groupOptions={services.dictionariesService.getCountriesGroupedByRegion()}
          />
          <FormikSelectField
            name="addressFromCountries"
            label={formatMessage('ORIGIN_COUNTRIES')}
            groupOptions={services.dictionariesService.getCountriesGroupedByRegion()}
          />
          <FormikSelectField
            name="directionTypes"
            label={formatMessage('DIRECTION_TYPES')}
            options={deliveryTypeOptions}
            optionFilterProp="label"
          />
          <Divider className={styles.divider} />
          <div className={styles.buttonsWrapper}>
            <Button
              type="dashed"
              className={styles.cancelButton}
              onClick={() => setPanelVisibility(false)}
            >
              {formatMessage('CANCEL')}
            </Button>
            <Button htmlType="submit" type="primary">
              {formatMessage('APPLY')}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

const FormikRangePickerField: FunctionComponent<{ name: string; label: string }> = ({
  name,
  label,
}) => {
  const [field, , helpers] = useField(name);
  return (
    <div className={styles.field}>
      <Label text={label} />
      <RangePicker
        allowClear
        disabledDate={current => current && current > moment().endOf('day')}
        format="YYYY-MM-DD"
        getPopupContainer={triggerNode => triggerNode && triggerNode.parentElement!}
        value={field.value ? [moment(field.value.start), moment(field.value.end)] : [null, null]}
        onChange={range => {
          if (range) {
            const [start, end] = range;
            helpers.setValue(
              start && end
                ? {
                    start: start.startOf('day').toISOString(),
                    end: end.endOf('day').toISOString(),
                  }
                : undefined
            );
          } else {
            helpers.setValue(undefined);
          }
        }}
      />
    </div>
  );
};

type SelectWithOption = {
  name: string;
  label: string;
  options: SelectOption[];
  optionFilterProp?: string;
};

type SelectWithGroupOptions = {
  name: string;
  label: string;
  groupOptions: GroupSelectOption;
  optionFilterProp?: string;
};

const FormikSelectField: FunctionComponent<SelectWithOption | SelectWithGroupOptions> = ({
  name,
  label,
  optionFilterProp = 'children',
  ...restProps
}) => {
  const [field, , helpers] = useField(name);
  const formatMessage = useFormatMessage();
  const commonProps = {
    mode: 'multiple' as const,
    placeholder: formatMessage('PLEASE_SELECT'),
    getPopupContainer: (triggerNode: any) =>
      triggerNode ? triggerNode.parentElement! : document.body,
    optionFilterProp,
    onChange: (values: string[]) => helpers.setValue(values),
  };

  if ('options' in restProps) {
    return (
      <div className={styles.field}>
        <Label text={label} />
        <Select {...field} {...commonProps} options={restProps.options} />
      </div>
    );
  }

  return (
    <div className={styles.field}>
      <Label text={label} />
      <Select {...field} {...commonProps} groupOptions={restProps.groupOptions} />
    </div>
  );
};

const styles = stylesheet({
  form: { display: 'grid', gap: '10px' },
  divider: { marginTop: 10, marginBottom: 10 },
  cancelButton: { marginRight: '10px' },
  field: { display: 'grid', gap: '5px' },
  dateFilteringWrapper: { display: 'grid', gridAutoFlow: 'column', columnGap: '10px' },
  showMyShipmentsField: { display: 'grid', gridTemplateColumns: '1fr auto' },
  buttonsWrapper: { display: 'flex', justifyContent: 'flex-end' },
  labelTooltipIcon: {
    marginLeft: '5px',
    color: 'rgba(0, 0, 0, 0.5)',
  },
});
