import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { AddButton } from '@src/components';
import { Button, PriceInput, Select, Tooltip } from '@src/controls';
import { CarrierProductModel, ConfigRegionModel } from '@src/models';
import { configActions, configSelectors } from '@src/modules/config';
import { commons } from '@src/styles';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { adjust, isEmpty } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { classes, stylesheet } from 'typestyle';

import { FormattedMessage } from '@src/i18n';
import { RootState } from '@src/modules';
import { Field, FieldArray, FieldArrayRenderProps, FieldProps, Form, Formik } from 'formik';
import {
  ConditionInPostalCodeRange,
  getBasePriceFormValue,
  getPriceRuleFormValues,
  getPriceRuleFromFormValue,
  parsePriceRules,
  parsePriceString,
  PriceRuleConditionFormValue,
  PriceRuleType,
} from '../helpers';
import { CategoryRef } from './rules/category-ref';
import { Country } from './rules/country';
import { Attribute } from './rules/attribute';
import { HasAnyDiscount } from './rules/has_any_discount';
import { HasVouchers } from './rules/has_vouchers';
import { IsHoliday } from './rules/is_holiday';
import { PostalCode } from './rules/postal-code';
import { TotalDiscount } from './rules/total_discount';
import { TotalQuantity } from './rules/total_quantity';
import { TotalValue } from './rules/total_value';
import { TotalWeight } from './rules/total_weight';
import { TotalVolume } from './rules/total_volume';
import { Voucher } from './rules/voucher';
import { Region } from './rules/region';
import { GeoListing } from './rules/geo-listing';
import {
  conditionHasDependenciesMet,
  getValidSelectOptions,
  removeConditionAndItsDependants,
} from './rules-filtering';
import { SelectOption } from '@src/controls/select';
import { CarrierProductAvailability } from '@src/containers/regions/carrier-products/rule-adding-container/rules/carrier_product_availability';
import { InPostalCodeRange } from './rules/in_postal_code_range';

interface OwnProps {
  regionId: string;
  product: CarrierProductModel;
  onSaveClick: () => void;
  priceRuleIndex?: number;
}

const mapStateToProps = (
  state: RootState,
  props: OwnProps & RouteComponentProps<{ regionId?: string }>
) => ({
  categories: configSelectors.getCategoriesWithMethodsFromRegionCarrierProducts(
    state,
    props.regionId
  ),
  region: configSelectors.getRegionById(state, props.regionId),
});

const mapDispatchToProps = () => ({
  updateCarrierProduct: dispatchOnDraftSiteId(configActions.updateCarrierProductRequest),
});

type Props = OwnProps &
  RouteComponentProps<{ regionId?: string }> &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export interface RuleAddingValues {
  conditions: PriceRuleConditionFormValue[];
  price: string;
}

interface ClearUnavailableConditionsProps {
  arrayHelpers: FieldArrayRenderProps;
  selectedType: PriceRuleType;
  setSelectedType: (priceRuleType: PriceRuleType) => void;
  options: SelectOption[];
  values: RuleAddingValues;
  region: ConfigRegionModel | undefined;
}

const ClearUnavailableConditions: React.FC<ClearUnavailableConditionsProps> = ({
  arrayHelpers,
  region,
  selectedType,
  setSelectedType,
  options,
  values,
}) => {
  // deselect no longer available condtion from the select component
  React.useEffect(() => {
    if (options.length > 0 && !options.find(option => option.value === selectedType)) {
      setSelectedType(options[0].value as PriceRuleType);
    }

    const conditionsWithUnmetDependencies = values.conditions.filter(
      condition => !conditionHasDependenciesMet(condition, region) // !priceRuleHasDependenciesMet(condition.type, region)
    );
    conditionsWithUnmetDependencies.forEach(condition => {
      removeConditionAndItsDependants(condition.type, values.conditions, arrayHelpers);
    });
  });
  return null;
};

const Component: React.FunctionComponent<Props> = ({
  regionId,
  product,
  priceRuleIndex,
  updateCarrierProduct,
  onSaveClick,
  categories,
  region,
}) => {
  const [selectedType, setSelectedType] = React.useState(PriceRuleType.TOTAL_VALUE);

  const getEmptyConditionBasedOnType = (): PriceRuleConditionFormValue | undefined => {
    switch (selectedType) {
      case PriceRuleType.TOTAL_VALUE:
        return {
          type: PriceRuleType.TOTAL_VALUE,
          operator: '>',
          value: '0',
        };
      case PriceRuleType.TOTAL_CART_ITEMS_VALUE:
        return {
          type: PriceRuleType.TOTAL_CART_ITEMS_VALUE,
          operator: '>',
          value: '0',
        };
      case PriceRuleType.VOUCHER:
        return {
          type: PriceRuleType.VOUCHER,
          values: [],
        };
      case PriceRuleType.HAS_VOUCHERS:
        return {
          type: PriceRuleType.HAS_VOUCHERS,
          values: [],
        };
      case PriceRuleType.COUNTRY:
        return {
          type: PriceRuleType.COUNTRY,
          value: '',
        };
      case PriceRuleType.REGION:
        return {
          type: PriceRuleType.REGION,
          value: '',
        };
      case PriceRuleType.GEO_LISTING:
        return {
          type: PriceRuleType.GEO_LISTING,
          value: '',
        };
      case PriceRuleType.HAS_CART_ATTRIBUTE:
        return {
          type: PriceRuleType.HAS_CART_ATTRIBUTE,
          value: '',
        };

      case PriceRuleType.HAS_CART_ITEM_ATTRIBUTE:
        return {
          type: PriceRuleType.HAS_CART_ITEM_ATTRIBUTE,
          value: '',
        };

      case PriceRuleType.CATEGORY_REF:
        return {
          type: PriceRuleType.CATEGORY_REF,
          value: '',
        };
      case PriceRuleType.TOTAL_WEIGHT:
        return {
          type: PriceRuleType.TOTAL_WEIGHT,
          operator: '>',
          value: 0,
        };
      case PriceRuleType.TOTAL_VOLUME:
        return {
          type: PriceRuleType.TOTAL_VOLUME,
          operator: '>',
          value: 0,
        };
      case PriceRuleType.TOTAL_DISCOUNT:
        return {
          type: PriceRuleType.TOTAL_DISCOUNT,
          operator: '>',
          value: '0',
        };
      case PriceRuleType.TOTAL_QUANTITY:
        return {
          type: PriceRuleType.TOTAL_QUANTITY,
          operator: '>',
          value: 0,
        };
      case PriceRuleType.POSTAL_CODE:
        return {
          type: PriceRuleType.POSTAL_CODE,
          values: [],
        };
      case PriceRuleType.HAS_ANY_DISCOUNT:
        return {
          type: PriceRuleType.HAS_ANY_DISCOUNT,
        };
      case PriceRuleType.IS_HOLIDAY:
        return {
          type: PriceRuleType.IS_HOLIDAY,
        };
      case PriceRuleType.CARRIER_PRODUCT_AVAILABILITY:
        return {
          type: PriceRuleType.CARRIER_PRODUCT_AVAILABILITY,
          value: '',
        };
      case PriceRuleType.IN_POSTAL_CODE_RANGE:
        return {
          type: PriceRuleType.IN_POSTAL_CODE_RANGE,
          value: '',
        };
      // Those two below are just to make exhaustive switch work. Should never be used in this context
      case PriceRuleType.UNKNOWN:
        return {
          type: PriceRuleType.UNKNOWN,
          value: '',
        };
      case PriceRuleType.TRUE:
        return {
          type: PriceRuleType.TRUE,
        };

      default:
        return switchExhaustiveCheck(selectedType);
    }
  };

  const saveChanges = (values: RuleAddingValues) => {
    const { price, conditions } = values;
    const parsedPriceRules = parsePriceRules(product.priceRules);

    const parsedEditableRules = getPriceRuleFormValues(parsedPriceRules);
    const parsedBasePrice = parsePriceString(getBasePriceFormValue(parsedPriceRules));

    updateCarrierProduct({
      model: {
        regionId,
        method: product.shippingMethod,
        externalMethodId: product.externalMethodId,
        priceRules: [
          ...(priceRuleIndex !== undefined
            ? adjust(
                priceRuleIndex,
                () => getPriceRuleFromFormValue({ price, conditions }),
                parsedEditableRules.map(getPriceRuleFromFormValue)
              )
            : [
                ...parsedEditableRules.map(getPriceRuleFromFormValue),
                getPriceRuleFromFormValue({ price, conditions }),
              ]),
          { conditions: ['true'], price: parsedBasePrice },
        ],
        deliveryTypes: product.deliveryTypes,
      },
    });
  };

  const getInitialFormValues = () => {
    const parsedPriceRules = getPriceRuleFormValues(parsePriceRules(product.priceRules));

    return {
      conditions: priceRuleIndex !== undefined ? parsedPriceRules[priceRuleIndex].conditions : [],
      price: priceRuleIndex !== undefined ? parsedPriceRules[priceRuleIndex].price : '0',
    };
  };

  return (
    <Formik
      initialValues={getInitialFormValues()}
      onSubmit={values => {
        saveChanges(values);
        onSaveClick();
      }}
      isInitialValid={false}
    >
      {({ values, setFieldValue, touched, errors }) => {
        const options = getValidSelectOptions(
          [
            { value: PriceRuleType.TOTAL_VALUE, label: 'Total Cart Value' },
            {
              value: PriceRuleType.TOTAL_CART_ITEMS_VALUE,
              label: 'Total Cart Items Value',
            },
            { value: PriceRuleType.TOTAL_WEIGHT, label: 'Total Cart Weight' },
            { value: PriceRuleType.TOTAL_VOLUME, label: 'Total Cart Volume' },
            { value: PriceRuleType.TOTAL_DISCOUNT, label: 'Total Cart Discount' },
            { value: PriceRuleType.TOTAL_QUANTITY, label: 'Total Cart Quantity' },
            { value: PriceRuleType.VOUCHER, label: 'Voucher (Single)' },
            { value: PriceRuleType.HAS_VOUCHERS, label: 'Vouchers (Multiple)' },
            { value: PriceRuleType.POSTAL_CODE, label: 'Postal Code' },
            { value: PriceRuleType.COUNTRY, label: 'Country' },
            { value: PriceRuleType.REGION, label: 'Region / State' },
            { value: PriceRuleType.GEO_LISTING, label: 'Geographical listing' },
            { value: PriceRuleType.HAS_CART_ATTRIBUTE, label: 'Cart Attribute' },
            {
              value: PriceRuleType.HAS_CART_ITEM_ATTRIBUTE,
              label: 'Cart Item Attribute',
            },
            { value: PriceRuleType.CATEGORY_REF, label: 'Category Reference' },
            { value: PriceRuleType.HAS_ANY_DISCOUNT, label: 'Has Any Discount' },
            { value: PriceRuleType.IS_HOLIDAY, label: 'Is Holiday' },
            {
              value: PriceRuleType.CARRIER_PRODUCT_AVAILABILITY,
              label: 'Carrier Product Availability',
            },
            {
              value: PriceRuleType.IN_POSTAL_CODE_RANGE,
              label: 'In Postal Code Range',
            },
          ],
          values.conditions,
          region
        );

        return (
          <Form>
            <FieldArray name="conditions">
              {arrayHelpers => (
                <div className={styles.wrapper}>
                  <ClearUnavailableConditions
                    arrayHelpers={arrayHelpers}
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                    region={region}
                    options={options}
                    values={values}
                  />
                  <div className={styles.addCondition}>
                    <Select
                      className={styles.conditionSelect}
                      options={options}
                      value={selectedType}
                      onChange={(value: PriceRuleType) => {
                        setSelectedType(value);
                      }}
                    />
                    <AddButton
                      text={<FormattedMessage id="ADD_CONDITION" />}
                      onClick={() => arrayHelpers.unshift(getEmptyConditionBasedOnType())}
                    />
                  </div>
                  <div className={styles.conditions}>
                    {values.conditions.map((condition, index) => (
                      <div
                        key={`${condition.type}_${index}`}
                        className={classes(commons.alignCenter, commons.flex)}
                      >
                        {(() => {
                          switch (condition.type) {
                            case PriceRuleType.TOTAL_VALUE:
                              return (
                                <TotalValue
                                  name={`conditions[${index}]`}
                                  setFieldValue={setFieldValue}
                                  label={<FormattedMessage id="TOTAL_VALUE_IS" />}
                                />
                              );
                            case PriceRuleType.TOTAL_CART_ITEMS_VALUE:
                              return (
                                <TotalValue
                                  name={`conditions[${index}]`}
                                  setFieldValue={setFieldValue}
                                  label={<FormattedMessage id="TOTAL_CART_ITEMS_VALUE_IS" />}
                                />
                              );
                            case PriceRuleType.VOUCHER:
                              return (
                                <Voucher
                                  name={`conditions[${index}]`}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                />
                              );
                            case PriceRuleType.HAS_VOUCHERS:
                              return (
                                <HasVouchers
                                  name={`conditions[${index}]`}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                />
                              );
                            case PriceRuleType.POSTAL_CODE:
                              return (
                                <PostalCode
                                  name={`conditions[${index}]`}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                />
                              );
                            case PriceRuleType.HAS_CART_ATTRIBUTE:
                            case PriceRuleType.HAS_CART_ITEM_ATTRIBUTE:
                              return (
                                <Attribute
                                  type={condition.type}
                                  name={`conditions[${index}]`}
                                  errors={errors}
                                  touched={touched}
                                />
                              );
                            case PriceRuleType.COUNTRY:
                              return (
                                <Country
                                  name={`conditions[${index}]`}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                />
                              );
                            case PriceRuleType.REGION:
                              return (
                                <Region
                                  name={`conditions[${index}]`}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                  countries={region?.regionCountryConfig?.included || []}
                                />
                              );
                            case PriceRuleType.GEO_LISTING:
                              return (
                                <GeoListing
                                  name={`conditions[${index}]`}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                  currentGeoListings={values.conditions.filter(
                                    condition => condition.type === PriceRuleType.GEO_LISTING
                                  )}
                                />
                              );
                            case PriceRuleType.CATEGORY_REF:
                              return (
                                <CategoryRef
                                  name={`conditions[${index}]`}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                  categories={categories}
                                />
                              );
                            case PriceRuleType.TOTAL_WEIGHT:
                              return (
                                <TotalWeight
                                  name={`conditions[${index}]`}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                  touched={touched}
                                />
                              );
                            case PriceRuleType.TOTAL_VOLUME:
                              return (
                                <TotalVolume
                                  name={`conditions[${index}]`}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                  touched={touched}
                                />
                              );
                            case PriceRuleType.TOTAL_DISCOUNT:
                              return (
                                <TotalDiscount
                                  name={`conditions[${index}]`}
                                  setFieldValue={setFieldValue}
                                />
                              );
                            case PriceRuleType.TOTAL_QUANTITY:
                              return (
                                <TotalQuantity
                                  name={`conditions[${index}]`}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                  touched={touched}
                                />
                              );
                            case PriceRuleType.HAS_ANY_DISCOUNT:
                              return <HasAnyDiscount name={`conditions[${index}]`} />;
                            case PriceRuleType.IS_HOLIDAY:
                              return <IsHoliday name={`conditions[${index}]`} />;
                            case PriceRuleType.CARRIER_PRODUCT_AVAILABILITY:
                              return (
                                <CarrierProductAvailability
                                  name={`conditions[${index}]`}
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                />
                              );
                            case PriceRuleType.IN_POSTAL_CODE_RANGE:
                              return (
                                <InPostalCodeRange
                                  name={`conditions[${index}]`}
                                  postalCodesCondition={
                                    values.conditions.find(
                                      condition =>
                                        condition.type === PriceRuleType.IN_POSTAL_CODE_RANGE
                                    ) as ConditionInPostalCodeRange
                                  }
                                  errors={errors}
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                />
                              );
                            default:
                              return condition.type;
                          }
                        })()}

                        <DeleteOutlined
                          onClick={() =>
                            removeConditionAndItsDependants(
                              values.conditions[index].type,
                              values.conditions,
                              arrayHelpers
                            )
                          }
                          className={styles.deleteIcon}
                        />
                      </div>
                    ))}
                  </div>
                  <div className={styles.footer}>
                    <div className={classes(commons.flex, commons.alignCenter)}>
                      <FormattedMessage id="CARRIER_PRODUCT_PRICE" />
                      <Tooltip title={<FormattedMessage id="RULE_ADDING_TOOLTIP" />}>
                        <InfoCircleOutlined
                          style={{
                            marginBottom: 'auto',
                            marginLeft: '4px',
                            marginRight: '6px',
                          }}
                        />
                      </Tooltip>
                      <Field name="price">
                        {({ field }: FieldProps<RuleAddingValues['price']>) => (
                          <PriceInput
                            {...field}
                            onChange={value => setFieldValue('price', value)}
                          />
                        )}
                      </Field>
                    </div>
                    <Button type="primary" htmlType="submit" disabled={isEmpty(values.conditions)}>
                      <FormattedMessage id="SAVE" />
                    </Button>
                  </div>
                </div>
              )}
            </FieldArray>
          </Form>
        );
      }}
    </Formik>
  );
};

const styles = stylesheet({
  wrapper: {
    minWidth: '420px',
  },
  conditions: {
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
    paddingTop: '20px',
    borderTop: '1px solid #D9D9D9',
  },
  addCondition: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  conditionSelect: {
    width: '150px',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
  },
  deleteIcon: {
    fontSize: '16px',
    marginLeft: '10px',
  },
});

export const RuleAddingContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Component)
);
