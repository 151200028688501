import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { connect } from 'react-redux';
import { Omit } from 'utility-types';

import { Badge, Table } from '../../../controls';
import { AddonModel } from '../../../models';
import { RootState } from '../../../modules';

type ComponentProps = {
  pageSize: number;
  size?: 'middle' | 'small';
  showEmptyMessage?: boolean;
  items: AddonModel[];
  onItemDelete?: (index: number) => void;
  onItemUpdate?: (index: number, record: AddonModel) => void;
};

@observer
class Component extends React.Component<ComponentProps, {}> {
  @observable
  sortingState = {
    order: 'descend' as 'descend',
    columnKey: 'created',
  };

  @observable tempRecords = new Map<number, AddonModel>();

  setSortingState = (sorting: typeof Component.prototype.sortingState) => {
    this.sortingState.columnKey = sorting.columnKey;
    this.sortingState.order = sorting.order;
  };

  handleEditAction = (index: number) => {
    this.tempRecords.set(index, this.props.items[index]);
  };

  handleRemoveAction = (index: number) => {
    const { onItemDelete } = this.props;
    if (onItemDelete) {
      onItemDelete(index);
    }
  };

  handleCancelAction = (index: number) => {
    this.tempRecords.delete(index);
  };

  handleSaveAction = (index: number) => {
    const { onItemUpdate } = this.props;
    if (onItemUpdate && this.tempRecords.has(index)) {
      const data = this.tempRecords.get(index)!;
      onItemUpdate(index, data);
    }
    this.tempRecords.delete(index);
  };

  render() {
    const { items, size = 'middle', showEmptyMessage } = this.props;

    return (
      <Table<AddonModel>
        size={size}
        title={() => ''}
        dataSource={items}
        rowKey={(record, i) => record.name + i}
        pagination={false}
        onChange={(paging, filtering, sorting) => {
          this.setSortingState(sorting as any);
        }}
        showEmptyMessage={showEmptyMessage}
        emptyMessage={<p>List is empty</p>}
        sortDirections={['ascend', 'descend']}
      >
        <Table.Column<AddonModel>
          title="Name"
          key="name"
          width="100px"
          render={(text, record, index) => record.name || <Badge.Empty />}
        />
        <Table.Column<AddonModel>
          title="Code"
          key="code"
          width="50px"
          render={(text, record) => record.code || <Badge.Empty />}
        />
        <Table.Column<AddonModel>
          title="Description"
          key="description"
          width="200px"
          render={(text, record) => record.description || <Badge.Empty />}
        />
      </Table>
    );
  }
}

// Connected Type
type OwnProps = Omit<ComponentProps, 'pageSize'>;

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  pageSize: state.app.pageSize,
});

export const AddonsTable = connect(mapStateToProps, {})(Component);
