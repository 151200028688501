import React, { Fragment, VFC } from 'react';
import { FindTrackingForm } from './find-tracking-form';
import { useFindTracking } from './use-find-tracking';
import { WidgetSection } from './widget-section';
import { WidgetCard } from '../../components';

export const DeliveryTracking: VFC = () => {
  const [trackingData, fetchTrackingData, trackingDataLoading] = useFindTracking();

  return (
    <Fragment>
      <FindTrackingForm onSubmit={fetchTrackingData} />
      <WidgetCard isLoading={trackingDataLoading}>
        <WidgetSection trackingData={trackingData} />
      </WidgetCard>
    </Fragment>
  );
};
