// tslint:disable:jsx-no-multiline-js
import { Card } from 'antd';
import * as React from 'react';

type Props = {
  title?: string | JSX.Element;
  message?: string;
};
type State = {};

const DEFAULT_TITLE = 'No items';
const DEFAULT_MESSAGE = 'Currently there are no items.';

class Component extends React.Component<Props, State> {
  render() {
    const { title = DEFAULT_TITLE, message = DEFAULT_MESSAGE } = this.props;

    const description = <div>{message && <p>{message}</p>}</div>;

    return (
      <section>
        <Card title={title} bordered={false}>
          {description}
        </Card>
      </section>
    );
  }
}

export default Component;
