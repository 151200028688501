import { getType } from 'typesafe-actions';

import { ContactModel } from '../../models';
import { Reducer } from '../../modules';

import { addressBookActions } from '.';
import { ERRORS } from '../../dictionaries';

// STATE
export type State = Readonly<{
  isFetching: boolean;
  error: string | null;
  contacts: ContactModel[];
  count: number;

  /**
   * In shipment's detail page we have three separate fields for addresses:
   * Customer, Sender, Delivery Address
   * Each of them makes API calls when filtering in the dropdown, which causes problems since
   * if user filters contacts in one field, the other are broken because they were using the same global state.
   * That's why we distinguish between these states so that the fields are fully independent.
   */
  customerContacts: ContactModel[];
  senderContacts: ContactModel[];
  deliveryContacts: ContactModel[];
}>;

const initialState: State = {
  isFetching: false,
  error: null,
  contacts: [],
  count: 0,
  customerContacts: [],
  senderContacts: [],
  deliveryContacts: [],
};

const reducer: Reducer<State> = (state: State = initialState, action): State => {
  switch (action.type) {
    case getType(addressBookActions.getContactsListRequest):
    case getType(addressBookActions.createContactRequest):
    case getType(addressBookActions.createContactFromModalRequest):
    case getType(addressBookActions.updateContactRequest):
    case getType(addressBookActions.updateContactFromModalRequest):
    case getType(addressBookActions.deleteContactsRequest):
    case getType(addressBookActions.createContactsWithCSVRequest):
    case getType(addressBookActions.getCustomerContactsRequest):
    case getType(addressBookActions.getSenderContactsRequest):
    case getType(addressBookActions.getDeliveryContactsRequest):
      return { ...state, isFetching: true, error: null };

    case getType(addressBookActions.getContactsListError):
    case getType(addressBookActions.createContactError):
    case getType(addressBookActions.updateContactError):
    case getType(addressBookActions.getCustomerContactsError):
    case getType(addressBookActions.getSenderContactsError):
    case getType(addressBookActions.getDeliveryContactsError):
      return { ...state, isFetching: false, error: action.payload };

    case getType(addressBookActions.deleteContactsError):
      return { ...state, isFetching: false, error: ERRORS.DELETE_ADDRESS_ERROR };
    case getType(addressBookActions.createContactsWithCSVError):
      return { ...state, isFetching: false, error: ERRORS.CREATE_ADDRESSES_FAILED };

    case getType(addressBookActions.updateContactSuccess):
      return { ...state, isFetching: false };
    case getType(addressBookActions.deleteContactsSuccess):
      return {
        ...state,
        contacts: state.contacts.filter(contact => !action.payload.deletedIds.includes(contact.id)),
        isFetching: false,
      };

    case getType(addressBookActions.createContactsWithCSVSuccess):
      const { contacts, count } = action.payload;
      return {
        ...state,
        contacts: [...contacts, ...state.contacts],
        count: state.count + count,
        isFetching: false,
      };

    case getType(addressBookActions.getContactsListSuccess):
      return {
        ...state,
        isFetching: false,
        contacts: action.payload.contacts,
        customerContacts: action.payload.contacts,
        senderContacts: action.payload.contacts,
        deliveryContacts: action.payload.contacts,
        count: action.payload.count,
      };

    case getType(addressBookActions.getCustomerContactsSuccess):
      return {
        ...state,
        isFetching: false,
        customerContacts: action.payload.contacts,
      };

    case getType(addressBookActions.getSenderContactsSuccess):
      return {
        ...state,
        isFetching: false,
        senderContacts: action.payload.contacts,
      };

    case getType(addressBookActions.getDeliveryContactsSuccess):
      return {
        ...state,
        isFetching: false,
        deliveryContacts: action.payload.contacts,
      };

    case getType(addressBookActions.createTempContact):
      return { ...state, isFetching: false, contacts: [...state.contacts, action.payload] };
    case getType(addressBookActions.createContactSuccess):
      // do not update contacts here as state is already updated by optimistic update in epics
      return { ...state, isFetching: false };

    default:
      return state;
  }
};

export default reducer;
