import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;

const MERGE_LABELS_REQUEST = '@labelMerger/MERGE_LABELS_REQUEST';
const MERGE_LABELS_SUCCESS = '@labelMerger/MERGE_LABELS_SUCCESS';
const MERGE_LABELS_ERROR = '@labelMerger/MERGE_LABELS_ERROR';

const RESET_MERGED = '@labelMerger/RESET_MERGED';

export const mergeLabelsRequest = createAction(
  MERGE_LABELS_REQUEST,
  resolve => (payload: { siteId: string; fileNames: string[] }) => resolve(payload)
);

export const mergeLabelsSuccess = createAction(
  MERGE_LABELS_SUCCESS,
  resolve => (url: string) => resolve(url)
);
export const mergeLabelsError = createAction(
  MERGE_LABELS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const resetMerged = createAction(RESET_MERGED);
