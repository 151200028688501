import { getType } from 'typesafe-actions';

import { RootAction } from '..';
import { UserGetModel } from '../../models';
import * as actions from './auth-actions';

// STATE
export type AuthState = Readonly<{
  isLoading: boolean;
  isPasswordResetSuccess: boolean;
  error: string | null;
  authToken: string | null;
  authUser: UserGetModel | null;
  lastActiveDate: number | null;
}>;

export const initialState: AuthState = {
  isLoading: false,
  isPasswordResetSuccess: false,
  error: null,
  authToken: null,
  authUser: null,
  lastActiveDate: null,
};

// REDUCER
export default function reducer(state: AuthState = initialState, action: RootAction): AuthState {
  switch (action.type) {
    case getType(actions.authenticate):
    case getType(actions.passwordReset):
    case getType(actions.fetchAuthUser):
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case getType(actions.authenticateError):
    case getType(actions.passwordResetError):
    case getType(actions.fetchAuthUserError):
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case getType(actions.authenticateSuccess):
      return {
        ...state,
        isLoading: false,
        authToken: action.payload,
      };

    case getType(actions.fetchAuthUserSuccess):
      return {
        ...state,
        isLoading: false,
        authUser: action.payload,
      };

    case getType(actions.logout):
      return {
        ...state,
        authToken: null,
        authUser: null,
        lastActiveDate: null,
      };

    case getType(actions.passwordResetSuccess):
      return {
        ...state,
        isLoading: false,
        isPasswordResetSuccess: true,
      };

    case getType(actions.updateLastActivity):
      return {
        ...state,
        lastActiveDate: action.payload,
      };

    default:
      return state;
  }
}
