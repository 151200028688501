import { SIWItemModel } from '@src/models';
import { getPostalCodeValidationByCountry } from '@src/utils/validation';
import { yupToFormErrors } from 'formik';
import { mixed, object, string } from 'yup';
import { Cart, CartGroup, Dimensions } from '@src/api/delivery-checkout';
import { parsePriceString } from '../regions/carrier-products/helpers';
import { Moment } from 'moment';
import { RangePickerBaseProps } from 'antd/lib/date-picker/generatePicker';

export type CartItemV4 = {
  attributes?: string[];
  dimensions?: Dimensions;
  discount: string;
  image_url?: string;
  name: string;
  out_of_stock?: boolean;
  price: string;
  quantity: number;
  site_external_id?: string;
  sku: string;
  weight?: number;
  shipping_date?: RangePickerBaseProps<Moment>['value'];
};

export type CartV4 = {
  attributes?: string[];
  cart_id: string;
  delivery_price_discount?: number;
  external_id?: string;
  groups: CartGroup[];
  items: CartItemV4[];
  pre_order?: boolean;
  total_discount: string;
  total_value: string;
  vouchers?: string[];
  shipping_date?: RangePickerBaseProps<Moment>['value'];
};

export type WidgetFormValues = {
  useDraft: boolean;
  purchaseCountry: string;
  purchaseRegion?: string;
  purchaseCurrency: string;
  locale: string;
  totalValue: string;
  voucher: string;
  items: Array<Omit<SIWItemModel, 'price'> & { price: string }>;
  shippingDate?: { start: string; end: string };
  cartId?: string;
  cartAttributes?: string;
  meta: { key: string; values: string }[];
  searchAddress: {
    addressLine1: string;
    postalCode: string;
    city: string;
  };
  deliveryAddress: {
    addressLine1: string;
    name: string;
    postalCode: string;
    city: string;
    email: string;
    phone: string;
  };
  externalId?: string;
};

export const getInitalValues = (draftSiteId: string): WidgetFormValues => {
  return {
    purchaseCountry: 'SE',
    purchaseCurrency: 'SEK',
    locale: 'en-US',
    totalValue: '10.00',
    items: [
      {
        sku: 'a3ec72d0-836f-4668-9c54-5c86d67ca897',
        name: 'Keyboard',
        attributes: [],
        weight: 0,
        quantity: 1,
        price: '0',
      },
    ],
    voucher: '',
    useDraft: !!draftSiteId,
    shippingDate: undefined,
    cartId: undefined,
    cartAttributes: undefined,
    meta: [
      { key: 'fulfilment_check_endpoint', values: '' },
      { key: 'fulfilment_check_ignore', values: '' },
    ],
    searchAddress: {
      addressLine1: '',
      postalCode: '',
      city: '',
    },
    externalId: undefined,
    deliveryAddress: {
      addressLine1: '',
      postalCode: '',
      city: '',
      name: 'Ingrid Tester',
      email: 'test@ingrid.com',
      phone: '',
    },
  };
};

export const getValidation = (values: WidgetFormValues) =>
  object({
    purchaseCountry: string().min(2).max(2).required(),
    purchaseCurrency: string().min(3).max(3).required(),
    locale: string().required(),
    searchAddress: object({
      postalCode: mixed().test(
        'match postalCode',
        `Zip code is not valid for selected purchase country`,
        // tslint:disable-next-line: only-arrow-functions
        function (value: string) {
          return value
            ? getPostalCodeValidationByCountry((this.options.context as any).purchaseCountry).test(
                value
              )
            : true;
        }
      ),
    }),
    deliveryAddress: object({
      postalCode: mixed().test(
        'match postalCode',
        `Zip code is not valid for selected purchase country`,
        // tslint:disable-next-line: only-arrow-functions
        function (value: string) {
          return value
            ? getPostalCodeValidationByCountry((this.options.context as any).purchaseCountry).test(
                value
              )
            : true;
        }
      ),
    }),
  })
    .validate(values, {
      abortEarly: false,
      context: { purchaseCountry: values.purchaseCountry },
    })
    // if there are no errors do not return them
    // tslint:disable-next-line: no-empty
    .then(() => {})
    .catch(err => yupToFormErrors(err));

export const mapRangePickerArrayToObject = (values: RangePickerBaseProps<Moment>['value']) => {
  return values?.[0] && values?.[1]
    ? { start: values?.[0].format(), end: values?.[1].format() }
    : undefined;
};

export function mapCart(cart: CartV4): Cart {
  return {
    ...cart,
    total_discount: parsePriceString(cart.total_discount),
    total_value: parsePriceString(cart.total_value),
    shipping_date: mapRangePickerArrayToObject(cart.shipping_date),
    items: cart.items.map(item => ({
      ...item,
      discount: parsePriceString(item.discount),
      price: parsePriceString(item.price),
      shipping_date: mapRangePickerArrayToObject(item.shipping_date),
    })),
  };
}
