import { MADError } from '@src/utils';
import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;
import { ConfigSiteModel } from '../../models';

const GET_SITES_REQUEST = '@sites/GET_SITES_REQUEST';
const GET_SITES_SUCCESS = '@sites/GET_SITES_SUCCESS';
const GET_SITES_ERROR = '@sites/GET_SITES_ERROR';

export const getSitesRequest = createAction(
  GET_SITES_REQUEST,
  resolve =>
    ({
      merchantId,
      siteId,
      onComplete = () => null,
    }: {
      merchantId: string;
      siteId: string;
      onComplete?: () => void;
    }) =>
      resolve({ merchantId, siteId }, { onComplete })
);
export const getSitesSuccess = createAction(
  GET_SITES_SUCCESS,
  resolve => (sites: ConfigSiteModel[]) => resolve(sites)
);

export const getSitesError = createAction(
  GET_SITES_ERROR,
  resolve => (error: MADError) => resolve(error)
);
