import { combineReducers } from 'redux';
import { optimistic, OptimisticState } from 'redux-optimistic-ui';
import { getType } from 'typesafe-actions';
import { dissoc } from 'ramda';

import { CarrierProductMappingModel, CustomEventModel, InternalEventModel } from '@src/models';
import { Reducer } from '@src/modules';
import { trackingWidgetActions } from '.';
import { configActions } from '../config';
import { sitesActions } from '../sites';

export type TrackingWidgetState = Readonly<{
  carrierProductMappingsBySiteId: OptimisticState<{
    [siteId: string]: CarrierProductMappingModel[];
  }>;
  customTrackingEventsBySiteId: OptimisticState<{ [siteId: string]: CustomEventModel[] }>;
  internalTrackingEventsBySiteId: OptimisticState<{ [siteId: string]: InternalEventModel[] }>;
  isFetching: boolean;
}>;

const carrierProductMappingsBySiteId: Reducer<
  TrackingWidgetState['carrierProductMappingsBySiteId']['current']
> = (state = {}, action) => {
  switch (action.type) {
    case getType(sitesActions.getSitesSuccess):
      return action.payload.reduce<
        TrackingWidgetState['carrierProductMappingsBySiteId']['current']
      >((result, item) => {
        return { ...result, [item.id]: item.tracking?.carrierProductMappings ?? [] };
      }, {});

    default:
      return state;
  }
};

const isFetching: Reducer<TrackingWidgetState['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(trackingWidgetActions.createCustomEventRequest):
      return true;

    case getType(trackingWidgetActions.createCustomEventError):
    case getType(trackingWidgetActions.createCustomEventSuccess):
      return false;

    default:
      return state;
  }
};

const customTrackingEventsBySiteId: Reducer<
  TrackingWidgetState['customTrackingEventsBySiteId']['current']
> = (state = {}, action) => {
  switch (action.type) {
    case getType(sitesActions.getSitesSuccess):
      return action.payload.reduce<TrackingWidgetState['customTrackingEventsBySiteId']['current']>(
        (result, item) => {
          return { ...result, [item.id]: item.tracking?.eventTypes?.custom ?? [] };
        },
        {}
      );

    case getType(configActions.createDraftSuccess):
      return { ...state, [action.payload.siteId]: state[action.payload.master.siteId] };

    case getType(trackingWidgetActions.updateCustomEventRequest): {
      const { siteId, upsertModel } = action.payload;
      return {
        ...state,
        [siteId]: state[siteId].map(event =>
          event.customEventTypeId === upsertModel.id
            ? {
                ...event,
                customEventTypeId: upsertModel.id,
                name: upsertModel.name,
                state: upsertModel.state,
              }
            : event
        ),
      };
    }

    case getType(trackingWidgetActions.updateCustomEventTranslationRequest): {
      const { siteId, upsertModel: updateModel } = action.payload;
      return {
        ...state,
        [siteId]: state[siteId].map(event =>
          event.customEventTypeId === updateModel.id
            ? {
                ...event,
                localization: {
                  ...event.localization,
                  [updateModel.locale]: {
                    name: updateModel.name,
                    description: updateModel.description,
                  },
                },
              }
            : event
        ),
      };
    }

    case getType(trackingWidgetActions.deleteCustomEventTranslationRequest): {
      const { siteId, deleteModel } = action.payload;
      return {
        ...state,
        [siteId]: state[siteId].map(event =>
          event.customEventTypeId === deleteModel.id
            ? {
                ...event,
                localization: dissoc(deleteModel.locale, event.localization),
              }
            : event
        ),
      };
    }

    case getType(trackingWidgetActions.deleteCustomEventRequest): {
      const { siteId, id } = action.payload;
      return { ...state, [siteId]: state[siteId].filter(link => link.customEventTypeId !== id) };
    }

    case getType(trackingWidgetActions.createCustomEventSuccess):
      return {
        ...state,
        [action.payload.siteId]: [...state[action.payload.siteId], action.payload.displayModel],
      };

    default:
      return state;
  }
};

const internalTrackingEventsBySiteId: Reducer<
  TrackingWidgetState['internalTrackingEventsBySiteId']['current']
> = (state = {}, action) => {
  switch (action.type) {
    case getType(sitesActions.getSitesSuccess):
      return action.payload.reduce<
        TrackingWidgetState['internalTrackingEventsBySiteId']['current']
      >((result, item) => {
        return { ...result, [item.id]: item.tracking?.eventTypes?.internal ?? [] };
      }, {});

    case getType(configActions.createDraftSuccess):
      return { ...state, [action.payload.siteId]: state[action.payload.master.siteId] };

    case getType(trackingWidgetActions.updateInternalEventRequest): {
      const { siteId, updateModel } = action.payload;
      return {
        ...state,
        [siteId]: state[siteId].map(event =>
          event.id === updateModel.id
            ? {
                ...event,
                id: updateModel.id,
                state: updateModel.state,
              }
            : event
        ),
      };
    }

    default:
      return state;
  }
};
export default combineReducers<TrackingWidgetState>({
  carrierProductMappingsBySiteId: optimistic(carrierProductMappingsBySiteId),
  customTrackingEventsBySiteId: optimistic(customTrackingEventsBySiteId),
  internalTrackingEventsBySiteId: optimistic(internalTrackingEventsBySiteId),
  isFetching,
});
