import * as Yup from 'yup';

export const validationSchema = Yup.object().shape(
  {
    trackingNumber: Yup.string()
      .test(
        'notBothAtTheSameTime',
        'You cannot pass external id at the same time as tracking number',
        function (value) {
          const { externalId } = this.parent;
          if (externalId && value) {
            return false;
          }
          return true;
        }
      )
      .when(['externalId'], {
        is: externalId => !externalId,
        then: Yup.string().required('Tracking number is required'),
      }),

    externalId: Yup.string()
      .test(
        'notBothAtTheSameTime',
        'You cannot pass tracking number at the same time as external id',
        function (value) {
          const { trackingNumber } = this.parent;
          if (trackingNumber && value) {
            return false;
          }
          return true;
        }
      )
      .when(['trackingNumber'], {
        is: trackingNumber => !trackingNumber,
        then: Yup.string().required('External id is required'),
      }),

    contact: Yup.string().required('Contact is required'),
  },
  [['trackingNumber', 'externalId']]
);
