import { Switch } from 'antd';
import { equals, insert, remove, without } from 'ramda';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { DragableRow } from '@src/components';
import { Table } from '@src/controls';
import { DragIcon } from '@src/images/drag-icon';
import { ShippingLogo, ShippingLogoName } from '@src/components/shipping-logo';

type Props = {
  availableCarrierLogos: string[];
  carrierLogos: string[];
  updateCarrierLogos: (carrierLogos: string[]) => void;
};

type TableItem = {
  carrierLogo: string;
  checked: boolean;
};

export const PlaceholderCarrierLogosTable: React.FC<Props> = ({
  availableCarrierLogos,
  carrierLogos,
  updateCarrierLogos,
}) => {
  const [logoOrder, setLogoOrder] = React.useState([
    ...carrierLogos,
    ...availableCarrierLogos.filter(
      availableLogo =>
        !carrierLogos.find(logo => availableLogo.split('-')[0] === logo.split('-')[0])
    ),
  ]);
  const dataSource: TableItem[] = logoOrder.map(logo => ({
    carrierLogo: logo,
    checked: !!carrierLogos.find(carrierLogo => logo.split('-')[0] === carrierLogo.split('-')[0]),
  }));

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const elem = logoOrder[dragIndex];
    const newLogoOrder = insert(hoverIndex, elem, remove(dragIndex, 1, logoOrder));
    setLogoOrder(newLogoOrder);
    const newCarrierLogos = newLogoOrder.filter(logo => carrierLogos.includes(logo));
    if (!equals(carrierLogos, newCarrierLogos)) {
      updateCarrierLogos(newCarrierLogos);
    }
  };

  return (
    <Table<TableItem>
      className={styles.table}
      dataSource={dataSource}
      rowKey={record => record.carrierLogo}
      onRow={(_, index) => ({ index, moveRow })}
      components={{ body: { row: DragableRow } }}
      pagination={false}
      hideTitle={true}
    >
      <Table.Column<TableItem>
        title="Carrier"
        key="carrier"
        render={(_, record) => {
          return (
            <div className={styles.logoWrapper}>
              <DragIcon />
              <ShippingLogo className={styles.logo} shippingMethod={record.carrierLogo} />
              <ShippingLogoName shippingMethod={record.carrierLogo} />
            </div>
          );
        }}
      />
      <Table.Column<TableItem>
        className={styles.showColumn}
        title="Show"
        key="show"
        render={(_, record) => {
          return (
            <Switch
              onChange={enabled => {
                if (enabled) {
                  updateCarrierLogos([...carrierLogos, record.carrierLogo]);
                } else {
                  updateCarrierLogos(carrierLogos.filter(logo => logo !== record.carrierLogo));
                  if (
                    !availableCarrierLogos.find(
                      availableLogo =>
                        record.carrierLogo.split('-')[0] === availableLogo.split('-')[0]
                    )
                  ) {
                    setLogoOrder(without([record.carrierLogo], logoOrder));
                  }
                }
              }}
              checked={record.checked}
            />
          );
        }}
      />
    </Table>
  );
};

const styles = stylesheet({
  table: {
    $nest: {
      '.ant-table-thead tr th': {
        background: 'rgba(0,0,0,0.02)',
        borderBottom: '1px solid rgba(0,0,0,0.09)',
      },
    },
  },
  showColumn: {
    $nest: {
      '&.ant-table-cell': {
        textAlign: 'right',
      },
      '.ant-table-header-column': {
        width: '100%',
      },
      '.ant-table-header-column div': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    paddingRight: '8px',
  },
});
