import * as React from 'react';
import { stylesheet } from 'typestyle';

import { Tag } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { configSelectors } from '@src/modules/config';
import { defaultTheme } from '@src/styles';
import { useSelector } from '@src/utils/hooks';
import { LabelDetailsEdit } from './label-details-edit';
import { LabelTranslationsEdit } from './label-translations-edit';

interface Props {
  labelId: string;
  categoryId: string;
}

export const LabelEdit: React.FC<Props> = ({ categoryId, labelId }) => {
  const categoryLabel = useSelector(state =>
    configSelectors.getCategoryById(state, categoryId)?.labels?.find(label => label.id === labelId)
  );
  if (!categoryLabel) {
    return null;
  }
  return (
    <div>
      <Tag color={defaultTheme.color.primary} nonClickable={true}>
        <FormattedMessage id="LABEL_DETAILS.TITLE" />
      </Tag>
      <h2 className={styles.header}>{categoryLabel.name}</h2>
      <LabelDetailsEdit label={categoryLabel} categoryId={categoryId} />
      {categoryLabel.type === 'LABEL_TYPE_SWAN' || (
        <LabelTranslationsEdit label={categoryLabel} categoryId={categoryId} />
      )}
    </div>
  );
};

const styles = stylesheet({
  header: {
    marginTop: '10px',
  },
});
