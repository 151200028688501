import * as React from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { Spin } from 'antd';

import { useDispatch, useSelector } from '@src/utils/hooks';
import { services } from '@src/services';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { SitesSelectors } from '@src/modules/sites';
import { useCreateDraft } from '@src/utils/conditional-dispatchers';
import { configActions } from '@src/modules/config';

import { FeaturesTable } from './features-table';
import { UpsellWidgetDrawer } from '@src/components/widget-drawers/upsell-widget-drawer';

export const UpsellDefaultSettingsComponent: React.FC = () => {
  const siteId = useSelector(SitesSelectors.getDraftSiteIdOrMasterSiteId);
  const isFetching = useSelector(state => state.sites.isFetching);
  const isCreatingDraft = useSelector(state => state.config.isCreatingDraft);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const createDraft = useCreateDraft();

  const updateMutation = useMutation<void, void, Record<string, any>>(async values => {
    const draftSiteId = await createDraft();
    dispatch(configActions.genericConfigModificationRequest());
    const configuration = {
      features: values,
    };
    await services.selfCareService.updateUpsellConfiguration(draftSiteId, configuration);
    dispatch(configActions.genericConfigModificationSuccess());
    // old draft site data might load inbetween.
    queryClient.invalidateQueries(['site']);
  });

  const siteQuery = useQuery({
    queryKey: ['site', siteId],
    queryFn: async () => {
      return await services.configService.getSite(siteId);
    },
    staleTime: Infinity,
    enabled: !updateMutation.isLoading,
  });

  const features = siteQuery.data?.upsellWidgetConfiguration?.features || {};

  if (isFetching || !siteQuery.isSuccess) {
    return <Spin size="large" />;
  }

  return (
    <FeaturesTable
      key={siteQuery.dataUpdatedAt}
      initialValues={features}
      loading={isCreatingDraft || siteQuery.isFetching || updateMutation.isLoading}
      onSubmit={updateMutation.mutate}
    />
  );
};

export const UpsellDefaultSettings: React.FC = () => {
  return (
    <>
      <StatusBarContainer />
      <h2>Default upsell settings</h2>
      <UpsellDefaultSettingsComponent />
      <UpsellWidgetDrawer />
    </>
  );
};
