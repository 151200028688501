import { getRandomNumber } from '@src/utils/number';
import { createOptimisticAction } from '@src/utils/optimistic-updates';
import { BEGIN, COMMIT, REVERT } from 'redux-optimistic-ui';
import { Action, deprecated } from 'typesafe-actions';
import {
  CreateGroupModel,
  RBACGroupModel,
  RoleModel,
  UpdateGroupModel,
  UserCreateModel,
  UserGetModel,
  UserSummaryModel,
} from '../../models';
const { createAction } = deprecated;

const FETCH_LIST_USERS = '@users/FETCH_LIST_USERS';
const FETCH_LIST_USERS_SUCCESS = '@users/FETCH_LIST_USERS_SUCCESS';
const FETCH_LIST_USERS_ERROR = '@users/FETCH_LIST_USERS_ERROR';
const FETCH_CREATE_USER = '@users/FETCH_CREATE_USER';
const FETCH_CREATE_USER_SUCCESS = '@users/FETCH_CREATE_USER_SUCCESS';
const FETCH_CREATE_USER_ERROR = '@users/FETCH_CREATE_USER_ERROR';
const FETCH_DELETE_USER = '@users/FETCH_DELETE_USER';
const FETCH_DELETE_USER_SUCCESS = '@users/FETCH_DELETE_USER_SUCCESS';
const FETCH_DELETE_USER_ERROR = '@users/FETCH_DELETE_USER_ERROR';
const UPDATE_USER_REQUEST = '@users/UPDATE_USER_REQUEST';
const UPDATE_USER_SUCCESS = '@users/UPDATE_USER_SUCCESS';
const UPDATE_USER_ERROR = '@users/UPDATE_USER_ERROR';
const GET_USER_REQUEST = '@users/GET_USER_REQUEST';
const GET_USER_SUCCESS = '@users/GET_USER_SUCCESS';
const GET_USER_ERROR = '@users/GET_USER_ERROR';
const GET_GROUP_LIST_REQUEST = '@users/GET_GROUP_LIST_REQUEST';
const GET_GROUP_LIST_SUCCESS = '@users/GET_GROUP_LIST_SUCCESS';
const GET_GROUP_LIST_ERROR = '@users/GET_GROUP_LIST_ERROR';
const ASSIGN_USER_TO_GROUP_REQUEST = '@users/ASSIGN_USER_TO_GROUP_REQUEST';
const ASSIGN_USER_TO_GROUP_SUCCESS = '@users/ASSIGN_USER_TO_GROUP_SUCCESS';
const ASSIGN_USER_TO_GROUP_ERROR = '@users/ASSIGN_USER_TO_GROUP_ERROR';
const REMOVE_USER_FROM_GROUP_REQUEST = '@users/REMOVE_USER_FROM_GROUP_REQUEST';
const REMOVE_USER_FROM_GROUP_SUCCESS = '@users/REMOVE_USER_FROM_GROUP_SUCCESS';
const REMOVE_USER_FROM_GROUP_ERROR = '@users/REMOVE_USER_FROM_GROUP_ERROR';
const LIST_ROLES_REQUEST = '@users/LIST_ROLES_REQUEST';
const LIST_ROLES_SUCCESS = '@users/LIST_ROLES_SUCCESS';
const LIST_ROLES_ERROR = '@users/LIST_ROLES_ERROR';
const CREATE_GROUP_REQUEST = '@users/CREATE_GROUP_REQUEST';
const CREATE_GROUP_SUCCESS = '@users/CREATE_GROUP_SUCCESS';
const CREATE_GROUP_ERROR = '@users/CREATE_GROUP_ERROR';
const DELETE_GROUP_REQUEST = '@users/DELETE_GROUP_REQUEST';
const DELETE_GROUP_SUCCESS = '@users/DELETE_GROUP_SUCCESS';
const DELETE_GROUP_ERROR = '@users/DELETE_GROUP_ERROR';
const UPDATE_GROUP_REQUEST = '@users/UPDATE_GROUP_REQUEST';
const UPDATE_GROUP_SUCCESS = '@users/UPDATE_GROUP_SUCCESS';
const UPDATE_GROUP_ERROR = '@users/UPDATE_GROUP_ERROR';
const LIST_USER_SUMMARIES_REQUEST = '@users/LIST_USER_SUMMARIES_REQUEST';
const LIST_USER_SUMMARIES_SUCCESS = '@users/LIST_USER_SUMMARIES_SUCCESS';
const LIST_USER_SUMMARIES_ERROR = '@users/LIST_USER_SUMMARIES_ERROR';

export const fetchListUsers = createAction(FETCH_LIST_USERS);
export const fetchListUsersSuccess = createAction(
  FETCH_LIST_USERS_SUCCESS,
  resolve => (users: UserGetModel[]) => resolve(users)
);
export const fetchListUsersError = createAction(
  FETCH_LIST_USERS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const fetchCreateUser = createAction(
  FETCH_CREATE_USER,
  resolve => (payload: { user: UserCreateModel; onComplete: (userId: string) => Action<any>[] }) =>
    resolve(payload.user, { onComplete: payload.onComplete })
);
export const fetchCreateUserSuccess = createAction(
  FETCH_CREATE_USER_SUCCESS,
  resolve => (user: UserGetModel) => resolve(user)
);
export const fetchCreateUserError = createAction(
  FETCH_CREATE_USER_ERROR,
  resolve => (error: string) => resolve(error)
);

export const updateUserRequest = createAction(
  UPDATE_USER_REQUEST,
  resolve => (payload: { user: UserCreateModel; onComplete: (userId: string) => Action<any>[] }) =>
    resolve(payload.user, { onComplete: payload.onComplete })
);
export const updateUserSuccess = createAction(
  UPDATE_USER_SUCCESS,
  resolve => (user: UserGetModel) => resolve(user)
);
export const updateUserError = createAction(
  UPDATE_USER_ERROR,
  resolve => (error: string) => resolve(error)
);

export const getUserRequest = createAction(
  GET_USER_REQUEST,
  resolve => (payload: { userId: string }) => resolve(payload)
);
export const getUserSuccess = createAction(
  GET_USER_SUCCESS,
  resolve => (user: UserGetModel) => resolve(user)
);
export const getUserError = createAction(
  GET_USER_ERROR,
  resolve => (error: string) => resolve(error)
);

export const fetchDeleteUser = createAction(
  FETCH_DELETE_USER,
  resolve => (id: string) => resolve(id)
);
export const fetchDeleteUserSuccess = createAction(
  FETCH_DELETE_USER_SUCCESS,
  resolve => (id: string) => resolve(id)
);
export const fetchDeleteUserError = createAction(
  FETCH_DELETE_USER_ERROR,
  resolve => (error: string) => resolve(error)
);
export const getGroupListRequest = createAction(
  GET_GROUP_LIST_REQUEST,
  resolve => (payload: { merchantId: string; siteId: string }) => resolve(payload)
);
export const getGroupListSuccess = createAction(
  GET_GROUP_LIST_SUCCESS,
  resolve => (groups: RBACGroupModel[]) => resolve(groups)
);
export const getGroupListError = createAction(
  GET_GROUP_LIST_ERROR,
  resolve => (error: string) => resolve(error)
);

export const assignUserToGroupRequest = createAction(
  ASSIGN_USER_TO_GROUP_REQUEST,
  resolve => (payload: { userId: string; groupId: string }) => resolve(payload)
);
export const assignUserToGroupSuccess = createAction(ASSIGN_USER_TO_GROUP_SUCCESS);
export const assignUserToGroupError = createAction(
  ASSIGN_USER_TO_GROUP_ERROR,
  resolve => (error: string) => resolve(error)
);

export const removeGroupFromUserRequest = createAction(
  REMOVE_USER_FROM_GROUP_REQUEST,
  resolve => (payload: { userId: string; groupId: string }) => resolve(payload)
);
export const removeGroupFromUserSuccess = createAction(REMOVE_USER_FROM_GROUP_SUCCESS);
export const removeGroupFromUserError = createAction(
  REMOVE_USER_FROM_GROUP_ERROR,
  resolve => (error: string) => resolve(error)
);
export const listRolesRequest = createAction(LIST_ROLES_REQUEST);
export const listRolesSuccess = createAction(
  LIST_ROLES_SUCCESS,
  resolve => (payload: { roles: RoleModel[] }) => resolve(payload)
);
export const listRolesError = createAction(
  LIST_ROLES_ERROR,
  resolve => (error: string) => resolve(error)
);

export const createGroupRequest = createAction(
  CREATE_GROUP_REQUEST,
  resolve =>
    ({ onComplete, ...payload }: { model: CreateGroupModel; onComplete?: () => void }) =>
      resolve(payload, { onComplete })
);
export const createGroupSuccess = createAction(
  CREATE_GROUP_SUCCESS,
  resolve => (payload: RBACGroupModel) => resolve(payload)
);
export const createGroupError = createAction(
  CREATE_GROUP_ERROR,
  resolve => (error: string) => resolve(error)
);

export const deleteGroupRequest = createOptimisticAction(
  DELETE_GROUP_REQUEST,
  resolve => (payload: { id: string }) =>
    resolve(payload, {
      optimistic: {
        type: BEGIN,
        id: getRandomNumber(),
      },
    })
);
export const deleteGroupSuccess = createOptimisticAction(
  DELETE_GROUP_SUCCESS,
  resolve => (payload: { id: string; transactionId: number }) =>
    resolve(payload, { optimistic: { type: COMMIT, id: payload.transactionId } })
);
export const deleteGroupError = createOptimisticAction(
  DELETE_GROUP_ERROR,
  resolve =>
    ({ error, transactionId }: { error: string; transactionId: number }) =>
      resolve(error, { optimistic: { type: REVERT, id: transactionId } })
);

export const updateGroupRequest = createAction(
  UPDATE_GROUP_REQUEST,
  resolve =>
    ({ onComplete, ...payload }: { model: UpdateGroupModel; onComplete?: () => void }) =>
      resolve(payload, { onComplete })
);
export const updateGroupSuccess = createAction(UPDATE_GROUP_SUCCESS);
export const updateGroupError = createAction(
  UPDATE_GROUP_ERROR,
  resolve => (error: string) => resolve(error)
);

export const listUserSummariesRequest = createAction(
  LIST_USER_SUMMARIES_REQUEST,
  resolve =>
    (payload: { userIds: string[]; siteId: string; isSuperuser: boolean; merchantId: string }) =>
      resolve(payload)
);

export const listUserSummariesSuccess = createAction(
  LIST_USER_SUMMARIES_SUCCESS,
  resolve => (payload: UserSummaryModel[]) => resolve(payload)
);

export const listUserSummariesError = createAction(
  LIST_USER_SUMMARIES_ERROR,
  resolve => (error: string) => resolve(error)
);
