import * as React from 'react';
import { stylesheet } from 'typestyle';
import { Table, Tag, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { DeleteButton } from '@src/controls';
import { MESSAGES } from '@src/dictionaries';
import { VariantModel } from '@src/models';

interface Props {
  data: VariantModel[];
  loading: boolean;
  renderDetails: (values: VariantModel, idx: number) => React.ReactNode;
  disableEdit?: boolean;
  onDelete: (record: VariantModel) => void;
  onEdit: (record: VariantModel) => void;
  onShowCart: (record: VariantModel) => void;
}

export const VariantList: React.FC<Props> = ({
  data,
  loading,
  renderDetails,
  disableEdit,
  onDelete,
  onEdit,
  onShowCart,
}) => {
  const columns: ColumnType<VariantModel>[] = [
    {
      key: 'details',
      render: (dummy: string, record: VariantModel, idx: number) => renderDetails(record, idx),
    },
    {
      dataIndex: 'id',
      key: 'cart',
      render: (id: string, record: VariantModel, idx: number) => {
        if (idx === 0 && record.diff.no_of_changes) {
          return (
            <Tooltip title="Out of sync means that the experiment original is not the same as the production site configuration. The production site has been updated since the experiment was created.">
              <Tag color="gold">out of sync</Tag>
            </Tooltip>
          );
        }

        if (disableEdit || idx === 0) {
          return null;
        }

        return (
          <div className={styles.linkWrapper}>
            <a onClick={() => onShowCart(record)} className={styles.link}>
              Cart
            </a>
            <a onClick={() => onEdit(record)} className={styles.link}>
              Modify
            </a>
            <div className={styles.link}>
              <DeleteButton title={`Delete "${record.name}"`} onConfirm={() => onDelete(record)} />
            </div>
          </div>
        );
      },
      align: 'left',
      className: styles.cell,
    },
  ];

  return (
    <Table
      dataSource={data}
      rowKey="id"
      columns={columns}
      loading={loading}
      pagination={false}
      showHeader={false}
      locale={{ emptyText: MESSAGES.EMPTY_TABLE }}
      className={styles.table}
    />
  );
};

const styles = stylesheet({
  table: {
    borderTop: '1px solid #D9D9D9',
  },
  cell: {
    verticalAlign: 'top',
    lineHeight: '32px',
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  link: {
    padding: '0 16px',
  },
});
