import * as React from 'react';
import { stylesheet } from 'typestyle';

import { DragableRow, LinkStyleButton } from '@src/components';
import { Switch, Table } from '@src/controls';
import { CategoryLabelModel } from '@src/models';

import { FormattedMessage } from '@src/i18n';
import { DragIcon } from '@src/images/drag-icon';
import leafIcon from '@src/images/icons/leaf.svg';
import lightningIcon from '@src/images/icons/lightning.svg';
import swanIcon from '@src/images/icons/swan-logo.svg';
import { configActions } from '@src/modules/config';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { DeleteLabelPopover } from './delete-label-popover';

interface Props {
  labels: CategoryLabelModel[];
  categoryId: string;
  isCreatingLabel: boolean;
  onLabelClick: (labelId: string) => void;
}

type LabelsWithIcon = Exclude<CategoryLabelModel['type'], 'LABEL_TYPE_CUSTOM'>;

const getLabelIcon = (type: LabelsWithIcon) => {
  const iconsDict: { [key in LabelsWithIcon]: string } = {
    LABEL_TYPE_PRIORITY: lightningIcon,
    LABEL_TYPE_SUSTAINABILITY: leafIcon,
    LABEL_TYPE_SWAN: swanIcon,
  };
  return iconsDict[type];
};

const labelTypeToNameMap: { [key in CategoryLabelModel['type']]: string } = {
  LABEL_TYPE_CUSTOM: 'Custom',
  LABEL_TYPE_PRIORITY: 'Priority',
  LABEL_TYPE_SUSTAINABILITY: 'Sustainability',
  LABEL_TYPE_SWAN: 'Swan',
};

export const LabelsTable: React.FC<Props> = ({
  labels,
  categoryId,
  isCreatingLabel,
  onLabelClick,
}) => {
  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const labelIdsWithoutSelectedOne = labels
      .filter((_, index) => index !== dragIndex)
      .map(label => label.id);

    const updatedOrder: string[] = [
      ...labelIdsWithoutSelectedOne.slice(0, hoverIndex),
      labels[dragIndex].id,
      ...labelIdsWithoutSelectedOne.slice(hoverIndex),
    ];

    dispatchOnDraftSiteId(configActions.changeCategoryLabelsOrderRequest)({
      model: {
        labelsIds: updatedOrder,
        categoryId,
      },
    });
  };

  const switchActiveState = ({ active, label }: { active: boolean; label: CategoryLabelModel }) => {
    dispatchOnDraftSiteId(configActions.updateCategoryLabelDetailsRequest)({
      model: {
        labelId: label.id,
        name: label.name,
        type: label.type,
        categoryId,
        state: active ? 'ACTIVE' : 'INACTIVE',
      },
    });
  };

  return (
    <Table<CategoryLabelModel>
      dataSource={labels}
      loading={isCreatingLabel}
      pagination={false}
      onRow={(_, index) => ({ index, moveRow })}
      components={{ body: { row: DragableRow } }}
      rowKey={(_, index) => `row_${index}`}
      hideTitle
      className={styles.table}
    >
      <Table.Column<CategoryLabelModel>
        title={<FormattedMessage id="NAME" />}
        key="Name"
        render={(_, label) => (
          <div className={styles.flexWrapper}>
            <DragIcon />
            <LinkStyleButton className={styles.flexWrapper} onClick={() => onLabelClick(label.id)}>
              {label.name}
            </LinkStyleButton>
          </div>
        )}
      />
      <Table.Column<CategoryLabelModel>
        title={<FormattedMessage id="TYPE" />}
        key="Type"
        render={(_, label) => (
          <div className={styles.flexWrapper}>
            {label.type !== 'LABEL_TYPE_CUSTOM' && (
              <img className={styles.typeIcon} src={getLabelIcon(label.type)} draggable="false" />
            )}
            <span className={styles.type}>{labelTypeToNameMap[label.type]}</span>
          </div>
        )}
      />
      <Table.Column<CategoryLabelModel>
        title={<FormattedMessage id="ACTIVE" />}
        key="Active"
        render={(_, label) => (
          <Switch
            checked={label.state === 'ACTIVE'}
            onChange={active => switchActiveState({ active, label })}
          />
        )}
      />
      <Table.Column<CategoryLabelModel>
        title={<FormattedMessage id="DELETE" />}
        key="Delete"
        render={(_, label) => <DeleteLabelPopover label={label} categoryId={categoryId} />}
      />
    </Table>
  );
};

const styles = stylesheet({
  table: {
    $nest: {
      table: {
        borderCollapse: 'collapse',
      },
    },
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  typeIcon: {
    height: '14px',
    width: '14px',
  },
  type: {
    marginLeft: '4px',
  },
});
