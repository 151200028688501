import { Tooltip } from 'antd';
import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { head } from 'ramda';
import { stylesheet } from 'typestyle';
import { useFormatMessage } from '@src/i18n';
import { services } from '@src/services';
import { useMasterSiteId } from '@src/utils/hooks';
import { FindTrackingData } from './use-find-tracking';
import { CopyRow } from '../../components';
import { trimTrackingPageUrl } from '../../utils';

const env = services.envService.getEnvironmentalVariables();
const { moiraiService } = services;

type Props = {
  trackingData: FindTrackingData;
  locale: string;
};

export const Details: FC<Props> = ({ trackingData, locale }) => {
  const formatMessage = useFormatMessage();
  const siteId = useMasterSiteId();
  const [trackingPageUrl, setTrackingPageUrl] = useState('');

  const { trackingNumbers, externalId, tosId } = trackingData;

  useQuery({
    queryKey: ['moirai/token.generate', siteId, locale, externalId, tosId, trackingNumbers],
    queryFn: () => {
      if (tosId || (externalId && trackingData.trackingIdentifier === 'externalId')) {
        return moiraiService.generateToken({ siteId, locale, externalId, tosId });
      }

      return moiraiService.generateToken({ siteId, locale, trackingNumber: head(trackingNumbers) });
    },
    onSuccess: ({ token }) => {
      const newTrackingPageUrl = new URL(`${locale}/${token}`, env.MAD_TRACKING_PAGE_LINK).href;
      setTrackingPageUrl(newTrackingPageUrl);
    },
  });

  const trackingNumberRow =
    !!trackingNumbers.length &&
    trackingNumbers.map(trackingNumber => (
      <CopyRow
        key={trackingNumber}
        textToCopy={trackingNumber}
        notificationText={formatMessage('TRACKING_NUMBER_COPY_NOTIFICATION')}
      >
        {formatMessage('TRACKING_NUMBER_WITH_LABEL', {
          trackingNumber,
        })}
      </CopyRow>
    ));

  const externalIdRow = externalId && (
    <CopyRow
      textToCopy={externalId}
      notificationText={formatMessage('EXTERNAL_ID_COPY_NOTIFICATION')}
    >
      {formatMessage('EXTERNAL_ID_WITH_LABEL', { externalId })}
    </CopyRow>
  );

  const trackingPageUrlRow = trackingPageUrl && (
    <CopyRow
      textToCopy={trackingPageUrl}
      notificationText={formatMessage('TRACKING_PAGE_LINK_COPY_NOTIFICATION')}
    >
      <Tooltip title={trackingPageUrl}>
        <a href={trackingPageUrl} target="_blank" rel="noopener noreferrer">
          {trimTrackingPageUrl(trackingPageUrl)}
        </a>
      </Tooltip>
    </CopyRow>
  );

  return (
    <div className={styles.rows}>
      {trackingNumberRow}
      {externalIdRow}
      {trackingPageUrlRow}
    </div>
  );
};

const styles = stylesheet({
  rows: { display: 'grid', gap: '8px' },
  generateTrackingPageUrlButton: { width: 'min-content' },
});
