import { always, cond, equals } from 'ramda';
import * as React from 'react';

import { Tag } from '../../../controls';
import { OrderStatusEnum } from '../../../models';

interface IProps {
  status: OrderStatusEnum | undefined;
}

const TransportOrderStatusTag: React.StatelessComponent<IProps> = props => {
  const { status } = props;

  const { text, color } = cond([
    [equals(OrderStatusEnum.ACTIVE), always({ text: 'Active', color: 'green-inverse' })],
    [equals(OrderStatusEnum.ARCHIVED), always({ text: 'Archived', color: 'grey' })],
    [equals(OrderStatusEnum.UNKNOWN), always({ text: 'Unknown', color: 'lightgrey' })],
    [equals(undefined), always({ text: 'Missing', color: 'lightgrey' })],
  ])(status!);

  return <Tag color={color}>{text}</Tag>;
};

export { TransportOrderStatusTag };
