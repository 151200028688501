import { ReceiptWidgetConfiguration } from '@src/models';
import { FieldNames } from './types';

export const getInitialValues = (
  features?: ReceiptWidgetConfiguration['features']
): { [K in FieldNames]: boolean } => ({
  'header.enable': features?.header.enable_section ?? false,
  'order_details.enable':
    (features?.order_details.enable_header && features?.order_details.enable_section) ?? false,
  'delivery.enable':
    (features?.delivery.enable_header && features.delivery.enable_section) ?? false,
  'delivery.is_courier_instructions_editable':
    features?.delivery.is_courier_instructions_editable ?? false,
  'delivery.is_door_code_editable': features?.delivery.is_door_code_editable ?? false,
  'order_summary.enable':
    (features?.order_summary.enable_section && features?.order_summary.enable_header) ?? false,
  'rating.enable': (features?.rating.enable_section && features.rating.enable_header) ?? false,
  'footer.show_slogan': features?.footer.show_slogan ?? false,
});
