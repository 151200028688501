import React, { FC } from 'react';
import { useSelector } from '@src/utils/hooks';
import { configSelectors } from '@src/modules/config';
import { style as tss } from 'typestyle';
import { ShippingLogo } from '@src/components/shipping-logo';

type Props = {
  shippingMethod: string;
};

export const CarrierProductWithLogo: FC<Props> = ({ shippingMethod }) => {
  const rootState = useSelector(state => state);

  const method = configSelectors.getShippingMethodForShipment(rootState, shippingMethod);

  return (
    <div className={styles.containerStyles}>
      {method && (
        <ShippingLogo className={styles.shipmentMethodLogo} shippingMethod={shippingMethod} />
      )}
      <span>{method?.name ?? '-'}</span>
    </div>
  );
};

const styles = {
  containerStyles: tss({
    display: 'flex',
    alignItems: 'center',
  }),
  shipmentMethodLogo: tss({
    marginRight: '8px',
  }),
};
