import * as React from 'react';
import { Formik } from 'formik';

import { InputNumber } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, AutoSaveFormikEnhancer, inputStyle, wideLayout } from '@src/forms';

import {
  ConfigShippingCategoryModel,
  UpdateCategoryDetailsModel,
} from '@src/models';

const InputNumberField = withFormItem(InputNumber, wideLayout);

interface Props {
  category: ConfigShippingCategoryModel;
  onCategoryDetailChange: (values: Partial<UpdateCategoryDetailsModel>) => void;
}

export const MaxChoicesCount: React.FC<Props> = ({
  category,
  onCategoryDetailChange,
}) => {
  if (category.deliveryType !== 'DELIVERY') {
    return null;
  }

  const disabled = (category.shippingMethods ?? []).length > 1

  const labelTooltip = disabled
    ? 'Timeslots limits can\'t be set for category with more than one carrier products. Please contact Ingrid Customer Support and get more help.'
    : 'Limits number of timeslots available for consumer to a given number. Leave this field empty to show all available timeslots for a carrier product used in this category.';

  return (
    <AutoSaveForm>
      <Formik
        initialValues={{
          maxChoicesCount: category.maxChoicesCount,
        }}
        enableReinitialize={false}
        onSubmit={() => undefined}
      >
        {formik => (
          <AutoSaveFormikEnhancer
            onSave={(value) => {
              onCategoryDetailChange({
                maxChoicesCount: formik.values.maxChoicesCount,
              });
            }}
            name="maxChoicesCount"
            render={({ onBlur, onKeyDown }) => (
              <InputNumberField
                label="Limit timeslots to"
                labelTooltip={labelTooltip}
                className={inputStyle}
                disabled={disabled}
                min={0}
                value={formik.values.maxChoicesCount}
                onBlur={onBlur}
                onChange={(value: number) =>
                  formik.setFieldValue(
                    'maxChoicesCount',
                    value
                  )
                }
                onKeyDown={onKeyDown}
              />
            )}
          />
        )}
      </Formik>
    </AutoSaveForm>
  );
}
