import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';

import { modalConfirm } from './controls';
import { MESSAGES } from './dictionaries';
import { getRootReducer, RootState } from './modules';
import { rootEpic } from './modules/root-epic';
import { services } from './services';

const env = services.envService.getEnvironmentalVariables();

const composeEnhancers =
  (env.MAD_DEPLOY_TARGET === 'local' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const browserHistory = createBrowserHistory({
  // This overwrites default window.confirm alert used with react-router <Prompt /> component
  getUserConfirmation(message: string, callback: (allowTransition: boolean) => any) {
    modalConfirm({
      title: MESSAGES.PAGE_LEAVE_PROMPT_TITLE,
      content: message,
      onOk: () => callback(true),
      onCancel: () => callback(false),
    });
  },
});

export function configureStore(initialState?: RootState) {
  const epicMiddleware = createEpicMiddleware({ dependencies: services });
  const middlewares = [epicMiddleware, routerMiddleware(browserHistory)];
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  const createdStore = createStore(
    getRootReducer(browserHistory),
    (initialState as unknown)!,
    enhancer
  );
  epicMiddleware.run(rootEpic);

  return createdStore;
}

const getLocalStorageState = () => {
  if (!services.cookiesService.getSessionActive()) {
    services.localStorageService.clearState();
    return;
  }
  return services.localStorageService.loadState();
};

export const store = configureStore(getLocalStorageState());
