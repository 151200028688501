import { eventDataValue } from '@src/modules/delivery-tracking-notifications/smart-notifications-settings/smart-notifications.types';

export const eventLogicData: Record<string, eventDataValue> = {
  pickup_reminder_settings: {
    key: 'pickup_reminder_settings',
    eventLogic: [
      {
        eventHeader: 'NOTIFICATIONS.SMART.DELIVERED_PICKUP',
        eventMessage: 'NOTIFICATIONS.SMART.EVENT_HAPPENED',
      },
      {
        eventHeader: 'NOTIFICATIONS.SMART.HOUR_THRESHOLD',
        eventMessage: 'NOTIFICATIONS.SMART.HOURS_PASSED',
        threshold: true,
      },
      {
        eventHeader: 'NOTIFICATIONS.SMART.PICKED_UP_BY_CUSTOMER',
        eventMessage: 'NOTIFICATIONS.SMART.EVENT_DIDNT_HAPPEN',
      },
    ],
  },
};
