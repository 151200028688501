import React, { FC } from 'react';
import { stylesheet } from 'typestyle';

import { GenerateForm } from './generate-form';
import { WidgetSection } from './widget-section';
import { useTrackingData } from './hooks';

export const GenerateSection: FC = () => {
  const [trackingData, setTrackingData] = useTrackingData();

  return (
    <div className={styles.main}>
      <GenerateForm setTrackingData={setTrackingData} />
      <WidgetSection trackingData={trackingData} />
    </div>
  );
};

const styles = stylesheet({
  main: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '24px',
  },
});
