import { combineEpics, Epic } from 'redux-observable';
import { from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { RootAction, RootState, Services } from '../../modules';
import { MADError } from '../../utils';
import { siwActions } from './';

const createWidgetSession: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { siwService, loggingService, messageService }
) =>
  action$.pipe(
    filter(isActionOf(siwActions.createSessionRequest)),
    switchMap(({ payload: { siteId, model } }) =>
      observableFrom(siwService.createSessionWith(model, siteId)).pipe(
        map(res => siwActions.createSessionSuccess(res)),
        catchError((error: MADError) =>
          observableOf(siwActions.createSessionError(error.message)).pipe(
            tap(() => {
              messageService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const updateWidgetSession: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { siwService, loggingService, messageService }
) =>
  action$.pipe(
    filter(isActionOf(siwActions.updateSessionRequest)),
    switchMap(({ payload: { siteId, model } }) =>
      observableFrom(siwService.updateSessionWith(model, siteId)).pipe(
        tap(() => siwService.suspendWidget()),
        map(res => siwActions.updateSessionSuccess(res)),
        tap(() => siwService.resumeWidget()),
        catchError((error: MADError) =>
          observableOf(siwActions.updateSessionError(error.message)).pipe(
            tap(() => {
              messageService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const getSession: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { siwService, loggingService, messageService }
) =>
  action$.pipe(
    filter(isActionOf(siwActions.getSessionRequest)),
    switchMap(({ payload: { siteId, sessionId }, meta }) =>
      observableFrom(siwService.getSession(siteId, sessionId)).pipe(
        map(res => siwActions.getSessionSuccess({ model: res.model, dto: res.dto })),
        finalize(() => {
          meta.onComplete?.();
        }),
        catchError((error: MADError) =>
          observableOf(siwActions.getSessionError(error.message)).pipe(
            tap(() => {
              messageService.error(error.message);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const completeSession: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { siwService, loggingService, messageService }
) =>
  action$.pipe(
    filter(isActionOf(siwActions.completeSessionRequest)),
    switchMap(({ payload: { siteId, session, externalId }, meta }) => {
      let tosId: null | string = null;
      return observableFrom(siwService.completeSession({ siteId, session, externalId })).pipe(
        map(res => {
          tosId = res.tosId;
          return siwActions.completeSessionSuccess(res.tosId);
        }),
        catchError((error: MADError) =>
          observableOf(siwActions.completeSessionError(error.message)).pipe(
            tap(() => {
              messageService.error(error.message);
              loggingService.logError(error);
            })
          )
        ),
        finalize(() => {
          if (tosId) {
            meta.onComplete(tosId);
          }
        })
      );
    })
  );

export const siwEpics = combineEpics(
  createWidgetSession,
  updateWidgetSession,
  getSession,
  completeSession
);
