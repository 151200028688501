import { Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { FormattedMessage } from '@src/i18n';
import { getFieldArrayError } from '@src/utils/forms';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { array, string } from 'yup';
import { RuleAddingValues } from '../rule-adding-container';

const SelectItem = withFormItem(Select);

interface Props {
  name: string;
  errors: FormikProps<RuleAddingValues>['errors'];
  touched: FormikProps<RuleAddingValues>['touched'];
  setFieldValue: FormikProps<RuleAddingValues>['setFieldValue'];
}

const postalCodeValidate = async (value: string) => {
  try {
    await array()
      .min(1, 'Provide at least one postal code')
      .of(string().matches(/^[^\s^'"]+$/, 'Postal code should not contain spaces or apostrophes'))
      .validate(value);
  } catch (err) {
    return err;
  }
};

export const PostalCode: React.FunctionComponent<Props> = ({
  name,
  errors,
  touched,
  setFieldValue,
}) => {
  return (
    <div className={styles.wrapper}>
      <Field name={`${name}.values`} validate={postalCodeValidate}>
        {({ field }: FieldProps<string>) => (
          <SelectItem
            label={<FormattedMessage id="POSTAL_CODE_IS_ONE_OF" />}
            className={styles.select}
            options={[]}
            mode="tags"
            {...field}
            onChange={values => {
              setFieldValue(`${name}.values`, values);
            }}
            placeholder="-"
            error={getFieldArrayError(touched, errors, `${name}.values`)}
          />
        )}
      </Field>
    </div>
  );
};

const styles = stylesheet({
  wrapper: {
    padding: '5px 0',
    $nest: {
      '.ant-row': {
        display: 'flex',
        marginBottom: 0,
      },
      '.ant-form-explain': {
        width: '245px',
      },
    },
  },
  select: {
    width: '245px !important',
  },
  label: {
    paddingRight: '10px',
    whiteSpace: 'nowrap',
  },
});
