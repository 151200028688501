import React from 'react';
import { Button } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { TrackingPreviewDrawerData, TrackingRecord } from './types';
import { SourceTag, TextWithCopy, StatusTag, CarrierProductWithLogo } from './components';
import { dateUtils, stringUtils } from '@src/utils';
import { style as tss } from 'typestyle';
import { humanize } from '@src/utils/string';

export const columns = (
  orderByDirection: 'ascend' | 'descend',
  showTrackingPreviewDrawerWith: (previewData: TrackingPreviewDrawerData) => void
) => {
  const columns: ColumnType<TrackingRecord>[] = [
    {
      title: 'Tracking number',
      dataIndex: 'tracking_number',
      key: 'tracking_number',
      width: 150,
      render: trackingNumber => (
        <TextWithCopy
          text={trackingNumber}
          onCopyText="Tracking Number (SSCC) copied to clipboard."
          className={styles.textWithCopyStyles}
        >
          <span>{stringUtils.truncateId(trackingNumber)}</span>
        </TextWithCopy>
      ),
    },
    {
      title: 'External ID',
      dataIndex: 'external_id',
      key: 'external_id',
      render: externalId =>
        (externalId && (
          <TextWithCopy text={externalId} onCopyText="External ID copied to clipboard.">
            {externalId}
          </TextWithCopy>
        )) ??
        '-',
    },
    {
      title: 'Carrier Product',
      dataIndex: 'carrier_product_id',
      key: 'carrier_product_id',
      render: (_, record) => {
        return (
          <CarrierProductWithLogo
            carrierProductId={record.carrier_product_id}
            carrier={record.carrier_product_carrier}
            name={record.carrier_product_name}
          />
        );
      },
    },
    {
      title: 'Carrier Product reference',
      dataIndex: 'carrier_product_ref',
      key: 'carrier_product_ref',
      render: carrierProductRef => carrierProductRef ?? '-',
    },
    {
      title: 'Source',
      dataIndex: 'tracking_record_source',
      key: 'trackingRecordSource',
      render: source => (source && <SourceTag source={source} />) ?? '-',
    },
    {
      title: 'Status',
      dataIndex: 'latest_tracking_status',
      key: 'latest_tracking_status',
      render: status => <StatusTag status={status} />,
    },
    {
      title: 'Direction',
      dataIndex: 'direction_type',
      key: 'direction_type',
      render: direction => humanize(direction ?? '-'),
    },
    {
      title: 'Date created',
      dataIndex: 'created_at',
      key: 'created_at',
      sortDirections: ['descend', 'ascend', 'descend'],
      sortOrder: orderByDirection,
      sorter: true,
      render: createdAt => dateUtils.formatIsoDate(createdAt),
    },
    {
      render: (_, { external_id, tracking_number, tos_id }) => (
        <Button
          onClick={event => {
            event.stopPropagation();
            showTrackingPreviewDrawerWith({
              externalId: external_id ?? '',
              tosId: tos_id ?? '',
              trackingNumber: tracking_number ?? '',
            });
          }}
          type="ghost"
          size="small"
        >
          Preview
        </Button>
      ),
    },
  ];

  return columns;
};

const styles = {
  textWithCopyStyles: tss({
    justifyContent: 'space-between',
  }),
};
