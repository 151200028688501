import * as React from 'react';
import * as moment from 'moment';
import { Tooltip, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { DeleteButton } from '@src/controls';
import { Link } from '@src/components/link';
import { MESSAGES } from '@src/dictionaries';
import { ExperimentModel } from '@src/models';
import { stylesheet } from 'typestyle';
import { getCountryCodeToCountryNameMapping } from '@src/services/dictionaries-service';
import { isAuthUserSuperUser } from '@src/modules/auth/auth-selectors';
import { useSelector } from '@src/utils/hooks';

interface Props {
  data?: ExperimentModel[];
  loading: boolean;
  disableDelete?: boolean;
  onDelete: (id: string) => void;
}

const countryCodeToNameMap = getCountryCodeToCountryNameMapping();
export const ExperimentList: React.FC<Props> = ({ data, loading, disableDelete, onDelete }) => {
  const isSuperUser = useSelector(isAuthUserSuperUser);
  const columns: ColumnType<ExperimentModel>[] = [
    {
      title: 'Name',
      dataIndex: 'name',

      render: (text: string, record: ExperimentModel) => {
        return record.type === 'INTERNAL' && !isSuperUser ? (
          <Tooltip
            title={`This is an Ingrid internal experiment aiming to improve UX. It only affects the UI, not the configuration. If you want to run a different experiment in ${
              countryCodeToNameMap[record.country]
            } or have questions about this one, please contact Ingrid support`}
            trigger="hover"
          >
            <Link
              showIcon
              baseStyles
              route={{ name: 'EXPERIMENT_DETAILS', experimentId: record.id }}
              disabled
            >
              {text}
            </Link>
          </Tooltip>
        ) : (
          <Link showIcon baseStyles route={{ name: 'EXPERIMENT_DETAILS', experimentId: record.id }}>
            {text}
          </Link>
        );
      },
      width: '20%',
    },
    {
      title: 'Audience',
      dataIndex: 'country',
      render: value => countryCodeToNameMap[value],
      width: '14%',
    },
    {
      title: 'Traffic share',
      dataIndex: 'traffic',
      render: (traffic: number, record: ExperimentModel) => {
        return record.externalTrafficBalancingEnabled ? '' : `${traffic / 10}%`;
      },
      width: '14%',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      render: value => moment(value).format('D MMMM YYYY, HH:mm'),
      width: '16%',
    },
    {
      title: 'Goal',
      dataIndex: 'description',
      width: '20%',
    },
    {
      dataIndex: 'id',
      key: 'delete',
      render: (id: string, record: ExperimentModel) => {
        if (record.type === 'INTERNAL') {
          return null;
        }
        if (disableDelete) {
          return (
            <Tooltip
              title="Cannot delete a running experiment"
              trigger="hover"
              placement="topRight"
            >
              <span>Delete</span>
            </Tooltip>
          );
        }

        return <DeleteButton title={`Delete "${record.name}"`} onConfirm={() => onDelete(id)} />;
      },
      align: 'right',
    },
  ];

  return (
    <Table
      dataSource={data || []}
      rowKey="id"
      columns={columns}
      loading={loading}
      pagination={false}
      locale={{ emptyText: MESSAGES.EMPTY_TABLE }}
      rowClassName={record =>
        record.type === 'INTERNAL' && !isSuperUser ? styles.disabledRow : ''
      }
    />
  );
};

const styles = stylesheet({
  disabledRow: {
    opacity: 0.65,
    cursor: 'not-allowed',
  },
});
