import { CopyOutlined } from '@ant-design/icons';
import * as React from 'react';
import * as CopyToClipboard from 'react-copy-to-clipboard';
import { stylesheet } from 'typestyle';

import { Alert, Modal, Tag } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { ConfigSiteModel } from '@src/models';
import { services } from '@src/services';
import { defaultTheme } from '@src/styles';
import { RenameForm } from '.';

interface OwnProps {
  site: ConfigSiteModel | undefined;
  isVisible: boolean;
  hideDetailsModal: () => void;
  forbidenNames?: string[];
  handleChangeName: (name: string) => void;
  hasDraft: boolean;
}

export const SiteModal: React.FunctionComponent<OwnProps> = ({
  site,
  isVisible,
  hideDetailsModal,
  forbidenNames,
  handleChangeName,
  hasDraft,
}) => {
  const copyableItem = (value: string, label: string) => (
    <>
      {value}
      &nbsp;
      <CopyToClipboard
        text={value}
        onCopy={() => services.messageService.success(`${label} copied to clipboard.`)}
      >
        <CopyOutlined
          style={{ fontSize: '16px' }}
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
        />
      </CopyToClipboard>
    </>
  );

  if (!site) {
    return null;
  }
  return (
    <Modal
      visible={isVisible}
      onOk={hideDetailsModal}
      onCancel={hideDetailsModal}
      closable={false}
      footer={null}
      destroyOnClose
      className={styles.modal}
    >
      {hasDraft && (
        <Alert
          className={styles.fallbackAlert}
          message={
            <FormattedMessage className={styles.fallbackAlertMessage} id="SITE_DRAFT_ALERT" />
          }
          type="warning"
          showIcon
        />
      )}
      <div>
        <Tag color={defaultTheme.color.primary} nonClickable={true}>
          <FormattedMessage id="SITE" />
        </Tag>
        <h2 className={styles.header}>{site.name}</h2>
      </div>
      <div className={styles.contentContainer}>
        <div>Name</div>
        <RenameForm
          name={site.name}
          forbidenNames={forbidenNames}
          handleChangeName={handleChangeName}
        />
        <div>ID</div>
        <div>{copyableItem(site.id, 'Site id')}</div>
        <div>Private key</div>
        <div>{copyableItem(site.privateKey, 'Private key')}</div>
        <div>Private key (base64)</div>
        <div>{copyableItem(btoa(site.privateKey), 'Private key (base64)')}</div>
      </div>
    </Modal>
  );
};

const styles = stylesheet({
  modal: {
    minWidth: 800,
  },
  header: {
    margin: '20px 0',
  },
  contentContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridGap: '24px 10px',
    alignItems: 'center',
    margin: 20,
    $nest: {
      '&>*:nth-child(odd)': {
        fontWeight: 'bolder',
      },
    },
  },
  fallbackAlert: {
    border: 0,
    marginBottom: 5,
  },
  fallbackAlertMessage: {
    paddingLeft: 5,
  },
});
