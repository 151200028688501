import { ShoppingOutlined } from '@ant-design/icons';
import { ListFixedHeader } from '@src/components';
import { Card } from '@src/controls';
import { useFormatMessage } from '@src/i18n';
import { SitesSelectors } from '@src/modules/sites';
import { services } from '@src/services';
import { messageService } from '@src/services/message-service';
import { MADError } from '@src/utils';
import { useSelector } from '@src/utils/hooks';
import { Button, Skeleton } from 'antd';
import { Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { useMutation } from 'react-query';
import { ConfigurationForm } from './configuration-form';
import { Widget } from './widget';

export type FormValues = {
  sessionId: string;
  locale: string;
};

export const ReceiptWidgetTestClient: FunctionComponent = () => {
  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);
  const [token, setToken] = useState<string | null>(null);
  const privateKeyMutation = useMutation(() => services.configService.getPrivateKey(siteId));
  const tokenMutation = useMutation<string, MADError, { sessionId: string; privateKey: string }>(
    ({ sessionId, privateKey }) =>
      services.receiptService.getAuthToken(sessionId, siteId, privateKey),
    {
      onSuccess: token => setToken(token),
      onError: error => {
        messageService.error(`Error: ${error.message}; Trace ID: ${error.traceId}`);
      },
    }
  );
  const formatMessage = useFormatMessage();

  return (
    <>
      <ListFixedHeader
        title="Receipt widget test client"
        IconComponent={ShoppingOutlined}
        showSearch={false}
      />
      <Card
        title={formatMessage('SETTINGS')}
        extra={
          <>
            {token ? (
              <Button onClick={() => setToken(null)}>{formatMessage('GO_BACK')}</Button>
            ) : (
              <Button
                type="primary"
                form="receipt-widget"
                htmlType="submit"
                key={token}
                loading={privateKeyMutation.isLoading || tokenMutation.isLoading}
              >
                {formatMessage('GO_TO_WIDGET')}
              </Button>
            )}
          </>
        }
      >
        <Formik<FormValues>
          initialValues={{ sessionId: '', locale: 'en-US' }}
          enableReinitialize
          onSubmit={({ sessionId }) => {
            privateKeyMutation.mutate(undefined, {
              onSuccess: ({ privateKey }) => {
                tokenMutation.mutate({ sessionId, privateKey });
              },
            });
          }}
        >
          {privateKeyMutation.isLoading || tokenMutation.isLoading ? (
            <Skeleton />
          ) : token ? (
            <Widget token={token} />
          ) : (
            <ConfigurationForm />
          )}
        </Formik>
      </Card>
    </>
  );
};
