import { useEffect, useState } from 'react';

export const useTrackingLinksToggleDisability = (
  isLoading: boolean,
  trackingLinks?: { [locale: string]: string }
) => {
  const [disableTrackingLinksToggle, setDisableTrackingLinksToggle] = useState(false);

  useEffect(() => {
    if (isLoading || !trackingLinks) {
      setDisableTrackingLinksToggle(true);
    } else {
      setDisableTrackingLinksToggle(false);
    }
  }, [isLoading, trackingLinks]);

  return { disabled: disableTrackingLinksToggle };
};
