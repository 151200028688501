import { sanitizeUrl } from '@braintree/sanitize-url';
import { isNotNil } from 'ramda-adjunct';
import * as React from 'react';
import { combineEpics, Epic } from 'redux-observable';
import { from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, delay, filter, map, switchMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { reportsActions } from '.';
import { reportsSelectors } from '.';
import { RootAction, RootState, Services } from '..';
import { ERRORS } from '../../dictionaries';
import { ReportStatus } from '../../models';
import { MADError } from '../../utils';
import { authSelectors } from '../auth';
import { SitesSelectors } from '../sites';

const notificationConfig = {
  info: {
    description:
      'Report is currently being created. Depending on numbers of items, it can take up to few minutes. You will be notified when it is ready',
    message: 'Report is being created',
    duration: 0,
  },
  success: {
    description:
      'Report has been created successfully and is ready to download. Please see audit area for link',
    message: 'Report has been created',
    duration: 0,
  },
  error: {
    description: (
      <div>
        Please try again or contact support at&nbsp;
        <a href="mailto:support@ingrid.com">support@ingrid.com</a>
      </div>
    ),
    message: 'Report could not be created',
    duration: 0,
  },
};

// Shipment report epics

const createShipmentsReport: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { reportsService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(reportsActions.createShipmentsReportRequest)),
    switchMap(({ payload: { siteId, model } }) =>
      observableFrom(reportsService.createShipmentsReportWith(siteId, model)).pipe(
        map(res =>
          reportsActions.createShipmentsReportSuccess({
            model: res,
            userId: authSelectors.getAuthUserId(state$.value),
          })
        ),
        tap(() =>
          notificationService.info(
            notificationConfig.info.description,
            notificationConfig.info.message,
            notificationConfig.info.duration
          )
        ),
        catchError((error: MADError) =>
          observableOf(reportsActions.createReportError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(
                notificationConfig.error.description,
                notificationConfig.error.message,
                notificationConfig.error.duration
              );
            })
          )
        )
      )
    )
  );

const getShipmentsReport: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { reportsService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(reportsActions.createShipmentsReportSuccess)),
    switchMap(({ payload: { model } }) =>
      observableFrom(
        reportsService.getShipmentsReportWith(
          SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
          model.id
        )
      ).pipe(
        map(res => reportsActions.getShipmentsReport(res)),
        catchError((error: MADError) =>
          observableOf(reportsActions.getReportError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(
                notificationConfig.error.description,
                notificationConfig.error.message,
                notificationConfig.error.duration
              );
            })
          )
        )
      )
    )
  );

const getShipmentsReportDelayed: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { reportsService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(reportsActions.getShipmentsReportRequest)),
    delay(2000),
    switchMap(() =>
      observableFrom(
        reportsService.getShipmentsReportWith(
          SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
          reportsSelectors.getReportIdForCurrentUser(state$.value)
        )
      ).pipe(
        map(res => reportsActions.getShipmentsReport(res)),
        catchError((error: MADError) =>
          observableOf(reportsActions.getReportError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(
                notificationConfig.error.description,
                notificationConfig.error.message,
                notificationConfig.error.duration
              );
            })
          )
        )
      )
    )
  );

const checkShipmentsReportStatus: Epic<RootAction, RootAction, Services> = (
  action$,
  _,
  { notificationService }
) =>
  action$.pipe(
    filter(isActionOf(reportsActions.getShipmentsReport)),
    map(action => {
      switch (action.payload.status) {
        case ReportStatus.PENDING:
          return reportsActions.getShipmentsReportRequest();
        case ReportStatus.DONE:
          return reportsActions.generateTempUrl({ url: action.payload.uri });
        case ReportStatus.ERROR:
          notificationService.error(
            notificationConfig.error.description,
            notificationConfig.error.message,
            notificationConfig.error.duration
          );
          return reportsActions.getReportError(ERRORS.GENERATING_SHIPMENTS_REPORT_ERROR);

        default:
          return;
      }
    }),
    filter(isNotNil)
  );

// Order report epics

const createOrdersReport: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { reportsService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(reportsActions.createOrdersReportRequest)),
    switchMap(({ payload: { siteId, model } }) =>
      observableFrom(reportsService.createOrdersReportWith(siteId, model)).pipe(
        map(res =>
          reportsActions.createOrdersReportSuccess({
            model: res,
            userId: authSelectors.getAuthUserId(state$.value),
          })
        ),
        tap(() =>
          notificationService.info(
            notificationConfig.info.description,
            notificationConfig.info.message,
            notificationConfig.info.duration
          )
        ),
        catchError((error: MADError) =>
          observableOf(reportsActions.createReportError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(
                notificationConfig.error.description,
                notificationConfig.error.message,
                notificationConfig.error.duration
              );
            })
          )
        )
      )
    )
  );

const getOrdersReport: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { reportsService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(reportsActions.createOrdersReportSuccess)),
    switchMap(({ payload: { model } }) =>
      observableFrom(
        reportsService.getOrdersReportWith(
          SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
          model.id
        )
      ).pipe(
        map(res => reportsActions.getOrdersReport(res)),
        catchError((error: MADError) =>
          observableOf(reportsActions.getReportError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(
                notificationConfig.error.description,
                notificationConfig.error.message,
                notificationConfig.error.duration
              );
            })
          )
        )
      )
    )
  );

const getOrdersReportDelayed: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { reportsService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(reportsActions.getOrdersReportRequest)),
    delay(2000),
    switchMap(() =>
      observableFrom(
        reportsService.getOrdersReportWith(
          SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
          reportsSelectors.getReportIdForCurrentUser(state$.value)
        )
      ).pipe(
        map(res => reportsActions.getOrdersReport(res)),
        catchError((error: MADError) =>
          observableOf(reportsActions.getReportError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(
                notificationConfig.error.description,
                notificationConfig.error.message,
                notificationConfig.error.duration
              );
            })
          )
        )
      )
    )
  );

const checkOrdersReportStatus: Epic<RootAction, RootAction, Services> = (
  action$,
  _,
  { notificationService }
) =>
  action$.pipe(
    filter(isActionOf(reportsActions.getOrdersReport)),
    map(action => {
      switch (action.payload.status) {
        case ReportStatus.PENDING:
          return reportsActions.getOrdersReportRequest();
        case ReportStatus.DONE:
          return reportsActions.generateTempUrl({ url: action.payload.uri });
        case ReportStatus.ERROR:
          notificationService.error(
            notificationConfig.error.description,
            notificationConfig.error.message,
            notificationConfig.error.duration
          );
          return reportsActions.getReportError(ERRORS.GENERATING_SHIPMENTS_REPORT_ERROR);

        default:
          return;
      }
    }),
    filter(isNotNil)
  );

// Special report epics

const createSpecialReport: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { reportsService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(reportsActions.createSpecialReportRequest)),
    switchMap(({ payload: { siteId, model } }) =>
      observableFrom(reportsService.createSpecialReportWith(siteId, model)).pipe(
        map(res =>
          reportsActions.createSpecialReportSuccess({
            model: res,
            userId: authSelectors.getAuthUserId(state$.value),
          })
        ),
        tap(() =>
          notificationService.info(
            notificationConfig.info.description,
            notificationConfig.info.message,
            notificationConfig.info.duration
          )
        ),
        catchError((error: MADError) =>
          observableOf(reportsActions.createReportError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(
                notificationConfig.error.description,
                notificationConfig.error.message,
                notificationConfig.error.duration
              );
            })
          )
        )
      )
    )
  );

const getSpecialReport: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { reportsService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(reportsActions.createSpecialReportSuccess)),
    switchMap(({ payload: { model } }) =>
      observableFrom(
        reportsService.getSpecialReportWith(
          SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
          model.id
        )
      ).pipe(
        map(res => reportsActions.getSpecialReport(res)),
        catchError((error: MADError) =>
          observableOf(reportsActions.getReportError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(
                notificationConfig.error.description,
                notificationConfig.error.message,
                notificationConfig.error.duration
              );
            })
          )
        )
      )
    )
  );

const getSpecialReportDelayed: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { reportsService, loggingService, notificationService }
) =>
  action$.pipe(
    filter(isActionOf(reportsActions.getSpecialReportRequest)),
    delay(2000),
    switchMap(() =>
      observableFrom(
        reportsService.getSpecialReportWith(
          SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
          reportsSelectors.getReportIdForCurrentUser(state$.value)
        )
      ).pipe(
        map(res => reportsActions.getSpecialReport(res)),
        catchError((error: MADError) =>
          observableOf(reportsActions.getReportError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(
                notificationConfig.error.description,
                notificationConfig.error.message,
                notificationConfig.error.duration
              );
            })
          )
        )
      )
    )
  );

const checkSpecialReportStatus: Epic<RootAction, RootAction, Services> = (
  action$,
  _,
  { notificationService }
) =>
  action$.pipe(
    filter(isActionOf(reportsActions.getSpecialReport)),
    map(action => {
      switch (action.payload.status) {
        case ReportStatus.PENDING:
          return reportsActions.getSpecialReportRequest();
        case ReportStatus.DONE:
          return reportsActions.generateTempUrl({ url: action.payload.uri });
        case ReportStatus.ERROR:
          notificationService.error(
            notificationConfig.error.description,
            notificationConfig.error.message,
            notificationConfig.error.duration
          );
          return reportsActions.getReportError(ERRORS.GENERATING_SHIPMENTS_REPORT_ERROR);

        default:
          return;
      }
    }),
    filter(isNotNil)
  );

// Common report epics

const generateTempUrlWithToken: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { loggingService, notificationService, gentokenService }
) =>
  action$.pipe(
    filter(isActionOf(reportsActions.generateTempUrl)),
    switchMap(({ payload: { url } }) =>
      observableFrom(
        gentokenService.getBlobToken(
          SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
          url + '&filename=report.csv'
        )
      ).pipe(
        delay(5000),
        map(({ url: urlWithToken }) =>
          reportsActions.getReportSuccess({
            uri: sanitizeUrl(urlWithToken),
            userId: authSelectors.getAuthUserId(state$.value),
          })
        ),
        tap(() =>
          notificationService.success(
            notificationConfig.success.description,
            notificationConfig.success.message,
            notificationConfig.success.duration
          )
        ),
        catchError((error: MADError) =>
          observableOf(reportsActions.getReportError(error.message)).pipe(
            tap(() => {
              loggingService.logError(error);
              notificationService.error(
                notificationConfig.error.description,
                notificationConfig.error.message,
                notificationConfig.error.duration
              );
            })
          )
        )
      )
    )
  );

export const reportsEpics = combineEpics(
  createShipmentsReport,
  getShipmentsReport,
  checkShipmentsReportStatus,
  getShipmentsReportDelayed,
  createOrdersReport,
  getOrdersReport,
  checkOrdersReportStatus,
  getOrdersReportDelayed,
  createSpecialReport,
  getSpecialReport,
  checkSpecialReportStatus,
  getSpecialReportDelayed,
  generateTempUrlWithToken
);
