import { combineEpics, Epic } from 'redux-observable';
import { from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { somSearchActions } from '.';
import { RootAction, RootState, Services } from '../../modules';
import { MADError } from '../../utils';

type Direction = 'ASCENDING' | 'DESCENDING' | undefined;

const getShipmentSummariesEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { somSearchService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(somSearchActions.getShipmentSummariesRequest)),
    switchMap(({ payload }) =>
      observableFrom(
        somSearchService.getShipmentList(
          payload.siteId,
          [payload.tosId],
          payload.direction as Direction,
          payload.pageNumber,
          payload.pageSize,
          payload.filters
        )
      ).pipe(
        concatMap(res => {
          const { filters } = payload;

          if (filters) {
            return [
              somSearchActions.getShipmentSummariesSuccess(res),
              somSearchActions.setActiveFilters(filters),
            ];
          }
          return [somSearchActions.getShipmentSummariesSuccess(res)];
        }),
        catchError((error: MADError) =>
          observableOf(somSearchActions.getShipmentSummariesError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const getShipmentMetadata: Epic<RootAction, RootAction, RootState, Services> = (
  actions$,
  {},
  { somSearchService, loggingService }
) =>
  actions$.pipe(
    filter(isActionOf(somSearchActions.getShipmentMetadataRequest)),
    switchMap(({ payload }) =>
      observableFrom(somSearchService.getShipmentMetadata(payload.siteId, payload.shipmentId)).pipe(
        map(res =>
          somSearchActions.getShipmentMetadataSuccess({ shipmentId: payload.shipmentId, ...res })
        ),
        catchError((error: MADError) =>
          observableOf(somSearchActions.getShipmentMetadataError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

const getShipmentsStatuses: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { somSearchService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(somSearchActions.getShipmentsStatusesRequest)),
    switchMap(({ payload }) =>
      observableFrom(
        somSearchService.getShipmentsStatuses(payload.siteId, payload.shipmentIds)
      ).pipe(
        map(somSearchActions.getShipmentsStatusesSuccess),
        catchError((error: MADError) =>
          observableOf(somSearchActions.getShipmentsStatusesError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

export const somSearchEpics = combineEpics(
  getShipmentSummariesEpic,
  getShipmentMetadata,
  getShipmentsStatuses
);
