import * as React from 'react';
import { connect } from 'react-redux';

import { Modal } from '@src/controls';
import { RootState } from '@src/modules';
import { tagsSelectors } from '@src/modules/tags';
import { WatchlistModalForm } from '.';

interface OwnProps {
  isVisible: boolean;
  onCancel: () => void;
  onOk: (selectedTagsIds: string[]) => void;
}

const mapStateToProps = (state: RootState) => ({
  userTagsOptions: tagsSelectors.getUserTagOptions(state),
});

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

class Component extends React.Component<Props> {
  handleSubmit = ({ selectedTagsIds }: { selectedTagsIds: string[] }) => {
    this.props.onOk(selectedTagsIds);
  };

  render() {
    const { isVisible, onCancel, userTagsOptions } = this.props;
    return (
      <Modal visible={isVisible} closable={true} footer={null} onCancel={onCancel}>
        <WatchlistModalForm
          userTagsOptions={userTagsOptions}
          onSubmit={this.handleSubmit}
          onCancel={onCancel}
        />
      </Modal>
    );
  }
}

export const WatchlistModalContainer = connect(mapStateToProps, {})(Component);
