import { combineReducers } from 'redux';

import { notificationsReducer, NotificationsState } from './notifications-reducers';
import { triggersReducer, TriggersState } from './triggers-reducers';

const shipmentNotificationsReducer = combineReducers({
  notifications: notificationsReducer,
  triggers: triggersReducer,
});

export interface ShipmentNotificationsState {
  notifications: NotificationsState;
  triggers: TriggersState;
}

export default shipmentNotificationsReducer;
