import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { stylesheet } from 'typestyle';

import { Input, Select, Switch } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, inputStyle, MakeAutoSaveFormikEnhancer, widerLayout } from '@src/forms';
import leafIcon from '@src/images/icons/leaf.svg';
import lightningIcon from '@src/images/icons/lightning.svg';
import swanIcon from '@src/images/icons/swan-logo.svg';
import { CategoryLabelModel } from '@src/models';
import { configActions } from '@src/modules/config';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { getFormikError } from '@src/utils/forms';

const InputField = withFormItem(Input, widerLayout);
const SelectField = withFormItem(Select, widerLayout);
const SwitchField = withFormItem(Switch, widerLayout);

const TypedAutoSaveEnhancer = MakeAutoSaveFormikEnhancer<LabelDetailsFormikValues>();

interface LabelDetailsFormikValues {
  name: CategoryLabelModel['name'];
  type: CategoryLabelModel['type'];
  active: boolean;
}

interface Props {
  categoryId: string;
  label: CategoryLabelModel;
}

export const LabelDetailsEdit: React.FC<Props> = ({ label, categoryId }) => {
  const handleUpdate = (values: Partial<LabelDetailsFormikValues>) => {
    dispatchOnDraftSiteId(configActions.updateCategoryLabelDetailsRequest)({
      model: {
        categoryId,
        labelId: label.id,
        name: values.name,
        type: values.type,
        state: values.active ? 'ACTIVE' : 'INACTIVE',
      },
    });
  };
  return (
    <Formik
      key={label.id}
      initialValues={{
        name: label.name,
        type: label.type,
        active: label.state === 'ACTIVE',
      }}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
    >
      {formikProps => (
        <AutoSaveForm>
          <InputField
            label={<FormattedMessage id="ID" />}
            className={inputStyle}
            value={label.id}
            disabled={true}
          />
          <TypedAutoSaveEnhancer
            onSave={handleUpdate}
            name="name"
            render={({ name, onBlur, onKeyDown }) => (
              <Field name={name}>
                {({ field }: FieldProps<LabelDetailsFormikValues['name']>) => (
                  <InputField
                    label={<FormattedMessage id="NAME" />}
                    className={inputStyle}
                    {...field}
                    error={getFormikError(formikProps.touched.name, formikProps.errors.name)}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                  />
                )}
              </Field>
            )}
          />

          <TypedAutoSaveEnhancer
            name="type"
            onSave={handleUpdate}
            render={({ name, onInstantChange }) => (
              <Field name={name}>
                {({ field }: FieldProps<LabelDetailsFormikValues['type']>) => (
                  <SelectField
                    {...field}
                    options={[
                      {
                        value: 'LABEL_TYPE_SUSTAINABILITY',
                        label: (
                          <div className={styles.typeLabelWrapper}>
                            <img src={leafIcon} className={styles.typeIcon} />
                            <span className={styles.type}>Sustainability</span>
                          </div>
                        ),
                      },
                      {
                        value: 'LABEL_TYPE_PRIORITY',
                        label: (
                          <div className={styles.typeLabelWrapper}>
                            <img src={lightningIcon} className={styles.typeIcon} />
                            <span className={styles.type}>Priority</span>
                          </div>
                        ),
                      },
                      {
                        value: 'LABEL_TYPE_CUSTOM',
                        label: (
                          <div className={styles.typeLabelWrapper}>
                            <span className={styles.type}>Custom</span>
                          </div>
                        ),
                      },
                      {
                        value: 'LABEL_TYPE_SWAN',
                        label: (
                          <div className={styles.typeLabelWrapper}>
                            <img src={swanIcon} className={styles.typeIcon} />
                            <span className={styles.type}>Swan</span>
                          </div>
                        ),
                      },
                    ]}
                    onChange={(value: string) => onInstantChange(value)}
                    label={<FormattedMessage id="TYPE" />}
                    labelTooltip={<FormattedMessage id="CATEGORY_LABEL_MODAL.TYPE_TOOLTIP" />}
                    className={inputStyle}
                  />
                )}
              </Field>
            )}
          />

          <SwitchField
            label={<FormattedMessage id="ACTIVE" />}
            checked={label.state === 'ACTIVE'}
            onChange={value => {
              handleUpdate({ ...formikProps.values, active: value });
            }}
          />
        </AutoSaveForm>
      )}
    </Formik>
  );
};

const styles = stylesheet({
  typeIcon: {
    height: '14px',
    width: '14px',
  },
  type: {
    marginLeft: '4px',
  },
  typeLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});
