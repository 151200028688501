import { FilterOutlined } from '@ant-design/icons';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { style as tss } from 'typestyle';
import { TransportOrderListFiltersModel } from '../../../models';

import { Col, Divider, Row } from 'antd';
import { action, IObservableArray, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as mtz from 'moment-timezone';
import { LABELS } from '../../../dictionaries';
import { Button, Card, Label, RangePicker, Select } from './../../../controls';

interface IProps {
  onApplyClick: (payload: TransportOrderListFiltersModel) => void;
  onClearClick: () => void;
  filters: TransportOrderListFiltersModel | null;
  disabled?: boolean;
}

@observer
export class TransportOrderFilterPanel extends React.Component<IProps, {}> {
  $isfiltersPanelVisible = observable(false);

  $selectedSource: IObservableArray<string> = observable([]);
  $createdAtRange: IObservableArray<string> = observable([]);

  componentDidMount() {
    // add event listener for clicks
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    // make sure you remove the listener when the component is destroyed
    document.removeEventListener('click', this.handleClick, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.filters) {
      this.updateFilters(nextProps.filters);
    } else {
      this.clearFilters();
    }
  }

  updateFilters(filters: TransportOrderListFiltersModel) {
    const { sources, createdAtRange } = filters;

    this.$selectedSource.replace(sources);
    this.$createdAtRange.replace(createdAtRange);
  }

  @action
  clearFilters = () => {
    this.$selectedSource.replace([]);
    this.$createdAtRange.replace([]);
  };

  checkIFiltersClear = (): boolean => {
    return !!(
      this.$selectedSource.slice().length === 0 && this.$createdAtRange.slice().length === 0
    );
  };

  @action
  handleClick = (e: any) => {
    if (!ReactDOM.findDOMNode(this)!.contains(e.target)) {
      // the click was outside your component, so handle closing here
      this.$isfiltersPanelVisible.set(false);
    }
  };

  handleFilterButtonClick = () => {
    this.$isfiltersPanelVisible.set(!this.$isfiltersPanelVisible.get());
    if (this.props.filters) {
      this.updateFilters(this.props.filters);
    } else {
      this.clearFilters();
    }
  };

  handleShippingMethodSelect = (value: string) => {
    this.$selectedSource.push(value);
  };

  handleShippingMethodDeselect = (value: string) => {
    this.$selectedSource.remove(value);
  };

  getContainer = (trigger: Element) => {
    return trigger && trigger.parentNode;
  };

  handleCreatedAtChange = (dates: [mtz.Moment, mtz.Moment]) => {
    this.$createdAtRange.replace([dates[0].toISOString(), dates[1].toISOString()]);
  };

  handleShippingDateChange = (dates: [mtz.Moment, mtz.Moment]) => {
    this.$selectedSource.replace([dates[0].toISOString(), dates[1].toISOString()]);
  };

  @action
  handleApplyClick = () => {
    this.$isfiltersPanelVisible.set(false);

    const shipmentListFilters: TransportOrderListFiltersModel = {
      sources: this.$selectedSource.slice(),
      createdAtRange:
        this.$createdAtRange.peek().length === 2
          ? [this.$createdAtRange.peek()[0], this.$createdAtRange.peek()[1]]
          : [],
    };
    this.props.onApplyClick(shipmentListFilters);
  };

  @action
  handleClearClick = () => {
    this.$isfiltersPanelVisible.set(false);
    this.props.onClearClick();
  };

  disabledDate = (current: mtz.Moment) => current && current > mtz().endOf('day');

  render() {
    const { disabled } = this.props;

    const createdAtValue: [mtz.Moment, mtz.Moment] | boolean = this.$createdAtRange.slice()
      .length === 2 && [mtz(this.$createdAtRange.slice()[0]), mtz(this.$createdAtRange.slice()[1])];

    const CreatedAtCol = (
      <Col span={20}>
        <div className={styles.column}>
          <div className={styles.columnLabel}>
            <Label text="Created at" />
          </div>
          <RangePicker
            onChange={this.handleCreatedAtChange}
            allowClear={false}
            disabledDate={this.disabledDate}
            value={createdAtValue || undefined}
            getPopupContainer={triggerNode => triggerNode && triggerNode.parentElement!}
            renderExtraFooter={() => (
              <Button
                onClick={() => {
                  this.$createdAtRange.replace([]);
                }}
              >
                Clear
              </Button>
            )}
          />
        </div>
      </Col>
    );

    const SourcesCol = (
      <Col span={20}>
        <div className={styles.column}>
          <div className={styles.columnLabel}>
            <Label text="Sources" />
          </div>
          <Select
            className={styles.sourcesSelect}
            mode="multiple"
            placeholder="Please select"
            value={this.$selectedSource.peek().filter(item => item !== '')}
            onSelect={this.handleShippingMethodSelect}
            onDeselect={this.handleShippingMethodDeselect}
            getPopupContainer={triggerNode =>
              triggerNode ? triggerNode.parentElement! : document.body
            }
            filterOption={(stringQuery, { props: { children } }) =>
              children ? children.toString().toLowerCase().includes(stringQuery) : true
            }
            options={[
              { value: 'som', label: 'TA' },
              { value: 'cos', label: 'Checkout' },
              { value: 'cos:v2', label: 'Checkout (split-shipment)' },
              { value: 'mad', label: 'MAD' },
              { value: 'tracking', label: 'Tracking' },
              { value: 'demo', label: 'Demo' },
            ]}
          />
        </div>
      </Col>
    );

    const FiltersPanel = this.$isfiltersPanelVisible.get() && (
      <Card className={styles.root}>
        <Row>{CreatedAtCol}</Row>
        <Row>{SourcesCol}</Row>
        <Divider className={styles.divider} />
        <div className={styles.applyButtonWrapper}>
          <Button onClick={this.handleApplyClick} type="primary">
            {LABELS.APPLY}
          </Button>
        </div>
      </Card>
    );

    return (
      <div>
        <div className={styles.rootWrapper}>{FiltersPanel}</div>
        <Button
          type={this.props.filters && !this.checkIFiltersClear() ? 'primary' : undefined}
          onClick={this.handleFilterButtonClick}
          disabled={disabled}
        >
          <FilterOutlined style={{ fontSize: '16px' }} />
        </Button>
      </div>
    );
  }
}

const styles = {
  root: tss({ width: 350, boxShadow: '0 2px 8px rgba(0, 0, 0, .15)' }),
  applyButtonWrapper: tss({
    textAlign: 'right',
  }),
  rootWrapper: tss({
    position: 'absolute',
    marginLeft: '-302px',
    marginTop: '32px',
    zIndex: 100,
  }),
  column: tss({
    paddingBottom: '30px',
  }),
  columnLabel: tss({
    paddingBottom: '10px',
  }),
  sourcesSelect: tss({ width: '100%' }),
  divider: tss({ marginTop: 10, marginBottom: 10 }),
};
