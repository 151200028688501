import * as React from 'react';
import { stylesheet } from 'typestyle';

interface Props {
  title?: React.ReactNode;
}

export class SimpleList extends React.Component<Props> {
  static Li: React.FunctionComponent = ({ children }) => <li>{children}</li>;
  render() {
    return (
      <>
        {this.props.title && <div className={styles.title}>{this.props.title}</div>}
        <ul className={styles.ul}>{this.props.children}</ul>
      </>
    );
  }
}

const styles = stylesheet({
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    $nest: {
      'li::before': {
        content: "'•'",
        color: 'rgba(0, 0, 0, 0.3)',
        fontWeight: 'bold',
        display: 'inline-block',
        marginRight: '5px',
      },
    },
  },
  title: {
    marginBottom: '5px',
    marginTop: '5px',
  },
});
