import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { services } from '@src/services';
import { configureAuth, getEnterpriseConnectionWithPathname } from '../app-auth';
import LoadingPage from '../containers/loading-container';
import LoginPage from '../containers/login/auth0-login';
import { RootState } from '../modules';
import { authSelectors } from '../modules/auth';
import {
  loadEnterpriseConnection,
  saveEnterpriseConnection,
} from '../services/local-storage-service';

const env = services.envService.getEnvironmentalVariables();

type DispatchProps = {};

const mapStateToProps = (state: RootState) => ({
  isTokenExpired: authSelectors.isAuthTokenExpired(state),
  authUser: state.auth.authUser,
});
type StateProps = ReturnType<typeof mapStateToProps>;

type Props = DispatchProps & StateProps & RouteComponentProps<any>;

export class BaseLayer extends React.Component<Props, {}> {
  appAuth = configureAuth({
    connection: this.getEnterpriseConnection(),
    authDomain: this.getAuthDomain(),
  });

  getEnterpriseConnection() {
    const { location } = this.props;

    const connection = getEnterpriseConnectionWithPathname(location.pathname);
    if (connection) {
      this.setEnterpriseConnectionCookie(connection);
      return connection;
    }
    const connection2 = this.getEnterpriseConnectionFromCookie();
    if (connection2) {
      return connection2;
    }
    return undefined;
  }

  getEnterpriseConnectionFromCookie() {
    const connection = loadEnterpriseConnection();
    if (connection) {
      return connection;
    }
    return undefined;
  }

  getAuthDomain() {
    const { location } = this.props;

    const connectionFromPath = getEnterpriseConnectionWithPathname(location.pathname);
    const connectionFromCookie = this.getEnterpriseConnectionFromCookie();

    if (connectionFromPath || connectionFromCookie) {
      return env.MAD_AUTH0_APOTEKET_DOMAIN;
    }

    return env.MAD_AUTH0_DOMAIN;
  }

  setEnterpriseConnectionCookie(connection: string) {
    saveEnterpriseConnection(connection);
  }

  render() {
    const { authUser, isTokenExpired } = this.props;

    if (isTokenExpired) {
      return (
        <LoadingPage>
          <LoginPage appAuth={this.appAuth} />
        </LoadingPage>
      );
    }

    if (!authUser) {
      return <LoadingPage />;
    }

    return this.props.children;
  }
}

const Connected = connect(mapStateToProps, {})(BaseLayer);
export default withRouter(Connected);
