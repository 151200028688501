import * as React from 'react';

import { ComponentReturningChildren } from '@src/components';
import { Select, TabPane, Tabs } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, inputStyle, widerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { DeliveryAddonModel, DeliveryAddonTranslationModel } from '@src/models';
import { configActions } from '@src/modules/config';
import { getAvailableLocalesOptions } from '@src/modules/dictionaries/selectors';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { useSelector } from '@src/utils/hooks';

import { DeliveryAddonTranslationEdit } from './delivery-addon-translation-edit';

const LabelWrapper = withFormItem(ComponentReturningChildren, widerLayout);

interface Props {
  addon: DeliveryAddonModel;
  categoryId: string;
}

export const DeliveryAddonTranslationsEdit: React.FC<Props> = ({ addon, categoryId }) => {
  const availableLocalesOptions = useSelector(getAvailableLocalesOptions);

  const [translations, updateTranslations] = React.useState<{
    [locale: string]: DeliveryAddonTranslationModel;
  }>(addon.localization ?? {});

  const emptyTranslation: DeliveryAddonTranslationModel = {
    name: '',
    description: '',
  };

  const deleteTranslations = (value: string) => {
    dispatchOnDraftSiteId(configActions.deleteDeliveryAddonTranslationRequest)({
      model: {
        categoryId,
        addonId: addon.id,
        locale: value,
      },
    });
    return null;
  };

  return (
    <AutoSaveForm>
      <LabelWrapper
        label={<FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.TRANSLATIONS" />}
        labelTooltip={<FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.TRANSLATIONS_TOOLTIP" />}
      >
        <Select
          placeholder="Select locales"
          mode="multiple"
          options={availableLocalesOptions}
          value={Object.keys(translations)}
          onChange={(val: string[]) => {
            const newLocales = val.reduce((allLocales, locale) => {
              return {
                ...allLocales,
                [locale]:
                  addon.localization && addon.localization[locale]
                    ? addon.localization[locale]
                    : emptyTranslation,
              };
            }, {});
            updateTranslations(newLocales);
          }}
          onDeselect={deleteTranslations}
          className={inputStyle}
        />
        <Tabs defaultActiveKey={Object.keys(translations)[0]}>
          {Object.entries(translations).map(
            ([language, translation]: [string, DeliveryAddonTranslationModel]) => (
              <TabPane tab={language} key={language}>
                <DeliveryAddonTranslationEdit
                  language={language}
                  categoryId={categoryId}
                  addonId={addon.id}
                  translation={translation}
                />
              </TabPane>
            )
          )}
        </Tabs>
      </LabelWrapper>
    </AutoSaveForm>
  );
};
