import { Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { getFieldArrayError } from '@src/utils/forms';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { string } from 'yup';
import { RuleAddingValues } from '../rule-adding-container';
import { ConditionGeoListing, PriceRuleConditionFormValue } from '../../helpers';
import { SelectOption } from '@src/controls/select';

const SelectItem = withFormItem(Select);

interface Props {
  name: string;
  errors: FormikProps<RuleAddingValues>['errors'];
  touched: FormikProps<RuleAddingValues>['touched'];
  setFieldValue: FormikProps<RuleAddingValues>['setFieldValue'];
  currentGeoListings: PriceRuleConditionFormValue[];
}

const GeoListingValidate = async (value: string) => {
  try {
    await string()
      .required('Geographical listing cannot be empty')
      .oneOf(
        Object.values(GeoListingType),
        err => `${err.value} is not a valid geographical listing`
      )
      .validate(value);
  } catch (err) {
    return err;
  }
};

export enum GeoListingType {
  'DHL_REMOTE_AREA' = 'is_dhl_remote_area',
  'UPS_REMOTE_AREA' = 'is_ups_remote_area',
  'UPS_EXTENDED_REMOTE_AREA' = 'is_ups_extended_remote_area',
  'POST_NORD_REMOTE_AREA' = 'is_pnl_remote_area',
}
const allGeoListings: SelectOption<string>[] = [
  { value: GeoListingType.DHL_REMOTE_AREA, label: 'DHL Remote Areas' },
  { value: GeoListingType.UPS_REMOTE_AREA, label: 'UPS Remote Areas' },
  { value: GeoListingType.UPS_EXTENDED_REMOTE_AREA, label: 'UPS Extended Remote Areas' },
  { value: GeoListingType.POST_NORD_REMOTE_AREA, label: 'PostNord Remote Areas' },
];

export const GeoListing: React.FunctionComponent<Props> = ({
  name,
  errors,
  touched,
  setFieldValue,
  currentGeoListings,
}) => (
  <div className={styles.wrapper}>
    <Field name={`${name}.value`} validate={(value: string) => GeoListingValidate(value)}>
      {({ field }: FieldProps<string>) => (
        <SelectItem
          className={styles.label}
          filterOption={true}
          showSearch
          options={allGeoListings.map(
            // disable listings that are already added as conditions in the price rule
            // because they should be unique
            listing => {
              listing.disabled = !!currentGeoListings.find(
                list => (list as ConditionGeoListing).value === listing.value
              );
              return listing;
            }
          )}
          placeholder={'Please select...'}
          optionFilterProp={'label'}
          label="If Geographical listing is"
          style={{ width: '220px' }}
          error={getFieldArrayError(touched, errors, `${name}.value`)}
          {...field}
          onChange={(value: string) => {
            setFieldValue(`${name}.value`, value);
          }}
        />
      )}
    </Field>
  </div>
);

const styles = stylesheet({
  wrapper: {
    padding: '5px 0',
    $nest: {
      '.ant-row': {
        display: 'flex',
        flexWrap: 'nowrap',
        marginBottom: 0,
      },
    },
  },
  label: {
    $nest: {
      '&.ant-select': {
        border: '1px solid #d9d9d9',
        borderRadius: '3px',
      },
      '&.ant-select:hover': {
        border: '1px solid #5bc2b6',
      },
      '&.ant-select-open': {
        border: '1px solid #5bc2b6',
      },
    },
  },
});
