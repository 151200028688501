import React from 'react';
import { stylesheet } from 'typestyle';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';

import { ListFixedHeader } from '@src/components';
import { useFormatMessage } from '@src/i18n';

import { FindSection } from './find-section';
import { GenerateSection } from './generate-section';

const { TabPane } = Tabs;

export const TrackingPlayground = () => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <ListFixedHeader
        title={formatMessage('TRACKING_PLAYGROUND')}
        IconComponent={EnvironmentOutlined}
        showSearch={false}
      />
      <Tabs type="card" className={styles.tabs} destroyInactiveTabPane>
        <TabPane tab="Generate" key="generate">
          <GenerateSection />
        </TabPane>
        <TabPane tab="Find" key="find">
          <FindSection />
        </TabPane>
      </Tabs>
    </>
  );
};

const styles = stylesheet({
  tabs: {
    $nest: {
      '.ant-tabs-content-holder': {
        overflow: 'auto',
      },
    },
  },
});
