import { createAction } from 'typesafe-actions';

import {
  CreateShipmentReportModel,
  CreateOrderReportModel,
  CreateSpecialReportModel,
  CreateReportResponseModel,
  ReportModel,
} from '../../models';

// Shipment report actions

export const createShipmentsReportRequest = createAction(
  '@reports/CREATE_SHIPMENTS_REPORTS_REQUEST',
  (payload: { siteId: string; model: CreateShipmentReportModel }) => payload
)();

export const createShipmentsReportSuccess = createAction(
  '@reports/CREATE_SHIPMENTS_REPORTS_SUCCESS',
  (payload: { userId: string; model: CreateReportResponseModel }) => payload
)();

export const getShipmentsReport = createAction(
  '@reports/GET_SHIPMENTS_REPORT',
  (report: ReportModel) => report
)();

export const getShipmentsReportRequest = createAction('@reports/GET_SHIPMENTS_REPORT_REQUEST')();

// Order report actions

export const createOrdersReportRequest = createAction(
  '@reports/CREATE_ORDERS_REPORTS_REQUEST',
  (payload: { siteId: string; model: CreateOrderReportModel }) => payload
)();

export const createOrdersReportSuccess = createAction(
  '@reports/CREATE_ORDERS_REPORTS_SUCCESS',
  (payload: { userId: string; model: CreateReportResponseModel }) => payload
)();

export const getOrdersReport = createAction(
  '@reports/GET_ORDERS_REPORT',
  (report: ReportModel) => report
)();

export const getOrdersReportRequest = createAction('@reports/GET_ORDERS_REPORT_REQUEST')();

// Special report actions

export const createSpecialReportRequest = createAction(
  '@reports/CREATE_SPECIAL_REPORTS_REQUEST',
  (payload: { siteId: string; model: CreateSpecialReportModel }) => payload
)();

export const createSpecialReportSuccess = createAction(
  '@reports/CREATE_SPECIAL_REPORTS_SUCCESS',
  (payload: { userId: string; model: CreateReportResponseModel }) => payload
)();

export const getSpecialReport = createAction(
  '@reports/GET_SPECIAL_REPORT',
  (report: ReportModel) => report
)();

export const getSpecialReportRequest = createAction('@reports/GET_SPECIAL_REPORT_REQUEST')();

// Common report actions

export const createReportError = createAction(
  '@reports/CREATE_REPORT_ERROR',
  (error: string) => error
)();

export const generateTempUrl = createAction(
  '@reports/GENERATE_TEMP_URL',
  (payload: { url: string }) => payload
)();

export const getReportSuccess = createAction(
  '@reports/GET_REPORT_SUCCESS',
  (payload: { uri: string; userId: string }) => payload
)();

export const getReportError = createAction(
  '@reports/GET_REPORT_ERROR',
  (error: string) => error
)();
