import leafIcon from '@src/images/ingrid-logo.svg';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { Link } from './link';

const styles = {
  root: tss({
    width: 300,
    marginRight: 'auto',
    marginTop: '3px',
    display: 'flex',
    alignItems: 'center',
  }),
  logo: tss({
    paddingLeft: 24,
    cursor: 'pointer',
  }),
};

const Component = () => {
  return (
    <div className={styles.root}>
      <Link route={{ name: 'HOME_PAGE' }}>
        <img src={leafIcon} className={styles.logo} alt="Ingrid Logo" />
      </Link>
    </div>
  );
};

export default Component;
