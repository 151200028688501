import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';

import { DeliveryEventModel, DeliveryStatusModel } from '../../models';
import { Reducer } from '../../modules';

import * as deliveriesActions from './actions';

export type State = {
  readonly isFetching: boolean;
  readonly deliveries: DeliveryStatusModel[];
  readonly deliveriesHistoryById: { [id: string]: DeliveryEventModel[] };
  readonly error: string | null;
};

const isFetching: Reducer<State['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(deliveriesActions.getShipmentListStatusesRequest):
    case getType(deliveriesActions.getDeliveryStatusesRequest):
      return true;

    case getType(deliveriesActions.getShipmentListStatusesSuccess):
    case getType(deliveriesActions.getShipmentListStatusesError):
    case getType(deliveriesActions.getDeliveryStatusesError):
    case getType(deliveriesActions.getDeliveryStatusesSuccess):
      return false;

    default:
      return state;
  }
};

const deliveries: Reducer<State['deliveries']> = (state = [], action) => {
  switch (action.type) {
    case getType(deliveriesActions.getShipmentListStatusesSuccess):
      return [...action.payload];

    default:
      return state;
  }
};

const error: Reducer<State['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(deliveriesActions.getShipmentListStatusesError):
    case getType(deliveriesActions.getDeliveryStatusesError):
      return action.payload;

    case getType(deliveriesActions.getShipmentListStatusesRequest):
    case getType(deliveriesActions.getDeliveryStatusesRequest):
      return null;

    default:
      return state;
  }
};

const deliveriesHistoryById: Reducer<State['deliveriesHistoryById']> = (state = {}, action) => {
  switch (action.type) {
    case getType(deliveriesActions.getDeliveryStatusesSuccess):
      return { ...state, [action.payload.deliveryId]: action.payload.models };
    default:
      return state;
  }
};

const reducer: Reducer<State> = combineReducers({
  deliveries,
  isFetching,
  error,
  deliveriesHistoryById,
});

export default reducer;
