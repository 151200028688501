import { Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { getCountriesOptions, getCountryCodes } from '@src/services/dictionaries-service';
import { getFieldArrayError } from '@src/utils/forms';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { string } from 'yup';
import { RuleAddingValues } from '../rule-adding-container';

const SelectItem = withFormItem(Select);

interface Props {
  name: string;
  errors: FormikProps<RuleAddingValues>['errors'];
  touched: FormikProps<RuleAddingValues>['touched'];
  setFieldValue: FormikProps<RuleAddingValues>['setFieldValue'];
}

const CountryValidate = async (value: string) => {
  try {
    await string()
      .required('Country cannot be empty')
      .oneOf(getCountryCodes(), err => `${err.value} is not a valid country`)
      .validate(value);
  } catch (err) {
    return err;
  }
};

export const Country: React.FunctionComponent<Props> = ({
  name,
  errors,
  touched,
  setFieldValue,
}) => {
  const countriesOptions = getCountriesOptions();
  return (
    <div className={styles.wrapper}>
      <Field name={`${name}.value`} validate={CountryValidate}>
        {({ field }: FieldProps<string>) => (
          <SelectItem
            className={styles.label}
            options={countriesOptions}
            filterOption={true}
            showSearch
            placeholder={'Please select...'}
            optionFilterProp={'label'}
            label="If Country is"
            style={{ width: '300px' }}
            error={getFieldArrayError(touched, errors, `${name}.value`)}
            {...field}
            onChange={(value: string) => {
              setFieldValue(`${name}.value`, value);
            }}
          />
        )}
      </Field>
    </div>
  );
};

const styles = stylesheet({
  wrapper: {
    padding: '5px 0',
    $nest: {
      '.ant-row': {
        display: 'flex',
        flexWrap: 'nowrap',
        marginBottom: 0,
      },
    },
  },
  label: {
    $nest: {
      '&.ant-select': {
        border: '1px solid #d9d9d9',
        borderRadius: '3px',
      },
      '&.ant-select:hover': {
        border: '1px solid #5bc2b6',
      },
      '&.ant-select-open': {
        border: '1px solid #5bc2b6',
      },
    },
  },
});
