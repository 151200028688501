import * as React from 'react';

import { RootState } from '@src/modules';
import { ShipmentsSelectors } from '@src/modules/shipments';
import { connect } from 'react-redux';
import { ExternalID } from './components/external-id';

interface OwnProps {
  itemId: string;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  customerOrderNumber: state.somSearch.orderNumber,
  externalId: ShipmentsSelectors.getExternalIdForApoteket(state, ownProps.itemId),
  externalOrderIds: ShipmentsSelectors.getExternalOrderIds(state, ownProps.itemId),
});

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

class Component extends React.Component<Props> {
  render() {
    const { customerOrderNumber, externalId, externalOrderIds } = this.props;
    return (
      <div>
        {customerOrderNumber && <ExternalID id={customerOrderNumber} index={1} />}
        {externalId && <ExternalID id={externalId} index={customerOrderNumber ? 2 : 1} />}
        {externalOrderIds.map((externalOrderId, idx) => (
          <ExternalID
            id={externalOrderId}
            index={idx + 1 + (customerOrderNumber ? 1 : 0) + (externalId ? 1 : 0)}
          />
        ))}
      </div>
    );
  }
}

export const ExternalIdsContainer = connect(mapStateToProps)(Component);
