import { createSelector } from 'reselect';
import { Option } from 'space-lift';

import { authSelectors } from '../auth';
import { RootState } from '../root-reducer';
import { RequestState } from './reducers';

const getReports = (state: RootState) => state.reports;

export const getReportIdForCurrentUser = createSelector(
  [getReports, authSelectors.getAuthUserId],
  (reports, currentUserId) =>
    Option(reports.reportIdByUserId[currentUserId]).fold(
      () => '',
      report => report.id
    )
);

export const getUriForCurrentUser = createSelector(
  [getReports, authSelectors.getAuthUserId],
  (reports, currentUserId) => reports.uriByUserId[currentUserId]
);

export const getReportRequestStatus = createSelector([getReports], reports => reports.status);

export const getReportBadgeStatus = createSelector(getReportRequestStatus, status => {
  switch (status) {
    case RequestState.active:
      return 'processing';
    case RequestState.exception:
      return 'error';
    case RequestState.success:
      return 'success';
    default:
      return undefined;
  }
});
