import * as React from 'react';
import { connect } from 'react-redux';

import { RootAction, RootState } from '@src/modules';
import { configActions, configSelectors } from '@src/modules/config';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';

import { Spin } from 'antd';
import { Dispatch } from 'redux';
import {
  WarehouseAdditionalData,
  WarehouseCutoffTimesList,
  WarehouseDetailsAddress,
  WarehouseHeader,
  WarehouseID,
  WarehouseLinkedRegions,
  WarehouseShippingDateAdjustment,
} from './components';
import { warehouseSelectors } from './selectors';

interface OwnProps {
  warehouseId: string;
  regionId?: string;
  showLinkedRegions?: boolean;
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  regionsNamesIdsByWarehouseId: warehouseSelectors.getRegionNameToWarehouseIdMap(
    state,
    props.warehouseId
  ),
  warehouse: warehouseSelectors.getWarehouseById(state, props.warehouseId),
  region: props.regionId ? configSelectors.getRegionById(state, props.regionId) : undefined,
  isCreatingDraft: state.config.isCreatingDraft,
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  updateWarehouseDetails: dispatchOnDraftSiteId(configActions.updateWarehouseDetailsRequest),
  createWarehouseCutoffTimes: dispatchOnDraftSiteId(
    configActions.createWarehouseCutoffTimesRequest
  ),
  setWarehouseSDA: dispatchOnDraftSiteId(configActions.setWarehouseShippingDateAdjustmentRequest),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

const Component: React.FunctionComponent<Props> = ({
  warehouse,
  region,
  updateWarehouseDetails,
  createWarehouseCutoffTimes,
  setWarehouseSDA,
  regionsNamesIdsByWarehouseId,
  isCreatingDraft,
  showLinkedRegions,
}) => {
  if (!warehouse) {
    return <Spin />;
  }
  return (
    <Spin spinning={isCreatingDraft}>
      <WarehouseHeader name={warehouse.address.name} />
      <WarehouseID id={warehouse.id} />
      {showLinkedRegions && (
        <WarehouseLinkedRegions regionsNamesIdsByWarehouseId={regionsNamesIdsByWarehouseId} />
      )}
      <WarehouseAdditionalData
        warehouse={warehouse}
        updateWarehouseDetails={updateWarehouseDetails}
      />
      <WarehouseShippingDateAdjustment warehouse={warehouse} setWarehouseSDA={setWarehouseSDA} />
      <WarehouseDetailsAddress
        warehouse={warehouse}
        updateWarehouseDetails={updateWarehouseDetails}
      />
      <WarehouseCutoffTimesList
        warehouse={warehouse}
        region={region!}
        createCutoffTimes={createWarehouseCutoffTimes}
      />
    </Spin>
  );
};
export const WarehouseContainer = connect(mapStateToProps, mapDispatchToProps)(Component);
