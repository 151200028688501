import { connect, FormikProps } from 'formik';
import { and, pathOr } from 'ramda';
import { pathNotEq } from 'ramda-adjunct';
import * as React from 'react';

import { Modal } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { ConfigFilterRuleModel } from '@src/models';
import { FilterRulesValues } from './filter-rules';

interface OwnProps {
  isVisible: boolean;
  changeVisibility: (value: boolean) => void;
  values: {
    conditions: string[];
  };
  handleSave: (updatedValues: FilterRulesValues) => void;
  filterRules: ConfigFilterRuleModel[];
  index: number;
  name: string;
}

type Props = OwnProps & { formik: FormikProps<OwnProps['values']> };

export const Component: React.FunctionComponent<Props> = ({
  isVisible,
  changeVisibility,
  handleSave,
  values,
  filterRules,
  index,
  formik: { setFieldValue },
  name,
}) => {
  return (
    <Modal
      title={<FormattedMessage id="EDIT_FILTER_RULES" />}
      visible={isVisible}
      onOk={() => {
        handleSave(values);
        changeVisibility(false);
      }}
      onCancel={() => {
        if (
          and(
            pathOr(false, [index, 'condition'], filterRules),
            pathNotEq([index, 'condition'], 'true', filterRules)
          )
        ) {
          setFieldValue(name, filterRules[index].condition);
        } else {
          setFieldValue(name, '');
        }
        changeVisibility(false);
      }}
    >
      <FormattedMessage id="EDIT_FILTER_RULES_CONFIRM_BODY" />
    </Modal>
  );
};

export const ConfirmationModal = connect<OwnProps, {}>(Component);
