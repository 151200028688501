import * as React from 'react';
import { connect } from 'react-redux';
import { DOMAIN, LABELS } from '../../dictionaries';
import { RootState } from '../../modules';

import { routerActions } from '@src/modules/router';
import { addressBookActions } from '../../modules/address-book';
import { eventTrackingActions } from '../../modules/event-tracking';
import { AddressBookContactForm } from './components/address-book-contact-form';

// Component API
const mapStateToProps = (state: RootState) => ({
  addressBookError: state.addressBook.error,
  isFetching: state.addressBook.isFetching,
});
const dispatchProps = {
  createContact: addressBookActions.createContactRequest,
  viewAddressCreatePage: eventTrackingActions.viewAddressCreatePage,
  toggleAdditionalContactInfo: eventTrackingActions.toggleAdditionalContactInfo,
  routerPush: routerActions.push,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;
type State = {};

class AddressBookContactCreateContainer extends React.Component<Props, State> {
  handleCancel = () => {
    this.props.routerPush({ name: 'ADDRESS_BOOK' });
  };

  componentDidMount() {
    this.props.viewAddressCreatePage();
  }

  render() {
    const { addressBookError, isFetching, toggleAdditionalContactInfo } = this.props;

    return (
      <AddressBookContactForm
        addressBookError={addressBookError}
        isLoading={isFetching}
        createContact={this.props.createContact}
        title={[LABELS.CREATE, DOMAIN.ADDRESS_BOOK].join(' ')}
        onBack={this.handleCancel}
        onBackLabel={LABELS.CANCEL}
        onSubmitLabel={LABELS.SAVE}
        toggleAdditionalInfo={toggleAdditionalContactInfo}
      />
    );
  }
}

export default connect(mapStateToProps, dispatchProps)(AddressBookContactCreateContainer);
