import * as React from 'react';
import { stylesheet } from 'typestyle';

import { configSelectors } from '@src/modules/config';
import { Switch, Tooltip } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { ConfigShippingCategoryModel } from '@src/models';
import { getStateAsBoolean } from '@src/utils/data-transformations';
import { useSelector } from '@src/utils/hooks';
import { checkIfPreselectionOrderComplete } from '@src/utils/preselection-order-utils';

/**
 * Switch with tooltip to toggle active/inactive state of category.
 */
export const ActivateSwitch: React.FC<{
  category: ConfigShippingCategoryModel;
  onChange: (value: boolean) => void;
}> = ({ category, onChange }) => {
  const categoryPreselectionOrder = useSelector(state =>
    configSelectors.getCategoryPreselectionOrder(state, category.id)
  );
  const hasPreselectionOrderWarning = !checkIfPreselectionOrderComplete(categoryPreselectionOrder);
  // Simple <Tooltip><Switch /></Tooltip> unfortunately won't work (there would be incorrect tooltip show/hide behaviour)
  // and therefore following hack with absolute positioned clickable area is needed
  return (
    <div className={styles.clickableAreaWrapper}>
      {hasPreselectionOrderWarning && category.state !== 'ACTIVE' && (
        <Tooltip
          trigger="hover"
          title={<FormattedMessage id="PRESELECTION_ORDER_ACTIVATE_ALERT" />}
        >
          <div className={styles.clickableArea}></div>
        </Tooltip>
      )}
      <Switch
        checked={getStateAsBoolean(category.state)}
        disabled={hasPreselectionOrderWarning && category.state !== 'ACTIVE'}
        onChange={onChange}
      />
    </div>
  );
};

const styles = stylesheet({
  clickableAreaWrapper: {
    position: 'relative',
  },
  clickableArea: {
    height: '28px',
    width: '55px',
    position: 'absolute',
    zIndex: 999,
  },
});
