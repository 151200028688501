import * as qs from 'query-string';

import { TransportOrderDTO, TransportOrderListFiltersModel, TransportOrderModel } from '../models';
import { apiUtils, authFetch } from '../utils';

const TOS_URL = apiUtils.getApiUrl() + '/frontend/tos';

type Direction = 'ASCENDING' | 'DESCENDING';

export async function listTransportOrders(
  siteId: string,
  pageSize: number,
  pageNumber: number,
  direction: Direction = 'DESCENDING',
  searchQuery?: string,
  filters?: TransportOrderListFiltersModel
): Promise<{ orders: TransportOrderModel[]; count: number }> {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  let filtersDTO;
  if (filters) {
    filtersDTO = {
      ['filtering.created_at.from']: filters.createdAtRange[0],
      ['filtering.created_at.to']: filters.createdAtRange[1],
      ['filtering.sources']: filters.sources,
    };
  }

  return authFetch<{ orders?: TransportOrderDTO[]; total_count?: string }>(
    `${TOS_URL}/order.list?` +
      qs.stringify({
        site_id: siteId,
        ['order_by']: 'CREATED_AT',
        ['order_by_direction']: direction,
        ['pagination.limit']: pageSize,
        ['pagination.offset']: (pageNumber - 1) * pageSize,
        ['filtering.id']: searchQuery,
        ...filtersDTO,
      }),
    options
  ).then(res => {
    return {
      orders: res.orders
        ? res.orders
            .map(order => order && TransportOrderModel.createFromDTO(order))
            .filter(order => order != null && order.id != null)
        : [],
      count: res.total_count ? parseInt(res.total_count, 10) : 0,
    };
  });
}

export async function getTransportOrder(siteId: string, id: string): Promise<TransportOrderModel> {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<{ order: TransportOrderDTO }>(TOS_URL + `/order.get?id=${id}`, options).then(
    res => {
      if (res == null || res.order == null) {
        throw new Error('Resource not found!');
      }
      return TransportOrderModel.createFromDTO(res.order);
    }
  );
}

export async function createTransportOrder(
  siteId: string,
  model: TransportOrderModel
): Promise<TransportOrderModel> {
  const payload = TransportOrderDTO.createFromModel(model);
  // stub site_id
  payload.site_id = siteId;

  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };

  return authFetch<{ order: TransportOrderDTO }>(TOS_URL + '/order.create', options).then(res => {
    if (res == null || res.order == null) {
      throw new Error('Resource not found!');
    }
    return TransportOrderModel.createFromDTO(res.order);
  });
}

export async function updateTransportOrder(
  siteId: string,
  model: TransportOrderModel
): Promise<TransportOrderModel> {
  const payload = TransportOrderDTO.createFromModel(model);
  // stub site_id
  payload.site_id = siteId;

  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify(payload),
  };

  return authFetch<{ order: TransportOrderDTO }>(TOS_URL + '/order.update', options).then(res => {
    if (res == null || res.order == null) {
      throw new Error('Resource not found!');
    }
    return TransportOrderModel.createFromDTO(res.order);
  });
}
