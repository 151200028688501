import { List } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';
import { isEmpty } from 'ramda';

import { LimitedNumberOfItems } from '@src/components';
import { Link } from '@src/components/link';
import { Button, Tag } from '@src/controls';
import { mapping } from '@src/dictionaries';
import { FormattedMessage } from '@src/i18n';
import { configSelectors } from '@src/modules/config';
import { getRegionTypeByCountryConfig, getTagColors } from './helpers';
import { defaultTheme } from '@src/styles';
import { ConfigRegionModel, ConfigWarehouseModel } from '@src/models';

interface Props {
  isFetching: boolean;
  regions: ReturnType<typeof configSelectors['getRegionsByDraftOrCurrentSiteId']>;
  onRegionDelete: (regionId: string) => void;
  warehouses: ConfigWarehouseModel[];
  renderLink: (region: ConfigRegionModel) => React.ReactNode;
}

interface ListItem {
  content: JSX.Element;
}

export const RegionsList: React.FunctionComponent<Props> = ({
  regions,
  isFetching,
  onRegionDelete,
  warehouses,
  renderLink,
}) => {
  const data: ListItem[] = regions.map(region => {
    const tagColors = getTagColors(region.regionCountryConfig, region.regionPostalCodeConfig);
    return {
      content: (
        <div className={styles.listItem}>
          {renderLink(region)}
          <div className={styles.subRegions}>
            <Tag
              color={tagColors.background}
              textColor={tagColors.text}
              borderColor={tagColors.border}
              nonClickable={true}
            >
              <FormattedMessage
                id={getRegionTypeByCountryConfig(
                  region.regionCountryConfig,
                  region.regionPostalCodeConfig
                )}
              />
            </Tag>
            <div className={styles.subRegionItems}>
              {region.regionCountryConfig ? (
                <LimitedNumberOfItems
                  list={configSelectors.getCountries(region.regionCountryConfig)}
                />
              ) : (
                <>
                  <span>
                    {mapping.mapCountryCodeToName(region.regionPostalCodeConfig!.country!)}
                  </span>
                  &nbsp;
                  <LimitedNumberOfItems
                    list={
                      region.regionPostalCodeConfig!.postalCodes ||
                      region.regionPostalCodeConfig!.excludedPostalCodes!
                    }
                  />
                </>
              )}
            </div>
          </div>
          <div className={styles.status}>
            {region.state === 'ACTIVE' ? (
              <FormattedMessage id="ACTIVE" />
            ) : (
              <FormattedMessage id="INACTIVE" />
            )}
          </div>
          <Button type="link" onClick={() => onRegionDelete(region.id)}>
            <FormattedMessage id="DELETE" />
          </Button>
        </div>
      ),
    };
  });

  const getEmptyText = () => {
    return (
      <p className={styles.emptyRegionsList}>
        {isEmpty(warehouses) ? (
          <FormattedMessage
            id="EMPTY_REGIONS_LIST_NO_WAREHOUSE"
            values={{
              a: (chunks: string) => (
                <Link route={{ name: 'WAREHOUSES_LIST' }} key={chunks}>
                  {chunks}
                </Link>
              ),
            }}
          />
        ) : (
          <FormattedMessage id="REGIONS_EMPTY_LIST" />
        )}
      </p>
    );
  };

  return (
    <List
      locale={{ emptyText: getEmptyText() }}
      dataSource={data}
      loading={isFetching}
      className={styles.list}
      renderItem={(item: ListItem) => (
        <List.Item>
          <List.Item.Meta description={item.content} />
        </List.Item>
      )}
    />
  );
};

const styles = stylesheet({
  listItem: {
    display: 'grid',
    gridTemplateColumns: '300px 1fr repeat(2, 100px)',
    gridAutoFlow: 'column',
    gridGap: '20px',
    placeItems: 'center self-start',
  },
  subRegions: {
    marginRight: 'auto',
  },
  status: {
    width: '90px',
    textAlign: 'left',
  },
  lightGrey: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  subRegionItems: {
    marginTop: '2px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  emptyRegionsList: {
    maxWidth: '60ch',
    $nest: {
      a: {
        color: defaultTheme.color.primary,
      },
    },
  },
  list: {
    $nest: {
      '.ant-list-empty-text': {
        display: 'grid',
        placeItems: 'center',
      },
    },
  },
});
