import * as React from 'react';

import { parsePriceNumber } from '@src/containers/regions/carrier-products/helpers';
import { CartItemModel, TransportOrderModel } from '@src/models';
import { style } from 'typestyle';
import { Table } from '../../../controls';
import { DOMAIN } from '../../../dictionaries';

interface IItemsTableProps {
  cartItems: TransportOrderModel['cart']['items'];
  currency?: string;
}

export const CartItemsTable: React.FC<IItemsTableProps> = ({ cartItems, currency }) => {
  return (
    <section style={{ position: 'relative' }}>
      <h4 className={styles.header}>{`${DOMAIN.LINE_ITEMS} (${cartItems.length})`}</h4>
      <Table<CartItemModel>
        size={'middle'}
        title={() => ''}
        dataSource={cartItems}
        rowKey={(record, index) => (index ? index.toString() : '')}
        pagination={false}
        showEmptyMessage={false}
        emptyMessage={<p>List is empty</p>}
        sortDirections={['ascend', 'descend']}
        className={styles.tableTitle}
      >
        <Table.Column<CartItemModel>
          title="SKU"
          key="sku"
          width="150px"
          render={(_, record) => record.sku || '-'}
        />
        <Table.Column<CartItemModel>
          title="Name"
          key="name"
          width="150px"
          render={(_, record) => record.name || '-'}
        />
        <Table.Column<CartItemModel>
          title="Qty"
          key="qty"
          width="100px"
          render={(_, record) => record.quantity || '-'}
        />
        <Table.Column<CartItemModel>
          title="Weight [gm]"
          key="weight"
          width="100px"
          render={(_, record) => record.weight || '-'}
        />
        <Table.Column<CartItemModel>
          title="Dimensions [mm]"
          key="dimensions"
          width="150px"
          render={(_text, record) => {
            if (!record.dimensions) {
              return '-';
            }
            const { height, length, width } = record.dimensions;
            return height && length && width ? (
              <p className={styles.dimensions}>
                {height}x{length}x{width}
              </p>
            ) : (
              '-'
            );
          }}
        />
        <Table.Column<CartItemModel>
          title={currency ? `Price [${currency}]` : `Price`}
          key="price"
          width="100px"
          render={(text, record) =>
            record.price !== undefined ? parsePriceNumber(record.price) : '-'
          }
        />
      </Table>
    </section>
  );
};

const styles = {
  tableTitle: style({
    $nest: {
      '.ant-table-title': {
        padding: '0',
      },
    },
  }),
  addButton: style({
    paddingBottom: '16px',
  }),
  header: style({
    marginBottom: '16px',
  }),
  dimensions: style({
    margin: 0,
  }),
};
