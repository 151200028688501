import { parseIsoDate } from '../utils/date';

export const dateSorter = (a: string, b: string) => {
  const dateA = parseIsoDate(a) || new Date();
  const dateB = parseIsoDate(b) || new Date();

  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }
  // a must be equal to b
  return 0;
};
