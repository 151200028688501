import { CopyOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { classes, style as tss } from 'typestyle';

import { services } from '@src/services';

type Props = {
  text: string;
  onCopyText: string;
  className?: string;
};

export const TextWithCopy: FC<Props> = ({ text, onCopyText, children, className }) => (
  <div className={classes(className, styles.withCopyContainer)}>
    {children}
    <CopyToClipboard text={text} onCopy={() => services.messageService.success(onCopyText)}>
      <CopyOutlined style={{ fontSize: '16px' }} onClick={e => e.stopPropagation()} />
    </CopyToClipboard>
  </div>
);

const styles = {
  withCopyContainer: tss({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  }),
};
