import { GenericAddressEntityDTO, GenericAddressEntityModel } from './selfcare-models';
import { TimeSlotDTO, TimeSlotModel } from './tos-models';
import { supportedRegionInCountry } from '@src/utils/address';

export class SIWSessionCreateModel {
  purchaseCountry: string;
  purchaseCurrency: string;
  locale: string;
  cart: SIWCartModel;
  searchAddress: SIWSearchAddressModel;
  meta?: { [key: string]: string };

  static createFromDTO(dto: SIWSessionCreateDTO): SIWSessionCreateModel {
    return {
      purchaseCountry: dto.purchase_country,
      purchaseCurrency: dto.purchase_currency,
      locale: dto.locale,
      cart: SIWCartModel.createFromDTO(dto.cart),
      searchAddress: SIWSearchAddressModel.createFromDTO(dto.search_address),
      meta: dto.meta,
    };
  }
}

export class SIWSessionCreateDTO {
  purchase_country: string;
  purchase_currency: string;
  locale: string;
  cart: SIWCartDTO;
  search_address: SIWSearchAddressDTO;
  meta?: { [key: string]: string };

  static createFromModel(model: SIWSessionCreateModel): SIWSessionCreateDTO {
    return {
      purchase_country: model.purchaseCountry,
      purchase_currency: model.purchaseCurrency,
      locale: model.locale,
      cart: SIWCartDTO.createFromModel(model.cart),
      search_address: SIWSearchAddressDTO.createFromModel(model.searchAddress),
      meta: model.meta,
    };
  }
}
export class SIWSearchAddressModel {
  country: string;
  postalCode: string;
  name?: string;
  addressLines?: string[];
  city?: string;
  region?: string;

  static createFromDTO(dto: SIWSearchAddressDTO): SIWSearchAddressModel {
    return {
      country: dto.country,
      postalCode: dto.postal_code,
      name: dto.name,
      addressLines: dto.address_lines,
      city: dto.city,
      region: supportedRegionInCountry(dto.country) ? dto.region : undefined,
    };
  }
}

class SIWSearchAddressDTO {
  country: string;
  postal_code: string;
  name?: string;
  address_lines?: string[];
  city?: string;
  region?: string;

  static createFromModel(model: SIWSearchAddressModel): SIWSearchAddressDTO {
    return {
      country: model.country,
      postal_code: model.postalCode,
      name: model.name,
      address_lines: model.addressLines,
      city: model.city,
      region: supportedRegionInCountry(model.country) ? model.region : undefined,
    };
  }
}

export class SIWCartModel {
  totalValue: number;
  currency: string;
  items: SIWItemModel[];
  voucher: string;
  shippingDate?: { start: string; end: string };
  cartId?: string;
  attributes?: string[];

  static createFromDTO(dto: SIWCartDTO): SIWCartModel {
    return {
      totalValue: dto.total_value,
      currency: dto.currency,
      items: dto.items.map(item => SIWItemModel.createFromDTO(item)),
      voucher: dto.voucher,
      shippingDate: dto.shipping_date,
      cartId: dto.cart_id,
      attributes: dto.attributes,
    };
  }
}

export class SIWCartDTO {
  total_value: number;
  currency: string;
  items: SIWItemDTO[];
  voucher: string;
  shipping_date?: { start: string; end: string };
  cart_id?: string;
  attributes?: string[];

  static createFromModel(model: SIWCartModel): SIWCartDTO {
    return {
      total_value: model.totalValue,
      currency: model.currency,
      items: model.items.map(item => SIWItemDTO.createFromModel(item)),
      voucher: model.voucher,
      shipping_date: model.shippingDate,
      cart_id: model.cartId,
      attributes: model.attributes,
    };
  }
}

export class SIWItemModel {
  sku: string;
  name: string;
  attributes: string[];
  weight: number;
  dimensions?: {
    height?: number;
    length?: number;
    width?: number;
  };
  quantity?: number;
  price?: number;

  static createFromDTO(dto: SIWItemDTO): SIWItemModel {
    return {
      sku: dto.sku,
      name: dto.name,
      attributes: dto.attributes,
      weight: dto.weight,
      dimensions: dto.dimensions,
      quantity: dto.quantity,
      price: dto.price,
    };
  }
}

export class SIWItemDTO {
  sku: string;
  name: string;
  attributes: string[];
  weight: number;
  dimensions?: {
    height?: number;
    length?: number;
    width?: number;
  };
  quantity?: number;
  price?: number;

  static createFromModel(model: SIWItemModel): SIWItemDTO {
    return {
      sku: model.sku,
      name: model.name,
      attributes: model.attributes,
      weight: model.weight,
      dimensions: model.dimensions,
      quantity: model.quantity,
      price: model.price,
    };
  }
}

export class SIWSessionCreateResponseModel {
  session: SIWSessionModel;
  htmlSnippet: string;

  static createFromDTO(dto: SIWSessionCreateResponseDTO): SIWSessionCreateResponseModel {
    return {
      session: SIWSessionModel.createFromDTO(dto.session),
      htmlSnippet: dto.html_snippet,
    };
  }
}

export class SIWSessionCreateResponseDTO {
  session: SIWSessionDTO;
  html_snippet: string;
}

export class SIWSessionUpdateModel {
  id: string;
  cart: SIWCartModel;
  searchAddress: SIWSearchAddressModel;
}

export class SIWSessionUpdateDTO {
  id: string;
  cart: SIWCartDTO;
  search_address?: SIWSearchAddressDTO;

  static createFromModel(model: SIWSessionUpdateModel): SIWSessionUpdateDTO {
    return {
      id: model.id,
      cart: SIWCartDTO.createFromModel(model.cart),
      search_address:
        model.searchAddress && SIWSearchAddressDTO.createFromModel(model.searchAddress),
    };
  }
}

export class SIWSessionModel {
  id: string;
  status: string;
  cart: SIWCartModel;
  expiresAt: string;
  searchAddress?: SIWSearchAddressModel;
  result: ResultModel;

  externalId?: string;

  static createFromDTO(dto: SIWSessionDTO): SIWSessionModel {
    return {
      id: dto.id,
      status: dto.status,
      cart: SIWCartModel.createFromDTO(dto.cart),
      expiresAt: dto.expires_at,
      searchAddress: dto.search_address && SIWSearchAddressModel.createFromDTO(dto.search_address),
      result: ResultModel.createFromDTO(dto.result),
      externalId: dto.external_id,
    };
  }
}

export class SIWSessionDTO {
  id: string;
  status: string;
  cart: SIWCartDTO;
  expires_at: string;
  search_address: SIWSearchAddressDTO;
  result: ResultDTO;
  external_id?: string;
}

export class ResultDTO {
  shipping?: ResultShippingDTO;
  category?: ResultCategoryDTO;
  pricing?: ResultPricingDTO;
}

export class ResultModel {
  shipping?: ResultShippingModel;
  category?: ResultCategoryModel;
  pricing?: ResultPricingModel;

  static createFromDTO(dto: ResultDTO): ResultModel {
    return {
      shipping: dto.shipping && ResultShippingModel.createFromDTO(dto.shipping),
      category: dto.category,
      pricing: dto.pricing,
    };
  }
}

export class ResultPricingModel {
  price?: number;
}

export class ResultPricingDTO {
  price?: number;
}

export class ResultCategoryDTO {
  name?: string;
}

export class ResultCategoryModel {
  name?: string;
}

export class ResultShippingDTO {
  shipping_method?: string;
  delivery_type?: string;
  delivery_time?: TimeSlotDTO;
  external_method_id?: string;
  location?: PickupLocationDTO;
  carrier?: string;
  product?: string;
}

export class ResultShippingModel {
  shippingMethod?: string;
  deliveryType?: string;
  deliveryTime?: TimeSlotModel;
  location?: PickupLocationModel;
  externalMethodId?: string;
  carrier?: string;
  product?: string;

  static createFromDTO(dto: ResultShippingDTO): ResultShippingModel {
    return {
      shippingMethod: dto.shipping_method,
      deliveryType: dto.delivery_type,
      deliveryTime: dto.delivery_time,
      externalMethodId: dto.external_method_id,
      location: dto.location && PickupLocationModel.createFromDTO(dto.location),
      carrier: dto.carrier,
      product: dto.product,
    };
  }
}

class PickupLocationDTO {
  name?: string;
  external_id?: string;
  address?: Pick<
    GenericAddressEntityDTO,
    'name' | 'country' | 'postal_code' | 'address_lines' | 'city' | 'coordinates'
  >;
}

export class PickupLocationModel {
  name?: string;
  externalId?: string;
  address?: Pick<
    GenericAddressEntityModel,
    'name' | 'country' | 'postalCode' | 'addressLines' | 'city' | 'coordinates'
  >;

  static createFromDTO(dto: PickupLocationDTO): PickupLocationModel {
    return {
      name: dto.name,
      externalId: dto.external_id,
      address: dto.address && {
        name: dto.address.name,
        country: dto.address.country,
        postalCode: dto.address.postal_code,
        addressLines: dto.address.address_lines,
        city: dto.address.city,
      },
    };
  }
}
