import { TrackingEvent } from '@src/api/wimo';
import { dateUtils } from '@src/utils';
import { pathOr } from 'ramda';
import React from 'react';
import { StatusTag } from '../components';
import { style } from 'typestyle';

const styles = {
  itemStyles: style({
    display: 'flex',
    flexDirection: 'column',
  }),
  descriptionStyles: style({
    color: 'rgba(0, 0, 0, 0.6)',
  }),
  additionalInfo: style({ whiteSpace: 'pre' }),
};

export const StatusItem = ({
  trackingEvent,
  additionalInfo,
}: {
  trackingEvent: TrackingEvent;
  additionalInfo?: string[];
}) => {
  return (
    <div className={styles.itemStyles}>
      <StatusTag status={trackingEvent.tracking_status!} />
      <span>{dateUtils.formatIsoDate(trackingEvent.created_at)}</span>
      <span>{pathOr('', ['name'], trackingEvent.location)}</span>
      <span className={styles.descriptionStyles}>{trackingEvent.origin_status}</span>
      {additionalInfo ? (
        <span className={styles.additionalInfo}>{additionalInfo.join('\n')}</span>
      ) : null}
    </div>
  );
};
