import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';

import { SIWSessionDTO, SIWSessionModel } from '../../models';
import { Reducer } from '../../modules';
import { GET_TRANSPORT_ORDER_SUCCESS } from '../transport-orders/types';
import * as siwActions from './actions';

export type State = Readonly<{
  session: SIWSessionModel | null;
  error: string | null;
  isFetching: boolean;
  htmlSnippet: string | null;
  orderSession: SIWSessionModel | null;
  // this is used just to show raw JSON view of the session in transport order details
  orderSessionDTO: SIWSessionDTO | null;
}>;

const session: Reducer<State['session']> = (state = null, action) => {
  switch (action.type) {
    case getType(siwActions.createSessionSuccess):
      return action.payload.session;

    case getType(siwActions.getSessionSuccess):
      return action.payload.model;

    case getType(siwActions.resetWidgetSession):
      return null;

    case getType(siwActions.completeSessionSuccess):
      return null;

    default:
      return state;
  }
};

const error: Reducer<State['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(siwActions.createSessionError):
      return action.payload;

    case getType(siwActions.createSessionSuccess):
    case getType(siwActions.createSessionRequest):
    case getType(siwActions.resetWidgetSession):
      return null;

    default:
      return state;
  }
};

const isFetching: Reducer<State['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(siwActions.createSessionRequest):
    case getType(siwActions.getSessionRequest):
      return true;

    case getType(siwActions.createSessionError):
    case getType(siwActions.createSessionSuccess):
    case getType(siwActions.resetWidgetSession):
    case getType(siwActions.getSessionError):
    case getType(siwActions.getSessionSuccess):
      return false;

    default:
      return state;
  }
};

const htmlSnippet: Reducer<State['htmlSnippet']> = (state = null, action) => {
  switch (action.type) {
    case getType(siwActions.createSessionSuccess):
      return action.payload.htmlSnippet;

    case getType(siwActions.resetWidgetSession):
      return null;

    case getType(siwActions.completeSessionSuccess):
      return null;

    default:
      return state;
  }
};

const orderSession: Reducer<State['orderSession']> = (state = null, action) => {
  switch (action.type) {
    case getType(siwActions.getSessionSuccess):
      return action.payload.model;

    case getType(siwActions.getSessionError):
    case getType(siwActions.getSessionRequest):
    case GET_TRANSPORT_ORDER_SUCCESS:
      return null;

    default:
      return state;
  }
};

const orderSessionDTO: Reducer<State['orderSessionDTO']> = (state = null, action) => {
  switch (action.type) {
    case getType(siwActions.getSessionSuccess):
      return action.payload.dto;

    case getType(siwActions.getSessionError):
    case getType(siwActions.getSessionRequest):
    case GET_TRANSPORT_ORDER_SUCCESS:
      return null;

    default:
      return state;
  }
};

const reducer: Reducer<State> = combineReducers({
  session,
  error,
  isFetching,
  htmlSnippet,
  orderSession,
  orderSessionDTO,
});

export default reducer;
