import { createSelector } from 'reselect';
import { RootState } from '../root-reducer';

const getBlobsState = (state: RootState) => state.blobs;

export const attachmentField = createSelector(
  getBlobsState,
  attachment => attachment.attachmentField || ''
);
export const attachmentUrl = createSelector(
  getBlobsState,
  attachment => attachment.attachmentUrl || ''
);
export const attachmentError = createSelector(getBlobsState, attachment => attachment.error || '');
