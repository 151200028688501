export const trimRightFromParamIfExist = (path: string, param: string): string => {
  const params = path.split('/');
  const index = params.indexOf(param);
  if (index >= 0) {
    const result = params.slice(0, index);
    if (result) {
      return `${result.join('/')}`;
    }
  }

  return path;
};

export const extractParam = (path: string, param: string): string | null => {
  const params = path.split('/');
  const index = params.indexOf(param);
  if (index >= 0) {
    const newState = params[index + 1];
    if (newState) {
      return newState;
    }
  }

  return null;
};

export const hasParam = (param: string, path: string): boolean => {
  const params = path.split('/');
  const index = params.indexOf(param);
  return index >= 0;
};

export const updateParamWithValue = (param: string, value: string, path: string): string => {
  const params = path.split('/');
  const index = params.indexOf(param);
  if (index >= 0) {
    params[index + 1] = value;
    return `${params.join('/')}`;
  }

  return path;
};

/**
 * Gets current page name from URL path.
 *
 * There are 3 options:
 * 1. both merchant and sites are present in URL so current location will be at 5th position
 * /merchants/MERCHANT_ID/sites/SITE_ID/CURRENT_PAGE
 * 2. merchants or sites are present in URL so current location will be at 4th position
 * /sites/SITE_ID/CURRENT_PAGE
 * 3. there is no merchants or sites in URL
 * /CURRENT_PAGE
 * 4. root path is provided
 * /
 */
export const getCurrentPageName = (path: string): string => {
  if (hasParam('merchants', path) && hasParam('sites', path) && hasParam('shipments', path)) {
    return 'shipments';
  }
  const params = path.split('/');
  if (hasParam('merchants', path) && hasParam('sites', path)) {
    return params[5] || '/';
  }
  if (hasParam('merchants', path) || hasParam('sites', path)) {
    return params[3] || '/';
  }
  return params[1] || '/';
};

export const isWithinShipmentsRoute = (path: string) => {
  return /shipments\/(edit|create)/.test(path);
};

export const isWithinTransportOrdersRoute = (path: string) => {
  return /transport-orders\/(details|create)/.test(path);
};
