import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;

import { DeliveryStatus, ShipmentListFiltersModel, ShipmentSummaryModel } from '@src/models';

const GET_SHIPMENT_SUMMARIES_REQUEST = '@somSearch/GET_SHIPMENT_SUMMARIES_REQUEST';
const GET_SHIPMENT_SUMMARIES_SUCCESS = '@somSearch/GET_SHIPMENT_SUMMARIES_SUCCESS';
const GET_SHIPMENT_SUMMARIES_ERROR = '@somSearch/GET_SHIPMENT_SUMMARIES_ERROR';
const SET_ACTIVE_FILTERS = '@somSearch/SET_ACTIVE_FILTERS';
const CLEAR_ACTIVE_FILTERS = '@somSearch/CLEAR_ACTIVE_FILTERS';
const GET_SHIPMENT_METADATA_REQUEST = '@somSearch/GET_SHIPMENT_METADATA_REQUEST';
const GET_SHIPMENT_METADATA_SUCCESS = '@somSearch/GET_SHIPMENT_METADATA_SUCCESS';
const GET_SHIPMENT_METADATA_ERROR = '@somSearch/GET_SHIPMENT_METADATA_ERROR';
const GET_SHIPMENTS_STATUSES_REQUEST = '@somSearch/GET_SHIPEMNTS_STATUSES_REQUEST';
const GET_SHIPMENTS_STATUSES_SUCCESS = '@somSearch/GET_SHIPEMNTS_STATUSES_SUCCESS';
const GET_SHIPMENTS_STATUSES_ERROR = '@somSearch/GET_SHIPMENTS_STATUSES_ERROR';

export const getShipmentsStatusesRequest = createAction(
  GET_SHIPMENTS_STATUSES_REQUEST,
  resolve => (payload: { siteId: string; shipmentIds: string[] }) => resolve(payload)
);
export const getShipmentsStatusesSuccess = createAction(
  GET_SHIPMENTS_STATUSES_SUCCESS,
  resolve =>
    (
      payload: [
        {
          status: DeliveryStatus;
          shipmentId: string;
        }
      ]
    ) =>
      resolve(payload)
);
export const getShipmentsStatusesError = createAction(
  GET_SHIPMENTS_STATUSES_ERROR,
  resolve => (error: string) => resolve(error)
);

export const getShipmentSummariesRequest = createAction(
  GET_SHIPMENT_SUMMARIES_REQUEST,
  resolve =>
    (payload: {
      siteId: string;
      tosId: string;
      pageNumber: number;
      pageSize: number;
      direction?: string;
      filters?: ShipmentListFiltersModel;
    }) =>
      resolve(payload)
);

export const getShipmentSummariesError = createAction(
  GET_SHIPMENT_SUMMARIES_ERROR,
  resolve => (error: string) => resolve(error)
);

export const setActiveFilters = createAction(
  SET_ACTIVE_FILTERS,
  resolve => (filters: ShipmentListFiltersModel) => resolve(filters)
);
export const clearActiveFilters = createAction(CLEAR_ACTIVE_FILTERS);

export const getShipmentSummariesSuccess = createAction(
  GET_SHIPMENT_SUMMARIES_SUCCESS,
  resolve => (payload: { shipments: ShipmentSummaryModel[] }) => resolve(payload)
);

export const getShipmentMetadataRequest = createAction(
  GET_SHIPMENT_METADATA_REQUEST,
  resolve => (payload: { siteId: string; shipmentId: string }) => resolve(payload)
);
export const getShipmentMetadataSuccess = createAction(
  GET_SHIPMENT_METADATA_SUCCESS,
  resolve => (payload: { shipmentId: string; status: DeliveryStatus; orderNumber: string }) =>
    resolve(payload)
);
export const getShipmentMetadataError = createAction(
  GET_SHIPMENT_METADATA_ERROR,
  resolve => (error: string) => resolve(error)
);
