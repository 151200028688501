import { v4 } from 'uuid';
import { AddressDTO, AddressModel } from './';

export interface ContactDTO {
  id: string;
  address: AddressDTO;
  location_ref: string;
  phone?: string;
  email: string;
  notes: string;
  site_id: string;
  created_at: string;
  updated_at: string;
  additional_fields: MaybeKeyValueArray;
}

export class ContactModel {
  id: string = v4();
  address: AddressModel = new AddressModel();
  locationRef: string;
  phone?: string;
  email: string;
  notes: string;
  siteId: string;
  createdAt: string;
  updatedAt: string;
  additionalFields: AdditionalFields = {};

  static deserialize(dto: ContactDTO): ContactModel {
    return {
      id: dto.id,
      address: AddressModel.createFromDTO(dto.address),
      locationRef: dto.location_ref,
      phone: dto.phone,
      email: dto.email,
      notes: dto.notes,
      siteId: dto.site_id,
      createdAt: dto.created_at,
      updatedAt: dto.updated_at,
      additionalFields: mapToAdditionalFields(dto.additional_fields),
    };
  }

  static serialize(model: ContactModel): ContactDTO {
    return {
      id: model.id,
      address: AddressDTO.createFromModel(model.address),
      location_ref: model.locationRef,
      phone: model.phone,
      email: model.email,
      notes: model.notes,
      site_id: model.siteId,
      created_at: model.createdAt,
      updated_at: model.updatedAt,
      additional_fields: mapToKeyValueArray(model.additionalFields),
    };
  }
}

export class PaginationDTO {
  offset: number;
  limit: number;
}

export class PaginationModel {
  offset: number;
  limit: number;
}

export type AdditionalFields = { [key: string]: string | undefined };
export type MaybeKeyValueArray = { key: string; value: string | undefined }[] | undefined;

function mapToKeyValueArray(fields: AdditionalFields): MaybeKeyValueArray {
  return Object.entries(fields).map(([key, value]) => ({ key, value }));
}

function mapToAdditionalFields(arr: MaybeKeyValueArray): AdditionalFields {
  const objects = arr == null ? [] : arr.map(({ key, value }) => ({ [key]: value }));
  return Object.assign({}, ...objects);
}
