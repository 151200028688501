import { apiUtils, authFetch } from '../utils';
import * as qs from 'query-string';
import { GetTrackingEventsResponse } from '@src/api/wimo';

const URL = apiUtils.getApiUrl() + '/frontend/wimo/tracking.events';

type FetchTrackingEventsParams = {
  trackingNumber: string;
  siteId: string;
  locale: string;
};

export const fetchTrackingEvents = async ({ siteId, ...params }: FetchTrackingEventsParams) => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return await authFetch<GetTrackingEventsResponse>(`${URL}?${qs.stringify(params)}`, options);
};
