import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown as AntDropdown, Menu } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';
import { isNotEmpty } from 'ramda-adjunct';
import * as React from 'react';
import { classes, style as tss } from 'typestyle';
import { LABELS } from '../dictionaries';
import { defaultTheme } from '../styles';

type TriggerTypes = 'click' | 'hover';
type HTMLTypes = 'submit' | 'button';
interface DropdownButtonProps {
  htmlType?: HTMLTypes;
  disabled?: boolean;
  onMainActionClick?: null | ((ev: any) => any);
  mainActionLabel?: string | JSX.Element;
  overlayConfig?: { label: string | JSX.Element; handler: (ev: any) => any }[];
  trigger?: TriggerTypes;
  Icon?: React.ComponentType;
  className?: string;
}

const styles = {
  Dropdown: tss({
    cursor: 'pointer',
  }),
  DropdownButtonWrapper: tss({
    paddingRight: 24,
    marginBottom: 17,
    display: 'flex',
  }),
  mainActionNoRightRadius: tss({
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
  }),
  additionalActions: tss({
    fontWeight: 'bold',
    color: defaultTheme.color.primary,
  }),
  additionalActionsNoLeftRadius: tss({
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: 0,
    borderColor: defaultTheme.color.primary,
    fontWeight: defaultTheme.font.bold,
    color: defaultTheme.color.primary,
  }),
};

class Dropdown extends React.Component<DropDownProps, {}> {
  static Button: React.StatelessComponent<DropdownButtonProps> = ({
    disabled = false,
    htmlType = 'button' as HTMLTypes,
    mainActionLabel = LABELS.SUBMIT,
    onMainActionClick = () => null,
    overlayConfig = [],
    trigger = 'click' as TriggerTypes,
    Icon = EllipsisOutlined,
    className = styles.DropdownButtonWrapper,
  }) => {
    return (
      <div className={className}>
        {onMainActionClick && (
          <Button
            htmlType={htmlType}
            type="primary"
            loading={disabled}
            disabled={disabled}
            onClick={onMainActionClick}
            className={isNotEmpty(overlayConfig) ? styles.mainActionNoRightRadius : undefined}
          >
            {mainActionLabel}
          </Button>
        )}
        {isNotEmpty(overlayConfig) && (
          <AntDropdown
            overlay={
              <Menu>
                {overlayConfig.map((a, i) => (
                  <Menu.Item key={`${a.label}${i}`}>
                    <a onClick={a.handler}> {a.label} </a>
                  </Menu.Item>
                ))}
              </Menu>
            }
            disabled={disabled}
            trigger={[trigger]}
          >
            <Button
              className={classes(
                onMainActionClick ? styles.additionalActionsNoLeftRadius : styles.additionalActions,
                't_show-additional-actions'
              )}
              icon={<Icon />}
            />
          </AntDropdown>
        )}
      </div>
    );
  };
  render() {
    const { children, ...restProps } = this.props;
    return (
      <AntDropdown {...restProps} className={styles.Dropdown}>
        <>{children}</>
      </AntDropdown>
    );
  }
}

export default Dropdown;
