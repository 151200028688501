import { difference } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { DOMAIN, LABELS } from '@src/dictionaries';
import { UserCreateModel } from '@src/models';
import { RootAction, RootState } from '@src/modules';
import { authSelectors } from '@src/modules/auth';
import { tagsActions, tagsSelectors } from '@src/modules/tags';
import { usersActions, usersSelectors } from '@src/modules/users';
import { UserForm } from './components';

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  userGroup: usersSelectors.getUserGroup(state, props.userId),
  userGroups: usersSelectors.getUserGroups(state, props.userId),
  user: usersSelectors.getUserById(state, props.userId),
  isFetching: state.users.fetching,
  error: state.users.error,
  siteTagsOptions: tagsSelectors.getSiteTagOptions(state),
  siteTagsIdsForUser: tagsSelectors.getSiteTagsForUser(state),
  groupMembershipOptions: usersSelectors.getGroupMembershipOptions(state),
  accountTypes: authSelectors.getAccountTypes(state),
  groupsIdsWithAccessToWatchlists: usersSelectors.getGroupsIdsThatHaveWatchlistRole(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  ...bindActionCreators(
    {
      getUser: usersActions.getUserRequest,
      updateUser: usersActions.updateUserRequest,
      getSiteTagsPerUser: tagsActions.listSiteTagsPerUserRequest,
      getSiteTagsList: tagsActions.listSiteTagsRequest,
      getGroupsList: usersActions.getGroupListRequest,
    },
    dispatch
  ),
  updateUserWithTags: tagsActions.updateUserWithTagsRequest,
  assignGroupToUser: usersActions.assignUserToGroupRequest,
  removeGroupFromUser: usersActions.removeGroupFromUserRequest,
});

interface OwnProps {
  userId: string;
  siteId: string;
  merchantId: string;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & OwnProps;

class Component extends React.Component<Props> {
  componentDidMount() {
    const {
      getUser,
      userId,
      getSiteTagsList,
      siteId,
      getSiteTagsPerUser,
      getGroupsList,
      merchantId,
    } = this.props;
    getUser({ userId });
    getSiteTagsPerUser({ siteId, userId });
    getSiteTagsList({ siteId });
    getGroupsList({ merchantId, siteId });
  }

  handleEditUser = (user: UserCreateModel, groupIds: string[], tagsIds: string[]) => {
    const {
      updateUser,
      updateUserWithTags,
      assignGroupToUser,
      siteId,
      userGroups,
      removeGroupFromUser,
    } = this.props;
    const groupsIdsToAssign = difference(groupIds, userGroups);
    const groupsIdsToRemove = difference(userGroups, groupIds);
    updateUser({
      user,
      onComplete: userId => [
        ...groupsIdsToAssign.map(groupId => assignGroupToUser({ userId, groupId })),
        ...groupsIdsToRemove.map(groupId => removeGroupFromUser({ userId, groupId })),
        updateUserWithTags({ userId, tagsIds, siteId }),
      ],
    });
  };

  render() {
    const {
      isFetching,
      error,
      siteTagsOptions,
      user,
      siteTagsIdsForUser,
      groupMembershipOptions,
      userGroups,
      accountTypes,
      groupsIdsWithAccessToWatchlists,
    } = this.props;
    return (
      <UserForm
        groupMembershipOptions={groupMembershipOptions}
        onSubmit={this.handleEditUser}
        title={[LABELS.EDIT, DOMAIN.USER].join(' ')}
        isLoading={isFetching}
        error={error}
        siteTagsOptions={siteTagsOptions}
        userToEdit={user}
        userToEditGroups={userGroups}
        mode="edit"
        userTagsIds={siteTagsIdsForUser}
        accountTypes={accountTypes}
        groupsIdsWithAccessToWatchlists={groupsIdsWithAccessToWatchlists}
      />
    );
  }
}

export const UsersEditContainer = connect(mapStateToProps, mapDispatchToProps)(Component);
