import * as React from 'react';
import { Button, Form, Row } from 'antd';
import { Input } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { CreateVariantModel } from '@src/models';

interface Props {
  siteId: string;
  onSubmit: (values: CreateVariantModel) => void;
}

export const VariantForm: React.FC<Props> = ({ siteId, onSubmit }) => {
  return (
    <Form initialValues={{ siteId }} labelCol={{ span: 8 }} onFinish={onSubmit}>
      <Form.Item name="siteId" hidden />

      <Form.Item
        name="name"
        label={<FormattedMessage id="NAME" />}
        rules={[{ required: true, message: '' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="description" label={<FormattedMessage id="DESCRIPTION" />}>
        <Input />
      </Form.Item>

      <Row justify="end">
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="ADD" />
        </Button>
      </Row>
    </Form>
  );
};
