import * as React from 'react';
import { stylesheet } from 'typestyle';

import { DragableRow, LinkStyleButton } from '@src/components';
import { parsePriceNumber } from '@src/containers/regions/carrier-products/helpers';
import { Switch, Table } from '@src/controls';
import { DeliveryAddonModel } from '@src/models';

import { FormattedMessage } from '@src/i18n';
import { DragIcon } from '@src/images/drag-icon';
import { configActions } from '@src/modules/config';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { DeleteAddonPopover } from './delete-addon-popover';

interface Props {
  deliveryAddons: DeliveryAddonModel[];
  categoryId: string;
  isCreatingAddon: boolean;
  onAddonClick: (addonId: string) => void;
}

const addonTypeToNameMap: { [key in DeliveryAddonModel['type']]: string } = {
  TYPE_UNKNOWN: 'Unknown',
  TYPE_CUSTOM: 'Custom',
  TYPE_CARRY_IN: 'Carry in',
  TYPE_LEAVE_AT_DOOR: 'Leave at door',
  TYPE_UNWRAPPING: 'Unwrapping',
};

export const DeliveryAddonList: React.FC<Props> = ({
  deliveryAddons,
  categoryId,
  isCreatingAddon,
  onAddonClick,
}) => {
  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const addonIdsWithoutSelectedOne = deliveryAddons
      .filter((_, index) => index !== dragIndex)
      .map(addon => addon.id);

    const updatedOrder: string[] = [
      ...addonIdsWithoutSelectedOne.slice(0, hoverIndex),
      deliveryAddons[dragIndex].id,
      ...addonIdsWithoutSelectedOne.slice(hoverIndex),
    ];

    dispatchOnDraftSiteId(configActions.adjustDeliveryAddonsOrderRequest)({
      model: {
        addonIds: updatedOrder,
        categoryId,
      },
    });
  };

  const switchActiveState = ({ active, addon }: { active: boolean; addon: DeliveryAddonModel }) => {
    dispatchOnDraftSiteId(configActions.updateDeliveryAddonRequest)({
      model: {
        categoryId,
        addonId: addon.id,
        name: addon.name,
        type: addon.type,
        externalId: addon.externalId,
        defaults: addon.defaults,
        price: addon.price,
        state: active ? 'ACTIVE' : 'INACTIVE',
      },
    });
  };

  return (
    <Table<DeliveryAddonModel>
      dataSource={deliveryAddons}
      loading={isCreatingAddon}
      pagination={false}
      onRow={(_, index) => ({ index, moveRow })}
      components={{ body: { row: DragableRow } }}
      rowKey={(_, index) => `row_${index}`}
      hideTitle
      className={styles.table}
    >
      <Table.Column<DeliveryAddonModel>
        title={<FormattedMessage id="NAME" />}
        key="Name"
        render={(_, addon) => (
          <div className={styles.flexWrapper}>
            <DragIcon />
            <LinkStyleButton className={styles.flexWrapper} onClick={() => onAddonClick(addon.id)}>
              {addon.name}
            </LinkStyleButton>
          </div>
        )}
      />
      <Table.Column<DeliveryAddonModel>
        title={<FormattedMessage id="TYPE" />}
        key="Type"
        render={(_, addon) => (
          <div className={styles.flexWrapper}>
            <span className={styles.type}>{addonTypeToNameMap[addon.type]}</span>
          </div>
        )}
      />
      <Table.Column<DeliveryAddonModel>
        title={<FormattedMessage id="PRICE" />}
        key="Price"
        render={(_, addon) => (
          <div className={styles.flexWrapper}>
            <span className={styles.type}>{parsePriceNumber(parseInt(addon.price.value, 10))}</span>
          </div>
        )}
      />
      <Table.Column<DeliveryAddonModel>
        title={<FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.VAT_RATE" />}
        key="vatRate"
        render={(_, addon) => (
          <div className={styles.flexWrapper}>
            <span className={styles.type}>{addon.price.vatRate}</span>
          </div>
        )}
      />
      <Table.Column<DeliveryAddonModel>
        title={<FormattedMessage id="ACTIVE" />}
        key="Active"
        render={(_, addon) => (
          <Switch
            checked={addon.state === 'ACTIVE'}
            onChange={active => switchActiveState({ active, addon })}
          />
        )}
      />
      <Table.Column<DeliveryAddonModel>
        title={<FormattedMessage id="DELETE" />}
        key="Delete"
        render={(_, addon) => <DeleteAddonPopover addon={addon} categoryId={categoryId} />}
      />
    </Table>
  );
};

const styles = stylesheet({
  table: {
    $nest: {
      table: {
        borderCollapse: 'collapse',
      },
    },
  },
  flexWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  typeIcon: {
    height: '14px',
    width: '14px',
  },
  type: {
    marginLeft: '4px',
  },
});
