import React, { FC } from 'react';
import { Tooltip, Button } from 'antd';
import { hasPath } from 'ramda';
import { useQueryClient } from 'react-query';

import { FormattedMessage } from '@src/i18n';
import { ConfigSiteModel } from '@src/models';
import { services } from '@src/services';

import { TableGrid } from './table-grid';
import { useMutationOnDraft } from '@src/utils/queries';

type Props = {
  countryCode: string;
  siteData: ConfigSiteModel['receiptWidgetConfiguration'] | undefined;
};

export const FeatureCreator: FC<Props> = ({ countryCode, siteData, children }) => {
  const queryClient = useQueryClient();

  const onCreateCountryConfig = useMutationOnDraft(
    siteId =>
      services.selfCareService.upsertReceiptConfiguration(siteId, countryCode, { features: {} }),
    {
      onSuccess: () => queryClient.invalidateQueries(['site']),
    }
  );

  const hasCountryConfig = hasPath(['by_country', countryCode], siteData);

  return (
    <TableGrid>
      {hasCountryConfig ? (
        children
      ) : (
        <Tooltip title={<FormattedMessage id="SET_COUNTRY_SETTINGS_TOOLTIP" />}>
          <Button
            onClick={() => onCreateCountryConfig.mutate({})}
            size="small"
            loading={onCreateCountryConfig.isLoading}
            type="link"
          >
            <FormattedMessage id="SET_COUNTRY_SETTINGS" />
          </Button>
        </Tooltip>
      )}
    </TableGrid>
  );
};
