import { mapping } from '@src/dictionaries';
import {
  CommandModel,
  ConfigChangeModel,
  ConfigRegionModel,
  ConfigShippingCategoryModel,
  ConfigShippingMethodModel,
  CustomShippingMethodModel,
  DeliveryTypeEnum,
  ExtendedShippingMethodModel,
  ParcelTypeEnum,
  UserSummaryModel,
} from '@src/models';
import { RootState } from '@src/modules';
import { SitesSelectors } from '@src/modules/sites';
import { usersSelectors } from '@src/modules/users';
import { getAllCountries, groupCountriesByRegion } from '@src/services/dictionaries-service';
import { CategoryPreselectionOrder } from '@src/utils/preselection-order-utils';
import { PartiallyRequired } from '@src/utils/types';
import {
  comparator,
  compose,
  countBy,
  flatten,
  groupBy,
  head,
  identity,
  isEmpty,
  isNil,
  map,
  partial,
  pipe,
  prop,
  reduce,
  reject,
  sort,
  sortBy,
  toLower,
  toPairs,
  uniq,
  uniqBy,
} from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import { createSelector } from 'reselect';

const getState = (state: RootState) => state.config;

export const getIsCreatingDraft = createSelector(getState, state => state.isCreatingDraft);

const getWidgetConfigurationState = createSelector(
  getState,
  state => state.widgetConfigurationBySiteId.current
);

export const getVariantSiteIdOrEmpty = createSelector(getState, state => {
  return state.variantSiteId || '';
});

const getRegionsState = createSelector(getState, state => state.regionsBySiteId.current);

const getShippingCategoryState = createSelector(
  getState,
  state => state.shippingCategoryBySiteId.current
);

const getCustomBookingMethodsState = createSelector(
  getState,
  state => state.customBookingMethodsBySiteId
);

const getShippingMethodsState = createSelector(getState, state => state.shippingMethodsBySiteId);
const getCustomShippingMethodsState = createSelector(
  getState,
  state => state.customShippingMethodsBySiteId
);

export const getWarehouseState = createSelector(
  getState,
  state => state.warehousesBySiteId.current
);

export const getWidgetConfigurationByDraftOrCurrentSiteId = createSelector(
  getVariantSiteIdOrEmpty,
  SitesSelectors.getDraftSiteIdOrEmpty,
  SitesSelectors.getSelectedSiteIdOrEmpty,
  getWidgetConfigurationState,
  (variantSiteId, draftSiteId, siteId, configState) =>
    configState[variantSiteId] || configState[draftSiteId] || configState[siteId] || []
);

export const getWidgetCountryConfiguration = createSelector(
  getWidgetConfigurationByDraftOrCurrentSiteId,
  (_: RootState, countryCode: string) => countryCode,
  (widgetConfiguration, countryCode) => {
    return widgetConfiguration.byCountry?.[countryCode];
  }
);

export const getRegionsByDraftOrCurrentSiteId = createSelector(
  getVariantSiteIdOrEmpty,
  SitesSelectors.getDraftSiteIdOrEmpty,
  SitesSelectors.getSelectedSiteIdOrEmpty,
  getRegionsState,
  (variantSiteId, draftSiteId, siteId, state) =>
    state[variantSiteId] || state[draftSiteId] || state[siteId] || []
);

export const getShippingCategoriesByDraftOrCurrentSite = createSelector(
  getVariantSiteIdOrEmpty,
  SitesSelectors.getDraftSiteIdOrEmpty,
  SitesSelectors.getSelectedSiteIdOrEmpty,
  getShippingCategoryState,
  (variantSiteId, draftSiteId, siteId, state) =>
    state[variantSiteId] || state[draftSiteId] || state[siteId] || []
);

export const getShippingMethodsByDraftOrCurrentSite = createSelector(
  getVariantSiteIdOrEmpty,
  SitesSelectors.getDraftSiteIdOrEmpty,
  SitesSelectors.getSelectedSiteIdOrEmpty,
  getShippingMethodsState,
  (variantSiteId, draftSiteId, siteId, state) =>
    state[variantSiteId] || state[draftSiteId] || state[siteId] || []
);

export const getCustomShippingMethodsByDraftOrCurrentSite = createSelector(
  getVariantSiteIdOrEmpty,
  SitesSelectors.getDraftSiteIdOrEmpty,
  SitesSelectors.getSelectedSiteIdOrEmpty,
  getCustomShippingMethodsState,
  (variantSiteId, draftSiteId, siteId, customShippingMethods) =>
    customShippingMethods[variantSiteId] ||
    customShippingMethods[draftSiteId] ||
    customShippingMethods[siteId] ||
    []
);

export const getCustomBookingMethodsByCurrentSite = createSelector(
  SitesSelectors.getSelectedSiteIdOrEmpty,
  getCustomBookingMethodsState,
  (siteId, customBookingMethods) => customBookingMethods[siteId] ?? []
);

export const getShippingMethodsByCurrentSite = createSelector(
  SitesSelectors.getSelectedSiteIdOrEmpty,
  getShippingMethodsState,
  (siteId, state) => state[siteId] || []
);

const getRegionId = (state: RootState, id: string) => id;

export const getRegionById = createSelector(
  getRegionsByDraftOrCurrentSiteId,
  getRegionId,
  (regions, id) => regions.find(region => region.id === id)
);

export const getShippingCategoriesByCurrentRegionId = createSelector(
  getShippingCategoriesByDraftOrCurrentSite,
  getRegionById,
  (categories, region) =>
    region ? categories.filter(category => category.regionIds.includes(region.id)) : []
);

export const getShippingCategoriesByRegionIdForSite = createSelector(
  getShippingCategoriesByDraftOrCurrentSite,
  getRegionsByDraftOrCurrentSiteId,
  (categories, regions) =>
    regions.reduce(
      (whole, region) => ({
        ...whole,
        [region.id]: categories.filter(category => category.regionIds.includes(region.id)),
      }),
      {} as { [key: string]: ConfigShippingCategoryModel[] }
    )
);

export const getRegionsNames = createSelector(getRegionsByDraftOrCurrentSiteId, regions =>
  regions.map(region => region.name)
);

const getCategoryId = (state: RootState, id: string) => id;

export const getCategoryById = createSelector(
  getShippingCategoriesByDraftOrCurrentSite,
  getCategoryId,
  (shippingCategories, id) =>
    shippingCategories.find(category => category.id === id) || new ConfigShippingCategoryModel()
);

export const shouldShowSpinner = createSelector(getRegionsState, getState, (regions, state) => {
  return isEmpty(regions) || state.isFetching;
});

export const getCountries = (regionCountryConfig: ConfigRegionModel['regionCountryConfig']) => {
  if (regionCountryConfig) {
    const countries = (regionCountryConfig.included ||
      regionCountryConfig.excluded ||
      []) as string[];

    return sort(
      comparator((a, b) => a < b),
      countries.map(mapping.mapCountryCodeToName)
    );
  }
  return [];
};

export const isCategoryChainDelivery = createSelector(getCategoryById, category =>
  isNotNil(category.deliveryChain)
);

export const getCategoryChainDelivery = createSelector(
  getCategoryById,
  category => category.deliveryChain
);

export const getShippingMethodsFromChainDelivery = createSelector(
  getCategoryById,
  isCategoryChainDelivery,
  (category, isChain) =>
    isChain
      ? [
          category.deliveryChain!.firstMile.shippingMethod,
          ...category.deliveryChain!.lastMiles.map(entry => entry.shippingMethod),
        ]
      : []
);

export const getCategoriesWithMethodsFromRegionCarrierProducts = createSelector(
  getRegionById,
  getShippingCategoriesByCurrentRegionId,
  (region, categories) =>
    categories
      .map(category => ({
        ...category,
        shippingMethods: category.shippingMethods.filter(categoryMethod =>
          region!.carrierProducts.some(product => product.shippingMethod === categoryMethod)
        ),
      }))
      .sort((a, b) => {
        if (a.sortOrder === b.sortOrder) {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        }
        if ((a.sortOrder || 0) > (b.sortOrder || 0)) {
          return 1;
        }
        return -1;
      })
);

export const getRegionCarrierProducts = createSelector(getRegionById, region =>
  region ? region.carrierProducts : []
);

export const getUnusedCarrierProductsWithLimitedDeliveryTypes = createSelector(
  // Note that this selector should NOT be used for any booking related code
  // It filters out method based on 'shipping_config_status', booking has a separate 'booking_config_status'
  getRegionCarrierProducts,
  getShippingMethodsByDraftOrCurrentSite,
  (addedProducts, availableMethods) => {
    return availableMethods
      .map(method => {
        const duplicateProducts = addedProducts.filter(
          product => product.shippingMethod === method.id
        );

        return !isEmpty(duplicateProducts)
          ? {
              ...method,
              deliveryTypes: method.deliveryTypes.filter(
                dt =>
                  !duplicateProducts.some(duplicateProduct =>
                    duplicateProduct.deliveryTypes.includes(dt)
                  )
              ),
            }
          : method;
      })
      .filter(product => !isEmpty(product.deliveryTypes))
      .filter(
        product =>
          product.shippingConfigStatus !== 'METHOD_SHIPPING_STATUS_UNAVAILABLE' &&
          product.deprecatedOnListing !== 'DEPRECATED_METHOD_VISIBILITY_INVISIBLE'
      )
      .sort((a, b) => new Intl.Collator('en').compare(a.name || a.id, b.name || a.id));
  }
);

export const getShippingMethodsOptionsGroupedByCarrier = createSelector(
  getUnusedCarrierProductsWithLimitedDeliveryTypes,
  getCustomShippingMethodsByDraftOrCurrentSite,
  (carrierProducts, customShippingMethods) =>
    groupShippingMethodsOptionsByCarrier(customShippingMethods)(carrierProducts)
);

export const groupShippingMethodsOptionsByCarrier = (
  customShippingMethods: CustomShippingMethodModel[]
) => {
  const customCarrierProductsGroupName = 'Custom Carrier Products';
  return pipe(
    groupBy<ExtendedShippingMethodModel>(carrierProduct =>
      customShippingMethods.some(method => method.shippingMethod === carrierProduct.id)
        ? customCarrierProductsGroupName
        : carrierProduct.carrier || customCarrierProductsGroupName
    ),
    toPairs,
    sort((productsGroupA, productsGroupB) => {
      // We want custom carrier products to be easily found
      // so they're pushed to the end of the list
      if (prop(0, productsGroupA) === customCarrierProductsGroupName) {
        return 1;
      } else if (prop(0, productsGroupB) === customCarrierProductsGroupName) {
        return -1;
      } else {
        return new Intl.Collator('en').compare(prop(0, productsGroupA), prop(0, productsGroupB));
      }
    }),
    reduce<
      [string, ExtendedShippingMethodModel[]],
      { [groupKey: string]: { label: string; value: string; optionLabel: string }[] }
    >(
      (whole, [carrierName, cp]) => ({
        ...whole,
        [carrierName]: cp.map(product => ({
          value: product.id,
          label: product.name || product.id,
          optionLabel: `${product.name || product.id}`,
        })),
      }),
      {}
    )
  );
};

/**
 * Calculate how many regions are defined for each country.
 * 3 regions for Sweden and 1 region for Norway === [{ countryCode: 'SE', count: 5}, {countryCode: 'NO', count: 1}]
 */
export const getCountryCount = createSelector(getRegionsByDraftOrCurrentSiteId, regions => {
  const allCountries = regions.reduce((whole, region) => {
    if (region.regionPostalCodeConfig?.country) {
      return [...whole, region.regionPostalCodeConfig.country];
    }
    if (region.regionCountryConfig?.included) {
      return [...whole, ...region.regionCountryConfig.included];
    }
    return whole;
  }, []);

  return toPairs(countBy(identity, allCountries)).map(([countryCode, count]) => ({
    countryCode,
    count,
  }));
});

export const getCountryCountForSpecificCountry = (
  state: RootState,
  countryISO: string | undefined
) => {
  const regions = getRegionsByDraftOrCurrentSiteId(state);

  const allCountries = regions.reduce((whole, region) => {
    if (countryISO && region.regionPostalCodeConfig?.country === countryISO) {
      return [...whole, region.regionPostalCodeConfig.country];
    }
    if (countryISO && region.regionCountryConfig?.included?.includes(countryISO)) {
      return [...whole, countryISO];
    }
    return whole;
  }, []);

  return toPairs(countBy(identity, allCountries)).map(([countryCode, count]) => ({
    countryCode,
    count,
  }));
};

export const getRegionsByCountry = (state: RootState, countryISO: string | undefined) => {
  const regions = getRegionsByDraftOrCurrentSiteId(state);

  return !countryISO
    ? regions
    : regions.reduce((whole, region) => {
        if (region.regionPostalCodeConfig) {
          if (region.regionPostalCodeConfig!.country === countryISO) {
            return [...whole, region];
          }
        }

        if (region.regionCountryConfig?.excluded) {
          if (!region.regionCountryConfig?.excluded.includes(countryISO)) {
            return [...whole, region];
          }
        }

        if (region.regionCountryConfig?.included) {
          if (region.regionCountryConfig?.included.includes(countryISO)) {
            return [...whole, region];
          }
        }

        return whole;
      }, []);
};

export const getRegionIdsByCountry = (state: RootState, countryISO: string) => {
  const regions = getRegionsByDraftOrCurrentSiteId(state);
  const regionByCountry = regions.reduce((whole, region) => {
    if (region.regionPostalCodeConfig) {
      if (region.regionPostalCodeConfig!.country === countryISO) {
        return [...whole, region.id];
      }
    }

    if (region.regionCountryConfig?.included) {
      const regionCountryIncludedId = region.regionCountryConfig?.included.reduce(
        (reduced, code) => {
          if (code === countryISO) {
            return [...reduced, region.id];
          }
          return reduced;
        },
        []
      );
      return [...whole, ...regionCountryIncludedId];
    }
    return whole;
  }, []);

  return regionByCountry;
};

export const getRegionsAlphabetically = createSelector(
  getRegionsByDraftOrCurrentSiteId,
  regions => {
    return sortBy(compose(toLower, prop('name')), regions);
  }
);

export const getRegionsByCountryAlphabetically = createSelector(getRegionsByCountry, regions => {
  return sortBy(compose(toLower, prop('name')), regions);
});

export const filterCategoriesByCountry = createSelector(
  getRegionIdsByCountry,
  getShippingCategoriesByDraftOrCurrentSite,
  (regionsIdByCountry, categories) => {
    return categories.filter(category =>
      category.regionIds.some(regionId => regionsIdByCountry.includes(regionId))
    );
  }
);

const getCountry = (state: RootState, country: string) => country;

export const getCategoriesWithPreselectionOrder = createSelector(
  filterCategoriesByCountry,
  getCountry,
  (categories, country) => {
    return categories
      .filter(category => isNotNil(category.countrySettings?.[country]))
      .sort(
        (c1, c2) =>
          c1.countrySettings![country]!.preselectionOrder -
          c2.countrySettings![country]!.preselectionOrder
      );
  }
);

export const getCategoriesWithoutPreselectionOrder = createSelector(
  filterCategoriesByCountry,
  getCountry,
  (categories, country) => {
    return categories.filter(category => isNil(category.countrySettings?.[country]));
  }
);

export const getMethodsBasedOnRegionCarrierProducts = (
  state: RootState,
  categoryId: string,
  regionId: string
) => {
  const isChainDelivery = isCategoryChainDelivery(state, categoryId);
  const shippingMethodsForChainDelivery = getShippingMethodsFromChainDelivery(state, categoryId);
  const category = getCategoryById(state, categoryId);
  const region = getRegionById(state, regionId);

  return (isChainDelivery ? shippingMethodsForChainDelivery : category.shippingMethods).filter(
    method => region!.carrierProducts.map(product => product.shippingMethod).includes(method)
  );
};

export const getUnusedMethodOptionsBasedOnRegionCarrierProducts = (
  state: RootState,
  categoryId: string,
  regionId: string
) => {
  const carrierProductMethods = getMethodsBasedOnRegionCarrierProducts(state, categoryId, regionId);
  const shippingMethodsOptions = getShippingMethodsOptionsWithDeliveryTypes(state);
  const region = getRegionById(state, regionId);
  const category = getCategoryById(state, categoryId);

  return shippingMethodsOptions
    .filter(method =>
      region!.carrierProducts.map(product => product.shippingMethod).includes(method.value)
    )
    .filter(method => !carrierProductMethods.includes(method.value))
    .map(option => ({
      value: option.value,
      label: option.label || option.value,
      disabled: !option.deliveryTypes.includes(category.deliveryType),
    }));
};

export const getDeliveryTypesOptionsLimitedByMethods = createSelector(
  getMethodsBasedOnRegionCarrierProducts,
  methods => {
    return [
      { value: DeliveryTypeEnum.DELIVERY, label: DeliveryTypeEnum.DELIVERY },
      { value: DeliveryTypeEnum.PICKUP, label: DeliveryTypeEnum.PICKUP },
      { value: DeliveryTypeEnum.MAILBOX, label: DeliveryTypeEnum.MAILBOX },
      { value: DeliveryTypeEnum.INSTORE, label: DeliveryTypeEnum.INSTORE },
    ].map(option => ({ ...option, disabled: !isEmpty(methods) }));
  }
);

export const getChangesList = createSelector(getState, state => state.changes);

export const filterOutUndefinedChanges = createSelector(getChangesList, changes =>
  changes.filter(change => Object.values(change.command).some(command => isNotNil(command)))
);

const sortChanges = (changes: ConfigChangeModel[]) =>
  changes.sort((a, b) => new Date(b!.updatedAt).getTime() - new Date(a!.updatedAt).getTime());

const filterBy = (by: keyof CommandModel, changes: ConfigChangeModel[]) =>
  changes.filter(change => isNotNil(change.command[by]));

const filterCommandByTheSameMethod = (method: string, changes: ConfigChangeModel[]) =>
  changes.filter(change => change.command.updateRegionProduct!.method === method);

export const groupProductChangesByShippingMethod = (changes: ConfigChangeModel[]) => {
  const updateRegionProductChanges = filterBy('updateRegionProduct', changes);
  const shippingMethods = uniq(
    updateRegionProductChanges.map(change => change.command.updateRegionProduct!.method)
  );

  return shippingMethods.map(method =>
    pipe(
      partial(filterCommandByTheSameMethod, [method]),
      sortChanges,
      head
    )(updateRegionProductChanges)
  );
};

export const groupChangesByProperty = (changes: ConfigChangeModel[], getBy: keyof CommandModel) => {
  const groupByAndGetFirst = (func: (change: ConfigChangeModel) => string) => {
    return pipe(
      filterBy,
      groupBy(func),
      groupedChanges => Object.values(groupedChanges),
      groupedChangesValues => {
        return groupedChangesValues.map(groupedChanges => {
          return uniqBy(change => {
            if (!!change.command.updateRegionProduct) {
              return change.command.updateRegionProduct.method;
            }
            if (!!change.command.updateWarehouseCutoffTimes) {
              return change.command.updateWarehouseCutoffTimes.method;
            }
            return undefined;
          }, groupedChanges);
        });
      }
    )(getBy, changes);
  };

  switch (getBy) {
    case 'updateCountrySettingsRequest':
      return groupByAndGetFirst(change => change.command.updateCountrySettingsRequest!.country);
    case 'updateCarrierProductMappingRequest':
      return groupByAndGetFirst(change => change.command.updateCarrierProductMappingRequest!.id);
    case 'updateCategoryDetails':
      return groupByAndGetFirst(change => change.command.updateCategoryDetails!.categoryId);
    case 'updateRegionDetails':
      return groupByAndGetFirst(change => change.command.updateRegionDetails!.regionId);
    case 'updateWarehouseCutoffTimes':
      return groupByAndGetFirst(change => change.command.updateWarehouseCutoffTimes!.warehouseId);
    case 'updateCategoryTranslations':
      return groupByAndGetFirst(change => change.command.updateCategoryTranslations!.categoryId);
    case 'updateWarehouseDetails':
      return groupByAndGetFirst(change => change.command.updateWarehouseDetails!.warehouseId);
    case 'updateRegionProduct':
      return groupByAndGetFirst(change => change.command.updateRegionProduct!.regionId);
    case 'setWarehouseShippingDateAdjustmentRequest':
      return groupByAndGetFirst(
        change => change.command.setWarehouseShippingDateAdjustmentRequest!.warehouseId
      );
    case 'deleteWarehouseShippingDateAdjustmentRequest':
      return groupByAndGetFirst(
        change => change.command.deleteWarehouseShippingDateAdjustmentRequest!.warehouseId
      );
    case 'updateCategoryLabelRequest':
      return groupByAndGetFirst(change => change.command.updateCategoryLabelRequest!.labelId);
    case 'upsertCategoryLabelTranslationRequest':
      return groupByAndGetFirst(
        change =>
          `${change.command.upsertCategoryLabelTranslationRequest!.labelId} ${
            change.command.upsertCategoryLabelTranslationRequest!.locale
          }`
      );
    case 'deleteCategoryLabelTranslationRequest':
      return groupByAndGetFirst(
        change =>
          `${change.command.deleteCategoryLabelTranslationRequest!.labelId} ${
            change.command.deleteCategoryLabelTranslationRequest!.locale
          }`
      );
    case 'adjustCategoryLabelsOrderRequest':
      return groupByAndGetFirst(
        change => change.command.adjustCategoryLabelsOrderRequest!.categoryId
      );
    case 'updateDeliveryAddonRequest':
      return groupByAndGetFirst(change => change.command.updateDeliveryAddonRequest!.addonId);
    case 'upsertDeliveryAddonTranslationRequest':
      return groupByAndGetFirst(
        change =>
          `${change.command.upsertDeliveryAddonTranslationRequest!.addonId} ${
            change.command.upsertDeliveryAddonTranslationRequest!.locale
          }`
      );
    case 'deleteDeliveryAddonTranslationRequest':
      return groupByAndGetFirst(
        change =>
          `${change.command.deleteDeliveryAddonTranslationRequest!.addonId} ${
            change.command.deleteDeliveryAddonTranslationRequest!.locale
          }`
      );
    case 'adjustDeliveryAddonsOrderRequest':
      return groupByAndGetFirst(
        change => change.command.adjustDeliveryAddonsOrderRequest!.categoryId
      );
    case 'upsertCustomEventTypeTranslationRequest':
      return groupByAndGetFirst(
        change =>
          `${change.command.upsertCustomEventTypeTranslationRequest!.id} ${
            change.command.upsertCustomEventTypeTranslationRequest!.locale
          }`
      );
    case 'deleteCustomEventTypeTranslationRequest':
      return groupByAndGetFirst(
        change =>
          `${change.command.deleteCustomEventTypeTranslationRequest!.id} ${
            change.command.deleteCustomEventTypeTranslationRequest!.locale
          }`
      );
    default:
      return [];
  }
};

const getAllChangesByProperty = (changes: ConfigChangeModel[], getBy: keyof CommandModel) => {
  switch (getBy) {
    case 'addCategoryTagsRequest':
      return [changes.filter(change => change.command.addCategoryTagsRequest)];
    case 'removeCategoryTagsRequest':
      return [changes.filter(change => change.command.removeCategoryTagsRequest)];
    case 'deleteCategoryLabelRequest':
      return [changes.filter(change => change.command.deleteCategoryLabelRequest)];
    case 'createCategoryLabelRequest':
      return [changes.filter(change => change.command.createCategoryLabelRequest)];
    case 'deleteDeliveryAddonRequest':
      return [changes.filter(change => change.command.deleteDeliveryAddonRequest)];
    case 'createDeliveryAddonRequest':
      return [changes.filter(change => change.command.createDeliveryAddonRequest)];
    case 'deleteCategoryTranslations':
      return [changes.filter(change => change.command.deleteCategoryTranslations)];
    case 'createCategoryRequest':
      return [changes.filter(change => change.command.createCategoryRequest)];
    case 'deleteCategoryRequest':
      return [changes.filter(change => change.command.deleteCategoryRequest)];
    case 'createRegionRequest':
      return [changes.filter(change => change.command.createRegionRequest)];
    case 'deleteRegionRequest':
      return [changes.filter(change => change.command.deleteRegionRequest)];
    case 'createRegionProductRequest':
      return [changes.filter(change => change.command.createRegionProductRequest)];
    case 'deleteRegionProductRequest':
      return [changes.filter(change => change.command.deleteRegionProductRequest)];
    case 'createWarehouseRequest':
      return [changes.filter(change => change.command.createWarehouseRequest)];
    case 'deleteWarehouseRequest':
      return [changes.filter(change => change.command.deleteWarehouseRequest)];
    case 'createWarehouseCutoffTimesRequest':
      return [changes.filter(change => change.command.createWarehouseCutoffTimesRequest)];
    case 'updateWidgetConfigurationRequest':
      return [changes.filter(change => change.command.updateWidgetConfigurationRequest)];
    case 'deleteWarehouseCutoffTimesRequest':
      return [changes.filter(change => change.command.deleteWarehouseCutoffTimesRequest)];
    case 'upsertCustomEventTypeRequest':
      return [changes.filter(change => change.command.upsertCustomEventTypeRequest)];
    case 'upsertCarrierProductSettingsRequest':
      return [changes.filter(change => change.command.upsertCarrierProductSettingsRequest)];
    case 'deleteCustomEventTypeRequest':
      return [changes.filter(change => change.command.deleteCustomEventTypeRequest)];
    case 'configureInternalEventTypeRequest':
      return [changes.filter(change => change.command.configureInternalEventTypeRequest)];
    case 'deleteCarrierProductMappingRequest':
      return [changes.filter(change => change.command.deleteCarrierProductMappingRequest)];
    case 'createCarrierProductMappingRequest':
      return [changes.filter(change => change.command.createCarrierProductMappingRequest)];
    case 'updateDeliveryUpsellWidgetConfigurationRequest':
      return [
        changes.filter(change => change.command.updateDeliveryUpsellWidgetConfigurationRequest),
      ];
    case 'createDeliveryUpsellWidgetCountryConfigurationRequest':
      return [
        changes.filter(
          change => change.command.createDeliveryUpsellWidgetCountryConfigurationRequest
        ),
      ];
    case 'updateDeliveryUpsellWidgetCountryConfigurationRequest':
      return [
        changes.filter(
          change => change.command.updateDeliveryUpsellWidgetCountryConfigurationRequest
        ),
      ];
    case 'updateFaqWidgetDefaultConfigurationRequest':
      return [changes.filter(change => change.command.updateFaqWidgetDefaultConfigurationRequest)];
    case 'upsertFaqWidgetCountryConfigurationRequest':
      return [changes.filter(change => change.command.upsertFaqWidgetCountryConfigurationRequest)];
    case 'updateProductPageWidgetDefaultConfigurationRequest':
      return [
        changes.filter(change => change.command.updateProductPageWidgetDefaultConfigurationRequest),
      ];
    case 'upsertProductPageWidgetCountryConfigurationRequest':
      return [
        changes.filter(change => change.command.upsertProductPageWidgetCountryConfigurationRequest),
      ];
    case 'updateReceiptWidgetDefaultConfigurationRequest':
      return [
        changes.filter(change => change.command.updateReceiptWidgetDefaultConfigurationRequest),
      ];
    case 'upsertReceiptWidgetCountryConfigurationRequest':
      return [
        changes.filter(change => change.command.upsertReceiptWidgetCountryConfigurationRequest),
      ];
    default:
      return [];
  }
};

type WidgetChange = Exclude<ConfigChangeModel, 'command'> & {
  command: PartiallyRequired<ConfigChangeModel['command'], 'updateWidgetConfigurationRequest'>;
};
const groupWidgetConfigurationChangesByFeature = (changes: ConfigChangeModel[]) => {
  const widgetChanges = changes.filter(
    change => change.command.updateWidgetConfigurationRequest
  ) as unknown as WidgetChange[];
  const groupFunc = (change: WidgetChange) => {
    const features = change.command.updateWidgetConfigurationRequest.widgetConfiguration.features;
    const style = change.command.updateWidgetConfigurationRequest.widgetConfiguration.style;
    if (typeof style?.accentColor1 === 'string') {
      return 'accentColor1';
    }
    if (typeof style?.accentColor2 === 'string') {
      return 'accentColor2';
    }
    if (!features) {
      return '';
    }
    switch (true) {
      case features.autocompleteStreet !== undefined:
        return 'autocompleteStreet';
      case features.disableAddressForm !== undefined:
        return 'disableAddressForm';
      case features.displayLocationType !== undefined:
        return 'displayLocationType';
      case features.displayUpfrontAddress !== undefined:
        return 'displayUpfrontAddress';
      case features.dontRequireStreetOnAddressFields !== undefined:
        return 'dontRequireStreetOnAddressFields';
      case features.doorCode !== undefined:
        return 'doorCode';
      case features.hideStreetOnAddressFields !== undefined:
        return 'hideStreetOnAddressFields';
      case features.hideToc !== undefined:
        return 'hideToc';
      case features.newSearchAddress !== undefined:
        return 'newSearchAddress';
      case features.showCarrierIcons !== undefined:
        return 'showCarrierIcons';
      case features.showCityOnAddressFields !== undefined:
        return 'showCityOnAddressFields';
      case features.showMap !== undefined:
        return 'showMap';
      case features.showRegion !== undefined:
        return 'showRegion';
      case features.showShippingPriceOnServicePointsView !== undefined:
        return 'showShippingPriceOnServicePointsView';
      case features.showShippingCategoriesBeforeAddress !== undefined:
        return 'showShippingCategoriesBeforeAddress';
      case features.showStreetOnAddressModule !== undefined:
        return 'showStreetOnAddressModule';
      case features.requireRegion !== undefined:
        return 'requireRegion';
      case features.requireStreetOnAddressModule !== undefined:
        return 'requireStreetOnAddressModule';
      case features.showCityOnAddressModule !== undefined:
        return 'showCityOnAddressModule';
      case features.enableWidgetBorder !== undefined:
        return 'enableWidgetBorder';
      case features.enableTransparentBackground !== undefined:
        return 'enableTransparentBackground';
      case features.enableAccentColorForWidgetElements !== undefined:
        return 'enableAccentColorForWidgetElements';
      case features.showSlogan !== undefined:
        return 'showSlogan';
      default:
        return '';
    }
  };

  return pipe(
    groupBy(groupFunc),
    groupedChanges => Object.values(groupedChanges),
    groupedChangesValues => groupedChangesValues.map(ch => [head(ch)])
  )(widgetChanges);
};

const getCreateWidgetCountryConfigurationRequests = (changes: ConfigChangeModel[]) => {
  return changes.filter(change => isNotNil(change.command.createWidgetCountryConfigurationRequest));
};

const getCountryFromChange = (change: ConfigChangeModel) =>
  change.command.updateWidgetCountryConfigurationRequest!.country;

const getFeatureFromChange = (change: ConfigChangeModel) => {
  const { features } = change.command.updateWidgetCountryConfigurationRequest!;
  if (!features) {
    return null; // happens when carrierLogos have been changed
  }
  const [featureKey] = Object.entries(features).find(
    ([_, featureValue]) => featureValue !== undefined
  ) ?? [''];
  return featureKey;
};

const getUpdateWidgetCountryConfigurationRequests = (changes: ConfigChangeModel[]) => {
  const relevantChanges = changes.filter(change =>
    isNotNil(change.command.updateWidgetCountryConfigurationRequest)
  );
  const latestChanges = relevantChanges.reduce((latestChangesAcc: any, change) => {
    const country = getCountryFromChange(change);
    const feature = getFeatureFromChange(change);
    if (feature) {
      return {
        ...latestChangesAcc,
        [country]: latestChangesAcc[country]
          ? {
              ...latestChangesAcc[country],
              [feature]: latestChangesAcc[country][feature] ?? change,
            }
          : { [feature]: change },
      };
    } else {
      return {
        ...latestChangesAcc,
        [country]: latestChangesAcc[country]
          ? {
              ...latestChangesAcc[country],
              carrierLogos: latestChangesAcc[country].carrierLogos ?? change,
            }
          : { carrierLogos: change },
      };
    }
  }, {});
  return flatten(
    Object.values(latestChanges).map((latestCountryChanges: any) =>
      Object.values(latestCountryChanges)
    )
  );
};

export const getLatestChanges = createSelector(filterOutUndefinedChanges, changes => {
  return pipe(
    reject(isNil),
    sortChanges
  )(
    flatten([
      groupChangesByProperty(changes, 'updateCountrySettingsRequest'),
      groupChangesByProperty(changes, 'updateRegionDetails'),
      groupChangesByProperty(changes, 'updateRegionProduct'),
      groupChangesByProperty(changes, 'updateCategoryDeliveryTime'),
      groupChangesByProperty(changes, 'updateCategoryTranslations'),
      groupChangesByProperty(changes, 'updateCategoryDetails'),
      groupChangesByProperty(changes, 'updateWarehouseCutoffTimes'),
      groupChangesByProperty(changes, 'updateWarehouseDetails'),
      groupChangesByProperty(changes, 'setWarehouseShippingDateAdjustmentRequest'),
      groupChangesByProperty(changes, 'deleteWarehouseShippingDateAdjustmentRequest'),
      groupChangesByProperty(changes, 'updateCategoryLabelRequest'),
      groupChangesByProperty(changes, 'updateDeliveryAddonRequest'),
      groupChangesByProperty(changes, 'upsertCategoryLabelTranslationRequest'),
      groupChangesByProperty(changes, 'upsertDeliveryAddonTranslationRequest'),
      groupChangesByProperty(changes, 'deleteCategoryLabelTranslationRequest'),
      groupChangesByProperty(changes, 'deleteDeliveryAddonTranslationRequest'),
      groupChangesByProperty(changes, 'adjustCategoryLabelsOrderRequest'),
      groupChangesByProperty(changes, 'adjustDeliveryAddonsOrderRequest'),
      groupChangesByProperty(changes, 'updateCarrierProductMappingRequest'),
      getAllChangesByProperty(changes, 'deleteCategoryTranslations'),
      getAllChangesByProperty(changes, 'createCategoryRequest'),
      getAllChangesByProperty(changes, 'addCategoryTagsRequest'),
      getAllChangesByProperty(changes, 'removeCategoryTagsRequest'),
      getAllChangesByProperty(changes, 'deleteCategoryLabelRequest'),
      getAllChangesByProperty(changes, 'deleteDeliveryAddonRequest'),
      getAllChangesByProperty(changes, 'createCategoryLabelRequest'),
      getAllChangesByProperty(changes, 'createDeliveryAddonRequest'),
      getAllChangesByProperty(changes, 'deleteCategoryRequest'),
      getAllChangesByProperty(changes, 'createRegionRequest'),
      getAllChangesByProperty(changes, 'deleteRegionRequest'),
      getAllChangesByProperty(changes, 'createRegionProductRequest'),
      getAllChangesByProperty(changes, 'deleteRegionProductRequest'),
      getAllChangesByProperty(changes, 'createWarehouseRequest'),
      getAllChangesByProperty(changes, 'deleteWarehouseRequest'),
      getAllChangesByProperty(changes, 'createWarehouseCutoffTimesRequest'),
      getAllChangesByProperty(changes, 'deleteWarehouseCutoffTimesRequest'),
      getAllChangesByProperty(changes, 'upsertCustomEventTypeRequest'),
      getAllChangesByProperty(changes, 'deleteCustomEventTypeRequest'),
      getAllChangesByProperty(changes, 'deleteCarrierProductMappingRequest'),
      getAllChangesByProperty(changes, 'createCarrierProductMappingRequest'),
      groupChangesByProperty(changes, 'upsertCustomEventTypeTranslationRequest'),
      groupChangesByProperty(changes, 'deleteCustomEventTypeTranslationRequest'),
      getAllChangesByProperty(changes, 'configureInternalEventTypeRequest'),
      getAllChangesByProperty(changes, 'upsertCarrierProductSettingsRequest'),
      getAllChangesByProperty(changes, 'updateDeliveryUpsellWidgetConfigurationRequest'),
      getAllChangesByProperty(changes, 'createDeliveryUpsellWidgetCountryConfigurationRequest'),
      getAllChangesByProperty(changes, 'updateDeliveryUpsellWidgetCountryConfigurationRequest'),
      getAllChangesByProperty(changes, 'updateFaqWidgetDefaultConfigurationRequest'),
      getAllChangesByProperty(changes, 'upsertFaqWidgetCountryConfigurationRequest'),
      getAllChangesByProperty(changes, 'updateProductPageWidgetDefaultConfigurationRequest'),
      getAllChangesByProperty(changes, 'upsertProductPageWidgetCountryConfigurationRequest'),
      getAllChangesByProperty(changes, 'updateReceiptWidgetDefaultConfigurationRequest'),
      getAllChangesByProperty(changes, 'upsertReceiptWidgetCountryConfigurationRequest'),
      groupWidgetConfigurationChangesByFeature(changes),
      getCreateWidgetCountryConfigurationRequests(changes),
      getUpdateWidgetCountryConfigurationRequests(changes),
    ])
  );
});

const extractEmail = (userSummary?: UserSummaryModel): string | undefined =>
  userSummary && (userSummary.email || (userSummary.isSuperuser ? 'superuser' : 'unknown'));

export const getChangesWithEmails = createSelector(
  getLatestChanges,
  usersSelectors.getUserSummaries,
  (changes, userSummaries) =>
    changes.map(ch =>
      Object.assign({}, ch, {
        email: extractEmail(userSummaries.find(us => us.id === ch.userId)),
      })
    )
);

export const isShippingMethodConfigured = (state: RootState, shippingMethod: string) => {
  const statusesForConfiguredMethods: ExtendedShippingMethodModel['shippingConfigStatus'][] = [
    'METHOD_SHIPPING_STATUS_CONFIGURED',
    'CREATE_REGION_PRODUCT_STATUS_CONFIGURED',
    'CREATE_REGION_PRODUCT_STATUS_ALREADY_CONFIGURED',
  ];

  const statusForMethod =
    getShippingMethodsByDraftOrCurrentSite(state).find(method => method.id === shippingMethod)
      ?.shippingConfigStatus || 'METHOD_SHIPPING_STATUS_CONFIGURED';

  return statusesForConfiguredMethods.includes(statusForMethod);
};

export const getShippingMethodsOptions = createSelector(getShippingMethodsByCurrentSite, methods =>
  methods.map(sm => ({ value: sm.id, label: sm.name }))
);

export const getShippingMethodsDictByDraftOrCurrentSite = createSelector(
  getShippingMethodsByDraftOrCurrentSite,
  methods =>
    methods.reduce<{ [key: string]: string }>(
      (acc, value) => ((acc[value.id] = value.name || value.id), acc),
      {}
    )
);

export const getConfiguredShippingMethodsForShipping = createSelector(
  getShippingMethodsByCurrentSite,
  methods =>
    methods.filter(method => method.shippingConfigStatus === 'METHOD_SHIPPING_STATUS_CONFIGURED')
);

export const getConfiguredShippingMethodsForBooking = createSelector(
  getShippingMethodsByCurrentSite,
  methods =>
    methods.filter(method => method.bookingConfigStatus === 'METHOD_BOOKING_STATUS_CONFIGURED')
);

export const getShippingMethodNamesConfiguredForBooking = createSelector(
  getConfiguredShippingMethodsForBooking,
  shippingMethods => shippingMethods.map(sm => sm.id)
);

export const getRealBookingMethodsGroupedByCarrier = createSelector(
  getConfiguredShippingMethodsForBooking,
  configuredShippingMethodsForBooking =>
    groupBookingMethodsByCarrier(configuredShippingMethodsForBooking)
);

const customBookingMethodsCarrierName = 'Custom Booking Methods';

export const groupBookingMethodsByCarrier = pipe(
  groupBy<ExtendedShippingMethodModel>(method => method.carrier || customBookingMethodsCarrierName),
  toPairs,
  sort((productsGroupA, productsGroupB) => {
    // We want custom booking methods to be easily found
    // so they're pushed to the end of the list
    if (prop(0, productsGroupA) === customBookingMethodsCarrierName) {
      return 1;
    } else if (prop(0, productsGroupB) === customBookingMethodsCarrierName) {
      return -1;
    } else {
      return new Intl.Collator('en').compare(prop(0, productsGroupA), prop(0, productsGroupB));
    }
  }),
  reduce<
    [string, ExtendedShippingMethodModel[]],
    { [groupKey: string]: { label: string; value: string; optionLabel: string }[] }
  >(
    (whole, [carrierName, cp]) => ({
      ...whole,
      [carrierName]: cp
        .sort((a, b) => Intl.Collator('en').compare(`${a.name || a.id}`, `${b.name || b.id}`))
        .map(product => ({
          value: product.id,
          label: product.name || product.id,
          optionLabel: `${product.name || product.id}`,
        })),
    }),
    {}
  )
);

export const getShippingMethodForShipmentById = (state: RootState, itemId: string) => {
  const shipmentShippingMethod =
    state.shipments.byId[itemId]?.parcels?.[0]?.deliveries?.[0]?.shippingMethod ?? '';
  const shippingMethods = getShippingMethodsByCurrentSite(state);
  return shippingMethods.find(sm => sm.id === shipmentShippingMethod);
};

export const getShippingMethodForShipment = (state: RootState, shippingMethod: string) => {
  const shippingMethods = getShippingMethodsByCurrentSite(state);
  return shippingMethods.find(sm => sm.id === shippingMethod);
};

export const getShippingMethodsOptionsWithDeliveryTypes = createSelector(
  getShippingMethodsByDraftOrCurrentSite,
  methods => methods.map(sm => ({ value: sm.id, label: sm.name, deliveryTypes: sm.deliveryTypes }))
);

export const getShippingMethodsDict = createSelector(getShippingMethodsByCurrentSite, methods =>
  methods.reduce<{ [key: string]: string }>(
    (acc, value) => ((acc[value.id] = value.name || value.id), acc),
    {}
  )
);

export const getShippingMethodsParcelTypes = createSelector(
  getShippingMethodsByCurrentSite,
  methods => {
    return reduce(
      (acc: { [key: string]: ParcelTypeEnum }, value: ConfigShippingMethodModel) => ({
        ...acc,
        [value.id]: value.parcelType,
      }),
      {},
      methods
    );
  }
);

export const getPrivateKeyFromConfig = createSelector(getState, state => state.privateKey);

/**
 * Get all regions given category is linked to.
 */
export const getCategoryRegions = createSelector(
  getCategoryById,
  getRegionsByDraftOrCurrentSiteId,
  (category, regions) => {
    return regions.filter(region => category.regionIds.includes(region.id));
  }
);

/**
 * Find all countries given category is used in.
 */
export const getCategoryCountries = createSelector(
  getCategoryById,
  getRegionsByDraftOrCurrentSiteId,
  (category, regions) => {
    const categoryRegions = regions.filter(region => category.regionIds.includes(region.id));
    const categoryCountries = pipe(
      map((region: ConfigRegionModel) => {
        if (region.regionType === 'zipcode' && region.regionPostalCodeConfig?.country) {
          return [region.regionPostalCodeConfig.country];
        }
        if (region.regionType === 'country' && region.regionCountryConfig?.included) {
          return region.regionCountryConfig.included;
        }
        return [];
      }),
      flatten,
      uniq
    )(categoryRegions);
    return categoryCountries;
  }
);

/**
 * For given categoryId, gather Preselection order for every country this category is used in.
 */
export const getCategoryPreselectionOrder = (
  state: RootState,
  categoryId: string
): CategoryPreselectionOrder => {
  const categoryCountries = getCategoryCountries(state, categoryId);
  const categoryPreselectionOrder = categoryCountries.map(country => ({
    country,
    sorted: getCategoriesWithPreselectionOrder(state, country),
    unsorted: getCategoriesWithoutPreselectionOrder(state, country),
  }));
  return categoryPreselectionOrder;
};

export const getCountriesGroupedByGeoregionForDefinedRegions = (state: RootState) => {
  const countriesUsedInRegions = getRegionsByDraftOrCurrentSiteId(state).reduce((all, region) => {
    if (region.regionCountryConfig?.included) {
      return [...all, ...region.regionCountryConfig.included];
    }
    if (region.regionPostalCodeConfig?.country) {
      return [...all, region.regionPostalCodeConfig.country];
    }
    return all;
  }, []);
  const filteredCountries = getAllCountries().filter(country =>
    countriesUsedInRegions?.includes(country.code)
  );
  const groupedCountries = groupCountriesByRegion(filteredCountries);

  return groupedCountries;
};
