import * as React from 'react';
import { connect } from 'react-redux';

import { RootState } from '@src/modules';
import { authSelectors } from '@src/modules/auth';
import {
  shipmentNotificationsActions,
  shipmentNotificationsSelectors,
} from '@src/modules/shipment-notifications';
import { NotificationList } from './components';

const mapStateToProps = (state: RootState) => ({
  userId: authSelectors.getAuthUserId(state),
  siteId: state.sites.selectedSiteId,
  notifications: shipmentNotificationsSelectors.getNotificationsSorted(state),
  thereIsNoUnreadNotifications: shipmentNotificationsSelectors.thereIsNoUnreadNotifications(state),
  isLoading: state.shipmentNotifications.notifications.isFetching,
});
const dispatchProps = {
  markShipmentNotificationAsRead: shipmentNotificationsActions.markNotificationsAsReadRequest,
  listShipmentNotifications: shipmentNotificationsActions.listNotificationsRequest,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

class Component extends React.Component<Props> {
  handleRefreshNotifications = () => {
    const { listShipmentNotifications, siteId, userId } = this.props;
    if (siteId) {
      listShipmentNotifications({
        siteId,
        userId,
      });
    }
  };

  handleMarkAsRead = (id?: string) => {
    const { markShipmentNotificationAsRead, siteId, userId, notifications } = this.props;
    markShipmentNotificationAsRead({
      siteId: siteId!,
      userId,
      ids: id
        ? [id]
        : notifications
            .filter(notification => !notification.readAt)
            .map(notification => notification.id),
    });
  };

  render() {
    const { notifications, thereIsNoUnreadNotifications, isLoading } = this.props;
    return (
      <NotificationList
        notifications={notifications}
        markAsRead={this.handleMarkAsRead}
        refreshNotifications={this.handleRefreshNotifications}
        thereIsNoUnreadNotifications={thereIsNoUnreadNotifications}
        isLoading={isLoading}
      />
    );
  }
}

export const NotificationsListContainer = connect(mapStateToProps, dispatchProps)(Component);
