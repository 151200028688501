import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';

import {
  ShipmentSummaryModel,
  TransportOrderListFiltersModel,
  TransportOrderModel,
} from '../../models';
import { Reducer } from '../../modules';
import { appActions } from '../app';
import { shipmentsActions } from '../shipments';

import {
  CLEAR_ACTIVE_TRANSPORT_ORDER_FILTERS,
  CLEAR_TRANSPORT_ORDERS_LIST,
  CREATE_TRANSPORT_ORDER_ERROR,
  CREATE_TRANSPORT_ORDER_REQUEST,
  CREATE_TRANSPORT_ORDER_SUCCESS,
  GET_TRANSPORT_ORDER_ERROR,
  GET_TRANSPORT_ORDER_LIST_ERROR,
  GET_TRANSPORT_ORDER_LIST_REQUEST,
  GET_TRANSPORT_ORDER_LIST_SUCCESS,
  GET_TRANSPORT_ORDER_REQUEST,
  GET_TRANSPORT_ORDER_SUCCESS,
  SET_ACTIVE_TRANSPORT_ORDER_FILTERS,
  UPDATE_TRANSPORT_ORDER_ERROR,
  UPDATE_TRANSPORT_ORDER_LIST_PAGINATION,
  UPDATE_TRANSPORT_ORDER_REQUEST,
  UPDATE_TRANSPORT_ORDER_SUCCESS,
  GET_SHIPMENTS_SUMMARIES_LIST_BY_TOS_SUCCESS,
  GET_SHIPMENTS_SUMMARIES_LIST_BY_TOS_ERROR,
} from './types';

// STATE
export type State = {
  readonly isFetching: boolean;
  readonly error: string | null;
  readonly listIds: string[];
  readonly byId: { [id: string]: TransportOrderModel | undefined };
  readonly count: number;
  readonly filters: TransportOrderListFiltersModel | null;
  readonly paginationNumber: number;
  readonly shipmentSummariesByTosId: { [id: string]: ShipmentSummaryModel[] };
};

const isFetching: Reducer<State['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case GET_TRANSPORT_ORDER_LIST_REQUEST:
    case GET_TRANSPORT_ORDER_REQUEST:
    case CREATE_TRANSPORT_ORDER_REQUEST:
    case UPDATE_TRANSPORT_ORDER_REQUEST:
      return true;

    case GET_TRANSPORT_ORDER_LIST_SUCCESS:
    case GET_TRANSPORT_ORDER_LIST_ERROR:
    case GET_TRANSPORT_ORDER_SUCCESS:
    case GET_TRANSPORT_ORDER_ERROR:
    case CREATE_TRANSPORT_ORDER_SUCCESS:
    case CREATE_TRANSPORT_ORDER_ERROR:
    case UPDATE_TRANSPORT_ORDER_SUCCESS:
    case UPDATE_TRANSPORT_ORDER_ERROR:
      return false;

    default:
      return state;
  }
};

const error: Reducer<State['error']> = (state = null, action) => {
  switch (action.type) {
    case GET_TRANSPORT_ORDER_LIST_ERROR:
    case GET_TRANSPORT_ORDER_ERROR:
    case CREATE_TRANSPORT_ORDER_ERROR:
    case UPDATE_TRANSPORT_ORDER_ERROR:
      return action.payload;

    case GET_TRANSPORT_ORDER_LIST_REQUEST:
    case GET_TRANSPORT_ORDER_REQUEST:
    case CREATE_TRANSPORT_ORDER_REQUEST:
    case UPDATE_TRANSPORT_ORDER_REQUEST:
    case getType(appActions.resetErrors):
      return null;

    default:
      return state;
  }
};

const filters: Reducer<State['filters']> = (
  state = { sources: ['som', 'cos', 'cos:v2', 'mad', 'tracking'], createdAtRange: [] },
  action
) => {
  switch (action.type) {
    case SET_ACTIVE_TRANSPORT_ORDER_FILTERS:
      return action.payload;

    case CLEAR_ACTIVE_TRANSPORT_ORDER_FILTERS:
      return null;

    default:
      return state;
  }
};

const listIds: Reducer<State['listIds']> = (state = [], action) => {
  switch (action.type) {
    case GET_TRANSPORT_ORDER_LIST_SUCCESS:
      return action.payload.orders.map(item => item.id);

    case CLEAR_TRANSPORT_ORDERS_LIST:
      return [];

    default:
      return state;
  }
};

const byId: Reducer<State['byId']> = (state = {}, action) => {
  switch (action.type) {
    case GET_TRANSPORT_ORDER_LIST_SUCCESS:
      return action.payload.orders.reduce<State['byId']>(
        (result, item) => ((result[item.id] = item), result),
        {}
      );

    case GET_TRANSPORT_ORDER_SUCCESS:
    case CREATE_TRANSPORT_ORDER_SUCCESS:
    case UPDATE_TRANSPORT_ORDER_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    case CLEAR_TRANSPORT_ORDERS_LIST:
      return {};

    default:
      return state;
  }
};

const count: Reducer<State['count']> = (state = 0, action) => {
  switch (action.type) {
    case GET_TRANSPORT_ORDER_LIST_SUCCESS:
      return action.payload.count;

    default:
      return state;
  }
};

const paginationNumber: Reducer<State['paginationNumber']> = (state = 1, action) => {
  switch (action.type) {
    case UPDATE_TRANSPORT_ORDER_LIST_PAGINATION:
      return action.payload;
    default:
      return state;
  }
};

const shipmentSummariesByTosId: Reducer<State['shipmentSummariesByTosId']> = (
  state = {},
  action
) => {
  switch (action.type) {
    case GET_SHIPMENTS_SUMMARIES_LIST_BY_TOS_SUCCESS:
      return { [action.payload.tosId]: action.payload.shipments };

    case getType(shipmentsActions.createShipmentSuccess):
      const {
        tosId,
        id,
        customerInfo,
        createdAt,
        shippingDate = '',
        addressFrom,
        addressTo,
        parcels,
      } = action.payload;
      const prevState = state[tosId] ?? [];

      return {
        ...state,
        [tosId]: [
          ...prevState,
          {
            id,
            customerInfo,
            createdAt,
            shippingDate,
            shippingMethod: parcels?.[0].deliveries?.[0].shippingMethod ?? '',
            addressFrom,
            addressTo,
            status: 'CREATED',
            trackingNumbers: [],
          },
        ],
      };

    case GET_SHIPMENTS_SUMMARIES_LIST_BY_TOS_ERROR:
      return {};

    default:
      return state;
  }
};

const reducer: Reducer<State> = combineReducers({
  isFetching,
  error,
  listIds,
  byId,
  count,
  filters,
  paginationNumber,
  shipmentSummariesByTosId,
});

export default reducer;
