import { FieldState, FormState, Validator } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Input } from '../controls';
import { withFieldStateInput, withFormItem } from '../decorators';

const InputField = withFormItem(withFieldStateInput(Input));

type IFormState = FormState<{
  inputFieldState: FieldState<string>;
}>;

interface IProps {
  formState: IFormState;
  label: string;
  validators?: Validator<string>[];
  disabled?: boolean;
  autoFocus?: boolean;
  required?: boolean;
}

@observer
export class InputFieldForm extends React.Component<IProps, {}> {
  static createFormState = (externalId: string = ''): IFormState =>
    new FormState({
      inputFieldState: new FieldState(externalId),
    });

  UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const validators = this.props.validators;
    const nextValidators = nextProps.validators;
    if (validators && nextValidators && validators !== nextValidators) {
      nextProps.formState.$.inputFieldState.validators(...validators);
    }
  }

  componentDidMount() {
    this.props.formState.$.inputFieldState.validators(...(this.props.validators || []));
  }

  render() {
    const { formState, label, ...restProps } = this.props;

    return (
      <InputField
        labelCol={{ span: 24 }}
        style={{ width: '200px' }}
        label={label}
        fieldState={formState.$.inputFieldState}
        error={formState.$.inputFieldState.error}
        {...restProps}
      />
    );
  }
}
