import { DeleteOutlined } from '@ant-design/icons';
import { withFormItem } from '@src/decorators';
import { FormattedMessage } from '@src/i18n';
import { groupBookingMethodsByCarrier } from '@src/modules/config/selectors';
import { ShipmentsSelectors } from '@src/modules/shipments';
import { SitesSelectors } from '@src/modules/sites';
import { siwSelectors } from '@src/modules/siw';
import { services } from '@src/services';
import { useMasterSiteId } from '@src/utils/hooks';
import { Tooltip } from 'antd';
import { FieldState, FormState } from 'formstate';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { equals, pathOr } from 'ramda';
import * as React from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { stylesheet } from 'typestyle';
import { MetadataInputs } from '.';
import { Button, Card, Select, Spinner, Switch } from '../../../controls';
import {
  CarrierAddonsForm,
  CarrierAddonsFormState,
  DateSelectorForm,
  DateSelectorFormState,
  ShippingMethodSelectorForm,
  ShippingMethodSelectorFormState,
} from '../../../forms';
import {
  AddonModel,
  CarrierAddonsModel,
  ParcelTypeEnum,
  ShipmentModel,
  TemplateModel,
} from '../../../models';
import { RootState } from '../../../modules';
import { stringToDecimal } from '../../../utils/currency';
import { ShippingMethodMetadata, shippingMethodMetadataSelector } from '../shipping-method-options';
import {
  ShipmentOptionsDetails,
  ShipmentOptionsDetailsFormState,
} from './shipment-options-details';

const SelectField = withFormItem(Select);
const SwitchField = withFormItem(Switch);

type OwnProps = {
  formState: ShipmentOptionsFormState;
  disabled?: boolean;
  autoFocus?: boolean;
  showAddons?: boolean;
  carrierAddons: CarrierAddonsModel[];
  isPreorder?: boolean;
  changePreorder?: (b: boolean) => void;
  hidePickupPointSelection?: boolean;
  onOpenWidgetButtonClick?: () => void;
  shipmentId?: string;
  showCustomBookingMethods: boolean;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  siteId: SitesSelectors.getSelectedSiteIdOrEmpty(state),
  getShippingMetadata: shippingMethodMetadataSelector(state),
  location: siwSelectors.getLocationData(state),
  isFetchingSession: siwSelectors.isFetching(state),
  shippingType: ShipmentsSelectors.getShippingTypeForShipment(state, ownProps.shipmentId),
});

export class ShipmentOptionsFormState extends FormState<{
  valueFieldState: FieldState<number | undefined>;
  shippingDateSelectorFormState: DateSelectorFormState;
  shippingMethodSelectorFormState: ShippingMethodSelectorFormState;
  metadataFieldState: FieldState<{ [name: string]: string }>;
  carrierAddonsFormState: CarrierAddonsFormState;
  shipmentOptionsDetailsFormState: ShipmentOptionsDetailsFormState;
  pickupPointFormState: FieldState<{ locationName: string; locationExternalId: string }>;
}> {
  static create = (
    {
      shipmentValue,
      shippingMethod,
      shippingDate,
      availableAddons,
      enabledAddons = [],
      locationName,
      locationExternalId,
      meta,
      courierInstructions,
      doorCode,
    }: {
      shipmentValue?: ShipmentModel['shipmentValue'];
      shippingDate?: ShipmentModel['shippingDate'];
      shippingMethod?: string;
      availableAddons: CarrierAddonsModel[];
      enabledAddons?: AddonModel[];
      locationName: string;
      locationExternalId: string;
      meta?: ShipmentModel['meta'];
      courierInstructions?: string;
      doorCode?: string;
    } = {
      shipmentValue: '',
      availableAddons: [],
      enabledAddons: [],
      locationName: '',
      locationExternalId: '',
    }
  ): ShipmentOptionsFormState => {
    return new FormState({
      valueFieldState: new FieldState(shipmentValue ? stringToDecimal(shipmentValue) : undefined),
      shippingDateSelectorFormState: DateSelectorFormState.create(shippingDate),
      shippingMethodSelectorFormState: ShippingMethodSelectorFormState.create(shippingMethod),
      metadataFieldState: new FieldState({
        ...meta,
      }),
      shipmentOptionsDetailsFormState: ShipmentOptionsDetailsFormState.create({
        courierInstructions,
        doorCode,
      }),
      carrierAddonsFormState: CarrierAddonsFormState.create({
        availableAddons,
        enabledAddons,
      }),
      pickupPointFormState: new FieldState({
        locationName,
        locationExternalId,
      }),
    });
  };

  static createFromTemplate = (
    model: Partial<TemplateModel> = {},
    availableAddons: CarrierAddonsModel[] = [],
    locationName: string = '',
    locationExternalId: string = ''
  ): ShipmentOptionsFormState => {
    return new FormState({
      valueFieldState: new FieldState(
        model && model.shipmentValue ? stringToDecimal(model.shipmentValue) : undefined
      ),
      // templates do not have shippingDate on em
      shippingDateSelectorFormState: DateSelectorFormState.create(undefined),
      shippingMethodSelectorFormState: ShippingMethodSelectorFormState.create(model.shippingMethod),
      metadataFieldState: new FieldState({
        ...model.meta,
      }),
      shipmentOptionsDetailsFormState: ShipmentOptionsDetailsFormState.create({
        doorCode: pathOr('', ['addressTo', 'doorCode'], model),
        courierInstructions: model.courierInstructions,
      }),
      carrierAddonsFormState: CarrierAddonsFormState.create({
        availableAddons,
        enabledAddons: model.addons,
      }),
      pickupPointFormState: new FieldState({ locationName, locationExternalId }),
    });
  };
}

// COMPONENT

type State = {
  shipmentType: ParcelTypeEnum;
};

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

const getMetadataFields = (
  selectedMethod: string,
  getShippingMetadata: (value: string) => ShippingMethodMetadata[]
) => {
  return getShippingMetadata(selectedMethod);
};

@observer
class ShipmentOptionsFormBase extends React.Component<Props, State> {
  state: State = {
    shipmentType: this.props.shippingType || ParcelTypeEnum.PACKAGE,
  };
  @observable
  shippingMethod: string =
    this.props.formState.$.shippingMethodSelectorFormState.$.shippingMethodFieldState.value.toString();

  parcelTypeOptions = [
    {
      value: ParcelTypeEnum.PACKAGE,
      label: ParcelTypeEnum.PACKAGE.toLowerCase(),
    },
    {
      value: ParcelTypeEnum.PALLET,
      label: ParcelTypeEnum.PALLET.toLowerCase(),
    },
    {
      value: ParcelTypeEnum.LETTER,
      label: ParcelTypeEnum.LETTER.toLowerCase(),
    },
  ];

  @computed
  get filteredAddonCodes() {
    const filteredAddons = this.props.carrierAddons.filter(addon =>
      addon.shipping_methods?.includes(this.shippingMethod)
    );

    return filteredAddons.map(addon => addon.code);
  }

  updateAddonsFormState = () => {
    const { carrierAddonsFormState } = this.props.formState.$;
    const enabledCodes = carrierAddonsFormState.$.filter(a => a.$.isOn).map(a => a.$.code);

    this.props.formState.$.carrierAddonsFormState = new FormState(
      this.props.carrierAddons.map(
        a => new FieldState({ ...a, isOn: enabledCodes.includes(a.code) })
      )
    );
  };

  componentDidMount() {
    this.updateAddonsFormState();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.shipmentType !== this.state.shipmentType) {
      this.resetSelectedShippingMethod();
    }
    const newShippingMethod =
      this.props.formState.$.shippingMethodSelectorFormState.$.shippingMethodFieldState.value.toString();

    if (this.shippingMethod !== newShippingMethod) {
      this.shippingMethod = newShippingMethod;
    }
    if (!equals(this.props.carrierAddons, prevProps.carrierAddons)) {
      this.updateAddonsFormState();
    }
    if (!equals(this.props.location, prevProps.location)) {
      this.props.formState.$.pickupPointFormState.$.locationName = this.props.location.name;
      this.props.formState.$.pickupPointFormState.$.locationExternalId =
        this.props.location.externalId;
      this.props.formState.$.shippingMethodSelectorFormState.$.shippingMethodFieldState.value =
        this.props.location.shippingMethod;
      this.resetAddons();
    }
  }

  resetSelectedShippingMethod() {
    this.props.formState.$.shippingMethodSelectorFormState.$.shippingMethodFieldState.value = '';
  }

  resetAddons() {
    this.props.formState.$.carrierAddonsFormState = CarrierAddonsFormState.create({
      availableAddons: this.props.carrierAddons,
    });
  }

  resetPickupPointData() {
    this.props.formState.$.pickupPointFormState = new FieldState({
      locationName: '',
      locationExternalId: '',
    });
  }

  renderPickupPointSection = () => {
    const {
      hidePickupPointSelection,
      isFetchingSession,
      disabled,
      formState: {
        $: { pickupPointFormState },
      },
    } = this.props;
    if (!hidePickupPointSelection) {
      if (isFetchingSession) {
        return (
          <div className={styles.spinnerWrapper}>
            <Spinner />
          </div>
        );
      }
      return (
        <div className={styles.description}>
          <div className={styles.pickupPointInfo}>
            <Tooltip placement="topLeft" title={pickupPointFormState.$.locationName}>
              <div className={styles.pickupPointName}>
                Pickup point name: {pickupPointFormState.$.locationName}
              </div>
            </Tooltip>
            <Tooltip placement="topLeft" title={pickupPointFormState.$.locationExternalId}>
              <div className={styles.pickupPointRef}>
                Pickup point reference: {pickupPointFormState.$.locationExternalId}
              </div>
            </Tooltip>
          </div>
          <Button
            icon={<DeleteOutlined />}
            title="Remove pickup point"
            onClick={() => {
              this.resetPickupPointData();
              this.resetSelectedShippingMethod();
            }}
            disabled={disabled || pickupPointFormState.$.locationExternalId === ''}
          />
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      disabled,
      showAddons = false,
      isPreorder = false,
      changePreorder,
      isFetchingSession,
      hidePickupPointSelection: isOnTemplatePages,
      onOpenWidgetButtonClick,
      showCustomBookingMethods,
    } = this.props;
    const {
      shippingDateSelectorFormState,
      shippingMethodSelectorFormState,
      metadataFieldState,
      carrierAddonsFormState,
      shipmentOptionsDetailsFormState,
    } = this.props.formState.$;

    const shippingMethod =
      shippingMethodSelectorFormState.$.shippingMethodFieldState.value.toString();
    const metadataFields = getMetadataFields(shippingMethod, this.props.getShippingMetadata);

    return (
      <Card
        title={
          <div className={styles.cardTitle}>
            <FormattedMessage id="SHIPPING_OPTIONS" />
            {!isOnTemplatePages && (
              <Button
                disabled={disabled}
                onClick={() => onOpenWidgetButtonClick && onOpenWidgetButtonClick()}
              >
                Select from Checkout
              </Button>
            )}
          </div>
        }
        className={styles.card}
      >
        <section>
          <div className={styles.wrapper}>
            <div className={styles.preorderWrapper}>
              <SwitchField
                label="Preorder"
                checked={isPreorder}
                onChange={changePreorder}
                disabled={disabled}
              />
              {isPreorder && (
                <DateSelectorForm disabled={disabled} formState={shippingDateSelectorFormState} />
              )}
            </div>
            {this.renderPickupPointSection()}

            <SelectField
              label="Shipment type"
              options={this.parcelTypeOptions}
              value={this.state.shipmentType}
              onChange={(e: ParcelTypeEnum) => this.setState({ shipmentType: ParcelTypeEnum[e] })}
              disabled={disabled}
              labelCol={{ span: 24 }}
            />
            <ShippingMethodForm
              disabled={disabled}
              shippingMethodSelectorFormState={shippingMethodSelectorFormState}
              isFetchingSession={isFetchingSession}
              onChange={() => {
                this.resetPickupPointData();
                this.resetAddons();
              }}
              shipmentType={this.state.shipmentType}
              showCustomBookingMethods={showCustomBookingMethods}
            />
            <ShipmentOptionsDetails
              formState={shipmentOptionsDetailsFormState}
              disabled={disabled}
            />
          </div>
          <MetadataInputs
            disabled={disabled}
            metadataFields={metadataFields}
            fieldState={metadataFieldState}
          />
          <CarrierAddonsForm
            formState={carrierAddonsFormState}
            showAddons={showAddons && !!this.shippingMethod}
            shippingMethod={this.shippingMethod}
            allowedAddonCodes={this.filteredAddonCodes}
          />
        </section>
      </Card>
    );
  }
}

type ShippingMethodsProps = {
  disabled: boolean | undefined;
  shippingMethodSelectorFormState: ShippingMethodSelectorFormState;
  isFetchingSession: boolean;
  onChange: () => void;
  shipmentType: ParcelTypeEnum;
  showCustomBookingMethods: Props['showCustomBookingMethods'];
};

const ShippingMethodForm = ({
  disabled,
  shipmentType,
  isFetchingSession,
  onChange,
  shippingMethodSelectorFormState,
  showCustomBookingMethods,
}: ShippingMethodsProps) => {
  const siteId = useMasterSiteId();
  const { data, isFetching } = useQuery({
    queryKey: 'shipping.methods.list',
    queryFn: () => services.selfCareService.listShippingMethods(siteId, showCustomBookingMethods),
    select: data =>
      groupBookingMethodsByCarrier(
        data
          .filter(method => method.bookingConfigStatus === 'METHOD_BOOKING_STATUS_CONFIGURED')
          .filter(method => shipmentType === method.parcelType || method.id === 'gnc-std')
      ),
  });

  return (
    <ShippingMethodSelectorForm
      disabled={disabled}
      formState={shippingMethodSelectorFormState}
      shippingMethods={data ?? {}}
      loading={isFetchingSession || isFetching}
      onChange={onChange}
    />
  );
};

const styles = stylesheet({
  wrapper: {
    width: '400px',
  },
  preorderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputNumberField: {
    width: '150px',
  },
  dateSelector: {
    marginBottom: 0,
  },
  card: {
    marginBottom: '24px',
  },
  cardTitle: {
    width: '400px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  description: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pickupPointInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '12px',
    cursor: 'default',
  },
  pickupPointName: {
    marginBottom: '12px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '350px',
  },
  pickupPointRef: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '350px',
  },
  spinnerWrapper: {
    minHeight: '66px',
  },
});

export const ShipmentOptionsForm = connect(mapStateToProps, {})(ShipmentOptionsFormBase);
ShipmentOptionsForm.displayName = 'ShipmentOptionsForm';
