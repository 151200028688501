import { Form as AntForm, FormItemProps } from 'antd';
import * as React from 'react';

const FormItem: React.FC<FormItemProps> = props => {
  const { children, ...restProps } = props;

  return <AntForm.Item {...restProps}>{children}</AntForm.Item>;
};

export default FormItem;
