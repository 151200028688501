import { InfoCircleOutlined } from '@ant-design/icons';
import * as React from 'react';
import { clamp } from 'lodash';
import { Button, Form, InputNumber, Row, Switch, Tooltip } from 'antd';
import { Input, Select } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { CreateExperimentModel } from '@src/models';
import { useSelector } from '@src/utils/hooks';
import { getCountriesGroupedByGeoregionForDefinedRegions } from '@src/modules/config/selectors';
import { isAuthUserSuperUser } from '@src/modules/auth/auth-selectors';
import { externalBalancingTooltip } from './experiment-details';

interface Props {
  siteId: string;
  onSubmit: (values: CreateExperimentModel) => void;
}

interface CreateExperimentForm extends Omit<CreateExperimentModel, 'type'> {
  internal: boolean;
}

export const CreateExperimentForm: React.FC<Props> = ({ siteId, onSubmit }) => {
  const isSuperUser = useSelector(isAuthUserSuperUser);
  const countriesOptions = useSelector(getCountriesGroupedByGeoregionForDefinedRegions);

  const [trafficEnabled, setTrafficEnabled] = React.useState<boolean>(true);

  return (
    <Form
      initialValues={{ siteId, internal: false }}
      labelCol={{ span: 8 }}
      labelAlign="left"
      onFinish={({ internal, ...values }: CreateExperimentForm) =>
        onSubmit({ ...values, type: internal ? 'INTERNAL' : 'EXTERNAL' })
      }
    >
      <Form.Item name="siteId" hidden />

      <Form.Item
        name="name"
        label={<FormattedMessage id="NAME" />}
        rules={[{ required: true, message: '' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="externalTrafficBalancingEnabled"
        label={
          <>
            <span style={{ whiteSpace: 'normal' }}>
              External balancing of traffic
            </span>
            <Tooltip title={externalBalancingTooltip}>
              <InfoCircleOutlined style={{ marginLeft: '3px' }} />
            </Tooltip>
          </>
        }
        valuePropName="checked"
      >
        <Switch onChange={(checked) => setTrafficEnabled(!checked)} />
      </Form.Item>

      { trafficEnabled && (
        <Form.Item
          name="traffic"
          label={<FormattedMessage id="TRAFFIC" />}
          rules={[{ required: true, message: '' }]}
        >
          <InputNumber
            step={10}
            min={0}
            max={1000}
            formatter={(value: number) => (value !== undefined ? `${value / 10}%` : '')}
            parser={(value: string) => {
              const numValue = value !== undefined ? value.replace('%', '') : 0;
              return clamp((numValue as number) * 10, 0, 1000);
            }}
          />
        </Form.Item>
      )}

      <Form.Item name="country" label="Audience" rules={[{ required: true, message: '' }]}>
        <Select
          groupOptions={countriesOptions}
          showSearch
          filterOption={true}
          optionFilterProp="children"
        />
      </Form.Item>

      {isSuperUser && (
        <Form.Item name="internal" label="Internal" valuePropName="checked">
          <Switch />
        </Form.Item>
      )}

      <Row justify="end">
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="ADD" />
        </Button>
      </Row>
    </Form>
  );
};
