import { FieldState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';

type BaseProps = {
  tags?: string[];
  editable?: boolean;
  onChangeHandler?: (value: string[]) => void;
};

type HOCProps = {
  fieldState?: FieldState<string[]>;
};

export function withFieldStateTagGroup<WrappedComponentProps extends BaseProps>(
  WrappedComponent:
    | React.StatelessComponent<WrappedComponentProps>
    | React.ComponentClass<WrappedComponentProps>
) {
  const HOC: React.StatelessComponent<HOCProps & WrappedComponentProps> = props => {
    const { fieldState, ...passThroughProps } = props as HOCProps;

    const overriddenBaseProps: BaseProps = {};
    if (fieldState) {
      overriddenBaseProps.tags = fieldState.value;
      overriddenBaseProps.onChangeHandler = (value: string[]) => {
        fieldState.onChange(value);
      };
    }

    return <WrappedComponent {...(passThroughProps as any)} {...overriddenBaseProps} />;
  };

  return observer(HOC);
}
