import { combineEpics, Epic } from 'redux-observable';
import { from as observableFrom, of as observableOf } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { getType, isActionOf } from 'typesafe-actions';

import { SitesSelectors } from '@src/modules/sites';
import { ERRORS } from '../../dictionaries';
import { RootAction, RootState, Services } from '../../modules';
import { MADError } from '../../utils';
import { routerActions } from '../router';
import { addressBookActions } from './';

const fetchContactsListEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { addressBookService, loggingService }
) =>
  action$.pipe(
    filter(
      isActionOf([
        addressBookActions.getContactsListRequest,
        addressBookActions.getCustomerContactsRequest,
        addressBookActions.getSenderContactsRequest,
        addressBookActions.getDeliveryContactsRequest,
      ])
    ),
    debounceTime(1000),
    switchMap(({ payload: { searchQuery, pageLimit, pageNumber }, type }) =>
      observableFrom(
        addressBookService.fetchContactsList(
          SitesSelectors.getSelectedSiteIdOrEmpty(state$.value),
          searchQuery,
          pageLimit,
          pageNumber
        )
      ).pipe(
        map(res => {
          switch (type) {
            case getType(addressBookActions.getCustomerContactsRequest):
              return addressBookActions.getCustomerContactsSuccess(res);
            case getType(addressBookActions.getSenderContactsRequest):
              return addressBookActions.getSenderContactsSuccess(res);
            case getType(addressBookActions.getDeliveryContactsRequest):
              return addressBookActions.getDeliveryContactsSuccess(res);
            case getType(addressBookActions.getContactsListRequest):
              return addressBookActions.getContactsListSuccess(res);
          }
        }),
        catchError((error: MADError) => {
          loggingService.logError(error);
          switch (type) {
            case getType(addressBookActions.getCustomerContactsRequest):
              return observableOf(addressBookActions.getCustomerContactsError(error.message));
            case getType(addressBookActions.getSenderContactsRequest):
              return observableOf(addressBookActions.getSenderContactsError(error.message));
            case getType(addressBookActions.getDeliveryContactsRequest):
              return observableOf(addressBookActions.getDeliveryContactsError(error.message));
            default:
              return observableOf(addressBookActions.getContactsListError(error.message));
          }
        })
      )
    )
  );

const createContactEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { addressBookService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(addressBookActions.createContactRequest)),
    switchMap(action => {
      // Optimistic update
      const contacts = state$.value.addressBook.contacts;
      const tempId = action.payload.id;
      contacts.push(action.payload);
      return observableFrom(
        addressBookService.createContact(state$.value.sites.selectedSiteId || '', action.payload)
      ).pipe(
        mergeMap(res => {
          messageService.success('Creating new address completed successfully');
          contacts.map(c => {
            if (c.id === tempId) {
              c.id = res.id;
            }
            return c;
          });
          return observableFrom([
            addressBookActions.createContactSuccess(res),
            routerActions.push({ name: 'ADDRESS_BOOK' }),
          ]);
        }),
        catchError((error: MADError) => {
          loggingService.logError(error);
          messageService.error(ERRORS.CREATE_FAILED);
          contacts.filter(c => c.id === tempId);
          return observableOf(addressBookActions.createContactError(error.message));
        })
      );
    })
  );

const createContactFromModalEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { addressBookService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(addressBookActions.createContactFromModalRequest)),
    switchMap(action => {
      // optimistic update
      const contacts = state$.value.addressBook.contacts;
      const tempId = action.payload.id;
      contacts.push(action.payload);
      return observableFrom(
        addressBookService.createContact(state$.value.sites.selectedSiteId || '', action.payload)
      ).pipe(
        mergeMap(res => {
          messageService.success('Creating new address completed successfully');
          contacts.map(c => {
            if (c.id === tempId) {
              c.id = res.id;
            }
            return c;
          });
          return observableFrom([addressBookActions.createContactSuccess(res)]);
        }),
        catchError((error: MADError) => {
          loggingService.logError(error);
          messageService.error(ERRORS.CREATE_FAILED);
          contacts.filter(c => c.id === tempId);
          return observableOf(addressBookActions.createContactError(error.message));
        })
      );
    })
  );

const updateContactEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { addressBookService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(addressBookActions.updateContactRequest)),
    switchMap(action => {
      return observableFrom(
        addressBookService.updateContact(state$.value.sites.selectedSiteId || '', action.payload)
      ).pipe(
        mergeMap(res => {
          messageService.success(ERRORS.UPDATE_SUCCESS);
          return observableFrom([
            addressBookActions.updateContactSuccess(res),
            routerActions.push({ name: 'ADDRESS_BOOK' }),
          ]);
        }),
        catchError((error: MADError) => {
          loggingService.logError(error);
          messageService.error(ERRORS.UPDATE_FAILED);
          return observableOf(addressBookActions.updateContactError(error.message));
        })
      );
    })
  );

const updateContactFromModalEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { addressBookService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(addressBookActions.updateContactFromModalRequest)),
    switchMap(action => {
      return addressBookService
        .updateContact(state$.value.sites.selectedSiteId || '', action.payload)
        .then(res => {
          messageService.success(ERRORS.UPDATE_SUCCESS);
          return addressBookActions.updateContactSuccess(res);
        })
        .catch((error: MADError) => {
          loggingService.logError(error);
          messageService.error(ERRORS.UPDATE_FAILED);
          return addressBookActions.updateContactError(error.message);
        });
    })
  );

const deleteContactsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { addressBookService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(addressBookActions.deleteContactsRequest)),
    filter(() => {
      if (state$.value.sites.selectedSiteId == null) {
        throw new Error('No selectedSiteId in the store');
      }
      return true;
    }),
    switchMap(action => {
      return addressBookService
        .deleteContacts(state$.value.sites.selectedSiteId || '', action.payload)
        .then(() =>
          addressBookActions.deleteContactsSuccess({
            count: action.payload.length,
            deletedIds: action.payload,
          })
        )
        .catch((error: MADError) => {
          loggingService.logError(error);
          return addressBookActions.deleteContactsError(error.message);
        });
    })
  );

const importContactsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { addressBookService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(addressBookActions.createContactsWithCSVRequest)),
    switchMap(action =>
      observableOf([state$.value.sites.selectedSiteId]).pipe(
        map(([maybeSelectedSiteId]) => {
          if (maybeSelectedSiteId == null) {
            throw new Error('No selectedSiteId in the store');
          }
          return maybeSelectedSiteId;
        }),
        switchMap(() =>
          observableFrom(
            addressBookService.importContacts(
              state$.value.sites.selectedSiteId || '',
              action.payload
            )
          ).pipe(map(addressBookActions.createContactsWithCSVSuccess))
        ),
        catchError((error: MADError) => {
          loggingService.logError(error);
          return observableOf(addressBookActions.createContactsWithCSVError(error.message));
        })
      )
    )
  );

const addressBookMessagesEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { messageService }
) =>
  action$.pipe(
    filter(
      isActionOf([
        addressBookActions.createContactsWithCSVSuccess,
        addressBookActions.deleteContactsSuccess,
        addressBookActions.createContactsWithCSVError,
        addressBookActions.getContactsListError,
        addressBookActions.deleteContactsError,
      ])
    ),
    tap(action => {
      switch (action.type) {
        case getType(addressBookActions.createContactsWithCSVSuccess):
          return messageService.success(ERRORS.CREATE_ADDRESS_SUCCESS(action.payload.count));
        case getType(addressBookActions.deleteContactsSuccess):
          return messageService.success(ERRORS.DELETE_CONTACTS_SUCCESS(action.payload.count));
        case getType(addressBookActions.createContactsWithCSVError):
          return messageService.error(ERRORS.CREATE_ADDRESSES_FAILED);
        case getType(addressBookActions.getContactsListError):
          return messageService.error(ERRORS.LIST_CONTACTS_ERROR);
        case getType(addressBookActions.deleteContactsError):
          return messageService.error(ERRORS.DELETE_ADDRESS_ERROR);
        default:
          throw Error('Message not in expected type');
      }
    }),
    ignoreElements()
  );

export const addressBookEpics = combineEpics(
  fetchContactsListEpic,
  createContactEpic,
  createContactFromModalEpic,
  updateContactEpic,
  updateContactFromModalEpic,
  deleteContactsEpic,
  importContactsEpic,
  addressBookMessagesEpic
);
