import { routerActions } from 'connected-react-router';
import { head, partial, pipe, prop, sortBy } from 'ramda';
import { combineEpics, Epic } from 'redux-observable';
import { from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { RootAction, RootState, Services } from '..';
import { MADError } from '../../utils';
import { hasParam, updateParamWithValue } from '../../utils/url';
import { merchantsActions } from './';

const rewriteUrlWithMerchantId: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { cookiesService }
) =>
  action$.pipe(
    filter(isActionOf(merchantsActions.selectActiveMerchant)),
    filter(() => {
      const router = state$.value.router;
      return !!(router && router.location && router.location.pathname);
    }),
    tap(action => {
      cookiesService.setMerchantSelected(action.payload.merchantId);
      cookiesService.setSiteSelected(action.payload.siteId);
    }),
    map(action => {
      const path = state$.value.router.location!.pathname;
      const updatePathParams: (path: string) => string = pipe(
        partial(updateParamWithValue, ['merchants', action.payload.merchantId]),
        partial(updateParamWithValue, ['sites', action.payload.siteId])
      );

      const newPath = hasParam('sites', path)
        ? updatePathParams(path)
        : `/merchants/${action.payload.merchantId}/sites/${action.payload.siteId}/`;

      return routerActions.push(newPath);
    })
  );

const fetchMerchants: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { configService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(merchantsActions.listMerchants)),
    switchMap(action =>
      observableFrom(configService.listMerchants()).pipe(
        map(res => merchantsActions.listMerchantsSuccess(sortBy(prop('name'), res))),
        catchError((err: MADError) =>
          observableOf(merchantsActions.listMerchantsError('Command failed: ' + action.type)).pipe(
            tap(() => {
              loggingService.logError(err);
            })
          )
        )
      )
    )
  );

const selectDefaultMerchant: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { cookiesService }
) =>
  action$.pipe(
    filter(isActionOf(merchantsActions.listMerchantsSuccess)),
    filter(
      () =>
        state$.value.merchants.selectedMerchantId == null ||
        state$.value.sites.selectedSiteId == null
    ),
    map(action => {
      if (action.payload.length === 0) {
        return merchantsActions.selectActiveMerchant({ merchantId: '', siteId: '' });
      }

      const savedMerchantId =
        state$.value.merchants.selectedMerchantId == null
          ? cookiesService.getMerchantSelected()
          : state$.value.merchants.selectedMerchantId;

      const merchantToPick =
        (savedMerchantId && action.payload.find(site => site.id === savedMerchantId)) ||
        head(action.payload);

      if (!merchantToPick || !merchantToPick.sites) {
        return merchantsActions.selectActiveMerchant({ merchantId: '', siteId: '' });
      }

      const savedSiteId = cookiesService.getSiteSelected();
      const siteToPick =
        (savedSiteId && merchantToPick.sites.find(site => site.id === savedSiteId)) ||
        head(merchantToPick.sites);

      if (!siteToPick) {
        return merchantsActions.selectActiveMerchant({ merchantId: '', siteId: '' });
      }

      return merchantsActions.selectActiveMerchant({
        merchantId: merchantToPick!.id,
        siteId: siteToPick!.id,
      });
    })
  );

const rewriteUrlWithMerchantAndSiteId: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { cookiesService, localStorageService }
) =>
  action$.pipe(
    filter(isActionOf(merchantsActions.changeActiveMerchantAndSite)),
    filter(() => {
      const router = state$.value.router;
      return !!(router && router.location && router.location.pathname);
    }),
    tap(action => {
      cookiesService.setMerchantSelected(action.payload.merchantId);
      cookiesService.setSiteSelected(action.payload.siteId);
      if (action.payload.siteId !== state$.value.sites.selectedSiteId) {
        localStorageService.clearWidgetConfigurationFormState(
          localStorageService.MAD_CHECKOUT_WIDGET_CONFIGURATION_FORM_STATE_KEY
        );
        localStorageService.clearWidgetConfigurationFormState(
          localStorageService.MAD_TRACKING_WIDGET_CONFIGURATION_FORM_STATE_KEY
        );
      }
    }),
    map(action => {
      const path = state$.value.router.location!.pathname;

      const updatePathParams: (path: string) => string = pipe(
        partial(updateParamWithValue, ['merchants', action.payload.merchantId]),
        partial(updateParamWithValue, ['sites', action.payload.siteId])
      );
      const newPath = updatePathParams(path);
      return routerActions.push(newPath);
    })
  );

export const merchantsEpics = combineEpics(
  rewriteUrlWithMerchantId,
  fetchMerchants,
  selectDefaultMerchant,
  rewriteUrlWithMerchantAndSiteId
);
