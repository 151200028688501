import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input/TextArea';
import * as React from 'react';

export interface IProps extends TextAreaProps {
  autoFocus?: boolean;
  disabled?: boolean;
  autosize?: boolean | { minRows?: number; maxRows?: number };
}

const Component: React.FunctionComponent<IProps> = props => {
  const { ...restProps } = props;

  return <Input.TextArea {...restProps} />;
};

export default Component;
