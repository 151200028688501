import React, { FC } from 'react';
import { Tag } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { defaultTheme } from '@src/styles';
import { stylesheet as tss } from 'typestyle';

type Props = {
  withCountryTag?: boolean;
};

export const Header: FC<Props> = ({ withCountryTag = false, children }) => (
  <div className={styles.header}>
    {withCountryTag && (
      <Tag color={defaultTheme.color.primary} nonClickable={true}>
        <FormattedMessage id="COUNTRY" />
      </Tag>
    )}
    <h2>{children}</h2>
  </div>
);

const styles = tss({
  header: {
    margin: '24px 0',
  },
});
