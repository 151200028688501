import { useFormikContext } from 'formik';
import { isEmpty } from 'ramda';
import * as React from 'react';

import { Button } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { stylesheet } from 'typestyle';
import { WidgetFormValues } from '../helpers';
import { FormikInputField } from './fields';

export const DeliveryAddress: React.FunctionComponent = () => {
  const { touched, setFieldValue, values, setTouched, validateForm } =
    useFormikContext<WidgetFormValues>();
  const searchAddressIsEmpty = isEmpty(
    Object.values(values.searchAddress).filter(value => !!value)
  );

  return (
    <>
      <div className={styles.deliveryAddressWrapper}>
        <div className={styles.deliveryAddressFields}>
          <FormikInputField
            name="deliveryAddress.postalCode"
            label={<FormattedMessage id="ZIP_CODE" />}
          />
          <FormikInputField
            name="deliveryAddress.addressLine1"
            label={<FormattedMessage id="ADDRESS_LINE" values={{ num: 1 }} />}
          />
          <FormikInputField name="deliveryAddress.city" label={<FormattedMessage id="CITY" />} />
          <FormikInputField
            name="deliveryAddress.name"
            label={<FormattedMessage id="CHECKOUT_TESTCLIENT.CUSTOMER_NAME_FIELD.LABEL" />}
          />
          <FormikInputField
            name="deliveryAddress.email"
            label={<FormattedMessage id="CHECKOUT_TESTCLIENT.CUSTOMER_EMAIL_FIELD.LABEL" />}
          />
          <FormikInputField
            name="deliveryAddress.phone"
            label={<FormattedMessage id="CHECKOUT_TESTCLIENT.CUSTOMER_PHONE_FIELD.LABEL" />}
          />
        </div>
        <Button
          type="primary"
          className={styles.useSearchAddressButton}
          disabled={searchAddressIsEmpty}
          onClick={() => {
            const updatedDeliveryAdddress = {
              ...values.deliveryAddress,
              ...values.searchAddress,
            };
            setFieldValue('deliveryAddress', updatedDeliveryAdddress);
            // fields that were changed in search address need to be set as touched to show validation
            setTouched({
              ...touched,
              deliveryAddress: {
                ...touched.searchAddress,
              },
            });
            validateForm({
              ...values,
              deliveryAddress: updatedDeliveryAdddress,
            });
          }}
        >
          <FormattedMessage id={'USE_SEARCH_ADDRESS'} />
        </Button>
      </div>
    </>
  );
};

const styles = stylesheet({
  useSearchAddressButton: {
    marginLeft: 'auto',
    marginTop: '4px',
  },
  deliveryAddressWrapper: {
    display: 'flex',
  },
  deliveryAddressFields: {
    flex: 1,
    marginRight: '16px',
  },
});
