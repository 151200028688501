import { Switch } from 'antd';
import * as React from 'react';
import { classes, style as tss } from 'typestyle';

import { Table } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { DragIcon } from '@src/images/drag-icon';
import {
  ConfigRegionModel,
  ConfigShippingCategoryModel,
  DeliveryTypeEnum,
  StateEnum,
} from '@src/models';
import { configSelectors } from '@src/modules/config';
import { defaultTheme } from '@src/styles';
import { useSelector } from '@src/utils/hooks';

import {
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DragSourceCollector,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceSpec,
  DropTarget,
  DropTargetCollector,
  DropTargetConnector,
  DropTargetMonitor,
  DropTargetSpec,
} from 'react-dnd';

const getDeliveryTypeMessageId = (deliveryType: DeliveryTypeEnum) => {
  switch (deliveryType) {
    case DeliveryTypeEnum.DELIVERY:
      return 'DELIVERY_TYPE_DELIVERY';
    case DeliveryTypeEnum.INSTORE:
      return 'DELIVERY_TYPE_INSTORE';
    case DeliveryTypeEnum.MAILBOX:
      return 'DELIVERY_TYPE_MAILBOX';
    case DeliveryTypeEnum.PICKUP:
      return 'DELIVERY_TYPE_PICKUP';
    case DeliveryTypeEnum.UNKNOWN:
      return 'DELIVERY_TYPE_UNKNOWN';
  }
};

const getRegionName = (regions: ConfigRegionModel[], regionId: string) => {
  return regions.find(region => region.id === regionId)?.name || '';
};

type OwnProps = {
  dataSource: ConfigShippingCategoryModel[];
  categoryType: 'sorted' | 'unsorted';
  moveCategory: (source: string, target: string) => void;
  fallbacks: { [categoryId: string]: boolean };
  toggleFallback: (categoryId: string) => void;
  editedCategoryId?: string; // used to assign distinct backround to category edited in Regions
};

export const PreselectionOrderModalTable: React.FC<OwnProps> = ({
  dataSource,
  categoryType,
  moveCategory,
  fallbacks,
  toggleFallback,
  editedCategoryId,
}) => {
  const regions = useSelector(state => configSelectors.getRegionsByDraftOrCurrentSiteId(state));

  return (
    <Table<ConfigShippingCategoryModel>
      className={styles.table}
      dataSource={dataSource}
      pagination={false}
      rowKey="id"
      onRow={(record, index) => ({
        categoryId: record.id,
        moveCategory,
        categoryType,
        index,
        isEdited: record.id === editedCategoryId,
      })}
      components={{ body: { row: CategoryRow } }}
      hideTitle={true}
      useAntdEmptyMessage={true}
    >
      <Table.Column<ConfigShippingCategoryModel>
        title=""
        key="dragIcon"
        render={() => <DragIcon />}
        width="24px"
      />
      <Table.Column<ConfigShippingCategoryModel>
        title="Name"
        dataIndex="name"
        key="name"
        width="248px"
      />
      <Table.Column<ConfigShippingCategoryModel>
        title="Regions"
        key="regions"
        render={(text, record) => record.regionIds.map(id => getRegionName(regions, id)).join(', ')}
        width="200px"
      />
      <Table.Column<ConfigShippingCategoryModel>
        title="Delivery type"
        key="deliveryType"
        render={(text, record) => (
          <FormattedMessage id={getDeliveryTypeMessageId(record.deliveryType)} />
        )}
      />
      <Table.Column<ConfigShippingCategoryModel>
        title="Status"
        key="status"
        render={(text, record) => (
          <FormattedMessage id={record.state === StateEnum.ACTIVE ? 'ACTIVE' : 'INACTIVE'} />
        )}
      />
      <Table.Column<ConfigShippingCategoryModel>
        title={categoryType === 'sorted' ? 'Fallback' : ''}
        key="fallback"
        render={(text, record) =>
          categoryType === 'sorted' ? (
            <Switch checked={fallbacks[record.id]} onChange={value => toggleFallback(record.id)} />
          ) : undefined
        }
        width="120px"
      />
    </Table>
  );
};

const styles = {
  table: tss({
    $nest: {
      '.ant-table-thead th': {
        backgroundColor: defaultTheme.regionForm.color.tableHeader,
      },
      // fragile hack, but only thing that worked to hide Antd's "No Data" component
      'td.ant-table-cell[colspan="6"]': {
        display: 'none',
      },
    },
  }),
};

type CategoryItem = { categoryId: string };

const CATEGORY_ITEM = 'CATEGORY_ITEM';

type CategoryRowOwnProps = {
  categoryId: string;
  categoryType: OwnProps['categoryType'];
  moveCategory: OwnProps['moveCategory'];
  isEdited: boolean;
  index: number;
};

type CategoryRowDragSourceCollectedProps = {
  connectDragSource: ConnectDragSource;
  isDragging: boolean;
};

type CategoryRowDropTargetCollectedProps = {
  connectDropTarget: ConnectDropTarget;
  isOver: boolean;
};

let draggingIndex = -1;

const _CategoryRow: React.FC<
  CategoryRowOwnProps & CategoryRowDragSourceCollectedProps & CategoryRowDropTargetCollectedProps
> = ({
  connectDragSource,
  isDragging,
  connectDropTarget,
  isOver,
  categoryId,
  moveCategory,
  categoryType,
  index,
  isEdited,
  ...restProps
}) => {
  const className = classes(
    rowStyles.row,
    isEdited ? rowStyles.isEdited : undefined,
    isOver && draggingIndex > index ? 'is-over-top' : '',
    isOver && draggingIndex < index ? 'is-over-bottom' : '',
    isDragging ? 'is-dragging' : ''
  );
  return connectDropTarget(connectDragSource(<tr {...restProps} className={className} />));
};

const rowStyles = {
  row: tss({
    cursor: 'pointer',
    transform: 'translateZ(0)', // fixes safari issue with displaying drag ghost on cell
    $nest: {
      '&:hover td': {
        background: defaultTheme.color.white,
      },
      '&.is-dragging': {
        visibility: 'hidden',
        transition: 'all 0s !important',
        $nest: {
          '*': {
            transition: 'all 0s !important',
          },
        },
      },
      '&.is-dragging + tr td': {
        borderTop: '1px solid #E8E8E8',
      },
      '& td': {
        borderTop: '1px solid #FAF8F7',
        borderBottom: '1px solid #E8E8E8',
      },
      '&.is-over-top td': {
        borderTop: `2px dashed ${defaultTheme.color.primary}`,
      },
      '&.is-over-bottom td': {
        borderBottom: `2px dashed ${defaultTheme.color.primary}`,
      },
    },
  }),
  isEdited: tss({
    $nest: {
      '& td': {
        background: 'rgba(0, 0, 0, 0.1)', // defaultTheme.color.primary with some transparency
      },
      '&:hover td': {
        background: 'rgba(0, 0, 0, 0.05)', // defaultTheme.color.primary with even more transparency
      },
    },
  }),
};

const categoryRowDropTargetSpec: DropTargetSpec<CategoryRowOwnProps> = {
  drop: props => {
    return {
      categoryId: props.categoryId,
    };
  },
  canDrop: props => {
    return props.categoryType === 'sorted';
  },
};

const categoryRowDropTargetCollect: DropTargetCollector<
  CategoryRowDropTargetCollectedProps,
  CategoryRowOwnProps
> = (connect: DropTargetConnector, monitor: DropTargetMonitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver() && monitor.canDrop(),
  };
};

const categoryRowDragSourceSpec: DragSourceSpec<CategoryRowOwnProps, CategoryItem> = {
  beginDrag: props => {
    draggingIndex = props.categoryType === 'unsorted' ? Infinity : props.index;
    return {
      categoryId: props.categoryId,
    };
  },
  endDrag: (props, monitor) => {
    if (!monitor.didDrop()) {
      return;
    }
    const dropResult = monitor.getDropResult();
    const item = monitor.getItem();
    props.moveCategory(item.categoryId, dropResult.categoryId);
  },
};

const categoryRowDragSourceCollect: DragSourceCollector<
  CategoryRowDragSourceCollectedProps,
  CategoryRowOwnProps
> = (connect: DragSourceConnector, monitor: DragSourceMonitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

const CategoryRow = DropTarget(
  CATEGORY_ITEM,
  categoryRowDropTargetSpec,
  categoryRowDropTargetCollect
)(DragSource(CATEGORY_ITEM, categoryRowDragSourceSpec, categoryRowDragSourceCollect)(_CategoryRow));

type PlaceholderDropTargetCollectedProps = {
  connectDropTarget: ConnectDropTarget;
  highlighted: boolean;
  hovered: boolean;
};

const _CategoryRowPlaceholder: React.FC<PlaceholderDropTargetCollectedProps> = ({
  connectDropTarget,
  highlighted,
  hovered,
}) => {
  const className = hovered
    ? rowPlaceholderStyles.hovered
    : highlighted
    ? rowPlaceholderStyles.highligthed
    : rowPlaceholderStyles.default;
  return connectDropTarget(
    <div className={`${rowPlaceholderStyles.base} ${className}`}>
      <FormattedMessage className="message" id="DROP_CATEGORY_HERE" />
    </div>
  );
};

const rowPlaceholderStyles = {
  base: tss({
    height: '48px',
    marginTop: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  default: tss({
    border: '2px dotted #A6A6A6',
    $nest: {
      '.message': {
        display: 'none',
      },
    },
  }),
  highligthed: tss({
    border: `2px dotted ${defaultTheme.color.primary}`,
    backgroundColor: defaultTheme.color.white,
    $nest: {
      '.message': {
        color: defaultTheme.color.primary,
      },
    },
  }),
  hovered: tss({
    border: `2px dotted ${defaultTheme.color.primary}`,
    $nest: {
      '.message': {
        color: defaultTheme.color.primary,
      },
    },
  }),
};

const dropTargetSpec: DropTargetSpec<{}> = {
  drop: () => {
    return {
      categoryId: '__category_placeholder_row__',
    };
  },
};

const placeholderDropTargetCollect: DropTargetCollector<PlaceholderDropTargetCollectedProps, {}> = (
  connect,
  monitor
) => ({
  highlighted: monitor.canDrop(),
  hovered: monitor.isOver(),
  connectDropTarget: connect.dropTarget(),
});

export const CategoryRowPlaceholder = DropTarget(
  CATEGORY_ITEM,
  dropTargetSpec,
  placeholderDropTargetCollect
)(_CategoryRowPlaceholder);
