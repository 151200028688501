import { GeneralCustomsDeclaration } from '@src/containers/shipments/components/customs-declaration/types';
import * as qs from 'query-string';
import { unnest } from 'ramda';
import {
  DeliveryDTO,
  DeliveryModel,
  EditableNotesDTO,
  EditableNotesModel,
  ShipmentCreateDTO,
  ShipmentCreateModel,
  ShipmentDTO,
  ShipmentEditDTO,
  ShipmentEditModel,
  ShipmentListFiltersModel,
  ShipmentModel,
  ShipmentsListFiltersDTO,
} from '../models';
import { apiUtils, authFetch } from '../utils';

const SOM_URL = apiUtils.getApiUrl() + '/frontend/som';

type Direction = 'ASCENDING' | 'DESCENDING';

export async function getShipmentList(
  siteId: string,
  tosIds: string[],
  direction: Direction = 'DESCENDING',
  pageNumber: number = 1,
  pageSize: number = 10,
  filters?: ShipmentListFiltersModel,
  filterId?: string
): Promise<{ shipments: ShipmentModel[] }> {
  if (!siteId) {
    throw new Error('siteId is missing');
  }

  tosIds = tosIds.filter(tosId => tosId !== '');

  let filtersDTO;
  if (filters) {
    filtersDTO = ShipmentsListFiltersDTO.createFromModel(filters);
  }

  if (filterId) {
    filtersDTO = { ...filtersDTO, id: filterId };
  }

  if (tosIds.length) {
    filtersDTO = { ...filtersDTO, tos_ids: tosIds };
  }

  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({
      pagination: {
        offset: (pageNumber - 1) * pageSize,
        limit: pageSize,
      },
      order_by_direction: direction,
      filtering: filtersDTO,
    }),
  };

  return authFetch<{ shipments?: ShipmentDTO[] }>(`${SOM_URL}/shipments.list`, options).then(
    res => ({
      shipments: res.shipments
        ? res.shipments.map(shipment => ShipmentModel.createFromDTO(shipment))
        : [],
    })
  );
}

export async function createShipment(
  siteId: string,
  model: ShipmentCreateModel
): Promise<ShipmentModel> {
  const payload = ShipmentCreateDTO.createFromModel(model);
  // stub tos id
  payload.tos_id = model.tosId;

  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{ shipment: ShipmentDTO }>(`${SOM_URL}/shipments.create`, options).then(res => {
    return ShipmentModel.createFromDTO(res.shipment);
  });
}

type BulkBookingShipmentsResponse = {
  failed_shipment_ids?: string[];
  succeeded_shipment_ids?: string[];
};

export const bulkBookShipments = async (siteId: string, shipmentIds: string[]) => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ shipment_ids: shipmentIds }),
  };

  return authFetch<BulkBookingShipmentsResponse>(`${SOM_URL}/shipments.book_parcels_bulk`, options);
};

export async function cancelShipment(siteId: string, id: string): Promise<{}> {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ shipment_id: id }),
  };

  return authFetch<{}>(`${SOM_URL}/shipments.cancel`, options).then(res => {
    return res;
  });
}

export function customsDeclarationUpsert(
  customsDeclaration: GeneralCustomsDeclaration,
  siteId: string,
  shipmentId: string
) {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({
      customs_declaration: customsDeclaration,
      site_id: siteId,
      shipment_id: shipmentId,
    }),
  };

  return authFetch<void>(`${SOM_URL}/customs_declaration.upsert`, options);
}

export function sendFreightDocuments(email: string, returnShipmentId: string, siteId: string) {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({
      email,
      return_shipment_id: returnShipmentId,
      site_id: siteId,
    }),
  };

  return authFetch<void>(`${SOM_URL}/shipments.send_freight_documents`, options);
}

export async function getShipment(siteId: string, id: string): Promise<ShipmentModel> {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-site-id': siteId,
    },
  };

  return authFetch<{ shipment: ShipmentDTO }>(
    `${SOM_URL}/shipments.get?` +
      qs.stringify({
        id,
      }),
    options
  ).then(res => {
    return ShipmentModel.createFromDTO(res.shipment);
  });
}

export async function bookParcels(siteId: string, shipmentId: string): Promise<ShipmentModel> {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ shipment_id: shipmentId }),
  };

  return authFetch<{ shipment: ShipmentDTO }>(`${SOM_URL}/shipments.bookParcels`, options).then(
    res => ShipmentModel.createFromDTO(res.shipment)
  );
}

const createBookPickupRequest = (siteId: string, trackingNumer: string): Promise<DeliveryDTO> => {
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ tracking_number: trackingNumer }),
  };

  return authFetch<{ delivery: DeliveryDTO }>(`${SOM_URL}/deliveries.bookPickup`, options).then(
    res => res.delivery
  );
};

export async function bookPickupDeliveries(
  siteId: string,
  trackingNumbers: string[]
): Promise<DeliveryModel[]> {
  const requests = trackingNumbers.map(trackingNumber =>
    createBookPickupRequest(siteId, trackingNumber)
  );

  return Promise.all(requests).then(res =>
    unnest(res).map(delivery => DeliveryModel.createFromDTO(delivery))
  );
}

export async function editShipment(
  siteId: string,
  model: ShipmentEditModel
): Promise<ShipmentModel> {
  const payload = ShipmentEditDTO.createFromModel(model);
  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({
      ...payload,
    }),
  };

  return authFetch<{ shipment: ShipmentDTO }>(`${SOM_URL}/shipments.update`, options).then(res => {
    return ShipmentModel.createFromDTO(res.shipment);
  });
}

export async function editNote(siteId: string, model: EditableNotesModel): Promise<{}> {
  const payload = EditableNotesDTO.createFromModel(model);

  const options: RequestInit = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{ shipment: ShipmentDTO }>(`${SOM_URL}/shipments.updateNotes`, options);
}

export async function createAndBook(
  siteId: string,
  model: ShipmentCreateModel
): Promise<ShipmentModel> {
  const payload = ShipmentCreateDTO.createFromModel(model);
  const options = {
    method: 'POST',
    headers: {
      'x-site-id': siteId,
    },
    body: JSON.stringify({ ...payload }),
  };
  return authFetch<{ shipment: ShipmentDTO }>(`${SOM_URL}/shipments.createAndBook`, options).then(
    res => ShipmentModel.createFromDTO(res.shipment)
  );
}
