import { FieldState, FormState } from 'formstate';
import { observer } from 'mobx-react';
import * as moment from 'moment';
import * as React from 'react';

import { DatePicker } from '../controls';
import { withFieldStateDatePicker, withFormItem } from '../decorators';
import { validationUtils } from '../utils';

const DatePickerField = withFormItem(withFieldStateDatePicker(DatePicker));

export class DateSelectorFormState extends FormState<{
  dateFieldState: FieldState<moment.Moment | undefined>;
}> {
  static create = (shippingDate: string | undefined): DateSelectorFormState => {
    return new FormState({
      dateFieldState: new FieldState(moment(shippingDate)).validators(
        validationUtils.requiredDatePickerWithMessage('Shipping date is required.')
      ),
    });
  };
}

// COMPONENT

type Props = {
  formState: DateSelectorFormState;
  disabled?: boolean;
};

@observer
export class DateSelectorForm extends React.Component<Props, {}> {
  render() {
    const { disabled } = this.props;
    const { dateFieldState } = this.props.formState.$;

    return (
      <DatePickerField
        required={true}
        disabled={disabled}
        allowClear={false}
        label={'Shipping Date'}
        placeholder="Select Date"
        fieldState={dateFieldState}
        error={dateFieldState.error}
      />
    );
  }
}
