import { Modal, Spinner } from '@src/controls';
import { useFormatMessage } from '@src/i18n';
import { configSelectors } from '@src/modules/config';
import { listCarrierProductMapping } from '@src/services/selfcare-service';
import { useSelector, useSiteId } from '@src/utils/hooks';
import { Form } from 'antd';
import { Formik } from 'formik';
import * as React from 'react';
import { useQuery } from 'react-query';
import { style as tss } from 'typestyle';
import {
  buildCarrierProductMappingsPayload,
  buildFormValues,
  buildValidationSchema,
  CarrierProductMappingForm,
  modalFormStyles,
} from '../form-commons';
import {
  AddNewRowButtonConnected,
  DeleteRowButtonConnected,
  SubmitButtonConnected,
} from '../form-components/buttons';
import {
  AlwaysShowTrackingLinksFieldConnected,
  CarrierProductFieldConnected,
  CarrierProductRefFieldConnected,
  LocaleFieldConnected,
  TrackingConfigStatusFieldConnected,
  UrlFieldConnected,
  UseCustomMappingFieldConnected,
} from '../form-components/connected-fields';
import { commonFormItemProps } from '../form-components/fields';
import { useCreateCarrierProductMappingMutation } from './mutations';
import {
  handleCustomMappingChange,
  handleDisabled,
  useHandleOnCarrierProductChange,
} from '@src/containers/product-mappings/utils/create';

type Props = {
  isVisible: boolean;
  carrierProductRef: string;
  onCancel: () => void;
};

export const AutogeneratedCarrierProductMappingsCreateModal: React.FC<Props> = ({
  isVisible,
  carrierProductRef,
  onCancel,
}) => {
  const formatMessage = useFormatMessage();
  const siteId = useSiteId();
  const createCarrierProductMappingMutation = useCreateCarrierProductMappingMutation();

  const { data: mappingsData, isFetching: mappingsIsFetching } = useQuery(
    ['carrier_product_mapping.list', siteId],
    () => {
      return listCarrierProductMapping(siteId);
    },
    { enabled: !!siteId }
  );

  const { onProductCarrierChange, isLoadingTrackingConfigStatus } =
    useHandleOnCarrierProductChange(siteId);

  const isCreatingDraft = useSelector(configSelectors.getIsCreatingDraft);

  return (
    <Modal visible={isVisible} footer={null} closable={false} onCancel={onCancel} destroyOnClose>
      {isCreatingDraft || mappingsIsFetching ? (
        <Spinner />
      ) : (
        <>
          <span className={modalFormStyles.modalHeader}>{carrierProductRef}</span>
          <Formik<CarrierProductMappingForm>
            initialValues={buildFormValues({
              carrier_product_ref: carrierProductRef,
            })}
            onSubmit={(values, formikBag) => {
              createCarrierProductMappingMutation.mutate(
                buildCarrierProductMappingsPayload(values)
              );
              onCancel();
              formikBag.resetForm();
            }}
            validationSchema={buildValidationSchema(mappingsData)}
          >
            {formik => (
              <>
                <CarrierProductRefFieldConnected />
                <UseCustomMappingFieldConnected
                  onChange={value => handleCustomMappingChange(formik, value)}
                />
                {formik.values.custom ? (
                  <Form.Item label={formatMessage('TRACKING_LINKS_URLS')} {...commonFormItemProps}>
                    {formik.values.tracking_links.map((_, idx) => {
                      return (
                        <div key={idx} className={modalFormStyles.trackingLinkRow}>
                          <div className={modalFormStyles.fieldSpacing}>
                            <LocaleFieldConnected idx={idx} disabled={idx === 0} />
                          </div>
                          <div className={modalFormStyles.fieldSpacing}>
                            <UrlFieldConnected idx={idx} />
                          </div>
                          {idx !== 0 && (
                            <DeleteRowButtonConnected
                              idx={idx}
                              className={tss({ marginTop: '5px' })}
                            />
                          )}
                        </div>
                      );
                    })}
                    <AddNewRowButtonConnected />
                  </Form.Item>
                ) : (
                  <>
                    <CarrierProductFieldConnected
                      onChange={newProductCarrierId =>
                        onProductCarrierChange(newProductCarrierId, formik)
                      }
                    />
                    <TrackingConfigStatusFieldConnected isLoading={isLoadingTrackingConfigStatus} />
                  </>
                )}
                <AlwaysShowTrackingLinksFieldConnected disabled={handleDisabled(formik)} />
                <SubmitButtonConnected />
              </>
            )}
          </Formik>
        </>
      )}
    </Modal>
  );
};
