import { Col, Row } from 'antd';
import { FieldState, FormState } from 'formstate';
import { observer } from 'mobx-react';
import { compose, either, isEmpty, isNil, reject } from 'ramda';
import * as React from 'react';
import { style as tss } from 'typestyle';
import { Card, Switch, Tooltip } from '../controls';
import { withFieldStateSwitch, withFormItem } from '../decorators';
import { CarrierAddonsModel, ShipmentModel } from '../models';
import defaultTheme from '../styles/theme.default';
import { capitalizeEachWord, removeUnderscores } from '../utils/string';

const SwitchField = withFormItem(withFieldStateSwitch(Switch));

export class CarrierAddonsFormState extends FormState<FieldState<CarrierAddonsModel>[]> {
  static create({
    availableAddons,
    enabledAddons = [],
  }: {
    availableAddons: CarrierAddonsModel[];
    enabledAddons?: ShipmentModel['addons'];
  }): CarrierAddonsFormState {
    const enabledAddonsCodes = new Set(enabledAddons.map(x => x.code));

    return new FormState(
      availableAddons.map(
        addon =>
          new FieldState({
            ...addon,
            isOn: enabledAddonsCodes.has(addon.code),
          })
      )
    );
  }
}

// COMPONENT

type Props = {
  formState: CarrierAddonsFormState;
  disabled?: boolean;
  showAddons?: boolean;
  shippingMethod?: string;
  allowedAddonCodes: string[];
};

const styles = {
  additionalServices: tss({
    marginTop: defaultTheme.lineHeight,
  }),
  switchWrapper: tss({
    $nest: {
      '.ant-form-item': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
      },
      '.ant-form-item-control': {
        paddingLeft: '8px',
        marginLeft: 'auto',
        flexGrow: 0,
      },
      '.ant-form-item-label': {
        textOverflow: 'ellipsis',
        textAlign: 'left',
        $nest: {
          label: {
            display: 'inline',
          },
        },
      },
    },
  }),
  switch: tss({
    paddingLeft: '10px',
  }),
};

@observer
export class CarrierAddonsForm extends React.Component<Props, {}> {
  getLabel(ca: CarrierAddonsModel): string {
    const parts = [ca.code, compose(capitalizeEachWord, removeUnderscores)(ca.name)];
    const filtered = reject(either(isNil, isEmpty))(parts);
    return filtered.join(' - ');
  }

  getCarrier(sm?: string): string {
    return sm ? sm.split('-')[0] : '';
  }

  render() {
    const { disabled, formState, showAddons, allowedAddonCodes, shippingMethod } = this.props;

    if (isEmpty(formState) || !showAddons || isEmpty(allowedAddonCodes)) {
      return null;
    }

    const carrier = this.getCarrier(shippingMethod);

    const filteredFormState = formState.$.filter(
      a => allowedAddonCodes.includes(a.$.code) && a.$.carrier === carrier
    );

    return (
      <Card title="Additional Services" className={styles.additionalServices}>
        <Row gutter={48}>
          {filteredFormState.map((a, i) => (
            <Col span={6} className={styles.switchWrapper} key={`${i}-${a.$.code}-${a.$.name}`}>
              <Tooltip title={a.$.description}>
                <SwitchField
                  className={styles.switch}
                  key={i}
                  disabled={disabled}
                  label={this.getLabel(a.$)}
                  checked={a.$.isOn}
                  onChange={(checked: boolean) => {
                    a.$.isOn = checked;
                  }}
                />
              </Tooltip>
            </Col>
          ))}
        </Row>
      </Card>
    );
  }
}
