import { intl } from '@src/i18n';
import {} from 'react-intl';
import { FeatureDefinitions } from './types';

const { formatMessage } = intl;

export const featureDefinitions: FeatureDefinitions[] = [
  {
    name: 'header.enable',
    title: formatMessage({ id: 'RECEIPT_FEATURES.HEADER.TITLE' }),
    description: formatMessage({ id: 'RECEIPT_FEATURES.HEADER.DESCRIPTION' }),
  },
  {
    name: 'order_details.enable',
    title: formatMessage({ id: 'RECEIPT_FEATURES.ORDER_DETAILS.TITLE' }),
    description: formatMessage({ id: 'RECEIPT_FEATURES.ORDER_DETAILS.DESCRIPTION' }),
  },
  {
    name: 'delivery.enable',
    title: formatMessage({ id: 'RECEIPT_FEATURES.DELIVERY.TITLE' }),
    description: formatMessage({ id: 'RECEIPT_FEATURES.DELIVERY.DESCRIPTION' }),
  },
  {
    name: 'delivery.is_courier_instructions_editable',
    title: formatMessage({ id: 'RECEIPT_FEATURES.COURIER_INSTRUCTIONS.TITLE' }),
    description: formatMessage({ id: 'RECEIPT_FEATURES.COURIER_INSTRUCTIONS.DESCRIPTION' }),
  },
  {
    name: 'delivery.is_door_code_editable',
    title: formatMessage({ id: 'RECEIPT_FEATURES.DOOR_CODE.TITLE' }),
    description: formatMessage({ id: 'RECEIPT_FEATURES.DOOR_CODE.DESCRIPTION' }),
  },
  {
    name: 'order_summary.enable',
    title: formatMessage({ id: 'RECEIPT_FEATURES.SUMMARY.TITLE' }),
    description: formatMessage({ id: 'RECEIPT_FEATURES.SUMMARY.DESCRIPTION' }),
  },
  {
    name: 'rating.enable',
    title: formatMessage({ id: 'RECEIPT_FEATURES.RATING.TITLE' }),
    description: formatMessage({ id: 'RECEIPT_FEATURES.RATING.DESCRIPTION' }),
  },
  {
    name: 'footer.show_slogan',
    title: formatMessage({ id: 'RECEIPT_FEATURES.SLOGAN.TITLE' }),
    description: formatMessage({ id: 'RECEIPT_FEATURES.SLOGAN.DESCRIPTION' }),
  },
];
