import * as React from 'react';
import { Popover } from 'antd';

interface OwnProps {
  render: (close: () => void) => React.ReactNode;
}

const FormPopover: React.FC<OwnProps> = ({ render, children }) => {
  const [visible, setVisible] = React.useState(false);

  const closeFn = () => setVisible(false);

  return (
    <Popover
      visible={visible}
      onVisibleChange={setVisible}
      destroyTooltipOnHide
      trigger="click"
      placement="bottomLeft"
      content={render(closeFn)}
      overlayStyle={{ width: '500px' }}
    >
      {children}
    </Popover>
  );
};

export default FormPopover;
