import { services } from '@src/services';
import { humanize } from '@src/utils/string';
import { Row } from 'antd';
import React, { FC } from 'react';
import { style as tss } from 'typestyle';
import { contentTypes, InCoTerms } from './constants';
import { FormItem } from './form-items';
import type { CustomsDeclarationFormItem } from './types';

export const GeneralInformation: FC = () => {
  const content = formItems.map(props => <FormItem key={props.id} {...props} />);

  return (
    <div>
      <h3>General Information</h3>
      <Row gutter={[8, 16]} className={styles.row}>
        {content}
      </Row>
    </div>
  );
};

const styles = {
  row: tss({
    maxWidth: '800px',
  }),
};

export const formItems: CustomsDeclarationFormItem[] = [
  {
    id: 'payers_name',
    label: "Payer's name",
    inputType: 'text',
  },
  {
    id: 'address_lines',
    label: 'Address line',
    inputType: 'text',
  },
  {
    id: 'city',
    label: 'City',
    inputType: 'text',
  },
  {
    id: 'country',
    label: 'Country',
    inputType: 'text',
  },
  {
    id: 'region',
    label: 'Region',
    inputType: 'text',
  },
  {
    id: 'postal_code',
    label: 'Postal code',
    inputType: 'text',
  },
  {
    id: 'contents_type',
    label: 'Content Type',
    inputType: 'select',
    options: contentTypes.map(contentType => ({
      label: humanize(contentType),
      value: contentType,
    })),
  },
  {
    id: 'contents_explanation',
    label: 'Content explanation',
    inputType: 'text',
  },
  {
    id: 'total_gross_weight',
    label: 'Total gross weight (g)',
    inputType: 'number',
  },
  {
    id: 'incoterms',
    label: 'International Commercial Terms',
    inputType: 'select',
    options: Object.entries(InCoTerms).map(([key, value]) => ({ value: key, label: value })),
  },
  {
    id: 'currency',
    label: 'Purchase currency',
    inputType: 'select',
    options: services.dictionariesService.getCurrenciesOptions(),
    showSearch: true,
  },
  {
    id: 'invoice_number',
    label: 'Invoice number',
    inputType: 'text',
  },
  {
    id: 'invoice_date',
    label: 'Invoice date',
    inputType: 'date',
  },
  {
    id: 'seller_tax_id_number',
    label: 'Seller tax id number',
    inputType: 'text',
  },
  {
    id: 'buyer_tax_id_number',
    label: 'Buyer tax id number',
    inputType: 'text',
  },
];
