import { Input } from 'antd';
import { SearchProps } from 'antd/lib/input/Search';
import * as React from 'react';
const Search = Input.Search;

export interface IProps extends SearchProps {
  autoFocus?: boolean;
  componentRef?: (el: any) => any;
  onSearchValueChange?: (value: string) => void;
}

const trimWhitespace = (value: string) => value && value.trim();

const Component: React.StatelessComponent<IProps> = props => {
  const { onBlur, componentRef, onSearchValueChange, ...restProps } = props;

  const handleOnChange = (ev: React.FormEvent<any>) => {
    if (onSearchValueChange) {
      onSearchValueChange(ev.currentTarget.value);
    }
  };

  const handleBlur = (ev: React.FormEvent<any>) => {
    const trimmed = trimWhitespace(ev.currentTarget.value);
    if (trimmed && trimmed !== props.value && props.onChange) {
      props.onChange({ target: { value: trimmed } } as any);
    }
  };

  return (
    <Search
      onChange={handleOnChange}
      ref={el => componentRef && componentRef(el)}
      {...restProps}
      onBlur={onBlur || handleBlur}
    />
  );
};

export default Component;
