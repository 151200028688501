import { ConfigShippingCategoryModel } from '@src/models';

export type CategoryPreselectionOrder = {
  country: string;
  sorted: ConfigShippingCategoryModel[];
  unsorted: ConfigShippingCategoryModel[];
}[];

/**
 * Check if category preselection order is fully set (all countries are "green")
 */
export const checkIfPreselectionOrderComplete = (
  categoryPreselectionOrder: CategoryPreselectionOrder
): boolean => {
  for (const country of categoryPreselectionOrder) {
    if (country.unsorted.length !== 0) {
      return false;
    }
  }
  return true;
};

export const getCountriesWithCompletePreselectionOrder = (
  categoryPreselectionOrder: CategoryPreselectionOrder
) => categoryPreselectionOrder.filter(({ unsorted }) => unsorted.length === 0);

export const getCountriesWithPartialPreselectionOrder = (
  categoryPreselectionOrder: CategoryPreselectionOrder
) =>
  categoryPreselectionOrder.filter(
    ({ sorted, unsorted }) => sorted.length !== 0 && unsorted.length !== 0
  );

export const getCountriesWithNonePreselectionOrder = (
  categoryPreselectionOrder: CategoryPreselectionOrder
) =>
  categoryPreselectionOrder.filter(
    ({ sorted, unsorted }) => sorted.length === 0 && unsorted.length !== 0
  );

export const getStatus = (
  preselectionOrder: CategoryPreselectionOrder[0]
): 'completePreselectionOrder' | 'partialPreselectionOrder' | 'nonePreselectionOrder' => {
  if (preselectionOrder.unsorted.length === 0) {
    return 'completePreselectionOrder';
  }
  if (preselectionOrder.sorted.length !== 0 && preselectionOrder.unsorted.length !== 0) {
    return 'partialPreselectionOrder';
  }
  return 'nonePreselectionOrder';
};
