import { useState } from 'react';
import { useSelector } from 'react-redux';
import { SitesSelectors } from '@src/modules/sites';

export const useDraftToggle = () => {
  const draftSiteId = useSelector(SitesSelectors.getDraftSiteIdOrEmpty);
  const [isUsingDraft, setUsingDraft] = useState(false);

  const toggleUsingDraft = () => setUsingDraft(!isUsingDraft);

  const isDraftAvailable = !!draftSiteId;

  return [isUsingDraft, isDraftAvailable, toggleUsingDraft] as const;
};
