import { Breadcrumb } from 'antd';
import React, { FC } from 'react';
import { stylesheet as tss } from 'typestyle';

import { Link } from '@src/components/link';
import { FormattedMessage } from '@src/i18n';

const { Item } = Breadcrumb;

export const BreadcrumbSection: FC = ({ children }) => (
  <Breadcrumb>
    <Item>
      <Link route={{ name: 'RECEIPT_FEATURES' }}>
        <FormattedMessage id="FEATURES" className={styles.breadcrumbLink} />
      </Link>
    </Item>
    <Item>{children}</Item>
  </Breadcrumb>
);

const styles = tss({
  breadcrumbLink: {
    cursor: 'pointer',
  },
});
