import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { either, isEmpty, isNil, reject } from 'ramda';
import * as React from 'react';
import { Modal } from '../../../controls';
import { LABELS } from '../../../dictionaries';
import { ShippingAddressForm, ShippingAddressFormState } from '../../../forms';
import { AddressModel, ContactModel } from '../../../models';
import { isValidFormStateContainer } from '../../../utils/validation';

// Component API

type Props = {
  visible: boolean;
  onOk: (item: any) => void;
  onCancel: () => void;
  contact?: ContactModel;
};

@observer
export class ShippingAddressEditModal extends React.Component<Props, {}> {
  @observable
  formStateContainer = {
    shippingAddressFormState: ShippingAddressFormState.create(),
  };

  componentDidMount() {
    this.initializeFormState();
  }

  initializeFormState = () => {
    const { contact } = this.props;
    if (!contact) {
      return;
    }

    this.formStateContainer.shippingAddressFormState = ShippingAddressFormState.create(contact);
  };

  submitHandler = async () => {
    if (!(await isValidFormStateContainer(this.formStateContainer))) {
      return;
    }
    const { shippingAddressFormState } = this.formStateContainer;

    const { contact } = this.props;

    const addressBookItemModel = contact || new ContactModel();

    const addressLines: string[] = [
      shippingAddressFormState.$.addressLine1FieldState.$,
      shippingAddressFormState.$.addressLine2FieldState.$,
      shippingAddressFormState.$.addressLine3FieldState.$,
    ];

    const addressModel = new AddressModel();
    addressModel.addressLines = reject(either(isNil, isEmpty))(addressLines);
    addressModel.name = shippingAddressFormState.$.nameFieldState.$;
    addressModel.region = shippingAddressFormState.$.regionFieldState.$;
    addressModel.postalCode = shippingAddressFormState.$.postalCodeFieldState.$;
    addressModel.country =
      shippingAddressFormState.$.countrySelectorFormState.$.countryFieldState.$.toString();
    addressModel.city = shippingAddressFormState.$.cityFieldState.$;

    addressBookItemModel.email = shippingAddressFormState.$.emailFieldState.$;
    addressBookItemModel.phone = shippingAddressFormState.$.phoneNumberFieldState.$;
    addressBookItemModel.address = addressModel;

    this.props.onOk(addressBookItemModel);
  };

  cancelHandler = () => {
    this.props.onCancel();
  };

  render() {
    return (
      <Modal
        visible={this.props.visible}
        title={[LABELS.EDIT, LABELS.ADDRESS].join(' ')}
        onOk={this.submitHandler}
        onCancel={this.cancelHandler}
        okText={LABELS.SAVE}
      >
        <ShippingAddressForm formState={this.formStateContainer.shippingAddressFormState} />
      </Modal>
    );
  }
}
