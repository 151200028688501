import { DeliveryStatus } from '.';

export enum NotificationType {
  /*Represents a notification triggered with an information what was the status of the given tracking number at a specific time.*/
  STATUS_AT_TIME = 'STATUS_AT_TIME',
  /*Represents a notification triggered if the given tracking number didn’t reach the given status before a specific time.*/
  EXPECT_STATUS_AT_TIME = 'EXPECT_STATUS_AT_TIME',
  /*Represents a notification triggered when the given tracking number changes its status to the given one.*/
  STATUS_CHANGED_TO = 'STATUS_CHANGED_TO',
  UNKNOWN = 'UNKNOWN',
}

export class TriggerCreateDTO {
  tracking_numbers: string[];
  user_id: string;
  site_id: string;
  type?: NotificationType;
  due_date?: string;
  expected_status?: DeliveryStatus;
  shipment_id: string;
  meta?: ShipmentNotificationMetaDTO;

  static createFromModel(model: TriggerCreateModel): TriggerCreateDTO {
    return {
      tracking_numbers: model.trackingNumbers,
      type: model.type,
      due_date: model.dueDate,
      expected_status: model.expectedStatus,
      shipment_id: model.shipmentId,
      user_id: model.userId,
      site_id: model.siteId,
      meta: model.meta && ShipmentNotificationMetaDTO.createFromModel(model.meta),
    };
  }
}

export class ShipmentNotificationDTO {
  id: string;
  tracking_number: string;
  type: NotificationType;
  due_date?: string;
  expected_status?: DeliveryStatus;
  actual_status: DeliveryStatus;
  created_at: string;
  read_at?: string;
  shipment_id: string;
  meta?: ShipmentNotificationMetaDTO;

  static createFromModel(model: ShipmentNotificationModel): ShipmentNotificationDTO {
    return {
      id: model.id,
      tracking_number: model.trackingNumber,
      type: model.type,
      due_date: model.dueDate,
      expected_status: model.expectedStatus,
      actual_status: model.actualStatus,
      created_at: model.createdAt,
      read_at: model.readAt,
      shipment_id: model.shipmentId,
      meta: model.meta && ShipmentNotificationMetaDTO.createFromModel(model.meta),
    };
  }
}

export class TriggerCreateModel {
  /*Tracking number to be watched.*/
  trackingNumbers: string[];
  userId: string;
  siteId: string;
  type?: NotificationType;
  /* Date at which/before which the check should be performed. */
  dueDate?: string;
  expectedStatus?: DeliveryStatus;
  shipmentId: string;
  meta?: ShipmentNotificationMetaModel;

  static createFromDTO(dto: TriggerCreateDTO): TriggerCreateModel {
    return {
      trackingNumbers: dto.tracking_numbers,
      userId: dto.user_id,
      siteId: dto.site_id,
      type: dto.type,
      dueDate: dto.due_date,
      expectedStatus: dto.expected_status,
      shipmentId: dto.shipment_id,
      meta: dto.meta && ShipmentNotificationMetaModel.createFromDTO(dto.meta),
    };
  }
}

export class ShipmentNotificationModel {
  id: string;
  trackingNumber: string;
  type: NotificationType;
  dueDate?: string;
  expectedStatus?: DeliveryStatus;
  actualStatus: DeliveryStatus;
  createdAt: string;
  readAt?: string;
  shipmentId: string;
  meta?: ShipmentNotificationMetaModel;

  static createFromDTO(DTO: ShipmentNotificationDTO): ShipmentNotificationModel {
    return {
      id: DTO.id,
      trackingNumber: DTO.tracking_number,
      type: DTO.type,
      dueDate: DTO.due_date,
      expectedStatus: DTO.expected_status,
      actualStatus: DTO.actual_status,
      createdAt: DTO.created_at,
      readAt: DTO.read_at,
      shipmentId: DTO.shipment_id,
      meta: DTO.meta && ShipmentNotificationMetaModel.createFromDTO(DTO.meta),
    };
  }
}

export class ShipmentNotificationMetaModel {
  /* Backend will accept any field name in meta field */
  customerName?: string;

  static createFromDTO(dto: ShipmentNotificationMetaDTO): ShipmentNotificationMetaModel {
    return {
      customerName: dto.customer_name,
    };
  }
}

export class ShipmentNotificationMetaDTO {
  customer_name?: string;

  static createFromModel(model: ShipmentNotificationMetaModel): ShipmentNotificationMetaDTO {
    return {
      customer_name: model.customerName,
    };
  }
}

export class TriggerListItemDTO {
  id: string;
  tracking_number: string;
  type: NotificationType;
  due_date?: string;
  created_at: string;
  shipment_id: string;
  expected_status: DeliveryStatus;
  meta?: ShipmentNotificationMetaDTO;
}

export class TriggerListItemModel {
  id: string;
  trackingNumber: string;
  type: NotificationType;
  dueDate?: string;
  createdAt: string;
  shipmentId: string;
  expectedStatus: DeliveryStatus;
  meta?: ShipmentNotificationMetaModel;

  static createFromDTO(dto: TriggerListItemDTO): TriggerListItemModel {
    return {
      id: dto.id,
      trackingNumber: dto.tracking_number,
      type: dto.type,
      dueDate: dto.due_date,
      createdAt: dto.created_at,
      shipmentId: dto.shipment_id,
      expectedStatus: dto.expected_status,
      meta: dto.meta && ShipmentNotificationMetaModel.createFromDTO(dto.meta),
    };
  }
}
