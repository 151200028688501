import { Input } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import * as React from 'react';

const decimalPlacesCount = (num: string) => {
  return (num.split('.')[1] || []).length;
};

const currencyParser = (value: string) => {
  const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
  const parsed = parseFloat(value);
  if (!isNaN(parsed) && reg.test(value)) {
    return decimalPlacesCount(value) > 2 ? currencyFormatter(value) : value;
  } else if (value === '') {
    return '';
  }
  return;
};

const currencyFormatter = (value: string): string => {
  const parsed = parseFloat(value) || 0;
  return parsed.toFixed(2);
};

export interface IProps extends InputProps {
  autoFocus?: boolean;
  value?: string;
}

const Component: React.StatelessComponent<IProps> = props => {
  const { ...restProps } = props;

  restProps.onChange = e => {
    const { value } = e.target as any;
    const parsed = currencyParser(value);
    if (props.onChange && parsed != null) {
      props.onChange({ target: { value: parsed } } as any);
    }
  };

  restProps.onBlur = e => {
    const { value } = e.target as any;
    if (props.onChange && value != null) {
      const formatted = currencyFormatter(value);
      props.onChange({ target: { value: formatted } } as any);
    }
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  return <Input {...restProps} />;
};

export default Component;
