import { Input } from 'antd';
import * as React from 'react';
import { classes, stylesheet } from 'typestyle';

import { priceToFixed } from '@src/containers/regions/carrier-products/helpers';

/**
 * Valid prices:
 * 1
 * 10
 * 500000
 * 123,
 * 123.
 * 235.1
 * 642,7
 * 23.40
 * 24,99
 */
const PRICE_REGEXP = /^\d+[.,]?\d{0,2}$/;

interface PriceInputProps {
  value?: string;
  name?: string;
  disabled?: boolean;
  className?: string;
  onChange?: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const PriceInput: React.VFC<PriceInputProps> = ({ value = '', ...props }) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === '' || value.match(PRICE_REGEXP)) {
      props.onChange?.(value);
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    props.onChange?.(priceToFixed(value));
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      props.onChange?.(priceToFixed(value));
    }
    if (props.onKeyDown) {
      props.onKeyDown(event);
    }
  };

  const ownClassName = props.disabled ? styles.disabledInput : styles.input;
  return (
    <Input
      type="string"
      name={props.name}
      className={classes(ownClassName, props.className)}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  );
};

const baseInputStyle = {
  width: '96px',
  height: '32px',
};

const styles = stylesheet({
  input: {
    paddingLeft: '11px',
    ...baseInputStyle,
  },
  disabledInput: {
    cursor: 'not-allowed',
    color: 'rgba(0, 0, 0, 0.4)',
    ...baseInputStyle,
  },
});
