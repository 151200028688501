import { ComponentReturningChildren } from '@src/components';
import { Input, RangePicker } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { widerLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { Field, FieldProps, useFormikContext } from 'formik';
import moment from 'moment';
import { pathOr } from 'ramda';
import * as React from 'react';
import { WidgetFormValues } from '../helpers';
import { FormikInputField } from './fields';

const InputField = withFormItem(Input, widerLayout);
const RangePickerField = withFormItem(RangePicker, widerLayout);
const LabelWrapper = withFormItem(ComponentReturningChildren, widerLayout);

export const Additional: React.FunctionComponent<{ widgetReady: boolean }> = ({ widgetReady }) => {
  const { setFieldValue, values } = useFormikContext<WidgetFormValues>();
  return (
    <>
      <FormikInputField name="voucher" label={<FormattedMessage id="VOUCHER" />} />
      <Field name="shippingDate">
        {({ field }: FieldProps<WidgetFormValues['shippingDate']>) => (
          <RangePickerField
            allowClear={true}
            label={<FormattedMessage id="SHIPPING_DATE" />}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
            value={
              field.value ? [moment(field.value.start), moment(field.value.end)] : [null, null]
            }
            onChange={range => {
              if (range) {
                const [start, end] = range;
                setFieldValue(
                  'shippingDate',
                  start && end ? { start: start.format(), end: end.format() } : undefined
                );
              } else {
                setFieldValue('shippingDate', undefined);
              }
            }}
          />
        )}
      </Field>
      <FormikInputField name="cartId" label={<FormattedMessage id="CART_ID" />} />
      <FormikInputField
        name="cartAttributes"
        label="Cart attributes"
        placeholder="Comma separated values"
      />
      <FormikInputField
        name="externalId"
        label="External ID"
        placeholder="Transport order external ID"
      />
      {!widgetReady && (
        <Field name="meta[0]">
          {() => (
            <LabelWrapper label="Meta">
              <InputField
                placeholder="key"
                value={pathOr('', [0, 'key'], values.meta)}
                onChange={value => {
                  setFieldValue('meta[0].key', value.target.value);
                }}
              />
              <InputField
                placeholder="comma separated values"
                value={pathOr('', [0, 'values'], values.meta)}
                onChange={value => setFieldValue('meta[0].values', value.target.value)}
              />
            </LabelWrapper>
          )}
        </Field>
      )}
      {!widgetReady && (
        <Field name="meta[1]">
          {() => (
            // empty div to align with other fields in form
            <LabelWrapper label={<div />}>
              <InputField
                placeholder="key"
                value={pathOr('', [1, 'key'], values.meta)}
                onChange={value => setFieldValue('meta[1].key', value.target.value)}
              />
              <InputField
                placeholder="comma seprated values"
                value={pathOr('', [1, 'values'], values.meta)}
                onChange={value => setFieldValue('meta[1].values', value.target.value)}
              />
            </LabelWrapper>
          )}
        </Field>
      )}
    </>
  );
};
