import { Modal } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';

import { FormattedMessage } from '@src/i18n';
import { RootState } from '@src/modules';
import { configActions } from '@src/modules/config';
import { getSelectedMerchantId } from '@src/modules/merchants/merchants-selectors';
import { sitesActions, SitesSelectors } from '@src/modules/sites';

const mapStateToProps = (state: RootState) => ({
  error: state.config.error,
  merchantId: getSelectedMerchantId(state),
  siteId: SitesSelectors.getSelectedSiteIdOrEmpty(state),
});

const dispatchProps = {
  getSites: sitesActions.getSitesRequest,
  deleteDraft: configActions.deleteDraftRequest,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;
interface State {
  title: string | React.ReactElement;
  message: string | React.ReactElement;
  okAction: () => void;
  show: boolean;
}
type Actions = 'DRAFT_EXISTS' | 'SITE_OUTDATED' | 'HIDE_MODAL';

export enum RegionError {
  draftExist = 'invalid request: draft already exists',
  outOfSyncDraft = 'invalid request: error when checking draft sync status: master was updated: draft is out of sync',
}

export const Component: React.FunctionComponent<Props> = ({
  error,
  getSites,
  merchantId,
  siteId,
  deleteDraft,
}) => {
  const initialState: State = {
    title: '',
    message: '',
    okAction: () => undefined,
    show: false,
  };

  function reducer(currentState: State, action: Actions): State {
    switch (action) {
      case 'DRAFT_EXISTS':
        return {
          title: <FormattedMessage id="DRAFT_ALREADY_EXISTS" />,
          message: <FormattedMessage id="DRAFT_ALREADY_EXISTS_MSG" />,
          okAction: () => getSites({ merchantId: merchantId!, siteId: siteId! }),
          show: true,
        };
      case 'SITE_OUTDATED':
        return {
          title: <FormattedMessage id="DRAFT_OUT_OF_SYNC" />,
          message: <FormattedMessage id="DRAFT_OUT_OF_SYNC_MSG" />,
          okAction: () => deleteDraft(),
          show: true,
        };
      case 'HIDE_MODAL':
        return initialState;
      default:
        return currentState;
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    switch (error as RegionError) {
      case RegionError.draftExist:
        dispatch('DRAFT_EXISTS');
        break;
      case RegionError.outOfSyncDraft:
        dispatch('SITE_OUTDATED');
        break;
    }
  }, [error]);

  return error ? (
    <Modal
      visible={state.show}
      title={state.title}
      onOk={() => {
        state.okAction();
        dispatch('HIDE_MODAL');
      }}
      onCancel={() => {
        dispatch('HIDE_MODAL');
      }}
    >
      {state.message}
    </Modal>
  ) : null;
};

export const RegionErrorHandlingContainer = connect(mapStateToProps, dispatchProps)(Component);
