import { TrackingStatus } from '@src/models';
import { defaultTheme } from '@src/styles';

export const getColorForStatus = (status: TrackingStatus) => {
  switch (status) {
    case 'ORDER_CREATED':
    case 'SHIPMENT_CREATED':
    case 'LOGISTIC_UNIT_CREATED':
    case 'ORDER_BOOKED':
    case 'PICKUP_BOOKING_SCHEDULED':
    case 'PICKUP_BOOKED':
    case 'LOGISTIC_UNIT_RECEIVED_TRACKING_NUMBER':
    case 'DELIVERY_IN_PROGRESS':
    case 'IN_TRANSIT':
    case 'FORWARDED_TO_THIRD_PARTY':
    case 'IN_CUSTOMS':
    case 'NOTIFICATION_SENT':
    case 'BOOKING_RECEIVED_BY_CARRIER':
    case 'ARRIVED_AT_DISTRIBUTION_TERMINAL':
    case 'RECEIVED_AT_PICKUP_POINT':
    case 'DEPARTED_FROM_PICKUP_POINT':
    case 'TRACKING_NUMBER_REGISTERED':
    case 'LABEL_CREATED_BY_CARRIER':
    case 'RETURN_PICKUP_SCHEDULED':
    case 'CARRIER_PRODUCT_ID_ASSIGNED':
    case 'ARRIVED_AT_PICKUP_POINT':
    case 'PICKED_UP':
    case 'AWAITING_SUPPLIER':
    case 'LINEHAUL_TO_CARRIER':
      return defaultTheme.color.statusPurple;

    case 'RETURNED':
    case 'DELAYED':
    case 'REDIRECTED':
    case 'RETURNED_TO_SENDER':
    case 'PICKUP_DELAYED':
    case 'CONTACT_MERCHANT_SUPPORT':
    case 'CONTACT_CARRIER_SUPPORT':
    case 'ON_HOLD':
    case 'SHIPMENT_CANCELED':
    case 'CANCELED':
    case 'ESTIMATED_DELIVERY_TIME_EXCEEDED':
    case 'ESTIMATED_SUBMISSION_TIME_EXCEEDED':
      return defaultTheme.color.statusOrange;

    case 'DELIVERED':
    case 'NOTIFICATION_DELIVERED':
      return defaultTheme.color.statusGreen;

    case 'UNKNOWN':
    case 'INFO':
    case 'ESTIMATED_DELIVERY_TIME_UPDATED':
    case 'CUSTOM':
    case 'NOTIFICATION_TRIGGERED':
      return defaultTheme.color.statusGrey;

    case 'ERROR':
    case 'FAILED':
    case 'PICKUP_FAILED':
    case 'LOST':
    case 'DAMAGED':
    case 'UNDELIVERABLE':
    case 'NOTIFICATION_FAILED':
      return defaultTheme.color.dotStatusRed;
    default:
      // TODO: unkown status, consider adding some notification/alert for devs (see TRACK-582 for details)
      return defaultTheme.color.statusGrey;
  }
};
