import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';

import { TokenAuthModel } from '../../models';
import { Reducer } from '../../modules';
import * as tokenAuthActions from './actions';

export type State = Readonly<{
  token: TokenAuthModel | null;
  isFetching: boolean;
  error: string | null;
}>;

const token: Reducer<State['token']> = (state = null, action) => {
  switch (action.type) {
    case getType(tokenAuthActions.getAuthTokenSuccess):
      return action.payload;

    case getType(tokenAuthActions.getAuthTokenRequest):
    case getType(tokenAuthActions.getAuthTokenError):
      return null;

    default:
      return state;
  }
};

const isFetching: Reducer<State['isFetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(tokenAuthActions.getAuthTokenRequest):
      return true;

    case getType(tokenAuthActions.getAuthTokenError):
    case getType(tokenAuthActions.getAuthTokenSuccess):
      return false;

    default:
      return state;
  }
};

const error: Reducer<State['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(tokenAuthActions.getAuthTokenError):
      return action.payload;

    case getType(tokenAuthActions.getAuthTokenSuccess):
    case getType(tokenAuthActions.getAuthTokenRequest):
      return null;

    default:
      return state;
  }
};

const reducer: Reducer<State> = combineReducers({
  token,
  isFetching,
  error,
});

export default reducer;
