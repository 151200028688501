import * as css from 'csstips';
import * as React from 'react';
import { style as tss } from 'typestyle';

type Props = {
  style?: React.CSSProperties;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  actions?: JSX.Element;
  badge?: string | JSX.Element;
  above?: string | JSX.Element;
  below?: string | JSX.Element;
};

const styles = {
  root: tss(css.vertical, { paddingBottom: 20 }),
  titleRow: tss(css.horizontal, css.betweenJustified),
  titleColumn: tss(css.vertical, css.verticallySpaced(0)),
  titleSlug: tss(css.horizontal, css.horizontallySpaced(10)),
  badge: tss({}),
  subtitleRow: tss({ paddingBottom: 10 }),
  actions: tss({ display: 'flex' }),
};

const Component: React.StatelessComponent<Props> = ({
  style,
  title,
  subtitle,
  actions,
  badge,
  above,
  below,
}) => {
  return (
    <header className={styles.root} style={style}>
      <div className={styles.titleRow}>
        <div className={styles.titleColumn}>
          <div>{above}</div>
          <div className={styles.titleSlug}>
            <h2>{title}</h2>
            <div className={styles.badge}>{badge}</div>
          </div>
        </div>
        <div className={styles.actions}>{actions}</div>
      </div>
      <div className={styles.subtitleRow}>{subtitle}</div>
      <div>{below}</div>
    </header>
  );
};

export default Component;
