import * as React from 'react';
import { Modal as AntModal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

interface OwnProps extends ModalProps {
  content: React.ReactNode | string;
  onOk: () => void;
  children: React.ReactElement;
}

const ModalPopover: React.FC<OwnProps> = ({ content, onOk, children, ...restProps }) => {
  const [visible, setVisible] = React.useState(false);

  const button = React.cloneElement(children, {
    onClick: () => setVisible(true),
  });

  return (
    <>
      {button}
      <AntModal
        {...restProps}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => {
          setVisible(false);
          onOk();
        }}
      >
        {content}
      </AntModal>
    </>
  );
};

export default ModalPopover;
