import { all, flatten } from 'ramda';
import { isNotEmpty, isTruthy } from 'ramda-adjunct';
import { createSelector } from 'reselect';

import { stringUtils } from '@src/utils';

import { RootState } from '../root-reducer';

const getLabelMergerState = (state: RootState) => state.labelMerger;

export const getLabelsFileNames = (state: RootState, id: string) => {
  const shipment = state.shipments.byId[id];
  return shipment && shipment.parcels
    ? flatten(shipment.parcels.map(parcel => parcel.deliveries)).map(
        delivery => delivery.labelUrl && stringUtils.getFileStem(delivery.labelUrl) + '.pdf'
      )
    : [];
};

export const getCanMerge = (state: RootState, id: string) => {
  const labels = getLabelsFileNames(state, id);
  return isNotEmpty(labels) && all(isTruthy)(labels);
};

export const getIsFetchingMergedLabel = createSelector(
  getLabelMergerState,
  labelMerger => labelMerger.isFetching
);

export const getMergedLabel = createSelector(
  getLabelMergerState,
  labelMerger => labelMerger.merged
);
