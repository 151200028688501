import { ArrowRightOutlined, CopyOutlined, RollbackOutlined } from '@ant-design/icons';
import { TableProps } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import * as React from 'react';
import * as CopyToClipboard from 'react-copy-to-clipboard';
import { stylesheet } from 'typestyle';

import { ShipmentStatusTag } from '@src/containers/shipments/components';
import { Direction } from '@src/containers/tracking-numbers/types';
import { Spinner, Table, Tooltip } from '@src/controls';
import { MESSAGES } from '@src/dictionaries';
import { ShipmentSummaryModel } from '@src/models';
import { services } from '@src/services';
import { stringUtils } from '@src/utils';
import { dateSorter } from '@src/utils/sorting';
import { capitalize } from '@src/utils/string';

interface Props {
  isLoading?: boolean;
  items: ShipmentSummaryModel[];
  pagination?: {};
  onChange?: TableProps<ShipmentSummaryModel>['onChange'];
  className?: string;
  rowSelection?: TableRowSelection<ShipmentSummaryModel>;
  sortingState: { order: 'descend' | 'ascend'; columnKey: string };
  getShippingMethodName: (record: ShipmentSummaryModel) => string;
  isFetchingStatuses?: boolean;
  onRowClick: (id: string) => void;
}

const getShipmentSSCCHeader = (record: ShipmentSummaryModel) => {
  return record.trackingNumbers.map(trackingNumber => (
    <div key={trackingNumber} className={styles.flexElement}>
      {trackingNumber ? (
        <Tooltip title={trackingNumber}>
          {stringUtils.truncateId(trackingNumber)}
          <CopyToClipboard
            text={trackingNumber}
            onCopy={() =>
              services.messageService.success(`Tracking Number (SSCC) copied to clipboard.`)
            }
          >
            <CopyOutlined
              className={styles.icon}
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
            />
          </CopyToClipboard>
        </Tooltip>
      ) : null}
    </div>
  ));
};

const getCreatedAt = (record: ShipmentSummaryModel) => {
  const createdAt = record.createdAt;
  return createdAt ? new Date(createdAt).toLocaleString() : '';
};

export const ShipmentsTable: React.FunctionComponent<Props> = ({
  isLoading,
  items,
  pagination,
  onChange,
  className,
  rowSelection,
  sortingState,
  getShippingMethodName,
  isFetchingStatuses,
  onRowClick,
}) => {
  const getEmptyMessage = () =>
    isLoading ? <Spinner delay={0} active={isLoading} /> : MESSAGES.EMPTY_SHIPMENTS_LIST;

  return (
    <Table<ShipmentSummaryModel>
      loading={isLoading}
      dataSource={items}
      rowKey={record => record.id}
      pagination={pagination}
      onChange={onChange}
      emptyMessage={getEmptyMessage()}
      className={className}
      rowSelection={rowSelection}
      hideTitle={true}
      sortDirections={['ascend', 'descend']}
      onRow={record => ({
        onClick: () => onRowClick(record.id),
        className: styles.clickableElement,
      })}
    >
      <Table.Column<ShipmentSummaryModel>
        key="directionType"
        width={20}
        render={(_, record) => getDirectionType(record?.directionType)}
      />
      <Table.Column<ShipmentSummaryModel>
        title="SSCC"
        key="sscc"
        width="180px"
        render={(text, record) => getShipmentSSCCHeader(record)}
      />
      <Table.Column<ShipmentSummaryModel>
        title="Created at"
        key="createdAt"
        width="230px"
        render={(text, record) => getCreatedAt(record)}
        sorter={(recordA, recordB) => dateSorter(recordA.createdAt, recordB.createdAt)}
        sortOrder={sortingState.columnKey === 'createdAt' ? sortingState.order : undefined}
      />
      <Table.Column<ShipmentSummaryModel>
        title="Sender Name"
        key="addressFrom"
        width="150px"
        render={(text, record) => (record.addressFrom && record.addressFrom.name) || ''}
      />
      <Table.Column<ShipmentSummaryModel>
        title="Recipient"
        key="customerInfo"
        width="150px"
        render={(text, record) => (record.customerInfo && record.customerInfo.address.name) || ''}
      />
      <Table.Column<ShipmentSummaryModel>
        title="Delivery Address Name"
        key="addressTo"
        width="150px"
        render={(text, record) => (record.addressTo && record.addressTo.name) || ''}
      />
      <Table.Column<ShipmentSummaryModel>
        title="Shipping date"
        key="shippingDate"
        width="170px"
        render={(text, record) =>
          (record.shippingDate && new Date(record.shippingDate).toLocaleString()) || ''
        }
      />
      <Table.Column<ShipmentSummaryModel>
        title="Carrier Product"
        key="carrierProduct"
        width="140px"
        render={(text, record) => getShippingMethodName(record)}
      />
      <Table.Column<ShipmentSummaryModel>
        title="Status"
        key="status"
        width="80px"
        render={(text, record) =>
          isFetchingStatuses ? (
            <Spinner delay={0} active={true} />
          ) : (
            <ShipmentStatusTag status={record.status} />
          )
        }
      />
    </Table>
  );
};

const styles = stylesheet({
  clickableElement: {
    cursor: 'pointer',
    $nest: {
      '& .anticon-copy': {
        opacity: 0,
      },
      '& :hover .anticon-copy': {
        opacity: 1,
      },
    },
  },
  flexElement: {
    $nest: {
      span: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  },
  icon: {
    fontSize: '16px',
  },
});

const getDirectionType = (directionType?: Direction) => {
  switch (directionType) {
    case 'OUTBOUND':
      return (
        <Tooltip placement="top" title={capitalize(directionType.toLocaleLowerCase())}>
          <ArrowRightOutlined height={16} width={16} />
        </Tooltip>
      );

    case 'RETURN':
      return (
        <Tooltip placement="top" title={capitalize(directionType.toLocaleLowerCase())}>
          <RollbackOutlined height={16} width={16} />
        </Tooltip>
      );

    default:
      return null;
  }
};
