import { useIntl } from 'react-intl';
import * as enUSMessages from '../../locales/en-US.json';

export const useFormatMessage = () => {
  const { formatMessage } = useIntl();

  // simplify formatMessage API
  const simpleFormatMessage = (id: keyof typeof enUSMessages, values?: { [key: string]: any }) =>
    formatMessage({ id }, { multiple: false, count: '', ...values });

  return simpleFormatMessage;
};
