import { Col, Row } from 'antd';
import { FormState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Label } from '../../../controls';
import { AddressPanel, AddressSelectorFormState } from '../../../forms';
import { ContactModel, TransportOrderModel } from '../../../models';

export class TransportOrderAddressSelectorFormState extends FormState<{
  fromAddressSelectorFormState: AddressSelectorFormState;
  toAddressSelectorFormState: AddressSelectorFormState;
}> {
  static create = (): TransportOrderAddressSelectorFormState => {
    return new FormState({
      fromAddressSelectorFormState: AddressSelectorFormState.create(),
      toAddressSelectorFormState: AddressSelectorFormState.create(),
    });
  };

  static createWithData = (
    transportOrder?: TransportOrderModel,
    contactsList?: ContactModel[]
  ): TransportOrderAddressSelectorFormState => {
    const newFormState = TransportOrderAddressSelectorFormState.create();

    if (transportOrder == null || contactsList == null) {
      return newFormState;
    }

    const { fromAddressSelectorFormState, toAddressSelectorFormState } = newFormState.$;

    const selectedSender = contactsList.find(
      item =>
        item.id === transportOrder.sender.contactInfo.externalId ||
        item.address.name === transportOrder.sender.contactInfo.address.name
    );

    if (selectedSender) {
      fromAddressSelectorFormState.$.addressIdFieldState.value = selectedSender.id;
    } else {
      const senderContactModel = new ContactModel();
      senderContactModel.id = transportOrder.sender.contactInfo.externalId || senderContactModel.id;
      senderContactModel.address = transportOrder.sender.contactInfo.address;
      senderContactModel.email = transportOrder.sender.contactInfo.email;
      senderContactModel.phone = transportOrder.sender.contactInfo.phone;
      transportOrder.sender.contactInfo.externalId = senderContactModel.id;

      contactsList.push(senderContactModel);
      fromAddressSelectorFormState.$.addressIdFieldState.value = senderContactModel.id;
    }

    const selectedReceiver = contactsList.find(
      item =>
        item.id === transportOrder.receiver.contactInfo.externalId ||
        item.address.name === transportOrder.receiver.contactInfo.address.name
    );

    if (selectedReceiver) {
      toAddressSelectorFormState.$.addressIdFieldState.value = selectedReceiver.id;
    } else {
      const receiverContactModel = new ContactModel();
      receiverContactModel.id =
        transportOrder.receiver.contactInfo.externalId || receiverContactModel.id;
      receiverContactModel.address = transportOrder.receiver.contactInfo.address;
      receiverContactModel.email = transportOrder.receiver.contactInfo.email;
      receiverContactModel.phone = transportOrder.receiver.contactInfo.phone;
      transportOrder.receiver.contactInfo.externalId = receiverContactModel.id;
      contactsList.push(receiverContactModel);

      toAddressSelectorFormState.$.addressIdFieldState.value = receiverContactModel.id;
    }

    return newFormState;
  };
}

// COMPONENT

type Props = {
  formState: TransportOrderAddressSelectorFormState;
  contacts: ContactModel[];
  onCreateContact: (contact: ContactModel) => void;
  onUpdateContact: (contact: ContactModel) => void;
  onSearchContact: (searchQuery: string) => void;
  isFetching?: boolean;
  detailsVisible?: boolean;
  autoFocus?: boolean;
};

@observer
export class TransportOrderAddressSection extends React.Component<Props, {}> {
  render() {
    const { contacts, onCreateContact, onUpdateContact, isFetching } = this.props;
    const { fromAddressSelectorFormState, toAddressSelectorFormState } = this.props.formState.$;

    return (
      <section>
        <Row justify="center" align="top">
          <Col span={12}>
            <Label text="Sender Address" />
            <AddressPanel
              detailsVisible={true}
              isFetching={isFetching}
              formState={fromAddressSelectorFormState}
              onCreateContact={onCreateContact}
              onUpdateContact={onUpdateContact}
              contacts={contacts}
              filterType="consignor"
            />
          </Col>
          <Col span={12}>
            <Label text="Delivery Address" />
            <AddressPanel
              detailsVisible={true}
              isFetching={isFetching}
              formState={toAddressSelectorFormState}
              onCreateContact={onCreateContact}
              onUpdateContact={onUpdateContact}
              contacts={contacts}
              filterType="delivery"
            />
          </Col>
        </Row>
      </section>
    );
  }
}
