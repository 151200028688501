import * as Sentry from '@sentry/react';

import { UserGetModel } from '@src/models';
import { getEnvironmentalVariables } from '@src/services/env-service';
import { MADError } from '@src/utils';

const env = getEnvironmentalVariables();

const IGNORED_ERRORS = [
  'Error: missing auth token',
  'Error: At least one database, enterprise or social connection needs to be available.',
  'Error: unauthenticated: invalid token: Token is expired',
];

const initErrorTracking = () => {
  switch (env.MAD_DEPLOY_TARGET) {
    case 'stage':
      Sentry.init({
        dsn: env.MAD_SENTRY_DSN,
        environment: env.MAD_DEPLOY_TARGET,
        release: env.MAD_APP_VERSION,
        ignoreErrors: IGNORED_ERRORS,
      });
      break;

    case 'production':
      Sentry.init({
        dsn: env.MAD_SENTRY_DSN,
        environment: env.MAD_DEPLOY_TARGET,
        release: env.MAD_APP_VERSION,
        ignoreErrors: IGNORED_ERRORS,
      });

      break;

    default:
      return;
  }
};

export const sendError = (error: MADError, info?: React.ErrorInfo) => {
  Sentry.withScope(scope => {
    scope.setExtra('traceId', error.traceId || '');
    if (info) {
      scope.setExtra('componentStack', info.componentStack);
    }
    Sentry.captureException(error);
  });
};

export const setUserContext = (user: UserGetModel) => {
  const userRole = user.superUser ? 'superUser' : UserGetModel.getUserGroup(user.groups).name;
  Sentry.configureScope(scope => {
    scope.setTag(`userRole`, `${userRole}`);
    scope.setUser({
      id: user.id,
      username: user.name,
      email: user.email,
    });
  });
};

export const clearUserContext = () =>
  Sentry.configureScope(scope => {
    scope.clear();
  });

initErrorTracking();
