import { BellOutlined } from '@ant-design/icons';
import { Badge, Popover, Tabs } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { style as tss } from 'typestyle';

import { TabPane } from '@src/controls';
import { RootState } from '@src/modules';
import { shipmentNotificationsSelectors } from '@src/modules/shipment-notifications';
import { NotificationsListContainer } from './notifications-list-container';
import { TriggersListContainer } from './triggers-list-container';

const mapStateToProps = (state: RootState) => ({
  thereIsNoUnreadNotifications: shipmentNotificationsSelectors.thereIsNoUnreadNotifications(state),
});

type Props = ReturnType<typeof mapStateToProps>;

class Component extends React.Component<Props> {
  render() {
    const { thereIsNoUnreadNotifications } = this.props;
    return (
      <div className={styles.root}>
        <Popover
          trigger="click"
          content={
            <Tabs defaultActiveKey="1" tabBarStyle={{ marginBottom: '5px' }}>
              <TabPane tab="Notifications" key="1">
                <NotificationsListContainer />
              </TabPane>
              <TabPane tab="Pending" key="2">
                <TriggersListContainer />
              </TabPane>
            </Tabs>
          }
          placement="bottomRight"
          arrowPointAtCenter={true}
          getPopupContainer={e => e!.parentElement!}
          overlayClassName={styles.popover}
        >
          <Badge dot={!thereIsNoUnreadNotifications}>
            <BellOutlined style={{ fontSize: '23px', color: '#fff' }} />
          </Badge>
        </Popover>
      </div>
    );
  }
}

const styles = {
  root: tss({
    position: 'relative',
    cursor: 'pointer',
    marginRight: '20px',
  }),
  popover: tss({
    width: '500px',
    $nest: {
      '& .ant-popover-inner-content': {
        padding: '0 12px 12px 12px',
      },
    },
  }),
};

export const ShipmentNotificationsContainer = connect(mapStateToProps)(Component);
