import { useState } from 'react';
import { head } from 'ramda';
import { useMutation } from 'react-query';
import { fetchByExternalIdOrTrackingNumber } from '@src/services/tracking-numbers-service';
import { useMasterSiteId } from '@src/utils/hooks';
import { FormValues } from './types';

export type FindTrackingData = {
  trackingNumbers: string[];
  externalId: string;
  contact: string;
  tosId: string | undefined;
  trackingIdentifier: 'externalId' | 'trackingNumber';
};

export const useFindTracking = () => {
  const siteId = useMasterSiteId();

  const [trackingData, setTrackingData] = useState<FindTrackingData | undefined>(undefined);

  const { mutate, isLoading } = useMutation({
    mutationKey: ['find-tracking-data', siteId],
    mutationFn: ({ trackingNumber, externalId }: FormValues) => {
      if (externalId) {
        return fetchByExternalIdOrTrackingNumber(externalId, siteId, true);
      }

      return fetchByExternalIdOrTrackingNumber(trackingNumber, siteId);
    },
    onSuccess: ({ tracking_records }, formValues) => {
      const trackingRecord = head(tracking_records ?? []);

      const trackingNumbers = tracking_records?.map(({ tracking_number }) => tracking_number) ?? [];
      const externalId = trackingRecord?.external_id ?? formValues.externalId;
      const tosId = trackingRecord?.tos_id;

      setTrackingData({
        trackingNumbers,
        externalId,
        contact: formValues.contact,
        trackingIdentifier: formValues.externalId ? 'externalId' : 'trackingNumber',
        tosId,
      });
    },
  });

  return [trackingData, mutate, isLoading] as const;
};
