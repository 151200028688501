import { Tooltip } from 'antd';
import React, { CSSProperties, FC, Fragment } from 'react';
import { style } from 'typestyle';

type Props = {
  isDisabled: boolean;
  tooltipText: string;
};

export const WithTooltip: FC<Props> = ({ isDisabled, tooltipText, children }) => {
  if (isDisabled) {
    return (
      <Tooltip
        autoAdjustOverflow={false}
        title={tooltipText}
        overlayInnerStyle={overlayInnerStyle}
        overlayClassName={overlayClass}
        placement="top"
      >
        {children}
      </Tooltip>
    );
  }

  return <Fragment>{children}</Fragment>;
};

const overlayClass = style({
  $nest: {
    '.ant-tooltip-arrow': {
      display: 'none',
    },
  },
});

const overlayInnerStyle: CSSProperties = {
  whiteSpace: 'nowrap',
  width: 'min-content',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  padding: '4px 6px',
  borderRadius: '4px',
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '18px',
  minHeight: 'unset',
};
