import { withFormItem } from '@src/decorators';
import { PersistEnhancer, widerLayout } from '@src/forms';
import { useFormatMessage } from '@src/i18n';
import { getFormikError } from '@src/utils/forms';
import { Collapse, Input, Select } from 'antd';
import { Field, FieldProps, Form, useFormikContext } from 'formik';
import React from 'react';
import { FormValues } from './testclient';

export const InputField = withFormItem(Input, widerLayout);
export const SelectField = withFormItem(Select, widerLayout);

export const ConfigurationForm = () => {
  const { touched, errors, setFieldValue } = useFormikContext<FormValues>();
  const formatMessage = useFormatMessage();

  return (
    <Form id="receipt-widget">
      <PersistEnhancer localStorageKey="receipt-widget-form" />
      <Collapse defaultActiveKey={['1']}>
        <Collapse.Panel header={formatMessage('GENERAL')} key="1">
          <Field name="sessionId">
            {({ field }: FieldProps<FormValues['sessionId']>) => (
              <InputField
                {...field}
                required
                label={formatMessage('SESSION_ID')}
                error={getFormikError(touched.sessionId, errors.sessionId)}
                onChange={event => setFieldValue(field.name, event.target.value.trim())}
              />
            )}
          </Field>
          <Field name="locale">
            {({ field }: FieldProps<FormValues['locale']>) => (
              <SelectField
                {...field}
                required
                label={formatMessage('LABEL')}
                options={[
                  { label: 'en-US', value: 'en-US' },
                  { label: 'en-GB', value: 'en-GB' },
                  { label: 'sv-SE', value: 'sv-SE' },
                ]}
                filterOption={true}
                showSearch
                optionFilterProp={'label'}
                onChange={value => setFieldValue(field.name, value)}
                error={getFormikError(touched.locale, errors.locale)}
                getPopupContainer={() =>
                  document.getElementById('select-scroll-helper') || document.body
                }
              />
            )}
          </Field>
        </Collapse.Panel>
      </Collapse>
    </Form>
  );
};
