import { createAction } from 'typesafe-actions';

import { SessionEventModel } from '@src/models';

const LIST_SESSION_EVENTS_REQUEST = '@sessionHistory/LIST_SESSION_EVENTS_REQUEST';
const LIST_SESSION_EVENTS_SUCCESS = '@sessionHistory/LIST_SESSION_EVENTS_SUCCESS';
const LIST_SESSION_EVENTS_ERROR = '@sessionHistory/LIST_SESSION_EVENTS_ERROR';

export const listSessionEventsRequest = createAction(
  LIST_SESSION_EVENTS_REQUEST,
  (payload: { sessionID: string }) => payload
)();

export const listSessionEventsError = createAction(
  LIST_SESSION_EVENTS_ERROR,
  (error: string) => error
)();

export const listSessionEventsSuccess = createAction(
  LIST_SESSION_EVENTS_SUCCESS,
  (payload: { events: SessionEventModel[]; rawEvents: any[] }) => payload
)();
