import {
  ShipmentIdsByTagModel,
  ShipmentSummaryModel,
  SiteTagModel,
  UserTagModel,
} from '@src/models';
import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;

const CREATE_SITE_TAG_REQUEST = '@tags/CREATE_SITE_TAG_REQUEST';
const CREATE_SITE_TAG_SUCCESS = '@tags/CREATE_SITE_TAG_REQUEST_SUCCESS';
const CREATE_SITE_TAG_ERROR = '@tags/CREATE_SITE_TAG_REQUEST_ERROR';
const LIST_SITE_TAGS_REQUEST = '@tags/LIST_SITE_TAGS_REQUEST';
const LIST_SITE_TAGS_SUCCESS = '@tags/LIST_SITE_TAGS_SUCCESS';
const LIST_SITE_TAGS_ERROR = '@tags/LIST_SITE_TAGS_ERROR';
const LIST_SITE_TAGS_PER_USER_REQUEST = '@tags/LIST_SITE_TAGS_PER_USER_REQUEST';
const LIST_SITE_TAGS_PER_USER_SUCCESS = '@tags/LIST_SITE_TAGS_PER_USER_SUCCESS';
const LIST_SITE_TAGS_PER_USER_ERROR = '@tags/LIST_SITE_TAGS_PER_USER_ERROR';
const DELETE_SITE_TAGS_REQUEST = '@tags/DELETE_SITE_TAGS_REQUEST';
const DELETE_SITE_TAGS_SUCCESS = '@tags/DELETE_SITE_TAGS_SUCCESS';
const DELETE_SITE_TAGS_ERROR = '@tags/DELETE_SITE_TAGS_ERROR';
const RENAME_SITE_TAG_REQUEST = '@tags/RENAME_SITE_TAG_REQUEST';
const RENAME_SITE_TAG_SUCCESS = '@tags/RENAME_SITE_TAG_SUCCESS';
const RENAME_SITE_TAG_ERROR = '@tags/RENAME_SITE_TAG_ERROR';

const LIST_USER_TAGS_REQUEST = '@tags/LIST_USER_TAGS_REQUEST';
const LIST_USER_TAGS_SUCCESS = '@tags/LIST_USER_TAGS_SUCCESS';
const LIST_USER_TAGS_ERROR = '@tags/LIST_USER_TAGS_ERROR';
const UPDATE_USER_WITH_TAGS_REQUEST = '@tags/UPDATE_USER_WITH_TAGS_REQUEST';
const UPDATE_USER_WITH_TAGS_ERROR = '@tags/UPDATE_USER_WITH_TAGS_ERROR';
const UPDATE_USER_WITH_TAGS_SUCCESS = '@tags/UPDATE_USER_WITH_TAGS_SUCCESS';

const ADD_TAGS_TO_SHIPMENTS_REQUEST = '@tags/ADD_TAGS_TO_SHIPMENTS_REQUEST';
const ADD_TAGS_TO_SHIPMENTS_SUCCESS = '@tags/ADD_TAGS_TO_SHIPMENTS_SUCCESS';
const ADD_TAGS_TO_SHIPMENTS_ERROR = '@tags/ADD_TAGS_TO_SHIPMENTS_ERROR';

const REMOVE_TAG_FROM_SHIPMENTS_REQUEST = '@tags/REMOVE_TAG_FROM_SHIPMENTS_REQUEST';
const REMOVE_TAG_FROM_SHIPMENTS_SUCCESS = '@tags/REMOVE_TAG_FROM_SHIPMENTS_SUCCESS';
const REMOVE_TAG_FROM_SHIPMENTS_ERROR = '@tags/REMOVE_TAG_FROM_SHIPMENTS_ERROR';

const GET_SHIPMENTS_BY_TAGS_REQUEST = '@tags/GET_SHIPMENTS_BY_TAGS_REQUEST';
const GET_SHIPMENTS_BY_TAGS_SUCCESS = '@tags/GET_SHIPMENTS_BY_TAGS_SUCCESS';
const GET_SHIPMENTS_BY_TAGS_ERROR = '@tags/GET_SHIPMENTS_BY_TAGS_ERROR';

const GET_SHIPMENT_IDS_BY_TAGS_REQUEST = '@tags/GET_SHIPMENT_IDS_BY_TAGS_REQUEST';
const GET_SHIPMENT_IDS_BY_TAGS_SUCCESS = '@tags/GET_SHIPMENT_IDS_BY_TAGS_SUCCESS';
const GET_SHIPMENT_IDS_BY_TAGS_ERROR = '@tags/GET_SHIPMENT_IDS_BY_TAGS_ERROR';
const RESET_TAGGED_SHIPMENTS = '@tags/RESET_TAGGED_SHIPMENTS';

const GET_SHIPMENTS_BY_TAG_ID_REQUEST = '@tags/GET_SHIPMENTS_BY_TAG_ID_REQUEST';
const GET_SHIPMENTS_BY_TAG_ID_SUCCESS = '@tags/GET_SHIPMENTS_BY_TAG_ID_SUCCESS';
const GET_SHIPMENTS_BY_TAG_ID_ERROR = '@tags/GET_SHIPMENTS_BY_TAG_ID_ERROR';

export const getShipmentsByTagIdRequest = createAction(
  GET_SHIPMENTS_BY_TAG_ID_REQUEST,
  resolve => (payload: { siteId: string; tagId: string; shipmentIds: string[] }) => resolve(payload)
);

export const getShipmentsByTagIdSuccess = createAction(
  GET_SHIPMENTS_BY_TAG_ID_SUCCESS,
  resolve => (payload: { shipments: ShipmentSummaryModel[]; tagId: string }) => resolve(payload)
);

export const getShipmentsByTagIdError = createAction(
  GET_SHIPMENTS_BY_TAG_ID_ERROR,
  resolve => (error: string) => resolve(error)
);

export const resetTaggedShipments = createAction(RESET_TAGGED_SHIPMENTS);

export const getShipmentIdsByTagsRequest = createAction(
  GET_SHIPMENT_IDS_BY_TAGS_REQUEST,
  resolve =>
    ({
      onComplete = () => null,
      ...payload
    }: {
      siteId: string;
      tags: string[];
      onComplete?: (arg: ShipmentIdsByTagModel[]) => void;
    }) =>
      resolve(payload, { onComplete })
);
export const getShipmentIdsByTagsSuccess = createAction(
  GET_SHIPMENT_IDS_BY_TAGS_SUCCESS,
  resolve => (shipmentIds: ShipmentIdsByTagModel[]) => resolve(shipmentIds)
);
export const getShipmentIdsByTagsError = createAction(
  GET_SHIPMENT_IDS_BY_TAGS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const createSiteTagRequest = createAction(
  CREATE_SITE_TAG_REQUEST,
  resolve => (payload: { siteId: string; name: string }) => resolve(payload)
);
export const createSiteTagSuccess = createAction(
  CREATE_SITE_TAG_SUCCESS,
  resolve => (siteTag: SiteTagModel) => resolve(siteTag)
);
export const createSiteTagError = createAction(
  CREATE_SITE_TAG_ERROR,
  resolve => (error: string) => resolve(error)
);

export const listSiteTagsRequest = createAction(
  LIST_SITE_TAGS_REQUEST,
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      userId?: string;
      onComplete?: (arg: SiteTagModel[]) => void;
    }) =>
      resolve(payload, { onComplete })
);
export const listSiteTagsSuccess = createAction(
  LIST_SITE_TAGS_SUCCESS,
  resolve => (siteTags: SiteTagModel[]) => resolve(siteTags)
);
export const listSiteTagsError = createAction(
  LIST_SITE_TAGS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const listSiteTagsPerUserRequest = createAction(
  LIST_SITE_TAGS_PER_USER_REQUEST,
  resolve => (payload: { siteId: string; userId?: string }) => resolve(payload)
);
export const listSiteTagsPerUserSuccess = createAction(
  LIST_SITE_TAGS_PER_USER_SUCCESS,
  resolve => (siteTags: SiteTagModel[]) => resolve(siteTags)
);
export const listSiteTagsPerUserError = createAction(
  LIST_SITE_TAGS_PER_USER_ERROR,
  resolve => (error: string) => resolve(error)
);

export const deleteSiteTagsRequest = createAction(
  DELETE_SITE_TAGS_REQUEST,
  resolve => (payload: { siteId: string; ids: string[] }) => resolve(payload)
);
export const deleteSiteTagsSuccess = createAction(
  DELETE_SITE_TAGS_SUCCESS,
  resolve => (payload: { ids: string[] }) => resolve(payload)
);
export const deleteSiteTagsError = createAction(
  DELETE_SITE_TAGS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const renameSiteTagRequest = createAction(
  RENAME_SITE_TAG_REQUEST,
  resolve => (payload: { siteId: string; name: string; id: string }) => resolve(payload)
);
export const renameSiteTagSuccess = createAction(
  RENAME_SITE_TAG_SUCCESS,
  resolve => (userTag: UserTagModel) => resolve(userTag)
);
export const renameSiteTagError = createAction(
  RENAME_SITE_TAG_ERROR,
  resolve => (error: string) => resolve(error)
);

export const listUserTagsRequest = createAction(
  LIST_USER_TAGS_REQUEST,
  resolve =>
    ({
      siteId,
      onComplete = () => null,
    }: {
      siteId: string;
      onComplete?: (arg: UserTagModel[]) => void;
    }) =>
      resolve({ siteId }, { onComplete })
);
export const listUserTagsSuccess = createAction(
  LIST_USER_TAGS_SUCCESS,
  resolve => (userTags: UserTagModel[]) => resolve(userTags)
);
export const listUserTagsError = createAction(
  LIST_USER_TAGS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const updateUserWithTagsRequest = createAction(
  UPDATE_USER_WITH_TAGS_REQUEST,
  resolve => (payload: { userId: string; tagsIds: string[]; siteId: string }) => resolve(payload)
);
export const updateUserWithTagsSuccess = createAction(UPDATE_USER_WITH_TAGS_SUCCESS);
export const updateUserWithTagsError = createAction(
  UPDATE_USER_WITH_TAGS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const addTagsToShipmentsRequest = createAction(
  ADD_TAGS_TO_SHIPMENTS_REQUEST,
  resolve =>
    ({
      onComplete = () => null,
      ...payload
    }: {
      siteId: string;
      tagIds: string[];
      shipmentIds: string[];
      onComplete?: () => void;
    }) =>
      resolve(payload, { onComplete })
);
export const addTagsToShipmentsSuccess = createAction(ADD_TAGS_TO_SHIPMENTS_SUCCESS);
export const addTagsToShipmentsError = createAction(
  ADD_TAGS_TO_SHIPMENTS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const removeTagFromShipmentsRequest = createAction(
  REMOVE_TAG_FROM_SHIPMENTS_REQUEST,
  resolve =>
    ({
      onComplete,
      ...payload
    }: {
      siteId: string;
      tagId: string;
      shipmentIds: string[];
      onComplete: () => void;
    }) =>
      resolve(payload, { onComplete })
);
export const removeTagFromShipmentsSuccess = createAction(REMOVE_TAG_FROM_SHIPMENTS_SUCCESS);
export const removeTagFromShipmentsError = createAction(
  REMOVE_TAG_FROM_SHIPMENTS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const getShipmentsByTagsRequest = createAction(
  GET_SHIPMENTS_BY_TAGS_REQUEST,
  resolve => (payload: { siteId: string; tagIds: string[] }) => resolve(payload)
);
export const getShipmentsByTagsSuccess = createAction(
  GET_SHIPMENTS_BY_TAGS_SUCCESS,
  resolve =>
    (
      payload: {
        tag: UserTagModel;
        shipmentIds: string[];
      }[]
    ) =>
      resolve(payload)
);
export const getShipmentsByTagsError = createAction(
  GET_SHIPMENTS_BY_TAGS_ERROR,
  resolve => (error: string) => resolve(error)
);
