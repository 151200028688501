import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { classes, stylesheet } from 'typestyle';

import { Route } from '@src/modules/router/types';
import { getFullPathForRoute } from '@src/modules/router/utils';
import themeDefault from '@src/styles/theme.default';
import { LinkIcon } from './icons';

interface LinkProps {
  route: Route;
  showIcon?: boolean;
  className?: string;
  baseStyles?: boolean;
  onClick?: () => void;
  usePrimaryColors?: boolean;
  disabled?: boolean;
}

export const Link: React.FC<LinkProps> = ({
  route,
  children,
  showIcon = false,
  baseStyles = false,
  usePrimaryColors = false,
  disabled = false,
  className,
  onClick,
  ...props
}) => {
  const path = getFullPathForRoute(route);

  return (
    <RouterLink
      to={{ pathname: path, state: route.additionalState }}
      className={classes(
        baseStyles && styles.link,
        className,
        usePrimaryColors ? styles.primaryColors : styles.defaultColors,
        disabled ? styles.disabledLink : null
      )}
      onClick={disabled ? e => e.preventDefault() : onClick}
      // We have to spread out props so tooltips can be shown over links
      {...props}
    >
      {showIcon && (
        <LinkIcon
          className={styles.icon}
          fill={usePrimaryColors ? themeDefault.color.primary : 'rgba(0, 0, 0, 0.75)'}
        />
      )}
      {children}
    </RouterLink>
  );
};

interface LinkStyleButtonProps {
  onClick: () => void;
  className?: string;
  showIcon?: boolean;
}

// Use for links that send you to a different context but on same url, like opening a modal
export const LinkStyleButton: React.FC<LinkStyleButtonProps> = ({
  children,
  onClick,
  className,
  showIcon = true,
}) => {
  return (
    <span className={classes(styles.link, className)} onClick={onClick}>
      {showIcon && <LinkIcon className={styles.icon} />}
      {children}
    </span>
  );
};

const styles = stylesheet({
  link: {
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    cursor: 'pointer',
    fontWeight: 500,
    $nest: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  disabledLink: {
    cursor: 'not-allowed',
  },
  defaultColors: {
    $nest: {
      '&:hover': {
        color: 'rgba(0, 0, 0, 0.75)',
      },
    },
    color: 'rgba(0, 0, 0, 0.75)',
  },
  primaryColors: {
    $nest: {
      '&:hover': {
        color: themeDefault.color.primary,
      },
    },
    color: themeDefault.color.primary,
  },
  icon: {
    marginRight: '10px',
  },
});
