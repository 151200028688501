import { ProjectOutlined } from '@ant-design/icons';
import React, { FC, Fragment } from 'react';
import { ContainerContent, ContainerFixedHeader } from '@src/components';
import { routerActions } from '@src/modules/router';
import { useDispatch } from 'react-redux';
import { LABELS } from '@src/dictionaries';
import { style as tss } from 'typestyle';
import { Card, Tag } from '@src/controls';
import { Col, Row } from 'antd';
import { useQuery } from 'react-query';
import { services } from '@src/services';
import { useSelector } from '@src/utils/hooks';
import { SitesSelectors } from '@src/modules/sites';
import { head } from 'ramda';
import { HistorySection } from './history-section';
import { ReferencesInfoRow } from './references-info-row';
import { GeneralInfoRow } from './general-info-row';
import { getColorForStatus } from '@src/utils';
import { humanize } from '@src/utils/string';

const { fetchTrackingNumber } = services.trackingNumberService;

type Props = {
  trackingNumber: string;
};

export const TrackingNumberDetails: FC<Props> = ({ trackingNumber }) => {
  const dispatch = useDispatch();
  const rootState = useSelector(state => state);
  const isBookingEnabled = SitesSelectors.getSelectedSiteBookingEnabled(rootState);
  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);

  const { data, isFetching } = useQuery(['trackingNumber', trackingNumber, siteId], () =>
    fetchTrackingNumber(trackingNumber, siteId)
  );

  const trackingRecord = head(data?.tracking_records ?? []);

  const latestStatus = trackingRecord?.latest_tracking_status ?? 'UNKNOWN';

  const color = getColorForStatus(latestStatus);

  const shipmentInfoAvailable = isBookingEnabled && Boolean(trackingRecord?.shipment_id);

  return (
    <Fragment>
      <ContainerFixedHeader
        hideMainButton
        className={styles.headerStyles}
        createdAt={trackingRecord?.created_at}
        lastUpdated={trackingRecord?.updated_at}
        title={trackingNumber}
        IconComponent={ProjectOutlined}
        isLoading={isFetching}
        statusComponent={
          <Tag textColor="white" borderColor={color} style={{ backgroundColor: color }}>
            {humanize(latestStatus)}
          </Tag>
        }
        onBack={() => dispatch(routerActions.push({ name: 'TRACKING_NUMBERS' }))}
        onBackLabel={LABELS.BACK}
      />
      <ContainerContent>
        <Row gutter={16} className={styles.row}>
          <Col xxl={18} xl={16} lg={14}>
            <Card title="References" className={styles.card}>
              <Row gutter={[16, 56]}>
                <ReferencesInfoRow
                  record={trackingRecord}
                  shipmentInfoAvailable={shipmentInfoAvailable}
                />
                <GeneralInfoRow record={trackingRecord} />
              </Row>
            </Card>
          </Col>
          <Col xxl={6} xl={8} lg={10}>
            <HistorySection
              trackingNumber={trackingNumber}
              tosId={trackingRecord?.tos_id}
              externalId={trackingRecord?.external_id}
            />
          </Col>
        </Row>
      </ContainerContent>
    </Fragment>
  );
};

const styles = {
  row: tss({
    // ensures the scroll content does not row ancestor padding
    // so the content is not visible above the sticky header while scrolling
    overflowY: 'auto',
  }),
  card: tss({
    position: 'sticky',
    top: 0,
  }),
  headerStyles: tss({
    $nest: {
      '.ant-tag': {
        display: 'inline-block',
        marginLeft: '8px',
      },
    },
  }),
};
