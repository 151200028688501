import { Breadcrumb, Popover } from 'antd';
import { toLower } from 'ramda';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { AddButton } from '@src/components';
import { Link } from '@src/components/link';
import { Alert, Col } from '@src/controls';
import { FormattedMessage } from '@src/i18n';
import { ConfigMerchantModel } from '@src/models';
import { merchantsActions, MerchantsSelectors } from '@src/modules/merchants';
import { services } from '@src/services';
import { MADError, modelUtils } from '@src/utils';
import { useDispatch, useSelector } from '@src/utils/hooks';
import { CreateForm, MerchantDetails, SiteModal } from './components';

interface Props {
  merchantId: string;
}

export const MerchantsDetailsContainer: React.FunctionComponent<Props> = ({ merchantId }) => {
  const merchants = useSelector(MerchantsSelectors.getMerchants);
  const [merchant, setMerchant] = React.useState<ConfigMerchantModel | undefined>(undefined);
  const [error, setError] = React.useState<MADError | null>(null);
  const [createPopoverVisible, setCreatePopoverVisible] = React.useState(false);
  const [selectedSiteId, setSelectedSiteId] = React.useState<string | null>(null);
  const [siteModalVisible, setSiteModalVisible] = React.useState(false);

  const SHOW_NOTIFICATION_SEC = 4;

  const dispatch = useDispatch();

  const fetchAndSetNewMerchant = () => {
    services.configService
      .getMerchant(merchantId)
      .then(setMerchant)
      .catch((err: MADError) => {
        services.loggingService.logError(err);
        setError(err);
      });
  };

  React.useEffect(() => {
    fetchAndSetNewMerchant();
  }, [merchantId]);

  const handleCreateSite = (name: string) => {
    setCreatePopoverVisible(false);
    services.selfCareService
      .createSite(merchantId, name)
      .then(({ id }) => {
        services.notificationService.success(`Site '${name}' created`);
        fetchAndSetNewMerchant();
        setSelectedSiteId(id);
        dispatch(merchantsActions.listMerchants());
        setSiteModalVisible(true);
      })
      .catch((err: MADError) => {
        services.loggingService.logError(err);
        services.notificationService.error('Failed to create site', 'Error', SHOW_NOTIFICATION_SEC);
      });
  };

  const handleSiteClick = (siteId: string) => {
    setSiteModalVisible(true);
    setSelectedSiteId(siteId);
  };

  const handleChangeMerchantName = (name: string) => {
    services.selfCareService
      .renameMerchant(merchantId, name)
      .then(() => {
        services.notificationService.success(`Merchant '${name}' renamed`);
        fetchAndSetNewMerchant();
        dispatch(merchantsActions.listMerchants());
      })
      .catch((err: MADError) => {
        services.loggingService.logError(err);
        services.notificationService.error(
          'Failed to rename merchant',
          'Error',
          SHOW_NOTIFICATION_SEC
        );
      });
  };

  const handleChangeSiteName = (name: string) => {
    services.selfCareService
      .renameSite(selectedSiteId!, name)
      .then(() => {
        services.notificationService.success(`Site '${name}' renamed`);
        fetchAndSetNewMerchant();
        dispatch(merchantsActions.listMerchants());
      })
      .catch((err: MADError) => {
        services.loggingService.logError(err);
        services.notificationService.error('Failed to rename site', 'Error', SHOW_NOTIFICATION_SEC);
      });
  };

  const merchantHasDrafts = () =>
    !!(merchant && !!merchant.sites.filter(s => s.metadata?.draft?.isDraft).length);

  const siteHasDraft = () =>
    !!(merchant && merchant.sites.find(s => s.metadata?.draft?.masterSiteId === selectedSiteId));

  const siteNames = merchant?.sites.map(site => site.name);
  const selectedSiteName = merchant?.sites.find(site => site.id === selectedSiteId)?.name;

  const merchantNames = merchants.map(m => m.name).map(toLower);

  return (
    <>
      {merchantHasDrafts() && (
        <Alert
          className={styles.fallbackAlert}
          message={
            <FormattedMessage className={styles.fallbackAlertMessage} id="MERCHANT_DRAFT_ALERT" />
          }
          type="warning"
          showIcon
        />
      )}
      <Breadcrumb className={styles.breadcrumb}>
        <Breadcrumb.Item>
          <Link className={styles.breadcrumbLink} route={{ name: 'MERCHANTS_LIST' }}>
            <FormattedMessage id="MERCHANT" values={{ multiple: true }} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{merchant?.name ?? merchantId}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.wrapper}>
        {error ? (
          <div>
            <h3>Failed to fetch merchant</h3>
            <h4>Error</h4>
            <pre>{error.message}</pre>
            <h4>TraceId</h4>
            <pre>{error.traceId}</pre>
          </div>
        ) : (
          <MerchantDetails
            merchant={merchant && modelUtils.filterOutDraftsFromMerchant(merchant)}
            isFetching={!merchant}
            onItemClick={handleSiteClick}
            forbidenNames={merchantNames.filter(name => name !== merchant?.name)}
            handleChangeName={handleChangeMerchantName}
          />
        )}
        <Col offset={4}>
          <Popover
            destroyTooltipOnHide
            trigger="click"
            title={<FormattedMessage id="NEW_SITE" />}
            visible={createPopoverVisible}
            onVisibleChange={setCreatePopoverVisible}
            content={<CreateForm onSubmit={handleCreateSite} forbidenNames={siteNames} />}
          >
            <AddButton text={<FormattedMessage id="ADD_NEW" />} className={styles.addButton} />
          </Popover>
        </Col>
      </div>
      <SiteModal
        site={merchant?.sites.find(site => site.id === selectedSiteId)}
        isVisible={siteModalVisible}
        hideDetailsModal={() => setSiteModalVisible(false)}
        forbidenNames={siteNames?.filter(name => name !== selectedSiteName)}
        handleChangeName={handleChangeSiteName}
        hasDraft={siteHasDraft()}
      />
    </>
  );
};

const styles = stylesheet({
  wrapper: {
    padding: '24px',
  },
  breadcrumb: {
    padding: '10px 0 0 28px',
  },
  breadcrumbLink: {
    cursor: 'pointer',
  },
  addButton: {
    width: '100px',
    padding: 0,
    margin: '15px',
  },
  fallbackAlert: {
    border: 0,
  },
  fallbackAlertMessage: {
    paddingLeft: 5,
  },
});
