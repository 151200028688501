import { Button } from 'antd';
import { Field } from 'formik';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { ComponentReturningChildren, LinkStyleButton } from '@src/components';
import { Modal, Progress, Select } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { LABELS } from '@src/dictionaries';
import { inputStyle, regionFormItemLayout } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import { ConfigRegionModel, ConfigWarehouseModel, UpdateConfigRegionModel } from '@src/models';
import { ConfigState } from '@src/modules/config';
import { defaultTheme } from '@src/styles';

const LabelWrapper = withFormItem(ComponentReturningChildren, regionFormItemLayout);
const SelectField = withFormItem(Select);

interface Props {
  region: ConfigRegionModel;
  warehouses: ConfigWarehouseModel[];
  openWarehouseModal: () => void;
  handleUpdate: (model: Partial<UpdateConfigRegionModel>) => void;
  onWarehouseChange: (warehouseId: string) => void;
  copyCutoffsProgress: ConfigState['copyCutoffsProgress'];
}

export const WarehouseField: React.FunctionComponent<Props> = ({
  openWarehouseModal,
  region,
  warehouses,
  onWarehouseChange,
  copyCutoffsProgress,
}) => {
  const [confirmSwitchWarehouseModalVisible, setConfirmSwitchWarehouseModalVisible] =
    React.useState(false);
  const [showSelect, setShowSelect] = React.useState(false);

  const currentWarehouse = warehouses.find(w => w.id === region.warehouseId);

  const [selectedWarehouse, setSelectedWarehouse] = React.useState(
    (currentWarehouse && currentWarehouse.id) || ''
  );

  return (
    <LabelWrapper
      label={<FormattedMessage id="WAREHOUSE" values={{ multiple: false }} />}
      className={styles.label}
    >
      {showSelect ? (
        <Field name="warehouseId">
          {() => (
            <SelectField
              placeholder={LABELS.CHANGE_WAREHOUSE}
              autoFocus={true}
              className={inputStyle}
              options={warehouses.map(w => ({
                value: w.id,
                label: w.address.name,
              }))}
              value={currentWarehouse!.id}
              onSelect={(value: string) => {
                setSelectedWarehouse(value);
                setConfirmSwitchWarehouseModalVisible(true);
              }}
            />
          )}
        </Field>
      ) : (
        <>
          <LinkStyleButton className={styles.link} onClick={openWarehouseModal}>
            {currentWarehouse ? currentWarehouse.address.name : ''}
          </LinkStyleButton>
          <Button type="link" onClick={() => setShowSelect(true)}>
            Change
          </Button>
        </>
      )}
      <Modal
        centered
        width={450}
        destroyOnClose
        visible={confirmSwitchWarehouseModalVisible || copyCutoffsProgress.isCopying}
        onOk={() => {
          onWarehouseChange(selectedWarehouse);
          setConfirmSwitchWarehouseModalVisible(false);
        }}
        onCancel={() => setConfirmSwitchWarehouseModalVisible(false)}
        okButtonProps={{ disabled: copyCutoffsProgress.isCopying }}
        cancelButtonProps={{ disabled: copyCutoffsProgress.isCopying }}
      >
        {copyCutoffsProgress.isCopying ? (
          <section className={styles.confirmSection}>
            {<FormattedMessage id="COPYING" />}
            <Progress percent={Math.ceil(copyCutoffsProgress.progress * 100)} />
          </section>
        ) : (
          <section className={styles.confirmSection}>
            {<FormattedMessage id="CONFIRM_CHANGE_WAREHOUSE" />}
          </section>
        )}
      </Modal>
    </LabelWrapper>
  );
};

const styles = stylesheet({
  link: {
    marginLeft: '11px',
  },
  warehouseSelect: {
    marginLeft: '10px',
  },
  selectDivider: {
    margin: '4px 0',
  },
  addButton: {
    padding: '10px',
    borderBottom: `1px solid ${defaultTheme.color.palePrimary}`,
  },
  newWarehouseDiv: {
    padding: '8px',
    cursor: 'pointer',
  },
  confirmSection: {
    padding: '10px 0',
  },
  progressSection: {
    width: '100%',
    padding: '10px 0',
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
    $nest: {
      '& .ant-form-item': {
        flex: 1,
      },
    },
  },
});
