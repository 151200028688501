import { UserGetModel } from '@src/models';
import { getEnvironmentalVariables } from '@src/services/env-service';
import amplitude from 'amplitude-js';
import { omit } from 'ramda';

const env = getEnvironmentalVariables();

export const initEventTracking = () => {
  switch (env.MAD_DEPLOY_TARGET) {
    case 'production':
    case 'stage':
      amplitude
        .getInstance()
        .init(env.MAD_AMPLITUDE_KEY, undefined, { trackingOptions: { ip_address: false } }); // disable ip address collection to be GDPR ready
      break;
    default:
      return;
  }
};

export const logEvent = (
  mandatoryProperties: {
    name: string;
    site: string;
    merchant?: string;
    description: string;
    category:
      | 'Search'
      | 'Book & Edit'
      | 'Templates'
      | 'Addressbook'
      | 'Other'
      | 'Transport orders'
      | 'Config';
  },
  additionalProperties?: {}
) => {
  switch (env.MAD_DEPLOY_TARGET) {
    case 'production':
    case 'stage':
      amplitude.getInstance().logEvent(mandatoryProperties.name, {
        ...omit(['name'], mandatoryProperties),
        ...additionalProperties,
      });
      break;
    default:
      return;
  }
};

export const setEventTrackingUser = (user: UserGetModel) => {
  switch (env.MAD_DEPLOY_TARGET) {
    case 'production':
    case 'stage':
      amplitude.getInstance().setUserId(user.email || user.externalId || '');
      amplitude.getInstance().setUserProperties({
        user_type: user.superUser ? 'superUser' : UserGetModel.getUserGroup(user.groups).name,
        account_type: user.authenticationType,
      });
      break;
    default:
      return;
  }
};

export const setMerchantName = (name: string) => {
  switch (env.MAD_DEPLOY_TARGET) {
    case 'production':
    case 'stage':
      amplitude.getInstance().identify(new amplitude.Identify().set('merchant', name));
      break;
    default:
      return;
  }
};

initEventTracking();
