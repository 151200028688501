import { NotificationType } from '@src/models';
import { services } from '@src/services';

export const mapNotificationTypeToDescription = (type: NotificationType) => {
  switch (type) {
    case NotificationType.EXPECT_STATUS_AT_TIME:
      return 'Status and time based notification';
    case NotificationType.STATUS_CHANGED_TO:
      return 'Status based notification';
    case NotificationType.UNKNOWN:
      return '';
    case NotificationType.STATUS_AT_TIME:
      return 'Time based notification';
  }
};

export const mapCountryCodeToName = (countryCode: string) => {
  const countryFromJSON = services.dictionariesService
    .getAllCountries()
    .find(country => country.code === countryCode);
  return countryFromJSON ? countryFromJSON.name : countryCode;
};

export const mapCountryNameToCode = (countryName: string) => {
  const countryFromJSON = services.dictionariesService
    .getAllCountries()
    .find(country => country.name.toUpperCase() === countryName.toUpperCase());
  return countryFromJSON ? countryFromJSON.code : '';
};
