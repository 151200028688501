import * as React from 'react';
import { Breadcrumb } from 'antd';

import { FormattedMessage } from '@src/i18n';
import { Link } from '@src/components/link';

export const BreadcrumbSection: React.FC = ({ children }) => (
  <Breadcrumb>
    <Breadcrumb.Item>
      <Link route={{ name: 'PRODUCT_PAGE_FEATURES' }}>
        <FormattedMessage id="FEATURES" />
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>{children}</Breadcrumb.Item>
  </Breadcrumb>
);
