import { ColumnsType } from 'antd/lib/table';
import React from 'react';

export type DataType = {
  key: string;
  name: string;
  emailStatus?: boolean;
  smsStatus?: boolean;
};

export type ChannelDataType = {
  name: string;
  details: React.ReactNode;
};

export const channelColumns: ColumnsType<ChannelDataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '60%',
    render: text => <span>{text}</span>,
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details',
    render: text => <span>{text}</span>,
  },
];
