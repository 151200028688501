import { AutoComplete, Col, Row } from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { Field, FieldProps, Formik, FormikProps, useField } from 'formik';
import * as React from 'react';
import { classes, style } from 'typestyle';
import { Omit } from 'utility-types';
import { object, string, StringSchema } from 'yup';

import { Input, InputNumber } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, AutoSaveFormikEnhancer, inputStyle } from '@src/forms';
import { FormattedMessage } from '@src/i18n';
import {
  ConfigWarehouseModel,
  ContactDataModel,
  GenericAddressEntityModel,
  StateEnum,
  UpdateWarehouseDetailsModel,
} from '@src/models';
import { services } from '@src/services';
import { defaultTheme } from '@src/styles';
import { getFormikError } from '@src/utils/forms';
import { getPostalCodeValidationByCountry } from '@src/utils/validation';
import { clamp } from 'ramda';

const addresslabelSpan = 6;
const addressContentSpan = 18;
const labelSpan = 8;
const contentSpan = 16;

const layout = {
  colon: false,
  labelCol: { span: labelSpan },
  wrapperCol: { span: contentSpan },
};

const InputField = withFormItem(Input, layout);
const AutoCompleteField = withFormItem<AutoCompleteProps>(AutoComplete, layout);
const NumberField = withFormItem(InputNumber, layout);

interface WarehouseDetailsAddressProps {
  warehouse: ConfigWarehouseModel;
  updateWarehouseDetails: (payload: { model: UpdateWarehouseDetailsModel }) => void;
}

const AddressDetailsSchema = object().shape({
  name: string().required(),
  city: string().min(1).required(),
  postalCode: string()
    .when('country', (country: string, schema: StringSchema) =>
      schema.matches(
        getPostalCodeValidationByCountry(
          services.dictionariesService.getCountryNameToCountryCodeMapping()[country]
        ),
        `Postal code is not valid for ${country}`
      )
    )
    .required(),
  country: string()
    .oneOf(services.dictionariesService.getCountryNames(), 'Invalid country name')
    .required(),
});

const ContactDataSchema = object().shape({
  email: string().email(),
  phone: string(),
});

type FormValues = Omit<GenericAddressEntityModel, 'careOf' | 'attn' | 'region' | 'doorCode'>;

export class WarehouseDetailsAddress extends React.Component<WarehouseDetailsAddressProps, {}> {
  updateWarehouseDetailsAddress = (values: Partial<GenericAddressEntityModel>) => {
    const {
      warehouse: { address },
    } = this.props;
    this.props.updateWarehouseDetails({
      model: {
        warehouseId: this.props.warehouse.id,
        address: {
          ...address,
          ...values,
          ...(values.country
            ? {
                country:
                  services.dictionariesService.getCountryNameToCountryCodeMapping()[values.country],
              }
            : {}),
        },
        timezone: this.props.warehouse.timezone,
        state: this.props.warehouse.state || StateEnum.INACTIVE,
      },
    });
  };

  updateWarehouseContactData = (values: Partial<ContactDataModel>) => {
    const {
      warehouse: { contactData },
    } = this.props;

    this.props.updateWarehouseDetails({
      model: {
        warehouseId: this.props.warehouse.id,
        address: this.props.warehouse.address,
        timezone: this.props.warehouse.timezone,
        state: this.props.warehouse.state || StateEnum.INACTIVE,
        contactData: {
          ...contactData,
          ...values,
        },
      },
    });
  };

  render() {
    const { address, contactData } = this.props.warehouse;
    return (
      <Row>
        <Col span={addresslabelSpan}>
          <FormattedMessage className={styles.groupLabel} id="ADDRESS" />
        </Col>
        <Col span={addressContentSpan} className={styles.topSeparator}>
          <Formik
            initialValues={{
              ...address,
              country: address.country
                ? services.dictionariesService.getCountryCodeToCountryNameMapping()[address.country]
                : '',
            }}
            // tslint:disable-next-line: no-empty
            onSubmit={() => {}}
            validationSchema={AddressDetailsSchema}
            enableReinitialize={true}
          >
            {(renderProps: FormikProps<FormValues>) => (
              <AutoSaveForm>
                <FormikInputField
                  name="name"
                  label={<FormattedMessage id="NAME" className={styles.label} />}
                  onSave={values => this.updateWarehouseDetailsAddress({ name: values.name })}
                />
                <FormikInputField
                  name="addressLines[0]"
                  onSave={values =>
                    this.updateWarehouseDetailsAddress({
                      addressLines: values.addressLines,
                    })
                  }
                  label={
                    <FormattedMessage
                      id="ADDRESS_LINE"
                      values={{ num: 1 }}
                      className={styles.label}
                    />
                  }
                />
                <FormikInputField
                  name="addressLines[1]"
                  label={
                    <FormattedMessage
                      id="ADDRESS_LINE"
                      values={{ num: 2 }}
                      className={styles.label}
                    />
                  }
                  onSave={values =>
                    this.updateWarehouseDetailsAddress({
                      addressLines: values.addressLines,
                    })
                  }
                />
                <AutoSaveFormikEnhancer
                  name="country"
                  onSave={values =>
                    this.updateWarehouseDetailsAddress({
                      country: values.country,
                      city: values.city,
                      postalCode: values.postalCode,
                    })
                  }
                  validateAllFields
                  render={({ name, onInstantChange }) => (
                    <Field name={name}>
                      {({ field }: FieldProps<GenericAddressEntityModel['country']>) => (
                        <AutoCompleteField
                          label={<FormattedMessage id="COUNTRY" className={styles.label} />}
                          className={inputStyle}
                          placeholder={'-'}
                          {...field}
                          options={services.dictionariesService
                            .getAllCountries()
                            .map(country => ({ value: country.name }))}
                          onChange={(value: string) => {
                            renderProps.setFieldValue(name, value);
                            renderProps.setFieldValue('postalCode', '');
                            renderProps.setFieldValue('city', '');
                            renderProps.setTouched({
                              postalCode: true,
                              city: true,
                            });
                            onInstantChange(value);
                          }}
                          error={getFormikError(
                            renderProps.touched.country,
                            renderProps.errors.country
                          )}
                          getPopupContainer={(targetNode: any) =>
                            targetNode.parentElement || document.body
                          }
                          filterOption={true}
                          optionFilterProp={'value'}
                          showSearch
                        />
                      )}
                    </Field>
                  )}
                />
                <FormikInputField
                  name="city"
                  label={<FormattedMessage id="CITY" className={styles.label} />}
                  onSave={values =>
                    this.updateWarehouseDetailsAddress({
                      city: values.city,
                      country: values.country,
                      postalCode: values.postalCode,
                    })
                  }
                />
                <FormikInputField
                  name="postalCode"
                  onSave={values =>
                    this.updateWarehouseDetailsAddress({
                      postalCode: values.postalCode,
                      city: values.city,
                      country: values.country,
                    })
                  }
                  label={<FormattedMessage id="ZIP_CODE" className={styles.label} />}
                />
                <Row>
                  <Col span={labelSpan}>
                    <FormattedMessage
                      className={classes(styles.label, styles.groupLabel)}
                      id="COORDINATES"
                    />
                  </Col>
                  <Col span={contentSpan}>
                    <FormikNumberField
                      name="coordinates.lat"
                      min={-90}
                      max={90}
                      label={<FormattedMessage className={styles.label} id="COORDINATES_LAT" />}
                      onSave={values =>
                        this.updateWarehouseDetailsAddress({
                          coordinates: values.coordinates,
                        })
                      }
                    />
                    <FormikNumberField
                      name="coordinates.lng"
                      min={-180}
                      max={180}
                      label={<FormattedMessage className={styles.label} id="COORDINATES_LNG" />}
                      onSave={values =>
                        this.updateWarehouseDetailsAddress({
                          coordinates: values.coordinates,
                        })
                      }
                    />
                  </Col>
                </Row>
              </AutoSaveForm>
            )}
          </Formik>
          <Formik
            initialValues={{
              ...contactData,
            }}
            // tslint:disable-next-line: no-empty
            onSubmit={() => {}}
            validationSchema={ContactDataSchema}
            enableReinitialize={true}
          >
            <AutoSaveForm>
              <FormikInputField
                name="phone"
                label={<FormattedMessage id="PHONE" values={{ num: 2 }} className={styles.label} />}
                onSave={values =>
                  this.updateWarehouseContactData({
                    phone: values.phone,
                  })
                }
              />
              <FormikInputField
                name="email"
                label={<FormattedMessage id="EMAIL" values={{ num: 2 }} className={styles.label} />}
                onSave={values =>
                  this.updateWarehouseContactData({
                    email: values.email,
                  })
                }
              />
            </AutoSaveForm>
          </Formik>
        </Col>
      </Row>
    );
  }
}

const FormikNumberField: React.FunctionComponent<{
  name: string;
  min: number;
  max: number;
  label: React.ReactNode;
  onSave: (values: any) => void;
}> = ({ name, min, max, label, onSave }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <AutoSaveFormikEnhancer
      name={name}
      onSave={onSave}
      render={({ onBlur, onKeyDown }) => (
        <NumberField
          label={label}
          className={inputStyle}
          placeholder={'-'}
          {...field}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          min={min}
          max={max}
          precision={7}
          onChange={value =>
            // removing value from numeric field resets it to 0
            // so when value is 0 send null to reset field.
            // coordinates 0,0 are not valid for warehouse location
            // clamp sets value between max - min
            value === 0 ? helpers.setValue(null) : helpers.setValue(clamp(min, max, value))
          }
          error={getFormikError(meta.touched, meta.error)}
          style={{ width: '100%' }}
        />
      )}
    />
  );
};

const FormikInputField: React.FunctionComponent<{
  name: string;
  label: React.ReactNode;
  onSave: (values: any) => void;
}> = ({ name, onSave, label }) => {
  const [field, meta] = useField(name);
  return (
    <AutoSaveFormikEnhancer
      name={name}
      onSave={onSave}
      validateAllFields
      render={({ onBlur, onKeyDown }) => (
        <InputField
          label={label}
          className={inputStyle}
          placeholder={'-'}
          {...field}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          error={getFormikError(meta.touched, meta.error)}
        />
      )}
    />
  );
};

const styles = {
  groupLabel: style({
    color: defaultTheme.regionForm.color.label,
    position: 'absolute',
    top: '9px',
  }),
  label: style({
    paddingLeft: '11px',
  }),
  topSeparator: style({
    borderTop: '1px solid rgba(0, 0, 0, 0.09)',
    paddingTop: '10px',
  }),
};
