import { head } from 'ramda';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { SitesSelectors } from '@src/modules/sites';
import { services } from '@src/services';
import { useSelector } from '@src/utils/hooks';

export type TrackingData = {
  externalId: string | undefined;
  trackingNumbers: string[] | undefined;
  trackingPageToken: string | undefined;
  trackingWidgetToken: string | undefined;
  contact: string | undefined;
};

export const useTrackingData = () => {
  const [trackingData, setTrackingData] = useState<TrackingData>({
    externalId: undefined,
    trackingNumbers: undefined,
    trackingPageToken: undefined,
    trackingWidgetToken: undefined,
    contact: undefined,
  });

  return [trackingData, setTrackingData] as const;
};

const { fetchByExternalIdOrTrackingNumber } = services.trackingNumberService;

type FindTrackingDataParams = {
  externalId?: string;
  trackingNumber?: string;
};

export const useFindTrackingData = ({ externalId, trackingNumber }: FindTrackingDataParams) => {
  const siteId = useSelector(SitesSelectors.getSelectedSiteIdOrEmpty);
  const { data, isFetching } = useQuery(
    ['trackingNumberOrExternalId', siteId, externalId, trackingNumber],
    () => fetchByExternalIdOrTrackingNumber(externalId || trackingNumber || '', siteId)
  );

  const trackingRecord = head(data?.tracking_records ?? []);

  return [
    {
      trackingNumber: trackingRecord?.tracking_number,
      externalId: trackingRecord?.external_id,
    },
    isFetching,
  ] as const;
};
