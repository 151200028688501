import { defaultTheme } from '@src/styles';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { stylesheet } from 'typestyle';

const skeletonHeaderSize = {
  width: '66%',
  height: '20px',
};
const skeletonHeight = '17px';

export const SkeletonSideMenu = () => {
  return (
    <SkeletonTheme color={defaultTheme.color.statusGrey}>
      <div className={styles.wrapper}>
        <Skeleton {...skeletonHeaderSize} />
        <Skeleton height={skeletonHeight} count={4} className={styles.skeleton} />
        <Skeleton {...skeletonHeaderSize} />
        <Skeleton height={skeletonHeight} count={5} className={styles.skeleton} />
        <Skeleton {...skeletonHeaderSize} />
        <Skeleton height={skeletonHeight} count={1} className={styles.skeleton} />
        <Skeleton {...skeletonHeaderSize} />
        <Skeleton height={skeletonHeight} count={2} className={styles.skeleton} />
        <Skeleton {...skeletonHeaderSize} />
        <Skeleton height={skeletonHeight} count={3} className={styles.skeleton} />
      </div>
    </SkeletonTheme>
  );
};

const styles = stylesheet({
  wrapper: {
    width: '240px',
    height: '100%',
    padding: '15px 20px',
    display: 'grid',
    gap: '10px',
  },
  skeleton: {
    marginBottom: '8px',
  },
});
