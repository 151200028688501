import { Switch, Tooltip } from 'antd';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { DragableRow } from '@src/components';
import { FormattedMessage } from '@src/i18n';
import { ConfigRegionModel, ConfigShippingCategoryModel, StateEnum } from '@src/models';

import { Table } from '@src/controls';

import { Link } from '@src/components/link';
import { DragIcon } from '@src/images/drag-icon';
import { configSelectors } from '@src/modules/config';
import { updateCountrySettingsRequest } from '@src/modules/config/actions';
import { siwActions } from '@src/modules/siw';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { useDispatch, useSelector } from '@src/utils/hooks';
import { humanize } from '@src/utils/string';

interface Props {
  categories: ConfigShippingCategoryModel[];
  countryISO: string;
}

const getRegionName = (regions: ConfigRegionModel[], regionId: string) => {
  return regions.find(region => region.id === regionId)?.name || '';
};

export const PreselectionOrderTable: React.FC<Props> = ({ categories, countryISO }) => {
  const dispatch = useDispatch();
  const regions = useSelector(state => configSelectors.getRegionsByDraftOrCurrentSiteId(state));
  const isCreatingDraft = useSelector(state => state.config.isCreatingDraft);

  const updateCountrySettings = (
    updatedCategories: ConfigShippingCategoryModel[],
    country: string
  ) => {
    const preselectionOrderSettings = {
      country,
      countrySettings: updatedCategories.map((category, idx) => ({
        categoryId: category.id,
        preselectionOrder: idx + 1,
        useForFallback: getFallbackValue(category.id),
      })),
    };
    return preselectionOrderSettings;
  };

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const categoriesWithoutSelectedOne = categories.filter((_, index) => index !== dragIndex);
    const updatedCategories = [
      ...categoriesWithoutSelectedOne.slice(0, hoverIndex),
      categories[dragIndex],
      ...categoriesWithoutSelectedOne.slice(hoverIndex),
    ];
    const countrySettings = updateCountrySettings(updatedCategories, countryISO);
    dispatchOnDraftSiteId(updateCountrySettingsRequest)({
      model: countrySettings,
      onComplete: () => dispatch(siwActions.resetWidgetSession()),
    });
  };

  const getFallbackValue = (categoryId: string) => {
    const category = categories.find(c => c.id === categoryId)!;
    const fallbackValue = !!category.countrySettings?.[countryISO]?.useForFallback;
    return fallbackValue;
  };

  const setFallbackValue = (categoryId: string, value: boolean) => {
    dispatchOnDraftSiteId(updateCountrySettingsRequest)({
      model: {
        country: countryISO,
        countrySettings: categories.map((category, idx) => {
          return {
            categoryId: category.id,
            preselectionOrder: idx + 1,
            useForFallback: category.id === categoryId ? value : getFallbackValue(category.id),
          };
        }),
      },
      onComplete: () => dispatch(siwActions.resetWidgetSession()),
    });
  };

  return (
    <Table<ConfigShippingCategoryModel>
      dataSource={categories}
      hideTitle={true}
      pagination={false}
      rowKey={(record, index) => `${index}`}
      components={{ body: { row: DragableRow } }}
      onRow={(record, index) => ({
        index,
        moveRow,
      })}
      className={styles.table}
      loading={isCreatingDraft}
    >
      <Table.Column<ConfigShippingCategoryModel>
        title={''}
        width={'50px'}
        key="iconsColumn"
        render={() => <DragIcon className={styles.dragIcon} />}
      />
      <Table.Column<ConfigShippingCategoryModel>
        title={<FormattedMessage id="DELIVERY_CATEGORY" />}
        key="shippingCategory"
        render={record => <span>{record.name}</span>}
      />
      <Table.Column<ConfigShippingCategoryModel>
        title={<FormattedMessage id="REGION" values={{ multiple: true }} />}
        key="regions"
        render={({}, record) =>
          record.regionIds.map((id, index) => (
            <>
              <Link
                showIcon
                baseStyles
                key={`${index}-${id}-${record.id}`}
                route={{ name: 'REGION_DETAILS', regionId: id }}
              >
                {getRegionName(regions, id)}
              </Link>
              {index === record.regionIds.length - 1 ? '' : ', '}
            </>
          ))
        }
      />
      <Table.Column<ConfigShippingCategoryModel>
        title={<FormattedMessage id="DELIVERY_TYPE" />}
        key="deliveryType"
        render={record => humanize(record.deliveryType)}
      />
      <Table.Column<ConfigShippingCategoryModel>
        title={<FormattedMessage id="STATUS" />}
        key="status"
        render={record => (
          <FormattedMessage id={record.state === StateEnum.ACTIVE ? 'ACTIVE' : 'INACTIVE'} />
        )}
      />
      <Table.Column<ConfigShippingCategoryModel>
        title={'Fallback'}
        key="fallback"
        render={record => {
          const value = getFallbackValue(record.id);
          const disabled =
            value && categories.reduce((acc, c) => acc + Number(getFallbackValue(c.id)), 0) === 1;
          return (
            <Tooltip title={disabled && <FormattedMessage id="FALLBACK_MUST_BE_SET" />}>
              <Switch
                disabled={disabled}
                checked={value}
                onChange={newValue => {
                  setFallbackValue(record.id, newValue);
                }}
              />
            </Tooltip>
          );
        }}
      />
    </Table>
  );
};
const styles = stylesheet({
  table: {
    width: '100%',
    overflow: 'scroll',
    $nest: {
      table: {
        borderCollapse: 'collapse',
      },
      '.ant-table-thead > tr > th': {
        backgroundColor: 'rgba(0, 0, 0, 0.02);',
        borderBottom: '1px solid rgba(0,0,0,0.09)',
      },
      '.ant-table-tbody > tr > td': {
        borderBottom: '1px solid rgba(0,0,0,0.09)',
      },
    },
  },
  dragIcon: {
    marginLeft: '5px',
  },
  arrowIcon: {
    position: 'absolute',
    left: '50px',
    marginTop: '7px',
  },
});
