import { withFormItem } from '@src/decorators';
import { bigLabelLayout } from '@src/forms';
import { useFormatMessage } from '@src/i18n';
import { getFormikError } from '@src/utils/forms';
import { Input } from 'antd';
import { Field, FieldProps, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { FormValues } from '@src/containers/tracking-playground/generate-section/common';

const InputField = withFormItem(Input, bigLabelLayout);

type Props = {
  isGeneratingToken?: boolean;
};

export const MessagingNotificationTypeVariants = ({ isGeneratingToken }: Props) => {
  const formatMessage = useFormatMessage();
  const formik = useFormikContext<FormValues>();

  useEffect(() => {
    !formik.values.notificationType.email && formik.setFieldValue('emailAddress', '');
    !formik.values.notificationType.sms && formik.setFieldValue('phoneNumber', '');
  }, [formik.values.notificationType]);

  return (
    <>
      {formik.values.notificationType.email && (
        <Field name="emailAddress">
          {({ field, meta }: FieldProps<FormValues['emailAddress']>) => (
            <InputField
              {...field}
              type="email"
              value={formik.values.emailAddress}
              label={formatMessage('TRACKING_PLAYGROUND.NOTIFICATIONS.EMAIL')}
              disabled={isGeneratingToken}
              error={getFormikError(meta.touched, meta.error)}
            />
          )}
        </Field>
      )}
      {formik.values.notificationType.sms && (
        <Field name="phoneNumber">
          {({ field, meta }: FieldProps<FormValues['phoneNumber']>) => (
            <InputField
              {...field}
              type="tel"
              value={formik.values.phoneNumber}
              label={formatMessage('TRACKING_PLAYGROUND.NOTIFICATIONS.PHONE_NUMBER')}
              disabled={isGeneratingToken}
              error={getFormikError(meta.touched, meta.error)}
            />
          )}
        </Field>
      )}
    </>
  );
};
