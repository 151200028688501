import { Row } from 'antd';
import { FieldState, FormState } from 'formstate';
import { observer } from 'mobx-react';
import { isNotEmpty } from 'ramda-adjunct';
import * as React from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { style } from 'typestyle';

import { DOMAIN, LABELS } from '../../../dictionaries';
import { defaultTheme } from '../../../styles';

import { Link } from '@src/components/link';
import { Select, Spinner, Text } from '../../../controls';
import { withFieldStateSelect, withFormItem } from '../../../decorators';
import { TransportOrderModel } from '../../../models';
import {
  TransportOrderSourceTag,
  TransportOrderStatusTag,
} from '../../transport-orders/components';

const TransportOrderSelectField = withFormItem(withFieldStateSelect(Select));
const FormItemText = withFormItem(Text);

type IFormState = FormState<{
  transportOrderIdFieldState: FieldState<string>;
}>;

interface IProps {
  formState: IFormState;
  transportOrders: TransportOrderModel[];
  onSearchTransportOrder?: (searchQuery: string) => void;
  showTosIdAnchor?: boolean;
  disabled?: boolean;
  isFetching?: boolean;
  onChange?: (v: any) => void;
  clearTransportOrdersList?: () => void;
}

const styles = {
  row: style({
    justifyContent: 'space-between',
    paddingBottom: '5px',
    paddingTop: '5px',
  }),
  tosIdAnchor: style({
    marginBottom: 0,
    $nest: {
      '.ant-form-item-label': {
        fontWeight: defaultTheme.font.bold,
      },
    },
  }),
  title: style({
    fontWeight: defaultTheme.font.bold,
  }),
  label: style({
    color: 'rgba(0, 0, 0, 0.5)',
  }),
  option: style({
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingBottom: '5px',
  }),
  TOSelectWrapper: style({
    width: defaultTheme.tosIdFormWidth,
    paddingRight: '40px',
    $nest: {
      '.t_to-select-dropdown': {
        maxHeight: '400px',
      },
      '.ant-select-dropdown-menu': {
        maxHeight: '400px',
      },
      '.ant-select-dropdown-menu-item': {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
      },
      'li:not(:last-child)': {
        borderBottom: '1px solid #e8e8e8',
      },
    },
  }),
  externalIdWrapper: style({
    marginBottom: '10px',
    $nest: {
      '& > p': {
        margin: 0,
      },
    },
  }),
};

@observer
export class TransportOrderIdForm extends React.Component<IProps> {
  searchInput$ = new Subject<string>();

  searchInputSubscription = this.searchInput$.pipe(debounceTime(350)).subscribe(value => {
    const { onSearchTransportOrder, clearTransportOrdersList } = this.props;
    if (value.length >= 5 && onSearchTransportOrder) {
      onSearchTransportOrder(value);
    }
    if (value.length === 0 && clearTransportOrdersList) {
      clearTransportOrdersList();
    }
  });

  static createFormState = (tosId: string = ''): IFormState =>
    new FormState({
      transportOrderIdFieldState: new FieldState(tosId),
    });

  onSearchHandler = (value: string) => this.searchInput$.next(value);

  render() {
    const { formState, disabled, isFetching, transportOrders, showTosIdAnchor, onChange } =
      this.props;

    const options = transportOrders.map(transportOrder => ({
      value: transportOrder.id,
      label: transportOrder.id,
      metadata: transportOrder.metadata,
      status: transportOrder.status,
      fromName: transportOrder.sender.contactInfo.address.name,
      toName: transportOrder.receiver.contactInfo.address.name,
      createdAt: new Date(transportOrder.createdAt).toLocaleString(),
      shipmentCount: (transportOrder.shipments && transportOrder.shipments.length) || 0,
      additionalFilterByValues: transportOrder.external.referenceIds,
    }));
    const createTosIdAnchor = () => {
      const tosId = formState.$.transportOrderIdFieldState.$;

      return (
        <FormItemText
          className={styles.tosIdAnchor}
          label={DOMAIN.TRANSPORT_ORDER}
          labelCol={{ span: 24 }}
        >
          <Link usePrimaryColors route={{ name: 'TRANSPORT_ORDER_DETAILS', tosId }}>
            {tosId}
          </Link>
        </FormItemText>
      );
    };

    const optionRenderer = (item: typeof options[number]) => {
      return (
        <div className={styles.option}>
          <Row justify="start" className={styles.row}>
            <p className={styles.title}>{item.label}</p>
            <TransportOrderStatusTag status={item.status} />
            {item.additionalFilterByValues && (
              <div className={styles.externalIdWrapper}>
                <p className={styles.label}>External IDs:</p>
                {item.additionalFilterByValues.map(externalId => (
                  <p>{externalId}</p>
                ))}
              </div>
            )}
          </Row>
          <Row justify="space-between">
            <div>
              <span className={styles.label}>{LABELS.CREATED_ON}:</span>&nbsp;
              <span>{item.createdAt}</span>
            </div>
            <TransportOrderSourceTag source={item?.metadata?.source} />
          </Row>
          <Row justify="start" className={styles.row}>
            <div>
              <span className={styles.label}>{LABELS.FROM}:</span>&nbsp;
              <span>{item.fromName}</span>
            </div>
            <div>
              <span className={styles.label}>{LABELS.TO}:</span>&nbsp;
              <span>{item.toName}</span>
            </div>
            <p>{`${item.shipmentCount} ${DOMAIN.SHIPMENT}(s)`}</p>
          </Row>
        </div>
      );
    };

    const LoadingIndicator = isFetching ? (
      <Spinner size="small" />
    ) : (
      <div style={{ marginLeft: '12px' }}>No transport order found</div>
    );

    return showTosIdAnchor ? (
      createTosIdAnchor()
    ) : (
      <div className={styles.TOSelectWrapper}>
        <TransportOrderSelectField
          dropdownAlign={{
            overflow: {
              adjustY: false,
            },
          }}
          allowClear={true}
          label={DOMAIN.TRANSPORT_ORDER}
          disabled={disabled}
          placeholder="Search by ID (minimum 5 characters) or external ID (full ID)"
          optionLabelProp="value"
          onSearch={this.onSearchHandler}
          caseInsensitiveSearch={true}
          notFoundContent={LoadingIndicator}
          loading={isFetching}
          options={options}
          optionRenderer={optionRenderer}
          showSearch={true}
          fieldState={formState.$.transportOrderIdFieldState}
          error={formState.$.transportOrderIdFieldState.error}
          onChange={onChange}
          dropdownClassName="t_to-select-dropdown"
          className="t_to-select"
          filterOption={(stringQuery, { props: { value, additionalFilterByValues } }) => {
            // try to filter first by value then by external ids with is additionalFilterByValues
            if (value) {
              if (value.toString().includes(stringQuery.toUpperCase())) {
                return true;
              } else if (isNotEmpty(additionalFilterByValues) && additionalFilterByValues) {
                return additionalFilterByValues.includes(stringQuery);
              }
            }
            return false;
          }}
          labelCol={{ span: 24 }}
        />
      </div>
    );
  }
}
