import { ShipmentNotificationModel, TriggerListItemModel } from '@src/models';
import { deprecated } from 'typesafe-actions';
const { createAction } = deprecated;

import { TriggerCreateModel } from '@src/models';

const CREATE_SHIPMENT_NOTIFICATIONS_TRIGGER_REQUEST =
  '@shipmentNotifications/CREATE_SHIPMENT_NOTIFICATIONS_TRIGGER_REQUEST';
const CREATE_SHIPMENT_NOTIFICATIONS_TRIGGER_SUCCESS =
  '@shipmentNotifications/CREATE_SHIPMENT_NOTIFICATIONS_TRIGGER_SUCCESS';
const CREATE_SHIPMENT_NOTIFICATIONS_TRIGGER_ERROR =
  '@shipmentNotifications/CREATE_SHIPMENT_NOTIFICATIONS_TRIGGER_ERROR';

const LIST_NOTIFICATIONS_REQUEST = '@shipmentNotifications/LIST_NOTIFICATIONS_REQUEST';
const LIST_NOTIFICATIONS_SUCCESS = '@shipmentNotifications/LIST_NOTIFICATIONS_SUCCESS';
const LIST_NOTIFICATIONS_ERROR = '@shipmentNotifications/LIST_NOTIFICATIONS_ERROR';

const MARK_NOTIFICATION_AS_READ_REQUEST =
  '@shipmentNotifications/MARK_NOTIFICATION_AS_READ_REQUEST';
const MARK_NOTIFICATION_AS_READ_SUCCESS =
  '@shipmentNotifications/MARK_NOTIFICATION_AS_READ_SUCCESS';
const MARK_NOTIFICATION_AS_READ_ERROR = '@shipmentNotifications/MARK_NOTIFICATION_AS_READ_ERROR';

const LIST_NOTIFICATION_TRIGGERS_REQUEST =
  '@shipmentNotifications/LIST_NOTIFICATION_TRIGGERS_REQUEST';
const LIST_NOTIFICATION_TRIGGERS_SUCCESS =
  '@shipmentNotifications/LIST_NOTIFICATION_TRIGGERS_SUCCESS';
const LIST_NOTIFICATION_TRIGGERS_ERROR = '@shipmentNotifications/LIST_NOTIFICATION_TRIGGERS_ERROR';

const DELETE_NOTIFICATION_TRIGGER_REQUEST =
  '@shipmentNotifications/DELETE_NOTIFICATION_TRIGGER_REQUEST';
const DELETE_NOTIFICATION_TRIGGER_SUCCESS =
  '@shipmentNotifications/DELETE_NOTIFICATION_TRIGGER_SUCCESS';
const DELETE_NOTIFICATION_TRIGGER_ERROR =
  '@shipmentNotifications/DELETE_NOTIFICATION_TRIGGER_ERROR';

export const createTriggerRequest = createAction(
  CREATE_SHIPMENT_NOTIFICATIONS_TRIGGER_REQUEST,
  resolve => (payload: TriggerCreateModel) => resolve(payload)
);
export const createTriggerSuccess = createAction(CREATE_SHIPMENT_NOTIFICATIONS_TRIGGER_SUCCESS);
export const createTriggerError = createAction(
  CREATE_SHIPMENT_NOTIFICATIONS_TRIGGER_ERROR,
  resolve => (error: string) => resolve(error)
);
export const markNotificationsAsReadRequest = createAction(
  MARK_NOTIFICATION_AS_READ_REQUEST,
  resolve => (payload: { userId: string; siteId: string; ids: string[] }) => resolve(payload)
);
export const markNotificationsAsReadSuccess = createAction(MARK_NOTIFICATION_AS_READ_SUCCESS);
export const markNotificationsAsReadError = createAction(
  MARK_NOTIFICATION_AS_READ_ERROR,
  resolve => (payload: { error: string; ids: string[] }) => resolve(payload)
);

export const listNotificationsRequest = createAction(
  LIST_NOTIFICATIONS_REQUEST,
  resolve => (payload: { siteId: string; userId: string }) => resolve(payload)
);
export const listNotificationsSuccess = createAction(
  LIST_NOTIFICATIONS_SUCCESS,
  resolve => (payload: ShipmentNotificationModel[]) => resolve(payload)
);

export const listNotificationsError = createAction(
  LIST_NOTIFICATIONS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const listNotificationTriggersRequest = createAction(LIST_NOTIFICATION_TRIGGERS_REQUEST);
export const listNotificationTriggersSuccess = createAction(
  LIST_NOTIFICATION_TRIGGERS_SUCCESS,
  resolve => (payload: TriggerListItemModel[]) => resolve(payload)
);
export const listNotificationTriggersError = createAction(
  LIST_NOTIFICATION_TRIGGERS_ERROR,
  resolve => (error: string) => resolve(error)
);

export const deleteNotificationTriggerRequest = createAction(
  DELETE_NOTIFICATION_TRIGGER_REQUEST,
  resolve => (id: string) => resolve(id)
);
export const deleteNotificationTriggerSuccess = createAction(DELETE_NOTIFICATION_TRIGGER_SUCCESS);
export const deleteNotificationTriggerError = createAction(
  DELETE_NOTIFICATION_TRIGGER_ERROR,
  resolve => (error: string) => resolve(error)
);
