import { commonFormItemProps } from '@src/containers/product-mappings/form-components/fields';
import { CarrierProductMappingForm } from '@src/containers/product-mappings/form-commons';
import {
  LocaleFieldAutoSave,
  UrlFieldAutoSave,
} from '@src/containers/product-mappings/form-components/autosave-fields';
import {
  AddNewRowButtonConnected,
  DeleteRowButtonConnected,
} from '@src/containers/product-mappings/form-components/buttons';
import { stylesheet } from 'typestyle';
import { Form } from 'antd';
import * as React from 'react';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useFormatMessage } from '@src/i18n';

type Props = {
  handleSave: (values: CarrierProductMappingForm) => void;
};

export const TrackingLinksTable = ({ handleSave }: Props) => {
  const formatMessage = useFormatMessage();
  const formik = useFormikContext<CarrierProductMappingForm>();

  useEffect(() => {
    if (formik.values.tracking_links?.[0]?.locale === 'defaultlink') {
      formik.setFieldValue('tracking_links', [{ locale: 'default', url: '' }]);
    }
  }, []);

  return (
    <Form.Item
      label={formatMessage('TRACKING_LINKS_URLS')}
      {...commonFormItemProps}
      labelAlign="left"
    >
      <ul className={styles.trackingLinksList}>
        {formik.values.tracking_links.map((_, idx) => {
          return (
            <li key={`integrated-link-${idx}`} className={styles.trackingLink}>
              <div className={styles.fieldSpacing}>
                <LocaleFieldAutoSave
                  idx={idx}
                  disabled={idx === 0}
                  handleSave={handleSave}
                  formik={formik}
                />
              </div>
              <div className={styles.fieldSpacing}>
                <UrlFieldAutoSave idx={idx} handleSave={handleSave} />
              </div>
              {idx !== 0 && (
                <DeleteRowButtonConnected
                  idx={idx}
                  handleSave={handleSave}
                  className={styles.deleteRow}
                />
              )}
            </li>
          );
        })}
      </ul>
      <AddNewRowButtonConnected handleSave={handleSave} />
    </Form.Item>
  );
};

const styles = stylesheet({
  fieldSpacing: {
    paddingRight: '8px',
  },
  trackingLinksList: { padding: 0, listStyle: 'none' },
  deleteRow: { marginTop: '6px' },
  trackingLink: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'start',
    paddingBottom: '8px',
    $nest: {
      '& .ant-form-item': {
        margin: '0 !important',
      },
    },
  },
});
