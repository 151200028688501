import { CustomBookingMethodModel } from '@src/models';
import { Col, Row } from 'antd';
import React, { FC } from 'react';
import { style as tss } from 'typestyle';
import { MethodCard } from './method-card';
import { TextStack } from './text-stack';

type Props = {
  bookingMethodModel: CustomBookingMethodModel;
};

export const BookingMethodCard: FC<Props> = ({ bookingMethodModel }) => {
  const { method, bookingMethod, steps } = bookingMethodModel;

  const bookingMethods = steps?.map(step => <MethodCard key={step.name} step={step} />);

  if (!bookingMethods) {
    return null;
  }

  return (
    <div className={styles.bookingMethodCard}>
      <Row>
        <Col span={6}>
          <div className={styles.descriptionCol}>
            <TextStack title="Shipping method" content={[method]} boldContent />
            {bookingMethod && <TextStack title="Booking method" content={[bookingMethod]} />}
          </div>
        </Col>
        <Col span={18} className={styles.methodsCol}>
          {bookingMethods}
        </Col>
      </Row>
    </div>
  );
};

const styles = {
  bookingMethodCard: tss({
    borderRadius: '4px',
    padding: '24px',
    backgroundColor: '#FAF8F7',
  }),
  methodsCol: tss({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  }),
  descriptionCol: tss({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  }),
};
