import { StateEnum } from '@src/models';

export const getStateAsBoolean = (status?: StateEnum) => {
  if (status) {
    return status === StateEnum.ACTIVE ? true : false;
  }
  return false;
};

export const getBooleanAsState = (status?: boolean) => {
  return status ? StateEnum.ACTIVE : StateEnum.INACTIVE;
};
