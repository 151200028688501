import { TagProps } from 'antd/lib/tag';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Button, Input, Tag, Tooltip } from '../controls';

export interface IProps extends TagProps {
  tags?: string[];
  editable?: boolean;
  onChangeHandler?: (value: string[]) => void;
  name?: string;
}

@observer
class Component extends React.Component<IProps, {}> {
  @observable tags: string[] = [];
  @observable inputVisible: boolean = false;
  @observable inputValue: string = '';

  componentDidMount() {
    const { tags } = this.props;
    if (tags) {
      this.tags = this.props.tags || [];
    }
  }

  handleClose = (removedTag: string) => {
    const tags = this.tags.filter(tag => tag !== removedTag);
    this.tags = tags;

    const { onChangeHandler } = this.props;
    if (onChangeHandler) {
      onChangeHandler(tags);
    }
  };

  showInput = () => {
    this.inputVisible = true;
  };

  handleInputChange = (e: any) => {
    this.inputValue = e.target.value;
  };

  handleInputConfirm = () => {
    const inputValue = this.inputValue;
    let tags = this.tags;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }

    this.tags = tags;
    this.inputVisible = false;
    this.inputValue = '';

    const { onChangeHandler } = this.props;
    if (onChangeHandler) {
      onChangeHandler(tags);
    }
  };

  render() {
    const { editable, name } = this.props;

    return (
      <div>
        {this.tags.map((tag, index) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag
              key={tag}
              style={{ margin: '2px' }}
              closable={editable}
              onClose={() => this.handleClose(tag)}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {this.inputVisible && (
          <Input
            autoFocus={true}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={this.inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
            name={name}
          />
        )}
        {!this.inputVisible && editable && (
          <Button size="small" type="dashed" onClick={this.showInput}>
            + New Tag
          </Button>
        )}
      </div>
    );
  }
}

export default Component;
