import { Drawer } from 'antd';
import React, { FC } from 'react';
import { TrackingPreviewDrawerData } from '../types';
import { DeliveryTrackingContent } from './delivery-tracking-content';

type Props = {
  drawerData: TrackingPreviewDrawerData;
  isVisible: boolean;
  onClose: () => void;
};

export const TrackingPreviewDrawer: FC<Props> = ({ isVisible, drawerData, onClose }) => {
  return (
    <Drawer destroyOnClose width={600} closable onClose={onClose} visible={isVisible}>
      <DeliveryTrackingContent drawerData={drawerData} />
    </Drawer>
  );
};
