import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import * as React from 'react';
import { style as tss } from 'typestyle';

import { AddButton } from '@src/components';
import { FormattedMessage } from '@src/i18n';
import { WarehouseCutoffTimesFormContainer } from './warehouse-cutoff-times-form-container';

import { Button, Select, Tooltip } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { LABELS } from '@src/dictionaries';
import {
  ConfigRegionModel,
  ConfigWarehouseModel,
  CreateWarehouseCutoffTimesModel,
} from '@src/models';
import { defaultCutoffTimes } from '@src/utils';
import { useSelector } from '@src/utils/hooks';
import { warehouseSelectors } from '../selectors';

const SelectField = withFormItem(Select);

interface OwnProps {
  warehouse: ConfigWarehouseModel;
  region?: ConfigRegionModel;
  createCutoffTimes: (properties: { model: CreateWarehouseCutoffTimesModel }) => void;
}

export const WarehouseCutoffTimesList: React.FunctionComponent<OwnProps> = ({
  warehouse,
  region,
  createCutoffTimes,
}) => {
  const [isAddingOperatingSchedule, setIsAddingOperatingSchedule] = React.useState(false);
  const [selectedMethod, setSelectedMethod] = React.useState<string | null>(null);
  const shippingMethodOptionsGroupedByCarrier = useSelector(state =>
    warehouseSelectors.getShippingMethodsOptionsForWarehouse(state, region?.id, warehouse.id)
  );

  const handleCreateCutoffTimes = () => {
    if (selectedMethod) {
      createCutoffTimes({
        model: {
          warehouseId: warehouse.id,
          method: selectedMethod,
          cutoffTimes: defaultCutoffTimes,
        },
      });

      setIsAddingOperatingSchedule(false);
      setSelectedMethod(null);
    }
  };

  return (
    <Row>
      <Col span={6}>
        <div className={styles.wrapper}>
          <FormattedMessage id="OPERATING_SCHEDULES" />
          <Tooltip title={<FormattedMessage id="OPERATING_SCHEDULES_TOOLTIP" />}>
            <InfoCircleOutlined
              style={{
                marginBottom: 'auto',
                marginLeft: '5px',
              }}
            />
          </Tooltip>
        </div>
      </Col>
      <Col span={18}>
        {warehouse.operatingSchedule ? (
          Object.entries(warehouse.operatingSchedule).map(([shippingMethod, values]) => (
            <WarehouseCutoffTimesFormContainer
              key={shippingMethod}
              region={region}
              warehouseId={warehouse.id}
              shippingMethod={shippingMethod}
              cutoffTimes={values && values.cutoffTimes}
              carrierCutoff={values && values.carrierCutoff}
              operatingSchedule={warehouse.operatingSchedule!}
            />
          ))
        ) : (
          <Divider className={styles.divider} />
        )}
        {isAddingOperatingSchedule ? (
          <div className={styles.addProductContainer}>
            <SelectField
              showSearch
              filterOption={(stringQuery, { props: { children } }) => {
                const re = new RegExp(stringQuery, 'i');
                return children ? re.test(children.toString()) : true;
              }}
              placeholder={LABELS.SELECT_METHOD}
              labelClassName={styles.selectWrapper}
              className={styles.selectMethod}
              value={selectedMethod || ''}
              groupOptions={shippingMethodOptionsGroupedByCarrier}
              onChange={v => setSelectedMethod(v.toString())}
              wrapperCol={{ span: 24 }}
            />
            <Button
              type="primary"
              className={styles.button}
              onClick={handleCreateCutoffTimes}
              disabled={!selectedMethod}
            >
              <FormattedMessage id="SAVE" />
            </Button>
            <Button className={styles.button} onClick={() => setIsAddingOperatingSchedule(false)}>
              <FormattedMessage id="CANCEL" />
            </Button>
          </div>
        ) : (
          <AddButton
            text={LABELS.NEW_OPERATING_SCHEDULES}
            onClick={() => setIsAddingOperatingSchedule(true)}
          />
        )}
      </Col>
    </Row>
  );
};
const styles = {
  wrapper: tss({
    color: 'rgba(0, 0, 0, 0.5)',
    top: '25px',
    padding: '10px 0',
    display: 'flex',
  }),
  divider: tss({
    margin: '5px 0',
  }),
  selectWrapper: tss({ width: '400px' }),
  selectMethod: tss({
    $nest: {
      '*': {
        boxShadow: 'none !important',
      },
      '.ant-select-selection': {
        border: 'none',
        background: 'inherit',
      },
    },
  }),
  addProductContainer: tss({
    display: 'flex',
  }),
  button: tss({
    marginLeft: '10px',
  }),
};
