import { sanitizeUrl } from '@braintree/sanitize-url';
import { combineEpics, Epic } from 'redux-observable';
import { concat as observableConcat, from as observableFrom, of as observableOf } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { ERRORS } from '../../dictionaries';
import { RootAction, RootState, Services } from '../../modules';
import { MADError } from '../../utils';
import { blobsActions } from './';

const storeFile: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { blobsService, messageService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(blobsActions.storeFileRequest)),
    switchMap(({ payload: { siteId, file } }) =>
      observableFrom(blobsService.storeBlob(siteId, file)).pipe(
        switchMap(({ payload }) =>
          observableConcat(
            observableOf(blobsActions.storeFileSuccess(payload)).pipe(
              tap(() => {
                messageService.success(ERRORS.STORING_FILE_SUCCESS);
              })
            ),
            observableOf(blobsActions.getTokenRequest({ siteId, url: payload }))
          )
        ),
        catchError((error: MADError) =>
          observableOf(blobsActions.storeFileError(error.message)).pipe(
            tap(() => {
              messageService.error(ERRORS.STORING_FILE_ERROR);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

const getToken: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { messageService, loggingService, gentokenService }
) =>
  action$.pipe(
    filter(isActionOf(blobsActions.getTokenRequest)),
    mergeMap(({ payload: { siteId, url } }) =>
      observableFrom(gentokenService.getBlobToken(siteId, url)).pipe(
        map(({ url: urlWithToken }) => blobsActions.getTokenSuccess(sanitizeUrl(urlWithToken))),
        catchError((error: MADError) =>
          observableOf(blobsActions.getTokenError(error.message)).pipe(
            tap(() => {
              messageService.error(ERRORS.GETTING_FILE_ERROR);
              loggingService.logError(error);
            })
          )
        )
      )
    )
  );

export const blobsEpics = combineEpics(storeFile, getToken);
