import { Table } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { TableProps } from 'antd/lib/table';
import * as React from 'react';
import { classes, style as tss } from 'typestyle';

const DEFAULT_EMPTY_MESSAGE = 'List is empty';

const styles = {
  listHeaderStyle: { padding: '16px 0' },
  superSimplePagination: tss({
    $nest: {
      '.ant-pagination-item-active': {
        display: 'inline-block !important' as any,
      },
      '.ant-pagination-jump-next': {
        display: 'none',
      },
      '.ant-pagination-item': {
        display: 'none',
      },
    },
  }),
};

interface ITableEmptyProps {
  message: string | JSX.Element;
}
const TableEmpty: React.FunctionComponent<ITableEmptyProps> = props => {
  const { message } = props;

  return <>{message}</>;
};

interface CustomPaginationProps extends PaginationProps {
  superSimple?: boolean;
}

interface IProps<T> extends TableProps<T> {
  hideTitle?: boolean;
  showEmptyMessage?: boolean;
  emptyMessage?: string | JSX.Element;
  useAntdEmptyMessage?: boolean;
  pagination?: CustomPaginationProps | false;
  onRow?: (data: T, index?: number) => object;
}

class Component<T extends object> extends React.Component<IProps<T>, {}> {
  static Column = Table.Column;

  render() {
    const {
      children,
      hideTitle,
      emptyMessage,
      title,
      pagination,
      className,
      showEmptyMessage = true,
      useAntdEmptyMessage,
      ...restProps
    } = this.props;

    const { dataSource } = this.props;

    // Antd displays "No data" + icon when datasource is empty, if !useAntdEmptyMessage then our custom solution is used
    if (!useAntdEmptyMessage) {
      if (!dataSource || dataSource.length === 0) {
        if (!showEmptyMessage) {
          return null;
        } else {
          return <TableEmpty message={emptyMessage || DEFAULT_EMPTY_MESSAGE} />;
        }
      }
    }

    const paginationTotal =
      typeof this.props.pagination === 'object' && this.props.pagination.total;

    const itemsCount = (currentPageData: readonly []) =>
      currentPageData ? `${paginationTotal || dataSource?.length} items` : 0;

    const superSimplePaginationStyles =
      pagination && pagination.superSimple && styles.superSimplePagination;

    return (
      <Table<T>
        bordered={false}
        title={hideTitle ? undefined : title || itemsCount}
        pagination={pagination}
        className={classes(className, superSimplePaginationStyles)}
        {...restProps}
      >
        {children}
      </Table>
    );
  }
}

export default Component;
