export const DOMAIN = {
  FEATURES: 'Features',
  REGION: 'Region',
  REGIONS: 'Regions',
  LOCATION: 'Location',
  LOCATIONS: 'Locations',
  WAREHOUSES: 'Warehouses',
  WAREHOUSE: 'Warehouse',
  OPERATING_SCHEDULE: 'Operating Schedule',
  CUTOFF_TIMES: 'Cutoff Times',
  TRANSPORT_ORDER: 'Transport Order',
  TRANSPORT_ORDERS: 'Transport Orders',
  SHIPMENTS: 'Shipments',
  SHIPMENT: 'Shipment',
  LINE_ITEMS: 'Line Items',
  ADDRESS_BOOK: 'Addresses',
  CONTACT: 'Contact',
  USER: 'User',
  TEMPLATE: 'Shipment Template',
  TEMPLATES: 'Shipments Templates',
  WATCHLISTS: 'Watchlists',
  GROUPS: 'Groups',
  MERCHANTS: 'Merchants',
  EXPERIMENTS: 'Experiments',
  EXPERIMENT: 'Experiment',
  TRACKING_NUMBERS: 'Tracking numbers',
  BOOKING_RULES: 'Booking rules',
};
