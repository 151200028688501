import { combineEpics, Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { sessionHistoryActions } from '.';
import { RootAction, RootState, Services } from '..';
import { MADError } from '../../utils';

const getSessionHistoryEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  {},
  { cosEventsService, loggingService }
) =>
  action$.pipe(
    filter(isActionOf(sessionHistoryActions.listSessionEventsRequest)),
    switchMap(action =>
      from(cosEventsService.listEvents(action.payload.sessionID)).pipe(
        map(res => sessionHistoryActions.listSessionEventsSuccess(res)),
        catchError((error: MADError) =>
          of(sessionHistoryActions.listSessionEventsError(error.message)).pipe(
            tap(() => loggingService.logError(error))
          )
        )
      )
    )
  );

export const sessionEventsEpics = combineEpics(getSessionHistoryEpic);
