import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { defaultTo, either, isEmpty, isNil, join, path, pipe, prop, props, reject } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { style } from 'typestyle';
import { defaultTheme } from '../../../styles';

import { Badge, Button, Spinner, Table, Tooltip } from '../../../controls';
import { LABELS, MESSAGES } from '../../../dictionaries';
import {
  ShipmentModel,
  TransportOrderListFiltersModel,
  TransportOrderModel,
} from '../../../models';
import { RootState } from '../../../modules';
import { stringUtils } from '../../../utils';
import { getNumberOfToFilters } from '../../../utils/counters';
import { formatIsoDate } from '../../../utils/date';
import { dateSorter } from '../../../utils/sorting';
import { TransportOrderFilterPanel } from '../components';
import { TransportOrderSourceTag } from './';

type ComponentProps = Readonly<{
  isLoading: boolean;
  pageSize: number;
  total: number;
  currentPage: number;
  onPaginationChange: (nextPage: number, direction: string) => void;
  items: TransportOrderModel[];
  onItemClick: (id: string) => void;
  filters: TransportOrderListFiltersModel;
  onFiltersApply: (filters: TransportOrderListFiltersModel) => void;
  onFiltersClear: () => void;
  onExportItems: () => void;
  isAnyItemSelected: () => boolean;
}>;

@observer
class Component extends React.Component<ComponentProps, {}> {
  @observable
  sortingState = {
    order: 'descend' as 'ascend' | 'descend',
    columnKey: 'created',
  };

  currentSearchValue = '';

  getAddressName = pipe(
    path(['contactInfo', 'address']),
    props(['name', 'careOf']),
    reject(either(isNil, isEmpty)),
    join(', ')
  );

  getShipmentsStatus: (shipments: ShipmentModel[] | undefined) => number = pipe(
    defaultTo([]),
    prop('length')
  );

  setSortingState = (sorting: typeof Component.prototype.sortingState) => {
    this.sortingState.columnKey = sorting.columnKey;
    this.sortingState.order = sorting.order;
  };

  handleItemClick = (id: string) => {
    this.props.onItemClick(id);
  };

  createItemButton = (id: string) => (
    <Tooltip title={id}>{stringUtils.truncateId(id) || <Badge.Empty />}</Tooltip>
  );

  handleTableChange: typeof Table.prototype.props.onChange = (pagination, filters, sorter: any) => {
    if (
      typeof pagination === 'object' &&
      (pagination.current !== this.props.currentPage || this.sortingState.order !== sorter.order)
    ) {
      this.props.onPaginationChange(
        pagination.current || 1,
        sorter.order === 'ascend' ? 'ASCENDING' : 'DESCENDING'
      );
    }

    this.setSortingState(sorter as any);
  };

  render() {
    const {
      isLoading,
      items,
      pageSize,
      total,
      currentPage,
      filters,
      onFiltersApply,
      onFiltersClear,
    } = this.props;

    const { getPlurarFormOf } = stringUtils;

    const paginationConfig = {
      defaultCurrent: currentPage,
      current: currentPage,
      pageSize,
      total,
      size: 'small' as const,
      showQuickJumper: true,
      showTotal: (count: number, range: number[]) => `${range[0]}-${range[1]} of ${count} items`,
      showSizeChanger: false,
    };

    const emptyMessage = isLoading ? (
      <Spinner delay={0} active={isLoading} />
    ) : (
      MESSAGES.EMPTY_TRANSPORT_ORDERS_LIST
    );

    return (
      <section className={styles.section}>
        <div className={styles.exportWrapper}>
          <Button
            onClick={() => this.props.onExportItems()}
            disabled={!this.props.isAnyItemSelected()}
          >
            {LABELS.EXPORT_TO_CSV}
          </Button>
        </div>
        <div className={styles.filtersWrapper}>
          <TransportOrderFilterPanel
            filters={this.props.filters}
            onApplyClick={onFiltersApply}
            onClearClick={onFiltersClear}
            disabled={isLoading}
          />
        </div>
        {filters && getNumberOfToFilters(filters) ? (
          <div>
            {getPlurarFormOf('filter', getNumberOfToFilters(filters))} applied •{' '}
            {getPlurarFormOf('item', total)} after filtering
            <span className={styles.clearFiltersButton} onClick={onFiltersClear}>
              &nbsp; {LABELS.CLEAR_ALL_FILTERS}
            </span>
          </div>
        ) : (
          <div>
            <span>{this.props.total} items</span>
          </div>
        )}
        <Table<TransportOrderModel>
          loading={isLoading}
          dataSource={items}
          rowKey={record => record.id}
          pagination={paginationConfig}
          onChange={this.handleTableChange}
          emptyMessage={filters ? undefined : emptyMessage}
          sortDirections={['ascend', 'descend']}
          onRow={record => ({
            onClick: () => this.props.onItemClick(record.id),
            className: styles.clickableElement,
          })}
          title={() => ''}
        >
          <Table.Column<TransportOrderModel>
            title="Transport Order ID"
            key="name"
            width="10px"
            render={(text, record) => record.id}
          />
          <Table.Column<TransportOrderModel>
            title="External Order ID"
            key="external_id"
            width="10px"
            render={(text, record) => record.external?.referenceIds?.[0]}
          />
          <Table.Column<TransportOrderModel>
            title="Created at"
            key="created"
            width="160px"
            render={(text, record) => formatIsoDate(record.createdAt)}
            sorter={(recordA, recordB) => dateSorter(recordA.createdAt, recordB.createdAt)}
            sortOrder={
              this.sortingState.columnKey === 'created' ? this.sortingState.order : undefined
            }
          />
          <Table.Column<TransportOrderModel>
            title="Source"
            key="source"
            width="80px"
            render={(text, record) => <TransportOrderSourceTag source={record?.metadata?.source} />}
          />
          <Table.Column<TransportOrderModel>
            title="Sender Address"
            key="addressFrom"
            width="150px"
            render={(text, record) => this.getAddressName(record.sender)}
          />
          <Table.Column<TransportOrderModel>
            title="Delivery Address"
            key="addressTo"
            width="150px"
            render={(text, record) => this.getAddressName(record.receiver)}
          />
        </Table>
      </section>
    );
  }
}

// Connected Type
type OwnProps = Pick<
  ComponentProps,
  | 'isLoading'
  | 'pageSize'
  | 'total'
  | 'currentPage'
  | 'onPaginationChange'
  | 'items'
  | 'onItemClick'
  | 'onFiltersApply'
  | 'onFiltersClear'
>;

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  filters: state.transportOrders.filters,
});

export const TransportOrderList = connect(mapStateToProps, {})(Component);

const styles = {
  section: style({ position: 'relative' }),
  exportWrapper: style({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '10px',
    marginBottom: '10px',
  }),
  filtersWrapper: style({ position: 'absolute', right: '0', marginTop: '10px', zIndex: 100 }),
  clearFiltersButton: style({ color: defaultTheme.color.primary, cursor: 'pointer' }),
  clickableElement: style({ cursor: 'pointer' }),
};
