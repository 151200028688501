import * as React from 'react';

import { Tag } from '../../../controls';
import { TrackingStatus } from '../../../models';
import { capitalize, removeUnderscores } from '../../../utils/string';

interface IProps {
  tracking_status: TrackingStatus;
  tagColor: string;
  style?: object;
}

const DeliveryEventTag: React.StatelessComponent<IProps> = props => {
  const { tracking_status, style, tagColor } = props;
  return (
    <Tag color={tagColor} style={style}>
      {capitalize(removeUnderscores(tracking_status.toLowerCase()))}
    </Tag>
  );
};

export { DeliveryEventTag };
