import * as React from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { Spin } from 'antd';

import { useDispatch, useSelector } from '@src/utils/hooks';
import { Tag } from '@src/controls';
import { StatusBarContainer } from '@src/components/status-bar/status-bar-container';
import { defaultTheme } from '@src/styles';
import { FormattedMessage } from '@src/i18n';
import { stringUtils } from '@src/utils';
import { SitesSelectors } from '@src/modules/sites';
import { services } from '@src/services';
import { mapCountryNameToCode } from '@src/dictionaries/mapping';
import { useCreateDraft } from '@src/utils/conditional-dispatchers';
import { configActions } from '@src/modules/config';

import { FeaturesTable } from './features-table';
import { UpsellWidgetDrawer } from '@src/components/widget-drawers/upsell-widget-drawer';

interface OwnProps {
  country: string;
}

export const UpsellCountryPageComponent: React.FC<OwnProps> = ({ country }) => {
  const countryCode = mapCountryNameToCode(country);
  const capitalizedCountry = stringUtils.capitalize(country);

  const siteId = useSelector(SitesSelectors.getDraftSiteIdOrMasterSiteId);
  const isFetching = useSelector(state => state.sites.isFetching);
  const isCreatingDraft = useSelector(state => state.config.isCreatingDraft);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const createDraft = useCreateDraft();

  const updateMutation = useMutation<void, void, Record<string, any>>(async values => {
    const draftSiteId = await createDraft();
    dispatch(configActions.genericConfigModificationRequest());
    await services.selfCareService.updateUpsellCountryConfiguration(
      draftSiteId,
      countryCode,
      values
    );
    dispatch(configActions.genericConfigModificationSuccess());
    // old draft site data might load inbetween.
    queryClient.invalidateQueries(['site']);
  });

  const countryMutation = useMutation(async () => {
    const draftSiteId = await createDraft();
    dispatch(configActions.genericConfigModificationRequest());
    const siteQueryData = queryClient.getQueryData(['site', siteId]) as any;
    const features = siteQueryData?.upsellWidgetConfiguration?.features || {};
    await services.selfCareService.createUpsellCountryConfiguration(
      draftSiteId,
      countryCode,
      features
    );
    dispatch(configActions.genericConfigModificationSuccess());
    // old draft site data might load inbetween.
    queryClient.invalidateQueries(['site']);
  });

  const siteQuery = useQuery({
    queryKey: ['site', siteId],
    queryFn: async () => {
      return await services.configService.getSite(siteId);
    },
    staleTime: Infinity,
    enabled: !updateMutation.isLoading && !countryMutation.isLoading,
  });

  const configuration = siteQuery.data?.upsellWidgetConfiguration || {};

  if (isFetching || !siteQuery.isSuccess || countryMutation.isLoading) {
    return <Spin size="large" />;
  }

  const hasConfiguration = configuration.by_country && configuration.by_country[countryCode];

  if (!hasConfiguration) {
    return (
      <a onClick={() => countryMutation.mutate()}>
        <FormattedMessage id="SET_COUNTRY_SETTINGS" />
      </a>
    );
  }

  return (
    <FeaturesTable
      key={siteQuery.dataUpdatedAt}
      initialValues={configuration.by_country[countryCode]}
      headerTitle={capitalizedCountry}
      loading={isCreatingDraft || siteQuery.isFetching || updateMutation.isLoading}
      onSubmit={updateMutation.mutate}
    />
  );
};

export const UpsellCountryPage: React.FC<OwnProps> = ({ country }) => {
  const capitalizedCountry = stringUtils.capitalize(country);

  return (
    <>
      <StatusBarContainer />
      <div>
        <Tag color={defaultTheme.color.primary} nonClickable={true}>
          <FormattedMessage id="COUNTRY" />
        </Tag>
        <h2>{capitalizedCountry}</h2>
      </div>
      <UpsellCountryPageComponent country={country} />
      <UpsellWidgetDrawer />
    </>
  );
};
