import { ArrowRightOutlined, InboxOutlined, RollbackOutlined } from '@ant-design/icons';
import { ContactDetails } from '@src/components/contact-details';
import { configSelectors } from '@src/modules/config';
import { routerActions, routerSelectors } from '@src/modules/router';
import { siwActions, siwSelectors } from '@src/modules/siw';
import { services } from '@src/services';
import { capitalize } from '@src/utils/string';
import { isValidFormStateContainer } from '@src/utils/validation';
import { Col, Modal as AntModal, Modal, Row } from 'antd';
import { isEqual, omit } from 'lodash';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import * as moment from 'moment';
import { compose, defaultTo, eqBy, equals, flatten, path, pathOr, prop, unionWith } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import * as React from 'react';
import { connect } from 'react-redux';
import lift, { Option } from 'space-lift';
import { stylesheet } from 'typestyle';
import { v4 } from 'uuid';
import { TrackAndTraceContainer } from '.';
import { CarrierProductWithLogo, ContainerContent, ContainerFixedHeader } from '../../components';
import { Button, Card, modalConfirm, Spinner, Text } from '../../controls';
import { withFormItem } from '../../decorators';
import { LABELS, MESSAGES } from '../../dictionaries';
import { InputFieldForm } from '../../forms';
import {
  AddonModel,
  AddressModel,
  ContactModel,
  CustomerInfoModel,
  DeliveryModel,
  ExternalOrderContentItemModel,
  LineItemModel,
  ParcelModel,
  ShipmentEditModel,
  ShipmentModel,
  TransportOrderContentItemModel,
} from '../../models';
import { RootState } from '../../modules';
import { addressBookActions } from '../../modules/address-book';
import { blobsActions, blobsSelectors } from '../../modules/blobs';
import { dictionariesActions } from '../../modules/dictionaries';
import { eventTrackingActions } from '../../modules/event-tracking';
import { labelMergerActions } from '../../modules/label-merger';
import { shipmentsActions, ShipmentsSelectors } from '../../modules/shipments';
import { somSearchActions, SomSearchSelectors } from '../../modules/som-search';
import {
  transportOrdersActionCreators,
  TransportOrdersSelectors,
} from '../../modules/transport-orders';
import { defaultTheme } from '../../styles';
import { decimalToStringWithoutFactorial } from '../../utils/currency';
import { isFormStateContainerDirty } from '../../utils/forms';
import { CheckoutWidgetConfiguration } from '../checkout-widget';
import type { Direction } from '../tracking-numbers/types';
import {
  AddonsTable,
  createLineItemFormState,
  DeliveryTime,
  DeliveryTimeFormState,
  ExternalOrdersTable,
  ExtraInformation,
  ExtraInformationFormState,
  LineItemFormState,
  LineItemsTableForm,
  LineItemsTableFormState,
  ShipmentAddressSection,
  ShipmentAddressSelectorFormState,
  ShipmentOptionsForm,
  ShipmentOptionsFormState,
  ShipmentStatusTag,
  TransportOrderIdForm,
  TransportOrdersTable,
} from './components';
import { CustomsDeclaration } from './components/customs-declaration';
import { ExternalIdsContainer } from './external-ids-container';
import { InvoiceNumber, InvoiceNumberFormState } from './components/invoice-number';

const FormItemText = withFormItem(Text);

// Component API
type OwnProps = {
  itemId: string;
  tosId?: string;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  isFetching: state.shipments.isFetching,
  isFetchingMetadata: state.somSearch.isFetching,
  isFetchingContacts: state.addressBook.isFetching,
  isFetchingOrderNumber: state.somSearch.isFetching,
  shipmentStatus: SomSearchSelectors.getShipmentStatus(state, ownProps.itemId),
  shipment: ShipmentsSelectors.getShipmentById(state, ownProps.itemId),
  trackingNumbers: ShipmentsSelectors.getDeliveryTrackingNumbersForShipment(state, ownProps.itemId),
  shipmentsError: state.shipments.error,
  shipmentLineItems: ShipmentsSelectors.getShipmentLineItems(state, ownProps.itemId),
  carrierAddons: state.dictionaries.carrierAddons,
  contactsList: state.addressBook.contacts,
  customerContacts: state.addressBook.customerContacts,
  senderContacts: state.addressBook.senderContacts,
  deliveryContacts: state.addressBook.deliveryContacts,
  selectedSiteId: state.sites.selectedSiteId,
  transportOrderList: TransportOrdersSelectors.getTransportOrderList(state),
  transportOrder: TransportOrdersSelectors.getTransportOrderById(state, ownProps.tosId!),
  isFetchingTransportOrders: state.transportOrders.isFetching,
  attachmentField: blobsSelectors.attachmentField(state),
  attachmentUrl: blobsSelectors.attachmentUrl(state),
  shippingMethodForShipment: configSelectors.getShippingMethodForShipmentById(
    state,
    ownProps.itemId
  ),
  routerState: routerSelectors.getRouterState(state),
  sessionId: siwSelectors.getSessionId(state),
  siteId: state.sites.selectedSiteId,
  locationAddress: siwSelectors.getLocationAddress(state),
  deliveryTime: siwSelectors.getDeliveryTime(state),
  shippingType: ShipmentsSelectors.getShippingTypeForShipment(state, ownProps.itemId),
  configuredShippingMethodsNames: configSelectors.getShippingMethodNamesConfiguredForBooking(state),
});
const dispatchProps = {
  getShipment: shipmentsActions.getShipmentRequest,
  cancelShipment: shipmentsActions.cancelShipmentRequest,
  duplicateShipment: shipmentsActions.duplicateShipment,
  createReturn: shipmentsActions.createReturn,
  cancelAndDuplicateShipment: shipmentsActions.cancelAndDuplicateShipment,
  getContacts: addressBookActions.getContactsListRequest,
  getCustomerContacts: addressBookActions.getCustomerContactsRequest,
  getSenderContacts: addressBookActions.getSenderContactsRequest,
  getDeliveryContacts: addressBookActions.getDeliveryContactsRequest,
  createContact: addressBookActions.createContactRequest,
  createContactFromModal: addressBookActions.createContactFromModalRequest,
  createTempContact: addressBookActions.createTempContact,
  updateContactFromModal: addressBookActions.updateContactFromModalRequest,
  getTransportOrder: transportOrdersActionCreators.getTransportOrderRequest,
  getTransportOrderList: transportOrdersActionCreators.getTransportOrderListRequest,
  updateContact: addressBookActions.updateContactRequest,
  bookAndPrintParcels: shipmentsActions.bookAndPrintParcelsRequest,
  bookParcels: shipmentsActions.bookParcelsRequest,
  getCarrierAddons: dictionariesActions.getCarrierAddonsRequest,
  bookPickup: shipmentsActions.bookPickupDeliveriesRequest,
  editShipment: shipmentsActions.editShipmentRequest,
  storeFile: blobsActions.storeFileRequest,
  storeFileSuccess: blobsActions.storeFileSuccess,
  getToken: blobsActions.getTokenRequest,
  resetAttachment: blobsActions.resetAttachment,
  viewShipmentPage: eventTrackingActions.viewShipmentPage,
  editShipmentButtonClick: eventTrackingActions.editShipmentButtonClick,
  cancelShipmentClick: eventTrackingActions.cancelShipmentClick,
  getShipmentMetadata: somSearchActions.getShipmentMetadataRequest,
  resetMergedLabels: labelMergerActions.resetMerged,
  getSession: siwActions.getSessionRequest,
  routerPush: routerActions.push,
  routerGoBack: routerActions.goBack,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & OwnProps;

const styles = stylesheet({
  addressCard: {
    marginBottom: defaultTheme.lineHeight,
    $nest: {
      '.ant-card-body': {
        minHeight: '200px',
      },
    },
  },
  card: {
    marginBottom: defaultTheme.lineHeight,
  },
  referencesFormItem: {
    $nest: {
      '.ant-form-item-label': {
        fontWeight: defaultTheme.font.bold,
      },
    },
  },
  shippingLogo: {
    marginRight: '8px',
  },
  referencesWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  sscc: {
    flex: 1,
  },
  carrierProduct: {
    flex: 1,
  },
  directionType: {
    flex: 1,
  },
  transportOrder: {
    flex: 1,
  },
});

type State = {
  isSelectPickupPointModalVisible: boolean;
  isUpdatingShipment: boolean;
};

@observer
export class Component extends React.Component<Props, State> {
  state: State = {
    isSelectPickupPointModalVisible: false,
    isUpdatingShipment: false,
  };
  @observable editMode = false;

  @observable
  formStateContainer = {
    tosIdFormState: TransportOrderIdForm.createFormState(),
    shipmentOptionsFormState: ShipmentOptionsFormState.create(),
    shipmentAddressSelectorFormState: ShipmentAddressSelectorFormState.create(),
    lineItemsTableFormState: LineItemsTableFormState.create(),
    externalIdFormState: InputFieldForm.createFormState(),
    extraInformationFormState: ExtraInformationFormState.create(),
    deliveryTimeFormState: DeliveryTimeFormState.create(),
    invoiceNumberFormState: InvoiceNumberFormState.create(),
  };

  @observable transportOrders = observable<TransportOrderContentItemModel>([]);
  @observable externalOrders = observable<ExternalOrderContentItemModel>([]);

  templateContacts = observable<ContactModel>([]);

  @observable isPreorder = observable<boolean>();
  @observable formSubmitted = false;

  expandedRowKeys = observable<string>([]);

  @computed
  get formStateDirty() {
    return isFormStateContainerDirty(this.formStateContainer);
  }

  componentDidMount() {
    const { shipment, itemId, viewShipmentPage, selectedSiteId } = this.props;

    viewShipmentPage();
    if (selectedSiteId) {
      this.props.getShipmentMetadata({ shipmentId: itemId, siteId: selectedSiteId });
    }
    this.props.getShipment({ id: itemId });
    this.props.getContacts({});
    this.props.getTransportOrderList();
    this.props.getCarrierAddons();

    if (shipment != null) {
      this.initializeFormState(shipment);
    }

    this.props.resetAttachment();
    this.props.resetMergedLabels();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevModel = prevProps.shipment;
    const { shipment, locationAddress, sessionId, siteId, getSession, deliveryTime } = this.props;

    const shipmentWithoutUpdatedAtProperty = omit(shipment, 'updatedAt');
    const prevModelWithoutUpdatedAtProperty = omit(prevModel, 'updatedAt');
    const isNewObjectEqualToOldOne = isEqual(
      shipmentWithoutUpdatedAtProperty,
      prevModelWithoutUpdatedAtProperty
    );

    if (shipment != null && !isNewObjectEqualToOldOne && !this.state.isUpdatingShipment) {
      this.initializeFormState(shipment);
    }

    if (!equals(prevProps.locationAddress, locationAddress) && locationAddress) {
      const { toAddressSelectorFormState } =
        this.formStateContainer.shipmentAddressSelectorFormState.$;
      const toContactModel = new ContactModel();
      toContactModel.address = {
        ...locationAddress,
        coordinates: {
          lat: locationAddress.coordinates?.lat.toString() ?? '0',
          lng: locationAddress.coordinates?.lng.toString() ?? '0',
        },
      };
      toContactModel.id = v4();

      this.templateContacts.push(toContactModel);
      toAddressSelectorFormState.$.addressIdFieldState.value = toContactModel.id;
    }

    if (!equals(prevProps.deliveryTime, deliveryTime) && deliveryTime) {
      this.formStateContainer.deliveryTimeFormState = DeliveryTimeFormState.create(deliveryTime);
    }

    if (prevState.isSelectPickupPointModalVisible !== this.state.isSelectPickupPointModalVisible) {
      if (sessionId && siteId) {
        getSession({ siteId, sessionId });
      }
    }
  }

  getLocationDataFromShipment = (model: ShipmentModel) => {
    const locationExternalId = model.parcels[0]?.deliveries[0]?.locationRef ?? null;
    if (locationExternalId) {
      return {
        locationName: model.addressTo.name,
        locationExternalId: model.parcels[0]?.deliveries[0]?.locationRef ?? '',
      };
    } else {
      return {
        locationName: '',
        locationExternalId: '',
      };
    }
  };

  initializeFormState = (model: ShipmentModel) => {
    this.formStateContainer.tosIdFormState = TransportOrderIdForm.createFormState(model.tosId);
    this.formStateContainer.externalIdFormState = InputFieldForm.createFormState(model.externalId);
    this.formStateContainer.lineItemsTableFormState = LineItemsTableFormState.create(
      model.lineItems.map(lI => ({ active: false, lineItem: lI }))
    );
    this.formStateContainer.deliveryTimeFormState = DeliveryTimeFormState.create({
      start: model.parcels?.[0]?.deliveries?.[0]?.deliveryTime?.start,
      end: model.parcels?.[0]?.deliveries?.[0]?.deliveryTime?.end,
    });

    const locationData = this.getLocationDataFromShipment(model);
    this.formStateContainer.shipmentOptionsFormState = ShipmentOptionsFormState.create({
      shipmentValue: model.shipmentValue,
      shippingDate: model.shippingDate,
      shippingMethod: model.parcels?.[0]?.deliveries?.[0]?.shippingMethod,
      availableAddons: this.props.carrierAddons,
      enabledAddons: model.addons,
      locationName: locationData.locationName,
      locationExternalId: locationData.locationExternalId,
      meta: model.meta,
      courierInstructions: model.parcels?.[0]?.deliveries?.[0]?.courierInstructions ?? '',
      doorCode: pathOr('', ['addressTo', 'doorCode'], model),
    });

    this.isPreorder.set(moment(model.shippingDate).isAfter(undefined, 'day'));

    this.formStateContainer.shipmentAddressSelectorFormState =
      ShipmentAddressSelectorFormState.create();
    this.loadAddresses(model);
    this.transportOrders.replace(model.contents.transportOrders);
    this.externalOrders.replace(model.contents.externalOrders);

    this.formStateContainer.extraInformationFormState = ExtraInformationFormState.create({
      notes: model?.meta?.notes,
      attachment: model?.meta?.attachment,
    });

    this.formStateContainer.invoiceNumberFormState = InvoiceNumberFormState.create({
      invoiceNumber: model?.meta?.['label_mod_data.invoice_num'],
    });

    if (
      path(['meta', 'attachment'], model) &&
      this.props.selectedSiteId &&
      this.props.attachmentUrl === ''
    ) {
      this.props.storeFileSuccess(model.meta.attachment);
      this.props.getToken({ siteId: this.props.selectedSiteId, url: model.meta.attachment });
    }
  };

  loadAddresses = (model?: ShipmentModel) => {
    if (model == null) {
      return;
    }

    this.templateContacts.clear();
    const {
      customerAddressSelectorFormState,
      fromAddressSelectorFormState,
      toAddressSelectorFormState,
      returnAddressSelectorFormState,
    } = this.formStateContainer.shipmentAddressSelectorFormState.$;

    const customerContactModel = new ContactModel();
    customerContactModel.address = { ...model.customerInfo.address };
    if (model.customerInfo.address.name) {
      customerContactModel.address.name = model.customerInfo.address.name;
    }
    customerContactModel.email = model.customerInfo.email;
    customerContactModel.phone = model.customerInfo.phone;
    this.templateContacts.push(customerContactModel);
    customerAddressSelectorFormState.$.addressIdFieldState.value = customerContactModel.id;

    const fromContactModel = new ContactModel();
    fromContactModel.address = { ...model.addressFrom };
    if (model.addressFrom.name) {
      fromContactModel.address.name = model.addressFrom.name;
    }
    this.templateContacts.push(fromContactModel);
    fromAddressSelectorFormState.$.addressIdFieldState.value = fromContactModel.id;

    const toContactModel = new ContactModel();
    toContactModel.address = { ...model.addressTo };
    if (model.addressTo.name) {
      toContactModel.address.name = model.addressTo.name;
    }
    this.templateContacts.push(toContactModel);
    toAddressSelectorFormState.$.addressIdFieldState.value = toContactModel.id;

    if (model.addressReturn.addressLines.length) {
      const returnContactModel = new ContactModel();

      returnContactModel.address = { ...model.addressReturn };
      if (model.addressReturn.name) {
        returnContactModel.address.name = model.addressReturn.name;
      }
      this.templateContacts.push(returnContactModel);
      returnAddressSelectorFormState.$.addressIdFieldState.value = returnContactModel.id;
    }
  };

  handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
  };

  handleEditShipment = () => {
    this.props.editShipmentButtonClick();
    this.editMode = true;
  };

  handleCancelEdit = () => {
    this.editMode = false;
    this.props.getShipment({ id: this.props.shipment ? this.props.shipment.id : '' });
  };

  onEditShipment = async () => {
    const { shipmentAddressSelectorFormState } = this.formStateContainer;

    const formStatesToValidate = {
      deliveryTime: this.formStateContainer.deliveryTimeFormState,
    };

    if (!(await isValidFormStateContainer(formStatesToValidate))) {
      return;
    }

    const returnAddressId =
      shipmentAddressSelectorFormState.$.returnAddressSelectorFormState.$.addressIdFieldState.value;

    if (!returnAddressId) {
      AntModal.confirm({
        content: 'You have not submitted a return address. Do you wish to proceed anyway?',
        okText: 'Confirm',
        onOk: this.editShipment,
        cancelText: 'Cancel',
      });

      return;
    }

    this.editShipment();
  };

  editShipment = async () => {
    const {
      shipmentOptionsFormState,
      shipmentAddressSelectorFormState,
      externalIdFormState,
      extraInformationFormState,
      deliveryTimeFormState,
      invoiceNumberFormState,
    } = this.formStateContainer;

    const shipment = new ShipmentEditModel();
    shipment.shipmentId = this.props.shipment!.id;

    const addons =
      this.formStateContainer.shipmentOptionsFormState.$.carrierAddonsFormState.$.filter(
        a => a.$.isOn
      ).map(
        a =>
          ({
            code: a.$.code,
            name: a.$.name,
            description: a.$.description,
          } as AddonModel)
      );

    const { contactsList, customerContacts, deliveryContacts, senderContacts } = this.props;

    const {
      fromAddressSelectorFormState,
      toAddressSelectorFormState,
      customerAddressSelectorFormState,
      returnAddressSelectorFormState,
    } = shipmentAddressSelectorFormState.$;

    const allContacts = [
      ...contactsList,
      ...customerContacts,
      ...deliveryContacts,
      ...senderContacts,
      ...this.templateContacts.slice(),
    ];

    const customerId = customerAddressSelectorFormState.$.addressIdFieldState.value;

    const toAddressId = toAddressSelectorFormState.$.addressIdFieldState.value;
    const fromAddressId = fromAddressSelectorFormState.$.addressIdFieldState.value;
    const returnAddressId = returnAddressSelectorFormState.$.addressIdFieldState.value;

    const toAddressBookModel = allContacts.find(item => item.id === toAddressId);
    const fromAddressBookModel = allContacts.find(item => item.id === fromAddressId);
    const customerAddressBookModel = allContacts.find(item => item.id === customerId);
    const returnAddressBookModel = allContacts.find(item => item.id === returnAddressId);

    const customerModel = new CustomerInfoModel();
    customerModel.address =
      (customerAddressBookModel && customerAddressBookModel.address) || new AddressModel();
    customerModel.email = (customerAddressBookModel && customerAddressBookModel.email) || '';
    customerModel.phone = (customerAddressBookModel && customerAddressBookModel.phone) || '';

    shipment.customerInfo = customerModel;
    shipment.addressFrom =
      (fromAddressBookModel && fromAddressBookModel.address) || new AddressModel();
    shipment.addressReturn = returnAddressBookModel?.address;
    shipment.addressTo = (toAddressBookModel && toAddressBookModel.address) || new AddressModel();

    const shippingDate =
      shipmentOptionsFormState.$.shippingDateSelectorFormState.$.dateFieldState.$;
    shipment.shippingDate = (shippingDate && shippingDate.toISOString()) || '';
    shipment.shippingMethod =
      shipmentOptionsFormState.$.shippingMethodSelectorFormState.$.shippingMethodFieldState.value;
    shipment.shipmentValue = decimalToStringWithoutFactorial(
      shipmentOptionsFormState.$.valueFieldState.$
    );
    shipment.locationRef = shipmentOptionsFormState.$.pickupPointFormState.$.locationExternalId;

    shipment.meta = shipmentOptionsFormState.$.metadataFieldState.value;
    shipment.courierInstructions =
      shipmentOptionsFormState.$.shipmentOptionsDetailsFormState.$.courierInstructions.$;
    shipment.addressTo.doorCode =
      shipmentOptionsFormState.$.shipmentOptionsDetailsFormState.$.doorCode.$;

    const notes = extraInformationFormState.$.notesFieldState.$;
    if (notes) {
      shipment.meta = { ...shipment.meta, notes };
    }

    const attachment = this.props.attachmentField;
    if (attachment) {
      shipment.meta = { ...shipment.meta, attachment };
    }

    const invoiceNumber = invoiceNumberFormState.$.invoiceNumberFieldState.$;
    if (invoiceNumber) {
      shipment.meta = {
        ...shipment.meta,
        ['label_mod_data.invoice_num']: invoiceNumber,
      };
    }

    shipment.externalId = externalIdFormState.$.inputFieldState.$;

    if (deliveryTimeFormState.$.start.$ && deliveryTimeFormState.$.end.$) {
      shipment.deliveryTime = {
        start: deliveryTimeFormState.$.start.$.format(),
        end: deliveryTimeFormState.$.end.$.format(),
      };
    }

    shipment.addons = addons;

    this.setState({ isUpdatingShipment: true });
    this.props.editShipment({
      siteId: this.props.selectedSiteId || '',
      shipment,
      onComplete: () => {
        this.setState({ isUpdatingShipment: false });
        this.editMode = false;
      },
    });
  };

  handleBack = () => {
    const { tosId, routerPush, routerGoBack } = this.props;
    if (tosId) {
      routerPush({ name: 'TRANSPORT_ORDER_DETAILS', tosId });
    } else {
      const { routerState } = this.props;
      routerState.useCustomBack ? routerGoBack() : routerPush({ name: 'SHIPMENT_LIST' });
    }
  };

  handleCancel = () => {
    const { cancelShipment, cancelShipmentClick, shipment, selectedSiteId } = this.props;
    if (shipment == null || selectedSiteId == null) {
      return;
    }
    modalConfirm({
      title: MESSAGES.CANCEL_SHIPMENT_HEADER,
      content: MESSAGES.CANCEL_SHIPMENT_CONTENT,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        cancelShipment({ siteId: selectedSiteId, shipmentId: shipment.id });
        cancelShipmentClick();
      },
    });
  };

  handleLabelDuplicate = () => {
    const { duplicateShipment, shipment } = this.props;
    if (shipment == null) {
      return;
    }
    duplicateShipment({
      shipmentModel: { ...shipment, shippingDate: moment().format() },
    });
  };

  handleCreateReturn = () => {
    const { createReturn, shipment } = this.props;
    if (shipment == null) {
      return;
    }

    const locationRef = path<string>(['parcels', '0', 'deliveries', '0', 'locationRef'], shipment);

    const returnShipmentModel: ShipmentModel = {
      ...shipment,
      customerInfo: { ...shipment.customerInfo, address: shipment.addressFrom },
      addressFrom: locationRef ? shipment.customerInfo.address : shipment.addressTo,
      addressTo: shipment.addressFrom,
      shippingDate: moment().format(),
    };

    createReturn({ shipmentModel: returnShipmentModel });
  };

  handleCancelAndDuplicate = () => {
    const { cancelAndDuplicateShipment, shipment, selectedSiteId } = this.props;
    if (shipment == null || selectedSiteId == null) {
      return;
    }
    modalConfirm({
      title: MESSAGES.CANCEL_SHIPMENT_HEADER,
      content: MESSAGES.CANCEL_SHIPMENT_CONTENT,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        cancelAndDuplicateShipment({
          siteId: selectedSiteId,
          shipmentId: shipment.id,
          shipmentModel: { ...shipment, shippingDate: moment().format() },
        });
      },
    });
  };

  handleSearchContact = (searchQuery: string) => {
    this.props.getContacts({ searchQuery });
  };

  getShipmentTrackingNumbers = (record: ShipmentModel) => {
    const getTrackingNumbers = (deliveries: DeliveryModel[]) =>
      deliveries.map(delivery => delivery.trackingNumber);
    const getDeliveries = (parcels: ParcelModel[]) =>
      flatten(parcels.map(parcel => parcel.deliveries));
    return compose(getTrackingNumbers, getDeliveries, defaultTo([]), prop('parcels'))(record);
  };

  getHeaderSecondaryActionHandler = () => {
    const { shipment, selectedSiteId, shipmentStatus } = this.props;
    if (shipment == null || selectedSiteId == null) {
      return () => null;
    }

    if (shipmentStatus === 'CREATED') {
      return () =>
        this.props.bookAndPrintParcels({
          siteId: selectedSiteId,
          shipmentId: this.props.itemId,
          tosId: shipment?.tosId,
        });
    }

    return;
  };

  getHeaderMainActionHandler = () => {
    const { shipment, selectedSiteId, shipmentStatus } = this.props;
    if (shipment == null || selectedSiteId == null) {
      return () => null;
    }
    if (this.editMode) {
      return () => this.onEditShipment();
    }

    switch (shipmentStatus) {
      case 'CREATED':
        return () =>
          this.props.bookParcels({ siteId: selectedSiteId, shipmentId: this.props.itemId });
      case 'BOOKED':
        const trackingNumbers = this.getShipmentTrackingNumbers(shipment);
        return () =>
          this.props.bookPickup({
            siteId: selectedSiteId,
            shipmentId: this.props.itemId,
            trackingNumbers,
          });
      default:
        return null;
    }
  };

  getHeaderMainActionLabel = () => {
    if (this.editMode) {
      return LABELS.SAVE;
    }
    switch (this.props.shipmentStatus) {
      case 'CREATED':
        return LABELS.BOOK;
      case 'BOOKED':
        return LABELS.SCHEDULE_PICKUP;
      default:
        return LABELS.SUBMIT;
    }
  };

  getParcelRowNumberById = (): string => {
    return '0';
  };

  handleAddLineItemItem = () => {
    const { lineItemsTableFormState } = this.formStateContainer;
    lineItemsTableFormState.$.push(createLineItemFormState(true, new LineItemModel()));
  };

  handleDeleteLineItem = (item: LineItemFormState) => {
    const { lineItemsTableFormState } = this.formStateContainer;

    lineItemsTableFormState.$.remove(item);
  };

  handleDeleteTransportOrderItem = (index: number) => {
    this.transportOrders.remove(this.transportOrders[index]);
  };

  handleAddTransportOrderItem = () => {
    this.transportOrders.push({
      externalRef: '',
      tosId: '',
    });
  };

  handleUpdateTransportOrderItem = (index: number, item: any) => {
    this.transportOrders[index] = item;
  };

  handleAddExternalOrderItem = () => {
    this.externalOrders.push({
      externalId: '',
    });
  };

  handleUpdateExternalOrderItem = (index: number, item: any) => {
    this.externalOrders[index] = item;
  };

  handleDeleteExternalOrderItem = (index: number) => {
    this.externalOrders.remove(this.externalOrders[index]);
  };

  handleChangePreorder = (b: boolean) => {
    this.isPreorder.set(b);
  };

  handleStoreFile = (file: File) => {
    const { selectedSiteId, storeFile } = this.props;
    if (selectedSiteId) {
      storeFile({ siteId: selectedSiteId, file });
    }
  };

  @action
  handleExpandedRowsChange = (ids: string[]) => {
    this.expandedRowKeys.replace(ids);
  };

  @action
  handleGotoParcel = (id: string) => {
    this.expandedRowKeys.replace([id]);
  };

  handleSearchCustomerContact = (searchQuery: string) => {
    this.props.getCustomerContacts({ searchQuery });
  };

  handleSearchSenderContact = (searchQuery: string) => {
    this.props.getSenderContacts({ searchQuery });
  };

  handleSearchDeliveryContact = (searchQuery: string) => {
    this.props.getDeliveryContacts({ searchQuery });
  };

  handleCreateContactFromModal = (contact: ContactModel, shouldSaveInAddressBook: boolean) =>
    shouldSaveInAddressBook
      ? this.props.createContactFromModal(contact)
      : this.props.createTempContact(contact);

  handleUpdateContactFromModal = (contact: ContactModel) =>
    isNotNil(contact.createdAt) && this.props.updateContactFromModal(contact);

  setModalVisibility = (to: boolean) => {
    if (to) {
      services.siwService.resumeWidget();
    } else {
      services.siwService.suspendWidget();
    }
    this.setState({
      isSelectPickupPointModalVisible: to,
    });
  };

  render() {
    const {
      itemId,
      isFetching,
      isFetchingMetadata,
      shipment,
      shipmentLineItems,
      contactsList,
      shipmentStatus,
      isFetchingContacts,
      transportOrderList,
      isFetchingTransportOrders,
      shipmentsError,
      trackingNumbers,
      customerContacts,
      senderContacts,
      deliveryContacts,
      shippingMethodForShipment,
    } = this.props;
    const allContacts = [...contactsList, ...this.templateContacts.slice()];
    const parcels = Option(shipment)
      .map(s => s.parcels)
      .getOrElse([]);

    const transportOrderToParcelMap: { [key: string]: string } = lift(parcels)
      .map(p =>
        Option(p.contents)
          .map(c => c.transportOrders)
          .map(eos =>
            lift(eos)
              .map(eo => eo.tosId)
              .fold({}, (acc, val) => ({ ...acc, [val]: p.id }))
          )
          .getOrElse({})
      )
      .fold({}, (acc, val) => ({ ...acc, ...val }))
      .value();

    const externalOrderToParcelMap: { [key: string]: string } = lift(parcels)
      .map(p =>
        Option(p.contents)
          .map(c => c.externalOrders)
          .map(eos =>
            lift(eos)
              .map(eo => eo.externalId)
              .fold({}, (acc, val) => ({ ...acc, [val]: p.id }))
          )
          .getOrElse({})
      )
      .fold({}, (acc, val) => ({ ...acc, ...val }))
      .value();

    const {
      tosIdFormState,
      shipmentOptionsFormState,
      lineItemsTableFormState,
      shipmentAddressSelectorFormState,
      extraInformationFormState,
      deliveryTimeFormState,
      invoiceNumberFormState,
    } = this.formStateContainer;

    const transportOrders =
      (shipment && shipment.contents && shipment.contents.transportOrders) || [];
    const externalOrders =
      (shipment && shipment.contents && shipment.contents.externalOrders) || [];
    const addons = (shipment && shipment.addons) || [];

    const isStatusLoading = isFetching || isFetchingMetadata;

    const Addresses = shipment && (
      <Row gutter={16}>
        <Col span={6}>
          <Card title="Recipient Address" className={styles.addressCard}>
            <ContactDetails
              address={shipment.customerInfo.address}
              email={shipment.customerInfo.email}
              phone={shipment.customerInfo.phone}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Sender Address" className={styles.addressCard}>
            <ContactDetails address={shipment.addressFrom} />
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Delivery Address" className={styles.addressCard}>
            <ContactDetails address={shipment.addressTo} />
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Return Address" className={styles.addressCard}>
            <ContactDetails address={shipment.addressReturn} />
          </Card>
        </Col>
      </Row>
    );

    const additionalActionsConfig = () => {
      const withCreateReturnButton = <T extends {}>(actions: T[]) => {
        if (this.props.shipment?.directionType !== 'RETURN') {
          return [...actions, { label: LABELS.CREATE_RETURN, handler: this.handleCreateReturn }];
        }

        return actions;
      };

      if (this.editMode) {
        return [{ label: LABELS.CANCEL, handler: this.handleCancelEdit }];
      }

      switch (shipmentStatus) {
        case 'CREATED':
          return [
            { label: `${LABELS.CANCEL} ${LABELS.SHIPMENT}`, handler: this.handleCancel },
            { label: LABELS.CANCEL_DUPLICATE, handler: this.handleCancelAndDuplicate },
            { label: LABELS.EDIT_SHIPMENT, handler: this.handleEditShipment },
            { label: LABELS.DUPLICATE, handler: this.handleLabelDuplicate },
          ];

        case 'BOOKED':
          return withCreateReturnButton([
            { label: `${LABELS.CANCEL} ${LABELS.SHIPMENT}`, handler: this.handleCancel },
            { label: LABELS.CANCEL_DUPLICATE, handler: this.handleCancelAndDuplicate },
            { label: LABELS.DUPLICATE, handler: this.handleLabelDuplicate },
          ]);

        default:
          return withCreateReturnButton([
            { label: LABELS.DUPLICATE, handler: this.handleLabelDuplicate },
          ]);
      }
    };

    const attachmentField = (shipment && shipment.meta && shipment.meta.attachment) || '';
    const attachmentUrl =
      (shipment && shipment.meta && shipment.meta.attachment && this.props.attachmentUrl) || '';

    return (
      <>
        <Modal
          title="Select pickup point"
          visible={this.state.isSelectPickupPointModalVisible}
          onOk={() => this.setModalVisibility(false)}
          onCancel={() => this.setModalVisibility(false)}
          width={720}
        >
          <CheckoutWidgetConfiguration doNotUseDraft={true} />
        </Modal>
        <form onSubmit={this.handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
          <ContainerFixedHeader
            title={LABELS.SHIPMENT_EDIT_TITLE}
            subtitle={`ID: ${itemId}`}
            IconComponent={InboxOutlined}
            onBack={this.handleBack}
            onBackLabel={LABELS.BACK}
            isLoading={isStatusLoading || this.state.isUpdatingShipment}
            mainActionLabel={this.getHeaderMainActionLabel()}
            onMainActionClick={this.getHeaderMainActionHandler()}
            secondaryButton={
              <>
                {shipmentStatus === 'CREATED' && (
                  <div style={{ display: 'flex', marginBottom: '17px', paddingRight: '16px' }}>
                    <Button
                      disabled={isStatusLoading || this.state.isUpdatingShipment}
                      onClick={this.getHeaderSecondaryActionHandler()}
                      type="primary"
                    >
                      Book & print
                    </Button>
                  </div>
                )}
              </>
            }
            status={shipmentStatus}
            lastUpdated={shipment && (shipment.updatedAt || shipment.createdAt)}
            statusComponent={
              isStatusLoading ? (
                <Spinner delay={0} active={isStatusLoading} style={{ paddingLeft: '24px' }} />
              ) : (
                <ShipmentStatusTag
                  status={shipmentStatus}
                  style={{ marginLeft: '24px', marginTop: '3px' }}
                />
              )
            }
            error={shipmentsError}
            additionalActionsConfig={additionalActionsConfig()}
          />
          <ContainerContent>
            <Card title="References" className={styles.card}>
              <div className={styles.referencesWrapper}>
                <div className={styles.sscc}>
                  <FormItemText
                    label={LABELS.SSCC_FULL}
                    className={styles.referencesFormItem}
                    labelCol={{ span: 24 }}
                  >
                    {trackingNumbers.length
                      ? trackingNumbers.map(trackingNumber => (
                          <li key={trackingNumber}>
                            <b>{trackingNumber}</b>
                          </li>
                        ))
                      : '-'}
                    <ExternalIdsContainer itemId={itemId} />
                  </FormItemText>
                </div>
                <div className={styles.directionType}>
                  <FormItemText
                    label={LABELS.DIRECTION}
                    className={styles.referencesFormItem}
                    labelCol={{ span: 24 }}
                  >
                    {getDirectionType(shipment?.directionType)}
                  </FormItemText>
                </div>
                <div className={styles.carrierProduct}>
                  <FormItemText
                    label={LABELS.CARRIER_SERVICE}
                    className={styles.referencesFormItem}
                    labelCol={{ span: 24 }}
                  >
                    <CarrierProductWithLogo shippingMethod={shippingMethodForShipment?.id ?? '-'} />
                  </FormItemText>
                </div>
                <div>
                  <TransportOrderIdForm
                    disabled={true}
                    formState={tosIdFormState}
                    transportOrders={transportOrderList}
                    isFetching={isFetchingTransportOrders}
                    showTosIdAnchor={!this.editMode}
                  />
                </div>
              </div>
            </Card>
            {this.editMode ? (
              <Card title="Addresses" className={styles.card}>
                <ShipmentAddressSection
                  isFetching={isFetchingContacts}
                  formState={shipmentAddressSelectorFormState}
                  onCreateContact={this.handleCreateContactFromModal}
                  onUpdateContact={this.handleUpdateContactFromModal}
                  handleSearchCustomerContact={this.handleSearchCustomerContact}
                  handleSearchSenderContact={this.handleSearchSenderContact}
                  handleSearchDeliveryAddress={this.handleSearchDeliveryContact}
                  customerContacts={unionWith(eqBy(prop('id')), allContacts, customerContacts)}
                  senderContacts={unionWith(eqBy(prop('id')), allContacts, senderContacts)}
                  deliveryContacts={unionWith(eqBy(prop('id')), allContacts, deliveryContacts)}
                />
              </Card>
            ) : (
              Addresses
            )}

            <TrackAndTraceContainer itemId={itemId} />

            <Card title="Order Content">
              {shipmentLineItems && shipmentLineItems.length > 0 && (
                <LineItemsTableForm
                  formState={lineItemsTableFormState}
                  onGoToParcel={this.handleGotoParcel}
                  disabled={true}
                />
              )}
              {transportOrders.length > 0 && (
                <>
                  <TransportOrdersTable
                    size={'middle'}
                    showEmptyMessage={false}
                    itemToParcelMap={transportOrderToParcelMap}
                    onGoToParcel={this.handleGotoParcel}
                    items={transportOrders}
                    isInShipmentEditView={true}
                  />
                </>
              )}
              {externalOrders.length > 0 && (
                <>
                  <ExternalOrdersTable
                    size={'middle'}
                    itemToParcelMap={externalOrderToParcelMap}
                    onGoToParcel={this.handleGotoParcel}
                    items={externalOrders}
                  />
                </>
              )}
            </Card>

            <br />

            {shipment && (
              <ShipmentOptionsForm
                disabled={!this.editMode}
                formState={shipmentOptionsFormState}
                carrierAddons={this.props.carrierAddons}
                showAddons={this.editMode}
                isPreorder={this.isPreorder.get()}
                changePreorder={this.handleChangePreorder}
                shipmentId={shipment.id}
                onOpenWidgetButtonClick={() => this.setModalVisibility(true)}
                showCustomBookingMethods={false}
              />
            )}

            <Card title="Delivery Time" className={styles.card}>
              <DeliveryTime formState={deliveryTimeFormState} disabled={!this.editMode} />
            </Card>

            {addons.length > 0 && !this.editMode && (
              <>
                <Card title={`Addons (${addons.length})`} className={styles.card}>
                  <AddonsTable size={'middle'} items={addons} />
                </Card>
              </>
            )}

            <ExtraInformation
              isNotInEditMode={!this.editMode}
              formState={extraInformationFormState}
              storeFile={this.handleStoreFile}
              attachmentField={attachmentField}
              attachmentUrl={attachmentUrl}
              shipmentId={itemId}
            >
              <InvoiceNumber
                disabled={!this.editMode}
                formState={invoiceNumberFormState}
                label="Invoice number"
              />
            </ExtraInformation>

            <CustomsDeclaration id={itemId} shipmentStatus={shipmentStatus} />
          </ContainerContent>
        </form>
      </>
    );
  }
}

const Connected = connect(mapStateToProps, dispatchProps)(Component);
export default Connected;
Connected.displayName = 'ShipmentEditContainer';

const getDirectionType = (directionType?: Direction) => {
  switch (directionType) {
    case 'OUTBOUND':
      return (
        <React.Fragment>
          <ArrowRightOutlined height={16} width={16} />{' '}
          {capitalize(directionType.toLocaleLowerCase())}
        </React.Fragment>
      );

    case 'RETURN':
      return (
        <React.Fragment>
          <RollbackOutlined height={16} width={16} />{' '}
          {capitalize(directionType.toLocaleLowerCase())}
        </React.Fragment>
      );

    default:
      return '-';
  }
};
