import { Card } from 'antd';
import React, { CSSProperties, FC } from 'react';

type Props = {
  isLoading: boolean;
};

export const WidgetCard: FC<Props> = ({ isLoading, children }) => (
  <Card bodyStyle={cardStyles} loading={isLoading}>
    {children}
  </Card>
);

const cardStyles: CSSProperties = {
  display: 'grid',
  gap: '8px',
  height: '100%',
};
