import { useField } from 'formik';
import * as React from 'react';

import { Input } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { widerLayout } from '@src/forms';
import { getFormikError } from '@src/utils/forms';

const InputField = withFormItem(Input, widerLayout);

export const FormikInputField: React.FunctionComponent<{
  name: string;
  label: React.ReactNode;
  placeholder?: string;
}> = ({ name, label, placeholder }) => {
  const [field, meta] = useField(name);
  return (
    <InputField
      {...field}
      label={label}
      error={getFormikError(meta.touched, meta.error)}
      placeholder={placeholder}
    />
  );
};
