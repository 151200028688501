import { Layout } from 'antd';
import * as React from 'react';
import { style as tss } from 'typestyle';
const { Content } = Layout;

import { HeaderLogo, UncaughtErrorModal } from '../components';
import { withErrorBoundary } from '../decorators';
import { ModulesErrorLayer } from '../layers';

import BaseLayout from './base-layout';

const UncaughtErrorModalWithErrorBoundary = withErrorBoundary(UncaughtErrorModal);

export interface PrimaryLayoutOptions {
  showNotifications: boolean;
}
interface Props {
  options?: PrimaryLayoutOptions;
}

const baseStyle = tss({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
});

const PrimaryLayout: React.FunctionComponent<Props> = ({
  options = { showNotifications: true },
  children,
}) => {
  return (
    <BaseLayout
      showNotifications={options.showNotifications}
      headerChild={<HeaderLogo />}
      contentChild={
        <Content className={baseStyle}>
          <UncaughtErrorModalWithErrorBoundary>
            <ModulesErrorLayer>{children}</ModulesErrorLayer>
          </UncaughtErrorModalWithErrorBoundary>
        </Content>
      }
    />
  );
};

export default PrimaryLayout;
