import { getAuthUser } from '@src/modules/auth/auth-selectors';
import { getSelectedMerchantName } from '@src/modules/merchants/merchants-selectors';
import { getMasterSiteNameOrEmpty } from '@src/modules/sites/sites-selectors';
import { initPosthog, registerProperties } from '@src/services/posthog-service';
import { useFlags } from '@src/utils/feature-flags';
import { useSelector } from '@src/utils/hooks';
import * as React from 'react';

export const Posthog: React.FC = () => {
  const siteName = useSelector(getMasterSiteNameOrEmpty);
  const merchantName = useSelector(getSelectedMerchantName);
  const posthogInitalised = React.useRef(false);
  const user = useSelector(getAuthUser);
  const flags = useFlags();

  const setPosthogInitialised = () => {
    posthogInitalised.current = true;
  };

  React.useEffect(() => {
    if (!flags.posthogEnabled) {
      return;
    }

    if (siteName && merchantName && user?.email) {
      if (posthogInitalised.current) {
        return registerProperties({ siteName, merchantName });
      } else {
        return initPosthog({ siteName, merchantName, email: user.email }, setPosthogInitialised);
      }
    }
  }, [siteName, merchantName, user?.email]);

  return null;
};
