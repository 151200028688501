import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { classes, stylesheet } from 'typestyle';
import { number, object, string } from 'yup';

import {
  parsePriceNumber,
  parsePriceString,
} from '@src/containers/regions/carrier-products/helpers';
import { Input, PriceInput, Select, Switch } from '@src/controls';
import { withFormItem } from '@src/decorators';
import { AutoSaveForm, inputStyle, MakeAutoSaveFormikEnhancer, widerLayout } from '@src/forms';
import { DeliveryAddonModel } from '@src/models';
import { configActions } from '@src/modules/config';
import { dispatchOnDraftSiteId } from '@src/utils/conditional-dispatchers';
import { getFormikError } from '@src/utils/forms';

import * as enUSMessages from '../../../../../../locales/en-US.json';

const InputField = withFormItem(Input, widerLayout);
const SelectField = withFormItem(Select, widerLayout);
const SwitchField = withFormItem(Switch, widerLayout);
const PriceField = withFormItem(PriceInput, widerLayout);

const TypedAutoSaveEnhancer = MakeAutoSaveFormikEnhancer<DeliveryAddonDetailsFormikValues>();

interface DeliveryAddonDetailsFormikValues {
  name: DeliveryAddonModel['name'];
  type: DeliveryAddonModel['type'];
  externalAddonId: DeliveryAddonModel['externalId'];
  price: DeliveryAddonModel['price']['value'] | undefined;
  vatRate: string | undefined;
  active: boolean;
}

interface Props {
  categoryId: string;
  addon: DeliveryAddonModel;
  allExternalAddonIds: string[];
}

const buildValidationSchema = (excludedExternalAddonIds: Props['allExternalAddonIds']) =>
  object({
    name: string().required(),
    externalAddonId: string()
      .required()
      .notOneOf(
        excludedExternalAddonIds,
        enUSMessages['CATEGORY_MODAL.DELIVERY_ADDONS.EXTERNAL_ADDON_ID_VALIDATION']
      ),
    vatRate: number().min(0).max(100),
  });

export const DeliveryAddonDetailsEdit: React.FC<Props> = ({
  addon,
  categoryId,
  allExternalAddonIds,
}) => {
  const handleUpdate = (values: DeliveryAddonDetailsFormikValues) => {
    dispatchOnDraftSiteId(configActions.updateDeliveryAddonRequest)({
      model: {
        categoryId,
        addonId: addon.id,
        name: values.name,
        type: values.type,
        externalId: values.externalAddonId,
        price: {
          value: values.price ? String(parsePriceString(values.price)) : '0',
          vatRate: values.vatRate ? Number(parseFloat(values.vatRate).toFixed(2)) : 0,
        },
        defaults: {
          selected: addon.defaults.selected,
        },
        state: values.active ? 'ACTIVE' : 'INACTIVE',
      },
    });
  };
  return (
    <Formik
      initialValues={{
        name: addon.name,
        type: addon.type,
        externalAddonId: addon.externalId,
        price: parsePriceNumber(parseInt(addon.price.value, 10)),
        vatRate: String(addon.price.vatRate),
        active: addon.state === 'ACTIVE',
      }}
      // tslint:disable-next-line: no-empty
      onSubmit={() => {}}
      validationSchema={buildValidationSchema(
        allExternalAddonIds.filter(externalId => externalId !== addon.externalId)
      )}
    >
      {formikProps => (
        <AutoSaveForm>
          <InputField
            label={<FormattedMessage id="ID" />}
            className={inputStyle}
            value={addon.id}
            disabled={true}
          />
          <TypedAutoSaveEnhancer
            onSave={handleUpdate}
            name="name"
            render={({ name, onBlur, onKeyDown }) => (
              <Field name={name}>
                {({ field }: FieldProps<DeliveryAddonDetailsFormikValues['name']>) => (
                  <InputField
                    label={<FormattedMessage id="NAME" />}
                    className={inputStyle}
                    {...field}
                    error={getFormikError(formikProps.touched.name, formikProps.errors.name)}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                  />
                )}
              </Field>
            )}
          />

          <TypedAutoSaveEnhancer
            name="type"
            onSave={handleUpdate}
            render={({ name, onInstantChange }) => (
              <Field name={name}>
                {({ field }: FieldProps<DeliveryAddonDetailsFormikValues['type']>) => (
                  <SelectField
                    {...field}
                    options={[
                      {
                        value: 'TYPE_CUSTOM',
                        label: (
                          <div className={styles.typeLabelWrapper}>
                            <span className={styles.type}>{'Custom'}</span>
                          </div>
                        ),
                      },
                      {
                        value: 'TYPE_CARRY_IN',
                        label: (
                          <div className={styles.typeLabelWrapper}>
                            <span className={styles.type}>{'Carry in'}</span>
                          </div>
                        ),
                      },
                      {
                        value: 'TYPE_LEAVE_AT_DOOR',
                        label: (
                          <div className={styles.typeLabelWrapper}>
                            <span className={styles.type}>{'Leave at door'}</span>
                          </div>
                        ),
                      },
                      {
                        value: 'TYPE_UNWRAPPING',
                        label: (
                          <div className={styles.typeLabelWrapper}>
                            <span className={styles.type}>{'Unwrapping'}</span>
                          </div>
                        ),
                      },
                    ]}
                    onChange={(value: string) => onInstantChange(value)}
                    label={<FormattedMessage id="TYPE" />}
                    className={inputStyle}
                  />
                )}
              </Field>
            )}
          />

          <TypedAutoSaveEnhancer
            onSave={handleUpdate}
            name="externalAddonId"
            render={({ name, onBlur, onKeyDown }) => (
              <Field name={name}>
                {({ field }: FieldProps<DeliveryAddonDetailsFormikValues['externalAddonId']>) => (
                  <InputField
                    label={
                      <FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.EXTERNAL_ADDON_ID" />
                    }
                    className={inputStyle}
                    {...field}
                    error={getFormikError(
                      formikProps.touched.externalAddonId,
                      formikProps.errors.externalAddonId
                    )}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                  />
                )}
              </Field>
            )}
          />

          <TypedAutoSaveEnhancer
            onSave={handleUpdate}
            name="price"
            render={({ name, onBlur, onKeyDown }) => (
              <Field name={name}>
                {({ field }: FieldProps<DeliveryAddonDetailsFormikValues['price']>) => (
                  <PriceField
                    {...field}
                    value={field.value || ''}
                    label={<FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.PRICE" />}
                    className={inputStyle}
                    error={getFormikError(formikProps.touched.price, formikProps.errors.price)}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    onChange={value => formikProps.setFieldValue('price', value)}
                  />
                )}
              </Field>
            )}
          />

          <TypedAutoSaveEnhancer
            onSave={values => {
              if (values.vatRate === '' || values.vatRate === undefined) {
                formikProps.setFieldValue('vatRate', '0');
              } else {
                formikProps.setFieldValue('vatRate', parseFloat(values.vatRate!).toFixed(2));
              }
              handleUpdate(values);
            }}
            name="vatRate"
            render={({ name, onBlur, onKeyDown }) => (
              <Field name={name}>
                {({ field }: FieldProps<DeliveryAddonDetailsFormikValues['vatRate']>) => (
                  <InputField
                    label={<FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.VAT_RATE" />}
                    labelTooltip={
                      <FormattedMessage id="CATEGORY_MODAL.DELIVERY_ADDONS.VAT_RATE_TOOLTIP" />
                    }
                    className={classes(inputStyle, styles.vatRateInput)}
                    {...field}
                    error={getFormikError(formikProps.touched.vatRate, formikProps.errors.vatRate)}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                  />
                )}
              </Field>
            )}
          />

          <SwitchField
            label={<FormattedMessage id="ACTIVE" />}
            checked={addon.state === 'ACTIVE'}
            onChange={value => {
              formikProps.setFieldValue('active', value);
              handleUpdate({ ...formikProps.values, active: value });
            }}
          />
        </AutoSaveForm>
      )}
    </Formik>
  );
};

const styles = stylesheet({
  typeIcon: {
    height: '14px',
    width: '14px',
  },
  type: {
    marginLeft: '4px',
  },
  typeLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  vatRateInput: {
    width: '96px',
  },
});
