import { Modal } from 'antd';
import * as React from 'react';

import { FormattedMessage } from '@src/i18n';

interface Props {
  isVisible: boolean;
  toggleVisibility: (to: boolean) => void;
  handleOk: () => void;
}

export const RemoveDraftModal: React.FunctionComponent<Props> = ({
  toggleVisibility,
  isVisible,
  handleOk,
}) => (
  <Modal
    title={<FormattedMessage id="REMOVE_DRAFT" />}
    okText={<FormattedMessage id="REMOVE" />}
    okType={'primary'}
    okButtonProps={{
      danger: true,
    }}
    cancelText={<FormattedMessage id="CANCEL" />}
    onOk={() => {
      handleOk();
      toggleVisibility(false);
    }}
    onCancel={() => toggleVisibility(false)}
    width={480}
    visible={isVisible}
  >
    <FormattedMessage id="REMOVE_DRAFT_MESSAGE" />
  </Modal>
);
