import { InboxOutlined } from '@ant-design/icons';
import { configSelectors } from '@src/modules/config';
import { RequestState } from '@src/modules/reports/reducers';
import { tagsActions, tagsSelectors } from '@src/modules/tags';
import moment from 'moment';
import { equals, isEmpty } from 'ramda';
import * as React from 'react';
import { connect } from 'react-redux';
import { style as tss } from 'typestyle';

import { Link } from '@src/components/link';

import { routerActions } from '@src/modules/router';
import { ListFixedHeader } from '../../components';
import { DOMAIN, LABELS } from '../../dictionaries';
import { CreateShipmentReportModel, CreateSpecialReportModel, ShipmentListFiltersModel, SpecialReportType } from '../../models';
import { RootState } from '../../modules/';
import { eventTrackingActions } from '../../modules/event-tracking';
import { reportsActions, reportsSelectors } from '../../modules/reports';
import { shipmentsActions } from '../../modules/shipments';
import { somSearchActions } from '../../modules/som-search';
import { userprofileActions, userprofileSelectors } from '../../modules/userprofile';
import { isWithinShipmentsRoute } from '../../utils/url';
import { Button, Card, modalConfirm } from './../../controls';
import { ExportAllShipmentsModal, ShipmentList } from './components';
import { additionalActionsContext } from './components/additional-actions';

// Component API
const mapStateToProps = (state: RootState) => ({
  selectedSiteId: state.sites.selectedSiteId,
  isFetching: state.somSearch.isFetching || state.transportOrders.isFetching,
  shipmentSummaryList: state.somSearch.shipmentSummaries,
  shippingMethodsDict: configSelectors.getShippingMethodsDict(state),
  filters: state.somSearch.filters,
  isFetchingStatuses: state.deliveries.isFetching,
  userShipmentIds: userprofileSelectors.getShipmentIds(state),
  reportsRequestStatus: reportsSelectors.getReportRequestStatus(state),
  reportsUri: reportsSelectors.getUriForCurrentUser(state),
  userPersonalTag: tagsSelectors.getUserPersonalTag(state),
});

const dispatchProps = {
  getShipmentSummariesList: somSearchActions.getShipmentSummariesRequest,
  clearActiveFilters: somSearchActions.clearActiveFilters,
  updateShipmentListPagination: shipmentsActions.updateShipmentListPagination,
  getShipmentIdsForUser: userprofileActions.getShipmentIdsRequest,
  exportShipments: reportsActions.createShipmentsReportRequest,
  exportSpecial: reportsActions.createSpecialReportRequest,
  applyShipmentsFiltersClick: eventTrackingActions.applyShipmentsFiltersClick,
  searchShipmentButtonClick: eventTrackingActions.searchShipmentButtonClick,
  addShipmentsToWatchlist: tagsActions.addTagsToShipmentsRequest,
  getUserTags: tagsActions.listUserTagsRequest,
  resetTaggedShipments: tagsActions.resetTaggedShipments,
  routerPush: routerActions.push,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const { Provider } = additionalActionsContext;

enum SelectModeEnum {
  all = 'all',
  current = 'current',
  filter = 'filter',
  initial = 'initial',
}

interface State {
  selectedFilters: ShipmentListFiltersModel | undefined;
  isModalVisible: boolean;
  reportType: SpecialReportType;
  selectedRowKeys: string[];
  exportMode: SelectModeEnum;
  paginationNumber: number;
}

class ShipmentListContainer extends React.Component<Props, State> {
  pageSize = 51;

  state = {
    selectedFilters: undefined,
    isModalVisible: false,
    reportType: SpecialReportType.REPORT_TYPE_UNKNOWN,
    selectedRowKeys: [],
    exportMode: SelectModeEnum.initial,
    paginationNumber: 1,
  };

  initialFetch = () => {
    const {
      filters,
      getUserTags,
      getShipmentSummariesList,
      getShipmentIdsForUser,
      selectedSiteId,
    } = this.props;
    const filtersToPass = filters ? filters : undefined;
    getShipmentSummariesList({
      siteId: selectedSiteId || '',
      tosId: '',
      pageNumber: this.state.paginationNumber,
      pageSize: 51,
      filters: filtersToPass,
    });
    getUserTags({ siteId: selectedSiteId! });
    getShipmentIdsForUser({ siteId: selectedSiteId || '' });
  };

  componentDidMount() {
    this.initialFetch();
  }

  componentWillUnmount() {
    if (!isWithinShipmentsRoute(window.location.pathname)) {
      this.setState({ paginationNumber: 1 });
      this.props.clearActiveFilters();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      !equals(nextProps.shipmentSummaryList, this.props.shipmentSummaryList) &&
      this.state.exportMode === SelectModeEnum.all
    ) {
      this.setState({ selectedRowKeys: nextProps.shipmentSummaryList.map(i => i.id) });
    }
  }

  handleListItemClick = (itemId: string) => {
    this.props.routerPush({ name: 'SHIPMENT_EDIT', shipmentId: itemId });
  };

  onPaginationButtonClick = (
    sortDirection: 'ASCENDING' | 'DESCENDING',
    mode: 'forward' | 'backward'
  ) => {
    const { filters, getShipmentSummariesList, selectedSiteId } = this.props;
    const filtersToPass = filters ? filters : undefined;
    const pageNumber = this.calculatePageNumber(mode);

    this.setState({ paginationNumber: pageNumber });

    getShipmentSummariesList({
      siteId: selectedSiteId || '',
      tosId: '',
      direction: sortDirection,
      pageNumber,
      pageSize: 50,
      filters: filtersToPass,
    });

    this.setState(prevState => ({
      selectedFilters: filtersToPass,
      exportMode: filtersToPass ? SelectModeEnum.filter : prevState.exportMode,
    }));
  };

  calculatePageNumber = (mode: 'forward' | 'backward') => {
    switch (mode) {
      case 'backward':
        return this.state.paginationNumber - 1;
      case 'forward':
        return this.state.paginationNumber + 1;
    }
  };

  handleFiltersApply = (selectedFilters: ShipmentListFiltersModel, showUserShipments: boolean) => {
    const {
      applyShipmentsFiltersClick,
      userShipmentIds,
      getShipmentSummariesList,
      selectedSiteId,
      filters,
    } = this.props;

    const filtersToPass = { ...filters, ...selectedFilters };
    if (showUserShipments) {
      filtersToPass.shipmentIds = userShipmentIds;
    }
    this.setState({ paginationNumber: 1 });
    getShipmentSummariesList({
      siteId: selectedSiteId || '',
      tosId: '',
      pageNumber: 1,
      pageSize: this.pageSize,
      filters: filtersToPass,
    });
    this.setState({
      selectedFilters: filtersToPass,
      exportMode: SelectModeEnum.initial,
      selectedRowKeys: [],
    });
    applyShipmentsFiltersClick(filtersToPass);
  };

  handleFiltersClear = () => {
    const { getShipmentSummariesList, clearActiveFilters, selectedSiteId } = this.props;
    this.setState({ paginationNumber: 1 });
    getShipmentSummariesList({
      siteId: selectedSiteId || '',
      tosId: '',
      pageNumber: 1,
      pageSize: this.pageSize,
    });
    clearActiveFilters();
    this.setState({ selectedFilters: undefined, exportMode: SelectModeEnum.initial });
  };

  handleSearchItems = (value: string) => {
    const { filters, getShipmentSummariesList, searchShipmentButtonClick, selectedSiteId } =
      this.props;
    this.setState({ paginationNumber: 1 });

    const filtersToPass = { ...filters, id: value };
    getShipmentSummariesList({
      siteId: selectedSiteId || '',
      tosId: '',
      pageSize: this.pageSize,
      filters: filtersToPass,
      pageNumber: 1,
    });
    searchShipmentButtonClick();
  };

  onSelectChange = (selectedRowKeys: string[]) =>
    this.setState({
      selectedRowKeys,
      exportMode: isEmpty(selectedRowKeys) ? SelectModeEnum.initial : SelectModeEnum.current,
    });

  getRowSelection = () => ({
    selectedRowKeys: this.state.selectedRowKeys,
    onChange: this.onSelectChange,
    hideDefaultSelections: true,
    selections: [
      {
        key: 'all-data',
        text: 'Select All Data For Export',
        onSelect: (selected: string[]) =>
          this.setState({
            selectedRowKeys: selected,
            exportMode: this.props.filters ? SelectModeEnum.filter : SelectModeEnum.all,
          }),
      },
      {
        key: 'Deselect-all',
        text: 'Deselect All Data',
        onSelect: () => this.setState({ selectedRowKeys: [], exportMode: SelectModeEnum.initial }),
      },
      {
        key: 'current',
        text: 'Select Current Page',
        onSelect: (selected: string[]) =>
          this.setState({ selectedRowKeys: selected, exportMode: SelectModeEnum.current }),
      },
    ],
  });

  handleExportItems = (reportType: SpecialReportType) => {
    const { reportsRequestStatus } = this.props;
    if (reportsRequestStatus === RequestState.active) {
      modalConfirm({
        title: 'Are you sure that you want to create report?',
        content:
          'You already have peding report request. Creating new one will remove existing one.',
        onOk: () => this.toggleShipmentExport(reportType),
      });
    } else {
      this.toggleShipmentExport(reportType);
    }
  };

  toggleShipmentExport = (reportType: SpecialReportType) => {
    const { exportShipments, exportSpecial, selectedSiteId: siteId } = this.props;
    const { exportMode, selectedFilters, selectedRowKeys } = this.state;

    let model = new CreateShipmentReportModel();
    switch (exportMode) {
      case SelectModeEnum.filter: {
        model.filtering = selectedFilters;
        break;
      }
      case SelectModeEnum.current: {
        model.ids = selectedRowKeys;
        break;
      }

      case SelectModeEnum.all: {
        this.showExportAllModal(reportType, true);
        return;
      }

      default:
        break;
    }

    if (!siteId) {
      return
    }

    if (reportType != SpecialReportType.REPORT_TYPE_UNKNOWN) {
      model = CreateSpecialReportModel.createFromShipmentModel(reportType, model)
      exportSpecial({siteId, model})
      return
    }

    exportShipments({ siteId, model });
  };

  handleExportAllShipments = (reportType: SpecialReportType, dateRange: moment.Moment[]) => {
    const { exportShipments, exportSpecial, selectedSiteId: siteId } = this.props;
    let model = new CreateShipmentReportModel();
    model.filtering = {
      createdAtRange: {
        start: dateRange[0].startOf('day').toISOString(),
        end: dateRange[1].endOf('day').toISOString(),
      },
    };

    this.hideExportAllModal()

    if (!siteId) {
      return
    }

    if (reportType != SpecialReportType.REPORT_TYPE_UNKNOWN) {
      model = CreateSpecialReportModel.createFromShipmentModel(reportType, model)
      exportSpecial({siteId, model})
      return
    }

    exportShipments({ siteId, model });
  };

  hideExportAllModal = () => this.showExportAllModal(SpecialReportType.REPORT_TYPE_UNKNOWN, false);

  showExportAllModal = (reportType: SpecialReportType, visible: boolean) =>
    this.setState(() => ({
      isModalVisible: visible,
      reportType: reportType,
    }));

  handleAddToWatchList = () => {
    const { selectedSiteId, addShipmentsToWatchlist, userPersonalTag, resetTaggedShipments } =
      this.props;
    addShipmentsToWatchlist({
      siteId: selectedSiteId || '',
      shipmentIds: this.state.selectedRowKeys,
      tagIds: [userPersonalTag!.id],
      onComplete: () => resetTaggedShipments(),
    });
    this.setState({ selectedRowKeys: [], exportMode: SelectModeEnum.initial });
  };

  getTableSelectedInfo = () => {
    const { selectedRowKeys, exportMode } = this.state;
    const { shipmentSummaryList } = this.props;
    if (!isEmpty(selectedRowKeys)) {
      if (exportMode === SelectModeEnum.current) {
        return `Selected ${selectedRowKeys.length} ${
          shipmentSummaryList.length === 1 ? ` ${LABELS.ITEM}` : ` ${LABELS.ITEMS}`
        }`;
      }
      if (exportMode === SelectModeEnum.all) {
        return `Selected all items`;
      }
      if (exportMode === SelectModeEnum.filter) {
        return 'Selected all filtered items';
      }
      return `Selected ${selectedRowKeys.length} ${
        shipmentSummaryList.length === 1 ? ` ${LABELS.ITEM}` : ` ${LABELS.ITEMS}`
      }`;
    }
    return undefined;
  };

  render() {
    const {
      isFetching,
      filters,
      shippingMethodsDict,
      userShipmentIds,
      isFetchingStatuses,
      shipmentSummaryList,
      userPersonalTag,
    } = this.props;

    const { selectedRowKeys, reportType } = this.state;

    const contextValue = {
      handleFiltersApply: this.handleFiltersApply,
      clearFilters: this.handleFiltersClear,
    };

    return (
      <div>
        <ListFixedHeader
          title={DOMAIN.SHIPMENTS}
          IconComponent={InboxOutlined}
          onSearchItems={this.handleSearchItems}
          isLoading={isFetching}
          searchPlaceholder={LABELS.SHIPMENTS_SEARCH_PLACEHOLDER}
          filtersSearchValue={filters ? filters.id : ''}
          actions={
            <div style={{ position: 'relative' }}>
              <Link route={{ name: 'SHIPMENT_CREATE' }}>
                <Button type="primary"> {LABELS.CREATE}</Button>
              </Link>
            </div>
          }
        />
        <ExportAllShipmentsModal
          visible={this.state.isModalVisible}
          onCancel={this.hideExportAllModal}
          onOk={(dateRange) => this.handleExportAllShipments(reportType, dateRange)}
        />
        <Card bordered={false} className={styles.card}>
          <Provider value={contextValue}>
            <ShipmentList
              isLoading={isFetching}
              isFetchingStatuses={isFetchingStatuses}
              currentPage={this.state.paginationNumber}
              onItemClick={this.handleListItemClick}
              shippingMethodsDict={shippingMethodsDict}
              onFiltersApply={this.handleFiltersApply}
              onFiltersClear={this.handleFiltersClear}
              myShipmentsFilterAllowed={userShipmentIds ? userShipmentIds.length > 0 : false}
              onExportItems={this.handleExportItems}
              isAnyItemSelected={
                this.state.exportMode !== SelectModeEnum.initial &&
                !isEmpty(this.state.selectedRowKeys)
              }
              rowSelection={this.getRowSelection()}
              tableSelectedInfo={this.getTableSelectedInfo()}
              shipmentSummaries={shipmentSummaryList}
              onAddToWatchlist={this.handleAddToWatchList}
              addToWatchlistDisabled={
                this.state.exportMode === 'all' && userPersonalTag !== undefined
              }
              onPaginationButtonClick={this.onPaginationButtonClick}
              selectedRowKeys={selectedRowKeys}
            />
          </Provider>
        </Card>
      </div>
    );
  }
}

const styles = {
  card: tss({
    minHeight: '650px',
  }),
};
const Connected = connect(mapStateToProps, dispatchProps)(ShipmentListContainer);
Connected.displayName = 'ShipmentListContainer';
export default Connected;
