import { HomeContainer } from '@src/containers';
import { ExperimentDetailsContainer, ExperimentListContainer } from '@src/containers/experiment';
import { CountryListPage, CountryPage, DefaultSettingsPage } from '@src/containers/features';
import { GroupsListContainer } from '@src/containers/groups';
import { MerchantsDetailsContainer, MerchantsListContainer } from '@src/containers/merchants';
import { ProductMappingsContainer } from '@src/containers/product-mappings';
import {
  ReceiptCountrySettings,
  ReceiptDefaultSettings,
  ReceiptFeatures,
  ReceiptWidgetTestClient,
} from '@src/containers/receipt-widget';
import { RegionDetailsPage, RegionsListPage } from '@src/containers/regions';
import { TrackingNumberDetails, TrackingNumbers } from '@src/containers/tracking-numbers';
import { TrackingPlayground } from '@src/containers/tracking-playground';
import { WarehouseDetailsContainer, WarehouseListPage } from '@src/containers/warehouse';
import {
  ContextLayout,
  ContextLayoutOptions,
  PrimaryLayout,
  PrimaryLayoutOptions,
} from '@src/layouts';
import { RouterPaths } from '@src/modules/router/paths';
import * as React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import AddressBookContactCreateContainer from '../containers/address-book/address-book-contact-create-container';
import AddressBookContactEditContainer from '../containers/address-book/address-book-contact-edit-container';
import { AddressBookContainer } from '../containers/address-book/address-book-container';
import { BookingRules } from '../containers/booking-rules';
import { CheckoutWidgetConfigurationPage } from '../containers/checkout-widget';
import { SessionEventsPage } from '../containers/session-events';
import ShipmentCreateContainer from '../containers/shipments/shipment-create-container';
import { ShipmentBookAndPrint } from '@src/containers/shipments/shipment-book-and-print';
import { ShipmentEditContainer } from '@src/containers/shipments';
import ShipmentListContainer from '../containers/shipments/shipment-list-container';
import { TagsContainer } from '../containers/tags';
import {
  TemplateCreateContainer,
  TemplateEditContainer,
  TemplateListContainer,
} from '../containers/templates';
import {
  TransportOrderDetailsContainer,
  TransportOrderListContainer,
} from '../containers/transport-orders';
import {
  UpsellCountryPage,
  UpsellDefaultSettings,
  UpsellFeatures,
  UpsellWidgetConfiguration,
} from '../containers/upsell-widget';
import { FaqWidgetConfiguration } from '../containers/faq-widget';
import { ProductPageWidgetConfiguration } from '@src/containers/product-page-widget/widget-configuration';
import { UsersCreateContainer, UsersEditContainer, UsersListContainer } from '../containers/users';
import { Dashboard, useEnabledDashboards } from '../containers/dashboard';
import { WatchlistListContainer } from '../containers/watchlists';
import { DeliveryTrackingNotifications } from '@src/modules/delivery-tracking-notifications';
import { ProductPageFeatures } from '@src/containers/product-page-widget/product-page-features';
import { ProductPageDefaultSettings } from '@src/containers/product-page-widget/product-page-default-settings';
import { ProductPageCountrySettings } from '@src/containers/product-page-widget/product-page-country-settings';
import { FaqWidgetFeatures } from '@src/containers/faq-widget/faq-wdget-features';
import { FaqWidgetCountrySettings } from '@src/containers/faq-widget/faq-widget-country-settings';
import { FaqWidgetDefaultSettings } from '@src/containers/faq-widget/faq-widget-default-settings';

const withPrimaryLayout = (Component: JSX.Element, layoutOptions?: PrimaryLayoutOptions) => (
  <PrimaryLayout options={layoutOptions}>{Component}</PrimaryLayout>
);
const withContextLayout = (Component: JSX.Element, layoutOptions?: ContextLayoutOptions) => (
  <ContextLayout options={layoutOptions}>{Component}</ContextLayout>
);

const RouterLayer = () => {
  const dashboards = useEnabledDashboards();
  return (
    <Switch>
      <Route
        exact={true}
        path={RouterPaths.TRANSPORT_ORDER_LIST}
        component={() => withContextLayout(<TransportOrderListContainer />)}
      />
      <Route
        exact={true}
        path={RouterPaths.EXPERIMENT_LIST}
        component={() => withContextLayout(<ExperimentListContainer />)}
      />
      <Route
        exact={true}
        path={RouterPaths.EXPERIMENT_DETAILS}
        component={(props: RouteComponentProps<{ experimentId: string }>) =>
          withContextLayout(
            <ExperimentDetailsContainer experimentId={props.match.params.experimentId} />,
            {
              showNotifications: false,
              noPadding: true,
            }
          )
        }
      />
      <Route
        exact={true}
        path={RouterPaths.TRANSPORT_ORDER_DETAILS}
        render={({ match }) =>
          withPrimaryLayout(
            <TransportOrderDetailsContainer itemId={match.params.transportOrderId ?? ''} />
          )
        }
      />
      <Route
        exact={true}
        path={RouterPaths.SHIPMENT_LIST}
        render={({ match }) =>
          withContextLayout(<ShipmentListContainer key={match.params.siteId} />)
        }
      />
      <Route
        exact={true}
        path={RouterPaths.SHIPMENT_BOOK_AND_PRINT}
        render={() => <ShipmentBookAndPrint />}
      />
      <Route
        exact={true}
        path={RouterPaths.SHIPMENT_CREATE}
        render={({ match }) =>
          withPrimaryLayout(<ShipmentCreateContainer tosId={match.params.tosId} />)
        }
      />
      <Route
        exact={true}
        path={RouterPaths.SHIPMENT_EDIT}
        render={({ match }) =>
          withPrimaryLayout(
            <ShipmentEditContainer
              key={match.params.shipmentId}
              itemId={match.params.shipmentId ?? ''}
              tosId={match.params.tosId}
            />
          )
        }
      />
      <Route
        exact={true}
        path={RouterPaths.USERS}
        render={() => withContextLayout(<UsersListContainer />)}
      />
      <Route
        exact={true}
        path={RouterPaths.USERS_CREATE}
        render={({ match }) =>
          withPrimaryLayout(
            <UsersCreateContainer
              siteId={match.params.siteId ?? ''}
              merchantId={match.params.merchantId ?? ''}
            />
          )
        }
      />
      <Route
        exact={true}
        path={RouterPaths.USERS_EDIT}
        render={({ match }) =>
          withPrimaryLayout(
            <UsersEditContainer
              userId={match.params.userId ?? ''}
              siteId={match.params.siteId ?? ''}
              merchantId={match.params.merchantId ?? ''}
            />
          )
        }
      />
      <Route
        exact={true}
        path={RouterPaths.ADDRESS_BOOK}
        component={() => withContextLayout(<AddressBookContainer />)}
      />
      <Route
        exact={true}
        path={RouterPaths.ADDRESS_BOOK_CREATE_CONTACT}
        component={() => withPrimaryLayout(<AddressBookContactCreateContainer />)}
      />
      <Route
        exact={true}
        path={RouterPaths.ADDRESS_BOOK_EDIT_CONTACT}
        component={({ match }: any) =>
          withPrimaryLayout(<AddressBookContactEditContainer contactId={match.params.contactId} />)
        }
      />
      <Route
        exact={true}
        path={RouterPaths.TEMPLATE_LIST}
        component={({ match }: any) =>
          withContextLayout(<TemplateListContainer siteId={match.params.siteId} />)
        }
      />
      <Route
        exact={true}
        path={RouterPaths.TEMPLATE_CREATE}
        component={({ match }: any) => withPrimaryLayout(<TemplateCreateContainer />)}
      />
      <Route
        exact={true}
        path={RouterPaths.TEMPLATE_EDIT}
        component={({ match }: any) =>
          withPrimaryLayout(<TemplateEditContainer templateId={match.params.templateId} />)
        }
      />
      <Route
        exact={true}
        path={RouterPaths.CHECKOUT_WIDGET_TEST_CLIENT}
        component={({ match }: any) =>
          withContextLayout(<CheckoutWidgetConfigurationPage />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.UPSELL_FEATURES}
        component={({ match }: any) =>
          withContextLayout(<UpsellFeatures />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.UPSELL_DEFAULT_SETTINGS}
        component={({ match }: any) =>
          withContextLayout(<UpsellDefaultSettings />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.UPSELL_COUNTRY_DETAILS}
        component={({ match }: any) =>
          withContextLayout(<UpsellCountryPage country={match.params.country} />, {
            showNotifications: false,
            noPadding: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.UPSELL_WIDGET_TEST_CLIENT}
        component={({ match }: any) =>
          withContextLayout(<UpsellWidgetConfiguration />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.FAQ_WIDGET_FEATURES}
        component={() =>
          withContextLayout(<FaqWidgetFeatures />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.FAQ_WIDGET_TEST_CLIENT}
        component={({ match }: any) =>
          withContextLayout(<FaqWidgetConfiguration />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.FAQ_WIDGET_DEFAULT_SETTINGS}
        component={() =>
          withContextLayout(<FaqWidgetDefaultSettings />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.FAQ_WIDGET_COUNTRY_DETAILS}
        component={({ match }: any) =>
          withContextLayout(<FaqWidgetCountrySettings country={match.params.country} />, {
            showNotifications: false,
            noPadding: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.PRODUCT_PAGE_FEATURES}
        component={() =>
          withContextLayout(<ProductPageFeatures />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.PRODUCT_PAGE_WIDGET_TEST_CLIENT}
        component={() =>
          withContextLayout(<ProductPageWidgetConfiguration />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.PRODUCT_PAGE_DEFAULT_SETTINGS}
        component={() =>
          withContextLayout(<ProductPageDefaultSettings />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.PRODUCT_PAGE_COUNTRY_DETAILS}
        component={({ match }: any) =>
          withContextLayout(<ProductPageCountrySettings country={match.params.country} />, {
            showNotifications: false,
            noPadding: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.RECEIPT_FEATURES}
        component={() =>
          withContextLayout(<ReceiptFeatures />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.RECEIPT_WIDGET_TEST_CLIENT}
        component={() =>
          withContextLayout(<ReceiptWidgetTestClient />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.RECEIPT_DEFAULT_SETTINGS}
        component={() =>
          withContextLayout(<ReceiptDefaultSettings />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.RECEIPT_COUNTRY_SETTINGS}
        component={({ match }: any) =>
          withContextLayout(<ReceiptCountrySettings country={match.params.country} />, {
            noPadding: false,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.TRACKING_PLAYGROUND}
        component={() =>
          withContextLayout(<TrackingPlayground />, {
            showNotifications: false,
          })
        }
      />
      {dashboards.tracking && (
        <Route
          exact={true}
          path={RouterPaths.TRACKING_DASHBOARD}
          component={() =>
            withContextLayout(
              <Dashboard type="tracking" fallback="Can not render tracking dashboard" />,
              {
                showNotifications: false,
              }
            )
          }
        />
      )}
      {dashboards.checkout && (
        <Route
          exact={true}
          path={RouterPaths.CHECKOUT_DASHBOARD}
          component={() =>
            withContextLayout(
              <Dashboard type="checkout" fallback="Can not render checkout dashboard" />,
              {
                showNotifications: false,
              }
            )
          }
        />
      )}
      {dashboards.transport_administration && (
        <Route
          exact={true}
          path={RouterPaths.TRANSPORT_ADMINISTRATION_DASHBOARD}
          component={() =>
            withContextLayout(
              <Dashboard type="transport_administration" fallback="Can not render TA dashboard" />,
              {
                showNotifications: false,
              }
            )
          }
        />
      )}

      <Route
        exact={true}
        path={RouterPaths.PRODUCT_MAPPINGS}
        component={() =>
          withContextLayout(<ProductMappingsContainer />, {
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.TRACKING_NUMBERS}
        component={() =>
          withContextLayout(<TrackingNumbers />, {
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.TRACKING_NUMBER_DETAILS}
        component={({ match, location }: RouteComponentProps<{ trackingNumber: string }>) => {
          // workaroud for `history` package issue: https://github.com/remix-run/history/issues/505
          // use the raw trackingNumber from the state if possible; if not, use the decoded value from the URL param
          const derivedTrackingNumber =
            location?.state?.trackingNumber || match.params.trackingNumber;
          return withContextLayout(
            <TrackingNumberDetails trackingNumber={derivedTrackingNumber} />
          );
        }}
      />
      <Route
        exact={true}
        path={RouterPaths.NOTIFICATIONS}
        component={() => withContextLayout(<DeliveryTrackingNotifications />)}
      />
      <Route
        exact={true}
        path={RouterPaths.WATCHLIST_LIST}
        component={({ match }: any) =>
          withContextLayout(<WatchlistListContainer siteId={match.params.siteId} />)
        }
      />
      <Route
        exact={true}
        path={RouterPaths.TAGS_LIST}
        component={() => withContextLayout(<TagsContainer />)}
      />
      <Route
        exact={true}
        path={RouterPaths.COUNTRY_LIST}
        component={() =>
          withContextLayout(<CountryListPage />, {
            noPadding: true,
            showNotifications: false,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.DEFAULT_SETTINGS}
        component={() => withContextLayout(<DefaultSettingsPage />)}
      />
      <Route
        exact={true}
        path={RouterPaths.COUNTRY_DETAILS}
        component={(props: RouteComponentProps<{ country: string }>) =>
          withContextLayout(<CountryPage country={props.match.params.country} />, {
            showNotifications: false,
            noPadding: true,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.REGIONS_LIST}
        component={(props: RouteComponentProps<{ regionId: string }>) =>
          withContextLayout(<RegionsListPage />, { noPadding: true, showNotifications: false })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.REGION_DETAILS}
        component={(props: RouteComponentProps<{ regionId: string }>) =>
          withContextLayout(<RegionDetailsPage regionId={props.match.params.regionId} />, {
            showNotifications: false,
            noPadding: true,
          })
        }
      />
      <Route
        exact={true}
        path={RouterPaths.WAREHOUSES_LIST}
        component={() =>
          withContextLayout(<WarehouseListPage />, { noPadding: true, showNotifications: false })
        }
      />
      <Route
        exact
        path={RouterPaths.WAREHOUSE_DETAILS}
        component={(props: RouteComponentProps<{ warehouseId: string }>) =>
          withContextLayout(
            <WarehouseDetailsContainer warehouseId={props.match.params.warehouseId} />,
            {
              noPadding: true,
              showNotifications: false,
            }
          )
        }
      />
      <Route
        exact
        path={RouterPaths.GROUPS_LIST}
        component={(props: RouteComponentProps<{ merchantId: string; siteId: string }>) =>
          withContextLayout(
            <GroupsListContainer
              merchantId={props.match.params.merchantId}
              siteId={props.match.params.siteId}
            />
          )
        }
      />
      <Route
        exact={true}
        path={RouterPaths.SESSION_EVENTS}
        component={() =>
          withContextLayout(<SessionEventsPage />, {
            noPadding: true,
            showNotifications: false,
          })
        }
      />
      <Route
        exact
        path={RouterPaths.MERCHANTS_LIST}
        component={() => withContextLayout(<MerchantsListContainer />)}
      />
      <Route
        exact
        path={RouterPaths.MERCHANT_DETAILS}
        component={(props: RouteComponentProps<{ detailsMerchantId: string }>) =>
          withContextLayout(
            <MerchantsDetailsContainer merchantId={props.match.params.detailsMerchantId} />,
            { noPadding: true, showNotifications: false }
          )
        }
      />
      <Route
        exact
        path={RouterPaths.BOOKING_RULES}
        component={() => withContextLayout(<BookingRules />)}
      />
      <Route path={RouterPaths.HOME} component={() => withContextLayout(<HomeContainer />)} />
    </Switch>
  );
};

export default RouterLayer;
