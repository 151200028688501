import { FieldState, FormState } from 'formstate';
import * as React from 'react';

import { Input, Row } from '../../../controls';
import { withFieldStateInput, withFormItem } from '../../../decorators';
import { TransportOrderModel } from '../../../models';

const InputField = withFormItem(withFieldStateInput(Input));

type IFormState = FormState<{
  referenceId1FieldState: FieldState<string>;
  referenceId2FieldState: FieldState<string>;
  referenceId3FieldState: FieldState<string>;
}>;

interface IProps {
  formState: IFormState;
}

export class ReferencesForm extends React.Component<IProps, {}> {
  static createFormState = (model: TransportOrderModel = new TransportOrderModel()): IFormState =>
    new FormState({
      referenceId1FieldState: new FieldState(
        model.external.referenceIds && model.external.referenceIds[0]
      ),
      referenceId2FieldState: new FieldState(
        model.external.referenceIds && model.external.referenceIds[1]
      ),
      referenceId3FieldState: new FieldState(
        model.external.referenceIds && model.external.referenceIds[2]
      ),
    });

  render() {
    const { formState } = this.props;

    return (
      <Row justifyContent={'space-around'}>
        <InputField
          label={`Reference ID 1`}
          fieldState={formState.$.referenceId1FieldState}
          error={formState.$.referenceId1FieldState.error}
        />
        <InputField
          label={`Reference ID 2`}
          fieldState={formState.$.referenceId2FieldState}
          error={formState.$.referenceId2FieldState.error}
        />
        <InputField
          label={`Reference ID 3`}
          fieldState={formState.$.referenceId3FieldState}
          error={formState.$.referenceId3FieldState.error}
        />
      </Row>
    );
  }
}
