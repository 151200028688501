import lift, { Option } from 'space-lift';

import { DeliveryStatus, DocumentModel, ParcelModel } from '../../models';
import defaultTheme from '../../styles/theme.default';
import { stringUtils } from '../../utils';

export const getTrackingId = (parcel: ParcelModel): string | undefined =>
  Option(parcel)
    .map(p => p.deliveries)
    .flatMap(ds => Option(lift(ds).first().get()))
    .map(d => d.trackingNumber)
    .get();

export const getLabel = (parcel: ParcelModel): DocumentModel | undefined =>
  Option(parcel.deliveries || [])
    .flatMap(ds => Option(ds[0]))
    .map(d => ({
      id: d.id,
      fileType: stringUtils.getFileExtension(d.labelUrl) || '',
      documentType: 'Delivery Label',
      type: 'Normal',
      url: d.labelUrl,
      parcelId: parcel.id,
      createdAt: '',
      carrier: d.shippingMethod.split('-')[0],
    }))
    .get();

export const getDeliveryTextColorForDeliveryStatus = (status: DeliveryStatus) => {
  switch (status) {
    case 'CREATED':
    case 'BOOKED':
    case 'PICKUP_REQUESTED':
    case 'PICKUP_SCHEDULED':
    case 'PICKUP_BOOKED':
    case 'IN_TRANSIT':
    case 'DELIVERY_IN_PROGRESS':
    case 'ARRIVED_AT_PICKUP_POINT':
    case 'PICKUP_REGISTERED':
    case 'NOTIFICATION_SENT':
    case 'BOOKING_RECEIVED_BY_CARRIER':
    case 'FORWARDED_TO_THIRD_PARTY':
    case 'IN_CUSTOMS':
    case 'PICKED_UP':
    case 'INSTORE_ARRIVED_AT_PICKUP_POINT':
    case 'INSTORE_PARCEL_RETURNED':
      return defaultTheme.color.statusPurple;

    case 'DELIVERED':
    case 'INSTORE_PARCEL_PICKED_UP':
      return defaultTheme.color.statusGreen;

    case 'CANCELED':
    case 'DELAYED':
    case 'RETURNED':
    case 'REDIRECTED':
    case 'RETURNED_TO_SENDER':
    case 'PICKUP_DELAYED':
    case 'CONTACT_MERCHANT_SUPPORT':
    case 'CONTACT_CARRIER_SUPPORT':
    case 'INSTORE_PARCEL_REFUSED':
    case 'INSTORE_PARCEL_OVERDUE':
      return defaultTheme.color.statusOrange;

    case 'INFO':
    case 'INSTORE_PARCEL_RELOCATED':
    case 'UNKNOWN':
      return defaultTheme.color.statusGrey;

    case 'ERROR':
    case 'FAILED':
    case 'LOST':
    case 'DAMAGED':
    case 'UNDELIVERABLE':
      return defaultTheme.color.dotStatusRed;

    default:
      return defaultTheme.color.statusGrey;
  }
};
