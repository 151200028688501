import { combineEpics } from 'redux-observable';
import { addressBookEpics } from './address-book';
import { appEpics } from './app';
import { authEpics } from './auth/auth-epics';
import { blobsEpics } from './blobs';
import { configEpics } from './config';
import { deliveriesEpics } from './deliveries/epics';
import { dictionariesEpics } from './dictionaries/epics';
import { eventTrackingEpics } from './event-tracking';
import { labelMergerEpics } from './label-merger';
import { merchantsEpics } from './merchants/merchants-epics';
import { reportsEpics } from './reports/epics';
import { sessionEventsEpics } from './session-events/epics';
import { shipmentNotificationsEpics } from './shipment-notifications';
import { shipmentsEpics } from './shipments';
import { sitesEpics } from './sites';
import { siwEpics } from './siw';
import { somSearchEpics } from './som-search/epics';
import { tagsEpics } from './tags/epics';
import { tokenAuthEpics } from './tokenauth';
import { trackingWidgetEpics } from './tracking-widget/epics';
import { transportOrdersEpics } from './transport-orders';
import { userdataEpics } from './userprofile';
import { usersEpics } from './users/users-epics';
export const rootEpic = combineEpics(
  eventTrackingEpics,
  appEpics,
  authEpics,
  blobsEpics,
  deliveriesEpics,
  dictionariesEpics,
  merchantsEpics,
  sitesEpics,
  usersEpics,
  transportOrdersEpics,
  shipmentsEpics,
  addressBookEpics,
  userdataEpics,
  siwEpics,
  reportsEpics,
  somSearchEpics,
  labelMergerEpics,
  tokenAuthEpics,
  tagsEpics,
  shipmentNotificationsEpics,
  configEpics,
  sessionEventsEpics,
  trackingWidgetEpics
);
