import * as React from 'react';
import { style as tss } from 'typestyle';

import { Link } from '@src/components';
import { FormattedMessage } from '@src/i18n';

type WarehouseLinkedRegionsProps = {
  regionsNamesIdsByWarehouseId: { [x: string]: string };
};

export const WarehouseLinkedRegions: React.FC<WarehouseLinkedRegionsProps> = ({
  regionsNamesIdsByWarehouseId,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <FormattedMessage id="LINKED_REGIONS" />
      </div>
      <span className={styles.linksWrapper}>
        {Object.entries(regionsNamesIdsByWarehouseId).map(([name, id], index) => (
          <span className={styles.linkWrapper} key={id}>
            <Link
              route={{ name: 'REGION_DETAILS', regionId: id }}
              baseStyles
              showIcon
              className={styles.link}
            >
              {name}
            </Link>
            {index === Object.values(regionsNamesIdsByWarehouseId).length - 1 ? '' : ','}
          </span>
        ))}
      </span>
    </div>
  );
};

const styles = {
  wrapper: tss({
    margin: '-2px 0 8px 0',
    display: 'flex',
    alignItems: 'flex-start',
  }),
  linksWrapper: tss({
    flexBasis: '75%',
    marginLeft: '15px',
    display: 'flex',
    flexWrap: 'wrap',
  }),
  label: tss({
    color: 'rgba(0, 0, 0, 0.5)',
    flexBasis: '25%',
  }),
  linkWrapper: tss({
    marginRight: '10px',
  }),
  link: tss({
    whiteSpace: 'nowrap',
  }),
};
