import { InboxOutlined } from '@ant-design/icons';
import * as React from 'react';
import { stylesheet } from 'typestyle';

import { useDispatch, useSelector } from '@src/utils/hooks';
import { ListFixedHeader, SessionEventsList } from '../../components';
import { sessionEventsSelectors, sessionHistoryActions } from '../../modules/session-events';

const SessionEventsPage: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(sessionEventsSelectors.isFetching);

  return (
    <div className={styles.componentWrapper}>
      <div className={styles.search}>
        <ListFixedHeader
          title={'Session History'}
          isLoading={isFetching}
          searchPlaceholder={'Enter session ID'}
          IconComponent={InboxOutlined}
          onSearchItems={(value: string) =>
            dispatch(sessionHistoryActions.listSessionEventsRequest({ sessionID: value }))
          }
        />
      </div>

      <SessionEventsList />
    </div>
  );
};

const styles = stylesheet({
  componentWrapper: {
    padding: '24px',
  },
  search: {
    paddingTop: '24px',
  },
});

export default SessionEventsPage;
