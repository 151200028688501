import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { connect } from 'react-redux';

import { ContainerHeader, ErrorAlert } from '@src/components';
import { Link } from '@src/components/link';
import { Button, Card, modalConfirm } from '@src/controls';
import { DOMAIN, ERRORS, LABELS, MESSAGES } from '@src/dictionaries';
import { RootState } from '@src/modules/';
import { authSelectors } from '@src/modules/auth';
import { eventTrackingActions } from '@src/modules/event-tracking';
import { tagsActions } from '@src/modules/tags';
import { userprofileActions } from '@src/modules/userprofile';
import TemplateList from './components/template-list';

// Component API
type OwnProps = {
  siteId: string;
};

const mapStateToProps = (state: RootState) => ({
  error: state.userprofile.error,
  pageSize: state.app.pageSize,
  isFetching: state.userprofile.isFetching,
  items: state.userprofile.templates,
  userId: authSelectors.getAuthUserId(state),
});

const dispatchProps = {
  getTemplates: userprofileActions.getTemplateListRequest,
  deleteTemplates: userprofileActions.deleteTemplatesRequest,
  templateListPageView: eventTrackingActions.templateListPageView,
  getSiteTags: tagsActions.listSiteTagsRequest,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & OwnProps;

@observer
class TemplateListContainer extends React.Component<Props> {
  @observable currentPage = 1;

  componentDidMount() {
    const { siteId, getTemplates, templateListPageView, getSiteTags, userId } = this.props;
    templateListPageView();
    getSiteTags({
      siteId,
      userId,
      onComplete: tags =>
        getTemplates({
          siteId,
          tags: tags.map(tag => tag.id),
        }),
    });
  }

  handlePaginationChange = (nextPage: number) => {
    this.currentPage = nextPage;
  };

  handleDeleteClick = (templateIds: string[], onDeleteCb: () => void) => {
    const { siteId } = this.props;

    modalConfirm({
      title: MESSAGES.DELETE_TEMPLATES_HEADER_LIST_VIEW(templateIds.length),
      content: MESSAGES.DELETE_TEMPLATES_CONTENT_LIST_VIEW(templateIds.length),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.props.deleteTemplates({ siteId, templateIds });
        onDeleteCb();
      },
    });
  };

  render() {
    const { items, isFetching, error } = this.props;
    return (
      <div>
        <ErrorAlert
          error={error}
          visible={!!error}
          showIcon={true}
          banner={true}
          closeText={ERRORS.CLOSE_ALERT}
        />
        <Card bordered={false}>
          <ContainerHeader
            title={DOMAIN.TEMPLATES}
            actions={
              <Link route={{ name: 'TEMPLATE_CREATE' }}>
                <Button type="primary"> {LABELS.CREATE}</Button>
              </Link>
            }
          />
          <TemplateList
            addressBookError={error}
            isLoading={isFetching}
            total={items.length}
            currentPage={this.currentPage}
            onPaginationChange={this.handlePaginationChange}
            items={items}
            onDelete={this.handleDeleteClick}
          />
        </Card>
      </div>
    );
  }
}

export default connect(mapStateToProps, dispatchProps)(TemplateListContainer);
