import { Direction } from '@src/containers/tracking-numbers/types';
import { v4 } from 'uuid';

import { AddressDTO, AddressModel, CustomerInfoDTO, CustomerInfoModel } from './';
import type { DeliveryStatus } from './tracking-models';

export class ShipmentSummaryDTO {
  id: string;
  customer_info: CustomerInfoDTO;
  shipping_date: string;
  shipping_method: string;
  created_at: string;
  address_from: AddressDTO;
  address_to: AddressDTO;
  status: DeliveryStatus;
  tracking_numbers: string[];
  direction_type?: Direction;

  static createFromModel(model: ShipmentSummaryModel): ShipmentSummaryDTO {
    return {
      id: model.id || v4(),
      customer_info:
        (model.customerInfo && CustomerInfoDTO.createFromModel(model.customerInfo)) ||
        new CustomerInfoModel(),
      shipping_date: model.shippingDate,
      created_at: model.createdAt,
      shipping_method: model.shippingMethod,
      address_from:
        (model.addressFrom && AddressDTO.createFromModel(model.addressFrom)) || new AddressModel(),
      address_to:
        (model.addressTo && AddressDTO.createFromModel(model.addressTo)) || new AddressModel(),
      status: model.status || 'UNKNOWN',
      tracking_numbers: model.trackingNumbers,
      direction_type: model.directionType,
    };
  }
}

export class ShipmentSummaryModel {
  id: string = v4();
  customerInfo: CustomerInfoModel = new CustomerInfoModel();
  shippingDate: string;
  shippingMethod: string = '';
  createdAt: string = '';
  addressFrom: AddressModel = new AddressModel();
  addressTo: AddressModel = new AddressModel();
  status: DeliveryStatus = 'UNKNOWN';
  trackingNumbers: string[];
  directionType?: Direction;

  static createFromDTO(model: ShipmentSummaryDTO): ShipmentSummaryModel {
    return {
      id: model.id,
      customerInfo:
        (model.customer_info && CustomerInfoModel.createFromDTO(model.customer_info)) ||
        new CustomerInfoModel(),
      shippingDate: model.shipping_date || '',
      shippingMethod: model.shipping_method || '',
      createdAt: model.created_at,
      addressFrom:
        (model.address_from && AddressModel.createFromDTO(model.address_from)) ||
        new AddressModel(),
      addressTo:
        (model.address_to && AddressModel.createFromDTO(model.address_to)) || new AddressModel(),
      status: (model.status === 'INVALID_BOOKING' ? 'CREATED' : model.status) || 'UNKNOWN',
      trackingNumbers: model.tracking_numbers,
      directionType: model.direction_type,
    };
  }
}
