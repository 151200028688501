import { getType } from 'typesafe-actions';

import { combineReducers } from 'redux';
import { optimistic, OptimisticState } from 'redux-optimistic-ui';
import { RBACGroupModel, RoleModel, UserGetModel, UserSummaryModel } from '../../models';
import { Reducer } from '../../modules';
import * as actions from './users-actions';

export type UsersState = Readonly<{
  fetching: boolean;
  error: string | null;
  users: UserGetModel[] | null;
  groups: OptimisticState<RBACGroupModel[] | null>;
  roles: RoleModel[] | null;
  userSummaries: UserSummaryModel[];
}>;

const fetching: Reducer<UsersState['fetching']> = (state = false, action) => {
  switch (action.type) {
    case getType(actions.fetchListUsers):
    case getType(actions.fetchCreateUser):
    case getType(actions.fetchDeleteUser):
    case getType(actions.updateUserRequest):
    case getType(actions.getUserRequest):
    case getType(actions.getGroupListRequest):
    case getType(actions.createGroupRequest):
    case getType(actions.deleteGroupRequest):
    case getType(actions.listRolesRequest):
    case getType(actions.updateGroupRequest):
      return true;

    case getType(actions.fetchListUsersSuccess):
    case getType(actions.fetchListUsersError):
    case getType(actions.fetchCreateUserSuccess):
    case getType(actions.fetchCreateUserError):
    case getType(actions.fetchDeleteUserSuccess):
    case getType(actions.fetchDeleteUserError):
    case getType(actions.updateUserSuccess):
    case getType(actions.updateUserError):
    case getType(actions.listRolesSuccess):
    case getType(actions.listRolesError):
    case getType(actions.getUserSuccess):
    case getType(actions.getUserError):
    case getType(actions.getGroupListSuccess):
    case getType(actions.getGroupListError):
    case getType(actions.createGroupSuccess):
    case getType(actions.createGroupError):
    case getType(actions.deleteGroupSuccess):
    case getType(actions.deleteGroupError):
    case getType(actions.updateGroupSuccess):
    case getType(actions.updateGroupError):
      return false;
    default:
      return state;
  }
};

const error: Reducer<UsersState['error']> = (state = null, action) => {
  switch (action.type) {
    case getType(actions.fetchListUsers):
    case getType(actions.fetchCreateUser):
    case getType(actions.fetchDeleteUser):
    case getType(actions.updateUserRequest):
    case getType(actions.getUserRequest):
    case getType(actions.getGroupListRequest):
    case getType(actions.createGroupRequest):
    case getType(actions.deleteGroupRequest):
    case getType(actions.listRolesRequest):
    case getType(actions.updateGroupRequest):
    case getType(actions.removeGroupFromUserRequest):
    case getType(actions.removeGroupFromUserSuccess):
      return null;

    case getType(actions.fetchListUsersError):
    case getType(actions.fetchCreateUserError):
    case getType(actions.fetchDeleteUserError):
    case getType(actions.updateUserError):
    case getType(actions.getUserError):
    case getType(actions.getGroupListError):
    case getType(actions.createGroupError):
    case getType(actions.deleteGroupError):
    case getType(actions.listRolesError):
    case getType(actions.updateGroupError):
    case getType(actions.removeGroupFromUserError):
      return action.payload;

    default:
      return state;
  }
};

const users: Reducer<UsersState['users']> = (state = null, action) => {
  switch (action.type) {
    case getType(actions.updateUserSuccess):
      return state && state.map(user => (user.id === action.payload.id ? action.payload : user));

    case getType(actions.getUserSuccess):
      return [action.payload];

    case getType(actions.fetchListUsersSuccess):
      return action.payload;

    case getType(actions.fetchCreateUserSuccess):
      return state ? state.concat(action.payload) : [action.payload];

    case getType(actions.fetchDeleteUserSuccess):
      return state && state.filter(user => user.id !== action.payload);

    default:
      return state;
  }
};

const groups: Reducer<UsersState['groups']['current']> = (state = null, action) => {
  switch (action.type) {
    case getType(actions.getGroupListSuccess):
      return action.payload;

    case getType(actions.deleteGroupSuccess):
      return state && state.filter(g => g.id !== action.payload.id);

    default:
      return state;
  }
};

const roles: Reducer<UsersState['roles']> = (state = null, action) => {
  switch (action.type) {
    case getType(actions.listRolesSuccess):
      return action.payload.roles;

    default:
      return state;
  }
};

const userSummaries: Reducer<UsersState['userSummaries']> = (state = [], action) => {
  switch (action.type) {
    case getType(actions.listUserSummariesSuccess):
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  fetching,
  error,
  users,
  groups: optimistic(groups),
  roles,
  userSummaries,
});
