export const deliveryStatusList = [
  'CREATED',
  'BOOKED',
  'PICKUP_REQUESTED',
  'PICKUP_SCHEDULED',
  'PICKUP_BOOKED',
  'IN_TRANSIT',
  'DELIVERED',
  'CANCELED',
  'DELIVERY_IN_PROGRESS',
  'ARRIVED_AT_PICKUP_POINT',
  'DELAYED',
  'RETURNED',
  'INFO',
  'ERROR',
  'PICKUP_REGISTERED',
  'NOTIFICATION_SENT',
  'BOOKING_RECEIVED_BY_CARRIER',
  'FAILED',
  'FORWARDED_TO_THIRD_PARTY',
  'IN_CUSTOMS',
  'REDIRECTED',
  'LOST',
  'UNKNOWN',
  'DAMAGED',
  'RETURNED_TO_SENDER',
  'PICKUP_DELAYED',
  'PICKED_UP',
  'UNDELIVERABLE',
  'CONTACT_MERCHANT_SUPPORT',
  'CONTACT_CARRIER_SUPPORT',
  'INSTORE_PARCEL_REFUSED',
  'INSTORE_PARCEL_OVERDUE',
  'INSTORE_PARCEL_PICKED_UP',
  'INSTORE_PARCEL_RELOCATED',
  'INSTORE_PARCEL_RETURNED',
  'INSTORE_ARRIVED_AT_PICKUP_POINT',
  'INVALID_BOOKING',
] as const;

export type DeliveryStatus = typeof deliveryStatusList[number];

export class DeliveryStatusDTO {
  delivery_id: string;
  status: DeliveryStatus;
  updated_at: string;

  static createFromModel(model: DeliveryStatusModel): DeliveryStatusDTO {
    return {
      delivery_id: model.deliveryId,
      status: model.status,
      updated_at: model.updatedAt,
    };
  }
}

export class DeliveryStatusModel {
  deliveryId: string;
  status: DeliveryStatus;
  updatedAt: string;

  static createFromDTO(model: DeliveryStatusDTO): DeliveryStatusModel {
    return {
      deliveryId: model.delivery_id,
      status: model.status ? model.status : 'UNKNOWN',
      updatedAt: model.updated_at,
    };
  }
}
