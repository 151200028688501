import { Table } from 'antd';
import React, { FC } from 'react';
import { style as tss } from 'typestyle';
import type { CustomsDeclarationItem } from '../types';
import { columns } from './columns';

type Props = {
  items: CustomsDeclarationItem[];
};

export const ItemsDetails: FC<Props> = ({ items }) => (
  <div>
    <h3 className={styles.title}>General Information</h3>
    <Table columns={columns} dataSource={items} pagination={false} className={styles.headerCell} />
  </div>
);

const styles = {
  title: tss({ marginBottom: '16px' }),
  headerCell: tss({
    $nest: {
      '.ant-table-thead th': {
        padding: '8px',
        lineHeight: '16px',
        fontWeight: 500,
        fontSize: '14px',
      },
      '.ant-table-tbody td': {
        padding: '8px',
      },
    },
  }),
};
