import { Modal } from '@src/controls';
import { useFormatMessage } from '@src/i18n';
import * as React from 'react';

export const UncaughtErrorModal: React.FunctionComponent<{
  onReset: () => void;
}> = ({ onReset }) => {
  const formatMessage = useFormatMessage();

  return (
    <Modal closable={false} visible={true} footer={null}>
      <h1>{formatMessage('ERROR_MODAL_HEADER')}</h1>
      <p>{formatMessage('ERROR_MODAL_MAIN_TEXT')}</p>
      <p>
        {formatMessage('ERROR_MODAL_SECONDARY_TEXT', {
          a: (str: string) => <a onClick={() => onReset()}>{str}</a>,
        })}
      </p>
    </Modal>
  );
};
