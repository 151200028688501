import { Field, FieldProps, Formik, FormikErrors, FormikTouched } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { classes, stylesheet } from 'typestyle';
import { array, ArraySchema, mixed, object, string } from 'yup';

import { Alert, Input, Modal, Radio, Select, Switch, Tag, TextArea } from '@src/controls';
import { withFormItem } from '@src/decorators';
import {
  AutoSaveForm,
  inputStyle,
  MakeAutoSaveFormikEnhancer,
  regionFormItemLayout,
} from '@src/forms';
import { useFormatMessage } from '@src/i18n';
import {
  ConfigRegionModel,
  ConfigShippingCategoryModel,
  ConfigWarehouseModel,
  UpdateConfigRegionModel,
} from '@src/models';
import { ConfigState } from '@src/modules/config';
import { defaultTheme } from '@src/styles';
import { getBooleanAsState, getStateAsBoolean } from '@src/utils/data-transformations';
import { getFormikError } from '@src/utils/forms';
import {
  getCountriesByRegionType,
  getRegionTypeByCountryConfig,
  getRegionZipCodesCodesDetails,
  regionTypeOptions,
  regionTypeOptionsWithDisabledZipCodes,
  sortCountriesCodesByTheirNames,
} from './helpers';
import { WarehouseField } from './warehouse-field';
import { services } from '@src/services';
import {
  ConditionRegion,
  getPriceRuleConditionFormValue,
  getPriceRuleType,
  PriceRuleType,
} from '../carrier-products/helpers';
import { difference } from 'lodash';
import { getRegionsOptions } from '@src/services/dictionaries-service';
import { without } from 'ramda';

const InputField = withFormItem(Input, regionFormItemLayout);
const SwitchField = withFormItem(Switch, regionFormItemLayout);
const RadioField = withFormItem(Radio, regionFormItemLayout);
const SelectField = withFormItem(Select, regionFormItemLayout);
const TextAreaField = withFormItem(TextArea, regionFormItemLayout);

export interface Props {
  categories: ConfigShippingCategoryModel[];
  region: ConfigRegionModel;
  handleUpdate: (model: Partial<UpdateConfigRegionModel>) => void;
  openWarehouseModal: () => void;
  warehouses: ConfigWarehouseModel[];
  handleWarehouseChange: (id: string) => void;
  copyCutoffsProgress: ConfigState['copyCutoffsProgress'];
  regionsNames: string[];
}

export interface RegionDetailsFormValues {
  id: string;
  active: boolean;
  name: string;
  createdAt: string;
  type: 'COUNTRIES_INCLUDED' | 'COUNTRIES_EXCLUDED' | 'ZIP_CODES_INCLUDED' | 'ZIP_CODES_EXCLUDED';
  countries: string[];
  zipCodes: string | undefined;
  warehouseId: string;
  regionsNames: string[];
  showHighDemandAffectsDeliveryDisclaimerMessage: boolean;
}

const AutoSaveFormikEnhancer = MakeAutoSaveFormikEnhancer<RegionDetailsFormValues>();

export const RegionDetails: React.FunctionComponent<Props> = ({
  categories,
  region,
  regionsNames,
  handleUpdate,
  openWarehouseModal,
  warehouses,
  handleWarehouseChange,
  copyCutoffsProgress,
}) => {
  const formattedMessage = useFormatMessage();
  const [countriesWaringModalVisible, setCountriesWaringModalVisible] = React.useState(false);

  // validation for name is separeted from RegionDetailsSchema as we need data from props.
  const validateRegionName = (value: string) => {
    const regionNamesInCapitals = regionsNames.map(regionName => regionName.toUpperCase());
    if (regionNamesInCapitals.includes(value.toUpperCase().trim()) && value !== region.name) {
      return 'This region name already exists';
    } else if (value.trim() === '') {
      return 'Name is a required field';
    }
    return '';
  };

  const onConnectedFieldsSave = (
    values: RegionDetailsFormValues,
    errors: FormikErrors<RegionDetailsFormValues>,
    setTouched: (
      touched: FormikTouched<RegionDetailsFormValues>,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    // we need to send whole country or zipCode config to the backend while
    // updating one of fields: 'type', 'countries' & 'zipCodes' - this helper
    // is for sending proper data
    if (values.type === 'COUNTRIES_INCLUDED') {
      handleUpdate({
        regionType: 'country',
        countryConfig: { included: values.countries },
      });
    } else if (values.type === 'COUNTRIES_EXCLUDED') {
      handleUpdate({
        regionType: 'country',
        countryConfig: { excluded: values.countries },
      });
    } else if (values.type === 'ZIP_CODES_INCLUDED') {
      if (errors.zipCodes) {
        // do not send request when zipCodes have errors, set this field
        // to touched instead to let user know about errors
        setTouched({ zipCodes: true });
        return;
      }
      handleUpdate({
        regionType: 'zipcode',
        regionPostalCodeConfig: {
          country: values.countries[0],
          postalCodes: values.zipCodes?.split(',') ?? [''],
        },
      });
    } else if (values.type === 'ZIP_CODES_EXCLUDED') {
      if (errors.zipCodes) {
        // do not send request when zipCodes have errors, set this field
        // to touched instead to let user know about errors
        setTouched({ zipCodes: true });
        return;
      }
      handleUpdate({
        regionType: 'zipcode',
        regionPostalCodeConfig: {
          country: values.countries[0],
          excludedPostalCodes: values.zipCodes?.split(',') ?? [''],
        },
      });
    }
  };

  const checkIfCountryCanBeRemoved = (removedCountry: string) => {
    const countryRegions = getRegionsOptions(removedCountry);

    if (countryRegions.length > 0) {
      const checkIfRegionIsFromRemovedCountry = (condition: string) => {
        const region = (getPriceRuleConditionFormValue(condition) as ConditionRegion).value;
        const regionComesFromCountry = countryRegions.find(({ value }) => value === region);
        return regionComesFromCountry;
      };

      const regionHasCountryDependentPriceRules = !!region.carrierProducts.find(product =>
        product.priceRules.find(
          priceRule =>
            (priceRule.condition &&
              getPriceRuleType(priceRule.condition) === PriceRuleType.REGION &&
              checkIfRegionIsFromRemovedCountry(priceRule.condition)) ||
            (priceRule.conditions &&
              priceRule.conditions.some(
                condition =>
                  getPriceRuleType(condition) === PriceRuleType.REGION &&
                  checkIfRegionIsFromRemovedCountry(condition)
              ))
        )
      );

      return !regionHasCountryDependentPriceRules;
    }
    return true;
  };

  const allCategoriesInactive = categories.every(category => category.state === 'INACTIVE');

  return (
    <>
      <div>
        <Tag color={defaultTheme.color.primary} nonClickable={true}>
          {formattedMessage('REGION')}
        </Tag>
        {allCategoriesInactive && (
          <Alert
            type="warning"
            message="All shipping categories are inactive, please enable at least one shipping category"
            style={{ marginTop: '10px' }}
          />
        )}
        <h2 className={styles.header}>{region.name}</h2>
      </div>
      <Formik<RegionDetailsFormValues>
        initialValues={{
          regionsNames,
          id: region.id,
          active: getStateAsBoolean(region.state),
          name: region.name,
          createdAt: moment(region.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          type: getRegionTypeByCountryConfig(
            region.regionCountryConfig,
            region.regionPostalCodeConfig
          ),
          countries: getCountriesByRegionType(region),
          zipCodes: getRegionZipCodesCodesDetails(region),
          warehouseId: region.warehouseId,
          showHighDemandAffectsDeliveryDisclaimerMessage:
            region.features?.showHighDemandAffectsDeliveryDisclaimerMessage ?? false,
        }}
        validationSchema={object().shape({
          type: mixed().oneOf(regionTypeOptions.map(option => option.value)),
          zipCodes: string().when('type', {
            is: (type: string) => type === 'ZIP_CODES_INCLUDED' || type === 'ZIP_CODES_EXCLUDED',
            then: (fieldSchema: ArraySchema<string>) =>
              fieldSchema.required(
                formattedMessage('FIELD_REQUIRED_VALIDATION', { field: 'Zip codes' })
              ),
          }),
          countries: array<string>().required(),
        })}
        onSubmit={() => undefined}
        enableReinitialize={true}
      >
        {({ errors, touched, setFieldValue, setTouched, values: { type, countries } }) => {
          return (
            <AutoSaveForm>
              <Field name="id">
                {({ field }: FieldProps<RegionDetailsFormValues['id']>) => (
                  <InputField
                    label={formattedMessage('ID')}
                    className={inputStyle}
                    {...field}
                    error={getFormikError(touched.id, errors.id)}
                    disabled={true}
                  />
                )}
              </Field>
              <AutoSaveFormikEnhancer
                name="active"
                onSave={values => handleUpdate({ state: getBooleanAsState(values.active) })}
                render={({ onInstantChange }) => (
                  <Field name="active">
                    {({ field }: FieldProps<RegionDetailsFormValues['active']>) => (
                      <SwitchField
                        label={formattedMessage('ACTIVE')}
                        labelTooltip={formattedMessage('REGION_ACTIVE_TOOLTIP')}
                        {...field}
                        checked={field.value}
                        onChange={value => {
                          onInstantChange(value);
                        }}
                      />
                    )}
                  </Field>
                )}
              />
              <AutoSaveFormikEnhancer
                name="name"
                onSave={values => handleUpdate({ ...values, name: values.name.trim() })}
                render={({ name, onBlur, onKeyDown }) => (
                  <Field name={name} validate={validateRegionName}>
                    {({ field }: FieldProps<RegionDetailsFormValues['name']>) => (
                      <InputField
                        label={formattedMessage('NAME')}
                        className={inputStyle}
                        error={getFormikError(touched.name, errors.name)}
                        {...field}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                      />
                    )}
                  </Field>
                )}
              />
              <AutoSaveFormikEnhancer
                name="type"
                onSave={values => onConnectedFieldsSave(values, errors, setTouched)}
                render={({ name, onInstantChange }) => (
                  <Field name={name}>
                    {({ field }: FieldProps<RegionDetailsFormValues['type']>) => (
                      <RadioField
                        {...field}
                        onChange={event => onInstantChange(event.target.value)}
                        value={field.value}
                        options={
                          countries.length > 1
                            ? regionTypeOptionsWithDisabledZipCodes
                            : regionTypeOptions
                        }
                        label={formattedMessage('TYPE')}
                        labelAlign="left"
                        className={styles.radioInput}
                        error={getFormikError(touched.type, errors.type)}
                      />
                    )}
                  </Field>
                )}
              />

              <AutoSaveFormikEnhancer
                name="countries"
                key={type}
                onSave={values => onConnectedFieldsSave(values, errors, setTouched)}
                render={({ name, onBlur, onInstantChange }) => (
                  <Field name={name}>
                    {({ field }: FieldProps<RegionDetailsFormValues['countries']>) => (
                      <SelectField
                        {...field}
                        onChange={(value: string[] | string) => {
                          const removedCountry = difference(field.value, value)[0];
                          const canBeRemoved = checkIfCountryCanBeRemoved(removedCountry);
                          if (!canBeRemoved) {
                            return setCountriesWaringModalVisible(true);
                          }

                          setFieldValue(
                            'countries',
                            sortCountriesCodesByTheirNames(Array.isArray(value) ? value : [value])
                          );
                        }}
                        groupOptions={services.dictionariesService.getCountriesGroupedByRegion()}
                        label={formattedMessage('COUNTRY', {
                          multiple: type !== 'ZIP_CODES_INCLUDED' && type !== 'ZIP_CODES_EXCLUDED',
                        })}
                        onBlur={onBlur}
                        labelAlign="left"
                        className={classes(inputStyle, styles.countriesInput)}
                        value={field.value}
                        mode={
                          type === 'ZIP_CODES_INCLUDED' || type === 'ZIP_CODES_EXCLUDED'
                            ? undefined
                            : 'multiple'
                        }
                        showSearch
                        filterOption={true}
                        onDeselect={(deselected: string) => {
                          const canBeRemoved = checkIfCountryCanBeRemoved(deselected);
                          if (canBeRemoved) {
                            onInstantChange(without([deselected], field.value ?? []));
                          }
                        }}
                        optionFilterProp="children"
                        error={getFormikError(touched.countries, errors.countries)}
                      />
                    )}
                  </Field>
                )}
              />
              {(type === 'ZIP_CODES_INCLUDED' || type === 'ZIP_CODES_EXCLUDED') && (
                <AutoSaveFormikEnhancer
                  name="zipCodes"
                  onSave={values => onConnectedFieldsSave(values, errors, setTouched)}
                  render={({ name, onBlur }) => (
                    <Field name={name}>
                      {({ field }: FieldProps<RegionDetailsFormValues['zipCodes']>) => (
                        <TextAreaField
                          label={formattedMessage('ZIP_CODES')}
                          labelAlign="left"
                          rows={5}
                          error={getFormikError(touched.zipCodes, errors.zipCodes)}
                          {...field}
                          onBlur={onBlur}
                          placeholder={formattedMessage('ZIP_CODES_TEXTAREA_PLACEHOLDER')}
                          required={true}
                          className={classes(inputStyle, styles.zipCodesField)}
                        />
                      )}
                    </Field>
                  )}
                />
              )}
              <WarehouseField
                region={region}
                warehouses={warehouses}
                handleUpdate={handleUpdate}
                openWarehouseModal={openWarehouseModal}
                onWarehouseChange={handleWarehouseChange}
                copyCutoffsProgress={copyCutoffsProgress}
              />
              <AutoSaveFormikEnhancer
                name="showHighDemandAffectsDeliveryDisclaimerMessage"
                onSave={values => {
                  handleUpdate({
                    features: {
                      showHighDemandAffectsDeliveryDisclaimerMessage:
                        values.showHighDemandAffectsDeliveryDisclaimerMessage,
                    },
                  });
                }}
                render={({ onInstantChange }) => (
                  <Field name="showHighDemandAffectsDeliveryDisclaimerMessage">
                    {({
                      field,
                    }: FieldProps<
                      RegionDetailsFormValues['showHighDemandAffectsDeliveryDisclaimerMessage']
                    >) => (
                      <>
                        <SwitchField
                          label={formattedMessage('HIGH_DEMAND_TOGGLE_LABEL')}
                          labelTooltip={formattedMessage('HIGH_DEMAND_TOGGLE_TOOLTIP')}
                          {...field}
                          checked={field.value}
                          onChange={value => {
                            onInstantChange(value);
                          }}
                        />
                      </>
                    )}
                  </Field>
                )}
              />
              <Field name="createdAt">
                {({ field }: FieldProps<RegionDetailsFormValues['createdAt']>) => (
                  <InputField
                    label={formattedMessage('CREATED_AT')}
                    className={inputStyle}
                    {...field}
                    error={getFormikError(touched.createdAt, errors.createdAt)}
                    disabled={true}
                  />
                )}
              </Field>
            </AutoSaveForm>
          );
        }}
      </Formik>
      <Modal
        visible={countriesWaringModalVisible}
        closable={true}
        footer={null}
        onCancel={() => setCountriesWaringModalVisible(false)}
      >
        {formattedMessage('REGION.COUNTRIES.PRICE_RULES_WARNING_MODAL')}
      </Modal>
    </>
  );
};

const styles = stylesheet({
  header: {
    marginTop: '10px',
  },
  link: {
    marginLeft: '11px',
  },
  countriesInput: {
    $nest: {
      '.ant-select-selection__rendered': {
        marginRight: 0,
      },
    },
  },
  radioInput: {
    $nest: {
      '> * + *': {
        marginTop: '8px',
      },
    },
  },
  zipCodesField: {
    fontFeatureSettings: 'normal',
  },
});
