import { connect, FormikProps, getIn } from 'formik';
import { equals, isEmpty, type } from 'ramda';
import * as React from 'react';

interface OwnProps<FormValues = any> {
  render: ({
    onBlur,
    onKeyDown,
    onOpenChange,
    name,
    onInstantChange,
  }: {
    name: Extract<keyof FormValues, string>;
    // Binding the value to FormValues[OwnProps['name']] would be great, hard to achieve with TS 3.5.2
    onInstantChange: (value: FormValues[keyof FormValues], validate?: boolean) => void;
    onBlur: <E>(event: E) => void;
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onOpenChange: (open: boolean) => void;
  }) => JSX.Element | JSX.Element[];
  name: Extract<keyof FormValues, string>;
  onSave: (values: FormValues) => void;
  validateAllFields?: boolean;
}

interface FormProps<Values> extends OwnProps<Values> {
  formik: FormikProps<Values>;
}

type FormValue = string | number | null;

interface State {
  temporaryFormValue: FormValue;
}

class Component<Values> extends React.Component<FormProps<Values>, State> {
  state = {
    temporaryFormValue: getIn(this.props.formik.values, this.props.name),
  };

  handleBlur = (event: any) => {
    // Select passes a string value, while other inputs pass event, hence the ifs below
    if (type(event) === 'Object') {
      this.props.formik.handleBlur(event);
      this.autoSave();
    } else {
      this.props.formik.setFieldTouched(this.props.name);
      this.props.formik.validateForm().then(() => this.autoSave());
    }
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'Enter':
      case 'Escape':
        this.autoSave();
    }
  };

  hasValueChanged = (newValues?: Values) => {
    const { formik, name } = this.props;

    const values = newValues || formik.values;
    if (equals(getIn(values, name), this.state.temporaryFormValue)) {
      return false;
    } else {
      return true;
    }
  };

  // this is antd TimePicker specific
  handleOpenChange = (open: boolean) => {
    if (!open) {
      // without this wait formik has old value from the time picker, its preety weird but will do for now
      setTimeout(() => this.autoSave(), 0);
    }
  };

  handleInstantChange = (value: any, validate: boolean = true) => {
    const { formik, name } = this.props;
    // Use for Select/Switches, things that expose value on change
    formik.setFieldValue(name, value);
    formik.setFieldTouched(name, true, false);
    const newValues = {
      ...formik.values,
      [name]: value,
    };

    if (validate) {
      formik.validateForm(newValues).then(() => {
        this.autoSave(newValues, { checkIfValueHasChanged: false });
      });
    } else {
      this.autoSave(newValues, { checkIfValueHasChanged: false });
    }
  };

  autoSave = (
    newValues?: Values,
    options: { checkIfValueHasChanged: boolean } = { checkIfValueHasChanged: true }
  ) => {
    const { onSave, name, formik, validateAllFields } = this.props;
    const values = newValues || formik.values;

    if (validateAllFields && !isEmpty(formik.errors)) {
      this.setState({ temporaryFormValue: getIn(values, name) });
      return;
    } else if (formik.errors[name]) {
      this.setState({ temporaryFormValue: getIn(values, name) });
      return;
    }

    if (options && options.checkIfValueHasChanged) {
      if (this.hasValueChanged(values)) {
        this.setState({ temporaryFormValue: getIn(values, name) });
        onSave(values);
      }
    } else {
      this.setState({ temporaryFormValue: getIn(values, name) });
      onSave(values);
    }
  };

  render() {
    const { render, name } = this.props;
    return render({
      onBlur: this.handleBlur,
      onKeyDown: this.handleKeyDown,
      onOpenChange: this.handleOpenChange,
      onInstantChange: this.handleInstantChange,
      name,
    });
  }
}

export const AutoSaveFormikEnhancer = connect<OwnProps, {}>(Component);

// Use the one below if you want typed form Values
export const MakeAutoSaveFormikEnhancer = <T extends {}>() => {
  class TypedAutoSaveEnhancer extends Component<T> {}

  return connect<OwnProps<T>, T>(TypedAutoSaveEnhancer);
};
